/*eslint-disable block-scoped-var, no-redeclare, no-control-regex, no-prototype-builtins*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

    $root.visor = (function() {

        /**
         * Namespace visor.
         * @exports visor
         * @namespace
         */
        var visor = {};

        visor.clientrpc = (function() {

            /**
             * Namespace clientrpc.
             * @memberof visor
             * @namespace
             */
            var clientrpc = {};

            clientrpc.plaid = (function() {

                /**
                 * Namespace plaid.
                 * @memberof visor.clientrpc
                 * @namespace
                 */
                var plaid = {};

                plaid.Institution = (function() {

                    /**
                     * Properties of an Institution.
                     * @memberof visor.clientrpc.plaid
                     * @interface IInstitution
                     * @property {string|null} [id] Institution id
                     * @property {string|null} [name] Institution name
                     * @property {string|null} [logoUrl] Institution logoUrl
                     */

                    /**
                     * Constructs a new Institution.
                     * @memberof visor.clientrpc.plaid
                     * @classdesc Represents an Institution.
                     * @implements IInstitution
                     * @constructor
                     * @param {visor.clientrpc.plaid.IInstitution=} [properties] Properties to set
                     */
                    function Institution(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Institution id.
                     * @member {string} id
                     * @memberof visor.clientrpc.plaid.Institution
                     * @instance
                     */
                    Institution.prototype.id = "";

                    /**
                     * Institution name.
                     * @member {string} name
                     * @memberof visor.clientrpc.plaid.Institution
                     * @instance
                     */
                    Institution.prototype.name = "";

                    /**
                     * Institution logoUrl.
                     * @member {string} logoUrl
                     * @memberof visor.clientrpc.plaid.Institution
                     * @instance
                     */
                    Institution.prototype.logoUrl = "";

                    /**
                     * Creates a new Institution instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {visor.clientrpc.plaid.IInstitution=} [properties] Properties to set
                     * @returns {visor.clientrpc.plaid.Institution} Institution instance
                     */
                    Institution.create = function create(properties) {
                        return new Institution(properties);
                    };

                    /**
                     * Encodes the specified Institution message. Does not implicitly {@link visor.clientrpc.plaid.Institution.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {visor.clientrpc.plaid.IInstitution} message Institution message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Institution.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.logoUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified Institution message, length delimited. Does not implicitly {@link visor.clientrpc.plaid.Institution.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {visor.clientrpc.plaid.IInstitution} message Institution message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Institution.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Institution message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.plaid.Institution} Institution
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Institution.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.plaid.Institution();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.logoUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Institution message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.plaid.Institution} Institution
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Institution.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Institution message.
                     * @function verify
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Institution.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
                            if (!$util.isString(message.logoUrl))
                                return "logoUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates an Institution message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.plaid.Institution} Institution
                     */
                    Institution.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.plaid.Institution)
                            return object;
                        var message = new $root.visor.clientrpc.plaid.Institution();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.logoUrl != null)
                            message.logoUrl = String(object.logoUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Institution message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.plaid.Institution
                     * @static
                     * @param {visor.clientrpc.plaid.Institution} message Institution
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Institution.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.name = "";
                            object.logoUrl = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
                            object.logoUrl = message.logoUrl;
                        return object;
                    };

                    /**
                     * Converts this Institution to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.plaid.Institution
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Institution.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Institution;
                })();

                plaid.InstitutionManifest = (function() {

                    /**
                     * Properties of an InstitutionManifest.
                     * @memberof visor.clientrpc.plaid
                     * @interface IInstitutionManifest
                     * @property {Array.<visor.clientrpc.plaid.IInstitution>|null} [allInstitutions] InstitutionManifest allInstitutions
                     * @property {Array.<visor.clientrpc.plaid.IInstitution>|null} [topInstitutions] InstitutionManifest topInstitutions
                     */

                    /**
                     * Constructs a new InstitutionManifest.
                     * @memberof visor.clientrpc.plaid
                     * @classdesc Represents an InstitutionManifest.
                     * @implements IInstitutionManifest
                     * @constructor
                     * @param {visor.clientrpc.plaid.IInstitutionManifest=} [properties] Properties to set
                     */
                    function InstitutionManifest(properties) {
                        this.allInstitutions = [];
                        this.topInstitutions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InstitutionManifest allInstitutions.
                     * @member {Array.<visor.clientrpc.plaid.IInstitution>} allInstitutions
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @instance
                     */
                    InstitutionManifest.prototype.allInstitutions = $util.emptyArray;

                    /**
                     * InstitutionManifest topInstitutions.
                     * @member {Array.<visor.clientrpc.plaid.IInstitution>} topInstitutions
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @instance
                     */
                    InstitutionManifest.prototype.topInstitutions = $util.emptyArray;

                    /**
                     * Creates a new InstitutionManifest instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {visor.clientrpc.plaid.IInstitutionManifest=} [properties] Properties to set
                     * @returns {visor.clientrpc.plaid.InstitutionManifest} InstitutionManifest instance
                     */
                    InstitutionManifest.create = function create(properties) {
                        return new InstitutionManifest(properties);
                    };

                    /**
                     * Encodes the specified InstitutionManifest message. Does not implicitly {@link visor.clientrpc.plaid.InstitutionManifest.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {visor.clientrpc.plaid.IInstitutionManifest} message InstitutionManifest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InstitutionManifest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.allInstitutions != null && message.allInstitutions.length)
                            for (var i = 0; i < message.allInstitutions.length; ++i)
                                $root.visor.clientrpc.plaid.Institution.encode(message.allInstitutions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.topInstitutions != null && message.topInstitutions.length)
                            for (var i = 0; i < message.topInstitutions.length; ++i)
                                $root.visor.clientrpc.plaid.Institution.encode(message.topInstitutions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified InstitutionManifest message, length delimited. Does not implicitly {@link visor.clientrpc.plaid.InstitutionManifest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {visor.clientrpc.plaid.IInstitutionManifest} message InstitutionManifest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InstitutionManifest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InstitutionManifest message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.plaid.InstitutionManifest} InstitutionManifest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InstitutionManifest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.plaid.InstitutionManifest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.allInstitutions && message.allInstitutions.length))
                                    message.allInstitutions = [];
                                message.allInstitutions.push($root.visor.clientrpc.plaid.Institution.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.topInstitutions && message.topInstitutions.length))
                                    message.topInstitutions = [];
                                message.topInstitutions.push($root.visor.clientrpc.plaid.Institution.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InstitutionManifest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.plaid.InstitutionManifest} InstitutionManifest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InstitutionManifest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InstitutionManifest message.
                     * @function verify
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InstitutionManifest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.allInstitutions != null && message.hasOwnProperty("allInstitutions")) {
                            if (!Array.isArray(message.allInstitutions))
                                return "allInstitutions: array expected";
                            for (var i = 0; i < message.allInstitutions.length; ++i) {
                                var error = $root.visor.clientrpc.plaid.Institution.verify(message.allInstitutions[i]);
                                if (error)
                                    return "allInstitutions." + error;
                            }
                        }
                        if (message.topInstitutions != null && message.hasOwnProperty("topInstitutions")) {
                            if (!Array.isArray(message.topInstitutions))
                                return "topInstitutions: array expected";
                            for (var i = 0; i < message.topInstitutions.length; ++i) {
                                var error = $root.visor.clientrpc.plaid.Institution.verify(message.topInstitutions[i]);
                                if (error)
                                    return "topInstitutions." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an InstitutionManifest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.plaid.InstitutionManifest} InstitutionManifest
                     */
                    InstitutionManifest.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.plaid.InstitutionManifest)
                            return object;
                        var message = new $root.visor.clientrpc.plaid.InstitutionManifest();
                        if (object.allInstitutions) {
                            if (!Array.isArray(object.allInstitutions))
                                throw TypeError(".visor.clientrpc.plaid.InstitutionManifest.allInstitutions: array expected");
                            message.allInstitutions = [];
                            for (var i = 0; i < object.allInstitutions.length; ++i) {
                                if (typeof object.allInstitutions[i] !== "object")
                                    throw TypeError(".visor.clientrpc.plaid.InstitutionManifest.allInstitutions: object expected");
                                message.allInstitutions[i] = $root.visor.clientrpc.plaid.Institution.fromObject(object.allInstitutions[i]);
                            }
                        }
                        if (object.topInstitutions) {
                            if (!Array.isArray(object.topInstitutions))
                                throw TypeError(".visor.clientrpc.plaid.InstitutionManifest.topInstitutions: array expected");
                            message.topInstitutions = [];
                            for (var i = 0; i < object.topInstitutions.length; ++i) {
                                if (typeof object.topInstitutions[i] !== "object")
                                    throw TypeError(".visor.clientrpc.plaid.InstitutionManifest.topInstitutions: object expected");
                                message.topInstitutions[i] = $root.visor.clientrpc.plaid.Institution.fromObject(object.topInstitutions[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an InstitutionManifest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @static
                     * @param {visor.clientrpc.plaid.InstitutionManifest} message InstitutionManifest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InstitutionManifest.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.allInstitutions = [];
                            object.topInstitutions = [];
                        }
                        if (message.allInstitutions && message.allInstitutions.length) {
                            object.allInstitutions = [];
                            for (var j = 0; j < message.allInstitutions.length; ++j)
                                object.allInstitutions[j] = $root.visor.clientrpc.plaid.Institution.toObject(message.allInstitutions[j], options);
                        }
                        if (message.topInstitutions && message.topInstitutions.length) {
                            object.topInstitutions = [];
                            for (var j = 0; j < message.topInstitutions.length; ++j)
                                object.topInstitutions[j] = $root.visor.clientrpc.plaid.Institution.toObject(message.topInstitutions[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this InstitutionManifest to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.plaid.InstitutionManifest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InstitutionManifest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return InstitutionManifest;
                })();

                return plaid;
            })();

            clientrpc.ClientRPCService = (function() {

                /**
                 * Constructs a new ClientRPCService service.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ClientRPCService
                 * @extends $protobuf.rpc.Service
                 * @constructor
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 */
                function ClientRPCService(rpcImpl, requestDelimited, responseDelimited) {
                    $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                }

                (ClientRPCService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ClientRPCService;

                /**
                 * Creates new ClientRPCService service using the specified rpc implementation.
                 * @function create
                 * @memberof visor.clientrpc.ClientRPCService
                 * @static
                 * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                 * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                 * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                 * @returns {ClientRPCService} RPC service. Useful where requests and/or responses are streamed.
                 */
                ClientRPCService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                    return new this(rpcImpl, requestDelimited, responseDelimited);
                };

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#testErrors}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef TestErrorsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.TestErrorsResponse} [response] TestErrorsResponse
                 */

                /**
                 * Calls TestErrors.
                 * @function testErrors
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ITestErrorsRequest} request TestErrorsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.TestErrorsCallback} callback Node-style callback called with the error, if any, and TestErrorsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.testErrors = function testErrors(request, callback) {
                    return this.rpcCall(testErrors, $root.visor.clientrpc.TestErrorsRequest, $root.visor.clientrpc.TestErrorsResponse, request, callback);
                }, "name", { value: "TestErrors" });

                /**
                 * Calls TestErrors.
                 * @function testErrors
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ITestErrorsRequest} request TestErrorsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.TestErrorsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#ping}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef PingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.PingResponse} [response] PingResponse
                 */

                /**
                 * Calls Ping.
                 * @function ping
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPingRequest} request PingRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.PingCallback} callback Node-style callback called with the error, if any, and PingResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.ping = function ping(request, callback) {
                    return this.rpcCall(ping, $root.visor.clientrpc.PingRequest, $root.visor.clientrpc.PingResponse, request, callback);
                }, "name", { value: "Ping" });

                /**
                 * Calls Ping.
                 * @function ping
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPingRequest} request PingRequest message or plain object
                 * @returns {Promise<visor.clientrpc.PingResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#startOnboarding}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef StartOnboardingCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.StartOnboardingResponse} [response] StartOnboardingResponse
                 */

                /**
                 * Calls StartOnboarding.
                 * @function startOnboarding
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStartOnboardingRequest} request StartOnboardingRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.StartOnboardingCallback} callback Node-style callback called with the error, if any, and StartOnboardingResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.startOnboarding = function startOnboarding(request, callback) {
                    return this.rpcCall(startOnboarding, $root.visor.clientrpc.StartOnboardingRequest, $root.visor.clientrpc.StartOnboardingResponse, request, callback);
                }, "name", { value: "StartOnboarding" });

                /**
                 * Calls StartOnboarding.
                 * @function startOnboarding
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStartOnboardingRequest} request StartOnboardingRequest message or plain object
                 * @returns {Promise<visor.clientrpc.StartOnboardingResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#appLaunch}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef AppLaunchCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.AppLaunchResponse} [response] AppLaunchResponse
                 */

                /**
                 * Calls AppLaunch.
                 * @function appLaunch
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IAppLaunchRequest} request AppLaunchRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.AppLaunchCallback} callback Node-style callback called with the error, if any, and AppLaunchResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.appLaunch = function appLaunch(request, callback) {
                    return this.rpcCall(appLaunch, $root.visor.clientrpc.AppLaunchRequest, $root.visor.clientrpc.AppLaunchResponse, request, callback);
                }, "name", { value: "AppLaunch" });

                /**
                 * Calls AppLaunch.
                 * @function appLaunch
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IAppLaunchRequest} request AppLaunchRequest message or plain object
                 * @returns {Promise<visor.clientrpc.AppLaunchResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeLedgerStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteLedgerStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteLedgerStepResponse} [response] CompleteLedgerStepResponse
                 */

                /**
                 * Calls CompleteLedgerStep.
                 * @function completeLedgerStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteLedgerStepRequest} request CompleteLedgerStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteLedgerStepCallback} callback Node-style callback called with the error, if any, and CompleteLedgerStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeLedgerStep = function completeLedgerStep(request, callback) {
                    return this.rpcCall(completeLedgerStep, $root.visor.clientrpc.CompleteLedgerStepRequest, $root.visor.clientrpc.CompleteLedgerStepResponse, request, callback);
                }, "name", { value: "CompleteLedgerStep" });

                /**
                 * Calls CompleteLedgerStep.
                 * @function completeLedgerStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteLedgerStepRequest} request CompleteLedgerStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteLedgerStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeReviewBoostPerformanceStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteReviewBoostPerformanceStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteReviewBoostPerformanceStepResponse} [response] CompleteReviewBoostPerformanceStepResponse
                 */

                /**
                 * Calls CompleteReviewBoostPerformanceStep.
                 * @function completeReviewBoostPerformanceStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepRequest} request CompleteReviewBoostPerformanceStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteReviewBoostPerformanceStepCallback} callback Node-style callback called with the error, if any, and CompleteReviewBoostPerformanceStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeReviewBoostPerformanceStep = function completeReviewBoostPerformanceStep(request, callback) {
                    return this.rpcCall(completeReviewBoostPerformanceStep, $root.visor.clientrpc.CompleteReviewBoostPerformanceStepRequest, $root.visor.clientrpc.CompleteReviewBoostPerformanceStepResponse, request, callback);
                }, "name", { value: "CompleteReviewBoostPerformanceStep" });

                /**
                 * Calls CompleteReviewBoostPerformanceStep.
                 * @function completeReviewBoostPerformanceStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepRequest} request CompleteReviewBoostPerformanceStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteReviewBoostPerformanceStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeTextStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteTextStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteTextStepResponse} [response] CompleteTextStepResponse
                 */

                /**
                 * Calls CompleteTextStep.
                 * @function completeTextStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTextStepRequest} request CompleteTextStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteTextStepCallback} callback Node-style callback called with the error, if any, and CompleteTextStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeTextStep = function completeTextStep(request, callback) {
                    return this.rpcCall(completeTextStep, $root.visor.clientrpc.CompleteTextStepRequest, $root.visor.clientrpc.CompleteTextStepResponse, request, callback);
                }, "name", { value: "CompleteTextStep" });

                /**
                 * Calls CompleteTextStep.
                 * @function completeTextStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTextStepRequest} request CompleteTextStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteTextStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeDateStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteDateStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteDateStepResponse} [response] CompleteDateStepResponse
                 */

                /**
                 * Calls CompleteDateStep.
                 * @function completeDateStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDateStepRequest} request CompleteDateStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteDateStepCallback} callback Node-style callback called with the error, if any, and CompleteDateStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeDateStep = function completeDateStep(request, callback) {
                    return this.rpcCall(completeDateStep, $root.visor.clientrpc.CompleteDateStepRequest, $root.visor.clientrpc.CompleteDateStepResponse, request, callback);
                }, "name", { value: "CompleteDateStep" });

                /**
                 * Calls CompleteDateStep.
                 * @function completeDateStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDateStepRequest} request CompleteDateStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteDateStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeChoiceStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteChoiceStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteChoiceStepResponse} [response] CompleteChoiceStepResponse
                 */

                /**
                 * Calls CompleteChoiceStep.
                 * @function completeChoiceStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteChoiceStepRequest} request CompleteChoiceStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteChoiceStepCallback} callback Node-style callback called with the error, if any, and CompleteChoiceStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeChoiceStep = function completeChoiceStep(request, callback) {
                    return this.rpcCall(completeChoiceStep, $root.visor.clientrpc.CompleteChoiceStepRequest, $root.visor.clientrpc.CompleteChoiceStepResponse, request, callback);
                }, "name", { value: "CompleteChoiceStep" });

                /**
                 * Calls CompleteChoiceStep.
                 * @function completeChoiceStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteChoiceStepRequest} request CompleteChoiceStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteChoiceStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeYesNoStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteYesNoStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteYesNoStepResponse} [response] CompleteYesNoStepResponse
                 */

                /**
                 * Calls CompleteYesNoStep.
                 * @function completeYesNoStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteYesNoStepRequest} request CompleteYesNoStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteYesNoStepCallback} callback Node-style callback called with the error, if any, and CompleteYesNoStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeYesNoStep = function completeYesNoStep(request, callback) {
                    return this.rpcCall(completeYesNoStep, $root.visor.clientrpc.CompleteYesNoStepRequest, $root.visor.clientrpc.CompleteYesNoStepResponse, request, callback);
                }, "name", { value: "CompleteYesNoStep" });

                /**
                 * Calls CompleteYesNoStep.
                 * @function completeYesNoStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteYesNoStepRequest} request CompleteYesNoStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteYesNoStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeAmountStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteAmountStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteAmountStepResponse} [response] CompleteAmountStepResponse
                 */

                /**
                 * Calls CompleteAmountStep.
                 * @function completeAmountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteAmountStepRequest} request CompleteAmountStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteAmountStepCallback} callback Node-style callback called with the error, if any, and CompleteAmountStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeAmountStep = function completeAmountStep(request, callback) {
                    return this.rpcCall(completeAmountStep, $root.visor.clientrpc.CompleteAmountStepRequest, $root.visor.clientrpc.CompleteAmountStepResponse, request, callback);
                }, "name", { value: "CompleteAmountStep" });

                /**
                 * Calls CompleteAmountStep.
                 * @function completeAmountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteAmountStepRequest} request CompleteAmountStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteAmountStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeCurrencyStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteCurrencyStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteCurrencyStepResponse} [response] CompleteCurrencyStepResponse
                 */

                /**
                 * Calls CompleteCurrencyStep.
                 * @function completeCurrencyStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCurrencyStepRequest} request CompleteCurrencyStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteCurrencyStepCallback} callback Node-style callback called with the error, if any, and CompleteCurrencyStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeCurrencyStep = function completeCurrencyStep(request, callback) {
                    return this.rpcCall(completeCurrencyStep, $root.visor.clientrpc.CompleteCurrencyStepRequest, $root.visor.clientrpc.CompleteCurrencyStepResponse, request, callback);
                }, "name", { value: "CompleteCurrencyStep" });

                /**
                 * Calls CompleteCurrencyStep.
                 * @function completeCurrencyStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCurrencyStepRequest} request CompleteCurrencyStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteCurrencyStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeFinancialCatchupStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteFinancialCatchupStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteFinancialCatchupStepResponse} [response] CompleteFinancialCatchupStepResponse
                 */

                /**
                 * Calls CompleteFinancialCatchupStep.
                 * @function completeFinancialCatchupStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepRequest} request CompleteFinancialCatchupStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteFinancialCatchupStepCallback} callback Node-style callback called with the error, if any, and CompleteFinancialCatchupStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeFinancialCatchupStep = function completeFinancialCatchupStep(request, callback) {
                    return this.rpcCall(completeFinancialCatchupStep, $root.visor.clientrpc.CompleteFinancialCatchupStepRequest, $root.visor.clientrpc.CompleteFinancialCatchupStepResponse, request, callback);
                }, "name", { value: "CompleteFinancialCatchupStep" });

                /**
                 * Calls CompleteFinancialCatchupStep.
                 * @function completeFinancialCatchupStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepRequest} request CompleteFinancialCatchupStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteFinancialCatchupStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeDataEntryStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteDataEntryStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteDataEntryStepResponse} [response] CompleteDataEntryStepResponse
                 */

                /**
                 * Calls CompleteDataEntryStep.
                 * @function completeDataEntryStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDataEntryStepRequest} request CompleteDataEntryStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteDataEntryStepCallback} callback Node-style callback called with the error, if any, and CompleteDataEntryStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeDataEntryStep = function completeDataEntryStep(request, callback) {
                    return this.rpcCall(completeDataEntryStep, $root.visor.clientrpc.CompleteDataEntryStepRequest, $root.visor.clientrpc.CompleteDataEntryStepResponse, request, callback);
                }, "name", { value: "CompleteDataEntryStep" });

                /**
                 * Calls CompleteDataEntryStep.
                 * @function completeDataEntryStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDataEntryStepRequest} request CompleteDataEntryStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteDataEntryStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeConnectPayrollStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteConnectPayrollStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteConnectPayrollStepResponse} [response] CompleteConnectPayrollStepResponse
                 */

                /**
                 * Calls CompleteConnectPayrollStep.
                 * @function completeConnectPayrollStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepRequest} request CompleteConnectPayrollStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteConnectPayrollStepCallback} callback Node-style callback called with the error, if any, and CompleteConnectPayrollStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeConnectPayrollStep = function completeConnectPayrollStep(request, callback) {
                    return this.rpcCall(completeConnectPayrollStep, $root.visor.clientrpc.CompleteConnectPayrollStepRequest, $root.visor.clientrpc.CompleteConnectPayrollStepResponse, request, callback);
                }, "name", { value: "CompleteConnectPayrollStep" });

                /**
                 * Calls CompleteConnectPayrollStep.
                 * @function completeConnectPayrollStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepRequest} request CompleteConnectPayrollStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteConnectPayrollStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeReviewPayrollStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteReviewPayrollStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteReviewPayrollStepResponse} [response] CompleteReviewPayrollStepResponse
                 */

                /**
                 * Calls CompleteReviewPayrollStep.
                 * @function completeReviewPayrollStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepRequest} request CompleteReviewPayrollStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteReviewPayrollStepCallback} callback Node-style callback called with the error, if any, and CompleteReviewPayrollStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeReviewPayrollStep = function completeReviewPayrollStep(request, callback) {
                    return this.rpcCall(completeReviewPayrollStep, $root.visor.clientrpc.CompleteReviewPayrollStepRequest, $root.visor.clientrpc.CompleteReviewPayrollStepResponse, request, callback);
                }, "name", { value: "CompleteReviewPayrollStep" });

                /**
                 * Calls CompleteReviewPayrollStep.
                 * @function completeReviewPayrollStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepRequest} request CompleteReviewPayrollStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteReviewPayrollStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeInterstitialStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteInterstitialStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteInterstitialStepResponse} [response] CompleteInterstitialStepResponse
                 */

                /**
                 * Calls CompleteInterstitialStep.
                 * @function completeInterstitialStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteInterstitialStepRequest} request CompleteInterstitialStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteInterstitialStepCallback} callback Node-style callback called with the error, if any, and CompleteInterstitialStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeInterstitialStep = function completeInterstitialStep(request, callback) {
                    return this.rpcCall(completeInterstitialStep, $root.visor.clientrpc.CompleteInterstitialStepRequest, $root.visor.clientrpc.CompleteInterstitialStepResponse, request, callback);
                }, "name", { value: "CompleteInterstitialStep" });

                /**
                 * Calls CompleteInterstitialStep.
                 * @function completeInterstitialStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteInterstitialStepRequest} request CompleteInterstitialStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteInterstitialStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeSignupStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteSignupStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteSignupStepResponse} [response] CompleteSignupStepResponse
                 */

                /**
                 * Calls CompleteSignupStep.
                 * @function completeSignupStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSignupStepRequest} request CompleteSignupStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteSignupStepCallback} callback Node-style callback called with the error, if any, and CompleteSignupStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeSignupStep = function completeSignupStep(request, callback) {
                    return this.rpcCall(completeSignupStep, $root.visor.clientrpc.CompleteSignupStepRequest, $root.visor.clientrpc.CompleteSignupStepResponse, request, callback);
                }, "name", { value: "CompleteSignupStep" });

                /**
                 * Calls CompleteSignupStep.
                 * @function completeSignupStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSignupStepRequest} request CompleteSignupStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteSignupStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completePermissionStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompletePermissionStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompletePermissionStepResponse} [response] CompletePermissionStepResponse
                 */

                /**
                 * Calls CompletePermissionStep.
                 * @function completePermissionStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePermissionStepRequest} request CompletePermissionStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompletePermissionStepCallback} callback Node-style callback called with the error, if any, and CompletePermissionStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completePermissionStep = function completePermissionStep(request, callback) {
                    return this.rpcCall(completePermissionStep, $root.visor.clientrpc.CompletePermissionStepRequest, $root.visor.clientrpc.CompletePermissionStepResponse, request, callback);
                }, "name", { value: "CompletePermissionStep" });

                /**
                 * Calls CompletePermissionStep.
                 * @function completePermissionStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePermissionStepRequest} request CompletePermissionStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompletePermissionStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeOffboardingStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteOffboardingStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteOffboardingStepResponse} [response] CompleteOffboardingStepResponse
                 */

                /**
                 * Calls CompleteOffboardingStep.
                 * @function completeOffboardingStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteOffboardingStepRequest} request CompleteOffboardingStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteOffboardingStepCallback} callback Node-style callback called with the error, if any, and CompleteOffboardingStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeOffboardingStep = function completeOffboardingStep(request, callback) {
                    return this.rpcCall(completeOffboardingStep, $root.visor.clientrpc.CompleteOffboardingStepRequest, $root.visor.clientrpc.CompleteOffboardingStepResponse, request, callback);
                }, "name", { value: "CompleteOffboardingStep" });

                /**
                 * Calls CompleteOffboardingStep.
                 * @function completeOffboardingStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteOffboardingStepRequest} request CompleteOffboardingStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteOffboardingStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeDocumentRequestStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteDocumentRequestStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteDocumentRequestStepResponse} [response] CompleteDocumentRequestStepResponse
                 */

                /**
                 * Calls CompleteDocumentRequestStep.
                 * @function completeDocumentRequestStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepRequest} request CompleteDocumentRequestStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteDocumentRequestStepCallback} callback Node-style callback called with the error, if any, and CompleteDocumentRequestStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeDocumentRequestStep = function completeDocumentRequestStep(request, callback) {
                    return this.rpcCall(completeDocumentRequestStep, $root.visor.clientrpc.CompleteDocumentRequestStepRequest, $root.visor.clientrpc.CompleteDocumentRequestStepResponse, request, callback);
                }, "name", { value: "CompleteDocumentRequestStep" });

                /**
                 * Calls CompleteDocumentRequestStep.
                 * @function completeDocumentRequestStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepRequest} request CompleteDocumentRequestStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteDocumentRequestStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeGovernmentIDUploadStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteGovernmentIDUploadStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteGovernmentIDUploadStepResponse} [response] CompleteGovernmentIDUploadStepResponse
                 */

                /**
                 * Calls CompleteGovernmentIDUploadStep.
                 * @function completeGovernmentIDUploadStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepRequest} request CompleteGovernmentIDUploadStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteGovernmentIDUploadStepCallback} callback Node-style callback called with the error, if any, and CompleteGovernmentIDUploadStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeGovernmentIDUploadStep = function completeGovernmentIDUploadStep(request, callback) {
                    return this.rpcCall(completeGovernmentIDUploadStep, $root.visor.clientrpc.CompleteGovernmentIDUploadStepRequest, $root.visor.clientrpc.CompleteGovernmentIDUploadStepResponse, request, callback);
                }, "name", { value: "CompleteGovernmentIDUploadStep" });

                /**
                 * Calls CompleteGovernmentIDUploadStep.
                 * @function completeGovernmentIDUploadStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepRequest} request CompleteGovernmentIDUploadStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteGovernmentIDUploadStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeDocumentDeliveryStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteDocumentDeliveryStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteDocumentDeliveryStepResponse} [response] CompleteDocumentDeliveryStepResponse
                 */

                /**
                 * Calls CompleteDocumentDeliveryStep.
                 * @function completeDocumentDeliveryStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepRequest} request CompleteDocumentDeliveryStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteDocumentDeliveryStepCallback} callback Node-style callback called with the error, if any, and CompleteDocumentDeliveryStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeDocumentDeliveryStep = function completeDocumentDeliveryStep(request, callback) {
                    return this.rpcCall(completeDocumentDeliveryStep, $root.visor.clientrpc.CompleteDocumentDeliveryStepRequest, $root.visor.clientrpc.CompleteDocumentDeliveryStepResponse, request, callback);
                }, "name", { value: "CompleteDocumentDeliveryStep" });

                /**
                 * Calls CompleteDocumentDeliveryStep.
                 * @function completeDocumentDeliveryStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepRequest} request CompleteDocumentDeliveryStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteDocumentDeliveryStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeReviewLoyaltyProfileStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteReviewLoyaltyProfileStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse} [response] CompleteReviewLoyaltyProfileStepResponse
                 */

                /**
                 * Calls CompleteReviewLoyaltyProfileStep.
                 * @function completeReviewLoyaltyProfileStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepRequest} request CompleteReviewLoyaltyProfileStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteReviewLoyaltyProfileStepCallback} callback Node-style callback called with the error, if any, and CompleteReviewLoyaltyProfileStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeReviewLoyaltyProfileStep = function completeReviewLoyaltyProfileStep(request, callback) {
                    return this.rpcCall(completeReviewLoyaltyProfileStep, $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest, $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse, request, callback);
                }, "name", { value: "CompleteReviewLoyaltyProfileStep" });

                /**
                 * Calls CompleteReviewLoyaltyProfileStep.
                 * @function completeReviewLoyaltyProfileStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepRequest} request CompleteReviewLoyaltyProfileStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeWaitForBoostStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteWaitForBoostStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteWaitForBoostStepResponse} [response] CompleteWaitForBoostStepResponse
                 */

                /**
                 * Calls CompleteWaitForBoostStep.
                 * @function completeWaitForBoostStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepRequest} request CompleteWaitForBoostStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteWaitForBoostStepCallback} callback Node-style callback called with the error, if any, and CompleteWaitForBoostStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeWaitForBoostStep = function completeWaitForBoostStep(request, callback) {
                    return this.rpcCall(completeWaitForBoostStep, $root.visor.clientrpc.CompleteWaitForBoostStepRequest, $root.visor.clientrpc.CompleteWaitForBoostStepResponse, request, callback);
                }, "name", { value: "CompleteWaitForBoostStep" });

                /**
                 * Calls CompleteWaitForBoostStep.
                 * @function completeWaitForBoostStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepRequest} request CompleteWaitForBoostStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteWaitForBoostStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeCashBoostIntroStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteCashBoostIntroStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteCashBoostIntroStepResponse} [response] CompleteCashBoostIntroStepResponse
                 */

                /**
                 * Calls CompleteCashBoostIntroStep.
                 * @function completeCashBoostIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepRequest} request CompleteCashBoostIntroStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteCashBoostIntroStepCallback} callback Node-style callback called with the error, if any, and CompleteCashBoostIntroStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeCashBoostIntroStep = function completeCashBoostIntroStep(request, callback) {
                    return this.rpcCall(completeCashBoostIntroStep, $root.visor.clientrpc.CompleteCashBoostIntroStepRequest, $root.visor.clientrpc.CompleteCashBoostIntroStepResponse, request, callback);
                }, "name", { value: "CompleteCashBoostIntroStep" });

                /**
                 * Calls CompleteCashBoostIntroStep.
                 * @function completeCashBoostIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepRequest} request CompleteCashBoostIntroStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteCashBoostIntroStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completePreSelectBankAccountStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompletePreSelectBankAccountStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompletePreSelectBankAccountStepResponse} [response] CompletePreSelectBankAccountStepResponse
                 */

                /**
                 * Calls CompletePreSelectBankAccountStep.
                 * @function completePreSelectBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepRequest} request CompletePreSelectBankAccountStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompletePreSelectBankAccountStepCallback} callback Node-style callback called with the error, if any, and CompletePreSelectBankAccountStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completePreSelectBankAccountStep = function completePreSelectBankAccountStep(request, callback) {
                    return this.rpcCall(completePreSelectBankAccountStep, $root.visor.clientrpc.CompletePreSelectBankAccountStepRequest, $root.visor.clientrpc.CompletePreSelectBankAccountStepResponse, request, callback);
                }, "name", { value: "CompletePreSelectBankAccountStep" });

                /**
                 * Calls CompletePreSelectBankAccountStep.
                 * @function completePreSelectBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepRequest} request CompletePreSelectBankAccountStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompletePreSelectBankAccountStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeConnectBankAccountStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteConnectBankAccountStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteConnectBankAccountStepResponse} [response] CompleteConnectBankAccountStepResponse
                 */

                /**
                 * Calls CompleteConnectBankAccountStep.
                 * @function completeConnectBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepRequest} request CompleteConnectBankAccountStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteConnectBankAccountStepCallback} callback Node-style callback called with the error, if any, and CompleteConnectBankAccountStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeConnectBankAccountStep = function completeConnectBankAccountStep(request, callback) {
                    return this.rpcCall(completeConnectBankAccountStep, $root.visor.clientrpc.CompleteConnectBankAccountStepRequest, $root.visor.clientrpc.CompleteConnectBankAccountStepResponse, request, callback);
                }, "name", { value: "CompleteConnectBankAccountStep" });

                /**
                 * Calls CompleteConnectBankAccountStep.
                 * @function completeConnectBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepRequest} request CompleteConnectBankAccountStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteConnectBankAccountStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeConnectDebitCardStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteConnectDebitCardStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteConnectDebitCardStepResponse} [response] CompleteConnectDebitCardStepResponse
                 */

                /**
                 * Calls CompleteConnectDebitCardStep.
                 * @function completeConnectDebitCardStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepRequest} request CompleteConnectDebitCardStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteConnectDebitCardStepCallback} callback Node-style callback called with the error, if any, and CompleteConnectDebitCardStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeConnectDebitCardStep = function completeConnectDebitCardStep(request, callback) {
                    return this.rpcCall(completeConnectDebitCardStep, $root.visor.clientrpc.CompleteConnectDebitCardStepRequest, $root.visor.clientrpc.CompleteConnectDebitCardStepResponse, request, callback);
                }, "name", { value: "CompleteConnectDebitCardStep" });

                /**
                 * Calls CompleteConnectDebitCardStep.
                 * @function completeConnectDebitCardStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepRequest} request CompleteConnectDebitCardStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteConnectDebitCardStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeSetupStripeStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteSetupStripeStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteSetupStripeStepResponse} [response] CompleteSetupStripeStepResponse
                 */

                /**
                 * Calls CompleteSetupStripeStep.
                 * @function completeSetupStripeStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSetupStripeStepRequest} request CompleteSetupStripeStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteSetupStripeStepCallback} callback Node-style callback called with the error, if any, and CompleteSetupStripeStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeSetupStripeStep = function completeSetupStripeStep(request, callback) {
                    return this.rpcCall(completeSetupStripeStep, $root.visor.clientrpc.CompleteSetupStripeStepRequest, $root.visor.clientrpc.CompleteSetupStripeStepResponse, request, callback);
                }, "name", { value: "CompleteSetupStripeStep" });

                /**
                 * Calls CompleteSetupStripeStep.
                 * @function completeSetupStripeStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSetupStripeStepRequest} request CompleteSetupStripeStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteSetupStripeStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeCashBoostAcceptStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteCashBoostAcceptStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteCashBoostAcceptStepResponse} [response] CompleteCashBoostAcceptStepResponse
                 */

                /**
                 * Calls CompleteCashBoostAcceptStep.
                 * @function completeCashBoostAcceptStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepRequest} request CompleteCashBoostAcceptStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteCashBoostAcceptStepCallback} callback Node-style callback called with the error, if any, and CompleteCashBoostAcceptStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeCashBoostAcceptStep = function completeCashBoostAcceptStep(request, callback) {
                    return this.rpcCall(completeCashBoostAcceptStep, $root.visor.clientrpc.CompleteCashBoostAcceptStepRequest, $root.visor.clientrpc.CompleteCashBoostAcceptStepResponse, request, callback);
                }, "name", { value: "CompleteCashBoostAcceptStep" });

                /**
                 * Calls CompleteCashBoostAcceptStep.
                 * @function completeCashBoostAcceptStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepRequest} request CompleteCashBoostAcceptStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteCashBoostAcceptStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeSubsequentCashBoostAcceptStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteSubsequentCashBoostAcceptStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse} [response] CompleteSubsequentCashBoostAcceptStepResponse
                 */

                /**
                 * Calls CompleteSubsequentCashBoostAcceptStep.
                 * @function completeSubsequentCashBoostAcceptStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepRequest} request CompleteSubsequentCashBoostAcceptStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteSubsequentCashBoostAcceptStepCallback} callback Node-style callback called with the error, if any, and CompleteSubsequentCashBoostAcceptStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeSubsequentCashBoostAcceptStep = function completeSubsequentCashBoostAcceptStep(request, callback) {
                    return this.rpcCall(completeSubsequentCashBoostAcceptStep, $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest, $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse, request, callback);
                }, "name", { value: "CompleteSubsequentCashBoostAcceptStep" });

                /**
                 * Calls CompleteSubsequentCashBoostAcceptStep.
                 * @function completeSubsequentCashBoostAcceptStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepRequest} request CompleteSubsequentCashBoostAcceptStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeAddressStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteAddressStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteAddressStepResponse} [response] CompleteAddressStepResponse
                 */

                /**
                 * Calls CompleteAddressStep.
                 * @function completeAddressStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteAddressStepRequest} request CompleteAddressStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteAddressStepCallback} callback Node-style callback called with the error, if any, and CompleteAddressStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeAddressStep = function completeAddressStep(request, callback) {
                    return this.rpcCall(completeAddressStep, $root.visor.clientrpc.CompleteAddressStepRequest, $root.visor.clientrpc.CompleteAddressStepResponse, request, callback);
                }, "name", { value: "CompleteAddressStep" });

                /**
                 * Calls CompleteAddressStep.
                 * @function completeAddressStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteAddressStepRequest} request CompleteAddressStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteAddressStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeTermsOfServiceStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteTermsOfServiceStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteTermsOfServiceStepResponse} [response] CompleteTermsOfServiceStepResponse
                 */

                /**
                 * Calls CompleteTermsOfServiceStep.
                 * @function completeTermsOfServiceStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepRequest} request CompleteTermsOfServiceStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteTermsOfServiceStepCallback} callback Node-style callback called with the error, if any, and CompleteTermsOfServiceStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeTermsOfServiceStep = function completeTermsOfServiceStep(request, callback) {
                    return this.rpcCall(completeTermsOfServiceStep, $root.visor.clientrpc.CompleteTermsOfServiceStepRequest, $root.visor.clientrpc.CompleteTermsOfServiceStepResponse, request, callback);
                }, "name", { value: "CompleteTermsOfServiceStep" });

                /**
                 * Calls CompleteTermsOfServiceStep.
                 * @function completeTermsOfServiceStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepRequest} request CompleteTermsOfServiceStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteTermsOfServiceStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeMultiDigitStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteMultiDigitStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteMultiDigitStepResponse} [response] CompleteMultiDigitStepResponse
                 */

                /**
                 * Calls CompleteMultiDigitStep.
                 * @function completeMultiDigitStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteMultiDigitStepRequest} request CompleteMultiDigitStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteMultiDigitStepCallback} callback Node-style callback called with the error, if any, and CompleteMultiDigitStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeMultiDigitStep = function completeMultiDigitStep(request, callback) {
                    return this.rpcCall(completeMultiDigitStep, $root.visor.clientrpc.CompleteMultiDigitStepRequest, $root.visor.clientrpc.CompleteMultiDigitStepResponse, request, callback);
                }, "name", { value: "CompleteMultiDigitStep" });

                /**
                 * Calls CompleteMultiDigitStep.
                 * @function completeMultiDigitStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteMultiDigitStepRequest} request CompleteMultiDigitStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteMultiDigitStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeLegalNameAndDateOfBirthStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteLegalNameAndDateOfBirthStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse} [response] CompleteLegalNameAndDateOfBirthStepResponse
                 */

                /**
                 * Calls CompleteLegalNameAndDateOfBirthStep.
                 * @function completeLegalNameAndDateOfBirthStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepRequest} request CompleteLegalNameAndDateOfBirthStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteLegalNameAndDateOfBirthStepCallback} callback Node-style callback called with the error, if any, and CompleteLegalNameAndDateOfBirthStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeLegalNameAndDateOfBirthStep = function completeLegalNameAndDateOfBirthStep(request, callback) {
                    return this.rpcCall(completeLegalNameAndDateOfBirthStep, $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest, $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse, request, callback);
                }, "name", { value: "CompleteLegalNameAndDateOfBirthStep" });

                /**
                 * Calls CompleteLegalNameAndDateOfBirthStep.
                 * @function completeLegalNameAndDateOfBirthStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepRequest} request CompleteLegalNameAndDateOfBirthStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeCashBoostQualificationStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteCashBoostQualificationStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteCashBoostQualificationStepResponse} [response] CompleteCashBoostQualificationStepResponse
                 */

                /**
                 * Calls CompleteCashBoostQualificationStep.
                 * @function completeCashBoostQualificationStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepRequest} request CompleteCashBoostQualificationStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteCashBoostQualificationStepCallback} callback Node-style callback called with the error, if any, and CompleteCashBoostQualificationStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeCashBoostQualificationStep = function completeCashBoostQualificationStep(request, callback) {
                    return this.rpcCall(completeCashBoostQualificationStep, $root.visor.clientrpc.CompleteCashBoostQualificationStepRequest, $root.visor.clientrpc.CompleteCashBoostQualificationStepResponse, request, callback);
                }, "name", { value: "CompleteCashBoostQualificationStep" });

                /**
                 * Calls CompleteCashBoostQualificationStep.
                 * @function completeCashBoostQualificationStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepRequest} request CompleteCashBoostQualificationStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteCashBoostQualificationStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeEmail2FAStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteEmail2FAStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteEmail2FAStepResponse} [response] CompleteEmail2FAStepResponse
                 */

                /**
                 * Calls CompleteEmail2FAStep.
                 * @function completeEmail2FAStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteEmail2FAStepRequest} request CompleteEmail2FAStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteEmail2FAStepCallback} callback Node-style callback called with the error, if any, and CompleteEmail2FAStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeEmail2FAStep = function completeEmail2FAStep(request, callback) {
                    return this.rpcCall(completeEmail2FAStep, $root.visor.clientrpc.CompleteEmail2FAStepRequest, $root.visor.clientrpc.CompleteEmail2FAStepResponse, request, callback);
                }, "name", { value: "CompleteEmail2FAStep" });

                /**
                 * Calls CompleteEmail2FAStep.
                 * @function completeEmail2FAStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteEmail2FAStepRequest} request CompleteEmail2FAStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteEmail2FAStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completePhone2FAStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompletePhone2FAStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompletePhone2FAStepResponse} [response] CompletePhone2FAStepResponse
                 */

                /**
                 * Calls CompletePhone2FAStep.
                 * @function completePhone2FAStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePhone2FAStepRequest} request CompletePhone2FAStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompletePhone2FAStepCallback} callback Node-style callback called with the error, if any, and CompletePhone2FAStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completePhone2FAStep = function completePhone2FAStep(request, callback) {
                    return this.rpcCall(completePhone2FAStep, $root.visor.clientrpc.CompletePhone2FAStepRequest, $root.visor.clientrpc.CompletePhone2FAStepResponse, request, callback);
                }, "name", { value: "CompletePhone2FAStep" });

                /**
                 * Calls CompletePhone2FAStep.
                 * @function completePhone2FAStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePhone2FAStepRequest} request CompletePhone2FAStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompletePhone2FAStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeVerifyBankAccountStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteVerifyBankAccountStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteVerifyBankAccountStepResponse} [response] CompleteVerifyBankAccountStepResponse
                 */

                /**
                 * Calls CompleteVerifyBankAccountStep.
                 * @function completeVerifyBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepRequest} request CompleteVerifyBankAccountStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteVerifyBankAccountStepCallback} callback Node-style callback called with the error, if any, and CompleteVerifyBankAccountStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeVerifyBankAccountStep = function completeVerifyBankAccountStep(request, callback) {
                    return this.rpcCall(completeVerifyBankAccountStep, $root.visor.clientrpc.CompleteVerifyBankAccountStepRequest, $root.visor.clientrpc.CompleteVerifyBankAccountStepResponse, request, callback);
                }, "name", { value: "CompleteVerifyBankAccountStep" });

                /**
                 * Calls CompleteVerifyBankAccountStep.
                 * @function completeVerifyBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepRequest} request CompleteVerifyBankAccountStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteVerifyBankAccountStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeUnemploymentIntroStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteUnemploymentIntroStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteUnemploymentIntroStepResponse} [response] CompleteUnemploymentIntroStepResponse
                 */

                /**
                 * Calls CompleteUnemploymentIntroStep.
                 * @function completeUnemploymentIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepRequest} request CompleteUnemploymentIntroStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteUnemploymentIntroStepCallback} callback Node-style callback called with the error, if any, and CompleteUnemploymentIntroStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeUnemploymentIntroStep = function completeUnemploymentIntroStep(request, callback) {
                    return this.rpcCall(completeUnemploymentIntroStep, $root.visor.clientrpc.CompleteUnemploymentIntroStepRequest, $root.visor.clientrpc.CompleteUnemploymentIntroStepResponse, request, callback);
                }, "name", { value: "CompleteUnemploymentIntroStep" });

                /**
                 * Calls CompleteUnemploymentIntroStep.
                 * @function completeUnemploymentIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepRequest} request CompleteUnemploymentIntroStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteUnemploymentIntroStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeUnemploymentHowItWorksStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteUnemploymentHowItWorksStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse} [response] CompleteUnemploymentHowItWorksStepResponse
                 */

                /**
                 * Calls CompleteUnemploymentHowItWorksStep.
                 * @function completeUnemploymentHowItWorksStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepRequest} request CompleteUnemploymentHowItWorksStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteUnemploymentHowItWorksStepCallback} callback Node-style callback called with the error, if any, and CompleteUnemploymentHowItWorksStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeUnemploymentHowItWorksStep = function completeUnemploymentHowItWorksStep(request, callback) {
                    return this.rpcCall(completeUnemploymentHowItWorksStep, $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest, $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse, request, callback);
                }, "name", { value: "CompleteUnemploymentHowItWorksStep" });

                /**
                 * Calls CompleteUnemploymentHowItWorksStep.
                 * @function completeUnemploymentHowItWorksStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepRequest} request CompleteUnemploymentHowItWorksStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createPayrollConnection}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreatePayrollConnectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreatePayrollConnectionResponse} [response] CreatePayrollConnectionResponse
                 */

                /**
                 * Calls CreatePayrollConnection.
                 * @function createPayrollConnection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreatePayrollConnectionRequest} request CreatePayrollConnectionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreatePayrollConnectionCallback} callback Node-style callback called with the error, if any, and CreatePayrollConnectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createPayrollConnection = function createPayrollConnection(request, callback) {
                    return this.rpcCall(createPayrollConnection, $root.visor.clientrpc.CreatePayrollConnectionRequest, $root.visor.clientrpc.CreatePayrollConnectionResponse, request, callback);
                }, "name", { value: "CreatePayrollConnection" });

                /**
                 * Calls CreatePayrollConnection.
                 * @function createPayrollConnection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreatePayrollConnectionRequest} request CreatePayrollConnectionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreatePayrollConnectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updatePayrollConnection}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdatePayrollConnectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdatePayrollConnectionResponse} [response] UpdatePayrollConnectionResponse
                 */

                /**
                 * Calls UpdatePayrollConnection.
                 * @function updatePayrollConnection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdatePayrollConnectionRequest} request UpdatePayrollConnectionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdatePayrollConnectionCallback} callback Node-style callback called with the error, if any, and UpdatePayrollConnectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updatePayrollConnection = function updatePayrollConnection(request, callback) {
                    return this.rpcCall(updatePayrollConnection, $root.visor.clientrpc.UpdatePayrollConnectionRequest, $root.visor.clientrpc.UpdatePayrollConnectionResponse, request, callback);
                }, "name", { value: "UpdatePayrollConnection" });

                /**
                 * Calls UpdatePayrollConnection.
                 * @function updatePayrollConnection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdatePayrollConnectionRequest} request UpdatePayrollConnectionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdatePayrollConnectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#removePayrollConnection}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef RemovePayrollConnectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.RemovePayrollConnectionResponse} [response] RemovePayrollConnectionResponse
                 */

                /**
                 * Calls RemovePayrollConnection.
                 * @function removePayrollConnection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IRemovePayrollConnectionRequest} request RemovePayrollConnectionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.RemovePayrollConnectionCallback} callback Node-style callback called with the error, if any, and RemovePayrollConnectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.removePayrollConnection = function removePayrollConnection(request, callback) {
                    return this.rpcCall(removePayrollConnection, $root.visor.clientrpc.RemovePayrollConnectionRequest, $root.visor.clientrpc.RemovePayrollConnectionResponse, request, callback);
                }, "name", { value: "RemovePayrollConnection" });

                /**
                 * Calls RemovePayrollConnection.
                 * @function removePayrollConnection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IRemovePayrollConnectionRequest} request RemovePayrollConnectionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.RemovePayrollConnectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#submitMFAAnswer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SubmitMFAAnswerCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SubmitMFAAnswerResponse} [response] SubmitMFAAnswerResponse
                 */

                /**
                 * Calls SubmitMFAAnswer.
                 * @function submitMFAAnswer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISubmitMFAAnswerRequest} request SubmitMFAAnswerRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SubmitMFAAnswerCallback} callback Node-style callback called with the error, if any, and SubmitMFAAnswerResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.submitMFAAnswer = function submitMFAAnswer(request, callback) {
                    return this.rpcCall(submitMFAAnswer, $root.visor.clientrpc.SubmitMFAAnswerRequest, $root.visor.clientrpc.SubmitMFAAnswerResponse, request, callback);
                }, "name", { value: "SubmitMFAAnswer" });

                /**
                 * Calls SubmitMFAAnswer.
                 * @function submitMFAAnswer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISubmitMFAAnswerRequest} request SubmitMFAAnswerRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SubmitMFAAnswerResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getClientUploadURL}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetClientUploadURLCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetClientUploadURLResponse} [response] GetClientUploadURLResponse
                 */

                /**
                 * Calls GetClientUploadURL.
                 * @function getClientUploadURL
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetClientUploadURLRequest} request GetClientUploadURLRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetClientUploadURLCallback} callback Node-style callback called with the error, if any, and GetClientUploadURLResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getClientUploadURL = function getClientUploadURL(request, callback) {
                    return this.rpcCall(getClientUploadURL, $root.visor.clientrpc.GetClientUploadURLRequest, $root.visor.clientrpc.GetClientUploadURLResponse, request, callback);
                }, "name", { value: "GetClientUploadURL" });

                /**
                 * Calls GetClientUploadURL.
                 * @function getClientUploadURL
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetClientUploadURLRequest} request GetClientUploadURLRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetClientUploadURLResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#fulfillDocumentRequest}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef FulfillDocumentRequestCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.FulfillDocumentRequestResponse} [response] FulfillDocumentRequestResponse
                 */

                /**
                 * Calls FulfillDocumentRequest.
                 * @function fulfillDocumentRequest
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFulfillDocumentRequestRequest} request FulfillDocumentRequestRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.FulfillDocumentRequestCallback} callback Node-style callback called with the error, if any, and FulfillDocumentRequestResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.fulfillDocumentRequest = function fulfillDocumentRequest(request, callback) {
                    return this.rpcCall(fulfillDocumentRequest, $root.visor.clientrpc.FulfillDocumentRequestRequest, $root.visor.clientrpc.FulfillDocumentRequestResponse, request, callback);
                }, "name", { value: "FulfillDocumentRequest" });

                /**
                 * Calls FulfillDocumentRequest.
                 * @function fulfillDocumentRequest
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFulfillDocumentRequestRequest} request FulfillDocumentRequestRequest message or plain object
                 * @returns {Promise<visor.clientrpc.FulfillDocumentRequestResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getDownloadURL}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetDownloadURLCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetDownloadURLResponse} [response] GetDownloadURLResponse
                 */

                /**
                 * Calls GetDownloadURL.
                 * @function getDownloadURL
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetDownloadURLRequest} request GetDownloadURLRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetDownloadURLCallback} callback Node-style callback called with the error, if any, and GetDownloadURLResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getDownloadURL = function getDownloadURL(request, callback) {
                    return this.rpcCall(getDownloadURL, $root.visor.clientrpc.GetDownloadURLRequest, $root.visor.clientrpc.GetDownloadURLResponse, request, callback);
                }, "name", { value: "GetDownloadURL" });

                /**
                 * Calls GetDownloadURL.
                 * @function getDownloadURL
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetDownloadURLRequest} request GetDownloadURLRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetDownloadURLResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#startScenarioSelection}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef StartScenarioSelectionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.StartScenarioSelectionResponse} [response] StartScenarioSelectionResponse
                 */

                /**
                 * Calls StartScenarioSelection.
                 * @function startScenarioSelection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStartScenarioSelectionRequest} request StartScenarioSelectionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.StartScenarioSelectionCallback} callback Node-style callback called with the error, if any, and StartScenarioSelectionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.startScenarioSelection = function startScenarioSelection(request, callback) {
                    return this.rpcCall(startScenarioSelection, $root.visor.clientrpc.StartScenarioSelectionRequest, $root.visor.clientrpc.StartScenarioSelectionResponse, request, callback);
                }, "name", { value: "StartScenarioSelection" });

                /**
                 * Calls StartScenarioSelection.
                 * @function startScenarioSelection
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStartScenarioSelectionRequest} request StartScenarioSelectionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.StartScenarioSelectionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#selectScenarioOption}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SelectScenarioOptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SelectScenarioOptionResponse} [response] SelectScenarioOptionResponse
                 */

                /**
                 * Calls SelectScenarioOption.
                 * @function selectScenarioOption
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISelectScenarioOptionRequest} request SelectScenarioOptionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SelectScenarioOptionCallback} callback Node-style callback called with the error, if any, and SelectScenarioOptionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.selectScenarioOption = function selectScenarioOption(request, callback) {
                    return this.rpcCall(selectScenarioOption, $root.visor.clientrpc.SelectScenarioOptionRequest, $root.visor.clientrpc.SelectScenarioOptionResponse, request, callback);
                }, "name", { value: "SelectScenarioOption" });

                /**
                 * Calls SelectScenarioOption.
                 * @function selectScenarioOption
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISelectScenarioOptionRequest} request SelectScenarioOptionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SelectScenarioOptionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#signProvisionalW4}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SignProvisionalW4Callback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SignProvisionalW4Response} [response] SignProvisionalW4Response
                 */

                /**
                 * Calls SignProvisionalW4.
                 * @function signProvisionalW4
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISignProvisionalW4Request} request SignProvisionalW4Request message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SignProvisionalW4Callback} callback Node-style callback called with the error, if any, and SignProvisionalW4Response
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.signProvisionalW4 = function signProvisionalW4(request, callback) {
                    return this.rpcCall(signProvisionalW4, $root.visor.clientrpc.SignProvisionalW4Request, $root.visor.clientrpc.SignProvisionalW4Response, request, callback);
                }, "name", { value: "SignProvisionalW4" });

                /**
                 * Calls SignProvisionalW4.
                 * @function signProvisionalW4
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISignProvisionalW4Request} request SignProvisionalW4Request message or plain object
                 * @returns {Promise<visor.clientrpc.SignProvisionalW4Response>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#confirmFinalW4Updates}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ConfirmFinalW4UpdatesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ConfirmFinalW4UpdatesResponse} [response] ConfirmFinalW4UpdatesResponse
                 */

                /**
                 * Calls ConfirmFinalW4Updates.
                 * @function confirmFinalW4Updates
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesRequest} request ConfirmFinalW4UpdatesRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ConfirmFinalW4UpdatesCallback} callback Node-style callback called with the error, if any, and ConfirmFinalW4UpdatesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.confirmFinalW4Updates = function confirmFinalW4Updates(request, callback) {
                    return this.rpcCall(confirmFinalW4Updates, $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest, $root.visor.clientrpc.ConfirmFinalW4UpdatesResponse, request, callback);
                }, "name", { value: "ConfirmFinalW4Updates" });

                /**
                 * Calls ConfirmFinalW4Updates.
                 * @function confirmFinalW4Updates
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesRequest} request ConfirmFinalW4UpdatesRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ConfirmFinalW4UpdatesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#provideHREmailAddressForW4Review}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ProvideHREmailAddressForW4ReviewCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse} [response] ProvideHREmailAddressForW4ReviewResponse
                 */

                /**
                 * Calls ProvideHREmailAddressForW4Review.
                 * @function provideHREmailAddressForW4Review
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewRequest} request ProvideHREmailAddressForW4ReviewRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ProvideHREmailAddressForW4ReviewCallback} callback Node-style callback called with the error, if any, and ProvideHREmailAddressForW4ReviewResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.provideHREmailAddressForW4Review = function provideHREmailAddressForW4Review(request, callback) {
                    return this.rpcCall(provideHREmailAddressForW4Review, $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest, $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse, request, callback);
                }, "name", { value: "ProvideHREmailAddressForW4Review" });

                /**
                 * Calls ProvideHREmailAddressForW4Review.
                 * @function provideHREmailAddressForW4Review
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewRequest} request ProvideHREmailAddressForW4ReviewRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createBoostCardCheckoutPayment}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateBoostCardCheckoutPaymentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateBoostCardCheckoutPaymentResponse} [response] CreateBoostCardCheckoutPaymentResponse
                 */

                /**
                 * Calls CreateBoostCardCheckoutPayment.
                 * @function createBoostCardCheckoutPayment
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentRequest} request CreateBoostCardCheckoutPaymentRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateBoostCardCheckoutPaymentCallback} callback Node-style callback called with the error, if any, and CreateBoostCardCheckoutPaymentResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createBoostCardCheckoutPayment = function createBoostCardCheckoutPayment(request, callback) {
                    return this.rpcCall(createBoostCardCheckoutPayment, $root.visor.clientrpc.CreateBoostCardCheckoutPaymentRequest, $root.visor.clientrpc.CreateBoostCardCheckoutPaymentResponse, request, callback);
                }, "name", { value: "CreateBoostCardCheckoutPayment" });

                /**
                 * Calls CreateBoostCardCheckoutPayment.
                 * @function createBoostCardCheckoutPayment
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentRequest} request CreateBoostCardCheckoutPaymentRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateBoostCardCheckoutPaymentResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateCheckoutSession}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateCheckoutSessionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateCheckoutSessionResponse} [response] UpdateCheckoutSessionResponse
                 */

                /**
                 * Calls UpdateCheckoutSession.
                 * @function updateCheckoutSession
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateCheckoutSessionRequest} request UpdateCheckoutSessionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateCheckoutSessionCallback} callback Node-style callback called with the error, if any, and UpdateCheckoutSessionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateCheckoutSession = function updateCheckoutSession(request, callback) {
                    return this.rpcCall(updateCheckoutSession, $root.visor.clientrpc.UpdateCheckoutSessionRequest, $root.visor.clientrpc.UpdateCheckoutSessionResponse, request, callback);
                }, "name", { value: "UpdateCheckoutSession" });

                /**
                 * Calls UpdateCheckoutSession.
                 * @function updateCheckoutSession
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateCheckoutSessionRequest} request UpdateCheckoutSessionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateCheckoutSessionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#finishCheckoutSession}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef FinishCheckoutSessionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.FinishCheckoutSessionResponse} [response] FinishCheckoutSessionResponse
                 */

                /**
                 * Calls FinishCheckoutSession.
                 * @function finishCheckoutSession
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinishCheckoutSessionRequest} request FinishCheckoutSessionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.FinishCheckoutSessionCallback} callback Node-style callback called with the error, if any, and FinishCheckoutSessionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.finishCheckoutSession = function finishCheckoutSession(request, callback) {
                    return this.rpcCall(finishCheckoutSession, $root.visor.clientrpc.FinishCheckoutSessionRequest, $root.visor.clientrpc.FinishCheckoutSessionResponse, request, callback);
                }, "name", { value: "FinishCheckoutSession" });

                /**
                 * Calls FinishCheckoutSession.
                 * @function finishCheckoutSession
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinishCheckoutSessionRequest} request FinishCheckoutSessionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.FinishCheckoutSessionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#listPaymentMethods}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ListPaymentMethodsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ListPaymentMethodsResponse} [response] ListPaymentMethodsResponse
                 */

                /**
                 * Calls ListPaymentMethods.
                 * @function listPaymentMethods
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IListPaymentMethodsRequest} request ListPaymentMethodsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ListPaymentMethodsCallback} callback Node-style callback called with the error, if any, and ListPaymentMethodsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.listPaymentMethods = function listPaymentMethods(request, callback) {
                    return this.rpcCall(listPaymentMethods, $root.visor.clientrpc.ListPaymentMethodsRequest, $root.visor.clientrpc.ListPaymentMethodsResponse, request, callback);
                }, "name", { value: "ListPaymentMethods" });

                /**
                 * Calls ListPaymentMethods.
                 * @function listPaymentMethods
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IListPaymentMethodsRequest} request ListPaymentMethodsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ListPaymentMethodsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#savePaymentMethod}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SavePaymentMethodCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SavePaymentMethodResponse} [response] SavePaymentMethodResponse
                 */

                /**
                 * Calls SavePaymentMethod.
                 * @function savePaymentMethod
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISavePaymentMethodRequest} request SavePaymentMethodRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SavePaymentMethodCallback} callback Node-style callback called with the error, if any, and SavePaymentMethodResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.savePaymentMethod = function savePaymentMethod(request, callback) {
                    return this.rpcCall(savePaymentMethod, $root.visor.clientrpc.SavePaymentMethodRequest, $root.visor.clientrpc.SavePaymentMethodResponse, request, callback);
                }, "name", { value: "SavePaymentMethod" });

                /**
                 * Calls SavePaymentMethod.
                 * @function savePaymentMethod
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISavePaymentMethodRequest} request SavePaymentMethodRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SavePaymentMethodResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#setDefaultPaymentMethod}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SetDefaultPaymentMethodCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SetDefaultPaymentMethodResponse} [response] SetDefaultPaymentMethodResponse
                 */

                /**
                 * Calls SetDefaultPaymentMethod.
                 * @function setDefaultPaymentMethod
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodRequest} request SetDefaultPaymentMethodRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SetDefaultPaymentMethodCallback} callback Node-style callback called with the error, if any, and SetDefaultPaymentMethodResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.setDefaultPaymentMethod = function setDefaultPaymentMethod(request, callback) {
                    return this.rpcCall(setDefaultPaymentMethod, $root.visor.clientrpc.SetDefaultPaymentMethodRequest, $root.visor.clientrpc.SetDefaultPaymentMethodResponse, request, callback);
                }, "name", { value: "SetDefaultPaymentMethod" });

                /**
                 * Calls SetDefaultPaymentMethod.
                 * @function setDefaultPaymentMethod
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodRequest} request SetDefaultPaymentMethodRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SetDefaultPaymentMethodResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#deletePaymentMethod}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef DeletePaymentMethodCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.DeletePaymentMethodResponse} [response] DeletePaymentMethodResponse
                 */

                /**
                 * Calls DeletePaymentMethod.
                 * @function deletePaymentMethod
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IDeletePaymentMethodRequest} request DeletePaymentMethodRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.DeletePaymentMethodCallback} callback Node-style callback called with the error, if any, and DeletePaymentMethodResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.deletePaymentMethod = function deletePaymentMethod(request, callback) {
                    return this.rpcCall(deletePaymentMethod, $root.visor.clientrpc.DeletePaymentMethodRequest, $root.visor.clientrpc.DeletePaymentMethodResponse, request, callback);
                }, "name", { value: "DeletePaymentMethod" });

                /**
                 * Calls DeletePaymentMethod.
                 * @function deletePaymentMethod
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IDeletePaymentMethodRequest} request DeletePaymentMethodRequest message or plain object
                 * @returns {Promise<visor.clientrpc.DeletePaymentMethodResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getPaymentHistory}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetPaymentHistoryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetPaymentHistoryResponse} [response] GetPaymentHistoryResponse
                 */

                /**
                 * Calls GetPaymentHistory.
                 * @function getPaymentHistory
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetPaymentHistoryRequest} request GetPaymentHistoryRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetPaymentHistoryCallback} callback Node-style callback called with the error, if any, and GetPaymentHistoryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getPaymentHistory = function getPaymentHistory(request, callback) {
                    return this.rpcCall(getPaymentHistory, $root.visor.clientrpc.GetPaymentHistoryRequest, $root.visor.clientrpc.GetPaymentHistoryResponse, request, callback);
                }, "name", { value: "GetPaymentHistory" });

                /**
                 * Calls GetPaymentHistory.
                 * @function getPaymentHistory
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetPaymentHistoryRequest} request GetPaymentHistoryRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetPaymentHistoryResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateProfileName}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateProfileNameCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateProfileNameResponse} [response] UpdateProfileNameResponse
                 */

                /**
                 * Calls UpdateProfileName.
                 * @function updateProfileName
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfileNameRequest} request UpdateProfileNameRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateProfileNameCallback} callback Node-style callback called with the error, if any, and UpdateProfileNameResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateProfileName = function updateProfileName(request, callback) {
                    return this.rpcCall(updateProfileName, $root.visor.clientrpc.UpdateProfileNameRequest, $root.visor.clientrpc.UpdateProfileNameResponse, request, callback);
                }, "name", { value: "UpdateProfileName" });

                /**
                 * Calls UpdateProfileName.
                 * @function updateProfileName
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfileNameRequest} request UpdateProfileNameRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateProfileNameResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateProfileEmail}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateProfileEmailCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateProfileEmailResponse} [response] UpdateProfileEmailResponse
                 */

                /**
                 * Calls UpdateProfileEmail.
                 * @function updateProfileEmail
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfileEmailRequest} request UpdateProfileEmailRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateProfileEmailCallback} callback Node-style callback called with the error, if any, and UpdateProfileEmailResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateProfileEmail = function updateProfileEmail(request, callback) {
                    return this.rpcCall(updateProfileEmail, $root.visor.clientrpc.UpdateProfileEmailRequest, $root.visor.clientrpc.UpdateProfileEmailResponse, request, callback);
                }, "name", { value: "UpdateProfileEmail" });

                /**
                 * Calls UpdateProfileEmail.
                 * @function updateProfileEmail
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfileEmailRequest} request UpdateProfileEmailRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateProfileEmailResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateProfilePassword}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateProfilePasswordCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateProfilePasswordResponse} [response] UpdateProfilePasswordResponse
                 */

                /**
                 * Calls UpdateProfilePassword.
                 * @function updateProfilePassword
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfilePasswordRequest} request UpdateProfilePasswordRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateProfilePasswordCallback} callback Node-style callback called with the error, if any, and UpdateProfilePasswordResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateProfilePassword = function updateProfilePassword(request, callback) {
                    return this.rpcCall(updateProfilePassword, $root.visor.clientrpc.UpdateProfilePasswordRequest, $root.visor.clientrpc.UpdateProfilePasswordResponse, request, callback);
                }, "name", { value: "UpdateProfilePassword" });

                /**
                 * Calls UpdateProfilePassword.
                 * @function updateProfilePassword
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfilePasswordRequest} request UpdateProfilePasswordRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateProfilePasswordResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateProfilePhoneNumber}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateProfilePhoneNumberCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateProfilePhoneNumberResponse} [response] UpdateProfilePhoneNumberResponse
                 */

                /**
                 * Calls UpdateProfilePhoneNumber.
                 * @function updateProfilePhoneNumber
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberRequest} request UpdateProfilePhoneNumberRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateProfilePhoneNumberCallback} callback Node-style callback called with the error, if any, and UpdateProfilePhoneNumberResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateProfilePhoneNumber = function updateProfilePhoneNumber(request, callback) {
                    return this.rpcCall(updateProfilePhoneNumber, $root.visor.clientrpc.UpdateProfilePhoneNumberRequest, $root.visor.clientrpc.UpdateProfilePhoneNumberResponse, request, callback);
                }, "name", { value: "UpdateProfilePhoneNumber" });

                /**
                 * Calls UpdateProfilePhoneNumber.
                 * @function updateProfilePhoneNumber
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberRequest} request UpdateProfilePhoneNumberRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateProfilePhoneNumberResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateProfileTimezone}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateProfileTimezoneCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateProfileTimezoneResponse} [response] UpdateProfileTimezoneResponse
                 */

                /**
                 * Calls UpdateProfileTimezone.
                 * @function updateProfileTimezone
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfileTimezoneRequest} request UpdateProfileTimezoneRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateProfileTimezoneCallback} callback Node-style callback called with the error, if any, and UpdateProfileTimezoneResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateProfileTimezone = function updateProfileTimezone(request, callback) {
                    return this.rpcCall(updateProfileTimezone, $root.visor.clientrpc.UpdateProfileTimezoneRequest, $root.visor.clientrpc.UpdateProfileTimezoneResponse, request, callback);
                }, "name", { value: "UpdateProfileTimezone" });

                /**
                 * Calls UpdateProfileTimezone.
                 * @function updateProfileTimezone
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateProfileTimezoneRequest} request UpdateProfileTimezoneRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateProfileTimezoneResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#sendMessage}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SendMessageCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SendMessageResponse} [response] SendMessageResponse
                 */

                /**
                 * Calls SendMessage.
                 * @function sendMessage
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISendMessageRequest} request SendMessageRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SendMessageCallback} callback Node-style callback called with the error, if any, and SendMessageResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.sendMessage = function sendMessage(request, callback) {
                    return this.rpcCall(sendMessage, $root.visor.clientrpc.SendMessageRequest, $root.visor.clientrpc.SendMessageResponse, request, callback);
                }, "name", { value: "SendMessage" });

                /**
                 * Calls SendMessage.
                 * @function sendMessage
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISendMessageRequest} request SendMessageRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SendMessageResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#readMessages}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ReadMessagesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ReadMessagesResponse} [response] ReadMessagesResponse
                 */

                /**
                 * Calls ReadMessages.
                 * @function readMessages
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReadMessagesRequest} request ReadMessagesRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ReadMessagesCallback} callback Node-style callback called with the error, if any, and ReadMessagesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.readMessages = function readMessages(request, callback) {
                    return this.rpcCall(readMessages, $root.visor.clientrpc.ReadMessagesRequest, $root.visor.clientrpc.ReadMessagesResponse, request, callback);
                }, "name", { value: "ReadMessages" });

                /**
                 * Calls ReadMessages.
                 * @function readMessages
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReadMessagesRequest} request ReadMessagesRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ReadMessagesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateDevice}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateDeviceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateDeviceResponse} [response] UpdateDeviceResponse
                 */

                /**
                 * Calls UpdateDevice.
                 * @function updateDevice
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateDeviceRequest} request UpdateDeviceRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateDeviceCallback} callback Node-style callback called with the error, if any, and UpdateDeviceResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateDevice = function updateDevice(request, callback) {
                    return this.rpcCall(updateDevice, $root.visor.clientrpc.UpdateDeviceRequest, $root.visor.clientrpc.UpdateDeviceResponse, request, callback);
                }, "name", { value: "UpdateDevice" });

                /**
                 * Calls UpdateDevice.
                 * @function updateDevice
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateDeviceRequest} request UpdateDeviceRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateDeviceResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getSessionRecordingDecision}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetSessionRecordingDecisionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetSessionRecordingDecisionResponse} [response] GetSessionRecordingDecisionResponse
                 */

                /**
                 * Calls GetSessionRecordingDecision.
                 * @function getSessionRecordingDecision
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionRequest} request GetSessionRecordingDecisionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetSessionRecordingDecisionCallback} callback Node-style callback called with the error, if any, and GetSessionRecordingDecisionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getSessionRecordingDecision = function getSessionRecordingDecision(request, callback) {
                    return this.rpcCall(getSessionRecordingDecision, $root.visor.clientrpc.GetSessionRecordingDecisionRequest, $root.visor.clientrpc.GetSessionRecordingDecisionResponse, request, callback);
                }, "name", { value: "GetSessionRecordingDecision" });

                /**
                 * Calls GetSessionRecordingDecision.
                 * @function getSessionRecordingDecision
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionRequest} request GetSessionRecordingDecisionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetSessionRecordingDecisionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#resendEmailVerificationLink}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ResendEmailVerificationLinkCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ResendEmailVerificationLinkResponse} [response] ResendEmailVerificationLinkResponse
                 */

                /**
                 * Calls ResendEmailVerificationLink.
                 * @function resendEmailVerificationLink
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResendEmailVerificationLinkRequest} request ResendEmailVerificationLinkRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ResendEmailVerificationLinkCallback} callback Node-style callback called with the error, if any, and ResendEmailVerificationLinkResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.resendEmailVerificationLink = function resendEmailVerificationLink(request, callback) {
                    return this.rpcCall(resendEmailVerificationLink, $root.visor.clientrpc.ResendEmailVerificationLinkRequest, $root.visor.clientrpc.ResendEmailVerificationLinkResponse, request, callback);
                }, "name", { value: "ResendEmailVerificationLink" });

                /**
                 * Calls ResendEmailVerificationLink.
                 * @function resendEmailVerificationLink
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResendEmailVerificationLinkRequest} request ResendEmailVerificationLinkRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ResendEmailVerificationLinkResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#login}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef LoginCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.LoginResponse} [response] LoginResponse
                 */

                /**
                 * Calls Login.
                 * @function login
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ILoginRequest} request LoginRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.LoginCallback} callback Node-style callback called with the error, if any, and LoginResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.login = function login(request, callback) {
                    return this.rpcCall(login, $root.visor.clientrpc.LoginRequest, $root.visor.clientrpc.LoginResponse, request, callback);
                }, "name", { value: "Login" });

                /**
                 * Calls Login.
                 * @function login
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ILoginRequest} request LoginRequest message or plain object
                 * @returns {Promise<visor.clientrpc.LoginResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#logout}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef LogoutCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.LogoutResponse} [response] LogoutResponse
                 */

                /**
                 * Calls Logout.
                 * @function logout
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ILogoutRequest} request LogoutRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.LogoutCallback} callback Node-style callback called with the error, if any, and LogoutResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.logout = function logout(request, callback) {
                    return this.rpcCall(logout, $root.visor.clientrpc.LogoutRequest, $root.visor.clientrpc.LogoutResponse, request, callback);
                }, "name", { value: "Logout" });

                /**
                 * Calls Logout.
                 * @function logout
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ILogoutRequest} request LogoutRequest message or plain object
                 * @returns {Promise<visor.clientrpc.LogoutResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#start2FASetup}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef Start2FASetupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.Start2FASetupResponse} [response] Start2FASetupResponse
                 */

                /**
                 * Calls Start2FASetup.
                 * @function start2FASetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStart2FASetupRequest} request Start2FASetupRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.Start2FASetupCallback} callback Node-style callback called with the error, if any, and Start2FASetupResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.start2FASetup = function start2FASetup(request, callback) {
                    return this.rpcCall(start2FASetup, $root.visor.clientrpc.Start2FASetupRequest, $root.visor.clientrpc.Start2FASetupResponse, request, callback);
                }, "name", { value: "Start2FASetup" });

                /**
                 * Calls Start2FASetup.
                 * @function start2FASetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStart2FASetupRequest} request Start2FASetupRequest message or plain object
                 * @returns {Promise<visor.clientrpc.Start2FASetupResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#finish2FASetup}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef Finish2FASetupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.Finish2FASetupResponse} [response] Finish2FASetupResponse
                 */

                /**
                 * Calls Finish2FASetup.
                 * @function finish2FASetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinish2FASetupRequest} request Finish2FASetupRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.Finish2FASetupCallback} callback Node-style callback called with the error, if any, and Finish2FASetupResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.finish2FASetup = function finish2FASetup(request, callback) {
                    return this.rpcCall(finish2FASetup, $root.visor.clientrpc.Finish2FASetupRequest, $root.visor.clientrpc.Finish2FASetupResponse, request, callback);
                }, "name", { value: "Finish2FASetup" });

                /**
                 * Calls Finish2FASetup.
                 * @function finish2FASetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinish2FASetupRequest} request Finish2FASetupRequest message or plain object
                 * @returns {Promise<visor.clientrpc.Finish2FASetupResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#disable2FA}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef Disable2FACallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.Disable2FAResponse} [response] Disable2FAResponse
                 */

                /**
                 * Calls Disable2FA.
                 * @function disable2FA
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IDisable2FARequest} request Disable2FARequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.Disable2FACallback} callback Node-style callback called with the error, if any, and Disable2FAResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.disable2FA = function disable2FA(request, callback) {
                    return this.rpcCall(disable2FA, $root.visor.clientrpc.Disable2FARequest, $root.visor.clientrpc.Disable2FAResponse, request, callback);
                }, "name", { value: "Disable2FA" });

                /**
                 * Calls Disable2FA.
                 * @function disable2FA
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IDisable2FARequest} request Disable2FARequest message or plain object
                 * @returns {Promise<visor.clientrpc.Disable2FAResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#startForgotPassword}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef StartForgotPasswordCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.StartForgotPasswordResponse} [response] StartForgotPasswordResponse
                 */

                /**
                 * Calls StartForgotPassword.
                 * @function startForgotPassword
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStartForgotPasswordRequest} request StartForgotPasswordRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.StartForgotPasswordCallback} callback Node-style callback called with the error, if any, and StartForgotPasswordResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.startForgotPassword = function startForgotPassword(request, callback) {
                    return this.rpcCall(startForgotPassword, $root.visor.clientrpc.StartForgotPasswordRequest, $root.visor.clientrpc.StartForgotPasswordResponse, request, callback);
                }, "name", { value: "StartForgotPassword" });

                /**
                 * Calls StartForgotPassword.
                 * @function startForgotPassword
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IStartForgotPasswordRequest} request StartForgotPasswordRequest message or plain object
                 * @returns {Promise<visor.clientrpc.StartForgotPasswordResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#finishForgotPassword}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef FinishForgotPasswordCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.FinishForgotPasswordResponse} [response] FinishForgotPasswordResponse
                 */

                /**
                 * Calls FinishForgotPassword.
                 * @function finishForgotPassword
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinishForgotPasswordRequest} request FinishForgotPasswordRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.FinishForgotPasswordCallback} callback Node-style callback called with the error, if any, and FinishForgotPasswordResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.finishForgotPassword = function finishForgotPassword(request, callback) {
                    return this.rpcCall(finishForgotPassword, $root.visor.clientrpc.FinishForgotPasswordRequest, $root.visor.clientrpc.FinishForgotPasswordResponse, request, callback);
                }, "name", { value: "FinishForgotPassword" });

                /**
                 * Calls FinishForgotPassword.
                 * @function finishForgotPassword
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinishForgotPasswordRequest} request FinishForgotPasswordRequest message or plain object
                 * @returns {Promise<visor.clientrpc.FinishForgotPasswordResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#confirmEmail}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ConfirmEmailCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ConfirmEmailResponse} [response] ConfirmEmailResponse
                 */

                /**
                 * Calls ConfirmEmail.
                 * @function confirmEmail
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IConfirmEmailRequest} request ConfirmEmailRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ConfirmEmailCallback} callback Node-style callback called with the error, if any, and ConfirmEmailResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.confirmEmail = function confirmEmail(request, callback) {
                    return this.rpcCall(confirmEmail, $root.visor.clientrpc.ConfirmEmailRequest, $root.visor.clientrpc.ConfirmEmailResponse, request, callback);
                }, "name", { value: "ConfirmEmail" });

                /**
                 * Calls ConfirmEmail.
                 * @function confirmEmail
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IConfirmEmailRequest} request ConfirmEmailRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ConfirmEmailResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#pauseSubscription}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef PauseSubscriptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.PauseSubscriptionResponse} [response] PauseSubscriptionResponse
                 */

                /**
                 * Calls PauseSubscription.
                 * @function pauseSubscription
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPauseSubscriptionRequest} request PauseSubscriptionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.PauseSubscriptionCallback} callback Node-style callback called with the error, if any, and PauseSubscriptionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.pauseSubscription = function pauseSubscription(request, callback) {
                    return this.rpcCall(pauseSubscription, $root.visor.clientrpc.PauseSubscriptionRequest, $root.visor.clientrpc.PauseSubscriptionResponse, request, callback);
                }, "name", { value: "PauseSubscription" });

                /**
                 * Calls PauseSubscription.
                 * @function pauseSubscription
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPauseSubscriptionRequest} request PauseSubscriptionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.PauseSubscriptionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#resumeSubscription}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ResumeSubscriptionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ResumeSubscriptionResponse} [response] ResumeSubscriptionResponse
                 */

                /**
                 * Calls ResumeSubscription.
                 * @function resumeSubscription
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResumeSubscriptionRequest} request ResumeSubscriptionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ResumeSubscriptionCallback} callback Node-style callback called with the error, if any, and ResumeSubscriptionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.resumeSubscription = function resumeSubscription(request, callback) {
                    return this.rpcCall(resumeSubscription, $root.visor.clientrpc.ResumeSubscriptionRequest, $root.visor.clientrpc.ResumeSubscriptionResponse, request, callback);
                }, "name", { value: "ResumeSubscription" });

                /**
                 * Calls ResumeSubscription.
                 * @function resumeSubscription
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResumeSubscriptionRequest} request ResumeSubscriptionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ResumeSubscriptionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCancelSubscriptionEligibility}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCancelSubscriptionEligibilityCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCancelSubscriptionEligibilityResponse} [response] GetCancelSubscriptionEligibilityResponse
                 */

                /**
                 * Calls GetCancelSubscriptionEligibility.
                 * @function getCancelSubscriptionEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityRequest} request GetCancelSubscriptionEligibilityRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCancelSubscriptionEligibilityCallback} callback Node-style callback called with the error, if any, and GetCancelSubscriptionEligibilityResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCancelSubscriptionEligibility = function getCancelSubscriptionEligibility(request, callback) {
                    return this.rpcCall(getCancelSubscriptionEligibility, $root.visor.clientrpc.GetCancelSubscriptionEligibilityRequest, $root.visor.clientrpc.GetCancelSubscriptionEligibilityResponse, request, callback);
                }, "name", { value: "GetCancelSubscriptionEligibility" });

                /**
                 * Calls GetCancelSubscriptionEligibility.
                 * @function getCancelSubscriptionEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityRequest} request GetCancelSubscriptionEligibilityRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCancelSubscriptionEligibilityResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCloseAccountEligibility}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCloseAccountEligibilityCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCloseAccountEligibilityResponse} [response] GetCloseAccountEligibilityResponse
                 */

                /**
                 * Calls GetCloseAccountEligibility.
                 * @function getCloseAccountEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityRequest} request GetCloseAccountEligibilityRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCloseAccountEligibilityCallback} callback Node-style callback called with the error, if any, and GetCloseAccountEligibilityResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCloseAccountEligibility = function getCloseAccountEligibility(request, callback) {
                    return this.rpcCall(getCloseAccountEligibility, $root.visor.clientrpc.GetCloseAccountEligibilityRequest, $root.visor.clientrpc.GetCloseAccountEligibilityResponse, request, callback);
                }, "name", { value: "GetCloseAccountEligibility" });

                /**
                 * Calls GetCloseAccountEligibility.
                 * @function getCloseAccountEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityRequest} request GetCloseAccountEligibilityRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCloseAccountEligibilityResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#closeAccount}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CloseAccountCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CloseAccountResponse} [response] CloseAccountResponse
                 */

                /**
                 * Calls CloseAccount.
                 * @function closeAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICloseAccountRequest} request CloseAccountRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CloseAccountCallback} callback Node-style callback called with the error, if any, and CloseAccountResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.closeAccount = function closeAccount(request, callback) {
                    return this.rpcCall(closeAccount, $root.visor.clientrpc.CloseAccountRequest, $root.visor.clientrpc.CloseAccountResponse, request, callback);
                }, "name", { value: "CloseAccount" });

                /**
                 * Calls CloseAccount.
                 * @function closeAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICloseAccountRequest} request CloseAccountRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CloseAccountResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#clearW4AdjustmentBanner}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ClearW4AdjustmentBannerCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ClearW4AdjustmentBannerResponse} [response] ClearW4AdjustmentBannerResponse
                 */

                /**
                 * Calls ClearW4AdjustmentBanner.
                 * @function clearW4AdjustmentBanner
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerRequest} request ClearW4AdjustmentBannerRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ClearW4AdjustmentBannerCallback} callback Node-style callback called with the error, if any, and ClearW4AdjustmentBannerResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.clearW4AdjustmentBanner = function clearW4AdjustmentBanner(request, callback) {
                    return this.rpcCall(clearW4AdjustmentBanner, $root.visor.clientrpc.ClearW4AdjustmentBannerRequest, $root.visor.clientrpc.ClearW4AdjustmentBannerResponse, request, callback);
                }, "name", { value: "ClearW4AdjustmentBanner" });

                /**
                 * Calls ClearW4AdjustmentBanner.
                 * @function clearW4AdjustmentBanner
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerRequest} request ClearW4AdjustmentBannerRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ClearW4AdjustmentBannerResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createLead}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateLeadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateLeadResponse} [response] CreateLeadResponse
                 */

                /**
                 * Calls CreateLead.
                 * @function createLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateLeadRequest} request CreateLeadRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateLeadCallback} callback Node-style callback called with the error, if any, and CreateLeadResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createLead = function createLead(request, callback) {
                    return this.rpcCall(createLead, $root.visor.clientrpc.CreateLeadRequest, $root.visor.clientrpc.CreateLeadResponse, request, callback);
                }, "name", { value: "CreateLead" });

                /**
                 * Calls CreateLead.
                 * @function createLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateLeadRequest} request CreateLeadRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateLeadResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createContactUsLead}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateContactUsLeadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateContactUsLeadResponse} [response] CreateContactUsLeadResponse
                 */

                /**
                 * Calls CreateContactUsLead.
                 * @function createContactUsLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateContactUsLeadRequest} request CreateContactUsLeadRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateContactUsLeadCallback} callback Node-style callback called with the error, if any, and CreateContactUsLeadResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createContactUsLead = function createContactUsLead(request, callback) {
                    return this.rpcCall(createContactUsLead, $root.visor.clientrpc.CreateContactUsLeadRequest, $root.visor.clientrpc.CreateContactUsLeadResponse, request, callback);
                }, "name", { value: "CreateContactUsLead" });

                /**
                 * Calls CreateContactUsLead.
                 * @function createContactUsLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateContactUsLeadRequest} request CreateContactUsLeadRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateContactUsLeadResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#sendLeadDownloadLink}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SendLeadDownloadLinkCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SendLeadDownloadLinkResponse} [response] SendLeadDownloadLinkResponse
                 */

                /**
                 * Calls SendLeadDownloadLink.
                 * @function sendLeadDownloadLink
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISendLeadDownloadLinkRequest} request SendLeadDownloadLinkRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SendLeadDownloadLinkCallback} callback Node-style callback called with the error, if any, and SendLeadDownloadLinkResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.sendLeadDownloadLink = function sendLeadDownloadLink(request, callback) {
                    return this.rpcCall(sendLeadDownloadLink, $root.visor.clientrpc.SendLeadDownloadLinkRequest, $root.visor.clientrpc.SendLeadDownloadLinkResponse, request, callback);
                }, "name", { value: "SendLeadDownloadLink" });

                /**
                 * Calls SendLeadDownloadLink.
                 * @function sendLeadDownloadLink
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISendLeadDownloadLinkRequest} request SendLeadDownloadLinkRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SendLeadDownloadLinkResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createAndroidLead}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateAndroidLeadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateAndroidLeadResponse} [response] CreateAndroidLeadResponse
                 */

                /**
                 * Calls CreateAndroidLead.
                 * @function createAndroidLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateAndroidLeadRequest} request CreateAndroidLeadRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateAndroidLeadCallback} callback Node-style callback called with the error, if any, and CreateAndroidLeadResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createAndroidLead = function createAndroidLead(request, callback) {
                    return this.rpcCall(createAndroidLead, $root.visor.clientrpc.CreateAndroidLeadRequest, $root.visor.clientrpc.CreateAndroidLeadResponse, request, callback);
                }, "name", { value: "CreateAndroidLead" });

                /**
                 * Calls CreateAndroidLead.
                 * @function createAndroidLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateAndroidLeadRequest} request CreateAndroidLeadRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateAndroidLeadResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getReferralLink}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetReferralLinkCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetReferralLinkResponse} [response] GetReferralLinkResponse
                 */

                /**
                 * Calls GetReferralLink.
                 * @function getReferralLink
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetReferralLinkRequest} request GetReferralLinkRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetReferralLinkCallback} callback Node-style callback called with the error, if any, and GetReferralLinkResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getReferralLink = function getReferralLink(request, callback) {
                    return this.rpcCall(getReferralLink, $root.visor.clientrpc.GetReferralLinkRequest, $root.visor.clientrpc.GetReferralLinkResponse, request, callback);
                }, "name", { value: "GetReferralLink" });

                /**
                 * Calls GetReferralLink.
                 * @function getReferralLink
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetReferralLinkRequest} request GetReferralLinkRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetReferralLinkResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getReferralsCreditBalance}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetReferralsCreditBalanceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetReferralsCreditBalanceResponse} [response] GetReferralsCreditBalanceResponse
                 */

                /**
                 * Calls GetReferralsCreditBalance.
                 * @function getReferralsCreditBalance
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceRequest} request GetReferralsCreditBalanceRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetReferralsCreditBalanceCallback} callback Node-style callback called with the error, if any, and GetReferralsCreditBalanceResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getReferralsCreditBalance = function getReferralsCreditBalance(request, callback) {
                    return this.rpcCall(getReferralsCreditBalance, $root.visor.clientrpc.GetReferralsCreditBalanceRequest, $root.visor.clientrpc.GetReferralsCreditBalanceResponse, request, callback);
                }, "name", { value: "GetReferralsCreditBalance" });

                /**
                 * Calls GetReferralsCreditBalance.
                 * @function getReferralsCreditBalance
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceRequest} request GetReferralsCreditBalanceRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetReferralsCreditBalanceResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getReferralCodeInformation}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetReferralCodeInformationCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetReferralCodeInformationResponse} [response] GetReferralCodeInformationResponse
                 */

                /**
                 * Calls GetReferralCodeInformation.
                 * @function getReferralCodeInformation
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetReferralCodeInformationRequest} request GetReferralCodeInformationRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetReferralCodeInformationCallback} callback Node-style callback called with the error, if any, and GetReferralCodeInformationResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getReferralCodeInformation = function getReferralCodeInformation(request, callback) {
                    return this.rpcCall(getReferralCodeInformation, $root.visor.clientrpc.GetReferralCodeInformationRequest, $root.visor.clientrpc.GetReferralCodeInformationResponse, request, callback);
                }, "name", { value: "GetReferralCodeInformation" });

                /**
                 * Calls GetReferralCodeInformation.
                 * @function getReferralCodeInformation
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetReferralCodeInformationRequest} request GetReferralCodeInformationRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetReferralCodeInformationResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createReferralLead}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateReferralLeadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateReferralLeadResponse} [response] CreateReferralLeadResponse
                 */

                /**
                 * Calls CreateReferralLead.
                 * @function createReferralLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateReferralLeadRequest} request CreateReferralLeadRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateReferralLeadCallback} callback Node-style callback called with the error, if any, and CreateReferralLeadResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createReferralLead = function createReferralLead(request, callback) {
                    return this.rpcCall(createReferralLead, $root.visor.clientrpc.CreateReferralLeadRequest, $root.visor.clientrpc.CreateReferralLeadResponse, request, callback);
                }, "name", { value: "CreateReferralLead" });

                /**
                 * Calls CreateReferralLead.
                 * @function createReferralLead
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateReferralLeadRequest} request CreateReferralLeadRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateReferralLeadResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createAppReview}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateAppReviewCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateAppReviewResponse} [response] CreateAppReviewResponse
                 */

                /**
                 * Calls CreateAppReview.
                 * @function createAppReview
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateAppReviewRequest} request CreateAppReviewRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateAppReviewCallback} callback Node-style callback called with the error, if any, and CreateAppReviewResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createAppReview = function createAppReview(request, callback) {
                    return this.rpcCall(createAppReview, $root.visor.clientrpc.CreateAppReviewRequest, $root.visor.clientrpc.CreateAppReviewResponse, request, callback);
                }, "name", { value: "CreateAppReview" });

                /**
                 * Calls CreateAppReview.
                 * @function createAppReview
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateAppReviewRequest} request CreateAppReviewRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateAppReviewResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#acknowledgeBoostedPayStatement}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef AcknowledgeBoostedPayStatementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.AcknowledgeBoostedPayStatementResponse} [response] AcknowledgeBoostedPayStatementResponse
                 */

                /**
                 * Calls AcknowledgeBoostedPayStatement.
                 * @function acknowledgeBoostedPayStatement
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementRequest} request AcknowledgeBoostedPayStatementRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.AcknowledgeBoostedPayStatementCallback} callback Node-style callback called with the error, if any, and AcknowledgeBoostedPayStatementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.acknowledgeBoostedPayStatement = function acknowledgeBoostedPayStatement(request, callback) {
                    return this.rpcCall(acknowledgeBoostedPayStatement, $root.visor.clientrpc.AcknowledgeBoostedPayStatementRequest, $root.visor.clientrpc.AcknowledgeBoostedPayStatementResponse, request, callback);
                }, "name", { value: "AcknowledgeBoostedPayStatement" });

                /**
                 * Calls AcknowledgeBoostedPayStatement.
                 * @function acknowledgeBoostedPayStatement
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementRequest} request AcknowledgeBoostedPayStatementRequest message or plain object
                 * @returns {Promise<visor.clientrpc.AcknowledgeBoostedPayStatementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCashBoostStatus}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCashBoostStatusCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCashBoostStatusResponse} [response] GetCashBoostStatusResponse
                 */

                /**
                 * Calls GetCashBoostStatus.
                 * @function getCashBoostStatus
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCashBoostStatusRequest} request GetCashBoostStatusRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCashBoostStatusCallback} callback Node-style callback called with the error, if any, and GetCashBoostStatusResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCashBoostStatus = function getCashBoostStatus(request, callback) {
                    return this.rpcCall(getCashBoostStatus, $root.visor.clientrpc.GetCashBoostStatusRequest, $root.visor.clientrpc.GetCashBoostStatusResponse, request, callback);
                }, "name", { value: "GetCashBoostStatus" });

                /**
                 * Calls GetCashBoostStatus.
                 * @function getCashBoostStatus
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCashBoostStatusRequest} request GetCashBoostStatusRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCashBoostStatusResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginPayboostSetupProject}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginPayboostSetupProjectCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginPayboostSetupProjectResponse} [response] BeginPayboostSetupProjectResponse
                 */

                /**
                 * Calls BeginPayboostSetupProject.
                 * @function beginPayboostSetupProject
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectRequest} request BeginPayboostSetupProjectRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginPayboostSetupProjectCallback} callback Node-style callback called with the error, if any, and BeginPayboostSetupProjectResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginPayboostSetupProject = function beginPayboostSetupProject(request, callback) {
                    return this.rpcCall(beginPayboostSetupProject, $root.visor.clientrpc.BeginPayboostSetupProjectRequest, $root.visor.clientrpc.BeginPayboostSetupProjectResponse, request, callback);
                }, "name", { value: "BeginPayboostSetupProject" });

                /**
                 * Calls BeginPayboostSetupProject.
                 * @function beginPayboostSetupProject
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectRequest} request BeginPayboostSetupProjectRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginPayboostSetupProjectResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginCashBoostSetup}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginCashBoostSetupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginCashBoostSetupResponse} [response] BeginCashBoostSetupResponse
                 */

                /**
                 * Calls BeginCashBoostSetup.
                 * @function beginCashBoostSetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginCashBoostSetupRequest} request BeginCashBoostSetupRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginCashBoostSetupCallback} callback Node-style callback called with the error, if any, and BeginCashBoostSetupResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginCashBoostSetup = function beginCashBoostSetup(request, callback) {
                    return this.rpcCall(beginCashBoostSetup, $root.visor.clientrpc.BeginCashBoostSetupRequest, $root.visor.clientrpc.BeginCashBoostSetupResponse, request, callback);
                }, "name", { value: "BeginCashBoostSetup" });

                /**
                 * Calls BeginCashBoostSetup.
                 * @function beginCashBoostSetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginCashBoostSetupRequest} request BeginCashBoostSetupRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginCashBoostSetupResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginSubsequentAdvanceSetup}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginSubsequentAdvanceSetupCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginSubsequentAdvanceSetupResponse} [response] BeginSubsequentAdvanceSetupResponse
                 */

                /**
                 * Calls BeginSubsequentAdvanceSetup.
                 * @function beginSubsequentAdvanceSetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupRequest} request BeginSubsequentAdvanceSetupRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginSubsequentAdvanceSetupCallback} callback Node-style callback called with the error, if any, and BeginSubsequentAdvanceSetupResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginSubsequentAdvanceSetup = function beginSubsequentAdvanceSetup(request, callback) {
                    return this.rpcCall(beginSubsequentAdvanceSetup, $root.visor.clientrpc.BeginSubsequentAdvanceSetupRequest, $root.visor.clientrpc.BeginSubsequentAdvanceSetupResponse, request, callback);
                }, "name", { value: "BeginSubsequentAdvanceSetup" });

                /**
                 * Calls BeginSubsequentAdvanceSetup.
                 * @function beginSubsequentAdvanceSetup
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupRequest} request BeginSubsequentAdvanceSetupRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginSubsequentAdvanceSetupResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createBankAccount}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateBankAccountCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateBankAccountResponse} [response] CreateBankAccountResponse
                 */

                /**
                 * Calls CreateBankAccount.
                 * @function createBankAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateBankAccountRequest} request CreateBankAccountRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateBankAccountCallback} callback Node-style callback called with the error, if any, and CreateBankAccountResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createBankAccount = function createBankAccount(request, callback) {
                    return this.rpcCall(createBankAccount, $root.visor.clientrpc.CreateBankAccountRequest, $root.visor.clientrpc.CreateBankAccountResponse, request, callback);
                }, "name", { value: "CreateBankAccount" });

                /**
                 * Calls CreateBankAccount.
                 * @function createBankAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateBankAccountRequest} request CreateBankAccountRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateBankAccountResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createDebitCard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateDebitCardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateDebitCardResponse} [response] CreateDebitCardResponse
                 */

                /**
                 * Calls CreateDebitCard.
                 * @function createDebitCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateDebitCardRequest} request CreateDebitCardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateDebitCardCallback} callback Node-style callback called with the error, if any, and CreateDebitCardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createDebitCard = function createDebitCard(request, callback) {
                    return this.rpcCall(createDebitCard, $root.visor.clientrpc.CreateDebitCardRequest, $root.visor.clientrpc.CreateDebitCardResponse, request, callback);
                }, "name", { value: "CreateDebitCard" });

                /**
                 * Calls CreateDebitCard.
                 * @function createDebitCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateDebitCardRequest} request CreateDebitCardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateDebitCardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getPlaidLinkToken}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetPlaidLinkTokenCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetPlaidLinkTokenResponse} [response] GetPlaidLinkTokenResponse
                 */

                /**
                 * Calls GetPlaidLinkToken.
                 * @function getPlaidLinkToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetPlaidLinkTokenRequest} request GetPlaidLinkTokenRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetPlaidLinkTokenCallback} callback Node-style callback called with the error, if any, and GetPlaidLinkTokenResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getPlaidLinkToken = function getPlaidLinkToken(request, callback) {
                    return this.rpcCall(getPlaidLinkToken, $root.visor.clientrpc.GetPlaidLinkTokenRequest, $root.visor.clientrpc.GetPlaidLinkTokenResponse, request, callback);
                }, "name", { value: "GetPlaidLinkToken" });

                /**
                 * Calls GetPlaidLinkToken.
                 * @function getPlaidLinkToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetPlaidLinkTokenRequest} request GetPlaidLinkTokenRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetPlaidLinkTokenResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateBankAccount}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateBankAccountCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateBankAccountResponse} [response] UpdateBankAccountResponse
                 */

                /**
                 * Calls UpdateBankAccount.
                 * @function updateBankAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateBankAccountRequest} request UpdateBankAccountRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateBankAccountCallback} callback Node-style callback called with the error, if any, and UpdateBankAccountResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateBankAccount = function updateBankAccount(request, callback) {
                    return this.rpcCall(updateBankAccount, $root.visor.clientrpc.UpdateBankAccountRequest, $root.visor.clientrpc.UpdateBankAccountResponse, request, callback);
                }, "name", { value: "UpdateBankAccount" });

                /**
                 * Calls UpdateBankAccount.
                 * @function updateBankAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateBankAccountRequest} request UpdateBankAccountRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateBankAccountResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateDebitCard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateDebitCardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateDebitCardResponse} [response] UpdateDebitCardResponse
                 */

                /**
                 * Calls UpdateDebitCard.
                 * @function updateDebitCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateDebitCardRequest} request UpdateDebitCardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateDebitCardCallback} callback Node-style callback called with the error, if any, and UpdateDebitCardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateDebitCard = function updateDebitCard(request, callback) {
                    return this.rpcCall(updateDebitCard, $root.visor.clientrpc.UpdateDebitCardRequest, $root.visor.clientrpc.UpdateDebitCardResponse, request, callback);
                }, "name", { value: "UpdateDebitCard" });

                /**
                 * Calls UpdateDebitCard.
                 * @function updateDebitCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateDebitCardRequest} request UpdateDebitCardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateDebitCardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#finishCashBoostCheckout}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef FinishCashBoostCheckoutCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.FinishCashBoostCheckoutResponse} [response] FinishCashBoostCheckoutResponse
                 */

                /**
                 * Calls FinishCashBoostCheckout.
                 * @function finishCashBoostCheckout
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutRequest} request FinishCashBoostCheckoutRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.FinishCashBoostCheckoutCallback} callback Node-style callback called with the error, if any, and FinishCashBoostCheckoutResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.finishCashBoostCheckout = function finishCashBoostCheckout(request, callback) {
                    return this.rpcCall(finishCashBoostCheckout, $root.visor.clientrpc.FinishCashBoostCheckoutRequest, $root.visor.clientrpc.FinishCashBoostCheckoutResponse, request, callback);
                }, "name", { value: "FinishCashBoostCheckout" });

                /**
                 * Calls FinishCashBoostCheckout.
                 * @function finishCashBoostCheckout
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutRequest} request FinishCashBoostCheckoutRequest message or plain object
                 * @returns {Promise<visor.clientrpc.FinishCashBoostCheckoutResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createRiskDecision}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateRiskDecisionCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateRiskDecisionResponse} [response] CreateRiskDecisionResponse
                 */

                /**
                 * Calls CreateRiskDecision.
                 * @function createRiskDecision
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateRiskDecisionRequest} request CreateRiskDecisionRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateRiskDecisionCallback} callback Node-style callback called with the error, if any, and CreateRiskDecisionResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createRiskDecision = function createRiskDecision(request, callback) {
                    return this.rpcCall(createRiskDecision, $root.visor.clientrpc.CreateRiskDecisionRequest, $root.visor.clientrpc.CreateRiskDecisionResponse, request, callback);
                }, "name", { value: "CreateRiskDecision" });

                /**
                 * Calls CreateRiskDecision.
                 * @function createRiskDecision
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateRiskDecisionRequest} request CreateRiskDecisionRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateRiskDecisionResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getSubsequentAdvanceEligibility}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetSubsequentAdvanceEligibilityCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetSubsequentAdvanceEligibilityResponse} [response] GetSubsequentAdvanceEligibilityResponse
                 */

                /**
                 * Calls GetSubsequentAdvanceEligibility.
                 * @function getSubsequentAdvanceEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityRequest} request GetSubsequentAdvanceEligibilityRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetSubsequentAdvanceEligibilityCallback} callback Node-style callback called with the error, if any, and GetSubsequentAdvanceEligibilityResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getSubsequentAdvanceEligibility = function getSubsequentAdvanceEligibility(request, callback) {
                    return this.rpcCall(getSubsequentAdvanceEligibility, $root.visor.clientrpc.GetSubsequentAdvanceEligibilityRequest, $root.visor.clientrpc.GetSubsequentAdvanceEligibilityResponse, request, callback);
                }, "name", { value: "GetSubsequentAdvanceEligibility" });

                /**
                 * Calls GetSubsequentAdvanceEligibility.
                 * @function getSubsequentAdvanceEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityRequest} request GetSubsequentAdvanceEligibilityRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetSubsequentAdvanceEligibilityResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createCashboostObjectIfRequired}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateCashboostObjectIfRequiredCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateCashboostObjectIfRequiredResponse} [response] CreateCashboostObjectIfRequiredResponse
                 */

                /**
                 * Calls CreateCashboostObjectIfRequired.
                 * @function createCashboostObjectIfRequired
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredRequest} request CreateCashboostObjectIfRequiredRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateCashboostObjectIfRequiredCallback} callback Node-style callback called with the error, if any, and CreateCashboostObjectIfRequiredResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createCashboostObjectIfRequired = function createCashboostObjectIfRequired(request, callback) {
                    return this.rpcCall(createCashboostObjectIfRequired, $root.visor.clientrpc.CreateCashboostObjectIfRequiredRequest, $root.visor.clientrpc.CreateCashboostObjectIfRequiredResponse, request, callback);
                }, "name", { value: "CreateCashboostObjectIfRequired" });

                /**
                 * Calls CreateCashboostObjectIfRequired.
                 * @function createCashboostObjectIfRequired
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredRequest} request CreateCashboostObjectIfRequiredRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateCashboostObjectIfRequiredResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#repayCashboostAdvance}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef RepayCashboostAdvanceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.RepayCashboostAdvanceResponse} [response] RepayCashboostAdvanceResponse
                 */

                /**
                 * Calls RepayCashboostAdvance.
                 * @function repayCashboostAdvance
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IRepayCashboostAdvanceRequest} request RepayCashboostAdvanceRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.RepayCashboostAdvanceCallback} callback Node-style callback called with the error, if any, and RepayCashboostAdvanceResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.repayCashboostAdvance = function repayCashboostAdvance(request, callback) {
                    return this.rpcCall(repayCashboostAdvance, $root.visor.clientrpc.RepayCashboostAdvanceRequest, $root.visor.clientrpc.RepayCashboostAdvanceResponse, request, callback);
                }, "name", { value: "RepayCashboostAdvance" });

                /**
                 * Calls RepayCashboostAdvance.
                 * @function repayCashboostAdvance
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IRepayCashboostAdvanceRequest} request RepayCashboostAdvanceRequest message or plain object
                 * @returns {Promise<visor.clientrpc.RepayCashboostAdvanceResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginExceptionWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginExceptionWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginExceptionWorkflowResponse} [response] BeginExceptionWorkflowResponse
                 */

                /**
                 * Calls BeginExceptionWorkflow.
                 * @function beginExceptionWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginExceptionWorkflowRequest} request BeginExceptionWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginExceptionWorkflowCallback} callback Node-style callback called with the error, if any, and BeginExceptionWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginExceptionWorkflow = function beginExceptionWorkflow(request, callback) {
                    return this.rpcCall(beginExceptionWorkflow, $root.visor.clientrpc.BeginExceptionWorkflowRequest, $root.visor.clientrpc.BeginExceptionWorkflowResponse, request, callback);
                }, "name", { value: "BeginExceptionWorkflow" });

                /**
                 * Calls BeginExceptionWorkflow.
                 * @function beginExceptionWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginExceptionWorkflowRequest} request BeginExceptionWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginExceptionWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#cashboostAdvancesHistory}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CashboostAdvancesHistoryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CashboostAdvancesHistoryResponse} [response] CashboostAdvancesHistoryResponse
                 */

                /**
                 * Calls CashboostAdvancesHistory.
                 * @function cashboostAdvancesHistory
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryRequest} request CashboostAdvancesHistoryRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CashboostAdvancesHistoryCallback} callback Node-style callback called with the error, if any, and CashboostAdvancesHistoryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.cashboostAdvancesHistory = function cashboostAdvancesHistory(request, callback) {
                    return this.rpcCall(cashboostAdvancesHistory, $root.visor.clientrpc.CashboostAdvancesHistoryRequest, $root.visor.clientrpc.CashboostAdvancesHistoryResponse, request, callback);
                }, "name", { value: "CashboostAdvancesHistory" });

                /**
                 * Calls CashboostAdvancesHistory.
                 * @function cashboostAdvancesHistory
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryRequest} request CashboostAdvancesHistoryRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CashboostAdvancesHistoryResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#listInvoices}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ListInvoicesCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ListInvoicesResponse} [response] ListInvoicesResponse
                 */

                /**
                 * Calls ListInvoices.
                 * @function listInvoices
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IListInvoicesRequest} request ListInvoicesRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ListInvoicesCallback} callback Node-style callback called with the error, if any, and ListInvoicesResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.listInvoices = function listInvoices(request, callback) {
                    return this.rpcCall(listInvoices, $root.visor.clientrpc.ListInvoicesRequest, $root.visor.clientrpc.ListInvoicesResponse, request, callback);
                }, "name", { value: "ListInvoices" });

                /**
                 * Calls ListInvoices.
                 * @function listInvoices
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IListInvoicesRequest} request ListInvoicesRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ListInvoicesResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#payInvoice}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef PayInvoiceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.PayInvoiceResponse} [response] PayInvoiceResponse
                 */

                /**
                 * Calls PayInvoice.
                 * @function payInvoice
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPayInvoiceRequest} request PayInvoiceRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.PayInvoiceCallback} callback Node-style callback called with the error, if any, and PayInvoiceResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.payInvoice = function payInvoice(request, callback) {
                    return this.rpcCall(payInvoice, $root.visor.clientrpc.PayInvoiceRequest, $root.visor.clientrpc.PayInvoiceResponse, request, callback);
                }, "name", { value: "PayInvoice" });

                /**
                 * Calls PayInvoice.
                 * @function payInvoice
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPayInvoiceRequest} request PayInvoiceRequest message or plain object
                 * @returns {Promise<visor.clientrpc.PayInvoiceResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createCard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateCardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateCardResponse} [response] CreateCardResponse
                 */

                /**
                 * Calls CreateCard.
                 * @function createCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateCardRequest} request CreateCardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateCardCallback} callback Node-style callback called with the error, if any, and CreateCardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createCard = function createCard(request, callback) {
                    return this.rpcCall(createCard, $root.visor.clientrpc.CreateCardRequest, $root.visor.clientrpc.CreateCardResponse, request, callback);
                }, "name", { value: "CreateCard" });

                /**
                 * Calls CreateCard.
                 * @function createCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateCardRequest} request CreateCardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateCardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getFootprintAuthToken}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetFootprintAuthTokenCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetFootprintAuthTokenResponse} [response] GetFootprintAuthTokenResponse
                 */

                /**
                 * Calls GetFootprintAuthToken.
                 * @function getFootprintAuthToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetFootprintAuthTokenRequest} request GetFootprintAuthTokenRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetFootprintAuthTokenCallback} callback Node-style callback called with the error, if any, and GetFootprintAuthTokenResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getFootprintAuthToken = function getFootprintAuthToken(request, callback) {
                    return this.rpcCall(getFootprintAuthToken, $root.visor.clientrpc.GetFootprintAuthTokenRequest, $root.visor.clientrpc.GetFootprintAuthTokenResponse, request, callback);
                }, "name", { value: "GetFootprintAuthToken" });

                /**
                 * Calls GetFootprintAuthToken.
                 * @function getFootprintAuthToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetFootprintAuthTokenRequest} request GetFootprintAuthTokenRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetFootprintAuthTokenResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCashBoostCheckoutOffer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCashBoostCheckoutOfferCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCashBoostCheckoutOfferResponse} [response] GetCashBoostCheckoutOfferResponse
                 */

                /**
                 * Calls GetCashBoostCheckoutOffer.
                 * @function getCashBoostCheckoutOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferRequest} request GetCashBoostCheckoutOfferRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCashBoostCheckoutOfferCallback} callback Node-style callback called with the error, if any, and GetCashBoostCheckoutOfferResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCashBoostCheckoutOffer = function getCashBoostCheckoutOffer(request, callback) {
                    return this.rpcCall(getCashBoostCheckoutOffer, $root.visor.clientrpc.GetCashBoostCheckoutOfferRequest, $root.visor.clientrpc.GetCashBoostCheckoutOfferResponse, request, callback);
                }, "name", { value: "GetCashBoostCheckoutOffer" });

                /**
                 * Calls GetCashBoostCheckoutOffer.
                 * @function getCashBoostCheckoutOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferRequest} request GetCashBoostCheckoutOfferRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCashBoostCheckoutOfferResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getBankAccountVerificationQuestions}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetBankAccountVerificationQuestionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetBankAccountVerificationQuestionsResponse} [response] GetBankAccountVerificationQuestionsResponse
                 */

                /**
                 * Calls GetBankAccountVerificationQuestions.
                 * @function getBankAccountVerificationQuestions
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsRequest} request GetBankAccountVerificationQuestionsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetBankAccountVerificationQuestionsCallback} callback Node-style callback called with the error, if any, and GetBankAccountVerificationQuestionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getBankAccountVerificationQuestions = function getBankAccountVerificationQuestions(request, callback) {
                    return this.rpcCall(getBankAccountVerificationQuestions, $root.visor.clientrpc.GetBankAccountVerificationQuestionsRequest, $root.visor.clientrpc.GetBankAccountVerificationQuestionsResponse, request, callback);
                }, "name", { value: "GetBankAccountVerificationQuestions" });

                /**
                 * Calls GetBankAccountVerificationQuestions.
                 * @function getBankAccountVerificationQuestions
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsRequest} request GetBankAccountVerificationQuestionsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetBankAccountVerificationQuestionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginChangeCashBoostBankAccountWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginChangeCashBoostBankAccountWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse} [response] BeginChangeCashBoostBankAccountWorkflowResponse
                 */

                /**
                 * Calls BeginChangeCashBoostBankAccountWorkflow.
                 * @function beginChangeCashBoostBankAccountWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowRequest} request BeginChangeCashBoostBankAccountWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginChangeCashBoostBankAccountWorkflowCallback} callback Node-style callback called with the error, if any, and BeginChangeCashBoostBankAccountWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginChangeCashBoostBankAccountWorkflow = function beginChangeCashBoostBankAccountWorkflow(request, callback) {
                    return this.rpcCall(beginChangeCashBoostBankAccountWorkflow, $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest, $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse, request, callback);
                }, "name", { value: "BeginChangeCashBoostBankAccountWorkflow" });

                /**
                 * Calls BeginChangeCashBoostBankAccountWorkflow.
                 * @function beginChangeCashBoostBankAccountWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowRequest} request BeginChangeCashBoostBankAccountWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#verifyBankAccount}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef VerifyBankAccountCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.VerifyBankAccountResponse} [response] VerifyBankAccountResponse
                 */

                /**
                 * Calls VerifyBankAccount.
                 * @function verifyBankAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IVerifyBankAccountRequest} request VerifyBankAccountRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.VerifyBankAccountCallback} callback Node-style callback called with the error, if any, and VerifyBankAccountResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.verifyBankAccount = function verifyBankAccount(request, callback) {
                    return this.rpcCall(verifyBankAccount, $root.visor.clientrpc.VerifyBankAccountRequest, $root.visor.clientrpc.VerifyBankAccountResponse, request, callback);
                }, "name", { value: "VerifyBankAccount" });

                /**
                 * Calls VerifyBankAccount.
                 * @function verifyBankAccount
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IVerifyBankAccountRequest} request VerifyBankAccountRequest message or plain object
                 * @returns {Promise<visor.clientrpc.VerifyBankAccountResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getFootprintClientDecryptionAuthToken}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetFootprintClientDecryptionAuthTokenCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse} [response] GetFootprintClientDecryptionAuthTokenResponse
                 */

                /**
                 * Calls GetFootprintClientDecryptionAuthToken.
                 * @function getFootprintClientDecryptionAuthToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenRequest} request GetFootprintClientDecryptionAuthTokenRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetFootprintClientDecryptionAuthTokenCallback} callback Node-style callback called with the error, if any, and GetFootprintClientDecryptionAuthTokenResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getFootprintClientDecryptionAuthToken = function getFootprintClientDecryptionAuthToken(request, callback) {
                    return this.rpcCall(getFootprintClientDecryptionAuthToken, $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest, $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse, request, callback);
                }, "name", { value: "GetFootprintClientDecryptionAuthToken" });

                /**
                 * Calls GetFootprintClientDecryptionAuthToken.
                 * @function getFootprintClientDecryptionAuthToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenRequest} request GetFootprintClientDecryptionAuthTokenRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createFootprintToken}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateFootprintTokenCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateFootprintTokenResponse} [response] CreateFootprintTokenResponse
                 */

                /**
                 * Calls CreateFootprintToken.
                 * @function createFootprintToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateFootprintTokenRequest} request CreateFootprintTokenRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateFootprintTokenCallback} callback Node-style callback called with the error, if any, and CreateFootprintTokenResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createFootprintToken = function createFootprintToken(request, callback) {
                    return this.rpcCall(createFootprintToken, $root.visor.clientrpc.CreateFootprintTokenRequest, $root.visor.clientrpc.CreateFootprintTokenResponse, request, callback);
                }, "name", { value: "CreateFootprintToken" });

                /**
                 * Calls CreateFootprintToken.
                 * @function createFootprintToken
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateFootprintTokenRequest} request CreateFootprintTokenRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateFootprintTokenResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeCollectFootprintStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteCollectFootprintStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteCollectFootprintStepResponse} [response] CompleteCollectFootprintStepResponse
                 */

                /**
                 * Calls CompleteCollectFootprintStep.
                 * @function completeCollectFootprintStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepRequest} request CompleteCollectFootprintStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteCollectFootprintStepCallback} callback Node-style callback called with the error, if any, and CompleteCollectFootprintStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeCollectFootprintStep = function completeCollectFootprintStep(request, callback) {
                    return this.rpcCall(completeCollectFootprintStep, $root.visor.clientrpc.CompleteCollectFootprintStepRequest, $root.visor.clientrpc.CompleteCollectFootprintStepResponse, request, callback);
                }, "name", { value: "CompleteCollectFootprintStep" });

                /**
                 * Calls CompleteCollectFootprintStep.
                 * @function completeCollectFootprintStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepRequest} request CompleteCollectFootprintStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteCollectFootprintStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getComplianceChecklist}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetComplianceChecklistCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetComplianceChecklistResponse} [response] GetComplianceChecklistResponse
                 */

                /**
                 * Calls GetComplianceChecklist.
                 * @function getComplianceChecklist
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetComplianceChecklistRequest} request GetComplianceChecklistRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetComplianceChecklistCallback} callback Node-style callback called with the error, if any, and GetComplianceChecklistResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getComplianceChecklist = function getComplianceChecklist(request, callback) {
                    return this.rpcCall(getComplianceChecklist, $root.visor.clientrpc.GetComplianceChecklistRequest, $root.visor.clientrpc.GetComplianceChecklistResponse, request, callback);
                }, "name", { value: "GetComplianceChecklist" });

                /**
                 * Calls GetComplianceChecklist.
                 * @function getComplianceChecklist
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetComplianceChecklistRequest} request GetComplianceChecklistRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetComplianceChecklistResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#resolveComplianceConfirmProfile}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ResolveComplianceConfirmProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ResolveConfirmProfileResponse} [response] ResolveConfirmProfileResponse
                 */

                /**
                 * Calls ResolveComplianceConfirmProfile.
                 * @function resolveComplianceConfirmProfile
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResolveConfirmProfileRequest} request ResolveConfirmProfileRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ResolveComplianceConfirmProfileCallback} callback Node-style callback called with the error, if any, and ResolveConfirmProfileResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.resolveComplianceConfirmProfile = function resolveComplianceConfirmProfile(request, callback) {
                    return this.rpcCall(resolveComplianceConfirmProfile, $root.visor.clientrpc.ResolveConfirmProfileRequest, $root.visor.clientrpc.ResolveConfirmProfileResponse, request, callback);
                }, "name", { value: "ResolveComplianceConfirmProfile" });

                /**
                 * Calls ResolveComplianceConfirmProfile.
                 * @function resolveComplianceConfirmProfile
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResolveConfirmProfileRequest} request ResolveConfirmProfileRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ResolveConfirmProfileResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#resolveUserSubmittedAppealReason}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ResolveUserSubmittedAppealReasonCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ResolveUserSubmittedAppealResponse} [response] ResolveUserSubmittedAppealResponse
                 */

                /**
                 * Calls ResolveUserSubmittedAppealReason.
                 * @function resolveUserSubmittedAppealReason
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealRequest} request ResolveUserSubmittedAppealRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ResolveUserSubmittedAppealReasonCallback} callback Node-style callback called with the error, if any, and ResolveUserSubmittedAppealResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.resolveUserSubmittedAppealReason = function resolveUserSubmittedAppealReason(request, callback) {
                    return this.rpcCall(resolveUserSubmittedAppealReason, $root.visor.clientrpc.ResolveUserSubmittedAppealRequest, $root.visor.clientrpc.ResolveUserSubmittedAppealResponse, request, callback);
                }, "name", { value: "ResolveUserSubmittedAppealReason" });

                /**
                 * Calls ResolveUserSubmittedAppealReason.
                 * @function resolveUserSubmittedAppealReason
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealRequest} request ResolveUserSubmittedAppealRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ResolveUserSubmittedAppealResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#resolveFootprintDocumentUpload}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ResolveFootprintDocumentUploadCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ResolveFootprintDocumentUploadResponse} [response] ResolveFootprintDocumentUploadResponse
                 */

                /**
                 * Calls ResolveFootprintDocumentUpload.
                 * @function resolveFootprintDocumentUpload
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadRequest} request ResolveFootprintDocumentUploadRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ResolveFootprintDocumentUploadCallback} callback Node-style callback called with the error, if any, and ResolveFootprintDocumentUploadResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.resolveFootprintDocumentUpload = function resolveFootprintDocumentUpload(request, callback) {
                    return this.rpcCall(resolveFootprintDocumentUpload, $root.visor.clientrpc.ResolveFootprintDocumentUploadRequest, $root.visor.clientrpc.ResolveFootprintDocumentUploadResponse, request, callback);
                }, "name", { value: "ResolveFootprintDocumentUpload" });

                /**
                 * Calls ResolveFootprintDocumentUpload.
                 * @function resolveFootprintDocumentUpload
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadRequest} request ResolveFootprintDocumentUploadRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ResolveFootprintDocumentUploadResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeComplianceChecklistStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteComplianceChecklistStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteComplianceChecklistStepResponse} [response] CompleteComplianceChecklistStepResponse
                 */

                /**
                 * Calls CompleteComplianceChecklistStep.
                 * @function completeComplianceChecklistStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepRequest} request CompleteComplianceChecklistStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteComplianceChecklistStepCallback} callback Node-style callback called with the error, if any, and CompleteComplianceChecklistStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeComplianceChecklistStep = function completeComplianceChecklistStep(request, callback) {
                    return this.rpcCall(completeComplianceChecklistStep, $root.visor.clientrpc.CompleteComplianceChecklistStepRequest, $root.visor.clientrpc.CompleteComplianceChecklistStepResponse, request, callback);
                }, "name", { value: "CompleteComplianceChecklistStep" });

                /**
                 * Calls CompleteComplianceChecklistStep.
                 * @function completeComplianceChecklistStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepRequest} request CompleteComplianceChecklistStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteComplianceChecklistStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginBoostCardProgramSetupWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginBoostCardProgramSetupWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse} [response] BeginBoostCardProgramSetupWorkflowResponse
                 */

                /**
                 * Calls BeginBoostCardProgramSetupWorkflow.
                 * @function beginBoostCardProgramSetupWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowRequest} request BeginBoostCardProgramSetupWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginBoostCardProgramSetupWorkflowCallback} callback Node-style callback called with the error, if any, and BeginBoostCardProgramSetupWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginBoostCardProgramSetupWorkflow = function beginBoostCardProgramSetupWorkflow(request, callback) {
                    return this.rpcCall(beginBoostCardProgramSetupWorkflow, $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest, $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse, request, callback);
                }, "name", { value: "BeginBoostCardProgramSetupWorkflow" });

                /**
                 * Calls BeginBoostCardProgramSetupWorkflow.
                 * @function beginBoostCardProgramSetupWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowRequest} request BeginBoostCardProgramSetupWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeBoostCardQualificationStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteBoostCardQualificationStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteBoostCardQualificationStepResponse} [response] CompleteBoostCardQualificationStepResponse
                 */

                /**
                 * Calls CompleteBoostCardQualificationStep.
                 * @function completeBoostCardQualificationStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepRequest} request CompleteBoostCardQualificationStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteBoostCardQualificationStepCallback} callback Node-style callback called with the error, if any, and CompleteBoostCardQualificationStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeBoostCardQualificationStep = function completeBoostCardQualificationStep(request, callback) {
                    return this.rpcCall(completeBoostCardQualificationStep, $root.visor.clientrpc.CompleteBoostCardQualificationStepRequest, $root.visor.clientrpc.CompleteBoostCardQualificationStepResponse, request, callback);
                }, "name", { value: "CompleteBoostCardQualificationStep" });

                /**
                 * Calls CompleteBoostCardQualificationStep.
                 * @function completeBoostCardQualificationStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepRequest} request CompleteBoostCardQualificationStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteBoostCardQualificationStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeBoostCardCheckoutStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteBoostCardCheckoutStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteBoostCardCheckoutStepResponse} [response] CompleteBoostCardCheckoutStepResponse
                 */

                /**
                 * Calls CompleteBoostCardCheckoutStep.
                 * @function completeBoostCardCheckoutStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepRequest} request CompleteBoostCardCheckoutStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteBoostCardCheckoutStepCallback} callback Node-style callback called with the error, if any, and CompleteBoostCardCheckoutStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeBoostCardCheckoutStep = function completeBoostCardCheckoutStep(request, callback) {
                    return this.rpcCall(completeBoostCardCheckoutStep, $root.visor.clientrpc.CompleteBoostCardCheckoutStepRequest, $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse, request, callback);
                }, "name", { value: "CompleteBoostCardCheckoutStep" });

                /**
                 * Calls CompleteBoostCardCheckoutStep.
                 * @function completeBoostCardCheckoutStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepRequest} request CompleteBoostCardCheckoutStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteBoostCardCheckoutStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getBankingDashboard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetBankingDashboardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetBankingDashboardResponse} [response] GetBankingDashboardResponse
                 */

                /**
                 * Calls GetBankingDashboard.
                 * @function getBankingDashboard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBankingDashboardRequest} request GetBankingDashboardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetBankingDashboardCallback} callback Node-style callback called with the error, if any, and GetBankingDashboardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getBankingDashboard = function getBankingDashboard(request, callback) {
                    return this.rpcCall(getBankingDashboard, $root.visor.clientrpc.GetBankingDashboardRequest, $root.visor.clientrpc.GetBankingDashboardResponse, request, callback);
                }, "name", { value: "GetBankingDashboard" });

                /**
                 * Calls GetBankingDashboard.
                 * @function getBankingDashboard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBankingDashboardRequest} request GetBankingDashboardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetBankingDashboardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCreditDashboard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCreditDashboardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCreditDashboardResponse} [response] GetCreditDashboardResponse
                 */

                /**
                 * Calls GetCreditDashboard.
                 * @function getCreditDashboard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditDashboardRequest} request GetCreditDashboardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCreditDashboardCallback} callback Node-style callback called with the error, if any, and GetCreditDashboardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCreditDashboard = function getCreditDashboard(request, callback) {
                    return this.rpcCall(getCreditDashboard, $root.visor.clientrpc.GetCreditDashboardRequest, $root.visor.clientrpc.GetCreditDashboardResponse, request, callback);
                }, "name", { value: "GetCreditDashboard" });

                /**
                 * Calls GetCreditDashboard.
                 * @function getCreditDashboard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditDashboardRequest} request GetCreditDashboardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCreditDashboardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#requestStatementCredit}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef RequestStatementCreditCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.RequestStatementCreditResponse} [response] RequestStatementCreditResponse
                 */

                /**
                 * Calls RequestStatementCredit.
                 * @function requestStatementCredit
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IRequestStatementCreditRequest} request RequestStatementCreditRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.RequestStatementCreditCallback} callback Node-style callback called with the error, if any, and RequestStatementCreditResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.requestStatementCredit = function requestStatementCredit(request, callback) {
                    return this.rpcCall(requestStatementCredit, $root.visor.clientrpc.RequestStatementCreditRequest, $root.visor.clientrpc.RequestStatementCreditResponse, request, callback);
                }, "name", { value: "RequestStatementCredit" });

                /**
                 * Calls RequestStatementCredit.
                 * @function requestStatementCredit
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IRequestStatementCreditRequest} request RequestStatementCreditRequest message or plain object
                 * @returns {Promise<visor.clientrpc.RequestStatementCreditResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getBoostCardDetails}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetBoostCardDetailsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetBoostCardDetailsResponse} [response] GetBoostCardDetailsResponse
                 */

                /**
                 * Calls GetBoostCardDetails.
                 * @function getBoostCardDetails
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBoostCardDetailsRequest} request GetBoostCardDetailsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetBoostCardDetailsCallback} callback Node-style callback called with the error, if any, and GetBoostCardDetailsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getBoostCardDetails = function getBoostCardDetails(request, callback) {
                    return this.rpcCall(getBoostCardDetails, $root.visor.clientrpc.GetBoostCardDetailsRequest, $root.visor.clientrpc.GetBoostCardDetailsResponse, request, callback);
                }, "name", { value: "GetBoostCardDetails" });

                /**
                 * Calls GetBoostCardDetails.
                 * @function getBoostCardDetails
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBoostCardDetailsRequest} request GetBoostCardDetailsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetBoostCardDetailsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#listBoostCardProgramStatements}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ListBoostCardProgramStatementsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ListBoostCardProgramStatementsResponse} [response] ListBoostCardProgramStatementsResponse
                 */

                /**
                 * Calls ListBoostCardProgramStatements.
                 * @function listBoostCardProgramStatements
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsRequest} request ListBoostCardProgramStatementsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ListBoostCardProgramStatementsCallback} callback Node-style callback called with the error, if any, and ListBoostCardProgramStatementsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.listBoostCardProgramStatements = function listBoostCardProgramStatements(request, callback) {
                    return this.rpcCall(listBoostCardProgramStatements, $root.visor.clientrpc.ListBoostCardProgramStatementsRequest, $root.visor.clientrpc.ListBoostCardProgramStatementsResponse, request, callback);
                }, "name", { value: "ListBoostCardProgramStatements" });

                /**
                 * Calls ListBoostCardProgramStatements.
                 * @function listBoostCardProgramStatements
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsRequest} request ListBoostCardProgramStatementsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ListBoostCardProgramStatementsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginBoostCardActivationWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginBoostCardActivationWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginBoostCardActivationWorkflowResponse} [response] BeginBoostCardActivationWorkflowResponse
                 */

                /**
                 * Calls BeginBoostCardActivationWorkflow.
                 * @function beginBoostCardActivationWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowRequest} request BeginBoostCardActivationWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginBoostCardActivationWorkflowCallback} callback Node-style callback called with the error, if any, and BeginBoostCardActivationWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginBoostCardActivationWorkflow = function beginBoostCardActivationWorkflow(request, callback) {
                    return this.rpcCall(beginBoostCardActivationWorkflow, $root.visor.clientrpc.BeginBoostCardActivationWorkflowRequest, $root.visor.clientrpc.BeginBoostCardActivationWorkflowResponse, request, callback);
                }, "name", { value: "BeginBoostCardActivationWorkflow" });

                /**
                 * Calls BeginBoostCardActivationWorkflow.
                 * @function beginBoostCardActivationWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowRequest} request BeginBoostCardActivationWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginBoostCardActivationWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createBoostCardTransfer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateBoostCardTransferCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateBoostCardTransferResponse} [response] CreateBoostCardTransferResponse
                 */

                /**
                 * Calls CreateBoostCardTransfer.
                 * @function createBoostCardTransfer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateBoostCardTransferRequest} request CreateBoostCardTransferRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateBoostCardTransferCallback} callback Node-style callback called with the error, if any, and CreateBoostCardTransferResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createBoostCardTransfer = function createBoostCardTransfer(request, callback) {
                    return this.rpcCall(createBoostCardTransfer, $root.visor.clientrpc.CreateBoostCardTransferRequest, $root.visor.clientrpc.CreateBoostCardTransferResponse, request, callback);
                }, "name", { value: "CreateBoostCardTransfer" });

                /**
                 * Calls CreateBoostCardTransfer.
                 * @function createBoostCardTransfer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateBoostCardTransferRequest} request CreateBoostCardTransferRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateBoostCardTransferResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#createCreditLineTransfer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CreateCreditLineTransferCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CreateCreditLineTransferResponse} [response] CreateCreditLineTransferResponse
                 */

                /**
                 * Calls CreateCreditLineTransfer.
                 * @function createCreditLineTransfer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateCreditLineTransferRequest} request CreateCreditLineTransferRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CreateCreditLineTransferCallback} callback Node-style callback called with the error, if any, and CreateCreditLineTransferResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.createCreditLineTransfer = function createCreditLineTransfer(request, callback) {
                    return this.rpcCall(createCreditLineTransfer, $root.visor.clientrpc.CreateCreditLineTransferRequest, $root.visor.clientrpc.CreateCreditLineTransferResponse, request, callback);
                }, "name", { value: "CreateCreditLineTransfer" });

                /**
                 * Calls CreateCreditLineTransfer.
                 * @function createCreditLineTransfer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICreateCreditLineTransferRequest} request CreateCreditLineTransferRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CreateCreditLineTransferResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateCreditLineAutoPayProfile}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateCreditLineAutoPayProfileCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateCreditLineAutoPayProfileResponse} [response] UpdateCreditLineAutoPayProfileResponse
                 */

                /**
                 * Calls UpdateCreditLineAutoPayProfile.
                 * @function updateCreditLineAutoPayProfile
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileRequest} request UpdateCreditLineAutoPayProfileRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateCreditLineAutoPayProfileCallback} callback Node-style callback called with the error, if any, and UpdateCreditLineAutoPayProfileResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateCreditLineAutoPayProfile = function updateCreditLineAutoPayProfile(request, callback) {
                    return this.rpcCall(updateCreditLineAutoPayProfile, $root.visor.clientrpc.UpdateCreditLineAutoPayProfileRequest, $root.visor.clientrpc.UpdateCreditLineAutoPayProfileResponse, request, callback);
                }, "name", { value: "UpdateCreditLineAutoPayProfile" });

                /**
                 * Calls UpdateCreditLineAutoPayProfile.
                 * @function updateCreditLineAutoPayProfile
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileRequest} request UpdateCreditLineAutoPayProfileRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateCreditLineAutoPayProfileResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCreditLineTransactions}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCreditLineTransactionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCreditLineTransactionsResponse} [response] GetCreditLineTransactionsResponse
                 */

                /**
                 * Calls GetCreditLineTransactions.
                 * @function getCreditLineTransactions
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditLineTransactionsRequest} request GetCreditLineTransactionsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCreditLineTransactionsCallback} callback Node-style callback called with the error, if any, and GetCreditLineTransactionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCreditLineTransactions = function getCreditLineTransactions(request, callback) {
                    return this.rpcCall(getCreditLineTransactions, $root.visor.clientrpc.GetCreditLineTransactionsRequest, $root.visor.clientrpc.GetCreditLineTransactionsResponse, request, callback);
                }, "name", { value: "GetCreditLineTransactions" });

                /**
                 * Calls GetCreditLineTransactions.
                 * @function getCreditLineTransactions
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditLineTransactionsRequest} request GetCreditLineTransactionsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCreditLineTransactionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getBoostCardCheckoutOffer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetBoostCardCheckoutOfferCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetBoostCardCheckoutOfferResponse} [response] GetBoostCardCheckoutOfferResponse
                 */

                /**
                 * Calls GetBoostCardCheckoutOffer.
                 * @function getBoostCardCheckoutOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferRequest} request GetBoostCardCheckoutOfferRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetBoostCardCheckoutOfferCallback} callback Node-style callback called with the error, if any, and GetBoostCardCheckoutOfferResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getBoostCardCheckoutOffer = function getBoostCardCheckoutOffer(request, callback) {
                    return this.rpcCall(getBoostCardCheckoutOffer, $root.visor.clientrpc.GetBoostCardCheckoutOfferRequest, $root.visor.clientrpc.GetBoostCardCheckoutOfferResponse, request, callback);
                }, "name", { value: "GetBoostCardCheckoutOffer" });

                /**
                 * Calls GetBoostCardCheckoutOffer.
                 * @function getBoostCardCheckoutOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferRequest} request GetBoostCardCheckoutOfferRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetBoostCardCheckoutOfferResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#replaceBoostCard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ReplaceBoostCardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ReplaceBoostCardResponse} [response] ReplaceBoostCardResponse
                 */

                /**
                 * Calls ReplaceBoostCard.
                 * @function replaceBoostCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReplaceBoostCardRequest} request ReplaceBoostCardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ReplaceBoostCardCallback} callback Node-style callback called with the error, if any, and ReplaceBoostCardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.replaceBoostCard = function replaceBoostCard(request, callback) {
                    return this.rpcCall(replaceBoostCard, $root.visor.clientrpc.ReplaceBoostCardRequest, $root.visor.clientrpc.ReplaceBoostCardResponse, request, callback);
                }, "name", { value: "ReplaceBoostCard" });

                /**
                 * Calls ReplaceBoostCard.
                 * @function replaceBoostCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReplaceBoostCardRequest} request ReplaceBoostCardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ReplaceBoostCardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateBoostCard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateBoostCardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateBoostCardResponse} [response] UpdateBoostCardResponse
                 */

                /**
                 * Calls UpdateBoostCard.
                 * @function updateBoostCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateBoostCardRequest} request UpdateBoostCardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateBoostCardCallback} callback Node-style callback called with the error, if any, and UpdateBoostCardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateBoostCard = function updateBoostCard(request, callback) {
                    return this.rpcCall(updateBoostCard, $root.visor.clientrpc.UpdateBoostCardRequest, $root.visor.clientrpc.UpdateBoostCardResponse, request, callback);
                }, "name", { value: "UpdateBoostCard" });

                /**
                 * Calls UpdateBoostCard.
                 * @function updateBoostCard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateBoostCardRequest} request UpdateBoostCardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateBoostCardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getExternalBankAccountBalance}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetExternalBankAccountBalanceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetExternalBankAccountBalanceResponse} [response] GetExternalBankAccountBalanceResponse
                 */

                /**
                 * Calls GetExternalBankAccountBalance.
                 * @function getExternalBankAccountBalance
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceRequest} request GetExternalBankAccountBalanceRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetExternalBankAccountBalanceCallback} callback Node-style callback called with the error, if any, and GetExternalBankAccountBalanceResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getExternalBankAccountBalance = function getExternalBankAccountBalance(request, callback) {
                    return this.rpcCall(getExternalBankAccountBalance, $root.visor.clientrpc.GetExternalBankAccountBalanceRequest, $root.visor.clientrpc.GetExternalBankAccountBalanceResponse, request, callback);
                }, "name", { value: "GetExternalBankAccountBalance" });

                /**
                 * Calls GetExternalBankAccountBalance.
                 * @function getExternalBankAccountBalance
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceRequest} request GetExternalBankAccountBalanceRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetExternalBankAccountBalanceResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getDisputeForms}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetDisputeFormsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetDisputeFormsResponse} [response] GetDisputeFormsResponse
                 */

                /**
                 * Calls GetDisputeForms.
                 * @function getDisputeForms
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetDisputeFormsRequest} request GetDisputeFormsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetDisputeFormsCallback} callback Node-style callback called with the error, if any, and GetDisputeFormsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getDisputeForms = function getDisputeForms(request, callback) {
                    return this.rpcCall(getDisputeForms, $root.visor.clientrpc.GetDisputeFormsRequest, $root.visor.clientrpc.GetDisputeFormsResponse, request, callback);
                }, "name", { value: "GetDisputeForms" });

                /**
                 * Calls GetDisputeForms.
                 * @function getDisputeForms
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetDisputeFormsRequest} request GetDisputeFormsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetDisputeFormsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#submitDispute}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SubmitDisputeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SubmitDisputeResponse} [response] SubmitDisputeResponse
                 */

                /**
                 * Calls SubmitDispute.
                 * @function submitDispute
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISubmitDisputeRequest} request SubmitDisputeRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SubmitDisputeCallback} callback Node-style callback called with the error, if any, and SubmitDisputeResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.submitDispute = function submitDispute(request, callback) {
                    return this.rpcCall(submitDispute, $root.visor.clientrpc.SubmitDisputeRequest, $root.visor.clientrpc.SubmitDisputeResponse, request, callback);
                }, "name", { value: "SubmitDispute" });

                /**
                 * Calls SubmitDispute.
                 * @function submitDispute
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISubmitDisputeRequest} request SubmitDisputeRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SubmitDisputeResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getDisputeStatistics}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetDisputeStatisticsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetDisputeStatisticsResponse} [response] GetDisputeStatisticsResponse
                 */

                /**
                 * Calls GetDisputeStatistics.
                 * @function getDisputeStatistics
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetDisputeStatisticsRequest} request GetDisputeStatisticsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetDisputeStatisticsCallback} callback Node-style callback called with the error, if any, and GetDisputeStatisticsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getDisputeStatistics = function getDisputeStatistics(request, callback) {
                    return this.rpcCall(getDisputeStatistics, $root.visor.clientrpc.GetDisputeStatisticsRequest, $root.visor.clientrpc.GetDisputeStatisticsResponse, request, callback);
                }, "name", { value: "GetDisputeStatistics" });

                /**
                 * Calls GetDisputeStatistics.
                 * @function getDisputeStatistics
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetDisputeStatisticsRequest} request GetDisputeStatisticsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetDisputeStatisticsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#setUpDirectDepositPDF}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SetUpDirectDepositPDFCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SetUpDirectDepositPDFResponse} [response] SetUpDirectDepositPDFResponse
                 */

                /**
                 * Calls SetUpDirectDepositPDF.
                 * @function setUpDirectDepositPDF
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFRequest} request SetUpDirectDepositPDFRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SetUpDirectDepositPDFCallback} callback Node-style callback called with the error, if any, and SetUpDirectDepositPDFResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.setUpDirectDepositPDF = function setUpDirectDepositPDF(request, callback) {
                    return this.rpcCall(setUpDirectDepositPDF, $root.visor.clientrpc.SetUpDirectDepositPDFRequest, $root.visor.clientrpc.SetUpDirectDepositPDFResponse, request, callback);
                }, "name", { value: "SetUpDirectDepositPDF" });

                /**
                 * Calls SetUpDirectDepositPDF.
                 * @function setUpDirectDepositPDF
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFRequest} request SetUpDirectDepositPDFRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SetUpDirectDepositPDFResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeCreditLineEnrollment}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteCreditLineEnrollmentCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteCreditLineEnrollmentResponse} [response] CompleteCreditLineEnrollmentResponse
                 */

                /**
                 * Calls CompleteCreditLineEnrollment.
                 * @function completeCreditLineEnrollment
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentRequest} request CompleteCreditLineEnrollmentRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteCreditLineEnrollmentCallback} callback Node-style callback called with the error, if any, and CompleteCreditLineEnrollmentResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeCreditLineEnrollment = function completeCreditLineEnrollment(request, callback) {
                    return this.rpcCall(completeCreditLineEnrollment, $root.visor.clientrpc.CompleteCreditLineEnrollmentRequest, $root.visor.clientrpc.CompleteCreditLineEnrollmentResponse, request, callback);
                }, "name", { value: "CompleteCreditLineEnrollment" });

                /**
                 * Calls CompleteCreditLineEnrollment.
                 * @function completeCreditLineEnrollment
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentRequest} request CompleteCreditLineEnrollmentRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteCreditLineEnrollmentResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCreditLineLimitIncreaseStatus}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCreditLineLimitIncreaseStatusCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse} [response] GetCreditLineLimitIncreaseStatusResponse
                 */

                /**
                 * Calls GetCreditLineLimitIncreaseStatus.
                 * @function getCreditLineLimitIncreaseStatus
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusRequest} request GetCreditLineLimitIncreaseStatusRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCreditLineLimitIncreaseStatusCallback} callback Node-style callback called with the error, if any, and GetCreditLineLimitIncreaseStatusResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCreditLineLimitIncreaseStatus = function getCreditLineLimitIncreaseStatus(request, callback) {
                    return this.rpcCall(getCreditLineLimitIncreaseStatus, $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest, $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse, request, callback);
                }, "name", { value: "GetCreditLineLimitIncreaseStatus" });

                /**
                 * Calls GetCreditLineLimitIncreaseStatus.
                 * @function getCreditLineLimitIncreaseStatus
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusRequest} request GetCreditLineLimitIncreaseStatusRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#pushBoostCardToWallet}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef PushBoostCardToWalletCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.PushBoostCardToWalletResponse} [response] PushBoostCardToWalletResponse
                 */

                /**
                 * Calls PushBoostCardToWallet.
                 * @function pushBoostCardToWallet
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPushBoostCardToWalletRequest} request PushBoostCardToWalletRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.PushBoostCardToWalletCallback} callback Node-style callback called with the error, if any, and PushBoostCardToWalletResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.pushBoostCardToWallet = function pushBoostCardToWallet(request, callback) {
                    return this.rpcCall(pushBoostCardToWallet, $root.visor.clientrpc.PushBoostCardToWalletRequest, $root.visor.clientrpc.PushBoostCardToWalletResponse, request, callback);
                }, "name", { value: "PushBoostCardToWallet" });

                /**
                 * Calls PushBoostCardToWallet.
                 * @function pushBoostCardToWallet
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPushBoostCardToWalletRequest} request PushBoostCardToWalletRequest message or plain object
                 * @returns {Promise<visor.clientrpc.PushBoostCardToWalletResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCreditLineDebtConsolidationOffer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCreditLineDebtConsolidationOfferCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse} [response] GetCreditLineDebtConsolidationOfferResponse
                 */

                /**
                 * Calls GetCreditLineDebtConsolidationOffer.
                 * @function getCreditLineDebtConsolidationOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferRequest} request GetCreditLineDebtConsolidationOfferRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCreditLineDebtConsolidationOfferCallback} callback Node-style callback called with the error, if any, and GetCreditLineDebtConsolidationOfferResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCreditLineDebtConsolidationOffer = function getCreditLineDebtConsolidationOffer(request, callback) {
                    return this.rpcCall(getCreditLineDebtConsolidationOffer, $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest, $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse, request, callback);
                }, "name", { value: "GetCreditLineDebtConsolidationOffer" });

                /**
                 * Calls GetCreditLineDebtConsolidationOffer.
                 * @function getCreditLineDebtConsolidationOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferRequest} request GetCreditLineDebtConsolidationOfferRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCreditBuilderOffer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCreditBuilderOfferCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCreditBuilderOfferResponse} [response] GetCreditBuilderOfferResponse
                 */

                /**
                 * Calls GetCreditBuilderOffer.
                 * @function getCreditBuilderOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditBuilderOfferRequest} request GetCreditBuilderOfferRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCreditBuilderOfferCallback} callback Node-style callback called with the error, if any, and GetCreditBuilderOfferResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCreditBuilderOffer = function getCreditBuilderOffer(request, callback) {
                    return this.rpcCall(getCreditBuilderOffer, $root.visor.clientrpc.GetCreditBuilderOfferRequest, $root.visor.clientrpc.GetCreditBuilderOfferResponse, request, callback);
                }, "name", { value: "GetCreditBuilderOffer" });

                /**
                 * Calls GetCreditBuilderOffer.
                 * @function getCreditBuilderOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditBuilderOfferRequest} request GetCreditBuilderOfferRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCreditBuilderOfferResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#enrollCreditBuilder}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef EnrollCreditBuilderCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.EnrollCreditBuilderResponse} [response] EnrollCreditBuilderResponse
                 */

                /**
                 * Calls EnrollCreditBuilder.
                 * @function enrollCreditBuilder
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IEnrollCreditBuilderRequest} request EnrollCreditBuilderRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.EnrollCreditBuilderCallback} callback Node-style callback called with the error, if any, and EnrollCreditBuilderResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.enrollCreditBuilder = function enrollCreditBuilder(request, callback) {
                    return this.rpcCall(enrollCreditBuilder, $root.visor.clientrpc.EnrollCreditBuilderRequest, $root.visor.clientrpc.EnrollCreditBuilderResponse, request, callback);
                }, "name", { value: "EnrollCreditBuilder" });

                /**
                 * Calls EnrollCreditBuilder.
                 * @function enrollCreditBuilder
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IEnrollCreditBuilderRequest} request EnrollCreditBuilderRequest message or plain object
                 * @returns {Promise<visor.clientrpc.EnrollCreditBuilderResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getBankingDataSource}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetBankingDataSourceCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetBankingDataSourceResponse} [response] GetBankingDataSourceResponse
                 */

                /**
                 * Calls GetBankingDataSource.
                 * @function getBankingDataSource
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBankingDataSourceRequest} request GetBankingDataSourceRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetBankingDataSourceCallback} callback Node-style callback called with the error, if any, and GetBankingDataSourceResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getBankingDataSource = function getBankingDataSource(request, callback) {
                    return this.rpcCall(getBankingDataSource, $root.visor.clientrpc.GetBankingDataSourceRequest, $root.visor.clientrpc.GetBankingDataSourceResponse, request, callback);
                }, "name", { value: "GetBankingDataSource" });

                /**
                 * Calls GetBankingDataSource.
                 * @function getBankingDataSource
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetBankingDataSourceRequest} request GetBankingDataSourceRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetBankingDataSourceResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#updateMailingAddress}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef UpdateMailingAddressCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.UpdateMailingAddressResponse} [response] UpdateMailingAddressResponse
                 */

                /**
                 * Calls UpdateMailingAddress.
                 * @function updateMailingAddress
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateMailingAddressRequest} request UpdateMailingAddressRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.UpdateMailingAddressCallback} callback Node-style callback called with the error, if any, and UpdateMailingAddressResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.updateMailingAddress = function updateMailingAddress(request, callback) {
                    return this.rpcCall(updateMailingAddress, $root.visor.clientrpc.UpdateMailingAddressRequest, $root.visor.clientrpc.UpdateMailingAddressResponse, request, callback);
                }, "name", { value: "UpdateMailingAddress" });

                /**
                 * Calls UpdateMailingAddress.
                 * @function updateMailingAddress
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IUpdateMailingAddressRequest} request UpdateMailingAddressRequest message or plain object
                 * @returns {Promise<visor.clientrpc.UpdateMailingAddressResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#signLoanAgreement}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SignLoanAgreementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SignLoanAgreementResponse} [response] SignLoanAgreementResponse
                 */

                /**
                 * Calls SignLoanAgreement.
                 * @function signLoanAgreement
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISignLoanAgreementRequest} request SignLoanAgreementRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SignLoanAgreementCallback} callback Node-style callback called with the error, if any, and SignLoanAgreementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.signLoanAgreement = function signLoanAgreement(request, callback) {
                    return this.rpcCall(signLoanAgreement, $root.visor.clientrpc.SignLoanAgreementRequest, $root.visor.clientrpc.SignLoanAgreementResponse, request, callback);
                }, "name", { value: "SignLoanAgreement" });

                /**
                 * Calls SignLoanAgreement.
                 * @function signLoanAgreement
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISignLoanAgreementRequest} request SignLoanAgreementRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SignLoanAgreementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getLoanAgreement}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetLoanAgreementCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetLoanAgreementResponse} [response] GetLoanAgreementResponse
                 */

                /**
                 * Calls GetLoanAgreement.
                 * @function getLoanAgreement
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetLoanAgreementRequest} request GetLoanAgreementRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetLoanAgreementCallback} callback Node-style callback called with the error, if any, and GetLoanAgreementResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getLoanAgreement = function getLoanAgreement(request, callback) {
                    return this.rpcCall(getLoanAgreement, $root.visor.clientrpc.GetLoanAgreementRequest, $root.visor.clientrpc.GetLoanAgreementResponse, request, callback);
                }, "name", { value: "GetLoanAgreement" });

                /**
                 * Calls GetLoanAgreement.
                 * @function getLoanAgreement
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetLoanAgreementRequest} request GetLoanAgreementRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetLoanAgreementResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginCreditBuilderProgramSetupWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginCreditBuilderProgramSetupWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse} [response] BeginCreditBuilderProgramSetupWorkflowResponse
                 */

                /**
                 * Calls BeginCreditBuilderProgramSetupWorkflow.
                 * @function beginCreditBuilderProgramSetupWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowRequest} request BeginCreditBuilderProgramSetupWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginCreditBuilderProgramSetupWorkflowCallback} callback Node-style callback called with the error, if any, and BeginCreditBuilderProgramSetupWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginCreditBuilderProgramSetupWorkflow = function beginCreditBuilderProgramSetupWorkflow(request, callback) {
                    return this.rpcCall(beginCreditBuilderProgramSetupWorkflow, $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest, $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse, request, callback);
                }, "name", { value: "BeginCreditBuilderProgramSetupWorkflow" });

                /**
                 * Calls BeginCreditBuilderProgramSetupWorkflow.
                 * @function beginCreditBuilderProgramSetupWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowRequest} request BeginCreditBuilderProgramSetupWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeCreditBuilderProgramIntroStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteCreditBuilderProgramIntroStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse} [response] CompleteCreditBuilderProgramIntroStepResponse
                 */

                /**
                 * Calls CompleteCreditBuilderProgramIntroStep.
                 * @function completeCreditBuilderProgramIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepRequest} request CompleteCreditBuilderProgramIntroStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteCreditBuilderProgramIntroStepCallback} callback Node-style callback called with the error, if any, and CompleteCreditBuilderProgramIntroStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeCreditBuilderProgramIntroStep = function completeCreditBuilderProgramIntroStep(request, callback) {
                    return this.rpcCall(completeCreditBuilderProgramIntroStep, $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest, $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse, request, callback);
                }, "name", { value: "CompleteCreditBuilderProgramIntroStep" });

                /**
                 * Calls CompleteCreditBuilderProgramIntroStep.
                 * @function completeCreditBuilderProgramIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepRequest} request CompleteCreditBuilderProgramIntroStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCreditBuilderProgramCheckoutOffer}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCreditBuilderProgramCheckoutOfferCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse} [response] GetCreditBuilderProgramCheckoutOfferResponse
                 */

                /**
                 * Calls GetCreditBuilderProgramCheckoutOffer.
                 * @function getCreditBuilderProgramCheckoutOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferRequest} request GetCreditBuilderProgramCheckoutOfferRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCreditBuilderProgramCheckoutOfferCallback} callback Node-style callback called with the error, if any, and GetCreditBuilderProgramCheckoutOfferResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCreditBuilderProgramCheckoutOffer = function getCreditBuilderProgramCheckoutOffer(request, callback) {
                    return this.rpcCall(getCreditBuilderProgramCheckoutOffer, $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest, $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse, request, callback);
                }, "name", { value: "GetCreditBuilderProgramCheckoutOffer" });

                /**
                 * Calls GetCreditBuilderProgramCheckoutOffer.
                 * @function getCreditBuilderProgramCheckoutOffer
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferRequest} request GetCreditBuilderProgramCheckoutOfferRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getZendeskJWT}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetZendeskJWTCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetZendeskJWTResponse} [response] GetZendeskJWTResponse
                 */

                /**
                 * Calls GetZendeskJWT.
                 * @function getZendeskJWT
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetZendeskJWTRequest} request GetZendeskJWTRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetZendeskJWTCallback} callback Node-style callback called with the error, if any, and GetZendeskJWTResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getZendeskJWT = function getZendeskJWT(request, callback) {
                    return this.rpcCall(getZendeskJWT, $root.visor.clientrpc.GetZendeskJWTRequest, $root.visor.clientrpc.GetZendeskJWTResponse, request, callback);
                }, "name", { value: "GetZendeskJWT" });

                /**
                 * Calls GetZendeskJWT.
                 * @function getZendeskJWT
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetZendeskJWTRequest} request GetZendeskJWTRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetZendeskJWTResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getCreditBoostDashboard}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetCreditBoostDashboardCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetCreditBoostDashboardResponse} [response] GetCreditBoostDashboardResponse
                 */

                /**
                 * Calls GetCreditBoostDashboard.
                 * @function getCreditBoostDashboard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditBoostDashboardRequest} request GetCreditBoostDashboardRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetCreditBoostDashboardCallback} callback Node-style callback called with the error, if any, and GetCreditBoostDashboardResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getCreditBoostDashboard = function getCreditBoostDashboard(request, callback) {
                    return this.rpcCall(getCreditBoostDashboard, $root.visor.clientrpc.GetCreditBoostDashboardRequest, $root.visor.clientrpc.GetCreditBoostDashboardResponse, request, callback);
                }, "name", { value: "GetCreditBoostDashboard" });

                /**
                 * Calls GetCreditBoostDashboard.
                 * @function getCreditBoostDashboard
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetCreditBoostDashboardRequest} request GetCreditBoostDashboardRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetCreditBoostDashboardResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#setupCreditBoost}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SetupCreditBoostCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SetupCreditBoostResponse} [response] SetupCreditBoostResponse
                 */

                /**
                 * Calls SetupCreditBoost.
                 * @function setupCreditBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISetupCreditBoostRequest} request SetupCreditBoostRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SetupCreditBoostCallback} callback Node-style callback called with the error, if any, and SetupCreditBoostResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.setupCreditBoost = function setupCreditBoost(request, callback) {
                    return this.rpcCall(setupCreditBoost, $root.visor.clientrpc.SetupCreditBoostRequest, $root.visor.clientrpc.SetupCreditBoostResponse, request, callback);
                }, "name", { value: "SetupCreditBoost" });

                /**
                 * Calls SetupCreditBoost.
                 * @function setupCreditBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISetupCreditBoostRequest} request SetupCreditBoostRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SetupCreditBoostResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#enrollCreditBoost}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef EnrollCreditBoostCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.EnrollCreditBoostResponse} [response] EnrollCreditBoostResponse
                 */

                /**
                 * Calls EnrollCreditBoost.
                 * @function enrollCreditBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IEnrollCreditBoostRequest} request EnrollCreditBoostRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.EnrollCreditBoostCallback} callback Node-style callback called with the error, if any, and EnrollCreditBoostResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.enrollCreditBoost = function enrollCreditBoost(request, callback) {
                    return this.rpcCall(enrollCreditBoost, $root.visor.clientrpc.EnrollCreditBoostRequest, $root.visor.clientrpc.EnrollCreditBoostResponse, request, callback);
                }, "name", { value: "EnrollCreditBoost" });

                /**
                 * Calls EnrollCreditBoost.
                 * @function enrollCreditBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IEnrollCreditBoostRequest} request EnrollCreditBoostRequest message or plain object
                 * @returns {Promise<visor.clientrpc.EnrollCreditBoostResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getHarmonicsAutoInsuranceEligibility}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetHarmonicsAutoInsuranceEligibilityCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse} [response] GetHarmonicsAutoInsuranceEligibilityResponse
                 */

                /**
                 * Calls GetHarmonicsAutoInsuranceEligibility.
                 * @function getHarmonicsAutoInsuranceEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityRequest} request GetHarmonicsAutoInsuranceEligibilityRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetHarmonicsAutoInsuranceEligibilityCallback} callback Node-style callback called with the error, if any, and GetHarmonicsAutoInsuranceEligibilityResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getHarmonicsAutoInsuranceEligibility = function getHarmonicsAutoInsuranceEligibility(request, callback) {
                    return this.rpcCall(getHarmonicsAutoInsuranceEligibility, $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest, $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse, request, callback);
                }, "name", { value: "GetHarmonicsAutoInsuranceEligibility" });

                /**
                 * Calls GetHarmonicsAutoInsuranceEligibility.
                 * @function getHarmonicsAutoInsuranceEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityRequest} request GetHarmonicsAutoInsuranceEligibilityRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#syncGridCardProduct}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SyncGridCardProductCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SyncGridCardProductResponse} [response] SyncGridCardProductResponse
                 */

                /**
                 * Calls SyncGridCardProduct.
                 * @function syncGridCardProduct
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncGridCardProductRequest} request SyncGridCardProductRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SyncGridCardProductCallback} callback Node-style callback called with the error, if any, and SyncGridCardProductResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.syncGridCardProduct = function syncGridCardProduct(request, callback) {
                    return this.rpcCall(syncGridCardProduct, $root.visor.clientrpc.SyncGridCardProductRequest, $root.visor.clientrpc.SyncGridCardProductResponse, request, callback);
                }, "name", { value: "SyncGridCardProduct" });

                /**
                 * Calls SyncGridCardProduct.
                 * @function syncGridCardProduct
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncGridCardProductRequest} request SyncGridCardProductRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SyncGridCardProductResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#syncCreditBoost}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SyncCreditBoostCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SyncCreditBoostResponse} [response] SyncCreditBoostResponse
                 */

                /**
                 * Calls SyncCreditBoost.
                 * @function syncCreditBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncCreditBoostRequest} request SyncCreditBoostRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SyncCreditBoostCallback} callback Node-style callback called with the error, if any, and SyncCreditBoostResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.syncCreditBoost = function syncCreditBoost(request, callback) {
                    return this.rpcCall(syncCreditBoost, $root.visor.clientrpc.SyncCreditBoostRequest, $root.visor.clientrpc.SyncCreditBoostResponse, request, callback);
                }, "name", { value: "SyncCreditBoost" });

                /**
                 * Calls SyncCreditBoost.
                 * @function syncCreditBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncCreditBoostRequest} request SyncCreditBoostRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SyncCreditBoostResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#syncBankAccounts}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SyncBankAccountsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SyncBankAccountsResponse} [response] SyncBankAccountsResponse
                 */

                /**
                 * Calls SyncBankAccounts.
                 * @function syncBankAccounts
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncBankAccountsRequest} request SyncBankAccountsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SyncBankAccountsCallback} callback Node-style callback called with the error, if any, and SyncBankAccountsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.syncBankAccounts = function syncBankAccounts(request, callback) {
                    return this.rpcCall(syncBankAccounts, $root.visor.clientrpc.SyncBankAccountsRequest, $root.visor.clientrpc.SyncBankAccountsResponse, request, callback);
                }, "name", { value: "SyncBankAccounts" });

                /**
                 * Calls SyncBankAccounts.
                 * @function syncBankAccounts
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncBankAccountsRequest} request SyncBankAccountsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SyncBankAccountsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#syncCards}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SyncCardsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SyncCardsResponse} [response] SyncCardsResponse
                 */

                /**
                 * Calls SyncCards.
                 * @function syncCards
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncCardsRequest} request SyncCardsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SyncCardsCallback} callback Node-style callback called with the error, if any, and SyncCardsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.syncCards = function syncCards(request, callback) {
                    return this.rpcCall(syncCards, $root.visor.clientrpc.SyncCardsRequest, $root.visor.clientrpc.SyncCardsResponse, request, callback);
                }, "name", { value: "SyncCards" });

                /**
                 * Calls SyncCards.
                 * @function syncCards
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISyncCardsRequest} request SyncCardsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SyncCardsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#enrollPayBoostAutoDelivery}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef EnrollPayBoostAutoDeliveryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.EnrollPayBoostAutoDeliveryResponse} [response] EnrollPayBoostAutoDeliveryResponse
                 */

                /**
                 * Calls EnrollPayBoostAutoDelivery.
                 * @function enrollPayBoostAutoDelivery
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryRequest} request EnrollPayBoostAutoDeliveryRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.EnrollPayBoostAutoDeliveryCallback} callback Node-style callback called with the error, if any, and EnrollPayBoostAutoDeliveryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.enrollPayBoostAutoDelivery = function enrollPayBoostAutoDelivery(request, callback) {
                    return this.rpcCall(enrollPayBoostAutoDelivery, $root.visor.clientrpc.EnrollPayBoostAutoDeliveryRequest, $root.visor.clientrpc.EnrollPayBoostAutoDeliveryResponse, request, callback);
                }, "name", { value: "EnrollPayBoostAutoDelivery" });

                /**
                 * Calls EnrollPayBoostAutoDelivery.
                 * @function enrollPayBoostAutoDelivery
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryRequest} request EnrollPayBoostAutoDeliveryRequest message or plain object
                 * @returns {Promise<visor.clientrpc.EnrollPayBoostAutoDeliveryResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginUnemploymentApplicationWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginUnemploymentApplicationWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse} [response] BeginUnemploymentApplicationWorkflowResponse
                 */

                /**
                 * Calls BeginUnemploymentApplicationWorkflow.
                 * @function beginUnemploymentApplicationWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowRequest} request BeginUnemploymentApplicationWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginUnemploymentApplicationWorkflowCallback} callback Node-style callback called with the error, if any, and BeginUnemploymentApplicationWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginUnemploymentApplicationWorkflow = function beginUnemploymentApplicationWorkflow(request, callback) {
                    return this.rpcCall(beginUnemploymentApplicationWorkflow, $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest, $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse, request, callback);
                }, "name", { value: "BeginUnemploymentApplicationWorkflow" });

                /**
                 * Calls BeginUnemploymentApplicationWorkflow.
                 * @function beginUnemploymentApplicationWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowRequest} request BeginUnemploymentApplicationWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#payPremium}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef PayPremiumCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.PayPremiumResponse} [response] PayPremiumResponse
                 */

                /**
                 * Calls PayPremium.
                 * @function payPremium
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPayPremiumRequest} request PayPremiumRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.PayPremiumCallback} callback Node-style callback called with the error, if any, and PayPremiumResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.payPremium = function payPremium(request, callback) {
                    return this.rpcCall(payPremium, $root.visor.clientrpc.PayPremiumRequest, $root.visor.clientrpc.PayPremiumResponse, request, callback);
                }, "name", { value: "PayPremium" });

                /**
                 * Calls PayPremium.
                 * @function payPremium
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPayPremiumRequest} request PayPremiumRequest message or plain object
                 * @returns {Promise<visor.clientrpc.PayPremiumResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getIncomeProtectionEligibility}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetIncomeProtectionEligibilityCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetIncomeProtectionEligibilityResponse} [response] GetIncomeProtectionEligibilityResponse
                 */

                /**
                 * Calls GetIncomeProtectionEligibility.
                 * @function getIncomeProtectionEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityRequest} request GetIncomeProtectionEligibilityRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetIncomeProtectionEligibilityCallback} callback Node-style callback called with the error, if any, and GetIncomeProtectionEligibilityResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getIncomeProtectionEligibility = function getIncomeProtectionEligibility(request, callback) {
                    return this.rpcCall(getIncomeProtectionEligibility, $root.visor.clientrpc.GetIncomeProtectionEligibilityRequest, $root.visor.clientrpc.GetIncomeProtectionEligibilityResponse, request, callback);
                }, "name", { value: "GetIncomeProtectionEligibility" });

                /**
                 * Calls GetIncomeProtectionEligibility.
                 * @function getIncomeProtectionEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityRequest} request GetIncomeProtectionEligibilityRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetIncomeProtectionEligibilityResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#reportIncomeProtectionJobLossChange}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ReportIncomeProtectionJobLossChangeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse} [response] ReportIncomeProtectionJobLossChangeResponse
                 */

                /**
                 * Calls ReportIncomeProtectionJobLossChange.
                 * @function reportIncomeProtectionJobLossChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeRequest} request ReportIncomeProtectionJobLossChangeRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ReportIncomeProtectionJobLossChangeCallback} callback Node-style callback called with the error, if any, and ReportIncomeProtectionJobLossChangeResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.reportIncomeProtectionJobLossChange = function reportIncomeProtectionJobLossChange(request, callback) {
                    return this.rpcCall(reportIncomeProtectionJobLossChange, $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest, $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse, request, callback);
                }, "name", { value: "ReportIncomeProtectionJobLossChange" });

                /**
                 * Calls ReportIncomeProtectionJobLossChange.
                 * @function reportIncomeProtectionJobLossChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeRequest} request ReportIncomeProtectionJobLossChangeRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#reportIncomeProtectionRoleChange}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ReportIncomeProtectionRoleChangeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ReportIncomeProtectionRoleChangeResponse} [response] ReportIncomeProtectionRoleChangeResponse
                 */

                /**
                 * Calls ReportIncomeProtectionRoleChange.
                 * @function reportIncomeProtectionRoleChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeRequest} request ReportIncomeProtectionRoleChangeRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ReportIncomeProtectionRoleChangeCallback} callback Node-style callback called with the error, if any, and ReportIncomeProtectionRoleChangeResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.reportIncomeProtectionRoleChange = function reportIncomeProtectionRoleChange(request, callback) {
                    return this.rpcCall(reportIncomeProtectionRoleChange, $root.visor.clientrpc.ReportIncomeProtectionRoleChangeRequest, $root.visor.clientrpc.ReportIncomeProtectionRoleChangeResponse, request, callback);
                }, "name", { value: "ReportIncomeProtectionRoleChange" });

                /**
                 * Calls ReportIncomeProtectionRoleChange.
                 * @function reportIncomeProtectionRoleChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeRequest} request ReportIncomeProtectionRoleChangeRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ReportIncomeProtectionRoleChangeResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#reportIncomeProtectionEmployerChange}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ReportIncomeProtectionEmployerChangeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse} [response] ReportIncomeProtectionEmployerChangeResponse
                 */

                /**
                 * Calls ReportIncomeProtectionEmployerChange.
                 * @function reportIncomeProtectionEmployerChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeRequest} request ReportIncomeProtectionEmployerChangeRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ReportIncomeProtectionEmployerChangeCallback} callback Node-style callback called with the error, if any, and ReportIncomeProtectionEmployerChangeResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.reportIncomeProtectionEmployerChange = function reportIncomeProtectionEmployerChange(request, callback) {
                    return this.rpcCall(reportIncomeProtectionEmployerChange, $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest, $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse, request, callback);
                }, "name", { value: "ReportIncomeProtectionEmployerChange" });

                /**
                 * Calls ReportIncomeProtectionEmployerChange.
                 * @function reportIncomeProtectionEmployerChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeRequest} request ReportIncomeProtectionEmployerChangeRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#reportIncomeProtectionLayoffChange}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ReportIncomeProtectionLayoffChangeCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse} [response] ReportIncomeProtectionLayoffChangeResponse
                 */

                /**
                 * Calls ReportIncomeProtectionLayoffChange.
                 * @function reportIncomeProtectionLayoffChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeRequest} request ReportIncomeProtectionLayoffChangeRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ReportIncomeProtectionLayoffChangeCallback} callback Node-style callback called with the error, if any, and ReportIncomeProtectionLayoffChangeResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.reportIncomeProtectionLayoffChange = function reportIncomeProtectionLayoffChange(request, callback) {
                    return this.rpcCall(reportIncomeProtectionLayoffChange, $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest, $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse, request, callback);
                }, "name", { value: "ReportIncomeProtectionLayoffChange" });

                /**
                 * Calls ReportIncomeProtectionLayoffChange.
                 * @function reportIncomeProtectionLayoffChange
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeRequest} request ReportIncomeProtectionLayoffChangeRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#reopenPolicy}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef ReopenPolicyCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.ReopenPolicyResponse} [response] ReopenPolicyResponse
                 */

                /**
                 * Calls ReopenPolicy.
                 * @function reopenPolicy
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReopenPolicyRequest} request ReopenPolicyRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.ReopenPolicyCallback} callback Node-style callback called with the error, if any, and ReopenPolicyResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.reopenPolicy = function reopenPolicy(request, callback) {
                    return this.rpcCall(reopenPolicy, $root.visor.clientrpc.ReopenPolicyRequest, $root.visor.clientrpc.ReopenPolicyResponse, request, callback);
                }, "name", { value: "ReopenPolicy" });

                /**
                 * Calls ReopenPolicy.
                 * @function reopenPolicy
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IReopenPolicyRequest} request ReopenPolicyRequest message or plain object
                 * @returns {Promise<visor.clientrpc.ReopenPolicyResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getIncomeProtectionHistory}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetIncomeProtectionHistoryCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetIncomeProtectionHistoryResponse} [response] GetIncomeProtectionHistoryResponse
                 */

                /**
                 * Calls GetIncomeProtectionHistory.
                 * @function getIncomeProtectionHistory
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryRequest} request GetIncomeProtectionHistoryRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetIncomeProtectionHistoryCallback} callback Node-style callback called with the error, if any, and GetIncomeProtectionHistoryResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getIncomeProtectionHistory = function getIncomeProtectionHistory(request, callback) {
                    return this.rpcCall(getIncomeProtectionHistory, $root.visor.clientrpc.GetIncomeProtectionHistoryRequest, $root.visor.clientrpc.GetIncomeProtectionHistoryResponse, request, callback);
                }, "name", { value: "GetIncomeProtectionHistory" });

                /**
                 * Calls GetIncomeProtectionHistory.
                 * @function getIncomeProtectionHistory
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryRequest} request GetIncomeProtectionHistoryRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetIncomeProtectionHistoryResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginClaimsApplicationWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginClaimsApplicationWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginClaimsApplicationWorkflowResponse} [response] BeginClaimsApplicationWorkflowResponse
                 */

                /**
                 * Calls BeginClaimsApplicationWorkflow.
                 * @function beginClaimsApplicationWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowRequest} request BeginClaimsApplicationWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginClaimsApplicationWorkflowCallback} callback Node-style callback called with the error, if any, and BeginClaimsApplicationWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginClaimsApplicationWorkflow = function beginClaimsApplicationWorkflow(request, callback) {
                    return this.rpcCall(beginClaimsApplicationWorkflow, $root.visor.clientrpc.BeginClaimsApplicationWorkflowRequest, $root.visor.clientrpc.BeginClaimsApplicationWorkflowResponse, request, callback);
                }, "name", { value: "BeginClaimsApplicationWorkflow" });

                /**
                 * Calls BeginClaimsApplicationWorkflow.
                 * @function beginClaimsApplicationWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowRequest} request BeginClaimsApplicationWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginClaimsApplicationWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeClaimsApplicationIntroStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteClaimsApplicationIntroStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteClaimsApplicationIntroStepResponse} [response] CompleteClaimsApplicationIntroStepResponse
                 */

                /**
                 * Calls CompleteClaimsApplicationIntroStep.
                 * @function completeClaimsApplicationIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepRequest} request CompleteClaimsApplicationIntroStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteClaimsApplicationIntroStepCallback} callback Node-style callback called with the error, if any, and CompleteClaimsApplicationIntroStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeClaimsApplicationIntroStep = function completeClaimsApplicationIntroStep(request, callback) {
                    return this.rpcCall(completeClaimsApplicationIntroStep, $root.visor.clientrpc.CompleteClaimsApplicationIntroStepRequest, $root.visor.clientrpc.CompleteClaimsApplicationIntroStepResponse, request, callback);
                }, "name", { value: "CompleteClaimsApplicationIntroStep" });

                /**
                 * Calls CompleteClaimsApplicationIntroStep.
                 * @function completeClaimsApplicationIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepRequest} request CompleteClaimsApplicationIntroStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteClaimsApplicationIntroStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeClaimsApplicationHowItWorksStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteClaimsApplicationHowItWorksStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse} [response] CompleteClaimsApplicationHowItWorksStepResponse
                 */

                /**
                 * Calls CompleteClaimsApplicationHowItWorksStep.
                 * @function completeClaimsApplicationHowItWorksStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepRequest} request CompleteClaimsApplicationHowItWorksStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteClaimsApplicationHowItWorksStepCallback} callback Node-style callback called with the error, if any, and CompleteClaimsApplicationHowItWorksStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeClaimsApplicationHowItWorksStep = function completeClaimsApplicationHowItWorksStep(request, callback) {
                    return this.rpcCall(completeClaimsApplicationHowItWorksStep, $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest, $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse, request, callback);
                }, "name", { value: "CompleteClaimsApplicationHowItWorksStep" });

                /**
                 * Calls CompleteClaimsApplicationHowItWorksStep.
                 * @function completeClaimsApplicationHowItWorksStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepRequest} request CompleteClaimsApplicationHowItWorksStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeClaimsApplicationDocumentsUploadIntroStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteClaimsApplicationDocumentsUploadIntroStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse} [response] CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 */

                /**
                 * Calls CompleteClaimsApplicationDocumentsUploadIntroStep.
                 * @function completeClaimsApplicationDocumentsUploadIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepRequest} request CompleteClaimsApplicationDocumentsUploadIntroStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteClaimsApplicationDocumentsUploadIntroStepCallback} callback Node-style callback called with the error, if any, and CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeClaimsApplicationDocumentsUploadIntroStep = function completeClaimsApplicationDocumentsUploadIntroStep(request, callback) {
                    return this.rpcCall(completeClaimsApplicationDocumentsUploadIntroStep, $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest, $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse, request, callback);
                }, "name", { value: "CompleteClaimsApplicationDocumentsUploadIntroStep" });

                /**
                 * Calls CompleteClaimsApplicationDocumentsUploadIntroStep.
                 * @function completeClaimsApplicationDocumentsUploadIntroStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepRequest} request CompleteClaimsApplicationDocumentsUploadIntroStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeClaimsApplicationOnboardingDocStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteClaimsApplicationOnboardingDocStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse} [response] CompleteClaimsApplicationOnboardingDocStepResponse
                 */

                /**
                 * Calls CompleteClaimsApplicationOnboardingDocStep.
                 * @function completeClaimsApplicationOnboardingDocStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepRequest} request CompleteClaimsApplicationOnboardingDocStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteClaimsApplicationOnboardingDocStepCallback} callback Node-style callback called with the error, if any, and CompleteClaimsApplicationOnboardingDocStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeClaimsApplicationOnboardingDocStep = function completeClaimsApplicationOnboardingDocStep(request, callback) {
                    return this.rpcCall(completeClaimsApplicationOnboardingDocStep, $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest, $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse, request, callback);
                }, "name", { value: "CompleteClaimsApplicationOnboardingDocStep" });

                /**
                 * Calls CompleteClaimsApplicationOnboardingDocStep.
                 * @function completeClaimsApplicationOnboardingDocStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepRequest} request CompleteClaimsApplicationOnboardingDocStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeClaimsApplicationPayoutInfoStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteClaimsApplicationPayoutInfoStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse} [response] CompleteClaimsApplicationPayoutInfoStepResponse
                 */

                /**
                 * Calls CompleteClaimsApplicationPayoutInfoStep.
                 * @function completeClaimsApplicationPayoutInfoStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepRequest} request CompleteClaimsApplicationPayoutInfoStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteClaimsApplicationPayoutInfoStepCallback} callback Node-style callback called with the error, if any, and CompleteClaimsApplicationPayoutInfoStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeClaimsApplicationPayoutInfoStep = function completeClaimsApplicationPayoutInfoStep(request, callback) {
                    return this.rpcCall(completeClaimsApplicationPayoutInfoStep, $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest, $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse, request, callback);
                }, "name", { value: "CompleteClaimsApplicationPayoutInfoStep" });

                /**
                 * Calls CompleteClaimsApplicationPayoutInfoStep.
                 * @function completeClaimsApplicationPayoutInfoStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepRequest} request CompleteClaimsApplicationPayoutInfoStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeClaimsApplicationPostSubmissionInfoStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteClaimsApplicationPostSubmissionInfoStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse} [response] CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 */

                /**
                 * Calls CompleteClaimsApplicationPostSubmissionInfoStep.
                 * @function completeClaimsApplicationPostSubmissionInfoStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepRequest} request CompleteClaimsApplicationPostSubmissionInfoStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteClaimsApplicationPostSubmissionInfoStepCallback} callback Node-style callback called with the error, if any, and CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeClaimsApplicationPostSubmissionInfoStep = function completeClaimsApplicationPostSubmissionInfoStep(request, callback) {
                    return this.rpcCall(completeClaimsApplicationPostSubmissionInfoStep, $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest, $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse, request, callback);
                }, "name", { value: "CompleteClaimsApplicationPostSubmissionInfoStep" });

                /**
                 * Calls CompleteClaimsApplicationPostSubmissionInfoStep.
                 * @function completeClaimsApplicationPostSubmissionInfoStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepRequest} request CompleteClaimsApplicationPostSubmissionInfoStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getClaimEligibility}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetClaimEligibilityCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetClaimEligibilityResponse} [response] GetClaimEligibilityResponse
                 */

                /**
                 * Calls GetClaimEligibility.
                 * @function getClaimEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetClaimEligibilityRequest} request GetClaimEligibilityRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetClaimEligibilityCallback} callback Node-style callback called with the error, if any, and GetClaimEligibilityResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getClaimEligibility = function getClaimEligibility(request, callback) {
                    return this.rpcCall(getClaimEligibility, $root.visor.clientrpc.GetClaimEligibilityRequest, $root.visor.clientrpc.GetClaimEligibilityResponse, request, callback);
                }, "name", { value: "GetClaimEligibility" });

                /**
                 * Calls GetClaimEligibility.
                 * @function getClaimEligibility
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetClaimEligibilityRequest} request GetClaimEligibilityRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetClaimEligibilityResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeSignatureStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteSignatureStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteSignatureStepResponse} [response] CompleteSignatureStepResponse
                 */

                /**
                 * Calls CompleteSignatureStep.
                 * @function completeSignatureStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSignatureStepRequest} request CompleteSignatureStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteSignatureStepCallback} callback Node-style callback called with the error, if any, and CompleteSignatureStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeSignatureStep = function completeSignatureStep(request, callback) {
                    return this.rpcCall(completeSignatureStep, $root.visor.clientrpc.CompleteSignatureStepRequest, $root.visor.clientrpc.CompleteSignatureStepResponse, request, callback);
                }, "name", { value: "CompleteSignatureStep" });

                /**
                 * Calls CompleteSignatureStep.
                 * @function completeSignatureStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteSignatureStepRequest} request CompleteSignatureStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteSignatureStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeConnectUnemploymentBankAccountStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteConnectUnemploymentBankAccountStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse} [response] CompleteConnectUnemploymentBankAccountStepResponse
                 */

                /**
                 * Calls CompleteConnectUnemploymentBankAccountStep.
                 * @function completeConnectUnemploymentBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepRequest} request CompleteConnectUnemploymentBankAccountStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteConnectUnemploymentBankAccountStepCallback} callback Node-style callback called with the error, if any, and CompleteConnectUnemploymentBankAccountStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeConnectUnemploymentBankAccountStep = function completeConnectUnemploymentBankAccountStep(request, callback) {
                    return this.rpcCall(completeConnectUnemploymentBankAccountStep, $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest, $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse, request, callback);
                }, "name", { value: "CompleteConnectUnemploymentBankAccountStep" });

                /**
                 * Calls CompleteConnectUnemploymentBankAccountStep.
                 * @function completeConnectUnemploymentBankAccountStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepRequest} request CompleteConnectUnemploymentBankAccountStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeConnectUnemploymentDebitCardStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteConnectUnemploymentDebitCardStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse} [response] CompleteConnectUnemploymentDebitCardStepResponse
                 */

                /**
                 * Calls CompleteConnectUnemploymentDebitCardStep.
                 * @function completeConnectUnemploymentDebitCardStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepRequest} request CompleteConnectUnemploymentDebitCardStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteConnectUnemploymentDebitCardStepCallback} callback Node-style callback called with the error, if any, and CompleteConnectUnemploymentDebitCardStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeConnectUnemploymentDebitCardStep = function completeConnectUnemploymentDebitCardStep(request, callback) {
                    return this.rpcCall(completeConnectUnemploymentDebitCardStep, $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest, $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse, request, callback);
                }, "name", { value: "CompleteConnectUnemploymentDebitCardStep" });

                /**
                 * Calls CompleteConnectUnemploymentDebitCardStep.
                 * @function completeConnectUnemploymentDebitCardStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepRequest} request CompleteConnectUnemploymentDebitCardStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getClaimsStatus}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetClaimsStatusCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetClaimsStatusResponse} [response] GetClaimsStatusResponse
                 */

                /**
                 * Calls GetClaimsStatus.
                 * @function getClaimsStatus
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetClaimsStatusRequest} request GetClaimsStatusRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetClaimsStatusCallback} callback Node-style callback called with the error, if any, and GetClaimsStatusResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getClaimsStatus = function getClaimsStatus(request, callback) {
                    return this.rpcCall(getClaimsStatus, $root.visor.clientrpc.GetClaimsStatusRequest, $root.visor.clientrpc.GetClaimsStatusResponse, request, callback);
                }, "name", { value: "GetClaimsStatus" });

                /**
                 * Calls GetClaimsStatus.
                 * @function getClaimsStatus
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetClaimsStatusRequest} request GetClaimsStatusRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetClaimsStatusResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#getPayBoostDocumentUploadDescriptions}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef GetPayBoostDocumentUploadDescriptionsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse} [response] GetPayBoostDocumentUploadDescriptionsResponse
                 */

                /**
                 * Calls GetPayBoostDocumentUploadDescriptions.
                 * @function getPayBoostDocumentUploadDescriptions
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsRequest} request GetPayBoostDocumentUploadDescriptionsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.GetPayBoostDocumentUploadDescriptionsCallback} callback Node-style callback called with the error, if any, and GetPayBoostDocumentUploadDescriptionsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.getPayBoostDocumentUploadDescriptions = function getPayBoostDocumentUploadDescriptions(request, callback) {
                    return this.rpcCall(getPayBoostDocumentUploadDescriptions, $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest, $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse, request, callback);
                }, "name", { value: "GetPayBoostDocumentUploadDescriptions" });

                /**
                 * Calls GetPayBoostDocumentUploadDescriptions.
                 * @function getPayBoostDocumentUploadDescriptions
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsRequest} request GetPayBoostDocumentUploadDescriptionsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#fulfillDocumentUploads}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef FulfillDocumentUploadsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.FulfillDocumentUploadsResponse} [response] FulfillDocumentUploadsResponse
                 */

                /**
                 * Calls FulfillDocumentUploads.
                 * @function fulfillDocumentUploads
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFulfillDocumentUploadsRequest} request FulfillDocumentUploadsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.FulfillDocumentUploadsCallback} callback Node-style callback called with the error, if any, and FulfillDocumentUploadsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.fulfillDocumentUploads = function fulfillDocumentUploads(request, callback) {
                    return this.rpcCall(fulfillDocumentUploads, $root.visor.clientrpc.FulfillDocumentUploadsRequest, $root.visor.clientrpc.FulfillDocumentUploadsResponse, request, callback);
                }, "name", { value: "FulfillDocumentUploads" });

                /**
                 * Calls FulfillDocumentUploads.
                 * @function fulfillDocumentUploads
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IFulfillDocumentUploadsRequest} request FulfillDocumentUploadsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.FulfillDocumentUploadsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#acknowledgeBoost}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef AcknowledgeBoostCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.AcknowledgeBoostResponse} [response] AcknowledgeBoostResponse
                 */

                /**
                 * Calls AcknowledgeBoost.
                 * @function acknowledgeBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IAcknowledgeBoostRequest} request AcknowledgeBoostRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.AcknowledgeBoostCallback} callback Node-style callback called with the error, if any, and AcknowledgeBoostResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.acknowledgeBoost = function acknowledgeBoost(request, callback) {
                    return this.rpcCall(acknowledgeBoost, $root.visor.clientrpc.AcknowledgeBoostRequest, $root.visor.clientrpc.AcknowledgeBoostResponse, request, callback);
                }, "name", { value: "AcknowledgeBoost" });

                /**
                 * Calls AcknowledgeBoost.
                 * @function acknowledgeBoost
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IAcknowledgeBoostRequest} request AcknowledgeBoostRequest message or plain object
                 * @returns {Promise<visor.clientrpc.AcknowledgeBoostResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#printDraftW4Filings}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef PrintDraftW4FilingsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.PrintDraftW4FilingsResponse} [response] PrintDraftW4FilingsResponse
                 */

                /**
                 * Calls PrintDraftW4Filings.
                 * @function printDraftW4Filings
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPrintDraftW4FilingsRequest} request PrintDraftW4FilingsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.PrintDraftW4FilingsCallback} callback Node-style callback called with the error, if any, and PrintDraftW4FilingsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.printDraftW4Filings = function printDraftW4Filings(request, callback) {
                    return this.rpcCall(printDraftW4Filings, $root.visor.clientrpc.PrintDraftW4FilingsRequest, $root.visor.clientrpc.PrintDraftW4FilingsResponse, request, callback);
                }, "name", { value: "PrintDraftW4Filings" });

                /**
                 * Calls PrintDraftW4Filings.
                 * @function printDraftW4Filings
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IPrintDraftW4FilingsRequest} request PrintDraftW4FilingsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.PrintDraftW4FilingsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#signW4Filings}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef SignW4FilingsCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.SignW4FilingsResponse} [response] SignW4FilingsResponse
                 */

                /**
                 * Calls SignW4Filings.
                 * @function signW4Filings
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISignW4FilingsRequest} request SignW4FilingsRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.SignW4FilingsCallback} callback Node-style callback called with the error, if any, and SignW4FilingsResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.signW4Filings = function signW4Filings(request, callback) {
                    return this.rpcCall(signW4Filings, $root.visor.clientrpc.SignW4FilingsRequest, $root.visor.clientrpc.SignW4FilingsResponse, request, callback);
                }, "name", { value: "SignW4Filings" });

                /**
                 * Calls SignW4Filings.
                 * @function signW4Filings
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ISignW4FilingsRequest} request SignW4FilingsRequest message or plain object
                 * @returns {Promise<visor.clientrpc.SignW4FilingsResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#beginPayBoostBoostCardWorkflow}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef BeginPayBoostBoostCardWorkflowCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse} [response] BeginPayBoostBoostCardWorkflowResponse
                 */

                /**
                 * Calls BeginPayBoostBoostCardWorkflow.
                 * @function beginPayBoostBoostCardWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowRequest} request BeginPayBoostBoostCardWorkflowRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.BeginPayBoostBoostCardWorkflowCallback} callback Node-style callback called with the error, if any, and BeginPayBoostBoostCardWorkflowResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.beginPayBoostBoostCardWorkflow = function beginPayBoostBoostCardWorkflow(request, callback) {
                    return this.rpcCall(beginPayBoostBoostCardWorkflow, $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest, $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse, request, callback);
                }, "name", { value: "BeginPayBoostBoostCardWorkflow" });

                /**
                 * Calls BeginPayBoostBoostCardWorkflow.
                 * @function beginPayBoostBoostCardWorkflow
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowRequest} request BeginPayBoostBoostCardWorkflowRequest message or plain object
                 * @returns {Promise<visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completePayBoostCheckout}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompletePayBoostCheckoutCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompletePayBoostCheckoutResponse} [response] CompletePayBoostCheckoutResponse
                 */

                /**
                 * Calls CompletePayBoostCheckout.
                 * @function completePayBoostCheckout
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutRequest} request CompletePayBoostCheckoutRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompletePayBoostCheckoutCallback} callback Node-style callback called with the error, if any, and CompletePayBoostCheckoutResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completePayBoostCheckout = function completePayBoostCheckout(request, callback) {
                    return this.rpcCall(completePayBoostCheckout, $root.visor.clientrpc.CompletePayBoostCheckoutRequest, $root.visor.clientrpc.CompletePayBoostCheckoutResponse, request, callback);
                }, "name", { value: "CompletePayBoostCheckout" });

                /**
                 * Calls CompletePayBoostCheckout.
                 * @function completePayBoostCheckout
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutRequest} request CompletePayBoostCheckoutRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompletePayBoostCheckoutResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completePayBoostCheckoutStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompletePayBoostCheckoutStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompletePayBoostCheckoutStepResponse} [response] CompletePayBoostCheckoutStepResponse
                 */

                /**
                 * Calls CompletePayBoostCheckoutStep.
                 * @function completePayBoostCheckoutStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepRequest} request CompletePayBoostCheckoutStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompletePayBoostCheckoutStepCallback} callback Node-style callback called with the error, if any, and CompletePayBoostCheckoutStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completePayBoostCheckoutStep = function completePayBoostCheckoutStep(request, callback) {
                    return this.rpcCall(completePayBoostCheckoutStep, $root.visor.clientrpc.CompletePayBoostCheckoutStepRequest, $root.visor.clientrpc.CompletePayBoostCheckoutStepResponse, request, callback);
                }, "name", { value: "CompletePayBoostCheckoutStep" });

                /**
                 * Calls CompletePayBoostCheckoutStep.
                 * @function completePayBoostCheckoutStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepRequest} request CompletePayBoostCheckoutStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompletePayBoostCheckoutStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeTemporaryGridCardHowItWorksStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteTemporaryGridCardHowItWorksStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse} [response] CompleteTemporaryGridCardHowItWorksStepResponse
                 */

                /**
                 * Calls CompleteTemporaryGridCardHowItWorksStep.
                 * @function completeTemporaryGridCardHowItWorksStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepRequest} request CompleteTemporaryGridCardHowItWorksStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteTemporaryGridCardHowItWorksStepCallback} callback Node-style callback called with the error, if any, and CompleteTemporaryGridCardHowItWorksStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeTemporaryGridCardHowItWorksStep = function completeTemporaryGridCardHowItWorksStep(request, callback) {
                    return this.rpcCall(completeTemporaryGridCardHowItWorksStep, $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest, $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse, request, callback);
                }, "name", { value: "CompleteTemporaryGridCardHowItWorksStep" });

                /**
                 * Calls CompleteTemporaryGridCardHowItWorksStep.
                 * @function completeTemporaryGridCardHowItWorksStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepRequest} request CompleteTemporaryGridCardHowItWorksStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse>} Promise
                 * @variation 2
                 */

                /**
                 * Callback as used by {@link visor.clientrpc.ClientRPCService#completeTemporaryGridCardMobileWalletStep}.
                 * @memberof visor.clientrpc.ClientRPCService
                 * @typedef CompleteTemporaryGridCardMobileWalletStepCallback
                 * @type {function}
                 * @param {Error|null} error Error, if any
                 * @param {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse} [response] CompleteTemporaryGridCardMobileWalletStepResponse
                 */

                /**
                 * Calls CompleteTemporaryGridCardMobileWalletStep.
                 * @function completeTemporaryGridCardMobileWalletStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepRequest} request CompleteTemporaryGridCardMobileWalletStepRequest message or plain object
                 * @param {visor.clientrpc.ClientRPCService.CompleteTemporaryGridCardMobileWalletStepCallback} callback Node-style callback called with the error, if any, and CompleteTemporaryGridCardMobileWalletStepResponse
                 * @returns {undefined}
                 * @variation 1
                 */
                Object.defineProperty(ClientRPCService.prototype.completeTemporaryGridCardMobileWalletStep = function completeTemporaryGridCardMobileWalletStep(request, callback) {
                    return this.rpcCall(completeTemporaryGridCardMobileWalletStep, $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest, $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse, request, callback);
                }, "name", { value: "CompleteTemporaryGridCardMobileWalletStep" });

                /**
                 * Calls CompleteTemporaryGridCardMobileWalletStep.
                 * @function completeTemporaryGridCardMobileWalletStep
                 * @memberof visor.clientrpc.ClientRPCService
                 * @instance
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepRequest} request CompleteTemporaryGridCardMobileWalletStepRequest message or plain object
                 * @returns {Promise<visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse>} Promise
                 * @variation 2
                 */

                return ClientRPCService;
            })();

            clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse = (function() {

                /**
                 * Properties of a CompleteTemporaryGridCardMobileWalletStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTemporaryGridCardMobileWalletStepResponse
                 */

                /**
                 * Constructs a new CompleteTemporaryGridCardMobileWalletStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTemporaryGridCardMobileWalletStepResponse.
                 * @implements ICompleteTemporaryGridCardMobileWalletStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepResponse=} [properties] Properties to set
                 */
                function CompleteTemporaryGridCardMobileWalletStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteTemporaryGridCardMobileWalletStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse} CompleteTemporaryGridCardMobileWalletStepResponse instance
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.create = function create(properties) {
                    return new CompleteTemporaryGridCardMobileWalletStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardMobileWalletStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepResponse} message CompleteTemporaryGridCardMobileWalletStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardMobileWalletStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepResponse} message CompleteTemporaryGridCardMobileWalletStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTemporaryGridCardMobileWalletStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse} CompleteTemporaryGridCardMobileWalletStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTemporaryGridCardMobileWalletStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse} CompleteTemporaryGridCardMobileWalletStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTemporaryGridCardMobileWalletStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteTemporaryGridCardMobileWalletStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse} CompleteTemporaryGridCardMobileWalletStepResponse
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteTemporaryGridCardMobileWalletStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse} message CompleteTemporaryGridCardMobileWalletStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteTemporaryGridCardMobileWalletStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTemporaryGridCardMobileWalletStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTemporaryGridCardMobileWalletStepResponse;
            })();

            clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest = (function() {

                /**
                 * Properties of a CompleteTemporaryGridCardMobileWalletStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTemporaryGridCardMobileWalletStepRequest
                 * @property {string|null} [stepToken] CompleteTemporaryGridCardMobileWalletStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteTemporaryGridCardMobileWalletStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteTemporaryGridCardMobileWalletStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTemporaryGridCardMobileWalletStepRequest.
                 * @implements ICompleteTemporaryGridCardMobileWalletStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepRequest=} [properties] Properties to set
                 */
                function CompleteTemporaryGridCardMobileWalletStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteTemporaryGridCardMobileWalletStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @instance
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.prototype.stepToken = "";

                /**
                 * CompleteTemporaryGridCardMobileWalletStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @instance
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteTemporaryGridCardMobileWalletStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest} CompleteTemporaryGridCardMobileWalletStepRequest instance
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.create = function create(properties) {
                    return new CompleteTemporaryGridCardMobileWalletStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardMobileWalletStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepRequest} message CompleteTemporaryGridCardMobileWalletStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardMobileWalletStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardMobileWalletStepRequest} message CompleteTemporaryGridCardMobileWalletStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTemporaryGridCardMobileWalletStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest} CompleteTemporaryGridCardMobileWalletStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTemporaryGridCardMobileWalletStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest} CompleteTemporaryGridCardMobileWalletStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTemporaryGridCardMobileWalletStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteTemporaryGridCardMobileWalletStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest} CompleteTemporaryGridCardMobileWalletStepRequest
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteTemporaryGridCardMobileWalletStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest} message CompleteTemporaryGridCardMobileWalletStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteTemporaryGridCardMobileWalletStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardMobileWalletStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTemporaryGridCardMobileWalletStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTemporaryGridCardMobileWalletStepRequest;
            })();

            clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse = (function() {

                /**
                 * Properties of a CompleteTemporaryGridCardHowItWorksStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTemporaryGridCardHowItWorksStepResponse
                 */

                /**
                 * Constructs a new CompleteTemporaryGridCardHowItWorksStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTemporaryGridCardHowItWorksStepResponse.
                 * @implements ICompleteTemporaryGridCardHowItWorksStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepResponse=} [properties] Properties to set
                 */
                function CompleteTemporaryGridCardHowItWorksStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteTemporaryGridCardHowItWorksStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse} CompleteTemporaryGridCardHowItWorksStepResponse instance
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.create = function create(properties) {
                    return new CompleteTemporaryGridCardHowItWorksStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardHowItWorksStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepResponse} message CompleteTemporaryGridCardHowItWorksStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardHowItWorksStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepResponse} message CompleteTemporaryGridCardHowItWorksStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTemporaryGridCardHowItWorksStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse} CompleteTemporaryGridCardHowItWorksStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTemporaryGridCardHowItWorksStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse} CompleteTemporaryGridCardHowItWorksStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTemporaryGridCardHowItWorksStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteTemporaryGridCardHowItWorksStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse} CompleteTemporaryGridCardHowItWorksStepResponse
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteTemporaryGridCardHowItWorksStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse} message CompleteTemporaryGridCardHowItWorksStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteTemporaryGridCardHowItWorksStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTemporaryGridCardHowItWorksStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTemporaryGridCardHowItWorksStepResponse;
            })();

            clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest = (function() {

                /**
                 * Properties of a CompleteTemporaryGridCardHowItWorksStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTemporaryGridCardHowItWorksStepRequest
                 * @property {string|null} [stepToken] CompleteTemporaryGridCardHowItWorksStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteTemporaryGridCardHowItWorksStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteTemporaryGridCardHowItWorksStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTemporaryGridCardHowItWorksStepRequest.
                 * @implements ICompleteTemporaryGridCardHowItWorksStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepRequest=} [properties] Properties to set
                 */
                function CompleteTemporaryGridCardHowItWorksStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteTemporaryGridCardHowItWorksStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @instance
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.prototype.stepToken = "";

                /**
                 * CompleteTemporaryGridCardHowItWorksStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @instance
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteTemporaryGridCardHowItWorksStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest} CompleteTemporaryGridCardHowItWorksStepRequest instance
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.create = function create(properties) {
                    return new CompleteTemporaryGridCardHowItWorksStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardHowItWorksStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepRequest} message CompleteTemporaryGridCardHowItWorksStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTemporaryGridCardHowItWorksStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTemporaryGridCardHowItWorksStepRequest} message CompleteTemporaryGridCardHowItWorksStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTemporaryGridCardHowItWorksStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest} CompleteTemporaryGridCardHowItWorksStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTemporaryGridCardHowItWorksStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest} CompleteTemporaryGridCardHowItWorksStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTemporaryGridCardHowItWorksStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteTemporaryGridCardHowItWorksStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest} CompleteTemporaryGridCardHowItWorksStepRequest
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteTemporaryGridCardHowItWorksStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest} message CompleteTemporaryGridCardHowItWorksStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteTemporaryGridCardHowItWorksStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTemporaryGridCardHowItWorksStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTemporaryGridCardHowItWorksStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTemporaryGridCardHowItWorksStepRequest;
            })();

            clientrpc.CompletePayBoostCheckoutStepResponse = (function() {

                /**
                 * Properties of a CompletePayBoostCheckoutStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompletePayBoostCheckoutStepResponse
                 */

                /**
                 * Constructs a new CompletePayBoostCheckoutStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePayBoostCheckoutStepResponse.
                 * @implements ICompletePayBoostCheckoutStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepResponse=} [properties] Properties to set
                 */
                function CompletePayBoostCheckoutStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompletePayBoostCheckoutStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepResponse} CompletePayBoostCheckoutStepResponse instance
                 */
                CompletePayBoostCheckoutStepResponse.create = function create(properties) {
                    return new CompletePayBoostCheckoutStepResponse(properties);
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutStepResponse message. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepResponse} message CompletePayBoostCheckoutStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepResponse} message CompletePayBoostCheckoutStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePayBoostCheckoutStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepResponse} CompletePayBoostCheckoutStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePayBoostCheckoutStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePayBoostCheckoutStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepResponse} CompletePayBoostCheckoutStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePayBoostCheckoutStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePayBoostCheckoutStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompletePayBoostCheckoutStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepResponse} CompletePayBoostCheckoutStepResponse
                 */
                CompletePayBoostCheckoutStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePayBoostCheckoutStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompletePayBoostCheckoutStepResponse();
                };

                /**
                 * Creates a plain object from a CompletePayBoostCheckoutStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.CompletePayBoostCheckoutStepResponse} message CompletePayBoostCheckoutStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePayBoostCheckoutStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompletePayBoostCheckoutStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePayBoostCheckoutStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePayBoostCheckoutStepResponse;
            })();

            clientrpc.CompletePayBoostCheckoutStepRequest = (function() {

                /**
                 * Properties of a CompletePayBoostCheckoutStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompletePayBoostCheckoutStepRequest
                 * @property {string|null} [stepToken] CompletePayBoostCheckoutStepRequest stepToken
                 * @property {string|null} [workflowToken] CompletePayBoostCheckoutStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompletePayBoostCheckoutStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePayBoostCheckoutStepRequest.
                 * @implements ICompletePayBoostCheckoutStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepRequest=} [properties] Properties to set
                 */
                function CompletePayBoostCheckoutStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompletePayBoostCheckoutStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @instance
                 */
                CompletePayBoostCheckoutStepRequest.prototype.stepToken = "";

                /**
                 * CompletePayBoostCheckoutStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @instance
                 */
                CompletePayBoostCheckoutStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompletePayBoostCheckoutStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepRequest} CompletePayBoostCheckoutStepRequest instance
                 */
                CompletePayBoostCheckoutStepRequest.create = function create(properties) {
                    return new CompletePayBoostCheckoutStepRequest(properties);
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutStepRequest message. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepRequest} message CompletePayBoostCheckoutStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutStepRequest} message CompletePayBoostCheckoutStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePayBoostCheckoutStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepRequest} CompletePayBoostCheckoutStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePayBoostCheckoutStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePayBoostCheckoutStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepRequest} CompletePayBoostCheckoutStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePayBoostCheckoutStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePayBoostCheckoutStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompletePayBoostCheckoutStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutStepRequest} CompletePayBoostCheckoutStepRequest
                 */
                CompletePayBoostCheckoutStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePayBoostCheckoutStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompletePayBoostCheckoutStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompletePayBoostCheckoutStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.CompletePayBoostCheckoutStepRequest} message CompletePayBoostCheckoutStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePayBoostCheckoutStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompletePayBoostCheckoutStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePayBoostCheckoutStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePayBoostCheckoutStepRequest;
            })();

            clientrpc.CompletePayBoostCheckoutRequest = (function() {

                /**
                 * Properties of a CompletePayBoostCheckoutRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompletePayBoostCheckoutRequest
                 */

                /**
                 * Constructs a new CompletePayBoostCheckoutRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePayBoostCheckoutRequest.
                 * @implements ICompletePayBoostCheckoutRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutRequest=} [properties] Properties to set
                 */
                function CompletePayBoostCheckoutRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompletePayBoostCheckoutRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutRequest} CompletePayBoostCheckoutRequest instance
                 */
                CompletePayBoostCheckoutRequest.create = function create(properties) {
                    return new CompletePayBoostCheckoutRequest(properties);
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutRequest message. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutRequest} message CompletePayBoostCheckoutRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutRequest} message CompletePayBoostCheckoutRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePayBoostCheckoutRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutRequest} CompletePayBoostCheckoutRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePayBoostCheckoutRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePayBoostCheckoutRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutRequest} CompletePayBoostCheckoutRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePayBoostCheckoutRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePayBoostCheckoutRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompletePayBoostCheckoutRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutRequest} CompletePayBoostCheckoutRequest
                 */
                CompletePayBoostCheckoutRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePayBoostCheckoutRequest)
                        return object;
                    return new $root.visor.clientrpc.CompletePayBoostCheckoutRequest();
                };

                /**
                 * Creates a plain object from a CompletePayBoostCheckoutRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.CompletePayBoostCheckoutRequest} message CompletePayBoostCheckoutRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePayBoostCheckoutRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompletePayBoostCheckoutRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePayBoostCheckoutRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePayBoostCheckoutRequest;
            })();

            clientrpc.CompletePayBoostCheckoutResponse = (function() {

                /**
                 * Properties of a CompletePayBoostCheckoutResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompletePayBoostCheckoutResponse
                 */

                /**
                 * Constructs a new CompletePayBoostCheckoutResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePayBoostCheckoutResponse.
                 * @implements ICompletePayBoostCheckoutResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutResponse=} [properties] Properties to set
                 */
                function CompletePayBoostCheckoutResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompletePayBoostCheckoutResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutResponse} CompletePayBoostCheckoutResponse instance
                 */
                CompletePayBoostCheckoutResponse.create = function create(properties) {
                    return new CompletePayBoostCheckoutResponse(properties);
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutResponse message. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutResponse} message CompletePayBoostCheckoutResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompletePayBoostCheckoutResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePayBoostCheckoutResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePayBoostCheckoutResponse} message CompletePayBoostCheckoutResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePayBoostCheckoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePayBoostCheckoutResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutResponse} CompletePayBoostCheckoutResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePayBoostCheckoutResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePayBoostCheckoutResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutResponse} CompletePayBoostCheckoutResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePayBoostCheckoutResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePayBoostCheckoutResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePayBoostCheckoutResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompletePayBoostCheckoutResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePayBoostCheckoutResponse} CompletePayBoostCheckoutResponse
                 */
                CompletePayBoostCheckoutResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePayBoostCheckoutResponse)
                        return object;
                    return new $root.visor.clientrpc.CompletePayBoostCheckoutResponse();
                };

                /**
                 * Creates a plain object from a CompletePayBoostCheckoutResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.CompletePayBoostCheckoutResponse} message CompletePayBoostCheckoutResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePayBoostCheckoutResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompletePayBoostCheckoutResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePayBoostCheckoutResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePayBoostCheckoutResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePayBoostCheckoutResponse;
            })();

            clientrpc.BeginPayBoostBoostCardWorkflowRequest = (function() {

                /**
                 * Properties of a BeginPayBoostBoostCardWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginPayBoostBoostCardWorkflowRequest
                 */

                /**
                 * Constructs a new BeginPayBoostBoostCardWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginPayBoostBoostCardWorkflowRequest.
                 * @implements IBeginPayBoostBoostCardWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowRequest=} [properties] Properties to set
                 */
                function BeginPayBoostBoostCardWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginPayBoostBoostCardWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest} BeginPayBoostBoostCardWorkflowRequest instance
                 */
                BeginPayBoostBoostCardWorkflowRequest.create = function create(properties) {
                    return new BeginPayBoostBoostCardWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginPayBoostBoostCardWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowRequest} message BeginPayBoostBoostCardWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayBoostBoostCardWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginPayBoostBoostCardWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowRequest} message BeginPayBoostBoostCardWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayBoostBoostCardWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginPayBoostBoostCardWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest} BeginPayBoostBoostCardWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayBoostBoostCardWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginPayBoostBoostCardWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest} BeginPayBoostBoostCardWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayBoostBoostCardWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginPayBoostBoostCardWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginPayBoostBoostCardWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginPayBoostBoostCardWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest} BeginPayBoostBoostCardWorkflowRequest
                 */
                BeginPayBoostBoostCardWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest)
                        return object;
                    return new $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest();
                };

                /**
                 * Creates a plain object from a BeginPayBoostBoostCardWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest} message BeginPayBoostBoostCardWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginPayBoostBoostCardWorkflowRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginPayBoostBoostCardWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginPayBoostBoostCardWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginPayBoostBoostCardWorkflowRequest;
            })();

            clientrpc.BeginPayBoostBoostCardWorkflowResponse = (function() {

                /**
                 * Properties of a BeginPayBoostBoostCardWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginPayBoostBoostCardWorkflowResponse
                 */

                /**
                 * Constructs a new BeginPayBoostBoostCardWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginPayBoostBoostCardWorkflowResponse.
                 * @implements IBeginPayBoostBoostCardWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowResponse=} [properties] Properties to set
                 */
                function BeginPayBoostBoostCardWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginPayBoostBoostCardWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse} BeginPayBoostBoostCardWorkflowResponse instance
                 */
                BeginPayBoostBoostCardWorkflowResponse.create = function create(properties) {
                    return new BeginPayBoostBoostCardWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginPayBoostBoostCardWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowResponse} message BeginPayBoostBoostCardWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayBoostBoostCardWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginPayBoostBoostCardWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginPayBoostBoostCardWorkflowResponse} message BeginPayBoostBoostCardWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayBoostBoostCardWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginPayBoostBoostCardWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse} BeginPayBoostBoostCardWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayBoostBoostCardWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginPayBoostBoostCardWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse} BeginPayBoostBoostCardWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayBoostBoostCardWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginPayBoostBoostCardWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginPayBoostBoostCardWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginPayBoostBoostCardWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse} BeginPayBoostBoostCardWorkflowResponse
                 */
                BeginPayBoostBoostCardWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginPayBoostBoostCardWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse} message BeginPayBoostBoostCardWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginPayBoostBoostCardWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginPayBoostBoostCardWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginPayBoostBoostCardWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginPayBoostBoostCardWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginPayBoostBoostCardWorkflowResponse;
            })();

            clientrpc.SignW4FilingsRequest = (function() {

                /**
                 * Properties of a SignW4FilingsRequest.
                 * @memberof visor.clientrpc
                 * @interface ISignW4FilingsRequest
                 * @property {string|null} [primaryW4FilingToken] SignW4FilingsRequest primaryW4FilingToken
                 * @property {string|null} [secondaryW4FilingToken] SignW4FilingsRequest secondaryW4FilingToken
                 * @property {string|null} [tertiaryW4FilingToken] SignW4FilingsRequest tertiaryW4FilingToken
                 * @property {Uint8Array|null} [signature] SignW4FilingsRequest signature
                 */

                /**
                 * Constructs a new SignW4FilingsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SignW4FilingsRequest.
                 * @implements ISignW4FilingsRequest
                 * @constructor
                 * @param {visor.clientrpc.ISignW4FilingsRequest=} [properties] Properties to set
                 */
                function SignW4FilingsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SignW4FilingsRequest primaryW4FilingToken.
                 * @member {string} primaryW4FilingToken
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @instance
                 */
                SignW4FilingsRequest.prototype.primaryW4FilingToken = "";

                /**
                 * SignW4FilingsRequest secondaryW4FilingToken.
                 * @member {string} secondaryW4FilingToken
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @instance
                 */
                SignW4FilingsRequest.prototype.secondaryW4FilingToken = "";

                /**
                 * SignW4FilingsRequest tertiaryW4FilingToken.
                 * @member {string} tertiaryW4FilingToken
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @instance
                 */
                SignW4FilingsRequest.prototype.tertiaryW4FilingToken = "";

                /**
                 * SignW4FilingsRequest signature.
                 * @member {Uint8Array} signature
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @instance
                 */
                SignW4FilingsRequest.prototype.signature = $util.newBuffer([]);

                /**
                 * Creates a new SignW4FilingsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.ISignW4FilingsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SignW4FilingsRequest} SignW4FilingsRequest instance
                 */
                SignW4FilingsRequest.create = function create(properties) {
                    return new SignW4FilingsRequest(properties);
                };

                /**
                 * Encodes the specified SignW4FilingsRequest message. Does not implicitly {@link visor.clientrpc.SignW4FilingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.ISignW4FilingsRequest} message SignW4FilingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignW4FilingsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.primaryW4FilingToken != null && message.hasOwnProperty("primaryW4FilingToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.primaryW4FilingToken);
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.signature);
                    if (message.secondaryW4FilingToken != null && message.hasOwnProperty("secondaryW4FilingToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.secondaryW4FilingToken);
                    if (message.tertiaryW4FilingToken != null && message.hasOwnProperty("tertiaryW4FilingToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.tertiaryW4FilingToken);
                    return writer;
                };

                /**
                 * Encodes the specified SignW4FilingsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SignW4FilingsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.ISignW4FilingsRequest} message SignW4FilingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignW4FilingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SignW4FilingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SignW4FilingsRequest} SignW4FilingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignW4FilingsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SignW4FilingsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.primaryW4FilingToken = reader.string();
                            break;
                        case 3:
                            message.secondaryW4FilingToken = reader.string();
                            break;
                        case 4:
                            message.tertiaryW4FilingToken = reader.string();
                            break;
                        case 2:
                            message.signature = reader.bytes();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SignW4FilingsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SignW4FilingsRequest} SignW4FilingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignW4FilingsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SignW4FilingsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SignW4FilingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.primaryW4FilingToken != null && message.hasOwnProperty("primaryW4FilingToken"))
                        if (!$util.isString(message.primaryW4FilingToken))
                            return "primaryW4FilingToken: string expected";
                    if (message.secondaryW4FilingToken != null && message.hasOwnProperty("secondaryW4FilingToken"))
                        if (!$util.isString(message.secondaryW4FilingToken))
                            return "secondaryW4FilingToken: string expected";
                    if (message.tertiaryW4FilingToken != null && message.hasOwnProperty("tertiaryW4FilingToken"))
                        if (!$util.isString(message.tertiaryW4FilingToken))
                            return "tertiaryW4FilingToken: string expected";
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                            return "signature: buffer expected";
                    return null;
                };

                /**
                 * Creates a SignW4FilingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SignW4FilingsRequest} SignW4FilingsRequest
                 */
                SignW4FilingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SignW4FilingsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SignW4FilingsRequest();
                    if (object.primaryW4FilingToken != null)
                        message.primaryW4FilingToken = String(object.primaryW4FilingToken);
                    if (object.secondaryW4FilingToken != null)
                        message.secondaryW4FilingToken = String(object.secondaryW4FilingToken);
                    if (object.tertiaryW4FilingToken != null)
                        message.tertiaryW4FilingToken = String(object.tertiaryW4FilingToken);
                    if (object.signature != null)
                        if (typeof object.signature === "string")
                            $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                        else if (object.signature.length)
                            message.signature = object.signature;
                    return message;
                };

                /**
                 * Creates a plain object from a SignW4FilingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.SignW4FilingsRequest} message SignW4FilingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SignW4FilingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.primaryW4FilingToken = "";
                        if (options.bytes === String)
                            object.signature = "";
                        else {
                            object.signature = [];
                            if (options.bytes !== Array)
                                object.signature = $util.newBuffer(object.signature);
                        }
                        object.secondaryW4FilingToken = "";
                        object.tertiaryW4FilingToken = "";
                    }
                    if (message.primaryW4FilingToken != null && message.hasOwnProperty("primaryW4FilingToken"))
                        object.primaryW4FilingToken = message.primaryW4FilingToken;
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
                    if (message.secondaryW4FilingToken != null && message.hasOwnProperty("secondaryW4FilingToken"))
                        object.secondaryW4FilingToken = message.secondaryW4FilingToken;
                    if (message.tertiaryW4FilingToken != null && message.hasOwnProperty("tertiaryW4FilingToken"))
                        object.tertiaryW4FilingToken = message.tertiaryW4FilingToken;
                    return object;
                };

                /**
                 * Converts this SignW4FilingsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SignW4FilingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SignW4FilingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SignW4FilingsRequest;
            })();

            clientrpc.SignW4FilingsResponse = (function() {

                /**
                 * Properties of a SignW4FilingsResponse.
                 * @memberof visor.clientrpc
                 * @interface ISignW4FilingsResponse
                 */

                /**
                 * Constructs a new SignW4FilingsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SignW4FilingsResponse.
                 * @implements ISignW4FilingsResponse
                 * @constructor
                 * @param {visor.clientrpc.ISignW4FilingsResponse=} [properties] Properties to set
                 */
                function SignW4FilingsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SignW4FilingsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.ISignW4FilingsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SignW4FilingsResponse} SignW4FilingsResponse instance
                 */
                SignW4FilingsResponse.create = function create(properties) {
                    return new SignW4FilingsResponse(properties);
                };

                /**
                 * Encodes the specified SignW4FilingsResponse message. Does not implicitly {@link visor.clientrpc.SignW4FilingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.ISignW4FilingsResponse} message SignW4FilingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignW4FilingsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SignW4FilingsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SignW4FilingsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.ISignW4FilingsResponse} message SignW4FilingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignW4FilingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SignW4FilingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SignW4FilingsResponse} SignW4FilingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignW4FilingsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SignW4FilingsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SignW4FilingsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SignW4FilingsResponse} SignW4FilingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignW4FilingsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SignW4FilingsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SignW4FilingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SignW4FilingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SignW4FilingsResponse} SignW4FilingsResponse
                 */
                SignW4FilingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SignW4FilingsResponse)
                        return object;
                    return new $root.visor.clientrpc.SignW4FilingsResponse();
                };

                /**
                 * Creates a plain object from a SignW4FilingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.SignW4FilingsResponse} message SignW4FilingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SignW4FilingsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SignW4FilingsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SignW4FilingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SignW4FilingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SignW4FilingsResponse;
            })();

            clientrpc.PrintDraftW4FilingsRequest = (function() {

                /**
                 * Properties of a PrintDraftW4FilingsRequest.
                 * @memberof visor.clientrpc
                 * @interface IPrintDraftW4FilingsRequest
                 * @property {string|null} [firstName] PrintDraftW4FilingsRequest firstName
                 * @property {string|null} [lastName] PrintDraftW4FilingsRequest lastName
                 * @property {string|null} [address] PrintDraftW4FilingsRequest address
                 * @property {string|null} [cityStateAndZip] PrintDraftW4FilingsRequest cityStateAndZip
                 * @property {visor.common.encryption.IEncryptedItem|null} [socialSecurityNumber] PrintDraftW4FilingsRequest socialSecurityNumber
                 */

                /**
                 * Constructs a new PrintDraftW4FilingsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PrintDraftW4FilingsRequest.
                 * @implements IPrintDraftW4FilingsRequest
                 * @constructor
                 * @param {visor.clientrpc.IPrintDraftW4FilingsRequest=} [properties] Properties to set
                 */
                function PrintDraftW4FilingsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PrintDraftW4FilingsRequest firstName.
                 * @member {string} firstName
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @instance
                 */
                PrintDraftW4FilingsRequest.prototype.firstName = "";

                /**
                 * PrintDraftW4FilingsRequest lastName.
                 * @member {string} lastName
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @instance
                 */
                PrintDraftW4FilingsRequest.prototype.lastName = "";

                /**
                 * PrintDraftW4FilingsRequest address.
                 * @member {string} address
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @instance
                 */
                PrintDraftW4FilingsRequest.prototype.address = "";

                /**
                 * PrintDraftW4FilingsRequest cityStateAndZip.
                 * @member {string} cityStateAndZip
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @instance
                 */
                PrintDraftW4FilingsRequest.prototype.cityStateAndZip = "";

                /**
                 * PrintDraftW4FilingsRequest socialSecurityNumber.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} socialSecurityNumber
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @instance
                 */
                PrintDraftW4FilingsRequest.prototype.socialSecurityNumber = null;

                /**
                 * Creates a new PrintDraftW4FilingsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.IPrintDraftW4FilingsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.PrintDraftW4FilingsRequest} PrintDraftW4FilingsRequest instance
                 */
                PrintDraftW4FilingsRequest.create = function create(properties) {
                    return new PrintDraftW4FilingsRequest(properties);
                };

                /**
                 * Encodes the specified PrintDraftW4FilingsRequest message. Does not implicitly {@link visor.clientrpc.PrintDraftW4FilingsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.IPrintDraftW4FilingsRequest} message PrintDraftW4FilingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PrintDraftW4FilingsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
                    if (message.address != null && message.hasOwnProperty("address"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.address);
                    if (message.cityStateAndZip != null && message.hasOwnProperty("cityStateAndZip"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.cityStateAndZip);
                    if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.socialSecurityNumber, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PrintDraftW4FilingsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.PrintDraftW4FilingsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.IPrintDraftW4FilingsRequest} message PrintDraftW4FilingsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PrintDraftW4FilingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PrintDraftW4FilingsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PrintDraftW4FilingsRequest} PrintDraftW4FilingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PrintDraftW4FilingsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PrintDraftW4FilingsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            message.firstName = reader.string();
                            break;
                        case 3:
                            message.lastName = reader.string();
                            break;
                        case 4:
                            message.address = reader.string();
                            break;
                        case 5:
                            message.cityStateAndZip = reader.string();
                            break;
                        case 6:
                            message.socialSecurityNumber = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PrintDraftW4FilingsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PrintDraftW4FilingsRequest} PrintDraftW4FilingsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PrintDraftW4FilingsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PrintDraftW4FilingsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PrintDraftW4FilingsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.cityStateAndZip != null && message.hasOwnProperty("cityStateAndZip"))
                        if (!$util.isString(message.cityStateAndZip))
                            return "cityStateAndZip: string expected";
                    if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.socialSecurityNumber);
                        if (error)
                            return "socialSecurityNumber." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PrintDraftW4FilingsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PrintDraftW4FilingsRequest} PrintDraftW4FilingsRequest
                 */
                PrintDraftW4FilingsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PrintDraftW4FilingsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.PrintDraftW4FilingsRequest();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.cityStateAndZip != null)
                        message.cityStateAndZip = String(object.cityStateAndZip);
                    if (object.socialSecurityNumber != null) {
                        if (typeof object.socialSecurityNumber !== "object")
                            throw TypeError(".visor.clientrpc.PrintDraftW4FilingsRequest.socialSecurityNumber: object expected");
                        message.socialSecurityNumber = $root.visor.common.encryption.EncryptedItem.fromObject(object.socialSecurityNumber);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PrintDraftW4FilingsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @static
                 * @param {visor.clientrpc.PrintDraftW4FilingsRequest} message PrintDraftW4FilingsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PrintDraftW4FilingsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.address = "";
                        object.cityStateAndZip = "";
                        object.socialSecurityNumber = null;
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.cityStateAndZip != null && message.hasOwnProperty("cityStateAndZip"))
                        object.cityStateAndZip = message.cityStateAndZip;
                    if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                        object.socialSecurityNumber = $root.visor.common.encryption.EncryptedItem.toObject(message.socialSecurityNumber, options);
                    return object;
                };

                /**
                 * Converts this PrintDraftW4FilingsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PrintDraftW4FilingsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PrintDraftW4FilingsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PrintDraftW4FilingsRequest;
            })();

            clientrpc.PrintDraftW4FilingsResponse = (function() {

                /**
                 * Properties of a PrintDraftW4FilingsResponse.
                 * @memberof visor.clientrpc
                 * @interface IPrintDraftW4FilingsResponse
                 */

                /**
                 * Constructs a new PrintDraftW4FilingsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PrintDraftW4FilingsResponse.
                 * @implements IPrintDraftW4FilingsResponse
                 * @constructor
                 * @param {visor.clientrpc.IPrintDraftW4FilingsResponse=} [properties] Properties to set
                 */
                function PrintDraftW4FilingsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new PrintDraftW4FilingsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.IPrintDraftW4FilingsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.PrintDraftW4FilingsResponse} PrintDraftW4FilingsResponse instance
                 */
                PrintDraftW4FilingsResponse.create = function create(properties) {
                    return new PrintDraftW4FilingsResponse(properties);
                };

                /**
                 * Encodes the specified PrintDraftW4FilingsResponse message. Does not implicitly {@link visor.clientrpc.PrintDraftW4FilingsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.IPrintDraftW4FilingsResponse} message PrintDraftW4FilingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PrintDraftW4FilingsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified PrintDraftW4FilingsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.PrintDraftW4FilingsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.IPrintDraftW4FilingsResponse} message PrintDraftW4FilingsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PrintDraftW4FilingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PrintDraftW4FilingsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PrintDraftW4FilingsResponse} PrintDraftW4FilingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PrintDraftW4FilingsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PrintDraftW4FilingsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PrintDraftW4FilingsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PrintDraftW4FilingsResponse} PrintDraftW4FilingsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PrintDraftW4FilingsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PrintDraftW4FilingsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PrintDraftW4FilingsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PrintDraftW4FilingsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PrintDraftW4FilingsResponse} PrintDraftW4FilingsResponse
                 */
                PrintDraftW4FilingsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PrintDraftW4FilingsResponse)
                        return object;
                    return new $root.visor.clientrpc.PrintDraftW4FilingsResponse();
                };

                /**
                 * Creates a plain object from a PrintDraftW4FilingsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @static
                 * @param {visor.clientrpc.PrintDraftW4FilingsResponse} message PrintDraftW4FilingsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PrintDraftW4FilingsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PrintDraftW4FilingsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PrintDraftW4FilingsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PrintDraftW4FilingsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PrintDraftW4FilingsResponse;
            })();

            clientrpc.AcknowledgeBoostRequest = (function() {

                /**
                 * Properties of an AcknowledgeBoostRequest.
                 * @memberof visor.clientrpc
                 * @interface IAcknowledgeBoostRequest
                 */

                /**
                 * Constructs a new AcknowledgeBoostRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an AcknowledgeBoostRequest.
                 * @implements IAcknowledgeBoostRequest
                 * @constructor
                 * @param {visor.clientrpc.IAcknowledgeBoostRequest=} [properties] Properties to set
                 */
                function AcknowledgeBoostRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new AcknowledgeBoostRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.AcknowledgeBoostRequest} AcknowledgeBoostRequest instance
                 */
                AcknowledgeBoostRequest.create = function create(properties) {
                    return new AcknowledgeBoostRequest(properties);
                };

                /**
                 * Encodes the specified AcknowledgeBoostRequest message. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostRequest} message AcknowledgeBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified AcknowledgeBoostRequest message, length delimited. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostRequest} message AcknowledgeBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AcknowledgeBoostRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.AcknowledgeBoostRequest} AcknowledgeBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AcknowledgeBoostRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AcknowledgeBoostRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.AcknowledgeBoostRequest} AcknowledgeBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AcknowledgeBoostRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AcknowledgeBoostRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an AcknowledgeBoostRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.AcknowledgeBoostRequest} AcknowledgeBoostRequest
                 */
                AcknowledgeBoostRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.AcknowledgeBoostRequest)
                        return object;
                    return new $root.visor.clientrpc.AcknowledgeBoostRequest();
                };

                /**
                 * Creates a plain object from an AcknowledgeBoostRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @static
                 * @param {visor.clientrpc.AcknowledgeBoostRequest} message AcknowledgeBoostRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AcknowledgeBoostRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AcknowledgeBoostRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.AcknowledgeBoostRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AcknowledgeBoostRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AcknowledgeBoostRequest;
            })();

            clientrpc.AcknowledgeBoostResponse = (function() {

                /**
                 * Properties of an AcknowledgeBoostResponse.
                 * @memberof visor.clientrpc
                 * @interface IAcknowledgeBoostResponse
                 */

                /**
                 * Constructs a new AcknowledgeBoostResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an AcknowledgeBoostResponse.
                 * @implements IAcknowledgeBoostResponse
                 * @constructor
                 * @param {visor.clientrpc.IAcknowledgeBoostResponse=} [properties] Properties to set
                 */
                function AcknowledgeBoostResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new AcknowledgeBoostResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.AcknowledgeBoostResponse} AcknowledgeBoostResponse instance
                 */
                AcknowledgeBoostResponse.create = function create(properties) {
                    return new AcknowledgeBoostResponse(properties);
                };

                /**
                 * Encodes the specified AcknowledgeBoostResponse message. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostResponse} message AcknowledgeBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified AcknowledgeBoostResponse message, length delimited. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostResponse} message AcknowledgeBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AcknowledgeBoostResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.AcknowledgeBoostResponse} AcknowledgeBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AcknowledgeBoostResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AcknowledgeBoostResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.AcknowledgeBoostResponse} AcknowledgeBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AcknowledgeBoostResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AcknowledgeBoostResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an AcknowledgeBoostResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.AcknowledgeBoostResponse} AcknowledgeBoostResponse
                 */
                AcknowledgeBoostResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.AcknowledgeBoostResponse)
                        return object;
                    return new $root.visor.clientrpc.AcknowledgeBoostResponse();
                };

                /**
                 * Creates a plain object from an AcknowledgeBoostResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @static
                 * @param {visor.clientrpc.AcknowledgeBoostResponse} message AcknowledgeBoostResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AcknowledgeBoostResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AcknowledgeBoostResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.AcknowledgeBoostResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AcknowledgeBoostResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AcknowledgeBoostResponse;
            })();

            clientrpc.FulfillDocumentUploadsRequest = (function() {

                /**
                 * Properties of a FulfillDocumentUploadsRequest.
                 * @memberof visor.clientrpc
                 * @interface IFulfillDocumentUploadsRequest
                 * @property {Object.<string,visor.clientrpc.FulfillDocumentUploadsRequest.IDocumentUpload>|null} [uploadsByDocumentType] FulfillDocumentUploadsRequest uploadsByDocumentType
                 */

                /**
                 * Constructs a new FulfillDocumentUploadsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FulfillDocumentUploadsRequest.
                 * @implements IFulfillDocumentUploadsRequest
                 * @constructor
                 * @param {visor.clientrpc.IFulfillDocumentUploadsRequest=} [properties] Properties to set
                 */
                function FulfillDocumentUploadsRequest(properties) {
                    this.uploadsByDocumentType = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FulfillDocumentUploadsRequest uploadsByDocumentType.
                 * @member {Object.<string,visor.clientrpc.FulfillDocumentUploadsRequest.IDocumentUpload>} uploadsByDocumentType
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @instance
                 */
                FulfillDocumentUploadsRequest.prototype.uploadsByDocumentType = $util.emptyObject;

                /**
                 * Creates a new FulfillDocumentUploadsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentUploadsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.FulfillDocumentUploadsRequest} FulfillDocumentUploadsRequest instance
                 */
                FulfillDocumentUploadsRequest.create = function create(properties) {
                    return new FulfillDocumentUploadsRequest(properties);
                };

                /**
                 * Encodes the specified FulfillDocumentUploadsRequest message. Does not implicitly {@link visor.clientrpc.FulfillDocumentUploadsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentUploadsRequest} message FulfillDocumentUploadsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentUploadsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uploadsByDocumentType != null && message.hasOwnProperty("uploadsByDocumentType"))
                        for (var keys = Object.keys(message.uploadsByDocumentType), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.encode(message.uploadsByDocumentType[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };

                /**
                 * Encodes the specified FulfillDocumentUploadsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.FulfillDocumentUploadsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentUploadsRequest} message FulfillDocumentUploadsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentUploadsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FulfillDocumentUploadsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FulfillDocumentUploadsRequest} FulfillDocumentUploadsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentUploadsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FulfillDocumentUploadsRequest(), key;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            reader.skip().pos++;
                            if (message.uploadsByDocumentType === $util.emptyObject)
                                message.uploadsByDocumentType = {};
                            key = reader.string();
                            reader.pos++;
                            message.uploadsByDocumentType[key] = $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FulfillDocumentUploadsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FulfillDocumentUploadsRequest} FulfillDocumentUploadsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentUploadsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FulfillDocumentUploadsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FulfillDocumentUploadsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.uploadsByDocumentType != null && message.hasOwnProperty("uploadsByDocumentType")) {
                        if (!$util.isObject(message.uploadsByDocumentType))
                            return "uploadsByDocumentType: object expected";
                        var key = Object.keys(message.uploadsByDocumentType);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.verify(message.uploadsByDocumentType[key[i]]);
                            if (error)
                                return "uploadsByDocumentType." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a FulfillDocumentUploadsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FulfillDocumentUploadsRequest} FulfillDocumentUploadsRequest
                 */
                FulfillDocumentUploadsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FulfillDocumentUploadsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.FulfillDocumentUploadsRequest();
                    if (object.uploadsByDocumentType) {
                        if (typeof object.uploadsByDocumentType !== "object")
                            throw TypeError(".visor.clientrpc.FulfillDocumentUploadsRequest.uploadsByDocumentType: object expected");
                        message.uploadsByDocumentType = {};
                        for (var keys = Object.keys(object.uploadsByDocumentType), i = 0; i < keys.length; ++i) {
                            if (typeof object.uploadsByDocumentType[keys[i]] !== "object")
                                throw TypeError(".visor.clientrpc.FulfillDocumentUploadsRequest.uploadsByDocumentType: object expected");
                            message.uploadsByDocumentType[keys[i]] = $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.fromObject(object.uploadsByDocumentType[keys[i]]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a FulfillDocumentUploadsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @static
                 * @param {visor.clientrpc.FulfillDocumentUploadsRequest} message FulfillDocumentUploadsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FulfillDocumentUploadsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.uploadsByDocumentType = {};
                    var keys2;
                    if (message.uploadsByDocumentType && (keys2 = Object.keys(message.uploadsByDocumentType)).length) {
                        object.uploadsByDocumentType = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.uploadsByDocumentType[keys2[j]] = $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.toObject(message.uploadsByDocumentType[keys2[j]], options);
                    }
                    return object;
                };

                /**
                 * Converts this FulfillDocumentUploadsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FulfillDocumentUploadsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                FulfillDocumentUploadsRequest.DocumentUpload = (function() {

                    /**
                     * Properties of a DocumentUpload.
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                     * @interface IDocumentUpload
                     * @property {Array.<string>|null} [fileTokens] DocumentUpload fileTokens
                     */

                    /**
                     * Constructs a new DocumentUpload.
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest
                     * @classdesc Represents a DocumentUpload.
                     * @implements IDocumentUpload
                     * @constructor
                     * @param {visor.clientrpc.FulfillDocumentUploadsRequest.IDocumentUpload=} [properties] Properties to set
                     */
                    function DocumentUpload(properties) {
                        this.fileTokens = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DocumentUpload fileTokens.
                     * @member {Array.<string>} fileTokens
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @instance
                     */
                    DocumentUpload.prototype.fileTokens = $util.emptyArray;

                    /**
                     * Creates a new DocumentUpload instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {visor.clientrpc.FulfillDocumentUploadsRequest.IDocumentUpload=} [properties] Properties to set
                     * @returns {visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload} DocumentUpload instance
                     */
                    DocumentUpload.create = function create(properties) {
                        return new DocumentUpload(properties);
                    };

                    /**
                     * Encodes the specified DocumentUpload message. Does not implicitly {@link visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {visor.clientrpc.FulfillDocumentUploadsRequest.IDocumentUpload} message DocumentUpload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DocumentUpload.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.fileTokens != null && message.fileTokens.length)
                            for (var i = 0; i < message.fileTokens.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileTokens[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified DocumentUpload message, length delimited. Does not implicitly {@link visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {visor.clientrpc.FulfillDocumentUploadsRequest.IDocumentUpload} message DocumentUpload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DocumentUpload.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DocumentUpload message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload} DocumentUpload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DocumentUpload.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.fileTokens && message.fileTokens.length))
                                    message.fileTokens = [];
                                message.fileTokens.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DocumentUpload message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload} DocumentUpload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DocumentUpload.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DocumentUpload message.
                     * @function verify
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DocumentUpload.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.fileTokens != null && message.hasOwnProperty("fileTokens")) {
                            if (!Array.isArray(message.fileTokens))
                                return "fileTokens: array expected";
                            for (var i = 0; i < message.fileTokens.length; ++i)
                                if (!$util.isString(message.fileTokens[i]))
                                    return "fileTokens: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a DocumentUpload message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload} DocumentUpload
                     */
                    DocumentUpload.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload)
                            return object;
                        var message = new $root.visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload();
                        if (object.fileTokens) {
                            if (!Array.isArray(object.fileTokens))
                                throw TypeError(".visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload.fileTokens: array expected");
                            message.fileTokens = [];
                            for (var i = 0; i < object.fileTokens.length; ++i)
                                message.fileTokens[i] = String(object.fileTokens[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DocumentUpload message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @static
                     * @param {visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload} message DocumentUpload
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DocumentUpload.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.fileTokens = [];
                        if (message.fileTokens && message.fileTokens.length) {
                            object.fileTokens = [];
                            for (var j = 0; j < message.fileTokens.length; ++j)
                                object.fileTokens[j] = message.fileTokens[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this DocumentUpload to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.FulfillDocumentUploadsRequest.DocumentUpload
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DocumentUpload.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DocumentUpload;
                })();

                return FulfillDocumentUploadsRequest;
            })();

            clientrpc.FulfillDocumentUploadsResponse = (function() {

                /**
                 * Properties of a FulfillDocumentUploadsResponse.
                 * @memberof visor.clientrpc
                 * @interface IFulfillDocumentUploadsResponse
                 */

                /**
                 * Constructs a new FulfillDocumentUploadsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FulfillDocumentUploadsResponse.
                 * @implements IFulfillDocumentUploadsResponse
                 * @constructor
                 * @param {visor.clientrpc.IFulfillDocumentUploadsResponse=} [properties] Properties to set
                 */
                function FulfillDocumentUploadsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new FulfillDocumentUploadsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentUploadsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.FulfillDocumentUploadsResponse} FulfillDocumentUploadsResponse instance
                 */
                FulfillDocumentUploadsResponse.create = function create(properties) {
                    return new FulfillDocumentUploadsResponse(properties);
                };

                /**
                 * Encodes the specified FulfillDocumentUploadsResponse message. Does not implicitly {@link visor.clientrpc.FulfillDocumentUploadsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentUploadsResponse} message FulfillDocumentUploadsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentUploadsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified FulfillDocumentUploadsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.FulfillDocumentUploadsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentUploadsResponse} message FulfillDocumentUploadsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentUploadsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FulfillDocumentUploadsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FulfillDocumentUploadsResponse} FulfillDocumentUploadsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentUploadsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FulfillDocumentUploadsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FulfillDocumentUploadsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FulfillDocumentUploadsResponse} FulfillDocumentUploadsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentUploadsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FulfillDocumentUploadsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FulfillDocumentUploadsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a FulfillDocumentUploadsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FulfillDocumentUploadsResponse} FulfillDocumentUploadsResponse
                 */
                FulfillDocumentUploadsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FulfillDocumentUploadsResponse)
                        return object;
                    return new $root.visor.clientrpc.FulfillDocumentUploadsResponse();
                };

                /**
                 * Creates a plain object from a FulfillDocumentUploadsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @static
                 * @param {visor.clientrpc.FulfillDocumentUploadsResponse} message FulfillDocumentUploadsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FulfillDocumentUploadsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this FulfillDocumentUploadsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FulfillDocumentUploadsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FulfillDocumentUploadsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FulfillDocumentUploadsResponse;
            })();

            clientrpc.GetPayBoostDocumentUploadDescriptionsRequest = (function() {

                /**
                 * Properties of a GetPayBoostDocumentUploadDescriptionsRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetPayBoostDocumentUploadDescriptionsRequest
                 */

                /**
                 * Constructs a new GetPayBoostDocumentUploadDescriptionsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetPayBoostDocumentUploadDescriptionsRequest.
                 * @implements IGetPayBoostDocumentUploadDescriptionsRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsRequest=} [properties] Properties to set
                 */
                function GetPayBoostDocumentUploadDescriptionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetPayBoostDocumentUploadDescriptionsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest} GetPayBoostDocumentUploadDescriptionsRequest instance
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.create = function create(properties) {
                    return new GetPayBoostDocumentUploadDescriptionsRequest(properties);
                };

                /**
                 * Encodes the specified GetPayBoostDocumentUploadDescriptionsRequest message. Does not implicitly {@link visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsRequest} message GetPayBoostDocumentUploadDescriptionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetPayBoostDocumentUploadDescriptionsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsRequest} message GetPayBoostDocumentUploadDescriptionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetPayBoostDocumentUploadDescriptionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest} GetPayBoostDocumentUploadDescriptionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetPayBoostDocumentUploadDescriptionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest} GetPayBoostDocumentUploadDescriptionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetPayBoostDocumentUploadDescriptionsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetPayBoostDocumentUploadDescriptionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest} GetPayBoostDocumentUploadDescriptionsRequest
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest)
                        return object;
                    return new $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest();
                };

                /**
                 * Creates a plain object from a GetPayBoostDocumentUploadDescriptionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @static
                 * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest} message GetPayBoostDocumentUploadDescriptionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetPayBoostDocumentUploadDescriptionsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayBoostDocumentUploadDescriptionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetPayBoostDocumentUploadDescriptionsRequest;
            })();

            /**
             * DocumentUploadType enum.
             * @name visor.clientrpc.DocumentUploadType
             * @enum {string}
             * @property {number} DOCUMENT_UPLOAD_TYPE_INVALID=0 DOCUMENT_UPLOAD_TYPE_INVALID value
             * @property {number} DOCUMENT_UPLOAD_TYPE_INVESTMENT_STATEMENT=1 DOCUMENT_UPLOAD_TYPE_INVESTMENT_STATEMENT value
             * @property {number} DOCUMENT_UPLOAD_TYPE_RETIREMENT_STATEMENT=2 DOCUMENT_UPLOAD_TYPE_RETIREMENT_STATEMENT value
             * @property {number} DOCUMENT_UPLOAD_TYPE_LAST_YEARS_TAX_RETURN=3 DOCUMENT_UPLOAD_TYPE_LAST_YEARS_TAX_RETURN value
             */
            clientrpc.DocumentUploadType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DOCUMENT_UPLOAD_TYPE_INVALID"] = 0;
                values[valuesById[1] = "DOCUMENT_UPLOAD_TYPE_INVESTMENT_STATEMENT"] = 1;
                values[valuesById[2] = "DOCUMENT_UPLOAD_TYPE_RETIREMENT_STATEMENT"] = 2;
                values[valuesById[3] = "DOCUMENT_UPLOAD_TYPE_LAST_YEARS_TAX_RETURN"] = 3;
                return values;
            })();

            clientrpc.GetPayBoostDocumentUploadDescriptionsResponse = (function() {

                /**
                 * Properties of a GetPayBoostDocumentUploadDescriptionsResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetPayBoostDocumentUploadDescriptionsResponse
                 * @property {Object.<string,visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.IDocumentDescription>|null} [descriptionsByDocumentType] GetPayBoostDocumentUploadDescriptionsResponse descriptionsByDocumentType
                 */

                /**
                 * Constructs a new GetPayBoostDocumentUploadDescriptionsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetPayBoostDocumentUploadDescriptionsResponse.
                 * @implements IGetPayBoostDocumentUploadDescriptionsResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsResponse=} [properties] Properties to set
                 */
                function GetPayBoostDocumentUploadDescriptionsResponse(properties) {
                    this.descriptionsByDocumentType = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetPayBoostDocumentUploadDescriptionsResponse descriptionsByDocumentType.
                 * @member {Object.<string,visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.IDocumentDescription>} descriptionsByDocumentType
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @instance
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.prototype.descriptionsByDocumentType = $util.emptyObject;

                /**
                 * Creates a new GetPayBoostDocumentUploadDescriptionsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse} GetPayBoostDocumentUploadDescriptionsResponse instance
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.create = function create(properties) {
                    return new GetPayBoostDocumentUploadDescriptionsResponse(properties);
                };

                /**
                 * Encodes the specified GetPayBoostDocumentUploadDescriptionsResponse message. Does not implicitly {@link visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsResponse} message GetPayBoostDocumentUploadDescriptionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.descriptionsByDocumentType != null && message.hasOwnProperty("descriptionsByDocumentType"))
                        for (var keys = Object.keys(message.descriptionsByDocumentType), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.encode(message.descriptionsByDocumentType[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };

                /**
                 * Encodes the specified GetPayBoostDocumentUploadDescriptionsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetPayBoostDocumentUploadDescriptionsResponse} message GetPayBoostDocumentUploadDescriptionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetPayBoostDocumentUploadDescriptionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse} GetPayBoostDocumentUploadDescriptionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse(), key;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            reader.skip().pos++;
                            if (message.descriptionsByDocumentType === $util.emptyObject)
                                message.descriptionsByDocumentType = {};
                            key = reader.string();
                            reader.pos++;
                            message.descriptionsByDocumentType[key] = $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetPayBoostDocumentUploadDescriptionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse} GetPayBoostDocumentUploadDescriptionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetPayBoostDocumentUploadDescriptionsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.descriptionsByDocumentType != null && message.hasOwnProperty("descriptionsByDocumentType")) {
                        if (!$util.isObject(message.descriptionsByDocumentType))
                            return "descriptionsByDocumentType: object expected";
                        var key = Object.keys(message.descriptionsByDocumentType);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.verify(message.descriptionsByDocumentType[key[i]]);
                            if (error)
                                return "descriptionsByDocumentType." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetPayBoostDocumentUploadDescriptionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse} GetPayBoostDocumentUploadDescriptionsResponse
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse();
                    if (object.descriptionsByDocumentType) {
                        if (typeof object.descriptionsByDocumentType !== "object")
                            throw TypeError(".visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.descriptionsByDocumentType: object expected");
                        message.descriptionsByDocumentType = {};
                        for (var keys = Object.keys(object.descriptionsByDocumentType), i = 0; i < keys.length; ++i) {
                            if (typeof object.descriptionsByDocumentType[keys[i]] !== "object")
                                throw TypeError(".visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.descriptionsByDocumentType: object expected");
                            message.descriptionsByDocumentType[keys[i]] = $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.fromObject(object.descriptionsByDocumentType[keys[i]]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetPayBoostDocumentUploadDescriptionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @static
                 * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse} message GetPayBoostDocumentUploadDescriptionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.descriptionsByDocumentType = {};
                    var keys2;
                    if (message.descriptionsByDocumentType && (keys2 = Object.keys(message.descriptionsByDocumentType)).length) {
                        object.descriptionsByDocumentType = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.descriptionsByDocumentType[keys2[j]] = $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.toObject(message.descriptionsByDocumentType[keys2[j]], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetPayBoostDocumentUploadDescriptionsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPayBoostDocumentUploadDescriptionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription = (function() {

                    /**
                     * Properties of a DocumentDescription.
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                     * @interface IDocumentDescription
                     * @property {visor.common.strings.ILocalizedString|null} [prompt] DocumentDescription prompt
                     * @property {visor.common.strings.ILocalizedString|null} [title] DocumentDescription title
                     * @property {boolean|null} [isSkippable] DocumentDescription isSkippable
                     * @property {visor.common.strings.ILocalizedString|null} [helpContentTitle] DocumentDescription helpContentTitle
                     * @property {visor.common.strings.ILocalizedString|null} [helpContentMessage] DocumentDescription helpContentMessage
                     */

                    /**
                     * Constructs a new DocumentDescription.
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse
                     * @classdesc Represents a DocumentDescription.
                     * @implements IDocumentDescription
                     * @constructor
                     * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.IDocumentDescription=} [properties] Properties to set
                     */
                    function DocumentDescription(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DocumentDescription prompt.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} prompt
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @instance
                     */
                    DocumentDescription.prototype.prompt = null;

                    /**
                     * DocumentDescription title.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} title
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @instance
                     */
                    DocumentDescription.prototype.title = null;

                    /**
                     * DocumentDescription isSkippable.
                     * @member {boolean} isSkippable
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @instance
                     */
                    DocumentDescription.prototype.isSkippable = false;

                    /**
                     * DocumentDescription helpContentTitle.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} helpContentTitle
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @instance
                     */
                    DocumentDescription.prototype.helpContentTitle = null;

                    /**
                     * DocumentDescription helpContentMessage.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} helpContentMessage
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @instance
                     */
                    DocumentDescription.prototype.helpContentMessage = null;

                    /**
                     * Creates a new DocumentDescription instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.IDocumentDescription=} [properties] Properties to set
                     * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription} DocumentDescription instance
                     */
                    DocumentDescription.create = function create(properties) {
                        return new DocumentDescription(properties);
                    };

                    /**
                     * Encodes the specified DocumentDescription message. Does not implicitly {@link visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.IDocumentDescription} message DocumentDescription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DocumentDescription.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.prompt != null && message.hasOwnProperty("prompt"))
                            $root.visor.common.strings.LocalizedString.encode(message.prompt, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.title != null && message.hasOwnProperty("title"))
                            $root.visor.common.strings.LocalizedString.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.isSkippable != null && message.hasOwnProperty("isSkippable"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isSkippable);
                        if (message.helpContentTitle != null && message.hasOwnProperty("helpContentTitle"))
                            $root.visor.common.strings.LocalizedString.encode(message.helpContentTitle, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.helpContentMessage != null && message.hasOwnProperty("helpContentMessage"))
                            $root.visor.common.strings.LocalizedString.encode(message.helpContentMessage, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified DocumentDescription message, length delimited. Does not implicitly {@link visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.IDocumentDescription} message DocumentDescription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DocumentDescription.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DocumentDescription message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription} DocumentDescription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DocumentDescription.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.prompt = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.title = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.isSkippable = reader.bool();
                                break;
                            case 4:
                                message.helpContentTitle = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.helpContentMessage = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DocumentDescription message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription} DocumentDescription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DocumentDescription.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DocumentDescription message.
                     * @function verify
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DocumentDescription.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.prompt != null && message.hasOwnProperty("prompt")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.prompt);
                            if (error)
                                return "prompt." + error;
                        }
                        if (message.title != null && message.hasOwnProperty("title")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.title);
                            if (error)
                                return "title." + error;
                        }
                        if (message.isSkippable != null && message.hasOwnProperty("isSkippable"))
                            if (typeof message.isSkippable !== "boolean")
                                return "isSkippable: boolean expected";
                        if (message.helpContentTitle != null && message.hasOwnProperty("helpContentTitle")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.helpContentTitle);
                            if (error)
                                return "helpContentTitle." + error;
                        }
                        if (message.helpContentMessage != null && message.hasOwnProperty("helpContentMessage")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.helpContentMessage);
                            if (error)
                                return "helpContentMessage." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a DocumentDescription message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription} DocumentDescription
                     */
                    DocumentDescription.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription)
                            return object;
                        var message = new $root.visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription();
                        if (object.prompt != null) {
                            if (typeof object.prompt !== "object")
                                throw TypeError(".visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.prompt: object expected");
                            message.prompt = $root.visor.common.strings.LocalizedString.fromObject(object.prompt);
                        }
                        if (object.title != null) {
                            if (typeof object.title !== "object")
                                throw TypeError(".visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.title: object expected");
                            message.title = $root.visor.common.strings.LocalizedString.fromObject(object.title);
                        }
                        if (object.isSkippable != null)
                            message.isSkippable = Boolean(object.isSkippable);
                        if (object.helpContentTitle != null) {
                            if (typeof object.helpContentTitle !== "object")
                                throw TypeError(".visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.helpContentTitle: object expected");
                            message.helpContentTitle = $root.visor.common.strings.LocalizedString.fromObject(object.helpContentTitle);
                        }
                        if (object.helpContentMessage != null) {
                            if (typeof object.helpContentMessage !== "object")
                                throw TypeError(".visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription.helpContentMessage: object expected");
                            message.helpContentMessage = $root.visor.common.strings.LocalizedString.fromObject(object.helpContentMessage);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DocumentDescription message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @static
                     * @param {visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription} message DocumentDescription
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DocumentDescription.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.prompt = null;
                            object.title = null;
                            object.isSkippable = false;
                            object.helpContentTitle = null;
                            object.helpContentMessage = null;
                        }
                        if (message.prompt != null && message.hasOwnProperty("prompt"))
                            object.prompt = $root.visor.common.strings.LocalizedString.toObject(message.prompt, options);
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = $root.visor.common.strings.LocalizedString.toObject(message.title, options);
                        if (message.isSkippable != null && message.hasOwnProperty("isSkippable"))
                            object.isSkippable = message.isSkippable;
                        if (message.helpContentTitle != null && message.hasOwnProperty("helpContentTitle"))
                            object.helpContentTitle = $root.visor.common.strings.LocalizedString.toObject(message.helpContentTitle, options);
                        if (message.helpContentMessage != null && message.hasOwnProperty("helpContentMessage"))
                            object.helpContentMessage = $root.visor.common.strings.LocalizedString.toObject(message.helpContentMessage, options);
                        return object;
                    };

                    /**
                     * Converts this DocumentDescription to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GetPayBoostDocumentUploadDescriptionsResponse.DocumentDescription
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DocumentDescription.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DocumentDescription;
                })();

                return GetPayBoostDocumentUploadDescriptionsResponse;
            })();

            clientrpc.GetClaimsStatusRequest = (function() {

                /**
                 * Properties of a GetClaimsStatusRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetClaimsStatusRequest
                 * @property {string|null} [testFixture] GetClaimsStatusRequest testFixture
                 */

                /**
                 * Constructs a new GetClaimsStatusRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetClaimsStatusRequest.
                 * @implements IGetClaimsStatusRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetClaimsStatusRequest=} [properties] Properties to set
                 */
                function GetClaimsStatusRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetClaimsStatusRequest testFixture.
                 * @member {string} testFixture
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @instance
                 */
                GetClaimsStatusRequest.prototype.testFixture = "";

                /**
                 * Creates a new GetClaimsStatusRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetClaimsStatusRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetClaimsStatusRequest} GetClaimsStatusRequest instance
                 */
                GetClaimsStatusRequest.create = function create(properties) {
                    return new GetClaimsStatusRequest(properties);
                };

                /**
                 * Encodes the specified GetClaimsStatusRequest message. Does not implicitly {@link visor.clientrpc.GetClaimsStatusRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetClaimsStatusRequest} message GetClaimsStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimsStatusRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.testFixture != null && message.hasOwnProperty("testFixture"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.testFixture);
                    return writer;
                };

                /**
                 * Encodes the specified GetClaimsStatusRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetClaimsStatusRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetClaimsStatusRequest} message GetClaimsStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimsStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetClaimsStatusRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetClaimsStatusRequest} GetClaimsStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimsStatusRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetClaimsStatusRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.testFixture = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetClaimsStatusRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetClaimsStatusRequest} GetClaimsStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimsStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetClaimsStatusRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetClaimsStatusRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.testFixture != null && message.hasOwnProperty("testFixture"))
                        if (!$util.isString(message.testFixture))
                            return "testFixture: string expected";
                    return null;
                };

                /**
                 * Creates a GetClaimsStatusRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetClaimsStatusRequest} GetClaimsStatusRequest
                 */
                GetClaimsStatusRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetClaimsStatusRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetClaimsStatusRequest();
                    if (object.testFixture != null)
                        message.testFixture = String(object.testFixture);
                    return message;
                };

                /**
                 * Creates a plain object from a GetClaimsStatusRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @static
                 * @param {visor.clientrpc.GetClaimsStatusRequest} message GetClaimsStatusRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetClaimsStatusRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.testFixture = "";
                    if (message.testFixture != null && message.hasOwnProperty("testFixture"))
                        object.testFixture = message.testFixture;
                    return object;
                };

                /**
                 * Converts this GetClaimsStatusRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetClaimsStatusRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetClaimsStatusRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetClaimsStatusRequest;
            })();

            clientrpc.GetClaimsStatusResponse = (function() {

                /**
                 * Properties of a GetClaimsStatusResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetClaimsStatusResponse
                 * @property {Array.<visor.clientrpc.IClaimsStatus>|null} [statuses] GetClaimsStatusResponse statuses
                 */

                /**
                 * Constructs a new GetClaimsStatusResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetClaimsStatusResponse.
                 * @implements IGetClaimsStatusResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetClaimsStatusResponse=} [properties] Properties to set
                 */
                function GetClaimsStatusResponse(properties) {
                    this.statuses = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetClaimsStatusResponse statuses.
                 * @member {Array.<visor.clientrpc.IClaimsStatus>} statuses
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @instance
                 */
                GetClaimsStatusResponse.prototype.statuses = $util.emptyArray;

                /**
                 * Creates a new GetClaimsStatusResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetClaimsStatusResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetClaimsStatusResponse} GetClaimsStatusResponse instance
                 */
                GetClaimsStatusResponse.create = function create(properties) {
                    return new GetClaimsStatusResponse(properties);
                };

                /**
                 * Encodes the specified GetClaimsStatusResponse message. Does not implicitly {@link visor.clientrpc.GetClaimsStatusResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetClaimsStatusResponse} message GetClaimsStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimsStatusResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.statuses != null && message.statuses.length)
                        for (var i = 0; i < message.statuses.length; ++i)
                            $root.visor.clientrpc.ClaimsStatus.encode(message.statuses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetClaimsStatusResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetClaimsStatusResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetClaimsStatusResponse} message GetClaimsStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimsStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetClaimsStatusResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetClaimsStatusResponse} GetClaimsStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimsStatusResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetClaimsStatusResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.statuses && message.statuses.length))
                                message.statuses = [];
                            message.statuses.push($root.visor.clientrpc.ClaimsStatus.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetClaimsStatusResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetClaimsStatusResponse} GetClaimsStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimsStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetClaimsStatusResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetClaimsStatusResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.statuses != null && message.hasOwnProperty("statuses")) {
                        if (!Array.isArray(message.statuses))
                            return "statuses: array expected";
                        for (var i = 0; i < message.statuses.length; ++i) {
                            var error = $root.visor.clientrpc.ClaimsStatus.verify(message.statuses[i]);
                            if (error)
                                return "statuses." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetClaimsStatusResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetClaimsStatusResponse} GetClaimsStatusResponse
                 */
                GetClaimsStatusResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetClaimsStatusResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetClaimsStatusResponse();
                    if (object.statuses) {
                        if (!Array.isArray(object.statuses))
                            throw TypeError(".visor.clientrpc.GetClaimsStatusResponse.statuses: array expected");
                        message.statuses = [];
                        for (var i = 0; i < object.statuses.length; ++i) {
                            if (typeof object.statuses[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetClaimsStatusResponse.statuses: object expected");
                            message.statuses[i] = $root.visor.clientrpc.ClaimsStatus.fromObject(object.statuses[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetClaimsStatusResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @static
                 * @param {visor.clientrpc.GetClaimsStatusResponse} message GetClaimsStatusResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetClaimsStatusResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.statuses = [];
                    if (message.statuses && message.statuses.length) {
                        object.statuses = [];
                        for (var j = 0; j < message.statuses.length; ++j)
                            object.statuses[j] = $root.visor.clientrpc.ClaimsStatus.toObject(message.statuses[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetClaimsStatusResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetClaimsStatusResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetClaimsStatusResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetClaimsStatusResponse;
            })();

            clientrpc.ClaimsStatus = (function() {

                /**
                 * Properties of a ClaimsStatus.
                 * @memberof visor.clientrpc
                 * @interface IClaimsStatus
                 * @property {visor.common.strings.ILocalizedString|null} [title] ClaimsStatus title
                 * @property {visor.common.time.ILocalDate|null} [date] ClaimsStatus date
                 * @property {visor.clientrpc.IIncomeProtectionHistoryEvent|null} [event] ClaimsStatus event
                 */

                /**
                 * Constructs a new ClaimsStatus.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ClaimsStatus.
                 * @implements IClaimsStatus
                 * @constructor
                 * @param {visor.clientrpc.IClaimsStatus=} [properties] Properties to set
                 */
                function ClaimsStatus(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ClaimsStatus title.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} title
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @instance
                 */
                ClaimsStatus.prototype.title = null;

                /**
                 * ClaimsStatus date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @instance
                 */
                ClaimsStatus.prototype.date = null;

                /**
                 * ClaimsStatus event.
                 * @member {visor.clientrpc.IIncomeProtectionHistoryEvent|null|undefined} event
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @instance
                 */
                ClaimsStatus.prototype.event = null;

                /**
                 * Creates a new ClaimsStatus instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {visor.clientrpc.IClaimsStatus=} [properties] Properties to set
                 * @returns {visor.clientrpc.ClaimsStatus} ClaimsStatus instance
                 */
                ClaimsStatus.create = function create(properties) {
                    return new ClaimsStatus(properties);
                };

                /**
                 * Encodes the specified ClaimsStatus message. Does not implicitly {@link visor.clientrpc.ClaimsStatus.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {visor.clientrpc.IClaimsStatus} message ClaimsStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimsStatus.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && message.hasOwnProperty("title"))
                        $root.visor.common.strings.LocalizedString.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.event != null && message.hasOwnProperty("event"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.encode(message.event, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ClaimsStatus message, length delimited. Does not implicitly {@link visor.clientrpc.ClaimsStatus.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {visor.clientrpc.IClaimsStatus} message ClaimsStatus message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClaimsStatus.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClaimsStatus message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ClaimsStatus} ClaimsStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimsStatus.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ClaimsStatus();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.title = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.event = $root.visor.clientrpc.IncomeProtectionHistoryEvent.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClaimsStatus message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ClaimsStatus} ClaimsStatus
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClaimsStatus.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClaimsStatus message.
                 * @function verify
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClaimsStatus.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    if (message.event != null && message.hasOwnProperty("event")) {
                        var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.verify(message.event);
                        if (error)
                            return "event." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ClaimsStatus message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ClaimsStatus} ClaimsStatus
                 */
                ClaimsStatus.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ClaimsStatus)
                        return object;
                    var message = new $root.visor.clientrpc.ClaimsStatus();
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".visor.clientrpc.ClaimsStatus.title: object expected");
                        message.title = $root.visor.common.strings.LocalizedString.fromObject(object.title);
                    }
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.ClaimsStatus.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    if (object.event != null) {
                        if (typeof object.event !== "object")
                            throw TypeError(".visor.clientrpc.ClaimsStatus.event: object expected");
                        message.event = $root.visor.clientrpc.IncomeProtectionHistoryEvent.fromObject(object.event);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ClaimsStatus message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @static
                 * @param {visor.clientrpc.ClaimsStatus} message ClaimsStatus
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClaimsStatus.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.title = null;
                        object.date = null;
                        object.event = null;
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.visor.common.strings.LocalizedString.toObject(message.title, options);
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    if (message.event != null && message.hasOwnProperty("event"))
                        object.event = $root.visor.clientrpc.IncomeProtectionHistoryEvent.toObject(message.event, options);
                    return object;
                };

                /**
                 * Converts this ClaimsStatus to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ClaimsStatus
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClaimsStatus.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ClaimsStatus;
            })();

            clientrpc.CompleteConnectUnemploymentDebitCardStepRequest = (function() {

                /**
                 * Properties of a CompleteConnectUnemploymentDebitCardStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectUnemploymentDebitCardStepRequest
                 * @property {string|null} [stepToken] CompleteConnectUnemploymentDebitCardStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteConnectUnemploymentDebitCardStepRequest workflowToken
                 * @property {string|null} [debitCardBinNumber] CompleteConnectUnemploymentDebitCardStepRequest debitCardBinNumber
                 */

                /**
                 * Constructs a new CompleteConnectUnemploymentDebitCardStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectUnemploymentDebitCardStepRequest.
                 * @implements ICompleteConnectUnemploymentDebitCardStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepRequest=} [properties] Properties to set
                 */
                function CompleteConnectUnemploymentDebitCardStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteConnectUnemploymentDebitCardStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @instance
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.prototype.stepToken = "";

                /**
                 * CompleteConnectUnemploymentDebitCardStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @instance
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteConnectUnemploymentDebitCardStepRequest debitCardBinNumber.
                 * @member {string} debitCardBinNumber
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @instance
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.prototype.debitCardBinNumber = "";

                /**
                 * Creates a new CompleteConnectUnemploymentDebitCardStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest} CompleteConnectUnemploymentDebitCardStepRequest instance
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.create = function create(properties) {
                    return new CompleteConnectUnemploymentDebitCardStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentDebitCardStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepRequest} message CompleteConnectUnemploymentDebitCardStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.debitCardBinNumber != null && message.hasOwnProperty("debitCardBinNumber"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.debitCardBinNumber);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentDebitCardStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepRequest} message CompleteConnectUnemploymentDebitCardStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectUnemploymentDebitCardStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest} CompleteConnectUnemploymentDebitCardStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.debitCardBinNumber = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectUnemploymentDebitCardStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest} CompleteConnectUnemploymentDebitCardStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectUnemploymentDebitCardStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.debitCardBinNumber != null && message.hasOwnProperty("debitCardBinNumber"))
                        if (!$util.isString(message.debitCardBinNumber))
                            return "debitCardBinNumber: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectUnemploymentDebitCardStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest} CompleteConnectUnemploymentDebitCardStepRequest
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.debitCardBinNumber != null)
                        message.debitCardBinNumber = String(object.debitCardBinNumber);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteConnectUnemploymentDebitCardStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest} message CompleteConnectUnemploymentDebitCardStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.debitCardBinNumber = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.debitCardBinNumber != null && message.hasOwnProperty("debitCardBinNumber"))
                        object.debitCardBinNumber = message.debitCardBinNumber;
                    return object;
                };

                /**
                 * Converts this CompleteConnectUnemploymentDebitCardStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectUnemploymentDebitCardStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectUnemploymentDebitCardStepRequest;
            })();

            clientrpc.CompleteConnectUnemploymentDebitCardStepResponse = (function() {

                /**
                 * Properties of a CompleteConnectUnemploymentDebitCardStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectUnemploymentDebitCardStepResponse
                 */

                /**
                 * Constructs a new CompleteConnectUnemploymentDebitCardStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectUnemploymentDebitCardStepResponse.
                 * @implements ICompleteConnectUnemploymentDebitCardStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepResponse=} [properties] Properties to set
                 */
                function CompleteConnectUnemploymentDebitCardStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteConnectUnemploymentDebitCardStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse} CompleteConnectUnemploymentDebitCardStepResponse instance
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.create = function create(properties) {
                    return new CompleteConnectUnemploymentDebitCardStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentDebitCardStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepResponse} message CompleteConnectUnemploymentDebitCardStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentDebitCardStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentDebitCardStepResponse} message CompleteConnectUnemploymentDebitCardStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectUnemploymentDebitCardStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse} CompleteConnectUnemploymentDebitCardStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectUnemploymentDebitCardStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse} CompleteConnectUnemploymentDebitCardStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectUnemploymentDebitCardStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectUnemploymentDebitCardStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse} CompleteConnectUnemploymentDebitCardStepResponse
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteConnectUnemploymentDebitCardStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse} message CompleteConnectUnemploymentDebitCardStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteConnectUnemploymentDebitCardStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentDebitCardStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectUnemploymentDebitCardStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectUnemploymentDebitCardStepResponse;
            })();

            clientrpc.CompleteConnectUnemploymentBankAccountStepRequest = (function() {

                /**
                 * Properties of a CompleteConnectUnemploymentBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectUnemploymentBankAccountStepRequest
                 * @property {string|null} [stepToken] CompleteConnectUnemploymentBankAccountStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteConnectUnemploymentBankAccountStepRequest workflowToken
                 * @property {string|null} [bankAccountToken] CompleteConnectUnemploymentBankAccountStepRequest bankAccountToken
                 * @property {visor.clientrpc.RiskAccountType|null} [accountType] CompleteConnectUnemploymentBankAccountStepRequest accountType
                 */

                /**
                 * Constructs a new CompleteConnectUnemploymentBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectUnemploymentBankAccountStepRequest.
                 * @implements ICompleteConnectUnemploymentBankAccountStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepRequest=} [properties] Properties to set
                 */
                function CompleteConnectUnemploymentBankAccountStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteConnectUnemploymentBankAccountStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @instance
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.prototype.stepToken = "";

                /**
                 * CompleteConnectUnemploymentBankAccountStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @instance
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteConnectUnemploymentBankAccountStepRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @instance
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.prototype.bankAccountToken = "";

                /**
                 * CompleteConnectUnemploymentBankAccountStepRequest accountType.
                 * @member {visor.clientrpc.RiskAccountType} accountType
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @instance
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.prototype.accountType = 0;

                /**
                 * Creates a new CompleteConnectUnemploymentBankAccountStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest} CompleteConnectUnemploymentBankAccountStepRequest instance
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.create = function create(properties) {
                    return new CompleteConnectUnemploymentBankAccountStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentBankAccountStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepRequest} message CompleteConnectUnemploymentBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.bankAccountToken);
                    if (message.accountType != null && message.hasOwnProperty("accountType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.accountType);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentBankAccountStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepRequest} message CompleteConnectUnemploymentBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectUnemploymentBankAccountStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest} CompleteConnectUnemploymentBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.bankAccountToken = reader.string();
                            break;
                        case 4:
                            message.accountType = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectUnemploymentBankAccountStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest} CompleteConnectUnemploymentBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectUnemploymentBankAccountStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    if (message.accountType != null && message.hasOwnProperty("accountType"))
                        switch (message.accountType) {
                        default:
                            return "accountType: enum value expected";
                        case 0:
                        case 1:
                        case 3:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a CompleteConnectUnemploymentBankAccountStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest} CompleteConnectUnemploymentBankAccountStepRequest
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    switch (object.accountType) {
                    case "RISK_ACCOUNT_TYPE_INVALID":
                    case 0:
                        message.accountType = 0;
                        break;
                    case "RISK_ACCOUNT_TYPE_BANK_ACCOUNT":
                    case 1:
                        message.accountType = 1;
                        break;
                    case "RISK_ACCOUNT_TYPE_GRID_CARD":
                    case 3:
                        message.accountType = 3;
                        break;
                    case "RISK_ACCOUNT_TYPE_PREPAID_DEBIT_CARD":
                    case 2:
                        message.accountType = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteConnectUnemploymentBankAccountStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest} message CompleteConnectUnemploymentBankAccountStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.bankAccountToken = "";
                        object.accountType = options.enums === String ? "RISK_ACCOUNT_TYPE_INVALID" : 0;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    if (message.accountType != null && message.hasOwnProperty("accountType"))
                        object.accountType = options.enums === String ? $root.visor.clientrpc.RiskAccountType[message.accountType] : message.accountType;
                    return object;
                };

                /**
                 * Converts this CompleteConnectUnemploymentBankAccountStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectUnemploymentBankAccountStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectUnemploymentBankAccountStepRequest;
            })();

            /**
             * RiskAccountType enum.
             * @name visor.clientrpc.RiskAccountType
             * @enum {string}
             * @property {number} RISK_ACCOUNT_TYPE_INVALID=0 RISK_ACCOUNT_TYPE_INVALID value
             * @property {number} RISK_ACCOUNT_TYPE_BANK_ACCOUNT=1 RISK_ACCOUNT_TYPE_BANK_ACCOUNT value
             * @property {number} RISK_ACCOUNT_TYPE_GRID_CARD=3 RISK_ACCOUNT_TYPE_GRID_CARD value
             * @property {number} RISK_ACCOUNT_TYPE_PREPAID_DEBIT_CARD=2 RISK_ACCOUNT_TYPE_PREPAID_DEBIT_CARD value
             */
            clientrpc.RiskAccountType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "RISK_ACCOUNT_TYPE_INVALID"] = 0;
                values[valuesById[1] = "RISK_ACCOUNT_TYPE_BANK_ACCOUNT"] = 1;
                values[valuesById[3] = "RISK_ACCOUNT_TYPE_GRID_CARD"] = 3;
                values[valuesById[2] = "RISK_ACCOUNT_TYPE_PREPAID_DEBIT_CARD"] = 2;
                return values;
            })();

            clientrpc.CompleteConnectUnemploymentBankAccountStepResponse = (function() {

                /**
                 * Properties of a CompleteConnectUnemploymentBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectUnemploymentBankAccountStepResponse
                 */

                /**
                 * Constructs a new CompleteConnectUnemploymentBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectUnemploymentBankAccountStepResponse.
                 * @implements ICompleteConnectUnemploymentBankAccountStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepResponse=} [properties] Properties to set
                 */
                function CompleteConnectUnemploymentBankAccountStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteConnectUnemploymentBankAccountStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse} CompleteConnectUnemploymentBankAccountStepResponse instance
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.create = function create(properties) {
                    return new CompleteConnectUnemploymentBankAccountStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentBankAccountStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepResponse} message CompleteConnectUnemploymentBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectUnemploymentBankAccountStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectUnemploymentBankAccountStepResponse} message CompleteConnectUnemploymentBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectUnemploymentBankAccountStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse} CompleteConnectUnemploymentBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectUnemploymentBankAccountStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse} CompleteConnectUnemploymentBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectUnemploymentBankAccountStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectUnemploymentBankAccountStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse} CompleteConnectUnemploymentBankAccountStepResponse
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteConnectUnemploymentBankAccountStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse} message CompleteConnectUnemploymentBankAccountStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteConnectUnemploymentBankAccountStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectUnemploymentBankAccountStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectUnemploymentBankAccountStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectUnemploymentBankAccountStepResponse;
            })();

            clientrpc.CompleteSignatureStepRequest = (function() {

                /**
                 * Properties of a CompleteSignatureStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSignatureStepRequest
                 * @property {string|null} [stepToken] CompleteSignatureStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteSignatureStepRequest workflowToken
                 * @property {Uint8Array|null} [signature] CompleteSignatureStepRequest signature
                 */

                /**
                 * Constructs a new CompleteSignatureStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSignatureStepRequest.
                 * @implements ICompleteSignatureStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSignatureStepRequest=} [properties] Properties to set
                 */
                function CompleteSignatureStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteSignatureStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @instance
                 */
                CompleteSignatureStepRequest.prototype.stepToken = "";

                /**
                 * CompleteSignatureStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @instance
                 */
                CompleteSignatureStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteSignatureStepRequest signature.
                 * @member {Uint8Array} signature
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @instance
                 */
                CompleteSignatureStepRequest.prototype.signature = $util.newBuffer([]);

                /**
                 * Creates a new CompleteSignatureStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSignatureStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSignatureStepRequest} CompleteSignatureStepRequest instance
                 */
                CompleteSignatureStepRequest.create = function create(properties) {
                    return new CompleteSignatureStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteSignatureStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteSignatureStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSignatureStepRequest} message CompleteSignatureStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignatureStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.signature);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSignatureStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSignatureStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSignatureStepRequest} message CompleteSignatureStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignatureStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSignatureStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSignatureStepRequest} CompleteSignatureStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignatureStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSignatureStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.signature = reader.bytes();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSignatureStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSignatureStepRequest} CompleteSignatureStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignatureStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSignatureStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSignatureStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                            return "signature: buffer expected";
                    return null;
                };

                /**
                 * Creates a CompleteSignatureStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSignatureStepRequest} CompleteSignatureStepRequest
                 */
                CompleteSignatureStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSignatureStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteSignatureStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.signature != null)
                        if (typeof object.signature === "string")
                            $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                        else if (object.signature.length)
                            message.signature = object.signature;
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteSignatureStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteSignatureStepRequest} message CompleteSignatureStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSignatureStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        if (options.bytes === String)
                            object.signature = "";
                        else {
                            object.signature = [];
                            if (options.bytes !== Array)
                                object.signature = $util.newBuffer(object.signature);
                        }
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
                    return object;
                };

                /**
                 * Converts this CompleteSignatureStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSignatureStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSignatureStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSignatureStepRequest;
            })();

            clientrpc.CompleteSignatureStepResponse = (function() {

                /**
                 * Properties of a CompleteSignatureStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSignatureStepResponse
                 */

                /**
                 * Constructs a new CompleteSignatureStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSignatureStepResponse.
                 * @implements ICompleteSignatureStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSignatureStepResponse=} [properties] Properties to set
                 */
                function CompleteSignatureStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteSignatureStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSignatureStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSignatureStepResponse} CompleteSignatureStepResponse instance
                 */
                CompleteSignatureStepResponse.create = function create(properties) {
                    return new CompleteSignatureStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteSignatureStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteSignatureStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSignatureStepResponse} message CompleteSignatureStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignatureStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSignatureStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSignatureStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSignatureStepResponse} message CompleteSignatureStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignatureStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSignatureStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSignatureStepResponse} CompleteSignatureStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignatureStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSignatureStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSignatureStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSignatureStepResponse} CompleteSignatureStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignatureStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSignatureStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSignatureStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteSignatureStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSignatureStepResponse} CompleteSignatureStepResponse
                 */
                CompleteSignatureStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSignatureStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteSignatureStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteSignatureStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteSignatureStepResponse} message CompleteSignatureStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSignatureStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteSignatureStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSignatureStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSignatureStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSignatureStepResponse;
            })();

            clientrpc.GetClaimEligibilityRequest = (function() {

                /**
                 * Properties of a GetClaimEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetClaimEligibilityRequest
                 */

                /**
                 * Constructs a new GetClaimEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetClaimEligibilityRequest.
                 * @implements IGetClaimEligibilityRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetClaimEligibilityRequest=} [properties] Properties to set
                 */
                function GetClaimEligibilityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetClaimEligibilityRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetClaimEligibilityRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetClaimEligibilityRequest} GetClaimEligibilityRequest instance
                 */
                GetClaimEligibilityRequest.create = function create(properties) {
                    return new GetClaimEligibilityRequest(properties);
                };

                /**
                 * Encodes the specified GetClaimEligibilityRequest message. Does not implicitly {@link visor.clientrpc.GetClaimEligibilityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetClaimEligibilityRequest} message GetClaimEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimEligibilityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetClaimEligibilityRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetClaimEligibilityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetClaimEligibilityRequest} message GetClaimEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimEligibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetClaimEligibilityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetClaimEligibilityRequest} GetClaimEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimEligibilityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetClaimEligibilityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetClaimEligibilityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetClaimEligibilityRequest} GetClaimEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimEligibilityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetClaimEligibilityRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetClaimEligibilityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetClaimEligibilityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetClaimEligibilityRequest} GetClaimEligibilityRequest
                 */
                GetClaimEligibilityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetClaimEligibilityRequest)
                        return object;
                    return new $root.visor.clientrpc.GetClaimEligibilityRequest();
                };

                /**
                 * Creates a plain object from a GetClaimEligibilityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.GetClaimEligibilityRequest} message GetClaimEligibilityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetClaimEligibilityRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetClaimEligibilityRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetClaimEligibilityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetClaimEligibilityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetClaimEligibilityRequest;
            })();

            clientrpc.GetClaimEligibilityResponse = (function() {

                /**
                 * Properties of a GetClaimEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetClaimEligibilityResponse
                 * @property {Array.<visor.clientrpc.IPolicy>|null} [policies] GetClaimEligibilityResponse policies
                 */

                /**
                 * Constructs a new GetClaimEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetClaimEligibilityResponse.
                 * @implements IGetClaimEligibilityResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetClaimEligibilityResponse=} [properties] Properties to set
                 */
                function GetClaimEligibilityResponse(properties) {
                    this.policies = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetClaimEligibilityResponse policies.
                 * @member {Array.<visor.clientrpc.IPolicy>} policies
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @instance
                 */
                GetClaimEligibilityResponse.prototype.policies = $util.emptyArray;

                /**
                 * Creates a new GetClaimEligibilityResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetClaimEligibilityResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetClaimEligibilityResponse} GetClaimEligibilityResponse instance
                 */
                GetClaimEligibilityResponse.create = function create(properties) {
                    return new GetClaimEligibilityResponse(properties);
                };

                /**
                 * Encodes the specified GetClaimEligibilityResponse message. Does not implicitly {@link visor.clientrpc.GetClaimEligibilityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetClaimEligibilityResponse} message GetClaimEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimEligibilityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.policies != null && message.policies.length)
                        for (var i = 0; i < message.policies.length; ++i)
                            $root.visor.clientrpc.Policy.encode(message.policies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetClaimEligibilityResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetClaimEligibilityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetClaimEligibilityResponse} message GetClaimEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClaimEligibilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetClaimEligibilityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetClaimEligibilityResponse} GetClaimEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimEligibilityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetClaimEligibilityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.policies && message.policies.length))
                                message.policies = [];
                            message.policies.push($root.visor.clientrpc.Policy.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetClaimEligibilityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetClaimEligibilityResponse} GetClaimEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClaimEligibilityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetClaimEligibilityResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetClaimEligibilityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.policies != null && message.hasOwnProperty("policies")) {
                        if (!Array.isArray(message.policies))
                            return "policies: array expected";
                        for (var i = 0; i < message.policies.length; ++i) {
                            var error = $root.visor.clientrpc.Policy.verify(message.policies[i]);
                            if (error)
                                return "policies." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetClaimEligibilityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetClaimEligibilityResponse} GetClaimEligibilityResponse
                 */
                GetClaimEligibilityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetClaimEligibilityResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetClaimEligibilityResponse();
                    if (object.policies) {
                        if (!Array.isArray(object.policies))
                            throw TypeError(".visor.clientrpc.GetClaimEligibilityResponse.policies: array expected");
                        message.policies = [];
                        for (var i = 0; i < object.policies.length; ++i) {
                            if (typeof object.policies[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetClaimEligibilityResponse.policies: object expected");
                            message.policies[i] = $root.visor.clientrpc.Policy.fromObject(object.policies[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetClaimEligibilityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.GetClaimEligibilityResponse} message GetClaimEligibilityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetClaimEligibilityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.policies = [];
                    if (message.policies && message.policies.length) {
                        object.policies = [];
                        for (var j = 0; j < message.policies.length; ++j)
                            object.policies[j] = $root.visor.clientrpc.Policy.toObject(message.policies[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetClaimEligibilityResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetClaimEligibilityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetClaimEligibilityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetClaimEligibilityResponse;
            })();

            clientrpc.Policy = (function() {

                /**
                 * Properties of a Policy.
                 * @memberof visor.clientrpc
                 * @interface IPolicy
                 * @property {string|null} [token] Policy token
                 * @property {boolean|null} [eligibleForClaim] Policy eligibleForClaim
                 * @property {string|null} [companyName] Policy companyName
                 * @property {visor.common.time.ILocalDate|null} [startDate] Policy startDate
                 * @property {visor.common.time.ILocalDate|null} [closeDate] Policy closeDate
                 */

                /**
                 * Constructs a new Policy.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Policy.
                 * @implements IPolicy
                 * @constructor
                 * @param {visor.clientrpc.IPolicy=} [properties] Properties to set
                 */
                function Policy(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Policy token.
                 * @member {string} token
                 * @memberof visor.clientrpc.Policy
                 * @instance
                 */
                Policy.prototype.token = "";

                /**
                 * Policy eligibleForClaim.
                 * @member {boolean} eligibleForClaim
                 * @memberof visor.clientrpc.Policy
                 * @instance
                 */
                Policy.prototype.eligibleForClaim = false;

                /**
                 * Policy companyName.
                 * @member {string} companyName
                 * @memberof visor.clientrpc.Policy
                 * @instance
                 */
                Policy.prototype.companyName = "";

                /**
                 * Policy startDate.
                 * @member {visor.common.time.ILocalDate|null|undefined} startDate
                 * @memberof visor.clientrpc.Policy
                 * @instance
                 */
                Policy.prototype.startDate = null;

                /**
                 * Policy closeDate.
                 * @member {visor.common.time.ILocalDate|null|undefined} closeDate
                 * @memberof visor.clientrpc.Policy
                 * @instance
                 */
                Policy.prototype.closeDate = null;

                /**
                 * Creates a new Policy instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {visor.clientrpc.IPolicy=} [properties] Properties to set
                 * @returns {visor.clientrpc.Policy} Policy instance
                 */
                Policy.create = function create(properties) {
                    return new Policy(properties);
                };

                /**
                 * Encodes the specified Policy message. Does not implicitly {@link visor.clientrpc.Policy.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {visor.clientrpc.IPolicy} message Policy message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Policy.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                    if (message.eligibleForClaim != null && message.hasOwnProperty("eligibleForClaim"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.eligibleForClaim);
                    if (message.companyName != null && message.hasOwnProperty("companyName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.companyName);
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        $root.visor.common.time.LocalDate.encode(message.startDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.closeDate != null && message.hasOwnProperty("closeDate"))
                        $root.visor.common.time.LocalDate.encode(message.closeDate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Policy message, length delimited. Does not implicitly {@link visor.clientrpc.Policy.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {visor.clientrpc.IPolicy} message Policy message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Policy.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Policy message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Policy} Policy
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Policy.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Policy();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.token = reader.string();
                            break;
                        case 2:
                            message.eligibleForClaim = reader.bool();
                            break;
                        case 3:
                            message.companyName = reader.string();
                            break;
                        case 4:
                            message.startDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.closeDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Policy message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Policy} Policy
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Policy.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Policy message.
                 * @function verify
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Policy.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.eligibleForClaim != null && message.hasOwnProperty("eligibleForClaim"))
                        if (typeof message.eligibleForClaim !== "boolean")
                            return "eligibleForClaim: boolean expected";
                    if (message.companyName != null && message.hasOwnProperty("companyName"))
                        if (!$util.isString(message.companyName))
                            return "companyName: string expected";
                    if (message.startDate != null && message.hasOwnProperty("startDate")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.startDate);
                        if (error)
                            return "startDate." + error;
                    }
                    if (message.closeDate != null && message.hasOwnProperty("closeDate")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.closeDate);
                        if (error)
                            return "closeDate." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Policy message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Policy} Policy
                 */
                Policy.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Policy)
                        return object;
                    var message = new $root.visor.clientrpc.Policy();
                    if (object.token != null)
                        message.token = String(object.token);
                    if (object.eligibleForClaim != null)
                        message.eligibleForClaim = Boolean(object.eligibleForClaim);
                    if (object.companyName != null)
                        message.companyName = String(object.companyName);
                    if (object.startDate != null) {
                        if (typeof object.startDate !== "object")
                            throw TypeError(".visor.clientrpc.Policy.startDate: object expected");
                        message.startDate = $root.visor.common.time.LocalDate.fromObject(object.startDate);
                    }
                    if (object.closeDate != null) {
                        if (typeof object.closeDate !== "object")
                            throw TypeError(".visor.clientrpc.Policy.closeDate: object expected");
                        message.closeDate = $root.visor.common.time.LocalDate.fromObject(object.closeDate);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Policy message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Policy
                 * @static
                 * @param {visor.clientrpc.Policy} message Policy
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Policy.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.token = "";
                        object.eligibleForClaim = false;
                        object.companyName = "";
                        object.startDate = null;
                        object.closeDate = null;
                    }
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.eligibleForClaim != null && message.hasOwnProperty("eligibleForClaim"))
                        object.eligibleForClaim = message.eligibleForClaim;
                    if (message.companyName != null && message.hasOwnProperty("companyName"))
                        object.companyName = message.companyName;
                    if (message.startDate != null && message.hasOwnProperty("startDate"))
                        object.startDate = $root.visor.common.time.LocalDate.toObject(message.startDate, options);
                    if (message.closeDate != null && message.hasOwnProperty("closeDate"))
                        object.closeDate = $root.visor.common.time.LocalDate.toObject(message.closeDate, options);
                    return object;
                };

                /**
                 * Converts this Policy to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Policy
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Policy.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Policy;
            })();

            clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationPostSubmissionInfoStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @property {string|null} [stepToken] CompleteClaimsApplicationPostSubmissionInfoStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteClaimsApplicationPostSubmissionInfoStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationPostSubmissionInfoStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationPostSubmissionInfoStepRequest.
                 * @implements ICompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepRequest=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationPostSubmissionInfoStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteClaimsApplicationPostSubmissionInfoStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.prototype.stepToken = "";

                /**
                 * CompleteClaimsApplicationPostSubmissionInfoStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteClaimsApplicationPostSubmissionInfoStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest} CompleteClaimsApplicationPostSubmissionInfoStepRequest instance
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.create = function create(properties) {
                    return new CompleteClaimsApplicationPostSubmissionInfoStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPostSubmissionInfoStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepRequest} message CompleteClaimsApplicationPostSubmissionInfoStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPostSubmissionInfoStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepRequest} message CompleteClaimsApplicationPostSubmissionInfoStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationPostSubmissionInfoStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest} CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationPostSubmissionInfoStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest} CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationPostSubmissionInfoStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationPostSubmissionInfoStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest} CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationPostSubmissionInfoStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest} message CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteClaimsApplicationPostSubmissionInfoStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationPostSubmissionInfoStepRequest;
            })();

            clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationPostSubmissionInfoStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationPostSubmissionInfoStepResponse
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationPostSubmissionInfoStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationPostSubmissionInfoStepResponse.
                 * @implements ICompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepResponse=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationPostSubmissionInfoStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteClaimsApplicationPostSubmissionInfoStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse} CompleteClaimsApplicationPostSubmissionInfoStepResponse instance
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.create = function create(properties) {
                    return new CompleteClaimsApplicationPostSubmissionInfoStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPostSubmissionInfoStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepResponse} message CompleteClaimsApplicationPostSubmissionInfoStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPostSubmissionInfoStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPostSubmissionInfoStepResponse} message CompleteClaimsApplicationPostSubmissionInfoStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationPostSubmissionInfoStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse} CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationPostSubmissionInfoStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse} CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationPostSubmissionInfoStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationPostSubmissionInfoStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse} CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationPostSubmissionInfoStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse} message CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteClaimsApplicationPostSubmissionInfoStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPostSubmissionInfoStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationPostSubmissionInfoStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationPostSubmissionInfoStepResponse;
            })();

            clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationPayoutInfoStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationPayoutInfoStepRequest
                 * @property {string|null} [stepToken] CompleteClaimsApplicationPayoutInfoStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteClaimsApplicationPayoutInfoStepRequest workflowToken
                 * @property {string|null} [bankAccountToken] CompleteClaimsApplicationPayoutInfoStepRequest bankAccountToken
                 * @property {visor.clientrpc.ClaimsPayoutMethod|null} [payoutMethod] CompleteClaimsApplicationPayoutInfoStepRequest payoutMethod
                 * @property {string|null} [cardToken] CompleteClaimsApplicationPayoutInfoStepRequest cardToken
                 * @property {visor.clientrpc.PayoutDeliveryType|null} [deliveryType] CompleteClaimsApplicationPayoutInfoStepRequest deliveryType
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationPayoutInfoStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationPayoutInfoStepRequest.
                 * @implements ICompleteClaimsApplicationPayoutInfoStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepRequest=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationPayoutInfoStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteClaimsApplicationPayoutInfoStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.prototype.stepToken = "";

                /**
                 * CompleteClaimsApplicationPayoutInfoStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteClaimsApplicationPayoutInfoStepRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.prototype.bankAccountToken = "";

                /**
                 * CompleteClaimsApplicationPayoutInfoStepRequest payoutMethod.
                 * @member {visor.clientrpc.ClaimsPayoutMethod} payoutMethod
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.prototype.payoutMethod = 0;

                /**
                 * CompleteClaimsApplicationPayoutInfoStepRequest cardToken.
                 * @member {string} cardToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.prototype.cardToken = "";

                /**
                 * CompleteClaimsApplicationPayoutInfoStepRequest deliveryType.
                 * @member {visor.clientrpc.PayoutDeliveryType} deliveryType
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.prototype.deliveryType = 0;

                /**
                 * Creates a new CompleteClaimsApplicationPayoutInfoStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest} CompleteClaimsApplicationPayoutInfoStepRequest instance
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.create = function create(properties) {
                    return new CompleteClaimsApplicationPayoutInfoStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPayoutInfoStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepRequest} message CompleteClaimsApplicationPayoutInfoStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.bankAccountToken);
                    if (message.payoutMethod != null && message.hasOwnProperty("payoutMethod"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.payoutMethod);
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.cardToken);
                    if (message.deliveryType != null && message.hasOwnProperty("deliveryType"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.deliveryType);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPayoutInfoStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepRequest} message CompleteClaimsApplicationPayoutInfoStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationPayoutInfoStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest} CompleteClaimsApplicationPayoutInfoStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.bankAccountToken = reader.string();
                            break;
                        case 4:
                            message.payoutMethod = reader.int32();
                            break;
                        case 5:
                            message.cardToken = reader.string();
                            break;
                        case 6:
                            message.deliveryType = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationPayoutInfoStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest} CompleteClaimsApplicationPayoutInfoStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationPayoutInfoStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    if (message.payoutMethod != null && message.hasOwnProperty("payoutMethod"))
                        switch (message.payoutMethod) {
                        default:
                            return "payoutMethod: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        if (!$util.isString(message.cardToken))
                            return "cardToken: string expected";
                    if (message.deliveryType != null && message.hasOwnProperty("deliveryType"))
                        switch (message.deliveryType) {
                        default:
                            return "deliveryType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationPayoutInfoStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest} CompleteClaimsApplicationPayoutInfoStepRequest
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    switch (object.payoutMethod) {
                    case "CLAIMS_PAYOUT_METHOD_INVALID":
                    case 0:
                        message.payoutMethod = 0;
                        break;
                    case "CLAIMS_PAYOUT_METHOD_GRID_CARD":
                    case 1:
                        message.payoutMethod = 1;
                        break;
                    case "CLAIMS_PAYOUT_METHOD_ACH":
                    case 2:
                        message.payoutMethod = 2;
                        break;
                    case "CLAIMS_PAYOUT_METHOD_DEBIT_CARD":
                    case 3:
                        message.payoutMethod = 3;
                        break;
                    }
                    if (object.cardToken != null)
                        message.cardToken = String(object.cardToken);
                    switch (object.deliveryType) {
                    case "PAYOUT_DELIVERY_TYPE_INVALID":
                    case 0:
                        message.deliveryType = 0;
                        break;
                    case "PAYOUT_DELIVERY_TYPE_STANDARD_DELIVERY":
                    case 1:
                        message.deliveryType = 1;
                        break;
                    case "PAYOUT_DELIVERY_TYPE_FAST_DELIVERY":
                    case 2:
                        message.deliveryType = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationPayoutInfoStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest} message CompleteClaimsApplicationPayoutInfoStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.bankAccountToken = "";
                        object.payoutMethod = options.enums === String ? "CLAIMS_PAYOUT_METHOD_INVALID" : 0;
                        object.cardToken = "";
                        object.deliveryType = options.enums === String ? "PAYOUT_DELIVERY_TYPE_INVALID" : 0;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    if (message.payoutMethod != null && message.hasOwnProperty("payoutMethod"))
                        object.payoutMethod = options.enums === String ? $root.visor.clientrpc.ClaimsPayoutMethod[message.payoutMethod] : message.payoutMethod;
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        object.cardToken = message.cardToken;
                    if (message.deliveryType != null && message.hasOwnProperty("deliveryType"))
                        object.deliveryType = options.enums === String ? $root.visor.clientrpc.PayoutDeliveryType[message.deliveryType] : message.deliveryType;
                    return object;
                };

                /**
                 * Converts this CompleteClaimsApplicationPayoutInfoStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationPayoutInfoStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationPayoutInfoStepRequest;
            })();

            /**
             * PayoutDeliveryType enum.
             * @name visor.clientrpc.PayoutDeliveryType
             * @enum {string}
             * @property {number} PAYOUT_DELIVERY_TYPE_INVALID=0 PAYOUT_DELIVERY_TYPE_INVALID value
             * @property {number} PAYOUT_DELIVERY_TYPE_STANDARD_DELIVERY=1 PAYOUT_DELIVERY_TYPE_STANDARD_DELIVERY value
             * @property {number} PAYOUT_DELIVERY_TYPE_FAST_DELIVERY=2 PAYOUT_DELIVERY_TYPE_FAST_DELIVERY value
             */
            clientrpc.PayoutDeliveryType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PAYOUT_DELIVERY_TYPE_INVALID"] = 0;
                values[valuesById[1] = "PAYOUT_DELIVERY_TYPE_STANDARD_DELIVERY"] = 1;
                values[valuesById[2] = "PAYOUT_DELIVERY_TYPE_FAST_DELIVERY"] = 2;
                return values;
            })();

            /**
             * ClaimsPayoutMethod enum.
             * @name visor.clientrpc.ClaimsPayoutMethod
             * @enum {string}
             * @property {number} CLAIMS_PAYOUT_METHOD_INVALID=0 CLAIMS_PAYOUT_METHOD_INVALID value
             * @property {number} CLAIMS_PAYOUT_METHOD_GRID_CARD=1 CLAIMS_PAYOUT_METHOD_GRID_CARD value
             * @property {number} CLAIMS_PAYOUT_METHOD_ACH=2 CLAIMS_PAYOUT_METHOD_ACH value
             * @property {number} CLAIMS_PAYOUT_METHOD_DEBIT_CARD=3 CLAIMS_PAYOUT_METHOD_DEBIT_CARD value
             */
            clientrpc.ClaimsPayoutMethod = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CLAIMS_PAYOUT_METHOD_INVALID"] = 0;
                values[valuesById[1] = "CLAIMS_PAYOUT_METHOD_GRID_CARD"] = 1;
                values[valuesById[2] = "CLAIMS_PAYOUT_METHOD_ACH"] = 2;
                values[valuesById[3] = "CLAIMS_PAYOUT_METHOD_DEBIT_CARD"] = 3;
                return values;
            })();

            clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationPayoutInfoStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationPayoutInfoStepResponse
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationPayoutInfoStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationPayoutInfoStepResponse.
                 * @implements ICompleteClaimsApplicationPayoutInfoStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepResponse=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationPayoutInfoStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteClaimsApplicationPayoutInfoStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse} CompleteClaimsApplicationPayoutInfoStepResponse instance
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.create = function create(properties) {
                    return new CompleteClaimsApplicationPayoutInfoStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPayoutInfoStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepResponse} message CompleteClaimsApplicationPayoutInfoStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationPayoutInfoStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationPayoutInfoStepResponse} message CompleteClaimsApplicationPayoutInfoStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationPayoutInfoStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse} CompleteClaimsApplicationPayoutInfoStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationPayoutInfoStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse} CompleteClaimsApplicationPayoutInfoStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationPayoutInfoStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationPayoutInfoStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse} CompleteClaimsApplicationPayoutInfoStepResponse
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationPayoutInfoStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse} message CompleteClaimsApplicationPayoutInfoStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteClaimsApplicationPayoutInfoStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationPayoutInfoStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationPayoutInfoStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationPayoutInfoStepResponse;
            })();

            clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationOnboardingDocStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationOnboardingDocStepRequest
                 * @property {string|null} [stepToken] CompleteClaimsApplicationOnboardingDocStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteClaimsApplicationOnboardingDocStepRequest workflowToken
                 * @property {Array.<string>|null} [fileTokens] CompleteClaimsApplicationOnboardingDocStepRequest fileTokens
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationOnboardingDocStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationOnboardingDocStepRequest.
                 * @implements ICompleteClaimsApplicationOnboardingDocStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepRequest=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationOnboardingDocStepRequest(properties) {
                    this.fileTokens = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteClaimsApplicationOnboardingDocStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.prototype.stepToken = "";

                /**
                 * CompleteClaimsApplicationOnboardingDocStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteClaimsApplicationOnboardingDocStepRequest fileTokens.
                 * @member {Array.<string>} fileTokens
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.prototype.fileTokens = $util.emptyArray;

                /**
                 * Creates a new CompleteClaimsApplicationOnboardingDocStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest} CompleteClaimsApplicationOnboardingDocStepRequest instance
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.create = function create(properties) {
                    return new CompleteClaimsApplicationOnboardingDocStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationOnboardingDocStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepRequest} message CompleteClaimsApplicationOnboardingDocStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.fileTokens != null && message.fileTokens.length)
                        for (var i = 0; i < message.fileTokens.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fileTokens[i]);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationOnboardingDocStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepRequest} message CompleteClaimsApplicationOnboardingDocStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationOnboardingDocStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest} CompleteClaimsApplicationOnboardingDocStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            if (!(message.fileTokens && message.fileTokens.length))
                                message.fileTokens = [];
                            message.fileTokens.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationOnboardingDocStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest} CompleteClaimsApplicationOnboardingDocStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationOnboardingDocStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.fileTokens != null && message.hasOwnProperty("fileTokens")) {
                        if (!Array.isArray(message.fileTokens))
                            return "fileTokens: array expected";
                        for (var i = 0; i < message.fileTokens.length; ++i)
                            if (!$util.isString(message.fileTokens[i]))
                                return "fileTokens: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationOnboardingDocStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest} CompleteClaimsApplicationOnboardingDocStepRequest
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.fileTokens) {
                        if (!Array.isArray(object.fileTokens))
                            throw TypeError(".visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest.fileTokens: array expected");
                        message.fileTokens = [];
                        for (var i = 0; i < object.fileTokens.length; ++i)
                            message.fileTokens[i] = String(object.fileTokens[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationOnboardingDocStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest} message CompleteClaimsApplicationOnboardingDocStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fileTokens = [];
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.fileTokens && message.fileTokens.length) {
                        object.fileTokens = [];
                        for (var j = 0; j < message.fileTokens.length; ++j)
                            object.fileTokens[j] = message.fileTokens[j];
                    }
                    return object;
                };

                /**
                 * Converts this CompleteClaimsApplicationOnboardingDocStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationOnboardingDocStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationOnboardingDocStepRequest;
            })();

            clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationOnboardingDocStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationOnboardingDocStepResponse
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationOnboardingDocStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationOnboardingDocStepResponse.
                 * @implements ICompleteClaimsApplicationOnboardingDocStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepResponse=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationOnboardingDocStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteClaimsApplicationOnboardingDocStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse} CompleteClaimsApplicationOnboardingDocStepResponse instance
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.create = function create(properties) {
                    return new CompleteClaimsApplicationOnboardingDocStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationOnboardingDocStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepResponse} message CompleteClaimsApplicationOnboardingDocStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationOnboardingDocStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationOnboardingDocStepResponse} message CompleteClaimsApplicationOnboardingDocStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationOnboardingDocStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse} CompleteClaimsApplicationOnboardingDocStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationOnboardingDocStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse} CompleteClaimsApplicationOnboardingDocStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationOnboardingDocStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationOnboardingDocStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse} CompleteClaimsApplicationOnboardingDocStepResponse
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationOnboardingDocStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse} message CompleteClaimsApplicationOnboardingDocStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteClaimsApplicationOnboardingDocStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationOnboardingDocStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationOnboardingDocStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationOnboardingDocStepResponse;
            })();

            clientrpc.GetIncomeProtectionHistoryRequest = (function() {

                /**
                 * Properties of a GetIncomeProtectionHistoryRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetIncomeProtectionHistoryRequest
                 * @property {string|null} [testFixture] GetIncomeProtectionHistoryRequest testFixture
                 */

                /**
                 * Constructs a new GetIncomeProtectionHistoryRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetIncomeProtectionHistoryRequest.
                 * @implements IGetIncomeProtectionHistoryRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryRequest=} [properties] Properties to set
                 */
                function GetIncomeProtectionHistoryRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetIncomeProtectionHistoryRequest testFixture.
                 * @member {string} testFixture
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @instance
                 */
                GetIncomeProtectionHistoryRequest.prototype.testFixture = "";

                /**
                 * Creates a new GetIncomeProtectionHistoryRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryRequest} GetIncomeProtectionHistoryRequest instance
                 */
                GetIncomeProtectionHistoryRequest.create = function create(properties) {
                    return new GetIncomeProtectionHistoryRequest(properties);
                };

                /**
                 * Encodes the specified GetIncomeProtectionHistoryRequest message. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionHistoryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryRequest} message GetIncomeProtectionHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionHistoryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.testFixture != null && message.hasOwnProperty("testFixture"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.testFixture);
                    return writer;
                };

                /**
                 * Encodes the specified GetIncomeProtectionHistoryRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionHistoryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryRequest} message GetIncomeProtectionHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetIncomeProtectionHistoryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryRequest} GetIncomeProtectionHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionHistoryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetIncomeProtectionHistoryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.testFixture = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetIncomeProtectionHistoryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryRequest} GetIncomeProtectionHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetIncomeProtectionHistoryRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIncomeProtectionHistoryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.testFixture != null && message.hasOwnProperty("testFixture"))
                        if (!$util.isString(message.testFixture))
                            return "testFixture: string expected";
                    return null;
                };

                /**
                 * Creates a GetIncomeProtectionHistoryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryRequest} GetIncomeProtectionHistoryRequest
                 */
                GetIncomeProtectionHistoryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetIncomeProtectionHistoryRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetIncomeProtectionHistoryRequest();
                    if (object.testFixture != null)
                        message.testFixture = String(object.testFixture);
                    return message;
                };

                /**
                 * Creates a plain object from a GetIncomeProtectionHistoryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @static
                 * @param {visor.clientrpc.GetIncomeProtectionHistoryRequest} message GetIncomeProtectionHistoryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIncomeProtectionHistoryRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.testFixture = "";
                    if (message.testFixture != null && message.hasOwnProperty("testFixture"))
                        object.testFixture = message.testFixture;
                    return object;
                };

                /**
                 * Converts this GetIncomeProtectionHistoryRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIncomeProtectionHistoryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetIncomeProtectionHistoryRequest;
            })();

            clientrpc.GetIncomeProtectionHistoryResponse = (function() {

                /**
                 * Properties of a GetIncomeProtectionHistoryResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetIncomeProtectionHistoryResponse
                 * @property {Array.<visor.clientrpc.IIncomeProtectionHistory>|null} [histories] GetIncomeProtectionHistoryResponse histories
                 */

                /**
                 * Constructs a new GetIncomeProtectionHistoryResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetIncomeProtectionHistoryResponse.
                 * @implements IGetIncomeProtectionHistoryResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryResponse=} [properties] Properties to set
                 */
                function GetIncomeProtectionHistoryResponse(properties) {
                    this.histories = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetIncomeProtectionHistoryResponse histories.
                 * @member {Array.<visor.clientrpc.IIncomeProtectionHistory>} histories
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @instance
                 */
                GetIncomeProtectionHistoryResponse.prototype.histories = $util.emptyArray;

                /**
                 * Creates a new GetIncomeProtectionHistoryResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryResponse} GetIncomeProtectionHistoryResponse instance
                 */
                GetIncomeProtectionHistoryResponse.create = function create(properties) {
                    return new GetIncomeProtectionHistoryResponse(properties);
                };

                /**
                 * Encodes the specified GetIncomeProtectionHistoryResponse message. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionHistoryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryResponse} message GetIncomeProtectionHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionHistoryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.histories != null && message.histories.length)
                        for (var i = 0; i < message.histories.length; ++i)
                            $root.visor.clientrpc.IncomeProtectionHistory.encode(message.histories[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetIncomeProtectionHistoryResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionHistoryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionHistoryResponse} message GetIncomeProtectionHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetIncomeProtectionHistoryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryResponse} GetIncomeProtectionHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionHistoryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetIncomeProtectionHistoryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.histories && message.histories.length))
                                message.histories = [];
                            message.histories.push($root.visor.clientrpc.IncomeProtectionHistory.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetIncomeProtectionHistoryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryResponse} GetIncomeProtectionHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetIncomeProtectionHistoryResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIncomeProtectionHistoryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.histories != null && message.hasOwnProperty("histories")) {
                        if (!Array.isArray(message.histories))
                            return "histories: array expected";
                        for (var i = 0; i < message.histories.length; ++i) {
                            var error = $root.visor.clientrpc.IncomeProtectionHistory.verify(message.histories[i]);
                            if (error)
                                return "histories." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetIncomeProtectionHistoryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetIncomeProtectionHistoryResponse} GetIncomeProtectionHistoryResponse
                 */
                GetIncomeProtectionHistoryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetIncomeProtectionHistoryResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetIncomeProtectionHistoryResponse();
                    if (object.histories) {
                        if (!Array.isArray(object.histories))
                            throw TypeError(".visor.clientrpc.GetIncomeProtectionHistoryResponse.histories: array expected");
                        message.histories = [];
                        for (var i = 0; i < object.histories.length; ++i) {
                            if (typeof object.histories[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetIncomeProtectionHistoryResponse.histories: object expected");
                            message.histories[i] = $root.visor.clientrpc.IncomeProtectionHistory.fromObject(object.histories[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetIncomeProtectionHistoryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @static
                 * @param {visor.clientrpc.GetIncomeProtectionHistoryResponse} message GetIncomeProtectionHistoryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIncomeProtectionHistoryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.histories = [];
                    if (message.histories && message.histories.length) {
                        object.histories = [];
                        for (var j = 0; j < message.histories.length; ++j)
                            object.histories[j] = $root.visor.clientrpc.IncomeProtectionHistory.toObject(message.histories[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetIncomeProtectionHistoryResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetIncomeProtectionHistoryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIncomeProtectionHistoryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetIncomeProtectionHistoryResponse;
            })();

            clientrpc.IncomeProtectionHistory = (function() {

                /**
                 * Properties of an IncomeProtectionHistory.
                 * @memberof visor.clientrpc
                 * @interface IIncomeProtectionHistory
                 * @property {Array.<visor.clientrpc.IIncomeProtectionHistoryEvent>|null} [events] IncomeProtectionHistory events
                 * @property {visor.common.strings.ILocalizedString|null} [title] IncomeProtectionHistory title
                 */

                /**
                 * Constructs a new IncomeProtectionHistory.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an IncomeProtectionHistory.
                 * @implements IIncomeProtectionHistory
                 * @constructor
                 * @param {visor.clientrpc.IIncomeProtectionHistory=} [properties] Properties to set
                 */
                function IncomeProtectionHistory(properties) {
                    this.events = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * IncomeProtectionHistory events.
                 * @member {Array.<visor.clientrpc.IIncomeProtectionHistoryEvent>} events
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @instance
                 */
                IncomeProtectionHistory.prototype.events = $util.emptyArray;

                /**
                 * IncomeProtectionHistory title.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} title
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @instance
                 */
                IncomeProtectionHistory.prototype.title = null;

                /**
                 * Creates a new IncomeProtectionHistory instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {visor.clientrpc.IIncomeProtectionHistory=} [properties] Properties to set
                 * @returns {visor.clientrpc.IncomeProtectionHistory} IncomeProtectionHistory instance
                 */
                IncomeProtectionHistory.create = function create(properties) {
                    return new IncomeProtectionHistory(properties);
                };

                /**
                 * Encodes the specified IncomeProtectionHistory message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistory.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {visor.clientrpc.IIncomeProtectionHistory} message IncomeProtectionHistory message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncomeProtectionHistory.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.events != null && message.events.length)
                        for (var i = 0; i < message.events.length; ++i)
                            $root.visor.clientrpc.IncomeProtectionHistoryEvent.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.title != null && message.hasOwnProperty("title"))
                        $root.visor.common.strings.LocalizedString.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified IncomeProtectionHistory message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistory.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {visor.clientrpc.IIncomeProtectionHistory} message IncomeProtectionHistory message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncomeProtectionHistory.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an IncomeProtectionHistory message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.IncomeProtectionHistory} IncomeProtectionHistory
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncomeProtectionHistory.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistory();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.events && message.events.length))
                                message.events = [];
                            message.events.push($root.visor.clientrpc.IncomeProtectionHistoryEvent.decode(reader, reader.uint32()));
                            break;
                        case 2:
                            message.title = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an IncomeProtectionHistory message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.IncomeProtectionHistory} IncomeProtectionHistory
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncomeProtectionHistory.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an IncomeProtectionHistory message.
                 * @function verify
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IncomeProtectionHistory.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.events != null && message.hasOwnProperty("events")) {
                        if (!Array.isArray(message.events))
                            return "events: array expected";
                        for (var i = 0; i < message.events.length; ++i) {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.verify(message.events[i]);
                            if (error)
                                return "events." + error;
                        }
                    }
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    return null;
                };

                /**
                 * Creates an IncomeProtectionHistory message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.IncomeProtectionHistory} IncomeProtectionHistory
                 */
                IncomeProtectionHistory.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.IncomeProtectionHistory)
                        return object;
                    var message = new $root.visor.clientrpc.IncomeProtectionHistory();
                    if (object.events) {
                        if (!Array.isArray(object.events))
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistory.events: array expected");
                        message.events = [];
                        for (var i = 0; i < object.events.length; ++i) {
                            if (typeof object.events[i] !== "object")
                                throw TypeError(".visor.clientrpc.IncomeProtectionHistory.events: object expected");
                            message.events[i] = $root.visor.clientrpc.IncomeProtectionHistoryEvent.fromObject(object.events[i]);
                        }
                    }
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistory.title: object expected");
                        message.title = $root.visor.common.strings.LocalizedString.fromObject(object.title);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an IncomeProtectionHistory message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @static
                 * @param {visor.clientrpc.IncomeProtectionHistory} message IncomeProtectionHistory
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IncomeProtectionHistory.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.events = [];
                    if (options.defaults)
                        object.title = null;
                    if (message.events && message.events.length) {
                        object.events = [];
                        for (var j = 0; j < message.events.length; ++j)
                            object.events[j] = $root.visor.clientrpc.IncomeProtectionHistoryEvent.toObject(message.events[j], options);
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.visor.common.strings.LocalizedString.toObject(message.title, options);
                    return object;
                };

                /**
                 * Converts this IncomeProtectionHistory to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.IncomeProtectionHistory
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IncomeProtectionHistory.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return IncomeProtectionHistory;
            })();

            clientrpc.IncomeProtectionHistoryEvent = (function() {

                /**
                 * Properties of an IncomeProtectionHistoryEvent.
                 * @memberof visor.clientrpc
                 * @interface IIncomeProtectionHistoryEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IEnrolled|null} [enrolledEvent] IncomeProtectionHistoryEvent enrolledEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaimAtFutureDate|null} [eligibleForClaimAtFutureDateEvent] IncomeProtectionHistoryEvent eligibleForClaimAtFutureDateEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaim|null} [eligibleForClaimEvent] IncomeProtectionHistoryEvent eligibleForClaimEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IPremiumPayment|null} [premiumPaymentEvent] IncomeProtectionHistoryEvent premiumPaymentEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IPolicyCanceled|null} [policyCanceledEvent] IncomeProtectionHistoryEvent policyCanceledEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimSubmitted|null} [claimSubmittedEvent] IncomeProtectionHistoryEvent claimSubmittedEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IBenefitPayout|null} [benefitPayoutEvent] IncomeProtectionHistoryEvent benefitPayoutEvent
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimRejected|null} [claimRejectedEvent] IncomeProtectionHistoryEvent claimRejectedEvent
                 * @property {visor.common.time.ILocalDate|null} [date] IncomeProtectionHistoryEvent date
                 * @property {visor.clientrpc.IncomeProtectionHistoryEvent.Status|null} [status] IncomeProtectionHistoryEvent status
                 * @property {visor.common.strings.ILocalizedString|null} [eventDescription] IncomeProtectionHistoryEvent eventDescription
                 * @property {number|null} [priority] IncomeProtectionHistoryEvent priority
                 * @property {number|null} [claimNumber] IncomeProtectionHistoryEvent claimNumber
                 * @property {number|null} [claimApplicationNumber] IncomeProtectionHistoryEvent claimApplicationNumber
                 */

                /**
                 * Constructs a new IncomeProtectionHistoryEvent.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an IncomeProtectionHistoryEvent.
                 * @implements IIncomeProtectionHistoryEvent
                 * @constructor
                 * @param {visor.clientrpc.IIncomeProtectionHistoryEvent=} [properties] Properties to set
                 */
                function IncomeProtectionHistoryEvent(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * IncomeProtectionHistoryEvent enrolledEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IEnrolled|null|undefined} enrolledEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.enrolledEvent = null;

                /**
                 * IncomeProtectionHistoryEvent eligibleForClaimAtFutureDateEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaimAtFutureDate|null|undefined} eligibleForClaimAtFutureDateEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.eligibleForClaimAtFutureDateEvent = null;

                /**
                 * IncomeProtectionHistoryEvent eligibleForClaimEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaim|null|undefined} eligibleForClaimEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.eligibleForClaimEvent = null;

                /**
                 * IncomeProtectionHistoryEvent premiumPaymentEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IPremiumPayment|null|undefined} premiumPaymentEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.premiumPaymentEvent = null;

                /**
                 * IncomeProtectionHistoryEvent policyCanceledEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IPolicyCanceled|null|undefined} policyCanceledEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.policyCanceledEvent = null;

                /**
                 * IncomeProtectionHistoryEvent claimSubmittedEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimSubmitted|null|undefined} claimSubmittedEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.claimSubmittedEvent = null;

                /**
                 * IncomeProtectionHistoryEvent benefitPayoutEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IBenefitPayout|null|undefined} benefitPayoutEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.benefitPayoutEvent = null;

                /**
                 * IncomeProtectionHistoryEvent claimRejectedEvent.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimRejected|null|undefined} claimRejectedEvent
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.claimRejectedEvent = null;

                /**
                 * IncomeProtectionHistoryEvent date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.date = null;

                /**
                 * IncomeProtectionHistoryEvent status.
                 * @member {visor.clientrpc.IncomeProtectionHistoryEvent.Status} status
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.status = 0;

                /**
                 * IncomeProtectionHistoryEvent eventDescription.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} eventDescription
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.eventDescription = null;

                /**
                 * IncomeProtectionHistoryEvent priority.
                 * @member {number} priority
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.priority = 0;

                /**
                 * IncomeProtectionHistoryEvent claimNumber.
                 * @member {number} claimNumber
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.claimNumber = 0;

                /**
                 * IncomeProtectionHistoryEvent claimApplicationNumber.
                 * @member {number} claimApplicationNumber
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                IncomeProtectionHistoryEvent.prototype.claimApplicationNumber = 0;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * IncomeProtectionHistoryEvent event.
                 * @member {"enrolledEvent"|"eligibleForClaimAtFutureDateEvent"|"eligibleForClaimEvent"|"premiumPaymentEvent"|"policyCanceledEvent"|"claimSubmittedEvent"|"benefitPayoutEvent"|"claimRejectedEvent"|undefined} event
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 */
                Object.defineProperty(IncomeProtectionHistoryEvent.prototype, "event", {
                    get: $util.oneOfGetter($oneOfFields = ["enrolledEvent", "eligibleForClaimAtFutureDateEvent", "eligibleForClaimEvent", "premiumPaymentEvent", "policyCanceledEvent", "claimSubmittedEvent", "benefitPayoutEvent", "claimRejectedEvent"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new IncomeProtectionHistoryEvent instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {visor.clientrpc.IIncomeProtectionHistoryEvent=} [properties] Properties to set
                 * @returns {visor.clientrpc.IncomeProtectionHistoryEvent} IncomeProtectionHistoryEvent instance
                 */
                IncomeProtectionHistoryEvent.create = function create(properties) {
                    return new IncomeProtectionHistoryEvent(properties);
                };

                /**
                 * Encodes the specified IncomeProtectionHistoryEvent message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {visor.clientrpc.IIncomeProtectionHistoryEvent} message IncomeProtectionHistoryEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncomeProtectionHistoryEvent.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.enrolledEvent != null && message.hasOwnProperty("enrolledEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled.encode(message.enrolledEvent, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.eligibleForClaimAtFutureDateEvent != null && message.hasOwnProperty("eligibleForClaimAtFutureDateEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.encode(message.eligibleForClaimAtFutureDateEvent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.eligibleForClaimEvent != null && message.hasOwnProperty("eligibleForClaimEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim.encode(message.eligibleForClaimEvent, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.premiumPaymentEvent != null && message.hasOwnProperty("premiumPaymentEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.encode(message.premiumPaymentEvent, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.policyCanceledEvent != null && message.hasOwnProperty("policyCanceledEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled.encode(message.policyCanceledEvent, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.claimSubmittedEvent != null && message.hasOwnProperty("claimSubmittedEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.encode(message.claimSubmittedEvent, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.benefitPayoutEvent != null && message.hasOwnProperty("benefitPayoutEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.encode(message.benefitPayoutEvent, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.claimRejectedEvent != null && message.hasOwnProperty("claimRejectedEvent"))
                        $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected.encode(message.claimRejectedEvent, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.status != null && message.hasOwnProperty("status"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.status);
                    if (message.eventDescription != null && message.hasOwnProperty("eventDescription"))
                        $root.visor.common.strings.LocalizedString.encode(message.eventDescription, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.priority);
                    if (message.claimNumber != null && message.hasOwnProperty("claimNumber"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int32(message.claimNumber);
                    if (message.claimApplicationNumber != null && message.hasOwnProperty("claimApplicationNumber"))
                        writer.uint32(/* id 14, wireType 0 =*/112).int32(message.claimApplicationNumber);
                    return writer;
                };

                /**
                 * Encodes the specified IncomeProtectionHistoryEvent message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {visor.clientrpc.IIncomeProtectionHistoryEvent} message IncomeProtectionHistoryEvent message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IncomeProtectionHistoryEvent.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an IncomeProtectionHistoryEvent message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.IncomeProtectionHistoryEvent} IncomeProtectionHistoryEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncomeProtectionHistoryEvent.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.enrolledEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.eligibleForClaimAtFutureDateEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.eligibleForClaimEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.premiumPaymentEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.policyCanceledEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.claimSubmittedEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.benefitPayoutEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.decode(reader, reader.uint32());
                            break;
                        case 8:
                            message.claimRejectedEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected.decode(reader, reader.uint32());
                            break;
                        case 9:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 10:
                            message.status = reader.int32();
                            break;
                        case 11:
                            message.eventDescription = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        case 12:
                            message.priority = reader.int32();
                            break;
                        case 13:
                            message.claimNumber = reader.int32();
                            break;
                        case 14:
                            message.claimApplicationNumber = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an IncomeProtectionHistoryEvent message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.IncomeProtectionHistoryEvent} IncomeProtectionHistoryEvent
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IncomeProtectionHistoryEvent.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an IncomeProtectionHistoryEvent message.
                 * @function verify
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IncomeProtectionHistoryEvent.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.enrolledEvent != null && message.hasOwnProperty("enrolledEvent")) {
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled.verify(message.enrolledEvent);
                            if (error)
                                return "enrolledEvent." + error;
                        }
                    }
                    if (message.eligibleForClaimAtFutureDateEvent != null && message.hasOwnProperty("eligibleForClaimAtFutureDateEvent")) {
                        if (properties.event === 1)
                            return "event: multiple values";
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.verify(message.eligibleForClaimAtFutureDateEvent);
                            if (error)
                                return "eligibleForClaimAtFutureDateEvent." + error;
                        }
                    }
                    if (message.eligibleForClaimEvent != null && message.hasOwnProperty("eligibleForClaimEvent")) {
                        if (properties.event === 1)
                            return "event: multiple values";
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim.verify(message.eligibleForClaimEvent);
                            if (error)
                                return "eligibleForClaimEvent." + error;
                        }
                    }
                    if (message.premiumPaymentEvent != null && message.hasOwnProperty("premiumPaymentEvent")) {
                        if (properties.event === 1)
                            return "event: multiple values";
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.verify(message.premiumPaymentEvent);
                            if (error)
                                return "premiumPaymentEvent." + error;
                        }
                    }
                    if (message.policyCanceledEvent != null && message.hasOwnProperty("policyCanceledEvent")) {
                        if (properties.event === 1)
                            return "event: multiple values";
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled.verify(message.policyCanceledEvent);
                            if (error)
                                return "policyCanceledEvent." + error;
                        }
                    }
                    if (message.claimSubmittedEvent != null && message.hasOwnProperty("claimSubmittedEvent")) {
                        if (properties.event === 1)
                            return "event: multiple values";
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.verify(message.claimSubmittedEvent);
                            if (error)
                                return "claimSubmittedEvent." + error;
                        }
                    }
                    if (message.benefitPayoutEvent != null && message.hasOwnProperty("benefitPayoutEvent")) {
                        if (properties.event === 1)
                            return "event: multiple values";
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.verify(message.benefitPayoutEvent);
                            if (error)
                                return "benefitPayoutEvent." + error;
                        }
                    }
                    if (message.claimRejectedEvent != null && message.hasOwnProperty("claimRejectedEvent")) {
                        if (properties.event === 1)
                            return "event: multiple values";
                        properties.event = 1;
                        {
                            var error = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected.verify(message.claimRejectedEvent);
                            if (error)
                                return "claimRejectedEvent." + error;
                        }
                    }
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    if (message.status != null && message.hasOwnProperty("status"))
                        switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            break;
                        }
                    if (message.eventDescription != null && message.hasOwnProperty("eventDescription")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.eventDescription);
                        if (error)
                            return "eventDescription." + error;
                    }
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        if (!$util.isInteger(message.priority))
                            return "priority: integer expected";
                    if (message.claimNumber != null && message.hasOwnProperty("claimNumber"))
                        if (!$util.isInteger(message.claimNumber))
                            return "claimNumber: integer expected";
                    if (message.claimApplicationNumber != null && message.hasOwnProperty("claimApplicationNumber"))
                        if (!$util.isInteger(message.claimApplicationNumber))
                            return "claimApplicationNumber: integer expected";
                    return null;
                };

                /**
                 * Creates an IncomeProtectionHistoryEvent message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.IncomeProtectionHistoryEvent} IncomeProtectionHistoryEvent
                 */
                IncomeProtectionHistoryEvent.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent)
                        return object;
                    var message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent();
                    if (object.enrolledEvent != null) {
                        if (typeof object.enrolledEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.enrolledEvent: object expected");
                        message.enrolledEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled.fromObject(object.enrolledEvent);
                    }
                    if (object.eligibleForClaimAtFutureDateEvent != null) {
                        if (typeof object.eligibleForClaimAtFutureDateEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.eligibleForClaimAtFutureDateEvent: object expected");
                        message.eligibleForClaimAtFutureDateEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.fromObject(object.eligibleForClaimAtFutureDateEvent);
                    }
                    if (object.eligibleForClaimEvent != null) {
                        if (typeof object.eligibleForClaimEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.eligibleForClaimEvent: object expected");
                        message.eligibleForClaimEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim.fromObject(object.eligibleForClaimEvent);
                    }
                    if (object.premiumPaymentEvent != null) {
                        if (typeof object.premiumPaymentEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.premiumPaymentEvent: object expected");
                        message.premiumPaymentEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.fromObject(object.premiumPaymentEvent);
                    }
                    if (object.policyCanceledEvent != null) {
                        if (typeof object.policyCanceledEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.policyCanceledEvent: object expected");
                        message.policyCanceledEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled.fromObject(object.policyCanceledEvent);
                    }
                    if (object.claimSubmittedEvent != null) {
                        if (typeof object.claimSubmittedEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.claimSubmittedEvent: object expected");
                        message.claimSubmittedEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.fromObject(object.claimSubmittedEvent);
                    }
                    if (object.benefitPayoutEvent != null) {
                        if (typeof object.benefitPayoutEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.benefitPayoutEvent: object expected");
                        message.benefitPayoutEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.fromObject(object.benefitPayoutEvent);
                    }
                    if (object.claimRejectedEvent != null) {
                        if (typeof object.claimRejectedEvent !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.claimRejectedEvent: object expected");
                        message.claimRejectedEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected.fromObject(object.claimRejectedEvent);
                    }
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    switch (object.status) {
                    case "INCOME_PROTECTION_HISTORY_EVENT_STATUS_INVALID":
                    case 0:
                        message.status = 0;
                        break;
                    case "INCOME_PROTECTION_HISTORY_EVENT_STATUS_PENDING":
                    case 1:
                        message.status = 1;
                        break;
                    case "INCOME_PROTECTION_HISTORY_EVENT_STATUS_COMPLETE":
                    case 2:
                        message.status = 2;
                        break;
                    case "INCOME_PROTECTION_HISTORY_EVENT_STATUS_FAILED":
                    case 3:
                        message.status = 3;
                        break;
                    case "INCOME_PROTECTION_HISTORY_EVENT_STATUS_REFUNDED":
                    case 4:
                        message.status = 4;
                        break;
                    case "INCOME_PROTECTION_HISTORY_EVENT_STATUS_DISPUTED":
                    case 5:
                        message.status = 5;
                        break;
                    case "INCOME_PROTECTION_HISTORY_EVENT_STATUS_FREE_MONTH":
                    case 6:
                        message.status = 6;
                        break;
                    }
                    if (object.eventDescription != null) {
                        if (typeof object.eventDescription !== "object")
                            throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.eventDescription: object expected");
                        message.eventDescription = $root.visor.common.strings.LocalizedString.fromObject(object.eventDescription);
                    }
                    if (object.priority != null)
                        message.priority = object.priority | 0;
                    if (object.claimNumber != null)
                        message.claimNumber = object.claimNumber | 0;
                    if (object.claimApplicationNumber != null)
                        message.claimApplicationNumber = object.claimApplicationNumber | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an IncomeProtectionHistoryEvent message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @static
                 * @param {visor.clientrpc.IncomeProtectionHistoryEvent} message IncomeProtectionHistoryEvent
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IncomeProtectionHistoryEvent.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.date = null;
                        object.status = options.enums === String ? "INCOME_PROTECTION_HISTORY_EVENT_STATUS_INVALID" : 0;
                        object.eventDescription = null;
                        object.priority = 0;
                        object.claimNumber = 0;
                        object.claimApplicationNumber = 0;
                    }
                    if (message.enrolledEvent != null && message.hasOwnProperty("enrolledEvent")) {
                        object.enrolledEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled.toObject(message.enrolledEvent, options);
                        if (options.oneofs)
                            object.event = "enrolledEvent";
                    }
                    if (message.eligibleForClaimAtFutureDateEvent != null && message.hasOwnProperty("eligibleForClaimAtFutureDateEvent")) {
                        object.eligibleForClaimAtFutureDateEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.toObject(message.eligibleForClaimAtFutureDateEvent, options);
                        if (options.oneofs)
                            object.event = "eligibleForClaimAtFutureDateEvent";
                    }
                    if (message.eligibleForClaimEvent != null && message.hasOwnProperty("eligibleForClaimEvent")) {
                        object.eligibleForClaimEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim.toObject(message.eligibleForClaimEvent, options);
                        if (options.oneofs)
                            object.event = "eligibleForClaimEvent";
                    }
                    if (message.premiumPaymentEvent != null && message.hasOwnProperty("premiumPaymentEvent")) {
                        object.premiumPaymentEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.toObject(message.premiumPaymentEvent, options);
                        if (options.oneofs)
                            object.event = "premiumPaymentEvent";
                    }
                    if (message.policyCanceledEvent != null && message.hasOwnProperty("policyCanceledEvent")) {
                        object.policyCanceledEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled.toObject(message.policyCanceledEvent, options);
                        if (options.oneofs)
                            object.event = "policyCanceledEvent";
                    }
                    if (message.claimSubmittedEvent != null && message.hasOwnProperty("claimSubmittedEvent")) {
                        object.claimSubmittedEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.toObject(message.claimSubmittedEvent, options);
                        if (options.oneofs)
                            object.event = "claimSubmittedEvent";
                    }
                    if (message.benefitPayoutEvent != null && message.hasOwnProperty("benefitPayoutEvent")) {
                        object.benefitPayoutEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.toObject(message.benefitPayoutEvent, options);
                        if (options.oneofs)
                            object.event = "benefitPayoutEvent";
                    }
                    if (message.claimRejectedEvent != null && message.hasOwnProperty("claimRejectedEvent")) {
                        object.claimRejectedEvent = $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected.toObject(message.claimRejectedEvent, options);
                        if (options.oneofs)
                            object.event = "claimRejectedEvent";
                    }
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    if (message.status != null && message.hasOwnProperty("status"))
                        object.status = options.enums === String ? $root.visor.clientrpc.IncomeProtectionHistoryEvent.Status[message.status] : message.status;
                    if (message.eventDescription != null && message.hasOwnProperty("eventDescription"))
                        object.eventDescription = $root.visor.common.strings.LocalizedString.toObject(message.eventDescription, options);
                    if (message.priority != null && message.hasOwnProperty("priority"))
                        object.priority = message.priority;
                    if (message.claimNumber != null && message.hasOwnProperty("claimNumber"))
                        object.claimNumber = message.claimNumber;
                    if (message.claimApplicationNumber != null && message.hasOwnProperty("claimApplicationNumber"))
                        object.claimApplicationNumber = message.claimApplicationNumber;
                    return object;
                };

                /**
                 * Converts this IncomeProtectionHistoryEvent to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IncomeProtectionHistoryEvent.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                IncomeProtectionHistoryEvent.Enrolled = (function() {

                    /**
                     * Properties of an Enrolled.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IEnrolled
                     */

                    /**
                     * Constructs a new Enrolled.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents an Enrolled.
                     * @implements IEnrolled
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEnrolled=} [properties] Properties to set
                     */
                    function Enrolled(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new Enrolled instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEnrolled=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled} Enrolled instance
                     */
                    Enrolled.create = function create(properties) {
                        return new Enrolled(properties);
                    };

                    /**
                     * Encodes the specified Enrolled message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEnrolled} message Enrolled message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Enrolled.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified Enrolled message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEnrolled} message Enrolled message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Enrolled.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Enrolled message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled} Enrolled
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Enrolled.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Enrolled message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled} Enrolled
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Enrolled.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Enrolled message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Enrolled.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an Enrolled message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled} Enrolled
                     */
                    Enrolled.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled)
                            return object;
                        return new $root.visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled();
                    };

                    /**
                     * Creates a plain object from an Enrolled message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled} message Enrolled
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Enrolled.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Enrolled to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.Enrolled
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Enrolled.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Enrolled;
                })();

                IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate = (function() {

                    /**
                     * Properties of an EligibleForClaimAtFutureDate.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IEligibleForClaimAtFutureDate
                     * @property {visor.common.time.ILocalDate|null} [eligibleAt] EligibleForClaimAtFutureDate eligibleAt
                     */

                    /**
                     * Constructs a new EligibleForClaimAtFutureDate.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents an EligibleForClaimAtFutureDate.
                     * @implements IEligibleForClaimAtFutureDate
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaimAtFutureDate=} [properties] Properties to set
                     */
                    function EligibleForClaimAtFutureDate(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * EligibleForClaimAtFutureDate eligibleAt.
                     * @member {visor.common.time.ILocalDate|null|undefined} eligibleAt
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @instance
                     */
                    EligibleForClaimAtFutureDate.prototype.eligibleAt = null;

                    /**
                     * Creates a new EligibleForClaimAtFutureDate instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaimAtFutureDate=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate} EligibleForClaimAtFutureDate instance
                     */
                    EligibleForClaimAtFutureDate.create = function create(properties) {
                        return new EligibleForClaimAtFutureDate(properties);
                    };

                    /**
                     * Encodes the specified EligibleForClaimAtFutureDate message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaimAtFutureDate} message EligibleForClaimAtFutureDate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EligibleForClaimAtFutureDate.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.eligibleAt != null && message.hasOwnProperty("eligibleAt"))
                            $root.visor.common.time.LocalDate.encode(message.eligibleAt, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified EligibleForClaimAtFutureDate message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaimAtFutureDate} message EligibleForClaimAtFutureDate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EligibleForClaimAtFutureDate.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EligibleForClaimAtFutureDate message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate} EligibleForClaimAtFutureDate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EligibleForClaimAtFutureDate.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.eligibleAt = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EligibleForClaimAtFutureDate message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate} EligibleForClaimAtFutureDate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EligibleForClaimAtFutureDate.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EligibleForClaimAtFutureDate message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EligibleForClaimAtFutureDate.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.eligibleAt != null && message.hasOwnProperty("eligibleAt")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.eligibleAt);
                            if (error)
                                return "eligibleAt." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an EligibleForClaimAtFutureDate message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate} EligibleForClaimAtFutureDate
                     */
                    EligibleForClaimAtFutureDate.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate)
                            return object;
                        var message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate();
                        if (object.eligibleAt != null) {
                            if (typeof object.eligibleAt !== "object")
                                throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate.eligibleAt: object expected");
                            message.eligibleAt = $root.visor.common.time.LocalDate.fromObject(object.eligibleAt);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an EligibleForClaimAtFutureDate message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate} message EligibleForClaimAtFutureDate
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EligibleForClaimAtFutureDate.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.eligibleAt = null;
                        if (message.eligibleAt != null && message.hasOwnProperty("eligibleAt"))
                            object.eligibleAt = $root.visor.common.time.LocalDate.toObject(message.eligibleAt, options);
                        return object;
                    };

                    /**
                     * Converts this EligibleForClaimAtFutureDate to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaimAtFutureDate
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EligibleForClaimAtFutureDate.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return EligibleForClaimAtFutureDate;
                })();

                IncomeProtectionHistoryEvent.EligibleForClaim = (function() {

                    /**
                     * Properties of an EligibleForClaim.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IEligibleForClaim
                     */

                    /**
                     * Constructs a new EligibleForClaim.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents an EligibleForClaim.
                     * @implements IEligibleForClaim
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaim=} [properties] Properties to set
                     */
                    function EligibleForClaim(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new EligibleForClaim instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaim=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim} EligibleForClaim instance
                     */
                    EligibleForClaim.create = function create(properties) {
                        return new EligibleForClaim(properties);
                    };

                    /**
                     * Encodes the specified EligibleForClaim message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaim} message EligibleForClaim message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EligibleForClaim.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified EligibleForClaim message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IEligibleForClaim} message EligibleForClaim message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EligibleForClaim.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EligibleForClaim message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim} EligibleForClaim
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EligibleForClaim.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EligibleForClaim message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim} EligibleForClaim
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EligibleForClaim.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EligibleForClaim message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EligibleForClaim.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an EligibleForClaim message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim} EligibleForClaim
                     */
                    EligibleForClaim.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim)
                            return object;
                        return new $root.visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim();
                    };

                    /**
                     * Creates a plain object from an EligibleForClaim message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim} message EligibleForClaim
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EligibleForClaim.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this EligibleForClaim to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.EligibleForClaim
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EligibleForClaim.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return EligibleForClaim;
                })();

                IncomeProtectionHistoryEvent.PremiumPayment = (function() {

                    /**
                     * Properties of a PremiumPayment.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IPremiumPayment
                     * @property {string|null} [paymentAccountInfo] PremiumPayment paymentAccountInfo
                     * @property {visor.common.money.IAmount|null} [amount] PremiumPayment amount
                     * @property {visor.common.strings.ILocalizedString|null} [enrollmentPeriod] PremiumPayment enrollmentPeriod
                     */

                    /**
                     * Constructs a new PremiumPayment.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents a PremiumPayment.
                     * @implements IPremiumPayment
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPremiumPayment=} [properties] Properties to set
                     */
                    function PremiumPayment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PremiumPayment paymentAccountInfo.
                     * @member {string} paymentAccountInfo
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @instance
                     */
                    PremiumPayment.prototype.paymentAccountInfo = "";

                    /**
                     * PremiumPayment amount.
                     * @member {visor.common.money.IAmount|null|undefined} amount
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @instance
                     */
                    PremiumPayment.prototype.amount = null;

                    /**
                     * PremiumPayment enrollmentPeriod.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} enrollmentPeriod
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @instance
                     */
                    PremiumPayment.prototype.enrollmentPeriod = null;

                    /**
                     * Creates a new PremiumPayment instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPremiumPayment=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment} PremiumPayment instance
                     */
                    PremiumPayment.create = function create(properties) {
                        return new PremiumPayment(properties);
                    };

                    /**
                     * Encodes the specified PremiumPayment message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPremiumPayment} message PremiumPayment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PremiumPayment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.paymentAccountInfo != null && message.hasOwnProperty("paymentAccountInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentAccountInfo);
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.enrollmentPeriod != null && message.hasOwnProperty("enrollmentPeriod"))
                            $root.visor.common.strings.LocalizedString.encode(message.enrollmentPeriod, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PremiumPayment message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPremiumPayment} message PremiumPayment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PremiumPayment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PremiumPayment message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment} PremiumPayment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PremiumPayment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.paymentAccountInfo = reader.string();
                                break;
                            case 2:
                                message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.enrollmentPeriod = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PremiumPayment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment} PremiumPayment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PremiumPayment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PremiumPayment message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PremiumPayment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.paymentAccountInfo != null && message.hasOwnProperty("paymentAccountInfo"))
                            if (!$util.isString(message.paymentAccountInfo))
                                return "paymentAccountInfo: string expected";
                        if (message.amount != null && message.hasOwnProperty("amount")) {
                            var error = $root.visor.common.money.Amount.verify(message.amount);
                            if (error)
                                return "amount." + error;
                        }
                        if (message.enrollmentPeriod != null && message.hasOwnProperty("enrollmentPeriod")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.enrollmentPeriod);
                            if (error)
                                return "enrollmentPeriod." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a PremiumPayment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment} PremiumPayment
                     */
                    PremiumPayment.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment)
                            return object;
                        var message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment();
                        if (object.paymentAccountInfo != null)
                            message.paymentAccountInfo = String(object.paymentAccountInfo);
                        if (object.amount != null) {
                            if (typeof object.amount !== "object")
                                throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.amount: object expected");
                            message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                        }
                        if (object.enrollmentPeriod != null) {
                            if (typeof object.enrollmentPeriod !== "object")
                                throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment.enrollmentPeriod: object expected");
                            message.enrollmentPeriod = $root.visor.common.strings.LocalizedString.fromObject(object.enrollmentPeriod);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PremiumPayment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment} message PremiumPayment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PremiumPayment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.paymentAccountInfo = "";
                            object.amount = null;
                            object.enrollmentPeriod = null;
                        }
                        if (message.paymentAccountInfo != null && message.hasOwnProperty("paymentAccountInfo"))
                            object.paymentAccountInfo = message.paymentAccountInfo;
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                        if (message.enrollmentPeriod != null && message.hasOwnProperty("enrollmentPeriod"))
                            object.enrollmentPeriod = $root.visor.common.strings.LocalizedString.toObject(message.enrollmentPeriod, options);
                        return object;
                    };

                    /**
                     * Converts this PremiumPayment to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PremiumPayment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PremiumPayment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PremiumPayment;
                })();

                IncomeProtectionHistoryEvent.PolicyCanceled = (function() {

                    /**
                     * Properties of a PolicyCanceled.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IPolicyCanceled
                     */

                    /**
                     * Constructs a new PolicyCanceled.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents a PolicyCanceled.
                     * @implements IPolicyCanceled
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPolicyCanceled=} [properties] Properties to set
                     */
                    function PolicyCanceled(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new PolicyCanceled instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPolicyCanceled=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled} PolicyCanceled instance
                     */
                    PolicyCanceled.create = function create(properties) {
                        return new PolicyCanceled(properties);
                    };

                    /**
                     * Encodes the specified PolicyCanceled message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPolicyCanceled} message PolicyCanceled message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PolicyCanceled.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified PolicyCanceled message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IPolicyCanceled} message PolicyCanceled message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PolicyCanceled.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PolicyCanceled message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled} PolicyCanceled
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PolicyCanceled.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PolicyCanceled message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled} PolicyCanceled
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PolicyCanceled.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PolicyCanceled message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PolicyCanceled.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a PolicyCanceled message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled} PolicyCanceled
                     */
                    PolicyCanceled.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled)
                            return object;
                        return new $root.visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled();
                    };

                    /**
                     * Creates a plain object from a PolicyCanceled message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled} message PolicyCanceled
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PolicyCanceled.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this PolicyCanceled to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.PolicyCanceled
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PolicyCanceled.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PolicyCanceled;
                })();

                IncomeProtectionHistoryEvent.ClaimSubmitted = (function() {

                    /**
                     * Properties of a ClaimSubmitted.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IClaimSubmitted
                     * @property {string|null} [payoutAccountInfo] ClaimSubmitted payoutAccountInfo
                     * @property {visor.common.money.IAmount|null} [benefitAmount] ClaimSubmitted benefitAmount
                     */

                    /**
                     * Constructs a new ClaimSubmitted.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents a ClaimSubmitted.
                     * @implements IClaimSubmitted
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimSubmitted=} [properties] Properties to set
                     */
                    function ClaimSubmitted(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ClaimSubmitted payoutAccountInfo.
                     * @member {string} payoutAccountInfo
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @instance
                     */
                    ClaimSubmitted.prototype.payoutAccountInfo = "";

                    /**
                     * ClaimSubmitted benefitAmount.
                     * @member {visor.common.money.IAmount|null|undefined} benefitAmount
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @instance
                     */
                    ClaimSubmitted.prototype.benefitAmount = null;

                    /**
                     * Creates a new ClaimSubmitted instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimSubmitted=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted} ClaimSubmitted instance
                     */
                    ClaimSubmitted.create = function create(properties) {
                        return new ClaimSubmitted(properties);
                    };

                    /**
                     * Encodes the specified ClaimSubmitted message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimSubmitted} message ClaimSubmitted message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ClaimSubmitted.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.payoutAccountInfo != null && message.hasOwnProperty("payoutAccountInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.payoutAccountInfo);
                        if (message.benefitAmount != null && message.hasOwnProperty("benefitAmount"))
                            $root.visor.common.money.Amount.encode(message.benefitAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ClaimSubmitted message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimSubmitted} message ClaimSubmitted message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ClaimSubmitted.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ClaimSubmitted message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted} ClaimSubmitted
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ClaimSubmitted.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.payoutAccountInfo = reader.string();
                                break;
                            case 2:
                                message.benefitAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ClaimSubmitted message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted} ClaimSubmitted
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ClaimSubmitted.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ClaimSubmitted message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ClaimSubmitted.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.payoutAccountInfo != null && message.hasOwnProperty("payoutAccountInfo"))
                            if (!$util.isString(message.payoutAccountInfo))
                                return "payoutAccountInfo: string expected";
                        if (message.benefitAmount != null && message.hasOwnProperty("benefitAmount")) {
                            var error = $root.visor.common.money.Amount.verify(message.benefitAmount);
                            if (error)
                                return "benefitAmount." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ClaimSubmitted message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted} ClaimSubmitted
                     */
                    ClaimSubmitted.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted)
                            return object;
                        var message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted();
                        if (object.payoutAccountInfo != null)
                            message.payoutAccountInfo = String(object.payoutAccountInfo);
                        if (object.benefitAmount != null) {
                            if (typeof object.benefitAmount !== "object")
                                throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted.benefitAmount: object expected");
                            message.benefitAmount = $root.visor.common.money.Amount.fromObject(object.benefitAmount);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ClaimSubmitted message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted} message ClaimSubmitted
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ClaimSubmitted.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.payoutAccountInfo = "";
                            object.benefitAmount = null;
                        }
                        if (message.payoutAccountInfo != null && message.hasOwnProperty("payoutAccountInfo"))
                            object.payoutAccountInfo = message.payoutAccountInfo;
                        if (message.benefitAmount != null && message.hasOwnProperty("benefitAmount"))
                            object.benefitAmount = $root.visor.common.money.Amount.toObject(message.benefitAmount, options);
                        return object;
                    };

                    /**
                     * Converts this ClaimSubmitted to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimSubmitted
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ClaimSubmitted.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ClaimSubmitted;
                })();

                IncomeProtectionHistoryEvent.BenefitPayout = (function() {

                    /**
                     * Properties of a BenefitPayout.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IBenefitPayout
                     * @property {string|null} [payoutAccountInfo] BenefitPayout payoutAccountInfo
                     * @property {visor.common.money.IAmount|null} [benefitAmount] BenefitPayout benefitAmount
                     */

                    /**
                     * Constructs a new BenefitPayout.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents a BenefitPayout.
                     * @implements IBenefitPayout
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IBenefitPayout=} [properties] Properties to set
                     */
                    function BenefitPayout(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * BenefitPayout payoutAccountInfo.
                     * @member {string} payoutAccountInfo
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @instance
                     */
                    BenefitPayout.prototype.payoutAccountInfo = "";

                    /**
                     * BenefitPayout benefitAmount.
                     * @member {visor.common.money.IAmount|null|undefined} benefitAmount
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @instance
                     */
                    BenefitPayout.prototype.benefitAmount = null;

                    /**
                     * Creates a new BenefitPayout instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IBenefitPayout=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout} BenefitPayout instance
                     */
                    BenefitPayout.create = function create(properties) {
                        return new BenefitPayout(properties);
                    };

                    /**
                     * Encodes the specified BenefitPayout message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IBenefitPayout} message BenefitPayout message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BenefitPayout.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.payoutAccountInfo != null && message.hasOwnProperty("payoutAccountInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.payoutAccountInfo);
                        if (message.benefitAmount != null && message.hasOwnProperty("benefitAmount"))
                            $root.visor.common.money.Amount.encode(message.benefitAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified BenefitPayout message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IBenefitPayout} message BenefitPayout message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BenefitPayout.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a BenefitPayout message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout} BenefitPayout
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BenefitPayout.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.payoutAccountInfo = reader.string();
                                break;
                            case 2:
                                message.benefitAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a BenefitPayout message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout} BenefitPayout
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BenefitPayout.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a BenefitPayout message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BenefitPayout.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.payoutAccountInfo != null && message.hasOwnProperty("payoutAccountInfo"))
                            if (!$util.isString(message.payoutAccountInfo))
                                return "payoutAccountInfo: string expected";
                        if (message.benefitAmount != null && message.hasOwnProperty("benefitAmount")) {
                            var error = $root.visor.common.money.Amount.verify(message.benefitAmount);
                            if (error)
                                return "benefitAmount." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a BenefitPayout message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout} BenefitPayout
                     */
                    BenefitPayout.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout)
                            return object;
                        var message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout();
                        if (object.payoutAccountInfo != null)
                            message.payoutAccountInfo = String(object.payoutAccountInfo);
                        if (object.benefitAmount != null) {
                            if (typeof object.benefitAmount !== "object")
                                throw TypeError(".visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout.benefitAmount: object expected");
                            message.benefitAmount = $root.visor.common.money.Amount.fromObject(object.benefitAmount);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a BenefitPayout message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout} message BenefitPayout
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BenefitPayout.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.payoutAccountInfo = "";
                            object.benefitAmount = null;
                        }
                        if (message.payoutAccountInfo != null && message.hasOwnProperty("payoutAccountInfo"))
                            object.payoutAccountInfo = message.payoutAccountInfo;
                        if (message.benefitAmount != null && message.hasOwnProperty("benefitAmount"))
                            object.benefitAmount = $root.visor.common.money.Amount.toObject(message.benefitAmount, options);
                        return object;
                    };

                    /**
                     * Converts this BenefitPayout to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.BenefitPayout
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BenefitPayout.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return BenefitPayout;
                })();

                IncomeProtectionHistoryEvent.ClaimRejected = (function() {

                    /**
                     * Properties of a ClaimRejected.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @interface IClaimRejected
                     */

                    /**
                     * Constructs a new ClaimRejected.
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent
                     * @classdesc Represents a ClaimRejected.
                     * @implements IClaimRejected
                     * @constructor
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimRejected=} [properties] Properties to set
                     */
                    function ClaimRejected(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new ClaimRejected instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimRejected=} [properties] Properties to set
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected} ClaimRejected instance
                     */
                    ClaimRejected.create = function create(properties) {
                        return new ClaimRejected(properties);
                    };

                    /**
                     * Encodes the specified ClaimRejected message. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimRejected} message ClaimRejected message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ClaimRejected.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified ClaimRejected message, length delimited. Does not implicitly {@link visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.IClaimRejected} message ClaimRejected message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ClaimRejected.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ClaimRejected message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected} ClaimRejected
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ClaimRejected.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ClaimRejected message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected} ClaimRejected
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ClaimRejected.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ClaimRejected message.
                     * @function verify
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ClaimRejected.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a ClaimRejected message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected} ClaimRejected
                     */
                    ClaimRejected.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected)
                            return object;
                        return new $root.visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected();
                    };

                    /**
                     * Creates a plain object from a ClaimRejected message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @static
                     * @param {visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected} message ClaimRejected
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ClaimRejected.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this ClaimRejected to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IncomeProtectionHistoryEvent.ClaimRejected
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ClaimRejected.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ClaimRejected;
                })();

                /**
                 * Status enum.
                 * @name visor.clientrpc.IncomeProtectionHistoryEvent.Status
                 * @enum {string}
                 * @property {number} INCOME_PROTECTION_HISTORY_EVENT_STATUS_INVALID=0 INCOME_PROTECTION_HISTORY_EVENT_STATUS_INVALID value
                 * @property {number} INCOME_PROTECTION_HISTORY_EVENT_STATUS_PENDING=1 INCOME_PROTECTION_HISTORY_EVENT_STATUS_PENDING value
                 * @property {number} INCOME_PROTECTION_HISTORY_EVENT_STATUS_COMPLETE=2 INCOME_PROTECTION_HISTORY_EVENT_STATUS_COMPLETE value
                 * @property {number} INCOME_PROTECTION_HISTORY_EVENT_STATUS_FAILED=3 INCOME_PROTECTION_HISTORY_EVENT_STATUS_FAILED value
                 * @property {number} INCOME_PROTECTION_HISTORY_EVENT_STATUS_REFUNDED=4 INCOME_PROTECTION_HISTORY_EVENT_STATUS_REFUNDED value
                 * @property {number} INCOME_PROTECTION_HISTORY_EVENT_STATUS_DISPUTED=5 INCOME_PROTECTION_HISTORY_EVENT_STATUS_DISPUTED value
                 * @property {number} INCOME_PROTECTION_HISTORY_EVENT_STATUS_FREE_MONTH=6 INCOME_PROTECTION_HISTORY_EVENT_STATUS_FREE_MONTH value
                 */
                IncomeProtectionHistoryEvent.Status = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "INCOME_PROTECTION_HISTORY_EVENT_STATUS_INVALID"] = 0;
                    values[valuesById[1] = "INCOME_PROTECTION_HISTORY_EVENT_STATUS_PENDING"] = 1;
                    values[valuesById[2] = "INCOME_PROTECTION_HISTORY_EVENT_STATUS_COMPLETE"] = 2;
                    values[valuesById[3] = "INCOME_PROTECTION_HISTORY_EVENT_STATUS_FAILED"] = 3;
                    values[valuesById[4] = "INCOME_PROTECTION_HISTORY_EVENT_STATUS_REFUNDED"] = 4;
                    values[valuesById[5] = "INCOME_PROTECTION_HISTORY_EVENT_STATUS_DISPUTED"] = 5;
                    values[valuesById[6] = "INCOME_PROTECTION_HISTORY_EVENT_STATUS_FREE_MONTH"] = 6;
                    return values;
                })();

                return IncomeProtectionHistoryEvent;
            })();

            clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationDocumentsUploadIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @property {string|null} [stepToken] CompleteClaimsApplicationDocumentsUploadIntroStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteClaimsApplicationDocumentsUploadIntroStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationDocumentsUploadIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationDocumentsUploadIntroStepRequest.
                 * @implements ICompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepRequest=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationDocumentsUploadIntroStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteClaimsApplicationDocumentsUploadIntroStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.prototype.stepToken = "";

                /**
                 * CompleteClaimsApplicationDocumentsUploadIntroStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteClaimsApplicationDocumentsUploadIntroStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest} CompleteClaimsApplicationDocumentsUploadIntroStepRequest instance
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.create = function create(properties) {
                    return new CompleteClaimsApplicationDocumentsUploadIntroStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationDocumentsUploadIntroStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepRequest} message CompleteClaimsApplicationDocumentsUploadIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationDocumentsUploadIntroStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepRequest} message CompleteClaimsApplicationDocumentsUploadIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationDocumentsUploadIntroStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest} CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationDocumentsUploadIntroStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest} CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationDocumentsUploadIntroStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationDocumentsUploadIntroStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest} CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationDocumentsUploadIntroStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest} message CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteClaimsApplicationDocumentsUploadIntroStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationDocumentsUploadIntroStepRequest;
            })();

            clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationDocumentsUploadIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationDocumentsUploadIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationDocumentsUploadIntroStepResponse.
                 * @implements ICompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepResponse=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationDocumentsUploadIntroStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteClaimsApplicationDocumentsUploadIntroStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse} CompleteClaimsApplicationDocumentsUploadIntroStepResponse instance
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.create = function create(properties) {
                    return new CompleteClaimsApplicationDocumentsUploadIntroStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationDocumentsUploadIntroStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepResponse} message CompleteClaimsApplicationDocumentsUploadIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationDocumentsUploadIntroStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationDocumentsUploadIntroStepResponse} message CompleteClaimsApplicationDocumentsUploadIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationDocumentsUploadIntroStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse} CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationDocumentsUploadIntroStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse} CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationDocumentsUploadIntroStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationDocumentsUploadIntroStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse} CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationDocumentsUploadIntroStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse} message CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteClaimsApplicationDocumentsUploadIntroStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationDocumentsUploadIntroStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationDocumentsUploadIntroStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationDocumentsUploadIntroStepResponse;
            })();

            clientrpc.CompleteClaimsApplicationHowItWorksStepRequest = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationHowItWorksStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationHowItWorksStepRequest
                 * @property {string|null} [stepToken] CompleteClaimsApplicationHowItWorksStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteClaimsApplicationHowItWorksStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationHowItWorksStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationHowItWorksStepRequest.
                 * @implements ICompleteClaimsApplicationHowItWorksStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepRequest=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationHowItWorksStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteClaimsApplicationHowItWorksStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.prototype.stepToken = "";

                /**
                 * CompleteClaimsApplicationHowItWorksStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteClaimsApplicationHowItWorksStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest} CompleteClaimsApplicationHowItWorksStepRequest instance
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.create = function create(properties) {
                    return new CompleteClaimsApplicationHowItWorksStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationHowItWorksStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepRequest} message CompleteClaimsApplicationHowItWorksStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationHowItWorksStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepRequest} message CompleteClaimsApplicationHowItWorksStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationHowItWorksStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest} CompleteClaimsApplicationHowItWorksStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationHowItWorksStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest} CompleteClaimsApplicationHowItWorksStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationHowItWorksStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationHowItWorksStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest} CompleteClaimsApplicationHowItWorksStepRequest
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationHowItWorksStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest} message CompleteClaimsApplicationHowItWorksStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteClaimsApplicationHowItWorksStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationHowItWorksStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationHowItWorksStepRequest;
            })();

            clientrpc.CompleteClaimsApplicationHowItWorksStepResponse = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationHowItWorksStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationHowItWorksStepResponse
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationHowItWorksStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationHowItWorksStepResponse.
                 * @implements ICompleteClaimsApplicationHowItWorksStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepResponse=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationHowItWorksStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteClaimsApplicationHowItWorksStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse} CompleteClaimsApplicationHowItWorksStepResponse instance
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.create = function create(properties) {
                    return new CompleteClaimsApplicationHowItWorksStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationHowItWorksStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepResponse} message CompleteClaimsApplicationHowItWorksStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationHowItWorksStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationHowItWorksStepResponse} message CompleteClaimsApplicationHowItWorksStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationHowItWorksStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse} CompleteClaimsApplicationHowItWorksStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationHowItWorksStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse} CompleteClaimsApplicationHowItWorksStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationHowItWorksStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationHowItWorksStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse} CompleteClaimsApplicationHowItWorksStepResponse
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationHowItWorksStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse} message CompleteClaimsApplicationHowItWorksStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteClaimsApplicationHowItWorksStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationHowItWorksStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationHowItWorksStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationHowItWorksStepResponse;
            })();

            clientrpc.CompleteClaimsApplicationIntroStepRequest = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationIntroStepRequest
                 * @property {string|null} [stepToken] CompleteClaimsApplicationIntroStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteClaimsApplicationIntroStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationIntroStepRequest.
                 * @implements ICompleteClaimsApplicationIntroStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepRequest=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationIntroStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteClaimsApplicationIntroStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationIntroStepRequest.prototype.stepToken = "";

                /**
                 * CompleteClaimsApplicationIntroStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @instance
                 */
                CompleteClaimsApplicationIntroStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteClaimsApplicationIntroStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepRequest} CompleteClaimsApplicationIntroStepRequest instance
                 */
                CompleteClaimsApplicationIntroStepRequest.create = function create(properties) {
                    return new CompleteClaimsApplicationIntroStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationIntroStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationIntroStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepRequest} message CompleteClaimsApplicationIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationIntroStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationIntroStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationIntroStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepRequest} message CompleteClaimsApplicationIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationIntroStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationIntroStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepRequest} CompleteClaimsApplicationIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationIntroStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationIntroStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationIntroStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepRequest} CompleteClaimsApplicationIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationIntroStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationIntroStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationIntroStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationIntroStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepRequest} CompleteClaimsApplicationIntroStepRequest
                 */
                CompleteClaimsApplicationIntroStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationIntroStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteClaimsApplicationIntroStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationIntroStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationIntroStepRequest} message CompleteClaimsApplicationIntroStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationIntroStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteClaimsApplicationIntroStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationIntroStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationIntroStepRequest;
            })();

            clientrpc.CompleteClaimsApplicationIntroStepResponse = (function() {

                /**
                 * Properties of a CompleteClaimsApplicationIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteClaimsApplicationIntroStepResponse
                 */

                /**
                 * Constructs a new CompleteClaimsApplicationIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteClaimsApplicationIntroStepResponse.
                 * @implements ICompleteClaimsApplicationIntroStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepResponse=} [properties] Properties to set
                 */
                function CompleteClaimsApplicationIntroStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteClaimsApplicationIntroStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepResponse} CompleteClaimsApplicationIntroStepResponse instance
                 */
                CompleteClaimsApplicationIntroStepResponse.create = function create(properties) {
                    return new CompleteClaimsApplicationIntroStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationIntroStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationIntroStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepResponse} message CompleteClaimsApplicationIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationIntroStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteClaimsApplicationIntroStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteClaimsApplicationIntroStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteClaimsApplicationIntroStepResponse} message CompleteClaimsApplicationIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteClaimsApplicationIntroStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteClaimsApplicationIntroStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepResponse} CompleteClaimsApplicationIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationIntroStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteClaimsApplicationIntroStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteClaimsApplicationIntroStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepResponse} CompleteClaimsApplicationIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteClaimsApplicationIntroStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteClaimsApplicationIntroStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteClaimsApplicationIntroStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteClaimsApplicationIntroStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteClaimsApplicationIntroStepResponse} CompleteClaimsApplicationIntroStepResponse
                 */
                CompleteClaimsApplicationIntroStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteClaimsApplicationIntroStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteClaimsApplicationIntroStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteClaimsApplicationIntroStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteClaimsApplicationIntroStepResponse} message CompleteClaimsApplicationIntroStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteClaimsApplicationIntroStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteClaimsApplicationIntroStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteClaimsApplicationIntroStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteClaimsApplicationIntroStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteClaimsApplicationIntroStepResponse;
            })();

            clientrpc.BeginClaimsApplicationWorkflowRequest = (function() {

                /**
                 * Properties of a BeginClaimsApplicationWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginClaimsApplicationWorkflowRequest
                 * @property {string|null} [policyToken] BeginClaimsApplicationWorkflowRequest policyToken
                 */

                /**
                 * Constructs a new BeginClaimsApplicationWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginClaimsApplicationWorkflowRequest.
                 * @implements IBeginClaimsApplicationWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowRequest=} [properties] Properties to set
                 */
                function BeginClaimsApplicationWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BeginClaimsApplicationWorkflowRequest policyToken.
                 * @member {string} policyToken
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @instance
                 */
                BeginClaimsApplicationWorkflowRequest.prototype.policyToken = "";

                /**
                 * Creates a new BeginClaimsApplicationWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowRequest} BeginClaimsApplicationWorkflowRequest instance
                 */
                BeginClaimsApplicationWorkflowRequest.create = function create(properties) {
                    return new BeginClaimsApplicationWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginClaimsApplicationWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginClaimsApplicationWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowRequest} message BeginClaimsApplicationWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginClaimsApplicationWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.policyToken);
                    return writer;
                };

                /**
                 * Encodes the specified BeginClaimsApplicationWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginClaimsApplicationWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowRequest} message BeginClaimsApplicationWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginClaimsApplicationWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginClaimsApplicationWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowRequest} BeginClaimsApplicationWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginClaimsApplicationWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginClaimsApplicationWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.policyToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginClaimsApplicationWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowRequest} BeginClaimsApplicationWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginClaimsApplicationWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginClaimsApplicationWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginClaimsApplicationWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        if (!$util.isString(message.policyToken))
                            return "policyToken: string expected";
                    return null;
                };

                /**
                 * Creates a BeginClaimsApplicationWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowRequest} BeginClaimsApplicationWorkflowRequest
                 */
                BeginClaimsApplicationWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginClaimsApplicationWorkflowRequest)
                        return object;
                    var message = new $root.visor.clientrpc.BeginClaimsApplicationWorkflowRequest();
                    if (object.policyToken != null)
                        message.policyToken = String(object.policyToken);
                    return message;
                };

                /**
                 * Creates a plain object from a BeginClaimsApplicationWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginClaimsApplicationWorkflowRequest} message BeginClaimsApplicationWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginClaimsApplicationWorkflowRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.policyToken = "";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        object.policyToken = message.policyToken;
                    return object;
                };

                /**
                 * Converts this BeginClaimsApplicationWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginClaimsApplicationWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginClaimsApplicationWorkflowRequest;
            })();

            clientrpc.BeginClaimsApplicationWorkflowResponse = (function() {

                /**
                 * Properties of a BeginClaimsApplicationWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginClaimsApplicationWorkflowResponse
                 */

                /**
                 * Constructs a new BeginClaimsApplicationWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginClaimsApplicationWorkflowResponse.
                 * @implements IBeginClaimsApplicationWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowResponse=} [properties] Properties to set
                 */
                function BeginClaimsApplicationWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginClaimsApplicationWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowResponse} BeginClaimsApplicationWorkflowResponse instance
                 */
                BeginClaimsApplicationWorkflowResponse.create = function create(properties) {
                    return new BeginClaimsApplicationWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginClaimsApplicationWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginClaimsApplicationWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowResponse} message BeginClaimsApplicationWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginClaimsApplicationWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginClaimsApplicationWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginClaimsApplicationWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginClaimsApplicationWorkflowResponse} message BeginClaimsApplicationWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginClaimsApplicationWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginClaimsApplicationWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowResponse} BeginClaimsApplicationWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginClaimsApplicationWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginClaimsApplicationWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginClaimsApplicationWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowResponse} BeginClaimsApplicationWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginClaimsApplicationWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginClaimsApplicationWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginClaimsApplicationWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginClaimsApplicationWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginClaimsApplicationWorkflowResponse} BeginClaimsApplicationWorkflowResponse
                 */
                BeginClaimsApplicationWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginClaimsApplicationWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginClaimsApplicationWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginClaimsApplicationWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginClaimsApplicationWorkflowResponse} message BeginClaimsApplicationWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginClaimsApplicationWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginClaimsApplicationWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginClaimsApplicationWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginClaimsApplicationWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginClaimsApplicationWorkflowResponse;
            })();

            clientrpc.CompleteUnemploymentHowItWorksStepRequest = (function() {

                /**
                 * Properties of a CompleteUnemploymentHowItWorksStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteUnemploymentHowItWorksStepRequest
                 * @property {string|null} [stepToken] CompleteUnemploymentHowItWorksStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteUnemploymentHowItWorksStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteUnemploymentHowItWorksStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteUnemploymentHowItWorksStepRequest.
                 * @implements ICompleteUnemploymentHowItWorksStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepRequest=} [properties] Properties to set
                 */
                function CompleteUnemploymentHowItWorksStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteUnemploymentHowItWorksStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @instance
                 */
                CompleteUnemploymentHowItWorksStepRequest.prototype.stepToken = "";

                /**
                 * CompleteUnemploymentHowItWorksStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @instance
                 */
                CompleteUnemploymentHowItWorksStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteUnemploymentHowItWorksStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest} CompleteUnemploymentHowItWorksStepRequest instance
                 */
                CompleteUnemploymentHowItWorksStepRequest.create = function create(properties) {
                    return new CompleteUnemploymentHowItWorksStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteUnemploymentHowItWorksStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepRequest} message CompleteUnemploymentHowItWorksStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentHowItWorksStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteUnemploymentHowItWorksStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepRequest} message CompleteUnemploymentHowItWorksStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentHowItWorksStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteUnemploymentHowItWorksStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest} CompleteUnemploymentHowItWorksStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentHowItWorksStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteUnemploymentHowItWorksStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest} CompleteUnemploymentHowItWorksStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentHowItWorksStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteUnemploymentHowItWorksStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteUnemploymentHowItWorksStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteUnemploymentHowItWorksStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest} CompleteUnemploymentHowItWorksStepRequest
                 */
                CompleteUnemploymentHowItWorksStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteUnemploymentHowItWorksStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest} message CompleteUnemploymentHowItWorksStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteUnemploymentHowItWorksStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteUnemploymentHowItWorksStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteUnemploymentHowItWorksStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteUnemploymentHowItWorksStepRequest;
            })();

            clientrpc.CompleteUnemploymentHowItWorksStepResponse = (function() {

                /**
                 * Properties of a CompleteUnemploymentHowItWorksStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteUnemploymentHowItWorksStepResponse
                 */

                /**
                 * Constructs a new CompleteUnemploymentHowItWorksStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteUnemploymentHowItWorksStepResponse.
                 * @implements ICompleteUnemploymentHowItWorksStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepResponse=} [properties] Properties to set
                 */
                function CompleteUnemploymentHowItWorksStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteUnemploymentHowItWorksStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse} CompleteUnemploymentHowItWorksStepResponse instance
                 */
                CompleteUnemploymentHowItWorksStepResponse.create = function create(properties) {
                    return new CompleteUnemploymentHowItWorksStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteUnemploymentHowItWorksStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepResponse} message CompleteUnemploymentHowItWorksStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentHowItWorksStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteUnemploymentHowItWorksStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentHowItWorksStepResponse} message CompleteUnemploymentHowItWorksStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentHowItWorksStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteUnemploymentHowItWorksStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse} CompleteUnemploymentHowItWorksStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentHowItWorksStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteUnemploymentHowItWorksStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse} CompleteUnemploymentHowItWorksStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentHowItWorksStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteUnemploymentHowItWorksStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteUnemploymentHowItWorksStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteUnemploymentHowItWorksStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse} CompleteUnemploymentHowItWorksStepResponse
                 */
                CompleteUnemploymentHowItWorksStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteUnemploymentHowItWorksStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse} message CompleteUnemploymentHowItWorksStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteUnemploymentHowItWorksStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteUnemploymentHowItWorksStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteUnemploymentHowItWorksStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteUnemploymentHowItWorksStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteUnemploymentHowItWorksStepResponse;
            })();

            clientrpc.ReopenPolicyRequest = (function() {

                /**
                 * Properties of a ReopenPolicyRequest.
                 * @memberof visor.clientrpc
                 * @interface IReopenPolicyRequest
                 * @property {string|null} [policyToken] ReopenPolicyRequest policyToken
                 */

                /**
                 * Constructs a new ReopenPolicyRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReopenPolicyRequest.
                 * @implements IReopenPolicyRequest
                 * @constructor
                 * @param {visor.clientrpc.IReopenPolicyRequest=} [properties] Properties to set
                 */
                function ReopenPolicyRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReopenPolicyRequest policyToken.
                 * @member {string} policyToken
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @instance
                 */
                ReopenPolicyRequest.prototype.policyToken = "";

                /**
                 * Creates a new ReopenPolicyRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {visor.clientrpc.IReopenPolicyRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReopenPolicyRequest} ReopenPolicyRequest instance
                 */
                ReopenPolicyRequest.create = function create(properties) {
                    return new ReopenPolicyRequest(properties);
                };

                /**
                 * Encodes the specified ReopenPolicyRequest message. Does not implicitly {@link visor.clientrpc.ReopenPolicyRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {visor.clientrpc.IReopenPolicyRequest} message ReopenPolicyRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReopenPolicyRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.policyToken);
                    return writer;
                };

                /**
                 * Encodes the specified ReopenPolicyRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ReopenPolicyRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {visor.clientrpc.IReopenPolicyRequest} message ReopenPolicyRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReopenPolicyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReopenPolicyRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReopenPolicyRequest} ReopenPolicyRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReopenPolicyRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReopenPolicyRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.policyToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReopenPolicyRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReopenPolicyRequest} ReopenPolicyRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReopenPolicyRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReopenPolicyRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReopenPolicyRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        if (!$util.isString(message.policyToken))
                            return "policyToken: string expected";
                    return null;
                };

                /**
                 * Creates a ReopenPolicyRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReopenPolicyRequest} ReopenPolicyRequest
                 */
                ReopenPolicyRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReopenPolicyRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ReopenPolicyRequest();
                    if (object.policyToken != null)
                        message.policyToken = String(object.policyToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ReopenPolicyRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @static
                 * @param {visor.clientrpc.ReopenPolicyRequest} message ReopenPolicyRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReopenPolicyRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.policyToken = "";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        object.policyToken = message.policyToken;
                    return object;
                };

                /**
                 * Converts this ReopenPolicyRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReopenPolicyRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReopenPolicyRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReopenPolicyRequest;
            })();

            clientrpc.ReopenPolicyResponse = (function() {

                /**
                 * Properties of a ReopenPolicyResponse.
                 * @memberof visor.clientrpc
                 * @interface IReopenPolicyResponse
                 * @property {visor.clientrpc.ReopenPolicyResponse.Result|null} [successful] ReopenPolicyResponse successful
                 * @property {visor.clientrpc.ReopenPolicyResponse.Result|null} [failure] ReopenPolicyResponse failure
                 */

                /**
                 * Constructs a new ReopenPolicyResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReopenPolicyResponse.
                 * @implements IReopenPolicyResponse
                 * @constructor
                 * @param {visor.clientrpc.IReopenPolicyResponse=} [properties] Properties to set
                 */
                function ReopenPolicyResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReopenPolicyResponse successful.
                 * @member {visor.clientrpc.ReopenPolicyResponse.Result} successful
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @instance
                 */
                ReopenPolicyResponse.prototype.successful = 0;

                /**
                 * ReopenPolicyResponse failure.
                 * @member {visor.clientrpc.ReopenPolicyResponse.Result} failure
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @instance
                 */
                ReopenPolicyResponse.prototype.failure = 0;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ReopenPolicyResponse result.
                 * @member {"successful"|"failure"|undefined} result
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @instance
                 */
                Object.defineProperty(ReopenPolicyResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["successful", "failure"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ReopenPolicyResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {visor.clientrpc.IReopenPolicyResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReopenPolicyResponse} ReopenPolicyResponse instance
                 */
                ReopenPolicyResponse.create = function create(properties) {
                    return new ReopenPolicyResponse(properties);
                };

                /**
                 * Encodes the specified ReopenPolicyResponse message. Does not implicitly {@link visor.clientrpc.ReopenPolicyResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {visor.clientrpc.IReopenPolicyResponse} message ReopenPolicyResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReopenPolicyResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.successful != null && message.hasOwnProperty("successful"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.successful);
                    if (message.failure != null && message.hasOwnProperty("failure"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.failure);
                    return writer;
                };

                /**
                 * Encodes the specified ReopenPolicyResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ReopenPolicyResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {visor.clientrpc.IReopenPolicyResponse} message ReopenPolicyResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReopenPolicyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReopenPolicyResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReopenPolicyResponse} ReopenPolicyResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReopenPolicyResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReopenPolicyResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.successful = reader.int32();
                            break;
                        case 2:
                            message.failure = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReopenPolicyResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReopenPolicyResponse} ReopenPolicyResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReopenPolicyResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReopenPolicyResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReopenPolicyResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.successful != null && message.hasOwnProperty("successful")) {
                        properties.result = 1;
                        switch (message.successful) {
                        default:
                            return "successful: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        switch (message.failure) {
                        default:
                            return "failure: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ReopenPolicyResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReopenPolicyResponse} ReopenPolicyResponse
                 */
                ReopenPolicyResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReopenPolicyResponse)
                        return object;
                    var message = new $root.visor.clientrpc.ReopenPolicyResponse();
                    switch (object.successful) {
                    case "REOPEN_POLICY_RESPONSE_RESULT_INVALID":
                    case 0:
                        message.successful = 0;
                        break;
                    case "REOPEN_POLICY_RESPONSE_RESULT_SUCCESS":
                    case 1:
                        message.successful = 1;
                        break;
                    case "REOPEN_POLICY_RESPONSE_RESULT_FAILED":
                    case 2:
                        message.successful = 2;
                        break;
                    }
                    switch (object.failure) {
                    case "REOPEN_POLICY_RESPONSE_RESULT_INVALID":
                    case 0:
                        message.failure = 0;
                        break;
                    case "REOPEN_POLICY_RESPONSE_RESULT_SUCCESS":
                    case 1:
                        message.failure = 1;
                        break;
                    case "REOPEN_POLICY_RESPONSE_RESULT_FAILED":
                    case 2:
                        message.failure = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReopenPolicyResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @static
                 * @param {visor.clientrpc.ReopenPolicyResponse} message ReopenPolicyResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReopenPolicyResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.successful != null && message.hasOwnProperty("successful")) {
                        object.successful = options.enums === String ? $root.visor.clientrpc.ReopenPolicyResponse.Result[message.successful] : message.successful;
                        if (options.oneofs)
                            object.result = "successful";
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        object.failure = options.enums === String ? $root.visor.clientrpc.ReopenPolicyResponse.Result[message.failure] : message.failure;
                        if (options.oneofs)
                            object.result = "failure";
                    }
                    return object;
                };

                /**
                 * Converts this ReopenPolicyResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReopenPolicyResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReopenPolicyResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Result enum.
                 * @name visor.clientrpc.ReopenPolicyResponse.Result
                 * @enum {string}
                 * @property {number} REOPEN_POLICY_RESPONSE_RESULT_INVALID=0 REOPEN_POLICY_RESPONSE_RESULT_INVALID value
                 * @property {number} REOPEN_POLICY_RESPONSE_RESULT_SUCCESS=1 REOPEN_POLICY_RESPONSE_RESULT_SUCCESS value
                 * @property {number} REOPEN_POLICY_RESPONSE_RESULT_FAILED=2 REOPEN_POLICY_RESPONSE_RESULT_FAILED value
                 */
                ReopenPolicyResponse.Result = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REOPEN_POLICY_RESPONSE_RESULT_INVALID"] = 0;
                    values[valuesById[1] = "REOPEN_POLICY_RESPONSE_RESULT_SUCCESS"] = 1;
                    values[valuesById[2] = "REOPEN_POLICY_RESPONSE_RESULT_FAILED"] = 2;
                    return values;
                })();

                return ReopenPolicyResponse;
            })();

            clientrpc.ReportIncomeProtectionJobLossChangeRequest = (function() {

                /**
                 * Properties of a ReportIncomeProtectionJobLossChangeRequest.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionJobLossChangeRequest
                 * @property {visor.common.time.ILocalDate|null} [date] ReportIncomeProtectionJobLossChangeRequest date
                 */

                /**
                 * Constructs a new ReportIncomeProtectionJobLossChangeRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionJobLossChangeRequest.
                 * @implements IReportIncomeProtectionJobLossChangeRequest
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeRequest=} [properties] Properties to set
                 */
                function ReportIncomeProtectionJobLossChangeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReportIncomeProtectionJobLossChangeRequest date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @instance
                 */
                ReportIncomeProtectionJobLossChangeRequest.prototype.date = null;

                /**
                 * Creates a new ReportIncomeProtectionJobLossChangeRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest} ReportIncomeProtectionJobLossChangeRequest instance
                 */
                ReportIncomeProtectionJobLossChangeRequest.create = function create(properties) {
                    return new ReportIncomeProtectionJobLossChangeRequest(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionJobLossChangeRequest message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeRequest} message ReportIncomeProtectionJobLossChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionJobLossChangeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionJobLossChangeRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeRequest} message ReportIncomeProtectionJobLossChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionJobLossChangeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionJobLossChangeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest} ReportIncomeProtectionJobLossChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionJobLossChangeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionJobLossChangeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest} ReportIncomeProtectionJobLossChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionJobLossChangeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionJobLossChangeRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionJobLossChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionJobLossChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest} ReportIncomeProtectionJobLossChangeRequest
                 */
                ReportIncomeProtectionJobLossChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest();
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionJobLossChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest} message ReportIncomeProtectionJobLossChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionJobLossChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.date = null;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    return object;
                };

                /**
                 * Converts this ReportIncomeProtectionJobLossChangeRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionJobLossChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionJobLossChangeRequest;
            })();

            clientrpc.ReportIncomeProtectionJobLossChangeResponse = (function() {

                /**
                 * Properties of a ReportIncomeProtectionJobLossChangeResponse.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionJobLossChangeResponse
                 */

                /**
                 * Constructs a new ReportIncomeProtectionJobLossChangeResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionJobLossChangeResponse.
                 * @implements IReportIncomeProtectionJobLossChangeResponse
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeResponse=} [properties] Properties to set
                 */
                function ReportIncomeProtectionJobLossChangeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ReportIncomeProtectionJobLossChangeResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse} ReportIncomeProtectionJobLossChangeResponse instance
                 */
                ReportIncomeProtectionJobLossChangeResponse.create = function create(properties) {
                    return new ReportIncomeProtectionJobLossChangeResponse(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionJobLossChangeResponse message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeResponse} message ReportIncomeProtectionJobLossChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionJobLossChangeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionJobLossChangeResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionJobLossChangeResponse} message ReportIncomeProtectionJobLossChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionJobLossChangeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionJobLossChangeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse} ReportIncomeProtectionJobLossChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionJobLossChangeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionJobLossChangeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse} ReportIncomeProtectionJobLossChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionJobLossChangeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionJobLossChangeResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionJobLossChangeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionJobLossChangeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse} ReportIncomeProtectionJobLossChangeResponse
                 */
                ReportIncomeProtectionJobLossChangeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse)
                        return object;
                    return new $root.visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse();
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionJobLossChangeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse} message ReportIncomeProtectionJobLossChangeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionJobLossChangeResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReportIncomeProtectionJobLossChangeResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionJobLossChangeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionJobLossChangeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionJobLossChangeResponse;
            })();

            clientrpc.ReportIncomeProtectionRoleChangeRequest = (function() {

                /**
                 * Properties of a ReportIncomeProtectionRoleChangeRequest.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionRoleChangeRequest
                 * @property {boolean|null} [isDirector] ReportIncomeProtectionRoleChangeRequest isDirector
                 * @property {visor.common.time.ILocalDate|null} [date] ReportIncomeProtectionRoleChangeRequest date
                 */

                /**
                 * Constructs a new ReportIncomeProtectionRoleChangeRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionRoleChangeRequest.
                 * @implements IReportIncomeProtectionRoleChangeRequest
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeRequest=} [properties] Properties to set
                 */
                function ReportIncomeProtectionRoleChangeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReportIncomeProtectionRoleChangeRequest isDirector.
                 * @member {boolean} isDirector
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @instance
                 */
                ReportIncomeProtectionRoleChangeRequest.prototype.isDirector = false;

                /**
                 * ReportIncomeProtectionRoleChangeRequest date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @instance
                 */
                ReportIncomeProtectionRoleChangeRequest.prototype.date = null;

                /**
                 * Creates a new ReportIncomeProtectionRoleChangeRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeRequest} ReportIncomeProtectionRoleChangeRequest instance
                 */
                ReportIncomeProtectionRoleChangeRequest.create = function create(properties) {
                    return new ReportIncomeProtectionRoleChangeRequest(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionRoleChangeRequest message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionRoleChangeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeRequest} message ReportIncomeProtectionRoleChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionRoleChangeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isDirector != null && message.hasOwnProperty("isDirector"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isDirector);
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionRoleChangeRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionRoleChangeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeRequest} message ReportIncomeProtectionRoleChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionRoleChangeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionRoleChangeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeRequest} ReportIncomeProtectionRoleChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionRoleChangeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionRoleChangeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isDirector = reader.bool();
                            break;
                        case 2:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionRoleChangeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeRequest} ReportIncomeProtectionRoleChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionRoleChangeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionRoleChangeRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionRoleChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isDirector != null && message.hasOwnProperty("isDirector"))
                        if (typeof message.isDirector !== "boolean")
                            return "isDirector: boolean expected";
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionRoleChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeRequest} ReportIncomeProtectionRoleChangeRequest
                 */
                ReportIncomeProtectionRoleChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionRoleChangeRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ReportIncomeProtectionRoleChangeRequest();
                    if (object.isDirector != null)
                        message.isDirector = Boolean(object.isDirector);
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.ReportIncomeProtectionRoleChangeRequest.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionRoleChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionRoleChangeRequest} message ReportIncomeProtectionRoleChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionRoleChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isDirector = false;
                        object.date = null;
                    }
                    if (message.isDirector != null && message.hasOwnProperty("isDirector"))
                        object.isDirector = message.isDirector;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    return object;
                };

                /**
                 * Converts this ReportIncomeProtectionRoleChangeRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionRoleChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionRoleChangeRequest;
            })();

            clientrpc.ReportIncomeProtectionRoleChangeResponse = (function() {

                /**
                 * Properties of a ReportIncomeProtectionRoleChangeResponse.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionRoleChangeResponse
                 */

                /**
                 * Constructs a new ReportIncomeProtectionRoleChangeResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionRoleChangeResponse.
                 * @implements IReportIncomeProtectionRoleChangeResponse
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeResponse=} [properties] Properties to set
                 */
                function ReportIncomeProtectionRoleChangeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ReportIncomeProtectionRoleChangeResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeResponse} ReportIncomeProtectionRoleChangeResponse instance
                 */
                ReportIncomeProtectionRoleChangeResponse.create = function create(properties) {
                    return new ReportIncomeProtectionRoleChangeResponse(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionRoleChangeResponse message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionRoleChangeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeResponse} message ReportIncomeProtectionRoleChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionRoleChangeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionRoleChangeResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionRoleChangeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionRoleChangeResponse} message ReportIncomeProtectionRoleChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionRoleChangeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionRoleChangeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeResponse} ReportIncomeProtectionRoleChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionRoleChangeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionRoleChangeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionRoleChangeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeResponse} ReportIncomeProtectionRoleChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionRoleChangeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionRoleChangeResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionRoleChangeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionRoleChangeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionRoleChangeResponse} ReportIncomeProtectionRoleChangeResponse
                 */
                ReportIncomeProtectionRoleChangeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionRoleChangeResponse)
                        return object;
                    return new $root.visor.clientrpc.ReportIncomeProtectionRoleChangeResponse();
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionRoleChangeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionRoleChangeResponse} message ReportIncomeProtectionRoleChangeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionRoleChangeResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReportIncomeProtectionRoleChangeResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionRoleChangeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionRoleChangeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionRoleChangeResponse;
            })();

            clientrpc.ReportIncomeProtectionEmployerChangeRequest = (function() {

                /**
                 * Properties of a ReportIncomeProtectionEmployerChangeRequest.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionEmployerChangeRequest
                 * @property {visor.common.time.ILocalDate|null} [date] ReportIncomeProtectionEmployerChangeRequest date
                 */

                /**
                 * Constructs a new ReportIncomeProtectionEmployerChangeRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionEmployerChangeRequest.
                 * @implements IReportIncomeProtectionEmployerChangeRequest
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeRequest=} [properties] Properties to set
                 */
                function ReportIncomeProtectionEmployerChangeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReportIncomeProtectionEmployerChangeRequest date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @instance
                 */
                ReportIncomeProtectionEmployerChangeRequest.prototype.date = null;

                /**
                 * Creates a new ReportIncomeProtectionEmployerChangeRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest} ReportIncomeProtectionEmployerChangeRequest instance
                 */
                ReportIncomeProtectionEmployerChangeRequest.create = function create(properties) {
                    return new ReportIncomeProtectionEmployerChangeRequest(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionEmployerChangeRequest message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeRequest} message ReportIncomeProtectionEmployerChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionEmployerChangeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionEmployerChangeRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeRequest} message ReportIncomeProtectionEmployerChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionEmployerChangeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionEmployerChangeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest} ReportIncomeProtectionEmployerChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionEmployerChangeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionEmployerChangeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest} ReportIncomeProtectionEmployerChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionEmployerChangeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionEmployerChangeRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionEmployerChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionEmployerChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest} ReportIncomeProtectionEmployerChangeRequest
                 */
                ReportIncomeProtectionEmployerChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest();
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionEmployerChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest} message ReportIncomeProtectionEmployerChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionEmployerChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.date = null;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    return object;
                };

                /**
                 * Converts this ReportIncomeProtectionEmployerChangeRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionEmployerChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionEmployerChangeRequest;
            })();

            clientrpc.ReportIncomeProtectionEmployerChangeResponse = (function() {

                /**
                 * Properties of a ReportIncomeProtectionEmployerChangeResponse.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionEmployerChangeResponse
                 */

                /**
                 * Constructs a new ReportIncomeProtectionEmployerChangeResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionEmployerChangeResponse.
                 * @implements IReportIncomeProtectionEmployerChangeResponse
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeResponse=} [properties] Properties to set
                 */
                function ReportIncomeProtectionEmployerChangeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ReportIncomeProtectionEmployerChangeResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse} ReportIncomeProtectionEmployerChangeResponse instance
                 */
                ReportIncomeProtectionEmployerChangeResponse.create = function create(properties) {
                    return new ReportIncomeProtectionEmployerChangeResponse(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionEmployerChangeResponse message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeResponse} message ReportIncomeProtectionEmployerChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionEmployerChangeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionEmployerChangeResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionEmployerChangeResponse} message ReportIncomeProtectionEmployerChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionEmployerChangeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionEmployerChangeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse} ReportIncomeProtectionEmployerChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionEmployerChangeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionEmployerChangeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse} ReportIncomeProtectionEmployerChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionEmployerChangeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionEmployerChangeResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionEmployerChangeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionEmployerChangeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse} ReportIncomeProtectionEmployerChangeResponse
                 */
                ReportIncomeProtectionEmployerChangeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse)
                        return object;
                    return new $root.visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse();
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionEmployerChangeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse} message ReportIncomeProtectionEmployerChangeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionEmployerChangeResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReportIncomeProtectionEmployerChangeResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionEmployerChangeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionEmployerChangeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionEmployerChangeResponse;
            })();

            clientrpc.ReportIncomeProtectionLayoffChangeRequest = (function() {

                /**
                 * Properties of a ReportIncomeProtectionLayoffChangeRequest.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionLayoffChangeRequest
                 * @property {visor.common.time.ILocalDate|null} [date] ReportIncomeProtectionLayoffChangeRequest date
                 */

                /**
                 * Constructs a new ReportIncomeProtectionLayoffChangeRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionLayoffChangeRequest.
                 * @implements IReportIncomeProtectionLayoffChangeRequest
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeRequest=} [properties] Properties to set
                 */
                function ReportIncomeProtectionLayoffChangeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReportIncomeProtectionLayoffChangeRequest date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @instance
                 */
                ReportIncomeProtectionLayoffChangeRequest.prototype.date = null;

                /**
                 * Creates a new ReportIncomeProtectionLayoffChangeRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest} ReportIncomeProtectionLayoffChangeRequest instance
                 */
                ReportIncomeProtectionLayoffChangeRequest.create = function create(properties) {
                    return new ReportIncomeProtectionLayoffChangeRequest(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionLayoffChangeRequest message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeRequest} message ReportIncomeProtectionLayoffChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionLayoffChangeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionLayoffChangeRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeRequest} message ReportIncomeProtectionLayoffChangeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionLayoffChangeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionLayoffChangeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest} ReportIncomeProtectionLayoffChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionLayoffChangeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionLayoffChangeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest} ReportIncomeProtectionLayoffChangeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionLayoffChangeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionLayoffChangeRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionLayoffChangeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionLayoffChangeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest} ReportIncomeProtectionLayoffChangeRequest
                 */
                ReportIncomeProtectionLayoffChangeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest();
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionLayoffChangeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest} message ReportIncomeProtectionLayoffChangeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionLayoffChangeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.date = null;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    return object;
                };

                /**
                 * Converts this ReportIncomeProtectionLayoffChangeRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionLayoffChangeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionLayoffChangeRequest;
            })();

            clientrpc.ReportIncomeProtectionLayoffChangeResponse = (function() {

                /**
                 * Properties of a ReportIncomeProtectionLayoffChangeResponse.
                 * @memberof visor.clientrpc
                 * @interface IReportIncomeProtectionLayoffChangeResponse
                 */

                /**
                 * Constructs a new ReportIncomeProtectionLayoffChangeResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReportIncomeProtectionLayoffChangeResponse.
                 * @implements IReportIncomeProtectionLayoffChangeResponse
                 * @constructor
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeResponse=} [properties] Properties to set
                 */
                function ReportIncomeProtectionLayoffChangeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ReportIncomeProtectionLayoffChangeResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse} ReportIncomeProtectionLayoffChangeResponse instance
                 */
                ReportIncomeProtectionLayoffChangeResponse.create = function create(properties) {
                    return new ReportIncomeProtectionLayoffChangeResponse(properties);
                };

                /**
                 * Encodes the specified ReportIncomeProtectionLayoffChangeResponse message. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeResponse} message ReportIncomeProtectionLayoffChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionLayoffChangeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ReportIncomeProtectionLayoffChangeResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {visor.clientrpc.IReportIncomeProtectionLayoffChangeResponse} message ReportIncomeProtectionLayoffChangeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReportIncomeProtectionLayoffChangeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReportIncomeProtectionLayoffChangeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse} ReportIncomeProtectionLayoffChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionLayoffChangeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReportIncomeProtectionLayoffChangeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse} ReportIncomeProtectionLayoffChangeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReportIncomeProtectionLayoffChangeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReportIncomeProtectionLayoffChangeResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReportIncomeProtectionLayoffChangeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ReportIncomeProtectionLayoffChangeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse} ReportIncomeProtectionLayoffChangeResponse
                 */
                ReportIncomeProtectionLayoffChangeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse)
                        return object;
                    return new $root.visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse();
                };

                /**
                 * Creates a plain object from a ReportIncomeProtectionLayoffChangeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @static
                 * @param {visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse} message ReportIncomeProtectionLayoffChangeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReportIncomeProtectionLayoffChangeResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReportIncomeProtectionLayoffChangeResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReportIncomeProtectionLayoffChangeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReportIncomeProtectionLayoffChangeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReportIncomeProtectionLayoffChangeResponse;
            })();

            clientrpc.GetIncomeProtectionEligibilityRequest = (function() {

                /**
                 * Properties of a GetIncomeProtectionEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetIncomeProtectionEligibilityRequest
                 * @property {string|null} [policyToken] GetIncomeProtectionEligibilityRequest policyToken
                 */

                /**
                 * Constructs a new GetIncomeProtectionEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetIncomeProtectionEligibilityRequest.
                 * @implements IGetIncomeProtectionEligibilityRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityRequest=} [properties] Properties to set
                 */
                function GetIncomeProtectionEligibilityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetIncomeProtectionEligibilityRequest policyToken.
                 * @member {string} policyToken
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @instance
                 */
                GetIncomeProtectionEligibilityRequest.prototype.policyToken = "";

                /**
                 * Creates a new GetIncomeProtectionEligibilityRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityRequest} GetIncomeProtectionEligibilityRequest instance
                 */
                GetIncomeProtectionEligibilityRequest.create = function create(properties) {
                    return new GetIncomeProtectionEligibilityRequest(properties);
                };

                /**
                 * Encodes the specified GetIncomeProtectionEligibilityRequest message. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionEligibilityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityRequest} message GetIncomeProtectionEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionEligibilityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.policyToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetIncomeProtectionEligibilityRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionEligibilityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityRequest} message GetIncomeProtectionEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionEligibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetIncomeProtectionEligibilityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityRequest} GetIncomeProtectionEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionEligibilityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetIncomeProtectionEligibilityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.policyToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetIncomeProtectionEligibilityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityRequest} GetIncomeProtectionEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionEligibilityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetIncomeProtectionEligibilityRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIncomeProtectionEligibilityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        if (!$util.isString(message.policyToken))
                            return "policyToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetIncomeProtectionEligibilityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityRequest} GetIncomeProtectionEligibilityRequest
                 */
                GetIncomeProtectionEligibilityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetIncomeProtectionEligibilityRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetIncomeProtectionEligibilityRequest();
                    if (object.policyToken != null)
                        message.policyToken = String(object.policyToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetIncomeProtectionEligibilityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.GetIncomeProtectionEligibilityRequest} message GetIncomeProtectionEligibilityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIncomeProtectionEligibilityRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.policyToken = "";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        object.policyToken = message.policyToken;
                    return object;
                };

                /**
                 * Converts this GetIncomeProtectionEligibilityRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIncomeProtectionEligibilityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetIncomeProtectionEligibilityRequest;
            })();

            clientrpc.GetIncomeProtectionEligibilityResponse = (function() {

                /**
                 * Properties of a GetIncomeProtectionEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetIncomeProtectionEligibilityResponse
                 * @property {boolean|null} [eligible] GetIncomeProtectionEligibilityResponse eligible
                 * @property {visor.common.money.IAmount|null} [monthlySubscriptionFee] GetIncomeProtectionEligibilityResponse monthlySubscriptionFee
                 */

                /**
                 * Constructs a new GetIncomeProtectionEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetIncomeProtectionEligibilityResponse.
                 * @implements IGetIncomeProtectionEligibilityResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityResponse=} [properties] Properties to set
                 */
                function GetIncomeProtectionEligibilityResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetIncomeProtectionEligibilityResponse eligible.
                 * @member {boolean} eligible
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @instance
                 */
                GetIncomeProtectionEligibilityResponse.prototype.eligible = false;

                /**
                 * GetIncomeProtectionEligibilityResponse monthlySubscriptionFee.
                 * @member {visor.common.money.IAmount|null|undefined} monthlySubscriptionFee
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @instance
                 */
                GetIncomeProtectionEligibilityResponse.prototype.monthlySubscriptionFee = null;

                /**
                 * Creates a new GetIncomeProtectionEligibilityResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityResponse} GetIncomeProtectionEligibilityResponse instance
                 */
                GetIncomeProtectionEligibilityResponse.create = function create(properties) {
                    return new GetIncomeProtectionEligibilityResponse(properties);
                };

                /**
                 * Encodes the specified GetIncomeProtectionEligibilityResponse message. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionEligibilityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityResponse} message GetIncomeProtectionEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionEligibilityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.eligible != null && message.hasOwnProperty("eligible"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.eligible);
                    if (message.monthlySubscriptionFee != null && message.hasOwnProperty("monthlySubscriptionFee"))
                        $root.visor.common.money.Amount.encode(message.monthlySubscriptionFee, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetIncomeProtectionEligibilityResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetIncomeProtectionEligibilityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetIncomeProtectionEligibilityResponse} message GetIncomeProtectionEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetIncomeProtectionEligibilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetIncomeProtectionEligibilityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityResponse} GetIncomeProtectionEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionEligibilityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetIncomeProtectionEligibilityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.eligible = reader.bool();
                            break;
                        case 2:
                            message.monthlySubscriptionFee = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetIncomeProtectionEligibilityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityResponse} GetIncomeProtectionEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetIncomeProtectionEligibilityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetIncomeProtectionEligibilityResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetIncomeProtectionEligibilityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.eligible != null && message.hasOwnProperty("eligible"))
                        if (typeof message.eligible !== "boolean")
                            return "eligible: boolean expected";
                    if (message.monthlySubscriptionFee != null && message.hasOwnProperty("monthlySubscriptionFee")) {
                        var error = $root.visor.common.money.Amount.verify(message.monthlySubscriptionFee);
                        if (error)
                            return "monthlySubscriptionFee." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetIncomeProtectionEligibilityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetIncomeProtectionEligibilityResponse} GetIncomeProtectionEligibilityResponse
                 */
                GetIncomeProtectionEligibilityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetIncomeProtectionEligibilityResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetIncomeProtectionEligibilityResponse();
                    if (object.eligible != null)
                        message.eligible = Boolean(object.eligible);
                    if (object.monthlySubscriptionFee != null) {
                        if (typeof object.monthlySubscriptionFee !== "object")
                            throw TypeError(".visor.clientrpc.GetIncomeProtectionEligibilityResponse.monthlySubscriptionFee: object expected");
                        message.monthlySubscriptionFee = $root.visor.common.money.Amount.fromObject(object.monthlySubscriptionFee);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetIncomeProtectionEligibilityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.GetIncomeProtectionEligibilityResponse} message GetIncomeProtectionEligibilityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetIncomeProtectionEligibilityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.eligible = false;
                        object.monthlySubscriptionFee = null;
                    }
                    if (message.eligible != null && message.hasOwnProperty("eligible"))
                        object.eligible = message.eligible;
                    if (message.monthlySubscriptionFee != null && message.hasOwnProperty("monthlySubscriptionFee"))
                        object.monthlySubscriptionFee = $root.visor.common.money.Amount.toObject(message.monthlySubscriptionFee, options);
                    return object;
                };

                /**
                 * Converts this GetIncomeProtectionEligibilityResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetIncomeProtectionEligibilityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetIncomeProtectionEligibilityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetIncomeProtectionEligibilityResponse;
            })();

            clientrpc.PayPremiumRequest = (function() {

                /**
                 * Properties of a PayPremiumRequest.
                 * @memberof visor.clientrpc
                 * @interface IPayPremiumRequest
                 * @property {string|null} [policyToken] PayPremiumRequest policyToken
                 * @property {string|null} [cardToken] PayPremiumRequest cardToken
                 * @property {boolean|null} [userPromisedFreeMonth] PayPremiumRequest userPromisedFreeMonth
                 */

                /**
                 * Constructs a new PayPremiumRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PayPremiumRequest.
                 * @implements IPayPremiumRequest
                 * @constructor
                 * @param {visor.clientrpc.IPayPremiumRequest=} [properties] Properties to set
                 */
                function PayPremiumRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PayPremiumRequest policyToken.
                 * @member {string} policyToken
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @instance
                 */
                PayPremiumRequest.prototype.policyToken = "";

                /**
                 * PayPremiumRequest cardToken.
                 * @member {string} cardToken
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @instance
                 */
                PayPremiumRequest.prototype.cardToken = "";

                /**
                 * PayPremiumRequest userPromisedFreeMonth.
                 * @member {boolean} userPromisedFreeMonth
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @instance
                 */
                PayPremiumRequest.prototype.userPromisedFreeMonth = false;

                /**
                 * Creates a new PayPremiumRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {visor.clientrpc.IPayPremiumRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.PayPremiumRequest} PayPremiumRequest instance
                 */
                PayPremiumRequest.create = function create(properties) {
                    return new PayPremiumRequest(properties);
                };

                /**
                 * Encodes the specified PayPremiumRequest message. Does not implicitly {@link visor.clientrpc.PayPremiumRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {visor.clientrpc.IPayPremiumRequest} message PayPremiumRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayPremiumRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.policyToken);
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardToken);
                    if (message.userPromisedFreeMonth != null && message.hasOwnProperty("userPromisedFreeMonth"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.userPromisedFreeMonth);
                    return writer;
                };

                /**
                 * Encodes the specified PayPremiumRequest message, length delimited. Does not implicitly {@link visor.clientrpc.PayPremiumRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {visor.clientrpc.IPayPremiumRequest} message PayPremiumRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayPremiumRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PayPremiumRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PayPremiumRequest} PayPremiumRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayPremiumRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayPremiumRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.policyToken = reader.string();
                            break;
                        case 2:
                            message.cardToken = reader.string();
                            break;
                        case 3:
                            message.userPromisedFreeMonth = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PayPremiumRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PayPremiumRequest} PayPremiumRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayPremiumRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PayPremiumRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayPremiumRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        if (!$util.isString(message.policyToken))
                            return "policyToken: string expected";
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        if (!$util.isString(message.cardToken))
                            return "cardToken: string expected";
                    if (message.userPromisedFreeMonth != null && message.hasOwnProperty("userPromisedFreeMonth"))
                        if (typeof message.userPromisedFreeMonth !== "boolean")
                            return "userPromisedFreeMonth: boolean expected";
                    return null;
                };

                /**
                 * Creates a PayPremiumRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PayPremiumRequest} PayPremiumRequest
                 */
                PayPremiumRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PayPremiumRequest)
                        return object;
                    var message = new $root.visor.clientrpc.PayPremiumRequest();
                    if (object.policyToken != null)
                        message.policyToken = String(object.policyToken);
                    if (object.cardToken != null)
                        message.cardToken = String(object.cardToken);
                    if (object.userPromisedFreeMonth != null)
                        message.userPromisedFreeMonth = Boolean(object.userPromisedFreeMonth);
                    return message;
                };

                /**
                 * Creates a plain object from a PayPremiumRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @static
                 * @param {visor.clientrpc.PayPremiumRequest} message PayPremiumRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayPremiumRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.policyToken = "";
                        object.cardToken = "";
                        object.userPromisedFreeMonth = false;
                    }
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        object.policyToken = message.policyToken;
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        object.cardToken = message.cardToken;
                    if (message.userPromisedFreeMonth != null && message.hasOwnProperty("userPromisedFreeMonth"))
                        object.userPromisedFreeMonth = message.userPromisedFreeMonth;
                    return object;
                };

                /**
                 * Converts this PayPremiumRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PayPremiumRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayPremiumRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PayPremiumRequest;
            })();

            clientrpc.PayPremiumResponse = (function() {

                /**
                 * Properties of a PayPremiumResponse.
                 * @memberof visor.clientrpc
                 * @interface IPayPremiumResponse
                 * @property {visor.clientrpc.PayPremiumResponse.IData|null} [success] PayPremiumResponse success
                 * @property {visor.clientrpc.PayPremiumResponse.IError|null} [failure] PayPremiumResponse failure
                 */

                /**
                 * Constructs a new PayPremiumResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PayPremiumResponse.
                 * @implements IPayPremiumResponse
                 * @constructor
                 * @param {visor.clientrpc.IPayPremiumResponse=} [properties] Properties to set
                 */
                function PayPremiumResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PayPremiumResponse success.
                 * @member {visor.clientrpc.PayPremiumResponse.IData|null|undefined} success
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @instance
                 */
                PayPremiumResponse.prototype.success = null;

                /**
                 * PayPremiumResponse failure.
                 * @member {visor.clientrpc.PayPremiumResponse.IError|null|undefined} failure
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @instance
                 */
                PayPremiumResponse.prototype.failure = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * PayPremiumResponse result.
                 * @member {"success"|"failure"|undefined} result
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @instance
                 */
                Object.defineProperty(PayPremiumResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["success", "failure"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new PayPremiumResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {visor.clientrpc.IPayPremiumResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.PayPremiumResponse} PayPremiumResponse instance
                 */
                PayPremiumResponse.create = function create(properties) {
                    return new PayPremiumResponse(properties);
                };

                /**
                 * Encodes the specified PayPremiumResponse message. Does not implicitly {@link visor.clientrpc.PayPremiumResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {visor.clientrpc.IPayPremiumResponse} message PayPremiumResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayPremiumResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && message.hasOwnProperty("success"))
                        $root.visor.clientrpc.PayPremiumResponse.Data.encode(message.success, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.failure != null && message.hasOwnProperty("failure"))
                        $root.visor.clientrpc.PayPremiumResponse.Error.encode(message.failure, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PayPremiumResponse message, length delimited. Does not implicitly {@link visor.clientrpc.PayPremiumResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {visor.clientrpc.IPayPremiumResponse} message PayPremiumResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayPremiumResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PayPremiumResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PayPremiumResponse} PayPremiumResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayPremiumResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayPremiumResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.success = $root.visor.clientrpc.PayPremiumResponse.Data.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.failure = $root.visor.clientrpc.PayPremiumResponse.Error.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PayPremiumResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PayPremiumResponse} PayPremiumResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayPremiumResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PayPremiumResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayPremiumResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.PayPremiumResponse.Data.verify(message.success);
                            if (error)
                                return "success." + error;
                        }
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.PayPremiumResponse.Error.verify(message.failure);
                            if (error)
                                return "failure." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PayPremiumResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PayPremiumResponse} PayPremiumResponse
                 */
                PayPremiumResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PayPremiumResponse)
                        return object;
                    var message = new $root.visor.clientrpc.PayPremiumResponse();
                    if (object.success != null) {
                        if (typeof object.success !== "object")
                            throw TypeError(".visor.clientrpc.PayPremiumResponse.success: object expected");
                        message.success = $root.visor.clientrpc.PayPremiumResponse.Data.fromObject(object.success);
                    }
                    if (object.failure != null) {
                        if (typeof object.failure !== "object")
                            throw TypeError(".visor.clientrpc.PayPremiumResponse.failure: object expected");
                        message.failure = $root.visor.clientrpc.PayPremiumResponse.Error.fromObject(object.failure);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PayPremiumResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @static
                 * @param {visor.clientrpc.PayPremiumResponse} message PayPremiumResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayPremiumResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        object.success = $root.visor.clientrpc.PayPremiumResponse.Data.toObject(message.success, options);
                        if (options.oneofs)
                            object.result = "success";
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        object.failure = $root.visor.clientrpc.PayPremiumResponse.Error.toObject(message.failure, options);
                        if (options.oneofs)
                            object.result = "failure";
                    }
                    return object;
                };

                /**
                 * Converts this PayPremiumResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PayPremiumResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayPremiumResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                PayPremiumResponse.Data = (function() {

                    /**
                     * Properties of a Data.
                     * @memberof visor.clientrpc.PayPremiumResponse
                     * @interface IData
                     * @property {string|null} [policyToken] Data policyToken
                     */

                    /**
                     * Constructs a new Data.
                     * @memberof visor.clientrpc.PayPremiumResponse
                     * @classdesc Represents a Data.
                     * @implements IData
                     * @constructor
                     * @param {visor.clientrpc.PayPremiumResponse.IData=} [properties] Properties to set
                     */
                    function Data(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Data policyToken.
                     * @member {string} policyToken
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @instance
                     */
                    Data.prototype.policyToken = "";

                    /**
                     * Creates a new Data instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.IData=} [properties] Properties to set
                     * @returns {visor.clientrpc.PayPremiumResponse.Data} Data instance
                     */
                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    /**
                     * Encodes the specified Data message. Does not implicitly {@link visor.clientrpc.PayPremiumResponse.Data.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.policyToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified Data message, length delimited. Does not implicitly {@link visor.clientrpc.PayPremiumResponse.Data.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.PayPremiumResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayPremiumResponse.Data();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.policyToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.PayPremiumResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Data message.
                     * @function verify
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Data.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                            if (!$util.isString(message.policyToken))
                                return "policyToken: string expected";
                        return null;
                    };

                    /**
                     * Creates a Data message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.PayPremiumResponse.Data} Data
                     */
                    Data.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.PayPremiumResponse.Data)
                            return object;
                        var message = new $root.visor.clientrpc.PayPremiumResponse.Data();
                        if (object.policyToken != null)
                            message.policyToken = String(object.policyToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Data message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.Data} message Data
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Data.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.policyToken = "";
                        if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                            object.policyToken = message.policyToken;
                        return object;
                    };

                    /**
                     * Converts this Data to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.PayPremiumResponse.Data
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Data.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Data;
                })();

                PayPremiumResponse.Error = (function() {

                    /**
                     * Properties of an Error.
                     * @memberof visor.clientrpc.PayPremiumResponse
                     * @interface IError
                     * @property {visor.clientrpc.PayPremiumResponse.Error.Code|null} [code] Error code
                     * @property {string|null} [message] Error message
                     */

                    /**
                     * Constructs a new Error.
                     * @memberof visor.clientrpc.PayPremiumResponse
                     * @classdesc Represents an Error.
                     * @implements IError
                     * @constructor
                     * @param {visor.clientrpc.PayPremiumResponse.IError=} [properties] Properties to set
                     */
                    function Error(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Error code.
                     * @member {visor.clientrpc.PayPremiumResponse.Error.Code} code
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @instance
                     */
                    Error.prototype.code = 0;

                    /**
                     * Error message.
                     * @member {string} message
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @instance
                     */
                    Error.prototype.message = "";

                    /**
                     * Creates a new Error instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.IError=} [properties] Properties to set
                     * @returns {visor.clientrpc.PayPremiumResponse.Error} Error instance
                     */
                    Error.create = function create(properties) {
                        return new Error(properties);
                    };

                    /**
                     * Encodes the specified Error message. Does not implicitly {@link visor.clientrpc.PayPremiumResponse.Error.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                        return writer;
                    };

                    /**
                     * Encodes the specified Error message, length delimited. Does not implicitly {@link visor.clientrpc.PayPremiumResponse.Error.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.PayPremiumResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayPremiumResponse.Error();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.int32();
                                break;
                            case 2:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.PayPremiumResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Error message.
                     * @function verify
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Error.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            switch (message.code) {
                            default:
                                return "code: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };

                    /**
                     * Creates an Error message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.PayPremiumResponse.Error} Error
                     */
                    Error.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.PayPremiumResponse.Error)
                            return object;
                        var message = new $root.visor.clientrpc.PayPremiumResponse.Error();
                        switch (object.code) {
                        case "PAY_PREMIUM_RESPONSE_ERROR_CODE_INVALID":
                        case 0:
                            message.code = 0;
                            break;
                        case "PAY_PREMIUM_RESPONSE_ERROR_CODE_PAYMENT_FAILED":
                        case 1:
                            message.code = 1;
                            break;
                        case "PAY_PREMIUM_RESPONSE_ERROR_CODE_CONFLICT":
                        case 2:
                            message.code = 2;
                            break;
                        }
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Error message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayPremiumResponse.Error} message Error
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Error.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.code = options.enums === String ? "PAY_PREMIUM_RESPONSE_ERROR_CODE_INVALID" : 0;
                            object.message = "";
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = options.enums === String ? $root.visor.clientrpc.PayPremiumResponse.Error.Code[message.code] : message.code;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };

                    /**
                     * Converts this Error to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.PayPremiumResponse.Error
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Error.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Code enum.
                     * @name visor.clientrpc.PayPremiumResponse.Error.Code
                     * @enum {string}
                     * @property {number} PAY_PREMIUM_RESPONSE_ERROR_CODE_INVALID=0 PAY_PREMIUM_RESPONSE_ERROR_CODE_INVALID value
                     * @property {number} PAY_PREMIUM_RESPONSE_ERROR_CODE_PAYMENT_FAILED=1 PAY_PREMIUM_RESPONSE_ERROR_CODE_PAYMENT_FAILED value
                     * @property {number} PAY_PREMIUM_RESPONSE_ERROR_CODE_CONFLICT=2 PAY_PREMIUM_RESPONSE_ERROR_CODE_CONFLICT value
                     */
                    Error.Code = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PAY_PREMIUM_RESPONSE_ERROR_CODE_INVALID"] = 0;
                        values[valuesById[1] = "PAY_PREMIUM_RESPONSE_ERROR_CODE_PAYMENT_FAILED"] = 1;
                        values[valuesById[2] = "PAY_PREMIUM_RESPONSE_ERROR_CODE_CONFLICT"] = 2;
                        return values;
                    })();

                    return Error;
                })();

                return PayPremiumResponse;
            })();

            clientrpc.BeginUnemploymentApplicationWorkflowRequest = (function() {

                /**
                 * Properties of a BeginUnemploymentApplicationWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginUnemploymentApplicationWorkflowRequest
                 * @property {boolean|null} [isStaging] BeginUnemploymentApplicationWorkflowRequest isStaging
                 */

                /**
                 * Constructs a new BeginUnemploymentApplicationWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginUnemploymentApplicationWorkflowRequest.
                 * @implements IBeginUnemploymentApplicationWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowRequest=} [properties] Properties to set
                 */
                function BeginUnemploymentApplicationWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BeginUnemploymentApplicationWorkflowRequest isStaging.
                 * @member {boolean} isStaging
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @instance
                 */
                BeginUnemploymentApplicationWorkflowRequest.prototype.isStaging = false;

                /**
                 * Creates a new BeginUnemploymentApplicationWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest} BeginUnemploymentApplicationWorkflowRequest instance
                 */
                BeginUnemploymentApplicationWorkflowRequest.create = function create(properties) {
                    return new BeginUnemploymentApplicationWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginUnemploymentApplicationWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowRequest} message BeginUnemploymentApplicationWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginUnemploymentApplicationWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isStaging != null && message.hasOwnProperty("isStaging"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isStaging);
                    return writer;
                };

                /**
                 * Encodes the specified BeginUnemploymentApplicationWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowRequest} message BeginUnemploymentApplicationWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginUnemploymentApplicationWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginUnemploymentApplicationWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest} BeginUnemploymentApplicationWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginUnemploymentApplicationWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isStaging = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginUnemploymentApplicationWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest} BeginUnemploymentApplicationWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginUnemploymentApplicationWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginUnemploymentApplicationWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginUnemploymentApplicationWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isStaging != null && message.hasOwnProperty("isStaging"))
                        if (typeof message.isStaging !== "boolean")
                            return "isStaging: boolean expected";
                    return null;
                };

                /**
                 * Creates a BeginUnemploymentApplicationWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest} BeginUnemploymentApplicationWorkflowRequest
                 */
                BeginUnemploymentApplicationWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest)
                        return object;
                    var message = new $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest();
                    if (object.isStaging != null)
                        message.isStaging = Boolean(object.isStaging);
                    return message;
                };

                /**
                 * Creates a plain object from a BeginUnemploymentApplicationWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest} message BeginUnemploymentApplicationWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginUnemploymentApplicationWorkflowRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.isStaging = false;
                    if (message.isStaging != null && message.hasOwnProperty("isStaging"))
                        object.isStaging = message.isStaging;
                    return object;
                };

                /**
                 * Converts this BeginUnemploymentApplicationWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginUnemploymentApplicationWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginUnemploymentApplicationWorkflowRequest;
            })();

            clientrpc.BeginUnemploymentApplicationWorkflowResponse = (function() {

                /**
                 * Properties of a BeginUnemploymentApplicationWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginUnemploymentApplicationWorkflowResponse
                 */

                /**
                 * Constructs a new BeginUnemploymentApplicationWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginUnemploymentApplicationWorkflowResponse.
                 * @implements IBeginUnemploymentApplicationWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowResponse=} [properties] Properties to set
                 */
                function BeginUnemploymentApplicationWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginUnemploymentApplicationWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse} BeginUnemploymentApplicationWorkflowResponse instance
                 */
                BeginUnemploymentApplicationWorkflowResponse.create = function create(properties) {
                    return new BeginUnemploymentApplicationWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginUnemploymentApplicationWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowResponse} message BeginUnemploymentApplicationWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginUnemploymentApplicationWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginUnemploymentApplicationWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginUnemploymentApplicationWorkflowResponse} message BeginUnemploymentApplicationWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginUnemploymentApplicationWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginUnemploymentApplicationWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse} BeginUnemploymentApplicationWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginUnemploymentApplicationWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginUnemploymentApplicationWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse} BeginUnemploymentApplicationWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginUnemploymentApplicationWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginUnemploymentApplicationWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginUnemploymentApplicationWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginUnemploymentApplicationWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse} BeginUnemploymentApplicationWorkflowResponse
                 */
                BeginUnemploymentApplicationWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginUnemploymentApplicationWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse} message BeginUnemploymentApplicationWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginUnemploymentApplicationWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginUnemploymentApplicationWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginUnemploymentApplicationWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginUnemploymentApplicationWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginUnemploymentApplicationWorkflowResponse;
            })();

            clientrpc.CompleteUnemploymentIntroStepRequest = (function() {

                /**
                 * Properties of a CompleteUnemploymentIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteUnemploymentIntroStepRequest
                 * @property {string|null} [stepToken] CompleteUnemploymentIntroStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteUnemploymentIntroStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteUnemploymentIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteUnemploymentIntroStepRequest.
                 * @implements ICompleteUnemploymentIntroStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepRequest=} [properties] Properties to set
                 */
                function CompleteUnemploymentIntroStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteUnemploymentIntroStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @instance
                 */
                CompleteUnemploymentIntroStepRequest.prototype.stepToken = "";

                /**
                 * CompleteUnemploymentIntroStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @instance
                 */
                CompleteUnemploymentIntroStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteUnemploymentIntroStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepRequest} CompleteUnemploymentIntroStepRequest instance
                 */
                CompleteUnemploymentIntroStepRequest.create = function create(properties) {
                    return new CompleteUnemploymentIntroStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteUnemploymentIntroStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentIntroStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepRequest} message CompleteUnemploymentIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentIntroStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteUnemploymentIntroStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentIntroStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepRequest} message CompleteUnemploymentIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentIntroStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteUnemploymentIntroStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepRequest} CompleteUnemploymentIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentIntroStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteUnemploymentIntroStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteUnemploymentIntroStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepRequest} CompleteUnemploymentIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentIntroStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteUnemploymentIntroStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteUnemploymentIntroStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteUnemploymentIntroStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepRequest} CompleteUnemploymentIntroStepRequest
                 */
                CompleteUnemploymentIntroStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteUnemploymentIntroStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteUnemploymentIntroStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteUnemploymentIntroStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteUnemploymentIntroStepRequest} message CompleteUnemploymentIntroStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteUnemploymentIntroStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteUnemploymentIntroStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteUnemploymentIntroStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteUnemploymentIntroStepRequest;
            })();

            clientrpc.CompleteUnemploymentIntroStepResponse = (function() {

                /**
                 * Properties of a CompleteUnemploymentIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteUnemploymentIntroStepResponse
                 */

                /**
                 * Constructs a new CompleteUnemploymentIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteUnemploymentIntroStepResponse.
                 * @implements ICompleteUnemploymentIntroStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepResponse=} [properties] Properties to set
                 */
                function CompleteUnemploymentIntroStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteUnemploymentIntroStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepResponse} CompleteUnemploymentIntroStepResponse instance
                 */
                CompleteUnemploymentIntroStepResponse.create = function create(properties) {
                    return new CompleteUnemploymentIntroStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteUnemploymentIntroStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentIntroStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepResponse} message CompleteUnemploymentIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentIntroStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteUnemploymentIntroStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteUnemploymentIntroStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteUnemploymentIntroStepResponse} message CompleteUnemploymentIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteUnemploymentIntroStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteUnemploymentIntroStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepResponse} CompleteUnemploymentIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentIntroStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteUnemploymentIntroStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteUnemploymentIntroStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepResponse} CompleteUnemploymentIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteUnemploymentIntroStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteUnemploymentIntroStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteUnemploymentIntroStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteUnemploymentIntroStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteUnemploymentIntroStepResponse} CompleteUnemploymentIntroStepResponse
                 */
                CompleteUnemploymentIntroStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteUnemploymentIntroStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteUnemploymentIntroStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteUnemploymentIntroStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteUnemploymentIntroStepResponse} message CompleteUnemploymentIntroStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteUnemploymentIntroStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteUnemploymentIntroStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteUnemploymentIntroStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteUnemploymentIntroStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteUnemploymentIntroStepResponse;
            })();

            clientrpc.CreateBoostCardCheckoutPaymentRequest = (function() {

                /**
                 * Properties of a CreateBoostCardCheckoutPaymentRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateBoostCardCheckoutPaymentRequest
                 * @property {string|null} [cardToken] CreateBoostCardCheckoutPaymentRequest cardToken
                 * @property {string|null} [stepToken] CreateBoostCardCheckoutPaymentRequest stepToken
                 * @property {string|null} [workflowToken] CreateBoostCardCheckoutPaymentRequest workflowToken
                 */

                /**
                 * Constructs a new CreateBoostCardCheckoutPaymentRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateBoostCardCheckoutPaymentRequest.
                 * @implements ICreateBoostCardCheckoutPaymentRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentRequest=} [properties] Properties to set
                 */
                function CreateBoostCardCheckoutPaymentRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBoostCardCheckoutPaymentRequest cardToken.
                 * @member {string} cardToken
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @instance
                 */
                CreateBoostCardCheckoutPaymentRequest.prototype.cardToken = "";

                /**
                 * CreateBoostCardCheckoutPaymentRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @instance
                 */
                CreateBoostCardCheckoutPaymentRequest.prototype.stepToken = "";

                /**
                 * CreateBoostCardCheckoutPaymentRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @instance
                 */
                CreateBoostCardCheckoutPaymentRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CreateBoostCardCheckoutPaymentRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentRequest} CreateBoostCardCheckoutPaymentRequest instance
                 */
                CreateBoostCardCheckoutPaymentRequest.create = function create(properties) {
                    return new CreateBoostCardCheckoutPaymentRequest(properties);
                };

                /**
                 * Encodes the specified CreateBoostCardCheckoutPaymentRequest message. Does not implicitly {@link visor.clientrpc.CreateBoostCardCheckoutPaymentRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentRequest} message CreateBoostCardCheckoutPaymentRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardCheckoutPaymentRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cardToken);
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CreateBoostCardCheckoutPaymentRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBoostCardCheckoutPaymentRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentRequest} message CreateBoostCardCheckoutPaymentRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardCheckoutPaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateBoostCardCheckoutPaymentRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentRequest} CreateBoostCardCheckoutPaymentRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardCheckoutPaymentRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBoostCardCheckoutPaymentRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cardToken = reader.string();
                            break;
                        case 2:
                            message.stepToken = reader.string();
                            break;
                        case 3:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateBoostCardCheckoutPaymentRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentRequest} CreateBoostCardCheckoutPaymentRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardCheckoutPaymentRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateBoostCardCheckoutPaymentRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBoostCardCheckoutPaymentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        if (!$util.isString(message.cardToken))
                            return "cardToken: string expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreateBoostCardCheckoutPaymentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentRequest} CreateBoostCardCheckoutPaymentRequest
                 */
                CreateBoostCardCheckoutPaymentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateBoostCardCheckoutPaymentRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateBoostCardCheckoutPaymentRequest();
                    if (object.cardToken != null)
                        message.cardToken = String(object.cardToken);
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBoostCardCheckoutPaymentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @static
                 * @param {visor.clientrpc.CreateBoostCardCheckoutPaymentRequest} message CreateBoostCardCheckoutPaymentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBoostCardCheckoutPaymentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cardToken = "";
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        object.cardToken = message.cardToken;
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CreateBoostCardCheckoutPaymentRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBoostCardCheckoutPaymentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBoostCardCheckoutPaymentRequest;
            })();

            clientrpc.CreateBoostCardCheckoutPaymentResponse = (function() {

                /**
                 * Properties of a CreateBoostCardCheckoutPaymentResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateBoostCardCheckoutPaymentResponse
                 * @property {string|null} [paymentToken] CreateBoostCardCheckoutPaymentResponse paymentToken
                 */

                /**
                 * Constructs a new CreateBoostCardCheckoutPaymentResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateBoostCardCheckoutPaymentResponse.
                 * @implements ICreateBoostCardCheckoutPaymentResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentResponse=} [properties] Properties to set
                 */
                function CreateBoostCardCheckoutPaymentResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBoostCardCheckoutPaymentResponse paymentToken.
                 * @member {string} paymentToken
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @instance
                 */
                CreateBoostCardCheckoutPaymentResponse.prototype.paymentToken = "";

                /**
                 * Creates a new CreateBoostCardCheckoutPaymentResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentResponse} CreateBoostCardCheckoutPaymentResponse instance
                 */
                CreateBoostCardCheckoutPaymentResponse.create = function create(properties) {
                    return new CreateBoostCardCheckoutPaymentResponse(properties);
                };

                /**
                 * Encodes the specified CreateBoostCardCheckoutPaymentResponse message. Does not implicitly {@link visor.clientrpc.CreateBoostCardCheckoutPaymentResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentResponse} message CreateBoostCardCheckoutPaymentResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardCheckoutPaymentResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentToken);
                    return writer;
                };

                /**
                 * Encodes the specified CreateBoostCardCheckoutPaymentResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBoostCardCheckoutPaymentResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardCheckoutPaymentResponse} message CreateBoostCardCheckoutPaymentResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardCheckoutPaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateBoostCardCheckoutPaymentResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentResponse} CreateBoostCardCheckoutPaymentResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardCheckoutPaymentResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBoostCardCheckoutPaymentResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.paymentToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateBoostCardCheckoutPaymentResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentResponse} CreateBoostCardCheckoutPaymentResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardCheckoutPaymentResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateBoostCardCheckoutPaymentResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBoostCardCheckoutPaymentResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        if (!$util.isString(message.paymentToken))
                            return "paymentToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreateBoostCardCheckoutPaymentResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateBoostCardCheckoutPaymentResponse} CreateBoostCardCheckoutPaymentResponse
                 */
                CreateBoostCardCheckoutPaymentResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateBoostCardCheckoutPaymentResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreateBoostCardCheckoutPaymentResponse();
                    if (object.paymentToken != null)
                        message.paymentToken = String(object.paymentToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBoostCardCheckoutPaymentResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @static
                 * @param {visor.clientrpc.CreateBoostCardCheckoutPaymentResponse} message CreateBoostCardCheckoutPaymentResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBoostCardCheckoutPaymentResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.paymentToken = "";
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        object.paymentToken = message.paymentToken;
                    return object;
                };

                /**
                 * Converts this CreateBoostCardCheckoutPaymentResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateBoostCardCheckoutPaymentResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBoostCardCheckoutPaymentResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBoostCardCheckoutPaymentResponse;
            })();

            clientrpc.GetSessionRecordingDecisionRequest = (function() {

                /**
                 * Properties of a GetSessionRecordingDecisionRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetSessionRecordingDecisionRequest
                 */

                /**
                 * Constructs a new GetSessionRecordingDecisionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetSessionRecordingDecisionRequest.
                 * @implements IGetSessionRecordingDecisionRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionRequest=} [properties] Properties to set
                 */
                function GetSessionRecordingDecisionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetSessionRecordingDecisionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionRequest} GetSessionRecordingDecisionRequest instance
                 */
                GetSessionRecordingDecisionRequest.create = function create(properties) {
                    return new GetSessionRecordingDecisionRequest(properties);
                };

                /**
                 * Encodes the specified GetSessionRecordingDecisionRequest message. Does not implicitly {@link visor.clientrpc.GetSessionRecordingDecisionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionRequest} message GetSessionRecordingDecisionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSessionRecordingDecisionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetSessionRecordingDecisionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetSessionRecordingDecisionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionRequest} message GetSessionRecordingDecisionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSessionRecordingDecisionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetSessionRecordingDecisionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionRequest} GetSessionRecordingDecisionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSessionRecordingDecisionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetSessionRecordingDecisionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetSessionRecordingDecisionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionRequest} GetSessionRecordingDecisionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSessionRecordingDecisionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetSessionRecordingDecisionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSessionRecordingDecisionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetSessionRecordingDecisionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionRequest} GetSessionRecordingDecisionRequest
                 */
                GetSessionRecordingDecisionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetSessionRecordingDecisionRequest)
                        return object;
                    return new $root.visor.clientrpc.GetSessionRecordingDecisionRequest();
                };

                /**
                 * Creates a plain object from a GetSessionRecordingDecisionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @static
                 * @param {visor.clientrpc.GetSessionRecordingDecisionRequest} message GetSessionRecordingDecisionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSessionRecordingDecisionRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetSessionRecordingDecisionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSessionRecordingDecisionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSessionRecordingDecisionRequest;
            })();

            clientrpc.GetSessionRecordingDecisionResponse = (function() {

                /**
                 * Properties of a GetSessionRecordingDecisionResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetSessionRecordingDecisionResponse
                 * @property {boolean|null} [shouldRecordSession] GetSessionRecordingDecisionResponse shouldRecordSession
                 */

                /**
                 * Constructs a new GetSessionRecordingDecisionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetSessionRecordingDecisionResponse.
                 * @implements IGetSessionRecordingDecisionResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionResponse=} [properties] Properties to set
                 */
                function GetSessionRecordingDecisionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSessionRecordingDecisionResponse shouldRecordSession.
                 * @member {boolean} shouldRecordSession
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @instance
                 */
                GetSessionRecordingDecisionResponse.prototype.shouldRecordSession = false;

                /**
                 * Creates a new GetSessionRecordingDecisionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionResponse} GetSessionRecordingDecisionResponse instance
                 */
                GetSessionRecordingDecisionResponse.create = function create(properties) {
                    return new GetSessionRecordingDecisionResponse(properties);
                };

                /**
                 * Encodes the specified GetSessionRecordingDecisionResponse message. Does not implicitly {@link visor.clientrpc.GetSessionRecordingDecisionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionResponse} message GetSessionRecordingDecisionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSessionRecordingDecisionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.shouldRecordSession != null && message.hasOwnProperty("shouldRecordSession"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.shouldRecordSession);
                    return writer;
                };

                /**
                 * Encodes the specified GetSessionRecordingDecisionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetSessionRecordingDecisionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {visor.clientrpc.IGetSessionRecordingDecisionResponse} message GetSessionRecordingDecisionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSessionRecordingDecisionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetSessionRecordingDecisionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionResponse} GetSessionRecordingDecisionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSessionRecordingDecisionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetSessionRecordingDecisionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.shouldRecordSession = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetSessionRecordingDecisionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionResponse} GetSessionRecordingDecisionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSessionRecordingDecisionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetSessionRecordingDecisionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSessionRecordingDecisionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.shouldRecordSession != null && message.hasOwnProperty("shouldRecordSession"))
                        if (typeof message.shouldRecordSession !== "boolean")
                            return "shouldRecordSession: boolean expected";
                    return null;
                };

                /**
                 * Creates a GetSessionRecordingDecisionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetSessionRecordingDecisionResponse} GetSessionRecordingDecisionResponse
                 */
                GetSessionRecordingDecisionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetSessionRecordingDecisionResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetSessionRecordingDecisionResponse();
                    if (object.shouldRecordSession != null)
                        message.shouldRecordSession = Boolean(object.shouldRecordSession);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSessionRecordingDecisionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @static
                 * @param {visor.clientrpc.GetSessionRecordingDecisionResponse} message GetSessionRecordingDecisionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSessionRecordingDecisionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.shouldRecordSession = false;
                    if (message.shouldRecordSession != null && message.hasOwnProperty("shouldRecordSession"))
                        object.shouldRecordSession = message.shouldRecordSession;
                    return object;
                };

                /**
                 * Converts this GetSessionRecordingDecisionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetSessionRecordingDecisionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSessionRecordingDecisionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSessionRecordingDecisionResponse;
            })();

            clientrpc.UpdateMailingAddressRequest = (function() {

                /**
                 * Properties of an UpdateMailingAddressRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateMailingAddressRequest
                 * @property {string|null} [addressLineOne] UpdateMailingAddressRequest addressLineOne
                 * @property {string|null} [addressLineTwo] UpdateMailingAddressRequest addressLineTwo
                 * @property {string|null} [city] UpdateMailingAddressRequest city
                 * @property {string|null} [state] UpdateMailingAddressRequest state
                 * @property {string|null} [zip] UpdateMailingAddressRequest zip
                 */

                /**
                 * Constructs a new UpdateMailingAddressRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateMailingAddressRequest.
                 * @implements IUpdateMailingAddressRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateMailingAddressRequest=} [properties] Properties to set
                 */
                function UpdateMailingAddressRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateMailingAddressRequest addressLineOne.
                 * @member {string} addressLineOne
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @instance
                 */
                UpdateMailingAddressRequest.prototype.addressLineOne = "";

                /**
                 * UpdateMailingAddressRequest addressLineTwo.
                 * @member {string} addressLineTwo
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @instance
                 */
                UpdateMailingAddressRequest.prototype.addressLineTwo = "";

                /**
                 * UpdateMailingAddressRequest city.
                 * @member {string} city
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @instance
                 */
                UpdateMailingAddressRequest.prototype.city = "";

                /**
                 * UpdateMailingAddressRequest state.
                 * @member {string} state
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @instance
                 */
                UpdateMailingAddressRequest.prototype.state = "";

                /**
                 * UpdateMailingAddressRequest zip.
                 * @member {string} zip
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @instance
                 */
                UpdateMailingAddressRequest.prototype.zip = "";

                /**
                 * Creates a new UpdateMailingAddressRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateMailingAddressRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateMailingAddressRequest} UpdateMailingAddressRequest instance
                 */
                UpdateMailingAddressRequest.create = function create(properties) {
                    return new UpdateMailingAddressRequest(properties);
                };

                /**
                 * Encodes the specified UpdateMailingAddressRequest message. Does not implicitly {@link visor.clientrpc.UpdateMailingAddressRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateMailingAddressRequest} message UpdateMailingAddressRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMailingAddressRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.addressLineOne != null && message.hasOwnProperty("addressLineOne"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.addressLineOne);
                    if (message.addressLineTwo != null && message.hasOwnProperty("addressLineTwo"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.addressLineTwo);
                    if (message.city != null && message.hasOwnProperty("city"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.city);
                    if (message.state != null && message.hasOwnProperty("state"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.state);
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.zip);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateMailingAddressRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateMailingAddressRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateMailingAddressRequest} message UpdateMailingAddressRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMailingAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateMailingAddressRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateMailingAddressRequest} UpdateMailingAddressRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMailingAddressRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateMailingAddressRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.addressLineOne = reader.string();
                            break;
                        case 2:
                            message.addressLineTwo = reader.string();
                            break;
                        case 3:
                            message.city = reader.string();
                            break;
                        case 4:
                            message.state = reader.string();
                            break;
                        case 5:
                            message.zip = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateMailingAddressRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateMailingAddressRequest} UpdateMailingAddressRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMailingAddressRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateMailingAddressRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateMailingAddressRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.addressLineOne != null && message.hasOwnProperty("addressLineOne"))
                        if (!$util.isString(message.addressLineOne))
                            return "addressLineOne: string expected";
                    if (message.addressLineTwo != null && message.hasOwnProperty("addressLineTwo"))
                        if (!$util.isString(message.addressLineTwo))
                            return "addressLineTwo: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        if (!$util.isString(message.zip))
                            return "zip: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateMailingAddressRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateMailingAddressRequest} UpdateMailingAddressRequest
                 */
                UpdateMailingAddressRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateMailingAddressRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateMailingAddressRequest();
                    if (object.addressLineOne != null)
                        message.addressLineOne = String(object.addressLineOne);
                    if (object.addressLineTwo != null)
                        message.addressLineTwo = String(object.addressLineTwo);
                    if (object.city != null)
                        message.city = String(object.city);
                    if (object.state != null)
                        message.state = String(object.state);
                    if (object.zip != null)
                        message.zip = String(object.zip);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateMailingAddressRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @static
                 * @param {visor.clientrpc.UpdateMailingAddressRequest} message UpdateMailingAddressRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateMailingAddressRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.addressLineOne = "";
                        object.addressLineTwo = "";
                        object.city = "";
                        object.state = "";
                        object.zip = "";
                    }
                    if (message.addressLineOne != null && message.hasOwnProperty("addressLineOne"))
                        object.addressLineOne = message.addressLineOne;
                    if (message.addressLineTwo != null && message.hasOwnProperty("addressLineTwo"))
                        object.addressLineTwo = message.addressLineTwo;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        object.zip = message.zip;
                    return object;
                };

                /**
                 * Converts this UpdateMailingAddressRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateMailingAddressRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateMailingAddressRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateMailingAddressRequest;
            })();

            clientrpc.UpdateMailingAddressResponse = (function() {

                /**
                 * Properties of an UpdateMailingAddressResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateMailingAddressResponse
                 */

                /**
                 * Constructs a new UpdateMailingAddressResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateMailingAddressResponse.
                 * @implements IUpdateMailingAddressResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateMailingAddressResponse=} [properties] Properties to set
                 */
                function UpdateMailingAddressResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateMailingAddressResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateMailingAddressResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateMailingAddressResponse} UpdateMailingAddressResponse instance
                 */
                UpdateMailingAddressResponse.create = function create(properties) {
                    return new UpdateMailingAddressResponse(properties);
                };

                /**
                 * Encodes the specified UpdateMailingAddressResponse message. Does not implicitly {@link visor.clientrpc.UpdateMailingAddressResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateMailingAddressResponse} message UpdateMailingAddressResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMailingAddressResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateMailingAddressResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateMailingAddressResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateMailingAddressResponse} message UpdateMailingAddressResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateMailingAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateMailingAddressResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateMailingAddressResponse} UpdateMailingAddressResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMailingAddressResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateMailingAddressResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateMailingAddressResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateMailingAddressResponse} UpdateMailingAddressResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateMailingAddressResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateMailingAddressResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateMailingAddressResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateMailingAddressResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateMailingAddressResponse} UpdateMailingAddressResponse
                 */
                UpdateMailingAddressResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateMailingAddressResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateMailingAddressResponse();
                };

                /**
                 * Creates a plain object from an UpdateMailingAddressResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @static
                 * @param {visor.clientrpc.UpdateMailingAddressResponse} message UpdateMailingAddressResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateMailingAddressResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateMailingAddressResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateMailingAddressResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateMailingAddressResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateMailingAddressResponse;
            })();

            clientrpc.GetBankingDataSourceRequest = (function() {

                /**
                 * Properties of a GetBankingDataSourceRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetBankingDataSourceRequest
                 */

                /**
                 * Constructs a new GetBankingDataSourceRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBankingDataSourceRequest.
                 * @implements IGetBankingDataSourceRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetBankingDataSourceRequest=} [properties] Properties to set
                 */
                function GetBankingDataSourceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetBankingDataSourceRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankingDataSourceRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBankingDataSourceRequest} GetBankingDataSourceRequest instance
                 */
                GetBankingDataSourceRequest.create = function create(properties) {
                    return new GetBankingDataSourceRequest(properties);
                };

                /**
                 * Encodes the specified GetBankingDataSourceRequest message. Does not implicitly {@link visor.clientrpc.GetBankingDataSourceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankingDataSourceRequest} message GetBankingDataSourceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDataSourceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetBankingDataSourceRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetBankingDataSourceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankingDataSourceRequest} message GetBankingDataSourceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDataSourceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBankingDataSourceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBankingDataSourceRequest} GetBankingDataSourceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDataSourceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBankingDataSourceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBankingDataSourceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBankingDataSourceRequest} GetBankingDataSourceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDataSourceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBankingDataSourceRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBankingDataSourceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetBankingDataSourceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBankingDataSourceRequest} GetBankingDataSourceRequest
                 */
                GetBankingDataSourceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBankingDataSourceRequest)
                        return object;
                    return new $root.visor.clientrpc.GetBankingDataSourceRequest();
                };

                /**
                 * Creates a plain object from a GetBankingDataSourceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @static
                 * @param {visor.clientrpc.GetBankingDataSourceRequest} message GetBankingDataSourceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBankingDataSourceRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetBankingDataSourceRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBankingDataSourceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBankingDataSourceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBankingDataSourceRequest;
            })();

            clientrpc.GetBankingDataSourceResponse = (function() {

                /**
                 * Properties of a GetBankingDataSourceResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetBankingDataSourceResponse
                 * @property {visor.clientrpc.GetBankingDataSourceResponse.Source|null} [bankingBalances] GetBankingDataSourceResponse bankingBalances
                 * @property {visor.clientrpc.GetBankingDataSourceResponse.Source|null} [transactions] GetBankingDataSourceResponse transactions
                 * @property {visor.clientrpc.GetBankingDataSourceResponse.Source|null} [creditBoost] GetBankingDataSourceResponse creditBoost
                 * @property {visor.clientrpc.GetBankingDataSourceResponse.Source|null} [cardDetails] GetBankingDataSourceResponse cardDetails
                 * @property {visor.clientrpc.GetBankingDataSourceResponse.Source|null} [creditLine] GetBankingDataSourceResponse creditLine
                 */

                /**
                 * Constructs a new GetBankingDataSourceResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBankingDataSourceResponse.
                 * @implements IGetBankingDataSourceResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetBankingDataSourceResponse=} [properties] Properties to set
                 */
                function GetBankingDataSourceResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBankingDataSourceResponse bankingBalances.
                 * @member {visor.clientrpc.GetBankingDataSourceResponse.Source} bankingBalances
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @instance
                 */
                GetBankingDataSourceResponse.prototype.bankingBalances = 0;

                /**
                 * GetBankingDataSourceResponse transactions.
                 * @member {visor.clientrpc.GetBankingDataSourceResponse.Source} transactions
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @instance
                 */
                GetBankingDataSourceResponse.prototype.transactions = 0;

                /**
                 * GetBankingDataSourceResponse creditBoost.
                 * @member {visor.clientrpc.GetBankingDataSourceResponse.Source} creditBoost
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @instance
                 */
                GetBankingDataSourceResponse.prototype.creditBoost = 0;

                /**
                 * GetBankingDataSourceResponse cardDetails.
                 * @member {visor.clientrpc.GetBankingDataSourceResponse.Source} cardDetails
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @instance
                 */
                GetBankingDataSourceResponse.prototype.cardDetails = 0;

                /**
                 * GetBankingDataSourceResponse creditLine.
                 * @member {visor.clientrpc.GetBankingDataSourceResponse.Source} creditLine
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @instance
                 */
                GetBankingDataSourceResponse.prototype.creditLine = 0;

                /**
                 * Creates a new GetBankingDataSourceResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankingDataSourceResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBankingDataSourceResponse} GetBankingDataSourceResponse instance
                 */
                GetBankingDataSourceResponse.create = function create(properties) {
                    return new GetBankingDataSourceResponse(properties);
                };

                /**
                 * Encodes the specified GetBankingDataSourceResponse message. Does not implicitly {@link visor.clientrpc.GetBankingDataSourceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankingDataSourceResponse} message GetBankingDataSourceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDataSourceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bankingBalances != null && message.hasOwnProperty("bankingBalances"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.bankingBalances);
                    if (message.transactions != null && message.hasOwnProperty("transactions"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.transactions);
                    if (message.creditBoost != null && message.hasOwnProperty("creditBoost"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.creditBoost);
                    if (message.cardDetails != null && message.hasOwnProperty("cardDetails"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.cardDetails);
                    if (message.creditLine != null && message.hasOwnProperty("creditLine"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.creditLine);
                    return writer;
                };

                /**
                 * Encodes the specified GetBankingDataSourceResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetBankingDataSourceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankingDataSourceResponse} message GetBankingDataSourceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDataSourceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBankingDataSourceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBankingDataSourceResponse} GetBankingDataSourceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDataSourceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBankingDataSourceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.bankingBalances = reader.int32();
                            break;
                        case 2:
                            message.transactions = reader.int32();
                            break;
                        case 3:
                            message.creditBoost = reader.int32();
                            break;
                        case 4:
                            message.cardDetails = reader.int32();
                            break;
                        case 5:
                            message.creditLine = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBankingDataSourceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBankingDataSourceResponse} GetBankingDataSourceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDataSourceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBankingDataSourceResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBankingDataSourceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bankingBalances != null && message.hasOwnProperty("bankingBalances"))
                        switch (message.bankingBalances) {
                        default:
                            return "bankingBalances: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.transactions != null && message.hasOwnProperty("transactions"))
                        switch (message.transactions) {
                        default:
                            return "transactions: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.creditBoost != null && message.hasOwnProperty("creditBoost"))
                        switch (message.creditBoost) {
                        default:
                            return "creditBoost: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.cardDetails != null && message.hasOwnProperty("cardDetails"))
                        switch (message.cardDetails) {
                        default:
                            return "cardDetails: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.creditLine != null && message.hasOwnProperty("creditLine"))
                        switch (message.creditLine) {
                        default:
                            return "creditLine: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetBankingDataSourceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBankingDataSourceResponse} GetBankingDataSourceResponse
                 */
                GetBankingDataSourceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBankingDataSourceResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetBankingDataSourceResponse();
                    switch (object.bankingBalances) {
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID":
                    case 0:
                        message.bankingBalances = 0;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC":
                    case 1:
                        message.bankingBalances = 1;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL":
                    case 2:
                        message.bankingBalances = 2;
                        break;
                    }
                    switch (object.transactions) {
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID":
                    case 0:
                        message.transactions = 0;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC":
                    case 1:
                        message.transactions = 1;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL":
                    case 2:
                        message.transactions = 2;
                        break;
                    }
                    switch (object.creditBoost) {
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID":
                    case 0:
                        message.creditBoost = 0;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC":
                    case 1:
                        message.creditBoost = 1;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL":
                    case 2:
                        message.creditBoost = 2;
                        break;
                    }
                    switch (object.cardDetails) {
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID":
                    case 0:
                        message.cardDetails = 0;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC":
                    case 1:
                        message.cardDetails = 1;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL":
                    case 2:
                        message.cardDetails = 2;
                        break;
                    }
                    switch (object.creditLine) {
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID":
                    case 0:
                        message.creditLine = 0;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC":
                    case 1:
                        message.creditLine = 1;
                        break;
                    case "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL":
                    case 2:
                        message.creditLine = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetBankingDataSourceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @static
                 * @param {visor.clientrpc.GetBankingDataSourceResponse} message GetBankingDataSourceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBankingDataSourceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.bankingBalances = options.enums === String ? "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID" : 0;
                        object.transactions = options.enums === String ? "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID" : 0;
                        object.creditBoost = options.enums === String ? "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID" : 0;
                        object.cardDetails = options.enums === String ? "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID" : 0;
                        object.creditLine = options.enums === String ? "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID" : 0;
                    }
                    if (message.bankingBalances != null && message.hasOwnProperty("bankingBalances"))
                        object.bankingBalances = options.enums === String ? $root.visor.clientrpc.GetBankingDataSourceResponse.Source[message.bankingBalances] : message.bankingBalances;
                    if (message.transactions != null && message.hasOwnProperty("transactions"))
                        object.transactions = options.enums === String ? $root.visor.clientrpc.GetBankingDataSourceResponse.Source[message.transactions] : message.transactions;
                    if (message.creditBoost != null && message.hasOwnProperty("creditBoost"))
                        object.creditBoost = options.enums === String ? $root.visor.clientrpc.GetBankingDataSourceResponse.Source[message.creditBoost] : message.creditBoost;
                    if (message.cardDetails != null && message.hasOwnProperty("cardDetails"))
                        object.cardDetails = options.enums === String ? $root.visor.clientrpc.GetBankingDataSourceResponse.Source[message.cardDetails] : message.cardDetails;
                    if (message.creditLine != null && message.hasOwnProperty("creditLine"))
                        object.creditLine = options.enums === String ? $root.visor.clientrpc.GetBankingDataSourceResponse.Source[message.creditLine] : message.creditLine;
                    return object;
                };

                /**
                 * Converts this GetBankingDataSourceResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBankingDataSourceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBankingDataSourceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Source enum.
                 * @name visor.clientrpc.GetBankingDataSourceResponse.Source
                 * @enum {string}
                 * @property {number} GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID=0 GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID value
                 * @property {number} GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC=1 GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC value
                 * @property {number} GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL=2 GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL value
                 */
                GetBankingDataSourceResponse.Source = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_INVALID"] = 0;
                    values[valuesById[1] = "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_RPC"] = 1;
                    values[valuesById[2] = "GET_BANKING_DATA_SOURCE_RESPONSE_SOURCE_FIREMODEL"] = 2;
                    return values;
                })();

                return GetBankingDataSourceResponse;
            })();

            clientrpc.EnrollCreditBuilderRequest = (function() {

                /**
                 * Properties of an EnrollCreditBuilderRequest.
                 * @memberof visor.clientrpc
                 * @interface IEnrollCreditBuilderRequest
                 * @property {string|null} [clientToken] EnrollCreditBuilderRequest clientToken
                 */

                /**
                 * Constructs a new EnrollCreditBuilderRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an EnrollCreditBuilderRequest.
                 * @implements IEnrollCreditBuilderRequest
                 * @constructor
                 * @param {visor.clientrpc.IEnrollCreditBuilderRequest=} [properties] Properties to set
                 */
                function EnrollCreditBuilderRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnrollCreditBuilderRequest clientToken.
                 * @member {string} clientToken
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @instance
                 */
                EnrollCreditBuilderRequest.prototype.clientToken = "";

                /**
                 * Creates a new EnrollCreditBuilderRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBuilderRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.EnrollCreditBuilderRequest} EnrollCreditBuilderRequest instance
                 */
                EnrollCreditBuilderRequest.create = function create(properties) {
                    return new EnrollCreditBuilderRequest(properties);
                };

                /**
                 * Encodes the specified EnrollCreditBuilderRequest message. Does not implicitly {@link visor.clientrpc.EnrollCreditBuilderRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBuilderRequest} message EnrollCreditBuilderRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBuilderRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientToken);
                    return writer;
                };

                /**
                 * Encodes the specified EnrollCreditBuilderRequest message, length delimited. Does not implicitly {@link visor.clientrpc.EnrollCreditBuilderRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBuilderRequest} message EnrollCreditBuilderRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBuilderRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnrollCreditBuilderRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.EnrollCreditBuilderRequest} EnrollCreditBuilderRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBuilderRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.EnrollCreditBuilderRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.clientToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnrollCreditBuilderRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.EnrollCreditBuilderRequest} EnrollCreditBuilderRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBuilderRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnrollCreditBuilderRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnrollCreditBuilderRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        if (!$util.isString(message.clientToken))
                            return "clientToken: string expected";
                    return null;
                };

                /**
                 * Creates an EnrollCreditBuilderRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.EnrollCreditBuilderRequest} EnrollCreditBuilderRequest
                 */
                EnrollCreditBuilderRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.EnrollCreditBuilderRequest)
                        return object;
                    var message = new $root.visor.clientrpc.EnrollCreditBuilderRequest();
                    if (object.clientToken != null)
                        message.clientToken = String(object.clientToken);
                    return message;
                };

                /**
                 * Creates a plain object from an EnrollCreditBuilderRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @static
                 * @param {visor.clientrpc.EnrollCreditBuilderRequest} message EnrollCreditBuilderRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnrollCreditBuilderRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.clientToken = "";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        object.clientToken = message.clientToken;
                    return object;
                };

                /**
                 * Converts this EnrollCreditBuilderRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.EnrollCreditBuilderRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnrollCreditBuilderRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnrollCreditBuilderRequest;
            })();

            clientrpc.EnrollCreditBuilderResponse = (function() {

                /**
                 * Properties of an EnrollCreditBuilderResponse.
                 * @memberof visor.clientrpc
                 * @interface IEnrollCreditBuilderResponse
                 */

                /**
                 * Constructs a new EnrollCreditBuilderResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an EnrollCreditBuilderResponse.
                 * @implements IEnrollCreditBuilderResponse
                 * @constructor
                 * @param {visor.clientrpc.IEnrollCreditBuilderResponse=} [properties] Properties to set
                 */
                function EnrollCreditBuilderResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new EnrollCreditBuilderResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBuilderResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.EnrollCreditBuilderResponse} EnrollCreditBuilderResponse instance
                 */
                EnrollCreditBuilderResponse.create = function create(properties) {
                    return new EnrollCreditBuilderResponse(properties);
                };

                /**
                 * Encodes the specified EnrollCreditBuilderResponse message. Does not implicitly {@link visor.clientrpc.EnrollCreditBuilderResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBuilderResponse} message EnrollCreditBuilderResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBuilderResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified EnrollCreditBuilderResponse message, length delimited. Does not implicitly {@link visor.clientrpc.EnrollCreditBuilderResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBuilderResponse} message EnrollCreditBuilderResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBuilderResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnrollCreditBuilderResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.EnrollCreditBuilderResponse} EnrollCreditBuilderResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBuilderResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.EnrollCreditBuilderResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnrollCreditBuilderResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.EnrollCreditBuilderResponse} EnrollCreditBuilderResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBuilderResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnrollCreditBuilderResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnrollCreditBuilderResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an EnrollCreditBuilderResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.EnrollCreditBuilderResponse} EnrollCreditBuilderResponse
                 */
                EnrollCreditBuilderResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.EnrollCreditBuilderResponse)
                        return object;
                    return new $root.visor.clientrpc.EnrollCreditBuilderResponse();
                };

                /**
                 * Creates a plain object from an EnrollCreditBuilderResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @static
                 * @param {visor.clientrpc.EnrollCreditBuilderResponse} message EnrollCreditBuilderResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnrollCreditBuilderResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this EnrollCreditBuilderResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.EnrollCreditBuilderResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnrollCreditBuilderResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnrollCreditBuilderResponse;
            })();

            clientrpc.GetCreditBuilderOfferRequest = (function() {

                /**
                 * Properties of a GetCreditBuilderOfferRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditBuilderOfferRequest
                 * @property {string|null} [clientToken] GetCreditBuilderOfferRequest clientToken
                 */

                /**
                 * Constructs a new GetCreditBuilderOfferRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditBuilderOfferRequest.
                 * @implements IGetCreditBuilderOfferRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditBuilderOfferRequest=} [properties] Properties to set
                 */
                function GetCreditBuilderOfferRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditBuilderOfferRequest clientToken.
                 * @member {string} clientToken
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @instance
                 */
                GetCreditBuilderOfferRequest.prototype.clientToken = "";

                /**
                 * Creates a new GetCreditBuilderOfferRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderOfferRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditBuilderOfferRequest} GetCreditBuilderOfferRequest instance
                 */
                GetCreditBuilderOfferRequest.create = function create(properties) {
                    return new GetCreditBuilderOfferRequest(properties);
                };

                /**
                 * Encodes the specified GetCreditBuilderOfferRequest message. Does not implicitly {@link visor.clientrpc.GetCreditBuilderOfferRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderOfferRequest} message GetCreditBuilderOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderOfferRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditBuilderOfferRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditBuilderOfferRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderOfferRequest} message GetCreditBuilderOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderOfferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditBuilderOfferRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditBuilderOfferRequest} GetCreditBuilderOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderOfferRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditBuilderOfferRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.clientToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditBuilderOfferRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditBuilderOfferRequest} GetCreditBuilderOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderOfferRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditBuilderOfferRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditBuilderOfferRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        if (!$util.isString(message.clientToken))
                            return "clientToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetCreditBuilderOfferRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditBuilderOfferRequest} GetCreditBuilderOfferRequest
                 */
                GetCreditBuilderOfferRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditBuilderOfferRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditBuilderOfferRequest();
                    if (object.clientToken != null)
                        message.clientToken = String(object.clientToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditBuilderOfferRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @static
                 * @param {visor.clientrpc.GetCreditBuilderOfferRequest} message GetCreditBuilderOfferRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditBuilderOfferRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.clientToken = "";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        object.clientToken = message.clientToken;
                    return object;
                };

                /**
                 * Converts this GetCreditBuilderOfferRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditBuilderOfferRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditBuilderOfferRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditBuilderOfferRequest;
            })();

            clientrpc.GetCreditBuilderOfferResponse = (function() {

                /**
                 * Properties of a GetCreditBuilderOfferResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditBuilderOfferResponse
                 * @property {number|null} [guaranteedScore] GetCreditBuilderOfferResponse guaranteedScore
                 * @property {visor.common.money.IAmount|null} [incentiveBonusAmount] GetCreditBuilderOfferResponse incentiveBonusAmount
                 */

                /**
                 * Constructs a new GetCreditBuilderOfferResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditBuilderOfferResponse.
                 * @implements IGetCreditBuilderOfferResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditBuilderOfferResponse=} [properties] Properties to set
                 */
                function GetCreditBuilderOfferResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditBuilderOfferResponse guaranteedScore.
                 * @member {number} guaranteedScore
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @instance
                 */
                GetCreditBuilderOfferResponse.prototype.guaranteedScore = 0;

                /**
                 * GetCreditBuilderOfferResponse incentiveBonusAmount.
                 * @member {visor.common.money.IAmount|null|undefined} incentiveBonusAmount
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @instance
                 */
                GetCreditBuilderOfferResponse.prototype.incentiveBonusAmount = null;

                /**
                 * Creates a new GetCreditBuilderOfferResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderOfferResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditBuilderOfferResponse} GetCreditBuilderOfferResponse instance
                 */
                GetCreditBuilderOfferResponse.create = function create(properties) {
                    return new GetCreditBuilderOfferResponse(properties);
                };

                /**
                 * Encodes the specified GetCreditBuilderOfferResponse message. Does not implicitly {@link visor.clientrpc.GetCreditBuilderOfferResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderOfferResponse} message GetCreditBuilderOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderOfferResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.guaranteedScore != null && message.hasOwnProperty("guaranteedScore"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.guaranteedScore);
                    if (message.incentiveBonusAmount != null && message.hasOwnProperty("incentiveBonusAmount"))
                        $root.visor.common.money.Amount.encode(message.incentiveBonusAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditBuilderOfferResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditBuilderOfferResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderOfferResponse} message GetCreditBuilderOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderOfferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditBuilderOfferResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditBuilderOfferResponse} GetCreditBuilderOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderOfferResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditBuilderOfferResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.guaranteedScore = reader.int32();
                            break;
                        case 2:
                            message.incentiveBonusAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditBuilderOfferResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditBuilderOfferResponse} GetCreditBuilderOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderOfferResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditBuilderOfferResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditBuilderOfferResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.guaranteedScore != null && message.hasOwnProperty("guaranteedScore"))
                        if (!$util.isInteger(message.guaranteedScore))
                            return "guaranteedScore: integer expected";
                    if (message.incentiveBonusAmount != null && message.hasOwnProperty("incentiveBonusAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.incentiveBonusAmount);
                        if (error)
                            return "incentiveBonusAmount." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetCreditBuilderOfferResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditBuilderOfferResponse} GetCreditBuilderOfferResponse
                 */
                GetCreditBuilderOfferResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditBuilderOfferResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditBuilderOfferResponse();
                    if (object.guaranteedScore != null)
                        message.guaranteedScore = object.guaranteedScore | 0;
                    if (object.incentiveBonusAmount != null) {
                        if (typeof object.incentiveBonusAmount !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditBuilderOfferResponse.incentiveBonusAmount: object expected");
                        message.incentiveBonusAmount = $root.visor.common.money.Amount.fromObject(object.incentiveBonusAmount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditBuilderOfferResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @static
                 * @param {visor.clientrpc.GetCreditBuilderOfferResponse} message GetCreditBuilderOfferResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditBuilderOfferResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.guaranteedScore = 0;
                        object.incentiveBonusAmount = null;
                    }
                    if (message.guaranteedScore != null && message.hasOwnProperty("guaranteedScore"))
                        object.guaranteedScore = message.guaranteedScore;
                    if (message.incentiveBonusAmount != null && message.hasOwnProperty("incentiveBonusAmount"))
                        object.incentiveBonusAmount = $root.visor.common.money.Amount.toObject(message.incentiveBonusAmount, options);
                    return object;
                };

                /**
                 * Converts this GetCreditBuilderOfferResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditBuilderOfferResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditBuilderOfferResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditBuilderOfferResponse;
            })();

            clientrpc.PushBoostCardToWalletRequest = (function() {

                /**
                 * Properties of a PushBoostCardToWalletRequest.
                 * @memberof visor.clientrpc
                 * @interface IPushBoostCardToWalletRequest
                 * @property {string|null} [subnetToken] PushBoostCardToWalletRequest subnetToken
                 * @property {visor.clientrpc.PushBoostCardToWalletRequest.IAppleWalletRequestData|null} [apple] PushBoostCardToWalletRequest apple
                 * @property {visor.clientrpc.PushBoostCardToWalletRequest.IGoogleWalletRequestData|null} [google] PushBoostCardToWalletRequest google
                 */

                /**
                 * Constructs a new PushBoostCardToWalletRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PushBoostCardToWalletRequest.
                 * @implements IPushBoostCardToWalletRequest
                 * @constructor
                 * @param {visor.clientrpc.IPushBoostCardToWalletRequest=} [properties] Properties to set
                 */
                function PushBoostCardToWalletRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PushBoostCardToWalletRequest subnetToken.
                 * @member {string} subnetToken
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @instance
                 */
                PushBoostCardToWalletRequest.prototype.subnetToken = "";

                /**
                 * PushBoostCardToWalletRequest apple.
                 * @member {visor.clientrpc.PushBoostCardToWalletRequest.IAppleWalletRequestData|null|undefined} apple
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @instance
                 */
                PushBoostCardToWalletRequest.prototype.apple = null;

                /**
                 * PushBoostCardToWalletRequest google.
                 * @member {visor.clientrpc.PushBoostCardToWalletRequest.IGoogleWalletRequestData|null|undefined} google
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @instance
                 */
                PushBoostCardToWalletRequest.prototype.google = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * PushBoostCardToWalletRequest walletType.
                 * @member {"apple"|"google"|undefined} walletType
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @instance
                 */
                Object.defineProperty(PushBoostCardToWalletRequest.prototype, "walletType", {
                    get: $util.oneOfGetter($oneOfFields = ["apple", "google"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new PushBoostCardToWalletRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {visor.clientrpc.IPushBoostCardToWalletRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.PushBoostCardToWalletRequest} PushBoostCardToWalletRequest instance
                 */
                PushBoostCardToWalletRequest.create = function create(properties) {
                    return new PushBoostCardToWalletRequest(properties);
                };

                /**
                 * Encodes the specified PushBoostCardToWalletRequest message. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {visor.clientrpc.IPushBoostCardToWalletRequest} message PushBoostCardToWalletRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushBoostCardToWalletRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.apple != null && message.hasOwnProperty("apple"))
                        $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.encode(message.apple, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.google != null && message.hasOwnProperty("google"))
                        $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData.encode(message.google, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.subnetToken);
                    return writer;
                };

                /**
                 * Encodes the specified PushBoostCardToWalletRequest message, length delimited. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {visor.clientrpc.IPushBoostCardToWalletRequest} message PushBoostCardToWalletRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushBoostCardToWalletRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PushBoostCardToWalletRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PushBoostCardToWalletRequest} PushBoostCardToWalletRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushBoostCardToWalletRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PushBoostCardToWalletRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3:
                            message.subnetToken = reader.string();
                            break;
                        case 1:
                            message.apple = $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.google = $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PushBoostCardToWalletRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PushBoostCardToWalletRequest} PushBoostCardToWalletRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushBoostCardToWalletRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PushBoostCardToWalletRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PushBoostCardToWalletRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        if (!$util.isString(message.subnetToken))
                            return "subnetToken: string expected";
                    if (message.apple != null && message.hasOwnProperty("apple")) {
                        properties.walletType = 1;
                        {
                            var error = $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.verify(message.apple);
                            if (error)
                                return "apple." + error;
                        }
                    }
                    if (message.google != null && message.hasOwnProperty("google")) {
                        if (properties.walletType === 1)
                            return "walletType: multiple values";
                        properties.walletType = 1;
                        {
                            var error = $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData.verify(message.google);
                            if (error)
                                return "google." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PushBoostCardToWalletRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PushBoostCardToWalletRequest} PushBoostCardToWalletRequest
                 */
                PushBoostCardToWalletRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PushBoostCardToWalletRequest)
                        return object;
                    var message = new $root.visor.clientrpc.PushBoostCardToWalletRequest();
                    if (object.subnetToken != null)
                        message.subnetToken = String(object.subnetToken);
                    if (object.apple != null) {
                        if (typeof object.apple !== "object")
                            throw TypeError(".visor.clientrpc.PushBoostCardToWalletRequest.apple: object expected");
                        message.apple = $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.fromObject(object.apple);
                    }
                    if (object.google != null) {
                        if (typeof object.google !== "object")
                            throw TypeError(".visor.clientrpc.PushBoostCardToWalletRequest.google: object expected");
                        message.google = $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData.fromObject(object.google);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PushBoostCardToWalletRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @static
                 * @param {visor.clientrpc.PushBoostCardToWalletRequest} message PushBoostCardToWalletRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PushBoostCardToWalletRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.subnetToken = "";
                    if (message.apple != null && message.hasOwnProperty("apple")) {
                        object.apple = $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.toObject(message.apple, options);
                        if (options.oneofs)
                            object.walletType = "apple";
                    }
                    if (message.google != null && message.hasOwnProperty("google")) {
                        object.google = $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData.toObject(message.google, options);
                        if (options.oneofs)
                            object.walletType = "google";
                    }
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        object.subnetToken = message.subnetToken;
                    return object;
                };

                /**
                 * Converts this PushBoostCardToWalletRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PushBoostCardToWalletRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                PushBoostCardToWalletRequest.AppleWalletRequestData = (function() {

                    /**
                     * Properties of an AppleWalletRequestData.
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                     * @interface IAppleWalletRequestData
                     * @property {string|null} [nonce] AppleWalletRequestData nonce
                     * @property {string|null} [nonceSignature] AppleWalletRequestData nonceSignature
                     * @property {Array.<string>|null} [certificates] AppleWalletRequestData certificates
                     */

                    /**
                     * Constructs a new AppleWalletRequestData.
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                     * @classdesc Represents an AppleWalletRequestData.
                     * @implements IAppleWalletRequestData
                     * @constructor
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IAppleWalletRequestData=} [properties] Properties to set
                     */
                    function AppleWalletRequestData(properties) {
                        this.certificates = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AppleWalletRequestData nonce.
                     * @member {string} nonce
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @instance
                     */
                    AppleWalletRequestData.prototype.nonce = "";

                    /**
                     * AppleWalletRequestData nonceSignature.
                     * @member {string} nonceSignature
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @instance
                     */
                    AppleWalletRequestData.prototype.nonceSignature = "";

                    /**
                     * AppleWalletRequestData certificates.
                     * @member {Array.<string>} certificates
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @instance
                     */
                    AppleWalletRequestData.prototype.certificates = $util.emptyArray;

                    /**
                     * Creates a new AppleWalletRequestData instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IAppleWalletRequestData=} [properties] Properties to set
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData} AppleWalletRequestData instance
                     */
                    AppleWalletRequestData.create = function create(properties) {
                        return new AppleWalletRequestData(properties);
                    };

                    /**
                     * Encodes the specified AppleWalletRequestData message. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IAppleWalletRequestData} message AppleWalletRequestData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AppleWalletRequestData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.nonce != null && message.hasOwnProperty("nonce"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.nonce);
                        if (message.nonceSignature != null && message.hasOwnProperty("nonceSignature"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.nonceSignature);
                        if (message.certificates != null && message.certificates.length)
                            for (var i = 0; i < message.certificates.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.certificates[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified AppleWalletRequestData message, length delimited. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IAppleWalletRequestData} message AppleWalletRequestData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AppleWalletRequestData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AppleWalletRequestData message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData} AppleWalletRequestData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AppleWalletRequestData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.nonce = reader.string();
                                break;
                            case 2:
                                message.nonceSignature = reader.string();
                                break;
                            case 3:
                                if (!(message.certificates && message.certificates.length))
                                    message.certificates = [];
                                message.certificates.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AppleWalletRequestData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData} AppleWalletRequestData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AppleWalletRequestData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AppleWalletRequestData message.
                     * @function verify
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AppleWalletRequestData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.nonce != null && message.hasOwnProperty("nonce"))
                            if (!$util.isString(message.nonce))
                                return "nonce: string expected";
                        if (message.nonceSignature != null && message.hasOwnProperty("nonceSignature"))
                            if (!$util.isString(message.nonceSignature))
                                return "nonceSignature: string expected";
                        if (message.certificates != null && message.hasOwnProperty("certificates")) {
                            if (!Array.isArray(message.certificates))
                                return "certificates: array expected";
                            for (var i = 0; i < message.certificates.length; ++i)
                                if (!$util.isString(message.certificates[i]))
                                    return "certificates: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates an AppleWalletRequestData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData} AppleWalletRequestData
                     */
                    AppleWalletRequestData.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData)
                            return object;
                        var message = new $root.visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData();
                        if (object.nonce != null)
                            message.nonce = String(object.nonce);
                        if (object.nonceSignature != null)
                            message.nonceSignature = String(object.nonceSignature);
                        if (object.certificates) {
                            if (!Array.isArray(object.certificates))
                                throw TypeError(".visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData.certificates: array expected");
                            message.certificates = [];
                            for (var i = 0; i < object.certificates.length; ++i)
                                message.certificates[i] = String(object.certificates[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AppleWalletRequestData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData} message AppleWalletRequestData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AppleWalletRequestData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.certificates = [];
                        if (options.defaults) {
                            object.nonce = "";
                            object.nonceSignature = "";
                        }
                        if (message.nonce != null && message.hasOwnProperty("nonce"))
                            object.nonce = message.nonce;
                        if (message.nonceSignature != null && message.hasOwnProperty("nonceSignature"))
                            object.nonceSignature = message.nonceSignature;
                        if (message.certificates && message.certificates.length) {
                            object.certificates = [];
                            for (var j = 0; j < message.certificates.length; ++j)
                                object.certificates[j] = message.certificates[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this AppleWalletRequestData to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.AppleWalletRequestData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AppleWalletRequestData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AppleWalletRequestData;
                })();

                PushBoostCardToWalletRequest.GoogleWalletRequestData = (function() {

                    /**
                     * Properties of a GoogleWalletRequestData.
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                     * @interface IGoogleWalletRequestData
                     */

                    /**
                     * Constructs a new GoogleWalletRequestData.
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest
                     * @classdesc Represents a GoogleWalletRequestData.
                     * @implements IGoogleWalletRequestData
                     * @constructor
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IGoogleWalletRequestData=} [properties] Properties to set
                     */
                    function GoogleWalletRequestData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new GoogleWalletRequestData instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IGoogleWalletRequestData=} [properties] Properties to set
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData} GoogleWalletRequestData instance
                     */
                    GoogleWalletRequestData.create = function create(properties) {
                        return new GoogleWalletRequestData(properties);
                    };

                    /**
                     * Encodes the specified GoogleWalletRequestData message. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IGoogleWalletRequestData} message GoogleWalletRequestData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GoogleWalletRequestData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified GoogleWalletRequestData message, length delimited. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.IGoogleWalletRequestData} message GoogleWalletRequestData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GoogleWalletRequestData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GoogleWalletRequestData message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData} GoogleWalletRequestData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GoogleWalletRequestData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GoogleWalletRequestData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData} GoogleWalletRequestData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GoogleWalletRequestData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GoogleWalletRequestData message.
                     * @function verify
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GoogleWalletRequestData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a GoogleWalletRequestData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData} GoogleWalletRequestData
                     */
                    GoogleWalletRequestData.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData)
                            return object;
                        return new $root.visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData();
                    };

                    /**
                     * Creates a plain object from a GoogleWalletRequestData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @static
                     * @param {visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData} message GoogleWalletRequestData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GoogleWalletRequestData.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this GoogleWalletRequestData to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.PushBoostCardToWalletRequest.GoogleWalletRequestData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GoogleWalletRequestData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GoogleWalletRequestData;
                })();

                return PushBoostCardToWalletRequest;
            })();

            clientrpc.PushBoostCardToWalletResponse = (function() {

                /**
                 * Properties of a PushBoostCardToWalletResponse.
                 * @memberof visor.clientrpc
                 * @interface IPushBoostCardToWalletResponse
                 * @property {string|null} [activationData] PushBoostCardToWalletResponse activationData
                 * @property {string|null} [encryptedData] PushBoostCardToWalletResponse encryptedData
                 * @property {string|null} [publicKey] PushBoostCardToWalletResponse publicKey
                 */

                /**
                 * Constructs a new PushBoostCardToWalletResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PushBoostCardToWalletResponse.
                 * @implements IPushBoostCardToWalletResponse
                 * @constructor
                 * @param {visor.clientrpc.IPushBoostCardToWalletResponse=} [properties] Properties to set
                 */
                function PushBoostCardToWalletResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PushBoostCardToWalletResponse activationData.
                 * @member {string} activationData
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @instance
                 */
                PushBoostCardToWalletResponse.prototype.activationData = "";

                /**
                 * PushBoostCardToWalletResponse encryptedData.
                 * @member {string} encryptedData
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @instance
                 */
                PushBoostCardToWalletResponse.prototype.encryptedData = "";

                /**
                 * PushBoostCardToWalletResponse publicKey.
                 * @member {string} publicKey
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @instance
                 */
                PushBoostCardToWalletResponse.prototype.publicKey = "";

                /**
                 * Creates a new PushBoostCardToWalletResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {visor.clientrpc.IPushBoostCardToWalletResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.PushBoostCardToWalletResponse} PushBoostCardToWalletResponse instance
                 */
                PushBoostCardToWalletResponse.create = function create(properties) {
                    return new PushBoostCardToWalletResponse(properties);
                };

                /**
                 * Encodes the specified PushBoostCardToWalletResponse message. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {visor.clientrpc.IPushBoostCardToWalletResponse} message PushBoostCardToWalletResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushBoostCardToWalletResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.activationData != null && message.hasOwnProperty("activationData"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.activationData);
                    if (message.encryptedData != null && message.hasOwnProperty("encryptedData"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.encryptedData);
                    if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.publicKey);
                    return writer;
                };

                /**
                 * Encodes the specified PushBoostCardToWalletResponse message, length delimited. Does not implicitly {@link visor.clientrpc.PushBoostCardToWalletResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {visor.clientrpc.IPushBoostCardToWalletResponse} message PushBoostCardToWalletResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PushBoostCardToWalletResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PushBoostCardToWalletResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PushBoostCardToWalletResponse} PushBoostCardToWalletResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushBoostCardToWalletResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PushBoostCardToWalletResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.activationData = reader.string();
                            break;
                        case 2:
                            message.encryptedData = reader.string();
                            break;
                        case 3:
                            message.publicKey = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PushBoostCardToWalletResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PushBoostCardToWalletResponse} PushBoostCardToWalletResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PushBoostCardToWalletResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PushBoostCardToWalletResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PushBoostCardToWalletResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.activationData != null && message.hasOwnProperty("activationData"))
                        if (!$util.isString(message.activationData))
                            return "activationData: string expected";
                    if (message.encryptedData != null && message.hasOwnProperty("encryptedData"))
                        if (!$util.isString(message.encryptedData))
                            return "encryptedData: string expected";
                    if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                        if (!$util.isString(message.publicKey))
                            return "publicKey: string expected";
                    return null;
                };

                /**
                 * Creates a PushBoostCardToWalletResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PushBoostCardToWalletResponse} PushBoostCardToWalletResponse
                 */
                PushBoostCardToWalletResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PushBoostCardToWalletResponse)
                        return object;
                    var message = new $root.visor.clientrpc.PushBoostCardToWalletResponse();
                    if (object.activationData != null)
                        message.activationData = String(object.activationData);
                    if (object.encryptedData != null)
                        message.encryptedData = String(object.encryptedData);
                    if (object.publicKey != null)
                        message.publicKey = String(object.publicKey);
                    return message;
                };

                /**
                 * Creates a plain object from a PushBoostCardToWalletResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @static
                 * @param {visor.clientrpc.PushBoostCardToWalletResponse} message PushBoostCardToWalletResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PushBoostCardToWalletResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.activationData = "";
                        object.encryptedData = "";
                        object.publicKey = "";
                    }
                    if (message.activationData != null && message.hasOwnProperty("activationData"))
                        object.activationData = message.activationData;
                    if (message.encryptedData != null && message.hasOwnProperty("encryptedData"))
                        object.encryptedData = message.encryptedData;
                    if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                        object.publicKey = message.publicKey;
                    return object;
                };

                /**
                 * Converts this PushBoostCardToWalletResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PushBoostCardToWalletResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PushBoostCardToWalletResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PushBoostCardToWalletResponse;
            })();

            clientrpc.GetCreditLineLimitIncreaseStatusRequest = (function() {

                /**
                 * Properties of a GetCreditLineLimitIncreaseStatusRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditLineLimitIncreaseStatusRequest
                 * @property {string|null} [creditLineToken] GetCreditLineLimitIncreaseStatusRequest creditLineToken
                 */

                /**
                 * Constructs a new GetCreditLineLimitIncreaseStatusRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditLineLimitIncreaseStatusRequest.
                 * @implements IGetCreditLineLimitIncreaseStatusRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusRequest=} [properties] Properties to set
                 */
                function GetCreditLineLimitIncreaseStatusRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditLineLimitIncreaseStatusRequest creditLineToken.
                 * @member {string} creditLineToken
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusRequest.prototype.creditLineToken = "";

                /**
                 * Creates a new GetCreditLineLimitIncreaseStatusRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest} GetCreditLineLimitIncreaseStatusRequest instance
                 */
                GetCreditLineLimitIncreaseStatusRequest.create = function create(properties) {
                    return new GetCreditLineLimitIncreaseStatusRequest(properties);
                };

                /**
                 * Encodes the specified GetCreditLineLimitIncreaseStatusRequest message. Does not implicitly {@link visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusRequest} message GetCreditLineLimitIncreaseStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineLimitIncreaseStatusRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.creditLineToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditLineLimitIncreaseStatusRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusRequest} message GetCreditLineLimitIncreaseStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineLimitIncreaseStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditLineLimitIncreaseStatusRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest} GetCreditLineLimitIncreaseStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineLimitIncreaseStatusRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.creditLineToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditLineLimitIncreaseStatusRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest} GetCreditLineLimitIncreaseStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineLimitIncreaseStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditLineLimitIncreaseStatusRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditLineLimitIncreaseStatusRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        if (!$util.isString(message.creditLineToken))
                            return "creditLineToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetCreditLineLimitIncreaseStatusRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest} GetCreditLineLimitIncreaseStatusRequest
                 */
                GetCreditLineLimitIncreaseStatusRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest();
                    if (object.creditLineToken != null)
                        message.creditLineToken = String(object.creditLineToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditLineLimitIncreaseStatusRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @static
                 * @param {visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest} message GetCreditLineLimitIncreaseStatusRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditLineLimitIncreaseStatusRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.creditLineToken = "";
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        object.creditLineToken = message.creditLineToken;
                    return object;
                };

                /**
                 * Converts this GetCreditLineLimitIncreaseStatusRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditLineLimitIncreaseStatusRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditLineLimitIncreaseStatusRequest;
            })();

            clientrpc.GetCreditLineLimitIncreaseStatusResponse = (function() {

                /**
                 * Properties of a GetCreditLineLimitIncreaseStatusResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditLineLimitIncreaseStatusResponse
                 * @property {visor.common.money.IAmount|null} [increaseAvailable] GetCreditLineLimitIncreaseStatusResponse increaseAvailable
                 * @property {number|null} [daysRemainingForSpendGoal] GetCreditLineLimitIncreaseStatusResponse daysRemainingForSpendGoal
                 * @property {visor.common.money.IAmount|null} [spendAchieved] GetCreditLineLimitIncreaseStatusResponse spendAchieved
                 * @property {visor.common.money.IAmount|null} [spendRequirement] GetCreditLineLimitIncreaseStatusResponse spendRequirement
                 * @property {number|null} [daysRemainingForDirectDepositGoal] GetCreditLineLimitIncreaseStatusResponse daysRemainingForDirectDepositGoal
                 * @property {visor.common.money.IAmount|null} [directDepositAchieved] GetCreditLineLimitIncreaseStatusResponse directDepositAchieved
                 * @property {visor.common.money.IAmount|null} [directDepositRequirement] GetCreditLineLimitIncreaseStatusResponse directDepositRequirement
                 * @property {boolean|null} [creditLineIsDelinquent] GetCreditLineLimitIncreaseStatusResponse creditLineIsDelinquent
                 */

                /**
                 * Constructs a new GetCreditLineLimitIncreaseStatusResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditLineLimitIncreaseStatusResponse.
                 * @implements IGetCreditLineLimitIncreaseStatusResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusResponse=} [properties] Properties to set
                 */
                function GetCreditLineLimitIncreaseStatusResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditLineLimitIncreaseStatusResponse increaseAvailable.
                 * @member {visor.common.money.IAmount|null|undefined} increaseAvailable
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.increaseAvailable = null;

                /**
                 * GetCreditLineLimitIncreaseStatusResponse daysRemainingForSpendGoal.
                 * @member {number} daysRemainingForSpendGoal
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.daysRemainingForSpendGoal = 0;

                /**
                 * GetCreditLineLimitIncreaseStatusResponse spendAchieved.
                 * @member {visor.common.money.IAmount|null|undefined} spendAchieved
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.spendAchieved = null;

                /**
                 * GetCreditLineLimitIncreaseStatusResponse spendRequirement.
                 * @member {visor.common.money.IAmount|null|undefined} spendRequirement
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.spendRequirement = null;

                /**
                 * GetCreditLineLimitIncreaseStatusResponse daysRemainingForDirectDepositGoal.
                 * @member {number} daysRemainingForDirectDepositGoal
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.daysRemainingForDirectDepositGoal = 0;

                /**
                 * GetCreditLineLimitIncreaseStatusResponse directDepositAchieved.
                 * @member {visor.common.money.IAmount|null|undefined} directDepositAchieved
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.directDepositAchieved = null;

                /**
                 * GetCreditLineLimitIncreaseStatusResponse directDepositRequirement.
                 * @member {visor.common.money.IAmount|null|undefined} directDepositRequirement
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.directDepositRequirement = null;

                /**
                 * GetCreditLineLimitIncreaseStatusResponse creditLineIsDelinquent.
                 * @member {boolean} creditLineIsDelinquent
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.creditLineIsDelinquent = false;

                /**
                 * Creates a new GetCreditLineLimitIncreaseStatusResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse} GetCreditLineLimitIncreaseStatusResponse instance
                 */
                GetCreditLineLimitIncreaseStatusResponse.create = function create(properties) {
                    return new GetCreditLineLimitIncreaseStatusResponse(properties);
                };

                /**
                 * Encodes the specified GetCreditLineLimitIncreaseStatusResponse message. Does not implicitly {@link visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusResponse} message GetCreditLineLimitIncreaseStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineLimitIncreaseStatusResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.increaseAvailable != null && message.hasOwnProperty("increaseAvailable"))
                        $root.visor.common.money.Amount.encode(message.increaseAvailable, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.daysRemainingForSpendGoal != null && message.hasOwnProperty("daysRemainingForSpendGoal"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.daysRemainingForSpendGoal);
                    if (message.spendAchieved != null && message.hasOwnProperty("spendAchieved"))
                        $root.visor.common.money.Amount.encode(message.spendAchieved, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.spendRequirement != null && message.hasOwnProperty("spendRequirement"))
                        $root.visor.common.money.Amount.encode(message.spendRequirement, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.daysRemainingForDirectDepositGoal != null && message.hasOwnProperty("daysRemainingForDirectDepositGoal"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.daysRemainingForDirectDepositGoal);
                    if (message.directDepositAchieved != null && message.hasOwnProperty("directDepositAchieved"))
                        $root.visor.common.money.Amount.encode(message.directDepositAchieved, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.directDepositRequirement != null && message.hasOwnProperty("directDepositRequirement"))
                        $root.visor.common.money.Amount.encode(message.directDepositRequirement, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.creditLineIsDelinquent != null && message.hasOwnProperty("creditLineIsDelinquent"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.creditLineIsDelinquent);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditLineLimitIncreaseStatusResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineLimitIncreaseStatusResponse} message GetCreditLineLimitIncreaseStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineLimitIncreaseStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditLineLimitIncreaseStatusResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse} GetCreditLineLimitIncreaseStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineLimitIncreaseStatusResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.increaseAvailable = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.daysRemainingForSpendGoal = reader.int32();
                            break;
                        case 3:
                            message.spendAchieved = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.spendRequirement = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.daysRemainingForDirectDepositGoal = reader.int32();
                            break;
                        case 6:
                            message.directDepositAchieved = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.directDepositRequirement = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 8:
                            message.creditLineIsDelinquent = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditLineLimitIncreaseStatusResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse} GetCreditLineLimitIncreaseStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineLimitIncreaseStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditLineLimitIncreaseStatusResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditLineLimitIncreaseStatusResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.increaseAvailable != null && message.hasOwnProperty("increaseAvailable")) {
                        var error = $root.visor.common.money.Amount.verify(message.increaseAvailable);
                        if (error)
                            return "increaseAvailable." + error;
                    }
                    if (message.daysRemainingForSpendGoal != null && message.hasOwnProperty("daysRemainingForSpendGoal"))
                        if (!$util.isInteger(message.daysRemainingForSpendGoal))
                            return "daysRemainingForSpendGoal: integer expected";
                    if (message.spendAchieved != null && message.hasOwnProperty("spendAchieved")) {
                        var error = $root.visor.common.money.Amount.verify(message.spendAchieved);
                        if (error)
                            return "spendAchieved." + error;
                    }
                    if (message.spendRequirement != null && message.hasOwnProperty("spendRequirement")) {
                        var error = $root.visor.common.money.Amount.verify(message.spendRequirement);
                        if (error)
                            return "spendRequirement." + error;
                    }
                    if (message.daysRemainingForDirectDepositGoal != null && message.hasOwnProperty("daysRemainingForDirectDepositGoal"))
                        if (!$util.isInteger(message.daysRemainingForDirectDepositGoal))
                            return "daysRemainingForDirectDepositGoal: integer expected";
                    if (message.directDepositAchieved != null && message.hasOwnProperty("directDepositAchieved")) {
                        var error = $root.visor.common.money.Amount.verify(message.directDepositAchieved);
                        if (error)
                            return "directDepositAchieved." + error;
                    }
                    if (message.directDepositRequirement != null && message.hasOwnProperty("directDepositRequirement")) {
                        var error = $root.visor.common.money.Amount.verify(message.directDepositRequirement);
                        if (error)
                            return "directDepositRequirement." + error;
                    }
                    if (message.creditLineIsDelinquent != null && message.hasOwnProperty("creditLineIsDelinquent"))
                        if (typeof message.creditLineIsDelinquent !== "boolean")
                            return "creditLineIsDelinquent: boolean expected";
                    return null;
                };

                /**
                 * Creates a GetCreditLineLimitIncreaseStatusResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse} GetCreditLineLimitIncreaseStatusResponse
                 */
                GetCreditLineLimitIncreaseStatusResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse();
                    if (object.increaseAvailable != null) {
                        if (typeof object.increaseAvailable !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse.increaseAvailable: object expected");
                        message.increaseAvailable = $root.visor.common.money.Amount.fromObject(object.increaseAvailable);
                    }
                    if (object.daysRemainingForSpendGoal != null)
                        message.daysRemainingForSpendGoal = object.daysRemainingForSpendGoal | 0;
                    if (object.spendAchieved != null) {
                        if (typeof object.spendAchieved !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse.spendAchieved: object expected");
                        message.spendAchieved = $root.visor.common.money.Amount.fromObject(object.spendAchieved);
                    }
                    if (object.spendRequirement != null) {
                        if (typeof object.spendRequirement !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse.spendRequirement: object expected");
                        message.spendRequirement = $root.visor.common.money.Amount.fromObject(object.spendRequirement);
                    }
                    if (object.daysRemainingForDirectDepositGoal != null)
                        message.daysRemainingForDirectDepositGoal = object.daysRemainingForDirectDepositGoal | 0;
                    if (object.directDepositAchieved != null) {
                        if (typeof object.directDepositAchieved !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse.directDepositAchieved: object expected");
                        message.directDepositAchieved = $root.visor.common.money.Amount.fromObject(object.directDepositAchieved);
                    }
                    if (object.directDepositRequirement != null) {
                        if (typeof object.directDepositRequirement !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse.directDepositRequirement: object expected");
                        message.directDepositRequirement = $root.visor.common.money.Amount.fromObject(object.directDepositRequirement);
                    }
                    if (object.creditLineIsDelinquent != null)
                        message.creditLineIsDelinquent = Boolean(object.creditLineIsDelinquent);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditLineLimitIncreaseStatusResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @static
                 * @param {visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse} message GetCreditLineLimitIncreaseStatusResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditLineLimitIncreaseStatusResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.increaseAvailable = null;
                        object.daysRemainingForSpendGoal = 0;
                        object.spendAchieved = null;
                        object.spendRequirement = null;
                        object.daysRemainingForDirectDepositGoal = 0;
                        object.directDepositAchieved = null;
                        object.directDepositRequirement = null;
                        object.creditLineIsDelinquent = false;
                    }
                    if (message.increaseAvailable != null && message.hasOwnProperty("increaseAvailable"))
                        object.increaseAvailable = $root.visor.common.money.Amount.toObject(message.increaseAvailable, options);
                    if (message.daysRemainingForSpendGoal != null && message.hasOwnProperty("daysRemainingForSpendGoal"))
                        object.daysRemainingForSpendGoal = message.daysRemainingForSpendGoal;
                    if (message.spendAchieved != null && message.hasOwnProperty("spendAchieved"))
                        object.spendAchieved = $root.visor.common.money.Amount.toObject(message.spendAchieved, options);
                    if (message.spendRequirement != null && message.hasOwnProperty("spendRequirement"))
                        object.spendRequirement = $root.visor.common.money.Amount.toObject(message.spendRequirement, options);
                    if (message.daysRemainingForDirectDepositGoal != null && message.hasOwnProperty("daysRemainingForDirectDepositGoal"))
                        object.daysRemainingForDirectDepositGoal = message.daysRemainingForDirectDepositGoal;
                    if (message.directDepositAchieved != null && message.hasOwnProperty("directDepositAchieved"))
                        object.directDepositAchieved = $root.visor.common.money.Amount.toObject(message.directDepositAchieved, options);
                    if (message.directDepositRequirement != null && message.hasOwnProperty("directDepositRequirement"))
                        object.directDepositRequirement = $root.visor.common.money.Amount.toObject(message.directDepositRequirement, options);
                    if (message.creditLineIsDelinquent != null && message.hasOwnProperty("creditLineIsDelinquent"))
                        object.creditLineIsDelinquent = message.creditLineIsDelinquent;
                    return object;
                };

                /**
                 * Converts this GetCreditLineLimitIncreaseStatusResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditLineLimitIncreaseStatusResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditLineLimitIncreaseStatusResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditLineLimitIncreaseStatusResponse;
            })();

            clientrpc.CompleteCreditLineEnrollmentRequest = (function() {

                /**
                 * Properties of a CompleteCreditLineEnrollmentRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCreditLineEnrollmentRequest
                 * @property {string|null} [boostCardProgramToken] CompleteCreditLineEnrollmentRequest boostCardProgramToken
                 * @property {string|null} [creditLineToken] CompleteCreditLineEnrollmentRequest creditLineToken
                 * @property {boolean|null} [autoPayEnabled] CompleteCreditLineEnrollmentRequest autoPayEnabled
                 * @property {visor.common.money.IAmount|null} [cashboostBalanceTransferAmount] CompleteCreditLineEnrollmentRequest cashboostBalanceTransferAmount
                 * @property {visor.common.money.IAmount|null} [advanceAmount] CompleteCreditLineEnrollmentRequest advanceAmount
                 * @property {visor.common.money.IAmount|null} [gridSubscriptionAmount] CompleteCreditLineEnrollmentRequest gridSubscriptionAmount
                 * @property {visor.common.money.IAmount|null} [gridSubscriptionReactivationFeeAmount] CompleteCreditLineEnrollmentRequest gridSubscriptionReactivationFeeAmount
                 */

                /**
                 * Constructs a new CompleteCreditLineEnrollmentRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCreditLineEnrollmentRequest.
                 * @implements ICompleteCreditLineEnrollmentRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentRequest=} [properties] Properties to set
                 */
                function CompleteCreditLineEnrollmentRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteCreditLineEnrollmentRequest boostCardProgramToken.
                 * @member {string} boostCardProgramToken
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                CompleteCreditLineEnrollmentRequest.prototype.boostCardProgramToken = "";

                /**
                 * CompleteCreditLineEnrollmentRequest creditLineToken.
                 * @member {string} creditLineToken
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                CompleteCreditLineEnrollmentRequest.prototype.creditLineToken = "";

                /**
                 * CompleteCreditLineEnrollmentRequest autoPayEnabled.
                 * @member {boolean} autoPayEnabled
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                CompleteCreditLineEnrollmentRequest.prototype.autoPayEnabled = false;

                /**
                 * CompleteCreditLineEnrollmentRequest cashboostBalanceTransferAmount.
                 * @member {visor.common.money.IAmount|null|undefined} cashboostBalanceTransferAmount
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                CompleteCreditLineEnrollmentRequest.prototype.cashboostBalanceTransferAmount = null;

                /**
                 * CompleteCreditLineEnrollmentRequest advanceAmount.
                 * @member {visor.common.money.IAmount|null|undefined} advanceAmount
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                CompleteCreditLineEnrollmentRequest.prototype.advanceAmount = null;

                /**
                 * CompleteCreditLineEnrollmentRequest gridSubscriptionAmount.
                 * @member {visor.common.money.IAmount|null|undefined} gridSubscriptionAmount
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                CompleteCreditLineEnrollmentRequest.prototype.gridSubscriptionAmount = null;

                /**
                 * CompleteCreditLineEnrollmentRequest gridSubscriptionReactivationFeeAmount.
                 * @member {visor.common.money.IAmount|null|undefined} gridSubscriptionReactivationFeeAmount
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                CompleteCreditLineEnrollmentRequest.prototype.gridSubscriptionReactivationFeeAmount = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * CompleteCreditLineEnrollmentRequest subscriptionType.
                 * @member {"gridSubscriptionAmount"|"gridSubscriptionReactivationFeeAmount"|undefined} subscriptionType
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 */
                Object.defineProperty(CompleteCreditLineEnrollmentRequest.prototype, "subscriptionType", {
                    get: $util.oneOfGetter($oneOfFields = ["gridSubscriptionAmount", "gridSubscriptionReactivationFeeAmount"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new CompleteCreditLineEnrollmentRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentRequest} CompleteCreditLineEnrollmentRequest instance
                 */
                CompleteCreditLineEnrollmentRequest.create = function create(properties) {
                    return new CompleteCreditLineEnrollmentRequest(properties);
                };

                /**
                 * Encodes the specified CompleteCreditLineEnrollmentRequest message. Does not implicitly {@link visor.clientrpc.CompleteCreditLineEnrollmentRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentRequest} message CompleteCreditLineEnrollmentRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditLineEnrollmentRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.boostCardProgramToken);
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.creditLineToken);
                    if (message.autoPayEnabled != null && message.hasOwnProperty("autoPayEnabled"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.autoPayEnabled);
                    if (message.cashboostBalanceTransferAmount != null && message.hasOwnProperty("cashboostBalanceTransferAmount"))
                        $root.visor.common.money.Amount.encode(message.cashboostBalanceTransferAmount, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.advanceAmount != null && message.hasOwnProperty("advanceAmount"))
                        $root.visor.common.money.Amount.encode(message.advanceAmount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.gridSubscriptionAmount != null && message.hasOwnProperty("gridSubscriptionAmount"))
                        $root.visor.common.money.Amount.encode(message.gridSubscriptionAmount, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.gridSubscriptionReactivationFeeAmount != null && message.hasOwnProperty("gridSubscriptionReactivationFeeAmount"))
                        $root.visor.common.money.Amount.encode(message.gridSubscriptionReactivationFeeAmount, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCreditLineEnrollmentRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCreditLineEnrollmentRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentRequest} message CompleteCreditLineEnrollmentRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditLineEnrollmentRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCreditLineEnrollmentRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentRequest} CompleteCreditLineEnrollmentRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditLineEnrollmentRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCreditLineEnrollmentRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.boostCardProgramToken = reader.string();
                            break;
                        case 2:
                            message.creditLineToken = reader.string();
                            break;
                        case 3:
                            message.autoPayEnabled = reader.bool();
                            break;
                        case 4:
                            message.cashboostBalanceTransferAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.advanceAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.gridSubscriptionAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.gridSubscriptionReactivationFeeAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCreditLineEnrollmentRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentRequest} CompleteCreditLineEnrollmentRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditLineEnrollmentRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCreditLineEnrollmentRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCreditLineEnrollmentRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        if (!$util.isString(message.boostCardProgramToken))
                            return "boostCardProgramToken: string expected";
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        if (!$util.isString(message.creditLineToken))
                            return "creditLineToken: string expected";
                    if (message.autoPayEnabled != null && message.hasOwnProperty("autoPayEnabled"))
                        if (typeof message.autoPayEnabled !== "boolean")
                            return "autoPayEnabled: boolean expected";
                    if (message.cashboostBalanceTransferAmount != null && message.hasOwnProperty("cashboostBalanceTransferAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.cashboostBalanceTransferAmount);
                        if (error)
                            return "cashboostBalanceTransferAmount." + error;
                    }
                    if (message.advanceAmount != null && message.hasOwnProperty("advanceAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.advanceAmount);
                        if (error)
                            return "advanceAmount." + error;
                    }
                    if (message.gridSubscriptionAmount != null && message.hasOwnProperty("gridSubscriptionAmount")) {
                        properties.subscriptionType = 1;
                        {
                            var error = $root.visor.common.money.Amount.verify(message.gridSubscriptionAmount);
                            if (error)
                                return "gridSubscriptionAmount." + error;
                        }
                    }
                    if (message.gridSubscriptionReactivationFeeAmount != null && message.hasOwnProperty("gridSubscriptionReactivationFeeAmount")) {
                        if (properties.subscriptionType === 1)
                            return "subscriptionType: multiple values";
                        properties.subscriptionType = 1;
                        {
                            var error = $root.visor.common.money.Amount.verify(message.gridSubscriptionReactivationFeeAmount);
                            if (error)
                                return "gridSubscriptionReactivationFeeAmount." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CompleteCreditLineEnrollmentRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentRequest} CompleteCreditLineEnrollmentRequest
                 */
                CompleteCreditLineEnrollmentRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCreditLineEnrollmentRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteCreditLineEnrollmentRequest();
                    if (object.boostCardProgramToken != null)
                        message.boostCardProgramToken = String(object.boostCardProgramToken);
                    if (object.creditLineToken != null)
                        message.creditLineToken = String(object.creditLineToken);
                    if (object.autoPayEnabled != null)
                        message.autoPayEnabled = Boolean(object.autoPayEnabled);
                    if (object.cashboostBalanceTransferAmount != null) {
                        if (typeof object.cashboostBalanceTransferAmount !== "object")
                            throw TypeError(".visor.clientrpc.CompleteCreditLineEnrollmentRequest.cashboostBalanceTransferAmount: object expected");
                        message.cashboostBalanceTransferAmount = $root.visor.common.money.Amount.fromObject(object.cashboostBalanceTransferAmount);
                    }
                    if (object.advanceAmount != null) {
                        if (typeof object.advanceAmount !== "object")
                            throw TypeError(".visor.clientrpc.CompleteCreditLineEnrollmentRequest.advanceAmount: object expected");
                        message.advanceAmount = $root.visor.common.money.Amount.fromObject(object.advanceAmount);
                    }
                    if (object.gridSubscriptionAmount != null) {
                        if (typeof object.gridSubscriptionAmount !== "object")
                            throw TypeError(".visor.clientrpc.CompleteCreditLineEnrollmentRequest.gridSubscriptionAmount: object expected");
                        message.gridSubscriptionAmount = $root.visor.common.money.Amount.fromObject(object.gridSubscriptionAmount);
                    }
                    if (object.gridSubscriptionReactivationFeeAmount != null) {
                        if (typeof object.gridSubscriptionReactivationFeeAmount !== "object")
                            throw TypeError(".visor.clientrpc.CompleteCreditLineEnrollmentRequest.gridSubscriptionReactivationFeeAmount: object expected");
                        message.gridSubscriptionReactivationFeeAmount = $root.visor.common.money.Amount.fromObject(object.gridSubscriptionReactivationFeeAmount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteCreditLineEnrollmentRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @static
                 * @param {visor.clientrpc.CompleteCreditLineEnrollmentRequest} message CompleteCreditLineEnrollmentRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCreditLineEnrollmentRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.boostCardProgramToken = "";
                        object.creditLineToken = "";
                        object.autoPayEnabled = false;
                        object.cashboostBalanceTransferAmount = null;
                        object.advanceAmount = null;
                    }
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        object.boostCardProgramToken = message.boostCardProgramToken;
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        object.creditLineToken = message.creditLineToken;
                    if (message.autoPayEnabled != null && message.hasOwnProperty("autoPayEnabled"))
                        object.autoPayEnabled = message.autoPayEnabled;
                    if (message.cashboostBalanceTransferAmount != null && message.hasOwnProperty("cashboostBalanceTransferAmount"))
                        object.cashboostBalanceTransferAmount = $root.visor.common.money.Amount.toObject(message.cashboostBalanceTransferAmount, options);
                    if (message.advanceAmount != null && message.hasOwnProperty("advanceAmount"))
                        object.advanceAmount = $root.visor.common.money.Amount.toObject(message.advanceAmount, options);
                    if (message.gridSubscriptionAmount != null && message.hasOwnProperty("gridSubscriptionAmount")) {
                        object.gridSubscriptionAmount = $root.visor.common.money.Amount.toObject(message.gridSubscriptionAmount, options);
                        if (options.oneofs)
                            object.subscriptionType = "gridSubscriptionAmount";
                    }
                    if (message.gridSubscriptionReactivationFeeAmount != null && message.hasOwnProperty("gridSubscriptionReactivationFeeAmount")) {
                        object.gridSubscriptionReactivationFeeAmount = $root.visor.common.money.Amount.toObject(message.gridSubscriptionReactivationFeeAmount, options);
                        if (options.oneofs)
                            object.subscriptionType = "gridSubscriptionReactivationFeeAmount";
                    }
                    return object;
                };

                /**
                 * Converts this CompleteCreditLineEnrollmentRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCreditLineEnrollmentRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCreditLineEnrollmentRequest;
            })();

            clientrpc.CompleteCreditLineEnrollmentResponse = (function() {

                /**
                 * Properties of a CompleteCreditLineEnrollmentResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCreditLineEnrollmentResponse
                 */

                /**
                 * Constructs a new CompleteCreditLineEnrollmentResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCreditLineEnrollmentResponse.
                 * @implements ICompleteCreditLineEnrollmentResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentResponse=} [properties] Properties to set
                 */
                function CompleteCreditLineEnrollmentResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteCreditLineEnrollmentResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentResponse} CompleteCreditLineEnrollmentResponse instance
                 */
                CompleteCreditLineEnrollmentResponse.create = function create(properties) {
                    return new CompleteCreditLineEnrollmentResponse(properties);
                };

                /**
                 * Encodes the specified CompleteCreditLineEnrollmentResponse message. Does not implicitly {@link visor.clientrpc.CompleteCreditLineEnrollmentResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentResponse} message CompleteCreditLineEnrollmentResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditLineEnrollmentResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCreditLineEnrollmentResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCreditLineEnrollmentResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditLineEnrollmentResponse} message CompleteCreditLineEnrollmentResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditLineEnrollmentResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCreditLineEnrollmentResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentResponse} CompleteCreditLineEnrollmentResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditLineEnrollmentResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCreditLineEnrollmentResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCreditLineEnrollmentResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentResponse} CompleteCreditLineEnrollmentResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditLineEnrollmentResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCreditLineEnrollmentResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCreditLineEnrollmentResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteCreditLineEnrollmentResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCreditLineEnrollmentResponse} CompleteCreditLineEnrollmentResponse
                 */
                CompleteCreditLineEnrollmentResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCreditLineEnrollmentResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteCreditLineEnrollmentResponse();
                };

                /**
                 * Creates a plain object from a CompleteCreditLineEnrollmentResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @static
                 * @param {visor.clientrpc.CompleteCreditLineEnrollmentResponse} message CompleteCreditLineEnrollmentResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCreditLineEnrollmentResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteCreditLineEnrollmentResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCreditLineEnrollmentResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCreditLineEnrollmentResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCreditLineEnrollmentResponse;
            })();

            clientrpc.GetCreditBoostDashboardRequest = (function() {

                /**
                 * Properties of a GetCreditBoostDashboardRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditBoostDashboardRequest
                 * @property {boolean|null} [testMode] GetCreditBoostDashboardRequest testMode
                 * @property {string|null} [clientToken] GetCreditBoostDashboardRequest clientToken
                 * @property {string|null} [creditBoostToken] GetCreditBoostDashboardRequest creditBoostToken
                 */

                /**
                 * Constructs a new GetCreditBoostDashboardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditBoostDashboardRequest.
                 * @implements IGetCreditBoostDashboardRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditBoostDashboardRequest=} [properties] Properties to set
                 */
                function GetCreditBoostDashboardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditBoostDashboardRequest testMode.
                 * @member {boolean} testMode
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @instance
                 */
                GetCreditBoostDashboardRequest.prototype.testMode = false;

                /**
                 * GetCreditBoostDashboardRequest clientToken.
                 * @member {string} clientToken
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @instance
                 */
                GetCreditBoostDashboardRequest.prototype.clientToken = "";

                /**
                 * GetCreditBoostDashboardRequest creditBoostToken.
                 * @member {string} creditBoostToken
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @instance
                 */
                GetCreditBoostDashboardRequest.prototype.creditBoostToken = "";

                /**
                 * Creates a new GetCreditBoostDashboardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBoostDashboardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditBoostDashboardRequest} GetCreditBoostDashboardRequest instance
                 */
                GetCreditBoostDashboardRequest.create = function create(properties) {
                    return new GetCreditBoostDashboardRequest(properties);
                };

                /**
                 * Encodes the specified GetCreditBoostDashboardRequest message. Does not implicitly {@link visor.clientrpc.GetCreditBoostDashboardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBoostDashboardRequest} message GetCreditBoostDashboardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBoostDashboardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.testMode != null && message.hasOwnProperty("testMode"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.testMode);
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientToken);
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.creditBoostToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditBoostDashboardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditBoostDashboardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBoostDashboardRequest} message GetCreditBoostDashboardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBoostDashboardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditBoostDashboardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditBoostDashboardRequest} GetCreditBoostDashboardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBoostDashboardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditBoostDashboardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.testMode = reader.bool();
                            break;
                        case 2:
                            message.clientToken = reader.string();
                            break;
                        case 3:
                            message.creditBoostToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditBoostDashboardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditBoostDashboardRequest} GetCreditBoostDashboardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBoostDashboardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditBoostDashboardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditBoostDashboardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.testMode != null && message.hasOwnProperty("testMode"))
                        if (typeof message.testMode !== "boolean")
                            return "testMode: boolean expected";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        if (!$util.isString(message.clientToken))
                            return "clientToken: string expected";
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        if (!$util.isString(message.creditBoostToken))
                            return "creditBoostToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetCreditBoostDashboardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditBoostDashboardRequest} GetCreditBoostDashboardRequest
                 */
                GetCreditBoostDashboardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditBoostDashboardRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditBoostDashboardRequest();
                    if (object.testMode != null)
                        message.testMode = Boolean(object.testMode);
                    if (object.clientToken != null)
                        message.clientToken = String(object.clientToken);
                    if (object.creditBoostToken != null)
                        message.creditBoostToken = String(object.creditBoostToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditBoostDashboardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @static
                 * @param {visor.clientrpc.GetCreditBoostDashboardRequest} message GetCreditBoostDashboardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditBoostDashboardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.testMode = false;
                        object.clientToken = "";
                        object.creditBoostToken = "";
                    }
                    if (message.testMode != null && message.hasOwnProperty("testMode"))
                        object.testMode = message.testMode;
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        object.clientToken = message.clientToken;
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        object.creditBoostToken = message.creditBoostToken;
                    return object;
                };

                /**
                 * Converts this GetCreditBoostDashboardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditBoostDashboardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditBoostDashboardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditBoostDashboardRequest;
            })();

            clientrpc.GetCreditBoostDashboardResponse = (function() {

                /**
                 * Properties of a GetCreditBoostDashboardResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditBoostDashboardResponse
                 * @property {Array.<visor.clientrpc.GetCreditBoostDashboardResponse.IScore>|null} [scores] GetCreditBoostDashboardResponse scores
                 */

                /**
                 * Constructs a new GetCreditBoostDashboardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditBoostDashboardResponse.
                 * @implements IGetCreditBoostDashboardResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditBoostDashboardResponse=} [properties] Properties to set
                 */
                function GetCreditBoostDashboardResponse(properties) {
                    this.scores = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditBoostDashboardResponse scores.
                 * @member {Array.<visor.clientrpc.GetCreditBoostDashboardResponse.IScore>} scores
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @instance
                 */
                GetCreditBoostDashboardResponse.prototype.scores = $util.emptyArray;

                /**
                 * Creates a new GetCreditBoostDashboardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBoostDashboardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditBoostDashboardResponse} GetCreditBoostDashboardResponse instance
                 */
                GetCreditBoostDashboardResponse.create = function create(properties) {
                    return new GetCreditBoostDashboardResponse(properties);
                };

                /**
                 * Encodes the specified GetCreditBoostDashboardResponse message. Does not implicitly {@link visor.clientrpc.GetCreditBoostDashboardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBoostDashboardResponse} message GetCreditBoostDashboardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBoostDashboardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scores != null && message.scores.length)
                        for (var i = 0; i < message.scores.length; ++i)
                            $root.visor.clientrpc.GetCreditBoostDashboardResponse.Score.encode(message.scores[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditBoostDashboardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditBoostDashboardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBoostDashboardResponse} message GetCreditBoostDashboardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBoostDashboardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditBoostDashboardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditBoostDashboardResponse} GetCreditBoostDashboardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBoostDashboardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditBoostDashboardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.scores && message.scores.length))
                                message.scores = [];
                            message.scores.push($root.visor.clientrpc.GetCreditBoostDashboardResponse.Score.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditBoostDashboardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditBoostDashboardResponse} GetCreditBoostDashboardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBoostDashboardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditBoostDashboardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditBoostDashboardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scores != null && message.hasOwnProperty("scores")) {
                        if (!Array.isArray(message.scores))
                            return "scores: array expected";
                        for (var i = 0; i < message.scores.length; ++i) {
                            var error = $root.visor.clientrpc.GetCreditBoostDashboardResponse.Score.verify(message.scores[i]);
                            if (error)
                                return "scores." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetCreditBoostDashboardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditBoostDashboardResponse} GetCreditBoostDashboardResponse
                 */
                GetCreditBoostDashboardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditBoostDashboardResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditBoostDashboardResponse();
                    if (object.scores) {
                        if (!Array.isArray(object.scores))
                            throw TypeError(".visor.clientrpc.GetCreditBoostDashboardResponse.scores: array expected");
                        message.scores = [];
                        for (var i = 0; i < object.scores.length; ++i) {
                            if (typeof object.scores[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetCreditBoostDashboardResponse.scores: object expected");
                            message.scores[i] = $root.visor.clientrpc.GetCreditBoostDashboardResponse.Score.fromObject(object.scores[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditBoostDashboardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @static
                 * @param {visor.clientrpc.GetCreditBoostDashboardResponse} message GetCreditBoostDashboardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditBoostDashboardResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.scores = [];
                    if (message.scores && message.scores.length) {
                        object.scores = [];
                        for (var j = 0; j < message.scores.length; ++j)
                            object.scores[j] = $root.visor.clientrpc.GetCreditBoostDashboardResponse.Score.toObject(message.scores[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetCreditBoostDashboardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditBoostDashboardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                GetCreditBoostDashboardResponse.Score = (function() {

                    /**
                     * Properties of a Score.
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                     * @interface IScore
                     * @property {visor.common.time.ILocalDate|null} [date] Score date
                     * @property {number|null} [score] Score score
                     */

                    /**
                     * Constructs a new Score.
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse
                     * @classdesc Represents a Score.
                     * @implements IScore
                     * @constructor
                     * @param {visor.clientrpc.GetCreditBoostDashboardResponse.IScore=} [properties] Properties to set
                     */
                    function Score(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Score date.
                     * @member {visor.common.time.ILocalDate|null|undefined} date
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @instance
                     */
                    Score.prototype.date = null;

                    /**
                     * Score score.
                     * @member {number} score
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @instance
                     */
                    Score.prototype.score = 0;

                    /**
                     * Creates a new Score instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {visor.clientrpc.GetCreditBoostDashboardResponse.IScore=} [properties] Properties to set
                     * @returns {visor.clientrpc.GetCreditBoostDashboardResponse.Score} Score instance
                     */
                    Score.create = function create(properties) {
                        return new Score(properties);
                    };

                    /**
                     * Encodes the specified Score message. Does not implicitly {@link visor.clientrpc.GetCreditBoostDashboardResponse.Score.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {visor.clientrpc.GetCreditBoostDashboardResponse.IScore} message Score message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Score.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.date != null && message.hasOwnProperty("date"))
                            $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.score != null && message.hasOwnProperty("score"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.score);
                        return writer;
                    };

                    /**
                     * Encodes the specified Score message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditBoostDashboardResponse.Score.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {visor.clientrpc.GetCreditBoostDashboardResponse.IScore} message Score message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Score.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Score message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GetCreditBoostDashboardResponse.Score} Score
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Score.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditBoostDashboardResponse.Score();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.score = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Score message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GetCreditBoostDashboardResponse.Score} Score
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Score.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Score message.
                     * @function verify
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Score.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.date != null && message.hasOwnProperty("date")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.date);
                            if (error)
                                return "date." + error;
                        }
                        if (message.score != null && message.hasOwnProperty("score"))
                            if (!$util.isInteger(message.score))
                                return "score: integer expected";
                        return null;
                    };

                    /**
                     * Creates a Score message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GetCreditBoostDashboardResponse.Score} Score
                     */
                    Score.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GetCreditBoostDashboardResponse.Score)
                            return object;
                        var message = new $root.visor.clientrpc.GetCreditBoostDashboardResponse.Score();
                        if (object.date != null) {
                            if (typeof object.date !== "object")
                                throw TypeError(".visor.clientrpc.GetCreditBoostDashboardResponse.Score.date: object expected");
                            message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                        }
                        if (object.score != null)
                            message.score = object.score | 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from a Score message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @static
                     * @param {visor.clientrpc.GetCreditBoostDashboardResponse.Score} message Score
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Score.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.date = null;
                            object.score = 0;
                        }
                        if (message.date != null && message.hasOwnProperty("date"))
                            object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                        if (message.score != null && message.hasOwnProperty("score"))
                            object.score = message.score;
                        return object;
                    };

                    /**
                     * Converts this Score to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GetCreditBoostDashboardResponse.Score
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Score.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Score;
                })();

                return GetCreditBoostDashboardResponse;
            })();

            clientrpc.EnrollCreditBoostRequest = (function() {

                /**
                 * Properties of an EnrollCreditBoostRequest.
                 * @memberof visor.clientrpc
                 * @interface IEnrollCreditBoostRequest
                 * @property {string|null} [clientToken] EnrollCreditBoostRequest clientToken
                 * @property {string|null} [creditBoostToken] EnrollCreditBoostRequest creditBoostToken
                 * @property {string|null} [userToken] EnrollCreditBoostRequest userToken
                 * @property {string|null} [clientKey] EnrollCreditBoostRequest clientKey
                 * @property {string|null} [arrayAppId] EnrollCreditBoostRequest arrayAppId
                 */

                /**
                 * Constructs a new EnrollCreditBoostRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an EnrollCreditBoostRequest.
                 * @implements IEnrollCreditBoostRequest
                 * @constructor
                 * @param {visor.clientrpc.IEnrollCreditBoostRequest=} [properties] Properties to set
                 */
                function EnrollCreditBoostRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnrollCreditBoostRequest clientToken.
                 * @member {string} clientToken
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @instance
                 */
                EnrollCreditBoostRequest.prototype.clientToken = "";

                /**
                 * EnrollCreditBoostRequest creditBoostToken.
                 * @member {string} creditBoostToken
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @instance
                 */
                EnrollCreditBoostRequest.prototype.creditBoostToken = "";

                /**
                 * EnrollCreditBoostRequest userToken.
                 * @member {string} userToken
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @instance
                 */
                EnrollCreditBoostRequest.prototype.userToken = "";

                /**
                 * EnrollCreditBoostRequest clientKey.
                 * @member {string} clientKey
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @instance
                 */
                EnrollCreditBoostRequest.prototype.clientKey = "";

                /**
                 * EnrollCreditBoostRequest arrayAppId.
                 * @member {string} arrayAppId
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @instance
                 */
                EnrollCreditBoostRequest.prototype.arrayAppId = "";

                /**
                 * Creates a new EnrollCreditBoostRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBoostRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.EnrollCreditBoostRequest} EnrollCreditBoostRequest instance
                 */
                EnrollCreditBoostRequest.create = function create(properties) {
                    return new EnrollCreditBoostRequest(properties);
                };

                /**
                 * Encodes the specified EnrollCreditBoostRequest message. Does not implicitly {@link visor.clientrpc.EnrollCreditBoostRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBoostRequest} message EnrollCreditBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBoostRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientToken);
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.creditBoostToken);
                    if (message.userToken != null && message.hasOwnProperty("userToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.userToken);
                    if (message.clientKey != null && message.hasOwnProperty("clientKey"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.clientKey);
                    if (message.arrayAppId != null && message.hasOwnProperty("arrayAppId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.arrayAppId);
                    return writer;
                };

                /**
                 * Encodes the specified EnrollCreditBoostRequest message, length delimited. Does not implicitly {@link visor.clientrpc.EnrollCreditBoostRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBoostRequest} message EnrollCreditBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBoostRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnrollCreditBoostRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.EnrollCreditBoostRequest} EnrollCreditBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBoostRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.EnrollCreditBoostRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.clientToken = reader.string();
                            break;
                        case 2:
                            message.creditBoostToken = reader.string();
                            break;
                        case 3:
                            message.userToken = reader.string();
                            break;
                        case 4:
                            message.clientKey = reader.string();
                            break;
                        case 5:
                            message.arrayAppId = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnrollCreditBoostRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.EnrollCreditBoostRequest} EnrollCreditBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBoostRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnrollCreditBoostRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnrollCreditBoostRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        if (!$util.isString(message.clientToken))
                            return "clientToken: string expected";
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        if (!$util.isString(message.creditBoostToken))
                            return "creditBoostToken: string expected";
                    if (message.userToken != null && message.hasOwnProperty("userToken"))
                        if (!$util.isString(message.userToken))
                            return "userToken: string expected";
                    if (message.clientKey != null && message.hasOwnProperty("clientKey"))
                        if (!$util.isString(message.clientKey))
                            return "clientKey: string expected";
                    if (message.arrayAppId != null && message.hasOwnProperty("arrayAppId"))
                        if (!$util.isString(message.arrayAppId))
                            return "arrayAppId: string expected";
                    return null;
                };

                /**
                 * Creates an EnrollCreditBoostRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.EnrollCreditBoostRequest} EnrollCreditBoostRequest
                 */
                EnrollCreditBoostRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.EnrollCreditBoostRequest)
                        return object;
                    var message = new $root.visor.clientrpc.EnrollCreditBoostRequest();
                    if (object.clientToken != null)
                        message.clientToken = String(object.clientToken);
                    if (object.creditBoostToken != null)
                        message.creditBoostToken = String(object.creditBoostToken);
                    if (object.userToken != null)
                        message.userToken = String(object.userToken);
                    if (object.clientKey != null)
                        message.clientKey = String(object.clientKey);
                    if (object.arrayAppId != null)
                        message.arrayAppId = String(object.arrayAppId);
                    return message;
                };

                /**
                 * Creates a plain object from an EnrollCreditBoostRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.EnrollCreditBoostRequest} message EnrollCreditBoostRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnrollCreditBoostRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.clientToken = "";
                        object.creditBoostToken = "";
                        object.userToken = "";
                        object.clientKey = "";
                        object.arrayAppId = "";
                    }
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        object.clientToken = message.clientToken;
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        object.creditBoostToken = message.creditBoostToken;
                    if (message.userToken != null && message.hasOwnProperty("userToken"))
                        object.userToken = message.userToken;
                    if (message.clientKey != null && message.hasOwnProperty("clientKey"))
                        object.clientKey = message.clientKey;
                    if (message.arrayAppId != null && message.hasOwnProperty("arrayAppId"))
                        object.arrayAppId = message.arrayAppId;
                    return object;
                };

                /**
                 * Converts this EnrollCreditBoostRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.EnrollCreditBoostRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnrollCreditBoostRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnrollCreditBoostRequest;
            })();

            clientrpc.EnrollCreditBoostResponse = (function() {

                /**
                 * Properties of an EnrollCreditBoostResponse.
                 * @memberof visor.clientrpc
                 * @interface IEnrollCreditBoostResponse
                 */

                /**
                 * Constructs a new EnrollCreditBoostResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an EnrollCreditBoostResponse.
                 * @implements IEnrollCreditBoostResponse
                 * @constructor
                 * @param {visor.clientrpc.IEnrollCreditBoostResponse=} [properties] Properties to set
                 */
                function EnrollCreditBoostResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new EnrollCreditBoostResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBoostResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.EnrollCreditBoostResponse} EnrollCreditBoostResponse instance
                 */
                EnrollCreditBoostResponse.create = function create(properties) {
                    return new EnrollCreditBoostResponse(properties);
                };

                /**
                 * Encodes the specified EnrollCreditBoostResponse message. Does not implicitly {@link visor.clientrpc.EnrollCreditBoostResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBoostResponse} message EnrollCreditBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBoostResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified EnrollCreditBoostResponse message, length delimited. Does not implicitly {@link visor.clientrpc.EnrollCreditBoostResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollCreditBoostResponse} message EnrollCreditBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollCreditBoostResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnrollCreditBoostResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.EnrollCreditBoostResponse} EnrollCreditBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBoostResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.EnrollCreditBoostResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnrollCreditBoostResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.EnrollCreditBoostResponse} EnrollCreditBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollCreditBoostResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnrollCreditBoostResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnrollCreditBoostResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an EnrollCreditBoostResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.EnrollCreditBoostResponse} EnrollCreditBoostResponse
                 */
                EnrollCreditBoostResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.EnrollCreditBoostResponse)
                        return object;
                    return new $root.visor.clientrpc.EnrollCreditBoostResponse();
                };

                /**
                 * Creates a plain object from an EnrollCreditBoostResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.EnrollCreditBoostResponse} message EnrollCreditBoostResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnrollCreditBoostResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this EnrollCreditBoostResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.EnrollCreditBoostResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnrollCreditBoostResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnrollCreditBoostResponse;
            })();

            clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest = (function() {

                /**
                 * Properties of a GetHarmonicsAutoInsuranceEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetHarmonicsAutoInsuranceEligibilityRequest
                 */

                /**
                 * Constructs a new GetHarmonicsAutoInsuranceEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetHarmonicsAutoInsuranceEligibilityRequest.
                 * @implements IGetHarmonicsAutoInsuranceEligibilityRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityRequest=} [properties] Properties to set
                 */
                function GetHarmonicsAutoInsuranceEligibilityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetHarmonicsAutoInsuranceEligibilityRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest} GetHarmonicsAutoInsuranceEligibilityRequest instance
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.create = function create(properties) {
                    return new GetHarmonicsAutoInsuranceEligibilityRequest(properties);
                };

                /**
                 * Encodes the specified GetHarmonicsAutoInsuranceEligibilityRequest message. Does not implicitly {@link visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityRequest} message GetHarmonicsAutoInsuranceEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetHarmonicsAutoInsuranceEligibilityRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityRequest} message GetHarmonicsAutoInsuranceEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetHarmonicsAutoInsuranceEligibilityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest} GetHarmonicsAutoInsuranceEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetHarmonicsAutoInsuranceEligibilityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest} GetHarmonicsAutoInsuranceEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetHarmonicsAutoInsuranceEligibilityRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetHarmonicsAutoInsuranceEligibilityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest} GetHarmonicsAutoInsuranceEligibilityRequest
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest)
                        return object;
                    return new $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest();
                };

                /**
                 * Creates a plain object from a GetHarmonicsAutoInsuranceEligibilityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest} message GetHarmonicsAutoInsuranceEligibilityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetHarmonicsAutoInsuranceEligibilityRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetHarmonicsAutoInsuranceEligibilityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetHarmonicsAutoInsuranceEligibilityRequest;
            })();

            clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse = (function() {

                /**
                 * Properties of a GetHarmonicsAutoInsuranceEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetHarmonicsAutoInsuranceEligibilityResponse
                 * @property {boolean|null} [isEligible] GetHarmonicsAutoInsuranceEligibilityResponse isEligible
                 */

                /**
                 * Constructs a new GetHarmonicsAutoInsuranceEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetHarmonicsAutoInsuranceEligibilityResponse.
                 * @implements IGetHarmonicsAutoInsuranceEligibilityResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityResponse=} [properties] Properties to set
                 */
                function GetHarmonicsAutoInsuranceEligibilityResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetHarmonicsAutoInsuranceEligibilityResponse isEligible.
                 * @member {boolean} isEligible
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @instance
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.prototype.isEligible = false;

                /**
                 * Creates a new GetHarmonicsAutoInsuranceEligibilityResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse} GetHarmonicsAutoInsuranceEligibilityResponse instance
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.create = function create(properties) {
                    return new GetHarmonicsAutoInsuranceEligibilityResponse(properties);
                };

                /**
                 * Encodes the specified GetHarmonicsAutoInsuranceEligibilityResponse message. Does not implicitly {@link visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityResponse} message GetHarmonicsAutoInsuranceEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isEligible);
                    return writer;
                };

                /**
                 * Encodes the specified GetHarmonicsAutoInsuranceEligibilityResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetHarmonicsAutoInsuranceEligibilityResponse} message GetHarmonicsAutoInsuranceEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetHarmonicsAutoInsuranceEligibilityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse} GetHarmonicsAutoInsuranceEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isEligible = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetHarmonicsAutoInsuranceEligibilityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse} GetHarmonicsAutoInsuranceEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetHarmonicsAutoInsuranceEligibilityResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        if (typeof message.isEligible !== "boolean")
                            return "isEligible: boolean expected";
                    return null;
                };

                /**
                 * Creates a GetHarmonicsAutoInsuranceEligibilityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse} GetHarmonicsAutoInsuranceEligibilityResponse
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse();
                    if (object.isEligible != null)
                        message.isEligible = Boolean(object.isEligible);
                    return message;
                };

                /**
                 * Creates a plain object from a GetHarmonicsAutoInsuranceEligibilityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse} message GetHarmonicsAutoInsuranceEligibilityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.isEligible = false;
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        object.isEligible = message.isEligible;
                    return object;
                };

                /**
                 * Converts this GetHarmonicsAutoInsuranceEligibilityResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetHarmonicsAutoInsuranceEligibilityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetHarmonicsAutoInsuranceEligibilityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetHarmonicsAutoInsuranceEligibilityResponse;
            })();

            clientrpc.SyncGridCardProductRequest = (function() {

                /**
                 * Properties of a SyncGridCardProductRequest.
                 * @memberof visor.clientrpc
                 * @interface ISyncGridCardProductRequest
                 * @property {number|Long|null} [transactionLimit] SyncGridCardProductRequest transactionLimit
                 */

                /**
                 * Constructs a new SyncGridCardProductRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncGridCardProductRequest.
                 * @implements ISyncGridCardProductRequest
                 * @constructor
                 * @param {visor.clientrpc.ISyncGridCardProductRequest=} [properties] Properties to set
                 */
                function SyncGridCardProductRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SyncGridCardProductRequest transactionLimit.
                 * @member {number|Long} transactionLimit
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @instance
                 */
                SyncGridCardProductRequest.prototype.transactionLimit = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new SyncGridCardProductRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {visor.clientrpc.ISyncGridCardProductRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncGridCardProductRequest} SyncGridCardProductRequest instance
                 */
                SyncGridCardProductRequest.create = function create(properties) {
                    return new SyncGridCardProductRequest(properties);
                };

                /**
                 * Encodes the specified SyncGridCardProductRequest message. Does not implicitly {@link visor.clientrpc.SyncGridCardProductRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {visor.clientrpc.ISyncGridCardProductRequest} message SyncGridCardProductRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncGridCardProductRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transactionLimit != null && message.hasOwnProperty("transactionLimit"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.transactionLimit);
                    return writer;
                };

                /**
                 * Encodes the specified SyncGridCardProductRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SyncGridCardProductRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {visor.clientrpc.ISyncGridCardProductRequest} message SyncGridCardProductRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncGridCardProductRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncGridCardProductRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncGridCardProductRequest} SyncGridCardProductRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncGridCardProductRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncGridCardProductRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.transactionLimit = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncGridCardProductRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncGridCardProductRequest} SyncGridCardProductRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncGridCardProductRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncGridCardProductRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncGridCardProductRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionLimit != null && message.hasOwnProperty("transactionLimit"))
                        if (!$util.isInteger(message.transactionLimit) && !(message.transactionLimit && $util.isInteger(message.transactionLimit.low) && $util.isInteger(message.transactionLimit.high)))
                            return "transactionLimit: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a SyncGridCardProductRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncGridCardProductRequest} SyncGridCardProductRequest
                 */
                SyncGridCardProductRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncGridCardProductRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SyncGridCardProductRequest();
                    if (object.transactionLimit != null)
                        if ($util.Long)
                            (message.transactionLimit = $util.Long.fromValue(object.transactionLimit)).unsigned = true;
                        else if (typeof object.transactionLimit === "string")
                            message.transactionLimit = parseInt(object.transactionLimit, 10);
                        else if (typeof object.transactionLimit === "number")
                            message.transactionLimit = object.transactionLimit;
                        else if (typeof object.transactionLimit === "object")
                            message.transactionLimit = new $util.LongBits(object.transactionLimit.low >>> 0, object.transactionLimit.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a SyncGridCardProductRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @static
                 * @param {visor.clientrpc.SyncGridCardProductRequest} message SyncGridCardProductRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncGridCardProductRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.transactionLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.transactionLimit = options.longs === String ? "0" : 0;
                    if (message.transactionLimit != null && message.hasOwnProperty("transactionLimit"))
                        if (typeof message.transactionLimit === "number")
                            object.transactionLimit = options.longs === String ? String(message.transactionLimit) : message.transactionLimit;
                        else
                            object.transactionLimit = options.longs === String ? $util.Long.prototype.toString.call(message.transactionLimit) : options.longs === Number ? new $util.LongBits(message.transactionLimit.low >>> 0, message.transactionLimit.high >>> 0).toNumber(true) : message.transactionLimit;
                    return object;
                };

                /**
                 * Converts this SyncGridCardProductRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncGridCardProductRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncGridCardProductRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncGridCardProductRequest;
            })();

            clientrpc.SyncGridCardProductResponse = (function() {

                /**
                 * Properties of a SyncGridCardProductResponse.
                 * @memberof visor.clientrpc
                 * @interface ISyncGridCardProductResponse
                 */

                /**
                 * Constructs a new SyncGridCardProductResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncGridCardProductResponse.
                 * @implements ISyncGridCardProductResponse
                 * @constructor
                 * @param {visor.clientrpc.ISyncGridCardProductResponse=} [properties] Properties to set
                 */
                function SyncGridCardProductResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SyncGridCardProductResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {visor.clientrpc.ISyncGridCardProductResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncGridCardProductResponse} SyncGridCardProductResponse instance
                 */
                SyncGridCardProductResponse.create = function create(properties) {
                    return new SyncGridCardProductResponse(properties);
                };

                /**
                 * Encodes the specified SyncGridCardProductResponse message. Does not implicitly {@link visor.clientrpc.SyncGridCardProductResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {visor.clientrpc.ISyncGridCardProductResponse} message SyncGridCardProductResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncGridCardProductResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SyncGridCardProductResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SyncGridCardProductResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {visor.clientrpc.ISyncGridCardProductResponse} message SyncGridCardProductResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncGridCardProductResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncGridCardProductResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncGridCardProductResponse} SyncGridCardProductResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncGridCardProductResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncGridCardProductResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncGridCardProductResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncGridCardProductResponse} SyncGridCardProductResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncGridCardProductResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncGridCardProductResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncGridCardProductResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SyncGridCardProductResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncGridCardProductResponse} SyncGridCardProductResponse
                 */
                SyncGridCardProductResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncGridCardProductResponse)
                        return object;
                    return new $root.visor.clientrpc.SyncGridCardProductResponse();
                };

                /**
                 * Creates a plain object from a SyncGridCardProductResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @static
                 * @param {visor.clientrpc.SyncGridCardProductResponse} message SyncGridCardProductResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncGridCardProductResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SyncGridCardProductResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncGridCardProductResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncGridCardProductResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncGridCardProductResponse;
            })();

            clientrpc.SyncCreditBoostRequest = (function() {

                /**
                 * Properties of a SyncCreditBoostRequest.
                 * @memberof visor.clientrpc
                 * @interface ISyncCreditBoostRequest
                 */

                /**
                 * Constructs a new SyncCreditBoostRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncCreditBoostRequest.
                 * @implements ISyncCreditBoostRequest
                 * @constructor
                 * @param {visor.clientrpc.ISyncCreditBoostRequest=} [properties] Properties to set
                 */
                function SyncCreditBoostRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SyncCreditBoostRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.ISyncCreditBoostRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncCreditBoostRequest} SyncCreditBoostRequest instance
                 */
                SyncCreditBoostRequest.create = function create(properties) {
                    return new SyncCreditBoostRequest(properties);
                };

                /**
                 * Encodes the specified SyncCreditBoostRequest message. Does not implicitly {@link visor.clientrpc.SyncCreditBoostRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.ISyncCreditBoostRequest} message SyncCreditBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCreditBoostRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SyncCreditBoostRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SyncCreditBoostRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.ISyncCreditBoostRequest} message SyncCreditBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCreditBoostRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncCreditBoostRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncCreditBoostRequest} SyncCreditBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCreditBoostRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncCreditBoostRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncCreditBoostRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncCreditBoostRequest} SyncCreditBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCreditBoostRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncCreditBoostRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncCreditBoostRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SyncCreditBoostRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncCreditBoostRequest} SyncCreditBoostRequest
                 */
                SyncCreditBoostRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncCreditBoostRequest)
                        return object;
                    return new $root.visor.clientrpc.SyncCreditBoostRequest();
                };

                /**
                 * Creates a plain object from a SyncCreditBoostRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.SyncCreditBoostRequest} message SyncCreditBoostRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncCreditBoostRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SyncCreditBoostRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncCreditBoostRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncCreditBoostRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncCreditBoostRequest;
            })();

            clientrpc.SyncCreditBoostResponse = (function() {

                /**
                 * Properties of a SyncCreditBoostResponse.
                 * @memberof visor.clientrpc
                 * @interface ISyncCreditBoostResponse
                 */

                /**
                 * Constructs a new SyncCreditBoostResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncCreditBoostResponse.
                 * @implements ISyncCreditBoostResponse
                 * @constructor
                 * @param {visor.clientrpc.ISyncCreditBoostResponse=} [properties] Properties to set
                 */
                function SyncCreditBoostResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SyncCreditBoostResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.ISyncCreditBoostResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncCreditBoostResponse} SyncCreditBoostResponse instance
                 */
                SyncCreditBoostResponse.create = function create(properties) {
                    return new SyncCreditBoostResponse(properties);
                };

                /**
                 * Encodes the specified SyncCreditBoostResponse message. Does not implicitly {@link visor.clientrpc.SyncCreditBoostResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.ISyncCreditBoostResponse} message SyncCreditBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCreditBoostResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SyncCreditBoostResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SyncCreditBoostResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.ISyncCreditBoostResponse} message SyncCreditBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCreditBoostResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncCreditBoostResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncCreditBoostResponse} SyncCreditBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCreditBoostResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncCreditBoostResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncCreditBoostResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncCreditBoostResponse} SyncCreditBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCreditBoostResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncCreditBoostResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncCreditBoostResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SyncCreditBoostResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncCreditBoostResponse} SyncCreditBoostResponse
                 */
                SyncCreditBoostResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncCreditBoostResponse)
                        return object;
                    return new $root.visor.clientrpc.SyncCreditBoostResponse();
                };

                /**
                 * Creates a plain object from a SyncCreditBoostResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.SyncCreditBoostResponse} message SyncCreditBoostResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncCreditBoostResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SyncCreditBoostResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncCreditBoostResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncCreditBoostResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncCreditBoostResponse;
            })();

            clientrpc.SyncBankAccountsRequest = (function() {

                /**
                 * Properties of a SyncBankAccountsRequest.
                 * @memberof visor.clientrpc
                 * @interface ISyncBankAccountsRequest
                 */

                /**
                 * Constructs a new SyncBankAccountsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncBankAccountsRequest.
                 * @implements ISyncBankAccountsRequest
                 * @constructor
                 * @param {visor.clientrpc.ISyncBankAccountsRequest=} [properties] Properties to set
                 */
                function SyncBankAccountsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SyncBankAccountsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {visor.clientrpc.ISyncBankAccountsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncBankAccountsRequest} SyncBankAccountsRequest instance
                 */
                SyncBankAccountsRequest.create = function create(properties) {
                    return new SyncBankAccountsRequest(properties);
                };

                /**
                 * Encodes the specified SyncBankAccountsRequest message. Does not implicitly {@link visor.clientrpc.SyncBankAccountsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {visor.clientrpc.ISyncBankAccountsRequest} message SyncBankAccountsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncBankAccountsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SyncBankAccountsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SyncBankAccountsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {visor.clientrpc.ISyncBankAccountsRequest} message SyncBankAccountsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncBankAccountsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncBankAccountsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncBankAccountsRequest} SyncBankAccountsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncBankAccountsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncBankAccountsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncBankAccountsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncBankAccountsRequest} SyncBankAccountsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncBankAccountsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncBankAccountsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncBankAccountsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SyncBankAccountsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncBankAccountsRequest} SyncBankAccountsRequest
                 */
                SyncBankAccountsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncBankAccountsRequest)
                        return object;
                    return new $root.visor.clientrpc.SyncBankAccountsRequest();
                };

                /**
                 * Creates a plain object from a SyncBankAccountsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @static
                 * @param {visor.clientrpc.SyncBankAccountsRequest} message SyncBankAccountsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncBankAccountsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SyncBankAccountsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncBankAccountsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncBankAccountsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncBankAccountsRequest;
            })();

            clientrpc.SyncBankAccountsResponse = (function() {

                /**
                 * Properties of a SyncBankAccountsResponse.
                 * @memberof visor.clientrpc
                 * @interface ISyncBankAccountsResponse
                 */

                /**
                 * Constructs a new SyncBankAccountsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncBankAccountsResponse.
                 * @implements ISyncBankAccountsResponse
                 * @constructor
                 * @param {visor.clientrpc.ISyncBankAccountsResponse=} [properties] Properties to set
                 */
                function SyncBankAccountsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SyncBankAccountsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {visor.clientrpc.ISyncBankAccountsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncBankAccountsResponse} SyncBankAccountsResponse instance
                 */
                SyncBankAccountsResponse.create = function create(properties) {
                    return new SyncBankAccountsResponse(properties);
                };

                /**
                 * Encodes the specified SyncBankAccountsResponse message. Does not implicitly {@link visor.clientrpc.SyncBankAccountsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {visor.clientrpc.ISyncBankAccountsResponse} message SyncBankAccountsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncBankAccountsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SyncBankAccountsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SyncBankAccountsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {visor.clientrpc.ISyncBankAccountsResponse} message SyncBankAccountsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncBankAccountsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncBankAccountsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncBankAccountsResponse} SyncBankAccountsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncBankAccountsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncBankAccountsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncBankAccountsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncBankAccountsResponse} SyncBankAccountsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncBankAccountsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncBankAccountsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncBankAccountsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SyncBankAccountsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncBankAccountsResponse} SyncBankAccountsResponse
                 */
                SyncBankAccountsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncBankAccountsResponse)
                        return object;
                    return new $root.visor.clientrpc.SyncBankAccountsResponse();
                };

                /**
                 * Creates a plain object from a SyncBankAccountsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @static
                 * @param {visor.clientrpc.SyncBankAccountsResponse} message SyncBankAccountsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncBankAccountsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SyncBankAccountsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncBankAccountsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncBankAccountsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncBankAccountsResponse;
            })();

            clientrpc.SyncCardsRequest = (function() {

                /**
                 * Properties of a SyncCardsRequest.
                 * @memberof visor.clientrpc
                 * @interface ISyncCardsRequest
                 */

                /**
                 * Constructs a new SyncCardsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncCardsRequest.
                 * @implements ISyncCardsRequest
                 * @constructor
                 * @param {visor.clientrpc.ISyncCardsRequest=} [properties] Properties to set
                 */
                function SyncCardsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SyncCardsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {visor.clientrpc.ISyncCardsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncCardsRequest} SyncCardsRequest instance
                 */
                SyncCardsRequest.create = function create(properties) {
                    return new SyncCardsRequest(properties);
                };

                /**
                 * Encodes the specified SyncCardsRequest message. Does not implicitly {@link visor.clientrpc.SyncCardsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {visor.clientrpc.ISyncCardsRequest} message SyncCardsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCardsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SyncCardsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SyncCardsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {visor.clientrpc.ISyncCardsRequest} message SyncCardsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCardsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncCardsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncCardsRequest} SyncCardsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCardsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncCardsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncCardsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncCardsRequest} SyncCardsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCardsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncCardsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncCardsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SyncCardsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncCardsRequest} SyncCardsRequest
                 */
                SyncCardsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncCardsRequest)
                        return object;
                    return new $root.visor.clientrpc.SyncCardsRequest();
                };

                /**
                 * Creates a plain object from a SyncCardsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @static
                 * @param {visor.clientrpc.SyncCardsRequest} message SyncCardsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncCardsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SyncCardsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncCardsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncCardsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncCardsRequest;
            })();

            clientrpc.SyncCardsResponse = (function() {

                /**
                 * Properties of a SyncCardsResponse.
                 * @memberof visor.clientrpc
                 * @interface ISyncCardsResponse
                 */

                /**
                 * Constructs a new SyncCardsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SyncCardsResponse.
                 * @implements ISyncCardsResponse
                 * @constructor
                 * @param {visor.clientrpc.ISyncCardsResponse=} [properties] Properties to set
                 */
                function SyncCardsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SyncCardsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {visor.clientrpc.ISyncCardsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SyncCardsResponse} SyncCardsResponse instance
                 */
                SyncCardsResponse.create = function create(properties) {
                    return new SyncCardsResponse(properties);
                };

                /**
                 * Encodes the specified SyncCardsResponse message. Does not implicitly {@link visor.clientrpc.SyncCardsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {visor.clientrpc.ISyncCardsResponse} message SyncCardsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCardsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SyncCardsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SyncCardsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {visor.clientrpc.ISyncCardsResponse} message SyncCardsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SyncCardsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SyncCardsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SyncCardsResponse} SyncCardsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCardsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SyncCardsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SyncCardsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SyncCardsResponse} SyncCardsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SyncCardsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SyncCardsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SyncCardsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SyncCardsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SyncCardsResponse} SyncCardsResponse
                 */
                SyncCardsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SyncCardsResponse)
                        return object;
                    return new $root.visor.clientrpc.SyncCardsResponse();
                };

                /**
                 * Creates a plain object from a SyncCardsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @static
                 * @param {visor.clientrpc.SyncCardsResponse} message SyncCardsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SyncCardsResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SyncCardsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SyncCardsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SyncCardsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SyncCardsResponse;
            })();

            clientrpc.SetupCreditBoostRequest = (function() {

                /**
                 * Properties of a SetupCreditBoostRequest.
                 * @memberof visor.clientrpc
                 * @interface ISetupCreditBoostRequest
                 * @property {string|null} [clientToken] SetupCreditBoostRequest clientToken
                 * @property {string|null} [creditBoostToken] SetupCreditBoostRequest creditBoostToken
                 */

                /**
                 * Constructs a new SetupCreditBoostRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SetupCreditBoostRequest.
                 * @implements ISetupCreditBoostRequest
                 * @constructor
                 * @param {visor.clientrpc.ISetupCreditBoostRequest=} [properties] Properties to set
                 */
                function SetupCreditBoostRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SetupCreditBoostRequest clientToken.
                 * @member {string} clientToken
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @instance
                 */
                SetupCreditBoostRequest.prototype.clientToken = "";

                /**
                 * SetupCreditBoostRequest creditBoostToken.
                 * @member {string} creditBoostToken
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @instance
                 */
                SetupCreditBoostRequest.prototype.creditBoostToken = "";

                /**
                 * Creates a new SetupCreditBoostRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.ISetupCreditBoostRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SetupCreditBoostRequest} SetupCreditBoostRequest instance
                 */
                SetupCreditBoostRequest.create = function create(properties) {
                    return new SetupCreditBoostRequest(properties);
                };

                /**
                 * Encodes the specified SetupCreditBoostRequest message. Does not implicitly {@link visor.clientrpc.SetupCreditBoostRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.ISetupCreditBoostRequest} message SetupCreditBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetupCreditBoostRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientToken);
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.creditBoostToken);
                    return writer;
                };

                /**
                 * Encodes the specified SetupCreditBoostRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SetupCreditBoostRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.ISetupCreditBoostRequest} message SetupCreditBoostRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetupCreditBoostRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SetupCreditBoostRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SetupCreditBoostRequest} SetupCreditBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetupCreditBoostRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SetupCreditBoostRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.clientToken = reader.string();
                            break;
                        case 2:
                            message.creditBoostToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SetupCreditBoostRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SetupCreditBoostRequest} SetupCreditBoostRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetupCreditBoostRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SetupCreditBoostRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetupCreditBoostRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        if (!$util.isString(message.clientToken))
                            return "clientToken: string expected";
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        if (!$util.isString(message.creditBoostToken))
                            return "creditBoostToken: string expected";
                    return null;
                };

                /**
                 * Creates a SetupCreditBoostRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SetupCreditBoostRequest} SetupCreditBoostRequest
                 */
                SetupCreditBoostRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SetupCreditBoostRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SetupCreditBoostRequest();
                    if (object.clientToken != null)
                        message.clientToken = String(object.clientToken);
                    if (object.creditBoostToken != null)
                        message.creditBoostToken = String(object.creditBoostToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SetupCreditBoostRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @static
                 * @param {visor.clientrpc.SetupCreditBoostRequest} message SetupCreditBoostRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetupCreditBoostRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.clientToken = "";
                        object.creditBoostToken = "";
                    }
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        object.clientToken = message.clientToken;
                    if (message.creditBoostToken != null && message.hasOwnProperty("creditBoostToken"))
                        object.creditBoostToken = message.creditBoostToken;
                    return object;
                };

                /**
                 * Converts this SetupCreditBoostRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SetupCreditBoostRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetupCreditBoostRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetupCreditBoostRequest;
            })();

            clientrpc.SetupCreditBoostResponse = (function() {

                /**
                 * Properties of a SetupCreditBoostResponse.
                 * @memberof visor.clientrpc
                 * @interface ISetupCreditBoostResponse
                 * @property {string|null} [ssn] SetupCreditBoostResponse ssn
                 * @property {visor.common.time.ILocalDate|null} [date] SetupCreditBoostResponse date
                 * @property {visor.clientrpc.IAddress|null} [address] SetupCreditBoostResponse address
                 */

                /**
                 * Constructs a new SetupCreditBoostResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SetupCreditBoostResponse.
                 * @implements ISetupCreditBoostResponse
                 * @constructor
                 * @param {visor.clientrpc.ISetupCreditBoostResponse=} [properties] Properties to set
                 */
                function SetupCreditBoostResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SetupCreditBoostResponse ssn.
                 * @member {string} ssn
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @instance
                 */
                SetupCreditBoostResponse.prototype.ssn = "";

                /**
                 * SetupCreditBoostResponse date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @instance
                 */
                SetupCreditBoostResponse.prototype.date = null;

                /**
                 * SetupCreditBoostResponse address.
                 * @member {visor.clientrpc.IAddress|null|undefined} address
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @instance
                 */
                SetupCreditBoostResponse.prototype.address = null;

                /**
                 * Creates a new SetupCreditBoostResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.ISetupCreditBoostResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SetupCreditBoostResponse} SetupCreditBoostResponse instance
                 */
                SetupCreditBoostResponse.create = function create(properties) {
                    return new SetupCreditBoostResponse(properties);
                };

                /**
                 * Encodes the specified SetupCreditBoostResponse message. Does not implicitly {@link visor.clientrpc.SetupCreditBoostResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.ISetupCreditBoostResponse} message SetupCreditBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetupCreditBoostResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ssn != null && message.hasOwnProperty("ssn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.ssn);
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.address != null && message.hasOwnProperty("address"))
                        $root.visor.clientrpc.Address.encode(message.address, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SetupCreditBoostResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SetupCreditBoostResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.ISetupCreditBoostResponse} message SetupCreditBoostResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetupCreditBoostResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SetupCreditBoostResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SetupCreditBoostResponse} SetupCreditBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetupCreditBoostResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SetupCreditBoostResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ssn = reader.string();
                            break;
                        case 2:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.address = $root.visor.clientrpc.Address.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SetupCreditBoostResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SetupCreditBoostResponse} SetupCreditBoostResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetupCreditBoostResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SetupCreditBoostResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetupCreditBoostResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ssn != null && message.hasOwnProperty("ssn"))
                        if (!$util.isString(message.ssn))
                            return "ssn: string expected";
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        var error = $root.visor.clientrpc.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SetupCreditBoostResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SetupCreditBoostResponse} SetupCreditBoostResponse
                 */
                SetupCreditBoostResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SetupCreditBoostResponse)
                        return object;
                    var message = new $root.visor.clientrpc.SetupCreditBoostResponse();
                    if (object.ssn != null)
                        message.ssn = String(object.ssn);
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.SetupCreditBoostResponse.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".visor.clientrpc.SetupCreditBoostResponse.address: object expected");
                        message.address = $root.visor.clientrpc.Address.fromObject(object.address);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SetupCreditBoostResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @static
                 * @param {visor.clientrpc.SetupCreditBoostResponse} message SetupCreditBoostResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetupCreditBoostResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.ssn = "";
                        object.date = null;
                        object.address = null;
                    }
                    if (message.ssn != null && message.hasOwnProperty("ssn"))
                        object.ssn = message.ssn;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.visor.clientrpc.Address.toObject(message.address, options);
                    return object;
                };

                /**
                 * Converts this SetupCreditBoostResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SetupCreditBoostResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetupCreditBoostResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetupCreditBoostResponse;
            })();

            clientrpc.EnrollPayBoostAutoDeliveryRequest = (function() {

                /**
                 * Properties of an EnrollPayBoostAutoDeliveryRequest.
                 * @memberof visor.clientrpc
                 * @interface IEnrollPayBoostAutoDeliveryRequest
                 * @property {string|null} [clientToken] EnrollPayBoostAutoDeliveryRequest clientToken
                 */

                /**
                 * Constructs a new EnrollPayBoostAutoDeliveryRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an EnrollPayBoostAutoDeliveryRequest.
                 * @implements IEnrollPayBoostAutoDeliveryRequest
                 * @constructor
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryRequest=} [properties] Properties to set
                 */
                function EnrollPayBoostAutoDeliveryRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnrollPayBoostAutoDeliveryRequest clientToken.
                 * @member {string} clientToken
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @instance
                 */
                EnrollPayBoostAutoDeliveryRequest.prototype.clientToken = "";

                /**
                 * Creates a new EnrollPayBoostAutoDeliveryRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryRequest} EnrollPayBoostAutoDeliveryRequest instance
                 */
                EnrollPayBoostAutoDeliveryRequest.create = function create(properties) {
                    return new EnrollPayBoostAutoDeliveryRequest(properties);
                };

                /**
                 * Encodes the specified EnrollPayBoostAutoDeliveryRequest message. Does not implicitly {@link visor.clientrpc.EnrollPayBoostAutoDeliveryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryRequest} message EnrollPayBoostAutoDeliveryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollPayBoostAutoDeliveryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientToken);
                    return writer;
                };

                /**
                 * Encodes the specified EnrollPayBoostAutoDeliveryRequest message, length delimited. Does not implicitly {@link visor.clientrpc.EnrollPayBoostAutoDeliveryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryRequest} message EnrollPayBoostAutoDeliveryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollPayBoostAutoDeliveryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnrollPayBoostAutoDeliveryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryRequest} EnrollPayBoostAutoDeliveryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollPayBoostAutoDeliveryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.EnrollPayBoostAutoDeliveryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.clientToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnrollPayBoostAutoDeliveryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryRequest} EnrollPayBoostAutoDeliveryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollPayBoostAutoDeliveryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnrollPayBoostAutoDeliveryRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnrollPayBoostAutoDeliveryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        if (!$util.isString(message.clientToken))
                            return "clientToken: string expected";
                    return null;
                };

                /**
                 * Creates an EnrollPayBoostAutoDeliveryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryRequest} EnrollPayBoostAutoDeliveryRequest
                 */
                EnrollPayBoostAutoDeliveryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.EnrollPayBoostAutoDeliveryRequest)
                        return object;
                    var message = new $root.visor.clientrpc.EnrollPayBoostAutoDeliveryRequest();
                    if (object.clientToken != null)
                        message.clientToken = String(object.clientToken);
                    return message;
                };

                /**
                 * Creates a plain object from an EnrollPayBoostAutoDeliveryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @static
                 * @param {visor.clientrpc.EnrollPayBoostAutoDeliveryRequest} message EnrollPayBoostAutoDeliveryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnrollPayBoostAutoDeliveryRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.clientToken = "";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        object.clientToken = message.clientToken;
                    return object;
                };

                /**
                 * Converts this EnrollPayBoostAutoDeliveryRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnrollPayBoostAutoDeliveryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnrollPayBoostAutoDeliveryRequest;
            })();

            clientrpc.EnrollPayBoostAutoDeliveryResponse = (function() {

                /**
                 * Properties of an EnrollPayBoostAutoDeliveryResponse.
                 * @memberof visor.clientrpc
                 * @interface IEnrollPayBoostAutoDeliveryResponse
                 */

                /**
                 * Constructs a new EnrollPayBoostAutoDeliveryResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an EnrollPayBoostAutoDeliveryResponse.
                 * @implements IEnrollPayBoostAutoDeliveryResponse
                 * @constructor
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryResponse=} [properties] Properties to set
                 */
                function EnrollPayBoostAutoDeliveryResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new EnrollPayBoostAutoDeliveryResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryResponse} EnrollPayBoostAutoDeliveryResponse instance
                 */
                EnrollPayBoostAutoDeliveryResponse.create = function create(properties) {
                    return new EnrollPayBoostAutoDeliveryResponse(properties);
                };

                /**
                 * Encodes the specified EnrollPayBoostAutoDeliveryResponse message. Does not implicitly {@link visor.clientrpc.EnrollPayBoostAutoDeliveryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryResponse} message EnrollPayBoostAutoDeliveryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollPayBoostAutoDeliveryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified EnrollPayBoostAutoDeliveryResponse message, length delimited. Does not implicitly {@link visor.clientrpc.EnrollPayBoostAutoDeliveryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {visor.clientrpc.IEnrollPayBoostAutoDeliveryResponse} message EnrollPayBoostAutoDeliveryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnrollPayBoostAutoDeliveryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnrollPayBoostAutoDeliveryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryResponse} EnrollPayBoostAutoDeliveryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollPayBoostAutoDeliveryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.EnrollPayBoostAutoDeliveryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnrollPayBoostAutoDeliveryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryResponse} EnrollPayBoostAutoDeliveryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnrollPayBoostAutoDeliveryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnrollPayBoostAutoDeliveryResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnrollPayBoostAutoDeliveryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an EnrollPayBoostAutoDeliveryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.EnrollPayBoostAutoDeliveryResponse} EnrollPayBoostAutoDeliveryResponse
                 */
                EnrollPayBoostAutoDeliveryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.EnrollPayBoostAutoDeliveryResponse)
                        return object;
                    return new $root.visor.clientrpc.EnrollPayBoostAutoDeliveryResponse();
                };

                /**
                 * Creates a plain object from an EnrollPayBoostAutoDeliveryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @static
                 * @param {visor.clientrpc.EnrollPayBoostAutoDeliveryResponse} message EnrollPayBoostAutoDeliveryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnrollPayBoostAutoDeliveryResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this EnrollPayBoostAutoDeliveryResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.EnrollPayBoostAutoDeliveryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnrollPayBoostAutoDeliveryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return EnrollPayBoostAutoDeliveryResponse;
            })();

            clientrpc.ListBoostCardProgramStatementsRequest = (function() {

                /**
                 * Properties of a ListBoostCardProgramStatementsRequest.
                 * @memberof visor.clientrpc
                 * @interface IListBoostCardProgramStatementsRequest
                 * @property {visor.clientrpc.SynapseNodeType|null} [synapseNodeType] ListBoostCardProgramStatementsRequest synapseNodeType
                 */

                /**
                 * Constructs a new ListBoostCardProgramStatementsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ListBoostCardProgramStatementsRequest.
                 * @implements IListBoostCardProgramStatementsRequest
                 * @constructor
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsRequest=} [properties] Properties to set
                 */
                function ListBoostCardProgramStatementsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBoostCardProgramStatementsRequest synapseNodeType.
                 * @member {visor.clientrpc.SynapseNodeType} synapseNodeType
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @instance
                 */
                ListBoostCardProgramStatementsRequest.prototype.synapseNodeType = 0;

                /**
                 * Creates a new ListBoostCardProgramStatementsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsRequest} ListBoostCardProgramStatementsRequest instance
                 */
                ListBoostCardProgramStatementsRequest.create = function create(properties) {
                    return new ListBoostCardProgramStatementsRequest(properties);
                };

                /**
                 * Encodes the specified ListBoostCardProgramStatementsRequest message. Does not implicitly {@link visor.clientrpc.ListBoostCardProgramStatementsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsRequest} message ListBoostCardProgramStatementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListBoostCardProgramStatementsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.synapseNodeType != null && message.hasOwnProperty("synapseNodeType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.synapseNodeType);
                    return writer;
                };

                /**
                 * Encodes the specified ListBoostCardProgramStatementsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ListBoostCardProgramStatementsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsRequest} message ListBoostCardProgramStatementsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListBoostCardProgramStatementsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ListBoostCardProgramStatementsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsRequest} ListBoostCardProgramStatementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListBoostCardProgramStatementsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListBoostCardProgramStatementsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.synapseNodeType = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ListBoostCardProgramStatementsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsRequest} ListBoostCardProgramStatementsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListBoostCardProgramStatementsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ListBoostCardProgramStatementsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBoostCardProgramStatementsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.synapseNodeType != null && message.hasOwnProperty("synapseNodeType"))
                        switch (message.synapseNodeType) {
                        default:
                            return "synapseNodeType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 6:
                        case 5:
                        case 7:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ListBoostCardProgramStatementsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsRequest} ListBoostCardProgramStatementsRequest
                 */
                ListBoostCardProgramStatementsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ListBoostCardProgramStatementsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ListBoostCardProgramStatementsRequest();
                    switch (object.synapseNodeType) {
                    case "SYNAPSE_NODE_TYPE_INVALID":
                    case 0:
                        message.synapseNodeType = 0;
                        break;
                    case "SYNAPSE_NODE_TYPE_ACH":
                    case 1:
                        message.synapseNodeType = 1;
                        break;
                    case "SYNAPSE_NODE_TYPE_DEPOSIT":
                    case 2:
                        message.synapseNodeType = 2;
                        break;
                    case "SYNAPSE_NODE_TYPE_RESERVE":
                    case 3:
                        message.synapseNodeType = 3;
                        break;
                    case "SYNAPSE_NODE_TYPE_CREDIT":
                    case 4:
                        message.synapseNodeType = 4;
                        break;
                    case "SYNAPSE_NODE_TYPE_REVOLVING":
                    case 6:
                        message.synapseNodeType = 6;
                        break;
                    case "SYNAPSE_NODE_TYPE_INTERCHANGE":
                    case 5:
                        message.synapseNodeType = 5;
                        break;
                    case "SYNAPSE_NODE_TYPE_REVOLVING_PIER":
                    case 7:
                        message.synapseNodeType = 7;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBoostCardProgramStatementsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @static
                 * @param {visor.clientrpc.ListBoostCardProgramStatementsRequest} message ListBoostCardProgramStatementsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBoostCardProgramStatementsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.synapseNodeType = options.enums === String ? "SYNAPSE_NODE_TYPE_INVALID" : 0;
                    if (message.synapseNodeType != null && message.hasOwnProperty("synapseNodeType"))
                        object.synapseNodeType = options.enums === String ? $root.visor.clientrpc.SynapseNodeType[message.synapseNodeType] : message.synapseNodeType;
                    return object;
                };

                /**
                 * Converts this ListBoostCardProgramStatementsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBoostCardProgramStatementsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListBoostCardProgramStatementsRequest;
            })();

            /**
             * SynapseNodeType enum.
             * @name visor.clientrpc.SynapseNodeType
             * @enum {string}
             * @property {number} SYNAPSE_NODE_TYPE_INVALID=0 SYNAPSE_NODE_TYPE_INVALID value
             * @property {number} SYNAPSE_NODE_TYPE_ACH=1 SYNAPSE_NODE_TYPE_ACH value
             * @property {number} SYNAPSE_NODE_TYPE_DEPOSIT=2 SYNAPSE_NODE_TYPE_DEPOSIT value
             * @property {number} SYNAPSE_NODE_TYPE_RESERVE=3 SYNAPSE_NODE_TYPE_RESERVE value
             * @property {number} SYNAPSE_NODE_TYPE_CREDIT=4 SYNAPSE_NODE_TYPE_CREDIT value
             * @property {number} SYNAPSE_NODE_TYPE_REVOLVING=6 SYNAPSE_NODE_TYPE_REVOLVING value
             * @property {number} SYNAPSE_NODE_TYPE_INTERCHANGE=5 SYNAPSE_NODE_TYPE_INTERCHANGE value
             * @property {number} SYNAPSE_NODE_TYPE_REVOLVING_PIER=7 SYNAPSE_NODE_TYPE_REVOLVING_PIER value
             */
            clientrpc.SynapseNodeType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SYNAPSE_NODE_TYPE_INVALID"] = 0;
                values[valuesById[1] = "SYNAPSE_NODE_TYPE_ACH"] = 1;
                values[valuesById[2] = "SYNAPSE_NODE_TYPE_DEPOSIT"] = 2;
                values[valuesById[3] = "SYNAPSE_NODE_TYPE_RESERVE"] = 3;
                values[valuesById[4] = "SYNAPSE_NODE_TYPE_CREDIT"] = 4;
                values[valuesById[6] = "SYNAPSE_NODE_TYPE_REVOLVING"] = 6;
                values[valuesById[5] = "SYNAPSE_NODE_TYPE_INTERCHANGE"] = 5;
                values[valuesById[7] = "SYNAPSE_NODE_TYPE_REVOLVING_PIER"] = 7;
                return values;
            })();

            clientrpc.ListBoostCardProgramStatementsResponse = (function() {

                /**
                 * Properties of a ListBoostCardProgramStatementsResponse.
                 * @memberof visor.clientrpc
                 * @interface IListBoostCardProgramStatementsResponse
                 * @property {Array.<visor.clientrpc.ListBoostCardProgramStatementsResponse.IStatement>|null} [statements] ListBoostCardProgramStatementsResponse statements
                 */

                /**
                 * Constructs a new ListBoostCardProgramStatementsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ListBoostCardProgramStatementsResponse.
                 * @implements IListBoostCardProgramStatementsResponse
                 * @constructor
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsResponse=} [properties] Properties to set
                 */
                function ListBoostCardProgramStatementsResponse(properties) {
                    this.statements = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListBoostCardProgramStatementsResponse statements.
                 * @member {Array.<visor.clientrpc.ListBoostCardProgramStatementsResponse.IStatement>} statements
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @instance
                 */
                ListBoostCardProgramStatementsResponse.prototype.statements = $util.emptyArray;

                /**
                 * Creates a new ListBoostCardProgramStatementsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse} ListBoostCardProgramStatementsResponse instance
                 */
                ListBoostCardProgramStatementsResponse.create = function create(properties) {
                    return new ListBoostCardProgramStatementsResponse(properties);
                };

                /**
                 * Encodes the specified ListBoostCardProgramStatementsResponse message. Does not implicitly {@link visor.clientrpc.ListBoostCardProgramStatementsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsResponse} message ListBoostCardProgramStatementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListBoostCardProgramStatementsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.statements != null && message.statements.length)
                        for (var i = 0; i < message.statements.length; ++i)
                            $root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement.encode(message.statements[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ListBoostCardProgramStatementsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ListBoostCardProgramStatementsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {visor.clientrpc.IListBoostCardProgramStatementsResponse} message ListBoostCardProgramStatementsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListBoostCardProgramStatementsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ListBoostCardProgramStatementsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse} ListBoostCardProgramStatementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListBoostCardProgramStatementsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListBoostCardProgramStatementsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.statements && message.statements.length))
                                message.statements = [];
                            message.statements.push($root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ListBoostCardProgramStatementsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse} ListBoostCardProgramStatementsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListBoostCardProgramStatementsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ListBoostCardProgramStatementsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListBoostCardProgramStatementsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.statements != null && message.hasOwnProperty("statements")) {
                        if (!Array.isArray(message.statements))
                            return "statements: array expected";
                        for (var i = 0; i < message.statements.length; ++i) {
                            var error = $root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement.verify(message.statements[i]);
                            if (error)
                                return "statements." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListBoostCardProgramStatementsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse} ListBoostCardProgramStatementsResponse
                 */
                ListBoostCardProgramStatementsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ListBoostCardProgramStatementsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.ListBoostCardProgramStatementsResponse();
                    if (object.statements) {
                        if (!Array.isArray(object.statements))
                            throw TypeError(".visor.clientrpc.ListBoostCardProgramStatementsResponse.statements: array expected");
                        message.statements = [];
                        for (var i = 0; i < object.statements.length; ++i) {
                            if (typeof object.statements[i] !== "object")
                                throw TypeError(".visor.clientrpc.ListBoostCardProgramStatementsResponse.statements: object expected");
                            message.statements[i] = $root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement.fromObject(object.statements[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListBoostCardProgramStatementsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @static
                 * @param {visor.clientrpc.ListBoostCardProgramStatementsResponse} message ListBoostCardProgramStatementsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListBoostCardProgramStatementsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.statements = [];
                    if (message.statements && message.statements.length) {
                        object.statements = [];
                        for (var j = 0; j < message.statements.length; ++j)
                            object.statements[j] = $root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement.toObject(message.statements[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListBoostCardProgramStatementsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListBoostCardProgramStatementsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListBoostCardProgramStatementsResponse.Statement = (function() {

                    /**
                     * Properties of a Statement.
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                     * @interface IStatement
                     * @property {google.protobuf.ITimestamp|null} [startDate] Statement startDate
                     * @property {google.protobuf.ITimestamp|null} [endDate] Statement endDate
                     * @property {string|null} [pdfUrl] Statement pdfUrl
                     * @property {string|null} [type] Statement type
                     */

                    /**
                     * Constructs a new Statement.
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse
                     * @classdesc Represents a Statement.
                     * @implements IStatement
                     * @constructor
                     * @param {visor.clientrpc.ListBoostCardProgramStatementsResponse.IStatement=} [properties] Properties to set
                     */
                    function Statement(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Statement startDate.
                     * @member {google.protobuf.ITimestamp|null|undefined} startDate
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @instance
                     */
                    Statement.prototype.startDate = null;

                    /**
                     * Statement endDate.
                     * @member {google.protobuf.ITimestamp|null|undefined} endDate
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @instance
                     */
                    Statement.prototype.endDate = null;

                    /**
                     * Statement pdfUrl.
                     * @member {string} pdfUrl
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @instance
                     */
                    Statement.prototype.pdfUrl = "";

                    /**
                     * Statement type.
                     * @member {string} type
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @instance
                     */
                    Statement.prototype.type = "";

                    /**
                     * Creates a new Statement instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {visor.clientrpc.ListBoostCardProgramStatementsResponse.IStatement=} [properties] Properties to set
                     * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement} Statement instance
                     */
                    Statement.create = function create(properties) {
                        return new Statement(properties);
                    };

                    /**
                     * Encodes the specified Statement message. Does not implicitly {@link visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {visor.clientrpc.ListBoostCardProgramStatementsResponse.IStatement} message Statement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Statement.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            $root.google.protobuf.Timestamp.encode(message.startDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            $root.google.protobuf.Timestamp.encode(message.endDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.pdfUrl != null && message.hasOwnProperty("pdfUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pdfUrl);
                        if (message.type != null && message.hasOwnProperty("type"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.type);
                        return writer;
                    };

                    /**
                     * Encodes the specified Statement message, length delimited. Does not implicitly {@link visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {visor.clientrpc.ListBoostCardProgramStatementsResponse.IStatement} message Statement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Statement.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Statement message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement} Statement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Statement.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.startDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.endDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.pdfUrl = reader.string();
                                break;
                            case 4:
                                message.type = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Statement message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement} Statement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Statement.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Statement message.
                     * @function verify
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Statement.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.startDate != null && message.hasOwnProperty("startDate")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.startDate);
                            if (error)
                                return "startDate." + error;
                        }
                        if (message.endDate != null && message.hasOwnProperty("endDate")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.endDate);
                            if (error)
                                return "endDate." + error;
                        }
                        if (message.pdfUrl != null && message.hasOwnProperty("pdfUrl"))
                            if (!$util.isString(message.pdfUrl))
                                return "pdfUrl: string expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            if (!$util.isString(message.type))
                                return "type: string expected";
                        return null;
                    };

                    /**
                     * Creates a Statement message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement} Statement
                     */
                    Statement.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement)
                            return object;
                        var message = new $root.visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement();
                        if (object.startDate != null)
                            if (object.startDate === null)
                                message.startDate = {};
                            else
                                message.startDate = {
                                    seconds: Math.floor(object.startDate.getTime() / 1000),
                                    nanos: object.startDate.getMilliseconds() * 1000000
                                };
                        if (object.endDate != null)
                            if (object.endDate === null)
                                message.endDate = {};
                            else
                                message.endDate = {
                                    seconds: Math.floor(object.endDate.getTime() / 1000),
                                    nanos: object.endDate.getMilliseconds() * 1000000
                                };
                        if (object.pdfUrl != null)
                            message.pdfUrl = String(object.pdfUrl);
                        if (object.type != null)
                            message.type = String(object.type);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Statement message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @static
                     * @param {visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement} message Statement
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Statement.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.startDate = null;
                            object.endDate = null;
                            object.pdfUrl = "";
                            object.type = "";
                        }
                        if (message.startDate != null && message.hasOwnProperty("startDate"))
                            object.startDate = new Date(message.startDate.seconds * 1000 + message.startDate.nanos / 1000000);
                        if (message.endDate != null && message.hasOwnProperty("endDate"))
                            object.endDate = new Date(message.endDate.seconds * 1000 + message.endDate.nanos / 1000000);
                        if (message.pdfUrl != null && message.hasOwnProperty("pdfUrl"))
                            object.pdfUrl = message.pdfUrl;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = message.type;
                        return object;
                    };

                    /**
                     * Converts this Statement to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.ListBoostCardProgramStatementsResponse.Statement
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Statement.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Statement;
                })();

                return ListBoostCardProgramStatementsResponse;
            })();

            clientrpc.CompleteGovernmentIDUploadStepRequest = (function() {

                /**
                 * Properties of a CompleteGovernmentIDUploadStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteGovernmentIDUploadStepRequest
                 * @property {string|null} [workflowToken] CompleteGovernmentIDUploadStepRequest workflowToken
                 * @property {string|null} [workflowStepToken] CompleteGovernmentIDUploadStepRequest workflowStepToken
                 * @property {string|null} [governmentIdFrontFileToken] CompleteGovernmentIDUploadStepRequest governmentIdFrontFileToken
                 * @property {string|null} [governmentIdBackFileToken] CompleteGovernmentIDUploadStepRequest governmentIdBackFileToken
                 */

                /**
                 * Constructs a new CompleteGovernmentIDUploadStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteGovernmentIDUploadStepRequest.
                 * @implements ICompleteGovernmentIDUploadStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepRequest=} [properties] Properties to set
                 */
                function CompleteGovernmentIDUploadStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteGovernmentIDUploadStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @instance
                 */
                CompleteGovernmentIDUploadStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteGovernmentIDUploadStepRequest workflowStepToken.
                 * @member {string} workflowStepToken
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @instance
                 */
                CompleteGovernmentIDUploadStepRequest.prototype.workflowStepToken = "";

                /**
                 * CompleteGovernmentIDUploadStepRequest governmentIdFrontFileToken.
                 * @member {string} governmentIdFrontFileToken
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @instance
                 */
                CompleteGovernmentIDUploadStepRequest.prototype.governmentIdFrontFileToken = "";

                /**
                 * CompleteGovernmentIDUploadStepRequest governmentIdBackFileToken.
                 * @member {string} governmentIdBackFileToken
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @instance
                 */
                CompleteGovernmentIDUploadStepRequest.prototype.governmentIdBackFileToken = "";

                /**
                 * Creates a new CompleteGovernmentIDUploadStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepRequest} CompleteGovernmentIDUploadStepRequest instance
                 */
                CompleteGovernmentIDUploadStepRequest.create = function create(properties) {
                    return new CompleteGovernmentIDUploadStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteGovernmentIDUploadStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteGovernmentIDUploadStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepRequest} message CompleteGovernmentIDUploadStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteGovernmentIDUploadStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.workflowToken);
                    if (message.workflowStepToken != null && message.hasOwnProperty("workflowStepToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowStepToken);
                    if (message.governmentIdFrontFileToken != null && message.hasOwnProperty("governmentIdFrontFileToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.governmentIdFrontFileToken);
                    if (message.governmentIdBackFileToken != null && message.hasOwnProperty("governmentIdBackFileToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.governmentIdBackFileToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteGovernmentIDUploadStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteGovernmentIDUploadStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepRequest} message CompleteGovernmentIDUploadStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteGovernmentIDUploadStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteGovernmentIDUploadStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepRequest} CompleteGovernmentIDUploadStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteGovernmentIDUploadStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteGovernmentIDUploadStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.workflowToken = reader.string();
                            break;
                        case 2:
                            message.workflowStepToken = reader.string();
                            break;
                        case 3:
                            message.governmentIdFrontFileToken = reader.string();
                            break;
                        case 4:
                            message.governmentIdBackFileToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteGovernmentIDUploadStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepRequest} CompleteGovernmentIDUploadStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteGovernmentIDUploadStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteGovernmentIDUploadStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteGovernmentIDUploadStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.workflowStepToken != null && message.hasOwnProperty("workflowStepToken"))
                        if (!$util.isString(message.workflowStepToken))
                            return "workflowStepToken: string expected";
                    if (message.governmentIdFrontFileToken != null && message.hasOwnProperty("governmentIdFrontFileToken"))
                        if (!$util.isString(message.governmentIdFrontFileToken))
                            return "governmentIdFrontFileToken: string expected";
                    if (message.governmentIdBackFileToken != null && message.hasOwnProperty("governmentIdBackFileToken"))
                        if (!$util.isString(message.governmentIdBackFileToken))
                            return "governmentIdBackFileToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteGovernmentIDUploadStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepRequest} CompleteGovernmentIDUploadStepRequest
                 */
                CompleteGovernmentIDUploadStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteGovernmentIDUploadStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteGovernmentIDUploadStepRequest();
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.workflowStepToken != null)
                        message.workflowStepToken = String(object.workflowStepToken);
                    if (object.governmentIdFrontFileToken != null)
                        message.governmentIdFrontFileToken = String(object.governmentIdFrontFileToken);
                    if (object.governmentIdBackFileToken != null)
                        message.governmentIdBackFileToken = String(object.governmentIdBackFileToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteGovernmentIDUploadStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteGovernmentIDUploadStepRequest} message CompleteGovernmentIDUploadStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteGovernmentIDUploadStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.workflowToken = "";
                        object.workflowStepToken = "";
                        object.governmentIdFrontFileToken = "";
                        object.governmentIdBackFileToken = "";
                    }
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.workflowStepToken != null && message.hasOwnProperty("workflowStepToken"))
                        object.workflowStepToken = message.workflowStepToken;
                    if (message.governmentIdFrontFileToken != null && message.hasOwnProperty("governmentIdFrontFileToken"))
                        object.governmentIdFrontFileToken = message.governmentIdFrontFileToken;
                    if (message.governmentIdBackFileToken != null && message.hasOwnProperty("governmentIdBackFileToken"))
                        object.governmentIdBackFileToken = message.governmentIdBackFileToken;
                    return object;
                };

                /**
                 * Converts this CompleteGovernmentIDUploadStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteGovernmentIDUploadStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteGovernmentIDUploadStepRequest;
            })();

            clientrpc.CompleteGovernmentIDUploadStepResponse = (function() {

                /**
                 * Properties of a CompleteGovernmentIDUploadStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteGovernmentIDUploadStepResponse
                 */

                /**
                 * Constructs a new CompleteGovernmentIDUploadStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteGovernmentIDUploadStepResponse.
                 * @implements ICompleteGovernmentIDUploadStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepResponse=} [properties] Properties to set
                 */
                function CompleteGovernmentIDUploadStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteGovernmentIDUploadStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepResponse} CompleteGovernmentIDUploadStepResponse instance
                 */
                CompleteGovernmentIDUploadStepResponse.create = function create(properties) {
                    return new CompleteGovernmentIDUploadStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteGovernmentIDUploadStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteGovernmentIDUploadStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepResponse} message CompleteGovernmentIDUploadStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteGovernmentIDUploadStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteGovernmentIDUploadStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteGovernmentIDUploadStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteGovernmentIDUploadStepResponse} message CompleteGovernmentIDUploadStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteGovernmentIDUploadStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteGovernmentIDUploadStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepResponse} CompleteGovernmentIDUploadStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteGovernmentIDUploadStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteGovernmentIDUploadStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteGovernmentIDUploadStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepResponse} CompleteGovernmentIDUploadStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteGovernmentIDUploadStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteGovernmentIDUploadStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteGovernmentIDUploadStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteGovernmentIDUploadStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteGovernmentIDUploadStepResponse} CompleteGovernmentIDUploadStepResponse
                 */
                CompleteGovernmentIDUploadStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteGovernmentIDUploadStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteGovernmentIDUploadStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteGovernmentIDUploadStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteGovernmentIDUploadStepResponse} message CompleteGovernmentIDUploadStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteGovernmentIDUploadStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteGovernmentIDUploadStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteGovernmentIDUploadStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteGovernmentIDUploadStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteGovernmentIDUploadStepResponse;
            })();

            clientrpc.AppLaunchRequest = (function() {

                /**
                 * Properties of an AppLaunchRequest.
                 * @memberof visor.clientrpc
                 * @interface IAppLaunchRequest
                 */

                /**
                 * Constructs a new AppLaunchRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an AppLaunchRequest.
                 * @implements IAppLaunchRequest
                 * @constructor
                 * @param {visor.clientrpc.IAppLaunchRequest=} [properties] Properties to set
                 */
                function AppLaunchRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new AppLaunchRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {visor.clientrpc.IAppLaunchRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.AppLaunchRequest} AppLaunchRequest instance
                 */
                AppLaunchRequest.create = function create(properties) {
                    return new AppLaunchRequest(properties);
                };

                /**
                 * Encodes the specified AppLaunchRequest message. Does not implicitly {@link visor.clientrpc.AppLaunchRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {visor.clientrpc.IAppLaunchRequest} message AppLaunchRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppLaunchRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified AppLaunchRequest message, length delimited. Does not implicitly {@link visor.clientrpc.AppLaunchRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {visor.clientrpc.IAppLaunchRequest} message AppLaunchRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppLaunchRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AppLaunchRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.AppLaunchRequest} AppLaunchRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppLaunchRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AppLaunchRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AppLaunchRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.AppLaunchRequest} AppLaunchRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppLaunchRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AppLaunchRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AppLaunchRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an AppLaunchRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.AppLaunchRequest} AppLaunchRequest
                 */
                AppLaunchRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.AppLaunchRequest)
                        return object;
                    return new $root.visor.clientrpc.AppLaunchRequest();
                };

                /**
                 * Creates a plain object from an AppLaunchRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @static
                 * @param {visor.clientrpc.AppLaunchRequest} message AppLaunchRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AppLaunchRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AppLaunchRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.AppLaunchRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AppLaunchRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AppLaunchRequest;
            })();

            clientrpc.AppLaunchResponse = (function() {

                /**
                 * Properties of an AppLaunchResponse.
                 * @memberof visor.clientrpc
                 * @interface IAppLaunchResponse
                 */

                /**
                 * Constructs a new AppLaunchResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an AppLaunchResponse.
                 * @implements IAppLaunchResponse
                 * @constructor
                 * @param {visor.clientrpc.IAppLaunchResponse=} [properties] Properties to set
                 */
                function AppLaunchResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new AppLaunchResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {visor.clientrpc.IAppLaunchResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.AppLaunchResponse} AppLaunchResponse instance
                 */
                AppLaunchResponse.create = function create(properties) {
                    return new AppLaunchResponse(properties);
                };

                /**
                 * Encodes the specified AppLaunchResponse message. Does not implicitly {@link visor.clientrpc.AppLaunchResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {visor.clientrpc.IAppLaunchResponse} message AppLaunchResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppLaunchResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified AppLaunchResponse message, length delimited. Does not implicitly {@link visor.clientrpc.AppLaunchResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {visor.clientrpc.IAppLaunchResponse} message AppLaunchResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AppLaunchResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AppLaunchResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.AppLaunchResponse} AppLaunchResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppLaunchResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AppLaunchResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AppLaunchResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.AppLaunchResponse} AppLaunchResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AppLaunchResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AppLaunchResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AppLaunchResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an AppLaunchResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.AppLaunchResponse} AppLaunchResponse
                 */
                AppLaunchResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.AppLaunchResponse)
                        return object;
                    return new $root.visor.clientrpc.AppLaunchResponse();
                };

                /**
                 * Creates a plain object from an AppLaunchResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @static
                 * @param {visor.clientrpc.AppLaunchResponse} message AppLaunchResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AppLaunchResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AppLaunchResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.AppLaunchResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AppLaunchResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AppLaunchResponse;
            })();

            /**
             * BoostCardShipmentStatus enum.
             * @name visor.clientrpc.BoostCardShipmentStatus
             * @enum {string}
             * @property {number} BOOST_CARD_SHIPMENT_STATUS_INVALID=0 BOOST_CARD_SHIPMENT_STATUS_INVALID value
             * @property {number} BOOST_CARD_SHIPMENT_STATUS_PREPARING=1 BOOST_CARD_SHIPMENT_STATUS_PREPARING value
             * @property {number} BOOST_CARD_SHIPMENT_STATUS_SHIPPED=2 BOOST_CARD_SHIPMENT_STATUS_SHIPPED value
             * @property {number} BOOST_CARD_SHIPMENT_STATUS_CANCELED=3 BOOST_CARD_SHIPMENT_STATUS_CANCELED value
             */
            clientrpc.BoostCardShipmentStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "BOOST_CARD_SHIPMENT_STATUS_INVALID"] = 0;
                values[valuesById[1] = "BOOST_CARD_SHIPMENT_STATUS_PREPARING"] = 1;
                values[valuesById[2] = "BOOST_CARD_SHIPMENT_STATUS_SHIPPED"] = 2;
                values[valuesById[3] = "BOOST_CARD_SHIPMENT_STATUS_CANCELED"] = 3;
                return values;
            })();

            clientrpc.BeginBoostCardActivationWorkflowRequest = (function() {

                /**
                 * Properties of a BeginBoostCardActivationWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginBoostCardActivationWorkflowRequest
                 */

                /**
                 * Constructs a new BeginBoostCardActivationWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginBoostCardActivationWorkflowRequest.
                 * @implements IBeginBoostCardActivationWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowRequest=} [properties] Properties to set
                 */
                function BeginBoostCardActivationWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginBoostCardActivationWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowRequest} BeginBoostCardActivationWorkflowRequest instance
                 */
                BeginBoostCardActivationWorkflowRequest.create = function create(properties) {
                    return new BeginBoostCardActivationWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginBoostCardActivationWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginBoostCardActivationWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowRequest} message BeginBoostCardActivationWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardActivationWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginBoostCardActivationWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginBoostCardActivationWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowRequest} message BeginBoostCardActivationWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardActivationWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginBoostCardActivationWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowRequest} BeginBoostCardActivationWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardActivationWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginBoostCardActivationWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginBoostCardActivationWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowRequest} BeginBoostCardActivationWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardActivationWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginBoostCardActivationWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginBoostCardActivationWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginBoostCardActivationWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowRequest} BeginBoostCardActivationWorkflowRequest
                 */
                BeginBoostCardActivationWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginBoostCardActivationWorkflowRequest)
                        return object;
                    return new $root.visor.clientrpc.BeginBoostCardActivationWorkflowRequest();
                };

                /**
                 * Creates a plain object from a BeginBoostCardActivationWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginBoostCardActivationWorkflowRequest} message BeginBoostCardActivationWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginBoostCardActivationWorkflowRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginBoostCardActivationWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginBoostCardActivationWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginBoostCardActivationWorkflowRequest;
            })();

            clientrpc.BeginBoostCardActivationWorkflowResponse = (function() {

                /**
                 * Properties of a BeginBoostCardActivationWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginBoostCardActivationWorkflowResponse
                 */

                /**
                 * Constructs a new BeginBoostCardActivationWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginBoostCardActivationWorkflowResponse.
                 * @implements IBeginBoostCardActivationWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowResponse=} [properties] Properties to set
                 */
                function BeginBoostCardActivationWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginBoostCardActivationWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowResponse} BeginBoostCardActivationWorkflowResponse instance
                 */
                BeginBoostCardActivationWorkflowResponse.create = function create(properties) {
                    return new BeginBoostCardActivationWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginBoostCardActivationWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginBoostCardActivationWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowResponse} message BeginBoostCardActivationWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardActivationWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginBoostCardActivationWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginBoostCardActivationWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardActivationWorkflowResponse} message BeginBoostCardActivationWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardActivationWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginBoostCardActivationWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowResponse} BeginBoostCardActivationWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardActivationWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginBoostCardActivationWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginBoostCardActivationWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowResponse} BeginBoostCardActivationWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardActivationWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginBoostCardActivationWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginBoostCardActivationWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginBoostCardActivationWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginBoostCardActivationWorkflowResponse} BeginBoostCardActivationWorkflowResponse
                 */
                BeginBoostCardActivationWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginBoostCardActivationWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginBoostCardActivationWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginBoostCardActivationWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginBoostCardActivationWorkflowResponse} message BeginBoostCardActivationWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginBoostCardActivationWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginBoostCardActivationWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginBoostCardActivationWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginBoostCardActivationWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginBoostCardActivationWorkflowResponse;
            })();

            clientrpc.GetBoostCardDetailsRequest = (function() {

                /**
                 * Properties of a GetBoostCardDetailsRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetBoostCardDetailsRequest
                 * @property {string|null} [subnetToken] GetBoostCardDetailsRequest subnetToken
                 */

                /**
                 * Constructs a new GetBoostCardDetailsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBoostCardDetailsRequest.
                 * @implements IGetBoostCardDetailsRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetBoostCardDetailsRequest=} [properties] Properties to set
                 */
                function GetBoostCardDetailsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBoostCardDetailsRequest subnetToken.
                 * @member {string} subnetToken
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @instance
                 */
                GetBoostCardDetailsRequest.prototype.subnetToken = "";

                /**
                 * Creates a new GetBoostCardDetailsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardDetailsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBoostCardDetailsRequest} GetBoostCardDetailsRequest instance
                 */
                GetBoostCardDetailsRequest.create = function create(properties) {
                    return new GetBoostCardDetailsRequest(properties);
                };

                /**
                 * Encodes the specified GetBoostCardDetailsRequest message. Does not implicitly {@link visor.clientrpc.GetBoostCardDetailsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardDetailsRequest} message GetBoostCardDetailsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardDetailsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.subnetToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetBoostCardDetailsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetBoostCardDetailsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardDetailsRequest} message GetBoostCardDetailsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardDetailsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBoostCardDetailsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBoostCardDetailsRequest} GetBoostCardDetailsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardDetailsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBoostCardDetailsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.subnetToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBoostCardDetailsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBoostCardDetailsRequest} GetBoostCardDetailsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardDetailsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBoostCardDetailsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBoostCardDetailsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        if (!$util.isString(message.subnetToken))
                            return "subnetToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetBoostCardDetailsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBoostCardDetailsRequest} GetBoostCardDetailsRequest
                 */
                GetBoostCardDetailsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBoostCardDetailsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetBoostCardDetailsRequest();
                    if (object.subnetToken != null)
                        message.subnetToken = String(object.subnetToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBoostCardDetailsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @static
                 * @param {visor.clientrpc.GetBoostCardDetailsRequest} message GetBoostCardDetailsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBoostCardDetailsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.subnetToken = "";
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        object.subnetToken = message.subnetToken;
                    return object;
                };

                /**
                 * Converts this GetBoostCardDetailsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBoostCardDetailsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBoostCardDetailsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBoostCardDetailsRequest;
            })();

            clientrpc.GetBoostCardDetailsResponse = (function() {

                /**
                 * Properties of a GetBoostCardDetailsResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetBoostCardDetailsResponse
                 * @property {string|null} [cardNumber] GetBoostCardDetailsResponse cardNumber
                 * @property {string|null} [cvc] GetBoostCardDetailsResponse cvc
                 * @property {number|Long|null} [expMonth] GetBoostCardDetailsResponse expMonth
                 * @property {number|Long|null} [expYear] GetBoostCardDetailsResponse expYear
                 * @property {visor.clientrpc.CardDetailsAvailability|null} [availability] GetBoostCardDetailsResponse availability
                 */

                /**
                 * Constructs a new GetBoostCardDetailsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBoostCardDetailsResponse.
                 * @implements IGetBoostCardDetailsResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetBoostCardDetailsResponse=} [properties] Properties to set
                 */
                function GetBoostCardDetailsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBoostCardDetailsResponse cardNumber.
                 * @member {string} cardNumber
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @instance
                 */
                GetBoostCardDetailsResponse.prototype.cardNumber = "";

                /**
                 * GetBoostCardDetailsResponse cvc.
                 * @member {string} cvc
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @instance
                 */
                GetBoostCardDetailsResponse.prototype.cvc = "";

                /**
                 * GetBoostCardDetailsResponse expMonth.
                 * @member {number|Long} expMonth
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @instance
                 */
                GetBoostCardDetailsResponse.prototype.expMonth = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * GetBoostCardDetailsResponse expYear.
                 * @member {number|Long} expYear
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @instance
                 */
                GetBoostCardDetailsResponse.prototype.expYear = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * GetBoostCardDetailsResponse availability.
                 * @member {visor.clientrpc.CardDetailsAvailability} availability
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @instance
                 */
                GetBoostCardDetailsResponse.prototype.availability = 0;

                /**
                 * Creates a new GetBoostCardDetailsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardDetailsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBoostCardDetailsResponse} GetBoostCardDetailsResponse instance
                 */
                GetBoostCardDetailsResponse.create = function create(properties) {
                    return new GetBoostCardDetailsResponse(properties);
                };

                /**
                 * Encodes the specified GetBoostCardDetailsResponse message. Does not implicitly {@link visor.clientrpc.GetBoostCardDetailsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardDetailsResponse} message GetBoostCardDetailsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardDetailsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cardNumber);
                    if (message.cvc != null && message.hasOwnProperty("cvc"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cvc);
                    if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.expMonth);
                    if (message.expYear != null && message.hasOwnProperty("expYear"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.expYear);
                    if (message.availability != null && message.hasOwnProperty("availability"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.availability);
                    return writer;
                };

                /**
                 * Encodes the specified GetBoostCardDetailsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetBoostCardDetailsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardDetailsResponse} message GetBoostCardDetailsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBoostCardDetailsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBoostCardDetailsResponse} GetBoostCardDetailsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardDetailsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBoostCardDetailsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cardNumber = reader.string();
                            break;
                        case 2:
                            message.cvc = reader.string();
                            break;
                        case 3:
                            message.expMonth = reader.uint64();
                            break;
                        case 4:
                            message.expYear = reader.uint64();
                            break;
                        case 5:
                            message.availability = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBoostCardDetailsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBoostCardDetailsResponse} GetBoostCardDetailsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBoostCardDetailsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBoostCardDetailsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber"))
                        if (!$util.isString(message.cardNumber))
                            return "cardNumber: string expected";
                    if (message.cvc != null && message.hasOwnProperty("cvc"))
                        if (!$util.isString(message.cvc))
                            return "cvc: string expected";
                    if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                        if (!$util.isInteger(message.expMonth) && !(message.expMonth && $util.isInteger(message.expMonth.low) && $util.isInteger(message.expMonth.high)))
                            return "expMonth: integer|Long expected";
                    if (message.expYear != null && message.hasOwnProperty("expYear"))
                        if (!$util.isInteger(message.expYear) && !(message.expYear && $util.isInteger(message.expYear.low) && $util.isInteger(message.expYear.high)))
                            return "expYear: integer|Long expected";
                    if (message.availability != null && message.hasOwnProperty("availability"))
                        switch (message.availability) {
                        default:
                            return "availability: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetBoostCardDetailsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBoostCardDetailsResponse} GetBoostCardDetailsResponse
                 */
                GetBoostCardDetailsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBoostCardDetailsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetBoostCardDetailsResponse();
                    if (object.cardNumber != null)
                        message.cardNumber = String(object.cardNumber);
                    if (object.cvc != null)
                        message.cvc = String(object.cvc);
                    if (object.expMonth != null)
                        if ($util.Long)
                            (message.expMonth = $util.Long.fromValue(object.expMonth)).unsigned = true;
                        else if (typeof object.expMonth === "string")
                            message.expMonth = parseInt(object.expMonth, 10);
                        else if (typeof object.expMonth === "number")
                            message.expMonth = object.expMonth;
                        else if (typeof object.expMonth === "object")
                            message.expMonth = new $util.LongBits(object.expMonth.low >>> 0, object.expMonth.high >>> 0).toNumber(true);
                    if (object.expYear != null)
                        if ($util.Long)
                            (message.expYear = $util.Long.fromValue(object.expYear)).unsigned = true;
                        else if (typeof object.expYear === "string")
                            message.expYear = parseInt(object.expYear, 10);
                        else if (typeof object.expYear === "number")
                            message.expYear = object.expYear;
                        else if (typeof object.expYear === "object")
                            message.expYear = new $util.LongBits(object.expYear.low >>> 0, object.expYear.high >>> 0).toNumber(true);
                    switch (object.availability) {
                    case "CARD_DETAILS_AVAILABILITY_INVALID":
                    case 0:
                        message.availability = 0;
                        break;
                    case "CARD_DETAILS_AVAILABILITY_AVAILABLE":
                    case 1:
                        message.availability = 1;
                        break;
                    case "CARD_DETAILS_AVAILABILITY_UNAVAILABLE_PRIOR_TO_FIRST_PAYMENT":
                    case 2:
                        message.availability = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetBoostCardDetailsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @static
                 * @param {visor.clientrpc.GetBoostCardDetailsResponse} message GetBoostCardDetailsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBoostCardDetailsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cardNumber = "";
                        object.cvc = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.expMonth = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expMonth = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.expYear = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.expYear = options.longs === String ? "0" : 0;
                        object.availability = options.enums === String ? "CARD_DETAILS_AVAILABILITY_INVALID" : 0;
                    }
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber"))
                        object.cardNumber = message.cardNumber;
                    if (message.cvc != null && message.hasOwnProperty("cvc"))
                        object.cvc = message.cvc;
                    if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                        if (typeof message.expMonth === "number")
                            object.expMonth = options.longs === String ? String(message.expMonth) : message.expMonth;
                        else
                            object.expMonth = options.longs === String ? $util.Long.prototype.toString.call(message.expMonth) : options.longs === Number ? new $util.LongBits(message.expMonth.low >>> 0, message.expMonth.high >>> 0).toNumber(true) : message.expMonth;
                    if (message.expYear != null && message.hasOwnProperty("expYear"))
                        if (typeof message.expYear === "number")
                            object.expYear = options.longs === String ? String(message.expYear) : message.expYear;
                        else
                            object.expYear = options.longs === String ? $util.Long.prototype.toString.call(message.expYear) : options.longs === Number ? new $util.LongBits(message.expYear.low >>> 0, message.expYear.high >>> 0).toNumber(true) : message.expYear;
                    if (message.availability != null && message.hasOwnProperty("availability"))
                        object.availability = options.enums === String ? $root.visor.clientrpc.CardDetailsAvailability[message.availability] : message.availability;
                    return object;
                };

                /**
                 * Converts this GetBoostCardDetailsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBoostCardDetailsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBoostCardDetailsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBoostCardDetailsResponse;
            })();

            /**
             * CardDetailsAvailability enum.
             * @name visor.clientrpc.CardDetailsAvailability
             * @enum {string}
             * @property {number} CARD_DETAILS_AVAILABILITY_INVALID=0 CARD_DETAILS_AVAILABILITY_INVALID value
             * @property {number} CARD_DETAILS_AVAILABILITY_AVAILABLE=1 CARD_DETAILS_AVAILABILITY_AVAILABLE value
             * @property {number} CARD_DETAILS_AVAILABILITY_UNAVAILABLE_PRIOR_TO_FIRST_PAYMENT=2 CARD_DETAILS_AVAILABILITY_UNAVAILABLE_PRIOR_TO_FIRST_PAYMENT value
             */
            clientrpc.CardDetailsAvailability = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CARD_DETAILS_AVAILABILITY_INVALID"] = 0;
                values[valuesById[1] = "CARD_DETAILS_AVAILABILITY_AVAILABLE"] = 1;
                values[valuesById[2] = "CARD_DETAILS_AVAILABILITY_UNAVAILABLE_PRIOR_TO_FIRST_PAYMENT"] = 2;
                return values;
            })();

            clientrpc.CreateBoostCardTransferRequest = (function() {

                /**
                 * Properties of a CreateBoostCardTransferRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateBoostCardTransferRequest
                 * @property {string|null} [destinationBoostCardProgramToken] CreateBoostCardTransferRequest destinationBoostCardProgramToken
                 * @property {string|null} [sourceBoostCardProgramToken] CreateBoostCardTransferRequest sourceBoostCardProgramToken
                 * @property {string|null} [destinationBankAccountToken] CreateBoostCardTransferRequest destinationBankAccountToken
                 * @property {string|null} [sourceBankAccountToken] CreateBoostCardTransferRequest sourceBankAccountToken
                 * @property {visor.common.money.IAmount|null} [transferAmount] CreateBoostCardTransferRequest transferAmount
                 */

                /**
                 * Constructs a new CreateBoostCardTransferRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateBoostCardTransferRequest.
                 * @implements ICreateBoostCardTransferRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateBoostCardTransferRequest=} [properties] Properties to set
                 */
                function CreateBoostCardTransferRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBoostCardTransferRequest destinationBoostCardProgramToken.
                 * @member {string} destinationBoostCardProgramToken
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @instance
                 */
                CreateBoostCardTransferRequest.prototype.destinationBoostCardProgramToken = "";

                /**
                 * CreateBoostCardTransferRequest sourceBoostCardProgramToken.
                 * @member {string} sourceBoostCardProgramToken
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @instance
                 */
                CreateBoostCardTransferRequest.prototype.sourceBoostCardProgramToken = "";

                /**
                 * CreateBoostCardTransferRequest destinationBankAccountToken.
                 * @member {string} destinationBankAccountToken
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @instance
                 */
                CreateBoostCardTransferRequest.prototype.destinationBankAccountToken = "";

                /**
                 * CreateBoostCardTransferRequest sourceBankAccountToken.
                 * @member {string} sourceBankAccountToken
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @instance
                 */
                CreateBoostCardTransferRequest.prototype.sourceBankAccountToken = "";

                /**
                 * CreateBoostCardTransferRequest transferAmount.
                 * @member {visor.common.money.IAmount|null|undefined} transferAmount
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @instance
                 */
                CreateBoostCardTransferRequest.prototype.transferAmount = null;

                /**
                 * Creates a new CreateBoostCardTransferRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardTransferRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateBoostCardTransferRequest} CreateBoostCardTransferRequest instance
                 */
                CreateBoostCardTransferRequest.create = function create(properties) {
                    return new CreateBoostCardTransferRequest(properties);
                };

                /**
                 * Encodes the specified CreateBoostCardTransferRequest message. Does not implicitly {@link visor.clientrpc.CreateBoostCardTransferRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardTransferRequest} message CreateBoostCardTransferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardTransferRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.destinationBoostCardProgramToken != null && message.hasOwnProperty("destinationBoostCardProgramToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.destinationBoostCardProgramToken);
                    if (message.sourceBoostCardProgramToken != null && message.hasOwnProperty("sourceBoostCardProgramToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceBoostCardProgramToken);
                    if (message.destinationBankAccountToken != null && message.hasOwnProperty("destinationBankAccountToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.destinationBankAccountToken);
                    if (message.sourceBankAccountToken != null && message.hasOwnProperty("sourceBankAccountToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.sourceBankAccountToken);
                    if (message.transferAmount != null && message.hasOwnProperty("transferAmount"))
                        $root.visor.common.money.Amount.encode(message.transferAmount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreateBoostCardTransferRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBoostCardTransferRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardTransferRequest} message CreateBoostCardTransferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardTransferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateBoostCardTransferRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateBoostCardTransferRequest} CreateBoostCardTransferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardTransferRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBoostCardTransferRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.destinationBoostCardProgramToken = reader.string();
                            break;
                        case 2:
                            message.sourceBoostCardProgramToken = reader.string();
                            break;
                        case 3:
                            message.destinationBankAccountToken = reader.string();
                            break;
                        case 4:
                            message.sourceBankAccountToken = reader.string();
                            break;
                        case 5:
                            message.transferAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateBoostCardTransferRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateBoostCardTransferRequest} CreateBoostCardTransferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardTransferRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateBoostCardTransferRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBoostCardTransferRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.destinationBoostCardProgramToken != null && message.hasOwnProperty("destinationBoostCardProgramToken"))
                        if (!$util.isString(message.destinationBoostCardProgramToken))
                            return "destinationBoostCardProgramToken: string expected";
                    if (message.sourceBoostCardProgramToken != null && message.hasOwnProperty("sourceBoostCardProgramToken"))
                        if (!$util.isString(message.sourceBoostCardProgramToken))
                            return "sourceBoostCardProgramToken: string expected";
                    if (message.destinationBankAccountToken != null && message.hasOwnProperty("destinationBankAccountToken"))
                        if (!$util.isString(message.destinationBankAccountToken))
                            return "destinationBankAccountToken: string expected";
                    if (message.sourceBankAccountToken != null && message.hasOwnProperty("sourceBankAccountToken"))
                        if (!$util.isString(message.sourceBankAccountToken))
                            return "sourceBankAccountToken: string expected";
                    if (message.transferAmount != null && message.hasOwnProperty("transferAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.transferAmount);
                        if (error)
                            return "transferAmount." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateBoostCardTransferRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateBoostCardTransferRequest} CreateBoostCardTransferRequest
                 */
                CreateBoostCardTransferRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateBoostCardTransferRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateBoostCardTransferRequest();
                    if (object.destinationBoostCardProgramToken != null)
                        message.destinationBoostCardProgramToken = String(object.destinationBoostCardProgramToken);
                    if (object.sourceBoostCardProgramToken != null)
                        message.sourceBoostCardProgramToken = String(object.sourceBoostCardProgramToken);
                    if (object.destinationBankAccountToken != null)
                        message.destinationBankAccountToken = String(object.destinationBankAccountToken);
                    if (object.sourceBankAccountToken != null)
                        message.sourceBankAccountToken = String(object.sourceBankAccountToken);
                    if (object.transferAmount != null) {
                        if (typeof object.transferAmount !== "object")
                            throw TypeError(".visor.clientrpc.CreateBoostCardTransferRequest.transferAmount: object expected");
                        message.transferAmount = $root.visor.common.money.Amount.fromObject(object.transferAmount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBoostCardTransferRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @static
                 * @param {visor.clientrpc.CreateBoostCardTransferRequest} message CreateBoostCardTransferRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBoostCardTransferRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.destinationBoostCardProgramToken = "";
                        object.sourceBoostCardProgramToken = "";
                        object.destinationBankAccountToken = "";
                        object.sourceBankAccountToken = "";
                        object.transferAmount = null;
                    }
                    if (message.destinationBoostCardProgramToken != null && message.hasOwnProperty("destinationBoostCardProgramToken"))
                        object.destinationBoostCardProgramToken = message.destinationBoostCardProgramToken;
                    if (message.sourceBoostCardProgramToken != null && message.hasOwnProperty("sourceBoostCardProgramToken"))
                        object.sourceBoostCardProgramToken = message.sourceBoostCardProgramToken;
                    if (message.destinationBankAccountToken != null && message.hasOwnProperty("destinationBankAccountToken"))
                        object.destinationBankAccountToken = message.destinationBankAccountToken;
                    if (message.sourceBankAccountToken != null && message.hasOwnProperty("sourceBankAccountToken"))
                        object.sourceBankAccountToken = message.sourceBankAccountToken;
                    if (message.transferAmount != null && message.hasOwnProperty("transferAmount"))
                        object.transferAmount = $root.visor.common.money.Amount.toObject(message.transferAmount, options);
                    return object;
                };

                /**
                 * Converts this CreateBoostCardTransferRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateBoostCardTransferRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBoostCardTransferRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBoostCardTransferRequest;
            })();

            clientrpc.CreateBoostCardTransferResponse = (function() {

                /**
                 * Properties of a CreateBoostCardTransferResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateBoostCardTransferResponse
                 */

                /**
                 * Constructs a new CreateBoostCardTransferResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateBoostCardTransferResponse.
                 * @implements ICreateBoostCardTransferResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateBoostCardTransferResponse=} [properties] Properties to set
                 */
                function CreateBoostCardTransferResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateBoostCardTransferResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardTransferResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateBoostCardTransferResponse} CreateBoostCardTransferResponse instance
                 */
                CreateBoostCardTransferResponse.create = function create(properties) {
                    return new CreateBoostCardTransferResponse(properties);
                };

                /**
                 * Encodes the specified CreateBoostCardTransferResponse message. Does not implicitly {@link visor.clientrpc.CreateBoostCardTransferResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardTransferResponse} message CreateBoostCardTransferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardTransferResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateBoostCardTransferResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBoostCardTransferResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBoostCardTransferResponse} message CreateBoostCardTransferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBoostCardTransferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateBoostCardTransferResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateBoostCardTransferResponse} CreateBoostCardTransferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardTransferResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBoostCardTransferResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateBoostCardTransferResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateBoostCardTransferResponse} CreateBoostCardTransferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBoostCardTransferResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateBoostCardTransferResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBoostCardTransferResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateBoostCardTransferResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateBoostCardTransferResponse} CreateBoostCardTransferResponse
                 */
                CreateBoostCardTransferResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateBoostCardTransferResponse)
                        return object;
                    return new $root.visor.clientrpc.CreateBoostCardTransferResponse();
                };

                /**
                 * Creates a plain object from a CreateBoostCardTransferResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @static
                 * @param {visor.clientrpc.CreateBoostCardTransferResponse} message CreateBoostCardTransferResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBoostCardTransferResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateBoostCardTransferResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateBoostCardTransferResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBoostCardTransferResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBoostCardTransferResponse;
            })();

            clientrpc.GetBoostCardCheckoutOfferRequest = (function() {

                /**
                 * Properties of a GetBoostCardCheckoutOfferRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetBoostCardCheckoutOfferRequest
                 */

                /**
                 * Constructs a new GetBoostCardCheckoutOfferRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBoostCardCheckoutOfferRequest.
                 * @implements IGetBoostCardCheckoutOfferRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferRequest=} [properties] Properties to set
                 */
                function GetBoostCardCheckoutOfferRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetBoostCardCheckoutOfferRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferRequest} GetBoostCardCheckoutOfferRequest instance
                 */
                GetBoostCardCheckoutOfferRequest.create = function create(properties) {
                    return new GetBoostCardCheckoutOfferRequest(properties);
                };

                /**
                 * Encodes the specified GetBoostCardCheckoutOfferRequest message. Does not implicitly {@link visor.clientrpc.GetBoostCardCheckoutOfferRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferRequest} message GetBoostCardCheckoutOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardCheckoutOfferRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetBoostCardCheckoutOfferRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetBoostCardCheckoutOfferRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferRequest} message GetBoostCardCheckoutOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardCheckoutOfferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBoostCardCheckoutOfferRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferRequest} GetBoostCardCheckoutOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardCheckoutOfferRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBoostCardCheckoutOfferRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBoostCardCheckoutOfferRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferRequest} GetBoostCardCheckoutOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardCheckoutOfferRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBoostCardCheckoutOfferRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBoostCardCheckoutOfferRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetBoostCardCheckoutOfferRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferRequest} GetBoostCardCheckoutOfferRequest
                 */
                GetBoostCardCheckoutOfferRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBoostCardCheckoutOfferRequest)
                        return object;
                    return new $root.visor.clientrpc.GetBoostCardCheckoutOfferRequest();
                };

                /**
                 * Creates a plain object from a GetBoostCardCheckoutOfferRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.GetBoostCardCheckoutOfferRequest} message GetBoostCardCheckoutOfferRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBoostCardCheckoutOfferRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetBoostCardCheckoutOfferRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBoostCardCheckoutOfferRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBoostCardCheckoutOfferRequest;
            })();

            clientrpc.GetBoostCardCheckoutOfferResponse = (function() {

                /**
                 * Properties of a GetBoostCardCheckoutOfferResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetBoostCardCheckoutOfferResponse
                 * @property {visor.clientrpc.CardStyle|null} [cardStyle] GetBoostCardCheckoutOfferResponse cardStyle
                 * @property {visor.clientrpc.Product|null} [product] GetBoostCardCheckoutOfferResponse product
                 * @property {visor.clientrpc.ISubscription|null} [existingSubscription] GetBoostCardCheckoutOfferResponse existingSubscription
                 * @property {visor.clientrpc.IAdvance|null} [advance] GetBoostCardCheckoutOfferResponse advance
                 * @property {visor.clientrpc.IAddress|null} [address] GetBoostCardCheckoutOfferResponse address
                 * @property {visor.clientrpc.ICard|null} [card] GetBoostCardCheckoutOfferResponse card
                 * @property {visor.clientrpc.IAddress|null} [mailingAddress] GetBoostCardCheckoutOfferResponse mailingAddress
                 * @property {visor.common.money.IAmount|null} [additionalCardFee] GetBoostCardCheckoutOfferResponse additionalCardFee
                 */

                /**
                 * Constructs a new GetBoostCardCheckoutOfferResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBoostCardCheckoutOfferResponse.
                 * @implements IGetBoostCardCheckoutOfferResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferResponse=} [properties] Properties to set
                 */
                function GetBoostCardCheckoutOfferResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBoostCardCheckoutOfferResponse cardStyle.
                 * @member {visor.clientrpc.CardStyle} cardStyle
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.cardStyle = 0;

                /**
                 * GetBoostCardCheckoutOfferResponse product.
                 * @member {visor.clientrpc.Product} product
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.product = 0;

                /**
                 * GetBoostCardCheckoutOfferResponse existingSubscription.
                 * @member {visor.clientrpc.ISubscription|null|undefined} existingSubscription
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.existingSubscription = null;

                /**
                 * GetBoostCardCheckoutOfferResponse advance.
                 * @member {visor.clientrpc.IAdvance|null|undefined} advance
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.advance = null;

                /**
                 * GetBoostCardCheckoutOfferResponse address.
                 * @member {visor.clientrpc.IAddress|null|undefined} address
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.address = null;

                /**
                 * GetBoostCardCheckoutOfferResponse card.
                 * @member {visor.clientrpc.ICard|null|undefined} card
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.card = null;

                /**
                 * GetBoostCardCheckoutOfferResponse mailingAddress.
                 * @member {visor.clientrpc.IAddress|null|undefined} mailingAddress
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.mailingAddress = null;

                /**
                 * GetBoostCardCheckoutOfferResponse additionalCardFee.
                 * @member {visor.common.money.IAmount|null|undefined} additionalCardFee
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 */
                GetBoostCardCheckoutOfferResponse.prototype.additionalCardFee = null;

                /**
                 * Creates a new GetBoostCardCheckoutOfferResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferResponse} GetBoostCardCheckoutOfferResponse instance
                 */
                GetBoostCardCheckoutOfferResponse.create = function create(properties) {
                    return new GetBoostCardCheckoutOfferResponse(properties);
                };

                /**
                 * Encodes the specified GetBoostCardCheckoutOfferResponse message. Does not implicitly {@link visor.clientrpc.GetBoostCardCheckoutOfferResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferResponse} message GetBoostCardCheckoutOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardCheckoutOfferResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cardStyle != null && message.hasOwnProperty("cardStyle"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.cardStyle);
                    if (message.product != null && message.hasOwnProperty("product"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.product);
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription"))
                        $root.visor.clientrpc.Subscription.encode(message.existingSubscription, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.advance != null && message.hasOwnProperty("advance"))
                        $root.visor.clientrpc.Advance.encode(message.advance, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.address != null && message.hasOwnProperty("address"))
                        $root.visor.clientrpc.Address.encode(message.address, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.card != null && message.hasOwnProperty("card"))
                        $root.visor.clientrpc.Card.encode(message.card, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                        $root.visor.clientrpc.Address.encode(message.mailingAddress, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.additionalCardFee != null && message.hasOwnProperty("additionalCardFee"))
                        $root.visor.common.money.Amount.encode(message.additionalCardFee, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetBoostCardCheckoutOfferResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetBoostCardCheckoutOfferResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetBoostCardCheckoutOfferResponse} message GetBoostCardCheckoutOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBoostCardCheckoutOfferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBoostCardCheckoutOfferResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferResponse} GetBoostCardCheckoutOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardCheckoutOfferResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBoostCardCheckoutOfferResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cardStyle = reader.int32();
                            break;
                        case 2:
                            message.product = reader.int32();
                            break;
                        case 3:
                            message.existingSubscription = $root.visor.clientrpc.Subscription.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.advance = $root.visor.clientrpc.Advance.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.address = $root.visor.clientrpc.Address.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.card = $root.visor.clientrpc.Card.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.mailingAddress = $root.visor.clientrpc.Address.decode(reader, reader.uint32());
                            break;
                        case 8:
                            message.additionalCardFee = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBoostCardCheckoutOfferResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferResponse} GetBoostCardCheckoutOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBoostCardCheckoutOfferResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBoostCardCheckoutOfferResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBoostCardCheckoutOfferResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cardStyle != null && message.hasOwnProperty("cardStyle"))
                        switch (message.cardStyle) {
                        default:
                            return "cardStyle: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.product != null && message.hasOwnProperty("product"))
                        switch (message.product) {
                        default:
                            return "product: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription")) {
                        var error = $root.visor.clientrpc.Subscription.verify(message.existingSubscription);
                        if (error)
                            return "existingSubscription." + error;
                    }
                    if (message.advance != null && message.hasOwnProperty("advance")) {
                        var error = $root.visor.clientrpc.Advance.verify(message.advance);
                        if (error)
                            return "advance." + error;
                    }
                    if (message.address != null && message.hasOwnProperty("address")) {
                        var error = $root.visor.clientrpc.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    if (message.card != null && message.hasOwnProperty("card")) {
                        var error = $root.visor.clientrpc.Card.verify(message.card);
                        if (error)
                            return "card." + error;
                    }
                    if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress")) {
                        var error = $root.visor.clientrpc.Address.verify(message.mailingAddress);
                        if (error)
                            return "mailingAddress." + error;
                    }
                    if (message.additionalCardFee != null && message.hasOwnProperty("additionalCardFee")) {
                        var error = $root.visor.common.money.Amount.verify(message.additionalCardFee);
                        if (error)
                            return "additionalCardFee." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetBoostCardCheckoutOfferResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBoostCardCheckoutOfferResponse} GetBoostCardCheckoutOfferResponse
                 */
                GetBoostCardCheckoutOfferResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBoostCardCheckoutOfferResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetBoostCardCheckoutOfferResponse();
                    switch (object.cardStyle) {
                    case "CARD_STYLE_INVALID":
                    case 0:
                        message.cardStyle = 0;
                        break;
                    case "CARD_STYLE_IVORY_PLASTIC":
                    case 1:
                        message.cardStyle = 1;
                        break;
                    }
                    switch (object.product) {
                    case "PRODUCT_INVALID":
                    case 0:
                        message.product = 0;
                        break;
                    case "PRODUCT_10_MONTHLY":
                    case 1:
                        message.product = 1;
                        break;
                    }
                    if (object.existingSubscription != null) {
                        if (typeof object.existingSubscription !== "object")
                            throw TypeError(".visor.clientrpc.GetBoostCardCheckoutOfferResponse.existingSubscription: object expected");
                        message.existingSubscription = $root.visor.clientrpc.Subscription.fromObject(object.existingSubscription);
                    }
                    if (object.advance != null) {
                        if (typeof object.advance !== "object")
                            throw TypeError(".visor.clientrpc.GetBoostCardCheckoutOfferResponse.advance: object expected");
                        message.advance = $root.visor.clientrpc.Advance.fromObject(object.advance);
                    }
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".visor.clientrpc.GetBoostCardCheckoutOfferResponse.address: object expected");
                        message.address = $root.visor.clientrpc.Address.fromObject(object.address);
                    }
                    if (object.card != null) {
                        if (typeof object.card !== "object")
                            throw TypeError(".visor.clientrpc.GetBoostCardCheckoutOfferResponse.card: object expected");
                        message.card = $root.visor.clientrpc.Card.fromObject(object.card);
                    }
                    if (object.mailingAddress != null) {
                        if (typeof object.mailingAddress !== "object")
                            throw TypeError(".visor.clientrpc.GetBoostCardCheckoutOfferResponse.mailingAddress: object expected");
                        message.mailingAddress = $root.visor.clientrpc.Address.fromObject(object.mailingAddress);
                    }
                    if (object.additionalCardFee != null) {
                        if (typeof object.additionalCardFee !== "object")
                            throw TypeError(".visor.clientrpc.GetBoostCardCheckoutOfferResponse.additionalCardFee: object expected");
                        message.additionalCardFee = $root.visor.common.money.Amount.fromObject(object.additionalCardFee);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetBoostCardCheckoutOfferResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.GetBoostCardCheckoutOfferResponse} message GetBoostCardCheckoutOfferResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBoostCardCheckoutOfferResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cardStyle = options.enums === String ? "CARD_STYLE_INVALID" : 0;
                        object.product = options.enums === String ? "PRODUCT_INVALID" : 0;
                        object.existingSubscription = null;
                        object.advance = null;
                        object.address = null;
                        object.card = null;
                        object.mailingAddress = null;
                        object.additionalCardFee = null;
                    }
                    if (message.cardStyle != null && message.hasOwnProperty("cardStyle"))
                        object.cardStyle = options.enums === String ? $root.visor.clientrpc.CardStyle[message.cardStyle] : message.cardStyle;
                    if (message.product != null && message.hasOwnProperty("product"))
                        object.product = options.enums === String ? $root.visor.clientrpc.Product[message.product] : message.product;
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription"))
                        object.existingSubscription = $root.visor.clientrpc.Subscription.toObject(message.existingSubscription, options);
                    if (message.advance != null && message.hasOwnProperty("advance"))
                        object.advance = $root.visor.clientrpc.Advance.toObject(message.advance, options);
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.visor.clientrpc.Address.toObject(message.address, options);
                    if (message.card != null && message.hasOwnProperty("card"))
                        object.card = $root.visor.clientrpc.Card.toObject(message.card, options);
                    if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                        object.mailingAddress = $root.visor.clientrpc.Address.toObject(message.mailingAddress, options);
                    if (message.additionalCardFee != null && message.hasOwnProperty("additionalCardFee"))
                        object.additionalCardFee = $root.visor.common.money.Amount.toObject(message.additionalCardFee, options);
                    return object;
                };

                /**
                 * Converts this GetBoostCardCheckoutOfferResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBoostCardCheckoutOfferResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBoostCardCheckoutOfferResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBoostCardCheckoutOfferResponse;
            })();

            clientrpc.Address = (function() {

                /**
                 * Properties of an Address.
                 * @memberof visor.clientrpc
                 * @interface IAddress
                 * @property {string|null} [addressLine_1] Address addressLine_1
                 * @property {string|null} [addressLine_2] Address addressLine_2
                 * @property {string|null} [city] Address city
                 * @property {string|null} [state] Address state
                 * @property {string|null} [zip] Address zip
                 */

                /**
                 * Constructs a new Address.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an Address.
                 * @implements IAddress
                 * @constructor
                 * @param {visor.clientrpc.IAddress=} [properties] Properties to set
                 */
                function Address(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Address addressLine_1.
                 * @member {string} addressLine_1
                 * @memberof visor.clientrpc.Address
                 * @instance
                 */
                Address.prototype.addressLine_1 = "";

                /**
                 * Address addressLine_2.
                 * @member {string} addressLine_2
                 * @memberof visor.clientrpc.Address
                 * @instance
                 */
                Address.prototype.addressLine_2 = "";

                /**
                 * Address city.
                 * @member {string} city
                 * @memberof visor.clientrpc.Address
                 * @instance
                 */
                Address.prototype.city = "";

                /**
                 * Address state.
                 * @member {string} state
                 * @memberof visor.clientrpc.Address
                 * @instance
                 */
                Address.prototype.state = "";

                /**
                 * Address zip.
                 * @member {string} zip
                 * @memberof visor.clientrpc.Address
                 * @instance
                 */
                Address.prototype.zip = "";

                /**
                 * Creates a new Address instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {visor.clientrpc.IAddress=} [properties] Properties to set
                 * @returns {visor.clientrpc.Address} Address instance
                 */
                Address.create = function create(properties) {
                    return new Address(properties);
                };

                /**
                 * Encodes the specified Address message. Does not implicitly {@link visor.clientrpc.Address.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {visor.clientrpc.IAddress} message Address message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Address.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.addressLine_1 != null && message.hasOwnProperty("addressLine_1"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.addressLine_1);
                    if (message.addressLine_2 != null && message.hasOwnProperty("addressLine_2"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.addressLine_2);
                    if (message.city != null && message.hasOwnProperty("city"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.city);
                    if (message.state != null && message.hasOwnProperty("state"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.state);
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.zip);
                    return writer;
                };

                /**
                 * Encodes the specified Address message, length delimited. Does not implicitly {@link visor.clientrpc.Address.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {visor.clientrpc.IAddress} message Address message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Address.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Address message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Address} Address
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Address.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Address();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.addressLine_1 = reader.string();
                            break;
                        case 2:
                            message.addressLine_2 = reader.string();
                            break;
                        case 3:
                            message.city = reader.string();
                            break;
                        case 4:
                            message.state = reader.string();
                            break;
                        case 5:
                            message.zip = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Address message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Address} Address
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Address.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Address message.
                 * @function verify
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Address.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.addressLine_1 != null && message.hasOwnProperty("addressLine_1"))
                        if (!$util.isString(message.addressLine_1))
                            return "addressLine_1: string expected";
                    if (message.addressLine_2 != null && message.hasOwnProperty("addressLine_2"))
                        if (!$util.isString(message.addressLine_2))
                            return "addressLine_2: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        if (!$util.isString(message.zip))
                            return "zip: string expected";
                    return null;
                };

                /**
                 * Creates an Address message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Address} Address
                 */
                Address.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Address)
                        return object;
                    var message = new $root.visor.clientrpc.Address();
                    if (object.addressLine_1 != null)
                        message.addressLine_1 = String(object.addressLine_1);
                    if (object.addressLine_2 != null)
                        message.addressLine_2 = String(object.addressLine_2);
                    if (object.city != null)
                        message.city = String(object.city);
                    if (object.state != null)
                        message.state = String(object.state);
                    if (object.zip != null)
                        message.zip = String(object.zip);
                    return message;
                };

                /**
                 * Creates a plain object from an Address message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Address
                 * @static
                 * @param {visor.clientrpc.Address} message Address
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Address.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.addressLine_1 = "";
                        object.addressLine_2 = "";
                        object.city = "";
                        object.state = "";
                        object.zip = "";
                    }
                    if (message.addressLine_1 != null && message.hasOwnProperty("addressLine_1"))
                        object.addressLine_1 = message.addressLine_1;
                    if (message.addressLine_2 != null && message.hasOwnProperty("addressLine_2"))
                        object.addressLine_2 = message.addressLine_2;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        object.zip = message.zip;
                    return object;
                };

                /**
                 * Converts this Address to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Address
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Address.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Address;
            })();

            /**
             * CardStyle enum.
             * @name visor.clientrpc.CardStyle
             * @enum {string}
             * @property {number} CARD_STYLE_INVALID=0 CARD_STYLE_INVALID value
             * @property {number} CARD_STYLE_IVORY_PLASTIC=1 CARD_STYLE_IVORY_PLASTIC value
             */
            clientrpc.CardStyle = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CARD_STYLE_INVALID"] = 0;
                values[valuesById[1] = "CARD_STYLE_IVORY_PLASTIC"] = 1;
                return values;
            })();

            /**
             * Product enum.
             * @name visor.clientrpc.Product
             * @enum {string}
             * @property {number} PRODUCT_INVALID=0 PRODUCT_INVALID value
             * @property {number} PRODUCT_10_MONTHLY=1 PRODUCT_10_MONTHLY value
             */
            clientrpc.Product = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PRODUCT_INVALID"] = 0;
                values[valuesById[1] = "PRODUCT_10_MONTHLY"] = 1;
                return values;
            })();

            clientrpc.Subscription = (function() {

                /**
                 * Properties of a Subscription.
                 * @memberof visor.clientrpc
                 * @interface ISubscription
                 * @property {string|null} [token] Subscription token
                 * @property {visor.common.money.IAmount|null} [outstandingAmount] Subscription outstandingAmount
                 * @property {visor.clientrpc.Subscription.State|null} [state] Subscription state
                 */

                /**
                 * Constructs a new Subscription.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Subscription.
                 * @implements ISubscription
                 * @constructor
                 * @param {visor.clientrpc.ISubscription=} [properties] Properties to set
                 */
                function Subscription(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Subscription token.
                 * @member {string} token
                 * @memberof visor.clientrpc.Subscription
                 * @instance
                 */
                Subscription.prototype.token = "";

                /**
                 * Subscription outstandingAmount.
                 * @member {visor.common.money.IAmount|null|undefined} outstandingAmount
                 * @memberof visor.clientrpc.Subscription
                 * @instance
                 */
                Subscription.prototype.outstandingAmount = null;

                /**
                 * Subscription state.
                 * @member {visor.clientrpc.Subscription.State} state
                 * @memberof visor.clientrpc.Subscription
                 * @instance
                 */
                Subscription.prototype.state = 0;

                /**
                 * Creates a new Subscription instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {visor.clientrpc.ISubscription=} [properties] Properties to set
                 * @returns {visor.clientrpc.Subscription} Subscription instance
                 */
                Subscription.create = function create(properties) {
                    return new Subscription(properties);
                };

                /**
                 * Encodes the specified Subscription message. Does not implicitly {@link visor.clientrpc.Subscription.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {visor.clientrpc.ISubscription} message Subscription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Subscription.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                    if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount"))
                        $root.visor.common.money.Amount.encode(message.outstandingAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.state != null && message.hasOwnProperty("state"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
                    return writer;
                };

                /**
                 * Encodes the specified Subscription message, length delimited. Does not implicitly {@link visor.clientrpc.Subscription.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {visor.clientrpc.ISubscription} message Subscription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Subscription.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Subscription message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Subscription} Subscription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Subscription.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Subscription();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.token = reader.string();
                            break;
                        case 2:
                            message.outstandingAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.state = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Subscription message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Subscription} Subscription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Subscription.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Subscription message.
                 * @function verify
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Subscription.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.outstandingAmount);
                        if (error)
                            return "outstandingAmount." + error;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a Subscription message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Subscription} Subscription
                 */
                Subscription.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Subscription)
                        return object;
                    var message = new $root.visor.clientrpc.Subscription();
                    if (object.token != null)
                        message.token = String(object.token);
                    if (object.outstandingAmount != null) {
                        if (typeof object.outstandingAmount !== "object")
                            throw TypeError(".visor.clientrpc.Subscription.outstandingAmount: object expected");
                        message.outstandingAmount = $root.visor.common.money.Amount.fromObject(object.outstandingAmount);
                    }
                    switch (object.state) {
                    case "SUBSCRIPTION_STATE_INVALID":
                    case 0:
                        message.state = 0;
                        break;
                    case "SUBSCRIPTION_STATE_ACTIVE":
                    case 1:
                        message.state = 1;
                        break;
                    case "SUBSCRIPTION_STATE_OVERDUE":
                    case 2:
                        message.state = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Subscription message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Subscription
                 * @static
                 * @param {visor.clientrpc.Subscription} message Subscription
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Subscription.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.token = "";
                        object.outstandingAmount = null;
                        object.state = options.enums === String ? "SUBSCRIPTION_STATE_INVALID" : 0;
                    }
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount"))
                        object.outstandingAmount = $root.visor.common.money.Amount.toObject(message.outstandingAmount, options);
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.visor.clientrpc.Subscription.State[message.state] : message.state;
                    return object;
                };

                /**
                 * Converts this Subscription to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Subscription
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Subscription.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * State enum.
                 * @name visor.clientrpc.Subscription.State
                 * @enum {string}
                 * @property {number} SUBSCRIPTION_STATE_INVALID=0 SUBSCRIPTION_STATE_INVALID value
                 * @property {number} SUBSCRIPTION_STATE_ACTIVE=1 SUBSCRIPTION_STATE_ACTIVE value
                 * @property {number} SUBSCRIPTION_STATE_OVERDUE=2 SUBSCRIPTION_STATE_OVERDUE value
                 */
                Subscription.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SUBSCRIPTION_STATE_INVALID"] = 0;
                    values[valuesById[1] = "SUBSCRIPTION_STATE_ACTIVE"] = 1;
                    values[valuesById[2] = "SUBSCRIPTION_STATE_OVERDUE"] = 2;
                    return values;
                })();

                return Subscription;
            })();

            clientrpc.Advance = (function() {

                /**
                 * Properties of an Advance.
                 * @memberof visor.clientrpc
                 * @interface IAdvance
                 * @property {string|null} [token] Advance token
                 * @property {visor.common.money.IAmount|null} [outstandingAmount] Advance outstandingAmount
                 */

                /**
                 * Constructs a new Advance.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an Advance.
                 * @implements IAdvance
                 * @constructor
                 * @param {visor.clientrpc.IAdvance=} [properties] Properties to set
                 */
                function Advance(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Advance token.
                 * @member {string} token
                 * @memberof visor.clientrpc.Advance
                 * @instance
                 */
                Advance.prototype.token = "";

                /**
                 * Advance outstandingAmount.
                 * @member {visor.common.money.IAmount|null|undefined} outstandingAmount
                 * @memberof visor.clientrpc.Advance
                 * @instance
                 */
                Advance.prototype.outstandingAmount = null;

                /**
                 * Creates a new Advance instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {visor.clientrpc.IAdvance=} [properties] Properties to set
                 * @returns {visor.clientrpc.Advance} Advance instance
                 */
                Advance.create = function create(properties) {
                    return new Advance(properties);
                };

                /**
                 * Encodes the specified Advance message. Does not implicitly {@link visor.clientrpc.Advance.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {visor.clientrpc.IAdvance} message Advance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Advance.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                    if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount"))
                        $root.visor.common.money.Amount.encode(message.outstandingAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Advance message, length delimited. Does not implicitly {@link visor.clientrpc.Advance.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {visor.clientrpc.IAdvance} message Advance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Advance.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Advance message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Advance} Advance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Advance.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Advance();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.token = reader.string();
                            break;
                        case 2:
                            message.outstandingAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Advance message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Advance} Advance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Advance.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Advance message.
                 * @function verify
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Advance.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.outstandingAmount);
                        if (error)
                            return "outstandingAmount." + error;
                    }
                    return null;
                };

                /**
                 * Creates an Advance message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Advance} Advance
                 */
                Advance.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Advance)
                        return object;
                    var message = new $root.visor.clientrpc.Advance();
                    if (object.token != null)
                        message.token = String(object.token);
                    if (object.outstandingAmount != null) {
                        if (typeof object.outstandingAmount !== "object")
                            throw TypeError(".visor.clientrpc.Advance.outstandingAmount: object expected");
                        message.outstandingAmount = $root.visor.common.money.Amount.fromObject(object.outstandingAmount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Advance message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Advance
                 * @static
                 * @param {visor.clientrpc.Advance} message Advance
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Advance.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.token = "";
                        object.outstandingAmount = null;
                    }
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount"))
                        object.outstandingAmount = $root.visor.common.money.Amount.toObject(message.outstandingAmount, options);
                    return object;
                };

                /**
                 * Converts this Advance to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Advance
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Advance.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Advance;
            })();

            clientrpc.GetBankingDashboardRequest = (function() {

                /**
                 * Properties of a GetBankingDashboardRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetBankingDashboardRequest
                 * @property {boolean|null} [returnTestFixtures] GetBankingDashboardRequest returnTestFixtures
                 * @property {google.protobuf.ITimestamp|null} [oldestTransactionPendingTimestamp] GetBankingDashboardRequest oldestTransactionPendingTimestamp
                 * @property {number|null} [offset] GetBankingDashboardRequest offset
                 * @property {string|null} [creditLineTestFixture] GetBankingDashboardRequest creditLineTestFixture
                 * @property {string|null} [rewardsLevelTestFixture] GetBankingDashboardRequest rewardsLevelTestFixture
                 */

                /**
                 * Constructs a new GetBankingDashboardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBankingDashboardRequest.
                 * @implements IGetBankingDashboardRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetBankingDashboardRequest=} [properties] Properties to set
                 */
                function GetBankingDashboardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBankingDashboardRequest returnTestFixtures.
                 * @member {boolean} returnTestFixtures
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @instance
                 */
                GetBankingDashboardRequest.prototype.returnTestFixtures = false;

                /**
                 * GetBankingDashboardRequest oldestTransactionPendingTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} oldestTransactionPendingTimestamp
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @instance
                 */
                GetBankingDashboardRequest.prototype.oldestTransactionPendingTimestamp = null;

                /**
                 * GetBankingDashboardRequest offset.
                 * @member {number} offset
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @instance
                 */
                GetBankingDashboardRequest.prototype.offset = 0;

                /**
                 * GetBankingDashboardRequest creditLineTestFixture.
                 * @member {string} creditLineTestFixture
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @instance
                 */
                GetBankingDashboardRequest.prototype.creditLineTestFixture = "";

                /**
                 * GetBankingDashboardRequest rewardsLevelTestFixture.
                 * @member {string} rewardsLevelTestFixture
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @instance
                 */
                GetBankingDashboardRequest.prototype.rewardsLevelTestFixture = "";

                /**
                 * Creates a new GetBankingDashboardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankingDashboardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBankingDashboardRequest} GetBankingDashboardRequest instance
                 */
                GetBankingDashboardRequest.create = function create(properties) {
                    return new GetBankingDashboardRequest(properties);
                };

                /**
                 * Encodes the specified GetBankingDashboardRequest message. Does not implicitly {@link visor.clientrpc.GetBankingDashboardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankingDashboardRequest} message GetBankingDashboardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDashboardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.returnTestFixtures);
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.oldestTransactionPendingTimestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
                    if (message.creditLineTestFixture != null && message.hasOwnProperty("creditLineTestFixture"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.creditLineTestFixture);
                    if (message.rewardsLevelTestFixture != null && message.hasOwnProperty("rewardsLevelTestFixture"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.rewardsLevelTestFixture);
                    return writer;
                };

                /**
                 * Encodes the specified GetBankingDashboardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetBankingDashboardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankingDashboardRequest} message GetBankingDashboardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDashboardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBankingDashboardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBankingDashboardRequest} GetBankingDashboardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDashboardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBankingDashboardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.returnTestFixtures = reader.bool();
                            break;
                        case 2:
                            message.oldestTransactionPendingTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.offset = reader.int32();
                            break;
                        case 4:
                            message.creditLineTestFixture = reader.string();
                            break;
                        case 5:
                            message.rewardsLevelTestFixture = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBankingDashboardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBankingDashboardRequest} GetBankingDashboardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDashboardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBankingDashboardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBankingDashboardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        if (typeof message.returnTestFixtures !== "boolean")
                            return "returnTestFixtures: boolean expected";
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.oldestTransactionPendingTimestamp);
                        if (error)
                            return "oldestTransactionPendingTimestamp." + error;
                    }
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.creditLineTestFixture != null && message.hasOwnProperty("creditLineTestFixture"))
                        if (!$util.isString(message.creditLineTestFixture))
                            return "creditLineTestFixture: string expected";
                    if (message.rewardsLevelTestFixture != null && message.hasOwnProperty("rewardsLevelTestFixture"))
                        if (!$util.isString(message.rewardsLevelTestFixture))
                            return "rewardsLevelTestFixture: string expected";
                    return null;
                };

                /**
                 * Creates a GetBankingDashboardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBankingDashboardRequest} GetBankingDashboardRequest
                 */
                GetBankingDashboardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBankingDashboardRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetBankingDashboardRequest();
                    if (object.returnTestFixtures != null)
                        message.returnTestFixtures = Boolean(object.returnTestFixtures);
                    if (object.oldestTransactionPendingTimestamp != null)
                        if (object.oldestTransactionPendingTimestamp === null)
                            message.oldestTransactionPendingTimestamp = {};
                        else
                            message.oldestTransactionPendingTimestamp = {
                                seconds: Math.floor(object.oldestTransactionPendingTimestamp.getTime() / 1000),
                                nanos: object.oldestTransactionPendingTimestamp.getMilliseconds() * 1000000
                            };
                    if (object.offset != null)
                        message.offset = object.offset | 0;
                    if (object.creditLineTestFixture != null)
                        message.creditLineTestFixture = String(object.creditLineTestFixture);
                    if (object.rewardsLevelTestFixture != null)
                        message.rewardsLevelTestFixture = String(object.rewardsLevelTestFixture);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBankingDashboardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @static
                 * @param {visor.clientrpc.GetBankingDashboardRequest} message GetBankingDashboardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBankingDashboardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.returnTestFixtures = false;
                        object.oldestTransactionPendingTimestamp = null;
                        object.offset = 0;
                        object.creditLineTestFixture = "";
                        object.rewardsLevelTestFixture = "";
                    }
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        object.returnTestFixtures = message.returnTestFixtures;
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp"))
                        object.oldestTransactionPendingTimestamp = new Date(message.oldestTransactionPendingTimestamp.seconds * 1000 + message.oldestTransactionPendingTimestamp.nanos / 1000000);
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.creditLineTestFixture != null && message.hasOwnProperty("creditLineTestFixture"))
                        object.creditLineTestFixture = message.creditLineTestFixture;
                    if (message.rewardsLevelTestFixture != null && message.hasOwnProperty("rewardsLevelTestFixture"))
                        object.rewardsLevelTestFixture = message.rewardsLevelTestFixture;
                    return object;
                };

                /**
                 * Converts this GetBankingDashboardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBankingDashboardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBankingDashboardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBankingDashboardRequest;
            })();

            clientrpc.GetBankingDashboardResponse = (function() {

                /**
                 * Properties of a GetBankingDashboardResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetBankingDashboardResponse
                 * @property {visor.common.money.IAmount|null} [availableFunds] GetBankingDashboardResponse availableFunds
                 * @property {visor.common.money.IAmount|null} [availableCash] GetBankingDashboardResponse availableCash
                 * @property {Array.<visor.clientrpc.IBoostTransaction>|null} [transactions] GetBankingDashboardResponse transactions
                 * @property {google.protobuf.ITimestamp|null} [oldestTransactionPendingTimestamp] GetBankingDashboardResponse oldestTransactionPendingTimestamp
                 * @property {google.protobuf.ITimestamp|null} [dataAsOfTimestamp] GetBankingDashboardResponse dataAsOfTimestamp
                 * @property {visor.clientrpc.IRewardsBalance|null} [rewardsBalance] GetBankingDashboardResponse rewardsBalance
                 * @property {number|null} [txCount] GetBankingDashboardResponse txCount
                 * @property {visor.clientrpc.GetBankingDashboardResponse.Result|null} [dashboardResult] GetBankingDashboardResponse dashboardResult
                 * @property {number|null} [monthsWithDirectDeposit] GetBankingDashboardResponse monthsWithDirectDeposit
                 * @property {visor.clientrpc.GetBankingDashboardResponse.FundBcpPromo|null} [fundBcpPromo] GetBankingDashboardResponse fundBcpPromo
                 * @property {visor.clientrpc.ICreditLineSummary|null} [creditLine] GetBankingDashboardResponse creditLine
                 * @property {visor.clientrpc.GetBankingDashboardResponse.RewardsLevel|null} [rewardsLevel] GetBankingDashboardResponse rewardsLevel
                 * @property {visor.clientrpc.GetBankingDashboardResponse.DataSource|null} [dataSource] GetBankingDashboardResponse dataSource
                 */

                /**
                 * Constructs a new GetBankingDashboardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBankingDashboardResponse.
                 * @implements IGetBankingDashboardResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetBankingDashboardResponse=} [properties] Properties to set
                 */
                function GetBankingDashboardResponse(properties) {
                    this.transactions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBankingDashboardResponse availableFunds.
                 * @member {visor.common.money.IAmount|null|undefined} availableFunds
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.availableFunds = null;

                /**
                 * GetBankingDashboardResponse availableCash.
                 * @member {visor.common.money.IAmount|null|undefined} availableCash
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.availableCash = null;

                /**
                 * GetBankingDashboardResponse transactions.
                 * @member {Array.<visor.clientrpc.IBoostTransaction>} transactions
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.transactions = $util.emptyArray;

                /**
                 * GetBankingDashboardResponse oldestTransactionPendingTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} oldestTransactionPendingTimestamp
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.oldestTransactionPendingTimestamp = null;

                /**
                 * GetBankingDashboardResponse dataAsOfTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} dataAsOfTimestamp
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.dataAsOfTimestamp = null;

                /**
                 * GetBankingDashboardResponse rewardsBalance.
                 * @member {visor.clientrpc.IRewardsBalance|null|undefined} rewardsBalance
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.rewardsBalance = null;

                /**
                 * GetBankingDashboardResponse txCount.
                 * @member {number} txCount
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.txCount = 0;

                /**
                 * GetBankingDashboardResponse dashboardResult.
                 * @member {visor.clientrpc.GetBankingDashboardResponse.Result} dashboardResult
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.dashboardResult = 0;

                /**
                 * GetBankingDashboardResponse monthsWithDirectDeposit.
                 * @member {number} monthsWithDirectDeposit
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.monthsWithDirectDeposit = 0;

                /**
                 * GetBankingDashboardResponse fundBcpPromo.
                 * @member {visor.clientrpc.GetBankingDashboardResponse.FundBcpPromo} fundBcpPromo
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.fundBcpPromo = 0;

                /**
                 * GetBankingDashboardResponse creditLine.
                 * @member {visor.clientrpc.ICreditLineSummary|null|undefined} creditLine
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.creditLine = null;

                /**
                 * GetBankingDashboardResponse rewardsLevel.
                 * @member {visor.clientrpc.GetBankingDashboardResponse.RewardsLevel} rewardsLevel
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.rewardsLevel = 0;

                /**
                 * GetBankingDashboardResponse dataSource.
                 * @member {visor.clientrpc.GetBankingDashboardResponse.DataSource} dataSource
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 */
                GetBankingDashboardResponse.prototype.dataSource = 0;

                /**
                 * Creates a new GetBankingDashboardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankingDashboardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBankingDashboardResponse} GetBankingDashboardResponse instance
                 */
                GetBankingDashboardResponse.create = function create(properties) {
                    return new GetBankingDashboardResponse(properties);
                };

                /**
                 * Encodes the specified GetBankingDashboardResponse message. Does not implicitly {@link visor.clientrpc.GetBankingDashboardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankingDashboardResponse} message GetBankingDashboardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDashboardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.availableFunds != null && message.hasOwnProperty("availableFunds"))
                        $root.visor.common.money.Amount.encode(message.availableFunds, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.transactions != null && message.transactions.length)
                        for (var i = 0; i < message.transactions.length; ++i)
                            $root.visor.clientrpc.BoostTransaction.encode(message.transactions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.oldestTransactionPendingTimestamp, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.dataAsOfTimestamp != null && message.hasOwnProperty("dataAsOfTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.dataAsOfTimestamp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.rewardsBalance != null && message.hasOwnProperty("rewardsBalance"))
                        $root.visor.clientrpc.RewardsBalance.encode(message.rewardsBalance, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.txCount != null && message.hasOwnProperty("txCount"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.txCount);
                    if (message.dashboardResult != null && message.hasOwnProperty("dashboardResult"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.dashboardResult);
                    if (message.monthsWithDirectDeposit != null && message.hasOwnProperty("monthsWithDirectDeposit"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.monthsWithDirectDeposit);
                    if (message.fundBcpPromo != null && message.hasOwnProperty("fundBcpPromo"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.fundBcpPromo);
                    if (message.creditLine != null && message.hasOwnProperty("creditLine"))
                        $root.visor.clientrpc.CreditLineSummary.encode(message.creditLine, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.availableCash != null && message.hasOwnProperty("availableCash"))
                        $root.visor.common.money.Amount.encode(message.availableCash, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.rewardsLevel != null && message.hasOwnProperty("rewardsLevel"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.rewardsLevel);
                    if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int32(message.dataSource);
                    return writer;
                };

                /**
                 * Encodes the specified GetBankingDashboardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetBankingDashboardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankingDashboardResponse} message GetBankingDashboardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankingDashboardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBankingDashboardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBankingDashboardResponse} GetBankingDashboardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDashboardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBankingDashboardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.availableFunds = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 11:
                            message.availableCash = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 2:
                            if (!(message.transactions && message.transactions.length))
                                message.transactions = [];
                            message.transactions.push($root.visor.clientrpc.BoostTransaction.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            message.oldestTransactionPendingTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.dataAsOfTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.rewardsBalance = $root.visor.clientrpc.RewardsBalance.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.txCount = reader.int32();
                            break;
                        case 7:
                            message.dashboardResult = reader.int32();
                            break;
                        case 8:
                            message.monthsWithDirectDeposit = reader.int32();
                            break;
                        case 9:
                            message.fundBcpPromo = reader.int32();
                            break;
                        case 10:
                            message.creditLine = $root.visor.clientrpc.CreditLineSummary.decode(reader, reader.uint32());
                            break;
                        case 12:
                            message.rewardsLevel = reader.int32();
                            break;
                        case 13:
                            message.dataSource = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBankingDashboardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBankingDashboardResponse} GetBankingDashboardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankingDashboardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBankingDashboardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBankingDashboardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.availableFunds != null && message.hasOwnProperty("availableFunds")) {
                        var error = $root.visor.common.money.Amount.verify(message.availableFunds);
                        if (error)
                            return "availableFunds." + error;
                    }
                    if (message.availableCash != null && message.hasOwnProperty("availableCash")) {
                        var error = $root.visor.common.money.Amount.verify(message.availableCash);
                        if (error)
                            return "availableCash." + error;
                    }
                    if (message.transactions != null && message.hasOwnProperty("transactions")) {
                        if (!Array.isArray(message.transactions))
                            return "transactions: array expected";
                        for (var i = 0; i < message.transactions.length; ++i) {
                            var error = $root.visor.clientrpc.BoostTransaction.verify(message.transactions[i]);
                            if (error)
                                return "transactions." + error;
                        }
                    }
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.oldestTransactionPendingTimestamp);
                        if (error)
                            return "oldestTransactionPendingTimestamp." + error;
                    }
                    if (message.dataAsOfTimestamp != null && message.hasOwnProperty("dataAsOfTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.dataAsOfTimestamp);
                        if (error)
                            return "dataAsOfTimestamp." + error;
                    }
                    if (message.rewardsBalance != null && message.hasOwnProperty("rewardsBalance")) {
                        var error = $root.visor.clientrpc.RewardsBalance.verify(message.rewardsBalance);
                        if (error)
                            return "rewardsBalance." + error;
                    }
                    if (message.txCount != null && message.hasOwnProperty("txCount"))
                        if (!$util.isInteger(message.txCount))
                            return "txCount: integer expected";
                    if (message.dashboardResult != null && message.hasOwnProperty("dashboardResult"))
                        switch (message.dashboardResult) {
                        default:
                            return "dashboardResult: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.monthsWithDirectDeposit != null && message.hasOwnProperty("monthsWithDirectDeposit"))
                        if (!$util.isInteger(message.monthsWithDirectDeposit))
                            return "monthsWithDirectDeposit: integer expected";
                    if (message.fundBcpPromo != null && message.hasOwnProperty("fundBcpPromo"))
                        switch (message.fundBcpPromo) {
                        default:
                            return "fundBcpPromo: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.creditLine != null && message.hasOwnProperty("creditLine")) {
                        var error = $root.visor.clientrpc.CreditLineSummary.verify(message.creditLine);
                        if (error)
                            return "creditLine." + error;
                    }
                    if (message.rewardsLevel != null && message.hasOwnProperty("rewardsLevel"))
                        switch (message.rewardsLevel) {
                        default:
                            return "rewardsLevel: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                        switch (message.dataSource) {
                        default:
                            return "dataSource: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetBankingDashboardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBankingDashboardResponse} GetBankingDashboardResponse
                 */
                GetBankingDashboardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBankingDashboardResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetBankingDashboardResponse();
                    if (object.availableFunds != null) {
                        if (typeof object.availableFunds !== "object")
                            throw TypeError(".visor.clientrpc.GetBankingDashboardResponse.availableFunds: object expected");
                        message.availableFunds = $root.visor.common.money.Amount.fromObject(object.availableFunds);
                    }
                    if (object.availableCash != null) {
                        if (typeof object.availableCash !== "object")
                            throw TypeError(".visor.clientrpc.GetBankingDashboardResponse.availableCash: object expected");
                        message.availableCash = $root.visor.common.money.Amount.fromObject(object.availableCash);
                    }
                    if (object.transactions) {
                        if (!Array.isArray(object.transactions))
                            throw TypeError(".visor.clientrpc.GetBankingDashboardResponse.transactions: array expected");
                        message.transactions = [];
                        for (var i = 0; i < object.transactions.length; ++i) {
                            if (typeof object.transactions[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetBankingDashboardResponse.transactions: object expected");
                            message.transactions[i] = $root.visor.clientrpc.BoostTransaction.fromObject(object.transactions[i]);
                        }
                    }
                    if (object.oldestTransactionPendingTimestamp != null)
                        if (object.oldestTransactionPendingTimestamp === null)
                            message.oldestTransactionPendingTimestamp = {};
                        else
                            message.oldestTransactionPendingTimestamp = {
                                seconds: Math.floor(object.oldestTransactionPendingTimestamp.getTime() / 1000),
                                nanos: object.oldestTransactionPendingTimestamp.getMilliseconds() * 1000000
                            };
                    if (object.dataAsOfTimestamp != null)
                        if (object.dataAsOfTimestamp === null)
                            message.dataAsOfTimestamp = {};
                        else
                            message.dataAsOfTimestamp = {
                                seconds: Math.floor(object.dataAsOfTimestamp.getTime() / 1000),
                                nanos: object.dataAsOfTimestamp.getMilliseconds() * 1000000
                            };
                    if (object.rewardsBalance != null) {
                        if (typeof object.rewardsBalance !== "object")
                            throw TypeError(".visor.clientrpc.GetBankingDashboardResponse.rewardsBalance: object expected");
                        message.rewardsBalance = $root.visor.clientrpc.RewardsBalance.fromObject(object.rewardsBalance);
                    }
                    if (object.txCount != null)
                        message.txCount = object.txCount | 0;
                    switch (object.dashboardResult) {
                    case "GET_BANKING_DASHBOARD_RESPONSE_RESULT_INVALID":
                    case 0:
                        message.dashboardResult = 0;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_RESULT_SUCCESS":
                    case 1:
                        message.dashboardResult = 1;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_RESULT_HAS_UNHANDLED_TRANSACTIONS":
                    case 2:
                        message.dashboardResult = 2;
                        break;
                    }
                    if (object.monthsWithDirectDeposit != null)
                        message.monthsWithDirectDeposit = object.monthsWithDirectDeposit | 0;
                    switch (object.fundBcpPromo) {
                    case "GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_INVALID":
                    case 0:
                        message.fundBcpPromo = 0;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_NONE":
                    case 1:
                        message.fundBcpPromo = 1;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_ADD_FIVE_GET_FIVE":
                    case 2:
                        message.fundBcpPromo = 2;
                        break;
                    }
                    if (object.creditLine != null) {
                        if (typeof object.creditLine !== "object")
                            throw TypeError(".visor.clientrpc.GetBankingDashboardResponse.creditLine: object expected");
                        message.creditLine = $root.visor.clientrpc.CreditLineSummary.fromObject(object.creditLine);
                    }
                    switch (object.rewardsLevel) {
                    case "GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_INVALID":
                    case 0:
                        message.rewardsLevel = 0;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_DEFAULT":
                    case 1:
                        message.rewardsLevel = 1;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_2X":
                    case 2:
                        message.rewardsLevel = 2;
                        break;
                    }
                    switch (object.dataSource) {
                    case "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_INVALID":
                    case 0:
                        message.dataSource = 0;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_RPC":
                    case 1:
                        message.dataSource = 1;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_PARTIAL":
                    case 2:
                        message.dataSource = 2;
                        break;
                    case "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_WITH_TXNS":
                    case 3:
                        message.dataSource = 3;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetBankingDashboardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @static
                 * @param {visor.clientrpc.GetBankingDashboardResponse} message GetBankingDashboardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBankingDashboardResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.transactions = [];
                    if (options.defaults) {
                        object.availableFunds = null;
                        object.oldestTransactionPendingTimestamp = null;
                        object.dataAsOfTimestamp = null;
                        object.rewardsBalance = null;
                        object.txCount = 0;
                        object.dashboardResult = options.enums === String ? "GET_BANKING_DASHBOARD_RESPONSE_RESULT_INVALID" : 0;
                        object.monthsWithDirectDeposit = 0;
                        object.fundBcpPromo = options.enums === String ? "GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_INVALID" : 0;
                        object.creditLine = null;
                        object.availableCash = null;
                        object.rewardsLevel = options.enums === String ? "GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_INVALID" : 0;
                        object.dataSource = options.enums === String ? "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_INVALID" : 0;
                    }
                    if (message.availableFunds != null && message.hasOwnProperty("availableFunds"))
                        object.availableFunds = $root.visor.common.money.Amount.toObject(message.availableFunds, options);
                    if (message.transactions && message.transactions.length) {
                        object.transactions = [];
                        for (var j = 0; j < message.transactions.length; ++j)
                            object.transactions[j] = $root.visor.clientrpc.BoostTransaction.toObject(message.transactions[j], options);
                    }
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp"))
                        object.oldestTransactionPendingTimestamp = new Date(message.oldestTransactionPendingTimestamp.seconds * 1000 + message.oldestTransactionPendingTimestamp.nanos / 1000000);
                    if (message.dataAsOfTimestamp != null && message.hasOwnProperty("dataAsOfTimestamp"))
                        object.dataAsOfTimestamp = new Date(message.dataAsOfTimestamp.seconds * 1000 + message.dataAsOfTimestamp.nanos / 1000000);
                    if (message.rewardsBalance != null && message.hasOwnProperty("rewardsBalance"))
                        object.rewardsBalance = $root.visor.clientrpc.RewardsBalance.toObject(message.rewardsBalance, options);
                    if (message.txCount != null && message.hasOwnProperty("txCount"))
                        object.txCount = message.txCount;
                    if (message.dashboardResult != null && message.hasOwnProperty("dashboardResult"))
                        object.dashboardResult = options.enums === String ? $root.visor.clientrpc.GetBankingDashboardResponse.Result[message.dashboardResult] : message.dashboardResult;
                    if (message.monthsWithDirectDeposit != null && message.hasOwnProperty("monthsWithDirectDeposit"))
                        object.monthsWithDirectDeposit = message.monthsWithDirectDeposit;
                    if (message.fundBcpPromo != null && message.hasOwnProperty("fundBcpPromo"))
                        object.fundBcpPromo = options.enums === String ? $root.visor.clientrpc.GetBankingDashboardResponse.FundBcpPromo[message.fundBcpPromo] : message.fundBcpPromo;
                    if (message.creditLine != null && message.hasOwnProperty("creditLine"))
                        object.creditLine = $root.visor.clientrpc.CreditLineSummary.toObject(message.creditLine, options);
                    if (message.availableCash != null && message.hasOwnProperty("availableCash"))
                        object.availableCash = $root.visor.common.money.Amount.toObject(message.availableCash, options);
                    if (message.rewardsLevel != null && message.hasOwnProperty("rewardsLevel"))
                        object.rewardsLevel = options.enums === String ? $root.visor.clientrpc.GetBankingDashboardResponse.RewardsLevel[message.rewardsLevel] : message.rewardsLevel;
                    if (message.dataSource != null && message.hasOwnProperty("dataSource"))
                        object.dataSource = options.enums === String ? $root.visor.clientrpc.GetBankingDashboardResponse.DataSource[message.dataSource] : message.dataSource;
                    return object;
                };

                /**
                 * Converts this GetBankingDashboardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBankingDashboardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBankingDashboardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * FundBcpPromo enum.
                 * @name visor.clientrpc.GetBankingDashboardResponse.FundBcpPromo
                 * @enum {string}
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_INVALID=0 GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_INVALID value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_NONE=1 GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_NONE value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_ADD_FIVE_GET_FIVE=2 GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_ADD_FIVE_GET_FIVE value
                 */
                GetBankingDashboardResponse.FundBcpPromo = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_INVALID"] = 0;
                    values[valuesById[1] = "GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_NONE"] = 1;
                    values[valuesById[2] = "GET_BANKING_DASHBOARD_RESPONSE_FUND_BCP_PROMO_ADD_FIVE_GET_FIVE"] = 2;
                    return values;
                })();

                /**
                 * Result enum.
                 * @name visor.clientrpc.GetBankingDashboardResponse.Result
                 * @enum {string}
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_RESULT_INVALID=0 GET_BANKING_DASHBOARD_RESPONSE_RESULT_INVALID value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_RESULT_SUCCESS=1 GET_BANKING_DASHBOARD_RESPONSE_RESULT_SUCCESS value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_RESULT_HAS_UNHANDLED_TRANSACTIONS=2 GET_BANKING_DASHBOARD_RESPONSE_RESULT_HAS_UNHANDLED_TRANSACTIONS value
                 */
                GetBankingDashboardResponse.Result = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_BANKING_DASHBOARD_RESPONSE_RESULT_INVALID"] = 0;
                    values[valuesById[1] = "GET_BANKING_DASHBOARD_RESPONSE_RESULT_SUCCESS"] = 1;
                    values[valuesById[2] = "GET_BANKING_DASHBOARD_RESPONSE_RESULT_HAS_UNHANDLED_TRANSACTIONS"] = 2;
                    return values;
                })();

                /**
                 * RewardsLevel enum.
                 * @name visor.clientrpc.GetBankingDashboardResponse.RewardsLevel
                 * @enum {string}
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_INVALID=0 GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_INVALID value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_DEFAULT=1 GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_DEFAULT value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_2X=2 GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_2X value
                 */
                GetBankingDashboardResponse.RewardsLevel = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_INVALID"] = 0;
                    values[valuesById[1] = "GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_DEFAULT"] = 1;
                    values[valuesById[2] = "GET_BANKING_DASHBOARD_RESPONSE_REWARDS_LEVEL_2X"] = 2;
                    return values;
                })();

                /**
                 * DataSource enum.
                 * @name visor.clientrpc.GetBankingDashboardResponse.DataSource
                 * @enum {string}
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_INVALID=0 GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_INVALID value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_RPC=1 GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_RPC value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_PARTIAL=2 GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_PARTIAL value
                 * @property {number} GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_WITH_TXNS=3 GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_WITH_TXNS value
                 */
                GetBankingDashboardResponse.DataSource = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_INVALID"] = 0;
                    values[valuesById[1] = "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_RPC"] = 1;
                    values[valuesById[2] = "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_PARTIAL"] = 2;
                    values[valuesById[3] = "GET_BANKING_DASHBOARD_RESPONSE_DATA_SOURCE_FIREMODEL_WITH_TXNS"] = 3;
                    return values;
                })();

                return GetBankingDashboardResponse;
            })();

            clientrpc.CreditLineSummary = (function() {

                /**
                 * Properties of a CreditLineSummary.
                 * @memberof visor.clientrpc
                 * @interface ICreditLineSummary
                 * @property {visor.common.money.IAmount|null} [totalBalance] CreditLineSummary totalBalance
                 * @property {visor.common.money.IAmount|null} [currentFinanceCharge] CreditLineSummary currentFinanceCharge
                 * @property {visor.clientrpc.CreditLineSummary.IStatement|null} [currentStatement] CreditLineSummary currentStatement
                 * @property {visor.clientrpc.CreditLineSummary.IPayment|null} [autoPayment] CreditLineSummary autoPayment
                 * @property {number|null} [daysPastDue] CreditLineSummary daysPastDue
                 */

                /**
                 * Constructs a new CreditLineSummary.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreditLineSummary.
                 * @implements ICreditLineSummary
                 * @constructor
                 * @param {visor.clientrpc.ICreditLineSummary=} [properties] Properties to set
                 */
                function CreditLineSummary(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreditLineSummary totalBalance.
                 * @member {visor.common.money.IAmount|null|undefined} totalBalance
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @instance
                 */
                CreditLineSummary.prototype.totalBalance = null;

                /**
                 * CreditLineSummary currentFinanceCharge.
                 * @member {visor.common.money.IAmount|null|undefined} currentFinanceCharge
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @instance
                 */
                CreditLineSummary.prototype.currentFinanceCharge = null;

                /**
                 * CreditLineSummary currentStatement.
                 * @member {visor.clientrpc.CreditLineSummary.IStatement|null|undefined} currentStatement
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @instance
                 */
                CreditLineSummary.prototype.currentStatement = null;

                /**
                 * CreditLineSummary autoPayment.
                 * @member {visor.clientrpc.CreditLineSummary.IPayment|null|undefined} autoPayment
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @instance
                 */
                CreditLineSummary.prototype.autoPayment = null;

                /**
                 * CreditLineSummary daysPastDue.
                 * @member {number} daysPastDue
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @instance
                 */
                CreditLineSummary.prototype.daysPastDue = 0;

                /**
                 * Creates a new CreditLineSummary instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {visor.clientrpc.ICreditLineSummary=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreditLineSummary} CreditLineSummary instance
                 */
                CreditLineSummary.create = function create(properties) {
                    return new CreditLineSummary(properties);
                };

                /**
                 * Encodes the specified CreditLineSummary message. Does not implicitly {@link visor.clientrpc.CreditLineSummary.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {visor.clientrpc.ICreditLineSummary} message CreditLineSummary message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreditLineSummary.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.totalBalance != null && message.hasOwnProperty("totalBalance"))
                        $root.visor.common.money.Amount.encode(message.totalBalance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.currentFinanceCharge != null && message.hasOwnProperty("currentFinanceCharge"))
                        $root.visor.common.money.Amount.encode(message.currentFinanceCharge, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.currentStatement != null && message.hasOwnProperty("currentStatement"))
                        $root.visor.clientrpc.CreditLineSummary.Statement.encode(message.currentStatement, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.autoPayment != null && message.hasOwnProperty("autoPayment"))
                        $root.visor.clientrpc.CreditLineSummary.Payment.encode(message.autoPayment, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.daysPastDue != null && message.hasOwnProperty("daysPastDue"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.daysPastDue);
                    return writer;
                };

                /**
                 * Encodes the specified CreditLineSummary message, length delimited. Does not implicitly {@link visor.clientrpc.CreditLineSummary.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {visor.clientrpc.ICreditLineSummary} message CreditLineSummary message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreditLineSummary.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreditLineSummary message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreditLineSummary} CreditLineSummary
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreditLineSummary.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreditLineSummary();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.totalBalance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.currentFinanceCharge = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.currentStatement = $root.visor.clientrpc.CreditLineSummary.Statement.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.autoPayment = $root.visor.clientrpc.CreditLineSummary.Payment.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.daysPastDue = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreditLineSummary message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreditLineSummary} CreditLineSummary
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreditLineSummary.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreditLineSummary message.
                 * @function verify
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreditLineSummary.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.totalBalance != null && message.hasOwnProperty("totalBalance")) {
                        var error = $root.visor.common.money.Amount.verify(message.totalBalance);
                        if (error)
                            return "totalBalance." + error;
                    }
                    if (message.currentFinanceCharge != null && message.hasOwnProperty("currentFinanceCharge")) {
                        var error = $root.visor.common.money.Amount.verify(message.currentFinanceCharge);
                        if (error)
                            return "currentFinanceCharge." + error;
                    }
                    if (message.currentStatement != null && message.hasOwnProperty("currentStatement")) {
                        var error = $root.visor.clientrpc.CreditLineSummary.Statement.verify(message.currentStatement);
                        if (error)
                            return "currentStatement." + error;
                    }
                    if (message.autoPayment != null && message.hasOwnProperty("autoPayment")) {
                        var error = $root.visor.clientrpc.CreditLineSummary.Payment.verify(message.autoPayment);
                        if (error)
                            return "autoPayment." + error;
                    }
                    if (message.daysPastDue != null && message.hasOwnProperty("daysPastDue"))
                        if (!$util.isInteger(message.daysPastDue))
                            return "daysPastDue: integer expected";
                    return null;
                };

                /**
                 * Creates a CreditLineSummary message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreditLineSummary} CreditLineSummary
                 */
                CreditLineSummary.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreditLineSummary)
                        return object;
                    var message = new $root.visor.clientrpc.CreditLineSummary();
                    if (object.totalBalance != null) {
                        if (typeof object.totalBalance !== "object")
                            throw TypeError(".visor.clientrpc.CreditLineSummary.totalBalance: object expected");
                        message.totalBalance = $root.visor.common.money.Amount.fromObject(object.totalBalance);
                    }
                    if (object.currentFinanceCharge != null) {
                        if (typeof object.currentFinanceCharge !== "object")
                            throw TypeError(".visor.clientrpc.CreditLineSummary.currentFinanceCharge: object expected");
                        message.currentFinanceCharge = $root.visor.common.money.Amount.fromObject(object.currentFinanceCharge);
                    }
                    if (object.currentStatement != null) {
                        if (typeof object.currentStatement !== "object")
                            throw TypeError(".visor.clientrpc.CreditLineSummary.currentStatement: object expected");
                        message.currentStatement = $root.visor.clientrpc.CreditLineSummary.Statement.fromObject(object.currentStatement);
                    }
                    if (object.autoPayment != null) {
                        if (typeof object.autoPayment !== "object")
                            throw TypeError(".visor.clientrpc.CreditLineSummary.autoPayment: object expected");
                        message.autoPayment = $root.visor.clientrpc.CreditLineSummary.Payment.fromObject(object.autoPayment);
                    }
                    if (object.daysPastDue != null)
                        message.daysPastDue = object.daysPastDue | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a CreditLineSummary message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @static
                 * @param {visor.clientrpc.CreditLineSummary} message CreditLineSummary
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreditLineSummary.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.totalBalance = null;
                        object.currentFinanceCharge = null;
                        object.currentStatement = null;
                        object.autoPayment = null;
                        object.daysPastDue = 0;
                    }
                    if (message.totalBalance != null && message.hasOwnProperty("totalBalance"))
                        object.totalBalance = $root.visor.common.money.Amount.toObject(message.totalBalance, options);
                    if (message.currentFinanceCharge != null && message.hasOwnProperty("currentFinanceCharge"))
                        object.currentFinanceCharge = $root.visor.common.money.Amount.toObject(message.currentFinanceCharge, options);
                    if (message.currentStatement != null && message.hasOwnProperty("currentStatement"))
                        object.currentStatement = $root.visor.clientrpc.CreditLineSummary.Statement.toObject(message.currentStatement, options);
                    if (message.autoPayment != null && message.hasOwnProperty("autoPayment"))
                        object.autoPayment = $root.visor.clientrpc.CreditLineSummary.Payment.toObject(message.autoPayment, options);
                    if (message.daysPastDue != null && message.hasOwnProperty("daysPastDue"))
                        object.daysPastDue = message.daysPastDue;
                    return object;
                };

                /**
                 * Converts this CreditLineSummary to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreditLineSummary
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreditLineSummary.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreditLineSummary.Statement = (function() {

                    /**
                     * Properties of a Statement.
                     * @memberof visor.clientrpc.CreditLineSummary
                     * @interface IStatement
                     * @property {visor.common.money.IAmount|null} [balance] Statement balance
                     * @property {visor.common.money.IAmount|null} [minimumPaymentAmount] Statement minimumPaymentAmount
                     * @property {visor.common.time.ILocalDate|null} [paymentDueDate] Statement paymentDueDate
                     * @property {visor.common.money.IAmount|null} [interestBalancePortion] Statement interestBalancePortion
                     * @property {visor.common.money.IAmount|null} [principalBalancePortion] Statement principalBalancePortion
                     * @property {visor.clientrpc.CreditLineSummary.Statement.CreditLinePaymentStatus|null} [paymentStatus] Statement paymentStatus
                     */

                    /**
                     * Constructs a new Statement.
                     * @memberof visor.clientrpc.CreditLineSummary
                     * @classdesc Represents a Statement.
                     * @implements IStatement
                     * @constructor
                     * @param {visor.clientrpc.CreditLineSummary.IStatement=} [properties] Properties to set
                     */
                    function Statement(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Statement balance.
                     * @member {visor.common.money.IAmount|null|undefined} balance
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @instance
                     */
                    Statement.prototype.balance = null;

                    /**
                     * Statement minimumPaymentAmount.
                     * @member {visor.common.money.IAmount|null|undefined} minimumPaymentAmount
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @instance
                     */
                    Statement.prototype.minimumPaymentAmount = null;

                    /**
                     * Statement paymentDueDate.
                     * @member {visor.common.time.ILocalDate|null|undefined} paymentDueDate
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @instance
                     */
                    Statement.prototype.paymentDueDate = null;

                    /**
                     * Statement interestBalancePortion.
                     * @member {visor.common.money.IAmount|null|undefined} interestBalancePortion
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @instance
                     */
                    Statement.prototype.interestBalancePortion = null;

                    /**
                     * Statement principalBalancePortion.
                     * @member {visor.common.money.IAmount|null|undefined} principalBalancePortion
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @instance
                     */
                    Statement.prototype.principalBalancePortion = null;

                    /**
                     * Statement paymentStatus.
                     * @member {visor.clientrpc.CreditLineSummary.Statement.CreditLinePaymentStatus} paymentStatus
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @instance
                     */
                    Statement.prototype.paymentStatus = 0;

                    /**
                     * Creates a new Statement instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.IStatement=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreditLineSummary.Statement} Statement instance
                     */
                    Statement.create = function create(properties) {
                        return new Statement(properties);
                    };

                    /**
                     * Encodes the specified Statement message. Does not implicitly {@link visor.clientrpc.CreditLineSummary.Statement.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.IStatement} message Statement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Statement.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.balance != null && message.hasOwnProperty("balance"))
                            $root.visor.common.money.Amount.encode(message.balance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.minimumPaymentAmount != null && message.hasOwnProperty("minimumPaymentAmount"))
                            $root.visor.common.money.Amount.encode(message.minimumPaymentAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.paymentDueDate != null && message.hasOwnProperty("paymentDueDate"))
                            $root.visor.common.time.LocalDate.encode(message.paymentDueDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.interestBalancePortion != null && message.hasOwnProperty("interestBalancePortion"))
                            $root.visor.common.money.Amount.encode(message.interestBalancePortion, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.principalBalancePortion != null && message.hasOwnProperty("principalBalancePortion"))
                            $root.visor.common.money.Amount.encode(message.principalBalancePortion, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.paymentStatus);
                        return writer;
                    };

                    /**
                     * Encodes the specified Statement message, length delimited. Does not implicitly {@link visor.clientrpc.CreditLineSummary.Statement.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.IStatement} message Statement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Statement.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Statement message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreditLineSummary.Statement} Statement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Statement.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreditLineSummary.Statement();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.balance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.minimumPaymentAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.paymentDueDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.interestBalancePortion = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.principalBalancePortion = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.paymentStatus = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Statement message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreditLineSummary.Statement} Statement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Statement.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Statement message.
                     * @function verify
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Statement.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.balance != null && message.hasOwnProperty("balance")) {
                            var error = $root.visor.common.money.Amount.verify(message.balance);
                            if (error)
                                return "balance." + error;
                        }
                        if (message.minimumPaymentAmount != null && message.hasOwnProperty("minimumPaymentAmount")) {
                            var error = $root.visor.common.money.Amount.verify(message.minimumPaymentAmount);
                            if (error)
                                return "minimumPaymentAmount." + error;
                        }
                        if (message.paymentDueDate != null && message.hasOwnProperty("paymentDueDate")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.paymentDueDate);
                            if (error)
                                return "paymentDueDate." + error;
                        }
                        if (message.interestBalancePortion != null && message.hasOwnProperty("interestBalancePortion")) {
                            var error = $root.visor.common.money.Amount.verify(message.interestBalancePortion);
                            if (error)
                                return "interestBalancePortion." + error;
                        }
                        if (message.principalBalancePortion != null && message.hasOwnProperty("principalBalancePortion")) {
                            var error = $root.visor.common.money.Amount.verify(message.principalBalancePortion);
                            if (error)
                                return "principalBalancePortion." + error;
                        }
                        if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                            switch (message.paymentStatus) {
                            default:
                                return "paymentStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a Statement message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreditLineSummary.Statement} Statement
                     */
                    Statement.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreditLineSummary.Statement)
                            return object;
                        var message = new $root.visor.clientrpc.CreditLineSummary.Statement();
                        if (object.balance != null) {
                            if (typeof object.balance !== "object")
                                throw TypeError(".visor.clientrpc.CreditLineSummary.Statement.balance: object expected");
                            message.balance = $root.visor.common.money.Amount.fromObject(object.balance);
                        }
                        if (object.minimumPaymentAmount != null) {
                            if (typeof object.minimumPaymentAmount !== "object")
                                throw TypeError(".visor.clientrpc.CreditLineSummary.Statement.minimumPaymentAmount: object expected");
                            message.minimumPaymentAmount = $root.visor.common.money.Amount.fromObject(object.minimumPaymentAmount);
                        }
                        if (object.paymentDueDate != null) {
                            if (typeof object.paymentDueDate !== "object")
                                throw TypeError(".visor.clientrpc.CreditLineSummary.Statement.paymentDueDate: object expected");
                            message.paymentDueDate = $root.visor.common.time.LocalDate.fromObject(object.paymentDueDate);
                        }
                        if (object.interestBalancePortion != null) {
                            if (typeof object.interestBalancePortion !== "object")
                                throw TypeError(".visor.clientrpc.CreditLineSummary.Statement.interestBalancePortion: object expected");
                            message.interestBalancePortion = $root.visor.common.money.Amount.fromObject(object.interestBalancePortion);
                        }
                        if (object.principalBalancePortion != null) {
                            if (typeof object.principalBalancePortion !== "object")
                                throw TypeError(".visor.clientrpc.CreditLineSummary.Statement.principalBalancePortion: object expected");
                            message.principalBalancePortion = $root.visor.common.money.Amount.fromObject(object.principalBalancePortion);
                        }
                        switch (object.paymentStatus) {
                        case "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_INVALID":
                        case 0:
                            message.paymentStatus = 0;
                            break;
                        case "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PROCESSING":
                        case 1:
                            message.paymentStatus = 1;
                            break;
                        case "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAID":
                        case 2:
                            message.paymentStatus = 2;
                            break;
                        case "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAYMENT_DUE":
                        case 3:
                            message.paymentStatus = 3;
                            break;
                        case "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_NO_PAYMENT_DUE":
                        case 4:
                            message.paymentStatus = 4;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Statement message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.Statement} message Statement
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Statement.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.balance = null;
                            object.minimumPaymentAmount = null;
                            object.paymentDueDate = null;
                            object.interestBalancePortion = null;
                            object.principalBalancePortion = null;
                            object.paymentStatus = options.enums === String ? "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_INVALID" : 0;
                        }
                        if (message.balance != null && message.hasOwnProperty("balance"))
                            object.balance = $root.visor.common.money.Amount.toObject(message.balance, options);
                        if (message.minimumPaymentAmount != null && message.hasOwnProperty("minimumPaymentAmount"))
                            object.minimumPaymentAmount = $root.visor.common.money.Amount.toObject(message.minimumPaymentAmount, options);
                        if (message.paymentDueDate != null && message.hasOwnProperty("paymentDueDate"))
                            object.paymentDueDate = $root.visor.common.time.LocalDate.toObject(message.paymentDueDate, options);
                        if (message.interestBalancePortion != null && message.hasOwnProperty("interestBalancePortion"))
                            object.interestBalancePortion = $root.visor.common.money.Amount.toObject(message.interestBalancePortion, options);
                        if (message.principalBalancePortion != null && message.hasOwnProperty("principalBalancePortion"))
                            object.principalBalancePortion = $root.visor.common.money.Amount.toObject(message.principalBalancePortion, options);
                        if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                            object.paymentStatus = options.enums === String ? $root.visor.clientrpc.CreditLineSummary.Statement.CreditLinePaymentStatus[message.paymentStatus] : message.paymentStatus;
                        return object;
                    };

                    /**
                     * Converts this Statement to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreditLineSummary.Statement
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Statement.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * CreditLinePaymentStatus enum.
                     * @name visor.clientrpc.CreditLineSummary.Statement.CreditLinePaymentStatus
                     * @enum {string}
                     * @property {number} CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_INVALID=0 CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_INVALID value
                     * @property {number} CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PROCESSING=1 CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PROCESSING value
                     * @property {number} CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAID=2 CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAID value
                     * @property {number} CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAYMENT_DUE=3 CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAYMENT_DUE value
                     * @property {number} CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_NO_PAYMENT_DUE=4 CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_NO_PAYMENT_DUE value
                     */
                    Statement.CreditLinePaymentStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_INVALID"] = 0;
                        values[valuesById[1] = "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PROCESSING"] = 1;
                        values[valuesById[2] = "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAID"] = 2;
                        values[valuesById[3] = "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_PAYMENT_DUE"] = 3;
                        values[valuesById[4] = "CREDIT_LINE_SUMMARY_STATEMENT_CREDIT_LINE_PAYMENT_STATUS_NO_PAYMENT_DUE"] = 4;
                        return values;
                    })();

                    return Statement;
                })();

                CreditLineSummary.Payment = (function() {

                    /**
                     * Properties of a Payment.
                     * @memberof visor.clientrpc.CreditLineSummary
                     * @interface IPayment
                     * @property {visor.common.money.IAmount|null} [amount] Payment amount
                     * @property {string|null} [fundingSourceName] Payment fundingSourceName
                     * @property {string|null} [fundingSourceToken] Payment fundingSourceToken
                     * @property {visor.common.time.ILocalDate|null} [scheduledPostingDate] Payment scheduledPostingDate
                     */

                    /**
                     * Constructs a new Payment.
                     * @memberof visor.clientrpc.CreditLineSummary
                     * @classdesc Represents a Payment.
                     * @implements IPayment
                     * @constructor
                     * @param {visor.clientrpc.CreditLineSummary.IPayment=} [properties] Properties to set
                     */
                    function Payment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Payment amount.
                     * @member {visor.common.money.IAmount|null|undefined} amount
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @instance
                     */
                    Payment.prototype.amount = null;

                    /**
                     * Payment fundingSourceName.
                     * @member {string} fundingSourceName
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @instance
                     */
                    Payment.prototype.fundingSourceName = "";

                    /**
                     * Payment fundingSourceToken.
                     * @member {string} fundingSourceToken
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @instance
                     */
                    Payment.prototype.fundingSourceToken = "";

                    /**
                     * Payment scheduledPostingDate.
                     * @member {visor.common.time.ILocalDate|null|undefined} scheduledPostingDate
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @instance
                     */
                    Payment.prototype.scheduledPostingDate = null;

                    /**
                     * Creates a new Payment instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.IPayment=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreditLineSummary.Payment} Payment instance
                     */
                    Payment.create = function create(properties) {
                        return new Payment(properties);
                    };

                    /**
                     * Encodes the specified Payment message. Does not implicitly {@link visor.clientrpc.CreditLineSummary.Payment.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.IPayment} message Payment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Payment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.fundingSourceName != null && message.hasOwnProperty("fundingSourceName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.fundingSourceName);
                        if (message.fundingSourceToken != null && message.hasOwnProperty("fundingSourceToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fundingSourceToken);
                        if (message.scheduledPostingDate != null && message.hasOwnProperty("scheduledPostingDate"))
                            $root.visor.common.time.LocalDate.encode(message.scheduledPostingDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Payment message, length delimited. Does not implicitly {@link visor.clientrpc.CreditLineSummary.Payment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.IPayment} message Payment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Payment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Payment message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreditLineSummary.Payment} Payment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Payment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreditLineSummary.Payment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.fundingSourceName = reader.string();
                                break;
                            case 3:
                                message.fundingSourceToken = reader.string();
                                break;
                            case 4:
                                message.scheduledPostingDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Payment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreditLineSummary.Payment} Payment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Payment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Payment message.
                     * @function verify
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Payment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.amount != null && message.hasOwnProperty("amount")) {
                            var error = $root.visor.common.money.Amount.verify(message.amount);
                            if (error)
                                return "amount." + error;
                        }
                        if (message.fundingSourceName != null && message.hasOwnProperty("fundingSourceName"))
                            if (!$util.isString(message.fundingSourceName))
                                return "fundingSourceName: string expected";
                        if (message.fundingSourceToken != null && message.hasOwnProperty("fundingSourceToken"))
                            if (!$util.isString(message.fundingSourceToken))
                                return "fundingSourceToken: string expected";
                        if (message.scheduledPostingDate != null && message.hasOwnProperty("scheduledPostingDate")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.scheduledPostingDate);
                            if (error)
                                return "scheduledPostingDate." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Payment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreditLineSummary.Payment} Payment
                     */
                    Payment.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreditLineSummary.Payment)
                            return object;
                        var message = new $root.visor.clientrpc.CreditLineSummary.Payment();
                        if (object.amount != null) {
                            if (typeof object.amount !== "object")
                                throw TypeError(".visor.clientrpc.CreditLineSummary.Payment.amount: object expected");
                            message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                        }
                        if (object.fundingSourceName != null)
                            message.fundingSourceName = String(object.fundingSourceName);
                        if (object.fundingSourceToken != null)
                            message.fundingSourceToken = String(object.fundingSourceToken);
                        if (object.scheduledPostingDate != null) {
                            if (typeof object.scheduledPostingDate !== "object")
                                throw TypeError(".visor.clientrpc.CreditLineSummary.Payment.scheduledPostingDate: object expected");
                            message.scheduledPostingDate = $root.visor.common.time.LocalDate.fromObject(object.scheduledPostingDate);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Payment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @static
                     * @param {visor.clientrpc.CreditLineSummary.Payment} message Payment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Payment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.amount = null;
                            object.fundingSourceName = "";
                            object.fundingSourceToken = "";
                            object.scheduledPostingDate = null;
                        }
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                        if (message.fundingSourceName != null && message.hasOwnProperty("fundingSourceName"))
                            object.fundingSourceName = message.fundingSourceName;
                        if (message.fundingSourceToken != null && message.hasOwnProperty("fundingSourceToken"))
                            object.fundingSourceToken = message.fundingSourceToken;
                        if (message.scheduledPostingDate != null && message.hasOwnProperty("scheduledPostingDate"))
                            object.scheduledPostingDate = $root.visor.common.time.LocalDate.toObject(message.scheduledPostingDate, options);
                        return object;
                    };

                    /**
                     * Converts this Payment to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreditLineSummary.Payment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Payment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Payment;
                })();

                return CreditLineSummary;
            })();

            clientrpc.CreateCreditLineTransferRequest = (function() {

                /**
                 * Properties of a CreateCreditLineTransferRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateCreditLineTransferRequest
                 * @property {string|null} [boostCardProgramToken] CreateCreditLineTransferRequest boostCardProgramToken
                 * @property {visor.common.money.IAmount|null} [transferAmount] CreateCreditLineTransferRequest transferAmount
                 * @property {visor.clientrpc.CreateCreditLineTransferRequest.IAchPaymentDetails|null} [achPayment] CreateCreditLineTransferRequest achPayment
                 * @property {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPaymentDetails|null} [boostCardProgramPayment] CreateCreditLineTransferRequest boostCardProgramPayment
                 * @property {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPayoutDetails|null} [boostCardProgramPayout] CreateCreditLineTransferRequest boostCardProgramPayout
                 * @property {visor.clientrpc.CreateCreditLineTransferRequest.IAchDisbursementDetails|null} [achDisbursement] CreateCreditLineTransferRequest achDisbursement
                 * @property {visor.clientrpc.CreateCreditLineTransferRequest.IInterchangePaymentDetails|null} [interchangePayment] CreateCreditLineTransferRequest interchangePayment
                 */

                /**
                 * Constructs a new CreateCreditLineTransferRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateCreditLineTransferRequest.
                 * @implements ICreateCreditLineTransferRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateCreditLineTransferRequest=} [properties] Properties to set
                 */
                function CreateCreditLineTransferRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateCreditLineTransferRequest boostCardProgramToken.
                 * @member {string} boostCardProgramToken
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                CreateCreditLineTransferRequest.prototype.boostCardProgramToken = "";

                /**
                 * CreateCreditLineTransferRequest transferAmount.
                 * @member {visor.common.money.IAmount|null|undefined} transferAmount
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                CreateCreditLineTransferRequest.prototype.transferAmount = null;

                /**
                 * CreateCreditLineTransferRequest achPayment.
                 * @member {visor.clientrpc.CreateCreditLineTransferRequest.IAchPaymentDetails|null|undefined} achPayment
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                CreateCreditLineTransferRequest.prototype.achPayment = null;

                /**
                 * CreateCreditLineTransferRequest boostCardProgramPayment.
                 * @member {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPaymentDetails|null|undefined} boostCardProgramPayment
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                CreateCreditLineTransferRequest.prototype.boostCardProgramPayment = null;

                /**
                 * CreateCreditLineTransferRequest boostCardProgramPayout.
                 * @member {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPayoutDetails|null|undefined} boostCardProgramPayout
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                CreateCreditLineTransferRequest.prototype.boostCardProgramPayout = null;

                /**
                 * CreateCreditLineTransferRequest achDisbursement.
                 * @member {visor.clientrpc.CreateCreditLineTransferRequest.IAchDisbursementDetails|null|undefined} achDisbursement
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                CreateCreditLineTransferRequest.prototype.achDisbursement = null;

                /**
                 * CreateCreditLineTransferRequest interchangePayment.
                 * @member {visor.clientrpc.CreateCreditLineTransferRequest.IInterchangePaymentDetails|null|undefined} interchangePayment
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                CreateCreditLineTransferRequest.prototype.interchangePayment = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * CreateCreditLineTransferRequest transferDetails.
                 * @member {"achPayment"|"boostCardProgramPayment"|"boostCardProgramPayout"|"achDisbursement"|"interchangePayment"|undefined} transferDetails
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 */
                Object.defineProperty(CreateCreditLineTransferRequest.prototype, "transferDetails", {
                    get: $util.oneOfGetter($oneOfFields = ["achPayment", "boostCardProgramPayment", "boostCardProgramPayout", "achDisbursement", "interchangePayment"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new CreateCreditLineTransferRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCreditLineTransferRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateCreditLineTransferRequest} CreateCreditLineTransferRequest instance
                 */
                CreateCreditLineTransferRequest.create = function create(properties) {
                    return new CreateCreditLineTransferRequest(properties);
                };

                /**
                 * Encodes the specified CreateCreditLineTransferRequest message. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCreditLineTransferRequest} message CreateCreditLineTransferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCreditLineTransferRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.boostCardProgramToken);
                    if (message.transferAmount != null && message.hasOwnProperty("transferAmount"))
                        $root.visor.common.money.Amount.encode(message.transferAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.achPayment != null && message.hasOwnProperty("achPayment"))
                        $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails.encode(message.achPayment, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.boostCardProgramPayment != null && message.hasOwnProperty("boostCardProgramPayment"))
                        $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails.encode(message.boostCardProgramPayment, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.boostCardProgramPayout != null && message.hasOwnProperty("boostCardProgramPayout"))
                        $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails.encode(message.boostCardProgramPayout, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.achDisbursement != null && message.hasOwnProperty("achDisbursement"))
                        $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails.encode(message.achDisbursement, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.interchangePayment != null && message.hasOwnProperty("interchangePayment"))
                        $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails.encode(message.interchangePayment, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreateCreditLineTransferRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCreditLineTransferRequest} message CreateCreditLineTransferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCreditLineTransferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateCreditLineTransferRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateCreditLineTransferRequest} CreateCreditLineTransferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCreditLineTransferRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCreditLineTransferRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.boostCardProgramToken = reader.string();
                            break;
                        case 2:
                            message.transferAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.achPayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.boostCardProgramPayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.boostCardProgramPayout = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.achDisbursement = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.interchangePayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateCreditLineTransferRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateCreditLineTransferRequest} CreateCreditLineTransferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCreditLineTransferRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateCreditLineTransferRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCreditLineTransferRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        if (!$util.isString(message.boostCardProgramToken))
                            return "boostCardProgramToken: string expected";
                    if (message.transferAmount != null && message.hasOwnProperty("transferAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.transferAmount);
                        if (error)
                            return "transferAmount." + error;
                    }
                    if (message.achPayment != null && message.hasOwnProperty("achPayment")) {
                        properties.transferDetails = 1;
                        {
                            var error = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails.verify(message.achPayment);
                            if (error)
                                return "achPayment." + error;
                        }
                    }
                    if (message.boostCardProgramPayment != null && message.hasOwnProperty("boostCardProgramPayment")) {
                        if (properties.transferDetails === 1)
                            return "transferDetails: multiple values";
                        properties.transferDetails = 1;
                        {
                            var error = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails.verify(message.boostCardProgramPayment);
                            if (error)
                                return "boostCardProgramPayment." + error;
                        }
                    }
                    if (message.boostCardProgramPayout != null && message.hasOwnProperty("boostCardProgramPayout")) {
                        if (properties.transferDetails === 1)
                            return "transferDetails: multiple values";
                        properties.transferDetails = 1;
                        {
                            var error = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails.verify(message.boostCardProgramPayout);
                            if (error)
                                return "boostCardProgramPayout." + error;
                        }
                    }
                    if (message.achDisbursement != null && message.hasOwnProperty("achDisbursement")) {
                        if (properties.transferDetails === 1)
                            return "transferDetails: multiple values";
                        properties.transferDetails = 1;
                        {
                            var error = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails.verify(message.achDisbursement);
                            if (error)
                                return "achDisbursement." + error;
                        }
                    }
                    if (message.interchangePayment != null && message.hasOwnProperty("interchangePayment")) {
                        if (properties.transferDetails === 1)
                            return "transferDetails: multiple values";
                        properties.transferDetails = 1;
                        {
                            var error = $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails.verify(message.interchangePayment);
                            if (error)
                                return "interchangePayment." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateCreditLineTransferRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateCreditLineTransferRequest} CreateCreditLineTransferRequest
                 */
                CreateCreditLineTransferRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateCreditLineTransferRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateCreditLineTransferRequest();
                    if (object.boostCardProgramToken != null)
                        message.boostCardProgramToken = String(object.boostCardProgramToken);
                    if (object.transferAmount != null) {
                        if (typeof object.transferAmount !== "object")
                            throw TypeError(".visor.clientrpc.CreateCreditLineTransferRequest.transferAmount: object expected");
                        message.transferAmount = $root.visor.common.money.Amount.fromObject(object.transferAmount);
                    }
                    if (object.achPayment != null) {
                        if (typeof object.achPayment !== "object")
                            throw TypeError(".visor.clientrpc.CreateCreditLineTransferRequest.achPayment: object expected");
                        message.achPayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails.fromObject(object.achPayment);
                    }
                    if (object.boostCardProgramPayment != null) {
                        if (typeof object.boostCardProgramPayment !== "object")
                            throw TypeError(".visor.clientrpc.CreateCreditLineTransferRequest.boostCardProgramPayment: object expected");
                        message.boostCardProgramPayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails.fromObject(object.boostCardProgramPayment);
                    }
                    if (object.boostCardProgramPayout != null) {
                        if (typeof object.boostCardProgramPayout !== "object")
                            throw TypeError(".visor.clientrpc.CreateCreditLineTransferRequest.boostCardProgramPayout: object expected");
                        message.boostCardProgramPayout = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails.fromObject(object.boostCardProgramPayout);
                    }
                    if (object.achDisbursement != null) {
                        if (typeof object.achDisbursement !== "object")
                            throw TypeError(".visor.clientrpc.CreateCreditLineTransferRequest.achDisbursement: object expected");
                        message.achDisbursement = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails.fromObject(object.achDisbursement);
                    }
                    if (object.interchangePayment != null) {
                        if (typeof object.interchangePayment !== "object")
                            throw TypeError(".visor.clientrpc.CreateCreditLineTransferRequest.interchangePayment: object expected");
                        message.interchangePayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails.fromObject(object.interchangePayment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateCreditLineTransferRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @static
                 * @param {visor.clientrpc.CreateCreditLineTransferRequest} message CreateCreditLineTransferRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCreditLineTransferRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.boostCardProgramToken = "";
                        object.transferAmount = null;
                    }
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        object.boostCardProgramToken = message.boostCardProgramToken;
                    if (message.transferAmount != null && message.hasOwnProperty("transferAmount"))
                        object.transferAmount = $root.visor.common.money.Amount.toObject(message.transferAmount, options);
                    if (message.achPayment != null && message.hasOwnProperty("achPayment")) {
                        object.achPayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails.toObject(message.achPayment, options);
                        if (options.oneofs)
                            object.transferDetails = "achPayment";
                    }
                    if (message.boostCardProgramPayment != null && message.hasOwnProperty("boostCardProgramPayment")) {
                        object.boostCardProgramPayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails.toObject(message.boostCardProgramPayment, options);
                        if (options.oneofs)
                            object.transferDetails = "boostCardProgramPayment";
                    }
                    if (message.boostCardProgramPayout != null && message.hasOwnProperty("boostCardProgramPayout")) {
                        object.boostCardProgramPayout = $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails.toObject(message.boostCardProgramPayout, options);
                        if (options.oneofs)
                            object.transferDetails = "boostCardProgramPayout";
                    }
                    if (message.achDisbursement != null && message.hasOwnProperty("achDisbursement")) {
                        object.achDisbursement = $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails.toObject(message.achDisbursement, options);
                        if (options.oneofs)
                            object.transferDetails = "achDisbursement";
                    }
                    if (message.interchangePayment != null && message.hasOwnProperty("interchangePayment")) {
                        object.interchangePayment = $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails.toObject(message.interchangePayment, options);
                        if (options.oneofs)
                            object.transferDetails = "interchangePayment";
                    }
                    return object;
                };

                /**
                 * Converts this CreateCreditLineTransferRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCreditLineTransferRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreateCreditLineTransferRequest.AchPaymentDetails = (function() {

                    /**
                     * Properties of an AchPaymentDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @interface IAchPaymentDetails
                     * @property {string|null} [bankAccountToken] AchPaymentDetails bankAccountToken
                     */

                    /**
                     * Constructs a new AchPaymentDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @classdesc Represents an AchPaymentDetails.
                     * @implements IAchPaymentDetails
                     * @constructor
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchPaymentDetails=} [properties] Properties to set
                     */
                    function AchPaymentDetails(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AchPaymentDetails bankAccountToken.
                     * @member {string} bankAccountToken
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @instance
                     */
                    AchPaymentDetails.prototype.bankAccountToken = "";

                    /**
                     * Creates a new AchPaymentDetails instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchPaymentDetails=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails} AchPaymentDetails instance
                     */
                    AchPaymentDetails.create = function create(properties) {
                        return new AchPaymentDetails(properties);
                    };

                    /**
                     * Encodes the specified AchPaymentDetails message. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchPaymentDetails} message AchPaymentDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AchPaymentDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.bankAccountToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified AchPaymentDetails message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchPaymentDetails} message AchPaymentDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AchPaymentDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AchPaymentDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails} AchPaymentDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AchPaymentDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.bankAccountToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AchPaymentDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails} AchPaymentDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AchPaymentDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AchPaymentDetails message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AchPaymentDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                            if (!$util.isString(message.bankAccountToken))
                                return "bankAccountToken: string expected";
                        return null;
                    };

                    /**
                     * Creates an AchPaymentDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails} AchPaymentDetails
                     */
                    AchPaymentDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails)
                            return object;
                        var message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails();
                        if (object.bankAccountToken != null)
                            message.bankAccountToken = String(object.bankAccountToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AchPaymentDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails} message AchPaymentDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AchPaymentDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.bankAccountToken = "";
                        if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                            object.bankAccountToken = message.bankAccountToken;
                        return object;
                    };

                    /**
                     * Converts this AchPaymentDetails to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchPaymentDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AchPaymentDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AchPaymentDetails;
                })();

                CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails = (function() {

                    /**
                     * Properties of a BoostCardProgramPaymentDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @interface IBoostCardProgramPaymentDetails
                     */

                    /**
                     * Constructs a new BoostCardProgramPaymentDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @classdesc Represents a BoostCardProgramPaymentDetails.
                     * @implements IBoostCardProgramPaymentDetails
                     * @constructor
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPaymentDetails=} [properties] Properties to set
                     */
                    function BoostCardProgramPaymentDetails(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new BoostCardProgramPaymentDetails instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPaymentDetails=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails} BoostCardProgramPaymentDetails instance
                     */
                    BoostCardProgramPaymentDetails.create = function create(properties) {
                        return new BoostCardProgramPaymentDetails(properties);
                    };

                    /**
                     * Encodes the specified BoostCardProgramPaymentDetails message. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPaymentDetails} message BoostCardProgramPaymentDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoostCardProgramPaymentDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified BoostCardProgramPaymentDetails message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPaymentDetails} message BoostCardProgramPaymentDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoostCardProgramPaymentDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a BoostCardProgramPaymentDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails} BoostCardProgramPaymentDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoostCardProgramPaymentDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a BoostCardProgramPaymentDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails} BoostCardProgramPaymentDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoostCardProgramPaymentDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a BoostCardProgramPaymentDetails message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BoostCardProgramPaymentDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a BoostCardProgramPaymentDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails} BoostCardProgramPaymentDetails
                     */
                    BoostCardProgramPaymentDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails)
                            return object;
                        return new $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails();
                    };

                    /**
                     * Creates a plain object from a BoostCardProgramPaymentDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails} message BoostCardProgramPaymentDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BoostCardProgramPaymentDetails.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this BoostCardProgramPaymentDetails to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPaymentDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BoostCardProgramPaymentDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return BoostCardProgramPaymentDetails;
                })();

                CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails = (function() {

                    /**
                     * Properties of a BoostCardProgramPayoutDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @interface IBoostCardProgramPayoutDetails
                     */

                    /**
                     * Constructs a new BoostCardProgramPayoutDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @classdesc Represents a BoostCardProgramPayoutDetails.
                     * @implements IBoostCardProgramPayoutDetails
                     * @constructor
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPayoutDetails=} [properties] Properties to set
                     */
                    function BoostCardProgramPayoutDetails(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new BoostCardProgramPayoutDetails instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPayoutDetails=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails} BoostCardProgramPayoutDetails instance
                     */
                    BoostCardProgramPayoutDetails.create = function create(properties) {
                        return new BoostCardProgramPayoutDetails(properties);
                    };

                    /**
                     * Encodes the specified BoostCardProgramPayoutDetails message. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPayoutDetails} message BoostCardProgramPayoutDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoostCardProgramPayoutDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified BoostCardProgramPayoutDetails message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IBoostCardProgramPayoutDetails} message BoostCardProgramPayoutDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BoostCardProgramPayoutDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a BoostCardProgramPayoutDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails} BoostCardProgramPayoutDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoostCardProgramPayoutDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a BoostCardProgramPayoutDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails} BoostCardProgramPayoutDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BoostCardProgramPayoutDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a BoostCardProgramPayoutDetails message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BoostCardProgramPayoutDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a BoostCardProgramPayoutDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails} BoostCardProgramPayoutDetails
                     */
                    BoostCardProgramPayoutDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails)
                            return object;
                        return new $root.visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails();
                    };

                    /**
                     * Creates a plain object from a BoostCardProgramPayoutDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails} message BoostCardProgramPayoutDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BoostCardProgramPayoutDetails.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this BoostCardProgramPayoutDetails to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.BoostCardProgramPayoutDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BoostCardProgramPayoutDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return BoostCardProgramPayoutDetails;
                })();

                CreateCreditLineTransferRequest.AchDisbursementDetails = (function() {

                    /**
                     * Properties of an AchDisbursementDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @interface IAchDisbursementDetails
                     * @property {string|null} [bankAccountToken] AchDisbursementDetails bankAccountToken
                     */

                    /**
                     * Constructs a new AchDisbursementDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @classdesc Represents an AchDisbursementDetails.
                     * @implements IAchDisbursementDetails
                     * @constructor
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchDisbursementDetails=} [properties] Properties to set
                     */
                    function AchDisbursementDetails(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AchDisbursementDetails bankAccountToken.
                     * @member {string} bankAccountToken
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @instance
                     */
                    AchDisbursementDetails.prototype.bankAccountToken = "";

                    /**
                     * Creates a new AchDisbursementDetails instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchDisbursementDetails=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails} AchDisbursementDetails instance
                     */
                    AchDisbursementDetails.create = function create(properties) {
                        return new AchDisbursementDetails(properties);
                    };

                    /**
                     * Encodes the specified AchDisbursementDetails message. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchDisbursementDetails} message AchDisbursementDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AchDisbursementDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.bankAccountToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified AchDisbursementDetails message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IAchDisbursementDetails} message AchDisbursementDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AchDisbursementDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AchDisbursementDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails} AchDisbursementDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AchDisbursementDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.bankAccountToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AchDisbursementDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails} AchDisbursementDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AchDisbursementDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AchDisbursementDetails message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AchDisbursementDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                            if (!$util.isString(message.bankAccountToken))
                                return "bankAccountToken: string expected";
                        return null;
                    };

                    /**
                     * Creates an AchDisbursementDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails} AchDisbursementDetails
                     */
                    AchDisbursementDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails)
                            return object;
                        var message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails();
                        if (object.bankAccountToken != null)
                            message.bankAccountToken = String(object.bankAccountToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AchDisbursementDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails} message AchDisbursementDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AchDisbursementDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.bankAccountToken = "";
                        if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                            object.bankAccountToken = message.bankAccountToken;
                        return object;
                    };

                    /**
                     * Converts this AchDisbursementDetails to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.AchDisbursementDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AchDisbursementDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AchDisbursementDetails;
                })();

                CreateCreditLineTransferRequest.InterchangePaymentDetails = (function() {

                    /**
                     * Properties of an InterchangePaymentDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @interface IInterchangePaymentDetails
                     * @property {string|null} [cardToken] InterchangePaymentDetails cardToken
                     */

                    /**
                     * Constructs a new InterchangePaymentDetails.
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest
                     * @classdesc Represents an InterchangePaymentDetails.
                     * @implements IInterchangePaymentDetails
                     * @constructor
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IInterchangePaymentDetails=} [properties] Properties to set
                     */
                    function InterchangePaymentDetails(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InterchangePaymentDetails cardToken.
                     * @member {string} cardToken
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @instance
                     */
                    InterchangePaymentDetails.prototype.cardToken = "";

                    /**
                     * Creates a new InterchangePaymentDetails instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IInterchangePaymentDetails=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails} InterchangePaymentDetails instance
                     */
                    InterchangePaymentDetails.create = function create(properties) {
                        return new InterchangePaymentDetails(properties);
                    };

                    /**
                     * Encodes the specified InterchangePaymentDetails message. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IInterchangePaymentDetails} message InterchangePaymentDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterchangePaymentDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cardToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified InterchangePaymentDetails message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.IInterchangePaymentDetails} message InterchangePaymentDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterchangePaymentDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InterchangePaymentDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails} InterchangePaymentDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterchangePaymentDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cardToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InterchangePaymentDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails} InterchangePaymentDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterchangePaymentDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InterchangePaymentDetails message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InterchangePaymentDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                            if (!$util.isString(message.cardToken))
                                return "cardToken: string expected";
                        return null;
                    };

                    /**
                     * Creates an InterchangePaymentDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails} InterchangePaymentDetails
                     */
                    InterchangePaymentDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails)
                            return object;
                        var message = new $root.visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails();
                        if (object.cardToken != null)
                            message.cardToken = String(object.cardToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from an InterchangePaymentDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @static
                     * @param {visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails} message InterchangePaymentDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InterchangePaymentDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.cardToken = "";
                        if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                            object.cardToken = message.cardToken;
                        return object;
                    };

                    /**
                     * Converts this InterchangePaymentDetails to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateCreditLineTransferRequest.InterchangePaymentDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InterchangePaymentDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return InterchangePaymentDetails;
                })();

                return CreateCreditLineTransferRequest;
            })();

            clientrpc.CreateCreditLineTransferResponse = (function() {

                /**
                 * Properties of a CreateCreditLineTransferResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateCreditLineTransferResponse
                 */

                /**
                 * Constructs a new CreateCreditLineTransferResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateCreditLineTransferResponse.
                 * @implements ICreateCreditLineTransferResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateCreditLineTransferResponse=} [properties] Properties to set
                 */
                function CreateCreditLineTransferResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateCreditLineTransferResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCreditLineTransferResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateCreditLineTransferResponse} CreateCreditLineTransferResponse instance
                 */
                CreateCreditLineTransferResponse.create = function create(properties) {
                    return new CreateCreditLineTransferResponse(properties);
                };

                /**
                 * Encodes the specified CreateCreditLineTransferResponse message. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCreditLineTransferResponse} message CreateCreditLineTransferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCreditLineTransferResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateCreditLineTransferResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCreditLineTransferResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCreditLineTransferResponse} message CreateCreditLineTransferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCreditLineTransferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateCreditLineTransferResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateCreditLineTransferResponse} CreateCreditLineTransferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCreditLineTransferResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCreditLineTransferResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateCreditLineTransferResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateCreditLineTransferResponse} CreateCreditLineTransferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCreditLineTransferResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateCreditLineTransferResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCreditLineTransferResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateCreditLineTransferResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateCreditLineTransferResponse} CreateCreditLineTransferResponse
                 */
                CreateCreditLineTransferResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateCreditLineTransferResponse)
                        return object;
                    return new $root.visor.clientrpc.CreateCreditLineTransferResponse();
                };

                /**
                 * Creates a plain object from a CreateCreditLineTransferResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @static
                 * @param {visor.clientrpc.CreateCreditLineTransferResponse} message CreateCreditLineTransferResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCreditLineTransferResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateCreditLineTransferResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateCreditLineTransferResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCreditLineTransferResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateCreditLineTransferResponse;
            })();

            clientrpc.UpdateCreditLineAutoPayProfileRequest = (function() {

                /**
                 * Properties of an UpdateCreditLineAutoPayProfileRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateCreditLineAutoPayProfileRequest
                 * @property {boolean|null} [autoPayEnabled] UpdateCreditLineAutoPayProfileRequest autoPayEnabled
                 * @property {string|null} [bankAccountToken] UpdateCreditLineAutoPayProfileRequest bankAccountToken
                 * @property {string|null} [boostCardProgramToken] UpdateCreditLineAutoPayProfileRequest boostCardProgramToken
                 * @property {visor.clientrpc.CreditLineAutoPayProfilePaymentType|null} [paymentType] UpdateCreditLineAutoPayProfileRequest paymentType
                 * @property {visor.common.money.IAmount|null} [amount] UpdateCreditLineAutoPayProfileRequest amount
                 */

                /**
                 * Constructs a new UpdateCreditLineAutoPayProfileRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateCreditLineAutoPayProfileRequest.
                 * @implements IUpdateCreditLineAutoPayProfileRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileRequest=} [properties] Properties to set
                 */
                function UpdateCreditLineAutoPayProfileRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateCreditLineAutoPayProfileRequest autoPayEnabled.
                 * @member {boolean} autoPayEnabled
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @instance
                 */
                UpdateCreditLineAutoPayProfileRequest.prototype.autoPayEnabled = false;

                /**
                 * UpdateCreditLineAutoPayProfileRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @instance
                 */
                UpdateCreditLineAutoPayProfileRequest.prototype.bankAccountToken = "";

                /**
                 * UpdateCreditLineAutoPayProfileRequest boostCardProgramToken.
                 * @member {string} boostCardProgramToken
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @instance
                 */
                UpdateCreditLineAutoPayProfileRequest.prototype.boostCardProgramToken = "";

                /**
                 * UpdateCreditLineAutoPayProfileRequest paymentType.
                 * @member {visor.clientrpc.CreditLineAutoPayProfilePaymentType} paymentType
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @instance
                 */
                UpdateCreditLineAutoPayProfileRequest.prototype.paymentType = 0;

                /**
                 * UpdateCreditLineAutoPayProfileRequest amount.
                 * @member {visor.common.money.IAmount|null|undefined} amount
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @instance
                 */
                UpdateCreditLineAutoPayProfileRequest.prototype.amount = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * UpdateCreditLineAutoPayProfileRequest from.
                 * @member {"bankAccountToken"|"boostCardProgramToken"|undefined} from
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @instance
                 */
                Object.defineProperty(UpdateCreditLineAutoPayProfileRequest.prototype, "from", {
                    get: $util.oneOfGetter($oneOfFields = ["bankAccountToken", "boostCardProgramToken"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new UpdateCreditLineAutoPayProfileRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileRequest} UpdateCreditLineAutoPayProfileRequest instance
                 */
                UpdateCreditLineAutoPayProfileRequest.create = function create(properties) {
                    return new UpdateCreditLineAutoPayProfileRequest(properties);
                };

                /**
                 * Encodes the specified UpdateCreditLineAutoPayProfileRequest message. Does not implicitly {@link visor.clientrpc.UpdateCreditLineAutoPayProfileRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileRequest} message UpdateCreditLineAutoPayProfileRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCreditLineAutoPayProfileRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.autoPayEnabled != null && message.hasOwnProperty("autoPayEnabled"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.autoPayEnabled);
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.bankAccountToken);
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.boostCardProgramToken);
                    if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.paymentType);
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateCreditLineAutoPayProfileRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateCreditLineAutoPayProfileRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileRequest} message UpdateCreditLineAutoPayProfileRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCreditLineAutoPayProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateCreditLineAutoPayProfileRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileRequest} UpdateCreditLineAutoPayProfileRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCreditLineAutoPayProfileRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateCreditLineAutoPayProfileRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.autoPayEnabled = reader.bool();
                            break;
                        case 2:
                            message.bankAccountToken = reader.string();
                            break;
                        case 3:
                            message.boostCardProgramToken = reader.string();
                            break;
                        case 4:
                            message.paymentType = reader.int32();
                            break;
                        case 5:
                            message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateCreditLineAutoPayProfileRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileRequest} UpdateCreditLineAutoPayProfileRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCreditLineAutoPayProfileRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateCreditLineAutoPayProfileRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateCreditLineAutoPayProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.autoPayEnabled != null && message.hasOwnProperty("autoPayEnabled"))
                        if (typeof message.autoPayEnabled !== "boolean")
                            return "autoPayEnabled: boolean expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken")) {
                        properties.from = 1;
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    }
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken")) {
                        if (properties.from === 1)
                            return "from: multiple values";
                        properties.from = 1;
                        if (!$util.isString(message.boostCardProgramToken))
                            return "boostCardProgramToken: string expected";
                    }
                    if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                        switch (message.paymentType) {
                        default:
                            return "paymentType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.visor.common.money.Amount.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateCreditLineAutoPayProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileRequest} UpdateCreditLineAutoPayProfileRequest
                 */
                UpdateCreditLineAutoPayProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateCreditLineAutoPayProfileRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateCreditLineAutoPayProfileRequest();
                    if (object.autoPayEnabled != null)
                        message.autoPayEnabled = Boolean(object.autoPayEnabled);
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    if (object.boostCardProgramToken != null)
                        message.boostCardProgramToken = String(object.boostCardProgramToken);
                    switch (object.paymentType) {
                    case "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_INVALID":
                    case 0:
                        message.paymentType = 0;
                        break;
                    case "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_STATEMENT_BALANCE":
                    case 1:
                        message.paymentType = 1;
                        break;
                    case "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_MINIMUM_PAYMENT":
                    case 2:
                        message.paymentType = 2;
                        break;
                    case "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CUSTOM_AMOUNT":
                    case 3:
                        message.paymentType = 3;
                        break;
                    case "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CURRENT_BALANCE":
                    case 4:
                        message.paymentType = 4;
                        break;
                    }
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".visor.clientrpc.UpdateCreditLineAutoPayProfileRequest.amount: object expected");
                        message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateCreditLineAutoPayProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @static
                 * @param {visor.clientrpc.UpdateCreditLineAutoPayProfileRequest} message UpdateCreditLineAutoPayProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateCreditLineAutoPayProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.autoPayEnabled = false;
                        object.paymentType = options.enums === String ? "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_INVALID" : 0;
                        object.amount = null;
                    }
                    if (message.autoPayEnabled != null && message.hasOwnProperty("autoPayEnabled"))
                        object.autoPayEnabled = message.autoPayEnabled;
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken")) {
                        object.bankAccountToken = message.bankAccountToken;
                        if (options.oneofs)
                            object.from = "bankAccountToken";
                    }
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken")) {
                        object.boostCardProgramToken = message.boostCardProgramToken;
                        if (options.oneofs)
                            object.from = "boostCardProgramToken";
                    }
                    if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                        object.paymentType = options.enums === String ? $root.visor.clientrpc.CreditLineAutoPayProfilePaymentType[message.paymentType] : message.paymentType;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                    return object;
                };

                /**
                 * Converts this UpdateCreditLineAutoPayProfileRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateCreditLineAutoPayProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateCreditLineAutoPayProfileRequest;
            })();

            /**
             * CreditLineAutoPayProfilePaymentType enum.
             * @name visor.clientrpc.CreditLineAutoPayProfilePaymentType
             * @enum {string}
             * @property {number} CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_INVALID=0 CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_INVALID value
             * @property {number} CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_STATEMENT_BALANCE=1 CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_STATEMENT_BALANCE value
             * @property {number} CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_MINIMUM_PAYMENT=2 CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_MINIMUM_PAYMENT value
             * @property {number} CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CUSTOM_AMOUNT=3 CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CUSTOM_AMOUNT value
             * @property {number} CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CURRENT_BALANCE=4 CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CURRENT_BALANCE value
             */
            clientrpc.CreditLineAutoPayProfilePaymentType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_INVALID"] = 0;
                values[valuesById[1] = "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_STATEMENT_BALANCE"] = 1;
                values[valuesById[2] = "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_MINIMUM_PAYMENT"] = 2;
                values[valuesById[3] = "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CUSTOM_AMOUNT"] = 3;
                values[valuesById[4] = "CREDIT_LINE_AUTO_PAY_PROFILE_PAYMENT_TYPE_CURRENT_BALANCE"] = 4;
                return values;
            })();

            clientrpc.UpdateCreditLineAutoPayProfileResponse = (function() {

                /**
                 * Properties of an UpdateCreditLineAutoPayProfileResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateCreditLineAutoPayProfileResponse
                 */

                /**
                 * Constructs a new UpdateCreditLineAutoPayProfileResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateCreditLineAutoPayProfileResponse.
                 * @implements IUpdateCreditLineAutoPayProfileResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileResponse=} [properties] Properties to set
                 */
                function UpdateCreditLineAutoPayProfileResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateCreditLineAutoPayProfileResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileResponse} UpdateCreditLineAutoPayProfileResponse instance
                 */
                UpdateCreditLineAutoPayProfileResponse.create = function create(properties) {
                    return new UpdateCreditLineAutoPayProfileResponse(properties);
                };

                /**
                 * Encodes the specified UpdateCreditLineAutoPayProfileResponse message. Does not implicitly {@link visor.clientrpc.UpdateCreditLineAutoPayProfileResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileResponse} message UpdateCreditLineAutoPayProfileResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCreditLineAutoPayProfileResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateCreditLineAutoPayProfileResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateCreditLineAutoPayProfileResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateCreditLineAutoPayProfileResponse} message UpdateCreditLineAutoPayProfileResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCreditLineAutoPayProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateCreditLineAutoPayProfileResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileResponse} UpdateCreditLineAutoPayProfileResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCreditLineAutoPayProfileResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateCreditLineAutoPayProfileResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateCreditLineAutoPayProfileResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileResponse} UpdateCreditLineAutoPayProfileResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCreditLineAutoPayProfileResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateCreditLineAutoPayProfileResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateCreditLineAutoPayProfileResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateCreditLineAutoPayProfileResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateCreditLineAutoPayProfileResponse} UpdateCreditLineAutoPayProfileResponse
                 */
                UpdateCreditLineAutoPayProfileResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateCreditLineAutoPayProfileResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateCreditLineAutoPayProfileResponse();
                };

                /**
                 * Creates a plain object from an UpdateCreditLineAutoPayProfileResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @static
                 * @param {visor.clientrpc.UpdateCreditLineAutoPayProfileResponse} message UpdateCreditLineAutoPayProfileResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateCreditLineAutoPayProfileResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateCreditLineAutoPayProfileResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateCreditLineAutoPayProfileResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateCreditLineAutoPayProfileResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateCreditLineAutoPayProfileResponse;
            })();

            clientrpc.GetCreditDashboardRequest = (function() {

                /**
                 * Properties of a GetCreditDashboardRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditDashboardRequest
                 * @property {boolean|null} [returnTestFixtures] GetCreditDashboardRequest returnTestFixtures
                 */

                /**
                 * Constructs a new GetCreditDashboardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditDashboardRequest.
                 * @implements IGetCreditDashboardRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditDashboardRequest=} [properties] Properties to set
                 */
                function GetCreditDashboardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditDashboardRequest returnTestFixtures.
                 * @member {boolean} returnTestFixtures
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @instance
                 */
                GetCreditDashboardRequest.prototype.returnTestFixtures = false;

                /**
                 * Creates a new GetCreditDashboardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditDashboardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditDashboardRequest} GetCreditDashboardRequest instance
                 */
                GetCreditDashboardRequest.create = function create(properties) {
                    return new GetCreditDashboardRequest(properties);
                };

                /**
                 * Encodes the specified GetCreditDashboardRequest message. Does not implicitly {@link visor.clientrpc.GetCreditDashboardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditDashboardRequest} message GetCreditDashboardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditDashboardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.returnTestFixtures);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditDashboardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditDashboardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditDashboardRequest} message GetCreditDashboardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditDashboardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditDashboardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditDashboardRequest} GetCreditDashboardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditDashboardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditDashboardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.returnTestFixtures = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditDashboardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditDashboardRequest} GetCreditDashboardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditDashboardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditDashboardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditDashboardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        if (typeof message.returnTestFixtures !== "boolean")
                            return "returnTestFixtures: boolean expected";
                    return null;
                };

                /**
                 * Creates a GetCreditDashboardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditDashboardRequest} GetCreditDashboardRequest
                 */
                GetCreditDashboardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditDashboardRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditDashboardRequest();
                    if (object.returnTestFixtures != null)
                        message.returnTestFixtures = Boolean(object.returnTestFixtures);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditDashboardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @static
                 * @param {visor.clientrpc.GetCreditDashboardRequest} message GetCreditDashboardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditDashboardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.returnTestFixtures = false;
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        object.returnTestFixtures = message.returnTestFixtures;
                    return object;
                };

                /**
                 * Converts this GetCreditDashboardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditDashboardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditDashboardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditDashboardRequest;
            })();

            clientrpc.GetCreditDashboardResponse = (function() {

                /**
                 * Properties of a GetCreditDashboardResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditDashboardResponse
                 * @property {number|Long|null} [ficoCreditScore] GetCreditDashboardResponse ficoCreditScore
                 */

                /**
                 * Constructs a new GetCreditDashboardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditDashboardResponse.
                 * @implements IGetCreditDashboardResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditDashboardResponse=} [properties] Properties to set
                 */
                function GetCreditDashboardResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditDashboardResponse ficoCreditScore.
                 * @member {number|Long} ficoCreditScore
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @instance
                 */
                GetCreditDashboardResponse.prototype.ficoCreditScore = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new GetCreditDashboardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditDashboardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditDashboardResponse} GetCreditDashboardResponse instance
                 */
                GetCreditDashboardResponse.create = function create(properties) {
                    return new GetCreditDashboardResponse(properties);
                };

                /**
                 * Encodes the specified GetCreditDashboardResponse message. Does not implicitly {@link visor.clientrpc.GetCreditDashboardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditDashboardResponse} message GetCreditDashboardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditDashboardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ficoCreditScore != null && message.hasOwnProperty("ficoCreditScore"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.ficoCreditScore);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditDashboardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditDashboardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditDashboardResponse} message GetCreditDashboardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditDashboardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditDashboardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditDashboardResponse} GetCreditDashboardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditDashboardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditDashboardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ficoCreditScore = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditDashboardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditDashboardResponse} GetCreditDashboardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditDashboardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditDashboardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditDashboardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ficoCreditScore != null && message.hasOwnProperty("ficoCreditScore"))
                        if (!$util.isInteger(message.ficoCreditScore) && !(message.ficoCreditScore && $util.isInteger(message.ficoCreditScore.low) && $util.isInteger(message.ficoCreditScore.high)))
                            return "ficoCreditScore: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a GetCreditDashboardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditDashboardResponse} GetCreditDashboardResponse
                 */
                GetCreditDashboardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditDashboardResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditDashboardResponse();
                    if (object.ficoCreditScore != null)
                        if ($util.Long)
                            (message.ficoCreditScore = $util.Long.fromValue(object.ficoCreditScore)).unsigned = false;
                        else if (typeof object.ficoCreditScore === "string")
                            message.ficoCreditScore = parseInt(object.ficoCreditScore, 10);
                        else if (typeof object.ficoCreditScore === "number")
                            message.ficoCreditScore = object.ficoCreditScore;
                        else if (typeof object.ficoCreditScore === "object")
                            message.ficoCreditScore = new $util.LongBits(object.ficoCreditScore.low >>> 0, object.ficoCreditScore.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditDashboardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @static
                 * @param {visor.clientrpc.GetCreditDashboardResponse} message GetCreditDashboardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditDashboardResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.ficoCreditScore = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.ficoCreditScore = options.longs === String ? "0" : 0;
                    if (message.ficoCreditScore != null && message.hasOwnProperty("ficoCreditScore"))
                        if (typeof message.ficoCreditScore === "number")
                            object.ficoCreditScore = options.longs === String ? String(message.ficoCreditScore) : message.ficoCreditScore;
                        else
                            object.ficoCreditScore = options.longs === String ? $util.Long.prototype.toString.call(message.ficoCreditScore) : options.longs === Number ? new $util.LongBits(message.ficoCreditScore.low >>> 0, message.ficoCreditScore.high >>> 0).toNumber() : message.ficoCreditScore;
                    return object;
                };

                /**
                 * Converts this GetCreditDashboardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditDashboardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditDashboardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditDashboardResponse;
            })();

            clientrpc.GetCreditLineTransactionsRequest = (function() {

                /**
                 * Properties of a GetCreditLineTransactionsRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditLineTransactionsRequest
                 * @property {number|null} [offset] GetCreditLineTransactionsRequest offset
                 * @property {google.protobuf.ITimestamp|null} [oldestTransactionPendingTimestamp] GetCreditLineTransactionsRequest oldestTransactionPendingTimestamp
                 * @property {boolean|null} [returnTestFixtures] GetCreditLineTransactionsRequest returnTestFixtures
                 * @property {number|null} [limit] GetCreditLineTransactionsRequest limit
                 */

                /**
                 * Constructs a new GetCreditLineTransactionsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditLineTransactionsRequest.
                 * @implements IGetCreditLineTransactionsRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditLineTransactionsRequest=} [properties] Properties to set
                 */
                function GetCreditLineTransactionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditLineTransactionsRequest offset.
                 * @member {number} offset
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @instance
                 */
                GetCreditLineTransactionsRequest.prototype.offset = 0;

                /**
                 * GetCreditLineTransactionsRequest oldestTransactionPendingTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} oldestTransactionPendingTimestamp
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @instance
                 */
                GetCreditLineTransactionsRequest.prototype.oldestTransactionPendingTimestamp = null;

                /**
                 * GetCreditLineTransactionsRequest returnTestFixtures.
                 * @member {boolean} returnTestFixtures
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @instance
                 */
                GetCreditLineTransactionsRequest.prototype.returnTestFixtures = false;

                /**
                 * GetCreditLineTransactionsRequest limit.
                 * @member {number} limit
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @instance
                 */
                GetCreditLineTransactionsRequest.prototype.limit = 0;

                /**
                 * Creates a new GetCreditLineTransactionsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineTransactionsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditLineTransactionsRequest} GetCreditLineTransactionsRequest instance
                 */
                GetCreditLineTransactionsRequest.create = function create(properties) {
                    return new GetCreditLineTransactionsRequest(properties);
                };

                /**
                 * Encodes the specified GetCreditLineTransactionsRequest message. Does not implicitly {@link visor.clientrpc.GetCreditLineTransactionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineTransactionsRequest} message GetCreditLineTransactionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineTransactionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.offset);
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.oldestTransactionPendingTimestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.returnTestFixtures);
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditLineTransactionsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditLineTransactionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineTransactionsRequest} message GetCreditLineTransactionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineTransactionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditLineTransactionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditLineTransactionsRequest} GetCreditLineTransactionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineTransactionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditLineTransactionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.offset = reader.int32();
                            break;
                        case 2:
                            message.oldestTransactionPendingTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.returnTestFixtures = reader.bool();
                            break;
                        case 4:
                            message.limit = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditLineTransactionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditLineTransactionsRequest} GetCreditLineTransactionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineTransactionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditLineTransactionsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditLineTransactionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.oldestTransactionPendingTimestamp);
                        if (error)
                            return "oldestTransactionPendingTimestamp." + error;
                    }
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        if (typeof message.returnTestFixtures !== "boolean")
                            return "returnTestFixtures: boolean expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    return null;
                };

                /**
                 * Creates a GetCreditLineTransactionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditLineTransactionsRequest} GetCreditLineTransactionsRequest
                 */
                GetCreditLineTransactionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditLineTransactionsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditLineTransactionsRequest();
                    if (object.offset != null)
                        message.offset = object.offset | 0;
                    if (object.oldestTransactionPendingTimestamp != null)
                        if (object.oldestTransactionPendingTimestamp === null)
                            message.oldestTransactionPendingTimestamp = {};
                        else
                            message.oldestTransactionPendingTimestamp = {
                                seconds: Math.floor(object.oldestTransactionPendingTimestamp.getTime() / 1000),
                                nanos: object.oldestTransactionPendingTimestamp.getMilliseconds() * 1000000
                            };
                    if (object.returnTestFixtures != null)
                        message.returnTestFixtures = Boolean(object.returnTestFixtures);
                    if (object.limit != null)
                        message.limit = object.limit | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditLineTransactionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @static
                 * @param {visor.clientrpc.GetCreditLineTransactionsRequest} message GetCreditLineTransactionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditLineTransactionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.offset = 0;
                        object.oldestTransactionPendingTimestamp = null;
                        object.returnTestFixtures = false;
                        object.limit = 0;
                    }
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.oldestTransactionPendingTimestamp != null && message.hasOwnProperty("oldestTransactionPendingTimestamp"))
                        object.oldestTransactionPendingTimestamp = new Date(message.oldestTransactionPendingTimestamp.seconds * 1000 + message.oldestTransactionPendingTimestamp.nanos / 1000000);
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        object.returnTestFixtures = message.returnTestFixtures;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    return object;
                };

                /**
                 * Converts this GetCreditLineTransactionsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditLineTransactionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditLineTransactionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditLineTransactionsRequest;
            })();

            clientrpc.GetCreditLineTransactionsResponse = (function() {

                /**
                 * Properties of a GetCreditLineTransactionsResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditLineTransactionsResponse
                 * @property {number|null} [transactionCount] GetCreditLineTransactionsResponse transactionCount
                 * @property {Array.<visor.clientrpc.IBoostTransaction>|null} [transactions] GetCreditLineTransactionsResponse transactions
                 */

                /**
                 * Constructs a new GetCreditLineTransactionsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditLineTransactionsResponse.
                 * @implements IGetCreditLineTransactionsResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditLineTransactionsResponse=} [properties] Properties to set
                 */
                function GetCreditLineTransactionsResponse(properties) {
                    this.transactions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditLineTransactionsResponse transactionCount.
                 * @member {number} transactionCount
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @instance
                 */
                GetCreditLineTransactionsResponse.prototype.transactionCount = 0;

                /**
                 * GetCreditLineTransactionsResponse transactions.
                 * @member {Array.<visor.clientrpc.IBoostTransaction>} transactions
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @instance
                 */
                GetCreditLineTransactionsResponse.prototype.transactions = $util.emptyArray;

                /**
                 * Creates a new GetCreditLineTransactionsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineTransactionsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditLineTransactionsResponse} GetCreditLineTransactionsResponse instance
                 */
                GetCreditLineTransactionsResponse.create = function create(properties) {
                    return new GetCreditLineTransactionsResponse(properties);
                };

                /**
                 * Encodes the specified GetCreditLineTransactionsResponse message. Does not implicitly {@link visor.clientrpc.GetCreditLineTransactionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineTransactionsResponse} message GetCreditLineTransactionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineTransactionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transactionCount != null && message.hasOwnProperty("transactionCount"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.transactionCount);
                    if (message.transactions != null && message.transactions.length)
                        for (var i = 0; i < message.transactions.length; ++i)
                            $root.visor.clientrpc.BoostTransaction.encode(message.transactions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditLineTransactionsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditLineTransactionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineTransactionsResponse} message GetCreditLineTransactionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineTransactionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditLineTransactionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditLineTransactionsResponse} GetCreditLineTransactionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineTransactionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditLineTransactionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.transactionCount = reader.int32();
                            break;
                        case 2:
                            if (!(message.transactions && message.transactions.length))
                                message.transactions = [];
                            message.transactions.push($root.visor.clientrpc.BoostTransaction.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditLineTransactionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditLineTransactionsResponse} GetCreditLineTransactionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineTransactionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditLineTransactionsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditLineTransactionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionCount != null && message.hasOwnProperty("transactionCount"))
                        if (!$util.isInteger(message.transactionCount))
                            return "transactionCount: integer expected";
                    if (message.transactions != null && message.hasOwnProperty("transactions")) {
                        if (!Array.isArray(message.transactions))
                            return "transactions: array expected";
                        for (var i = 0; i < message.transactions.length; ++i) {
                            var error = $root.visor.clientrpc.BoostTransaction.verify(message.transactions[i]);
                            if (error)
                                return "transactions." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetCreditLineTransactionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditLineTransactionsResponse} GetCreditLineTransactionsResponse
                 */
                GetCreditLineTransactionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditLineTransactionsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditLineTransactionsResponse();
                    if (object.transactionCount != null)
                        message.transactionCount = object.transactionCount | 0;
                    if (object.transactions) {
                        if (!Array.isArray(object.transactions))
                            throw TypeError(".visor.clientrpc.GetCreditLineTransactionsResponse.transactions: array expected");
                        message.transactions = [];
                        for (var i = 0; i < object.transactions.length; ++i) {
                            if (typeof object.transactions[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetCreditLineTransactionsResponse.transactions: object expected");
                            message.transactions[i] = $root.visor.clientrpc.BoostTransaction.fromObject(object.transactions[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditLineTransactionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @static
                 * @param {visor.clientrpc.GetCreditLineTransactionsResponse} message GetCreditLineTransactionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditLineTransactionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.transactions = [];
                    if (options.defaults)
                        object.transactionCount = 0;
                    if (message.transactionCount != null && message.hasOwnProperty("transactionCount"))
                        object.transactionCount = message.transactionCount;
                    if (message.transactions && message.transactions.length) {
                        object.transactions = [];
                        for (var j = 0; j < message.transactions.length; ++j)
                            object.transactions[j] = $root.visor.clientrpc.BoostTransaction.toObject(message.transactions[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetCreditLineTransactionsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditLineTransactionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditLineTransactionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditLineTransactionsResponse;
            })();

            clientrpc.RequestStatementCreditRequest = (function() {

                /**
                 * Properties of a RequestStatementCreditRequest.
                 * @memberof visor.clientrpc
                 * @interface IRequestStatementCreditRequest
                 * @property {number|Long|null} [rewardsPoints] RequestStatementCreditRequest rewardsPoints
                 */

                /**
                 * Constructs a new RequestStatementCreditRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a RequestStatementCreditRequest.
                 * @implements IRequestStatementCreditRequest
                 * @constructor
                 * @param {visor.clientrpc.IRequestStatementCreditRequest=} [properties] Properties to set
                 */
                function RequestStatementCreditRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestStatementCreditRequest rewardsPoints.
                 * @member {number|Long} rewardsPoints
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @instance
                 */
                RequestStatementCreditRequest.prototype.rewardsPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * RequestStatementCreditRequest redemption.
                 * @member {"rewardsPoints"|undefined} redemption
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @instance
                 */
                Object.defineProperty(RequestStatementCreditRequest.prototype, "redemption", {
                    get: $util.oneOfGetter($oneOfFields = ["rewardsPoints"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new RequestStatementCreditRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {visor.clientrpc.IRequestStatementCreditRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.RequestStatementCreditRequest} RequestStatementCreditRequest instance
                 */
                RequestStatementCreditRequest.create = function create(properties) {
                    return new RequestStatementCreditRequest(properties);
                };

                /**
                 * Encodes the specified RequestStatementCreditRequest message. Does not implicitly {@link visor.clientrpc.RequestStatementCreditRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {visor.clientrpc.IRequestStatementCreditRequest} message RequestStatementCreditRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStatementCreditRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rewardsPoints != null && message.hasOwnProperty("rewardsPoints"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.rewardsPoints);
                    return writer;
                };

                /**
                 * Encodes the specified RequestStatementCreditRequest message, length delimited. Does not implicitly {@link visor.clientrpc.RequestStatementCreditRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {visor.clientrpc.IRequestStatementCreditRequest} message RequestStatementCreditRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStatementCreditRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestStatementCreditRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.RequestStatementCreditRequest} RequestStatementCreditRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStatementCreditRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RequestStatementCreditRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.rewardsPoints = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestStatementCreditRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.RequestStatementCreditRequest} RequestStatementCreditRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStatementCreditRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestStatementCreditRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestStatementCreditRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.rewardsPoints != null && message.hasOwnProperty("rewardsPoints")) {
                        properties.redemption = 1;
                        if (!$util.isInteger(message.rewardsPoints) && !(message.rewardsPoints && $util.isInteger(message.rewardsPoints.low) && $util.isInteger(message.rewardsPoints.high)))
                            return "rewardsPoints: integer|Long expected";
                    }
                    return null;
                };

                /**
                 * Creates a RequestStatementCreditRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.RequestStatementCreditRequest} RequestStatementCreditRequest
                 */
                RequestStatementCreditRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.RequestStatementCreditRequest)
                        return object;
                    var message = new $root.visor.clientrpc.RequestStatementCreditRequest();
                    if (object.rewardsPoints != null)
                        if ($util.Long)
                            (message.rewardsPoints = $util.Long.fromValue(object.rewardsPoints)).unsigned = false;
                        else if (typeof object.rewardsPoints === "string")
                            message.rewardsPoints = parseInt(object.rewardsPoints, 10);
                        else if (typeof object.rewardsPoints === "number")
                            message.rewardsPoints = object.rewardsPoints;
                        else if (typeof object.rewardsPoints === "object")
                            message.rewardsPoints = new $util.LongBits(object.rewardsPoints.low >>> 0, object.rewardsPoints.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a RequestStatementCreditRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @static
                 * @param {visor.clientrpc.RequestStatementCreditRequest} message RequestStatementCreditRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestStatementCreditRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.rewardsPoints != null && message.hasOwnProperty("rewardsPoints")) {
                        if (typeof message.rewardsPoints === "number")
                            object.rewardsPoints = options.longs === String ? String(message.rewardsPoints) : message.rewardsPoints;
                        else
                            object.rewardsPoints = options.longs === String ? $util.Long.prototype.toString.call(message.rewardsPoints) : options.longs === Number ? new $util.LongBits(message.rewardsPoints.low >>> 0, message.rewardsPoints.high >>> 0).toNumber() : message.rewardsPoints;
                        if (options.oneofs)
                            object.redemption = "rewardsPoints";
                    }
                    return object;
                };

                /**
                 * Converts this RequestStatementCreditRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.RequestStatementCreditRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestStatementCreditRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestStatementCreditRequest;
            })();

            clientrpc.RequestStatementCreditResponse = (function() {

                /**
                 * Properties of a RequestStatementCreditResponse.
                 * @memberof visor.clientrpc
                 * @interface IRequestStatementCreditResponse
                 * @property {string|null} [statementCreditToken] RequestStatementCreditResponse statementCreditToken
                 */

                /**
                 * Constructs a new RequestStatementCreditResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a RequestStatementCreditResponse.
                 * @implements IRequestStatementCreditResponse
                 * @constructor
                 * @param {visor.clientrpc.IRequestStatementCreditResponse=} [properties] Properties to set
                 */
                function RequestStatementCreditResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RequestStatementCreditResponse statementCreditToken.
                 * @member {string} statementCreditToken
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @instance
                 */
                RequestStatementCreditResponse.prototype.statementCreditToken = "";

                /**
                 * Creates a new RequestStatementCreditResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {visor.clientrpc.IRequestStatementCreditResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.RequestStatementCreditResponse} RequestStatementCreditResponse instance
                 */
                RequestStatementCreditResponse.create = function create(properties) {
                    return new RequestStatementCreditResponse(properties);
                };

                /**
                 * Encodes the specified RequestStatementCreditResponse message. Does not implicitly {@link visor.clientrpc.RequestStatementCreditResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {visor.clientrpc.IRequestStatementCreditResponse} message RequestStatementCreditResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStatementCreditResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.statementCreditToken != null && message.hasOwnProperty("statementCreditToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.statementCreditToken);
                    return writer;
                };

                /**
                 * Encodes the specified RequestStatementCreditResponse message, length delimited. Does not implicitly {@link visor.clientrpc.RequestStatementCreditResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {visor.clientrpc.IRequestStatementCreditResponse} message RequestStatementCreditResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RequestStatementCreditResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RequestStatementCreditResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.RequestStatementCreditResponse} RequestStatementCreditResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStatementCreditResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RequestStatementCreditResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.statementCreditToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RequestStatementCreditResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.RequestStatementCreditResponse} RequestStatementCreditResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RequestStatementCreditResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RequestStatementCreditResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RequestStatementCreditResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.statementCreditToken != null && message.hasOwnProperty("statementCreditToken"))
                        if (!$util.isString(message.statementCreditToken))
                            return "statementCreditToken: string expected";
                    return null;
                };

                /**
                 * Creates a RequestStatementCreditResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.RequestStatementCreditResponse} RequestStatementCreditResponse
                 */
                RequestStatementCreditResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.RequestStatementCreditResponse)
                        return object;
                    var message = new $root.visor.clientrpc.RequestStatementCreditResponse();
                    if (object.statementCreditToken != null)
                        message.statementCreditToken = String(object.statementCreditToken);
                    return message;
                };

                /**
                 * Creates a plain object from a RequestStatementCreditResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @static
                 * @param {visor.clientrpc.RequestStatementCreditResponse} message RequestStatementCreditResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RequestStatementCreditResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.statementCreditToken = "";
                    if (message.statementCreditToken != null && message.hasOwnProperty("statementCreditToken"))
                        object.statementCreditToken = message.statementCreditToken;
                    return object;
                };

                /**
                 * Converts this RequestStatementCreditResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.RequestStatementCreditResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RequestStatementCreditResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RequestStatementCreditResponse;
            })();

            clientrpc.RewardsBalance = (function() {

                /**
                 * Properties of a RewardsBalance.
                 * @memberof visor.clientrpc
                 * @interface IRewardsBalance
                 * @property {google.protobuf.ITimestamp|null} [asOf] RewardsBalance asOf
                 * @property {number|Long|null} [current] RewardsBalance current
                 * @property {number|Long|null} [pending] RewardsBalance pending
                 * @property {number|Long|null} [available] RewardsBalance available
                 */

                /**
                 * Constructs a new RewardsBalance.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a RewardsBalance.
                 * @implements IRewardsBalance
                 * @constructor
                 * @param {visor.clientrpc.IRewardsBalance=} [properties] Properties to set
                 */
                function RewardsBalance(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RewardsBalance asOf.
                 * @member {google.protobuf.ITimestamp|null|undefined} asOf
                 * @memberof visor.clientrpc.RewardsBalance
                 * @instance
                 */
                RewardsBalance.prototype.asOf = null;

                /**
                 * RewardsBalance current.
                 * @member {number|Long} current
                 * @memberof visor.clientrpc.RewardsBalance
                 * @instance
                 */
                RewardsBalance.prototype.current = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RewardsBalance pending.
                 * @member {number|Long} pending
                 * @memberof visor.clientrpc.RewardsBalance
                 * @instance
                 */
                RewardsBalance.prototype.pending = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RewardsBalance available.
                 * @member {number|Long} available
                 * @memberof visor.clientrpc.RewardsBalance
                 * @instance
                 */
                RewardsBalance.prototype.available = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new RewardsBalance instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {visor.clientrpc.IRewardsBalance=} [properties] Properties to set
                 * @returns {visor.clientrpc.RewardsBalance} RewardsBalance instance
                 */
                RewardsBalance.create = function create(properties) {
                    return new RewardsBalance(properties);
                };

                /**
                 * Encodes the specified RewardsBalance message. Does not implicitly {@link visor.clientrpc.RewardsBalance.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {visor.clientrpc.IRewardsBalance} message RewardsBalance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RewardsBalance.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.asOf != null && message.hasOwnProperty("asOf"))
                        $root.google.protobuf.Timestamp.encode(message.asOf, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.current != null && message.hasOwnProperty("current"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.current);
                    if (message.pending != null && message.hasOwnProperty("pending"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.pending);
                    if (message.available != null && message.hasOwnProperty("available"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.available);
                    return writer;
                };

                /**
                 * Encodes the specified RewardsBalance message, length delimited. Does not implicitly {@link visor.clientrpc.RewardsBalance.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {visor.clientrpc.IRewardsBalance} message RewardsBalance message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RewardsBalance.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RewardsBalance message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.RewardsBalance} RewardsBalance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RewardsBalance.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RewardsBalance();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.asOf = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.current = reader.int64();
                            break;
                        case 3:
                            message.pending = reader.int64();
                            break;
                        case 4:
                            message.available = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RewardsBalance message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.RewardsBalance} RewardsBalance
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RewardsBalance.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RewardsBalance message.
                 * @function verify
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RewardsBalance.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.asOf != null && message.hasOwnProperty("asOf")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.asOf);
                        if (error)
                            return "asOf." + error;
                    }
                    if (message.current != null && message.hasOwnProperty("current"))
                        if (!$util.isInteger(message.current) && !(message.current && $util.isInteger(message.current.low) && $util.isInteger(message.current.high)))
                            return "current: integer|Long expected";
                    if (message.pending != null && message.hasOwnProperty("pending"))
                        if (!$util.isInteger(message.pending) && !(message.pending && $util.isInteger(message.pending.low) && $util.isInteger(message.pending.high)))
                            return "pending: integer|Long expected";
                    if (message.available != null && message.hasOwnProperty("available"))
                        if (!$util.isInteger(message.available) && !(message.available && $util.isInteger(message.available.low) && $util.isInteger(message.available.high)))
                            return "available: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a RewardsBalance message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.RewardsBalance} RewardsBalance
                 */
                RewardsBalance.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.RewardsBalance)
                        return object;
                    var message = new $root.visor.clientrpc.RewardsBalance();
                    if (object.asOf != null)
                        if (object.asOf === null)
                            message.asOf = {};
                        else
                            message.asOf = {
                                seconds: Math.floor(object.asOf.getTime() / 1000),
                                nanos: object.asOf.getMilliseconds() * 1000000
                            };
                    if (object.current != null)
                        if ($util.Long)
                            (message.current = $util.Long.fromValue(object.current)).unsigned = false;
                        else if (typeof object.current === "string")
                            message.current = parseInt(object.current, 10);
                        else if (typeof object.current === "number")
                            message.current = object.current;
                        else if (typeof object.current === "object")
                            message.current = new $util.LongBits(object.current.low >>> 0, object.current.high >>> 0).toNumber();
                    if (object.pending != null)
                        if ($util.Long)
                            (message.pending = $util.Long.fromValue(object.pending)).unsigned = false;
                        else if (typeof object.pending === "string")
                            message.pending = parseInt(object.pending, 10);
                        else if (typeof object.pending === "number")
                            message.pending = object.pending;
                        else if (typeof object.pending === "object")
                            message.pending = new $util.LongBits(object.pending.low >>> 0, object.pending.high >>> 0).toNumber();
                    if (object.available != null)
                        if ($util.Long)
                            (message.available = $util.Long.fromValue(object.available)).unsigned = false;
                        else if (typeof object.available === "string")
                            message.available = parseInt(object.available, 10);
                        else if (typeof object.available === "number")
                            message.available = object.available;
                        else if (typeof object.available === "object")
                            message.available = new $util.LongBits(object.available.low >>> 0, object.available.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a RewardsBalance message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.RewardsBalance
                 * @static
                 * @param {visor.clientrpc.RewardsBalance} message RewardsBalance
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RewardsBalance.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.asOf = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.current = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.current = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.pending = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.pending = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.available = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.available = options.longs === String ? "0" : 0;
                    }
                    if (message.asOf != null && message.hasOwnProperty("asOf"))
                        object.asOf = new Date(message.asOf.seconds * 1000 + message.asOf.nanos / 1000000);
                    if (message.current != null && message.hasOwnProperty("current"))
                        if (typeof message.current === "number")
                            object.current = options.longs === String ? String(message.current) : message.current;
                        else
                            object.current = options.longs === String ? $util.Long.prototype.toString.call(message.current) : options.longs === Number ? new $util.LongBits(message.current.low >>> 0, message.current.high >>> 0).toNumber() : message.current;
                    if (message.pending != null && message.hasOwnProperty("pending"))
                        if (typeof message.pending === "number")
                            object.pending = options.longs === String ? String(message.pending) : message.pending;
                        else
                            object.pending = options.longs === String ? $util.Long.prototype.toString.call(message.pending) : options.longs === Number ? new $util.LongBits(message.pending.low >>> 0, message.pending.high >>> 0).toNumber() : message.pending;
                    if (message.available != null && message.hasOwnProperty("available"))
                        if (typeof message.available === "number")
                            object.available = options.longs === String ? String(message.available) : message.available;
                        else
                            object.available = options.longs === String ? $util.Long.prototype.toString.call(message.available) : options.longs === Number ? new $util.LongBits(message.available.low >>> 0, message.available.high >>> 0).toNumber() : message.available;
                    return object;
                };

                /**
                 * Converts this RewardsBalance to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.RewardsBalance
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RewardsBalance.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RewardsBalance;
            })();

            clientrpc.BoostTransaction = (function() {

                /**
                 * Properties of a BoostTransaction.
                 * @memberof visor.clientrpc
                 * @interface IBoostTransaction
                 * @property {string|null} [uniqueIdentifier] BoostTransaction uniqueIdentifier
                 * @property {string|null} [token] BoostTransaction token
                 * @property {visor.clientrpc.BoostTransaction.Type|null} [type] BoostTransaction type
                 * @property {visor.clientrpc.BoostTransaction.State|null} [state] BoostTransaction state
                 * @property {string|null} [txDescriptor] BoostTransaction txDescriptor
                 * @property {visor.clientrpc.BoostTransaction.ICardInfo|null} [cardTxInfo] BoostTransaction cardTxInfo
                 * @property {visor.clientrpc.BoostTransaction.ITransferInfo|null} [transferTxInfo] BoostTransaction transferTxInfo
                 * @property {number|Long|null} [amount] BoostTransaction amount
                 * @property {visor.common.money.IAmount|null} [cashBack] BoostTransaction cashBack
                 * @property {number|null} [cashBackRate] BoostTransaction cashBackRate
                 * @property {google.protobuf.ITimestamp|null} [pendingTimestamp] BoostTransaction pendingTimestamp
                 * @property {google.protobuf.ITimestamp|null} [postedTimestamp] BoostTransaction postedTimestamp
                 * @property {google.protobuf.ITimestamp|null} [failedTimestamp] BoostTransaction failedTimestamp
                 */

                /**
                 * Constructs a new BoostTransaction.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BoostTransaction.
                 * @implements IBoostTransaction
                 * @constructor
                 * @param {visor.clientrpc.IBoostTransaction=} [properties] Properties to set
                 */
                function BoostTransaction(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BoostTransaction uniqueIdentifier.
                 * @member {string} uniqueIdentifier
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.uniqueIdentifier = "";

                /**
                 * BoostTransaction token.
                 * @member {string} token
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.token = "";

                /**
                 * BoostTransaction type.
                 * @member {visor.clientrpc.BoostTransaction.Type} type
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.type = 0;

                /**
                 * BoostTransaction state.
                 * @member {visor.clientrpc.BoostTransaction.State} state
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.state = 0;

                /**
                 * BoostTransaction txDescriptor.
                 * @member {string} txDescriptor
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.txDescriptor = "";

                /**
                 * BoostTransaction cardTxInfo.
                 * @member {visor.clientrpc.BoostTransaction.ICardInfo|null|undefined} cardTxInfo
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.cardTxInfo = null;

                /**
                 * BoostTransaction transferTxInfo.
                 * @member {visor.clientrpc.BoostTransaction.ITransferInfo|null|undefined} transferTxInfo
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.transferTxInfo = null;

                /**
                 * BoostTransaction amount.
                 * @member {number|Long} amount
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * BoostTransaction cashBack.
                 * @member {visor.common.money.IAmount|null|undefined} cashBack
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.cashBack = null;

                /**
                 * BoostTransaction cashBackRate.
                 * @member {number} cashBackRate
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.cashBackRate = 0;

                /**
                 * BoostTransaction pendingTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} pendingTimestamp
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.pendingTimestamp = null;

                /**
                 * BoostTransaction postedTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} postedTimestamp
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.postedTimestamp = null;

                /**
                 * BoostTransaction failedTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} failedTimestamp
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                BoostTransaction.prototype.failedTimestamp = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * BoostTransaction txInfo.
                 * @member {"cardTxInfo"|"transferTxInfo"|undefined} txInfo
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 */
                Object.defineProperty(BoostTransaction.prototype, "txInfo", {
                    get: $util.oneOfGetter($oneOfFields = ["cardTxInfo", "transferTxInfo"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new BoostTransaction instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {visor.clientrpc.IBoostTransaction=} [properties] Properties to set
                 * @returns {visor.clientrpc.BoostTransaction} BoostTransaction instance
                 */
                BoostTransaction.create = function create(properties) {
                    return new BoostTransaction(properties);
                };

                /**
                 * Encodes the specified BoostTransaction message. Does not implicitly {@link visor.clientrpc.BoostTransaction.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {visor.clientrpc.IBoostTransaction} message BoostTransaction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoostTransaction.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.uniqueIdentifier != null && message.hasOwnProperty("uniqueIdentifier"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.uniqueIdentifier);
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
                    if (message.type != null && message.hasOwnProperty("type"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
                    if (message.state != null && message.hasOwnProperty("state"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
                    if (message.txDescriptor != null && message.hasOwnProperty("txDescriptor"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.txDescriptor);
                    if (message.cardTxInfo != null && message.hasOwnProperty("cardTxInfo"))
                        $root.visor.clientrpc.BoostTransaction.CardInfo.encode(message.cardTxInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.transferTxInfo != null && message.hasOwnProperty("transferTxInfo"))
                        $root.visor.clientrpc.BoostTransaction.TransferInfo.encode(message.transferTxInfo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.amount);
                    if (message.cashBack != null && message.hasOwnProperty("cashBack"))
                        $root.visor.common.money.Amount.encode(message.cashBack, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.cashBackRate != null && message.hasOwnProperty("cashBackRate"))
                        writer.uint32(/* id 13, wireType 5 =*/109).float(message.cashBackRate);
                    if (message.pendingTimestamp != null && message.hasOwnProperty("pendingTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.pendingTimestamp, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                    if (message.postedTimestamp != null && message.hasOwnProperty("postedTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.postedTimestamp, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                    if (message.failedTimestamp != null && message.hasOwnProperty("failedTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.failedTimestamp, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified BoostTransaction message, length delimited. Does not implicitly {@link visor.clientrpc.BoostTransaction.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {visor.clientrpc.IBoostTransaction} message BoostTransaction message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoostTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BoostTransaction message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BoostTransaction} BoostTransaction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoostTransaction.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BoostTransaction();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.uniqueIdentifier = reader.string();
                            break;
                        case 2:
                            message.token = reader.string();
                            break;
                        case 3:
                            message.type = reader.int32();
                            break;
                        case 4:
                            message.state = reader.int32();
                            break;
                        case 5:
                            message.txDescriptor = reader.string();
                            break;
                        case 6:
                            message.cardTxInfo = $root.visor.clientrpc.BoostTransaction.CardInfo.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.transferTxInfo = $root.visor.clientrpc.BoostTransaction.TransferInfo.decode(reader, reader.uint32());
                            break;
                        case 11:
                            message.amount = reader.uint64();
                            break;
                        case 12:
                            message.cashBack = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 13:
                            message.cashBackRate = reader.float();
                            break;
                        case 14:
                            message.pendingTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 15:
                            message.postedTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 16:
                            message.failedTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BoostTransaction message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BoostTransaction} BoostTransaction
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoostTransaction.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BoostTransaction message.
                 * @function verify
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BoostTransaction.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.uniqueIdentifier != null && message.hasOwnProperty("uniqueIdentifier"))
                        if (!$util.isString(message.uniqueIdentifier))
                            return "uniqueIdentifier: string expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                        case 26:
                        case 17:
                        case 27:
                        case 28:
                        case 29:
                        case 30:
                        case 31:
                        case 32:
                        case 99:
                            break;
                        }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                    if (message.txDescriptor != null && message.hasOwnProperty("txDescriptor"))
                        if (!$util.isString(message.txDescriptor))
                            return "txDescriptor: string expected";
                    if (message.cardTxInfo != null && message.hasOwnProperty("cardTxInfo")) {
                        properties.txInfo = 1;
                        {
                            var error = $root.visor.clientrpc.BoostTransaction.CardInfo.verify(message.cardTxInfo);
                            if (error)
                                return "cardTxInfo." + error;
                        }
                    }
                    if (message.transferTxInfo != null && message.hasOwnProperty("transferTxInfo")) {
                        if (properties.txInfo === 1)
                            return "txInfo: multiple values";
                        properties.txInfo = 1;
                        {
                            var error = $root.visor.clientrpc.BoostTransaction.TransferInfo.verify(message.transferTxInfo);
                            if (error)
                                return "transferTxInfo." + error;
                        }
                    }
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                            return "amount: integer|Long expected";
                    if (message.cashBack != null && message.hasOwnProperty("cashBack")) {
                        var error = $root.visor.common.money.Amount.verify(message.cashBack);
                        if (error)
                            return "cashBack." + error;
                    }
                    if (message.cashBackRate != null && message.hasOwnProperty("cashBackRate"))
                        if (typeof message.cashBackRate !== "number")
                            return "cashBackRate: number expected";
                    if (message.pendingTimestamp != null && message.hasOwnProperty("pendingTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.pendingTimestamp);
                        if (error)
                            return "pendingTimestamp." + error;
                    }
                    if (message.postedTimestamp != null && message.hasOwnProperty("postedTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.postedTimestamp);
                        if (error)
                            return "postedTimestamp." + error;
                    }
                    if (message.failedTimestamp != null && message.hasOwnProperty("failedTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.failedTimestamp);
                        if (error)
                            return "failedTimestamp." + error;
                    }
                    return null;
                };

                /**
                 * Creates a BoostTransaction message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BoostTransaction} BoostTransaction
                 */
                BoostTransaction.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BoostTransaction)
                        return object;
                    var message = new $root.visor.clientrpc.BoostTransaction();
                    if (object.uniqueIdentifier != null)
                        message.uniqueIdentifier = String(object.uniqueIdentifier);
                    if (object.token != null)
                        message.token = String(object.token);
                    switch (object.type) {
                    case "BOOST_TRANSACTION_TYPE_INVALID":
                    case 0:
                        message.type = 0;
                        break;
                    case "BOOST_TRANSACTION_TYPE_ACH_TRANSFER_DEPOSIT":
                    case 1:
                        message.type = 1;
                        break;
                    case "BOOST_TRANSACTION_TYPE_ACH_TRANSFER_WITHDRAWAL":
                    case 2:
                        message.type = 2;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CASH_DEPOSIT":
                    case 3:
                        message.type = 3;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CASH_WITHDRAWAL":
                    case 4:
                        message.type = 4;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_CARD_CHARGE":
                    case 5:
                        message.type = 5;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_CARD_REFUND":
                    case 6:
                        message.type = 6;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_CARD_CASH_ADVANCE":
                    case 7:
                        message.type = 7;
                        break;
                    case "BOOST_TRANSACTION_TYPE_DEBIT_CARD_CHARGE":
                    case 8:
                        message.type = 8;
                        break;
                    case "BOOST_TRANSACTION_TYPE_DEBIT_CARD_REFUND":
                    case 9:
                        message.type = 9;
                        break;
                    case "BOOST_TRANSACTION_TYPE_DEBIT_CARD_PUSH_TO_DEBIT":
                    case 10:
                        message.type = 10;
                        break;
                    case "BOOST_TRANSACTION_TYPE_FEE":
                    case 11:
                        message.type = 11;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CASH_BACK":
                    case 12:
                        message.type = 12;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CASHBOOST_ADVANCE":
                    case 13:
                        message.type = 13;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CASHBOOST_REPAYMENT":
                    case 14:
                        message.type = 14;
                        break;
                    case "BOOST_TRANSACTION_TYPE_STATEMENT_CREDIT":
                    case 15:
                        message.type = 15;
                        break;
                    case "BOOST_TRANSACTION_TYPE_PROVISIONAL_DISPUTE_CREDIT":
                    case 16:
                        message.type = 16;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT":
                    case 18:
                        message.type = 18;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_LINE_DISBURSEMENT":
                    case 19:
                        message.type = 19;
                        break;
                    case "BOOST_TRANSACTION_TYPE_PAYMENT_FROM_THIRD_PARTY":
                    case 20:
                        message.type = 20;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT_TO_CASHBOOST":
                    case 21:
                        message.type = 21;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_GRID_PLUS_SUBSCRIPTION":
                    case 22:
                        message.type = 22;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_REACTIVATION_FEE":
                    case 23:
                        message.type = 23;
                        break;
                    case "BOOST_TRANSACTION_TYPE_SUBSCRIPTION_PAYMENT":
                    case 24:
                        message.type = 24;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_LINE_INTEREST_CHARGE":
                    case 25:
                        message.type = 25;
                        break;
                    case "BOOST_TRANSACTION_TYPE_CREDIT_LINE_ACH_PAYMENT":
                    case 26:
                        message.type = 26;
                        break;
                    case "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK":
                    case 17:
                        message.type = 17;
                        break;
                    case "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND":
                    case 27:
                        message.type = 27;
                        break;
                    case "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_ACH_RETURN":
                    case 28:
                        message.type = 28;
                        break;
                    case "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_ACH_RETURN":
                    case 29:
                        message.type = 29;
                        break;
                    case "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_NEGATIVE_SWEEP":
                    case 30:
                        message.type = 30;
                        break;
                    case "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_NEGATIVE_SWEEP":
                    case 31:
                        message.type = 31;
                        break;
                    case "BOOST_TRANSACTION_TYPE_BOOST_CARD_CREDIT":
                    case 32:
                        message.type = 32;
                        break;
                    case "BOOST_TRANSACTION_TYPE_GENERIC":
                    case 99:
                        message.type = 99;
                        break;
                    }
                    switch (object.state) {
                    case "BOOST_TRANSACTION_STATE_INVALID":
                    case 0:
                        message.state = 0;
                        break;
                    case "BOOST_TRANSACTION_STATE_PENDING":
                    case 1:
                        message.state = 1;
                        break;
                    case "BOOST_TRANSACTION_STATE_CANCELED":
                    case 2:
                        message.state = 2;
                        break;
                    case "BOOST_TRANSACTION_STATE_POSTED":
                    case 3:
                        message.state = 3;
                        break;
                    case "BOOST_TRANSACTION_STATE_FAILED":
                    case 4:
                        message.state = 4;
                        break;
                    case "BOOST_TRANSACTION_STATE_DISPUTE_SUBMITTED":
                    case 5:
                        message.state = 5;
                        break;
                    case "BOOST_TRANSACTION_STATE_DISPUTE_WON":
                    case 6:
                        message.state = 6;
                        break;
                    case "BOOST_TRANSACTION_STATE_DISPUTE_LOST":
                    case 7:
                        message.state = 7;
                        break;
                    }
                    if (object.txDescriptor != null)
                        message.txDescriptor = String(object.txDescriptor);
                    if (object.cardTxInfo != null) {
                        if (typeof object.cardTxInfo !== "object")
                            throw TypeError(".visor.clientrpc.BoostTransaction.cardTxInfo: object expected");
                        message.cardTxInfo = $root.visor.clientrpc.BoostTransaction.CardInfo.fromObject(object.cardTxInfo);
                    }
                    if (object.transferTxInfo != null) {
                        if (typeof object.transferTxInfo !== "object")
                            throw TypeError(".visor.clientrpc.BoostTransaction.transferTxInfo: object expected");
                        message.transferTxInfo = $root.visor.clientrpc.BoostTransaction.TransferInfo.fromObject(object.transferTxInfo);
                    }
                    if (object.amount != null)
                        if ($util.Long)
                            (message.amount = $util.Long.fromValue(object.amount)).unsigned = true;
                        else if (typeof object.amount === "string")
                            message.amount = parseInt(object.amount, 10);
                        else if (typeof object.amount === "number")
                            message.amount = object.amount;
                        else if (typeof object.amount === "object")
                            message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber(true);
                    if (object.cashBack != null) {
                        if (typeof object.cashBack !== "object")
                            throw TypeError(".visor.clientrpc.BoostTransaction.cashBack: object expected");
                        message.cashBack = $root.visor.common.money.Amount.fromObject(object.cashBack);
                    }
                    if (object.cashBackRate != null)
                        message.cashBackRate = Number(object.cashBackRate);
                    if (object.pendingTimestamp != null)
                        if (object.pendingTimestamp === null)
                            message.pendingTimestamp = {};
                        else
                            message.pendingTimestamp = {
                                seconds: Math.floor(object.pendingTimestamp.getTime() / 1000),
                                nanos: object.pendingTimestamp.getMilliseconds() * 1000000
                            };
                    if (object.postedTimestamp != null)
                        if (object.postedTimestamp === null)
                            message.postedTimestamp = {};
                        else
                            message.postedTimestamp = {
                                seconds: Math.floor(object.postedTimestamp.getTime() / 1000),
                                nanos: object.postedTimestamp.getMilliseconds() * 1000000
                            };
                    if (object.failedTimestamp != null)
                        if (object.failedTimestamp === null)
                            message.failedTimestamp = {};
                        else
                            message.failedTimestamp = {
                                seconds: Math.floor(object.failedTimestamp.getTime() / 1000),
                                nanos: object.failedTimestamp.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a BoostTransaction message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BoostTransaction
                 * @static
                 * @param {visor.clientrpc.BoostTransaction} message BoostTransaction
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BoostTransaction.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.uniqueIdentifier = "";
                        object.token = "";
                        object.type = options.enums === String ? "BOOST_TRANSACTION_TYPE_INVALID" : 0;
                        object.state = options.enums === String ? "BOOST_TRANSACTION_STATE_INVALID" : 0;
                        object.txDescriptor = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.amount = options.longs === String ? "0" : 0;
                        object.cashBack = null;
                        object.cashBackRate = 0;
                        object.pendingTimestamp = null;
                        object.postedTimestamp = null;
                        object.failedTimestamp = null;
                    }
                    if (message.uniqueIdentifier != null && message.hasOwnProperty("uniqueIdentifier"))
                        object.uniqueIdentifier = message.uniqueIdentifier;
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.visor.clientrpc.BoostTransaction.Type[message.type] : message.type;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.visor.clientrpc.BoostTransaction.State[message.state] : message.state;
                    if (message.txDescriptor != null && message.hasOwnProperty("txDescriptor"))
                        object.txDescriptor = message.txDescriptor;
                    if (message.cardTxInfo != null && message.hasOwnProperty("cardTxInfo")) {
                        object.cardTxInfo = $root.visor.clientrpc.BoostTransaction.CardInfo.toObject(message.cardTxInfo, options);
                        if (options.oneofs)
                            object.txInfo = "cardTxInfo";
                    }
                    if (message.transferTxInfo != null && message.hasOwnProperty("transferTxInfo")) {
                        object.transferTxInfo = $root.visor.clientrpc.BoostTransaction.TransferInfo.toObject(message.transferTxInfo, options);
                        if (options.oneofs)
                            object.txInfo = "transferTxInfo";
                    }
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        if (typeof message.amount === "number")
                            object.amount = options.longs === String ? String(message.amount) : message.amount;
                        else
                            object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber(true) : message.amount;
                    if (message.cashBack != null && message.hasOwnProperty("cashBack"))
                        object.cashBack = $root.visor.common.money.Amount.toObject(message.cashBack, options);
                    if (message.cashBackRate != null && message.hasOwnProperty("cashBackRate"))
                        object.cashBackRate = options.json && !isFinite(message.cashBackRate) ? String(message.cashBackRate) : message.cashBackRate;
                    if (message.pendingTimestamp != null && message.hasOwnProperty("pendingTimestamp"))
                        object.pendingTimestamp = new Date(message.pendingTimestamp.seconds * 1000 + message.pendingTimestamp.nanos / 1000000);
                    if (message.postedTimestamp != null && message.hasOwnProperty("postedTimestamp"))
                        object.postedTimestamp = new Date(message.postedTimestamp.seconds * 1000 + message.postedTimestamp.nanos / 1000000);
                    if (message.failedTimestamp != null && message.hasOwnProperty("failedTimestamp"))
                        object.failedTimestamp = new Date(message.failedTimestamp.seconds * 1000 + message.failedTimestamp.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this BoostTransaction to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BoostTransaction
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BoostTransaction.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                BoostTransaction.CardInfo = (function() {

                    /**
                     * Properties of a CardInfo.
                     * @memberof visor.clientrpc.BoostTransaction
                     * @interface ICardInfo
                     * @property {string|null} [merchantName] CardInfo merchantName
                     * @property {string|null} [merchantLogoUrl] CardInfo merchantLogoUrl
                     * @property {string|null} [merchantCategory] CardInfo merchantCategory
                     * @property {string|null} [merchantSubCategory] CardInfo merchantSubCategory
                     * @property {string|null} [merchantWebsite] CardInfo merchantWebsite
                     */

                    /**
                     * Constructs a new CardInfo.
                     * @memberof visor.clientrpc.BoostTransaction
                     * @classdesc Represents a CardInfo.
                     * @implements ICardInfo
                     * @constructor
                     * @param {visor.clientrpc.BoostTransaction.ICardInfo=} [properties] Properties to set
                     */
                    function CardInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CardInfo merchantName.
                     * @member {string} merchantName
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @instance
                     */
                    CardInfo.prototype.merchantName = "";

                    /**
                     * CardInfo merchantLogoUrl.
                     * @member {string} merchantLogoUrl
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @instance
                     */
                    CardInfo.prototype.merchantLogoUrl = "";

                    /**
                     * CardInfo merchantCategory.
                     * @member {string} merchantCategory
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @instance
                     */
                    CardInfo.prototype.merchantCategory = "";

                    /**
                     * CardInfo merchantSubCategory.
                     * @member {string} merchantSubCategory
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @instance
                     */
                    CardInfo.prototype.merchantSubCategory = "";

                    /**
                     * CardInfo merchantWebsite.
                     * @member {string} merchantWebsite
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @instance
                     */
                    CardInfo.prototype.merchantWebsite = "";

                    /**
                     * Creates a new CardInfo instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.ICardInfo=} [properties] Properties to set
                     * @returns {visor.clientrpc.BoostTransaction.CardInfo} CardInfo instance
                     */
                    CardInfo.create = function create(properties) {
                        return new CardInfo(properties);
                    };

                    /**
                     * Encodes the specified CardInfo message. Does not implicitly {@link visor.clientrpc.BoostTransaction.CardInfo.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.ICardInfo} message CardInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CardInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.merchantName != null && message.hasOwnProperty("merchantName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.merchantName);
                        if (message.merchantLogoUrl != null && message.hasOwnProperty("merchantLogoUrl"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.merchantLogoUrl);
                        if (message.merchantCategory != null && message.hasOwnProperty("merchantCategory"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.merchantCategory);
                        if (message.merchantSubCategory != null && message.hasOwnProperty("merchantSubCategory"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.merchantSubCategory);
                        if (message.merchantWebsite != null && message.hasOwnProperty("merchantWebsite"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.merchantWebsite);
                        return writer;
                    };

                    /**
                     * Encodes the specified CardInfo message, length delimited. Does not implicitly {@link visor.clientrpc.BoostTransaction.CardInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.ICardInfo} message CardInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CardInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CardInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.BoostTransaction.CardInfo} CardInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CardInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BoostTransaction.CardInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.merchantName = reader.string();
                                break;
                            case 2:
                                message.merchantLogoUrl = reader.string();
                                break;
                            case 3:
                                message.merchantCategory = reader.string();
                                break;
                            case 4:
                                message.merchantSubCategory = reader.string();
                                break;
                            case 5:
                                message.merchantWebsite = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CardInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.BoostTransaction.CardInfo} CardInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CardInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CardInfo message.
                     * @function verify
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CardInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.merchantName != null && message.hasOwnProperty("merchantName"))
                            if (!$util.isString(message.merchantName))
                                return "merchantName: string expected";
                        if (message.merchantLogoUrl != null && message.hasOwnProperty("merchantLogoUrl"))
                            if (!$util.isString(message.merchantLogoUrl))
                                return "merchantLogoUrl: string expected";
                        if (message.merchantCategory != null && message.hasOwnProperty("merchantCategory"))
                            if (!$util.isString(message.merchantCategory))
                                return "merchantCategory: string expected";
                        if (message.merchantSubCategory != null && message.hasOwnProperty("merchantSubCategory"))
                            if (!$util.isString(message.merchantSubCategory))
                                return "merchantSubCategory: string expected";
                        if (message.merchantWebsite != null && message.hasOwnProperty("merchantWebsite"))
                            if (!$util.isString(message.merchantWebsite))
                                return "merchantWebsite: string expected";
                        return null;
                    };

                    /**
                     * Creates a CardInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.BoostTransaction.CardInfo} CardInfo
                     */
                    CardInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.BoostTransaction.CardInfo)
                            return object;
                        var message = new $root.visor.clientrpc.BoostTransaction.CardInfo();
                        if (object.merchantName != null)
                            message.merchantName = String(object.merchantName);
                        if (object.merchantLogoUrl != null)
                            message.merchantLogoUrl = String(object.merchantLogoUrl);
                        if (object.merchantCategory != null)
                            message.merchantCategory = String(object.merchantCategory);
                        if (object.merchantSubCategory != null)
                            message.merchantSubCategory = String(object.merchantSubCategory);
                        if (object.merchantWebsite != null)
                            message.merchantWebsite = String(object.merchantWebsite);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CardInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.CardInfo} message CardInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CardInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.merchantName = "";
                            object.merchantLogoUrl = "";
                            object.merchantCategory = "";
                            object.merchantSubCategory = "";
                            object.merchantWebsite = "";
                        }
                        if (message.merchantName != null && message.hasOwnProperty("merchantName"))
                            object.merchantName = message.merchantName;
                        if (message.merchantLogoUrl != null && message.hasOwnProperty("merchantLogoUrl"))
                            object.merchantLogoUrl = message.merchantLogoUrl;
                        if (message.merchantCategory != null && message.hasOwnProperty("merchantCategory"))
                            object.merchantCategory = message.merchantCategory;
                        if (message.merchantSubCategory != null && message.hasOwnProperty("merchantSubCategory"))
                            object.merchantSubCategory = message.merchantSubCategory;
                        if (message.merchantWebsite != null && message.hasOwnProperty("merchantWebsite"))
                            object.merchantWebsite = message.merchantWebsite;
                        return object;
                    };

                    /**
                     * Converts this CardInfo to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.BoostTransaction.CardInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CardInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CardInfo;
                })();

                BoostTransaction.TransferInfo = (function() {

                    /**
                     * Properties of a TransferInfo.
                     * @memberof visor.clientrpc.BoostTransaction
                     * @interface ITransferInfo
                     * @property {string|null} [senderAccountNumber] TransferInfo senderAccountNumber
                     * @property {string|null} [senderRoutingNumber] TransferInfo senderRoutingNumber
                     * @property {string|null} [senderCompanyName] TransferInfo senderCompanyName
                     * @property {string|null} [transferType] TransferInfo transferType
                     * @property {string|null} [recipientName] TransferInfo recipientName
                     * @property {string|null} [expectedPostingDate] TransferInfo expectedPostingDate
                     * @property {string|null} [additionalInfo] TransferInfo additionalInfo
                     */

                    /**
                     * Constructs a new TransferInfo.
                     * @memberof visor.clientrpc.BoostTransaction
                     * @classdesc Represents a TransferInfo.
                     * @implements ITransferInfo
                     * @constructor
                     * @param {visor.clientrpc.BoostTransaction.ITransferInfo=} [properties] Properties to set
                     */
                    function TransferInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TransferInfo senderAccountNumber.
                     * @member {string} senderAccountNumber
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     */
                    TransferInfo.prototype.senderAccountNumber = "";

                    /**
                     * TransferInfo senderRoutingNumber.
                     * @member {string} senderRoutingNumber
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     */
                    TransferInfo.prototype.senderRoutingNumber = "";

                    /**
                     * TransferInfo senderCompanyName.
                     * @member {string} senderCompanyName
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     */
                    TransferInfo.prototype.senderCompanyName = "";

                    /**
                     * TransferInfo transferType.
                     * @member {string} transferType
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     */
                    TransferInfo.prototype.transferType = "";

                    /**
                     * TransferInfo recipientName.
                     * @member {string} recipientName
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     */
                    TransferInfo.prototype.recipientName = "";

                    /**
                     * TransferInfo expectedPostingDate.
                     * @member {string} expectedPostingDate
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     */
                    TransferInfo.prototype.expectedPostingDate = "";

                    /**
                     * TransferInfo additionalInfo.
                     * @member {string} additionalInfo
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     */
                    TransferInfo.prototype.additionalInfo = "";

                    /**
                     * Creates a new TransferInfo instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.ITransferInfo=} [properties] Properties to set
                     * @returns {visor.clientrpc.BoostTransaction.TransferInfo} TransferInfo instance
                     */
                    TransferInfo.create = function create(properties) {
                        return new TransferInfo(properties);
                    };

                    /**
                     * Encodes the specified TransferInfo message. Does not implicitly {@link visor.clientrpc.BoostTransaction.TransferInfo.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.ITransferInfo} message TransferInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TransferInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.senderAccountNumber != null && message.hasOwnProperty("senderAccountNumber"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.senderAccountNumber);
                        if (message.senderRoutingNumber != null && message.hasOwnProperty("senderRoutingNumber"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.senderRoutingNumber);
                        if (message.senderCompanyName != null && message.hasOwnProperty("senderCompanyName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.senderCompanyName);
                        if (message.transferType != null && message.hasOwnProperty("transferType"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.transferType);
                        if (message.recipientName != null && message.hasOwnProperty("recipientName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.recipientName);
                        if (message.expectedPostingDate != null && message.hasOwnProperty("expectedPostingDate"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.expectedPostingDate);
                        if (message.additionalInfo != null && message.hasOwnProperty("additionalInfo"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.additionalInfo);
                        return writer;
                    };

                    /**
                     * Encodes the specified TransferInfo message, length delimited. Does not implicitly {@link visor.clientrpc.BoostTransaction.TransferInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.ITransferInfo} message TransferInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TransferInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TransferInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.BoostTransaction.TransferInfo} TransferInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TransferInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BoostTransaction.TransferInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.senderAccountNumber = reader.string();
                                break;
                            case 2:
                                message.senderRoutingNumber = reader.string();
                                break;
                            case 3:
                                message.senderCompanyName = reader.string();
                                break;
                            case 4:
                                message.transferType = reader.string();
                                break;
                            case 5:
                                message.recipientName = reader.string();
                                break;
                            case 6:
                                message.expectedPostingDate = reader.string();
                                break;
                            case 7:
                                message.additionalInfo = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TransferInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.BoostTransaction.TransferInfo} TransferInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TransferInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TransferInfo message.
                     * @function verify
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TransferInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.senderAccountNumber != null && message.hasOwnProperty("senderAccountNumber"))
                            if (!$util.isString(message.senderAccountNumber))
                                return "senderAccountNumber: string expected";
                        if (message.senderRoutingNumber != null && message.hasOwnProperty("senderRoutingNumber"))
                            if (!$util.isString(message.senderRoutingNumber))
                                return "senderRoutingNumber: string expected";
                        if (message.senderCompanyName != null && message.hasOwnProperty("senderCompanyName"))
                            if (!$util.isString(message.senderCompanyName))
                                return "senderCompanyName: string expected";
                        if (message.transferType != null && message.hasOwnProperty("transferType"))
                            if (!$util.isString(message.transferType))
                                return "transferType: string expected";
                        if (message.recipientName != null && message.hasOwnProperty("recipientName"))
                            if (!$util.isString(message.recipientName))
                                return "recipientName: string expected";
                        if (message.expectedPostingDate != null && message.hasOwnProperty("expectedPostingDate"))
                            if (!$util.isString(message.expectedPostingDate))
                                return "expectedPostingDate: string expected";
                        if (message.additionalInfo != null && message.hasOwnProperty("additionalInfo"))
                            if (!$util.isString(message.additionalInfo))
                                return "additionalInfo: string expected";
                        return null;
                    };

                    /**
                     * Creates a TransferInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.BoostTransaction.TransferInfo} TransferInfo
                     */
                    TransferInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.BoostTransaction.TransferInfo)
                            return object;
                        var message = new $root.visor.clientrpc.BoostTransaction.TransferInfo();
                        if (object.senderAccountNumber != null)
                            message.senderAccountNumber = String(object.senderAccountNumber);
                        if (object.senderRoutingNumber != null)
                            message.senderRoutingNumber = String(object.senderRoutingNumber);
                        if (object.senderCompanyName != null)
                            message.senderCompanyName = String(object.senderCompanyName);
                        if (object.transferType != null)
                            message.transferType = String(object.transferType);
                        if (object.recipientName != null)
                            message.recipientName = String(object.recipientName);
                        if (object.expectedPostingDate != null)
                            message.expectedPostingDate = String(object.expectedPostingDate);
                        if (object.additionalInfo != null)
                            message.additionalInfo = String(object.additionalInfo);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TransferInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @static
                     * @param {visor.clientrpc.BoostTransaction.TransferInfo} message TransferInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TransferInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.senderAccountNumber = "";
                            object.senderRoutingNumber = "";
                            object.senderCompanyName = "";
                            object.transferType = "";
                            object.recipientName = "";
                            object.expectedPostingDate = "";
                            object.additionalInfo = "";
                        }
                        if (message.senderAccountNumber != null && message.hasOwnProperty("senderAccountNumber"))
                            object.senderAccountNumber = message.senderAccountNumber;
                        if (message.senderRoutingNumber != null && message.hasOwnProperty("senderRoutingNumber"))
                            object.senderRoutingNumber = message.senderRoutingNumber;
                        if (message.senderCompanyName != null && message.hasOwnProperty("senderCompanyName"))
                            object.senderCompanyName = message.senderCompanyName;
                        if (message.transferType != null && message.hasOwnProperty("transferType"))
                            object.transferType = message.transferType;
                        if (message.recipientName != null && message.hasOwnProperty("recipientName"))
                            object.recipientName = message.recipientName;
                        if (message.expectedPostingDate != null && message.hasOwnProperty("expectedPostingDate"))
                            object.expectedPostingDate = message.expectedPostingDate;
                        if (message.additionalInfo != null && message.hasOwnProperty("additionalInfo"))
                            object.additionalInfo = message.additionalInfo;
                        return object;
                    };

                    /**
                     * Converts this TransferInfo to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.BoostTransaction.TransferInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TransferInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return TransferInfo;
                })();

                /**
                 * State enum.
                 * @name visor.clientrpc.BoostTransaction.State
                 * @enum {string}
                 * @property {number} BOOST_TRANSACTION_STATE_INVALID=0 BOOST_TRANSACTION_STATE_INVALID value
                 * @property {number} BOOST_TRANSACTION_STATE_PENDING=1 BOOST_TRANSACTION_STATE_PENDING value
                 * @property {number} BOOST_TRANSACTION_STATE_CANCELED=2 BOOST_TRANSACTION_STATE_CANCELED value
                 * @property {number} BOOST_TRANSACTION_STATE_POSTED=3 BOOST_TRANSACTION_STATE_POSTED value
                 * @property {number} BOOST_TRANSACTION_STATE_FAILED=4 BOOST_TRANSACTION_STATE_FAILED value
                 * @property {number} BOOST_TRANSACTION_STATE_DISPUTE_SUBMITTED=5 BOOST_TRANSACTION_STATE_DISPUTE_SUBMITTED value
                 * @property {number} BOOST_TRANSACTION_STATE_DISPUTE_WON=6 BOOST_TRANSACTION_STATE_DISPUTE_WON value
                 * @property {number} BOOST_TRANSACTION_STATE_DISPUTE_LOST=7 BOOST_TRANSACTION_STATE_DISPUTE_LOST value
                 */
                BoostTransaction.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "BOOST_TRANSACTION_STATE_INVALID"] = 0;
                    values[valuesById[1] = "BOOST_TRANSACTION_STATE_PENDING"] = 1;
                    values[valuesById[2] = "BOOST_TRANSACTION_STATE_CANCELED"] = 2;
                    values[valuesById[3] = "BOOST_TRANSACTION_STATE_POSTED"] = 3;
                    values[valuesById[4] = "BOOST_TRANSACTION_STATE_FAILED"] = 4;
                    values[valuesById[5] = "BOOST_TRANSACTION_STATE_DISPUTE_SUBMITTED"] = 5;
                    values[valuesById[6] = "BOOST_TRANSACTION_STATE_DISPUTE_WON"] = 6;
                    values[valuesById[7] = "BOOST_TRANSACTION_STATE_DISPUTE_LOST"] = 7;
                    return values;
                })();

                /**
                 * Type enum.
                 * @name visor.clientrpc.BoostTransaction.Type
                 * @enum {string}
                 * @property {number} BOOST_TRANSACTION_TYPE_INVALID=0 BOOST_TRANSACTION_TYPE_INVALID value
                 * @property {number} BOOST_TRANSACTION_TYPE_ACH_TRANSFER_DEPOSIT=1 BOOST_TRANSACTION_TYPE_ACH_TRANSFER_DEPOSIT value
                 * @property {number} BOOST_TRANSACTION_TYPE_ACH_TRANSFER_WITHDRAWAL=2 BOOST_TRANSACTION_TYPE_ACH_TRANSFER_WITHDRAWAL value
                 * @property {number} BOOST_TRANSACTION_TYPE_CASH_DEPOSIT=3 BOOST_TRANSACTION_TYPE_CASH_DEPOSIT value
                 * @property {number} BOOST_TRANSACTION_TYPE_CASH_WITHDRAWAL=4 BOOST_TRANSACTION_TYPE_CASH_WITHDRAWAL value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_CARD_CHARGE=5 BOOST_TRANSACTION_TYPE_CREDIT_CARD_CHARGE value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_CARD_REFUND=6 BOOST_TRANSACTION_TYPE_CREDIT_CARD_REFUND value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_CARD_CASH_ADVANCE=7 BOOST_TRANSACTION_TYPE_CREDIT_CARD_CASH_ADVANCE value
                 * @property {number} BOOST_TRANSACTION_TYPE_DEBIT_CARD_CHARGE=8 BOOST_TRANSACTION_TYPE_DEBIT_CARD_CHARGE value
                 * @property {number} BOOST_TRANSACTION_TYPE_DEBIT_CARD_REFUND=9 BOOST_TRANSACTION_TYPE_DEBIT_CARD_REFUND value
                 * @property {number} BOOST_TRANSACTION_TYPE_DEBIT_CARD_PUSH_TO_DEBIT=10 BOOST_TRANSACTION_TYPE_DEBIT_CARD_PUSH_TO_DEBIT value
                 * @property {number} BOOST_TRANSACTION_TYPE_FEE=11 BOOST_TRANSACTION_TYPE_FEE value
                 * @property {number} BOOST_TRANSACTION_TYPE_CASH_BACK=12 BOOST_TRANSACTION_TYPE_CASH_BACK value
                 * @property {number} BOOST_TRANSACTION_TYPE_CASHBOOST_ADVANCE=13 BOOST_TRANSACTION_TYPE_CASHBOOST_ADVANCE value
                 * @property {number} BOOST_TRANSACTION_TYPE_CASHBOOST_REPAYMENT=14 BOOST_TRANSACTION_TYPE_CASHBOOST_REPAYMENT value
                 * @property {number} BOOST_TRANSACTION_TYPE_STATEMENT_CREDIT=15 BOOST_TRANSACTION_TYPE_STATEMENT_CREDIT value
                 * @property {number} BOOST_TRANSACTION_TYPE_PROVISIONAL_DISPUTE_CREDIT=16 BOOST_TRANSACTION_TYPE_PROVISIONAL_DISPUTE_CREDIT value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT=18 BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_LINE_DISBURSEMENT=19 BOOST_TRANSACTION_TYPE_CREDIT_LINE_DISBURSEMENT value
                 * @property {number} BOOST_TRANSACTION_TYPE_PAYMENT_FROM_THIRD_PARTY=20 BOOST_TRANSACTION_TYPE_PAYMENT_FROM_THIRD_PARTY value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT_TO_CASHBOOST=21 BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT_TO_CASHBOOST value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_GRID_PLUS_SUBSCRIPTION=22 BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_GRID_PLUS_SUBSCRIPTION value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_REACTIVATION_FEE=23 BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_REACTIVATION_FEE value
                 * @property {number} BOOST_TRANSACTION_TYPE_SUBSCRIPTION_PAYMENT=24 BOOST_TRANSACTION_TYPE_SUBSCRIPTION_PAYMENT value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_LINE_INTEREST_CHARGE=25 BOOST_TRANSACTION_TYPE_CREDIT_LINE_INTEREST_CHARGE value
                 * @property {number} BOOST_TRANSACTION_TYPE_CREDIT_LINE_ACH_PAYMENT=26 BOOST_TRANSACTION_TYPE_CREDIT_LINE_ACH_PAYMENT value
                 * @property {number} BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK=17 BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK value
                 * @property {number} BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND=27 BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND value
                 * @property {number} BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_ACH_RETURN=28 BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_ACH_RETURN value
                 * @property {number} BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_ACH_RETURN=29 BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_ACH_RETURN value
                 * @property {number} BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_NEGATIVE_SWEEP=30 BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_NEGATIVE_SWEEP value
                 * @property {number} BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_NEGATIVE_SWEEP=31 BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_NEGATIVE_SWEEP value
                 * @property {number} BOOST_TRANSACTION_TYPE_BOOST_CARD_CREDIT=32 BOOST_TRANSACTION_TYPE_BOOST_CARD_CREDIT value
                 * @property {number} BOOST_TRANSACTION_TYPE_GENERIC=99 BOOST_TRANSACTION_TYPE_GENERIC value
                 */
                BoostTransaction.Type = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "BOOST_TRANSACTION_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "BOOST_TRANSACTION_TYPE_ACH_TRANSFER_DEPOSIT"] = 1;
                    values[valuesById[2] = "BOOST_TRANSACTION_TYPE_ACH_TRANSFER_WITHDRAWAL"] = 2;
                    values[valuesById[3] = "BOOST_TRANSACTION_TYPE_CASH_DEPOSIT"] = 3;
                    values[valuesById[4] = "BOOST_TRANSACTION_TYPE_CASH_WITHDRAWAL"] = 4;
                    values[valuesById[5] = "BOOST_TRANSACTION_TYPE_CREDIT_CARD_CHARGE"] = 5;
                    values[valuesById[6] = "BOOST_TRANSACTION_TYPE_CREDIT_CARD_REFUND"] = 6;
                    values[valuesById[7] = "BOOST_TRANSACTION_TYPE_CREDIT_CARD_CASH_ADVANCE"] = 7;
                    values[valuesById[8] = "BOOST_TRANSACTION_TYPE_DEBIT_CARD_CHARGE"] = 8;
                    values[valuesById[9] = "BOOST_TRANSACTION_TYPE_DEBIT_CARD_REFUND"] = 9;
                    values[valuesById[10] = "BOOST_TRANSACTION_TYPE_DEBIT_CARD_PUSH_TO_DEBIT"] = 10;
                    values[valuesById[11] = "BOOST_TRANSACTION_TYPE_FEE"] = 11;
                    values[valuesById[12] = "BOOST_TRANSACTION_TYPE_CASH_BACK"] = 12;
                    values[valuesById[13] = "BOOST_TRANSACTION_TYPE_CASHBOOST_ADVANCE"] = 13;
                    values[valuesById[14] = "BOOST_TRANSACTION_TYPE_CASHBOOST_REPAYMENT"] = 14;
                    values[valuesById[15] = "BOOST_TRANSACTION_TYPE_STATEMENT_CREDIT"] = 15;
                    values[valuesById[16] = "BOOST_TRANSACTION_TYPE_PROVISIONAL_DISPUTE_CREDIT"] = 16;
                    values[valuesById[18] = "BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT"] = 18;
                    values[valuesById[19] = "BOOST_TRANSACTION_TYPE_CREDIT_LINE_DISBURSEMENT"] = 19;
                    values[valuesById[20] = "BOOST_TRANSACTION_TYPE_PAYMENT_FROM_THIRD_PARTY"] = 20;
                    values[valuesById[21] = "BOOST_TRANSACTION_TYPE_CREDIT_LINE_PAYMENT_TO_CASHBOOST"] = 21;
                    values[valuesById[22] = "BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_GRID_PLUS_SUBSCRIPTION"] = 22;
                    values[valuesById[23] = "BOOST_TRANSACTION_TYPE_CREDIT_LINE_CONVERSION_REACTIVATION_FEE"] = 23;
                    values[valuesById[24] = "BOOST_TRANSACTION_TYPE_SUBSCRIPTION_PAYMENT"] = 24;
                    values[valuesById[25] = "BOOST_TRANSACTION_TYPE_CREDIT_LINE_INTEREST_CHARGE"] = 25;
                    values[valuesById[26] = "BOOST_TRANSACTION_TYPE_CREDIT_LINE_ACH_PAYMENT"] = 26;
                    values[valuesById[17] = "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK"] = 17;
                    values[valuesById[27] = "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND"] = 27;
                    values[valuesById[28] = "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_ACH_RETURN"] = 28;
                    values[valuesById[29] = "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_ACH_RETURN"] = 29;
                    values[valuesById[30] = "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_CLAW_BACK_NEGATIVE_SWEEP"] = 30;
                    values[valuesById[31] = "BOOST_TRANSACTION_TYPE_BALANCE_CORRECTION_REFUND_NEGATIVE_SWEEP"] = 31;
                    values[valuesById[32] = "BOOST_TRANSACTION_TYPE_BOOST_CARD_CREDIT"] = 32;
                    values[valuesById[99] = "BOOST_TRANSACTION_TYPE_GENERIC"] = 99;
                    return values;
                })();

                return BoostTransaction;
            })();

            clientrpc.IneligibilityReason = (function() {

                /**
                 * Properties of an IneligibilityReason.
                 * @memberof visor.clientrpc
                 * @interface IIneligibilityReason
                 * @property {visor.clientrpc.IneligibilityReason.IOutstandingSubscription|null} [hasOutstandingSubscription] IneligibilityReason hasOutstandingSubscription
                 * @property {visor.clientrpc.IneligibilityReason.IOutstandingAdvance|null} [hasOutstandingAdvance] IneligibilityReason hasOutstandingAdvance
                 * @property {visor.clientrpc.IneligibilityReason.IDispute|null} [hasDispute] IneligibilityReason hasDispute
                 * @property {visor.clientrpc.IneligibilityReason.IChatConversation|null} [requiresChatConversation] IneligibilityReason requiresChatConversation
                 * @property {visor.clientrpc.IneligibilityReason.IActiveSubscription|null} [hasActiveSubscription] IneligibilityReason hasActiveSubscription
                 */

                /**
                 * Constructs a new IneligibilityReason.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an IneligibilityReason.
                 * @implements IIneligibilityReason
                 * @constructor
                 * @param {visor.clientrpc.IIneligibilityReason=} [properties] Properties to set
                 */
                function IneligibilityReason(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * IneligibilityReason hasOutstandingSubscription.
                 * @member {visor.clientrpc.IneligibilityReason.IOutstandingSubscription|null|undefined} hasOutstandingSubscription
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @instance
                 */
                IneligibilityReason.prototype.hasOutstandingSubscription = null;

                /**
                 * IneligibilityReason hasOutstandingAdvance.
                 * @member {visor.clientrpc.IneligibilityReason.IOutstandingAdvance|null|undefined} hasOutstandingAdvance
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @instance
                 */
                IneligibilityReason.prototype.hasOutstandingAdvance = null;

                /**
                 * IneligibilityReason hasDispute.
                 * @member {visor.clientrpc.IneligibilityReason.IDispute|null|undefined} hasDispute
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @instance
                 */
                IneligibilityReason.prototype.hasDispute = null;

                /**
                 * IneligibilityReason requiresChatConversation.
                 * @member {visor.clientrpc.IneligibilityReason.IChatConversation|null|undefined} requiresChatConversation
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @instance
                 */
                IneligibilityReason.prototype.requiresChatConversation = null;

                /**
                 * IneligibilityReason hasActiveSubscription.
                 * @member {visor.clientrpc.IneligibilityReason.IActiveSubscription|null|undefined} hasActiveSubscription
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @instance
                 */
                IneligibilityReason.prototype.hasActiveSubscription = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * IneligibilityReason reason.
                 * @member {"hasOutstandingSubscription"|"hasOutstandingAdvance"|"hasDispute"|"requiresChatConversation"|"hasActiveSubscription"|undefined} reason
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @instance
                 */
                Object.defineProperty(IneligibilityReason.prototype, "reason", {
                    get: $util.oneOfGetter($oneOfFields = ["hasOutstandingSubscription", "hasOutstandingAdvance", "hasDispute", "requiresChatConversation", "hasActiveSubscription"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new IneligibilityReason instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {visor.clientrpc.IIneligibilityReason=} [properties] Properties to set
                 * @returns {visor.clientrpc.IneligibilityReason} IneligibilityReason instance
                 */
                IneligibilityReason.create = function create(properties) {
                    return new IneligibilityReason(properties);
                };

                /**
                 * Encodes the specified IneligibilityReason message. Does not implicitly {@link visor.clientrpc.IneligibilityReason.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {visor.clientrpc.IIneligibilityReason} message IneligibilityReason message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IneligibilityReason.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hasOutstandingSubscription != null && message.hasOwnProperty("hasOutstandingSubscription"))
                        $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription.encode(message.hasOutstandingSubscription, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.hasOutstandingAdvance != null && message.hasOwnProperty("hasOutstandingAdvance"))
                        $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance.encode(message.hasOutstandingAdvance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.hasDispute != null && message.hasOwnProperty("hasDispute"))
                        $root.visor.clientrpc.IneligibilityReason.Dispute.encode(message.hasDispute, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.requiresChatConversation != null && message.hasOwnProperty("requiresChatConversation"))
                        $root.visor.clientrpc.IneligibilityReason.ChatConversation.encode(message.requiresChatConversation, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.hasActiveSubscription != null && message.hasOwnProperty("hasActiveSubscription"))
                        $root.visor.clientrpc.IneligibilityReason.ActiveSubscription.encode(message.hasActiveSubscription, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified IneligibilityReason message, length delimited. Does not implicitly {@link visor.clientrpc.IneligibilityReason.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {visor.clientrpc.IIneligibilityReason} message IneligibilityReason message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IneligibilityReason.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an IneligibilityReason message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.IneligibilityReason} IneligibilityReason
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IneligibilityReason.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IneligibilityReason();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.hasOutstandingSubscription = $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.hasOutstandingAdvance = $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.hasDispute = $root.visor.clientrpc.IneligibilityReason.Dispute.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.requiresChatConversation = $root.visor.clientrpc.IneligibilityReason.ChatConversation.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.hasActiveSubscription = $root.visor.clientrpc.IneligibilityReason.ActiveSubscription.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an IneligibilityReason message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.IneligibilityReason} IneligibilityReason
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IneligibilityReason.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an IneligibilityReason message.
                 * @function verify
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IneligibilityReason.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.hasOutstandingSubscription != null && message.hasOwnProperty("hasOutstandingSubscription")) {
                        properties.reason = 1;
                        {
                            var error = $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription.verify(message.hasOutstandingSubscription);
                            if (error)
                                return "hasOutstandingSubscription." + error;
                        }
                    }
                    if (message.hasOutstandingAdvance != null && message.hasOwnProperty("hasOutstandingAdvance")) {
                        if (properties.reason === 1)
                            return "reason: multiple values";
                        properties.reason = 1;
                        {
                            var error = $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance.verify(message.hasOutstandingAdvance);
                            if (error)
                                return "hasOutstandingAdvance." + error;
                        }
                    }
                    if (message.hasDispute != null && message.hasOwnProperty("hasDispute")) {
                        if (properties.reason === 1)
                            return "reason: multiple values";
                        properties.reason = 1;
                        {
                            var error = $root.visor.clientrpc.IneligibilityReason.Dispute.verify(message.hasDispute);
                            if (error)
                                return "hasDispute." + error;
                        }
                    }
                    if (message.requiresChatConversation != null && message.hasOwnProperty("requiresChatConversation")) {
                        if (properties.reason === 1)
                            return "reason: multiple values";
                        properties.reason = 1;
                        {
                            var error = $root.visor.clientrpc.IneligibilityReason.ChatConversation.verify(message.requiresChatConversation);
                            if (error)
                                return "requiresChatConversation." + error;
                        }
                    }
                    if (message.hasActiveSubscription != null && message.hasOwnProperty("hasActiveSubscription")) {
                        if (properties.reason === 1)
                            return "reason: multiple values";
                        properties.reason = 1;
                        {
                            var error = $root.visor.clientrpc.IneligibilityReason.ActiveSubscription.verify(message.hasActiveSubscription);
                            if (error)
                                return "hasActiveSubscription." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an IneligibilityReason message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.IneligibilityReason} IneligibilityReason
                 */
                IneligibilityReason.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.IneligibilityReason)
                        return object;
                    var message = new $root.visor.clientrpc.IneligibilityReason();
                    if (object.hasOutstandingSubscription != null) {
                        if (typeof object.hasOutstandingSubscription !== "object")
                            throw TypeError(".visor.clientrpc.IneligibilityReason.hasOutstandingSubscription: object expected");
                        message.hasOutstandingSubscription = $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription.fromObject(object.hasOutstandingSubscription);
                    }
                    if (object.hasOutstandingAdvance != null) {
                        if (typeof object.hasOutstandingAdvance !== "object")
                            throw TypeError(".visor.clientrpc.IneligibilityReason.hasOutstandingAdvance: object expected");
                        message.hasOutstandingAdvance = $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance.fromObject(object.hasOutstandingAdvance);
                    }
                    if (object.hasDispute != null) {
                        if (typeof object.hasDispute !== "object")
                            throw TypeError(".visor.clientrpc.IneligibilityReason.hasDispute: object expected");
                        message.hasDispute = $root.visor.clientrpc.IneligibilityReason.Dispute.fromObject(object.hasDispute);
                    }
                    if (object.requiresChatConversation != null) {
                        if (typeof object.requiresChatConversation !== "object")
                            throw TypeError(".visor.clientrpc.IneligibilityReason.requiresChatConversation: object expected");
                        message.requiresChatConversation = $root.visor.clientrpc.IneligibilityReason.ChatConversation.fromObject(object.requiresChatConversation);
                    }
                    if (object.hasActiveSubscription != null) {
                        if (typeof object.hasActiveSubscription !== "object")
                            throw TypeError(".visor.clientrpc.IneligibilityReason.hasActiveSubscription: object expected");
                        message.hasActiveSubscription = $root.visor.clientrpc.IneligibilityReason.ActiveSubscription.fromObject(object.hasActiveSubscription);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an IneligibilityReason message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @static
                 * @param {visor.clientrpc.IneligibilityReason} message IneligibilityReason
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IneligibilityReason.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.hasOutstandingSubscription != null && message.hasOwnProperty("hasOutstandingSubscription")) {
                        object.hasOutstandingSubscription = $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription.toObject(message.hasOutstandingSubscription, options);
                        if (options.oneofs)
                            object.reason = "hasOutstandingSubscription";
                    }
                    if (message.hasOutstandingAdvance != null && message.hasOwnProperty("hasOutstandingAdvance")) {
                        object.hasOutstandingAdvance = $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance.toObject(message.hasOutstandingAdvance, options);
                        if (options.oneofs)
                            object.reason = "hasOutstandingAdvance";
                    }
                    if (message.hasDispute != null && message.hasOwnProperty("hasDispute")) {
                        object.hasDispute = $root.visor.clientrpc.IneligibilityReason.Dispute.toObject(message.hasDispute, options);
                        if (options.oneofs)
                            object.reason = "hasDispute";
                    }
                    if (message.requiresChatConversation != null && message.hasOwnProperty("requiresChatConversation")) {
                        object.requiresChatConversation = $root.visor.clientrpc.IneligibilityReason.ChatConversation.toObject(message.requiresChatConversation, options);
                        if (options.oneofs)
                            object.reason = "requiresChatConversation";
                    }
                    if (message.hasActiveSubscription != null && message.hasOwnProperty("hasActiveSubscription")) {
                        object.hasActiveSubscription = $root.visor.clientrpc.IneligibilityReason.ActiveSubscription.toObject(message.hasActiveSubscription, options);
                        if (options.oneofs)
                            object.reason = "hasActiveSubscription";
                    }
                    return object;
                };

                /**
                 * Converts this IneligibilityReason to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.IneligibilityReason
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IneligibilityReason.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                IneligibilityReason.OutstandingSubscription = (function() {

                    /**
                     * Properties of an OutstandingSubscription.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @interface IOutstandingSubscription
                     * @property {visor.common.money.IAmount|null} [outstandingBalance] OutstandingSubscription outstandingBalance
                     */

                    /**
                     * Constructs a new OutstandingSubscription.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @classdesc Represents an OutstandingSubscription.
                     * @implements IOutstandingSubscription
                     * @constructor
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingSubscription=} [properties] Properties to set
                     */
                    function OutstandingSubscription(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OutstandingSubscription outstandingBalance.
                     * @member {visor.common.money.IAmount|null|undefined} outstandingBalance
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @instance
                     */
                    OutstandingSubscription.prototype.outstandingBalance = null;

                    /**
                     * Creates a new OutstandingSubscription instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingSubscription=} [properties] Properties to set
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingSubscription} OutstandingSubscription instance
                     */
                    OutstandingSubscription.create = function create(properties) {
                        return new OutstandingSubscription(properties);
                    };

                    /**
                     * Encodes the specified OutstandingSubscription message. Does not implicitly {@link visor.clientrpc.IneligibilityReason.OutstandingSubscription.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingSubscription} message OutstandingSubscription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OutstandingSubscription.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.outstandingBalance != null && message.hasOwnProperty("outstandingBalance"))
                            $root.visor.common.money.Amount.encode(message.outstandingBalance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OutstandingSubscription message, length delimited. Does not implicitly {@link visor.clientrpc.IneligibilityReason.OutstandingSubscription.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingSubscription} message OutstandingSubscription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OutstandingSubscription.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OutstandingSubscription message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingSubscription} OutstandingSubscription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OutstandingSubscription.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.outstandingBalance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OutstandingSubscription message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingSubscription} OutstandingSubscription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OutstandingSubscription.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OutstandingSubscription message.
                     * @function verify
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OutstandingSubscription.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.outstandingBalance != null && message.hasOwnProperty("outstandingBalance")) {
                            var error = $root.visor.common.money.Amount.verify(message.outstandingBalance);
                            if (error)
                                return "outstandingBalance." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OutstandingSubscription message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingSubscription} OutstandingSubscription
                     */
                    OutstandingSubscription.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription)
                            return object;
                        var message = new $root.visor.clientrpc.IneligibilityReason.OutstandingSubscription();
                        if (object.outstandingBalance != null) {
                            if (typeof object.outstandingBalance !== "object")
                                throw TypeError(".visor.clientrpc.IneligibilityReason.OutstandingSubscription.outstandingBalance: object expected");
                            message.outstandingBalance = $root.visor.common.money.Amount.fromObject(object.outstandingBalance);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OutstandingSubscription message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.OutstandingSubscription} message OutstandingSubscription
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OutstandingSubscription.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.outstandingBalance = null;
                        if (message.outstandingBalance != null && message.hasOwnProperty("outstandingBalance"))
                            object.outstandingBalance = $root.visor.common.money.Amount.toObject(message.outstandingBalance, options);
                        return object;
                    };

                    /**
                     * Converts this OutstandingSubscription to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingSubscription
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OutstandingSubscription.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OutstandingSubscription;
                })();

                IneligibilityReason.OutstandingAdvance = (function() {

                    /**
                     * Properties of an OutstandingAdvance.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @interface IOutstandingAdvance
                     * @property {visor.common.money.IAmount|null} [originalBalance] OutstandingAdvance originalBalance
                     * @property {visor.common.money.IAmount|null} [outstandingBalance] OutstandingAdvance outstandingBalance
                     */

                    /**
                     * Constructs a new OutstandingAdvance.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @classdesc Represents an OutstandingAdvance.
                     * @implements IOutstandingAdvance
                     * @constructor
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingAdvance=} [properties] Properties to set
                     */
                    function OutstandingAdvance(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OutstandingAdvance originalBalance.
                     * @member {visor.common.money.IAmount|null|undefined} originalBalance
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @instance
                     */
                    OutstandingAdvance.prototype.originalBalance = null;

                    /**
                     * OutstandingAdvance outstandingBalance.
                     * @member {visor.common.money.IAmount|null|undefined} outstandingBalance
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @instance
                     */
                    OutstandingAdvance.prototype.outstandingBalance = null;

                    /**
                     * Creates a new OutstandingAdvance instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingAdvance=} [properties] Properties to set
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingAdvance} OutstandingAdvance instance
                     */
                    OutstandingAdvance.create = function create(properties) {
                        return new OutstandingAdvance(properties);
                    };

                    /**
                     * Encodes the specified OutstandingAdvance message. Does not implicitly {@link visor.clientrpc.IneligibilityReason.OutstandingAdvance.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingAdvance} message OutstandingAdvance message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OutstandingAdvance.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.originalBalance != null && message.hasOwnProperty("originalBalance"))
                            $root.visor.common.money.Amount.encode(message.originalBalance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.outstandingBalance != null && message.hasOwnProperty("outstandingBalance"))
                            $root.visor.common.money.Amount.encode(message.outstandingBalance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OutstandingAdvance message, length delimited. Does not implicitly {@link visor.clientrpc.IneligibilityReason.OutstandingAdvance.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IOutstandingAdvance} message OutstandingAdvance message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OutstandingAdvance.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OutstandingAdvance message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingAdvance} OutstandingAdvance
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OutstandingAdvance.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.originalBalance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.outstandingBalance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OutstandingAdvance message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingAdvance} OutstandingAdvance
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OutstandingAdvance.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OutstandingAdvance message.
                     * @function verify
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OutstandingAdvance.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.originalBalance != null && message.hasOwnProperty("originalBalance")) {
                            var error = $root.visor.common.money.Amount.verify(message.originalBalance);
                            if (error)
                                return "originalBalance." + error;
                        }
                        if (message.outstandingBalance != null && message.hasOwnProperty("outstandingBalance")) {
                            var error = $root.visor.common.money.Amount.verify(message.outstandingBalance);
                            if (error)
                                return "outstandingBalance." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OutstandingAdvance message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IneligibilityReason.OutstandingAdvance} OutstandingAdvance
                     */
                    OutstandingAdvance.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance)
                            return object;
                        var message = new $root.visor.clientrpc.IneligibilityReason.OutstandingAdvance();
                        if (object.originalBalance != null) {
                            if (typeof object.originalBalance !== "object")
                                throw TypeError(".visor.clientrpc.IneligibilityReason.OutstandingAdvance.originalBalance: object expected");
                            message.originalBalance = $root.visor.common.money.Amount.fromObject(object.originalBalance);
                        }
                        if (object.outstandingBalance != null) {
                            if (typeof object.outstandingBalance !== "object")
                                throw TypeError(".visor.clientrpc.IneligibilityReason.OutstandingAdvance.outstandingBalance: object expected");
                            message.outstandingBalance = $root.visor.common.money.Amount.fromObject(object.outstandingBalance);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OutstandingAdvance message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.OutstandingAdvance} message OutstandingAdvance
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OutstandingAdvance.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.originalBalance = null;
                            object.outstandingBalance = null;
                        }
                        if (message.originalBalance != null && message.hasOwnProperty("originalBalance"))
                            object.originalBalance = $root.visor.common.money.Amount.toObject(message.originalBalance, options);
                        if (message.outstandingBalance != null && message.hasOwnProperty("outstandingBalance"))
                            object.outstandingBalance = $root.visor.common.money.Amount.toObject(message.outstandingBalance, options);
                        return object;
                    };

                    /**
                     * Converts this OutstandingAdvance to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IneligibilityReason.OutstandingAdvance
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OutstandingAdvance.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OutstandingAdvance;
                })();

                IneligibilityReason.Dispute = (function() {

                    /**
                     * Properties of a Dispute.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @interface IDispute
                     */

                    /**
                     * Constructs a new Dispute.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @classdesc Represents a Dispute.
                     * @implements IDispute
                     * @constructor
                     * @param {visor.clientrpc.IneligibilityReason.IDispute=} [properties] Properties to set
                     */
                    function Dispute(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new Dispute instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IDispute=} [properties] Properties to set
                     * @returns {visor.clientrpc.IneligibilityReason.Dispute} Dispute instance
                     */
                    Dispute.create = function create(properties) {
                        return new Dispute(properties);
                    };

                    /**
                     * Encodes the specified Dispute message. Does not implicitly {@link visor.clientrpc.IneligibilityReason.Dispute.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IDispute} message Dispute message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Dispute.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified Dispute message, length delimited. Does not implicitly {@link visor.clientrpc.IneligibilityReason.Dispute.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IDispute} message Dispute message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Dispute.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Dispute message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IneligibilityReason.Dispute} Dispute
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Dispute.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IneligibilityReason.Dispute();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Dispute message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IneligibilityReason.Dispute} Dispute
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Dispute.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Dispute message.
                     * @function verify
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Dispute.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a Dispute message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IneligibilityReason.Dispute} Dispute
                     */
                    Dispute.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IneligibilityReason.Dispute)
                            return object;
                        return new $root.visor.clientrpc.IneligibilityReason.Dispute();
                    };

                    /**
                     * Creates a plain object from a Dispute message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.Dispute} message Dispute
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Dispute.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Dispute to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IneligibilityReason.Dispute
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Dispute.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Dispute;
                })();

                IneligibilityReason.ActiveSubscription = (function() {

                    /**
                     * Properties of an ActiveSubscription.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @interface IActiveSubscription
                     */

                    /**
                     * Constructs a new ActiveSubscription.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @classdesc Represents an ActiveSubscription.
                     * @implements IActiveSubscription
                     * @constructor
                     * @param {visor.clientrpc.IneligibilityReason.IActiveSubscription=} [properties] Properties to set
                     */
                    function ActiveSubscription(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new ActiveSubscription instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IActiveSubscription=} [properties] Properties to set
                     * @returns {visor.clientrpc.IneligibilityReason.ActiveSubscription} ActiveSubscription instance
                     */
                    ActiveSubscription.create = function create(properties) {
                        return new ActiveSubscription(properties);
                    };

                    /**
                     * Encodes the specified ActiveSubscription message. Does not implicitly {@link visor.clientrpc.IneligibilityReason.ActiveSubscription.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IActiveSubscription} message ActiveSubscription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ActiveSubscription.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified ActiveSubscription message, length delimited. Does not implicitly {@link visor.clientrpc.IneligibilityReason.ActiveSubscription.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IActiveSubscription} message ActiveSubscription message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ActiveSubscription.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ActiveSubscription message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IneligibilityReason.ActiveSubscription} ActiveSubscription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ActiveSubscription.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IneligibilityReason.ActiveSubscription();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ActiveSubscription message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IneligibilityReason.ActiveSubscription} ActiveSubscription
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ActiveSubscription.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ActiveSubscription message.
                     * @function verify
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ActiveSubscription.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an ActiveSubscription message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IneligibilityReason.ActiveSubscription} ActiveSubscription
                     */
                    ActiveSubscription.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IneligibilityReason.ActiveSubscription)
                            return object;
                        return new $root.visor.clientrpc.IneligibilityReason.ActiveSubscription();
                    };

                    /**
                     * Creates a plain object from an ActiveSubscription message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.ActiveSubscription} message ActiveSubscription
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ActiveSubscription.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this ActiveSubscription to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IneligibilityReason.ActiveSubscription
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ActiveSubscription.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ActiveSubscription;
                })();

                IneligibilityReason.ChatConversation = (function() {

                    /**
                     * Properties of a ChatConversation.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @interface IChatConversation
                     * @property {visor.clientrpc.IneligibilityReason.ChatConversation.RequestMessage|null} [requiredChatRequestMessage] ChatConversation requiredChatRequestMessage
                     */

                    /**
                     * Constructs a new ChatConversation.
                     * @memberof visor.clientrpc.IneligibilityReason
                     * @classdesc Represents a ChatConversation.
                     * @implements IChatConversation
                     * @constructor
                     * @param {visor.clientrpc.IneligibilityReason.IChatConversation=} [properties] Properties to set
                     */
                    function ChatConversation(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ChatConversation requiredChatRequestMessage.
                     * @member {visor.clientrpc.IneligibilityReason.ChatConversation.RequestMessage} requiredChatRequestMessage
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @instance
                     */
                    ChatConversation.prototype.requiredChatRequestMessage = 0;

                    /**
                     * Creates a new ChatConversation instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IChatConversation=} [properties] Properties to set
                     * @returns {visor.clientrpc.IneligibilityReason.ChatConversation} ChatConversation instance
                     */
                    ChatConversation.create = function create(properties) {
                        return new ChatConversation(properties);
                    };

                    /**
                     * Encodes the specified ChatConversation message. Does not implicitly {@link visor.clientrpc.IneligibilityReason.ChatConversation.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IChatConversation} message ChatConversation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChatConversation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.requiredChatRequestMessage != null && message.hasOwnProperty("requiredChatRequestMessage"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.requiredChatRequestMessage);
                        return writer;
                    };

                    /**
                     * Encodes the specified ChatConversation message, length delimited. Does not implicitly {@link visor.clientrpc.IneligibilityReason.ChatConversation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.IChatConversation} message ChatConversation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChatConversation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ChatConversation message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.IneligibilityReason.ChatConversation} ChatConversation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChatConversation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.IneligibilityReason.ChatConversation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.requiredChatRequestMessage = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ChatConversation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.IneligibilityReason.ChatConversation} ChatConversation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChatConversation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ChatConversation message.
                     * @function verify
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ChatConversation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.requiredChatRequestMessage != null && message.hasOwnProperty("requiredChatRequestMessage"))
                            switch (message.requiredChatRequestMessage) {
                            default:
                                return "requiredChatRequestMessage: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a ChatConversation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.IneligibilityReason.ChatConversation} ChatConversation
                     */
                    ChatConversation.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.IneligibilityReason.ChatConversation)
                            return object;
                        var message = new $root.visor.clientrpc.IneligibilityReason.ChatConversation();
                        switch (object.requiredChatRequestMessage) {
                        case "INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_INVALID":
                        case 0:
                            message.requiredChatRequestMessage = 0;
                            break;
                        case "INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_CANCEL_MEMBERSHIP_WITH_REPAID_ADVANCES":
                        case 1:
                            message.requiredChatRequestMessage = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ChatConversation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @static
                     * @param {visor.clientrpc.IneligibilityReason.ChatConversation} message ChatConversation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ChatConversation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.requiredChatRequestMessage = options.enums === String ? "INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_INVALID" : 0;
                        if (message.requiredChatRequestMessage != null && message.hasOwnProperty("requiredChatRequestMessage"))
                            object.requiredChatRequestMessage = options.enums === String ? $root.visor.clientrpc.IneligibilityReason.ChatConversation.RequestMessage[message.requiredChatRequestMessage] : message.requiredChatRequestMessage;
                        return object;
                    };

                    /**
                     * Converts this ChatConversation to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.IneligibilityReason.ChatConversation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ChatConversation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * RequestMessage enum.
                     * @name visor.clientrpc.IneligibilityReason.ChatConversation.RequestMessage
                     * @enum {string}
                     * @property {number} INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_INVALID=0 INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_INVALID value
                     * @property {number} INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_CANCEL_MEMBERSHIP_WITH_REPAID_ADVANCES=1 INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_CANCEL_MEMBERSHIP_WITH_REPAID_ADVANCES value
                     */
                    ChatConversation.RequestMessage = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_INVALID"] = 0;
                        values[valuesById[1] = "INELIGIBILITY_REASON_CHAT_CONVERSATION_REQUEST_MESSAGE_CANCEL_MEMBERSHIP_WITH_REPAID_ADVANCES"] = 1;
                        return values;
                    })();

                    return ChatConversation;
                })();

                return IneligibilityReason;
            })();

            clientrpc.GetCancelSubscriptionEligibilityRequest = (function() {

                /**
                 * Properties of a GetCancelSubscriptionEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCancelSubscriptionEligibilityRequest
                 */

                /**
                 * Constructs a new GetCancelSubscriptionEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCancelSubscriptionEligibilityRequest.
                 * @implements IGetCancelSubscriptionEligibilityRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityRequest=} [properties] Properties to set
                 */
                function GetCancelSubscriptionEligibilityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetCancelSubscriptionEligibilityRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityRequest} GetCancelSubscriptionEligibilityRequest instance
                 */
                GetCancelSubscriptionEligibilityRequest.create = function create(properties) {
                    return new GetCancelSubscriptionEligibilityRequest(properties);
                };

                /**
                 * Encodes the specified GetCancelSubscriptionEligibilityRequest message. Does not implicitly {@link visor.clientrpc.GetCancelSubscriptionEligibilityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityRequest} message GetCancelSubscriptionEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCancelSubscriptionEligibilityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetCancelSubscriptionEligibilityRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCancelSubscriptionEligibilityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityRequest} message GetCancelSubscriptionEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCancelSubscriptionEligibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCancelSubscriptionEligibilityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityRequest} GetCancelSubscriptionEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCancelSubscriptionEligibilityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCancelSubscriptionEligibilityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCancelSubscriptionEligibilityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityRequest} GetCancelSubscriptionEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCancelSubscriptionEligibilityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCancelSubscriptionEligibilityRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCancelSubscriptionEligibilityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetCancelSubscriptionEligibilityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityRequest} GetCancelSubscriptionEligibilityRequest
                 */
                GetCancelSubscriptionEligibilityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCancelSubscriptionEligibilityRequest)
                        return object;
                    return new $root.visor.clientrpc.GetCancelSubscriptionEligibilityRequest();
                };

                /**
                 * Creates a plain object from a GetCancelSubscriptionEligibilityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.GetCancelSubscriptionEligibilityRequest} message GetCancelSubscriptionEligibilityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCancelSubscriptionEligibilityRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetCancelSubscriptionEligibilityRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCancelSubscriptionEligibilityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCancelSubscriptionEligibilityRequest;
            })();

            clientrpc.GetCancelSubscriptionEligibilityResponse = (function() {

                /**
                 * Properties of a GetCancelSubscriptionEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCancelSubscriptionEligibilityResponse
                 * @property {visor.clientrpc.IIneligibilityReason|null} [ineligibilityReason] GetCancelSubscriptionEligibilityResponse ineligibilityReason
                 */

                /**
                 * Constructs a new GetCancelSubscriptionEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCancelSubscriptionEligibilityResponse.
                 * @implements IGetCancelSubscriptionEligibilityResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityResponse=} [properties] Properties to set
                 */
                function GetCancelSubscriptionEligibilityResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCancelSubscriptionEligibilityResponse ineligibilityReason.
                 * @member {visor.clientrpc.IIneligibilityReason|null|undefined} ineligibilityReason
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @instance
                 */
                GetCancelSubscriptionEligibilityResponse.prototype.ineligibilityReason = null;

                /**
                 * Creates a new GetCancelSubscriptionEligibilityResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityResponse} GetCancelSubscriptionEligibilityResponse instance
                 */
                GetCancelSubscriptionEligibilityResponse.create = function create(properties) {
                    return new GetCancelSubscriptionEligibilityResponse(properties);
                };

                /**
                 * Encodes the specified GetCancelSubscriptionEligibilityResponse message. Does not implicitly {@link visor.clientrpc.GetCancelSubscriptionEligibilityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityResponse} message GetCancelSubscriptionEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCancelSubscriptionEligibilityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ineligibilityReason != null && message.hasOwnProperty("ineligibilityReason"))
                        $root.visor.clientrpc.IneligibilityReason.encode(message.ineligibilityReason, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCancelSubscriptionEligibilityResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCancelSubscriptionEligibilityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetCancelSubscriptionEligibilityResponse} message GetCancelSubscriptionEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCancelSubscriptionEligibilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCancelSubscriptionEligibilityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityResponse} GetCancelSubscriptionEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCancelSubscriptionEligibilityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCancelSubscriptionEligibilityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ineligibilityReason = $root.visor.clientrpc.IneligibilityReason.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCancelSubscriptionEligibilityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityResponse} GetCancelSubscriptionEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCancelSubscriptionEligibilityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCancelSubscriptionEligibilityResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCancelSubscriptionEligibilityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ineligibilityReason != null && message.hasOwnProperty("ineligibilityReason")) {
                        var error = $root.visor.clientrpc.IneligibilityReason.verify(message.ineligibilityReason);
                        if (error)
                            return "ineligibilityReason." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetCancelSubscriptionEligibilityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCancelSubscriptionEligibilityResponse} GetCancelSubscriptionEligibilityResponse
                 */
                GetCancelSubscriptionEligibilityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCancelSubscriptionEligibilityResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCancelSubscriptionEligibilityResponse();
                    if (object.ineligibilityReason != null) {
                        if (typeof object.ineligibilityReason !== "object")
                            throw TypeError(".visor.clientrpc.GetCancelSubscriptionEligibilityResponse.ineligibilityReason: object expected");
                        message.ineligibilityReason = $root.visor.clientrpc.IneligibilityReason.fromObject(object.ineligibilityReason);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCancelSubscriptionEligibilityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.GetCancelSubscriptionEligibilityResponse} message GetCancelSubscriptionEligibilityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCancelSubscriptionEligibilityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.ineligibilityReason = null;
                    if (message.ineligibilityReason != null && message.hasOwnProperty("ineligibilityReason"))
                        object.ineligibilityReason = $root.visor.clientrpc.IneligibilityReason.toObject(message.ineligibilityReason, options);
                    return object;
                };

                /**
                 * Converts this GetCancelSubscriptionEligibilityResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCancelSubscriptionEligibilityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCancelSubscriptionEligibilityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCancelSubscriptionEligibilityResponse;
            })();

            clientrpc.GetCloseAccountEligibilityRequest = (function() {

                /**
                 * Properties of a GetCloseAccountEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCloseAccountEligibilityRequest
                 */

                /**
                 * Constructs a new GetCloseAccountEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCloseAccountEligibilityRequest.
                 * @implements IGetCloseAccountEligibilityRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityRequest=} [properties] Properties to set
                 */
                function GetCloseAccountEligibilityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetCloseAccountEligibilityRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityRequest} GetCloseAccountEligibilityRequest instance
                 */
                GetCloseAccountEligibilityRequest.create = function create(properties) {
                    return new GetCloseAccountEligibilityRequest(properties);
                };

                /**
                 * Encodes the specified GetCloseAccountEligibilityRequest message. Does not implicitly {@link visor.clientrpc.GetCloseAccountEligibilityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityRequest} message GetCloseAccountEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCloseAccountEligibilityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetCloseAccountEligibilityRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCloseAccountEligibilityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityRequest} message GetCloseAccountEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCloseAccountEligibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCloseAccountEligibilityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityRequest} GetCloseAccountEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCloseAccountEligibilityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCloseAccountEligibilityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCloseAccountEligibilityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityRequest} GetCloseAccountEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCloseAccountEligibilityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCloseAccountEligibilityRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCloseAccountEligibilityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetCloseAccountEligibilityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityRequest} GetCloseAccountEligibilityRequest
                 */
                GetCloseAccountEligibilityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCloseAccountEligibilityRequest)
                        return object;
                    return new $root.visor.clientrpc.GetCloseAccountEligibilityRequest();
                };

                /**
                 * Creates a plain object from a GetCloseAccountEligibilityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.GetCloseAccountEligibilityRequest} message GetCloseAccountEligibilityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCloseAccountEligibilityRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetCloseAccountEligibilityRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCloseAccountEligibilityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCloseAccountEligibilityRequest;
            })();

            clientrpc.GetCloseAccountEligibilityResponse = (function() {

                /**
                 * Properties of a GetCloseAccountEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCloseAccountEligibilityResponse
                 * @property {visor.clientrpc.IIneligibilityReason|null} [ineligibilityReason] GetCloseAccountEligibilityResponse ineligibilityReason
                 */

                /**
                 * Constructs a new GetCloseAccountEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCloseAccountEligibilityResponse.
                 * @implements IGetCloseAccountEligibilityResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityResponse=} [properties] Properties to set
                 */
                function GetCloseAccountEligibilityResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCloseAccountEligibilityResponse ineligibilityReason.
                 * @member {visor.clientrpc.IIneligibilityReason|null|undefined} ineligibilityReason
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @instance
                 */
                GetCloseAccountEligibilityResponse.prototype.ineligibilityReason = null;

                /**
                 * Creates a new GetCloseAccountEligibilityResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityResponse} GetCloseAccountEligibilityResponse instance
                 */
                GetCloseAccountEligibilityResponse.create = function create(properties) {
                    return new GetCloseAccountEligibilityResponse(properties);
                };

                /**
                 * Encodes the specified GetCloseAccountEligibilityResponse message. Does not implicitly {@link visor.clientrpc.GetCloseAccountEligibilityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityResponse} message GetCloseAccountEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCloseAccountEligibilityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ineligibilityReason != null && message.hasOwnProperty("ineligibilityReason"))
                        $root.visor.clientrpc.IneligibilityReason.encode(message.ineligibilityReason, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCloseAccountEligibilityResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCloseAccountEligibilityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetCloseAccountEligibilityResponse} message GetCloseAccountEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCloseAccountEligibilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCloseAccountEligibilityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityResponse} GetCloseAccountEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCloseAccountEligibilityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCloseAccountEligibilityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ineligibilityReason = $root.visor.clientrpc.IneligibilityReason.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCloseAccountEligibilityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityResponse} GetCloseAccountEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCloseAccountEligibilityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCloseAccountEligibilityResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCloseAccountEligibilityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ineligibilityReason != null && message.hasOwnProperty("ineligibilityReason")) {
                        var error = $root.visor.clientrpc.IneligibilityReason.verify(message.ineligibilityReason);
                        if (error)
                            return "ineligibilityReason." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetCloseAccountEligibilityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCloseAccountEligibilityResponse} GetCloseAccountEligibilityResponse
                 */
                GetCloseAccountEligibilityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCloseAccountEligibilityResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCloseAccountEligibilityResponse();
                    if (object.ineligibilityReason != null) {
                        if (typeof object.ineligibilityReason !== "object")
                            throw TypeError(".visor.clientrpc.GetCloseAccountEligibilityResponse.ineligibilityReason: object expected");
                        message.ineligibilityReason = $root.visor.clientrpc.IneligibilityReason.fromObject(object.ineligibilityReason);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCloseAccountEligibilityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.GetCloseAccountEligibilityResponse} message GetCloseAccountEligibilityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCloseAccountEligibilityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.ineligibilityReason = null;
                    if (message.ineligibilityReason != null && message.hasOwnProperty("ineligibilityReason"))
                        object.ineligibilityReason = $root.visor.clientrpc.IneligibilityReason.toObject(message.ineligibilityReason, options);
                    return object;
                };

                /**
                 * Converts this GetCloseAccountEligibilityResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCloseAccountEligibilityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCloseAccountEligibilityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCloseAccountEligibilityResponse;
            })();

            clientrpc.BeginCashBoostSetupRequest = (function() {

                /**
                 * Properties of a BeginCashBoostSetupRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginCashBoostSetupRequest
                 * @property {string|null} [cashboostToken] BeginCashBoostSetupRequest cashboostToken
                 * @property {boolean|null} [canUseFootprintStep] BeginCashBoostSetupRequest canUseFootprintStep
                 */

                /**
                 * Constructs a new BeginCashBoostSetupRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginCashBoostSetupRequest.
                 * @implements IBeginCashBoostSetupRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginCashBoostSetupRequest=} [properties] Properties to set
                 */
                function BeginCashBoostSetupRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BeginCashBoostSetupRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @instance
                 */
                BeginCashBoostSetupRequest.prototype.cashboostToken = "";

                /**
                 * BeginCashBoostSetupRequest canUseFootprintStep.
                 * @member {boolean} canUseFootprintStep
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @instance
                 */
                BeginCashBoostSetupRequest.prototype.canUseFootprintStep = false;

                /**
                 * Creates a new BeginCashBoostSetupRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {visor.clientrpc.IBeginCashBoostSetupRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginCashBoostSetupRequest} BeginCashBoostSetupRequest instance
                 */
                BeginCashBoostSetupRequest.create = function create(properties) {
                    return new BeginCashBoostSetupRequest(properties);
                };

                /**
                 * Encodes the specified BeginCashBoostSetupRequest message. Does not implicitly {@link visor.clientrpc.BeginCashBoostSetupRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {visor.clientrpc.IBeginCashBoostSetupRequest} message BeginCashBoostSetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCashBoostSetupRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    if (message.canUseFootprintStep != null && message.hasOwnProperty("canUseFootprintStep"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canUseFootprintStep);
                    return writer;
                };

                /**
                 * Encodes the specified BeginCashBoostSetupRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginCashBoostSetupRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {visor.clientrpc.IBeginCashBoostSetupRequest} message BeginCashBoostSetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCashBoostSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginCashBoostSetupRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginCashBoostSetupRequest} BeginCashBoostSetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCashBoostSetupRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginCashBoostSetupRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        case 2:
                            message.canUseFootprintStep = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginCashBoostSetupRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginCashBoostSetupRequest} BeginCashBoostSetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCashBoostSetupRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginCashBoostSetupRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginCashBoostSetupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.canUseFootprintStep != null && message.hasOwnProperty("canUseFootprintStep"))
                        if (typeof message.canUseFootprintStep !== "boolean")
                            return "canUseFootprintStep: boolean expected";
                    return null;
                };

                /**
                 * Creates a BeginCashBoostSetupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginCashBoostSetupRequest} BeginCashBoostSetupRequest
                 */
                BeginCashBoostSetupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginCashBoostSetupRequest)
                        return object;
                    var message = new $root.visor.clientrpc.BeginCashBoostSetupRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.canUseFootprintStep != null)
                        message.canUseFootprintStep = Boolean(object.canUseFootprintStep);
                    return message;
                };

                /**
                 * Creates a plain object from a BeginCashBoostSetupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @static
                 * @param {visor.clientrpc.BeginCashBoostSetupRequest} message BeginCashBoostSetupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginCashBoostSetupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cashboostToken = "";
                        object.canUseFootprintStep = false;
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.canUseFootprintStep != null && message.hasOwnProperty("canUseFootprintStep"))
                        object.canUseFootprintStep = message.canUseFootprintStep;
                    return object;
                };

                /**
                 * Converts this BeginCashBoostSetupRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginCashBoostSetupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginCashBoostSetupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginCashBoostSetupRequest;
            })();

            clientrpc.BeginCashBoostSetupResponse = (function() {

                /**
                 * Properties of a BeginCashBoostSetupResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginCashBoostSetupResponse
                 */

                /**
                 * Constructs a new BeginCashBoostSetupResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginCashBoostSetupResponse.
                 * @implements IBeginCashBoostSetupResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginCashBoostSetupResponse=} [properties] Properties to set
                 */
                function BeginCashBoostSetupResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginCashBoostSetupResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {visor.clientrpc.IBeginCashBoostSetupResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginCashBoostSetupResponse} BeginCashBoostSetupResponse instance
                 */
                BeginCashBoostSetupResponse.create = function create(properties) {
                    return new BeginCashBoostSetupResponse(properties);
                };

                /**
                 * Encodes the specified BeginCashBoostSetupResponse message. Does not implicitly {@link visor.clientrpc.BeginCashBoostSetupResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {visor.clientrpc.IBeginCashBoostSetupResponse} message BeginCashBoostSetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCashBoostSetupResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginCashBoostSetupResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginCashBoostSetupResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {visor.clientrpc.IBeginCashBoostSetupResponse} message BeginCashBoostSetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCashBoostSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginCashBoostSetupResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginCashBoostSetupResponse} BeginCashBoostSetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCashBoostSetupResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginCashBoostSetupResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginCashBoostSetupResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginCashBoostSetupResponse} BeginCashBoostSetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCashBoostSetupResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginCashBoostSetupResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginCashBoostSetupResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginCashBoostSetupResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginCashBoostSetupResponse} BeginCashBoostSetupResponse
                 */
                BeginCashBoostSetupResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginCashBoostSetupResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginCashBoostSetupResponse();
                };

                /**
                 * Creates a plain object from a BeginCashBoostSetupResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @static
                 * @param {visor.clientrpc.BeginCashBoostSetupResponse} message BeginCashBoostSetupResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginCashBoostSetupResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginCashBoostSetupResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginCashBoostSetupResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginCashBoostSetupResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginCashBoostSetupResponse;
            })();

            clientrpc.BeginSubsequentAdvanceSetupRequest = (function() {

                /**
                 * Properties of a BeginSubsequentAdvanceSetupRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginSubsequentAdvanceSetupRequest
                 * @property {string|null} [cashboostToken] BeginSubsequentAdvanceSetupRequest cashboostToken
                 * @property {boolean|null} [isMigration] BeginSubsequentAdvanceSetupRequest isMigration
                 */

                /**
                 * Constructs a new BeginSubsequentAdvanceSetupRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginSubsequentAdvanceSetupRequest.
                 * @implements IBeginSubsequentAdvanceSetupRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupRequest=} [properties] Properties to set
                 */
                function BeginSubsequentAdvanceSetupRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BeginSubsequentAdvanceSetupRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @instance
                 */
                BeginSubsequentAdvanceSetupRequest.prototype.cashboostToken = "";

                /**
                 * BeginSubsequentAdvanceSetupRequest isMigration.
                 * @member {boolean} isMigration
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @instance
                 */
                BeginSubsequentAdvanceSetupRequest.prototype.isMigration = false;

                /**
                 * Creates a new BeginSubsequentAdvanceSetupRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupRequest} BeginSubsequentAdvanceSetupRequest instance
                 */
                BeginSubsequentAdvanceSetupRequest.create = function create(properties) {
                    return new BeginSubsequentAdvanceSetupRequest(properties);
                };

                /**
                 * Encodes the specified BeginSubsequentAdvanceSetupRequest message. Does not implicitly {@link visor.clientrpc.BeginSubsequentAdvanceSetupRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupRequest} message BeginSubsequentAdvanceSetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginSubsequentAdvanceSetupRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    if (message.isMigration != null && message.hasOwnProperty("isMigration"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isMigration);
                    return writer;
                };

                /**
                 * Encodes the specified BeginSubsequentAdvanceSetupRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginSubsequentAdvanceSetupRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupRequest} message BeginSubsequentAdvanceSetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginSubsequentAdvanceSetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginSubsequentAdvanceSetupRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupRequest} BeginSubsequentAdvanceSetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginSubsequentAdvanceSetupRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginSubsequentAdvanceSetupRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        case 2:
                            message.isMigration = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginSubsequentAdvanceSetupRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupRequest} BeginSubsequentAdvanceSetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginSubsequentAdvanceSetupRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginSubsequentAdvanceSetupRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginSubsequentAdvanceSetupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.isMigration != null && message.hasOwnProperty("isMigration"))
                        if (typeof message.isMigration !== "boolean")
                            return "isMigration: boolean expected";
                    return null;
                };

                /**
                 * Creates a BeginSubsequentAdvanceSetupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupRequest} BeginSubsequentAdvanceSetupRequest
                 */
                BeginSubsequentAdvanceSetupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginSubsequentAdvanceSetupRequest)
                        return object;
                    var message = new $root.visor.clientrpc.BeginSubsequentAdvanceSetupRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.isMigration != null)
                        message.isMigration = Boolean(object.isMigration);
                    return message;
                };

                /**
                 * Creates a plain object from a BeginSubsequentAdvanceSetupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @static
                 * @param {visor.clientrpc.BeginSubsequentAdvanceSetupRequest} message BeginSubsequentAdvanceSetupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginSubsequentAdvanceSetupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cashboostToken = "";
                        object.isMigration = false;
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.isMigration != null && message.hasOwnProperty("isMigration"))
                        object.isMigration = message.isMigration;
                    return object;
                };

                /**
                 * Converts this BeginSubsequentAdvanceSetupRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginSubsequentAdvanceSetupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginSubsequentAdvanceSetupRequest;
            })();

            clientrpc.BeginSubsequentAdvanceSetupResponse = (function() {

                /**
                 * Properties of a BeginSubsequentAdvanceSetupResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginSubsequentAdvanceSetupResponse
                 */

                /**
                 * Constructs a new BeginSubsequentAdvanceSetupResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginSubsequentAdvanceSetupResponse.
                 * @implements IBeginSubsequentAdvanceSetupResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupResponse=} [properties] Properties to set
                 */
                function BeginSubsequentAdvanceSetupResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginSubsequentAdvanceSetupResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupResponse} BeginSubsequentAdvanceSetupResponse instance
                 */
                BeginSubsequentAdvanceSetupResponse.create = function create(properties) {
                    return new BeginSubsequentAdvanceSetupResponse(properties);
                };

                /**
                 * Encodes the specified BeginSubsequentAdvanceSetupResponse message. Does not implicitly {@link visor.clientrpc.BeginSubsequentAdvanceSetupResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupResponse} message BeginSubsequentAdvanceSetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginSubsequentAdvanceSetupResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginSubsequentAdvanceSetupResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginSubsequentAdvanceSetupResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {visor.clientrpc.IBeginSubsequentAdvanceSetupResponse} message BeginSubsequentAdvanceSetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginSubsequentAdvanceSetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginSubsequentAdvanceSetupResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupResponse} BeginSubsequentAdvanceSetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginSubsequentAdvanceSetupResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginSubsequentAdvanceSetupResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginSubsequentAdvanceSetupResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupResponse} BeginSubsequentAdvanceSetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginSubsequentAdvanceSetupResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginSubsequentAdvanceSetupResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginSubsequentAdvanceSetupResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginSubsequentAdvanceSetupResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginSubsequentAdvanceSetupResponse} BeginSubsequentAdvanceSetupResponse
                 */
                BeginSubsequentAdvanceSetupResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginSubsequentAdvanceSetupResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginSubsequentAdvanceSetupResponse();
                };

                /**
                 * Creates a plain object from a BeginSubsequentAdvanceSetupResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @static
                 * @param {visor.clientrpc.BeginSubsequentAdvanceSetupResponse} message BeginSubsequentAdvanceSetupResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginSubsequentAdvanceSetupResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginSubsequentAdvanceSetupResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginSubsequentAdvanceSetupResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginSubsequentAdvanceSetupResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginSubsequentAdvanceSetupResponse;
            })();

            clientrpc.BeginPayboostSetupProjectRequest = (function() {

                /**
                 * Properties of a BeginPayboostSetupProjectRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginPayboostSetupProjectRequest
                 */

                /**
                 * Constructs a new BeginPayboostSetupProjectRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginPayboostSetupProjectRequest.
                 * @implements IBeginPayboostSetupProjectRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectRequest=} [properties] Properties to set
                 */
                function BeginPayboostSetupProjectRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginPayboostSetupProjectRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectRequest} BeginPayboostSetupProjectRequest instance
                 */
                BeginPayboostSetupProjectRequest.create = function create(properties) {
                    return new BeginPayboostSetupProjectRequest(properties);
                };

                /**
                 * Encodes the specified BeginPayboostSetupProjectRequest message. Does not implicitly {@link visor.clientrpc.BeginPayboostSetupProjectRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectRequest} message BeginPayboostSetupProjectRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayboostSetupProjectRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginPayboostSetupProjectRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginPayboostSetupProjectRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectRequest} message BeginPayboostSetupProjectRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayboostSetupProjectRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginPayboostSetupProjectRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectRequest} BeginPayboostSetupProjectRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayboostSetupProjectRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginPayboostSetupProjectRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginPayboostSetupProjectRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectRequest} BeginPayboostSetupProjectRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayboostSetupProjectRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginPayboostSetupProjectRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginPayboostSetupProjectRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginPayboostSetupProjectRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectRequest} BeginPayboostSetupProjectRequest
                 */
                BeginPayboostSetupProjectRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginPayboostSetupProjectRequest)
                        return object;
                    return new $root.visor.clientrpc.BeginPayboostSetupProjectRequest();
                };

                /**
                 * Creates a plain object from a BeginPayboostSetupProjectRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @static
                 * @param {visor.clientrpc.BeginPayboostSetupProjectRequest} message BeginPayboostSetupProjectRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginPayboostSetupProjectRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginPayboostSetupProjectRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginPayboostSetupProjectRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginPayboostSetupProjectRequest;
            })();

            clientrpc.BeginPayboostSetupProjectResponse = (function() {

                /**
                 * Properties of a BeginPayboostSetupProjectResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginPayboostSetupProjectResponse
                 */

                /**
                 * Constructs a new BeginPayboostSetupProjectResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginPayboostSetupProjectResponse.
                 * @implements IBeginPayboostSetupProjectResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectResponse=} [properties] Properties to set
                 */
                function BeginPayboostSetupProjectResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginPayboostSetupProjectResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectResponse} BeginPayboostSetupProjectResponse instance
                 */
                BeginPayboostSetupProjectResponse.create = function create(properties) {
                    return new BeginPayboostSetupProjectResponse(properties);
                };

                /**
                 * Encodes the specified BeginPayboostSetupProjectResponse message. Does not implicitly {@link visor.clientrpc.BeginPayboostSetupProjectResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectResponse} message BeginPayboostSetupProjectResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayboostSetupProjectResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginPayboostSetupProjectResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginPayboostSetupProjectResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {visor.clientrpc.IBeginPayboostSetupProjectResponse} message BeginPayboostSetupProjectResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginPayboostSetupProjectResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginPayboostSetupProjectResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectResponse} BeginPayboostSetupProjectResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayboostSetupProjectResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginPayboostSetupProjectResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginPayboostSetupProjectResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectResponse} BeginPayboostSetupProjectResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginPayboostSetupProjectResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginPayboostSetupProjectResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginPayboostSetupProjectResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginPayboostSetupProjectResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginPayboostSetupProjectResponse} BeginPayboostSetupProjectResponse
                 */
                BeginPayboostSetupProjectResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginPayboostSetupProjectResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginPayboostSetupProjectResponse();
                };

                /**
                 * Creates a plain object from a BeginPayboostSetupProjectResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @static
                 * @param {visor.clientrpc.BeginPayboostSetupProjectResponse} message BeginPayboostSetupProjectResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginPayboostSetupProjectResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginPayboostSetupProjectResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginPayboostSetupProjectResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginPayboostSetupProjectResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginPayboostSetupProjectResponse;
            })();

            clientrpc.AcknowledgeBoostedPayStatementRequest = (function() {

                /**
                 * Properties of an AcknowledgeBoostedPayStatementRequest.
                 * @memberof visor.clientrpc
                 * @interface IAcknowledgeBoostedPayStatementRequest
                 * @property {string|null} [payboostToken] AcknowledgeBoostedPayStatementRequest payboostToken
                 */

                /**
                 * Constructs a new AcknowledgeBoostedPayStatementRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an AcknowledgeBoostedPayStatementRequest.
                 * @implements IAcknowledgeBoostedPayStatementRequest
                 * @constructor
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementRequest=} [properties] Properties to set
                 */
                function AcknowledgeBoostedPayStatementRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AcknowledgeBoostedPayStatementRequest payboostToken.
                 * @member {string} payboostToken
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @instance
                 */
                AcknowledgeBoostedPayStatementRequest.prototype.payboostToken = "";

                /**
                 * Creates a new AcknowledgeBoostedPayStatementRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementRequest} AcknowledgeBoostedPayStatementRequest instance
                 */
                AcknowledgeBoostedPayStatementRequest.create = function create(properties) {
                    return new AcknowledgeBoostedPayStatementRequest(properties);
                };

                /**
                 * Encodes the specified AcknowledgeBoostedPayStatementRequest message. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostedPayStatementRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementRequest} message AcknowledgeBoostedPayStatementRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostedPayStatementRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payboostToken != null && message.hasOwnProperty("payboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.payboostToken);
                    return writer;
                };

                /**
                 * Encodes the specified AcknowledgeBoostedPayStatementRequest message, length delimited. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostedPayStatementRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementRequest} message AcknowledgeBoostedPayStatementRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostedPayStatementRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AcknowledgeBoostedPayStatementRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementRequest} AcknowledgeBoostedPayStatementRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostedPayStatementRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AcknowledgeBoostedPayStatementRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.payboostToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AcknowledgeBoostedPayStatementRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementRequest} AcknowledgeBoostedPayStatementRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostedPayStatementRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AcknowledgeBoostedPayStatementRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AcknowledgeBoostedPayStatementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payboostToken != null && message.hasOwnProperty("payboostToken"))
                        if (!$util.isString(message.payboostToken))
                            return "payboostToken: string expected";
                    return null;
                };

                /**
                 * Creates an AcknowledgeBoostedPayStatementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementRequest} AcknowledgeBoostedPayStatementRequest
                 */
                AcknowledgeBoostedPayStatementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.AcknowledgeBoostedPayStatementRequest)
                        return object;
                    var message = new $root.visor.clientrpc.AcknowledgeBoostedPayStatementRequest();
                    if (object.payboostToken != null)
                        message.payboostToken = String(object.payboostToken);
                    return message;
                };

                /**
                 * Creates a plain object from an AcknowledgeBoostedPayStatementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @static
                 * @param {visor.clientrpc.AcknowledgeBoostedPayStatementRequest} message AcknowledgeBoostedPayStatementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AcknowledgeBoostedPayStatementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.payboostToken = "";
                    if (message.payboostToken != null && message.hasOwnProperty("payboostToken"))
                        object.payboostToken = message.payboostToken;
                    return object;
                };

                /**
                 * Converts this AcknowledgeBoostedPayStatementRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AcknowledgeBoostedPayStatementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AcknowledgeBoostedPayStatementRequest;
            })();

            clientrpc.AcknowledgeBoostedPayStatementResponse = (function() {

                /**
                 * Properties of an AcknowledgeBoostedPayStatementResponse.
                 * @memberof visor.clientrpc
                 * @interface IAcknowledgeBoostedPayStatementResponse
                 */

                /**
                 * Constructs a new AcknowledgeBoostedPayStatementResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an AcknowledgeBoostedPayStatementResponse.
                 * @implements IAcknowledgeBoostedPayStatementResponse
                 * @constructor
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementResponse=} [properties] Properties to set
                 */
                function AcknowledgeBoostedPayStatementResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new AcknowledgeBoostedPayStatementResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementResponse} AcknowledgeBoostedPayStatementResponse instance
                 */
                AcknowledgeBoostedPayStatementResponse.create = function create(properties) {
                    return new AcknowledgeBoostedPayStatementResponse(properties);
                };

                /**
                 * Encodes the specified AcknowledgeBoostedPayStatementResponse message. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostedPayStatementResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementResponse} message AcknowledgeBoostedPayStatementResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostedPayStatementResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified AcknowledgeBoostedPayStatementResponse message, length delimited. Does not implicitly {@link visor.clientrpc.AcknowledgeBoostedPayStatementResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {visor.clientrpc.IAcknowledgeBoostedPayStatementResponse} message AcknowledgeBoostedPayStatementResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AcknowledgeBoostedPayStatementResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AcknowledgeBoostedPayStatementResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementResponse} AcknowledgeBoostedPayStatementResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostedPayStatementResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AcknowledgeBoostedPayStatementResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AcknowledgeBoostedPayStatementResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementResponse} AcknowledgeBoostedPayStatementResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AcknowledgeBoostedPayStatementResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AcknowledgeBoostedPayStatementResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AcknowledgeBoostedPayStatementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an AcknowledgeBoostedPayStatementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.AcknowledgeBoostedPayStatementResponse} AcknowledgeBoostedPayStatementResponse
                 */
                AcknowledgeBoostedPayStatementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.AcknowledgeBoostedPayStatementResponse)
                        return object;
                    return new $root.visor.clientrpc.AcknowledgeBoostedPayStatementResponse();
                };

                /**
                 * Creates a plain object from an AcknowledgeBoostedPayStatementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @static
                 * @param {visor.clientrpc.AcknowledgeBoostedPayStatementResponse} message AcknowledgeBoostedPayStatementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AcknowledgeBoostedPayStatementResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this AcknowledgeBoostedPayStatementResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.AcknowledgeBoostedPayStatementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AcknowledgeBoostedPayStatementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return AcknowledgeBoostedPayStatementResponse;
            })();

            clientrpc.GetLoanAgreementRequest = (function() {

                /**
                 * Properties of a GetLoanAgreementRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetLoanAgreementRequest
                 * @property {string|null} [creditLineToken] GetLoanAgreementRequest creditLineToken
                 */

                /**
                 * Constructs a new GetLoanAgreementRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetLoanAgreementRequest.
                 * @implements IGetLoanAgreementRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetLoanAgreementRequest=} [properties] Properties to set
                 */
                function GetLoanAgreementRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetLoanAgreementRequest creditLineToken.
                 * @member {string} creditLineToken
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @instance
                 */
                GetLoanAgreementRequest.prototype.creditLineToken = "";

                /**
                 * Creates a new GetLoanAgreementRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.IGetLoanAgreementRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetLoanAgreementRequest} GetLoanAgreementRequest instance
                 */
                GetLoanAgreementRequest.create = function create(properties) {
                    return new GetLoanAgreementRequest(properties);
                };

                /**
                 * Encodes the specified GetLoanAgreementRequest message. Does not implicitly {@link visor.clientrpc.GetLoanAgreementRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.IGetLoanAgreementRequest} message GetLoanAgreementRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetLoanAgreementRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.creditLineToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetLoanAgreementRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetLoanAgreementRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.IGetLoanAgreementRequest} message GetLoanAgreementRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetLoanAgreementRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetLoanAgreementRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetLoanAgreementRequest} GetLoanAgreementRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetLoanAgreementRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetLoanAgreementRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.creditLineToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetLoanAgreementRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetLoanAgreementRequest} GetLoanAgreementRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetLoanAgreementRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetLoanAgreementRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetLoanAgreementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        if (!$util.isString(message.creditLineToken))
                            return "creditLineToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetLoanAgreementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetLoanAgreementRequest} GetLoanAgreementRequest
                 */
                GetLoanAgreementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetLoanAgreementRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetLoanAgreementRequest();
                    if (object.creditLineToken != null)
                        message.creditLineToken = String(object.creditLineToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetLoanAgreementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.GetLoanAgreementRequest} message GetLoanAgreementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetLoanAgreementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.creditLineToken = "";
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        object.creditLineToken = message.creditLineToken;
                    return object;
                };

                /**
                 * Converts this GetLoanAgreementRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetLoanAgreementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetLoanAgreementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetLoanAgreementRequest;
            })();

            clientrpc.GetLoanAgreementResponse = (function() {

                /**
                 * Properties of a GetLoanAgreementResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetLoanAgreementResponse
                 * @property {string|null} [loanAgreementToken] GetLoanAgreementResponse loanAgreementToken
                 * @property {google.protobuf.ITimestamp|null} [createdAt] GetLoanAgreementResponse createdAt
                 * @property {number|null} [regularApr] GetLoanAgreementResponse regularApr
                 * @property {number|null} [promoApr] GetLoanAgreementResponse promoApr
                 * @property {string|null} [documentUrl] GetLoanAgreementResponse documentUrl
                 * @property {visor.common.money.IAmount|null} [creditLimit] GetLoanAgreementResponse creditLimit
                 * @property {visor.clientrpc.LoanAgreementPaymentPeriod|null} [paymentPeriod] GetLoanAgreementResponse paymentPeriod
                 * @property {visor.common.money.IAmount|null} [balance] GetLoanAgreementResponse balance
                 * @property {number|null} [promoDays] GetLoanAgreementResponse promoDays
                 */

                /**
                 * Constructs a new GetLoanAgreementResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetLoanAgreementResponse.
                 * @implements IGetLoanAgreementResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetLoanAgreementResponse=} [properties] Properties to set
                 */
                function GetLoanAgreementResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetLoanAgreementResponse loanAgreementToken.
                 * @member {string} loanAgreementToken
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.loanAgreementToken = "";

                /**
                 * GetLoanAgreementResponse createdAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.createdAt = null;

                /**
                 * GetLoanAgreementResponse regularApr.
                 * @member {number} regularApr
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.regularApr = 0;

                /**
                 * GetLoanAgreementResponse promoApr.
                 * @member {number} promoApr
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.promoApr = 0;

                /**
                 * GetLoanAgreementResponse documentUrl.
                 * @member {string} documentUrl
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.documentUrl = "";

                /**
                 * GetLoanAgreementResponse creditLimit.
                 * @member {visor.common.money.IAmount|null|undefined} creditLimit
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.creditLimit = null;

                /**
                 * GetLoanAgreementResponse paymentPeriod.
                 * @member {visor.clientrpc.LoanAgreementPaymentPeriod} paymentPeriod
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.paymentPeriod = 0;

                /**
                 * GetLoanAgreementResponse balance.
                 * @member {visor.common.money.IAmount|null|undefined} balance
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.balance = null;

                /**
                 * GetLoanAgreementResponse promoDays.
                 * @member {number} promoDays
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 */
                GetLoanAgreementResponse.prototype.promoDays = 0;

                /**
                 * Creates a new GetLoanAgreementResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.IGetLoanAgreementResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetLoanAgreementResponse} GetLoanAgreementResponse instance
                 */
                GetLoanAgreementResponse.create = function create(properties) {
                    return new GetLoanAgreementResponse(properties);
                };

                /**
                 * Encodes the specified GetLoanAgreementResponse message. Does not implicitly {@link visor.clientrpc.GetLoanAgreementResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.IGetLoanAgreementResponse} message GetLoanAgreementResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetLoanAgreementResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.loanAgreementToken != null && message.hasOwnProperty("loanAgreementToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.loanAgreementToken);
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.regularApr != null && message.hasOwnProperty("regularApr"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.regularApr);
                    if (message.promoApr != null && message.hasOwnProperty("promoApr"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.promoApr);
                    if (message.documentUrl != null && message.hasOwnProperty("documentUrl"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.documentUrl);
                    if (message.creditLimit != null && message.hasOwnProperty("creditLimit"))
                        $root.visor.common.money.Amount.encode(message.creditLimit, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.paymentPeriod != null && message.hasOwnProperty("paymentPeriod"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.paymentPeriod);
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        $root.visor.common.money.Amount.encode(message.balance, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.promoDays != null && message.hasOwnProperty("promoDays"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.promoDays);
                    return writer;
                };

                /**
                 * Encodes the specified GetLoanAgreementResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetLoanAgreementResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.IGetLoanAgreementResponse} message GetLoanAgreementResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetLoanAgreementResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetLoanAgreementResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetLoanAgreementResponse} GetLoanAgreementResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetLoanAgreementResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetLoanAgreementResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.loanAgreementToken = reader.string();
                            break;
                        case 2:
                            message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.regularApr = reader.int32();
                            break;
                        case 4:
                            message.promoApr = reader.int32();
                            break;
                        case 5:
                            message.documentUrl = reader.string();
                            break;
                        case 6:
                            message.creditLimit = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.paymentPeriod = reader.int32();
                            break;
                        case 8:
                            message.balance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 9:
                            message.promoDays = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetLoanAgreementResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetLoanAgreementResponse} GetLoanAgreementResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetLoanAgreementResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetLoanAgreementResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetLoanAgreementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.loanAgreementToken != null && message.hasOwnProperty("loanAgreementToken"))
                        if (!$util.isString(message.loanAgreementToken))
                            return "loanAgreementToken: string expected";
                    if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                        if (error)
                            return "createdAt." + error;
                    }
                    if (message.regularApr != null && message.hasOwnProperty("regularApr"))
                        if (!$util.isInteger(message.regularApr))
                            return "regularApr: integer expected";
                    if (message.promoApr != null && message.hasOwnProperty("promoApr"))
                        if (!$util.isInteger(message.promoApr))
                            return "promoApr: integer expected";
                    if (message.documentUrl != null && message.hasOwnProperty("documentUrl"))
                        if (!$util.isString(message.documentUrl))
                            return "documentUrl: string expected";
                    if (message.creditLimit != null && message.hasOwnProperty("creditLimit")) {
                        var error = $root.visor.common.money.Amount.verify(message.creditLimit);
                        if (error)
                            return "creditLimit." + error;
                    }
                    if (message.paymentPeriod != null && message.hasOwnProperty("paymentPeriod"))
                        switch (message.paymentPeriod) {
                        default:
                            return "paymentPeriod: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    if (message.balance != null && message.hasOwnProperty("balance")) {
                        var error = $root.visor.common.money.Amount.verify(message.balance);
                        if (error)
                            return "balance." + error;
                    }
                    if (message.promoDays != null && message.hasOwnProperty("promoDays"))
                        if (!$util.isInteger(message.promoDays))
                            return "promoDays: integer expected";
                    return null;
                };

                /**
                 * Creates a GetLoanAgreementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetLoanAgreementResponse} GetLoanAgreementResponse
                 */
                GetLoanAgreementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetLoanAgreementResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetLoanAgreementResponse();
                    if (object.loanAgreementToken != null)
                        message.loanAgreementToken = String(object.loanAgreementToken);
                    if (object.createdAt != null)
                        if (object.createdAt === null)
                            message.createdAt = {};
                        else
                            message.createdAt = {
                                seconds: Math.floor(object.createdAt.getTime() / 1000),
                                nanos: object.createdAt.getMilliseconds() * 1000000
                            };
                    if (object.regularApr != null)
                        message.regularApr = object.regularApr | 0;
                    if (object.promoApr != null)
                        message.promoApr = object.promoApr | 0;
                    if (object.documentUrl != null)
                        message.documentUrl = String(object.documentUrl);
                    if (object.creditLimit != null) {
                        if (typeof object.creditLimit !== "object")
                            throw TypeError(".visor.clientrpc.GetLoanAgreementResponse.creditLimit: object expected");
                        message.creditLimit = $root.visor.common.money.Amount.fromObject(object.creditLimit);
                    }
                    switch (object.paymentPeriod) {
                    case "LOAN_AGREEMENT_PAYMENT_PERIOD_INVALID":
                    case 0:
                        message.paymentPeriod = 0;
                        break;
                    case "LOAN_AGREEMENT_PAYMENT_PERIOD_MONTHLY":
                    case 1:
                        message.paymentPeriod = 1;
                        break;
                    }
                    if (object.balance != null) {
                        if (typeof object.balance !== "object")
                            throw TypeError(".visor.clientrpc.GetLoanAgreementResponse.balance: object expected");
                        message.balance = $root.visor.common.money.Amount.fromObject(object.balance);
                    }
                    if (object.promoDays != null)
                        message.promoDays = object.promoDays | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a GetLoanAgreementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.GetLoanAgreementResponse} message GetLoanAgreementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetLoanAgreementResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.loanAgreementToken = "";
                        object.createdAt = null;
                        object.regularApr = 0;
                        object.promoApr = 0;
                        object.documentUrl = "";
                        object.creditLimit = null;
                        object.paymentPeriod = options.enums === String ? "LOAN_AGREEMENT_PAYMENT_PERIOD_INVALID" : 0;
                        object.balance = null;
                        object.promoDays = 0;
                    }
                    if (message.loanAgreementToken != null && message.hasOwnProperty("loanAgreementToken"))
                        object.loanAgreementToken = message.loanAgreementToken;
                    if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                        object.createdAt = new Date(message.createdAt.seconds * 1000 + message.createdAt.nanos / 1000000);
                    if (message.regularApr != null && message.hasOwnProperty("regularApr"))
                        object.regularApr = message.regularApr;
                    if (message.promoApr != null && message.hasOwnProperty("promoApr"))
                        object.promoApr = message.promoApr;
                    if (message.documentUrl != null && message.hasOwnProperty("documentUrl"))
                        object.documentUrl = message.documentUrl;
                    if (message.creditLimit != null && message.hasOwnProperty("creditLimit"))
                        object.creditLimit = $root.visor.common.money.Amount.toObject(message.creditLimit, options);
                    if (message.paymentPeriod != null && message.hasOwnProperty("paymentPeriod"))
                        object.paymentPeriod = options.enums === String ? $root.visor.clientrpc.LoanAgreementPaymentPeriod[message.paymentPeriod] : message.paymentPeriod;
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        object.balance = $root.visor.common.money.Amount.toObject(message.balance, options);
                    if (message.promoDays != null && message.hasOwnProperty("promoDays"))
                        object.promoDays = message.promoDays;
                    return object;
                };

                /**
                 * Converts this GetLoanAgreementResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetLoanAgreementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetLoanAgreementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetLoanAgreementResponse;
            })();

            /**
             * LoanAgreementPaymentPeriod enum.
             * @name visor.clientrpc.LoanAgreementPaymentPeriod
             * @enum {string}
             * @property {number} LOAN_AGREEMENT_PAYMENT_PERIOD_INVALID=0 LOAN_AGREEMENT_PAYMENT_PERIOD_INVALID value
             * @property {number} LOAN_AGREEMENT_PAYMENT_PERIOD_MONTHLY=1 LOAN_AGREEMENT_PAYMENT_PERIOD_MONTHLY value
             */
            clientrpc.LoanAgreementPaymentPeriod = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "LOAN_AGREEMENT_PAYMENT_PERIOD_INVALID"] = 0;
                values[valuesById[1] = "LOAN_AGREEMENT_PAYMENT_PERIOD_MONTHLY"] = 1;
                return values;
            })();

            clientrpc.GetReferralsCreditBalanceRequest = (function() {

                /**
                 * Properties of a GetReferralsCreditBalanceRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetReferralsCreditBalanceRequest
                 */

                /**
                 * Constructs a new GetReferralsCreditBalanceRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetReferralsCreditBalanceRequest.
                 * @implements IGetReferralsCreditBalanceRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceRequest=} [properties] Properties to set
                 */
                function GetReferralsCreditBalanceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetReferralsCreditBalanceRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceRequest} GetReferralsCreditBalanceRequest instance
                 */
                GetReferralsCreditBalanceRequest.create = function create(properties) {
                    return new GetReferralsCreditBalanceRequest(properties);
                };

                /**
                 * Encodes the specified GetReferralsCreditBalanceRequest message. Does not implicitly {@link visor.clientrpc.GetReferralsCreditBalanceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceRequest} message GetReferralsCreditBalanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralsCreditBalanceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetReferralsCreditBalanceRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetReferralsCreditBalanceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceRequest} message GetReferralsCreditBalanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralsCreditBalanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetReferralsCreditBalanceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceRequest} GetReferralsCreditBalanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralsCreditBalanceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetReferralsCreditBalanceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetReferralsCreditBalanceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceRequest} GetReferralsCreditBalanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralsCreditBalanceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetReferralsCreditBalanceRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReferralsCreditBalanceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetReferralsCreditBalanceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceRequest} GetReferralsCreditBalanceRequest
                 */
                GetReferralsCreditBalanceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetReferralsCreditBalanceRequest)
                        return object;
                    return new $root.visor.clientrpc.GetReferralsCreditBalanceRequest();
                };

                /**
                 * Creates a plain object from a GetReferralsCreditBalanceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @static
                 * @param {visor.clientrpc.GetReferralsCreditBalanceRequest} message GetReferralsCreditBalanceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReferralsCreditBalanceRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetReferralsCreditBalanceRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReferralsCreditBalanceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetReferralsCreditBalanceRequest;
            })();

            clientrpc.GetReferralsCreditBalanceResponse = (function() {

                /**
                 * Properties of a GetReferralsCreditBalanceResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetReferralsCreditBalanceResponse
                 * @property {visor.common.money.IAmount|null} [balance] GetReferralsCreditBalanceResponse balance
                 * @property {string|null} [fallbackReferralsMessage] GetReferralsCreditBalanceResponse fallbackReferralsMessage
                 */

                /**
                 * Constructs a new GetReferralsCreditBalanceResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetReferralsCreditBalanceResponse.
                 * @implements IGetReferralsCreditBalanceResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceResponse=} [properties] Properties to set
                 */
                function GetReferralsCreditBalanceResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetReferralsCreditBalanceResponse balance.
                 * @member {visor.common.money.IAmount|null|undefined} balance
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @instance
                 */
                GetReferralsCreditBalanceResponse.prototype.balance = null;

                /**
                 * GetReferralsCreditBalanceResponse fallbackReferralsMessage.
                 * @member {string} fallbackReferralsMessage
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @instance
                 */
                GetReferralsCreditBalanceResponse.prototype.fallbackReferralsMessage = "";

                /**
                 * Creates a new GetReferralsCreditBalanceResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceResponse} GetReferralsCreditBalanceResponse instance
                 */
                GetReferralsCreditBalanceResponse.create = function create(properties) {
                    return new GetReferralsCreditBalanceResponse(properties);
                };

                /**
                 * Encodes the specified GetReferralsCreditBalanceResponse message. Does not implicitly {@link visor.clientrpc.GetReferralsCreditBalanceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceResponse} message GetReferralsCreditBalanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralsCreditBalanceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        $root.visor.common.money.Amount.encode(message.balance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fallbackReferralsMessage != null && message.hasOwnProperty("fallbackReferralsMessage"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fallbackReferralsMessage);
                    return writer;
                };

                /**
                 * Encodes the specified GetReferralsCreditBalanceResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetReferralsCreditBalanceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralsCreditBalanceResponse} message GetReferralsCreditBalanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralsCreditBalanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetReferralsCreditBalanceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceResponse} GetReferralsCreditBalanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralsCreditBalanceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetReferralsCreditBalanceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.balance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.fallbackReferralsMessage = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetReferralsCreditBalanceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceResponse} GetReferralsCreditBalanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralsCreditBalanceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetReferralsCreditBalanceResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReferralsCreditBalanceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.balance != null && message.hasOwnProperty("balance")) {
                        var error = $root.visor.common.money.Amount.verify(message.balance);
                        if (error)
                            return "balance." + error;
                    }
                    if (message.fallbackReferralsMessage != null && message.hasOwnProperty("fallbackReferralsMessage"))
                        if (!$util.isString(message.fallbackReferralsMessage))
                            return "fallbackReferralsMessage: string expected";
                    return null;
                };

                /**
                 * Creates a GetReferralsCreditBalanceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetReferralsCreditBalanceResponse} GetReferralsCreditBalanceResponse
                 */
                GetReferralsCreditBalanceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetReferralsCreditBalanceResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetReferralsCreditBalanceResponse();
                    if (object.balance != null) {
                        if (typeof object.balance !== "object")
                            throw TypeError(".visor.clientrpc.GetReferralsCreditBalanceResponse.balance: object expected");
                        message.balance = $root.visor.common.money.Amount.fromObject(object.balance);
                    }
                    if (object.fallbackReferralsMessage != null)
                        message.fallbackReferralsMessage = String(object.fallbackReferralsMessage);
                    return message;
                };

                /**
                 * Creates a plain object from a GetReferralsCreditBalanceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @static
                 * @param {visor.clientrpc.GetReferralsCreditBalanceResponse} message GetReferralsCreditBalanceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReferralsCreditBalanceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.balance = null;
                        object.fallbackReferralsMessage = "";
                    }
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        object.balance = $root.visor.common.money.Amount.toObject(message.balance, options);
                    if (message.fallbackReferralsMessage != null && message.hasOwnProperty("fallbackReferralsMessage"))
                        object.fallbackReferralsMessage = message.fallbackReferralsMessage;
                    return object;
                };

                /**
                 * Converts this GetReferralsCreditBalanceResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetReferralsCreditBalanceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReferralsCreditBalanceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetReferralsCreditBalanceResponse;
            })();

            clientrpc.GetReferralLinkRequest = (function() {

                /**
                 * Properties of a GetReferralLinkRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetReferralLinkRequest
                 */

                /**
                 * Constructs a new GetReferralLinkRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetReferralLinkRequest.
                 * @implements IGetReferralLinkRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetReferralLinkRequest=} [properties] Properties to set
                 */
                function GetReferralLinkRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetReferralLinkRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralLinkRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetReferralLinkRequest} GetReferralLinkRequest instance
                 */
                GetReferralLinkRequest.create = function create(properties) {
                    return new GetReferralLinkRequest(properties);
                };

                /**
                 * Encodes the specified GetReferralLinkRequest message. Does not implicitly {@link visor.clientrpc.GetReferralLinkRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralLinkRequest} message GetReferralLinkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralLinkRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetReferralLinkRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetReferralLinkRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralLinkRequest} message GetReferralLinkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralLinkRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetReferralLinkRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetReferralLinkRequest} GetReferralLinkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralLinkRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetReferralLinkRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetReferralLinkRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetReferralLinkRequest} GetReferralLinkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralLinkRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetReferralLinkRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReferralLinkRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetReferralLinkRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetReferralLinkRequest} GetReferralLinkRequest
                 */
                GetReferralLinkRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetReferralLinkRequest)
                        return object;
                    return new $root.visor.clientrpc.GetReferralLinkRequest();
                };

                /**
                 * Creates a plain object from a GetReferralLinkRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @static
                 * @param {visor.clientrpc.GetReferralLinkRequest} message GetReferralLinkRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReferralLinkRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetReferralLinkRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetReferralLinkRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReferralLinkRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetReferralLinkRequest;
            })();

            clientrpc.GetReferralLinkResponse = (function() {

                /**
                 * Properties of a GetReferralLinkResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetReferralLinkResponse
                 * @property {string|null} [referralLink] GetReferralLinkResponse referralLink
                 */

                /**
                 * Constructs a new GetReferralLinkResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetReferralLinkResponse.
                 * @implements IGetReferralLinkResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetReferralLinkResponse=} [properties] Properties to set
                 */
                function GetReferralLinkResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetReferralLinkResponse referralLink.
                 * @member {string} referralLink
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @instance
                 */
                GetReferralLinkResponse.prototype.referralLink = "";

                /**
                 * Creates a new GetReferralLinkResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralLinkResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetReferralLinkResponse} GetReferralLinkResponse instance
                 */
                GetReferralLinkResponse.create = function create(properties) {
                    return new GetReferralLinkResponse(properties);
                };

                /**
                 * Encodes the specified GetReferralLinkResponse message. Does not implicitly {@link visor.clientrpc.GetReferralLinkResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralLinkResponse} message GetReferralLinkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralLinkResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.referralLink != null && message.hasOwnProperty("referralLink"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.referralLink);
                    return writer;
                };

                /**
                 * Encodes the specified GetReferralLinkResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetReferralLinkResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralLinkResponse} message GetReferralLinkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralLinkResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetReferralLinkResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetReferralLinkResponse} GetReferralLinkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralLinkResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetReferralLinkResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.referralLink = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetReferralLinkResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetReferralLinkResponse} GetReferralLinkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralLinkResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetReferralLinkResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReferralLinkResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.referralLink != null && message.hasOwnProperty("referralLink"))
                        if (!$util.isString(message.referralLink))
                            return "referralLink: string expected";
                    return null;
                };

                /**
                 * Creates a GetReferralLinkResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetReferralLinkResponse} GetReferralLinkResponse
                 */
                GetReferralLinkResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetReferralLinkResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetReferralLinkResponse();
                    if (object.referralLink != null)
                        message.referralLink = String(object.referralLink);
                    return message;
                };

                /**
                 * Creates a plain object from a GetReferralLinkResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @static
                 * @param {visor.clientrpc.GetReferralLinkResponse} message GetReferralLinkResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReferralLinkResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.referralLink = "";
                    if (message.referralLink != null && message.hasOwnProperty("referralLink"))
                        object.referralLink = message.referralLink;
                    return object;
                };

                /**
                 * Converts this GetReferralLinkResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetReferralLinkResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReferralLinkResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetReferralLinkResponse;
            })();

            clientrpc.SendLeadDownloadLinkRequest = (function() {

                /**
                 * Properties of a SendLeadDownloadLinkRequest.
                 * @memberof visor.clientrpc
                 * @interface ISendLeadDownloadLinkRequest
                 * @property {string|null} [email] SendLeadDownloadLinkRequest email
                 * @property {string|null} [phoneNumber] SendLeadDownloadLinkRequest phoneNumber
                 */

                /**
                 * Constructs a new SendLeadDownloadLinkRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SendLeadDownloadLinkRequest.
                 * @implements ISendLeadDownloadLinkRequest
                 * @constructor
                 * @param {visor.clientrpc.ISendLeadDownloadLinkRequest=} [properties] Properties to set
                 */
                function SendLeadDownloadLinkRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendLeadDownloadLinkRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @instance
                 */
                SendLeadDownloadLinkRequest.prototype.email = "";

                /**
                 * SendLeadDownloadLinkRequest phoneNumber.
                 * @member {string} phoneNumber
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @instance
                 */
                SendLeadDownloadLinkRequest.prototype.phoneNumber = "";

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * SendLeadDownloadLinkRequest target.
                 * @member {"email"|"phoneNumber"|undefined} target
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @instance
                 */
                Object.defineProperty(SendLeadDownloadLinkRequest.prototype, "target", {
                    get: $util.oneOfGetter($oneOfFields = ["email", "phoneNumber"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new SendLeadDownloadLinkRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {visor.clientrpc.ISendLeadDownloadLinkRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SendLeadDownloadLinkRequest} SendLeadDownloadLinkRequest instance
                 */
                SendLeadDownloadLinkRequest.create = function create(properties) {
                    return new SendLeadDownloadLinkRequest(properties);
                };

                /**
                 * Encodes the specified SendLeadDownloadLinkRequest message. Does not implicitly {@link visor.clientrpc.SendLeadDownloadLinkRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {visor.clientrpc.ISendLeadDownloadLinkRequest} message SendLeadDownloadLinkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendLeadDownloadLinkRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.phoneNumber);
                    return writer;
                };

                /**
                 * Encodes the specified SendLeadDownloadLinkRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SendLeadDownloadLinkRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {visor.clientrpc.ISendLeadDownloadLinkRequest} message SendLeadDownloadLinkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendLeadDownloadLinkRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SendLeadDownloadLinkRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SendLeadDownloadLinkRequest} SendLeadDownloadLinkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendLeadDownloadLinkRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SendLeadDownloadLinkRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.email = reader.string();
                            break;
                        case 2:
                            message.phoneNumber = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SendLeadDownloadLinkRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SendLeadDownloadLinkRequest} SendLeadDownloadLinkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendLeadDownloadLinkRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SendLeadDownloadLinkRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendLeadDownloadLinkRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.email != null && message.hasOwnProperty("email")) {
                        properties.target = 1;
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        if (properties.target === 1)
                            return "target: multiple values";
                        properties.target = 1;
                        if (!$util.isString(message.phoneNumber))
                            return "phoneNumber: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a SendLeadDownloadLinkRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SendLeadDownloadLinkRequest} SendLeadDownloadLinkRequest
                 */
                SendLeadDownloadLinkRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SendLeadDownloadLinkRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SendLeadDownloadLinkRequest();
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.phoneNumber != null)
                        message.phoneNumber = String(object.phoneNumber);
                    return message;
                };

                /**
                 * Creates a plain object from a SendLeadDownloadLinkRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @static
                 * @param {visor.clientrpc.SendLeadDownloadLinkRequest} message SendLeadDownloadLinkRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendLeadDownloadLinkRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.email != null && message.hasOwnProperty("email")) {
                        object.email = message.email;
                        if (options.oneofs)
                            object.target = "email";
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        object.phoneNumber = message.phoneNumber;
                        if (options.oneofs)
                            object.target = "phoneNumber";
                    }
                    return object;
                };

                /**
                 * Converts this SendLeadDownloadLinkRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SendLeadDownloadLinkRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendLeadDownloadLinkRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendLeadDownloadLinkRequest;
            })();

            clientrpc.SendLeadDownloadLinkResponse = (function() {

                /**
                 * Properties of a SendLeadDownloadLinkResponse.
                 * @memberof visor.clientrpc
                 * @interface ISendLeadDownloadLinkResponse
                 */

                /**
                 * Constructs a new SendLeadDownloadLinkResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SendLeadDownloadLinkResponse.
                 * @implements ISendLeadDownloadLinkResponse
                 * @constructor
                 * @param {visor.clientrpc.ISendLeadDownloadLinkResponse=} [properties] Properties to set
                 */
                function SendLeadDownloadLinkResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SendLeadDownloadLinkResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {visor.clientrpc.ISendLeadDownloadLinkResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SendLeadDownloadLinkResponse} SendLeadDownloadLinkResponse instance
                 */
                SendLeadDownloadLinkResponse.create = function create(properties) {
                    return new SendLeadDownloadLinkResponse(properties);
                };

                /**
                 * Encodes the specified SendLeadDownloadLinkResponse message. Does not implicitly {@link visor.clientrpc.SendLeadDownloadLinkResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {visor.clientrpc.ISendLeadDownloadLinkResponse} message SendLeadDownloadLinkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendLeadDownloadLinkResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SendLeadDownloadLinkResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SendLeadDownloadLinkResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {visor.clientrpc.ISendLeadDownloadLinkResponse} message SendLeadDownloadLinkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendLeadDownloadLinkResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SendLeadDownloadLinkResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SendLeadDownloadLinkResponse} SendLeadDownloadLinkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendLeadDownloadLinkResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SendLeadDownloadLinkResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SendLeadDownloadLinkResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SendLeadDownloadLinkResponse} SendLeadDownloadLinkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendLeadDownloadLinkResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SendLeadDownloadLinkResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendLeadDownloadLinkResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SendLeadDownloadLinkResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SendLeadDownloadLinkResponse} SendLeadDownloadLinkResponse
                 */
                SendLeadDownloadLinkResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SendLeadDownloadLinkResponse)
                        return object;
                    return new $root.visor.clientrpc.SendLeadDownloadLinkResponse();
                };

                /**
                 * Creates a plain object from a SendLeadDownloadLinkResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @static
                 * @param {visor.clientrpc.SendLeadDownloadLinkResponse} message SendLeadDownloadLinkResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendLeadDownloadLinkResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SendLeadDownloadLinkResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SendLeadDownloadLinkResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendLeadDownloadLinkResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendLeadDownloadLinkResponse;
            })();

            clientrpc.CreateContactUsLeadRequest = (function() {

                /**
                 * Properties of a CreateContactUsLeadRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateContactUsLeadRequest
                 * @property {string|null} [name] CreateContactUsLeadRequest name
                 * @property {string|null} [email] CreateContactUsLeadRequest email
                 * @property {string|null} [message] CreateContactUsLeadRequest message
                 */

                /**
                 * Constructs a new CreateContactUsLeadRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateContactUsLeadRequest.
                 * @implements ICreateContactUsLeadRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateContactUsLeadRequest=} [properties] Properties to set
                 */
                function CreateContactUsLeadRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateContactUsLeadRequest name.
                 * @member {string} name
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @instance
                 */
                CreateContactUsLeadRequest.prototype.name = "";

                /**
                 * CreateContactUsLeadRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @instance
                 */
                CreateContactUsLeadRequest.prototype.email = "";

                /**
                 * CreateContactUsLeadRequest message.
                 * @member {string} message
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @instance
                 */
                CreateContactUsLeadRequest.prototype.message = "";

                /**
                 * Creates a new CreateContactUsLeadRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateContactUsLeadRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateContactUsLeadRequest} CreateContactUsLeadRequest instance
                 */
                CreateContactUsLeadRequest.create = function create(properties) {
                    return new CreateContactUsLeadRequest(properties);
                };

                /**
                 * Encodes the specified CreateContactUsLeadRequest message. Does not implicitly {@link visor.clientrpc.CreateContactUsLeadRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateContactUsLeadRequest} message CreateContactUsLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateContactUsLeadRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && message.hasOwnProperty("name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                    if (message.message != null && message.hasOwnProperty("message"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
                    return writer;
                };

                /**
                 * Encodes the specified CreateContactUsLeadRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateContactUsLeadRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateContactUsLeadRequest} message CreateContactUsLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateContactUsLeadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateContactUsLeadRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateContactUsLeadRequest} CreateContactUsLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateContactUsLeadRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateContactUsLeadRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.email = reader.string();
                            break;
                        case 3:
                            message.message = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateContactUsLeadRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateContactUsLeadRequest} CreateContactUsLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateContactUsLeadRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateContactUsLeadRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateContactUsLeadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.message != null && message.hasOwnProperty("message"))
                        if (!$util.isString(message.message))
                            return "message: string expected";
                    return null;
                };

                /**
                 * Creates a CreateContactUsLeadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateContactUsLeadRequest} CreateContactUsLeadRequest
                 */
                CreateContactUsLeadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateContactUsLeadRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateContactUsLeadRequest();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.message != null)
                        message.message = String(object.message);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateContactUsLeadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @static
                 * @param {visor.clientrpc.CreateContactUsLeadRequest} message CreateContactUsLeadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateContactUsLeadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.email = "";
                        object.message = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.message != null && message.hasOwnProperty("message"))
                        object.message = message.message;
                    return object;
                };

                /**
                 * Converts this CreateContactUsLeadRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateContactUsLeadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateContactUsLeadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateContactUsLeadRequest;
            })();

            clientrpc.CreateContactUsLeadResponse = (function() {

                /**
                 * Properties of a CreateContactUsLeadResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateContactUsLeadResponse
                 */

                /**
                 * Constructs a new CreateContactUsLeadResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateContactUsLeadResponse.
                 * @implements ICreateContactUsLeadResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateContactUsLeadResponse=} [properties] Properties to set
                 */
                function CreateContactUsLeadResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateContactUsLeadResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateContactUsLeadResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateContactUsLeadResponse} CreateContactUsLeadResponse instance
                 */
                CreateContactUsLeadResponse.create = function create(properties) {
                    return new CreateContactUsLeadResponse(properties);
                };

                /**
                 * Encodes the specified CreateContactUsLeadResponse message. Does not implicitly {@link visor.clientrpc.CreateContactUsLeadResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateContactUsLeadResponse} message CreateContactUsLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateContactUsLeadResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateContactUsLeadResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateContactUsLeadResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateContactUsLeadResponse} message CreateContactUsLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateContactUsLeadResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateContactUsLeadResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateContactUsLeadResponse} CreateContactUsLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateContactUsLeadResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateContactUsLeadResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateContactUsLeadResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateContactUsLeadResponse} CreateContactUsLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateContactUsLeadResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateContactUsLeadResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateContactUsLeadResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateContactUsLeadResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateContactUsLeadResponse} CreateContactUsLeadResponse
                 */
                CreateContactUsLeadResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateContactUsLeadResponse)
                        return object;
                    return new $root.visor.clientrpc.CreateContactUsLeadResponse();
                };

                /**
                 * Creates a plain object from a CreateContactUsLeadResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @static
                 * @param {visor.clientrpc.CreateContactUsLeadResponse} message CreateContactUsLeadResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateContactUsLeadResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateContactUsLeadResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateContactUsLeadResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateContactUsLeadResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateContactUsLeadResponse;
            })();

            clientrpc.ClearW4AdjustmentBannerRequest = (function() {

                /**
                 * Properties of a ClearW4AdjustmentBannerRequest.
                 * @memberof visor.clientrpc
                 * @interface IClearW4AdjustmentBannerRequest
                 */

                /**
                 * Constructs a new ClearW4AdjustmentBannerRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ClearW4AdjustmentBannerRequest.
                 * @implements IClearW4AdjustmentBannerRequest
                 * @constructor
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerRequest=} [properties] Properties to set
                 */
                function ClearW4AdjustmentBannerRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ClearW4AdjustmentBannerRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerRequest} ClearW4AdjustmentBannerRequest instance
                 */
                ClearW4AdjustmentBannerRequest.create = function create(properties) {
                    return new ClearW4AdjustmentBannerRequest(properties);
                };

                /**
                 * Encodes the specified ClearW4AdjustmentBannerRequest message. Does not implicitly {@link visor.clientrpc.ClearW4AdjustmentBannerRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerRequest} message ClearW4AdjustmentBannerRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClearW4AdjustmentBannerRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ClearW4AdjustmentBannerRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ClearW4AdjustmentBannerRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerRequest} message ClearW4AdjustmentBannerRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClearW4AdjustmentBannerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClearW4AdjustmentBannerRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerRequest} ClearW4AdjustmentBannerRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClearW4AdjustmentBannerRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ClearW4AdjustmentBannerRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClearW4AdjustmentBannerRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerRequest} ClearW4AdjustmentBannerRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClearW4AdjustmentBannerRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClearW4AdjustmentBannerRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClearW4AdjustmentBannerRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ClearW4AdjustmentBannerRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerRequest} ClearW4AdjustmentBannerRequest
                 */
                ClearW4AdjustmentBannerRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ClearW4AdjustmentBannerRequest)
                        return object;
                    return new $root.visor.clientrpc.ClearW4AdjustmentBannerRequest();
                };

                /**
                 * Creates a plain object from a ClearW4AdjustmentBannerRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @static
                 * @param {visor.clientrpc.ClearW4AdjustmentBannerRequest} message ClearW4AdjustmentBannerRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClearW4AdjustmentBannerRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ClearW4AdjustmentBannerRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClearW4AdjustmentBannerRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ClearW4AdjustmentBannerRequest;
            })();

            clientrpc.ClearW4AdjustmentBannerResponse = (function() {

                /**
                 * Properties of a ClearW4AdjustmentBannerResponse.
                 * @memberof visor.clientrpc
                 * @interface IClearW4AdjustmentBannerResponse
                 */

                /**
                 * Constructs a new ClearW4AdjustmentBannerResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ClearW4AdjustmentBannerResponse.
                 * @implements IClearW4AdjustmentBannerResponse
                 * @constructor
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerResponse=} [properties] Properties to set
                 */
                function ClearW4AdjustmentBannerResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ClearW4AdjustmentBannerResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerResponse} ClearW4AdjustmentBannerResponse instance
                 */
                ClearW4AdjustmentBannerResponse.create = function create(properties) {
                    return new ClearW4AdjustmentBannerResponse(properties);
                };

                /**
                 * Encodes the specified ClearW4AdjustmentBannerResponse message. Does not implicitly {@link visor.clientrpc.ClearW4AdjustmentBannerResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerResponse} message ClearW4AdjustmentBannerResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClearW4AdjustmentBannerResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ClearW4AdjustmentBannerResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ClearW4AdjustmentBannerResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {visor.clientrpc.IClearW4AdjustmentBannerResponse} message ClearW4AdjustmentBannerResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ClearW4AdjustmentBannerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ClearW4AdjustmentBannerResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerResponse} ClearW4AdjustmentBannerResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClearW4AdjustmentBannerResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ClearW4AdjustmentBannerResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ClearW4AdjustmentBannerResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerResponse} ClearW4AdjustmentBannerResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ClearW4AdjustmentBannerResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ClearW4AdjustmentBannerResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ClearW4AdjustmentBannerResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ClearW4AdjustmentBannerResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ClearW4AdjustmentBannerResponse} ClearW4AdjustmentBannerResponse
                 */
                ClearW4AdjustmentBannerResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ClearW4AdjustmentBannerResponse)
                        return object;
                    return new $root.visor.clientrpc.ClearW4AdjustmentBannerResponse();
                };

                /**
                 * Creates a plain object from a ClearW4AdjustmentBannerResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @static
                 * @param {visor.clientrpc.ClearW4AdjustmentBannerResponse} message ClearW4AdjustmentBannerResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ClearW4AdjustmentBannerResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ClearW4AdjustmentBannerResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ClearW4AdjustmentBannerResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ClearW4AdjustmentBannerResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ClearW4AdjustmentBannerResponse;
            })();

            clientrpc.TestErrorsRequest = (function() {

                /**
                 * Properties of a TestErrorsRequest.
                 * @memberof visor.clientrpc
                 * @interface ITestErrorsRequest
                 * @property {number|Long|null} [errorRate] TestErrorsRequest errorRate
                 */

                /**
                 * Constructs a new TestErrorsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a TestErrorsRequest.
                 * @implements ITestErrorsRequest
                 * @constructor
                 * @param {visor.clientrpc.ITestErrorsRequest=} [properties] Properties to set
                 */
                function TestErrorsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TestErrorsRequest errorRate.
                 * @member {number|Long} errorRate
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @instance
                 */
                TestErrorsRequest.prototype.errorRate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new TestErrorsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {visor.clientrpc.ITestErrorsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.TestErrorsRequest} TestErrorsRequest instance
                 */
                TestErrorsRequest.create = function create(properties) {
                    return new TestErrorsRequest(properties);
                };

                /**
                 * Encodes the specified TestErrorsRequest message. Does not implicitly {@link visor.clientrpc.TestErrorsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {visor.clientrpc.ITestErrorsRequest} message TestErrorsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TestErrorsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.errorRate != null && message.hasOwnProperty("errorRate"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.errorRate);
                    return writer;
                };

                /**
                 * Encodes the specified TestErrorsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.TestErrorsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {visor.clientrpc.ITestErrorsRequest} message TestErrorsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TestErrorsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TestErrorsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.TestErrorsRequest} TestErrorsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TestErrorsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.TestErrorsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.errorRate = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TestErrorsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.TestErrorsRequest} TestErrorsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TestErrorsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TestErrorsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TestErrorsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.errorRate != null && message.hasOwnProperty("errorRate"))
                        if (!$util.isInteger(message.errorRate) && !(message.errorRate && $util.isInteger(message.errorRate.low) && $util.isInteger(message.errorRate.high)))
                            return "errorRate: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a TestErrorsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.TestErrorsRequest} TestErrorsRequest
                 */
                TestErrorsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.TestErrorsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.TestErrorsRequest();
                    if (object.errorRate != null)
                        if ($util.Long)
                            (message.errorRate = $util.Long.fromValue(object.errorRate)).unsigned = true;
                        else if (typeof object.errorRate === "string")
                            message.errorRate = parseInt(object.errorRate, 10);
                        else if (typeof object.errorRate === "number")
                            message.errorRate = object.errorRate;
                        else if (typeof object.errorRate === "object")
                            message.errorRate = new $util.LongBits(object.errorRate.low >>> 0, object.errorRate.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a TestErrorsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @static
                 * @param {visor.clientrpc.TestErrorsRequest} message TestErrorsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TestErrorsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.errorRate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.errorRate = options.longs === String ? "0" : 0;
                    if (message.errorRate != null && message.hasOwnProperty("errorRate"))
                        if (typeof message.errorRate === "number")
                            object.errorRate = options.longs === String ? String(message.errorRate) : message.errorRate;
                        else
                            object.errorRate = options.longs === String ? $util.Long.prototype.toString.call(message.errorRate) : options.longs === Number ? new $util.LongBits(message.errorRate.low >>> 0, message.errorRate.high >>> 0).toNumber(true) : message.errorRate;
                    return object;
                };

                /**
                 * Converts this TestErrorsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.TestErrorsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TestErrorsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TestErrorsRequest;
            })();

            clientrpc.TestErrorsResponse = (function() {

                /**
                 * Properties of a TestErrorsResponse.
                 * @memberof visor.clientrpc
                 * @interface ITestErrorsResponse
                 * @property {Array.<string>|null} [documentsUpdated] TestErrorsResponse documentsUpdated
                 */

                /**
                 * Constructs a new TestErrorsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a TestErrorsResponse.
                 * @implements ITestErrorsResponse
                 * @constructor
                 * @param {visor.clientrpc.ITestErrorsResponse=} [properties] Properties to set
                 */
                function TestErrorsResponse(properties) {
                    this.documentsUpdated = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TestErrorsResponse documentsUpdated.
                 * @member {Array.<string>} documentsUpdated
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @instance
                 */
                TestErrorsResponse.prototype.documentsUpdated = $util.emptyArray;

                /**
                 * Creates a new TestErrorsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {visor.clientrpc.ITestErrorsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.TestErrorsResponse} TestErrorsResponse instance
                 */
                TestErrorsResponse.create = function create(properties) {
                    return new TestErrorsResponse(properties);
                };

                /**
                 * Encodes the specified TestErrorsResponse message. Does not implicitly {@link visor.clientrpc.TestErrorsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {visor.clientrpc.ITestErrorsResponse} message TestErrorsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TestErrorsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.documentsUpdated != null && message.documentsUpdated.length)
                        for (var i = 0; i < message.documentsUpdated.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.documentsUpdated[i]);
                    return writer;
                };

                /**
                 * Encodes the specified TestErrorsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.TestErrorsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {visor.clientrpc.ITestErrorsResponse} message TestErrorsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TestErrorsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TestErrorsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.TestErrorsResponse} TestErrorsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TestErrorsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.TestErrorsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            if (!(message.documentsUpdated && message.documentsUpdated.length))
                                message.documentsUpdated = [];
                            message.documentsUpdated.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TestErrorsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.TestErrorsResponse} TestErrorsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TestErrorsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TestErrorsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TestErrorsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.documentsUpdated != null && message.hasOwnProperty("documentsUpdated")) {
                        if (!Array.isArray(message.documentsUpdated))
                            return "documentsUpdated: array expected";
                        for (var i = 0; i < message.documentsUpdated.length; ++i)
                            if (!$util.isString(message.documentsUpdated[i]))
                                return "documentsUpdated: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a TestErrorsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.TestErrorsResponse} TestErrorsResponse
                 */
                TestErrorsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.TestErrorsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.TestErrorsResponse();
                    if (object.documentsUpdated) {
                        if (!Array.isArray(object.documentsUpdated))
                            throw TypeError(".visor.clientrpc.TestErrorsResponse.documentsUpdated: array expected");
                        message.documentsUpdated = [];
                        for (var i = 0; i < object.documentsUpdated.length; ++i)
                            message.documentsUpdated[i] = String(object.documentsUpdated[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TestErrorsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @static
                 * @param {visor.clientrpc.TestErrorsResponse} message TestErrorsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TestErrorsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.documentsUpdated = [];
                    if (message.documentsUpdated && message.documentsUpdated.length) {
                        object.documentsUpdated = [];
                        for (var j = 0; j < message.documentsUpdated.length; ++j)
                            object.documentsUpdated[j] = message.documentsUpdated[j];
                    }
                    return object;
                };

                /**
                 * Converts this TestErrorsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.TestErrorsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TestErrorsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TestErrorsResponse;
            })();

            clientrpc.PingRequest = (function() {

                /**
                 * Properties of a PingRequest.
                 * @memberof visor.clientrpc
                 * @interface IPingRequest
                 * @property {string|null} [roundtrip] PingRequest roundtrip
                 * @property {string|null} [clientTimestamp] PingRequest clientTimestamp
                 */

                /**
                 * Constructs a new PingRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PingRequest.
                 * @implements IPingRequest
                 * @constructor
                 * @param {visor.clientrpc.IPingRequest=} [properties] Properties to set
                 */
                function PingRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PingRequest roundtrip.
                 * @member {string} roundtrip
                 * @memberof visor.clientrpc.PingRequest
                 * @instance
                 */
                PingRequest.prototype.roundtrip = "";

                /**
                 * PingRequest clientTimestamp.
                 * @member {string} clientTimestamp
                 * @memberof visor.clientrpc.PingRequest
                 * @instance
                 */
                PingRequest.prototype.clientTimestamp = "";

                /**
                 * Creates a new PingRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {visor.clientrpc.IPingRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.PingRequest} PingRequest instance
                 */
                PingRequest.create = function create(properties) {
                    return new PingRequest(properties);
                };

                /**
                 * Encodes the specified PingRequest message. Does not implicitly {@link visor.clientrpc.PingRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {visor.clientrpc.IPingRequest} message PingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PingRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.roundtrip != null && message.hasOwnProperty("roundtrip"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.roundtrip);
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientTimestamp);
                    return writer;
                };

                /**
                 * Encodes the specified PingRequest message, length delimited. Does not implicitly {@link visor.clientrpc.PingRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {visor.clientrpc.IPingRequest} message PingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PingRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PingRequest} PingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PingRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PingRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.roundtrip = reader.string();
                            break;
                        case 2:
                            message.clientTimestamp = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PingRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PingRequest} PingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PingRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PingRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PingRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.roundtrip != null && message.hasOwnProperty("roundtrip"))
                        if (!$util.isString(message.roundtrip))
                            return "roundtrip: string expected";
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp"))
                        if (!$util.isString(message.clientTimestamp))
                            return "clientTimestamp: string expected";
                    return null;
                };

                /**
                 * Creates a PingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PingRequest} PingRequest
                 */
                PingRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PingRequest)
                        return object;
                    var message = new $root.visor.clientrpc.PingRequest();
                    if (object.roundtrip != null)
                        message.roundtrip = String(object.roundtrip);
                    if (object.clientTimestamp != null)
                        message.clientTimestamp = String(object.clientTimestamp);
                    return message;
                };

                /**
                 * Creates a plain object from a PingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PingRequest
                 * @static
                 * @param {visor.clientrpc.PingRequest} message PingRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PingRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.roundtrip = "";
                        object.clientTimestamp = "";
                    }
                    if (message.roundtrip != null && message.hasOwnProperty("roundtrip"))
                        object.roundtrip = message.roundtrip;
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp"))
                        object.clientTimestamp = message.clientTimestamp;
                    return object;
                };

                /**
                 * Converts this PingRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PingRequest;
            })();

            clientrpc.PingResponse = (function() {

                /**
                 * Properties of a PingResponse.
                 * @memberof visor.clientrpc
                 * @interface IPingResponse
                 * @property {string|null} [pingId] PingResponse pingId
                 */

                /**
                 * Constructs a new PingResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PingResponse.
                 * @implements IPingResponse
                 * @constructor
                 * @param {visor.clientrpc.IPingResponse=} [properties] Properties to set
                 */
                function PingResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PingResponse pingId.
                 * @member {string} pingId
                 * @memberof visor.clientrpc.PingResponse
                 * @instance
                 */
                PingResponse.prototype.pingId = "";

                /**
                 * Creates a new PingResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {visor.clientrpc.IPingResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.PingResponse} PingResponse instance
                 */
                PingResponse.create = function create(properties) {
                    return new PingResponse(properties);
                };

                /**
                 * Encodes the specified PingResponse message. Does not implicitly {@link visor.clientrpc.PingResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {visor.clientrpc.IPingResponse} message PingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PingResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pingId != null && message.hasOwnProperty("pingId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pingId);
                    return writer;
                };

                /**
                 * Encodes the specified PingResponse message, length delimited. Does not implicitly {@link visor.clientrpc.PingResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {visor.clientrpc.IPingResponse} message PingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PingResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PingResponse} PingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PingResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PingResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.pingId = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PingResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PingResponse} PingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PingResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PingResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PingResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pingId != null && message.hasOwnProperty("pingId"))
                        if (!$util.isString(message.pingId))
                            return "pingId: string expected";
                    return null;
                };

                /**
                 * Creates a PingResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PingResponse} PingResponse
                 */
                PingResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PingResponse)
                        return object;
                    var message = new $root.visor.clientrpc.PingResponse();
                    if (object.pingId != null)
                        message.pingId = String(object.pingId);
                    return message;
                };

                /**
                 * Creates a plain object from a PingResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PingResponse
                 * @static
                 * @param {visor.clientrpc.PingResponse} message PingResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PingResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.pingId = "";
                    if (message.pingId != null && message.hasOwnProperty("pingId"))
                        object.pingId = message.pingId;
                    return object;
                };

                /**
                 * Converts this PingResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PingResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PingResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PingResponse;
            })();

            clientrpc.StartOnboardingRequest = (function() {

                /**
                 * Properties of a StartOnboardingRequest.
                 * @memberof visor.clientrpc
                 * @interface IStartOnboardingRequest
                 * @property {string|null} [timezone] StartOnboardingRequest timezone
                 * @property {Object.<string,string>|null} [signupMetadata] StartOnboardingRequest signupMetadata
                 * @property {number|Long|null} [timetravelOffset] StartOnboardingRequest timetravelOffset
                 * @property {boolean|null} [testClient] StartOnboardingRequest testClient
                 */

                /**
                 * Constructs a new StartOnboardingRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a StartOnboardingRequest.
                 * @implements IStartOnboardingRequest
                 * @constructor
                 * @param {visor.clientrpc.IStartOnboardingRequest=} [properties] Properties to set
                 */
                function StartOnboardingRequest(properties) {
                    this.signupMetadata = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StartOnboardingRequest timezone.
                 * @member {string} timezone
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @instance
                 */
                StartOnboardingRequest.prototype.timezone = "";

                /**
                 * StartOnboardingRequest signupMetadata.
                 * @member {Object.<string,string>} signupMetadata
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @instance
                 */
                StartOnboardingRequest.prototype.signupMetadata = $util.emptyObject;

                /**
                 * StartOnboardingRequest timetravelOffset.
                 * @member {number|Long} timetravelOffset
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @instance
                 */
                StartOnboardingRequest.prototype.timetravelOffset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * StartOnboardingRequest testClient.
                 * @member {boolean} testClient
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @instance
                 */
                StartOnboardingRequest.prototype.testClient = false;

                /**
                 * Creates a new StartOnboardingRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {visor.clientrpc.IStartOnboardingRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.StartOnboardingRequest} StartOnboardingRequest instance
                 */
                StartOnboardingRequest.create = function create(properties) {
                    return new StartOnboardingRequest(properties);
                };

                /**
                 * Encodes the specified StartOnboardingRequest message. Does not implicitly {@link visor.clientrpc.StartOnboardingRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {visor.clientrpc.IStartOnboardingRequest} message StartOnboardingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartOnboardingRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.timezone != null && message.hasOwnProperty("timezone"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.timezone);
                    if (message.signupMetadata != null && message.hasOwnProperty("signupMetadata"))
                        for (var keys = Object.keys(message.signupMetadata), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.signupMetadata[keys[i]]).ldelim();
                    if (message.timetravelOffset != null && message.hasOwnProperty("timetravelOffset"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.timetravelOffset);
                    if (message.testClient != null && message.hasOwnProperty("testClient"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.testClient);
                    return writer;
                };

                /**
                 * Encodes the specified StartOnboardingRequest message, length delimited. Does not implicitly {@link visor.clientrpc.StartOnboardingRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {visor.clientrpc.IStartOnboardingRequest} message StartOnboardingRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartOnboardingRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StartOnboardingRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.StartOnboardingRequest} StartOnboardingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartOnboardingRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.StartOnboardingRequest(), key;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.timezone = reader.string();
                            break;
                        case 2:
                            reader.skip().pos++;
                            if (message.signupMetadata === $util.emptyObject)
                                message.signupMetadata = {};
                            key = reader.string();
                            reader.pos++;
                            message.signupMetadata[key] = reader.string();
                            break;
                        case 3:
                            message.timetravelOffset = reader.int64();
                            break;
                        case 4:
                            message.testClient = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StartOnboardingRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.StartOnboardingRequest} StartOnboardingRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartOnboardingRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StartOnboardingRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartOnboardingRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.timezone != null && message.hasOwnProperty("timezone"))
                        if (!$util.isString(message.timezone))
                            return "timezone: string expected";
                    if (message.signupMetadata != null && message.hasOwnProperty("signupMetadata")) {
                        if (!$util.isObject(message.signupMetadata))
                            return "signupMetadata: object expected";
                        var key = Object.keys(message.signupMetadata);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.signupMetadata[key[i]]))
                                return "signupMetadata: string{k:string} expected";
                    }
                    if (message.timetravelOffset != null && message.hasOwnProperty("timetravelOffset"))
                        if (!$util.isInteger(message.timetravelOffset) && !(message.timetravelOffset && $util.isInteger(message.timetravelOffset.low) && $util.isInteger(message.timetravelOffset.high)))
                            return "timetravelOffset: integer|Long expected";
                    if (message.testClient != null && message.hasOwnProperty("testClient"))
                        if (typeof message.testClient !== "boolean")
                            return "testClient: boolean expected";
                    return null;
                };

                /**
                 * Creates a StartOnboardingRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.StartOnboardingRequest} StartOnboardingRequest
                 */
                StartOnboardingRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.StartOnboardingRequest)
                        return object;
                    var message = new $root.visor.clientrpc.StartOnboardingRequest();
                    if (object.timezone != null)
                        message.timezone = String(object.timezone);
                    if (object.signupMetadata) {
                        if (typeof object.signupMetadata !== "object")
                            throw TypeError(".visor.clientrpc.StartOnboardingRequest.signupMetadata: object expected");
                        message.signupMetadata = {};
                        for (var keys = Object.keys(object.signupMetadata), i = 0; i < keys.length; ++i)
                            message.signupMetadata[keys[i]] = String(object.signupMetadata[keys[i]]);
                    }
                    if (object.timetravelOffset != null)
                        if ($util.Long)
                            (message.timetravelOffset = $util.Long.fromValue(object.timetravelOffset)).unsigned = false;
                        else if (typeof object.timetravelOffset === "string")
                            message.timetravelOffset = parseInt(object.timetravelOffset, 10);
                        else if (typeof object.timetravelOffset === "number")
                            message.timetravelOffset = object.timetravelOffset;
                        else if (typeof object.timetravelOffset === "object")
                            message.timetravelOffset = new $util.LongBits(object.timetravelOffset.low >>> 0, object.timetravelOffset.high >>> 0).toNumber();
                    if (object.testClient != null)
                        message.testClient = Boolean(object.testClient);
                    return message;
                };

                /**
                 * Creates a plain object from a StartOnboardingRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @static
                 * @param {visor.clientrpc.StartOnboardingRequest} message StartOnboardingRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartOnboardingRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.signupMetadata = {};
                    if (options.defaults) {
                        object.timezone = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timetravelOffset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timetravelOffset = options.longs === String ? "0" : 0;
                        object.testClient = false;
                    }
                    if (message.timezone != null && message.hasOwnProperty("timezone"))
                        object.timezone = message.timezone;
                    var keys2;
                    if (message.signupMetadata && (keys2 = Object.keys(message.signupMetadata)).length) {
                        object.signupMetadata = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.signupMetadata[keys2[j]] = message.signupMetadata[keys2[j]];
                    }
                    if (message.timetravelOffset != null && message.hasOwnProperty("timetravelOffset"))
                        if (typeof message.timetravelOffset === "number")
                            object.timetravelOffset = options.longs === String ? String(message.timetravelOffset) : message.timetravelOffset;
                        else
                            object.timetravelOffset = options.longs === String ? $util.Long.prototype.toString.call(message.timetravelOffset) : options.longs === Number ? new $util.LongBits(message.timetravelOffset.low >>> 0, message.timetravelOffset.high >>> 0).toNumber() : message.timetravelOffset;
                    if (message.testClient != null && message.hasOwnProperty("testClient"))
                        object.testClient = message.testClient;
                    return object;
                };

                /**
                 * Converts this StartOnboardingRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.StartOnboardingRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartOnboardingRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartOnboardingRequest;
            })();

            clientrpc.StartOnboardingResponse = (function() {

                /**
                 * Properties of a StartOnboardingResponse.
                 * @memberof visor.clientrpc
                 * @interface IStartOnboardingResponse
                 * @property {string|null} [firebaseAuthToken] StartOnboardingResponse firebaseAuthToken
                 */

                /**
                 * Constructs a new StartOnboardingResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a StartOnboardingResponse.
                 * @implements IStartOnboardingResponse
                 * @constructor
                 * @param {visor.clientrpc.IStartOnboardingResponse=} [properties] Properties to set
                 */
                function StartOnboardingResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StartOnboardingResponse firebaseAuthToken.
                 * @member {string} firebaseAuthToken
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @instance
                 */
                StartOnboardingResponse.prototype.firebaseAuthToken = "";

                /**
                 * Creates a new StartOnboardingResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {visor.clientrpc.IStartOnboardingResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.StartOnboardingResponse} StartOnboardingResponse instance
                 */
                StartOnboardingResponse.create = function create(properties) {
                    return new StartOnboardingResponse(properties);
                };

                /**
                 * Encodes the specified StartOnboardingResponse message. Does not implicitly {@link visor.clientrpc.StartOnboardingResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {visor.clientrpc.IStartOnboardingResponse} message StartOnboardingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartOnboardingResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firebaseAuthToken != null && message.hasOwnProperty("firebaseAuthToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firebaseAuthToken);
                    return writer;
                };

                /**
                 * Encodes the specified StartOnboardingResponse message, length delimited. Does not implicitly {@link visor.clientrpc.StartOnboardingResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {visor.clientrpc.IStartOnboardingResponse} message StartOnboardingResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartOnboardingResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StartOnboardingResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.StartOnboardingResponse} StartOnboardingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartOnboardingResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.StartOnboardingResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.firebaseAuthToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StartOnboardingResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.StartOnboardingResponse} StartOnboardingResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartOnboardingResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StartOnboardingResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartOnboardingResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firebaseAuthToken != null && message.hasOwnProperty("firebaseAuthToken"))
                        if (!$util.isString(message.firebaseAuthToken))
                            return "firebaseAuthToken: string expected";
                    return null;
                };

                /**
                 * Creates a StartOnboardingResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.StartOnboardingResponse} StartOnboardingResponse
                 */
                StartOnboardingResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.StartOnboardingResponse)
                        return object;
                    var message = new $root.visor.clientrpc.StartOnboardingResponse();
                    if (object.firebaseAuthToken != null)
                        message.firebaseAuthToken = String(object.firebaseAuthToken);
                    return message;
                };

                /**
                 * Creates a plain object from a StartOnboardingResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @static
                 * @param {visor.clientrpc.StartOnboardingResponse} message StartOnboardingResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartOnboardingResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.firebaseAuthToken = "";
                    if (message.firebaseAuthToken != null && message.hasOwnProperty("firebaseAuthToken"))
                        object.firebaseAuthToken = message.firebaseAuthToken;
                    return object;
                };

                /**
                 * Converts this StartOnboardingResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.StartOnboardingResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartOnboardingResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartOnboardingResponse;
            })();

            clientrpc.CompleteLedgerStepRequest = (function() {

                /**
                 * Properties of a CompleteLedgerStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteLedgerStepRequest
                 * @property {string|null} [stepToken] CompleteLedgerStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteLedgerStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteLedgerStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteLedgerStepRequest.
                 * @implements ICompleteLedgerStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteLedgerStepRequest=} [properties] Properties to set
                 */
                function CompleteLedgerStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteLedgerStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @instance
                 */
                CompleteLedgerStepRequest.prototype.stepToken = "";

                /**
                 * CompleteLedgerStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @instance
                 */
                CompleteLedgerStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteLedgerStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteLedgerStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteLedgerStepRequest} CompleteLedgerStepRequest instance
                 */
                CompleteLedgerStepRequest.create = function create(properties) {
                    return new CompleteLedgerStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteLedgerStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteLedgerStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteLedgerStepRequest} message CompleteLedgerStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLedgerStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteLedgerStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteLedgerStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteLedgerStepRequest} message CompleteLedgerStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLedgerStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteLedgerStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteLedgerStepRequest} CompleteLedgerStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLedgerStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteLedgerStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteLedgerStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteLedgerStepRequest} CompleteLedgerStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLedgerStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteLedgerStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteLedgerStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteLedgerStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteLedgerStepRequest} CompleteLedgerStepRequest
                 */
                CompleteLedgerStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteLedgerStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteLedgerStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteLedgerStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteLedgerStepRequest} message CompleteLedgerStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteLedgerStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteLedgerStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteLedgerStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteLedgerStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteLedgerStepRequest;
            })();

            clientrpc.CompleteLedgerStepResponse = (function() {

                /**
                 * Properties of a CompleteLedgerStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteLedgerStepResponse
                 */

                /**
                 * Constructs a new CompleteLedgerStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteLedgerStepResponse.
                 * @implements ICompleteLedgerStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteLedgerStepResponse=} [properties] Properties to set
                 */
                function CompleteLedgerStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteLedgerStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteLedgerStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteLedgerStepResponse} CompleteLedgerStepResponse instance
                 */
                CompleteLedgerStepResponse.create = function create(properties) {
                    return new CompleteLedgerStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteLedgerStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteLedgerStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteLedgerStepResponse} message CompleteLedgerStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLedgerStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteLedgerStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteLedgerStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteLedgerStepResponse} message CompleteLedgerStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLedgerStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteLedgerStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteLedgerStepResponse} CompleteLedgerStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLedgerStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteLedgerStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteLedgerStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteLedgerStepResponse} CompleteLedgerStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLedgerStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteLedgerStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteLedgerStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteLedgerStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteLedgerStepResponse} CompleteLedgerStepResponse
                 */
                CompleteLedgerStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteLedgerStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteLedgerStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteLedgerStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteLedgerStepResponse} message CompleteLedgerStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteLedgerStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteLedgerStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteLedgerStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteLedgerStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteLedgerStepResponse;
            })();

            clientrpc.CompleteReviewBoostPerformanceStepRequest = (function() {

                /**
                 * Properties of a CompleteReviewBoostPerformanceStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteReviewBoostPerformanceStepRequest
                 * @property {string|null} [stepToken] CompleteReviewBoostPerformanceStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteReviewBoostPerformanceStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteReviewBoostPerformanceStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteReviewBoostPerformanceStepRequest.
                 * @implements ICompleteReviewBoostPerformanceStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepRequest=} [properties] Properties to set
                 */
                function CompleteReviewBoostPerformanceStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteReviewBoostPerformanceStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @instance
                 */
                CompleteReviewBoostPerformanceStepRequest.prototype.stepToken = "";

                /**
                 * CompleteReviewBoostPerformanceStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @instance
                 */
                CompleteReviewBoostPerformanceStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteReviewBoostPerformanceStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepRequest} CompleteReviewBoostPerformanceStepRequest instance
                 */
                CompleteReviewBoostPerformanceStepRequest.create = function create(properties) {
                    return new CompleteReviewBoostPerformanceStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteReviewBoostPerformanceStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteReviewBoostPerformanceStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepRequest} message CompleteReviewBoostPerformanceStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewBoostPerformanceStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteReviewBoostPerformanceStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteReviewBoostPerformanceStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepRequest} message CompleteReviewBoostPerformanceStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewBoostPerformanceStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteReviewBoostPerformanceStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepRequest} CompleteReviewBoostPerformanceStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewBoostPerformanceStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteReviewBoostPerformanceStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteReviewBoostPerformanceStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepRequest} CompleteReviewBoostPerformanceStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewBoostPerformanceStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteReviewBoostPerformanceStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewBoostPerformanceStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteReviewBoostPerformanceStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepRequest} CompleteReviewBoostPerformanceStepRequest
                 */
                CompleteReviewBoostPerformanceStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteReviewBoostPerformanceStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteReviewBoostPerformanceStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteReviewBoostPerformanceStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteReviewBoostPerformanceStepRequest} message CompleteReviewBoostPerformanceStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewBoostPerformanceStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteReviewBoostPerformanceStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewBoostPerformanceStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteReviewBoostPerformanceStepRequest;
            })();

            clientrpc.CompleteReviewBoostPerformanceStepResponse = (function() {

                /**
                 * Properties of a CompleteReviewBoostPerformanceStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteReviewBoostPerformanceStepResponse
                 */

                /**
                 * Constructs a new CompleteReviewBoostPerformanceStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteReviewBoostPerformanceStepResponse.
                 * @implements ICompleteReviewBoostPerformanceStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepResponse=} [properties] Properties to set
                 */
                function CompleteReviewBoostPerformanceStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteReviewBoostPerformanceStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepResponse} CompleteReviewBoostPerformanceStepResponse instance
                 */
                CompleteReviewBoostPerformanceStepResponse.create = function create(properties) {
                    return new CompleteReviewBoostPerformanceStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteReviewBoostPerformanceStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteReviewBoostPerformanceStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepResponse} message CompleteReviewBoostPerformanceStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewBoostPerformanceStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteReviewBoostPerformanceStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteReviewBoostPerformanceStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewBoostPerformanceStepResponse} message CompleteReviewBoostPerformanceStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewBoostPerformanceStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteReviewBoostPerformanceStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepResponse} CompleteReviewBoostPerformanceStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewBoostPerformanceStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteReviewBoostPerformanceStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteReviewBoostPerformanceStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepResponse} CompleteReviewBoostPerformanceStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewBoostPerformanceStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteReviewBoostPerformanceStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewBoostPerformanceStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteReviewBoostPerformanceStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteReviewBoostPerformanceStepResponse} CompleteReviewBoostPerformanceStepResponse
                 */
                CompleteReviewBoostPerformanceStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteReviewBoostPerformanceStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteReviewBoostPerformanceStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteReviewBoostPerformanceStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteReviewBoostPerformanceStepResponse} message CompleteReviewBoostPerformanceStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewBoostPerformanceStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteReviewBoostPerformanceStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteReviewBoostPerformanceStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewBoostPerformanceStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteReviewBoostPerformanceStepResponse;
            })();

            clientrpc.CompleteTextStepRequest = (function() {

                /**
                 * Properties of a CompleteTextStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTextStepRequest
                 * @property {string|null} [stepToken] CompleteTextStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteTextStepRequest workflowToken
                 * @property {string|null} [text] CompleteTextStepRequest text
                 */

                /**
                 * Constructs a new CompleteTextStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTextStepRequest.
                 * @implements ICompleteTextStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTextStepRequest=} [properties] Properties to set
                 */
                function CompleteTextStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteTextStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @instance
                 */
                CompleteTextStepRequest.prototype.stepToken = "";

                /**
                 * CompleteTextStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @instance
                 */
                CompleteTextStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteTextStepRequest text.
                 * @member {string} text
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @instance
                 */
                CompleteTextStepRequest.prototype.text = "";

                /**
                 * Creates a new CompleteTextStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTextStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTextStepRequest} CompleteTextStepRequest instance
                 */
                CompleteTextStepRequest.create = function create(properties) {
                    return new CompleteTextStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteTextStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteTextStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTextStepRequest} message CompleteTextStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTextStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.text != null && message.hasOwnProperty("text"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTextStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTextStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTextStepRequest} message CompleteTextStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTextStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTextStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTextStepRequest} CompleteTextStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTextStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTextStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.text = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTextStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTextStepRequest} CompleteTextStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTextStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTextStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTextStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteTextStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTextStepRequest} CompleteTextStepRequest
                 */
                CompleteTextStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTextStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteTextStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.text != null)
                        message.text = String(object.text);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteTextStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteTextStepRequest} message CompleteTextStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTextStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.text = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    return object;
                };

                /**
                 * Converts this CompleteTextStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTextStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTextStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTextStepRequest;
            })();

            clientrpc.CompleteTextStepResponse = (function() {

                /**
                 * Properties of a CompleteTextStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTextStepResponse
                 */

                /**
                 * Constructs a new CompleteTextStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTextStepResponse.
                 * @implements ICompleteTextStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTextStepResponse=} [properties] Properties to set
                 */
                function CompleteTextStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteTextStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTextStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTextStepResponse} CompleteTextStepResponse instance
                 */
                CompleteTextStepResponse.create = function create(properties) {
                    return new CompleteTextStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteTextStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteTextStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTextStepResponse} message CompleteTextStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTextStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTextStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTextStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTextStepResponse} message CompleteTextStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTextStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTextStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTextStepResponse} CompleteTextStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTextStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTextStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTextStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTextStepResponse} CompleteTextStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTextStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTextStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTextStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteTextStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTextStepResponse} CompleteTextStepResponse
                 */
                CompleteTextStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTextStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteTextStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteTextStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteTextStepResponse} message CompleteTextStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTextStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteTextStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTextStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTextStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTextStepResponse;
            })();

            clientrpc.CompleteDateStepRequest = (function() {

                /**
                 * Properties of a CompleteDateStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDateStepRequest
                 * @property {string|null} [stepToken] CompleteDateStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteDateStepRequest workflowToken
                 * @property {visor.common.time.ILocalDate|null} [date] CompleteDateStepRequest date
                 */

                /**
                 * Constructs a new CompleteDateStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDateStepRequest.
                 * @implements ICompleteDateStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDateStepRequest=} [properties] Properties to set
                 */
                function CompleteDateStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteDateStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @instance
                 */
                CompleteDateStepRequest.prototype.stepToken = "";

                /**
                 * CompleteDateStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @instance
                 */
                CompleteDateStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteDateStepRequest date.
                 * @member {visor.common.time.ILocalDate|null|undefined} date
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @instance
                 */
                CompleteDateStepRequest.prototype.date = null;

                /**
                 * Creates a new CompleteDateStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDateStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDateStepRequest} CompleteDateStepRequest instance
                 */
                CompleteDateStepRequest.create = function create(properties) {
                    return new CompleteDateStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteDateStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteDateStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDateStepRequest} message CompleteDateStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDateStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDateStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDateStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDateStepRequest} message CompleteDateStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDateStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDateStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDateStepRequest} CompleteDateStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDateStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDateStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDateStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDateStepRequest} CompleteDateStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDateStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDateStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDateStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.date != null && message.hasOwnProperty("date")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.date);
                        if (error)
                            return "date." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteDateStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDateStepRequest} CompleteDateStepRequest
                 */
                CompleteDateStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDateStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteDateStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.CompleteDateStepRequest.date: object expected");
                        message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteDateStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteDateStepRequest} message CompleteDateStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDateStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.date = null;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.date != null && message.hasOwnProperty("date"))
                        object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                    return object;
                };

                /**
                 * Converts this CompleteDateStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDateStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDateStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDateStepRequest;
            })();

            clientrpc.CompleteDateStepResponse = (function() {

                /**
                 * Properties of a CompleteDateStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDateStepResponse
                 */

                /**
                 * Constructs a new CompleteDateStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDateStepResponse.
                 * @implements ICompleteDateStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDateStepResponse=} [properties] Properties to set
                 */
                function CompleteDateStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteDateStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDateStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDateStepResponse} CompleteDateStepResponse instance
                 */
                CompleteDateStepResponse.create = function create(properties) {
                    return new CompleteDateStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteDateStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteDateStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDateStepResponse} message CompleteDateStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDateStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDateStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDateStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDateStepResponse} message CompleteDateStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDateStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDateStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDateStepResponse} CompleteDateStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDateStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDateStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDateStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDateStepResponse} CompleteDateStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDateStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDateStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDateStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteDateStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDateStepResponse} CompleteDateStepResponse
                 */
                CompleteDateStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDateStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteDateStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteDateStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteDateStepResponse} message CompleteDateStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDateStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteDateStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDateStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDateStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDateStepResponse;
            })();

            clientrpc.CompleteAddressStepRequest = (function() {

                /**
                 * Properties of a CompleteAddressStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteAddressStepRequest
                 * @property {string|null} [stepToken] CompleteAddressStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteAddressStepRequest workflowToken
                 * @property {string|null} [address_1] CompleteAddressStepRequest address_1
                 * @property {string|null} [address_2] CompleteAddressStepRequest address_2
                 * @property {string|null} [city] CompleteAddressStepRequest city
                 * @property {string|null} [state] CompleteAddressStepRequest state
                 * @property {string|null} [zip] CompleteAddressStepRequest zip
                 * @property {string|null} [mailingAddress_1] CompleteAddressStepRequest mailingAddress_1
                 * @property {string|null} [mailingAddress_2] CompleteAddressStepRequest mailingAddress_2
                 * @property {string|null} [mailingCity] CompleteAddressStepRequest mailingCity
                 * @property {string|null} [mailingState] CompleteAddressStepRequest mailingState
                 * @property {string|null} [mailingZip] CompleteAddressStepRequest mailingZip
                 */

                /**
                 * Constructs a new CompleteAddressStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteAddressStepRequest.
                 * @implements ICompleteAddressStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteAddressStepRequest=} [properties] Properties to set
                 */
                function CompleteAddressStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteAddressStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.stepToken = "";

                /**
                 * CompleteAddressStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteAddressStepRequest address_1.
                 * @member {string} address_1
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.address_1 = "";

                /**
                 * CompleteAddressStepRequest address_2.
                 * @member {string} address_2
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.address_2 = "";

                /**
                 * CompleteAddressStepRequest city.
                 * @member {string} city
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.city = "";

                /**
                 * CompleteAddressStepRequest state.
                 * @member {string} state
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.state = "";

                /**
                 * CompleteAddressStepRequest zip.
                 * @member {string} zip
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.zip = "";

                /**
                 * CompleteAddressStepRequest mailingAddress_1.
                 * @member {string} mailingAddress_1
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.mailingAddress_1 = "";

                /**
                 * CompleteAddressStepRequest mailingAddress_2.
                 * @member {string} mailingAddress_2
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.mailingAddress_2 = "";

                /**
                 * CompleteAddressStepRequest mailingCity.
                 * @member {string} mailingCity
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.mailingCity = "";

                /**
                 * CompleteAddressStepRequest mailingState.
                 * @member {string} mailingState
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.mailingState = "";

                /**
                 * CompleteAddressStepRequest mailingZip.
                 * @member {string} mailingZip
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 */
                CompleteAddressStepRequest.prototype.mailingZip = "";

                /**
                 * Creates a new CompleteAddressStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteAddressStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteAddressStepRequest} CompleteAddressStepRequest instance
                 */
                CompleteAddressStepRequest.create = function create(properties) {
                    return new CompleteAddressStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteAddressStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteAddressStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteAddressStepRequest} message CompleteAddressStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAddressStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.address_1 != null && message.hasOwnProperty("address_1"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.address_1);
                    if (message.address_2 != null && message.hasOwnProperty("address_2"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.address_2);
                    if (message.city != null && message.hasOwnProperty("city"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.city);
                    if (message.state != null && message.hasOwnProperty("state"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.state);
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.zip);
                    if (message.mailingAddress_1 != null && message.hasOwnProperty("mailingAddress_1"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.mailingAddress_1);
                    if (message.mailingAddress_2 != null && message.hasOwnProperty("mailingAddress_2"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.mailingAddress_2);
                    if (message.mailingCity != null && message.hasOwnProperty("mailingCity"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.mailingCity);
                    if (message.mailingState != null && message.hasOwnProperty("mailingState"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.mailingState);
                    if (message.mailingZip != null && message.hasOwnProperty("mailingZip"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.mailingZip);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteAddressStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteAddressStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteAddressStepRequest} message CompleteAddressStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAddressStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteAddressStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteAddressStepRequest} CompleteAddressStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAddressStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteAddressStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.address_1 = reader.string();
                            break;
                        case 4:
                            message.address_2 = reader.string();
                            break;
                        case 5:
                            message.city = reader.string();
                            break;
                        case 6:
                            message.state = reader.string();
                            break;
                        case 7:
                            message.zip = reader.string();
                            break;
                        case 8:
                            message.mailingAddress_1 = reader.string();
                            break;
                        case 9:
                            message.mailingAddress_2 = reader.string();
                            break;
                        case 10:
                            message.mailingCity = reader.string();
                            break;
                        case 11:
                            message.mailingState = reader.string();
                            break;
                        case 12:
                            message.mailingZip = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteAddressStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteAddressStepRequest} CompleteAddressStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAddressStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteAddressStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteAddressStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.address_1 != null && message.hasOwnProperty("address_1"))
                        if (!$util.isString(message.address_1))
                            return "address_1: string expected";
                    if (message.address_2 != null && message.hasOwnProperty("address_2"))
                        if (!$util.isString(message.address_2))
                            return "address_2: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        if (!$util.isString(message.zip))
                            return "zip: string expected";
                    if (message.mailingAddress_1 != null && message.hasOwnProperty("mailingAddress_1"))
                        if (!$util.isString(message.mailingAddress_1))
                            return "mailingAddress_1: string expected";
                    if (message.mailingAddress_2 != null && message.hasOwnProperty("mailingAddress_2"))
                        if (!$util.isString(message.mailingAddress_2))
                            return "mailingAddress_2: string expected";
                    if (message.mailingCity != null && message.hasOwnProperty("mailingCity"))
                        if (!$util.isString(message.mailingCity))
                            return "mailingCity: string expected";
                    if (message.mailingState != null && message.hasOwnProperty("mailingState"))
                        if (!$util.isString(message.mailingState))
                            return "mailingState: string expected";
                    if (message.mailingZip != null && message.hasOwnProperty("mailingZip"))
                        if (!$util.isString(message.mailingZip))
                            return "mailingZip: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteAddressStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteAddressStepRequest} CompleteAddressStepRequest
                 */
                CompleteAddressStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteAddressStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteAddressStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.address_1 != null)
                        message.address_1 = String(object.address_1);
                    if (object.address_2 != null)
                        message.address_2 = String(object.address_2);
                    if (object.city != null)
                        message.city = String(object.city);
                    if (object.state != null)
                        message.state = String(object.state);
                    if (object.zip != null)
                        message.zip = String(object.zip);
                    if (object.mailingAddress_1 != null)
                        message.mailingAddress_1 = String(object.mailingAddress_1);
                    if (object.mailingAddress_2 != null)
                        message.mailingAddress_2 = String(object.mailingAddress_2);
                    if (object.mailingCity != null)
                        message.mailingCity = String(object.mailingCity);
                    if (object.mailingState != null)
                        message.mailingState = String(object.mailingState);
                    if (object.mailingZip != null)
                        message.mailingZip = String(object.mailingZip);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteAddressStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteAddressStepRequest} message CompleteAddressStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteAddressStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.address_1 = "";
                        object.address_2 = "";
                        object.city = "";
                        object.state = "";
                        object.zip = "";
                        object.mailingAddress_1 = "";
                        object.mailingAddress_2 = "";
                        object.mailingCity = "";
                        object.mailingState = "";
                        object.mailingZip = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.address_1 != null && message.hasOwnProperty("address_1"))
                        object.address_1 = message.address_1;
                    if (message.address_2 != null && message.hasOwnProperty("address_2"))
                        object.address_2 = message.address_2;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    if (message.zip != null && message.hasOwnProperty("zip"))
                        object.zip = message.zip;
                    if (message.mailingAddress_1 != null && message.hasOwnProperty("mailingAddress_1"))
                        object.mailingAddress_1 = message.mailingAddress_1;
                    if (message.mailingAddress_2 != null && message.hasOwnProperty("mailingAddress_2"))
                        object.mailingAddress_2 = message.mailingAddress_2;
                    if (message.mailingCity != null && message.hasOwnProperty("mailingCity"))
                        object.mailingCity = message.mailingCity;
                    if (message.mailingState != null && message.hasOwnProperty("mailingState"))
                        object.mailingState = message.mailingState;
                    if (message.mailingZip != null && message.hasOwnProperty("mailingZip"))
                        object.mailingZip = message.mailingZip;
                    return object;
                };

                /**
                 * Converts this CompleteAddressStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteAddressStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteAddressStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteAddressStepRequest;
            })();

            clientrpc.CompleteAddressStepResponse = (function() {

                /**
                 * Properties of a CompleteAddressStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteAddressStepResponse
                 */

                /**
                 * Constructs a new CompleteAddressStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteAddressStepResponse.
                 * @implements ICompleteAddressStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteAddressStepResponse=} [properties] Properties to set
                 */
                function CompleteAddressStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteAddressStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteAddressStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteAddressStepResponse} CompleteAddressStepResponse instance
                 */
                CompleteAddressStepResponse.create = function create(properties) {
                    return new CompleteAddressStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteAddressStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteAddressStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteAddressStepResponse} message CompleteAddressStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAddressStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteAddressStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteAddressStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteAddressStepResponse} message CompleteAddressStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAddressStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteAddressStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteAddressStepResponse} CompleteAddressStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAddressStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteAddressStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteAddressStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteAddressStepResponse} CompleteAddressStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAddressStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteAddressStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteAddressStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteAddressStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteAddressStepResponse} CompleteAddressStepResponse
                 */
                CompleteAddressStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteAddressStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteAddressStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteAddressStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteAddressStepResponse} message CompleteAddressStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteAddressStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteAddressStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteAddressStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteAddressStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteAddressStepResponse;
            })();

            clientrpc.CompleteMultiDigitStepRequest = (function() {

                /**
                 * Properties of a CompleteMultiDigitStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteMultiDigitStepRequest
                 * @property {string|null} [stepToken] CompleteMultiDigitStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteMultiDigitStepRequest workflowToken
                 * @property {string|null} [rawValue] CompleteMultiDigitStepRequest rawValue
                 * @property {visor.common.encryption.IEncryptedItem|null} [encryptedValue] CompleteMultiDigitStepRequest encryptedValue
                 */

                /**
                 * Constructs a new CompleteMultiDigitStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteMultiDigitStepRequest.
                 * @implements ICompleteMultiDigitStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteMultiDigitStepRequest=} [properties] Properties to set
                 */
                function CompleteMultiDigitStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteMultiDigitStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @instance
                 */
                CompleteMultiDigitStepRequest.prototype.stepToken = "";

                /**
                 * CompleteMultiDigitStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @instance
                 */
                CompleteMultiDigitStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteMultiDigitStepRequest rawValue.
                 * @member {string} rawValue
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @instance
                 */
                CompleteMultiDigitStepRequest.prototype.rawValue = "";

                /**
                 * CompleteMultiDigitStepRequest encryptedValue.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} encryptedValue
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @instance
                 */
                CompleteMultiDigitStepRequest.prototype.encryptedValue = null;

                /**
                 * Creates a new CompleteMultiDigitStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteMultiDigitStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteMultiDigitStepRequest} CompleteMultiDigitStepRequest instance
                 */
                CompleteMultiDigitStepRequest.create = function create(properties) {
                    return new CompleteMultiDigitStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteMultiDigitStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteMultiDigitStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteMultiDigitStepRequest} message CompleteMultiDigitStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteMultiDigitStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.rawValue != null && message.hasOwnProperty("rawValue"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.rawValue);
                    if (message.encryptedValue != null && message.hasOwnProperty("encryptedValue"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.encryptedValue, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteMultiDigitStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteMultiDigitStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteMultiDigitStepRequest} message CompleteMultiDigitStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteMultiDigitStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteMultiDigitStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteMultiDigitStepRequest} CompleteMultiDigitStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteMultiDigitStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteMultiDigitStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.rawValue = reader.string();
                            break;
                        case 4:
                            message.encryptedValue = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteMultiDigitStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteMultiDigitStepRequest} CompleteMultiDigitStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteMultiDigitStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteMultiDigitStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteMultiDigitStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.rawValue != null && message.hasOwnProperty("rawValue"))
                        if (!$util.isString(message.rawValue))
                            return "rawValue: string expected";
                    if (message.encryptedValue != null && message.hasOwnProperty("encryptedValue")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.encryptedValue);
                        if (error)
                            return "encryptedValue." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteMultiDigitStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteMultiDigitStepRequest} CompleteMultiDigitStepRequest
                 */
                CompleteMultiDigitStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteMultiDigitStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteMultiDigitStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.rawValue != null)
                        message.rawValue = String(object.rawValue);
                    if (object.encryptedValue != null) {
                        if (typeof object.encryptedValue !== "object")
                            throw TypeError(".visor.clientrpc.CompleteMultiDigitStepRequest.encryptedValue: object expected");
                        message.encryptedValue = $root.visor.common.encryption.EncryptedItem.fromObject(object.encryptedValue);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteMultiDigitStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteMultiDigitStepRequest} message CompleteMultiDigitStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteMultiDigitStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.rawValue = "";
                        object.encryptedValue = null;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.rawValue != null && message.hasOwnProperty("rawValue"))
                        object.rawValue = message.rawValue;
                    if (message.encryptedValue != null && message.hasOwnProperty("encryptedValue"))
                        object.encryptedValue = $root.visor.common.encryption.EncryptedItem.toObject(message.encryptedValue, options);
                    return object;
                };

                /**
                 * Converts this CompleteMultiDigitStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteMultiDigitStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteMultiDigitStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteMultiDigitStepRequest;
            })();

            clientrpc.CompleteMultiDigitStepResponse = (function() {

                /**
                 * Properties of a CompleteMultiDigitStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteMultiDigitStepResponse
                 */

                /**
                 * Constructs a new CompleteMultiDigitStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteMultiDigitStepResponse.
                 * @implements ICompleteMultiDigitStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteMultiDigitStepResponse=} [properties] Properties to set
                 */
                function CompleteMultiDigitStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteMultiDigitStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteMultiDigitStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteMultiDigitStepResponse} CompleteMultiDigitStepResponse instance
                 */
                CompleteMultiDigitStepResponse.create = function create(properties) {
                    return new CompleteMultiDigitStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteMultiDigitStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteMultiDigitStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteMultiDigitStepResponse} message CompleteMultiDigitStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteMultiDigitStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteMultiDigitStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteMultiDigitStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteMultiDigitStepResponse} message CompleteMultiDigitStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteMultiDigitStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteMultiDigitStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteMultiDigitStepResponse} CompleteMultiDigitStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteMultiDigitStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteMultiDigitStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteMultiDigitStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteMultiDigitStepResponse} CompleteMultiDigitStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteMultiDigitStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteMultiDigitStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteMultiDigitStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteMultiDigitStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteMultiDigitStepResponse} CompleteMultiDigitStepResponse
                 */
                CompleteMultiDigitStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteMultiDigitStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteMultiDigitStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteMultiDigitStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteMultiDigitStepResponse} message CompleteMultiDigitStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteMultiDigitStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteMultiDigitStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteMultiDigitStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteMultiDigitStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteMultiDigitStepResponse;
            })();

            clientrpc.CompleteLegalNameAndDateOfBirthStepRequest = (function() {

                /**
                 * Properties of a CompleteLegalNameAndDateOfBirthStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteLegalNameAndDateOfBirthStepRequest
                 * @property {string|null} [stepToken] CompleteLegalNameAndDateOfBirthStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteLegalNameAndDateOfBirthStepRequest workflowToken
                 * @property {string|null} [firstName] CompleteLegalNameAndDateOfBirthStepRequest firstName
                 * @property {string|null} [lastName] CompleteLegalNameAndDateOfBirthStepRequest lastName
                 * @property {visor.common.time.ILocalDate|null} [dateOfBirth] CompleteLegalNameAndDateOfBirthStepRequest dateOfBirth
                 */

                /**
                 * Constructs a new CompleteLegalNameAndDateOfBirthStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteLegalNameAndDateOfBirthStepRequest.
                 * @implements ICompleteLegalNameAndDateOfBirthStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepRequest=} [properties] Properties to set
                 */
                function CompleteLegalNameAndDateOfBirthStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteLegalNameAndDateOfBirthStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @instance
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.prototype.stepToken = "";

                /**
                 * CompleteLegalNameAndDateOfBirthStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @instance
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteLegalNameAndDateOfBirthStepRequest firstName.
                 * @member {string} firstName
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @instance
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.prototype.firstName = "";

                /**
                 * CompleteLegalNameAndDateOfBirthStepRequest lastName.
                 * @member {string} lastName
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @instance
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.prototype.lastName = "";

                /**
                 * CompleteLegalNameAndDateOfBirthStepRequest dateOfBirth.
                 * @member {visor.common.time.ILocalDate|null|undefined} dateOfBirth
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @instance
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.prototype.dateOfBirth = null;

                /**
                 * Creates a new CompleteLegalNameAndDateOfBirthStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest} CompleteLegalNameAndDateOfBirthStepRequest instance
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.create = function create(properties) {
                    return new CompleteLegalNameAndDateOfBirthStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteLegalNameAndDateOfBirthStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepRequest} message CompleteLegalNameAndDateOfBirthStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                        $root.visor.common.time.LocalDate.encode(message.dateOfBirth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteLegalNameAndDateOfBirthStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepRequest} message CompleteLegalNameAndDateOfBirthStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteLegalNameAndDateOfBirthStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest} CompleteLegalNameAndDateOfBirthStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.firstName = reader.string();
                            break;
                        case 4:
                            message.lastName = reader.string();
                            break;
                        case 5:
                            message.dateOfBirth = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteLegalNameAndDateOfBirthStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest} CompleteLegalNameAndDateOfBirthStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteLegalNameAndDateOfBirthStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.dateOfBirth);
                        if (error)
                            return "dateOfBirth." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteLegalNameAndDateOfBirthStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest} CompleteLegalNameAndDateOfBirthStepRequest
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.dateOfBirth != null) {
                        if (typeof object.dateOfBirth !== "object")
                            throw TypeError(".visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest.dateOfBirth: object expected");
                        message.dateOfBirth = $root.visor.common.time.LocalDate.fromObject(object.dateOfBirth);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteLegalNameAndDateOfBirthStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest} message CompleteLegalNameAndDateOfBirthStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.dateOfBirth = null;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                        object.dateOfBirth = $root.visor.common.time.LocalDate.toObject(message.dateOfBirth, options);
                    return object;
                };

                /**
                 * Converts this CompleteLegalNameAndDateOfBirthStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteLegalNameAndDateOfBirthStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteLegalNameAndDateOfBirthStepRequest;
            })();

            clientrpc.CompleteLegalNameAndDateOfBirthStepResponse = (function() {

                /**
                 * Properties of a CompleteLegalNameAndDateOfBirthStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteLegalNameAndDateOfBirthStepResponse
                 */

                /**
                 * Constructs a new CompleteLegalNameAndDateOfBirthStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteLegalNameAndDateOfBirthStepResponse.
                 * @implements ICompleteLegalNameAndDateOfBirthStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepResponse=} [properties] Properties to set
                 */
                function CompleteLegalNameAndDateOfBirthStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteLegalNameAndDateOfBirthStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse} CompleteLegalNameAndDateOfBirthStepResponse instance
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.create = function create(properties) {
                    return new CompleteLegalNameAndDateOfBirthStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteLegalNameAndDateOfBirthStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepResponse} message CompleteLegalNameAndDateOfBirthStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteLegalNameAndDateOfBirthStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteLegalNameAndDateOfBirthStepResponse} message CompleteLegalNameAndDateOfBirthStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteLegalNameAndDateOfBirthStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse} CompleteLegalNameAndDateOfBirthStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteLegalNameAndDateOfBirthStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse} CompleteLegalNameAndDateOfBirthStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteLegalNameAndDateOfBirthStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteLegalNameAndDateOfBirthStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse} CompleteLegalNameAndDateOfBirthStepResponse
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteLegalNameAndDateOfBirthStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse} message CompleteLegalNameAndDateOfBirthStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteLegalNameAndDateOfBirthStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteLegalNameAndDateOfBirthStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteLegalNameAndDateOfBirthStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteLegalNameAndDateOfBirthStepResponse;
            })();

            clientrpc.CompleteTermsOfServiceStepRequest = (function() {

                /**
                 * Properties of a CompleteTermsOfServiceStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTermsOfServiceStepRequest
                 * @property {string|null} [stepToken] CompleteTermsOfServiceStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteTermsOfServiceStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteTermsOfServiceStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTermsOfServiceStepRequest.
                 * @implements ICompleteTermsOfServiceStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepRequest=} [properties] Properties to set
                 */
                function CompleteTermsOfServiceStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteTermsOfServiceStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @instance
                 */
                CompleteTermsOfServiceStepRequest.prototype.stepToken = "";

                /**
                 * CompleteTermsOfServiceStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @instance
                 */
                CompleteTermsOfServiceStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteTermsOfServiceStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepRequest} CompleteTermsOfServiceStepRequest instance
                 */
                CompleteTermsOfServiceStepRequest.create = function create(properties) {
                    return new CompleteTermsOfServiceStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteTermsOfServiceStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteTermsOfServiceStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepRequest} message CompleteTermsOfServiceStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTermsOfServiceStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTermsOfServiceStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTermsOfServiceStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepRequest} message CompleteTermsOfServiceStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTermsOfServiceStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTermsOfServiceStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepRequest} CompleteTermsOfServiceStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTermsOfServiceStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTermsOfServiceStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTermsOfServiceStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepRequest} CompleteTermsOfServiceStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTermsOfServiceStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTermsOfServiceStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTermsOfServiceStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteTermsOfServiceStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepRequest} CompleteTermsOfServiceStepRequest
                 */
                CompleteTermsOfServiceStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTermsOfServiceStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteTermsOfServiceStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteTermsOfServiceStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteTermsOfServiceStepRequest} message CompleteTermsOfServiceStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTermsOfServiceStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteTermsOfServiceStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTermsOfServiceStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTermsOfServiceStepRequest;
            })();

            clientrpc.CompleteTermsOfServiceStepResponse = (function() {

                /**
                 * Properties of a CompleteTermsOfServiceStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteTermsOfServiceStepResponse
                 */

                /**
                 * Constructs a new CompleteTermsOfServiceStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteTermsOfServiceStepResponse.
                 * @implements ICompleteTermsOfServiceStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepResponse=} [properties] Properties to set
                 */
                function CompleteTermsOfServiceStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteTermsOfServiceStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepResponse} CompleteTermsOfServiceStepResponse instance
                 */
                CompleteTermsOfServiceStepResponse.create = function create(properties) {
                    return new CompleteTermsOfServiceStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteTermsOfServiceStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteTermsOfServiceStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepResponse} message CompleteTermsOfServiceStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTermsOfServiceStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteTermsOfServiceStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteTermsOfServiceStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteTermsOfServiceStepResponse} message CompleteTermsOfServiceStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteTermsOfServiceStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteTermsOfServiceStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepResponse} CompleteTermsOfServiceStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTermsOfServiceStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteTermsOfServiceStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteTermsOfServiceStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepResponse} CompleteTermsOfServiceStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteTermsOfServiceStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteTermsOfServiceStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteTermsOfServiceStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteTermsOfServiceStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteTermsOfServiceStepResponse} CompleteTermsOfServiceStepResponse
                 */
                CompleteTermsOfServiceStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteTermsOfServiceStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteTermsOfServiceStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteTermsOfServiceStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteTermsOfServiceStepResponse} message CompleteTermsOfServiceStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteTermsOfServiceStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteTermsOfServiceStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteTermsOfServiceStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteTermsOfServiceStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteTermsOfServiceStepResponse;
            })();

            clientrpc.CompleteChoiceStepRequest = (function() {

                /**
                 * Properties of a CompleteChoiceStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteChoiceStepRequest
                 * @property {string|null} [stepToken] CompleteChoiceStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteChoiceStepRequest workflowToken
                 * @property {Array.<visor.clientrpc.CompleteChoiceStepRequest.IChoice>|null} [choices] CompleteChoiceStepRequest choices
                 */

                /**
                 * Constructs a new CompleteChoiceStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteChoiceStepRequest.
                 * @implements ICompleteChoiceStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteChoiceStepRequest=} [properties] Properties to set
                 */
                function CompleteChoiceStepRequest(properties) {
                    this.choices = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteChoiceStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @instance
                 */
                CompleteChoiceStepRequest.prototype.stepToken = "";

                /**
                 * CompleteChoiceStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @instance
                 */
                CompleteChoiceStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteChoiceStepRequest choices.
                 * @member {Array.<visor.clientrpc.CompleteChoiceStepRequest.IChoice>} choices
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @instance
                 */
                CompleteChoiceStepRequest.prototype.choices = $util.emptyArray;

                /**
                 * Creates a new CompleteChoiceStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteChoiceStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteChoiceStepRequest} CompleteChoiceStepRequest instance
                 */
                CompleteChoiceStepRequest.create = function create(properties) {
                    return new CompleteChoiceStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteChoiceStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteChoiceStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteChoiceStepRequest} message CompleteChoiceStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteChoiceStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.choices != null && message.choices.length)
                        for (var i = 0; i < message.choices.length; ++i)
                            $root.visor.clientrpc.CompleteChoiceStepRequest.Choice.encode(message.choices[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteChoiceStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteChoiceStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteChoiceStepRequest} message CompleteChoiceStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteChoiceStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteChoiceStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteChoiceStepRequest} CompleteChoiceStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteChoiceStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteChoiceStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            if (!(message.choices && message.choices.length))
                                message.choices = [];
                            message.choices.push($root.visor.clientrpc.CompleteChoiceStepRequest.Choice.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteChoiceStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteChoiceStepRequest} CompleteChoiceStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteChoiceStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteChoiceStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteChoiceStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.choices != null && message.hasOwnProperty("choices")) {
                        if (!Array.isArray(message.choices))
                            return "choices: array expected";
                        for (var i = 0; i < message.choices.length; ++i) {
                            var error = $root.visor.clientrpc.CompleteChoiceStepRequest.Choice.verify(message.choices[i]);
                            if (error)
                                return "choices." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CompleteChoiceStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteChoiceStepRequest} CompleteChoiceStepRequest
                 */
                CompleteChoiceStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteChoiceStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteChoiceStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.choices) {
                        if (!Array.isArray(object.choices))
                            throw TypeError(".visor.clientrpc.CompleteChoiceStepRequest.choices: array expected");
                        message.choices = [];
                        for (var i = 0; i < object.choices.length; ++i) {
                            if (typeof object.choices[i] !== "object")
                                throw TypeError(".visor.clientrpc.CompleteChoiceStepRequest.choices: object expected");
                            message.choices[i] = $root.visor.clientrpc.CompleteChoiceStepRequest.Choice.fromObject(object.choices[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteChoiceStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteChoiceStepRequest} message CompleteChoiceStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteChoiceStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.choices = [];
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.choices && message.choices.length) {
                        object.choices = [];
                        for (var j = 0; j < message.choices.length; ++j)
                            object.choices[j] = $root.visor.clientrpc.CompleteChoiceStepRequest.Choice.toObject(message.choices[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CompleteChoiceStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteChoiceStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteChoiceStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CompleteChoiceStepRequest.Choice = (function() {

                    /**
                     * Properties of a Choice.
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest
                     * @interface IChoice
                     * @property {string|null} [choiceToken] Choice choiceToken
                     * @property {boolean|null} [isSelected] Choice isSelected
                     * @property {string|null} [notes] Choice notes
                     */

                    /**
                     * Constructs a new Choice.
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest
                     * @classdesc Represents a Choice.
                     * @implements IChoice
                     * @constructor
                     * @param {visor.clientrpc.CompleteChoiceStepRequest.IChoice=} [properties] Properties to set
                     */
                    function Choice(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Choice choiceToken.
                     * @member {string} choiceToken
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @instance
                     */
                    Choice.prototype.choiceToken = "";

                    /**
                     * Choice isSelected.
                     * @member {boolean} isSelected
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @instance
                     */
                    Choice.prototype.isSelected = false;

                    /**
                     * Choice notes.
                     * @member {string} notes
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @instance
                     */
                    Choice.prototype.notes = "";

                    /**
                     * Creates a new Choice instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {visor.clientrpc.CompleteChoiceStepRequest.IChoice=} [properties] Properties to set
                     * @returns {visor.clientrpc.CompleteChoiceStepRequest.Choice} Choice instance
                     */
                    Choice.create = function create(properties) {
                        return new Choice(properties);
                    };

                    /**
                     * Encodes the specified Choice message. Does not implicitly {@link visor.clientrpc.CompleteChoiceStepRequest.Choice.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {visor.clientrpc.CompleteChoiceStepRequest.IChoice} message Choice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Choice.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.choiceToken != null && message.hasOwnProperty("choiceToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.choiceToken);
                        if (message.isSelected != null && message.hasOwnProperty("isSelected"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isSelected);
                        if (message.notes != null && message.hasOwnProperty("notes"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.notes);
                        return writer;
                    };

                    /**
                     * Encodes the specified Choice message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteChoiceStepRequest.Choice.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {visor.clientrpc.CompleteChoiceStepRequest.IChoice} message Choice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Choice.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Choice message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CompleteChoiceStepRequest.Choice} Choice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Choice.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteChoiceStepRequest.Choice();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.choiceToken = reader.string();
                                break;
                            case 2:
                                message.isSelected = reader.bool();
                                break;
                            case 3:
                                message.notes = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Choice message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CompleteChoiceStepRequest.Choice} Choice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Choice.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Choice message.
                     * @function verify
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Choice.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.choiceToken != null && message.hasOwnProperty("choiceToken"))
                            if (!$util.isString(message.choiceToken))
                                return "choiceToken: string expected";
                        if (message.isSelected != null && message.hasOwnProperty("isSelected"))
                            if (typeof message.isSelected !== "boolean")
                                return "isSelected: boolean expected";
                        if (message.notes != null && message.hasOwnProperty("notes"))
                            if (!$util.isString(message.notes))
                                return "notes: string expected";
                        return null;
                    };

                    /**
                     * Creates a Choice message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CompleteChoiceStepRequest.Choice} Choice
                     */
                    Choice.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CompleteChoiceStepRequest.Choice)
                            return object;
                        var message = new $root.visor.clientrpc.CompleteChoiceStepRequest.Choice();
                        if (object.choiceToken != null)
                            message.choiceToken = String(object.choiceToken);
                        if (object.isSelected != null)
                            message.isSelected = Boolean(object.isSelected);
                        if (object.notes != null)
                            message.notes = String(object.notes);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Choice message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @static
                     * @param {visor.clientrpc.CompleteChoiceStepRequest.Choice} message Choice
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Choice.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.choiceToken = "";
                            object.isSelected = false;
                            object.notes = "";
                        }
                        if (message.choiceToken != null && message.hasOwnProperty("choiceToken"))
                            object.choiceToken = message.choiceToken;
                        if (message.isSelected != null && message.hasOwnProperty("isSelected"))
                            object.isSelected = message.isSelected;
                        if (message.notes != null && message.hasOwnProperty("notes"))
                            object.notes = message.notes;
                        return object;
                    };

                    /**
                     * Converts this Choice to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CompleteChoiceStepRequest.Choice
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Choice.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Choice;
                })();

                return CompleteChoiceStepRequest;
            })();

            clientrpc.CompleteChoiceStepResponse = (function() {

                /**
                 * Properties of a CompleteChoiceStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteChoiceStepResponse
                 */

                /**
                 * Constructs a new CompleteChoiceStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteChoiceStepResponse.
                 * @implements ICompleteChoiceStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteChoiceStepResponse=} [properties] Properties to set
                 */
                function CompleteChoiceStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteChoiceStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteChoiceStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteChoiceStepResponse} CompleteChoiceStepResponse instance
                 */
                CompleteChoiceStepResponse.create = function create(properties) {
                    return new CompleteChoiceStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteChoiceStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteChoiceStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteChoiceStepResponse} message CompleteChoiceStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteChoiceStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteChoiceStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteChoiceStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteChoiceStepResponse} message CompleteChoiceStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteChoiceStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteChoiceStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteChoiceStepResponse} CompleteChoiceStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteChoiceStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteChoiceStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteChoiceStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteChoiceStepResponse} CompleteChoiceStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteChoiceStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteChoiceStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteChoiceStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteChoiceStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteChoiceStepResponse} CompleteChoiceStepResponse
                 */
                CompleteChoiceStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteChoiceStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteChoiceStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteChoiceStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteChoiceStepResponse} message CompleteChoiceStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteChoiceStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteChoiceStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteChoiceStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteChoiceStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteChoiceStepResponse;
            })();

            clientrpc.CompleteYesNoStepRequest = (function() {

                /**
                 * Properties of a CompleteYesNoStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteYesNoStepRequest
                 * @property {string|null} [stepToken] CompleteYesNoStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteYesNoStepRequest workflowToken
                 * @property {boolean|null} [answer] CompleteYesNoStepRequest answer
                 */

                /**
                 * Constructs a new CompleteYesNoStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteYesNoStepRequest.
                 * @implements ICompleteYesNoStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteYesNoStepRequest=} [properties] Properties to set
                 */
                function CompleteYesNoStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteYesNoStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @instance
                 */
                CompleteYesNoStepRequest.prototype.stepToken = "";

                /**
                 * CompleteYesNoStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @instance
                 */
                CompleteYesNoStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteYesNoStepRequest answer.
                 * @member {boolean} answer
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @instance
                 */
                CompleteYesNoStepRequest.prototype.answer = false;

                /**
                 * Creates a new CompleteYesNoStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteYesNoStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteYesNoStepRequest} CompleteYesNoStepRequest instance
                 */
                CompleteYesNoStepRequest.create = function create(properties) {
                    return new CompleteYesNoStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteYesNoStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteYesNoStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteYesNoStepRequest} message CompleteYesNoStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteYesNoStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.answer != null && message.hasOwnProperty("answer"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.answer);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteYesNoStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteYesNoStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteYesNoStepRequest} message CompleteYesNoStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteYesNoStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteYesNoStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteYesNoStepRequest} CompleteYesNoStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteYesNoStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteYesNoStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.answer = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteYesNoStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteYesNoStepRequest} CompleteYesNoStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteYesNoStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteYesNoStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteYesNoStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.answer != null && message.hasOwnProperty("answer"))
                        if (typeof message.answer !== "boolean")
                            return "answer: boolean expected";
                    return null;
                };

                /**
                 * Creates a CompleteYesNoStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteYesNoStepRequest} CompleteYesNoStepRequest
                 */
                CompleteYesNoStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteYesNoStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteYesNoStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.answer != null)
                        message.answer = Boolean(object.answer);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteYesNoStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteYesNoStepRequest} message CompleteYesNoStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteYesNoStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.answer = false;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.answer != null && message.hasOwnProperty("answer"))
                        object.answer = message.answer;
                    return object;
                };

                /**
                 * Converts this CompleteYesNoStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteYesNoStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteYesNoStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteYesNoStepRequest;
            })();

            clientrpc.CompleteYesNoStepResponse = (function() {

                /**
                 * Properties of a CompleteYesNoStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteYesNoStepResponse
                 */

                /**
                 * Constructs a new CompleteYesNoStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteYesNoStepResponse.
                 * @implements ICompleteYesNoStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteYesNoStepResponse=} [properties] Properties to set
                 */
                function CompleteYesNoStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteYesNoStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteYesNoStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteYesNoStepResponse} CompleteYesNoStepResponse instance
                 */
                CompleteYesNoStepResponse.create = function create(properties) {
                    return new CompleteYesNoStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteYesNoStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteYesNoStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteYesNoStepResponse} message CompleteYesNoStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteYesNoStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteYesNoStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteYesNoStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteYesNoStepResponse} message CompleteYesNoStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteYesNoStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteYesNoStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteYesNoStepResponse} CompleteYesNoStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteYesNoStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteYesNoStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteYesNoStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteYesNoStepResponse} CompleteYesNoStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteYesNoStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteYesNoStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteYesNoStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteYesNoStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteYesNoStepResponse} CompleteYesNoStepResponse
                 */
                CompleteYesNoStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteYesNoStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteYesNoStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteYesNoStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteYesNoStepResponse} message CompleteYesNoStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteYesNoStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteYesNoStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteYesNoStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteYesNoStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteYesNoStepResponse;
            })();

            clientrpc.CompleteAmountStepRequest = (function() {

                /**
                 * Properties of a CompleteAmountStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteAmountStepRequest
                 * @property {string|null} [stepToken] CompleteAmountStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteAmountStepRequest workflowToken
                 * @property {visor.common.numeric.INullableUint64|null} [amount] CompleteAmountStepRequest amount
                 */

                /**
                 * Constructs a new CompleteAmountStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteAmountStepRequest.
                 * @implements ICompleteAmountStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteAmountStepRequest=} [properties] Properties to set
                 */
                function CompleteAmountStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteAmountStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @instance
                 */
                CompleteAmountStepRequest.prototype.stepToken = "";

                /**
                 * CompleteAmountStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @instance
                 */
                CompleteAmountStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteAmountStepRequest amount.
                 * @member {visor.common.numeric.INullableUint64|null|undefined} amount
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @instance
                 */
                CompleteAmountStepRequest.prototype.amount = null;

                /**
                 * Creates a new CompleteAmountStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteAmountStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteAmountStepRequest} CompleteAmountStepRequest instance
                 */
                CompleteAmountStepRequest.create = function create(properties) {
                    return new CompleteAmountStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteAmountStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteAmountStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteAmountStepRequest} message CompleteAmountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAmountStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        $root.visor.common.numeric.NullableUint64.encode(message.amount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteAmountStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteAmountStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteAmountStepRequest} message CompleteAmountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAmountStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteAmountStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteAmountStepRequest} CompleteAmountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAmountStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteAmountStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.amount = $root.visor.common.numeric.NullableUint64.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteAmountStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteAmountStepRequest} CompleteAmountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAmountStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteAmountStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteAmountStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.visor.common.numeric.NullableUint64.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteAmountStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteAmountStepRequest} CompleteAmountStepRequest
                 */
                CompleteAmountStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteAmountStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteAmountStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".visor.clientrpc.CompleteAmountStepRequest.amount: object expected");
                        message.amount = $root.visor.common.numeric.NullableUint64.fromObject(object.amount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteAmountStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteAmountStepRequest} message CompleteAmountStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteAmountStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.amount = null;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.visor.common.numeric.NullableUint64.toObject(message.amount, options);
                    return object;
                };

                /**
                 * Converts this CompleteAmountStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteAmountStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteAmountStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteAmountStepRequest;
            })();

            clientrpc.CompleteAmountStepResponse = (function() {

                /**
                 * Properties of a CompleteAmountStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteAmountStepResponse
                 */

                /**
                 * Constructs a new CompleteAmountStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteAmountStepResponse.
                 * @implements ICompleteAmountStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteAmountStepResponse=} [properties] Properties to set
                 */
                function CompleteAmountStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteAmountStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteAmountStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteAmountStepResponse} CompleteAmountStepResponse instance
                 */
                CompleteAmountStepResponse.create = function create(properties) {
                    return new CompleteAmountStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteAmountStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteAmountStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteAmountStepResponse} message CompleteAmountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAmountStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteAmountStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteAmountStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteAmountStepResponse} message CompleteAmountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteAmountStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteAmountStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteAmountStepResponse} CompleteAmountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAmountStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteAmountStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteAmountStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteAmountStepResponse} CompleteAmountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteAmountStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteAmountStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteAmountStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteAmountStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteAmountStepResponse} CompleteAmountStepResponse
                 */
                CompleteAmountStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteAmountStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteAmountStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteAmountStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteAmountStepResponse} message CompleteAmountStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteAmountStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteAmountStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteAmountStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteAmountStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteAmountStepResponse;
            })();

            clientrpc.CompleteCurrencyStepRequest = (function() {

                /**
                 * Properties of a CompleteCurrencyStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCurrencyStepRequest
                 * @property {string|null} [stepToken] CompleteCurrencyStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteCurrencyStepRequest workflowToken
                 * @property {visor.common.money.IAmount|null} [currency] CompleteCurrencyStepRequest currency
                 */

                /**
                 * Constructs a new CompleteCurrencyStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCurrencyStepRequest.
                 * @implements ICompleteCurrencyStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCurrencyStepRequest=} [properties] Properties to set
                 */
                function CompleteCurrencyStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteCurrencyStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @instance
                 */
                CompleteCurrencyStepRequest.prototype.stepToken = "";

                /**
                 * CompleteCurrencyStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @instance
                 */
                CompleteCurrencyStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteCurrencyStepRequest currency.
                 * @member {visor.common.money.IAmount|null|undefined} currency
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @instance
                 */
                CompleteCurrencyStepRequest.prototype.currency = null;

                /**
                 * Creates a new CompleteCurrencyStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCurrencyStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCurrencyStepRequest} CompleteCurrencyStepRequest instance
                 */
                CompleteCurrencyStepRequest.create = function create(properties) {
                    return new CompleteCurrencyStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteCurrencyStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteCurrencyStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCurrencyStepRequest} message CompleteCurrencyStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCurrencyStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        $root.visor.common.money.Amount.encode(message.currency, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCurrencyStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCurrencyStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCurrencyStepRequest} message CompleteCurrencyStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCurrencyStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCurrencyStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCurrencyStepRequest} CompleteCurrencyStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCurrencyStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCurrencyStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.currency = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCurrencyStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCurrencyStepRequest} CompleteCurrencyStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCurrencyStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCurrencyStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCurrencyStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.currency != null && message.hasOwnProperty("currency")) {
                        var error = $root.visor.common.money.Amount.verify(message.currency);
                        if (error)
                            return "currency." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteCurrencyStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCurrencyStepRequest} CompleteCurrencyStepRequest
                 */
                CompleteCurrencyStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCurrencyStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteCurrencyStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.currency != null) {
                        if (typeof object.currency !== "object")
                            throw TypeError(".visor.clientrpc.CompleteCurrencyStepRequest.currency: object expected");
                        message.currency = $root.visor.common.money.Amount.fromObject(object.currency);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteCurrencyStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteCurrencyStepRequest} message CompleteCurrencyStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCurrencyStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.currency = null;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        object.currency = $root.visor.common.money.Amount.toObject(message.currency, options);
                    return object;
                };

                /**
                 * Converts this CompleteCurrencyStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCurrencyStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCurrencyStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCurrencyStepRequest;
            })();

            clientrpc.CompleteCurrencyStepResponse = (function() {

                /**
                 * Properties of a CompleteCurrencyStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCurrencyStepResponse
                 */

                /**
                 * Constructs a new CompleteCurrencyStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCurrencyStepResponse.
                 * @implements ICompleteCurrencyStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCurrencyStepResponse=} [properties] Properties to set
                 */
                function CompleteCurrencyStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteCurrencyStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCurrencyStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCurrencyStepResponse} CompleteCurrencyStepResponse instance
                 */
                CompleteCurrencyStepResponse.create = function create(properties) {
                    return new CompleteCurrencyStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteCurrencyStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteCurrencyStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCurrencyStepResponse} message CompleteCurrencyStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCurrencyStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCurrencyStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCurrencyStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCurrencyStepResponse} message CompleteCurrencyStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCurrencyStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCurrencyStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCurrencyStepResponse} CompleteCurrencyStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCurrencyStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCurrencyStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCurrencyStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCurrencyStepResponse} CompleteCurrencyStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCurrencyStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCurrencyStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCurrencyStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteCurrencyStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCurrencyStepResponse} CompleteCurrencyStepResponse
                 */
                CompleteCurrencyStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCurrencyStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteCurrencyStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteCurrencyStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteCurrencyStepResponse} message CompleteCurrencyStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCurrencyStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteCurrencyStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCurrencyStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCurrencyStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCurrencyStepResponse;
            })();

            clientrpc.CompleteFinancialCatchupStepRequest = (function() {

                /**
                 * Properties of a CompleteFinancialCatchupStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteFinancialCatchupStepRequest
                 * @property {string|null} [stepToken] CompleteFinancialCatchupStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteFinancialCatchupStepRequest workflowToken
                 * @property {Array.<visor.clientrpc.CompleteFinancialCatchupStepRequest.IField>|null} [fields] CompleteFinancialCatchupStepRequest fields
                 */

                /**
                 * Constructs a new CompleteFinancialCatchupStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteFinancialCatchupStepRequest.
                 * @implements ICompleteFinancialCatchupStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepRequest=} [properties] Properties to set
                 */
                function CompleteFinancialCatchupStepRequest(properties) {
                    this.fields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteFinancialCatchupStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @instance
                 */
                CompleteFinancialCatchupStepRequest.prototype.stepToken = "";

                /**
                 * CompleteFinancialCatchupStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @instance
                 */
                CompleteFinancialCatchupStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteFinancialCatchupStepRequest fields.
                 * @member {Array.<visor.clientrpc.CompleteFinancialCatchupStepRequest.IField>} fields
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @instance
                 */
                CompleteFinancialCatchupStepRequest.prototype.fields = $util.emptyArray;

                /**
                 * Creates a new CompleteFinancialCatchupStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest} CompleteFinancialCatchupStepRequest instance
                 */
                CompleteFinancialCatchupStepRequest.create = function create(properties) {
                    return new CompleteFinancialCatchupStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteFinancialCatchupStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteFinancialCatchupStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepRequest} message CompleteFinancialCatchupStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteFinancialCatchupStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.fields != null && message.fields.length)
                        for (var i = 0; i < message.fields.length; ++i)
                            $root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.encode(message.fields[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteFinancialCatchupStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteFinancialCatchupStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepRequest} message CompleteFinancialCatchupStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteFinancialCatchupStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteFinancialCatchupStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest} CompleteFinancialCatchupStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteFinancialCatchupStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteFinancialCatchupStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            if (!(message.fields && message.fields.length))
                                message.fields = [];
                            message.fields.push($root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteFinancialCatchupStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest} CompleteFinancialCatchupStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteFinancialCatchupStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteFinancialCatchupStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteFinancialCatchupStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.fields != null && message.hasOwnProperty("fields")) {
                        if (!Array.isArray(message.fields))
                            return "fields: array expected";
                        for (var i = 0; i < message.fields.length; ++i) {
                            var error = $root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.verify(message.fields[i]);
                            if (error)
                                return "fields." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CompleteFinancialCatchupStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest} CompleteFinancialCatchupStepRequest
                 */
                CompleteFinancialCatchupStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteFinancialCatchupStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteFinancialCatchupStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.fields) {
                        if (!Array.isArray(object.fields))
                            throw TypeError(".visor.clientrpc.CompleteFinancialCatchupStepRequest.fields: array expected");
                        message.fields = [];
                        for (var i = 0; i < object.fields.length; ++i) {
                            if (typeof object.fields[i] !== "object")
                                throw TypeError(".visor.clientrpc.CompleteFinancialCatchupStepRequest.fields: object expected");
                            message.fields[i] = $root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.fromObject(object.fields[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteFinancialCatchupStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteFinancialCatchupStepRequest} message CompleteFinancialCatchupStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteFinancialCatchupStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fields = [];
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.fields && message.fields.length) {
                        object.fields = [];
                        for (var j = 0; j < message.fields.length; ++j)
                            object.fields[j] = $root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.toObject(message.fields[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CompleteFinancialCatchupStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteFinancialCatchupStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CompleteFinancialCatchupStepRequest.Field = (function() {

                    /**
                     * Properties of a Field.
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                     * @interface IField
                     * @property {string|null} [fieldToken] Field fieldToken
                     * @property {visor.common.money.IAmount|null} [currency] Field currency
                     */

                    /**
                     * Constructs a new Field.
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest
                     * @classdesc Represents a Field.
                     * @implements IField
                     * @constructor
                     * @param {visor.clientrpc.CompleteFinancialCatchupStepRequest.IField=} [properties] Properties to set
                     */
                    function Field(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Field fieldToken.
                     * @member {string} fieldToken
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @instance
                     */
                    Field.prototype.fieldToken = "";

                    /**
                     * Field currency.
                     * @member {visor.common.money.IAmount|null|undefined} currency
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @instance
                     */
                    Field.prototype.currency = null;

                    /**
                     * Creates a new Field instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteFinancialCatchupStepRequest.IField=} [properties] Properties to set
                     * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest.Field} Field instance
                     */
                    Field.create = function create(properties) {
                        return new Field(properties);
                    };

                    /**
                     * Encodes the specified Field message. Does not implicitly {@link visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteFinancialCatchupStepRequest.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.fieldToken != null && message.hasOwnProperty("fieldToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fieldToken);
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            $root.visor.common.money.Amount.encode(message.currency, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Field message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteFinancialCatchupStepRequest.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Field message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.fieldToken = reader.string();
                                break;
                            case 2:
                                message.currency = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Field message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Field message.
                     * @function verify
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Field.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.fieldToken != null && message.hasOwnProperty("fieldToken"))
                            if (!$util.isString(message.fieldToken))
                                return "fieldToken: string expected";
                        if (message.currency != null && message.hasOwnProperty("currency")) {
                            var error = $root.visor.common.money.Amount.verify(message.currency);
                            if (error)
                                return "currency." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Field message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CompleteFinancialCatchupStepRequest.Field} Field
                     */
                    Field.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field)
                            return object;
                        var message = new $root.visor.clientrpc.CompleteFinancialCatchupStepRequest.Field();
                        if (object.fieldToken != null)
                            message.fieldToken = String(object.fieldToken);
                        if (object.currency != null) {
                            if (typeof object.currency !== "object")
                                throw TypeError(".visor.clientrpc.CompleteFinancialCatchupStepRequest.Field.currency: object expected");
                            message.currency = $root.visor.common.money.Amount.fromObject(object.currency);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Field message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteFinancialCatchupStepRequest.Field} message Field
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Field.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.fieldToken = "";
                            object.currency = null;
                        }
                        if (message.fieldToken != null && message.hasOwnProperty("fieldToken"))
                            object.fieldToken = message.fieldToken;
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            object.currency = $root.visor.common.money.Amount.toObject(message.currency, options);
                        return object;
                    };

                    /**
                     * Converts this Field to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CompleteFinancialCatchupStepRequest.Field
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Field.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Field;
                })();

                return CompleteFinancialCatchupStepRequest;
            })();

            clientrpc.CompleteFinancialCatchupStepResponse = (function() {

                /**
                 * Properties of a CompleteFinancialCatchupStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteFinancialCatchupStepResponse
                 */

                /**
                 * Constructs a new CompleteFinancialCatchupStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteFinancialCatchupStepResponse.
                 * @implements ICompleteFinancialCatchupStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepResponse=} [properties] Properties to set
                 */
                function CompleteFinancialCatchupStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteFinancialCatchupStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepResponse} CompleteFinancialCatchupStepResponse instance
                 */
                CompleteFinancialCatchupStepResponse.create = function create(properties) {
                    return new CompleteFinancialCatchupStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteFinancialCatchupStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteFinancialCatchupStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepResponse} message CompleteFinancialCatchupStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteFinancialCatchupStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteFinancialCatchupStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteFinancialCatchupStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteFinancialCatchupStepResponse} message CompleteFinancialCatchupStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteFinancialCatchupStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteFinancialCatchupStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepResponse} CompleteFinancialCatchupStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteFinancialCatchupStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteFinancialCatchupStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteFinancialCatchupStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepResponse} CompleteFinancialCatchupStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteFinancialCatchupStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteFinancialCatchupStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteFinancialCatchupStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteFinancialCatchupStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteFinancialCatchupStepResponse} CompleteFinancialCatchupStepResponse
                 */
                CompleteFinancialCatchupStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteFinancialCatchupStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteFinancialCatchupStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteFinancialCatchupStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteFinancialCatchupStepResponse} message CompleteFinancialCatchupStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteFinancialCatchupStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteFinancialCatchupStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteFinancialCatchupStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteFinancialCatchupStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteFinancialCatchupStepResponse;
            })();

            clientrpc.CompleteDataEntryStepRequest = (function() {

                /**
                 * Properties of a CompleteDataEntryStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDataEntryStepRequest
                 * @property {string|null} [stepToken] CompleteDataEntryStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteDataEntryStepRequest workflowToken
                 * @property {Array.<visor.clientrpc.CompleteDataEntryStepRequest.IField>|null} [fields] CompleteDataEntryStepRequest fields
                 * @property {boolean|null} [ignoreOptionalChecks] CompleteDataEntryStepRequest ignoreOptionalChecks
                 */

                /**
                 * Constructs a new CompleteDataEntryStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDataEntryStepRequest.
                 * @implements ICompleteDataEntryStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDataEntryStepRequest=} [properties] Properties to set
                 */
                function CompleteDataEntryStepRequest(properties) {
                    this.fields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteDataEntryStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @instance
                 */
                CompleteDataEntryStepRequest.prototype.stepToken = "";

                /**
                 * CompleteDataEntryStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @instance
                 */
                CompleteDataEntryStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteDataEntryStepRequest fields.
                 * @member {Array.<visor.clientrpc.CompleteDataEntryStepRequest.IField>} fields
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @instance
                 */
                CompleteDataEntryStepRequest.prototype.fields = $util.emptyArray;

                /**
                 * CompleteDataEntryStepRequest ignoreOptionalChecks.
                 * @member {boolean} ignoreOptionalChecks
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @instance
                 */
                CompleteDataEntryStepRequest.prototype.ignoreOptionalChecks = false;

                /**
                 * Creates a new CompleteDataEntryStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDataEntryStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDataEntryStepRequest} CompleteDataEntryStepRequest instance
                 */
                CompleteDataEntryStepRequest.create = function create(properties) {
                    return new CompleteDataEntryStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteDataEntryStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteDataEntryStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDataEntryStepRequest} message CompleteDataEntryStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDataEntryStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.fields != null && message.fields.length)
                        for (var i = 0; i < message.fields.length; ++i)
                            $root.visor.clientrpc.CompleteDataEntryStepRequest.Field.encode(message.fields[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.ignoreOptionalChecks != null && message.hasOwnProperty("ignoreOptionalChecks"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.ignoreOptionalChecks);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDataEntryStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDataEntryStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDataEntryStepRequest} message CompleteDataEntryStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDataEntryStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDataEntryStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDataEntryStepRequest} CompleteDataEntryStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDataEntryStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDataEntryStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            if (!(message.fields && message.fields.length))
                                message.fields = [];
                            message.fields.push($root.visor.clientrpc.CompleteDataEntryStepRequest.Field.decode(reader, reader.uint32()));
                            break;
                        case 4:
                            message.ignoreOptionalChecks = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDataEntryStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDataEntryStepRequest} CompleteDataEntryStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDataEntryStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDataEntryStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDataEntryStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.fields != null && message.hasOwnProperty("fields")) {
                        if (!Array.isArray(message.fields))
                            return "fields: array expected";
                        for (var i = 0; i < message.fields.length; ++i) {
                            var error = $root.visor.clientrpc.CompleteDataEntryStepRequest.Field.verify(message.fields[i]);
                            if (error)
                                return "fields." + error;
                        }
                    }
                    if (message.ignoreOptionalChecks != null && message.hasOwnProperty("ignoreOptionalChecks"))
                        if (typeof message.ignoreOptionalChecks !== "boolean")
                            return "ignoreOptionalChecks: boolean expected";
                    return null;
                };

                /**
                 * Creates a CompleteDataEntryStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDataEntryStepRequest} CompleteDataEntryStepRequest
                 */
                CompleteDataEntryStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDataEntryStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteDataEntryStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.fields) {
                        if (!Array.isArray(object.fields))
                            throw TypeError(".visor.clientrpc.CompleteDataEntryStepRequest.fields: array expected");
                        message.fields = [];
                        for (var i = 0; i < object.fields.length; ++i) {
                            if (typeof object.fields[i] !== "object")
                                throw TypeError(".visor.clientrpc.CompleteDataEntryStepRequest.fields: object expected");
                            message.fields[i] = $root.visor.clientrpc.CompleteDataEntryStepRequest.Field.fromObject(object.fields[i]);
                        }
                    }
                    if (object.ignoreOptionalChecks != null)
                        message.ignoreOptionalChecks = Boolean(object.ignoreOptionalChecks);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteDataEntryStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteDataEntryStepRequest} message CompleteDataEntryStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDataEntryStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fields = [];
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.ignoreOptionalChecks = false;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.fields && message.fields.length) {
                        object.fields = [];
                        for (var j = 0; j < message.fields.length; ++j)
                            object.fields[j] = $root.visor.clientrpc.CompleteDataEntryStepRequest.Field.toObject(message.fields[j], options);
                    }
                    if (message.ignoreOptionalChecks != null && message.hasOwnProperty("ignoreOptionalChecks"))
                        object.ignoreOptionalChecks = message.ignoreOptionalChecks;
                    return object;
                };

                /**
                 * Converts this CompleteDataEntryStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDataEntryStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CompleteDataEntryStepRequest.Field = (function() {

                    /**
                     * Properties of a Field.
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                     * @interface IField
                     * @property {string|null} [fieldToken] Field fieldToken
                     * @property {visor.common.money.IAmount|null} [currency] Field currency
                     * @property {visor.common.time.ILocalDate|null} [date] Field date
                     */

                    /**
                     * Constructs a new Field.
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest
                     * @classdesc Represents a Field.
                     * @implements IField
                     * @constructor
                     * @param {visor.clientrpc.CompleteDataEntryStepRequest.IField=} [properties] Properties to set
                     */
                    function Field(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Field fieldToken.
                     * @member {string} fieldToken
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @instance
                     */
                    Field.prototype.fieldToken = "";

                    /**
                     * Field currency.
                     * @member {visor.common.money.IAmount|null|undefined} currency
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @instance
                     */
                    Field.prototype.currency = null;

                    /**
                     * Field date.
                     * @member {visor.common.time.ILocalDate|null|undefined} date
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @instance
                     */
                    Field.prototype.date = null;

                    // OneOf field names bound to virtual getters and setters
                    var $oneOfFields;

                    /**
                     * Field value.
                     * @member {"currency"|"date"|undefined} value
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @instance
                     */
                    Object.defineProperty(Field.prototype, "value", {
                        get: $util.oneOfGetter($oneOfFields = ["currency", "date"]),
                        set: $util.oneOfSetter($oneOfFields)
                    });

                    /**
                     * Creates a new Field instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteDataEntryStepRequest.IField=} [properties] Properties to set
                     * @returns {visor.clientrpc.CompleteDataEntryStepRequest.Field} Field instance
                     */
                    Field.create = function create(properties) {
                        return new Field(properties);
                    };

                    /**
                     * Encodes the specified Field message. Does not implicitly {@link visor.clientrpc.CompleteDataEntryStepRequest.Field.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteDataEntryStepRequest.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.fieldToken != null && message.hasOwnProperty("fieldToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fieldToken);
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            $root.visor.common.money.Amount.encode(message.currency, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.date != null && message.hasOwnProperty("date"))
                            $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Field message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDataEntryStepRequest.Field.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteDataEntryStepRequest.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Field message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CompleteDataEntryStepRequest.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDataEntryStepRequest.Field();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.fieldToken = reader.string();
                                break;
                            case 2:
                                message.currency = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Field message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CompleteDataEntryStepRequest.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Field message.
                     * @function verify
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Field.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        var properties = {};
                        if (message.fieldToken != null && message.hasOwnProperty("fieldToken"))
                            if (!$util.isString(message.fieldToken))
                                return "fieldToken: string expected";
                        if (message.currency != null && message.hasOwnProperty("currency")) {
                            properties.value = 1;
                            {
                                var error = $root.visor.common.money.Amount.verify(message.currency);
                                if (error)
                                    return "currency." + error;
                            }
                        }
                        if (message.date != null && message.hasOwnProperty("date")) {
                            if (properties.value === 1)
                                return "value: multiple values";
                            properties.value = 1;
                            {
                                var error = $root.visor.common.time.LocalDate.verify(message.date);
                                if (error)
                                    return "date." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Field message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CompleteDataEntryStepRequest.Field} Field
                     */
                    Field.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CompleteDataEntryStepRequest.Field)
                            return object;
                        var message = new $root.visor.clientrpc.CompleteDataEntryStepRequest.Field();
                        if (object.fieldToken != null)
                            message.fieldToken = String(object.fieldToken);
                        if (object.currency != null) {
                            if (typeof object.currency !== "object")
                                throw TypeError(".visor.clientrpc.CompleteDataEntryStepRequest.Field.currency: object expected");
                            message.currency = $root.visor.common.money.Amount.fromObject(object.currency);
                        }
                        if (object.date != null) {
                            if (typeof object.date !== "object")
                                throw TypeError(".visor.clientrpc.CompleteDataEntryStepRequest.Field.date: object expected");
                            message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Field message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @static
                     * @param {visor.clientrpc.CompleteDataEntryStepRequest.Field} message Field
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Field.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.fieldToken = "";
                        if (message.fieldToken != null && message.hasOwnProperty("fieldToken"))
                            object.fieldToken = message.fieldToken;
                        if (message.currency != null && message.hasOwnProperty("currency")) {
                            object.currency = $root.visor.common.money.Amount.toObject(message.currency, options);
                            if (options.oneofs)
                                object.value = "currency";
                        }
                        if (message.date != null && message.hasOwnProperty("date")) {
                            object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                            if (options.oneofs)
                                object.value = "date";
                        }
                        return object;
                    };

                    /**
                     * Converts this Field to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CompleteDataEntryStepRequest.Field
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Field.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Field;
                })();

                return CompleteDataEntryStepRequest;
            })();

            clientrpc.CompleteDataEntryStepResponse = (function() {

                /**
                 * Properties of a CompleteDataEntryStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDataEntryStepResponse
                 * @property {string|null} [sanityCheckMessage] CompleteDataEntryStepResponse sanityCheckMessage
                 */

                /**
                 * Constructs a new CompleteDataEntryStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDataEntryStepResponse.
                 * @implements ICompleteDataEntryStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDataEntryStepResponse=} [properties] Properties to set
                 */
                function CompleteDataEntryStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteDataEntryStepResponse sanityCheckMessage.
                 * @member {string} sanityCheckMessage
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @instance
                 */
                CompleteDataEntryStepResponse.prototype.sanityCheckMessage = "";

                /**
                 * Creates a new CompleteDataEntryStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDataEntryStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDataEntryStepResponse} CompleteDataEntryStepResponse instance
                 */
                CompleteDataEntryStepResponse.create = function create(properties) {
                    return new CompleteDataEntryStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteDataEntryStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteDataEntryStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDataEntryStepResponse} message CompleteDataEntryStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDataEntryStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sanityCheckMessage != null && message.hasOwnProperty("sanityCheckMessage"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sanityCheckMessage);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDataEntryStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDataEntryStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDataEntryStepResponse} message CompleteDataEntryStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDataEntryStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDataEntryStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDataEntryStepResponse} CompleteDataEntryStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDataEntryStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDataEntryStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.sanityCheckMessage = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDataEntryStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDataEntryStepResponse} CompleteDataEntryStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDataEntryStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDataEntryStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDataEntryStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sanityCheckMessage != null && message.hasOwnProperty("sanityCheckMessage"))
                        if (!$util.isString(message.sanityCheckMessage))
                            return "sanityCheckMessage: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteDataEntryStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDataEntryStepResponse} CompleteDataEntryStepResponse
                 */
                CompleteDataEntryStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDataEntryStepResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteDataEntryStepResponse();
                    if (object.sanityCheckMessage != null)
                        message.sanityCheckMessage = String(object.sanityCheckMessage);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteDataEntryStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteDataEntryStepResponse} message CompleteDataEntryStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDataEntryStepResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.sanityCheckMessage = "";
                    if (message.sanityCheckMessage != null && message.hasOwnProperty("sanityCheckMessage"))
                        object.sanityCheckMessage = message.sanityCheckMessage;
                    return object;
                };

                /**
                 * Converts this CompleteDataEntryStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDataEntryStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDataEntryStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDataEntryStepResponse;
            })();

            clientrpc.CompleteConnectPayrollStepRequest = (function() {

                /**
                 * Properties of a CompleteConnectPayrollStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectPayrollStepRequest
                 * @property {string|null} [stepToken] CompleteConnectPayrollStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteConnectPayrollStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteConnectPayrollStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectPayrollStepRequest.
                 * @implements ICompleteConnectPayrollStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepRequest=} [properties] Properties to set
                 */
                function CompleteConnectPayrollStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteConnectPayrollStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @instance
                 */
                CompleteConnectPayrollStepRequest.prototype.stepToken = "";

                /**
                 * CompleteConnectPayrollStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @instance
                 */
                CompleteConnectPayrollStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteConnectPayrollStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepRequest} CompleteConnectPayrollStepRequest instance
                 */
                CompleteConnectPayrollStepRequest.create = function create(properties) {
                    return new CompleteConnectPayrollStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteConnectPayrollStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteConnectPayrollStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepRequest} message CompleteConnectPayrollStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectPayrollStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectPayrollStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectPayrollStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepRequest} message CompleteConnectPayrollStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectPayrollStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectPayrollStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepRequest} CompleteConnectPayrollStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectPayrollStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectPayrollStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectPayrollStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepRequest} CompleteConnectPayrollStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectPayrollStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectPayrollStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectPayrollStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectPayrollStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepRequest} CompleteConnectPayrollStepRequest
                 */
                CompleteConnectPayrollStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectPayrollStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteConnectPayrollStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteConnectPayrollStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteConnectPayrollStepRequest} message CompleteConnectPayrollStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectPayrollStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteConnectPayrollStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectPayrollStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectPayrollStepRequest;
            })();

            clientrpc.CompleteConnectPayrollStepResponse = (function() {

                /**
                 * Properties of a CompleteConnectPayrollStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectPayrollStepResponse
                 */

                /**
                 * Constructs a new CompleteConnectPayrollStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectPayrollStepResponse.
                 * @implements ICompleteConnectPayrollStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepResponse=} [properties] Properties to set
                 */
                function CompleteConnectPayrollStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteConnectPayrollStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepResponse} CompleteConnectPayrollStepResponse instance
                 */
                CompleteConnectPayrollStepResponse.create = function create(properties) {
                    return new CompleteConnectPayrollStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteConnectPayrollStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteConnectPayrollStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepResponse} message CompleteConnectPayrollStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectPayrollStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectPayrollStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectPayrollStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectPayrollStepResponse} message CompleteConnectPayrollStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectPayrollStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectPayrollStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepResponse} CompleteConnectPayrollStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectPayrollStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectPayrollStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectPayrollStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepResponse} CompleteConnectPayrollStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectPayrollStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectPayrollStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectPayrollStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectPayrollStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectPayrollStepResponse} CompleteConnectPayrollStepResponse
                 */
                CompleteConnectPayrollStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectPayrollStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteConnectPayrollStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteConnectPayrollStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteConnectPayrollStepResponse} message CompleteConnectPayrollStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectPayrollStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteConnectPayrollStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectPayrollStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectPayrollStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectPayrollStepResponse;
            })();

            clientrpc.CompleteReviewPayrollStepRequest = (function() {

                /**
                 * Properties of a CompleteReviewPayrollStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteReviewPayrollStepRequest
                 * @property {string|null} [stepToken] CompleteReviewPayrollStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteReviewPayrollStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteReviewPayrollStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteReviewPayrollStepRequest.
                 * @implements ICompleteReviewPayrollStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepRequest=} [properties] Properties to set
                 */
                function CompleteReviewPayrollStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteReviewPayrollStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @instance
                 */
                CompleteReviewPayrollStepRequest.prototype.stepToken = "";

                /**
                 * CompleteReviewPayrollStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @instance
                 */
                CompleteReviewPayrollStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteReviewPayrollStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepRequest} CompleteReviewPayrollStepRequest instance
                 */
                CompleteReviewPayrollStepRequest.create = function create(properties) {
                    return new CompleteReviewPayrollStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteReviewPayrollStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteReviewPayrollStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepRequest} message CompleteReviewPayrollStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewPayrollStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteReviewPayrollStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteReviewPayrollStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepRequest} message CompleteReviewPayrollStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewPayrollStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteReviewPayrollStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepRequest} CompleteReviewPayrollStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewPayrollStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteReviewPayrollStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteReviewPayrollStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepRequest} CompleteReviewPayrollStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewPayrollStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteReviewPayrollStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewPayrollStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteReviewPayrollStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepRequest} CompleteReviewPayrollStepRequest
                 */
                CompleteReviewPayrollStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteReviewPayrollStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteReviewPayrollStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteReviewPayrollStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteReviewPayrollStepRequest} message CompleteReviewPayrollStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewPayrollStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteReviewPayrollStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewPayrollStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteReviewPayrollStepRequest;
            })();

            clientrpc.CompleteReviewPayrollStepResponse = (function() {

                /**
                 * Properties of a CompleteReviewPayrollStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteReviewPayrollStepResponse
                 */

                /**
                 * Constructs a new CompleteReviewPayrollStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteReviewPayrollStepResponse.
                 * @implements ICompleteReviewPayrollStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepResponse=} [properties] Properties to set
                 */
                function CompleteReviewPayrollStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteReviewPayrollStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepResponse} CompleteReviewPayrollStepResponse instance
                 */
                CompleteReviewPayrollStepResponse.create = function create(properties) {
                    return new CompleteReviewPayrollStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteReviewPayrollStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteReviewPayrollStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepResponse} message CompleteReviewPayrollStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewPayrollStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteReviewPayrollStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteReviewPayrollStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewPayrollStepResponse} message CompleteReviewPayrollStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewPayrollStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteReviewPayrollStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepResponse} CompleteReviewPayrollStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewPayrollStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteReviewPayrollStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteReviewPayrollStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepResponse} CompleteReviewPayrollStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewPayrollStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteReviewPayrollStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewPayrollStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteReviewPayrollStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteReviewPayrollStepResponse} CompleteReviewPayrollStepResponse
                 */
                CompleteReviewPayrollStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteReviewPayrollStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteReviewPayrollStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteReviewPayrollStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteReviewPayrollStepResponse} message CompleteReviewPayrollStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewPayrollStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteReviewPayrollStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteReviewPayrollStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewPayrollStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteReviewPayrollStepResponse;
            })();

            clientrpc.CompleteInterstitialStepRequest = (function() {

                /**
                 * Properties of a CompleteInterstitialStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteInterstitialStepRequest
                 * @property {string|null} [stepToken] CompleteInterstitialStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteInterstitialStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteInterstitialStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteInterstitialStepRequest.
                 * @implements ICompleteInterstitialStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteInterstitialStepRequest=} [properties] Properties to set
                 */
                function CompleteInterstitialStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteInterstitialStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @instance
                 */
                CompleteInterstitialStepRequest.prototype.stepToken = "";

                /**
                 * CompleteInterstitialStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @instance
                 */
                CompleteInterstitialStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteInterstitialStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteInterstitialStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteInterstitialStepRequest} CompleteInterstitialStepRequest instance
                 */
                CompleteInterstitialStepRequest.create = function create(properties) {
                    return new CompleteInterstitialStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteInterstitialStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteInterstitialStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteInterstitialStepRequest} message CompleteInterstitialStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteInterstitialStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteInterstitialStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteInterstitialStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteInterstitialStepRequest} message CompleteInterstitialStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteInterstitialStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteInterstitialStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteInterstitialStepRequest} CompleteInterstitialStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteInterstitialStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteInterstitialStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteInterstitialStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteInterstitialStepRequest} CompleteInterstitialStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteInterstitialStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteInterstitialStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteInterstitialStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteInterstitialStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteInterstitialStepRequest} CompleteInterstitialStepRequest
                 */
                CompleteInterstitialStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteInterstitialStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteInterstitialStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteInterstitialStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteInterstitialStepRequest} message CompleteInterstitialStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteInterstitialStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteInterstitialStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteInterstitialStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteInterstitialStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteInterstitialStepRequest;
            })();

            clientrpc.CompleteInterstitialStepResponse = (function() {

                /**
                 * Properties of a CompleteInterstitialStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteInterstitialStepResponse
                 */

                /**
                 * Constructs a new CompleteInterstitialStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteInterstitialStepResponse.
                 * @implements ICompleteInterstitialStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteInterstitialStepResponse=} [properties] Properties to set
                 */
                function CompleteInterstitialStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteInterstitialStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteInterstitialStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteInterstitialStepResponse} CompleteInterstitialStepResponse instance
                 */
                CompleteInterstitialStepResponse.create = function create(properties) {
                    return new CompleteInterstitialStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteInterstitialStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteInterstitialStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteInterstitialStepResponse} message CompleteInterstitialStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteInterstitialStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteInterstitialStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteInterstitialStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteInterstitialStepResponse} message CompleteInterstitialStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteInterstitialStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteInterstitialStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteInterstitialStepResponse} CompleteInterstitialStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteInterstitialStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteInterstitialStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteInterstitialStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteInterstitialStepResponse} CompleteInterstitialStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteInterstitialStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteInterstitialStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteInterstitialStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteInterstitialStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteInterstitialStepResponse} CompleteInterstitialStepResponse
                 */
                CompleteInterstitialStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteInterstitialStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteInterstitialStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteInterstitialStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteInterstitialStepResponse} message CompleteInterstitialStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteInterstitialStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteInterstitialStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteInterstitialStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteInterstitialStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteInterstitialStepResponse;
            })();

            clientrpc.CompleteSignupStepRequest = (function() {

                /**
                 * Properties of a CompleteSignupStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSignupStepRequest
                 * @property {string|null} [stepToken] CompleteSignupStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteSignupStepRequest workflowToken
                 * @property {string|null} [email] CompleteSignupStepRequest email
                 * @property {string|null} [encryptedPassword] CompleteSignupStepRequest encryptedPassword
                 * @property {google.protobuf.ITimestamp|null} [acceptedLegalTermsAtTimestamp] CompleteSignupStepRequest acceptedLegalTermsAtTimestamp
                 */

                /**
                 * Constructs a new CompleteSignupStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSignupStepRequest.
                 * @implements ICompleteSignupStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSignupStepRequest=} [properties] Properties to set
                 */
                function CompleteSignupStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteSignupStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @instance
                 */
                CompleteSignupStepRequest.prototype.stepToken = "";

                /**
                 * CompleteSignupStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @instance
                 */
                CompleteSignupStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteSignupStepRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @instance
                 */
                CompleteSignupStepRequest.prototype.email = "";

                /**
                 * CompleteSignupStepRequest encryptedPassword.
                 * @member {string} encryptedPassword
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @instance
                 */
                CompleteSignupStepRequest.prototype.encryptedPassword = "";

                /**
                 * CompleteSignupStepRequest acceptedLegalTermsAtTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} acceptedLegalTermsAtTimestamp
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @instance
                 */
                CompleteSignupStepRequest.prototype.acceptedLegalTermsAtTimestamp = null;

                /**
                 * Creates a new CompleteSignupStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSignupStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSignupStepRequest} CompleteSignupStepRequest instance
                 */
                CompleteSignupStepRequest.create = function create(properties) {
                    return new CompleteSignupStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteSignupStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteSignupStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSignupStepRequest} message CompleteSignupStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignupStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.encryptedPassword);
                    if (message.acceptedLegalTermsAtTimestamp != null && message.hasOwnProperty("acceptedLegalTermsAtTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.acceptedLegalTermsAtTimestamp, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSignupStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSignupStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSignupStepRequest} message CompleteSignupStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignupStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSignupStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSignupStepRequest} CompleteSignupStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignupStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSignupStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.email = reader.string();
                            break;
                        case 4:
                            message.encryptedPassword = reader.string();
                            break;
                        case 5:
                            message.acceptedLegalTermsAtTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSignupStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSignupStepRequest} CompleteSignupStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignupStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSignupStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSignupStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        if (!$util.isString(message.encryptedPassword))
                            return "encryptedPassword: string expected";
                    if (message.acceptedLegalTermsAtTimestamp != null && message.hasOwnProperty("acceptedLegalTermsAtTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.acceptedLegalTermsAtTimestamp);
                        if (error)
                            return "acceptedLegalTermsAtTimestamp." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteSignupStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSignupStepRequest} CompleteSignupStepRequest
                 */
                CompleteSignupStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSignupStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteSignupStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.encryptedPassword != null)
                        message.encryptedPassword = String(object.encryptedPassword);
                    if (object.acceptedLegalTermsAtTimestamp != null)
                        if (object.acceptedLegalTermsAtTimestamp === null)
                            message.acceptedLegalTermsAtTimestamp = {};
                        else
                            message.acceptedLegalTermsAtTimestamp = {
                                seconds: Math.floor(object.acceptedLegalTermsAtTimestamp.getTime() / 1000),
                                nanos: object.acceptedLegalTermsAtTimestamp.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteSignupStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteSignupStepRequest} message CompleteSignupStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSignupStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.email = "";
                        object.encryptedPassword = "";
                        object.acceptedLegalTermsAtTimestamp = null;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        object.encryptedPassword = message.encryptedPassword;
                    if (message.acceptedLegalTermsAtTimestamp != null && message.hasOwnProperty("acceptedLegalTermsAtTimestamp"))
                        object.acceptedLegalTermsAtTimestamp = new Date(message.acceptedLegalTermsAtTimestamp.seconds * 1000 + message.acceptedLegalTermsAtTimestamp.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this CompleteSignupStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSignupStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSignupStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSignupStepRequest;
            })();

            clientrpc.CompleteSignupStepResponse = (function() {

                /**
                 * Properties of a CompleteSignupStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSignupStepResponse
                 */

                /**
                 * Constructs a new CompleteSignupStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSignupStepResponse.
                 * @implements ICompleteSignupStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSignupStepResponse=} [properties] Properties to set
                 */
                function CompleteSignupStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteSignupStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSignupStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSignupStepResponse} CompleteSignupStepResponse instance
                 */
                CompleteSignupStepResponse.create = function create(properties) {
                    return new CompleteSignupStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteSignupStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteSignupStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSignupStepResponse} message CompleteSignupStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignupStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSignupStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSignupStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSignupStepResponse} message CompleteSignupStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSignupStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSignupStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSignupStepResponse} CompleteSignupStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignupStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSignupStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSignupStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSignupStepResponse} CompleteSignupStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSignupStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSignupStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSignupStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteSignupStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSignupStepResponse} CompleteSignupStepResponse
                 */
                CompleteSignupStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSignupStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteSignupStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteSignupStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteSignupStepResponse} message CompleteSignupStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSignupStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteSignupStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSignupStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSignupStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSignupStepResponse;
            })();

            clientrpc.CompletePermissionStepRequest = (function() {

                /**
                 * Properties of a CompletePermissionStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompletePermissionStepRequest
                 * @property {string|null} [stepToken] CompletePermissionStepRequest stepToken
                 * @property {string|null} [workflowToken] CompletePermissionStepRequest workflowToken
                 * @property {Array.<visor.clientrpc.CompletePermissionStepRequest.Permission>|null} [permissionsObtained] CompletePermissionStepRequest permissionsObtained
                 */

                /**
                 * Constructs a new CompletePermissionStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePermissionStepRequest.
                 * @implements ICompletePermissionStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompletePermissionStepRequest=} [properties] Properties to set
                 */
                function CompletePermissionStepRequest(properties) {
                    this.permissionsObtained = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompletePermissionStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @instance
                 */
                CompletePermissionStepRequest.prototype.stepToken = "";

                /**
                 * CompletePermissionStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @instance
                 */
                CompletePermissionStepRequest.prototype.workflowToken = "";

                /**
                 * CompletePermissionStepRequest permissionsObtained.
                 * @member {Array.<visor.clientrpc.CompletePermissionStepRequest.Permission>} permissionsObtained
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @instance
                 */
                CompletePermissionStepRequest.prototype.permissionsObtained = $util.emptyArray;

                /**
                 * Creates a new CompletePermissionStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePermissionStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePermissionStepRequest} CompletePermissionStepRequest instance
                 */
                CompletePermissionStepRequest.create = function create(properties) {
                    return new CompletePermissionStepRequest(properties);
                };

                /**
                 * Encodes the specified CompletePermissionStepRequest message. Does not implicitly {@link visor.clientrpc.CompletePermissionStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePermissionStepRequest} message CompletePermissionStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePermissionStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.permissionsObtained != null && message.permissionsObtained.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.permissionsObtained.length; ++i)
                            writer.int32(message.permissionsObtained[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified CompletePermissionStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePermissionStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePermissionStepRequest} message CompletePermissionStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePermissionStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePermissionStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePermissionStepRequest} CompletePermissionStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePermissionStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePermissionStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            if (!(message.permissionsObtained && message.permissionsObtained.length))
                                message.permissionsObtained = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.permissionsObtained.push(reader.int32());
                            } else
                                message.permissionsObtained.push(reader.int32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePermissionStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePermissionStepRequest} CompletePermissionStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePermissionStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePermissionStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePermissionStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.permissionsObtained != null && message.hasOwnProperty("permissionsObtained")) {
                        if (!Array.isArray(message.permissionsObtained))
                            return "permissionsObtained: array expected";
                        for (var i = 0; i < message.permissionsObtained.length; ++i)
                            switch (message.permissionsObtained[i]) {
                            default:
                                return "permissionsObtained: enum value[] expected";
                            case 0:
                            case 1:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a CompletePermissionStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePermissionStepRequest} CompletePermissionStepRequest
                 */
                CompletePermissionStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePermissionStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompletePermissionStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.permissionsObtained) {
                        if (!Array.isArray(object.permissionsObtained))
                            throw TypeError(".visor.clientrpc.CompletePermissionStepRequest.permissionsObtained: array expected");
                        message.permissionsObtained = [];
                        for (var i = 0; i < object.permissionsObtained.length; ++i)
                            switch (object.permissionsObtained[i]) {
                            default:
                            case "COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_INVALID":
                            case 0:
                                message.permissionsObtained[i] = 0;
                                break;
                            case "COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_PUSH":
                            case 1:
                                message.permissionsObtained[i] = 1;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompletePermissionStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @static
                 * @param {visor.clientrpc.CompletePermissionStepRequest} message CompletePermissionStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePermissionStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.permissionsObtained = [];
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.permissionsObtained && message.permissionsObtained.length) {
                        object.permissionsObtained = [];
                        for (var j = 0; j < message.permissionsObtained.length; ++j)
                            object.permissionsObtained[j] = options.enums === String ? $root.visor.clientrpc.CompletePermissionStepRequest.Permission[message.permissionsObtained[j]] : message.permissionsObtained[j];
                    }
                    return object;
                };

                /**
                 * Converts this CompletePermissionStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePermissionStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePermissionStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Permission enum.
                 * @name visor.clientrpc.CompletePermissionStepRequest.Permission
                 * @enum {string}
                 * @property {number} COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_INVALID=0 COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_INVALID value
                 * @property {number} COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_PUSH=1 COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_PUSH value
                 */
                CompletePermissionStepRequest.Permission = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_INVALID"] = 0;
                    values[valuesById[1] = "COMPLETE_PERMISSION_STEP_REQUEST_PERMISSION_PUSH"] = 1;
                    return values;
                })();

                return CompletePermissionStepRequest;
            })();

            clientrpc.CompletePermissionStepResponse = (function() {

                /**
                 * Properties of a CompletePermissionStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompletePermissionStepResponse
                 */

                /**
                 * Constructs a new CompletePermissionStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePermissionStepResponse.
                 * @implements ICompletePermissionStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompletePermissionStepResponse=} [properties] Properties to set
                 */
                function CompletePermissionStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompletePermissionStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePermissionStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePermissionStepResponse} CompletePermissionStepResponse instance
                 */
                CompletePermissionStepResponse.create = function create(properties) {
                    return new CompletePermissionStepResponse(properties);
                };

                /**
                 * Encodes the specified CompletePermissionStepResponse message. Does not implicitly {@link visor.clientrpc.CompletePermissionStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePermissionStepResponse} message CompletePermissionStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePermissionStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompletePermissionStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePermissionStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePermissionStepResponse} message CompletePermissionStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePermissionStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePermissionStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePermissionStepResponse} CompletePermissionStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePermissionStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePermissionStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePermissionStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePermissionStepResponse} CompletePermissionStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePermissionStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePermissionStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePermissionStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompletePermissionStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePermissionStepResponse} CompletePermissionStepResponse
                 */
                CompletePermissionStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePermissionStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompletePermissionStepResponse();
                };

                /**
                 * Creates a plain object from a CompletePermissionStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @static
                 * @param {visor.clientrpc.CompletePermissionStepResponse} message CompletePermissionStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePermissionStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompletePermissionStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePermissionStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePermissionStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePermissionStepResponse;
            })();

            clientrpc.CompleteOffboardingStepRequest = (function() {

                /**
                 * Properties of a CompleteOffboardingStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteOffboardingStepRequest
                 * @property {string|null} [stepToken] CompleteOffboardingStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteOffboardingStepRequest workflowToken
                 * @property {string|null} [emailOverride] CompleteOffboardingStepRequest emailOverride
                 */

                /**
                 * Constructs a new CompleteOffboardingStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteOffboardingStepRequest.
                 * @implements ICompleteOffboardingStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteOffboardingStepRequest=} [properties] Properties to set
                 */
                function CompleteOffboardingStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteOffboardingStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @instance
                 */
                CompleteOffboardingStepRequest.prototype.stepToken = "";

                /**
                 * CompleteOffboardingStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @instance
                 */
                CompleteOffboardingStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteOffboardingStepRequest emailOverride.
                 * @member {string} emailOverride
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @instance
                 */
                CompleteOffboardingStepRequest.prototype.emailOverride = "";

                /**
                 * Creates a new CompleteOffboardingStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteOffboardingStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteOffboardingStepRequest} CompleteOffboardingStepRequest instance
                 */
                CompleteOffboardingStepRequest.create = function create(properties) {
                    return new CompleteOffboardingStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteOffboardingStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteOffboardingStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteOffboardingStepRequest} message CompleteOffboardingStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteOffboardingStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.emailOverride != null && message.hasOwnProperty("emailOverride"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.emailOverride);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteOffboardingStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteOffboardingStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteOffboardingStepRequest} message CompleteOffboardingStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteOffboardingStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteOffboardingStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteOffboardingStepRequest} CompleteOffboardingStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteOffboardingStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteOffboardingStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.emailOverride = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteOffboardingStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteOffboardingStepRequest} CompleteOffboardingStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteOffboardingStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteOffboardingStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteOffboardingStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.emailOverride != null && message.hasOwnProperty("emailOverride"))
                        if (!$util.isString(message.emailOverride))
                            return "emailOverride: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteOffboardingStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteOffboardingStepRequest} CompleteOffboardingStepRequest
                 */
                CompleteOffboardingStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteOffboardingStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteOffboardingStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.emailOverride != null)
                        message.emailOverride = String(object.emailOverride);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteOffboardingStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteOffboardingStepRequest} message CompleteOffboardingStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteOffboardingStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.emailOverride = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.emailOverride != null && message.hasOwnProperty("emailOverride"))
                        object.emailOverride = message.emailOverride;
                    return object;
                };

                /**
                 * Converts this CompleteOffboardingStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteOffboardingStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteOffboardingStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteOffboardingStepRequest;
            })();

            clientrpc.CompleteOffboardingStepResponse = (function() {

                /**
                 * Properties of a CompleteOffboardingStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteOffboardingStepResponse
                 */

                /**
                 * Constructs a new CompleteOffboardingStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteOffboardingStepResponse.
                 * @implements ICompleteOffboardingStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteOffboardingStepResponse=} [properties] Properties to set
                 */
                function CompleteOffboardingStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteOffboardingStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteOffboardingStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteOffboardingStepResponse} CompleteOffboardingStepResponse instance
                 */
                CompleteOffboardingStepResponse.create = function create(properties) {
                    return new CompleteOffboardingStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteOffboardingStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteOffboardingStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteOffboardingStepResponse} message CompleteOffboardingStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteOffboardingStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteOffboardingStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteOffboardingStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteOffboardingStepResponse} message CompleteOffboardingStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteOffboardingStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteOffboardingStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteOffboardingStepResponse} CompleteOffboardingStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteOffboardingStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteOffboardingStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteOffboardingStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteOffboardingStepResponse} CompleteOffboardingStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteOffboardingStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteOffboardingStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteOffboardingStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteOffboardingStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteOffboardingStepResponse} CompleteOffboardingStepResponse
                 */
                CompleteOffboardingStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteOffboardingStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteOffboardingStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteOffboardingStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteOffboardingStepResponse} message CompleteOffboardingStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteOffboardingStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteOffboardingStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteOffboardingStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteOffboardingStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteOffboardingStepResponse;
            })();

            clientrpc.CompleteDocumentRequestStepRequest = (function() {

                /**
                 * Properties of a CompleteDocumentRequestStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDocumentRequestStepRequest
                 * @property {string|null} [stepToken] CompleteDocumentRequestStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteDocumentRequestStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteDocumentRequestStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDocumentRequestStepRequest.
                 * @implements ICompleteDocumentRequestStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepRequest=} [properties] Properties to set
                 */
                function CompleteDocumentRequestStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteDocumentRequestStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @instance
                 */
                CompleteDocumentRequestStepRequest.prototype.stepToken = "";

                /**
                 * CompleteDocumentRequestStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @instance
                 */
                CompleteDocumentRequestStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteDocumentRequestStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepRequest} CompleteDocumentRequestStepRequest instance
                 */
                CompleteDocumentRequestStepRequest.create = function create(properties) {
                    return new CompleteDocumentRequestStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteDocumentRequestStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteDocumentRequestStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepRequest} message CompleteDocumentRequestStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentRequestStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDocumentRequestStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDocumentRequestStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepRequest} message CompleteDocumentRequestStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentRequestStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDocumentRequestStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepRequest} CompleteDocumentRequestStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentRequestStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDocumentRequestStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDocumentRequestStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepRequest} CompleteDocumentRequestStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentRequestStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDocumentRequestStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDocumentRequestStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteDocumentRequestStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepRequest} CompleteDocumentRequestStepRequest
                 */
                CompleteDocumentRequestStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDocumentRequestStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteDocumentRequestStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteDocumentRequestStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteDocumentRequestStepRequest} message CompleteDocumentRequestStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDocumentRequestStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteDocumentRequestStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDocumentRequestStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDocumentRequestStepRequest;
            })();

            clientrpc.CompleteDocumentRequestStepResponse = (function() {

                /**
                 * Properties of a CompleteDocumentRequestStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDocumentRequestStepResponse
                 */

                /**
                 * Constructs a new CompleteDocumentRequestStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDocumentRequestStepResponse.
                 * @implements ICompleteDocumentRequestStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepResponse=} [properties] Properties to set
                 */
                function CompleteDocumentRequestStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteDocumentRequestStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepResponse} CompleteDocumentRequestStepResponse instance
                 */
                CompleteDocumentRequestStepResponse.create = function create(properties) {
                    return new CompleteDocumentRequestStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteDocumentRequestStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteDocumentRequestStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepResponse} message CompleteDocumentRequestStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentRequestStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDocumentRequestStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDocumentRequestStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentRequestStepResponse} message CompleteDocumentRequestStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentRequestStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDocumentRequestStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepResponse} CompleteDocumentRequestStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentRequestStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDocumentRequestStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDocumentRequestStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepResponse} CompleteDocumentRequestStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentRequestStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDocumentRequestStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDocumentRequestStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteDocumentRequestStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDocumentRequestStepResponse} CompleteDocumentRequestStepResponse
                 */
                CompleteDocumentRequestStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDocumentRequestStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteDocumentRequestStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteDocumentRequestStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteDocumentRequestStepResponse} message CompleteDocumentRequestStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDocumentRequestStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteDocumentRequestStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDocumentRequestStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDocumentRequestStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDocumentRequestStepResponse;
            })();

            clientrpc.CompleteDocumentDeliveryStepRequest = (function() {

                /**
                 * Properties of a CompleteDocumentDeliveryStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDocumentDeliveryStepRequest
                 * @property {string|null} [stepToken] CompleteDocumentDeliveryStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteDocumentDeliveryStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteDocumentDeliveryStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDocumentDeliveryStepRequest.
                 * @implements ICompleteDocumentDeliveryStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepRequest=} [properties] Properties to set
                 */
                function CompleteDocumentDeliveryStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteDocumentDeliveryStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @instance
                 */
                CompleteDocumentDeliveryStepRequest.prototype.stepToken = "";

                /**
                 * CompleteDocumentDeliveryStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @instance
                 */
                CompleteDocumentDeliveryStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteDocumentDeliveryStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepRequest} CompleteDocumentDeliveryStepRequest instance
                 */
                CompleteDocumentDeliveryStepRequest.create = function create(properties) {
                    return new CompleteDocumentDeliveryStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteDocumentDeliveryStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteDocumentDeliveryStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepRequest} message CompleteDocumentDeliveryStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentDeliveryStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDocumentDeliveryStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDocumentDeliveryStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepRequest} message CompleteDocumentDeliveryStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentDeliveryStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDocumentDeliveryStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepRequest} CompleteDocumentDeliveryStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentDeliveryStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDocumentDeliveryStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDocumentDeliveryStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepRequest} CompleteDocumentDeliveryStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentDeliveryStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDocumentDeliveryStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDocumentDeliveryStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteDocumentDeliveryStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepRequest} CompleteDocumentDeliveryStepRequest
                 */
                CompleteDocumentDeliveryStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDocumentDeliveryStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteDocumentDeliveryStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteDocumentDeliveryStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteDocumentDeliveryStepRequest} message CompleteDocumentDeliveryStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDocumentDeliveryStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteDocumentDeliveryStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDocumentDeliveryStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDocumentDeliveryStepRequest;
            })();

            clientrpc.CompleteDocumentDeliveryStepResponse = (function() {

                /**
                 * Properties of a CompleteDocumentDeliveryStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteDocumentDeliveryStepResponse
                 */

                /**
                 * Constructs a new CompleteDocumentDeliveryStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteDocumentDeliveryStepResponse.
                 * @implements ICompleteDocumentDeliveryStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepResponse=} [properties] Properties to set
                 */
                function CompleteDocumentDeliveryStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteDocumentDeliveryStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepResponse} CompleteDocumentDeliveryStepResponse instance
                 */
                CompleteDocumentDeliveryStepResponse.create = function create(properties) {
                    return new CompleteDocumentDeliveryStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteDocumentDeliveryStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteDocumentDeliveryStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepResponse} message CompleteDocumentDeliveryStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentDeliveryStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteDocumentDeliveryStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteDocumentDeliveryStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteDocumentDeliveryStepResponse} message CompleteDocumentDeliveryStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteDocumentDeliveryStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteDocumentDeliveryStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepResponse} CompleteDocumentDeliveryStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentDeliveryStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteDocumentDeliveryStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteDocumentDeliveryStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepResponse} CompleteDocumentDeliveryStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteDocumentDeliveryStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteDocumentDeliveryStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteDocumentDeliveryStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteDocumentDeliveryStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteDocumentDeliveryStepResponse} CompleteDocumentDeliveryStepResponse
                 */
                CompleteDocumentDeliveryStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteDocumentDeliveryStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteDocumentDeliveryStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteDocumentDeliveryStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteDocumentDeliveryStepResponse} message CompleteDocumentDeliveryStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteDocumentDeliveryStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteDocumentDeliveryStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteDocumentDeliveryStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteDocumentDeliveryStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteDocumentDeliveryStepResponse;
            })();

            clientrpc.CompleteReviewLoyaltyProfileStepRequest = (function() {

                /**
                 * Properties of a CompleteReviewLoyaltyProfileStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteReviewLoyaltyProfileStepRequest
                 * @property {string|null} [stepToken] CompleteReviewLoyaltyProfileStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteReviewLoyaltyProfileStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteReviewLoyaltyProfileStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteReviewLoyaltyProfileStepRequest.
                 * @implements ICompleteReviewLoyaltyProfileStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepRequest=} [properties] Properties to set
                 */
                function CompleteReviewLoyaltyProfileStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteReviewLoyaltyProfileStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @instance
                 */
                CompleteReviewLoyaltyProfileStepRequest.prototype.stepToken = "";

                /**
                 * CompleteReviewLoyaltyProfileStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @instance
                 */
                CompleteReviewLoyaltyProfileStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteReviewLoyaltyProfileStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest} CompleteReviewLoyaltyProfileStepRequest instance
                 */
                CompleteReviewLoyaltyProfileStepRequest.create = function create(properties) {
                    return new CompleteReviewLoyaltyProfileStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteReviewLoyaltyProfileStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepRequest} message CompleteReviewLoyaltyProfileStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewLoyaltyProfileStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteReviewLoyaltyProfileStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepRequest} message CompleteReviewLoyaltyProfileStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewLoyaltyProfileStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteReviewLoyaltyProfileStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest} CompleteReviewLoyaltyProfileStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewLoyaltyProfileStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteReviewLoyaltyProfileStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest} CompleteReviewLoyaltyProfileStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewLoyaltyProfileStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteReviewLoyaltyProfileStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewLoyaltyProfileStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteReviewLoyaltyProfileStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest} CompleteReviewLoyaltyProfileStepRequest
                 */
                CompleteReviewLoyaltyProfileStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteReviewLoyaltyProfileStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest} message CompleteReviewLoyaltyProfileStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewLoyaltyProfileStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteReviewLoyaltyProfileStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewLoyaltyProfileStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteReviewLoyaltyProfileStepRequest;
            })();

            clientrpc.CompleteReviewLoyaltyProfileStepResponse = (function() {

                /**
                 * Properties of a CompleteReviewLoyaltyProfileStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteReviewLoyaltyProfileStepResponse
                 */

                /**
                 * Constructs a new CompleteReviewLoyaltyProfileStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteReviewLoyaltyProfileStepResponse.
                 * @implements ICompleteReviewLoyaltyProfileStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepResponse=} [properties] Properties to set
                 */
                function CompleteReviewLoyaltyProfileStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteReviewLoyaltyProfileStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse} CompleteReviewLoyaltyProfileStepResponse instance
                 */
                CompleteReviewLoyaltyProfileStepResponse.create = function create(properties) {
                    return new CompleteReviewLoyaltyProfileStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteReviewLoyaltyProfileStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepResponse} message CompleteReviewLoyaltyProfileStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewLoyaltyProfileStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteReviewLoyaltyProfileStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteReviewLoyaltyProfileStepResponse} message CompleteReviewLoyaltyProfileStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteReviewLoyaltyProfileStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteReviewLoyaltyProfileStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse} CompleteReviewLoyaltyProfileStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewLoyaltyProfileStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteReviewLoyaltyProfileStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse} CompleteReviewLoyaltyProfileStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteReviewLoyaltyProfileStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteReviewLoyaltyProfileStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteReviewLoyaltyProfileStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteReviewLoyaltyProfileStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse} CompleteReviewLoyaltyProfileStepResponse
                 */
                CompleteReviewLoyaltyProfileStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteReviewLoyaltyProfileStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse} message CompleteReviewLoyaltyProfileStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteReviewLoyaltyProfileStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteReviewLoyaltyProfileStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteReviewLoyaltyProfileStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteReviewLoyaltyProfileStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteReviewLoyaltyProfileStepResponse;
            })();

            clientrpc.CreatePayrollConnectionRequest = (function() {

                /**
                 * Properties of a CreatePayrollConnectionRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreatePayrollConnectionRequest
                 * @property {string|null} [title] CreatePayrollConnectionRequest title
                 * @property {string|null} [employerName] CreatePayrollConnectionRequest employerName
                 * @property {string|null} [institutionToken] CreatePayrollConnectionRequest institutionToken
                 * @property {visor.common.encryption.IEncryptedItem|null} [encryptedUsername] CreatePayrollConnectionRequest encryptedUsername
                 * @property {visor.common.encryption.IEncryptedItem|null} [encryptedPassword] CreatePayrollConnectionRequest encryptedPassword
                 * @property {Object.<string,string>|null} [extraLoginFields] CreatePayrollConnectionRequest extraLoginFields
                 */

                /**
                 * Constructs a new CreatePayrollConnectionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreatePayrollConnectionRequest.
                 * @implements ICreatePayrollConnectionRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreatePayrollConnectionRequest=} [properties] Properties to set
                 */
                function CreatePayrollConnectionRequest(properties) {
                    this.extraLoginFields = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreatePayrollConnectionRequest title.
                 * @member {string} title
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @instance
                 */
                CreatePayrollConnectionRequest.prototype.title = "";

                /**
                 * CreatePayrollConnectionRequest employerName.
                 * @member {string} employerName
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @instance
                 */
                CreatePayrollConnectionRequest.prototype.employerName = "";

                /**
                 * CreatePayrollConnectionRequest institutionToken.
                 * @member {string} institutionToken
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @instance
                 */
                CreatePayrollConnectionRequest.prototype.institutionToken = "";

                /**
                 * CreatePayrollConnectionRequest encryptedUsername.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} encryptedUsername
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @instance
                 */
                CreatePayrollConnectionRequest.prototype.encryptedUsername = null;

                /**
                 * CreatePayrollConnectionRequest encryptedPassword.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} encryptedPassword
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @instance
                 */
                CreatePayrollConnectionRequest.prototype.encryptedPassword = null;

                /**
                 * CreatePayrollConnectionRequest extraLoginFields.
                 * @member {Object.<string,string>} extraLoginFields
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @instance
                 */
                CreatePayrollConnectionRequest.prototype.extraLoginFields = $util.emptyObject;

                /**
                 * Creates a new CreatePayrollConnectionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.ICreatePayrollConnectionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreatePayrollConnectionRequest} CreatePayrollConnectionRequest instance
                 */
                CreatePayrollConnectionRequest.create = function create(properties) {
                    return new CreatePayrollConnectionRequest(properties);
                };

                /**
                 * Encodes the specified CreatePayrollConnectionRequest message. Does not implicitly {@link visor.clientrpc.CreatePayrollConnectionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.ICreatePayrollConnectionRequest} message CreatePayrollConnectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreatePayrollConnectionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && message.hasOwnProperty("title"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                    if (message.employerName != null && message.hasOwnProperty("employerName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.employerName);
                    if (message.institutionToken != null && message.hasOwnProperty("institutionToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.institutionToken);
                    if (message.encryptedUsername != null && message.hasOwnProperty("encryptedUsername"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.encryptedUsername, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.encryptedPassword, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.extraLoginFields != null && message.hasOwnProperty("extraLoginFields"))
                        for (var keys = Object.keys(message.extraLoginFields), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.extraLoginFields[keys[i]]).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreatePayrollConnectionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreatePayrollConnectionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.ICreatePayrollConnectionRequest} message CreatePayrollConnectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreatePayrollConnectionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreatePayrollConnectionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreatePayrollConnectionRequest} CreatePayrollConnectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreatePayrollConnectionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreatePayrollConnectionRequest(), key;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.title = reader.string();
                            break;
                        case 2:
                            message.employerName = reader.string();
                            break;
                        case 3:
                            message.institutionToken = reader.string();
                            break;
                        case 4:
                            message.encryptedUsername = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.encryptedPassword = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 6:
                            reader.skip().pos++;
                            if (message.extraLoginFields === $util.emptyObject)
                                message.extraLoginFields = {};
                            key = reader.string();
                            reader.pos++;
                            message.extraLoginFields[key] = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreatePayrollConnectionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreatePayrollConnectionRequest} CreatePayrollConnectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreatePayrollConnectionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreatePayrollConnectionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreatePayrollConnectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.employerName != null && message.hasOwnProperty("employerName"))
                        if (!$util.isString(message.employerName))
                            return "employerName: string expected";
                    if (message.institutionToken != null && message.hasOwnProperty("institutionToken"))
                        if (!$util.isString(message.institutionToken))
                            return "institutionToken: string expected";
                    if (message.encryptedUsername != null && message.hasOwnProperty("encryptedUsername")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.encryptedUsername);
                        if (error)
                            return "encryptedUsername." + error;
                    }
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.encryptedPassword);
                        if (error)
                            return "encryptedPassword." + error;
                    }
                    if (message.extraLoginFields != null && message.hasOwnProperty("extraLoginFields")) {
                        if (!$util.isObject(message.extraLoginFields))
                            return "extraLoginFields: object expected";
                        var key = Object.keys(message.extraLoginFields);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.extraLoginFields[key[i]]))
                                return "extraLoginFields: string{k:string} expected";
                    }
                    return null;
                };

                /**
                 * Creates a CreatePayrollConnectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreatePayrollConnectionRequest} CreatePayrollConnectionRequest
                 */
                CreatePayrollConnectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreatePayrollConnectionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreatePayrollConnectionRequest();
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.employerName != null)
                        message.employerName = String(object.employerName);
                    if (object.institutionToken != null)
                        message.institutionToken = String(object.institutionToken);
                    if (object.encryptedUsername != null) {
                        if (typeof object.encryptedUsername !== "object")
                            throw TypeError(".visor.clientrpc.CreatePayrollConnectionRequest.encryptedUsername: object expected");
                        message.encryptedUsername = $root.visor.common.encryption.EncryptedItem.fromObject(object.encryptedUsername);
                    }
                    if (object.encryptedPassword != null) {
                        if (typeof object.encryptedPassword !== "object")
                            throw TypeError(".visor.clientrpc.CreatePayrollConnectionRequest.encryptedPassword: object expected");
                        message.encryptedPassword = $root.visor.common.encryption.EncryptedItem.fromObject(object.encryptedPassword);
                    }
                    if (object.extraLoginFields) {
                        if (typeof object.extraLoginFields !== "object")
                            throw TypeError(".visor.clientrpc.CreatePayrollConnectionRequest.extraLoginFields: object expected");
                        message.extraLoginFields = {};
                        for (var keys = Object.keys(object.extraLoginFields), i = 0; i < keys.length; ++i)
                            message.extraLoginFields[keys[i]] = String(object.extraLoginFields[keys[i]]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreatePayrollConnectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.CreatePayrollConnectionRequest} message CreatePayrollConnectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreatePayrollConnectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.extraLoginFields = {};
                    if (options.defaults) {
                        object.title = "";
                        object.employerName = "";
                        object.institutionToken = "";
                        object.encryptedUsername = null;
                        object.encryptedPassword = null;
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.employerName != null && message.hasOwnProperty("employerName"))
                        object.employerName = message.employerName;
                    if (message.institutionToken != null && message.hasOwnProperty("institutionToken"))
                        object.institutionToken = message.institutionToken;
                    if (message.encryptedUsername != null && message.hasOwnProperty("encryptedUsername"))
                        object.encryptedUsername = $root.visor.common.encryption.EncryptedItem.toObject(message.encryptedUsername, options);
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        object.encryptedPassword = $root.visor.common.encryption.EncryptedItem.toObject(message.encryptedPassword, options);
                    var keys2;
                    if (message.extraLoginFields && (keys2 = Object.keys(message.extraLoginFields)).length) {
                        object.extraLoginFields = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.extraLoginFields[keys2[j]] = message.extraLoginFields[keys2[j]];
                    }
                    return object;
                };

                /**
                 * Converts this CreatePayrollConnectionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreatePayrollConnectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreatePayrollConnectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreatePayrollConnectionRequest;
            })();

            clientrpc.CompleteWaitForBoostStepRequest = (function() {

                /**
                 * Properties of a CompleteWaitForBoostStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteWaitForBoostStepRequest
                 * @property {string|null} [stepToken] CompleteWaitForBoostStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteWaitForBoostStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteWaitForBoostStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteWaitForBoostStepRequest.
                 * @implements ICompleteWaitForBoostStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepRequest=} [properties] Properties to set
                 */
                function CompleteWaitForBoostStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteWaitForBoostStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @instance
                 */
                CompleteWaitForBoostStepRequest.prototype.stepToken = "";

                /**
                 * CompleteWaitForBoostStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @instance
                 */
                CompleteWaitForBoostStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteWaitForBoostStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepRequest} CompleteWaitForBoostStepRequest instance
                 */
                CompleteWaitForBoostStepRequest.create = function create(properties) {
                    return new CompleteWaitForBoostStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteWaitForBoostStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteWaitForBoostStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepRequest} message CompleteWaitForBoostStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteWaitForBoostStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteWaitForBoostStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteWaitForBoostStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepRequest} message CompleteWaitForBoostStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteWaitForBoostStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteWaitForBoostStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepRequest} CompleteWaitForBoostStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteWaitForBoostStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteWaitForBoostStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteWaitForBoostStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepRequest} CompleteWaitForBoostStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteWaitForBoostStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteWaitForBoostStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteWaitForBoostStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteWaitForBoostStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepRequest} CompleteWaitForBoostStepRequest
                 */
                CompleteWaitForBoostStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteWaitForBoostStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteWaitForBoostStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteWaitForBoostStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteWaitForBoostStepRequest} message CompleteWaitForBoostStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteWaitForBoostStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteWaitForBoostStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteWaitForBoostStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteWaitForBoostStepRequest;
            })();

            clientrpc.CompleteWaitForBoostStepResponse = (function() {

                /**
                 * Properties of a CompleteWaitForBoostStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteWaitForBoostStepResponse
                 */

                /**
                 * Constructs a new CompleteWaitForBoostStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteWaitForBoostStepResponse.
                 * @implements ICompleteWaitForBoostStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepResponse=} [properties] Properties to set
                 */
                function CompleteWaitForBoostStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteWaitForBoostStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepResponse} CompleteWaitForBoostStepResponse instance
                 */
                CompleteWaitForBoostStepResponse.create = function create(properties) {
                    return new CompleteWaitForBoostStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteWaitForBoostStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteWaitForBoostStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepResponse} message CompleteWaitForBoostStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteWaitForBoostStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteWaitForBoostStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteWaitForBoostStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteWaitForBoostStepResponse} message CompleteWaitForBoostStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteWaitForBoostStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteWaitForBoostStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepResponse} CompleteWaitForBoostStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteWaitForBoostStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteWaitForBoostStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteWaitForBoostStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepResponse} CompleteWaitForBoostStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteWaitForBoostStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteWaitForBoostStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteWaitForBoostStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteWaitForBoostStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteWaitForBoostStepResponse} CompleteWaitForBoostStepResponse
                 */
                CompleteWaitForBoostStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteWaitForBoostStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteWaitForBoostStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteWaitForBoostStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteWaitForBoostStepResponse} message CompleteWaitForBoostStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteWaitForBoostStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteWaitForBoostStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteWaitForBoostStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteWaitForBoostStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteWaitForBoostStepResponse;
            })();

            clientrpc.CreatePayrollConnectionResponse = (function() {

                /**
                 * Properties of a CreatePayrollConnectionResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreatePayrollConnectionResponse
                 * @property {string|null} [connectionToken] CreatePayrollConnectionResponse connectionToken
                 */

                /**
                 * Constructs a new CreatePayrollConnectionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreatePayrollConnectionResponse.
                 * @implements ICreatePayrollConnectionResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreatePayrollConnectionResponse=} [properties] Properties to set
                 */
                function CreatePayrollConnectionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreatePayrollConnectionResponse connectionToken.
                 * @member {string} connectionToken
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @instance
                 */
                CreatePayrollConnectionResponse.prototype.connectionToken = "";

                /**
                 * Creates a new CreatePayrollConnectionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.ICreatePayrollConnectionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreatePayrollConnectionResponse} CreatePayrollConnectionResponse instance
                 */
                CreatePayrollConnectionResponse.create = function create(properties) {
                    return new CreatePayrollConnectionResponse(properties);
                };

                /**
                 * Encodes the specified CreatePayrollConnectionResponse message. Does not implicitly {@link visor.clientrpc.CreatePayrollConnectionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.ICreatePayrollConnectionResponse} message CreatePayrollConnectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreatePayrollConnectionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.connectionToken);
                    return writer;
                };

                /**
                 * Encodes the specified CreatePayrollConnectionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreatePayrollConnectionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.ICreatePayrollConnectionResponse} message CreatePayrollConnectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreatePayrollConnectionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreatePayrollConnectionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreatePayrollConnectionResponse} CreatePayrollConnectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreatePayrollConnectionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreatePayrollConnectionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.connectionToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreatePayrollConnectionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreatePayrollConnectionResponse} CreatePayrollConnectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreatePayrollConnectionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreatePayrollConnectionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreatePayrollConnectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        if (!$util.isString(message.connectionToken))
                            return "connectionToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreatePayrollConnectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreatePayrollConnectionResponse} CreatePayrollConnectionResponse
                 */
                CreatePayrollConnectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreatePayrollConnectionResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreatePayrollConnectionResponse();
                    if (object.connectionToken != null)
                        message.connectionToken = String(object.connectionToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreatePayrollConnectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.CreatePayrollConnectionResponse} message CreatePayrollConnectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreatePayrollConnectionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.connectionToken = "";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        object.connectionToken = message.connectionToken;
                    return object;
                };

                /**
                 * Converts this CreatePayrollConnectionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreatePayrollConnectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreatePayrollConnectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreatePayrollConnectionResponse;
            })();

            clientrpc.UpdatePayrollConnectionRequest = (function() {

                /**
                 * Properties of an UpdatePayrollConnectionRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdatePayrollConnectionRequest
                 * @property {string|null} [connectionToken] UpdatePayrollConnectionRequest connectionToken
                 * @property {string|null} [employerName] UpdatePayrollConnectionRequest employerName
                 * @property {string|null} [institutionToken] UpdatePayrollConnectionRequest institutionToken
                 * @property {visor.common.encryption.IEncryptedItem|null} [encryptedUsername] UpdatePayrollConnectionRequest encryptedUsername
                 * @property {visor.common.encryption.IEncryptedItem|null} [encryptedPassword] UpdatePayrollConnectionRequest encryptedPassword
                 * @property {Object.<string,string>|null} [extraLoginFields] UpdatePayrollConnectionRequest extraLoginFields
                 */

                /**
                 * Constructs a new UpdatePayrollConnectionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdatePayrollConnectionRequest.
                 * @implements IUpdatePayrollConnectionRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdatePayrollConnectionRequest=} [properties] Properties to set
                 */
                function UpdatePayrollConnectionRequest(properties) {
                    this.extraLoginFields = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdatePayrollConnectionRequest connectionToken.
                 * @member {string} connectionToken
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @instance
                 */
                UpdatePayrollConnectionRequest.prototype.connectionToken = "";

                /**
                 * UpdatePayrollConnectionRequest employerName.
                 * @member {string} employerName
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @instance
                 */
                UpdatePayrollConnectionRequest.prototype.employerName = "";

                /**
                 * UpdatePayrollConnectionRequest institutionToken.
                 * @member {string} institutionToken
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @instance
                 */
                UpdatePayrollConnectionRequest.prototype.institutionToken = "";

                /**
                 * UpdatePayrollConnectionRequest encryptedUsername.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} encryptedUsername
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @instance
                 */
                UpdatePayrollConnectionRequest.prototype.encryptedUsername = null;

                /**
                 * UpdatePayrollConnectionRequest encryptedPassword.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} encryptedPassword
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @instance
                 */
                UpdatePayrollConnectionRequest.prototype.encryptedPassword = null;

                /**
                 * UpdatePayrollConnectionRequest extraLoginFields.
                 * @member {Object.<string,string>} extraLoginFields
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @instance
                 */
                UpdatePayrollConnectionRequest.prototype.extraLoginFields = $util.emptyObject;

                /**
                 * Creates a new UpdatePayrollConnectionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.IUpdatePayrollConnectionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdatePayrollConnectionRequest} UpdatePayrollConnectionRequest instance
                 */
                UpdatePayrollConnectionRequest.create = function create(properties) {
                    return new UpdatePayrollConnectionRequest(properties);
                };

                /**
                 * Encodes the specified UpdatePayrollConnectionRequest message. Does not implicitly {@link visor.clientrpc.UpdatePayrollConnectionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.IUpdatePayrollConnectionRequest} message UpdatePayrollConnectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdatePayrollConnectionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.connectionToken);
                    if (message.employerName != null && message.hasOwnProperty("employerName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.employerName);
                    if (message.institutionToken != null && message.hasOwnProperty("institutionToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.institutionToken);
                    if (message.encryptedUsername != null && message.hasOwnProperty("encryptedUsername"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.encryptedUsername, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.encryptedPassword, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.extraLoginFields != null && message.hasOwnProperty("extraLoginFields"))
                        for (var keys = Object.keys(message.extraLoginFields), i = 0; i < keys.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.extraLoginFields[keys[i]]).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UpdatePayrollConnectionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdatePayrollConnectionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.IUpdatePayrollConnectionRequest} message UpdatePayrollConnectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdatePayrollConnectionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdatePayrollConnectionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdatePayrollConnectionRequest} UpdatePayrollConnectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdatePayrollConnectionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdatePayrollConnectionRequest(), key;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.connectionToken = reader.string();
                            break;
                        case 2:
                            message.employerName = reader.string();
                            break;
                        case 3:
                            message.institutionToken = reader.string();
                            break;
                        case 4:
                            message.encryptedUsername = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.encryptedPassword = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 6:
                            reader.skip().pos++;
                            if (message.extraLoginFields === $util.emptyObject)
                                message.extraLoginFields = {};
                            key = reader.string();
                            reader.pos++;
                            message.extraLoginFields[key] = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdatePayrollConnectionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdatePayrollConnectionRequest} UpdatePayrollConnectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdatePayrollConnectionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdatePayrollConnectionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdatePayrollConnectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        if (!$util.isString(message.connectionToken))
                            return "connectionToken: string expected";
                    if (message.employerName != null && message.hasOwnProperty("employerName"))
                        if (!$util.isString(message.employerName))
                            return "employerName: string expected";
                    if (message.institutionToken != null && message.hasOwnProperty("institutionToken"))
                        if (!$util.isString(message.institutionToken))
                            return "institutionToken: string expected";
                    if (message.encryptedUsername != null && message.hasOwnProperty("encryptedUsername")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.encryptedUsername);
                        if (error)
                            return "encryptedUsername." + error;
                    }
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.encryptedPassword);
                        if (error)
                            return "encryptedPassword." + error;
                    }
                    if (message.extraLoginFields != null && message.hasOwnProperty("extraLoginFields")) {
                        if (!$util.isObject(message.extraLoginFields))
                            return "extraLoginFields: object expected";
                        var key = Object.keys(message.extraLoginFields);
                        for (var i = 0; i < key.length; ++i)
                            if (!$util.isString(message.extraLoginFields[key[i]]))
                                return "extraLoginFields: string{k:string} expected";
                    }
                    return null;
                };

                /**
                 * Creates an UpdatePayrollConnectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdatePayrollConnectionRequest} UpdatePayrollConnectionRequest
                 */
                UpdatePayrollConnectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdatePayrollConnectionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdatePayrollConnectionRequest();
                    if (object.connectionToken != null)
                        message.connectionToken = String(object.connectionToken);
                    if (object.employerName != null)
                        message.employerName = String(object.employerName);
                    if (object.institutionToken != null)
                        message.institutionToken = String(object.institutionToken);
                    if (object.encryptedUsername != null) {
                        if (typeof object.encryptedUsername !== "object")
                            throw TypeError(".visor.clientrpc.UpdatePayrollConnectionRequest.encryptedUsername: object expected");
                        message.encryptedUsername = $root.visor.common.encryption.EncryptedItem.fromObject(object.encryptedUsername);
                    }
                    if (object.encryptedPassword != null) {
                        if (typeof object.encryptedPassword !== "object")
                            throw TypeError(".visor.clientrpc.UpdatePayrollConnectionRequest.encryptedPassword: object expected");
                        message.encryptedPassword = $root.visor.common.encryption.EncryptedItem.fromObject(object.encryptedPassword);
                    }
                    if (object.extraLoginFields) {
                        if (typeof object.extraLoginFields !== "object")
                            throw TypeError(".visor.clientrpc.UpdatePayrollConnectionRequest.extraLoginFields: object expected");
                        message.extraLoginFields = {};
                        for (var keys = Object.keys(object.extraLoginFields), i = 0; i < keys.length; ++i)
                            message.extraLoginFields[keys[i]] = String(object.extraLoginFields[keys[i]]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdatePayrollConnectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.UpdatePayrollConnectionRequest} message UpdatePayrollConnectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdatePayrollConnectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.extraLoginFields = {};
                    if (options.defaults) {
                        object.connectionToken = "";
                        object.employerName = "";
                        object.institutionToken = "";
                        object.encryptedUsername = null;
                        object.encryptedPassword = null;
                    }
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        object.connectionToken = message.connectionToken;
                    if (message.employerName != null && message.hasOwnProperty("employerName"))
                        object.employerName = message.employerName;
                    if (message.institutionToken != null && message.hasOwnProperty("institutionToken"))
                        object.institutionToken = message.institutionToken;
                    if (message.encryptedUsername != null && message.hasOwnProperty("encryptedUsername"))
                        object.encryptedUsername = $root.visor.common.encryption.EncryptedItem.toObject(message.encryptedUsername, options);
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        object.encryptedPassword = $root.visor.common.encryption.EncryptedItem.toObject(message.encryptedPassword, options);
                    var keys2;
                    if (message.extraLoginFields && (keys2 = Object.keys(message.extraLoginFields)).length) {
                        object.extraLoginFields = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.extraLoginFields[keys2[j]] = message.extraLoginFields[keys2[j]];
                    }
                    return object;
                };

                /**
                 * Converts this UpdatePayrollConnectionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdatePayrollConnectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdatePayrollConnectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdatePayrollConnectionRequest;
            })();

            clientrpc.UpdatePayrollConnectionResponse = (function() {

                /**
                 * Properties of an UpdatePayrollConnectionResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdatePayrollConnectionResponse
                 * @property {string|null} [connectionToken] UpdatePayrollConnectionResponse connectionToken
                 */

                /**
                 * Constructs a new UpdatePayrollConnectionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdatePayrollConnectionResponse.
                 * @implements IUpdatePayrollConnectionResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdatePayrollConnectionResponse=} [properties] Properties to set
                 */
                function UpdatePayrollConnectionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdatePayrollConnectionResponse connectionToken.
                 * @member {string} connectionToken
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @instance
                 */
                UpdatePayrollConnectionResponse.prototype.connectionToken = "";

                /**
                 * Creates a new UpdatePayrollConnectionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.IUpdatePayrollConnectionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdatePayrollConnectionResponse} UpdatePayrollConnectionResponse instance
                 */
                UpdatePayrollConnectionResponse.create = function create(properties) {
                    return new UpdatePayrollConnectionResponse(properties);
                };

                /**
                 * Encodes the specified UpdatePayrollConnectionResponse message. Does not implicitly {@link visor.clientrpc.UpdatePayrollConnectionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.IUpdatePayrollConnectionResponse} message UpdatePayrollConnectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdatePayrollConnectionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.connectionToken);
                    return writer;
                };

                /**
                 * Encodes the specified UpdatePayrollConnectionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdatePayrollConnectionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.IUpdatePayrollConnectionResponse} message UpdatePayrollConnectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdatePayrollConnectionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdatePayrollConnectionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdatePayrollConnectionResponse} UpdatePayrollConnectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdatePayrollConnectionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdatePayrollConnectionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.connectionToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdatePayrollConnectionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdatePayrollConnectionResponse} UpdatePayrollConnectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdatePayrollConnectionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdatePayrollConnectionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdatePayrollConnectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        if (!$util.isString(message.connectionToken))
                            return "connectionToken: string expected";
                    return null;
                };

                /**
                 * Creates an UpdatePayrollConnectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdatePayrollConnectionResponse} UpdatePayrollConnectionResponse
                 */
                UpdatePayrollConnectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdatePayrollConnectionResponse)
                        return object;
                    var message = new $root.visor.clientrpc.UpdatePayrollConnectionResponse();
                    if (object.connectionToken != null)
                        message.connectionToken = String(object.connectionToken);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdatePayrollConnectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.UpdatePayrollConnectionResponse} message UpdatePayrollConnectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdatePayrollConnectionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.connectionToken = "";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        object.connectionToken = message.connectionToken;
                    return object;
                };

                /**
                 * Converts this UpdatePayrollConnectionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdatePayrollConnectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdatePayrollConnectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdatePayrollConnectionResponse;
            })();

            clientrpc.RemovePayrollConnectionRequest = (function() {

                /**
                 * Properties of a RemovePayrollConnectionRequest.
                 * @memberof visor.clientrpc
                 * @interface IRemovePayrollConnectionRequest
                 * @property {string|null} [connectionToken] RemovePayrollConnectionRequest connectionToken
                 */

                /**
                 * Constructs a new RemovePayrollConnectionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a RemovePayrollConnectionRequest.
                 * @implements IRemovePayrollConnectionRequest
                 * @constructor
                 * @param {visor.clientrpc.IRemovePayrollConnectionRequest=} [properties] Properties to set
                 */
                function RemovePayrollConnectionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RemovePayrollConnectionRequest connectionToken.
                 * @member {string} connectionToken
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @instance
                 */
                RemovePayrollConnectionRequest.prototype.connectionToken = "";

                /**
                 * Creates a new RemovePayrollConnectionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.IRemovePayrollConnectionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.RemovePayrollConnectionRequest} RemovePayrollConnectionRequest instance
                 */
                RemovePayrollConnectionRequest.create = function create(properties) {
                    return new RemovePayrollConnectionRequest(properties);
                };

                /**
                 * Encodes the specified RemovePayrollConnectionRequest message. Does not implicitly {@link visor.clientrpc.RemovePayrollConnectionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.IRemovePayrollConnectionRequest} message RemovePayrollConnectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePayrollConnectionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.connectionToken);
                    return writer;
                };

                /**
                 * Encodes the specified RemovePayrollConnectionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.RemovePayrollConnectionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.IRemovePayrollConnectionRequest} message RemovePayrollConnectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePayrollConnectionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RemovePayrollConnectionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.RemovePayrollConnectionRequest} RemovePayrollConnectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePayrollConnectionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RemovePayrollConnectionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.connectionToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RemovePayrollConnectionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.RemovePayrollConnectionRequest} RemovePayrollConnectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePayrollConnectionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RemovePayrollConnectionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemovePayrollConnectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        if (!$util.isString(message.connectionToken))
                            return "connectionToken: string expected";
                    return null;
                };

                /**
                 * Creates a RemovePayrollConnectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.RemovePayrollConnectionRequest} RemovePayrollConnectionRequest
                 */
                RemovePayrollConnectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.RemovePayrollConnectionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.RemovePayrollConnectionRequest();
                    if (object.connectionToken != null)
                        message.connectionToken = String(object.connectionToken);
                    return message;
                };

                /**
                 * Creates a plain object from a RemovePayrollConnectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @static
                 * @param {visor.clientrpc.RemovePayrollConnectionRequest} message RemovePayrollConnectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemovePayrollConnectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.connectionToken = "";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        object.connectionToken = message.connectionToken;
                    return object;
                };

                /**
                 * Converts this RemovePayrollConnectionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.RemovePayrollConnectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemovePayrollConnectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RemovePayrollConnectionRequest;
            })();

            clientrpc.RemovePayrollConnectionResponse = (function() {

                /**
                 * Properties of a RemovePayrollConnectionResponse.
                 * @memberof visor.clientrpc
                 * @interface IRemovePayrollConnectionResponse
                 */

                /**
                 * Constructs a new RemovePayrollConnectionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a RemovePayrollConnectionResponse.
                 * @implements IRemovePayrollConnectionResponse
                 * @constructor
                 * @param {visor.clientrpc.IRemovePayrollConnectionResponse=} [properties] Properties to set
                 */
                function RemovePayrollConnectionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new RemovePayrollConnectionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.IRemovePayrollConnectionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.RemovePayrollConnectionResponse} RemovePayrollConnectionResponse instance
                 */
                RemovePayrollConnectionResponse.create = function create(properties) {
                    return new RemovePayrollConnectionResponse(properties);
                };

                /**
                 * Encodes the specified RemovePayrollConnectionResponse message. Does not implicitly {@link visor.clientrpc.RemovePayrollConnectionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.IRemovePayrollConnectionResponse} message RemovePayrollConnectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePayrollConnectionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified RemovePayrollConnectionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.RemovePayrollConnectionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.IRemovePayrollConnectionResponse} message RemovePayrollConnectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RemovePayrollConnectionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RemovePayrollConnectionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.RemovePayrollConnectionResponse} RemovePayrollConnectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePayrollConnectionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RemovePayrollConnectionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RemovePayrollConnectionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.RemovePayrollConnectionResponse} RemovePayrollConnectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RemovePayrollConnectionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RemovePayrollConnectionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RemovePayrollConnectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a RemovePayrollConnectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.RemovePayrollConnectionResponse} RemovePayrollConnectionResponse
                 */
                RemovePayrollConnectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.RemovePayrollConnectionResponse)
                        return object;
                    return new $root.visor.clientrpc.RemovePayrollConnectionResponse();
                };

                /**
                 * Creates a plain object from a RemovePayrollConnectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @static
                 * @param {visor.clientrpc.RemovePayrollConnectionResponse} message RemovePayrollConnectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RemovePayrollConnectionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this RemovePayrollConnectionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.RemovePayrollConnectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RemovePayrollConnectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RemovePayrollConnectionResponse;
            })();

            clientrpc.SubmitMFAAnswerRequest = (function() {

                /**
                 * Properties of a SubmitMFAAnswerRequest.
                 * @memberof visor.clientrpc
                 * @interface ISubmitMFAAnswerRequest
                 * @property {string|null} [mfaChallengeToken] SubmitMFAAnswerRequest mfaChallengeToken
                 * @property {string|null} [mfaAnswer] SubmitMFAAnswerRequest mfaAnswer
                 * @property {string|null} [connectionToken] SubmitMFAAnswerRequest connectionToken
                 */

                /**
                 * Constructs a new SubmitMFAAnswerRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SubmitMFAAnswerRequest.
                 * @implements ISubmitMFAAnswerRequest
                 * @constructor
                 * @param {visor.clientrpc.ISubmitMFAAnswerRequest=} [properties] Properties to set
                 */
                function SubmitMFAAnswerRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SubmitMFAAnswerRequest mfaChallengeToken.
                 * @member {string} mfaChallengeToken
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @instance
                 */
                SubmitMFAAnswerRequest.prototype.mfaChallengeToken = "";

                /**
                 * SubmitMFAAnswerRequest mfaAnswer.
                 * @member {string} mfaAnswer
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @instance
                 */
                SubmitMFAAnswerRequest.prototype.mfaAnswer = "";

                /**
                 * SubmitMFAAnswerRequest connectionToken.
                 * @member {string} connectionToken
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @instance
                 */
                SubmitMFAAnswerRequest.prototype.connectionToken = "";

                /**
                 * Creates a new SubmitMFAAnswerRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {visor.clientrpc.ISubmitMFAAnswerRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SubmitMFAAnswerRequest} SubmitMFAAnswerRequest instance
                 */
                SubmitMFAAnswerRequest.create = function create(properties) {
                    return new SubmitMFAAnswerRequest(properties);
                };

                /**
                 * Encodes the specified SubmitMFAAnswerRequest message. Does not implicitly {@link visor.clientrpc.SubmitMFAAnswerRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {visor.clientrpc.ISubmitMFAAnswerRequest} message SubmitMFAAnswerRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitMFAAnswerRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.mfaChallengeToken != null && message.hasOwnProperty("mfaChallengeToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.mfaChallengeToken);
                    if (message.mfaAnswer != null && message.hasOwnProperty("mfaAnswer"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.mfaAnswer);
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.connectionToken);
                    return writer;
                };

                /**
                 * Encodes the specified SubmitMFAAnswerRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SubmitMFAAnswerRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {visor.clientrpc.ISubmitMFAAnswerRequest} message SubmitMFAAnswerRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitMFAAnswerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SubmitMFAAnswerRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SubmitMFAAnswerRequest} SubmitMFAAnswerRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitMFAAnswerRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SubmitMFAAnswerRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.mfaChallengeToken = reader.string();
                            break;
                        case 2:
                            message.mfaAnswer = reader.string();
                            break;
                        case 3:
                            message.connectionToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SubmitMFAAnswerRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SubmitMFAAnswerRequest} SubmitMFAAnswerRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitMFAAnswerRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SubmitMFAAnswerRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SubmitMFAAnswerRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.mfaChallengeToken != null && message.hasOwnProperty("mfaChallengeToken"))
                        if (!$util.isString(message.mfaChallengeToken))
                            return "mfaChallengeToken: string expected";
                    if (message.mfaAnswer != null && message.hasOwnProperty("mfaAnswer"))
                        if (!$util.isString(message.mfaAnswer))
                            return "mfaAnswer: string expected";
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        if (!$util.isString(message.connectionToken))
                            return "connectionToken: string expected";
                    return null;
                };

                /**
                 * Creates a SubmitMFAAnswerRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SubmitMFAAnswerRequest} SubmitMFAAnswerRequest
                 */
                SubmitMFAAnswerRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SubmitMFAAnswerRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SubmitMFAAnswerRequest();
                    if (object.mfaChallengeToken != null)
                        message.mfaChallengeToken = String(object.mfaChallengeToken);
                    if (object.mfaAnswer != null)
                        message.mfaAnswer = String(object.mfaAnswer);
                    if (object.connectionToken != null)
                        message.connectionToken = String(object.connectionToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SubmitMFAAnswerRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @static
                 * @param {visor.clientrpc.SubmitMFAAnswerRequest} message SubmitMFAAnswerRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SubmitMFAAnswerRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.mfaChallengeToken = "";
                        object.mfaAnswer = "";
                        object.connectionToken = "";
                    }
                    if (message.mfaChallengeToken != null && message.hasOwnProperty("mfaChallengeToken"))
                        object.mfaChallengeToken = message.mfaChallengeToken;
                    if (message.mfaAnswer != null && message.hasOwnProperty("mfaAnswer"))
                        object.mfaAnswer = message.mfaAnswer;
                    if (message.connectionToken != null && message.hasOwnProperty("connectionToken"))
                        object.connectionToken = message.connectionToken;
                    return object;
                };

                /**
                 * Converts this SubmitMFAAnswerRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SubmitMFAAnswerRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SubmitMFAAnswerRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SubmitMFAAnswerRequest;
            })();

            clientrpc.SubmitMFAAnswerResponse = (function() {

                /**
                 * Properties of a SubmitMFAAnswerResponse.
                 * @memberof visor.clientrpc
                 * @interface ISubmitMFAAnswerResponse
                 * @property {string|null} [retryReason] SubmitMFAAnswerResponse retryReason
                 */

                /**
                 * Constructs a new SubmitMFAAnswerResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SubmitMFAAnswerResponse.
                 * @implements ISubmitMFAAnswerResponse
                 * @constructor
                 * @param {visor.clientrpc.ISubmitMFAAnswerResponse=} [properties] Properties to set
                 */
                function SubmitMFAAnswerResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SubmitMFAAnswerResponse retryReason.
                 * @member {string} retryReason
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @instance
                 */
                SubmitMFAAnswerResponse.prototype.retryReason = "";

                /**
                 * Creates a new SubmitMFAAnswerResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {visor.clientrpc.ISubmitMFAAnswerResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SubmitMFAAnswerResponse} SubmitMFAAnswerResponse instance
                 */
                SubmitMFAAnswerResponse.create = function create(properties) {
                    return new SubmitMFAAnswerResponse(properties);
                };

                /**
                 * Encodes the specified SubmitMFAAnswerResponse message. Does not implicitly {@link visor.clientrpc.SubmitMFAAnswerResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {visor.clientrpc.ISubmitMFAAnswerResponse} message SubmitMFAAnswerResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitMFAAnswerResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.retryReason != null && message.hasOwnProperty("retryReason"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.retryReason);
                    return writer;
                };

                /**
                 * Encodes the specified SubmitMFAAnswerResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SubmitMFAAnswerResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {visor.clientrpc.ISubmitMFAAnswerResponse} message SubmitMFAAnswerResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitMFAAnswerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SubmitMFAAnswerResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SubmitMFAAnswerResponse} SubmitMFAAnswerResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitMFAAnswerResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SubmitMFAAnswerResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.retryReason = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SubmitMFAAnswerResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SubmitMFAAnswerResponse} SubmitMFAAnswerResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitMFAAnswerResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SubmitMFAAnswerResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SubmitMFAAnswerResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.retryReason != null && message.hasOwnProperty("retryReason"))
                        if (!$util.isString(message.retryReason))
                            return "retryReason: string expected";
                    return null;
                };

                /**
                 * Creates a SubmitMFAAnswerResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SubmitMFAAnswerResponse} SubmitMFAAnswerResponse
                 */
                SubmitMFAAnswerResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SubmitMFAAnswerResponse)
                        return object;
                    var message = new $root.visor.clientrpc.SubmitMFAAnswerResponse();
                    if (object.retryReason != null)
                        message.retryReason = String(object.retryReason);
                    return message;
                };

                /**
                 * Creates a plain object from a SubmitMFAAnswerResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @static
                 * @param {visor.clientrpc.SubmitMFAAnswerResponse} message SubmitMFAAnswerResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SubmitMFAAnswerResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.retryReason = "";
                    if (message.retryReason != null && message.hasOwnProperty("retryReason"))
                        object.retryReason = message.retryReason;
                    return object;
                };

                /**
                 * Converts this SubmitMFAAnswerResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SubmitMFAAnswerResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SubmitMFAAnswerResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SubmitMFAAnswerResponse;
            })();

            clientrpc.GetClientUploadURLRequest = (function() {

                /**
                 * Properties of a GetClientUploadURLRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetClientUploadURLRequest
                 * @property {string|null} [filename] GetClientUploadURLRequest filename
                 * @property {string|null} [mimeType] GetClientUploadURLRequest mimeType
                 */

                /**
                 * Constructs a new GetClientUploadURLRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetClientUploadURLRequest.
                 * @implements IGetClientUploadURLRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetClientUploadURLRequest=} [properties] Properties to set
                 */
                function GetClientUploadURLRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetClientUploadURLRequest filename.
                 * @member {string} filename
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @instance
                 */
                GetClientUploadURLRequest.prototype.filename = "";

                /**
                 * GetClientUploadURLRequest mimeType.
                 * @member {string} mimeType
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @instance
                 */
                GetClientUploadURLRequest.prototype.mimeType = "";

                /**
                 * Creates a new GetClientUploadURLRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {visor.clientrpc.IGetClientUploadURLRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetClientUploadURLRequest} GetClientUploadURLRequest instance
                 */
                GetClientUploadURLRequest.create = function create(properties) {
                    return new GetClientUploadURLRequest(properties);
                };

                /**
                 * Encodes the specified GetClientUploadURLRequest message. Does not implicitly {@link visor.clientrpc.GetClientUploadURLRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {visor.clientrpc.IGetClientUploadURLRequest} message GetClientUploadURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClientUploadURLRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.filename != null && message.hasOwnProperty("filename"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.filename);
                    if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.mimeType);
                    return writer;
                };

                /**
                 * Encodes the specified GetClientUploadURLRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetClientUploadURLRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {visor.clientrpc.IGetClientUploadURLRequest} message GetClientUploadURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClientUploadURLRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetClientUploadURLRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetClientUploadURLRequest} GetClientUploadURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClientUploadURLRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetClientUploadURLRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.filename = reader.string();
                            break;
                        case 2:
                            message.mimeType = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetClientUploadURLRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetClientUploadURLRequest} GetClientUploadURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClientUploadURLRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetClientUploadURLRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetClientUploadURLRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.filename != null && message.hasOwnProperty("filename"))
                        if (!$util.isString(message.filename))
                            return "filename: string expected";
                    if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                        if (!$util.isString(message.mimeType))
                            return "mimeType: string expected";
                    return null;
                };

                /**
                 * Creates a GetClientUploadURLRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetClientUploadURLRequest} GetClientUploadURLRequest
                 */
                GetClientUploadURLRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetClientUploadURLRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetClientUploadURLRequest();
                    if (object.filename != null)
                        message.filename = String(object.filename);
                    if (object.mimeType != null)
                        message.mimeType = String(object.mimeType);
                    return message;
                };

                /**
                 * Creates a plain object from a GetClientUploadURLRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @static
                 * @param {visor.clientrpc.GetClientUploadURLRequest} message GetClientUploadURLRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetClientUploadURLRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.filename = "";
                        object.mimeType = "";
                    }
                    if (message.filename != null && message.hasOwnProperty("filename"))
                        object.filename = message.filename;
                    if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                        object.mimeType = message.mimeType;
                    return object;
                };

                /**
                 * Converts this GetClientUploadURLRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetClientUploadURLRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetClientUploadURLRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetClientUploadURLRequest;
            })();

            clientrpc.GetClientUploadURLResponse = (function() {

                /**
                 * Properties of a GetClientUploadURLResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetClientUploadURLResponse
                 * @property {string|null} [signedUploadUrl] GetClientUploadURLResponse signedUploadUrl
                 * @property {string|null} [fileToken] GetClientUploadURLResponse fileToken
                 */

                /**
                 * Constructs a new GetClientUploadURLResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetClientUploadURLResponse.
                 * @implements IGetClientUploadURLResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetClientUploadURLResponse=} [properties] Properties to set
                 */
                function GetClientUploadURLResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetClientUploadURLResponse signedUploadUrl.
                 * @member {string} signedUploadUrl
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @instance
                 */
                GetClientUploadURLResponse.prototype.signedUploadUrl = "";

                /**
                 * GetClientUploadURLResponse fileToken.
                 * @member {string} fileToken
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @instance
                 */
                GetClientUploadURLResponse.prototype.fileToken = "";

                /**
                 * Creates a new GetClientUploadURLResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {visor.clientrpc.IGetClientUploadURLResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetClientUploadURLResponse} GetClientUploadURLResponse instance
                 */
                GetClientUploadURLResponse.create = function create(properties) {
                    return new GetClientUploadURLResponse(properties);
                };

                /**
                 * Encodes the specified GetClientUploadURLResponse message. Does not implicitly {@link visor.clientrpc.GetClientUploadURLResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {visor.clientrpc.IGetClientUploadURLResponse} message GetClientUploadURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClientUploadURLResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.signedUploadUrl != null && message.hasOwnProperty("signedUploadUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.signedUploadUrl);
                    if (message.fileToken != null && message.hasOwnProperty("fileToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetClientUploadURLResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetClientUploadURLResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {visor.clientrpc.IGetClientUploadURLResponse} message GetClientUploadURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetClientUploadURLResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetClientUploadURLResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetClientUploadURLResponse} GetClientUploadURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClientUploadURLResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetClientUploadURLResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.signedUploadUrl = reader.string();
                            break;
                        case 2:
                            message.fileToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetClientUploadURLResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetClientUploadURLResponse} GetClientUploadURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetClientUploadURLResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetClientUploadURLResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetClientUploadURLResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.signedUploadUrl != null && message.hasOwnProperty("signedUploadUrl"))
                        if (!$util.isString(message.signedUploadUrl))
                            return "signedUploadUrl: string expected";
                    if (message.fileToken != null && message.hasOwnProperty("fileToken"))
                        if (!$util.isString(message.fileToken))
                            return "fileToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetClientUploadURLResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetClientUploadURLResponse} GetClientUploadURLResponse
                 */
                GetClientUploadURLResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetClientUploadURLResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetClientUploadURLResponse();
                    if (object.signedUploadUrl != null)
                        message.signedUploadUrl = String(object.signedUploadUrl);
                    if (object.fileToken != null)
                        message.fileToken = String(object.fileToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetClientUploadURLResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @static
                 * @param {visor.clientrpc.GetClientUploadURLResponse} message GetClientUploadURLResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetClientUploadURLResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.signedUploadUrl = "";
                        object.fileToken = "";
                    }
                    if (message.signedUploadUrl != null && message.hasOwnProperty("signedUploadUrl"))
                        object.signedUploadUrl = message.signedUploadUrl;
                    if (message.fileToken != null && message.hasOwnProperty("fileToken"))
                        object.fileToken = message.fileToken;
                    return object;
                };

                /**
                 * Converts this GetClientUploadURLResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetClientUploadURLResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetClientUploadURLResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetClientUploadURLResponse;
            })();

            clientrpc.FulfillDocumentRequestRequest = (function() {

                /**
                 * Properties of a FulfillDocumentRequestRequest.
                 * @memberof visor.clientrpc
                 * @interface IFulfillDocumentRequestRequest
                 * @property {Array.<string>|null} [fileTokens] FulfillDocumentRequestRequest fileTokens
                 * @property {string|null} [documentRequestToken] FulfillDocumentRequestRequest documentRequestToken
                 */

                /**
                 * Constructs a new FulfillDocumentRequestRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FulfillDocumentRequestRequest.
                 * @implements IFulfillDocumentRequestRequest
                 * @constructor
                 * @param {visor.clientrpc.IFulfillDocumentRequestRequest=} [properties] Properties to set
                 */
                function FulfillDocumentRequestRequest(properties) {
                    this.fileTokens = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FulfillDocumentRequestRequest fileTokens.
                 * @member {Array.<string>} fileTokens
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @instance
                 */
                FulfillDocumentRequestRequest.prototype.fileTokens = $util.emptyArray;

                /**
                 * FulfillDocumentRequestRequest documentRequestToken.
                 * @member {string} documentRequestToken
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @instance
                 */
                FulfillDocumentRequestRequest.prototype.documentRequestToken = "";

                /**
                 * Creates a new FulfillDocumentRequestRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentRequestRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.FulfillDocumentRequestRequest} FulfillDocumentRequestRequest instance
                 */
                FulfillDocumentRequestRequest.create = function create(properties) {
                    return new FulfillDocumentRequestRequest(properties);
                };

                /**
                 * Encodes the specified FulfillDocumentRequestRequest message. Does not implicitly {@link visor.clientrpc.FulfillDocumentRequestRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentRequestRequest} message FulfillDocumentRequestRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentRequestRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fileTokens != null && message.fileTokens.length)
                        for (var i = 0; i < message.fileTokens.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileTokens[i]);
                    if (message.documentRequestToken != null && message.hasOwnProperty("documentRequestToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.documentRequestToken);
                    return writer;
                };

                /**
                 * Encodes the specified FulfillDocumentRequestRequest message, length delimited. Does not implicitly {@link visor.clientrpc.FulfillDocumentRequestRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentRequestRequest} message FulfillDocumentRequestRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentRequestRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FulfillDocumentRequestRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FulfillDocumentRequestRequest} FulfillDocumentRequestRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentRequestRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FulfillDocumentRequestRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.fileTokens && message.fileTokens.length))
                                message.fileTokens = [];
                            message.fileTokens.push(reader.string());
                            break;
                        case 2:
                            message.documentRequestToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FulfillDocumentRequestRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FulfillDocumentRequestRequest} FulfillDocumentRequestRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentRequestRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FulfillDocumentRequestRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FulfillDocumentRequestRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fileTokens != null && message.hasOwnProperty("fileTokens")) {
                        if (!Array.isArray(message.fileTokens))
                            return "fileTokens: array expected";
                        for (var i = 0; i < message.fileTokens.length; ++i)
                            if (!$util.isString(message.fileTokens[i]))
                                return "fileTokens: string[] expected";
                    }
                    if (message.documentRequestToken != null && message.hasOwnProperty("documentRequestToken"))
                        if (!$util.isString(message.documentRequestToken))
                            return "documentRequestToken: string expected";
                    return null;
                };

                /**
                 * Creates a FulfillDocumentRequestRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FulfillDocumentRequestRequest} FulfillDocumentRequestRequest
                 */
                FulfillDocumentRequestRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FulfillDocumentRequestRequest)
                        return object;
                    var message = new $root.visor.clientrpc.FulfillDocumentRequestRequest();
                    if (object.fileTokens) {
                        if (!Array.isArray(object.fileTokens))
                            throw TypeError(".visor.clientrpc.FulfillDocumentRequestRequest.fileTokens: array expected");
                        message.fileTokens = [];
                        for (var i = 0; i < object.fileTokens.length; ++i)
                            message.fileTokens[i] = String(object.fileTokens[i]);
                    }
                    if (object.documentRequestToken != null)
                        message.documentRequestToken = String(object.documentRequestToken);
                    return message;
                };

                /**
                 * Creates a plain object from a FulfillDocumentRequestRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @static
                 * @param {visor.clientrpc.FulfillDocumentRequestRequest} message FulfillDocumentRequestRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FulfillDocumentRequestRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fileTokens = [];
                    if (options.defaults)
                        object.documentRequestToken = "";
                    if (message.fileTokens && message.fileTokens.length) {
                        object.fileTokens = [];
                        for (var j = 0; j < message.fileTokens.length; ++j)
                            object.fileTokens[j] = message.fileTokens[j];
                    }
                    if (message.documentRequestToken != null && message.hasOwnProperty("documentRequestToken"))
                        object.documentRequestToken = message.documentRequestToken;
                    return object;
                };

                /**
                 * Converts this FulfillDocumentRequestRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FulfillDocumentRequestRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FulfillDocumentRequestRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FulfillDocumentRequestRequest;
            })();

            clientrpc.FulfillDocumentRequestResponse = (function() {

                /**
                 * Properties of a FulfillDocumentRequestResponse.
                 * @memberof visor.clientrpc
                 * @interface IFulfillDocumentRequestResponse
                 */

                /**
                 * Constructs a new FulfillDocumentRequestResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FulfillDocumentRequestResponse.
                 * @implements IFulfillDocumentRequestResponse
                 * @constructor
                 * @param {visor.clientrpc.IFulfillDocumentRequestResponse=} [properties] Properties to set
                 */
                function FulfillDocumentRequestResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new FulfillDocumentRequestResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentRequestResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.FulfillDocumentRequestResponse} FulfillDocumentRequestResponse instance
                 */
                FulfillDocumentRequestResponse.create = function create(properties) {
                    return new FulfillDocumentRequestResponse(properties);
                };

                /**
                 * Encodes the specified FulfillDocumentRequestResponse message. Does not implicitly {@link visor.clientrpc.FulfillDocumentRequestResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentRequestResponse} message FulfillDocumentRequestResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentRequestResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified FulfillDocumentRequestResponse message, length delimited. Does not implicitly {@link visor.clientrpc.FulfillDocumentRequestResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {visor.clientrpc.IFulfillDocumentRequestResponse} message FulfillDocumentRequestResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FulfillDocumentRequestResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FulfillDocumentRequestResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FulfillDocumentRequestResponse} FulfillDocumentRequestResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentRequestResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FulfillDocumentRequestResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FulfillDocumentRequestResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FulfillDocumentRequestResponse} FulfillDocumentRequestResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FulfillDocumentRequestResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FulfillDocumentRequestResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FulfillDocumentRequestResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a FulfillDocumentRequestResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FulfillDocumentRequestResponse} FulfillDocumentRequestResponse
                 */
                FulfillDocumentRequestResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FulfillDocumentRequestResponse)
                        return object;
                    return new $root.visor.clientrpc.FulfillDocumentRequestResponse();
                };

                /**
                 * Creates a plain object from a FulfillDocumentRequestResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @static
                 * @param {visor.clientrpc.FulfillDocumentRequestResponse} message FulfillDocumentRequestResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FulfillDocumentRequestResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this FulfillDocumentRequestResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FulfillDocumentRequestResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FulfillDocumentRequestResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FulfillDocumentRequestResponse;
            })();

            clientrpc.GetDownloadURLRequest = (function() {

                /**
                 * Properties of a GetDownloadURLRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetDownloadURLRequest
                 * @property {string|null} [fileToken] GetDownloadURLRequest fileToken
                 */

                /**
                 * Constructs a new GetDownloadURLRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetDownloadURLRequest.
                 * @implements IGetDownloadURLRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetDownloadURLRequest=} [properties] Properties to set
                 */
                function GetDownloadURLRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetDownloadURLRequest fileToken.
                 * @member {string} fileToken
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @instance
                 */
                GetDownloadURLRequest.prototype.fileToken = "";

                /**
                 * Creates a new GetDownloadURLRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {visor.clientrpc.IGetDownloadURLRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetDownloadURLRequest} GetDownloadURLRequest instance
                 */
                GetDownloadURLRequest.create = function create(properties) {
                    return new GetDownloadURLRequest(properties);
                };

                /**
                 * Encodes the specified GetDownloadURLRequest message. Does not implicitly {@link visor.clientrpc.GetDownloadURLRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {visor.clientrpc.IGetDownloadURLRequest} message GetDownloadURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDownloadURLRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fileToken != null && message.hasOwnProperty("fileToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetDownloadURLRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetDownloadURLRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {visor.clientrpc.IGetDownloadURLRequest} message GetDownloadURLRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDownloadURLRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetDownloadURLRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetDownloadURLRequest} GetDownloadURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDownloadURLRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetDownloadURLRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.fileToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetDownloadURLRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetDownloadURLRequest} GetDownloadURLRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDownloadURLRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetDownloadURLRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDownloadURLRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fileToken != null && message.hasOwnProperty("fileToken"))
                        if (!$util.isString(message.fileToken))
                            return "fileToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetDownloadURLRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetDownloadURLRequest} GetDownloadURLRequest
                 */
                GetDownloadURLRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetDownloadURLRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetDownloadURLRequest();
                    if (object.fileToken != null)
                        message.fileToken = String(object.fileToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetDownloadURLRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @static
                 * @param {visor.clientrpc.GetDownloadURLRequest} message GetDownloadURLRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDownloadURLRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.fileToken = "";
                    if (message.fileToken != null && message.hasOwnProperty("fileToken"))
                        object.fileToken = message.fileToken;
                    return object;
                };

                /**
                 * Converts this GetDownloadURLRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetDownloadURLRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDownloadURLRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetDownloadURLRequest;
            })();

            clientrpc.GetDownloadURLResponse = (function() {

                /**
                 * Properties of a GetDownloadURLResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetDownloadURLResponse
                 * @property {string|null} [downloadUrl] GetDownloadURLResponse downloadUrl
                 * @property {google.protobuf.ITimestamp|null} [expiresAt] GetDownloadURLResponse expiresAt
                 */

                /**
                 * Constructs a new GetDownloadURLResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetDownloadURLResponse.
                 * @implements IGetDownloadURLResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetDownloadURLResponse=} [properties] Properties to set
                 */
                function GetDownloadURLResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetDownloadURLResponse downloadUrl.
                 * @member {string} downloadUrl
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @instance
                 */
                GetDownloadURLResponse.prototype.downloadUrl = "";

                /**
                 * GetDownloadURLResponse expiresAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @instance
                 */
                GetDownloadURLResponse.prototype.expiresAt = null;

                /**
                 * Creates a new GetDownloadURLResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {visor.clientrpc.IGetDownloadURLResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetDownloadURLResponse} GetDownloadURLResponse instance
                 */
                GetDownloadURLResponse.create = function create(properties) {
                    return new GetDownloadURLResponse(properties);
                };

                /**
                 * Encodes the specified GetDownloadURLResponse message. Does not implicitly {@link visor.clientrpc.GetDownloadURLResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {visor.clientrpc.IGetDownloadURLResponse} message GetDownloadURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDownloadURLResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.downloadUrl != null && message.hasOwnProperty("downloadUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.downloadUrl);
                    if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                        $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetDownloadURLResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetDownloadURLResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {visor.clientrpc.IGetDownloadURLResponse} message GetDownloadURLResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDownloadURLResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetDownloadURLResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetDownloadURLResponse} GetDownloadURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDownloadURLResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetDownloadURLResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.downloadUrl = reader.string();
                            break;
                        case 2:
                            message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetDownloadURLResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetDownloadURLResponse} GetDownloadURLResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDownloadURLResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetDownloadURLResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDownloadURLResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.downloadUrl != null && message.hasOwnProperty("downloadUrl"))
                        if (!$util.isString(message.downloadUrl))
                            return "downloadUrl: string expected";
                    if (message.expiresAt != null && message.hasOwnProperty("expiresAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.expiresAt);
                        if (error)
                            return "expiresAt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetDownloadURLResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetDownloadURLResponse} GetDownloadURLResponse
                 */
                GetDownloadURLResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetDownloadURLResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetDownloadURLResponse();
                    if (object.downloadUrl != null)
                        message.downloadUrl = String(object.downloadUrl);
                    if (object.expiresAt != null)
                        if (object.expiresAt === null)
                            message.expiresAt = {};
                        else
                            message.expiresAt = {
                                seconds: Math.floor(object.expiresAt.getTime() / 1000),
                                nanos: object.expiresAt.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a GetDownloadURLResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @static
                 * @param {visor.clientrpc.GetDownloadURLResponse} message GetDownloadURLResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDownloadURLResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.downloadUrl = "";
                        object.expiresAt = null;
                    }
                    if (message.downloadUrl != null && message.hasOwnProperty("downloadUrl"))
                        object.downloadUrl = message.downloadUrl;
                    if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                        object.expiresAt = new Date(message.expiresAt.seconds * 1000 + message.expiresAt.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this GetDownloadURLResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetDownloadURLResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDownloadURLResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetDownloadURLResponse;
            })();

            clientrpc.StartScenarioSelectionRequest = (function() {

                /**
                 * Properties of a StartScenarioSelectionRequest.
                 * @memberof visor.clientrpc
                 * @interface IStartScenarioSelectionRequest
                 * @property {string|null} [scenarioToken] StartScenarioSelectionRequest scenarioToken
                 */

                /**
                 * Constructs a new StartScenarioSelectionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a StartScenarioSelectionRequest.
                 * @implements IStartScenarioSelectionRequest
                 * @constructor
                 * @param {visor.clientrpc.IStartScenarioSelectionRequest=} [properties] Properties to set
                 */
                function StartScenarioSelectionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StartScenarioSelectionRequest scenarioToken.
                 * @member {string} scenarioToken
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @instance
                 */
                StartScenarioSelectionRequest.prototype.scenarioToken = "";

                /**
                 * Creates a new StartScenarioSelectionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {visor.clientrpc.IStartScenarioSelectionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.StartScenarioSelectionRequest} StartScenarioSelectionRequest instance
                 */
                StartScenarioSelectionRequest.create = function create(properties) {
                    return new StartScenarioSelectionRequest(properties);
                };

                /**
                 * Encodes the specified StartScenarioSelectionRequest message. Does not implicitly {@link visor.clientrpc.StartScenarioSelectionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {visor.clientrpc.IStartScenarioSelectionRequest} message StartScenarioSelectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartScenarioSelectionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scenarioToken);
                    return writer;
                };

                /**
                 * Encodes the specified StartScenarioSelectionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.StartScenarioSelectionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {visor.clientrpc.IStartScenarioSelectionRequest} message StartScenarioSelectionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartScenarioSelectionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StartScenarioSelectionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.StartScenarioSelectionRequest} StartScenarioSelectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartScenarioSelectionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.StartScenarioSelectionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.scenarioToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StartScenarioSelectionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.StartScenarioSelectionRequest} StartScenarioSelectionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartScenarioSelectionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StartScenarioSelectionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartScenarioSelectionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        if (!$util.isString(message.scenarioToken))
                            return "scenarioToken: string expected";
                    return null;
                };

                /**
                 * Creates a StartScenarioSelectionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.StartScenarioSelectionRequest} StartScenarioSelectionRequest
                 */
                StartScenarioSelectionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.StartScenarioSelectionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.StartScenarioSelectionRequest();
                    if (object.scenarioToken != null)
                        message.scenarioToken = String(object.scenarioToken);
                    return message;
                };

                /**
                 * Creates a plain object from a StartScenarioSelectionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @static
                 * @param {visor.clientrpc.StartScenarioSelectionRequest} message StartScenarioSelectionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartScenarioSelectionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.scenarioToken = "";
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        object.scenarioToken = message.scenarioToken;
                    return object;
                };

                /**
                 * Converts this StartScenarioSelectionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.StartScenarioSelectionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartScenarioSelectionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartScenarioSelectionRequest;
            })();

            clientrpc.StartScenarioSelectionResponse = (function() {

                /**
                 * Properties of a StartScenarioSelectionResponse.
                 * @memberof visor.clientrpc
                 * @interface IStartScenarioSelectionResponse
                 */

                /**
                 * Constructs a new StartScenarioSelectionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a StartScenarioSelectionResponse.
                 * @implements IStartScenarioSelectionResponse
                 * @constructor
                 * @param {visor.clientrpc.IStartScenarioSelectionResponse=} [properties] Properties to set
                 */
                function StartScenarioSelectionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new StartScenarioSelectionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {visor.clientrpc.IStartScenarioSelectionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.StartScenarioSelectionResponse} StartScenarioSelectionResponse instance
                 */
                StartScenarioSelectionResponse.create = function create(properties) {
                    return new StartScenarioSelectionResponse(properties);
                };

                /**
                 * Encodes the specified StartScenarioSelectionResponse message. Does not implicitly {@link visor.clientrpc.StartScenarioSelectionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {visor.clientrpc.IStartScenarioSelectionResponse} message StartScenarioSelectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartScenarioSelectionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified StartScenarioSelectionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.StartScenarioSelectionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {visor.clientrpc.IStartScenarioSelectionResponse} message StartScenarioSelectionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartScenarioSelectionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StartScenarioSelectionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.StartScenarioSelectionResponse} StartScenarioSelectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartScenarioSelectionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.StartScenarioSelectionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StartScenarioSelectionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.StartScenarioSelectionResponse} StartScenarioSelectionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartScenarioSelectionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StartScenarioSelectionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartScenarioSelectionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a StartScenarioSelectionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.StartScenarioSelectionResponse} StartScenarioSelectionResponse
                 */
                StartScenarioSelectionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.StartScenarioSelectionResponse)
                        return object;
                    return new $root.visor.clientrpc.StartScenarioSelectionResponse();
                };

                /**
                 * Creates a plain object from a StartScenarioSelectionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @static
                 * @param {visor.clientrpc.StartScenarioSelectionResponse} message StartScenarioSelectionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartScenarioSelectionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this StartScenarioSelectionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.StartScenarioSelectionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartScenarioSelectionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartScenarioSelectionResponse;
            })();

            clientrpc.SelectScenarioOptionRequest = (function() {

                /**
                 * Properties of a SelectScenarioOptionRequest.
                 * @memberof visor.clientrpc
                 * @interface ISelectScenarioOptionRequest
                 * @property {string|null} [scenarioToken] SelectScenarioOptionRequest scenarioToken
                 * @property {string|null} [scenarioOptionToken] SelectScenarioOptionRequest scenarioOptionToken
                 * @property {string|null} [firstName] SelectScenarioOptionRequest firstName
                 * @property {string|null} [lastName] SelectScenarioOptionRequest lastName
                 * @property {string|null} [address] SelectScenarioOptionRequest address
                 * @property {string|null} [cityStateAndZip] SelectScenarioOptionRequest cityStateAndZip
                 * @property {visor.common.encryption.IEncryptedItem|null} [socialSecurityNumber] SelectScenarioOptionRequest socialSecurityNumber
                 */

                /**
                 * Constructs a new SelectScenarioOptionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SelectScenarioOptionRequest.
                 * @implements ISelectScenarioOptionRequest
                 * @constructor
                 * @param {visor.clientrpc.ISelectScenarioOptionRequest=} [properties] Properties to set
                 */
                function SelectScenarioOptionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SelectScenarioOptionRequest scenarioToken.
                 * @member {string} scenarioToken
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 */
                SelectScenarioOptionRequest.prototype.scenarioToken = "";

                /**
                 * SelectScenarioOptionRequest scenarioOptionToken.
                 * @member {string} scenarioOptionToken
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 */
                SelectScenarioOptionRequest.prototype.scenarioOptionToken = "";

                /**
                 * SelectScenarioOptionRequest firstName.
                 * @member {string} firstName
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 */
                SelectScenarioOptionRequest.prototype.firstName = "";

                /**
                 * SelectScenarioOptionRequest lastName.
                 * @member {string} lastName
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 */
                SelectScenarioOptionRequest.prototype.lastName = "";

                /**
                 * SelectScenarioOptionRequest address.
                 * @member {string} address
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 */
                SelectScenarioOptionRequest.prototype.address = "";

                /**
                 * SelectScenarioOptionRequest cityStateAndZip.
                 * @member {string} cityStateAndZip
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 */
                SelectScenarioOptionRequest.prototype.cityStateAndZip = "";

                /**
                 * SelectScenarioOptionRequest socialSecurityNumber.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} socialSecurityNumber
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 */
                SelectScenarioOptionRequest.prototype.socialSecurityNumber = null;

                /**
                 * Creates a new SelectScenarioOptionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {visor.clientrpc.ISelectScenarioOptionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SelectScenarioOptionRequest} SelectScenarioOptionRequest instance
                 */
                SelectScenarioOptionRequest.create = function create(properties) {
                    return new SelectScenarioOptionRequest(properties);
                };

                /**
                 * Encodes the specified SelectScenarioOptionRequest message. Does not implicitly {@link visor.clientrpc.SelectScenarioOptionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {visor.clientrpc.ISelectScenarioOptionRequest} message SelectScenarioOptionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectScenarioOptionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scenarioToken);
                    if (message.scenarioOptionToken != null && message.hasOwnProperty("scenarioOptionToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.scenarioOptionToken);
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
                    if (message.address != null && message.hasOwnProperty("address"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.address);
                    if (message.cityStateAndZip != null && message.hasOwnProperty("cityStateAndZip"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.cityStateAndZip);
                    if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.socialSecurityNumber, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SelectScenarioOptionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SelectScenarioOptionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {visor.clientrpc.ISelectScenarioOptionRequest} message SelectScenarioOptionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectScenarioOptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SelectScenarioOptionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SelectScenarioOptionRequest} SelectScenarioOptionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectScenarioOptionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SelectScenarioOptionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.scenarioToken = reader.string();
                            break;
                        case 2:
                            message.scenarioOptionToken = reader.string();
                            break;
                        case 3:
                            message.firstName = reader.string();
                            break;
                        case 4:
                            message.lastName = reader.string();
                            break;
                        case 5:
                            message.address = reader.string();
                            break;
                        case 6:
                            message.cityStateAndZip = reader.string();
                            break;
                        case 7:
                            message.socialSecurityNumber = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SelectScenarioOptionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SelectScenarioOptionRequest} SelectScenarioOptionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectScenarioOptionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SelectScenarioOptionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SelectScenarioOptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        if (!$util.isString(message.scenarioToken))
                            return "scenarioToken: string expected";
                    if (message.scenarioOptionToken != null && message.hasOwnProperty("scenarioOptionToken"))
                        if (!$util.isString(message.scenarioOptionToken))
                            return "scenarioOptionToken: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.address != null && message.hasOwnProperty("address"))
                        if (!$util.isString(message.address))
                            return "address: string expected";
                    if (message.cityStateAndZip != null && message.hasOwnProperty("cityStateAndZip"))
                        if (!$util.isString(message.cityStateAndZip))
                            return "cityStateAndZip: string expected";
                    if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.socialSecurityNumber);
                        if (error)
                            return "socialSecurityNumber." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SelectScenarioOptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SelectScenarioOptionRequest} SelectScenarioOptionRequest
                 */
                SelectScenarioOptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SelectScenarioOptionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SelectScenarioOptionRequest();
                    if (object.scenarioToken != null)
                        message.scenarioToken = String(object.scenarioToken);
                    if (object.scenarioOptionToken != null)
                        message.scenarioOptionToken = String(object.scenarioOptionToken);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.address != null)
                        message.address = String(object.address);
                    if (object.cityStateAndZip != null)
                        message.cityStateAndZip = String(object.cityStateAndZip);
                    if (object.socialSecurityNumber != null) {
                        if (typeof object.socialSecurityNumber !== "object")
                            throw TypeError(".visor.clientrpc.SelectScenarioOptionRequest.socialSecurityNumber: object expected");
                        message.socialSecurityNumber = $root.visor.common.encryption.EncryptedItem.fromObject(object.socialSecurityNumber);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SelectScenarioOptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @static
                 * @param {visor.clientrpc.SelectScenarioOptionRequest} message SelectScenarioOptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SelectScenarioOptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.scenarioToken = "";
                        object.scenarioOptionToken = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.address = "";
                        object.cityStateAndZip = "";
                        object.socialSecurityNumber = null;
                    }
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        object.scenarioToken = message.scenarioToken;
                    if (message.scenarioOptionToken != null && message.hasOwnProperty("scenarioOptionToken"))
                        object.scenarioOptionToken = message.scenarioOptionToken;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = message.address;
                    if (message.cityStateAndZip != null && message.hasOwnProperty("cityStateAndZip"))
                        object.cityStateAndZip = message.cityStateAndZip;
                    if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                        object.socialSecurityNumber = $root.visor.common.encryption.EncryptedItem.toObject(message.socialSecurityNumber, options);
                    return object;
                };

                /**
                 * Converts this SelectScenarioOptionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SelectScenarioOptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SelectScenarioOptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SelectScenarioOptionRequest;
            })();

            clientrpc.SelectScenarioOptionResponse = (function() {

                /**
                 * Properties of a SelectScenarioOptionResponse.
                 * @memberof visor.clientrpc
                 * @interface ISelectScenarioOptionResponse
                 */

                /**
                 * Constructs a new SelectScenarioOptionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SelectScenarioOptionResponse.
                 * @implements ISelectScenarioOptionResponse
                 * @constructor
                 * @param {visor.clientrpc.ISelectScenarioOptionResponse=} [properties] Properties to set
                 */
                function SelectScenarioOptionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SelectScenarioOptionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {visor.clientrpc.ISelectScenarioOptionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SelectScenarioOptionResponse} SelectScenarioOptionResponse instance
                 */
                SelectScenarioOptionResponse.create = function create(properties) {
                    return new SelectScenarioOptionResponse(properties);
                };

                /**
                 * Encodes the specified SelectScenarioOptionResponse message. Does not implicitly {@link visor.clientrpc.SelectScenarioOptionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {visor.clientrpc.ISelectScenarioOptionResponse} message SelectScenarioOptionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectScenarioOptionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SelectScenarioOptionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SelectScenarioOptionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {visor.clientrpc.ISelectScenarioOptionResponse} message SelectScenarioOptionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SelectScenarioOptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SelectScenarioOptionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SelectScenarioOptionResponse} SelectScenarioOptionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectScenarioOptionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SelectScenarioOptionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SelectScenarioOptionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SelectScenarioOptionResponse} SelectScenarioOptionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SelectScenarioOptionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SelectScenarioOptionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SelectScenarioOptionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SelectScenarioOptionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SelectScenarioOptionResponse} SelectScenarioOptionResponse
                 */
                SelectScenarioOptionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SelectScenarioOptionResponse)
                        return object;
                    return new $root.visor.clientrpc.SelectScenarioOptionResponse();
                };

                /**
                 * Creates a plain object from a SelectScenarioOptionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @static
                 * @param {visor.clientrpc.SelectScenarioOptionResponse} message SelectScenarioOptionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SelectScenarioOptionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SelectScenarioOptionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SelectScenarioOptionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SelectScenarioOptionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SelectScenarioOptionResponse;
            })();

            clientrpc.SignProvisionalW4Request = (function() {

                /**
                 * Properties of a SignProvisionalW4Request.
                 * @memberof visor.clientrpc
                 * @interface ISignProvisionalW4Request
                 * @property {string|null} [scenarioToken] SignProvisionalW4Request scenarioToken
                 * @property {Uint8Array|null} [signature] SignProvisionalW4Request signature
                 */

                /**
                 * Constructs a new SignProvisionalW4Request.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SignProvisionalW4Request.
                 * @implements ISignProvisionalW4Request
                 * @constructor
                 * @param {visor.clientrpc.ISignProvisionalW4Request=} [properties] Properties to set
                 */
                function SignProvisionalW4Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SignProvisionalW4Request scenarioToken.
                 * @member {string} scenarioToken
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @instance
                 */
                SignProvisionalW4Request.prototype.scenarioToken = "";

                /**
                 * SignProvisionalW4Request signature.
                 * @member {Uint8Array} signature
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @instance
                 */
                SignProvisionalW4Request.prototype.signature = $util.newBuffer([]);

                /**
                 * Creates a new SignProvisionalW4Request instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {visor.clientrpc.ISignProvisionalW4Request=} [properties] Properties to set
                 * @returns {visor.clientrpc.SignProvisionalW4Request} SignProvisionalW4Request instance
                 */
                SignProvisionalW4Request.create = function create(properties) {
                    return new SignProvisionalW4Request(properties);
                };

                /**
                 * Encodes the specified SignProvisionalW4Request message. Does not implicitly {@link visor.clientrpc.SignProvisionalW4Request.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {visor.clientrpc.ISignProvisionalW4Request} message SignProvisionalW4Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignProvisionalW4Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scenarioToken);
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.signature);
                    return writer;
                };

                /**
                 * Encodes the specified SignProvisionalW4Request message, length delimited. Does not implicitly {@link visor.clientrpc.SignProvisionalW4Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {visor.clientrpc.ISignProvisionalW4Request} message SignProvisionalW4Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignProvisionalW4Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SignProvisionalW4Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SignProvisionalW4Request} SignProvisionalW4Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignProvisionalW4Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SignProvisionalW4Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.scenarioToken = reader.string();
                            break;
                        case 2:
                            message.signature = reader.bytes();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SignProvisionalW4Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SignProvisionalW4Request} SignProvisionalW4Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignProvisionalW4Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SignProvisionalW4Request message.
                 * @function verify
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SignProvisionalW4Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        if (!$util.isString(message.scenarioToken))
                            return "scenarioToken: string expected";
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                            return "signature: buffer expected";
                    return null;
                };

                /**
                 * Creates a SignProvisionalW4Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SignProvisionalW4Request} SignProvisionalW4Request
                 */
                SignProvisionalW4Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SignProvisionalW4Request)
                        return object;
                    var message = new $root.visor.clientrpc.SignProvisionalW4Request();
                    if (object.scenarioToken != null)
                        message.scenarioToken = String(object.scenarioToken);
                    if (object.signature != null)
                        if (typeof object.signature === "string")
                            $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                        else if (object.signature.length)
                            message.signature = object.signature;
                    return message;
                };

                /**
                 * Creates a plain object from a SignProvisionalW4Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @static
                 * @param {visor.clientrpc.SignProvisionalW4Request} message SignProvisionalW4Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SignProvisionalW4Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.scenarioToken = "";
                        if (options.bytes === String)
                            object.signature = "";
                        else {
                            object.signature = [];
                            if (options.bytes !== Array)
                                object.signature = $util.newBuffer(object.signature);
                        }
                    }
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        object.scenarioToken = message.scenarioToken;
                    if (message.signature != null && message.hasOwnProperty("signature"))
                        object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
                    return object;
                };

                /**
                 * Converts this SignProvisionalW4Request to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SignProvisionalW4Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SignProvisionalW4Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SignProvisionalW4Request;
            })();

            clientrpc.SignProvisionalW4Response = (function() {

                /**
                 * Properties of a SignProvisionalW4Response.
                 * @memberof visor.clientrpc
                 * @interface ISignProvisionalW4Response
                 */

                /**
                 * Constructs a new SignProvisionalW4Response.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SignProvisionalW4Response.
                 * @implements ISignProvisionalW4Response
                 * @constructor
                 * @param {visor.clientrpc.ISignProvisionalW4Response=} [properties] Properties to set
                 */
                function SignProvisionalW4Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SignProvisionalW4Response instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {visor.clientrpc.ISignProvisionalW4Response=} [properties] Properties to set
                 * @returns {visor.clientrpc.SignProvisionalW4Response} SignProvisionalW4Response instance
                 */
                SignProvisionalW4Response.create = function create(properties) {
                    return new SignProvisionalW4Response(properties);
                };

                /**
                 * Encodes the specified SignProvisionalW4Response message. Does not implicitly {@link visor.clientrpc.SignProvisionalW4Response.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {visor.clientrpc.ISignProvisionalW4Response} message SignProvisionalW4Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignProvisionalW4Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SignProvisionalW4Response message, length delimited. Does not implicitly {@link visor.clientrpc.SignProvisionalW4Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {visor.clientrpc.ISignProvisionalW4Response} message SignProvisionalW4Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignProvisionalW4Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SignProvisionalW4Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SignProvisionalW4Response} SignProvisionalW4Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignProvisionalW4Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SignProvisionalW4Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SignProvisionalW4Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SignProvisionalW4Response} SignProvisionalW4Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignProvisionalW4Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SignProvisionalW4Response message.
                 * @function verify
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SignProvisionalW4Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SignProvisionalW4Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SignProvisionalW4Response} SignProvisionalW4Response
                 */
                SignProvisionalW4Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SignProvisionalW4Response)
                        return object;
                    return new $root.visor.clientrpc.SignProvisionalW4Response();
                };

                /**
                 * Creates a plain object from a SignProvisionalW4Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @static
                 * @param {visor.clientrpc.SignProvisionalW4Response} message SignProvisionalW4Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SignProvisionalW4Response.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SignProvisionalW4Response to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SignProvisionalW4Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SignProvisionalW4Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SignProvisionalW4Response;
            })();

            clientrpc.ProvideHREmailAddressForW4ReviewRequest = (function() {

                /**
                 * Properties of a ProvideHREmailAddressForW4ReviewRequest.
                 * @memberof visor.clientrpc
                 * @interface IProvideHREmailAddressForW4ReviewRequest
                 * @property {string|null} [w4Token] ProvideHREmailAddressForW4ReviewRequest w4Token
                 * @property {string|null} [email] ProvideHREmailAddressForW4ReviewRequest email
                 */

                /**
                 * Constructs a new ProvideHREmailAddressForW4ReviewRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ProvideHREmailAddressForW4ReviewRequest.
                 * @implements IProvideHREmailAddressForW4ReviewRequest
                 * @constructor
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewRequest=} [properties] Properties to set
                 */
                function ProvideHREmailAddressForW4ReviewRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ProvideHREmailAddressForW4ReviewRequest w4Token.
                 * @member {string} w4Token
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @instance
                 */
                ProvideHREmailAddressForW4ReviewRequest.prototype.w4Token = "";

                /**
                 * ProvideHREmailAddressForW4ReviewRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @instance
                 */
                ProvideHREmailAddressForW4ReviewRequest.prototype.email = "";

                /**
                 * Creates a new ProvideHREmailAddressForW4ReviewRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest} ProvideHREmailAddressForW4ReviewRequest instance
                 */
                ProvideHREmailAddressForW4ReviewRequest.create = function create(properties) {
                    return new ProvideHREmailAddressForW4ReviewRequest(properties);
                };

                /**
                 * Encodes the specified ProvideHREmailAddressForW4ReviewRequest message. Does not implicitly {@link visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewRequest} message ProvideHREmailAddressForW4ReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProvideHREmailAddressForW4ReviewRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.w4Token != null && message.hasOwnProperty("w4Token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.w4Token);
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified ProvideHREmailAddressForW4ReviewRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewRequest} message ProvideHREmailAddressForW4ReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProvideHREmailAddressForW4ReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProvideHREmailAddressForW4ReviewRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest} ProvideHREmailAddressForW4ReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProvideHREmailAddressForW4ReviewRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.w4Token = reader.string();
                            break;
                        case 2:
                            message.email = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProvideHREmailAddressForW4ReviewRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest} ProvideHREmailAddressForW4ReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProvideHREmailAddressForW4ReviewRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProvideHREmailAddressForW4ReviewRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProvideHREmailAddressForW4ReviewRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.w4Token != null && message.hasOwnProperty("w4Token"))
                        if (!$util.isString(message.w4Token))
                            return "w4Token: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a ProvideHREmailAddressForW4ReviewRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest} ProvideHREmailAddressForW4ReviewRequest
                 */
                ProvideHREmailAddressForW4ReviewRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest();
                    if (object.w4Token != null)
                        message.w4Token = String(object.w4Token);
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a ProvideHREmailAddressForW4ReviewRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @static
                 * @param {visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest} message ProvideHREmailAddressForW4ReviewRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProvideHREmailAddressForW4ReviewRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.w4Token = "";
                        object.email = "";
                    }
                    if (message.w4Token != null && message.hasOwnProperty("w4Token"))
                        object.w4Token = message.w4Token;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this ProvideHREmailAddressForW4ReviewRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProvideHREmailAddressForW4ReviewRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ProvideHREmailAddressForW4ReviewRequest;
            })();

            clientrpc.ProvideHREmailAddressForW4ReviewResponse = (function() {

                /**
                 * Properties of a ProvideHREmailAddressForW4ReviewResponse.
                 * @memberof visor.clientrpc
                 * @interface IProvideHREmailAddressForW4ReviewResponse
                 */

                /**
                 * Constructs a new ProvideHREmailAddressForW4ReviewResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ProvideHREmailAddressForW4ReviewResponse.
                 * @implements IProvideHREmailAddressForW4ReviewResponse
                 * @constructor
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewResponse=} [properties] Properties to set
                 */
                function ProvideHREmailAddressForW4ReviewResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ProvideHREmailAddressForW4ReviewResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse} ProvideHREmailAddressForW4ReviewResponse instance
                 */
                ProvideHREmailAddressForW4ReviewResponse.create = function create(properties) {
                    return new ProvideHREmailAddressForW4ReviewResponse(properties);
                };

                /**
                 * Encodes the specified ProvideHREmailAddressForW4ReviewResponse message. Does not implicitly {@link visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewResponse} message ProvideHREmailAddressForW4ReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProvideHREmailAddressForW4ReviewResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ProvideHREmailAddressForW4ReviewResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {visor.clientrpc.IProvideHREmailAddressForW4ReviewResponse} message ProvideHREmailAddressForW4ReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ProvideHREmailAddressForW4ReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ProvideHREmailAddressForW4ReviewResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse} ProvideHREmailAddressForW4ReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProvideHREmailAddressForW4ReviewResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ProvideHREmailAddressForW4ReviewResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse} ProvideHREmailAddressForW4ReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ProvideHREmailAddressForW4ReviewResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ProvideHREmailAddressForW4ReviewResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ProvideHREmailAddressForW4ReviewResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ProvideHREmailAddressForW4ReviewResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse} ProvideHREmailAddressForW4ReviewResponse
                 */
                ProvideHREmailAddressForW4ReviewResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse)
                        return object;
                    return new $root.visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse();
                };

                /**
                 * Creates a plain object from a ProvideHREmailAddressForW4ReviewResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @static
                 * @param {visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse} message ProvideHREmailAddressForW4ReviewResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ProvideHREmailAddressForW4ReviewResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ProvideHREmailAddressForW4ReviewResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ProvideHREmailAddressForW4ReviewResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ProvideHREmailAddressForW4ReviewResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ProvideHREmailAddressForW4ReviewResponse;
            })();

            clientrpc.ConfirmFinalW4UpdatesRequest = (function() {

                /**
                 * Properties of a ConfirmFinalW4UpdatesRequest.
                 * @memberof visor.clientrpc
                 * @interface IConfirmFinalW4UpdatesRequest
                 * @property {string|null} [scenarioToken] ConfirmFinalW4UpdatesRequest scenarioToken
                 * @property {boolean|null} [clientWillSubmit] ConfirmFinalW4UpdatesRequest clientWillSubmit
                 * @property {string|null} [emailAddressForFiling] ConfirmFinalW4UpdatesRequest emailAddressForFiling
                 * @property {visor.clientrpc.ConfirmFinalW4UpdatesRequest.IW4FilingMailingAddress|null} [mailingAddress] ConfirmFinalW4UpdatesRequest mailingAddress
                 */

                /**
                 * Constructs a new ConfirmFinalW4UpdatesRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ConfirmFinalW4UpdatesRequest.
                 * @implements IConfirmFinalW4UpdatesRequest
                 * @constructor
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesRequest=} [properties] Properties to set
                 */
                function ConfirmFinalW4UpdatesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ConfirmFinalW4UpdatesRequest scenarioToken.
                 * @member {string} scenarioToken
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @instance
                 */
                ConfirmFinalW4UpdatesRequest.prototype.scenarioToken = "";

                /**
                 * ConfirmFinalW4UpdatesRequest clientWillSubmit.
                 * @member {boolean} clientWillSubmit
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @instance
                 */
                ConfirmFinalW4UpdatesRequest.prototype.clientWillSubmit = false;

                /**
                 * ConfirmFinalW4UpdatesRequest emailAddressForFiling.
                 * @member {string} emailAddressForFiling
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @instance
                 */
                ConfirmFinalW4UpdatesRequest.prototype.emailAddressForFiling = "";

                /**
                 * ConfirmFinalW4UpdatesRequest mailingAddress.
                 * @member {visor.clientrpc.ConfirmFinalW4UpdatesRequest.IW4FilingMailingAddress|null|undefined} mailingAddress
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @instance
                 */
                ConfirmFinalW4UpdatesRequest.prototype.mailingAddress = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ConfirmFinalW4UpdatesRequest submissionData.
                 * @member {"clientWillSubmit"|"emailAddressForFiling"|"mailingAddress"|undefined} submissionData
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @instance
                 */
                Object.defineProperty(ConfirmFinalW4UpdatesRequest.prototype, "submissionData", {
                    get: $util.oneOfGetter($oneOfFields = ["clientWillSubmit", "emailAddressForFiling", "mailingAddress"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ConfirmFinalW4UpdatesRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest} ConfirmFinalW4UpdatesRequest instance
                 */
                ConfirmFinalW4UpdatesRequest.create = function create(properties) {
                    return new ConfirmFinalW4UpdatesRequest(properties);
                };

                /**
                 * Encodes the specified ConfirmFinalW4UpdatesRequest message. Does not implicitly {@link visor.clientrpc.ConfirmFinalW4UpdatesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesRequest} message ConfirmFinalW4UpdatesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmFinalW4UpdatesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.scenarioToken);
                    if (message.clientWillSubmit != null && message.hasOwnProperty("clientWillSubmit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.clientWillSubmit);
                    if (message.emailAddressForFiling != null && message.hasOwnProperty("emailAddressForFiling"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.emailAddressForFiling);
                    if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                        $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress.encode(message.mailingAddress, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ConfirmFinalW4UpdatesRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ConfirmFinalW4UpdatesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesRequest} message ConfirmFinalW4UpdatesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmFinalW4UpdatesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ConfirmFinalW4UpdatesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest} ConfirmFinalW4UpdatesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmFinalW4UpdatesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.scenarioToken = reader.string();
                            break;
                        case 2:
                            message.clientWillSubmit = reader.bool();
                            break;
                        case 3:
                            message.emailAddressForFiling = reader.string();
                            break;
                        case 4:
                            message.mailingAddress = $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ConfirmFinalW4UpdatesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest} ConfirmFinalW4UpdatesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmFinalW4UpdatesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ConfirmFinalW4UpdatesRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConfirmFinalW4UpdatesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        if (!$util.isString(message.scenarioToken))
                            return "scenarioToken: string expected";
                    if (message.clientWillSubmit != null && message.hasOwnProperty("clientWillSubmit")) {
                        properties.submissionData = 1;
                        if (typeof message.clientWillSubmit !== "boolean")
                            return "clientWillSubmit: boolean expected";
                    }
                    if (message.emailAddressForFiling != null && message.hasOwnProperty("emailAddressForFiling")) {
                        if (properties.submissionData === 1)
                            return "submissionData: multiple values";
                        properties.submissionData = 1;
                        if (!$util.isString(message.emailAddressForFiling))
                            return "emailAddressForFiling: string expected";
                    }
                    if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress")) {
                        if (properties.submissionData === 1)
                            return "submissionData: multiple values";
                        properties.submissionData = 1;
                        {
                            var error = $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress.verify(message.mailingAddress);
                            if (error)
                                return "mailingAddress." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ConfirmFinalW4UpdatesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest} ConfirmFinalW4UpdatesRequest
                 */
                ConfirmFinalW4UpdatesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest();
                    if (object.scenarioToken != null)
                        message.scenarioToken = String(object.scenarioToken);
                    if (object.clientWillSubmit != null)
                        message.clientWillSubmit = Boolean(object.clientWillSubmit);
                    if (object.emailAddressForFiling != null)
                        message.emailAddressForFiling = String(object.emailAddressForFiling);
                    if (object.mailingAddress != null) {
                        if (typeof object.mailingAddress !== "object")
                            throw TypeError(".visor.clientrpc.ConfirmFinalW4UpdatesRequest.mailingAddress: object expected");
                        message.mailingAddress = $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress.fromObject(object.mailingAddress);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ConfirmFinalW4UpdatesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @static
                 * @param {visor.clientrpc.ConfirmFinalW4UpdatesRequest} message ConfirmFinalW4UpdatesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConfirmFinalW4UpdatesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.scenarioToken = "";
                    if (message.scenarioToken != null && message.hasOwnProperty("scenarioToken"))
                        object.scenarioToken = message.scenarioToken;
                    if (message.clientWillSubmit != null && message.hasOwnProperty("clientWillSubmit")) {
                        object.clientWillSubmit = message.clientWillSubmit;
                        if (options.oneofs)
                            object.submissionData = "clientWillSubmit";
                    }
                    if (message.emailAddressForFiling != null && message.hasOwnProperty("emailAddressForFiling")) {
                        object.emailAddressForFiling = message.emailAddressForFiling;
                        if (options.oneofs)
                            object.submissionData = "emailAddressForFiling";
                    }
                    if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress")) {
                        object.mailingAddress = $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress.toObject(message.mailingAddress, options);
                        if (options.oneofs)
                            object.submissionData = "mailingAddress";
                    }
                    return object;
                };

                /**
                 * Converts this ConfirmFinalW4UpdatesRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConfirmFinalW4UpdatesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress = (function() {

                    /**
                     * Properties of a W4FilingMailingAddress.
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                     * @interface IW4FilingMailingAddress
                     * @property {string|null} [attentionLine] W4FilingMailingAddress attentionLine
                     * @property {string|null} [streetAddress] W4FilingMailingAddress streetAddress
                     * @property {string|null} [city] W4FilingMailingAddress city
                     * @property {string|null} [state] W4FilingMailingAddress state
                     * @property {string|null} [zip] W4FilingMailingAddress zip
                     */

                    /**
                     * Constructs a new W4FilingMailingAddress.
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest
                     * @classdesc Represents a W4FilingMailingAddress.
                     * @implements IW4FilingMailingAddress
                     * @constructor
                     * @param {visor.clientrpc.ConfirmFinalW4UpdatesRequest.IW4FilingMailingAddress=} [properties] Properties to set
                     */
                    function W4FilingMailingAddress(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * W4FilingMailingAddress attentionLine.
                     * @member {string} attentionLine
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @instance
                     */
                    W4FilingMailingAddress.prototype.attentionLine = "";

                    /**
                     * W4FilingMailingAddress streetAddress.
                     * @member {string} streetAddress
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @instance
                     */
                    W4FilingMailingAddress.prototype.streetAddress = "";

                    /**
                     * W4FilingMailingAddress city.
                     * @member {string} city
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @instance
                     */
                    W4FilingMailingAddress.prototype.city = "";

                    /**
                     * W4FilingMailingAddress state.
                     * @member {string} state
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @instance
                     */
                    W4FilingMailingAddress.prototype.state = "";

                    /**
                     * W4FilingMailingAddress zip.
                     * @member {string} zip
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @instance
                     */
                    W4FilingMailingAddress.prototype.zip = "";

                    /**
                     * Creates a new W4FilingMailingAddress instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {visor.clientrpc.ConfirmFinalW4UpdatesRequest.IW4FilingMailingAddress=} [properties] Properties to set
                     * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress} W4FilingMailingAddress instance
                     */
                    W4FilingMailingAddress.create = function create(properties) {
                        return new W4FilingMailingAddress(properties);
                    };

                    /**
                     * Encodes the specified W4FilingMailingAddress message. Does not implicitly {@link visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {visor.clientrpc.ConfirmFinalW4UpdatesRequest.IW4FilingMailingAddress} message W4FilingMailingAddress message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    W4FilingMailingAddress.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.attentionLine != null && message.hasOwnProperty("attentionLine"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.attentionLine);
                        if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.streetAddress);
                        if (message.city != null && message.hasOwnProperty("city"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.city);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.state);
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.zip);
                        return writer;
                    };

                    /**
                     * Encodes the specified W4FilingMailingAddress message, length delimited. Does not implicitly {@link visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {visor.clientrpc.ConfirmFinalW4UpdatesRequest.IW4FilingMailingAddress} message W4FilingMailingAddress message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    W4FilingMailingAddress.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a W4FilingMailingAddress message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress} W4FilingMailingAddress
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    W4FilingMailingAddress.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.attentionLine = reader.string();
                                break;
                            case 2:
                                message.streetAddress = reader.string();
                                break;
                            case 3:
                                message.city = reader.string();
                                break;
                            case 4:
                                message.state = reader.string();
                                break;
                            case 5:
                                message.zip = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a W4FilingMailingAddress message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress} W4FilingMailingAddress
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    W4FilingMailingAddress.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a W4FilingMailingAddress message.
                     * @function verify
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    W4FilingMailingAddress.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.attentionLine != null && message.hasOwnProperty("attentionLine"))
                            if (!$util.isString(message.attentionLine))
                                return "attentionLine: string expected";
                        if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
                            if (!$util.isString(message.streetAddress))
                                return "streetAddress: string expected";
                        if (message.city != null && message.hasOwnProperty("city"))
                            if (!$util.isString(message.city))
                                return "city: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            if (!$util.isString(message.state))
                                return "state: string expected";
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            if (!$util.isString(message.zip))
                                return "zip: string expected";
                        return null;
                    };

                    /**
                     * Creates a W4FilingMailingAddress message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress} W4FilingMailingAddress
                     */
                    W4FilingMailingAddress.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress)
                            return object;
                        var message = new $root.visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress();
                        if (object.attentionLine != null)
                            message.attentionLine = String(object.attentionLine);
                        if (object.streetAddress != null)
                            message.streetAddress = String(object.streetAddress);
                        if (object.city != null)
                            message.city = String(object.city);
                        if (object.state != null)
                            message.state = String(object.state);
                        if (object.zip != null)
                            message.zip = String(object.zip);
                        return message;
                    };

                    /**
                     * Creates a plain object from a W4FilingMailingAddress message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @static
                     * @param {visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress} message W4FilingMailingAddress
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    W4FilingMailingAddress.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.attentionLine = "";
                            object.streetAddress = "";
                            object.city = "";
                            object.state = "";
                            object.zip = "";
                        }
                        if (message.attentionLine != null && message.hasOwnProperty("attentionLine"))
                            object.attentionLine = message.attentionLine;
                        if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
                            object.streetAddress = message.streetAddress;
                        if (message.city != null && message.hasOwnProperty("city"))
                            object.city = message.city;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = message.state;
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            object.zip = message.zip;
                        return object;
                    };

                    /**
                     * Converts this W4FilingMailingAddress to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.ConfirmFinalW4UpdatesRequest.W4FilingMailingAddress
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    W4FilingMailingAddress.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return W4FilingMailingAddress;
                })();

                return ConfirmFinalW4UpdatesRequest;
            })();

            clientrpc.ConfirmFinalW4UpdatesResponse = (function() {

                /**
                 * Properties of a ConfirmFinalW4UpdatesResponse.
                 * @memberof visor.clientrpc
                 * @interface IConfirmFinalW4UpdatesResponse
                 */

                /**
                 * Constructs a new ConfirmFinalW4UpdatesResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ConfirmFinalW4UpdatesResponse.
                 * @implements IConfirmFinalW4UpdatesResponse
                 * @constructor
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesResponse=} [properties] Properties to set
                 */
                function ConfirmFinalW4UpdatesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ConfirmFinalW4UpdatesResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesResponse} ConfirmFinalW4UpdatesResponse instance
                 */
                ConfirmFinalW4UpdatesResponse.create = function create(properties) {
                    return new ConfirmFinalW4UpdatesResponse(properties);
                };

                /**
                 * Encodes the specified ConfirmFinalW4UpdatesResponse message. Does not implicitly {@link visor.clientrpc.ConfirmFinalW4UpdatesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesResponse} message ConfirmFinalW4UpdatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmFinalW4UpdatesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ConfirmFinalW4UpdatesResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ConfirmFinalW4UpdatesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {visor.clientrpc.IConfirmFinalW4UpdatesResponse} message ConfirmFinalW4UpdatesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmFinalW4UpdatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ConfirmFinalW4UpdatesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesResponse} ConfirmFinalW4UpdatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmFinalW4UpdatesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ConfirmFinalW4UpdatesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ConfirmFinalW4UpdatesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesResponse} ConfirmFinalW4UpdatesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmFinalW4UpdatesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ConfirmFinalW4UpdatesResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConfirmFinalW4UpdatesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ConfirmFinalW4UpdatesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ConfirmFinalW4UpdatesResponse} ConfirmFinalW4UpdatesResponse
                 */
                ConfirmFinalW4UpdatesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ConfirmFinalW4UpdatesResponse)
                        return object;
                    return new $root.visor.clientrpc.ConfirmFinalW4UpdatesResponse();
                };

                /**
                 * Creates a plain object from a ConfirmFinalW4UpdatesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @static
                 * @param {visor.clientrpc.ConfirmFinalW4UpdatesResponse} message ConfirmFinalW4UpdatesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConfirmFinalW4UpdatesResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ConfirmFinalW4UpdatesResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ConfirmFinalW4UpdatesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConfirmFinalW4UpdatesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ConfirmFinalW4UpdatesResponse;
            })();

            clientrpc.ListPaymentMethodsRequest = (function() {

                /**
                 * Properties of a ListPaymentMethodsRequest.
                 * @memberof visor.clientrpc
                 * @interface IListPaymentMethodsRequest
                 */

                /**
                 * Constructs a new ListPaymentMethodsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ListPaymentMethodsRequest.
                 * @implements IListPaymentMethodsRequest
                 * @constructor
                 * @param {visor.clientrpc.IListPaymentMethodsRequest=} [properties] Properties to set
                 */
                function ListPaymentMethodsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ListPaymentMethodsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {visor.clientrpc.IListPaymentMethodsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ListPaymentMethodsRequest} ListPaymentMethodsRequest instance
                 */
                ListPaymentMethodsRequest.create = function create(properties) {
                    return new ListPaymentMethodsRequest(properties);
                };

                /**
                 * Encodes the specified ListPaymentMethodsRequest message. Does not implicitly {@link visor.clientrpc.ListPaymentMethodsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {visor.clientrpc.IListPaymentMethodsRequest} message ListPaymentMethodsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListPaymentMethodsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ListPaymentMethodsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ListPaymentMethodsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {visor.clientrpc.IListPaymentMethodsRequest} message ListPaymentMethodsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListPaymentMethodsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ListPaymentMethodsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ListPaymentMethodsRequest} ListPaymentMethodsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListPaymentMethodsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListPaymentMethodsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ListPaymentMethodsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ListPaymentMethodsRequest} ListPaymentMethodsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListPaymentMethodsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ListPaymentMethodsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListPaymentMethodsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListPaymentMethodsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ListPaymentMethodsRequest} ListPaymentMethodsRequest
                 */
                ListPaymentMethodsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ListPaymentMethodsRequest)
                        return object;
                    return new $root.visor.clientrpc.ListPaymentMethodsRequest();
                };

                /**
                 * Creates a plain object from a ListPaymentMethodsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @static
                 * @param {visor.clientrpc.ListPaymentMethodsRequest} message ListPaymentMethodsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListPaymentMethodsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListPaymentMethodsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ListPaymentMethodsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListPaymentMethodsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListPaymentMethodsRequest;
            })();

            clientrpc.ListPaymentMethodsResponse = (function() {

                /**
                 * Properties of a ListPaymentMethodsResponse.
                 * @memberof visor.clientrpc
                 * @interface IListPaymentMethodsResponse
                 * @property {Array.<visor.clientrpc.stripe.IPaymentMethod>|null} [stripePaymentMethods] ListPaymentMethodsResponse stripePaymentMethods
                 */

                /**
                 * Constructs a new ListPaymentMethodsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ListPaymentMethodsResponse.
                 * @implements IListPaymentMethodsResponse
                 * @constructor
                 * @param {visor.clientrpc.IListPaymentMethodsResponse=} [properties] Properties to set
                 */
                function ListPaymentMethodsResponse(properties) {
                    this.stripePaymentMethods = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListPaymentMethodsResponse stripePaymentMethods.
                 * @member {Array.<visor.clientrpc.stripe.IPaymentMethod>} stripePaymentMethods
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @instance
                 */
                ListPaymentMethodsResponse.prototype.stripePaymentMethods = $util.emptyArray;

                /**
                 * Creates a new ListPaymentMethodsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {visor.clientrpc.IListPaymentMethodsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ListPaymentMethodsResponse} ListPaymentMethodsResponse instance
                 */
                ListPaymentMethodsResponse.create = function create(properties) {
                    return new ListPaymentMethodsResponse(properties);
                };

                /**
                 * Encodes the specified ListPaymentMethodsResponse message. Does not implicitly {@link visor.clientrpc.ListPaymentMethodsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {visor.clientrpc.IListPaymentMethodsResponse} message ListPaymentMethodsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListPaymentMethodsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stripePaymentMethods != null && message.stripePaymentMethods.length)
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i)
                            $root.visor.clientrpc.stripe.PaymentMethod.encode(message.stripePaymentMethods[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ListPaymentMethodsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ListPaymentMethodsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {visor.clientrpc.IListPaymentMethodsResponse} message ListPaymentMethodsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListPaymentMethodsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ListPaymentMethodsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ListPaymentMethodsResponse} ListPaymentMethodsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListPaymentMethodsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListPaymentMethodsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.stripePaymentMethods && message.stripePaymentMethods.length))
                                message.stripePaymentMethods = [];
                            message.stripePaymentMethods.push($root.visor.clientrpc.stripe.PaymentMethod.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ListPaymentMethodsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ListPaymentMethodsResponse} ListPaymentMethodsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListPaymentMethodsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ListPaymentMethodsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListPaymentMethodsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stripePaymentMethods != null && message.hasOwnProperty("stripePaymentMethods")) {
                        if (!Array.isArray(message.stripePaymentMethods))
                            return "stripePaymentMethods: array expected";
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i) {
                            var error = $root.visor.clientrpc.stripe.PaymentMethod.verify(message.stripePaymentMethods[i]);
                            if (error)
                                return "stripePaymentMethods." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ListPaymentMethodsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ListPaymentMethodsResponse} ListPaymentMethodsResponse
                 */
                ListPaymentMethodsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ListPaymentMethodsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.ListPaymentMethodsResponse();
                    if (object.stripePaymentMethods) {
                        if (!Array.isArray(object.stripePaymentMethods))
                            throw TypeError(".visor.clientrpc.ListPaymentMethodsResponse.stripePaymentMethods: array expected");
                        message.stripePaymentMethods = [];
                        for (var i = 0; i < object.stripePaymentMethods.length; ++i) {
                            if (typeof object.stripePaymentMethods[i] !== "object")
                                throw TypeError(".visor.clientrpc.ListPaymentMethodsResponse.stripePaymentMethods: object expected");
                            message.stripePaymentMethods[i] = $root.visor.clientrpc.stripe.PaymentMethod.fromObject(object.stripePaymentMethods[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListPaymentMethodsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @static
                 * @param {visor.clientrpc.ListPaymentMethodsResponse} message ListPaymentMethodsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListPaymentMethodsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.stripePaymentMethods = [];
                    if (message.stripePaymentMethods && message.stripePaymentMethods.length) {
                        object.stripePaymentMethods = [];
                        for (var j = 0; j < message.stripePaymentMethods.length; ++j)
                            object.stripePaymentMethods[j] = $root.visor.clientrpc.stripe.PaymentMethod.toObject(message.stripePaymentMethods[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this ListPaymentMethodsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ListPaymentMethodsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListPaymentMethodsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListPaymentMethodsResponse;
            })();

            clientrpc.SetDefaultPaymentMethodRequest = (function() {

                /**
                 * Properties of a SetDefaultPaymentMethodRequest.
                 * @memberof visor.clientrpc
                 * @interface ISetDefaultPaymentMethodRequest
                 * @property {string|null} [paymentMethodToken] SetDefaultPaymentMethodRequest paymentMethodToken
                 */

                /**
                 * Constructs a new SetDefaultPaymentMethodRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SetDefaultPaymentMethodRequest.
                 * @implements ISetDefaultPaymentMethodRequest
                 * @constructor
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodRequest=} [properties] Properties to set
                 */
                function SetDefaultPaymentMethodRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SetDefaultPaymentMethodRequest paymentMethodToken.
                 * @member {string} paymentMethodToken
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @instance
                 */
                SetDefaultPaymentMethodRequest.prototype.paymentMethodToken = "";

                /**
                 * Creates a new SetDefaultPaymentMethodRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodRequest} SetDefaultPaymentMethodRequest instance
                 */
                SetDefaultPaymentMethodRequest.create = function create(properties) {
                    return new SetDefaultPaymentMethodRequest(properties);
                };

                /**
                 * Encodes the specified SetDefaultPaymentMethodRequest message. Does not implicitly {@link visor.clientrpc.SetDefaultPaymentMethodRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodRequest} message SetDefaultPaymentMethodRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetDefaultPaymentMethodRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentMethodToken);
                    return writer;
                };

                /**
                 * Encodes the specified SetDefaultPaymentMethodRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SetDefaultPaymentMethodRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodRequest} message SetDefaultPaymentMethodRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetDefaultPaymentMethodRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SetDefaultPaymentMethodRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodRequest} SetDefaultPaymentMethodRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetDefaultPaymentMethodRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SetDefaultPaymentMethodRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.paymentMethodToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SetDefaultPaymentMethodRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodRequest} SetDefaultPaymentMethodRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetDefaultPaymentMethodRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SetDefaultPaymentMethodRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetDefaultPaymentMethodRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        if (!$util.isString(message.paymentMethodToken))
                            return "paymentMethodToken: string expected";
                    return null;
                };

                /**
                 * Creates a SetDefaultPaymentMethodRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodRequest} SetDefaultPaymentMethodRequest
                 */
                SetDefaultPaymentMethodRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SetDefaultPaymentMethodRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SetDefaultPaymentMethodRequest();
                    if (object.paymentMethodToken != null)
                        message.paymentMethodToken = String(object.paymentMethodToken);
                    return message;
                };

                /**
                 * Creates a plain object from a SetDefaultPaymentMethodRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.SetDefaultPaymentMethodRequest} message SetDefaultPaymentMethodRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetDefaultPaymentMethodRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.paymentMethodToken = "";
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        object.paymentMethodToken = message.paymentMethodToken;
                    return object;
                };

                /**
                 * Converts this SetDefaultPaymentMethodRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetDefaultPaymentMethodRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetDefaultPaymentMethodRequest;
            })();

            clientrpc.SetDefaultPaymentMethodResponse = (function() {

                /**
                 * Properties of a SetDefaultPaymentMethodResponse.
                 * @memberof visor.clientrpc
                 * @interface ISetDefaultPaymentMethodResponse
                 * @property {Array.<visor.clientrpc.stripe.IPaymentMethod>|null} [stripePaymentMethods] SetDefaultPaymentMethodResponse stripePaymentMethods
                 */

                /**
                 * Constructs a new SetDefaultPaymentMethodResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SetDefaultPaymentMethodResponse.
                 * @implements ISetDefaultPaymentMethodResponse
                 * @constructor
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodResponse=} [properties] Properties to set
                 */
                function SetDefaultPaymentMethodResponse(properties) {
                    this.stripePaymentMethods = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SetDefaultPaymentMethodResponse stripePaymentMethods.
                 * @member {Array.<visor.clientrpc.stripe.IPaymentMethod>} stripePaymentMethods
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @instance
                 */
                SetDefaultPaymentMethodResponse.prototype.stripePaymentMethods = $util.emptyArray;

                /**
                 * Creates a new SetDefaultPaymentMethodResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodResponse} SetDefaultPaymentMethodResponse instance
                 */
                SetDefaultPaymentMethodResponse.create = function create(properties) {
                    return new SetDefaultPaymentMethodResponse(properties);
                };

                /**
                 * Encodes the specified SetDefaultPaymentMethodResponse message. Does not implicitly {@link visor.clientrpc.SetDefaultPaymentMethodResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodResponse} message SetDefaultPaymentMethodResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetDefaultPaymentMethodResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stripePaymentMethods != null && message.stripePaymentMethods.length)
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i)
                            $root.visor.clientrpc.stripe.PaymentMethod.encode(message.stripePaymentMethods[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SetDefaultPaymentMethodResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SetDefaultPaymentMethodResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.ISetDefaultPaymentMethodResponse} message SetDefaultPaymentMethodResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetDefaultPaymentMethodResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SetDefaultPaymentMethodResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodResponse} SetDefaultPaymentMethodResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetDefaultPaymentMethodResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SetDefaultPaymentMethodResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.stripePaymentMethods && message.stripePaymentMethods.length))
                                message.stripePaymentMethods = [];
                            message.stripePaymentMethods.push($root.visor.clientrpc.stripe.PaymentMethod.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SetDefaultPaymentMethodResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodResponse} SetDefaultPaymentMethodResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetDefaultPaymentMethodResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SetDefaultPaymentMethodResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetDefaultPaymentMethodResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stripePaymentMethods != null && message.hasOwnProperty("stripePaymentMethods")) {
                        if (!Array.isArray(message.stripePaymentMethods))
                            return "stripePaymentMethods: array expected";
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i) {
                            var error = $root.visor.clientrpc.stripe.PaymentMethod.verify(message.stripePaymentMethods[i]);
                            if (error)
                                return "stripePaymentMethods." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a SetDefaultPaymentMethodResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SetDefaultPaymentMethodResponse} SetDefaultPaymentMethodResponse
                 */
                SetDefaultPaymentMethodResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SetDefaultPaymentMethodResponse)
                        return object;
                    var message = new $root.visor.clientrpc.SetDefaultPaymentMethodResponse();
                    if (object.stripePaymentMethods) {
                        if (!Array.isArray(object.stripePaymentMethods))
                            throw TypeError(".visor.clientrpc.SetDefaultPaymentMethodResponse.stripePaymentMethods: array expected");
                        message.stripePaymentMethods = [];
                        for (var i = 0; i < object.stripePaymentMethods.length; ++i) {
                            if (typeof object.stripePaymentMethods[i] !== "object")
                                throw TypeError(".visor.clientrpc.SetDefaultPaymentMethodResponse.stripePaymentMethods: object expected");
                            message.stripePaymentMethods[i] = $root.visor.clientrpc.stripe.PaymentMethod.fromObject(object.stripePaymentMethods[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SetDefaultPaymentMethodResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.SetDefaultPaymentMethodResponse} message SetDefaultPaymentMethodResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetDefaultPaymentMethodResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.stripePaymentMethods = [];
                    if (message.stripePaymentMethods && message.stripePaymentMethods.length) {
                        object.stripePaymentMethods = [];
                        for (var j = 0; j < message.stripePaymentMethods.length; ++j)
                            object.stripePaymentMethods[j] = $root.visor.clientrpc.stripe.PaymentMethod.toObject(message.stripePaymentMethods[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this SetDefaultPaymentMethodResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SetDefaultPaymentMethodResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetDefaultPaymentMethodResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetDefaultPaymentMethodResponse;
            })();

            clientrpc.DeletePaymentMethodRequest = (function() {

                /**
                 * Properties of a DeletePaymentMethodRequest.
                 * @memberof visor.clientrpc
                 * @interface IDeletePaymentMethodRequest
                 * @property {string|null} [paymentMethodToken] DeletePaymentMethodRequest paymentMethodToken
                 */

                /**
                 * Constructs a new DeletePaymentMethodRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a DeletePaymentMethodRequest.
                 * @implements IDeletePaymentMethodRequest
                 * @constructor
                 * @param {visor.clientrpc.IDeletePaymentMethodRequest=} [properties] Properties to set
                 */
                function DeletePaymentMethodRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeletePaymentMethodRequest paymentMethodToken.
                 * @member {string} paymentMethodToken
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @instance
                 */
                DeletePaymentMethodRequest.prototype.paymentMethodToken = "";

                /**
                 * Creates a new DeletePaymentMethodRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.IDeletePaymentMethodRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.DeletePaymentMethodRequest} DeletePaymentMethodRequest instance
                 */
                DeletePaymentMethodRequest.create = function create(properties) {
                    return new DeletePaymentMethodRequest(properties);
                };

                /**
                 * Encodes the specified DeletePaymentMethodRequest message. Does not implicitly {@link visor.clientrpc.DeletePaymentMethodRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.IDeletePaymentMethodRequest} message DeletePaymentMethodRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeletePaymentMethodRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentMethodToken);
                    return writer;
                };

                /**
                 * Encodes the specified DeletePaymentMethodRequest message, length delimited. Does not implicitly {@link visor.clientrpc.DeletePaymentMethodRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.IDeletePaymentMethodRequest} message DeletePaymentMethodRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeletePaymentMethodRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DeletePaymentMethodRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.DeletePaymentMethodRequest} DeletePaymentMethodRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeletePaymentMethodRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.DeletePaymentMethodRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.paymentMethodToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DeletePaymentMethodRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.DeletePaymentMethodRequest} DeletePaymentMethodRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeletePaymentMethodRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DeletePaymentMethodRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeletePaymentMethodRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        if (!$util.isString(message.paymentMethodToken))
                            return "paymentMethodToken: string expected";
                    return null;
                };

                /**
                 * Creates a DeletePaymentMethodRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.DeletePaymentMethodRequest} DeletePaymentMethodRequest
                 */
                DeletePaymentMethodRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.DeletePaymentMethodRequest)
                        return object;
                    var message = new $root.visor.clientrpc.DeletePaymentMethodRequest();
                    if (object.paymentMethodToken != null)
                        message.paymentMethodToken = String(object.paymentMethodToken);
                    return message;
                };

                /**
                 * Creates a plain object from a DeletePaymentMethodRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.DeletePaymentMethodRequest} message DeletePaymentMethodRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeletePaymentMethodRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.paymentMethodToken = "";
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        object.paymentMethodToken = message.paymentMethodToken;
                    return object;
                };

                /**
                 * Converts this DeletePaymentMethodRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.DeletePaymentMethodRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeletePaymentMethodRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeletePaymentMethodRequest;
            })();

            clientrpc.DeletePaymentMethodResponse = (function() {

                /**
                 * Properties of a DeletePaymentMethodResponse.
                 * @memberof visor.clientrpc
                 * @interface IDeletePaymentMethodResponse
                 * @property {Array.<visor.clientrpc.stripe.IPaymentMethod>|null} [stripePaymentMethods] DeletePaymentMethodResponse stripePaymentMethods
                 */

                /**
                 * Constructs a new DeletePaymentMethodResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a DeletePaymentMethodResponse.
                 * @implements IDeletePaymentMethodResponse
                 * @constructor
                 * @param {visor.clientrpc.IDeletePaymentMethodResponse=} [properties] Properties to set
                 */
                function DeletePaymentMethodResponse(properties) {
                    this.stripePaymentMethods = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DeletePaymentMethodResponse stripePaymentMethods.
                 * @member {Array.<visor.clientrpc.stripe.IPaymentMethod>} stripePaymentMethods
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @instance
                 */
                DeletePaymentMethodResponse.prototype.stripePaymentMethods = $util.emptyArray;

                /**
                 * Creates a new DeletePaymentMethodResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.IDeletePaymentMethodResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.DeletePaymentMethodResponse} DeletePaymentMethodResponse instance
                 */
                DeletePaymentMethodResponse.create = function create(properties) {
                    return new DeletePaymentMethodResponse(properties);
                };

                /**
                 * Encodes the specified DeletePaymentMethodResponse message. Does not implicitly {@link visor.clientrpc.DeletePaymentMethodResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.IDeletePaymentMethodResponse} message DeletePaymentMethodResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeletePaymentMethodResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stripePaymentMethods != null && message.stripePaymentMethods.length)
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i)
                            $root.visor.clientrpc.stripe.PaymentMethod.encode(message.stripePaymentMethods[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified DeletePaymentMethodResponse message, length delimited. Does not implicitly {@link visor.clientrpc.DeletePaymentMethodResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.IDeletePaymentMethodResponse} message DeletePaymentMethodResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DeletePaymentMethodResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DeletePaymentMethodResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.DeletePaymentMethodResponse} DeletePaymentMethodResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeletePaymentMethodResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.DeletePaymentMethodResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.stripePaymentMethods && message.stripePaymentMethods.length))
                                message.stripePaymentMethods = [];
                            message.stripePaymentMethods.push($root.visor.clientrpc.stripe.PaymentMethod.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DeletePaymentMethodResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.DeletePaymentMethodResponse} DeletePaymentMethodResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DeletePaymentMethodResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DeletePaymentMethodResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DeletePaymentMethodResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stripePaymentMethods != null && message.hasOwnProperty("stripePaymentMethods")) {
                        if (!Array.isArray(message.stripePaymentMethods))
                            return "stripePaymentMethods: array expected";
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i) {
                            var error = $root.visor.clientrpc.stripe.PaymentMethod.verify(message.stripePaymentMethods[i]);
                            if (error)
                                return "stripePaymentMethods." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a DeletePaymentMethodResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.DeletePaymentMethodResponse} DeletePaymentMethodResponse
                 */
                DeletePaymentMethodResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.DeletePaymentMethodResponse)
                        return object;
                    var message = new $root.visor.clientrpc.DeletePaymentMethodResponse();
                    if (object.stripePaymentMethods) {
                        if (!Array.isArray(object.stripePaymentMethods))
                            throw TypeError(".visor.clientrpc.DeletePaymentMethodResponse.stripePaymentMethods: array expected");
                        message.stripePaymentMethods = [];
                        for (var i = 0; i < object.stripePaymentMethods.length; ++i) {
                            if (typeof object.stripePaymentMethods[i] !== "object")
                                throw TypeError(".visor.clientrpc.DeletePaymentMethodResponse.stripePaymentMethods: object expected");
                            message.stripePaymentMethods[i] = $root.visor.clientrpc.stripe.PaymentMethod.fromObject(object.stripePaymentMethods[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a DeletePaymentMethodResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.DeletePaymentMethodResponse} message DeletePaymentMethodResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DeletePaymentMethodResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.stripePaymentMethods = [];
                    if (message.stripePaymentMethods && message.stripePaymentMethods.length) {
                        object.stripePaymentMethods = [];
                        for (var j = 0; j < message.stripePaymentMethods.length; ++j)
                            object.stripePaymentMethods[j] = $root.visor.clientrpc.stripe.PaymentMethod.toObject(message.stripePaymentMethods[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this DeletePaymentMethodResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.DeletePaymentMethodResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DeletePaymentMethodResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DeletePaymentMethodResponse;
            })();

            clientrpc.GetStripePaymentIntentClientSecretRequest = (function() {

                /**
                 * Properties of a GetStripePaymentIntentClientSecretRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetStripePaymentIntentClientSecretRequest
                 * @property {string|null} [checkoutSessionToken] GetStripePaymentIntentClientSecretRequest checkoutSessionToken
                 */

                /**
                 * Constructs a new GetStripePaymentIntentClientSecretRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetStripePaymentIntentClientSecretRequest.
                 * @implements IGetStripePaymentIntentClientSecretRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretRequest=} [properties] Properties to set
                 */
                function GetStripePaymentIntentClientSecretRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetStripePaymentIntentClientSecretRequest checkoutSessionToken.
                 * @member {string} checkoutSessionToken
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @instance
                 */
                GetStripePaymentIntentClientSecretRequest.prototype.checkoutSessionToken = "";

                /**
                 * Creates a new GetStripePaymentIntentClientSecretRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretRequest} GetStripePaymentIntentClientSecretRequest instance
                 */
                GetStripePaymentIntentClientSecretRequest.create = function create(properties) {
                    return new GetStripePaymentIntentClientSecretRequest(properties);
                };

                /**
                 * Encodes the specified GetStripePaymentIntentClientSecretRequest message. Does not implicitly {@link visor.clientrpc.GetStripePaymentIntentClientSecretRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretRequest} message GetStripePaymentIntentClientSecretRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStripePaymentIntentClientSecretRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.checkoutSessionToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetStripePaymentIntentClientSecretRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetStripePaymentIntentClientSecretRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretRequest} message GetStripePaymentIntentClientSecretRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStripePaymentIntentClientSecretRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetStripePaymentIntentClientSecretRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretRequest} GetStripePaymentIntentClientSecretRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStripePaymentIntentClientSecretRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetStripePaymentIntentClientSecretRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.checkoutSessionToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetStripePaymentIntentClientSecretRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretRequest} GetStripePaymentIntentClientSecretRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStripePaymentIntentClientSecretRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetStripePaymentIntentClientSecretRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStripePaymentIntentClientSecretRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        if (!$util.isString(message.checkoutSessionToken))
                            return "checkoutSessionToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetStripePaymentIntentClientSecretRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretRequest} GetStripePaymentIntentClientSecretRequest
                 */
                GetStripePaymentIntentClientSecretRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetStripePaymentIntentClientSecretRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetStripePaymentIntentClientSecretRequest();
                    if (object.checkoutSessionToken != null)
                        message.checkoutSessionToken = String(object.checkoutSessionToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetStripePaymentIntentClientSecretRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @static
                 * @param {visor.clientrpc.GetStripePaymentIntentClientSecretRequest} message GetStripePaymentIntentClientSecretRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStripePaymentIntentClientSecretRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.checkoutSessionToken = "";
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        object.checkoutSessionToken = message.checkoutSessionToken;
                    return object;
                };

                /**
                 * Converts this GetStripePaymentIntentClientSecretRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStripePaymentIntentClientSecretRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetStripePaymentIntentClientSecretRequest;
            })();

            clientrpc.GetStripePaymentIntentClientSecretResponse = (function() {

                /**
                 * Properties of a GetStripePaymentIntentClientSecretResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetStripePaymentIntentClientSecretResponse
                 * @property {string|null} [stripePaymentIntentClientSecret] GetStripePaymentIntentClientSecretResponse stripePaymentIntentClientSecret
                 */

                /**
                 * Constructs a new GetStripePaymentIntentClientSecretResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetStripePaymentIntentClientSecretResponse.
                 * @implements IGetStripePaymentIntentClientSecretResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretResponse=} [properties] Properties to set
                 */
                function GetStripePaymentIntentClientSecretResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetStripePaymentIntentClientSecretResponse stripePaymentIntentClientSecret.
                 * @member {string} stripePaymentIntentClientSecret
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @instance
                 */
                GetStripePaymentIntentClientSecretResponse.prototype.stripePaymentIntentClientSecret = "";

                /**
                 * Creates a new GetStripePaymentIntentClientSecretResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretResponse} GetStripePaymentIntentClientSecretResponse instance
                 */
                GetStripePaymentIntentClientSecretResponse.create = function create(properties) {
                    return new GetStripePaymentIntentClientSecretResponse(properties);
                };

                /**
                 * Encodes the specified GetStripePaymentIntentClientSecretResponse message. Does not implicitly {@link visor.clientrpc.GetStripePaymentIntentClientSecretResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretResponse} message GetStripePaymentIntentClientSecretResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStripePaymentIntentClientSecretResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stripePaymentIntentClientSecret != null && message.hasOwnProperty("stripePaymentIntentClientSecret"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stripePaymentIntentClientSecret);
                    return writer;
                };

                /**
                 * Encodes the specified GetStripePaymentIntentClientSecretResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetStripePaymentIntentClientSecretResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {visor.clientrpc.IGetStripePaymentIntentClientSecretResponse} message GetStripePaymentIntentClientSecretResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetStripePaymentIntentClientSecretResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetStripePaymentIntentClientSecretResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretResponse} GetStripePaymentIntentClientSecretResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStripePaymentIntentClientSecretResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetStripePaymentIntentClientSecretResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stripePaymentIntentClientSecret = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetStripePaymentIntentClientSecretResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretResponse} GetStripePaymentIntentClientSecretResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetStripePaymentIntentClientSecretResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetStripePaymentIntentClientSecretResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetStripePaymentIntentClientSecretResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stripePaymentIntentClientSecret != null && message.hasOwnProperty("stripePaymentIntentClientSecret"))
                        if (!$util.isString(message.stripePaymentIntentClientSecret))
                            return "stripePaymentIntentClientSecret: string expected";
                    return null;
                };

                /**
                 * Creates a GetStripePaymentIntentClientSecretResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetStripePaymentIntentClientSecretResponse} GetStripePaymentIntentClientSecretResponse
                 */
                GetStripePaymentIntentClientSecretResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetStripePaymentIntentClientSecretResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetStripePaymentIntentClientSecretResponse();
                    if (object.stripePaymentIntentClientSecret != null)
                        message.stripePaymentIntentClientSecret = String(object.stripePaymentIntentClientSecret);
                    return message;
                };

                /**
                 * Creates a plain object from a GetStripePaymentIntentClientSecretResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @static
                 * @param {visor.clientrpc.GetStripePaymentIntentClientSecretResponse} message GetStripePaymentIntentClientSecretResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetStripePaymentIntentClientSecretResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.stripePaymentIntentClientSecret = "";
                    if (message.stripePaymentIntentClientSecret != null && message.hasOwnProperty("stripePaymentIntentClientSecret"))
                        object.stripePaymentIntentClientSecret = message.stripePaymentIntentClientSecret;
                    return object;
                };

                /**
                 * Converts this GetStripePaymentIntentClientSecretResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetStripePaymentIntentClientSecretResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetStripePaymentIntentClientSecretResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetStripePaymentIntentClientSecretResponse;
            })();

            clientrpc.UpdateCheckoutSessionRequest = (function() {

                /**
                 * Properties of an UpdateCheckoutSessionRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateCheckoutSessionRequest
                 * @property {string|null} [checkoutSessionToken] UpdateCheckoutSessionRequest checkoutSessionToken
                 * @property {visor.common.money.IAmount|null} [newInitiationFee] UpdateCheckoutSessionRequest newInitiationFee
                 * @property {visor.clientrpc.UpdateCheckoutSessionRequest.IApplyCloseAccountDiscountRequest|null} [applyCloseAccountDiscount] UpdateCheckoutSessionRequest applyCloseAccountDiscount
                 */

                /**
                 * Constructs a new UpdateCheckoutSessionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateCheckoutSessionRequest.
                 * @implements IUpdateCheckoutSessionRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateCheckoutSessionRequest=} [properties] Properties to set
                 */
                function UpdateCheckoutSessionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateCheckoutSessionRequest checkoutSessionToken.
                 * @member {string} checkoutSessionToken
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @instance
                 */
                UpdateCheckoutSessionRequest.prototype.checkoutSessionToken = "";

                /**
                 * UpdateCheckoutSessionRequest newInitiationFee.
                 * @member {visor.common.money.IAmount|null|undefined} newInitiationFee
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @instance
                 */
                UpdateCheckoutSessionRequest.prototype.newInitiationFee = null;

                /**
                 * UpdateCheckoutSessionRequest applyCloseAccountDiscount.
                 * @member {visor.clientrpc.UpdateCheckoutSessionRequest.IApplyCloseAccountDiscountRequest|null|undefined} applyCloseAccountDiscount
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @instance
                 */
                UpdateCheckoutSessionRequest.prototype.applyCloseAccountDiscount = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * UpdateCheckoutSessionRequest change.
                 * @member {"newInitiationFee"|"applyCloseAccountDiscount"|undefined} change
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @instance
                 */
                Object.defineProperty(UpdateCheckoutSessionRequest.prototype, "change", {
                    get: $util.oneOfGetter($oneOfFields = ["newInitiationFee", "applyCloseAccountDiscount"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new UpdateCheckoutSessionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateCheckoutSessionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateCheckoutSessionRequest} UpdateCheckoutSessionRequest instance
                 */
                UpdateCheckoutSessionRequest.create = function create(properties) {
                    return new UpdateCheckoutSessionRequest(properties);
                };

                /**
                 * Encodes the specified UpdateCheckoutSessionRequest message. Does not implicitly {@link visor.clientrpc.UpdateCheckoutSessionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateCheckoutSessionRequest} message UpdateCheckoutSessionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCheckoutSessionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.checkoutSessionToken);
                    if (message.newInitiationFee != null && message.hasOwnProperty("newInitiationFee"))
                        $root.visor.common.money.Amount.encode(message.newInitiationFee, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.applyCloseAccountDiscount != null && message.hasOwnProperty("applyCloseAccountDiscount"))
                        $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest.encode(message.applyCloseAccountDiscount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateCheckoutSessionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateCheckoutSessionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateCheckoutSessionRequest} message UpdateCheckoutSessionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCheckoutSessionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateCheckoutSessionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateCheckoutSessionRequest} UpdateCheckoutSessionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCheckoutSessionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateCheckoutSessionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.checkoutSessionToken = reader.string();
                            break;
                        case 2:
                            message.newInitiationFee = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.applyCloseAccountDiscount = $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateCheckoutSessionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateCheckoutSessionRequest} UpdateCheckoutSessionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCheckoutSessionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateCheckoutSessionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateCheckoutSessionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        if (!$util.isString(message.checkoutSessionToken))
                            return "checkoutSessionToken: string expected";
                    if (message.newInitiationFee != null && message.hasOwnProperty("newInitiationFee")) {
                        properties.change = 1;
                        {
                            var error = $root.visor.common.money.Amount.verify(message.newInitiationFee);
                            if (error)
                                return "newInitiationFee." + error;
                        }
                    }
                    if (message.applyCloseAccountDiscount != null && message.hasOwnProperty("applyCloseAccountDiscount")) {
                        if (properties.change === 1)
                            return "change: multiple values";
                        properties.change = 1;
                        {
                            var error = $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest.verify(message.applyCloseAccountDiscount);
                            if (error)
                                return "applyCloseAccountDiscount." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an UpdateCheckoutSessionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateCheckoutSessionRequest} UpdateCheckoutSessionRequest
                 */
                UpdateCheckoutSessionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateCheckoutSessionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateCheckoutSessionRequest();
                    if (object.checkoutSessionToken != null)
                        message.checkoutSessionToken = String(object.checkoutSessionToken);
                    if (object.newInitiationFee != null) {
                        if (typeof object.newInitiationFee !== "object")
                            throw TypeError(".visor.clientrpc.UpdateCheckoutSessionRequest.newInitiationFee: object expected");
                        message.newInitiationFee = $root.visor.common.money.Amount.fromObject(object.newInitiationFee);
                    }
                    if (object.applyCloseAccountDiscount != null) {
                        if (typeof object.applyCloseAccountDiscount !== "object")
                            throw TypeError(".visor.clientrpc.UpdateCheckoutSessionRequest.applyCloseAccountDiscount: object expected");
                        message.applyCloseAccountDiscount = $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest.fromObject(object.applyCloseAccountDiscount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateCheckoutSessionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.UpdateCheckoutSessionRequest} message UpdateCheckoutSessionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateCheckoutSessionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.checkoutSessionToken = "";
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        object.checkoutSessionToken = message.checkoutSessionToken;
                    if (message.newInitiationFee != null && message.hasOwnProperty("newInitiationFee")) {
                        object.newInitiationFee = $root.visor.common.money.Amount.toObject(message.newInitiationFee, options);
                        if (options.oneofs)
                            object.change = "newInitiationFee";
                    }
                    if (message.applyCloseAccountDiscount != null && message.hasOwnProperty("applyCloseAccountDiscount")) {
                        object.applyCloseAccountDiscount = $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest.toObject(message.applyCloseAccountDiscount, options);
                        if (options.oneofs)
                            object.change = "applyCloseAccountDiscount";
                    }
                    return object;
                };

                /**
                 * Converts this UpdateCheckoutSessionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateCheckoutSessionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest = (function() {

                    /**
                     * Properties of an ApplyCloseAccountDiscountRequest.
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                     * @interface IApplyCloseAccountDiscountRequest
                     */

                    /**
                     * Constructs a new ApplyCloseAccountDiscountRequest.
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest
                     * @classdesc Represents an ApplyCloseAccountDiscountRequest.
                     * @implements IApplyCloseAccountDiscountRequest
                     * @constructor
                     * @param {visor.clientrpc.UpdateCheckoutSessionRequest.IApplyCloseAccountDiscountRequest=} [properties] Properties to set
                     */
                    function ApplyCloseAccountDiscountRequest(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new ApplyCloseAccountDiscountRequest instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {visor.clientrpc.UpdateCheckoutSessionRequest.IApplyCloseAccountDiscountRequest=} [properties] Properties to set
                     * @returns {visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest} ApplyCloseAccountDiscountRequest instance
                     */
                    ApplyCloseAccountDiscountRequest.create = function create(properties) {
                        return new ApplyCloseAccountDiscountRequest(properties);
                    };

                    /**
                     * Encodes the specified ApplyCloseAccountDiscountRequest message. Does not implicitly {@link visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {visor.clientrpc.UpdateCheckoutSessionRequest.IApplyCloseAccountDiscountRequest} message ApplyCloseAccountDiscountRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyCloseAccountDiscountRequest.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified ApplyCloseAccountDiscountRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {visor.clientrpc.UpdateCheckoutSessionRequest.IApplyCloseAccountDiscountRequest} message ApplyCloseAccountDiscountRequest message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyCloseAccountDiscountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ApplyCloseAccountDiscountRequest message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest} ApplyCloseAccountDiscountRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyCloseAccountDiscountRequest.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ApplyCloseAccountDiscountRequest message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest} ApplyCloseAccountDiscountRequest
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyCloseAccountDiscountRequest.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ApplyCloseAccountDiscountRequest message.
                     * @function verify
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ApplyCloseAccountDiscountRequest.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates an ApplyCloseAccountDiscountRequest message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest} ApplyCloseAccountDiscountRequest
                     */
                    ApplyCloseAccountDiscountRequest.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest)
                            return object;
                        return new $root.visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest();
                    };

                    /**
                     * Creates a plain object from an ApplyCloseAccountDiscountRequest message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @static
                     * @param {visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest} message ApplyCloseAccountDiscountRequest
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ApplyCloseAccountDiscountRequest.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this ApplyCloseAccountDiscountRequest to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.UpdateCheckoutSessionRequest.ApplyCloseAccountDiscountRequest
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ApplyCloseAccountDiscountRequest.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ApplyCloseAccountDiscountRequest;
                })();

                return UpdateCheckoutSessionRequest;
            })();

            clientrpc.UpdateCheckoutSessionResponse = (function() {

                /**
                 * Properties of an UpdateCheckoutSessionResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateCheckoutSessionResponse
                 */

                /**
                 * Constructs a new UpdateCheckoutSessionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateCheckoutSessionResponse.
                 * @implements IUpdateCheckoutSessionResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateCheckoutSessionResponse=} [properties] Properties to set
                 */
                function UpdateCheckoutSessionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateCheckoutSessionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateCheckoutSessionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateCheckoutSessionResponse} UpdateCheckoutSessionResponse instance
                 */
                UpdateCheckoutSessionResponse.create = function create(properties) {
                    return new UpdateCheckoutSessionResponse(properties);
                };

                /**
                 * Encodes the specified UpdateCheckoutSessionResponse message. Does not implicitly {@link visor.clientrpc.UpdateCheckoutSessionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateCheckoutSessionResponse} message UpdateCheckoutSessionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCheckoutSessionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateCheckoutSessionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateCheckoutSessionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateCheckoutSessionResponse} message UpdateCheckoutSessionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateCheckoutSessionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateCheckoutSessionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateCheckoutSessionResponse} UpdateCheckoutSessionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCheckoutSessionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateCheckoutSessionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateCheckoutSessionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateCheckoutSessionResponse} UpdateCheckoutSessionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateCheckoutSessionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateCheckoutSessionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateCheckoutSessionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateCheckoutSessionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateCheckoutSessionResponse} UpdateCheckoutSessionResponse
                 */
                UpdateCheckoutSessionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateCheckoutSessionResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateCheckoutSessionResponse();
                };

                /**
                 * Creates a plain object from an UpdateCheckoutSessionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.UpdateCheckoutSessionResponse} message UpdateCheckoutSessionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateCheckoutSessionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateCheckoutSessionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateCheckoutSessionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateCheckoutSessionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateCheckoutSessionResponse;
            })();

            clientrpc.FinishCheckoutSessionRequest = (function() {

                /**
                 * Properties of a FinishCheckoutSessionRequest.
                 * @memberof visor.clientrpc
                 * @interface IFinishCheckoutSessionRequest
                 * @property {string|null} [checkoutSessionToken] FinishCheckoutSessionRequest checkoutSessionToken
                 * @property {google.protobuf.ITimestamp|null} [acceptedBillingTermsAt] FinishCheckoutSessionRequest acceptedBillingTermsAt
                 * @property {string|null} [paymentMethodId] FinishCheckoutSessionRequest paymentMethodId
                 * @property {boolean|null} [enableFailureResponses] FinishCheckoutSessionRequest enableFailureResponses
                 */

                /**
                 * Constructs a new FinishCheckoutSessionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FinishCheckoutSessionRequest.
                 * @implements IFinishCheckoutSessionRequest
                 * @constructor
                 * @param {visor.clientrpc.IFinishCheckoutSessionRequest=} [properties] Properties to set
                 */
                function FinishCheckoutSessionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FinishCheckoutSessionRequest checkoutSessionToken.
                 * @member {string} checkoutSessionToken
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @instance
                 */
                FinishCheckoutSessionRequest.prototype.checkoutSessionToken = "";

                /**
                 * FinishCheckoutSessionRequest acceptedBillingTermsAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} acceptedBillingTermsAt
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @instance
                 */
                FinishCheckoutSessionRequest.prototype.acceptedBillingTermsAt = null;

                /**
                 * FinishCheckoutSessionRequest paymentMethodId.
                 * @member {string} paymentMethodId
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @instance
                 */
                FinishCheckoutSessionRequest.prototype.paymentMethodId = "";

                /**
                 * FinishCheckoutSessionRequest enableFailureResponses.
                 * @member {boolean} enableFailureResponses
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @instance
                 */
                FinishCheckoutSessionRequest.prototype.enableFailureResponses = false;

                /**
                 * Creates a new FinishCheckoutSessionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.IFinishCheckoutSessionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.FinishCheckoutSessionRequest} FinishCheckoutSessionRequest instance
                 */
                FinishCheckoutSessionRequest.create = function create(properties) {
                    return new FinishCheckoutSessionRequest(properties);
                };

                /**
                 * Encodes the specified FinishCheckoutSessionRequest message. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.IFinishCheckoutSessionRequest} message FinishCheckoutSessionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCheckoutSessionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.checkoutSessionToken);
                    if (message.acceptedBillingTermsAt != null && message.hasOwnProperty("acceptedBillingTermsAt"))
                        $root.google.protobuf.Timestamp.encode(message.acceptedBillingTermsAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.paymentMethodId);
                    if (message.enableFailureResponses != null && message.hasOwnProperty("enableFailureResponses"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.enableFailureResponses);
                    return writer;
                };

                /**
                 * Encodes the specified FinishCheckoutSessionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.IFinishCheckoutSessionRequest} message FinishCheckoutSessionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCheckoutSessionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FinishCheckoutSessionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FinishCheckoutSessionRequest} FinishCheckoutSessionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCheckoutSessionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishCheckoutSessionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.checkoutSessionToken = reader.string();
                            break;
                        case 2:
                            message.acceptedBillingTermsAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.paymentMethodId = reader.string();
                            break;
                        case 4:
                            message.enableFailureResponses = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FinishCheckoutSessionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FinishCheckoutSessionRequest} FinishCheckoutSessionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCheckoutSessionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FinishCheckoutSessionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FinishCheckoutSessionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        if (!$util.isString(message.checkoutSessionToken))
                            return "checkoutSessionToken: string expected";
                    if (message.acceptedBillingTermsAt != null && message.hasOwnProperty("acceptedBillingTermsAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.acceptedBillingTermsAt);
                        if (error)
                            return "acceptedBillingTermsAt." + error;
                    }
                    if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                        if (!$util.isString(message.paymentMethodId))
                            return "paymentMethodId: string expected";
                    if (message.enableFailureResponses != null && message.hasOwnProperty("enableFailureResponses"))
                        if (typeof message.enableFailureResponses !== "boolean")
                            return "enableFailureResponses: boolean expected";
                    return null;
                };

                /**
                 * Creates a FinishCheckoutSessionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FinishCheckoutSessionRequest} FinishCheckoutSessionRequest
                 */
                FinishCheckoutSessionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FinishCheckoutSessionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.FinishCheckoutSessionRequest();
                    if (object.checkoutSessionToken != null)
                        message.checkoutSessionToken = String(object.checkoutSessionToken);
                    if (object.acceptedBillingTermsAt != null)
                        if (object.acceptedBillingTermsAt === null)
                            message.acceptedBillingTermsAt = {};
                        else
                            message.acceptedBillingTermsAt = {
                                seconds: Math.floor(object.acceptedBillingTermsAt.getTime() / 1000),
                                nanos: object.acceptedBillingTermsAt.getMilliseconds() * 1000000
                            };
                    if (object.paymentMethodId != null)
                        message.paymentMethodId = String(object.paymentMethodId);
                    if (object.enableFailureResponses != null)
                        message.enableFailureResponses = Boolean(object.enableFailureResponses);
                    return message;
                };

                /**
                 * Creates a plain object from a FinishCheckoutSessionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @static
                 * @param {visor.clientrpc.FinishCheckoutSessionRequest} message FinishCheckoutSessionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FinishCheckoutSessionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.checkoutSessionToken = "";
                        object.acceptedBillingTermsAt = null;
                        object.paymentMethodId = "";
                        object.enableFailureResponses = false;
                    }
                    if (message.checkoutSessionToken != null && message.hasOwnProperty("checkoutSessionToken"))
                        object.checkoutSessionToken = message.checkoutSessionToken;
                    if (message.acceptedBillingTermsAt != null && message.hasOwnProperty("acceptedBillingTermsAt"))
                        object.acceptedBillingTermsAt = new Date(message.acceptedBillingTermsAt.seconds * 1000 + message.acceptedBillingTermsAt.nanos / 1000000);
                    if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                        object.paymentMethodId = message.paymentMethodId;
                    if (message.enableFailureResponses != null && message.hasOwnProperty("enableFailureResponses"))
                        object.enableFailureResponses = message.enableFailureResponses;
                    return object;
                };

                /**
                 * Converts this FinishCheckoutSessionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FinishCheckoutSessionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FinishCheckoutSessionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FinishCheckoutSessionRequest;
            })();

            clientrpc.FinishCheckoutSessionResponse = (function() {

                /**
                 * Properties of a FinishCheckoutSessionResponse.
                 * @memberof visor.clientrpc
                 * @interface IFinishCheckoutSessionResponse
                 * @property {visor.clientrpc.FinishCheckoutSessionResponse.ISuccess|null} [succeeded] FinishCheckoutSessionResponse succeeded
                 * @property {visor.clientrpc.stripe.DeclineCode|null} [cardDeclined] FinishCheckoutSessionResponse cardDeclined
                 * @property {visor.clientrpc.FinishCheckoutSessionResponse.IPayItForwardQualification|null} [payItForwardQualified] FinishCheckoutSessionResponse payItForwardQualified
                 */

                /**
                 * Constructs a new FinishCheckoutSessionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FinishCheckoutSessionResponse.
                 * @implements IFinishCheckoutSessionResponse
                 * @constructor
                 * @param {visor.clientrpc.IFinishCheckoutSessionResponse=} [properties] Properties to set
                 */
                function FinishCheckoutSessionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FinishCheckoutSessionResponse succeeded.
                 * @member {visor.clientrpc.FinishCheckoutSessionResponse.ISuccess|null|undefined} succeeded
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @instance
                 */
                FinishCheckoutSessionResponse.prototype.succeeded = null;

                /**
                 * FinishCheckoutSessionResponse cardDeclined.
                 * @member {visor.clientrpc.stripe.DeclineCode} cardDeclined
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @instance
                 */
                FinishCheckoutSessionResponse.prototype.cardDeclined = 0;

                /**
                 * FinishCheckoutSessionResponse payItForwardQualified.
                 * @member {visor.clientrpc.FinishCheckoutSessionResponse.IPayItForwardQualification|null|undefined} payItForwardQualified
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @instance
                 */
                FinishCheckoutSessionResponse.prototype.payItForwardQualified = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * FinishCheckoutSessionResponse result.
                 * @member {"succeeded"|"cardDeclined"|"payItForwardQualified"|undefined} result
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @instance
                 */
                Object.defineProperty(FinishCheckoutSessionResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["succeeded", "cardDeclined", "payItForwardQualified"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new FinishCheckoutSessionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.IFinishCheckoutSessionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.FinishCheckoutSessionResponse} FinishCheckoutSessionResponse instance
                 */
                FinishCheckoutSessionResponse.create = function create(properties) {
                    return new FinishCheckoutSessionResponse(properties);
                };

                /**
                 * Encodes the specified FinishCheckoutSessionResponse message. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.IFinishCheckoutSessionResponse} message FinishCheckoutSessionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCheckoutSessionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.succeeded != null && message.hasOwnProperty("succeeded"))
                        $root.visor.clientrpc.FinishCheckoutSessionResponse.Success.encode(message.succeeded, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.cardDeclined != null && message.hasOwnProperty("cardDeclined"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.cardDeclined);
                    if (message.payItForwardQualified != null && message.hasOwnProperty("payItForwardQualified"))
                        $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification.encode(message.payItForwardQualified, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified FinishCheckoutSessionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.IFinishCheckoutSessionResponse} message FinishCheckoutSessionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCheckoutSessionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FinishCheckoutSessionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FinishCheckoutSessionResponse} FinishCheckoutSessionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCheckoutSessionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishCheckoutSessionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.succeeded = $root.visor.clientrpc.FinishCheckoutSessionResponse.Success.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.cardDeclined = reader.int32();
                            break;
                        case 3:
                            message.payItForwardQualified = $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FinishCheckoutSessionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FinishCheckoutSessionResponse} FinishCheckoutSessionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCheckoutSessionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FinishCheckoutSessionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FinishCheckoutSessionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.succeeded != null && message.hasOwnProperty("succeeded")) {
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.FinishCheckoutSessionResponse.Success.verify(message.succeeded);
                            if (error)
                                return "succeeded." + error;
                        }
                    }
                    if (message.cardDeclined != null && message.hasOwnProperty("cardDeclined")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        switch (message.cardDeclined) {
                        default:
                            return "cardDeclined: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                        case 15:
                        case 16:
                        case 17:
                        case 18:
                        case 19:
                        case 20:
                        case 21:
                        case 22:
                        case 23:
                        case 24:
                        case 25:
                        case 26:
                        case 27:
                        case 28:
                        case 29:
                        case 30:
                        case 31:
                        case 32:
                        case 33:
                        case 34:
                        case 35:
                        case 36:
                        case 37:
                        case 38:
                        case 39:
                        case 40:
                        case 41:
                        case 42:
                        case 43:
                        case 44:
                            break;
                        }
                    }
                    if (message.payItForwardQualified != null && message.hasOwnProperty("payItForwardQualified")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification.verify(message.payItForwardQualified);
                            if (error)
                                return "payItForwardQualified." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a FinishCheckoutSessionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FinishCheckoutSessionResponse} FinishCheckoutSessionResponse
                 */
                FinishCheckoutSessionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FinishCheckoutSessionResponse)
                        return object;
                    var message = new $root.visor.clientrpc.FinishCheckoutSessionResponse();
                    if (object.succeeded != null) {
                        if (typeof object.succeeded !== "object")
                            throw TypeError(".visor.clientrpc.FinishCheckoutSessionResponse.succeeded: object expected");
                        message.succeeded = $root.visor.clientrpc.FinishCheckoutSessionResponse.Success.fromObject(object.succeeded);
                    }
                    switch (object.cardDeclined) {
                    case "DECLINE_CODE_INVALID":
                    case 0:
                        message.cardDeclined = 0;
                        break;
                    case "DECLINE_CODE_AUTHENTICATION_REQUIRED":
                    case 1:
                        message.cardDeclined = 1;
                        break;
                    case "DECLINE_CODE_APPROVE_WITH_ID":
                    case 2:
                        message.cardDeclined = 2;
                        break;
                    case "DECLINE_CODE_CALL_ISSUER":
                    case 3:
                        message.cardDeclined = 3;
                        break;
                    case "DECLINE_CODE_CARD_NOT_SUPPORTED":
                    case 4:
                        message.cardDeclined = 4;
                        break;
                    case "DECLINE_CODE_CARD_VELOCITY_EXCEEDED":
                    case 5:
                        message.cardDeclined = 5;
                        break;
                    case "DECLINE_CODE_CURRENCY_NOT_SUPPORTED":
                    case 6:
                        message.cardDeclined = 6;
                        break;
                    case "DECLINE_CODE_DO_NOT_HONOR":
                    case 7:
                        message.cardDeclined = 7;
                        break;
                    case "DECLINE_CODE_DO_NOT_TRY_AGAIN":
                    case 8:
                        message.cardDeclined = 8;
                        break;
                    case "DECLINE_CODE_DUPLICATE_TRANSACTION":
                    case 9:
                        message.cardDeclined = 9;
                        break;
                    case "DECLINE_CODE_EXPIRED_CARD":
                    case 10:
                        message.cardDeclined = 10;
                        break;
                    case "DECLINE_CODE_FRAUDULENT":
                    case 11:
                        message.cardDeclined = 11;
                        break;
                    case "DECLINE_CODE_GENERIC_DECLINE":
                    case 12:
                        message.cardDeclined = 12;
                        break;
                    case "DECLINE_CODE_INCORRECT_NUMBER":
                    case 13:
                        message.cardDeclined = 13;
                        break;
                    case "DECLINE_CODE_INCORRECT_CVC":
                    case 14:
                        message.cardDeclined = 14;
                        break;
                    case "DECLINE_CODE_INCORRECT_PIN":
                    case 15:
                        message.cardDeclined = 15;
                        break;
                    case "DECLINE_CODE_INCORRECT_ZIP":
                    case 16:
                        message.cardDeclined = 16;
                        break;
                    case "DECLINE_CODE_INSUFFICIENT_FUNDS":
                    case 17:
                        message.cardDeclined = 17;
                        break;
                    case "DECLINE_CODE_INVALID_ACCOUNT":
                    case 18:
                        message.cardDeclined = 18;
                        break;
                    case "DECLINE_CODE_INVALID_AMOUNT":
                    case 19:
                        message.cardDeclined = 19;
                        break;
                    case "DECLINE_CODE_INVALID_CVC":
                    case 20:
                        message.cardDeclined = 20;
                        break;
                    case "DECLINE_CODE_INVALID_EXPIRY_YEAR":
                    case 21:
                        message.cardDeclined = 21;
                        break;
                    case "DECLINE_CODE_INVALID_NUMBER":
                    case 22:
                        message.cardDeclined = 22;
                        break;
                    case "DECLINE_CODE_INVALID_PIN":
                    case 23:
                        message.cardDeclined = 23;
                        break;
                    case "DECLINE_CODE_ISSUER_NOT_AVAILABLE":
                    case 24:
                        message.cardDeclined = 24;
                        break;
                    case "DECLINE_CODE_LOST_CARD":
                    case 25:
                        message.cardDeclined = 25;
                        break;
                    case "DECLINE_CODE_MERCHANT_BLACKLIST":
                    case 26:
                        message.cardDeclined = 26;
                        break;
                    case "DECLINE_CODE_NEW_ACCOUNT_INFORMATION_AVAILABLE":
                    case 27:
                        message.cardDeclined = 27;
                        break;
                    case "DECLINE_CODE_NO_ACTION_TAKEN":
                    case 28:
                        message.cardDeclined = 28;
                        break;
                    case "DECLINE_CODE_NOT_PERMITTED":
                    case 29:
                        message.cardDeclined = 29;
                        break;
                    case "DECLINE_CODE_PICKUP_CARD":
                    case 30:
                        message.cardDeclined = 30;
                        break;
                    case "DECLINE_CODE_PINTRY_EXCEEDED":
                    case 31:
                        message.cardDeclined = 31;
                        break;
                    case "DECLINE_CODE_PROCESSING_ERROR":
                    case 32:
                        message.cardDeclined = 32;
                        break;
                    case "DECLINE_CODE_REENTER_TRANSACTION":
                    case 33:
                        message.cardDeclined = 33;
                        break;
                    case "DECLINE_CODE_RESTRICTED_CARD":
                    case 34:
                        message.cardDeclined = 34;
                        break;
                    case "DECLINE_CODE_REVOCATION_OF_ALL_AUTHORIZATIONS":
                    case 35:
                        message.cardDeclined = 35;
                        break;
                    case "DECLINE_CODE_REVOCATION_OF_AUTHORIZATION":
                    case 36:
                        message.cardDeclined = 36;
                        break;
                    case "DECLINE_CODE_SECURITY_VIOLATION":
                    case 37:
                        message.cardDeclined = 37;
                        break;
                    case "DECLINE_CODE_SERVICE_NOT_ALLOWED":
                    case 38:
                        message.cardDeclined = 38;
                        break;
                    case "DECLINE_CODE_STOLEN_CARD":
                    case 39:
                        message.cardDeclined = 39;
                        break;
                    case "DECLINE_CODE_STOP_PAYMENT_ORDER":
                    case 40:
                        message.cardDeclined = 40;
                        break;
                    case "DECLINE_CODE_TEST_MODE_DECLINE":
                    case 41:
                        message.cardDeclined = 41;
                        break;
                    case "DECLINE_CODE_TRANSACTION_NOT_ALLOWED":
                    case 42:
                        message.cardDeclined = 42;
                        break;
                    case "DECLINE_CODE_TRY_AGAIN_LATER":
                    case 43:
                        message.cardDeclined = 43;
                        break;
                    case "DECLINE_CODE_WITHDRAWAL_COUNT_LIMIT_EXCEEDED":
                    case 44:
                        message.cardDeclined = 44;
                        break;
                    }
                    if (object.payItForwardQualified != null) {
                        if (typeof object.payItForwardQualified !== "object")
                            throw TypeError(".visor.clientrpc.FinishCheckoutSessionResponse.payItForwardQualified: object expected");
                        message.payItForwardQualified = $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification.fromObject(object.payItForwardQualified);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a FinishCheckoutSessionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @static
                 * @param {visor.clientrpc.FinishCheckoutSessionResponse} message FinishCheckoutSessionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FinishCheckoutSessionResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.succeeded != null && message.hasOwnProperty("succeeded")) {
                        object.succeeded = $root.visor.clientrpc.FinishCheckoutSessionResponse.Success.toObject(message.succeeded, options);
                        if (options.oneofs)
                            object.result = "succeeded";
                    }
                    if (message.cardDeclined != null && message.hasOwnProperty("cardDeclined")) {
                        object.cardDeclined = options.enums === String ? $root.visor.clientrpc.stripe.DeclineCode[message.cardDeclined] : message.cardDeclined;
                        if (options.oneofs)
                            object.result = "cardDeclined";
                    }
                    if (message.payItForwardQualified != null && message.hasOwnProperty("payItForwardQualified")) {
                        object.payItForwardQualified = $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification.toObject(message.payItForwardQualified, options);
                        if (options.oneofs)
                            object.result = "payItForwardQualified";
                    }
                    return object;
                };

                /**
                 * Converts this FinishCheckoutSessionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FinishCheckoutSessionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                FinishCheckoutSessionResponse.Success = (function() {

                    /**
                     * Properties of a Success.
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                     * @interface ISuccess
                     */

                    /**
                     * Constructs a new Success.
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                     * @classdesc Represents a Success.
                     * @implements ISuccess
                     * @constructor
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.ISuccess=} [properties] Properties to set
                     */
                    function Success(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new Success instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.ISuccess=} [properties] Properties to set
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.Success} Success instance
                     */
                    Success.create = function create(properties) {
                        return new Success(properties);
                    };

                    /**
                     * Encodes the specified Success message. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionResponse.Success.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.ISuccess} message Success message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Success.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified Success message, length delimited. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionResponse.Success.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.ISuccess} message Success message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Success.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Success message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.Success} Success
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Success.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishCheckoutSessionResponse.Success();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Success message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.Success} Success
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Success.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Success message.
                     * @function verify
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Success.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a Success message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.Success} Success
                     */
                    Success.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.FinishCheckoutSessionResponse.Success)
                            return object;
                        return new $root.visor.clientrpc.FinishCheckoutSessionResponse.Success();
                    };

                    /**
                     * Creates a plain object from a Success message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.Success} message Success
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Success.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Success to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.Success
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Success.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Success;
                })();

                FinishCheckoutSessionResponse.PayItForwardQualification = (function() {

                    /**
                     * Properties of a PayItForwardQualification.
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                     * @interface IPayItForwardQualification
                     */

                    /**
                     * Constructs a new PayItForwardQualification.
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse
                     * @classdesc Represents a PayItForwardQualification.
                     * @implements IPayItForwardQualification
                     * @constructor
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.IPayItForwardQualification=} [properties] Properties to set
                     */
                    function PayItForwardQualification(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new PayItForwardQualification instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.IPayItForwardQualification=} [properties] Properties to set
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification} PayItForwardQualification instance
                     */
                    PayItForwardQualification.create = function create(properties) {
                        return new PayItForwardQualification(properties);
                    };

                    /**
                     * Encodes the specified PayItForwardQualification message. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.IPayItForwardQualification} message PayItForwardQualification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PayItForwardQualification.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified PayItForwardQualification message, length delimited. Does not implicitly {@link visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.IPayItForwardQualification} message PayItForwardQualification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PayItForwardQualification.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PayItForwardQualification message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification} PayItForwardQualification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PayItForwardQualification.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PayItForwardQualification message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification} PayItForwardQualification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PayItForwardQualification.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PayItForwardQualification message.
                     * @function verify
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PayItForwardQualification.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a PayItForwardQualification message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification} PayItForwardQualification
                     */
                    PayItForwardQualification.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification)
                            return object;
                        return new $root.visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification();
                    };

                    /**
                     * Creates a plain object from a PayItForwardQualification message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @static
                     * @param {visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification} message PayItForwardQualification
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PayItForwardQualification.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this PayItForwardQualification to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.FinishCheckoutSessionResponse.PayItForwardQualification
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PayItForwardQualification.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PayItForwardQualification;
                })();

                return FinishCheckoutSessionResponse;
            })();

            clientrpc.UpdateProfileNameRequest = (function() {

                /**
                 * Properties of an UpdateProfileNameRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfileNameRequest
                 * @property {string|null} [firstName] UpdateProfileNameRequest firstName
                 * @property {string|null} [lastName] UpdateProfileNameRequest lastName
                 */

                /**
                 * Constructs a new UpdateProfileNameRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfileNameRequest.
                 * @implements IUpdateProfileNameRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfileNameRequest=} [properties] Properties to set
                 */
                function UpdateProfileNameRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateProfileNameRequest firstName.
                 * @member {string} firstName
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @instance
                 */
                UpdateProfileNameRequest.prototype.firstName = "";

                /**
                 * UpdateProfileNameRequest lastName.
                 * @member {string} lastName
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @instance
                 */
                UpdateProfileNameRequest.prototype.lastName = "";

                /**
                 * Creates a new UpdateProfileNameRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileNameRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfileNameRequest} UpdateProfileNameRequest instance
                 */
                UpdateProfileNameRequest.create = function create(properties) {
                    return new UpdateProfileNameRequest(properties);
                };

                /**
                 * Encodes the specified UpdateProfileNameRequest message. Does not implicitly {@link visor.clientrpc.UpdateProfileNameRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileNameRequest} message UpdateProfileNameRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileNameRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfileNameRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfileNameRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileNameRequest} message UpdateProfileNameRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileNameRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfileNameRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfileNameRequest} UpdateProfileNameRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileNameRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfileNameRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.firstName = reader.string();
                            break;
                        case 2:
                            message.lastName = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfileNameRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfileNameRequest} UpdateProfileNameRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileNameRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfileNameRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfileNameRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfileNameRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfileNameRequest} UpdateProfileNameRequest
                 */
                UpdateProfileNameRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfileNameRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateProfileNameRequest();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateProfileNameRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @static
                 * @param {visor.clientrpc.UpdateProfileNameRequest} message UpdateProfileNameRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfileNameRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    return object;
                };

                /**
                 * Converts this UpdateProfileNameRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfileNameRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfileNameRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfileNameRequest;
            })();

            clientrpc.UpdateProfileNameResponse = (function() {

                /**
                 * Properties of an UpdateProfileNameResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfileNameResponse
                 */

                /**
                 * Constructs a new UpdateProfileNameResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfileNameResponse.
                 * @implements IUpdateProfileNameResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfileNameResponse=} [properties] Properties to set
                 */
                function UpdateProfileNameResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateProfileNameResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileNameResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfileNameResponse} UpdateProfileNameResponse instance
                 */
                UpdateProfileNameResponse.create = function create(properties) {
                    return new UpdateProfileNameResponse(properties);
                };

                /**
                 * Encodes the specified UpdateProfileNameResponse message. Does not implicitly {@link visor.clientrpc.UpdateProfileNameResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileNameResponse} message UpdateProfileNameResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileNameResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfileNameResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfileNameResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileNameResponse} message UpdateProfileNameResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileNameResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfileNameResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfileNameResponse} UpdateProfileNameResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileNameResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfileNameResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfileNameResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfileNameResponse} UpdateProfileNameResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileNameResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfileNameResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfileNameResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfileNameResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfileNameResponse} UpdateProfileNameResponse
                 */
                UpdateProfileNameResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfileNameResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateProfileNameResponse();
                };

                /**
                 * Creates a plain object from an UpdateProfileNameResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @static
                 * @param {visor.clientrpc.UpdateProfileNameResponse} message UpdateProfileNameResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfileNameResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateProfileNameResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfileNameResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfileNameResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfileNameResponse;
            })();

            clientrpc.UpdateProfileEmailRequest = (function() {

                /**
                 * Properties of an UpdateProfileEmailRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfileEmailRequest
                 * @property {string|null} [newEmail] UpdateProfileEmailRequest newEmail
                 */

                /**
                 * Constructs a new UpdateProfileEmailRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfileEmailRequest.
                 * @implements IUpdateProfileEmailRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfileEmailRequest=} [properties] Properties to set
                 */
                function UpdateProfileEmailRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateProfileEmailRequest newEmail.
                 * @member {string} newEmail
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @instance
                 */
                UpdateProfileEmailRequest.prototype.newEmail = "";

                /**
                 * Creates a new UpdateProfileEmailRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileEmailRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfileEmailRequest} UpdateProfileEmailRequest instance
                 */
                UpdateProfileEmailRequest.create = function create(properties) {
                    return new UpdateProfileEmailRequest(properties);
                };

                /**
                 * Encodes the specified UpdateProfileEmailRequest message. Does not implicitly {@link visor.clientrpc.UpdateProfileEmailRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileEmailRequest} message UpdateProfileEmailRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileEmailRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.newEmail);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfileEmailRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfileEmailRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileEmailRequest} message UpdateProfileEmailRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileEmailRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfileEmailRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfileEmailRequest} UpdateProfileEmailRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileEmailRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfileEmailRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.newEmail = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfileEmailRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfileEmailRequest} UpdateProfileEmailRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileEmailRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfileEmailRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfileEmailRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                        if (!$util.isString(message.newEmail))
                            return "newEmail: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfileEmailRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfileEmailRequest} UpdateProfileEmailRequest
                 */
                UpdateProfileEmailRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfileEmailRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateProfileEmailRequest();
                    if (object.newEmail != null)
                        message.newEmail = String(object.newEmail);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateProfileEmailRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @static
                 * @param {visor.clientrpc.UpdateProfileEmailRequest} message UpdateProfileEmailRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfileEmailRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.newEmail = "";
                    if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                        object.newEmail = message.newEmail;
                    return object;
                };

                /**
                 * Converts this UpdateProfileEmailRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfileEmailRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfileEmailRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfileEmailRequest;
            })();

            clientrpc.UpdateProfileEmailResponse = (function() {

                /**
                 * Properties of an UpdateProfileEmailResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfileEmailResponse
                 * @property {boolean|null} [emailVerificationSent] UpdateProfileEmailResponse emailVerificationSent
                 */

                /**
                 * Constructs a new UpdateProfileEmailResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfileEmailResponse.
                 * @implements IUpdateProfileEmailResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfileEmailResponse=} [properties] Properties to set
                 */
                function UpdateProfileEmailResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateProfileEmailResponse emailVerificationSent.
                 * @member {boolean} emailVerificationSent
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @instance
                 */
                UpdateProfileEmailResponse.prototype.emailVerificationSent = false;

                /**
                 * Creates a new UpdateProfileEmailResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileEmailResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfileEmailResponse} UpdateProfileEmailResponse instance
                 */
                UpdateProfileEmailResponse.create = function create(properties) {
                    return new UpdateProfileEmailResponse(properties);
                };

                /**
                 * Encodes the specified UpdateProfileEmailResponse message. Does not implicitly {@link visor.clientrpc.UpdateProfileEmailResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileEmailResponse} message UpdateProfileEmailResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileEmailResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.emailVerificationSent != null && message.hasOwnProperty("emailVerificationSent"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.emailVerificationSent);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfileEmailResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfileEmailResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileEmailResponse} message UpdateProfileEmailResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileEmailResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfileEmailResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfileEmailResponse} UpdateProfileEmailResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileEmailResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfileEmailResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.emailVerificationSent = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfileEmailResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfileEmailResponse} UpdateProfileEmailResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileEmailResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfileEmailResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfileEmailResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.emailVerificationSent != null && message.hasOwnProperty("emailVerificationSent"))
                        if (typeof message.emailVerificationSent !== "boolean")
                            return "emailVerificationSent: boolean expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfileEmailResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfileEmailResponse} UpdateProfileEmailResponse
                 */
                UpdateProfileEmailResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfileEmailResponse)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateProfileEmailResponse();
                    if (object.emailVerificationSent != null)
                        message.emailVerificationSent = Boolean(object.emailVerificationSent);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateProfileEmailResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @static
                 * @param {visor.clientrpc.UpdateProfileEmailResponse} message UpdateProfileEmailResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfileEmailResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.emailVerificationSent = false;
                    if (message.emailVerificationSent != null && message.hasOwnProperty("emailVerificationSent"))
                        object.emailVerificationSent = message.emailVerificationSent;
                    return object;
                };

                /**
                 * Converts this UpdateProfileEmailResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfileEmailResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfileEmailResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfileEmailResponse;
            })();

            clientrpc.UpdateProfilePasswordRequest = (function() {

                /**
                 * Properties of an UpdateProfilePasswordRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfilePasswordRequest
                 * @property {string|null} [encryptedNewPassword] UpdateProfilePasswordRequest encryptedNewPassword
                 */

                /**
                 * Constructs a new UpdateProfilePasswordRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfilePasswordRequest.
                 * @implements IUpdateProfilePasswordRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfilePasswordRequest=} [properties] Properties to set
                 */
                function UpdateProfilePasswordRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateProfilePasswordRequest encryptedNewPassword.
                 * @member {string} encryptedNewPassword
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @instance
                 */
                UpdateProfilePasswordRequest.prototype.encryptedNewPassword = "";

                /**
                 * Creates a new UpdateProfilePasswordRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePasswordRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfilePasswordRequest} UpdateProfilePasswordRequest instance
                 */
                UpdateProfilePasswordRequest.create = function create(properties) {
                    return new UpdateProfilePasswordRequest(properties);
                };

                /**
                 * Encodes the specified UpdateProfilePasswordRequest message. Does not implicitly {@link visor.clientrpc.UpdateProfilePasswordRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePasswordRequest} message UpdateProfilePasswordRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePasswordRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.encryptedNewPassword != null && message.hasOwnProperty("encryptedNewPassword"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.encryptedNewPassword);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfilePasswordRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfilePasswordRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePasswordRequest} message UpdateProfilePasswordRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfilePasswordRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfilePasswordRequest} UpdateProfilePasswordRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePasswordRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfilePasswordRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.encryptedNewPassword = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfilePasswordRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfilePasswordRequest} UpdateProfilePasswordRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePasswordRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfilePasswordRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfilePasswordRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.encryptedNewPassword != null && message.hasOwnProperty("encryptedNewPassword"))
                        if (!$util.isString(message.encryptedNewPassword))
                            return "encryptedNewPassword: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfilePasswordRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfilePasswordRequest} UpdateProfilePasswordRequest
                 */
                UpdateProfilePasswordRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfilePasswordRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateProfilePasswordRequest();
                    if (object.encryptedNewPassword != null)
                        message.encryptedNewPassword = String(object.encryptedNewPassword);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateProfilePasswordRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @static
                 * @param {visor.clientrpc.UpdateProfilePasswordRequest} message UpdateProfilePasswordRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfilePasswordRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.encryptedNewPassword = "";
                    if (message.encryptedNewPassword != null && message.hasOwnProperty("encryptedNewPassword"))
                        object.encryptedNewPassword = message.encryptedNewPassword;
                    return object;
                };

                /**
                 * Converts this UpdateProfilePasswordRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfilePasswordRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfilePasswordRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfilePasswordRequest;
            })();

            clientrpc.UpdateProfilePasswordResponse = (function() {

                /**
                 * Properties of an UpdateProfilePasswordResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfilePasswordResponse
                 */

                /**
                 * Constructs a new UpdateProfilePasswordResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfilePasswordResponse.
                 * @implements IUpdateProfilePasswordResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfilePasswordResponse=} [properties] Properties to set
                 */
                function UpdateProfilePasswordResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateProfilePasswordResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePasswordResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfilePasswordResponse} UpdateProfilePasswordResponse instance
                 */
                UpdateProfilePasswordResponse.create = function create(properties) {
                    return new UpdateProfilePasswordResponse(properties);
                };

                /**
                 * Encodes the specified UpdateProfilePasswordResponse message. Does not implicitly {@link visor.clientrpc.UpdateProfilePasswordResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePasswordResponse} message UpdateProfilePasswordResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePasswordResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfilePasswordResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfilePasswordResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePasswordResponse} message UpdateProfilePasswordResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfilePasswordResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfilePasswordResponse} UpdateProfilePasswordResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePasswordResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfilePasswordResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfilePasswordResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfilePasswordResponse} UpdateProfilePasswordResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePasswordResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfilePasswordResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfilePasswordResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfilePasswordResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfilePasswordResponse} UpdateProfilePasswordResponse
                 */
                UpdateProfilePasswordResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfilePasswordResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateProfilePasswordResponse();
                };

                /**
                 * Creates a plain object from an UpdateProfilePasswordResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @static
                 * @param {visor.clientrpc.UpdateProfilePasswordResponse} message UpdateProfilePasswordResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfilePasswordResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateProfilePasswordResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfilePasswordResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfilePasswordResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfilePasswordResponse;
            })();

            clientrpc.UpdateProfilePhoneNumberRequest = (function() {

                /**
                 * Properties of an UpdateProfilePhoneNumberRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfilePhoneNumberRequest
                 * @property {string|null} [newPhoneNumber] UpdateProfilePhoneNumberRequest newPhoneNumber
                 */

                /**
                 * Constructs a new UpdateProfilePhoneNumberRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfilePhoneNumberRequest.
                 * @implements IUpdateProfilePhoneNumberRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberRequest=} [properties] Properties to set
                 */
                function UpdateProfilePhoneNumberRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateProfilePhoneNumberRequest newPhoneNumber.
                 * @member {string} newPhoneNumber
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @instance
                 */
                UpdateProfilePhoneNumberRequest.prototype.newPhoneNumber = "";

                /**
                 * Creates a new UpdateProfilePhoneNumberRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberRequest} UpdateProfilePhoneNumberRequest instance
                 */
                UpdateProfilePhoneNumberRequest.create = function create(properties) {
                    return new UpdateProfilePhoneNumberRequest(properties);
                };

                /**
                 * Encodes the specified UpdateProfilePhoneNumberRequest message. Does not implicitly {@link visor.clientrpc.UpdateProfilePhoneNumberRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberRequest} message UpdateProfilePhoneNumberRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePhoneNumberRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.newPhoneNumber != null && message.hasOwnProperty("newPhoneNumber"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.newPhoneNumber);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfilePhoneNumberRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfilePhoneNumberRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberRequest} message UpdateProfilePhoneNumberRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePhoneNumberRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfilePhoneNumberRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberRequest} UpdateProfilePhoneNumberRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePhoneNumberRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfilePhoneNumberRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.newPhoneNumber = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfilePhoneNumberRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberRequest} UpdateProfilePhoneNumberRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePhoneNumberRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfilePhoneNumberRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfilePhoneNumberRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.newPhoneNumber != null && message.hasOwnProperty("newPhoneNumber"))
                        if (!$util.isString(message.newPhoneNumber))
                            return "newPhoneNumber: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfilePhoneNumberRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberRequest} UpdateProfilePhoneNumberRequest
                 */
                UpdateProfilePhoneNumberRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfilePhoneNumberRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateProfilePhoneNumberRequest();
                    if (object.newPhoneNumber != null)
                        message.newPhoneNumber = String(object.newPhoneNumber);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateProfilePhoneNumberRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @static
                 * @param {visor.clientrpc.UpdateProfilePhoneNumberRequest} message UpdateProfilePhoneNumberRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfilePhoneNumberRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.newPhoneNumber = "";
                    if (message.newPhoneNumber != null && message.hasOwnProperty("newPhoneNumber"))
                        object.newPhoneNumber = message.newPhoneNumber;
                    return object;
                };

                /**
                 * Converts this UpdateProfilePhoneNumberRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfilePhoneNumberRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfilePhoneNumberRequest;
            })();

            clientrpc.UpdateProfilePhoneNumberResponse = (function() {

                /**
                 * Properties of an UpdateProfilePhoneNumberResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfilePhoneNumberResponse
                 */

                /**
                 * Constructs a new UpdateProfilePhoneNumberResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfilePhoneNumberResponse.
                 * @implements IUpdateProfilePhoneNumberResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberResponse=} [properties] Properties to set
                 */
                function UpdateProfilePhoneNumberResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateProfilePhoneNumberResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberResponse} UpdateProfilePhoneNumberResponse instance
                 */
                UpdateProfilePhoneNumberResponse.create = function create(properties) {
                    return new UpdateProfilePhoneNumberResponse(properties);
                };

                /**
                 * Encodes the specified UpdateProfilePhoneNumberResponse message. Does not implicitly {@link visor.clientrpc.UpdateProfilePhoneNumberResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberResponse} message UpdateProfilePhoneNumberResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePhoneNumberResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfilePhoneNumberResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfilePhoneNumberResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfilePhoneNumberResponse} message UpdateProfilePhoneNumberResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfilePhoneNumberResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfilePhoneNumberResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberResponse} UpdateProfilePhoneNumberResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePhoneNumberResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfilePhoneNumberResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfilePhoneNumberResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberResponse} UpdateProfilePhoneNumberResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfilePhoneNumberResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfilePhoneNumberResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfilePhoneNumberResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfilePhoneNumberResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfilePhoneNumberResponse} UpdateProfilePhoneNumberResponse
                 */
                UpdateProfilePhoneNumberResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfilePhoneNumberResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateProfilePhoneNumberResponse();
                };

                /**
                 * Creates a plain object from an UpdateProfilePhoneNumberResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @static
                 * @param {visor.clientrpc.UpdateProfilePhoneNumberResponse} message UpdateProfilePhoneNumberResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfilePhoneNumberResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateProfilePhoneNumberResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfilePhoneNumberResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfilePhoneNumberResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfilePhoneNumberResponse;
            })();

            clientrpc.UpdateProfileTimezoneRequest = (function() {

                /**
                 * Properties of an UpdateProfileTimezoneRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfileTimezoneRequest
                 * @property {string|null} [newTimezone] UpdateProfileTimezoneRequest newTimezone
                 */

                /**
                 * Constructs a new UpdateProfileTimezoneRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfileTimezoneRequest.
                 * @implements IUpdateProfileTimezoneRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfileTimezoneRequest=} [properties] Properties to set
                 */
                function UpdateProfileTimezoneRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateProfileTimezoneRequest newTimezone.
                 * @member {string} newTimezone
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @instance
                 */
                UpdateProfileTimezoneRequest.prototype.newTimezone = "";

                /**
                 * Creates a new UpdateProfileTimezoneRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileTimezoneRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfileTimezoneRequest} UpdateProfileTimezoneRequest instance
                 */
                UpdateProfileTimezoneRequest.create = function create(properties) {
                    return new UpdateProfileTimezoneRequest(properties);
                };

                /**
                 * Encodes the specified UpdateProfileTimezoneRequest message. Does not implicitly {@link visor.clientrpc.UpdateProfileTimezoneRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileTimezoneRequest} message UpdateProfileTimezoneRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileTimezoneRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.newTimezone != null && message.hasOwnProperty("newTimezone"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.newTimezone);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfileTimezoneRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfileTimezoneRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileTimezoneRequest} message UpdateProfileTimezoneRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileTimezoneRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfileTimezoneRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfileTimezoneRequest} UpdateProfileTimezoneRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileTimezoneRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfileTimezoneRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.newTimezone = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfileTimezoneRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfileTimezoneRequest} UpdateProfileTimezoneRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileTimezoneRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfileTimezoneRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfileTimezoneRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.newTimezone != null && message.hasOwnProperty("newTimezone"))
                        if (!$util.isString(message.newTimezone))
                            return "newTimezone: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfileTimezoneRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfileTimezoneRequest} UpdateProfileTimezoneRequest
                 */
                UpdateProfileTimezoneRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfileTimezoneRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateProfileTimezoneRequest();
                    if (object.newTimezone != null)
                        message.newTimezone = String(object.newTimezone);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateProfileTimezoneRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @static
                 * @param {visor.clientrpc.UpdateProfileTimezoneRequest} message UpdateProfileTimezoneRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfileTimezoneRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.newTimezone = "";
                    if (message.newTimezone != null && message.hasOwnProperty("newTimezone"))
                        object.newTimezone = message.newTimezone;
                    return object;
                };

                /**
                 * Converts this UpdateProfileTimezoneRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfileTimezoneRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfileTimezoneRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfileTimezoneRequest;
            })();

            clientrpc.UpdateProfileTimezoneResponse = (function() {

                /**
                 * Properties of an UpdateProfileTimezoneResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateProfileTimezoneResponse
                 */

                /**
                 * Constructs a new UpdateProfileTimezoneResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateProfileTimezoneResponse.
                 * @implements IUpdateProfileTimezoneResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateProfileTimezoneResponse=} [properties] Properties to set
                 */
                function UpdateProfileTimezoneResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateProfileTimezoneResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileTimezoneResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateProfileTimezoneResponse} UpdateProfileTimezoneResponse instance
                 */
                UpdateProfileTimezoneResponse.create = function create(properties) {
                    return new UpdateProfileTimezoneResponse(properties);
                };

                /**
                 * Encodes the specified UpdateProfileTimezoneResponse message. Does not implicitly {@link visor.clientrpc.UpdateProfileTimezoneResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileTimezoneResponse} message UpdateProfileTimezoneResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileTimezoneResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateProfileTimezoneResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateProfileTimezoneResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateProfileTimezoneResponse} message UpdateProfileTimezoneResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateProfileTimezoneResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateProfileTimezoneResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateProfileTimezoneResponse} UpdateProfileTimezoneResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileTimezoneResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateProfileTimezoneResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateProfileTimezoneResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateProfileTimezoneResponse} UpdateProfileTimezoneResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateProfileTimezoneResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateProfileTimezoneResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateProfileTimezoneResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateProfileTimezoneResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateProfileTimezoneResponse} UpdateProfileTimezoneResponse
                 */
                UpdateProfileTimezoneResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateProfileTimezoneResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateProfileTimezoneResponse();
                };

                /**
                 * Creates a plain object from an UpdateProfileTimezoneResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @static
                 * @param {visor.clientrpc.UpdateProfileTimezoneResponse} message UpdateProfileTimezoneResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateProfileTimezoneResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateProfileTimezoneResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateProfileTimezoneResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateProfileTimezoneResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateProfileTimezoneResponse;
            })();

            clientrpc.SendMessageRequest = (function() {

                /**
                 * Properties of a SendMessageRequest.
                 * @memberof visor.clientrpc
                 * @interface ISendMessageRequest
                 * @property {string|null} [body] SendMessageRequest body
                 * @property {google.protobuf.ITimestamp|null} [clientTimestamp] SendMessageRequest clientTimestamp
                 * @property {Array.<string>|null} [fileTokens] SendMessageRequest fileTokens
                 * @property {visor.clientrpc.SendMessageRequest.MessageIntention|null} [intention] SendMessageRequest intention
                 */

                /**
                 * Constructs a new SendMessageRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SendMessageRequest.
                 * @implements ISendMessageRequest
                 * @constructor
                 * @param {visor.clientrpc.ISendMessageRequest=} [properties] Properties to set
                 */
                function SendMessageRequest(properties) {
                    this.fileTokens = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SendMessageRequest body.
                 * @member {string} body
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @instance
                 */
                SendMessageRequest.prototype.body = "";

                /**
                 * SendMessageRequest clientTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} clientTimestamp
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @instance
                 */
                SendMessageRequest.prototype.clientTimestamp = null;

                /**
                 * SendMessageRequest fileTokens.
                 * @member {Array.<string>} fileTokens
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @instance
                 */
                SendMessageRequest.prototype.fileTokens = $util.emptyArray;

                /**
                 * SendMessageRequest intention.
                 * @member {visor.clientrpc.SendMessageRequest.MessageIntention} intention
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @instance
                 */
                SendMessageRequest.prototype.intention = 0;

                /**
                 * Creates a new SendMessageRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {visor.clientrpc.ISendMessageRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SendMessageRequest} SendMessageRequest instance
                 */
                SendMessageRequest.create = function create(properties) {
                    return new SendMessageRequest(properties);
                };

                /**
                 * Encodes the specified SendMessageRequest message. Does not implicitly {@link visor.clientrpc.SendMessageRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {visor.clientrpc.ISendMessageRequest} message SendMessageRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendMessageRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.body != null && message.hasOwnProperty("body"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.clientTimestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.fileTokens != null && message.fileTokens.length)
                        for (var i = 0; i < message.fileTokens.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fileTokens[i]);
                    if (message.intention != null && message.hasOwnProperty("intention"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.intention);
                    return writer;
                };

                /**
                 * Encodes the specified SendMessageRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SendMessageRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {visor.clientrpc.ISendMessageRequest} message SendMessageRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SendMessageRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SendMessageRequest} SendMessageRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendMessageRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SendMessageRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.body = reader.string();
                            break;
                        case 2:
                            message.clientTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            if (!(message.fileTokens && message.fileTokens.length))
                                message.fileTokens = [];
                            message.fileTokens.push(reader.string());
                            break;
                        case 4:
                            message.intention = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SendMessageRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SendMessageRequest} SendMessageRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendMessageRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SendMessageRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendMessageRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.body != null && message.hasOwnProperty("body"))
                        if (!$util.isString(message.body))
                            return "body: string expected";
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.clientTimestamp);
                        if (error)
                            return "clientTimestamp." + error;
                    }
                    if (message.fileTokens != null && message.hasOwnProperty("fileTokens")) {
                        if (!Array.isArray(message.fileTokens))
                            return "fileTokens: array expected";
                        for (var i = 0; i < message.fileTokens.length; ++i)
                            if (!$util.isString(message.fileTokens[i]))
                                return "fileTokens: string[] expected";
                    }
                    if (message.intention != null && message.hasOwnProperty("intention"))
                        switch (message.intention) {
                        default:
                            return "intention: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a SendMessageRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SendMessageRequest} SendMessageRequest
                 */
                SendMessageRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SendMessageRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SendMessageRequest();
                    if (object.body != null)
                        message.body = String(object.body);
                    if (object.clientTimestamp != null)
                        if (object.clientTimestamp === null)
                            message.clientTimestamp = {};
                        else
                            message.clientTimestamp = {
                                seconds: Math.floor(object.clientTimestamp.getTime() / 1000),
                                nanos: object.clientTimestamp.getMilliseconds() * 1000000
                            };
                    if (object.fileTokens) {
                        if (!Array.isArray(object.fileTokens))
                            throw TypeError(".visor.clientrpc.SendMessageRequest.fileTokens: array expected");
                        message.fileTokens = [];
                        for (var i = 0; i < object.fileTokens.length; ++i)
                            message.fileTokens[i] = String(object.fileTokens[i]);
                    }
                    switch (object.intention) {
                    case "SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_INVALID":
                    case 0:
                        message.intention = 0;
                        break;
                    case "SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION":
                    case 1:
                        message.intention = 1;
                        break;
                    case "SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION_CLIENT_DENIED_CONFIRMATION":
                    case 2:
                        message.intention = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SendMessageRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @static
                 * @param {visor.clientrpc.SendMessageRequest} message SendMessageRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendMessageRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fileTokens = [];
                    if (options.defaults) {
                        object.body = "";
                        object.clientTimestamp = null;
                        object.intention = options.enums === String ? "SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_INVALID" : 0;
                    }
                    if (message.body != null && message.hasOwnProperty("body"))
                        object.body = message.body;
                    if (message.clientTimestamp != null && message.hasOwnProperty("clientTimestamp"))
                        object.clientTimestamp = new Date(message.clientTimestamp.seconds * 1000 + message.clientTimestamp.nanos / 1000000);
                    if (message.fileTokens && message.fileTokens.length) {
                        object.fileTokens = [];
                        for (var j = 0; j < message.fileTokens.length; ++j)
                            object.fileTokens[j] = message.fileTokens[j];
                    }
                    if (message.intention != null && message.hasOwnProperty("intention"))
                        object.intention = options.enums === String ? $root.visor.clientrpc.SendMessageRequest.MessageIntention[message.intention] : message.intention;
                    return object;
                };

                /**
                 * Converts this SendMessageRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SendMessageRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendMessageRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * MessageIntention enum.
                 * @name visor.clientrpc.SendMessageRequest.MessageIntention
                 * @enum {string}
                 * @property {number} SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_INVALID=0 SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_INVALID value
                 * @property {number} SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION=1 SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION value
                 * @property {number} SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION_CLIENT_DENIED_CONFIRMATION=2 SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION_CLIENT_DENIED_CONFIRMATION value
                 */
                SendMessageRequest.MessageIntention = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_INVALID"] = 0;
                    values[valuesById[1] = "SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION"] = 1;
                    values[valuesById[2] = "SEND_MESSAGE_REQUEST_MESSAGE_INTENTION_CANCEL_SUBSCRIPTION_CLIENT_DENIED_CONFIRMATION"] = 2;
                    return values;
                })();

                return SendMessageRequest;
            })();

            clientrpc.SendMessageResponse = (function() {

                /**
                 * Properties of a SendMessageResponse.
                 * @memberof visor.clientrpc
                 * @interface ISendMessageResponse
                 */

                /**
                 * Constructs a new SendMessageResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SendMessageResponse.
                 * @implements ISendMessageResponse
                 * @constructor
                 * @param {visor.clientrpc.ISendMessageResponse=} [properties] Properties to set
                 */
                function SendMessageResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SendMessageResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {visor.clientrpc.ISendMessageResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SendMessageResponse} SendMessageResponse instance
                 */
                SendMessageResponse.create = function create(properties) {
                    return new SendMessageResponse(properties);
                };

                /**
                 * Encodes the specified SendMessageResponse message. Does not implicitly {@link visor.clientrpc.SendMessageResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {visor.clientrpc.ISendMessageResponse} message SendMessageResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendMessageResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SendMessageResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SendMessageResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {visor.clientrpc.ISendMessageResponse} message SendMessageResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SendMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SendMessageResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SendMessageResponse} SendMessageResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendMessageResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SendMessageResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SendMessageResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SendMessageResponse} SendMessageResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SendMessageResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SendMessageResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SendMessageResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SendMessageResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SendMessageResponse} SendMessageResponse
                 */
                SendMessageResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SendMessageResponse)
                        return object;
                    return new $root.visor.clientrpc.SendMessageResponse();
                };

                /**
                 * Creates a plain object from a SendMessageResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @static
                 * @param {visor.clientrpc.SendMessageResponse} message SendMessageResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SendMessageResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SendMessageResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SendMessageResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SendMessageResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SendMessageResponse;
            })();

            clientrpc.UpdateDeviceRequest = (function() {

                /**
                 * Properties of an UpdateDeviceRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateDeviceRequest
                 * @property {string|null} [identifier] UpdateDeviceRequest identifier
                 * @property {string|null} [name] UpdateDeviceRequest name
                 * @property {string|null} [hardwareVersion] UpdateDeviceRequest hardwareVersion
                 * @property {string|null} [systemVersion] UpdateDeviceRequest systemVersion
                 * @property {string|null} [appVersion] UpdateDeviceRequest appVersion
                 * @property {string|null} [appBuild] UpdateDeviceRequest appBuild
                 * @property {string|null} [apnsToken] UpdateDeviceRequest apnsToken
                 * @property {visor.clientrpc.UpdateDeviceRequest.APNSOverride|null} [disableApns] UpdateDeviceRequest disableApns
                 * @property {visor.clientrpc.DevicePlatform|null} [platform] UpdateDeviceRequest platform
                 * @property {string|null} [bundleId] UpdateDeviceRequest bundleId
                 * @property {boolean|null} [isDev] UpdateDeviceRequest isDev
                 * @property {string|null} [icloudId] UpdateDeviceRequest icloudId
                 */

                /**
                 * Constructs a new UpdateDeviceRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateDeviceRequest.
                 * @implements IUpdateDeviceRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateDeviceRequest=} [properties] Properties to set
                 */
                function UpdateDeviceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateDeviceRequest identifier.
                 * @member {string} identifier
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.identifier = "";

                /**
                 * UpdateDeviceRequest name.
                 * @member {string} name
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.name = "";

                /**
                 * UpdateDeviceRequest hardwareVersion.
                 * @member {string} hardwareVersion
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.hardwareVersion = "";

                /**
                 * UpdateDeviceRequest systemVersion.
                 * @member {string} systemVersion
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.systemVersion = "";

                /**
                 * UpdateDeviceRequest appVersion.
                 * @member {string} appVersion
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.appVersion = "";

                /**
                 * UpdateDeviceRequest appBuild.
                 * @member {string} appBuild
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.appBuild = "";

                /**
                 * UpdateDeviceRequest apnsToken.
                 * @member {string} apnsToken
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.apnsToken = "";

                /**
                 * UpdateDeviceRequest disableApns.
                 * @member {visor.clientrpc.UpdateDeviceRequest.APNSOverride} disableApns
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.disableApns = 0;

                /**
                 * UpdateDeviceRequest platform.
                 * @member {visor.clientrpc.DevicePlatform} platform
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.platform = 0;

                /**
                 * UpdateDeviceRequest bundleId.
                 * @member {string} bundleId
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.bundleId = "";

                /**
                 * UpdateDeviceRequest isDev.
                 * @member {boolean} isDev
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.isDev = false;

                /**
                 * UpdateDeviceRequest icloudId.
                 * @member {string} icloudId
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 */
                UpdateDeviceRequest.prototype.icloudId = "";

                /**
                 * Creates a new UpdateDeviceRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateDeviceRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateDeviceRequest} UpdateDeviceRequest instance
                 */
                UpdateDeviceRequest.create = function create(properties) {
                    return new UpdateDeviceRequest(properties);
                };

                /**
                 * Encodes the specified UpdateDeviceRequest message. Does not implicitly {@link visor.clientrpc.UpdateDeviceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateDeviceRequest} message UpdateDeviceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDeviceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.identifier != null && message.hasOwnProperty("identifier"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.identifier);
                    if (message.name != null && message.hasOwnProperty("name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.hardwareVersion != null && message.hasOwnProperty("hardwareVersion"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.hardwareVersion);
                    if (message.systemVersion != null && message.hasOwnProperty("systemVersion"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.systemVersion);
                    if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.appVersion);
                    if (message.appBuild != null && message.hasOwnProperty("appBuild"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.appBuild);
                    if (message.apnsToken != null && message.hasOwnProperty("apnsToken"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.apnsToken);
                    if (message.disableApns != null && message.hasOwnProperty("disableApns"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.disableApns);
                    if (message.platform != null && message.hasOwnProperty("platform"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.platform);
                    if (message.bundleId != null && message.hasOwnProperty("bundleId"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.bundleId);
                    if (message.isDev != null && message.hasOwnProperty("isDev"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isDev);
                    if (message.icloudId != null && message.hasOwnProperty("icloudId"))
                        writer.uint32(/* id 12, wireType 2 =*/98).string(message.icloudId);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateDeviceRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateDeviceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateDeviceRequest} message UpdateDeviceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDeviceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateDeviceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateDeviceRequest} UpdateDeviceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDeviceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateDeviceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.identifier = reader.string();
                            break;
                        case 2:
                            message.name = reader.string();
                            break;
                        case 3:
                            message.hardwareVersion = reader.string();
                            break;
                        case 4:
                            message.systemVersion = reader.string();
                            break;
                        case 5:
                            message.appVersion = reader.string();
                            break;
                        case 6:
                            message.appBuild = reader.string();
                            break;
                        case 7:
                            message.apnsToken = reader.string();
                            break;
                        case 8:
                            message.disableApns = reader.int32();
                            break;
                        case 9:
                            message.platform = reader.int32();
                            break;
                        case 10:
                            message.bundleId = reader.string();
                            break;
                        case 11:
                            message.isDev = reader.bool();
                            break;
                        case 12:
                            message.icloudId = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateDeviceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateDeviceRequest} UpdateDeviceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDeviceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateDeviceRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDeviceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.identifier != null && message.hasOwnProperty("identifier"))
                        if (!$util.isString(message.identifier))
                            return "identifier: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.hardwareVersion != null && message.hasOwnProperty("hardwareVersion"))
                        if (!$util.isString(message.hardwareVersion))
                            return "hardwareVersion: string expected";
                    if (message.systemVersion != null && message.hasOwnProperty("systemVersion"))
                        if (!$util.isString(message.systemVersion))
                            return "systemVersion: string expected";
                    if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                        if (!$util.isString(message.appVersion))
                            return "appVersion: string expected";
                    if (message.appBuild != null && message.hasOwnProperty("appBuild"))
                        if (!$util.isString(message.appBuild))
                            return "appBuild: string expected";
                    if (message.apnsToken != null && message.hasOwnProperty("apnsToken"))
                        if (!$util.isString(message.apnsToken))
                            return "apnsToken: string expected";
                    if (message.disableApns != null && message.hasOwnProperty("disableApns"))
                        switch (message.disableApns) {
                        default:
                            return "disableApns: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.platform != null && message.hasOwnProperty("platform"))
                        switch (message.platform) {
                        default:
                            return "platform: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.bundleId != null && message.hasOwnProperty("bundleId"))
                        if (!$util.isString(message.bundleId))
                            return "bundleId: string expected";
                    if (message.isDev != null && message.hasOwnProperty("isDev"))
                        if (typeof message.isDev !== "boolean")
                            return "isDev: boolean expected";
                    if (message.icloudId != null && message.hasOwnProperty("icloudId"))
                        if (!$util.isString(message.icloudId))
                            return "icloudId: string expected";
                    return null;
                };

                /**
                 * Creates an UpdateDeviceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateDeviceRequest} UpdateDeviceRequest
                 */
                UpdateDeviceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateDeviceRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateDeviceRequest();
                    if (object.identifier != null)
                        message.identifier = String(object.identifier);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.hardwareVersion != null)
                        message.hardwareVersion = String(object.hardwareVersion);
                    if (object.systemVersion != null)
                        message.systemVersion = String(object.systemVersion);
                    if (object.appVersion != null)
                        message.appVersion = String(object.appVersion);
                    if (object.appBuild != null)
                        message.appBuild = String(object.appBuild);
                    if (object.apnsToken != null)
                        message.apnsToken = String(object.apnsToken);
                    switch (object.disableApns) {
                    case "UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_INVALID":
                    case 0:
                        message.disableApns = 0;
                        break;
                    case "UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_DISABLE":
                    case 1:
                        message.disableApns = 1;
                        break;
                    case "UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_ENABLE":
                    case 2:
                        message.disableApns = 2;
                        break;
                    }
                    switch (object.platform) {
                    case "DEVICE_PLATFORM_INVALID":
                    case 0:
                        message.platform = 0;
                        break;
                    case "DEVICE_PLATFORM_IOS":
                    case 1:
                        message.platform = 1;
                        break;
                    case "DEVICE_PLATFORM_ANDROID":
                    case 2:
                        message.platform = 2;
                        break;
                    }
                    if (object.bundleId != null)
                        message.bundleId = String(object.bundleId);
                    if (object.isDev != null)
                        message.isDev = Boolean(object.isDev);
                    if (object.icloudId != null)
                        message.icloudId = String(object.icloudId);
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateDeviceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @static
                 * @param {visor.clientrpc.UpdateDeviceRequest} message UpdateDeviceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDeviceRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.identifier = "";
                        object.name = "";
                        object.hardwareVersion = "";
                        object.systemVersion = "";
                        object.appVersion = "";
                        object.appBuild = "";
                        object.apnsToken = "";
                        object.disableApns = options.enums === String ? "UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_INVALID" : 0;
                        object.platform = options.enums === String ? "DEVICE_PLATFORM_INVALID" : 0;
                        object.bundleId = "";
                        object.isDev = false;
                        object.icloudId = "";
                    }
                    if (message.identifier != null && message.hasOwnProperty("identifier"))
                        object.identifier = message.identifier;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.hardwareVersion != null && message.hasOwnProperty("hardwareVersion"))
                        object.hardwareVersion = message.hardwareVersion;
                    if (message.systemVersion != null && message.hasOwnProperty("systemVersion"))
                        object.systemVersion = message.systemVersion;
                    if (message.appVersion != null && message.hasOwnProperty("appVersion"))
                        object.appVersion = message.appVersion;
                    if (message.appBuild != null && message.hasOwnProperty("appBuild"))
                        object.appBuild = message.appBuild;
                    if (message.apnsToken != null && message.hasOwnProperty("apnsToken"))
                        object.apnsToken = message.apnsToken;
                    if (message.disableApns != null && message.hasOwnProperty("disableApns"))
                        object.disableApns = options.enums === String ? $root.visor.clientrpc.UpdateDeviceRequest.APNSOverride[message.disableApns] : message.disableApns;
                    if (message.platform != null && message.hasOwnProperty("platform"))
                        object.platform = options.enums === String ? $root.visor.clientrpc.DevicePlatform[message.platform] : message.platform;
                    if (message.bundleId != null && message.hasOwnProperty("bundleId"))
                        object.bundleId = message.bundleId;
                    if (message.isDev != null && message.hasOwnProperty("isDev"))
                        object.isDev = message.isDev;
                    if (message.icloudId != null && message.hasOwnProperty("icloudId"))
                        object.icloudId = message.icloudId;
                    return object;
                };

                /**
                 * Converts this UpdateDeviceRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateDeviceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDeviceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * APNSOverride enum.
                 * @name visor.clientrpc.UpdateDeviceRequest.APNSOverride
                 * @enum {string}
                 * @property {number} UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_INVALID=0 UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_INVALID value
                 * @property {number} UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_DISABLE=1 UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_DISABLE value
                 * @property {number} UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_ENABLE=2 UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_ENABLE value
                 */
                UpdateDeviceRequest.APNSOverride = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_INVALID"] = 0;
                    values[valuesById[1] = "UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_DISABLE"] = 1;
                    values[valuesById[2] = "UPDATE_DEVICE_REQUEST_APNS_OVERRIDE_ENABLE"] = 2;
                    return values;
                })();

                return UpdateDeviceRequest;
            })();

            /**
             * DevicePlatform enum.
             * @name visor.clientrpc.DevicePlatform
             * @enum {string}
             * @property {number} DEVICE_PLATFORM_INVALID=0 DEVICE_PLATFORM_INVALID value
             * @property {number} DEVICE_PLATFORM_IOS=1 DEVICE_PLATFORM_IOS value
             * @property {number} DEVICE_PLATFORM_ANDROID=2 DEVICE_PLATFORM_ANDROID value
             */
            clientrpc.DevicePlatform = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DEVICE_PLATFORM_INVALID"] = 0;
                values[valuesById[1] = "DEVICE_PLATFORM_IOS"] = 1;
                values[valuesById[2] = "DEVICE_PLATFORM_ANDROID"] = 2;
                return values;
            })();

            clientrpc.UpdateDeviceResponse = (function() {

                /**
                 * Properties of an UpdateDeviceResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateDeviceResponse
                 */

                /**
                 * Constructs a new UpdateDeviceResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateDeviceResponse.
                 * @implements IUpdateDeviceResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateDeviceResponse=} [properties] Properties to set
                 */
                function UpdateDeviceResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateDeviceResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateDeviceResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateDeviceResponse} UpdateDeviceResponse instance
                 */
                UpdateDeviceResponse.create = function create(properties) {
                    return new UpdateDeviceResponse(properties);
                };

                /**
                 * Encodes the specified UpdateDeviceResponse message. Does not implicitly {@link visor.clientrpc.UpdateDeviceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateDeviceResponse} message UpdateDeviceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDeviceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateDeviceResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateDeviceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateDeviceResponse} message UpdateDeviceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDeviceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateDeviceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateDeviceResponse} UpdateDeviceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDeviceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateDeviceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateDeviceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateDeviceResponse} UpdateDeviceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDeviceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateDeviceResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDeviceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateDeviceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateDeviceResponse} UpdateDeviceResponse
                 */
                UpdateDeviceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateDeviceResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateDeviceResponse();
                };

                /**
                 * Creates a plain object from an UpdateDeviceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @static
                 * @param {visor.clientrpc.UpdateDeviceResponse} message UpdateDeviceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDeviceResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateDeviceResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateDeviceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDeviceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateDeviceResponse;
            })();

            clientrpc.ResendEmailVerificationLinkRequest = (function() {

                /**
                 * Properties of a ResendEmailVerificationLinkRequest.
                 * @memberof visor.clientrpc
                 * @interface IResendEmailVerificationLinkRequest
                 */

                /**
                 * Constructs a new ResendEmailVerificationLinkRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResendEmailVerificationLinkRequest.
                 * @implements IResendEmailVerificationLinkRequest
                 * @constructor
                 * @param {visor.clientrpc.IResendEmailVerificationLinkRequest=} [properties] Properties to set
                 */
                function ResendEmailVerificationLinkRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ResendEmailVerificationLinkRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {visor.clientrpc.IResendEmailVerificationLinkRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkRequest} ResendEmailVerificationLinkRequest instance
                 */
                ResendEmailVerificationLinkRequest.create = function create(properties) {
                    return new ResendEmailVerificationLinkRequest(properties);
                };

                /**
                 * Encodes the specified ResendEmailVerificationLinkRequest message. Does not implicitly {@link visor.clientrpc.ResendEmailVerificationLinkRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {visor.clientrpc.IResendEmailVerificationLinkRequest} message ResendEmailVerificationLinkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendEmailVerificationLinkRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ResendEmailVerificationLinkRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ResendEmailVerificationLinkRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {visor.clientrpc.IResendEmailVerificationLinkRequest} message ResendEmailVerificationLinkRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendEmailVerificationLinkRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResendEmailVerificationLinkRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkRequest} ResendEmailVerificationLinkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendEmailVerificationLinkRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResendEmailVerificationLinkRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResendEmailVerificationLinkRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkRequest} ResendEmailVerificationLinkRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendEmailVerificationLinkRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResendEmailVerificationLinkRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResendEmailVerificationLinkRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ResendEmailVerificationLinkRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkRequest} ResendEmailVerificationLinkRequest
                 */
                ResendEmailVerificationLinkRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResendEmailVerificationLinkRequest)
                        return object;
                    return new $root.visor.clientrpc.ResendEmailVerificationLinkRequest();
                };

                /**
                 * Creates a plain object from a ResendEmailVerificationLinkRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @static
                 * @param {visor.clientrpc.ResendEmailVerificationLinkRequest} message ResendEmailVerificationLinkRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResendEmailVerificationLinkRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ResendEmailVerificationLinkRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResendEmailVerificationLinkRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResendEmailVerificationLinkRequest;
            })();

            clientrpc.ResendEmailVerificationLinkResponse = (function() {

                /**
                 * Properties of a ResendEmailVerificationLinkResponse.
                 * @memberof visor.clientrpc
                 * @interface IResendEmailVerificationLinkResponse
                 */

                /**
                 * Constructs a new ResendEmailVerificationLinkResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResendEmailVerificationLinkResponse.
                 * @implements IResendEmailVerificationLinkResponse
                 * @constructor
                 * @param {visor.clientrpc.IResendEmailVerificationLinkResponse=} [properties] Properties to set
                 */
                function ResendEmailVerificationLinkResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ResendEmailVerificationLinkResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {visor.clientrpc.IResendEmailVerificationLinkResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkResponse} ResendEmailVerificationLinkResponse instance
                 */
                ResendEmailVerificationLinkResponse.create = function create(properties) {
                    return new ResendEmailVerificationLinkResponse(properties);
                };

                /**
                 * Encodes the specified ResendEmailVerificationLinkResponse message. Does not implicitly {@link visor.clientrpc.ResendEmailVerificationLinkResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {visor.clientrpc.IResendEmailVerificationLinkResponse} message ResendEmailVerificationLinkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendEmailVerificationLinkResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ResendEmailVerificationLinkResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ResendEmailVerificationLinkResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {visor.clientrpc.IResendEmailVerificationLinkResponse} message ResendEmailVerificationLinkResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResendEmailVerificationLinkResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResendEmailVerificationLinkResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkResponse} ResendEmailVerificationLinkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendEmailVerificationLinkResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResendEmailVerificationLinkResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResendEmailVerificationLinkResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkResponse} ResendEmailVerificationLinkResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResendEmailVerificationLinkResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResendEmailVerificationLinkResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResendEmailVerificationLinkResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ResendEmailVerificationLinkResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResendEmailVerificationLinkResponse} ResendEmailVerificationLinkResponse
                 */
                ResendEmailVerificationLinkResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResendEmailVerificationLinkResponse)
                        return object;
                    return new $root.visor.clientrpc.ResendEmailVerificationLinkResponse();
                };

                /**
                 * Creates a plain object from a ResendEmailVerificationLinkResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @static
                 * @param {visor.clientrpc.ResendEmailVerificationLinkResponse} message ResendEmailVerificationLinkResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResendEmailVerificationLinkResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ResendEmailVerificationLinkResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResendEmailVerificationLinkResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResendEmailVerificationLinkResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResendEmailVerificationLinkResponse;
            })();

            clientrpc.LoginRequest = (function() {

                /**
                 * Properties of a LoginRequest.
                 * @memberof visor.clientrpc
                 * @interface ILoginRequest
                 * @property {string|null} [email] LoginRequest email
                 * @property {string|null} [encryptedPassword] LoginRequest encryptedPassword
                 * @property {string|null} [twoFactorCode] LoginRequest twoFactorCode
                 */

                /**
                 * Constructs a new LoginRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a LoginRequest.
                 * @implements ILoginRequest
                 * @constructor
                 * @param {visor.clientrpc.ILoginRequest=} [properties] Properties to set
                 */
                function LoginRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LoginRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.LoginRequest
                 * @instance
                 */
                LoginRequest.prototype.email = "";

                /**
                 * LoginRequest encryptedPassword.
                 * @member {string} encryptedPassword
                 * @memberof visor.clientrpc.LoginRequest
                 * @instance
                 */
                LoginRequest.prototype.encryptedPassword = "";

                /**
                 * LoginRequest twoFactorCode.
                 * @member {string} twoFactorCode
                 * @memberof visor.clientrpc.LoginRequest
                 * @instance
                 */
                LoginRequest.prototype.twoFactorCode = "";

                /**
                 * Creates a new LoginRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {visor.clientrpc.ILoginRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.LoginRequest} LoginRequest instance
                 */
                LoginRequest.create = function create(properties) {
                    return new LoginRequest(properties);
                };

                /**
                 * Encodes the specified LoginRequest message. Does not implicitly {@link visor.clientrpc.LoginRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {visor.clientrpc.ILoginRequest} message LoginRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoginRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.encryptedPassword);
                    if (message.twoFactorCode != null && message.hasOwnProperty("twoFactorCode"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.twoFactorCode);
                    return writer;
                };

                /**
                 * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link visor.clientrpc.LoginRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {visor.clientrpc.ILoginRequest} message LoginRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LoginRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.LoginRequest} LoginRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoginRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.LoginRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.email = reader.string();
                            break;
                        case 2:
                            message.encryptedPassword = reader.string();
                            break;
                        case 3:
                            message.twoFactorCode = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.LoginRequest} LoginRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoginRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LoginRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LoginRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        if (!$util.isString(message.encryptedPassword))
                            return "encryptedPassword: string expected";
                    if (message.twoFactorCode != null && message.hasOwnProperty("twoFactorCode"))
                        if (!$util.isString(message.twoFactorCode))
                            return "twoFactorCode: string expected";
                    return null;
                };

                /**
                 * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.LoginRequest} LoginRequest
                 */
                LoginRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.LoginRequest)
                        return object;
                    var message = new $root.visor.clientrpc.LoginRequest();
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.encryptedPassword != null)
                        message.encryptedPassword = String(object.encryptedPassword);
                    if (object.twoFactorCode != null)
                        message.twoFactorCode = String(object.twoFactorCode);
                    return message;
                };

                /**
                 * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.LoginRequest
                 * @static
                 * @param {visor.clientrpc.LoginRequest} message LoginRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LoginRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.encryptedPassword = "";
                        object.twoFactorCode = "";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.encryptedPassword != null && message.hasOwnProperty("encryptedPassword"))
                        object.encryptedPassword = message.encryptedPassword;
                    if (message.twoFactorCode != null && message.hasOwnProperty("twoFactorCode"))
                        object.twoFactorCode = message.twoFactorCode;
                    return object;
                };

                /**
                 * Converts this LoginRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.LoginRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LoginRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LoginRequest;
            })();

            clientrpc.LoginResponse = (function() {

                /**
                 * Properties of a LoginResponse.
                 * @memberof visor.clientrpc
                 * @interface ILoginResponse
                 * @property {string|null} [firebaseAuthToken] LoginResponse firebaseAuthToken
                 * @property {visor.clientrpc.ITwoFAChallenge|null} [twoFaRequired] LoginResponse twoFaRequired
                 */

                /**
                 * Constructs a new LoginResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a LoginResponse.
                 * @implements ILoginResponse
                 * @constructor
                 * @param {visor.clientrpc.ILoginResponse=} [properties] Properties to set
                 */
                function LoginResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LoginResponse firebaseAuthToken.
                 * @member {string} firebaseAuthToken
                 * @memberof visor.clientrpc.LoginResponse
                 * @instance
                 */
                LoginResponse.prototype.firebaseAuthToken = "";

                /**
                 * LoginResponse twoFaRequired.
                 * @member {visor.clientrpc.ITwoFAChallenge|null|undefined} twoFaRequired
                 * @memberof visor.clientrpc.LoginResponse
                 * @instance
                 */
                LoginResponse.prototype.twoFaRequired = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * LoginResponse response.
                 * @member {"firebaseAuthToken"|"twoFaRequired"|undefined} response
                 * @memberof visor.clientrpc.LoginResponse
                 * @instance
                 */
                Object.defineProperty(LoginResponse.prototype, "response", {
                    get: $util.oneOfGetter($oneOfFields = ["firebaseAuthToken", "twoFaRequired"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new LoginResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {visor.clientrpc.ILoginResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.LoginResponse} LoginResponse instance
                 */
                LoginResponse.create = function create(properties) {
                    return new LoginResponse(properties);
                };

                /**
                 * Encodes the specified LoginResponse message. Does not implicitly {@link visor.clientrpc.LoginResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {visor.clientrpc.ILoginResponse} message LoginResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoginResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firebaseAuthToken != null && message.hasOwnProperty("firebaseAuthToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firebaseAuthToken);
                    if (message.twoFaRequired != null && message.hasOwnProperty("twoFaRequired"))
                        $root.visor.clientrpc.TwoFAChallenge.encode(message.twoFaRequired, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link visor.clientrpc.LoginResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {visor.clientrpc.ILoginResponse} message LoginResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LoginResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.LoginResponse} LoginResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoginResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.LoginResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.firebaseAuthToken = reader.string();
                            break;
                        case 2:
                            message.twoFaRequired = $root.visor.clientrpc.TwoFAChallenge.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.LoginResponse} LoginResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LoginResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LoginResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LoginResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.firebaseAuthToken != null && message.hasOwnProperty("firebaseAuthToken")) {
                        properties.response = 1;
                        if (!$util.isString(message.firebaseAuthToken))
                            return "firebaseAuthToken: string expected";
                    }
                    if (message.twoFaRequired != null && message.hasOwnProperty("twoFaRequired")) {
                        if (properties.response === 1)
                            return "response: multiple values";
                        properties.response = 1;
                        {
                            var error = $root.visor.clientrpc.TwoFAChallenge.verify(message.twoFaRequired);
                            if (error)
                                return "twoFaRequired." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.LoginResponse} LoginResponse
                 */
                LoginResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.LoginResponse)
                        return object;
                    var message = new $root.visor.clientrpc.LoginResponse();
                    if (object.firebaseAuthToken != null)
                        message.firebaseAuthToken = String(object.firebaseAuthToken);
                    if (object.twoFaRequired != null) {
                        if (typeof object.twoFaRequired !== "object")
                            throw TypeError(".visor.clientrpc.LoginResponse.twoFaRequired: object expected");
                        message.twoFaRequired = $root.visor.clientrpc.TwoFAChallenge.fromObject(object.twoFaRequired);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.LoginResponse
                 * @static
                 * @param {visor.clientrpc.LoginResponse} message LoginResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LoginResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.firebaseAuthToken != null && message.hasOwnProperty("firebaseAuthToken")) {
                        object.firebaseAuthToken = message.firebaseAuthToken;
                        if (options.oneofs)
                            object.response = "firebaseAuthToken";
                    }
                    if (message.twoFaRequired != null && message.hasOwnProperty("twoFaRequired")) {
                        object.twoFaRequired = $root.visor.clientrpc.TwoFAChallenge.toObject(message.twoFaRequired, options);
                        if (options.oneofs)
                            object.response = "twoFaRequired";
                    }
                    return object;
                };

                /**
                 * Converts this LoginResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.LoginResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LoginResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LoginResponse;
            })();

            clientrpc.TwoFAChallenge = (function() {

                /**
                 * Properties of a TwoFAChallenge.
                 * @memberof visor.clientrpc
                 * @interface ITwoFAChallenge
                 * @property {google.protobuf.ITimestamp|null} [codeSentAt] TwoFAChallenge codeSentAt
                 * @property {google.protobuf.ITimestamp|null} [nextAvailableAt] TwoFAChallenge nextAvailableAt
                 * @property {string|null} [codeSentToLast_4] TwoFAChallenge codeSentToLast_4
                 */

                /**
                 * Constructs a new TwoFAChallenge.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a TwoFAChallenge.
                 * @implements ITwoFAChallenge
                 * @constructor
                 * @param {visor.clientrpc.ITwoFAChallenge=} [properties] Properties to set
                 */
                function TwoFAChallenge(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TwoFAChallenge codeSentAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} codeSentAt
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @instance
                 */
                TwoFAChallenge.prototype.codeSentAt = null;

                /**
                 * TwoFAChallenge nextAvailableAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} nextAvailableAt
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @instance
                 */
                TwoFAChallenge.prototype.nextAvailableAt = null;

                /**
                 * TwoFAChallenge codeSentToLast_4.
                 * @member {string} codeSentToLast_4
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @instance
                 */
                TwoFAChallenge.prototype.codeSentToLast_4 = "";

                /**
                 * Creates a new TwoFAChallenge instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {visor.clientrpc.ITwoFAChallenge=} [properties] Properties to set
                 * @returns {visor.clientrpc.TwoFAChallenge} TwoFAChallenge instance
                 */
                TwoFAChallenge.create = function create(properties) {
                    return new TwoFAChallenge(properties);
                };

                /**
                 * Encodes the specified TwoFAChallenge message. Does not implicitly {@link visor.clientrpc.TwoFAChallenge.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {visor.clientrpc.ITwoFAChallenge} message TwoFAChallenge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TwoFAChallenge.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.codeSentAt != null && message.hasOwnProperty("codeSentAt"))
                        $root.google.protobuf.Timestamp.encode(message.codeSentAt, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.nextAvailableAt != null && message.hasOwnProperty("nextAvailableAt"))
                        $root.google.protobuf.Timestamp.encode(message.nextAvailableAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.codeSentToLast_4 != null && message.hasOwnProperty("codeSentToLast_4"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.codeSentToLast_4);
                    return writer;
                };

                /**
                 * Encodes the specified TwoFAChallenge message, length delimited. Does not implicitly {@link visor.clientrpc.TwoFAChallenge.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {visor.clientrpc.ITwoFAChallenge} message TwoFAChallenge message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TwoFAChallenge.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TwoFAChallenge message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.TwoFAChallenge} TwoFAChallenge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TwoFAChallenge.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.TwoFAChallenge();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.codeSentAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.nextAvailableAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.codeSentToLast_4 = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TwoFAChallenge message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.TwoFAChallenge} TwoFAChallenge
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TwoFAChallenge.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TwoFAChallenge message.
                 * @function verify
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TwoFAChallenge.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.codeSentAt != null && message.hasOwnProperty("codeSentAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.codeSentAt);
                        if (error)
                            return "codeSentAt." + error;
                    }
                    if (message.nextAvailableAt != null && message.hasOwnProperty("nextAvailableAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.nextAvailableAt);
                        if (error)
                            return "nextAvailableAt." + error;
                    }
                    if (message.codeSentToLast_4 != null && message.hasOwnProperty("codeSentToLast_4"))
                        if (!$util.isString(message.codeSentToLast_4))
                            return "codeSentToLast_4: string expected";
                    return null;
                };

                /**
                 * Creates a TwoFAChallenge message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.TwoFAChallenge} TwoFAChallenge
                 */
                TwoFAChallenge.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.TwoFAChallenge)
                        return object;
                    var message = new $root.visor.clientrpc.TwoFAChallenge();
                    if (object.codeSentAt != null)
                        if (object.codeSentAt === null)
                            message.codeSentAt = {};
                        else
                            message.codeSentAt = {
                                seconds: Math.floor(object.codeSentAt.getTime() / 1000),
                                nanos: object.codeSentAt.getMilliseconds() * 1000000
                            };
                    if (object.nextAvailableAt != null)
                        if (object.nextAvailableAt === null)
                            message.nextAvailableAt = {};
                        else
                            message.nextAvailableAt = {
                                seconds: Math.floor(object.nextAvailableAt.getTime() / 1000),
                                nanos: object.nextAvailableAt.getMilliseconds() * 1000000
                            };
                    if (object.codeSentToLast_4 != null)
                        message.codeSentToLast_4 = String(object.codeSentToLast_4);
                    return message;
                };

                /**
                 * Creates a plain object from a TwoFAChallenge message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @static
                 * @param {visor.clientrpc.TwoFAChallenge} message TwoFAChallenge
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TwoFAChallenge.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.codeSentAt = null;
                        object.nextAvailableAt = null;
                        object.codeSentToLast_4 = "";
                    }
                    if (message.codeSentAt != null && message.hasOwnProperty("codeSentAt"))
                        object.codeSentAt = new Date(message.codeSentAt.seconds * 1000 + message.codeSentAt.nanos / 1000000);
                    if (message.nextAvailableAt != null && message.hasOwnProperty("nextAvailableAt"))
                        object.nextAvailableAt = new Date(message.nextAvailableAt.seconds * 1000 + message.nextAvailableAt.nanos / 1000000);
                    if (message.codeSentToLast_4 != null && message.hasOwnProperty("codeSentToLast_4"))
                        object.codeSentToLast_4 = message.codeSentToLast_4;
                    return object;
                };

                /**
                 * Converts this TwoFAChallenge to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.TwoFAChallenge
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TwoFAChallenge.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TwoFAChallenge;
            })();

            clientrpc.LogoutRequest = (function() {

                /**
                 * Properties of a LogoutRequest.
                 * @memberof visor.clientrpc
                 * @interface ILogoutRequest
                 * @property {string|null} [deviceUuid] LogoutRequest deviceUuid
                 */

                /**
                 * Constructs a new LogoutRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a LogoutRequest.
                 * @implements ILogoutRequest
                 * @constructor
                 * @param {visor.clientrpc.ILogoutRequest=} [properties] Properties to set
                 */
                function LogoutRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LogoutRequest deviceUuid.
                 * @member {string} deviceUuid
                 * @memberof visor.clientrpc.LogoutRequest
                 * @instance
                 */
                LogoutRequest.prototype.deviceUuid = "";

                /**
                 * Creates a new LogoutRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {visor.clientrpc.ILogoutRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.LogoutRequest} LogoutRequest instance
                 */
                LogoutRequest.create = function create(properties) {
                    return new LogoutRequest(properties);
                };

                /**
                 * Encodes the specified LogoutRequest message. Does not implicitly {@link visor.clientrpc.LogoutRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {visor.clientrpc.ILogoutRequest} message LogoutRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogoutRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceUuid != null && message.hasOwnProperty("deviceUuid"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceUuid);
                    return writer;
                };

                /**
                 * Encodes the specified LogoutRequest message, length delimited. Does not implicitly {@link visor.clientrpc.LogoutRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {visor.clientrpc.ILogoutRequest} message LogoutRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LogoutRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.LogoutRequest} LogoutRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogoutRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.LogoutRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.deviceUuid = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LogoutRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.LogoutRequest} LogoutRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogoutRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LogoutRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogoutRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceUuid != null && message.hasOwnProperty("deviceUuid"))
                        if (!$util.isString(message.deviceUuid))
                            return "deviceUuid: string expected";
                    return null;
                };

                /**
                 * Creates a LogoutRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.LogoutRequest} LogoutRequest
                 */
                LogoutRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.LogoutRequest)
                        return object;
                    var message = new $root.visor.clientrpc.LogoutRequest();
                    if (object.deviceUuid != null)
                        message.deviceUuid = String(object.deviceUuid);
                    return message;
                };

                /**
                 * Creates a plain object from a LogoutRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.LogoutRequest
                 * @static
                 * @param {visor.clientrpc.LogoutRequest} message LogoutRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogoutRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.deviceUuid = "";
                    if (message.deviceUuid != null && message.hasOwnProperty("deviceUuid"))
                        object.deviceUuid = message.deviceUuid;
                    return object;
                };

                /**
                 * Converts this LogoutRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.LogoutRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogoutRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LogoutRequest;
            })();

            clientrpc.LogoutResponse = (function() {

                /**
                 * Properties of a LogoutResponse.
                 * @memberof visor.clientrpc
                 * @interface ILogoutResponse
                 */

                /**
                 * Constructs a new LogoutResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a LogoutResponse.
                 * @implements ILogoutResponse
                 * @constructor
                 * @param {visor.clientrpc.ILogoutResponse=} [properties] Properties to set
                 */
                function LogoutResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new LogoutResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {visor.clientrpc.ILogoutResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.LogoutResponse} LogoutResponse instance
                 */
                LogoutResponse.create = function create(properties) {
                    return new LogoutResponse(properties);
                };

                /**
                 * Encodes the specified LogoutResponse message. Does not implicitly {@link visor.clientrpc.LogoutResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {visor.clientrpc.ILogoutResponse} message LogoutResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogoutResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified LogoutResponse message, length delimited. Does not implicitly {@link visor.clientrpc.LogoutResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {visor.clientrpc.ILogoutResponse} message LogoutResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LogoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LogoutResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.LogoutResponse} LogoutResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogoutResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.LogoutResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LogoutResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.LogoutResponse} LogoutResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LogoutResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LogoutResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LogoutResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a LogoutResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.LogoutResponse} LogoutResponse
                 */
                LogoutResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.LogoutResponse)
                        return object;
                    return new $root.visor.clientrpc.LogoutResponse();
                };

                /**
                 * Creates a plain object from a LogoutResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.LogoutResponse
                 * @static
                 * @param {visor.clientrpc.LogoutResponse} message LogoutResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LogoutResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this LogoutResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.LogoutResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LogoutResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LogoutResponse;
            })();

            clientrpc.Start2FASetupRequest = (function() {

                /**
                 * Properties of a Start2FASetupRequest.
                 * @memberof visor.clientrpc
                 * @interface IStart2FASetupRequest
                 */

                /**
                 * Constructs a new Start2FASetupRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Start2FASetupRequest.
                 * @implements IStart2FASetupRequest
                 * @constructor
                 * @param {visor.clientrpc.IStart2FASetupRequest=} [properties] Properties to set
                 */
                function Start2FASetupRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Start2FASetupRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.IStart2FASetupRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.Start2FASetupRequest} Start2FASetupRequest instance
                 */
                Start2FASetupRequest.create = function create(properties) {
                    return new Start2FASetupRequest(properties);
                };

                /**
                 * Encodes the specified Start2FASetupRequest message. Does not implicitly {@link visor.clientrpc.Start2FASetupRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.IStart2FASetupRequest} message Start2FASetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Start2FASetupRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Start2FASetupRequest message, length delimited. Does not implicitly {@link visor.clientrpc.Start2FASetupRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.IStart2FASetupRequest} message Start2FASetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Start2FASetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Start2FASetupRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Start2FASetupRequest} Start2FASetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Start2FASetupRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Start2FASetupRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Start2FASetupRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Start2FASetupRequest} Start2FASetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Start2FASetupRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Start2FASetupRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Start2FASetupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Start2FASetupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Start2FASetupRequest} Start2FASetupRequest
                 */
                Start2FASetupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Start2FASetupRequest)
                        return object;
                    return new $root.visor.clientrpc.Start2FASetupRequest();
                };

                /**
                 * Creates a plain object from a Start2FASetupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.Start2FASetupRequest} message Start2FASetupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Start2FASetupRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Start2FASetupRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Start2FASetupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Start2FASetupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Start2FASetupRequest;
            })();

            clientrpc.Start2FASetupResponse = (function() {

                /**
                 * Properties of a Start2FASetupResponse.
                 * @memberof visor.clientrpc
                 * @interface IStart2FASetupResponse
                 * @property {string|null} [enrollmentToken] Start2FASetupResponse enrollmentToken
                 * @property {visor.clientrpc.ITwoFAChallenge|null} [twoFaChallenge] Start2FASetupResponse twoFaChallenge
                 */

                /**
                 * Constructs a new Start2FASetupResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Start2FASetupResponse.
                 * @implements IStart2FASetupResponse
                 * @constructor
                 * @param {visor.clientrpc.IStart2FASetupResponse=} [properties] Properties to set
                 */
                function Start2FASetupResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Start2FASetupResponse enrollmentToken.
                 * @member {string} enrollmentToken
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @instance
                 */
                Start2FASetupResponse.prototype.enrollmentToken = "";

                /**
                 * Start2FASetupResponse twoFaChallenge.
                 * @member {visor.clientrpc.ITwoFAChallenge|null|undefined} twoFaChallenge
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @instance
                 */
                Start2FASetupResponse.prototype.twoFaChallenge = null;

                /**
                 * Creates a new Start2FASetupResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.IStart2FASetupResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.Start2FASetupResponse} Start2FASetupResponse instance
                 */
                Start2FASetupResponse.create = function create(properties) {
                    return new Start2FASetupResponse(properties);
                };

                /**
                 * Encodes the specified Start2FASetupResponse message. Does not implicitly {@link visor.clientrpc.Start2FASetupResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.IStart2FASetupResponse} message Start2FASetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Start2FASetupResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.twoFaChallenge != null && message.hasOwnProperty("twoFaChallenge"))
                        $root.visor.clientrpc.TwoFAChallenge.encode(message.twoFaChallenge, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.enrollmentToken != null && message.hasOwnProperty("enrollmentToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.enrollmentToken);
                    return writer;
                };

                /**
                 * Encodes the specified Start2FASetupResponse message, length delimited. Does not implicitly {@link visor.clientrpc.Start2FASetupResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.IStart2FASetupResponse} message Start2FASetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Start2FASetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Start2FASetupResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Start2FASetupResponse} Start2FASetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Start2FASetupResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Start2FASetupResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            message.enrollmentToken = reader.string();
                            break;
                        case 1:
                            message.twoFaChallenge = $root.visor.clientrpc.TwoFAChallenge.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Start2FASetupResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Start2FASetupResponse} Start2FASetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Start2FASetupResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Start2FASetupResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Start2FASetupResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enrollmentToken != null && message.hasOwnProperty("enrollmentToken"))
                        if (!$util.isString(message.enrollmentToken))
                            return "enrollmentToken: string expected";
                    if (message.twoFaChallenge != null && message.hasOwnProperty("twoFaChallenge")) {
                        var error = $root.visor.clientrpc.TwoFAChallenge.verify(message.twoFaChallenge);
                        if (error)
                            return "twoFaChallenge." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Start2FASetupResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Start2FASetupResponse} Start2FASetupResponse
                 */
                Start2FASetupResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Start2FASetupResponse)
                        return object;
                    var message = new $root.visor.clientrpc.Start2FASetupResponse();
                    if (object.enrollmentToken != null)
                        message.enrollmentToken = String(object.enrollmentToken);
                    if (object.twoFaChallenge != null) {
                        if (typeof object.twoFaChallenge !== "object")
                            throw TypeError(".visor.clientrpc.Start2FASetupResponse.twoFaChallenge: object expected");
                        message.twoFaChallenge = $root.visor.clientrpc.TwoFAChallenge.fromObject(object.twoFaChallenge);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Start2FASetupResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.Start2FASetupResponse} message Start2FASetupResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Start2FASetupResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.twoFaChallenge = null;
                        object.enrollmentToken = "";
                    }
                    if (message.twoFaChallenge != null && message.hasOwnProperty("twoFaChallenge"))
                        object.twoFaChallenge = $root.visor.clientrpc.TwoFAChallenge.toObject(message.twoFaChallenge, options);
                    if (message.enrollmentToken != null && message.hasOwnProperty("enrollmentToken"))
                        object.enrollmentToken = message.enrollmentToken;
                    return object;
                };

                /**
                 * Converts this Start2FASetupResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Start2FASetupResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Start2FASetupResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Start2FASetupResponse;
            })();

            clientrpc.Finish2FASetupRequest = (function() {

                /**
                 * Properties of a Finish2FASetupRequest.
                 * @memberof visor.clientrpc
                 * @interface IFinish2FASetupRequest
                 * @property {string|null} [enrollmentToken] Finish2FASetupRequest enrollmentToken
                 * @property {string|null} [twoFactorCode] Finish2FASetupRequest twoFactorCode
                 */

                /**
                 * Constructs a new Finish2FASetupRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Finish2FASetupRequest.
                 * @implements IFinish2FASetupRequest
                 * @constructor
                 * @param {visor.clientrpc.IFinish2FASetupRequest=} [properties] Properties to set
                 */
                function Finish2FASetupRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Finish2FASetupRequest enrollmentToken.
                 * @member {string} enrollmentToken
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @instance
                 */
                Finish2FASetupRequest.prototype.enrollmentToken = "";

                /**
                 * Finish2FASetupRequest twoFactorCode.
                 * @member {string} twoFactorCode
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @instance
                 */
                Finish2FASetupRequest.prototype.twoFactorCode = "";

                /**
                 * Creates a new Finish2FASetupRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.IFinish2FASetupRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.Finish2FASetupRequest} Finish2FASetupRequest instance
                 */
                Finish2FASetupRequest.create = function create(properties) {
                    return new Finish2FASetupRequest(properties);
                };

                /**
                 * Encodes the specified Finish2FASetupRequest message. Does not implicitly {@link visor.clientrpc.Finish2FASetupRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.IFinish2FASetupRequest} message Finish2FASetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Finish2FASetupRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.twoFactorCode != null && message.hasOwnProperty("twoFactorCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.twoFactorCode);
                    if (message.enrollmentToken != null && message.hasOwnProperty("enrollmentToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.enrollmentToken);
                    return writer;
                };

                /**
                 * Encodes the specified Finish2FASetupRequest message, length delimited. Does not implicitly {@link visor.clientrpc.Finish2FASetupRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.IFinish2FASetupRequest} message Finish2FASetupRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Finish2FASetupRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Finish2FASetupRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Finish2FASetupRequest} Finish2FASetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Finish2FASetupRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Finish2FASetupRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            message.enrollmentToken = reader.string();
                            break;
                        case 1:
                            message.twoFactorCode = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Finish2FASetupRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Finish2FASetupRequest} Finish2FASetupRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Finish2FASetupRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Finish2FASetupRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Finish2FASetupRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.enrollmentToken != null && message.hasOwnProperty("enrollmentToken"))
                        if (!$util.isString(message.enrollmentToken))
                            return "enrollmentToken: string expected";
                    if (message.twoFactorCode != null && message.hasOwnProperty("twoFactorCode"))
                        if (!$util.isString(message.twoFactorCode))
                            return "twoFactorCode: string expected";
                    return null;
                };

                /**
                 * Creates a Finish2FASetupRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Finish2FASetupRequest} Finish2FASetupRequest
                 */
                Finish2FASetupRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Finish2FASetupRequest)
                        return object;
                    var message = new $root.visor.clientrpc.Finish2FASetupRequest();
                    if (object.enrollmentToken != null)
                        message.enrollmentToken = String(object.enrollmentToken);
                    if (object.twoFactorCode != null)
                        message.twoFactorCode = String(object.twoFactorCode);
                    return message;
                };

                /**
                 * Creates a plain object from a Finish2FASetupRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @static
                 * @param {visor.clientrpc.Finish2FASetupRequest} message Finish2FASetupRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Finish2FASetupRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.twoFactorCode = "";
                        object.enrollmentToken = "";
                    }
                    if (message.twoFactorCode != null && message.hasOwnProperty("twoFactorCode"))
                        object.twoFactorCode = message.twoFactorCode;
                    if (message.enrollmentToken != null && message.hasOwnProperty("enrollmentToken"))
                        object.enrollmentToken = message.enrollmentToken;
                    return object;
                };

                /**
                 * Converts this Finish2FASetupRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Finish2FASetupRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Finish2FASetupRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Finish2FASetupRequest;
            })();

            clientrpc.Finish2FASetupResponse = (function() {

                /**
                 * Properties of a Finish2FASetupResponse.
                 * @memberof visor.clientrpc
                 * @interface IFinish2FASetupResponse
                 */

                /**
                 * Constructs a new Finish2FASetupResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Finish2FASetupResponse.
                 * @implements IFinish2FASetupResponse
                 * @constructor
                 * @param {visor.clientrpc.IFinish2FASetupResponse=} [properties] Properties to set
                 */
                function Finish2FASetupResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Finish2FASetupResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.IFinish2FASetupResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.Finish2FASetupResponse} Finish2FASetupResponse instance
                 */
                Finish2FASetupResponse.create = function create(properties) {
                    return new Finish2FASetupResponse(properties);
                };

                /**
                 * Encodes the specified Finish2FASetupResponse message. Does not implicitly {@link visor.clientrpc.Finish2FASetupResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.IFinish2FASetupResponse} message Finish2FASetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Finish2FASetupResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Finish2FASetupResponse message, length delimited. Does not implicitly {@link visor.clientrpc.Finish2FASetupResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.IFinish2FASetupResponse} message Finish2FASetupResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Finish2FASetupResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Finish2FASetupResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Finish2FASetupResponse} Finish2FASetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Finish2FASetupResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Finish2FASetupResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Finish2FASetupResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Finish2FASetupResponse} Finish2FASetupResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Finish2FASetupResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Finish2FASetupResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Finish2FASetupResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Finish2FASetupResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Finish2FASetupResponse} Finish2FASetupResponse
                 */
                Finish2FASetupResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Finish2FASetupResponse)
                        return object;
                    return new $root.visor.clientrpc.Finish2FASetupResponse();
                };

                /**
                 * Creates a plain object from a Finish2FASetupResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @static
                 * @param {visor.clientrpc.Finish2FASetupResponse} message Finish2FASetupResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Finish2FASetupResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Finish2FASetupResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Finish2FASetupResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Finish2FASetupResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Finish2FASetupResponse;
            })();

            clientrpc.Disable2FARequest = (function() {

                /**
                 * Properties of a Disable2FARequest.
                 * @memberof visor.clientrpc
                 * @interface IDisable2FARequest
                 */

                /**
                 * Constructs a new Disable2FARequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Disable2FARequest.
                 * @implements IDisable2FARequest
                 * @constructor
                 * @param {visor.clientrpc.IDisable2FARequest=} [properties] Properties to set
                 */
                function Disable2FARequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Disable2FARequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {visor.clientrpc.IDisable2FARequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.Disable2FARequest} Disable2FARequest instance
                 */
                Disable2FARequest.create = function create(properties) {
                    return new Disable2FARequest(properties);
                };

                /**
                 * Encodes the specified Disable2FARequest message. Does not implicitly {@link visor.clientrpc.Disable2FARequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {visor.clientrpc.IDisable2FARequest} message Disable2FARequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Disable2FARequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Disable2FARequest message, length delimited. Does not implicitly {@link visor.clientrpc.Disable2FARequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {visor.clientrpc.IDisable2FARequest} message Disable2FARequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Disable2FARequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Disable2FARequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Disable2FARequest} Disable2FARequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Disable2FARequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Disable2FARequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Disable2FARequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Disable2FARequest} Disable2FARequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Disable2FARequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Disable2FARequest message.
                 * @function verify
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Disable2FARequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Disable2FARequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Disable2FARequest} Disable2FARequest
                 */
                Disable2FARequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Disable2FARequest)
                        return object;
                    return new $root.visor.clientrpc.Disable2FARequest();
                };

                /**
                 * Creates a plain object from a Disable2FARequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @static
                 * @param {visor.clientrpc.Disable2FARequest} message Disable2FARequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Disable2FARequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Disable2FARequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Disable2FARequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Disable2FARequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Disable2FARequest;
            })();

            clientrpc.Disable2FAResponse = (function() {

                /**
                 * Properties of a Disable2FAResponse.
                 * @memberof visor.clientrpc
                 * @interface IDisable2FAResponse
                 */

                /**
                 * Constructs a new Disable2FAResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Disable2FAResponse.
                 * @implements IDisable2FAResponse
                 * @constructor
                 * @param {visor.clientrpc.IDisable2FAResponse=} [properties] Properties to set
                 */
                function Disable2FAResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Disable2FAResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {visor.clientrpc.IDisable2FAResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.Disable2FAResponse} Disable2FAResponse instance
                 */
                Disable2FAResponse.create = function create(properties) {
                    return new Disable2FAResponse(properties);
                };

                /**
                 * Encodes the specified Disable2FAResponse message. Does not implicitly {@link visor.clientrpc.Disable2FAResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {visor.clientrpc.IDisable2FAResponse} message Disable2FAResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Disable2FAResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Disable2FAResponse message, length delimited. Does not implicitly {@link visor.clientrpc.Disable2FAResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {visor.clientrpc.IDisable2FAResponse} message Disable2FAResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Disable2FAResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Disable2FAResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Disable2FAResponse} Disable2FAResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Disable2FAResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Disable2FAResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Disable2FAResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Disable2FAResponse} Disable2FAResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Disable2FAResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Disable2FAResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Disable2FAResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Disable2FAResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Disable2FAResponse} Disable2FAResponse
                 */
                Disable2FAResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Disable2FAResponse)
                        return object;
                    return new $root.visor.clientrpc.Disable2FAResponse();
                };

                /**
                 * Creates a plain object from a Disable2FAResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @static
                 * @param {visor.clientrpc.Disable2FAResponse} message Disable2FAResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Disable2FAResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Disable2FAResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Disable2FAResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Disable2FAResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Disable2FAResponse;
            })();

            clientrpc.StartForgotPasswordRequest = (function() {

                /**
                 * Properties of a StartForgotPasswordRequest.
                 * @memberof visor.clientrpc
                 * @interface IStartForgotPasswordRequest
                 * @property {string|null} [email] StartForgotPasswordRequest email
                 */

                /**
                 * Constructs a new StartForgotPasswordRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a StartForgotPasswordRequest.
                 * @implements IStartForgotPasswordRequest
                 * @constructor
                 * @param {visor.clientrpc.IStartForgotPasswordRequest=} [properties] Properties to set
                 */
                function StartForgotPasswordRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StartForgotPasswordRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @instance
                 */
                StartForgotPasswordRequest.prototype.email = "";

                /**
                 * Creates a new StartForgotPasswordRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.IStartForgotPasswordRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.StartForgotPasswordRequest} StartForgotPasswordRequest instance
                 */
                StartForgotPasswordRequest.create = function create(properties) {
                    return new StartForgotPasswordRequest(properties);
                };

                /**
                 * Encodes the specified StartForgotPasswordRequest message. Does not implicitly {@link visor.clientrpc.StartForgotPasswordRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.IStartForgotPasswordRequest} message StartForgotPasswordRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartForgotPasswordRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified StartForgotPasswordRequest message, length delimited. Does not implicitly {@link visor.clientrpc.StartForgotPasswordRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.IStartForgotPasswordRequest} message StartForgotPasswordRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartForgotPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StartForgotPasswordRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.StartForgotPasswordRequest} StartForgotPasswordRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartForgotPasswordRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.StartForgotPasswordRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.email = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StartForgotPasswordRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.StartForgotPasswordRequest} StartForgotPasswordRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartForgotPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StartForgotPasswordRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartForgotPasswordRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a StartForgotPasswordRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.StartForgotPasswordRequest} StartForgotPasswordRequest
                 */
                StartForgotPasswordRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.StartForgotPasswordRequest)
                        return object;
                    var message = new $root.visor.clientrpc.StartForgotPasswordRequest();
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a StartForgotPasswordRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.StartForgotPasswordRequest} message StartForgotPasswordRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartForgotPasswordRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.email = "";
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this StartForgotPasswordRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.StartForgotPasswordRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartForgotPasswordRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartForgotPasswordRequest;
            })();

            clientrpc.ConfirmEmailRequest = (function() {

                /**
                 * Properties of a ConfirmEmailRequest.
                 * @memberof visor.clientrpc
                 * @interface IConfirmEmailRequest
                 * @property {string|null} [token] ConfirmEmailRequest token
                 * @property {string|null} [validationCode] ConfirmEmailRequest validationCode
                 */

                /**
                 * Constructs a new ConfirmEmailRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ConfirmEmailRequest.
                 * @implements IConfirmEmailRequest
                 * @constructor
                 * @param {visor.clientrpc.IConfirmEmailRequest=} [properties] Properties to set
                 */
                function ConfirmEmailRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ConfirmEmailRequest token.
                 * @member {string} token
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @instance
                 */
                ConfirmEmailRequest.prototype.token = "";

                /**
                 * ConfirmEmailRequest validationCode.
                 * @member {string} validationCode
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @instance
                 */
                ConfirmEmailRequest.prototype.validationCode = "";

                /**
                 * Creates a new ConfirmEmailRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {visor.clientrpc.IConfirmEmailRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ConfirmEmailRequest} ConfirmEmailRequest instance
                 */
                ConfirmEmailRequest.create = function create(properties) {
                    return new ConfirmEmailRequest(properties);
                };

                /**
                 * Encodes the specified ConfirmEmailRequest message. Does not implicitly {@link visor.clientrpc.ConfirmEmailRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {visor.clientrpc.IConfirmEmailRequest} message ConfirmEmailRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmEmailRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                    if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.validationCode);
                    return writer;
                };

                /**
                 * Encodes the specified ConfirmEmailRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ConfirmEmailRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {visor.clientrpc.IConfirmEmailRequest} message ConfirmEmailRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmEmailRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ConfirmEmailRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ConfirmEmailRequest} ConfirmEmailRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmEmailRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ConfirmEmailRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.token = reader.string();
                            break;
                        case 2:
                            message.validationCode = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ConfirmEmailRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ConfirmEmailRequest} ConfirmEmailRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmEmailRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ConfirmEmailRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConfirmEmailRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                        if (!$util.isString(message.validationCode))
                            return "validationCode: string expected";
                    return null;
                };

                /**
                 * Creates a ConfirmEmailRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ConfirmEmailRequest} ConfirmEmailRequest
                 */
                ConfirmEmailRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ConfirmEmailRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ConfirmEmailRequest();
                    if (object.token != null)
                        message.token = String(object.token);
                    if (object.validationCode != null)
                        message.validationCode = String(object.validationCode);
                    return message;
                };

                /**
                 * Creates a plain object from a ConfirmEmailRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @static
                 * @param {visor.clientrpc.ConfirmEmailRequest} message ConfirmEmailRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConfirmEmailRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.token = "";
                        object.validationCode = "";
                    }
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                        object.validationCode = message.validationCode;
                    return object;
                };

                /**
                 * Converts this ConfirmEmailRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ConfirmEmailRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConfirmEmailRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ConfirmEmailRequest;
            })();

            clientrpc.ConfirmEmailResponse = (function() {

                /**
                 * Properties of a ConfirmEmailResponse.
                 * @memberof visor.clientrpc
                 * @interface IConfirmEmailResponse
                 */

                /**
                 * Constructs a new ConfirmEmailResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ConfirmEmailResponse.
                 * @implements IConfirmEmailResponse
                 * @constructor
                 * @param {visor.clientrpc.IConfirmEmailResponse=} [properties] Properties to set
                 */
                function ConfirmEmailResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ConfirmEmailResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {visor.clientrpc.IConfirmEmailResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ConfirmEmailResponse} ConfirmEmailResponse instance
                 */
                ConfirmEmailResponse.create = function create(properties) {
                    return new ConfirmEmailResponse(properties);
                };

                /**
                 * Encodes the specified ConfirmEmailResponse message. Does not implicitly {@link visor.clientrpc.ConfirmEmailResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {visor.clientrpc.IConfirmEmailResponse} message ConfirmEmailResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmEmailResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ConfirmEmailResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ConfirmEmailResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {visor.clientrpc.IConfirmEmailResponse} message ConfirmEmailResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfirmEmailResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ConfirmEmailResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ConfirmEmailResponse} ConfirmEmailResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmEmailResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ConfirmEmailResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ConfirmEmailResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ConfirmEmailResponse} ConfirmEmailResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfirmEmailResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ConfirmEmailResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConfirmEmailResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ConfirmEmailResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ConfirmEmailResponse} ConfirmEmailResponse
                 */
                ConfirmEmailResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ConfirmEmailResponse)
                        return object;
                    return new $root.visor.clientrpc.ConfirmEmailResponse();
                };

                /**
                 * Creates a plain object from a ConfirmEmailResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @static
                 * @param {visor.clientrpc.ConfirmEmailResponse} message ConfirmEmailResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConfirmEmailResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ConfirmEmailResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ConfirmEmailResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConfirmEmailResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ConfirmEmailResponse;
            })();

            clientrpc.StartForgotPasswordResponse = (function() {

                /**
                 * Properties of a StartForgotPasswordResponse.
                 * @memberof visor.clientrpc
                 * @interface IStartForgotPasswordResponse
                 */

                /**
                 * Constructs a new StartForgotPasswordResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a StartForgotPasswordResponse.
                 * @implements IStartForgotPasswordResponse
                 * @constructor
                 * @param {visor.clientrpc.IStartForgotPasswordResponse=} [properties] Properties to set
                 */
                function StartForgotPasswordResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new StartForgotPasswordResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.IStartForgotPasswordResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.StartForgotPasswordResponse} StartForgotPasswordResponse instance
                 */
                StartForgotPasswordResponse.create = function create(properties) {
                    return new StartForgotPasswordResponse(properties);
                };

                /**
                 * Encodes the specified StartForgotPasswordResponse message. Does not implicitly {@link visor.clientrpc.StartForgotPasswordResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.IStartForgotPasswordResponse} message StartForgotPasswordResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartForgotPasswordResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified StartForgotPasswordResponse message, length delimited. Does not implicitly {@link visor.clientrpc.StartForgotPasswordResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.IStartForgotPasswordResponse} message StartForgotPasswordResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StartForgotPasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StartForgotPasswordResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.StartForgotPasswordResponse} StartForgotPasswordResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartForgotPasswordResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.StartForgotPasswordResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StartForgotPasswordResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.StartForgotPasswordResponse} StartForgotPasswordResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StartForgotPasswordResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StartForgotPasswordResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StartForgotPasswordResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a StartForgotPasswordResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.StartForgotPasswordResponse} StartForgotPasswordResponse
                 */
                StartForgotPasswordResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.StartForgotPasswordResponse)
                        return object;
                    return new $root.visor.clientrpc.StartForgotPasswordResponse();
                };

                /**
                 * Creates a plain object from a StartForgotPasswordResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.StartForgotPasswordResponse} message StartForgotPasswordResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StartForgotPasswordResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this StartForgotPasswordResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.StartForgotPasswordResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StartForgotPasswordResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StartForgotPasswordResponse;
            })();

            clientrpc.FinishForgotPasswordRequest = (function() {

                /**
                 * Properties of a FinishForgotPasswordRequest.
                 * @memberof visor.clientrpc
                 * @interface IFinishForgotPasswordRequest
                 * @property {string|null} [token] FinishForgotPasswordRequest token
                 * @property {string|null} [encryptedCode] FinishForgotPasswordRequest encryptedCode
                 * @property {string|null} [encryptedNewPassword] FinishForgotPasswordRequest encryptedNewPassword
                 */

                /**
                 * Constructs a new FinishForgotPasswordRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FinishForgotPasswordRequest.
                 * @implements IFinishForgotPasswordRequest
                 * @constructor
                 * @param {visor.clientrpc.IFinishForgotPasswordRequest=} [properties] Properties to set
                 */
                function FinishForgotPasswordRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FinishForgotPasswordRequest token.
                 * @member {string} token
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @instance
                 */
                FinishForgotPasswordRequest.prototype.token = "";

                /**
                 * FinishForgotPasswordRequest encryptedCode.
                 * @member {string} encryptedCode
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @instance
                 */
                FinishForgotPasswordRequest.prototype.encryptedCode = "";

                /**
                 * FinishForgotPasswordRequest encryptedNewPassword.
                 * @member {string} encryptedNewPassword
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @instance
                 */
                FinishForgotPasswordRequest.prototype.encryptedNewPassword = "";

                /**
                 * Creates a new FinishForgotPasswordRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.IFinishForgotPasswordRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.FinishForgotPasswordRequest} FinishForgotPasswordRequest instance
                 */
                FinishForgotPasswordRequest.create = function create(properties) {
                    return new FinishForgotPasswordRequest(properties);
                };

                /**
                 * Encodes the specified FinishForgotPasswordRequest message. Does not implicitly {@link visor.clientrpc.FinishForgotPasswordRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.IFinishForgotPasswordRequest} message FinishForgotPasswordRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishForgotPasswordRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                    if (message.encryptedCode != null && message.hasOwnProperty("encryptedCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.encryptedCode);
                    if (message.encryptedNewPassword != null && message.hasOwnProperty("encryptedNewPassword"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.encryptedNewPassword);
                    return writer;
                };

                /**
                 * Encodes the specified FinishForgotPasswordRequest message, length delimited. Does not implicitly {@link visor.clientrpc.FinishForgotPasswordRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.IFinishForgotPasswordRequest} message FinishForgotPasswordRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishForgotPasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FinishForgotPasswordRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FinishForgotPasswordRequest} FinishForgotPasswordRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishForgotPasswordRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishForgotPasswordRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.token = reader.string();
                            break;
                        case 2:
                            message.encryptedCode = reader.string();
                            break;
                        case 3:
                            message.encryptedNewPassword = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FinishForgotPasswordRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FinishForgotPasswordRequest} FinishForgotPasswordRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishForgotPasswordRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FinishForgotPasswordRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FinishForgotPasswordRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.encryptedCode != null && message.hasOwnProperty("encryptedCode"))
                        if (!$util.isString(message.encryptedCode))
                            return "encryptedCode: string expected";
                    if (message.encryptedNewPassword != null && message.hasOwnProperty("encryptedNewPassword"))
                        if (!$util.isString(message.encryptedNewPassword))
                            return "encryptedNewPassword: string expected";
                    return null;
                };

                /**
                 * Creates a FinishForgotPasswordRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FinishForgotPasswordRequest} FinishForgotPasswordRequest
                 */
                FinishForgotPasswordRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FinishForgotPasswordRequest)
                        return object;
                    var message = new $root.visor.clientrpc.FinishForgotPasswordRequest();
                    if (object.token != null)
                        message.token = String(object.token);
                    if (object.encryptedCode != null)
                        message.encryptedCode = String(object.encryptedCode);
                    if (object.encryptedNewPassword != null)
                        message.encryptedNewPassword = String(object.encryptedNewPassword);
                    return message;
                };

                /**
                 * Creates a plain object from a FinishForgotPasswordRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @static
                 * @param {visor.clientrpc.FinishForgotPasswordRequest} message FinishForgotPasswordRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FinishForgotPasswordRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.token = "";
                        object.encryptedCode = "";
                        object.encryptedNewPassword = "";
                    }
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.encryptedCode != null && message.hasOwnProperty("encryptedCode"))
                        object.encryptedCode = message.encryptedCode;
                    if (message.encryptedNewPassword != null && message.hasOwnProperty("encryptedNewPassword"))
                        object.encryptedNewPassword = message.encryptedNewPassword;
                    return object;
                };

                /**
                 * Converts this FinishForgotPasswordRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FinishForgotPasswordRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FinishForgotPasswordRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FinishForgotPasswordRequest;
            })();

            clientrpc.FinishForgotPasswordResponse = (function() {

                /**
                 * Properties of a FinishForgotPasswordResponse.
                 * @memberof visor.clientrpc
                 * @interface IFinishForgotPasswordResponse
                 */

                /**
                 * Constructs a new FinishForgotPasswordResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FinishForgotPasswordResponse.
                 * @implements IFinishForgotPasswordResponse
                 * @constructor
                 * @param {visor.clientrpc.IFinishForgotPasswordResponse=} [properties] Properties to set
                 */
                function FinishForgotPasswordResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new FinishForgotPasswordResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.IFinishForgotPasswordResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.FinishForgotPasswordResponse} FinishForgotPasswordResponse instance
                 */
                FinishForgotPasswordResponse.create = function create(properties) {
                    return new FinishForgotPasswordResponse(properties);
                };

                /**
                 * Encodes the specified FinishForgotPasswordResponse message. Does not implicitly {@link visor.clientrpc.FinishForgotPasswordResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.IFinishForgotPasswordResponse} message FinishForgotPasswordResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishForgotPasswordResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified FinishForgotPasswordResponse message, length delimited. Does not implicitly {@link visor.clientrpc.FinishForgotPasswordResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.IFinishForgotPasswordResponse} message FinishForgotPasswordResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishForgotPasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FinishForgotPasswordResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FinishForgotPasswordResponse} FinishForgotPasswordResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishForgotPasswordResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishForgotPasswordResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FinishForgotPasswordResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FinishForgotPasswordResponse} FinishForgotPasswordResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishForgotPasswordResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FinishForgotPasswordResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FinishForgotPasswordResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a FinishForgotPasswordResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FinishForgotPasswordResponse} FinishForgotPasswordResponse
                 */
                FinishForgotPasswordResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FinishForgotPasswordResponse)
                        return object;
                    return new $root.visor.clientrpc.FinishForgotPasswordResponse();
                };

                /**
                 * Creates a plain object from a FinishForgotPasswordResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @static
                 * @param {visor.clientrpc.FinishForgotPasswordResponse} message FinishForgotPasswordResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FinishForgotPasswordResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this FinishForgotPasswordResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FinishForgotPasswordResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FinishForgotPasswordResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FinishForgotPasswordResponse;
            })();

            clientrpc.ReadMessagesRequest = (function() {

                /**
                 * Properties of a ReadMessagesRequest.
                 * @memberof visor.clientrpc
                 * @interface IReadMessagesRequest
                 * @property {Array.<string>|null} [messageTokens] ReadMessagesRequest messageTokens
                 */

                /**
                 * Constructs a new ReadMessagesRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReadMessagesRequest.
                 * @implements IReadMessagesRequest
                 * @constructor
                 * @param {visor.clientrpc.IReadMessagesRequest=} [properties] Properties to set
                 */
                function ReadMessagesRequest(properties) {
                    this.messageTokens = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReadMessagesRequest messageTokens.
                 * @member {Array.<string>} messageTokens
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @instance
                 */
                ReadMessagesRequest.prototype.messageTokens = $util.emptyArray;

                /**
                 * Creates a new ReadMessagesRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {visor.clientrpc.IReadMessagesRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReadMessagesRequest} ReadMessagesRequest instance
                 */
                ReadMessagesRequest.create = function create(properties) {
                    return new ReadMessagesRequest(properties);
                };

                /**
                 * Encodes the specified ReadMessagesRequest message. Does not implicitly {@link visor.clientrpc.ReadMessagesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {visor.clientrpc.IReadMessagesRequest} message ReadMessagesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReadMessagesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.messageTokens != null && message.messageTokens.length)
                        for (var i = 0; i < message.messageTokens.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.messageTokens[i]);
                    return writer;
                };

                /**
                 * Encodes the specified ReadMessagesRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ReadMessagesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {visor.clientrpc.IReadMessagesRequest} message ReadMessagesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReadMessagesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReadMessagesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReadMessagesRequest} ReadMessagesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReadMessagesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReadMessagesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.messageTokens && message.messageTokens.length))
                                message.messageTokens = [];
                            message.messageTokens.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReadMessagesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReadMessagesRequest} ReadMessagesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReadMessagesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReadMessagesRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReadMessagesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.messageTokens != null && message.hasOwnProperty("messageTokens")) {
                        if (!Array.isArray(message.messageTokens))
                            return "messageTokens: array expected";
                        for (var i = 0; i < message.messageTokens.length; ++i)
                            if (!$util.isString(message.messageTokens[i]))
                                return "messageTokens: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a ReadMessagesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReadMessagesRequest} ReadMessagesRequest
                 */
                ReadMessagesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReadMessagesRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ReadMessagesRequest();
                    if (object.messageTokens) {
                        if (!Array.isArray(object.messageTokens))
                            throw TypeError(".visor.clientrpc.ReadMessagesRequest.messageTokens: array expected");
                        message.messageTokens = [];
                        for (var i = 0; i < object.messageTokens.length; ++i)
                            message.messageTokens[i] = String(object.messageTokens[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ReadMessagesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @static
                 * @param {visor.clientrpc.ReadMessagesRequest} message ReadMessagesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReadMessagesRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.messageTokens = [];
                    if (message.messageTokens && message.messageTokens.length) {
                        object.messageTokens = [];
                        for (var j = 0; j < message.messageTokens.length; ++j)
                            object.messageTokens[j] = message.messageTokens[j];
                    }
                    return object;
                };

                /**
                 * Converts this ReadMessagesRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReadMessagesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReadMessagesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReadMessagesRequest;
            })();

            clientrpc.ReadMessagesResponse = (function() {

                /**
                 * Properties of a ReadMessagesResponse.
                 * @memberof visor.clientrpc
                 * @interface IReadMessagesResponse
                 */

                /**
                 * Constructs a new ReadMessagesResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReadMessagesResponse.
                 * @implements IReadMessagesResponse
                 * @constructor
                 * @param {visor.clientrpc.IReadMessagesResponse=} [properties] Properties to set
                 */
                function ReadMessagesResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ReadMessagesResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {visor.clientrpc.IReadMessagesResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReadMessagesResponse} ReadMessagesResponse instance
                 */
                ReadMessagesResponse.create = function create(properties) {
                    return new ReadMessagesResponse(properties);
                };

                /**
                 * Encodes the specified ReadMessagesResponse message. Does not implicitly {@link visor.clientrpc.ReadMessagesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {visor.clientrpc.IReadMessagesResponse} message ReadMessagesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReadMessagesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ReadMessagesResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ReadMessagesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {visor.clientrpc.IReadMessagesResponse} message ReadMessagesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReadMessagesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReadMessagesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReadMessagesResponse} ReadMessagesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReadMessagesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReadMessagesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReadMessagesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReadMessagesResponse} ReadMessagesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReadMessagesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReadMessagesResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReadMessagesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ReadMessagesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReadMessagesResponse} ReadMessagesResponse
                 */
                ReadMessagesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReadMessagesResponse)
                        return object;
                    return new $root.visor.clientrpc.ReadMessagesResponse();
                };

                /**
                 * Creates a plain object from a ReadMessagesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @static
                 * @param {visor.clientrpc.ReadMessagesResponse} message ReadMessagesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReadMessagesResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReadMessagesResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReadMessagesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReadMessagesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReadMessagesResponse;
            })();

            clientrpc.PauseSubscriptionRequest = (function() {

                /**
                 * Properties of a PauseSubscriptionRequest.
                 * @memberof visor.clientrpc
                 * @interface IPauseSubscriptionRequest
                 * @property {string|null} [subscriptionToken] PauseSubscriptionRequest subscriptionToken
                 * @property {visor.clientrpc.PauseInterval|null} [interval] PauseSubscriptionRequest interval
                 */

                /**
                 * Constructs a new PauseSubscriptionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PauseSubscriptionRequest.
                 * @implements IPauseSubscriptionRequest
                 * @constructor
                 * @param {visor.clientrpc.IPauseSubscriptionRequest=} [properties] Properties to set
                 */
                function PauseSubscriptionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PauseSubscriptionRequest subscriptionToken.
                 * @member {string} subscriptionToken
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @instance
                 */
                PauseSubscriptionRequest.prototype.subscriptionToken = "";

                /**
                 * PauseSubscriptionRequest interval.
                 * @member {visor.clientrpc.PauseInterval} interval
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @instance
                 */
                PauseSubscriptionRequest.prototype.interval = 0;

                /**
                 * Creates a new PauseSubscriptionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.IPauseSubscriptionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.PauseSubscriptionRequest} PauseSubscriptionRequest instance
                 */
                PauseSubscriptionRequest.create = function create(properties) {
                    return new PauseSubscriptionRequest(properties);
                };

                /**
                 * Encodes the specified PauseSubscriptionRequest message. Does not implicitly {@link visor.clientrpc.PauseSubscriptionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.IPauseSubscriptionRequest} message PauseSubscriptionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PauseSubscriptionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.subscriptionToken);
                    if (message.interval != null && message.hasOwnProperty("interval"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.interval);
                    return writer;
                };

                /**
                 * Encodes the specified PauseSubscriptionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.PauseSubscriptionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.IPauseSubscriptionRequest} message PauseSubscriptionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PauseSubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PauseSubscriptionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PauseSubscriptionRequest} PauseSubscriptionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PauseSubscriptionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PauseSubscriptionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.subscriptionToken = reader.string();
                            break;
                        case 2:
                            message.interval = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PauseSubscriptionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PauseSubscriptionRequest} PauseSubscriptionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PauseSubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PauseSubscriptionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PauseSubscriptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        if (!$util.isString(message.subscriptionToken))
                            return "subscriptionToken: string expected";
                    if (message.interval != null && message.hasOwnProperty("interval"))
                        switch (message.interval) {
                        default:
                            return "interval: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a PauseSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PauseSubscriptionRequest} PauseSubscriptionRequest
                 */
                PauseSubscriptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PauseSubscriptionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.PauseSubscriptionRequest();
                    if (object.subscriptionToken != null)
                        message.subscriptionToken = String(object.subscriptionToken);
                    switch (object.interval) {
                    case "PAUSE_INTERVAL_INVALID":
                    case 0:
                        message.interval = 0;
                        break;
                    case "PAUSE_INTERVAL_ONE_MONTH":
                    case 1:
                        message.interval = 1;
                        break;
                    case "PAUSE_INTERVAL_THREE_MONTHS":
                    case 2:
                        message.interval = 2;
                        break;
                    case "PAUSE_INTERVAL_INDEFINITE":
                    case 3:
                        message.interval = 3;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PauseSubscriptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.PauseSubscriptionRequest} message PauseSubscriptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PauseSubscriptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.subscriptionToken = "";
                        object.interval = options.enums === String ? "PAUSE_INTERVAL_INVALID" : 0;
                    }
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        object.subscriptionToken = message.subscriptionToken;
                    if (message.interval != null && message.hasOwnProperty("interval"))
                        object.interval = options.enums === String ? $root.visor.clientrpc.PauseInterval[message.interval] : message.interval;
                    return object;
                };

                /**
                 * Converts this PauseSubscriptionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PauseSubscriptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PauseSubscriptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PauseSubscriptionRequest;
            })();

            clientrpc.PauseSubscriptionResponse = (function() {

                /**
                 * Properties of a PauseSubscriptionResponse.
                 * @memberof visor.clientrpc
                 * @interface IPauseSubscriptionResponse
                 */

                /**
                 * Constructs a new PauseSubscriptionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PauseSubscriptionResponse.
                 * @implements IPauseSubscriptionResponse
                 * @constructor
                 * @param {visor.clientrpc.IPauseSubscriptionResponse=} [properties] Properties to set
                 */
                function PauseSubscriptionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new PauseSubscriptionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.IPauseSubscriptionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.PauseSubscriptionResponse} PauseSubscriptionResponse instance
                 */
                PauseSubscriptionResponse.create = function create(properties) {
                    return new PauseSubscriptionResponse(properties);
                };

                /**
                 * Encodes the specified PauseSubscriptionResponse message. Does not implicitly {@link visor.clientrpc.PauseSubscriptionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.IPauseSubscriptionResponse} message PauseSubscriptionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PauseSubscriptionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified PauseSubscriptionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.PauseSubscriptionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.IPauseSubscriptionResponse} message PauseSubscriptionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PauseSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PauseSubscriptionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PauseSubscriptionResponse} PauseSubscriptionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PauseSubscriptionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PauseSubscriptionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PauseSubscriptionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PauseSubscriptionResponse} PauseSubscriptionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PauseSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PauseSubscriptionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PauseSubscriptionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a PauseSubscriptionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PauseSubscriptionResponse} PauseSubscriptionResponse
                 */
                PauseSubscriptionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PauseSubscriptionResponse)
                        return object;
                    return new $root.visor.clientrpc.PauseSubscriptionResponse();
                };

                /**
                 * Creates a plain object from a PauseSubscriptionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.PauseSubscriptionResponse} message PauseSubscriptionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PauseSubscriptionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this PauseSubscriptionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PauseSubscriptionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PauseSubscriptionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PauseSubscriptionResponse;
            })();

            clientrpc.ResumeSubscriptionRequest = (function() {

                /**
                 * Properties of a ResumeSubscriptionRequest.
                 * @memberof visor.clientrpc
                 * @interface IResumeSubscriptionRequest
                 * @property {string|null} [subscriptionToken] ResumeSubscriptionRequest subscriptionToken
                 */

                /**
                 * Constructs a new ResumeSubscriptionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResumeSubscriptionRequest.
                 * @implements IResumeSubscriptionRequest
                 * @constructor
                 * @param {visor.clientrpc.IResumeSubscriptionRequest=} [properties] Properties to set
                 */
                function ResumeSubscriptionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResumeSubscriptionRequest subscriptionToken.
                 * @member {string} subscriptionToken
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @instance
                 */
                ResumeSubscriptionRequest.prototype.subscriptionToken = "";

                /**
                 * Creates a new ResumeSubscriptionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.IResumeSubscriptionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResumeSubscriptionRequest} ResumeSubscriptionRequest instance
                 */
                ResumeSubscriptionRequest.create = function create(properties) {
                    return new ResumeSubscriptionRequest(properties);
                };

                /**
                 * Encodes the specified ResumeSubscriptionRequest message. Does not implicitly {@link visor.clientrpc.ResumeSubscriptionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.IResumeSubscriptionRequest} message ResumeSubscriptionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResumeSubscriptionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.subscriptionToken);
                    return writer;
                };

                /**
                 * Encodes the specified ResumeSubscriptionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ResumeSubscriptionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.IResumeSubscriptionRequest} message ResumeSubscriptionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResumeSubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResumeSubscriptionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResumeSubscriptionRequest} ResumeSubscriptionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResumeSubscriptionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResumeSubscriptionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.subscriptionToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResumeSubscriptionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResumeSubscriptionRequest} ResumeSubscriptionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResumeSubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResumeSubscriptionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResumeSubscriptionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        if (!$util.isString(message.subscriptionToken))
                            return "subscriptionToken: string expected";
                    return null;
                };

                /**
                 * Creates a ResumeSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResumeSubscriptionRequest} ResumeSubscriptionRequest
                 */
                ResumeSubscriptionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResumeSubscriptionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ResumeSubscriptionRequest();
                    if (object.subscriptionToken != null)
                        message.subscriptionToken = String(object.subscriptionToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ResumeSubscriptionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @static
                 * @param {visor.clientrpc.ResumeSubscriptionRequest} message ResumeSubscriptionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResumeSubscriptionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.subscriptionToken = "";
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        object.subscriptionToken = message.subscriptionToken;
                    return object;
                };

                /**
                 * Converts this ResumeSubscriptionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResumeSubscriptionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResumeSubscriptionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResumeSubscriptionRequest;
            })();

            clientrpc.ResumeSubscriptionResponse = (function() {

                /**
                 * Properties of a ResumeSubscriptionResponse.
                 * @memberof visor.clientrpc
                 * @interface IResumeSubscriptionResponse
                 */

                /**
                 * Constructs a new ResumeSubscriptionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResumeSubscriptionResponse.
                 * @implements IResumeSubscriptionResponse
                 * @constructor
                 * @param {visor.clientrpc.IResumeSubscriptionResponse=} [properties] Properties to set
                 */
                function ResumeSubscriptionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ResumeSubscriptionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.IResumeSubscriptionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResumeSubscriptionResponse} ResumeSubscriptionResponse instance
                 */
                ResumeSubscriptionResponse.create = function create(properties) {
                    return new ResumeSubscriptionResponse(properties);
                };

                /**
                 * Encodes the specified ResumeSubscriptionResponse message. Does not implicitly {@link visor.clientrpc.ResumeSubscriptionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.IResumeSubscriptionResponse} message ResumeSubscriptionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResumeSubscriptionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ResumeSubscriptionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ResumeSubscriptionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.IResumeSubscriptionResponse} message ResumeSubscriptionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResumeSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResumeSubscriptionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResumeSubscriptionResponse} ResumeSubscriptionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResumeSubscriptionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResumeSubscriptionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResumeSubscriptionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResumeSubscriptionResponse} ResumeSubscriptionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResumeSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResumeSubscriptionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResumeSubscriptionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ResumeSubscriptionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResumeSubscriptionResponse} ResumeSubscriptionResponse
                 */
                ResumeSubscriptionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResumeSubscriptionResponse)
                        return object;
                    return new $root.visor.clientrpc.ResumeSubscriptionResponse();
                };

                /**
                 * Creates a plain object from a ResumeSubscriptionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @static
                 * @param {visor.clientrpc.ResumeSubscriptionResponse} message ResumeSubscriptionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResumeSubscriptionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ResumeSubscriptionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResumeSubscriptionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResumeSubscriptionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResumeSubscriptionResponse;
            })();

            clientrpc.CloseAccountRequest = (function() {

                /**
                 * Properties of a CloseAccountRequest.
                 * @memberof visor.clientrpc
                 * @interface ICloseAccountRequest
                 * @property {string|null} [subscriptionToken] CloseAccountRequest subscriptionToken
                 * @property {boolean|null} [areYouSureBecauseThisIsIrreversible] CloseAccountRequest areYouSureBecauseThisIsIrreversible
                 */

                /**
                 * Constructs a new CloseAccountRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CloseAccountRequest.
                 * @implements ICloseAccountRequest
                 * @constructor
                 * @param {visor.clientrpc.ICloseAccountRequest=} [properties] Properties to set
                 */
                function CloseAccountRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CloseAccountRequest subscriptionToken.
                 * @member {string} subscriptionToken
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @instance
                 */
                CloseAccountRequest.prototype.subscriptionToken = "";

                /**
                 * CloseAccountRequest areYouSureBecauseThisIsIrreversible.
                 * @member {boolean} areYouSureBecauseThisIsIrreversible
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @instance
                 */
                CloseAccountRequest.prototype.areYouSureBecauseThisIsIrreversible = false;

                /**
                 * Creates a new CloseAccountRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {visor.clientrpc.ICloseAccountRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CloseAccountRequest} CloseAccountRequest instance
                 */
                CloseAccountRequest.create = function create(properties) {
                    return new CloseAccountRequest(properties);
                };

                /**
                 * Encodes the specified CloseAccountRequest message. Does not implicitly {@link visor.clientrpc.CloseAccountRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {visor.clientrpc.ICloseAccountRequest} message CloseAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CloseAccountRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.subscriptionToken);
                    if (message.areYouSureBecauseThisIsIrreversible != null && message.hasOwnProperty("areYouSureBecauseThisIsIrreversible"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.areYouSureBecauseThisIsIrreversible);
                    return writer;
                };

                /**
                 * Encodes the specified CloseAccountRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CloseAccountRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {visor.clientrpc.ICloseAccountRequest} message CloseAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CloseAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CloseAccountRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CloseAccountRequest} CloseAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CloseAccountRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CloseAccountRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.subscriptionToken = reader.string();
                            break;
                        case 2:
                            message.areYouSureBecauseThisIsIrreversible = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CloseAccountRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CloseAccountRequest} CloseAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CloseAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CloseAccountRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CloseAccountRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        if (!$util.isString(message.subscriptionToken))
                            return "subscriptionToken: string expected";
                    if (message.areYouSureBecauseThisIsIrreversible != null && message.hasOwnProperty("areYouSureBecauseThisIsIrreversible"))
                        if (typeof message.areYouSureBecauseThisIsIrreversible !== "boolean")
                            return "areYouSureBecauseThisIsIrreversible: boolean expected";
                    return null;
                };

                /**
                 * Creates a CloseAccountRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CloseAccountRequest} CloseAccountRequest
                 */
                CloseAccountRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CloseAccountRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CloseAccountRequest();
                    if (object.subscriptionToken != null)
                        message.subscriptionToken = String(object.subscriptionToken);
                    if (object.areYouSureBecauseThisIsIrreversible != null)
                        message.areYouSureBecauseThisIsIrreversible = Boolean(object.areYouSureBecauseThisIsIrreversible);
                    return message;
                };

                /**
                 * Creates a plain object from a CloseAccountRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @static
                 * @param {visor.clientrpc.CloseAccountRequest} message CloseAccountRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CloseAccountRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.subscriptionToken = "";
                        object.areYouSureBecauseThisIsIrreversible = false;
                    }
                    if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                        object.subscriptionToken = message.subscriptionToken;
                    if (message.areYouSureBecauseThisIsIrreversible != null && message.hasOwnProperty("areYouSureBecauseThisIsIrreversible"))
                        object.areYouSureBecauseThisIsIrreversible = message.areYouSureBecauseThisIsIrreversible;
                    return object;
                };

                /**
                 * Converts this CloseAccountRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CloseAccountRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CloseAccountRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CloseAccountRequest;
            })();

            clientrpc.CloseAccountResponse = (function() {

                /**
                 * Properties of a CloseAccountResponse.
                 * @memberof visor.clientrpc
                 * @interface ICloseAccountResponse
                 */

                /**
                 * Constructs a new CloseAccountResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CloseAccountResponse.
                 * @implements ICloseAccountResponse
                 * @constructor
                 * @param {visor.clientrpc.ICloseAccountResponse=} [properties] Properties to set
                 */
                function CloseAccountResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CloseAccountResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {visor.clientrpc.ICloseAccountResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CloseAccountResponse} CloseAccountResponse instance
                 */
                CloseAccountResponse.create = function create(properties) {
                    return new CloseAccountResponse(properties);
                };

                /**
                 * Encodes the specified CloseAccountResponse message. Does not implicitly {@link visor.clientrpc.CloseAccountResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {visor.clientrpc.ICloseAccountResponse} message CloseAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CloseAccountResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CloseAccountResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CloseAccountResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {visor.clientrpc.ICloseAccountResponse} message CloseAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CloseAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CloseAccountResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CloseAccountResponse} CloseAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CloseAccountResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CloseAccountResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CloseAccountResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CloseAccountResponse} CloseAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CloseAccountResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CloseAccountResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CloseAccountResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CloseAccountResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CloseAccountResponse} CloseAccountResponse
                 */
                CloseAccountResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CloseAccountResponse)
                        return object;
                    return new $root.visor.clientrpc.CloseAccountResponse();
                };

                /**
                 * Creates a plain object from a CloseAccountResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @static
                 * @param {visor.clientrpc.CloseAccountResponse} message CloseAccountResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CloseAccountResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CloseAccountResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CloseAccountResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CloseAccountResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CloseAccountResponse;
            })();

            /**
             * PauseInterval enum.
             * @name visor.clientrpc.PauseInterval
             * @enum {string}
             * @property {number} PAUSE_INTERVAL_INVALID=0 PAUSE_INTERVAL_INVALID value
             * @property {number} PAUSE_INTERVAL_ONE_MONTH=1 PAUSE_INTERVAL_ONE_MONTH value
             * @property {number} PAUSE_INTERVAL_THREE_MONTHS=2 PAUSE_INTERVAL_THREE_MONTHS value
             * @property {number} PAUSE_INTERVAL_INDEFINITE=3 PAUSE_INTERVAL_INDEFINITE value
             */
            clientrpc.PauseInterval = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PAUSE_INTERVAL_INVALID"] = 0;
                values[valuesById[1] = "PAUSE_INTERVAL_ONE_MONTH"] = 1;
                values[valuesById[2] = "PAUSE_INTERVAL_THREE_MONTHS"] = 2;
                values[valuesById[3] = "PAUSE_INTERVAL_INDEFINITE"] = 3;
                return values;
            })();

            clientrpc.SavePaymentMethodRequest = (function() {

                /**
                 * Properties of a SavePaymentMethodRequest.
                 * @memberof visor.clientrpc
                 * @interface ISavePaymentMethodRequest
                 * @property {string|null} [paymentMethodId] SavePaymentMethodRequest paymentMethodId
                 */

                /**
                 * Constructs a new SavePaymentMethodRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SavePaymentMethodRequest.
                 * @implements ISavePaymentMethodRequest
                 * @constructor
                 * @param {visor.clientrpc.ISavePaymentMethodRequest=} [properties] Properties to set
                 */
                function SavePaymentMethodRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SavePaymentMethodRequest paymentMethodId.
                 * @member {string} paymentMethodId
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @instance
                 */
                SavePaymentMethodRequest.prototype.paymentMethodId = "";

                /**
                 * Creates a new SavePaymentMethodRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.ISavePaymentMethodRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SavePaymentMethodRequest} SavePaymentMethodRequest instance
                 */
                SavePaymentMethodRequest.create = function create(properties) {
                    return new SavePaymentMethodRequest(properties);
                };

                /**
                 * Encodes the specified SavePaymentMethodRequest message. Does not implicitly {@link visor.clientrpc.SavePaymentMethodRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.ISavePaymentMethodRequest} message SavePaymentMethodRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePaymentMethodRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentMethodId);
                    return writer;
                };

                /**
                 * Encodes the specified SavePaymentMethodRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SavePaymentMethodRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.ISavePaymentMethodRequest} message SavePaymentMethodRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePaymentMethodRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SavePaymentMethodRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SavePaymentMethodRequest} SavePaymentMethodRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePaymentMethodRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SavePaymentMethodRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.paymentMethodId = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SavePaymentMethodRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SavePaymentMethodRequest} SavePaymentMethodRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePaymentMethodRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SavePaymentMethodRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SavePaymentMethodRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                        if (!$util.isString(message.paymentMethodId))
                            return "paymentMethodId: string expected";
                    return null;
                };

                /**
                 * Creates a SavePaymentMethodRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SavePaymentMethodRequest} SavePaymentMethodRequest
                 */
                SavePaymentMethodRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SavePaymentMethodRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SavePaymentMethodRequest();
                    if (object.paymentMethodId != null)
                        message.paymentMethodId = String(object.paymentMethodId);
                    return message;
                };

                /**
                 * Creates a plain object from a SavePaymentMethodRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @static
                 * @param {visor.clientrpc.SavePaymentMethodRequest} message SavePaymentMethodRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SavePaymentMethodRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.paymentMethodId = "";
                    if (message.paymentMethodId != null && message.hasOwnProperty("paymentMethodId"))
                        object.paymentMethodId = message.paymentMethodId;
                    return object;
                };

                /**
                 * Converts this SavePaymentMethodRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SavePaymentMethodRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SavePaymentMethodRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SavePaymentMethodRequest;
            })();

            clientrpc.SavePaymentMethodResponse = (function() {

                /**
                 * Properties of a SavePaymentMethodResponse.
                 * @memberof visor.clientrpc
                 * @interface ISavePaymentMethodResponse
                 * @property {Array.<visor.clientrpc.stripe.IPaymentMethod>|null} [stripePaymentMethods] SavePaymentMethodResponse stripePaymentMethods
                 */

                /**
                 * Constructs a new SavePaymentMethodResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SavePaymentMethodResponse.
                 * @implements ISavePaymentMethodResponse
                 * @constructor
                 * @param {visor.clientrpc.ISavePaymentMethodResponse=} [properties] Properties to set
                 */
                function SavePaymentMethodResponse(properties) {
                    this.stripePaymentMethods = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SavePaymentMethodResponse stripePaymentMethods.
                 * @member {Array.<visor.clientrpc.stripe.IPaymentMethod>} stripePaymentMethods
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @instance
                 */
                SavePaymentMethodResponse.prototype.stripePaymentMethods = $util.emptyArray;

                /**
                 * Creates a new SavePaymentMethodResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.ISavePaymentMethodResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SavePaymentMethodResponse} SavePaymentMethodResponse instance
                 */
                SavePaymentMethodResponse.create = function create(properties) {
                    return new SavePaymentMethodResponse(properties);
                };

                /**
                 * Encodes the specified SavePaymentMethodResponse message. Does not implicitly {@link visor.clientrpc.SavePaymentMethodResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.ISavePaymentMethodResponse} message SavePaymentMethodResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePaymentMethodResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stripePaymentMethods != null && message.stripePaymentMethods.length)
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i)
                            $root.visor.clientrpc.stripe.PaymentMethod.encode(message.stripePaymentMethods[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SavePaymentMethodResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SavePaymentMethodResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.ISavePaymentMethodResponse} message SavePaymentMethodResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SavePaymentMethodResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SavePaymentMethodResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SavePaymentMethodResponse} SavePaymentMethodResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePaymentMethodResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SavePaymentMethodResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.stripePaymentMethods && message.stripePaymentMethods.length))
                                message.stripePaymentMethods = [];
                            message.stripePaymentMethods.push($root.visor.clientrpc.stripe.PaymentMethod.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SavePaymentMethodResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SavePaymentMethodResponse} SavePaymentMethodResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SavePaymentMethodResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SavePaymentMethodResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SavePaymentMethodResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stripePaymentMethods != null && message.hasOwnProperty("stripePaymentMethods")) {
                        if (!Array.isArray(message.stripePaymentMethods))
                            return "stripePaymentMethods: array expected";
                        for (var i = 0; i < message.stripePaymentMethods.length; ++i) {
                            var error = $root.visor.clientrpc.stripe.PaymentMethod.verify(message.stripePaymentMethods[i]);
                            if (error)
                                return "stripePaymentMethods." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a SavePaymentMethodResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SavePaymentMethodResponse} SavePaymentMethodResponse
                 */
                SavePaymentMethodResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SavePaymentMethodResponse)
                        return object;
                    var message = new $root.visor.clientrpc.SavePaymentMethodResponse();
                    if (object.stripePaymentMethods) {
                        if (!Array.isArray(object.stripePaymentMethods))
                            throw TypeError(".visor.clientrpc.SavePaymentMethodResponse.stripePaymentMethods: array expected");
                        message.stripePaymentMethods = [];
                        for (var i = 0; i < object.stripePaymentMethods.length; ++i) {
                            if (typeof object.stripePaymentMethods[i] !== "object")
                                throw TypeError(".visor.clientrpc.SavePaymentMethodResponse.stripePaymentMethods: object expected");
                            message.stripePaymentMethods[i] = $root.visor.clientrpc.stripe.PaymentMethod.fromObject(object.stripePaymentMethods[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SavePaymentMethodResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @static
                 * @param {visor.clientrpc.SavePaymentMethodResponse} message SavePaymentMethodResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SavePaymentMethodResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.stripePaymentMethods = [];
                    if (message.stripePaymentMethods && message.stripePaymentMethods.length) {
                        object.stripePaymentMethods = [];
                        for (var j = 0; j < message.stripePaymentMethods.length; ++j)
                            object.stripePaymentMethods[j] = $root.visor.clientrpc.stripe.PaymentMethod.toObject(message.stripePaymentMethods[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this SavePaymentMethodResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SavePaymentMethodResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SavePaymentMethodResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SavePaymentMethodResponse;
            })();

            clientrpc.GetPaymentHistoryRequest = (function() {

                /**
                 * Properties of a GetPaymentHistoryRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetPaymentHistoryRequest
                 */

                /**
                 * Constructs a new GetPaymentHistoryRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetPaymentHistoryRequest.
                 * @implements IGetPaymentHistoryRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetPaymentHistoryRequest=} [properties] Properties to set
                 */
                function GetPaymentHistoryRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetPaymentHistoryRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {visor.clientrpc.IGetPaymentHistoryRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetPaymentHistoryRequest} GetPaymentHistoryRequest instance
                 */
                GetPaymentHistoryRequest.create = function create(properties) {
                    return new GetPaymentHistoryRequest(properties);
                };

                /**
                 * Encodes the specified GetPaymentHistoryRequest message. Does not implicitly {@link visor.clientrpc.GetPaymentHistoryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {visor.clientrpc.IGetPaymentHistoryRequest} message GetPaymentHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPaymentHistoryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetPaymentHistoryRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetPaymentHistoryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {visor.clientrpc.IGetPaymentHistoryRequest} message GetPaymentHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPaymentHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetPaymentHistoryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetPaymentHistoryRequest} GetPaymentHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPaymentHistoryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetPaymentHistoryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetPaymentHistoryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetPaymentHistoryRequest} GetPaymentHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPaymentHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetPaymentHistoryRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPaymentHistoryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetPaymentHistoryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetPaymentHistoryRequest} GetPaymentHistoryRequest
                 */
                GetPaymentHistoryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetPaymentHistoryRequest)
                        return object;
                    return new $root.visor.clientrpc.GetPaymentHistoryRequest();
                };

                /**
                 * Creates a plain object from a GetPaymentHistoryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @static
                 * @param {visor.clientrpc.GetPaymentHistoryRequest} message GetPaymentHistoryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPaymentHistoryRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetPaymentHistoryRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetPaymentHistoryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPaymentHistoryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetPaymentHistoryRequest;
            })();

            clientrpc.GetPaymentHistoryResponse = (function() {

                /**
                 * Properties of a GetPaymentHistoryResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetPaymentHistoryResponse
                 * @property {Array.<visor.clientrpc.stripe.IInvoice>|null} [stripeInvoices] GetPaymentHistoryResponse stripeInvoices
                 */

                /**
                 * Constructs a new GetPaymentHistoryResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetPaymentHistoryResponse.
                 * @implements IGetPaymentHistoryResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetPaymentHistoryResponse=} [properties] Properties to set
                 */
                function GetPaymentHistoryResponse(properties) {
                    this.stripeInvoices = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetPaymentHistoryResponse stripeInvoices.
                 * @member {Array.<visor.clientrpc.stripe.IInvoice>} stripeInvoices
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @instance
                 */
                GetPaymentHistoryResponse.prototype.stripeInvoices = $util.emptyArray;

                /**
                 * Creates a new GetPaymentHistoryResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {visor.clientrpc.IGetPaymentHistoryResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetPaymentHistoryResponse} GetPaymentHistoryResponse instance
                 */
                GetPaymentHistoryResponse.create = function create(properties) {
                    return new GetPaymentHistoryResponse(properties);
                };

                /**
                 * Encodes the specified GetPaymentHistoryResponse message. Does not implicitly {@link visor.clientrpc.GetPaymentHistoryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {visor.clientrpc.IGetPaymentHistoryResponse} message GetPaymentHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPaymentHistoryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stripeInvoices != null && message.stripeInvoices.length)
                        for (var i = 0; i < message.stripeInvoices.length; ++i)
                            $root.visor.clientrpc.stripe.Invoice.encode(message.stripeInvoices[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetPaymentHistoryResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetPaymentHistoryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {visor.clientrpc.IGetPaymentHistoryResponse} message GetPaymentHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPaymentHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetPaymentHistoryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetPaymentHistoryResponse} GetPaymentHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPaymentHistoryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetPaymentHistoryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.stripeInvoices && message.stripeInvoices.length))
                                message.stripeInvoices = [];
                            message.stripeInvoices.push($root.visor.clientrpc.stripe.Invoice.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetPaymentHistoryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetPaymentHistoryResponse} GetPaymentHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPaymentHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetPaymentHistoryResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPaymentHistoryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stripeInvoices != null && message.hasOwnProperty("stripeInvoices")) {
                        if (!Array.isArray(message.stripeInvoices))
                            return "stripeInvoices: array expected";
                        for (var i = 0; i < message.stripeInvoices.length; ++i) {
                            var error = $root.visor.clientrpc.stripe.Invoice.verify(message.stripeInvoices[i]);
                            if (error)
                                return "stripeInvoices." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetPaymentHistoryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetPaymentHistoryResponse} GetPaymentHistoryResponse
                 */
                GetPaymentHistoryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetPaymentHistoryResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetPaymentHistoryResponse();
                    if (object.stripeInvoices) {
                        if (!Array.isArray(object.stripeInvoices))
                            throw TypeError(".visor.clientrpc.GetPaymentHistoryResponse.stripeInvoices: array expected");
                        message.stripeInvoices = [];
                        for (var i = 0; i < object.stripeInvoices.length; ++i) {
                            if (typeof object.stripeInvoices[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetPaymentHistoryResponse.stripeInvoices: object expected");
                            message.stripeInvoices[i] = $root.visor.clientrpc.stripe.Invoice.fromObject(object.stripeInvoices[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetPaymentHistoryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @static
                 * @param {visor.clientrpc.GetPaymentHistoryResponse} message GetPaymentHistoryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPaymentHistoryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.stripeInvoices = [];
                    if (message.stripeInvoices && message.stripeInvoices.length) {
                        object.stripeInvoices = [];
                        for (var j = 0; j < message.stripeInvoices.length; ++j)
                            object.stripeInvoices[j] = $root.visor.clientrpc.stripe.Invoice.toObject(message.stripeInvoices[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetPaymentHistoryResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetPaymentHistoryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPaymentHistoryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetPaymentHistoryResponse;
            })();

            clientrpc.CreateLeadRequest = (function() {

                /**
                 * Properties of a CreateLeadRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateLeadRequest
                 * @property {string|null} [email] CreateLeadRequest email
                 * @property {string|null} [primaryPayrollInstitutionToken] CreateLeadRequest primaryPayrollInstitutionToken
                 * @property {visor.clientrpc.CreateLeadRequest.Covid19Impact|null} [covidImpact] CreateLeadRequest covidImpact
                 * @property {visor.clientrpc.CreateLeadRequest.MobilePlatform|null} [mobilePlatform] CreateLeadRequest mobilePlatform
                 * @property {string|null} [employer] CreateLeadRequest employer
                 */

                /**
                 * Constructs a new CreateLeadRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateLeadRequest.
                 * @implements ICreateLeadRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateLeadRequest=} [properties] Properties to set
                 */
                function CreateLeadRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateLeadRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @instance
                 */
                CreateLeadRequest.prototype.email = "";

                /**
                 * CreateLeadRequest primaryPayrollInstitutionToken.
                 * @member {string} primaryPayrollInstitutionToken
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @instance
                 */
                CreateLeadRequest.prototype.primaryPayrollInstitutionToken = "";

                /**
                 * CreateLeadRequest covidImpact.
                 * @member {visor.clientrpc.CreateLeadRequest.Covid19Impact} covidImpact
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @instance
                 */
                CreateLeadRequest.prototype.covidImpact = 0;

                /**
                 * CreateLeadRequest mobilePlatform.
                 * @member {visor.clientrpc.CreateLeadRequest.MobilePlatform} mobilePlatform
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @instance
                 */
                CreateLeadRequest.prototype.mobilePlatform = 0;

                /**
                 * CreateLeadRequest employer.
                 * @member {string} employer
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @instance
                 */
                CreateLeadRequest.prototype.employer = "";

                /**
                 * Creates a new CreateLeadRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateLeadRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateLeadRequest} CreateLeadRequest instance
                 */
                CreateLeadRequest.create = function create(properties) {
                    return new CreateLeadRequest(properties);
                };

                /**
                 * Encodes the specified CreateLeadRequest message. Does not implicitly {@link visor.clientrpc.CreateLeadRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateLeadRequest} message CreateLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLeadRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.primaryPayrollInstitutionToken != null && message.hasOwnProperty("primaryPayrollInstitutionToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.primaryPayrollInstitutionToken);
                    if (message.covidImpact != null && message.hasOwnProperty("covidImpact"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.covidImpact);
                    if (message.mobilePlatform != null && message.hasOwnProperty("mobilePlatform"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.mobilePlatform);
                    if (message.employer != null && message.hasOwnProperty("employer"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.employer);
                    return writer;
                };

                /**
                 * Encodes the specified CreateLeadRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateLeadRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateLeadRequest} message CreateLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLeadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateLeadRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateLeadRequest} CreateLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLeadRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateLeadRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.email = reader.string();
                            break;
                        case 2:
                            message.primaryPayrollInstitutionToken = reader.string();
                            break;
                        case 3:
                            message.covidImpact = reader.int32();
                            break;
                        case 4:
                            message.mobilePlatform = reader.int32();
                            break;
                        case 5:
                            message.employer = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateLeadRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateLeadRequest} CreateLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLeadRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateLeadRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateLeadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.primaryPayrollInstitutionToken != null && message.hasOwnProperty("primaryPayrollInstitutionToken"))
                        if (!$util.isString(message.primaryPayrollInstitutionToken))
                            return "primaryPayrollInstitutionToken: string expected";
                    if (message.covidImpact != null && message.hasOwnProperty("covidImpact"))
                        switch (message.covidImpact) {
                        default:
                            return "covidImpact: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.mobilePlatform != null && message.hasOwnProperty("mobilePlatform"))
                        switch (message.mobilePlatform) {
                        default:
                            return "mobilePlatform: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.employer != null && message.hasOwnProperty("employer"))
                        if (!$util.isString(message.employer))
                            return "employer: string expected";
                    return null;
                };

                /**
                 * Creates a CreateLeadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateLeadRequest} CreateLeadRequest
                 */
                CreateLeadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateLeadRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateLeadRequest();
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.primaryPayrollInstitutionToken != null)
                        message.primaryPayrollInstitutionToken = String(object.primaryPayrollInstitutionToken);
                    switch (object.covidImpact) {
                    case "CREATE_LEAD_REQUEST_COVID19_IMPACT_INVALID":
                    case 0:
                        message.covidImpact = 0;
                        break;
                    case "CREATE_LEAD_REQUEST_COVID19_IMPACT_FULLY_OR_PARTIALLY_UNEMPLOYED":
                    case 1:
                        message.covidImpact = 1;
                        break;
                    case "CREATE_LEAD_REQUEST_COVID19_IMPACT_NONE":
                    case 2:
                        message.covidImpact = 2;
                        break;
                    }
                    switch (object.mobilePlatform) {
                    case "CREATE_LEAD_REQUEST_MOBILE_PLATFORM_INVALID":
                    case 0:
                        message.mobilePlatform = 0;
                        break;
                    case "CREATE_LEAD_REQUEST_MOBILE_PLATFORM_IOS":
                    case 1:
                        message.mobilePlatform = 1;
                        break;
                    case "CREATE_LEAD_REQUEST_MOBILE_PLATFORM_ANDROID":
                    case 2:
                        message.mobilePlatform = 2;
                        break;
                    }
                    if (object.employer != null)
                        message.employer = String(object.employer);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateLeadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @static
                 * @param {visor.clientrpc.CreateLeadRequest} message CreateLeadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateLeadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.primaryPayrollInstitutionToken = "";
                        object.covidImpact = options.enums === String ? "CREATE_LEAD_REQUEST_COVID19_IMPACT_INVALID" : 0;
                        object.mobilePlatform = options.enums === String ? "CREATE_LEAD_REQUEST_MOBILE_PLATFORM_INVALID" : 0;
                        object.employer = "";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.primaryPayrollInstitutionToken != null && message.hasOwnProperty("primaryPayrollInstitutionToken"))
                        object.primaryPayrollInstitutionToken = message.primaryPayrollInstitutionToken;
                    if (message.covidImpact != null && message.hasOwnProperty("covidImpact"))
                        object.covidImpact = options.enums === String ? $root.visor.clientrpc.CreateLeadRequest.Covid19Impact[message.covidImpact] : message.covidImpact;
                    if (message.mobilePlatform != null && message.hasOwnProperty("mobilePlatform"))
                        object.mobilePlatform = options.enums === String ? $root.visor.clientrpc.CreateLeadRequest.MobilePlatform[message.mobilePlatform] : message.mobilePlatform;
                    if (message.employer != null && message.hasOwnProperty("employer"))
                        object.employer = message.employer;
                    return object;
                };

                /**
                 * Converts this CreateLeadRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateLeadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateLeadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Covid19Impact enum.
                 * @name visor.clientrpc.CreateLeadRequest.Covid19Impact
                 * @enum {string}
                 * @property {number} CREATE_LEAD_REQUEST_COVID19_IMPACT_INVALID=0 CREATE_LEAD_REQUEST_COVID19_IMPACT_INVALID value
                 * @property {number} CREATE_LEAD_REQUEST_COVID19_IMPACT_FULLY_OR_PARTIALLY_UNEMPLOYED=1 CREATE_LEAD_REQUEST_COVID19_IMPACT_FULLY_OR_PARTIALLY_UNEMPLOYED value
                 * @property {number} CREATE_LEAD_REQUEST_COVID19_IMPACT_NONE=2 CREATE_LEAD_REQUEST_COVID19_IMPACT_NONE value
                 */
                CreateLeadRequest.Covid19Impact = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CREATE_LEAD_REQUEST_COVID19_IMPACT_INVALID"] = 0;
                    values[valuesById[1] = "CREATE_LEAD_REQUEST_COVID19_IMPACT_FULLY_OR_PARTIALLY_UNEMPLOYED"] = 1;
                    values[valuesById[2] = "CREATE_LEAD_REQUEST_COVID19_IMPACT_NONE"] = 2;
                    return values;
                })();

                /**
                 * MobilePlatform enum.
                 * @name visor.clientrpc.CreateLeadRequest.MobilePlatform
                 * @enum {string}
                 * @property {number} CREATE_LEAD_REQUEST_MOBILE_PLATFORM_INVALID=0 CREATE_LEAD_REQUEST_MOBILE_PLATFORM_INVALID value
                 * @property {number} CREATE_LEAD_REQUEST_MOBILE_PLATFORM_IOS=1 CREATE_LEAD_REQUEST_MOBILE_PLATFORM_IOS value
                 * @property {number} CREATE_LEAD_REQUEST_MOBILE_PLATFORM_ANDROID=2 CREATE_LEAD_REQUEST_MOBILE_PLATFORM_ANDROID value
                 */
                CreateLeadRequest.MobilePlatform = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CREATE_LEAD_REQUEST_MOBILE_PLATFORM_INVALID"] = 0;
                    values[valuesById[1] = "CREATE_LEAD_REQUEST_MOBILE_PLATFORM_IOS"] = 1;
                    values[valuesById[2] = "CREATE_LEAD_REQUEST_MOBILE_PLATFORM_ANDROID"] = 2;
                    return values;
                })();

                return CreateLeadRequest;
            })();

            clientrpc.CreateLeadResponse = (function() {

                /**
                 * Properties of a CreateLeadResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateLeadResponse
                 * @property {visor.clientrpc.CreateLeadResponse.IAlpha|null} [alpha] CreateLeadResponse alpha
                 * @property {visor.clientrpc.CreateLeadResponse.IBeta|null} [beta] CreateLeadResponse beta
                 * @property {visor.clientrpc.CreateLeadResponse.IWaitlist|null} [waitlist] CreateLeadResponse waitlist
                 */

                /**
                 * Constructs a new CreateLeadResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateLeadResponse.
                 * @implements ICreateLeadResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateLeadResponse=} [properties] Properties to set
                 */
                function CreateLeadResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateLeadResponse alpha.
                 * @member {visor.clientrpc.CreateLeadResponse.IAlpha|null|undefined} alpha
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @instance
                 */
                CreateLeadResponse.prototype.alpha = null;

                /**
                 * CreateLeadResponse beta.
                 * @member {visor.clientrpc.CreateLeadResponse.IBeta|null|undefined} beta
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @instance
                 */
                CreateLeadResponse.prototype.beta = null;

                /**
                 * CreateLeadResponse waitlist.
                 * @member {visor.clientrpc.CreateLeadResponse.IWaitlist|null|undefined} waitlist
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @instance
                 */
                CreateLeadResponse.prototype.waitlist = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * CreateLeadResponse eligiblity.
                 * @member {"alpha"|"beta"|"waitlist"|undefined} eligiblity
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @instance
                 */
                Object.defineProperty(CreateLeadResponse.prototype, "eligiblity", {
                    get: $util.oneOfGetter($oneOfFields = ["alpha", "beta", "waitlist"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new CreateLeadResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateLeadResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateLeadResponse} CreateLeadResponse instance
                 */
                CreateLeadResponse.create = function create(properties) {
                    return new CreateLeadResponse(properties);
                };

                /**
                 * Encodes the specified CreateLeadResponse message. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateLeadResponse} message CreateLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLeadResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.alpha != null && message.hasOwnProperty("alpha"))
                        $root.visor.clientrpc.CreateLeadResponse.Alpha.encode(message.alpha, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.beta != null && message.hasOwnProperty("beta"))
                        $root.visor.clientrpc.CreateLeadResponse.Beta.encode(message.beta, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.waitlist != null && message.hasOwnProperty("waitlist"))
                        $root.visor.clientrpc.CreateLeadResponse.Waitlist.encode(message.waitlist, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreateLeadResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateLeadResponse} message CreateLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateLeadResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateLeadResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateLeadResponse} CreateLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLeadResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateLeadResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.alpha = $root.visor.clientrpc.CreateLeadResponse.Alpha.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.beta = $root.visor.clientrpc.CreateLeadResponse.Beta.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.waitlist = $root.visor.clientrpc.CreateLeadResponse.Waitlist.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateLeadResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateLeadResponse} CreateLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateLeadResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateLeadResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateLeadResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.alpha != null && message.hasOwnProperty("alpha")) {
                        properties.eligiblity = 1;
                        {
                            var error = $root.visor.clientrpc.CreateLeadResponse.Alpha.verify(message.alpha);
                            if (error)
                                return "alpha." + error;
                        }
                    }
                    if (message.beta != null && message.hasOwnProperty("beta")) {
                        if (properties.eligiblity === 1)
                            return "eligiblity: multiple values";
                        properties.eligiblity = 1;
                        {
                            var error = $root.visor.clientrpc.CreateLeadResponse.Beta.verify(message.beta);
                            if (error)
                                return "beta." + error;
                        }
                    }
                    if (message.waitlist != null && message.hasOwnProperty("waitlist")) {
                        if (properties.eligiblity === 1)
                            return "eligiblity: multiple values";
                        properties.eligiblity = 1;
                        {
                            var error = $root.visor.clientrpc.CreateLeadResponse.Waitlist.verify(message.waitlist);
                            if (error)
                                return "waitlist." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateLeadResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateLeadResponse} CreateLeadResponse
                 */
                CreateLeadResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateLeadResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreateLeadResponse();
                    if (object.alpha != null) {
                        if (typeof object.alpha !== "object")
                            throw TypeError(".visor.clientrpc.CreateLeadResponse.alpha: object expected");
                        message.alpha = $root.visor.clientrpc.CreateLeadResponse.Alpha.fromObject(object.alpha);
                    }
                    if (object.beta != null) {
                        if (typeof object.beta !== "object")
                            throw TypeError(".visor.clientrpc.CreateLeadResponse.beta: object expected");
                        message.beta = $root.visor.clientrpc.CreateLeadResponse.Beta.fromObject(object.beta);
                    }
                    if (object.waitlist != null) {
                        if (typeof object.waitlist !== "object")
                            throw TypeError(".visor.clientrpc.CreateLeadResponse.waitlist: object expected");
                        message.waitlist = $root.visor.clientrpc.CreateLeadResponse.Waitlist.fromObject(object.waitlist);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateLeadResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @static
                 * @param {visor.clientrpc.CreateLeadResponse} message CreateLeadResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateLeadResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.alpha != null && message.hasOwnProperty("alpha")) {
                        object.alpha = $root.visor.clientrpc.CreateLeadResponse.Alpha.toObject(message.alpha, options);
                        if (options.oneofs)
                            object.eligiblity = "alpha";
                    }
                    if (message.beta != null && message.hasOwnProperty("beta")) {
                        object.beta = $root.visor.clientrpc.CreateLeadResponse.Beta.toObject(message.beta, options);
                        if (options.oneofs)
                            object.eligiblity = "beta";
                    }
                    if (message.waitlist != null && message.hasOwnProperty("waitlist")) {
                        object.waitlist = $root.visor.clientrpc.CreateLeadResponse.Waitlist.toObject(message.waitlist, options);
                        if (options.oneofs)
                            object.eligiblity = "waitlist";
                    }
                    return object;
                };

                /**
                 * Converts this CreateLeadResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateLeadResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateLeadResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreateLeadResponse.Alpha = (function() {

                    /**
                     * Properties of an Alpha.
                     * @memberof visor.clientrpc.CreateLeadResponse
                     * @interface IAlpha
                     * @property {string|null} [redirectUrl] Alpha redirectUrl
                     */

                    /**
                     * Constructs a new Alpha.
                     * @memberof visor.clientrpc.CreateLeadResponse
                     * @classdesc Represents an Alpha.
                     * @implements IAlpha
                     * @constructor
                     * @param {visor.clientrpc.CreateLeadResponse.IAlpha=} [properties] Properties to set
                     */
                    function Alpha(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Alpha redirectUrl.
                     * @member {string} redirectUrl
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @instance
                     */
                    Alpha.prototype.redirectUrl = "";

                    /**
                     * Creates a new Alpha instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IAlpha=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateLeadResponse.Alpha} Alpha instance
                     */
                    Alpha.create = function create(properties) {
                        return new Alpha(properties);
                    };

                    /**
                     * Encodes the specified Alpha message. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.Alpha.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IAlpha} message Alpha message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Alpha.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.redirectUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified Alpha message, length delimited. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.Alpha.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IAlpha} message Alpha message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Alpha.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Alpha message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateLeadResponse.Alpha} Alpha
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Alpha.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateLeadResponse.Alpha();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.redirectUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Alpha message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateLeadResponse.Alpha} Alpha
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Alpha.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Alpha message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Alpha.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                            if (!$util.isString(message.redirectUrl))
                                return "redirectUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates an Alpha message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateLeadResponse.Alpha} Alpha
                     */
                    Alpha.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateLeadResponse.Alpha)
                            return object;
                        var message = new $root.visor.clientrpc.CreateLeadResponse.Alpha();
                        if (object.redirectUrl != null)
                            message.redirectUrl = String(object.redirectUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Alpha message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.Alpha} message Alpha
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Alpha.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.redirectUrl = "";
                        if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                            object.redirectUrl = message.redirectUrl;
                        return object;
                    };

                    /**
                     * Converts this Alpha to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateLeadResponse.Alpha
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Alpha.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Alpha;
                })();

                CreateLeadResponse.Beta = (function() {

                    /**
                     * Properties of a Beta.
                     * @memberof visor.clientrpc.CreateLeadResponse
                     * @interface IBeta
                     */

                    /**
                     * Constructs a new Beta.
                     * @memberof visor.clientrpc.CreateLeadResponse
                     * @classdesc Represents a Beta.
                     * @implements IBeta
                     * @constructor
                     * @param {visor.clientrpc.CreateLeadResponse.IBeta=} [properties] Properties to set
                     */
                    function Beta(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new Beta instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IBeta=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateLeadResponse.Beta} Beta instance
                     */
                    Beta.create = function create(properties) {
                        return new Beta(properties);
                    };

                    /**
                     * Encodes the specified Beta message. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.Beta.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IBeta} message Beta message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Beta.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified Beta message, length delimited. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.Beta.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IBeta} message Beta message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Beta.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Beta message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateLeadResponse.Beta} Beta
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Beta.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateLeadResponse.Beta();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Beta message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateLeadResponse.Beta} Beta
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Beta.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Beta message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Beta.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a Beta message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateLeadResponse.Beta} Beta
                     */
                    Beta.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateLeadResponse.Beta)
                            return object;
                        return new $root.visor.clientrpc.CreateLeadResponse.Beta();
                    };

                    /**
                     * Creates a plain object from a Beta message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.Beta} message Beta
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Beta.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Beta to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateLeadResponse.Beta
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Beta.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Beta;
                })();

                CreateLeadResponse.Waitlist = (function() {

                    /**
                     * Properties of a Waitlist.
                     * @memberof visor.clientrpc.CreateLeadResponse
                     * @interface IWaitlist
                     * @property {visor.common.strings.ILocalizedString|null} [waitlistReasonTitle] Waitlist waitlistReasonTitle
                     * @property {visor.common.strings.ILocalizedString|null} [waitlistReasonSubtitle] Waitlist waitlistReasonSubtitle
                     */

                    /**
                     * Constructs a new Waitlist.
                     * @memberof visor.clientrpc.CreateLeadResponse
                     * @classdesc Represents a Waitlist.
                     * @implements IWaitlist
                     * @constructor
                     * @param {visor.clientrpc.CreateLeadResponse.IWaitlist=} [properties] Properties to set
                     */
                    function Waitlist(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Waitlist waitlistReasonTitle.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} waitlistReasonTitle
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @instance
                     */
                    Waitlist.prototype.waitlistReasonTitle = null;

                    /**
                     * Waitlist waitlistReasonSubtitle.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} waitlistReasonSubtitle
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @instance
                     */
                    Waitlist.prototype.waitlistReasonSubtitle = null;

                    /**
                     * Creates a new Waitlist instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IWaitlist=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateLeadResponse.Waitlist} Waitlist instance
                     */
                    Waitlist.create = function create(properties) {
                        return new Waitlist(properties);
                    };

                    /**
                     * Encodes the specified Waitlist message. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.Waitlist.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IWaitlist} message Waitlist message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Waitlist.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.waitlistReasonTitle != null && message.hasOwnProperty("waitlistReasonTitle"))
                            $root.visor.common.strings.LocalizedString.encode(message.waitlistReasonTitle, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.waitlistReasonSubtitle != null && message.hasOwnProperty("waitlistReasonSubtitle"))
                            $root.visor.common.strings.LocalizedString.encode(message.waitlistReasonSubtitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Waitlist message, length delimited. Does not implicitly {@link visor.clientrpc.CreateLeadResponse.Waitlist.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.IWaitlist} message Waitlist message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Waitlist.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Waitlist message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateLeadResponse.Waitlist} Waitlist
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Waitlist.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateLeadResponse.Waitlist();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.waitlistReasonTitle = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.waitlistReasonSubtitle = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Waitlist message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateLeadResponse.Waitlist} Waitlist
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Waitlist.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Waitlist message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Waitlist.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.waitlistReasonTitle != null && message.hasOwnProperty("waitlistReasonTitle")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.waitlistReasonTitle);
                            if (error)
                                return "waitlistReasonTitle." + error;
                        }
                        if (message.waitlistReasonSubtitle != null && message.hasOwnProperty("waitlistReasonSubtitle")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.waitlistReasonSubtitle);
                            if (error)
                                return "waitlistReasonSubtitle." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Waitlist message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateLeadResponse.Waitlist} Waitlist
                     */
                    Waitlist.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateLeadResponse.Waitlist)
                            return object;
                        var message = new $root.visor.clientrpc.CreateLeadResponse.Waitlist();
                        if (object.waitlistReasonTitle != null) {
                            if (typeof object.waitlistReasonTitle !== "object")
                                throw TypeError(".visor.clientrpc.CreateLeadResponse.Waitlist.waitlistReasonTitle: object expected");
                            message.waitlistReasonTitle = $root.visor.common.strings.LocalizedString.fromObject(object.waitlistReasonTitle);
                        }
                        if (object.waitlistReasonSubtitle != null) {
                            if (typeof object.waitlistReasonSubtitle !== "object")
                                throw TypeError(".visor.clientrpc.CreateLeadResponse.Waitlist.waitlistReasonSubtitle: object expected");
                            message.waitlistReasonSubtitle = $root.visor.common.strings.LocalizedString.fromObject(object.waitlistReasonSubtitle);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Waitlist message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @static
                     * @param {visor.clientrpc.CreateLeadResponse.Waitlist} message Waitlist
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Waitlist.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.waitlistReasonTitle = null;
                            object.waitlistReasonSubtitle = null;
                        }
                        if (message.waitlistReasonTitle != null && message.hasOwnProperty("waitlistReasonTitle"))
                            object.waitlistReasonTitle = $root.visor.common.strings.LocalizedString.toObject(message.waitlistReasonTitle, options);
                        if (message.waitlistReasonSubtitle != null && message.hasOwnProperty("waitlistReasonSubtitle"))
                            object.waitlistReasonSubtitle = $root.visor.common.strings.LocalizedString.toObject(message.waitlistReasonSubtitle, options);
                        return object;
                    };

                    /**
                     * Converts this Waitlist to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateLeadResponse.Waitlist
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Waitlist.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Waitlist;
                })();

                return CreateLeadResponse;
            })();

            clientrpc.GetReferralCodeInformationRequest = (function() {

                /**
                 * Properties of a GetReferralCodeInformationRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetReferralCodeInformationRequest
                 * @property {string|null} [voucherCode] GetReferralCodeInformationRequest voucherCode
                 */

                /**
                 * Constructs a new GetReferralCodeInformationRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetReferralCodeInformationRequest.
                 * @implements IGetReferralCodeInformationRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetReferralCodeInformationRequest=} [properties] Properties to set
                 */
                function GetReferralCodeInformationRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetReferralCodeInformationRequest voucherCode.
                 * @member {string} voucherCode
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @instance
                 */
                GetReferralCodeInformationRequest.prototype.voucherCode = "";

                /**
                 * Creates a new GetReferralCodeInformationRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralCodeInformationRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetReferralCodeInformationRequest} GetReferralCodeInformationRequest instance
                 */
                GetReferralCodeInformationRequest.create = function create(properties) {
                    return new GetReferralCodeInformationRequest(properties);
                };

                /**
                 * Encodes the specified GetReferralCodeInformationRequest message. Does not implicitly {@link visor.clientrpc.GetReferralCodeInformationRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralCodeInformationRequest} message GetReferralCodeInformationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralCodeInformationRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.voucherCode != null && message.hasOwnProperty("voucherCode"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.voucherCode);
                    return writer;
                };

                /**
                 * Encodes the specified GetReferralCodeInformationRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetReferralCodeInformationRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {visor.clientrpc.IGetReferralCodeInformationRequest} message GetReferralCodeInformationRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralCodeInformationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetReferralCodeInformationRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetReferralCodeInformationRequest} GetReferralCodeInformationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralCodeInformationRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetReferralCodeInformationRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.voucherCode = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetReferralCodeInformationRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetReferralCodeInformationRequest} GetReferralCodeInformationRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralCodeInformationRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetReferralCodeInformationRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReferralCodeInformationRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.voucherCode != null && message.hasOwnProperty("voucherCode"))
                        if (!$util.isString(message.voucherCode))
                            return "voucherCode: string expected";
                    return null;
                };

                /**
                 * Creates a GetReferralCodeInformationRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetReferralCodeInformationRequest} GetReferralCodeInformationRequest
                 */
                GetReferralCodeInformationRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetReferralCodeInformationRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetReferralCodeInformationRequest();
                    if (object.voucherCode != null)
                        message.voucherCode = String(object.voucherCode);
                    return message;
                };

                /**
                 * Creates a plain object from a GetReferralCodeInformationRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @static
                 * @param {visor.clientrpc.GetReferralCodeInformationRequest} message GetReferralCodeInformationRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReferralCodeInformationRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.voucherCode = "";
                    if (message.voucherCode != null && message.hasOwnProperty("voucherCode"))
                        object.voucherCode = message.voucherCode;
                    return object;
                };

                /**
                 * Converts this GetReferralCodeInformationRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetReferralCodeInformationRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReferralCodeInformationRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetReferralCodeInformationRequest;
            })();

            clientrpc.GetReferralCodeInformationResponse = (function() {

                /**
                 * Properties of a GetReferralCodeInformationResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetReferralCodeInformationResponse
                 * @property {string|null} [referrerFirstName] GetReferralCodeInformationResponse referrerFirstName
                 * @property {visor.common.strings.ILocalizedString|null} [referralTitle] GetReferralCodeInformationResponse referralTitle
                 * @property {visor.common.strings.ILocalizedString|null} [referralDetails] GetReferralCodeInformationResponse referralDetails
                 */

                /**
                 * Constructs a new GetReferralCodeInformationResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetReferralCodeInformationResponse.
                 * @implements IGetReferralCodeInformationResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetReferralCodeInformationResponse=} [properties] Properties to set
                 */
                function GetReferralCodeInformationResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetReferralCodeInformationResponse referrerFirstName.
                 * @member {string} referrerFirstName
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @instance
                 */
                GetReferralCodeInformationResponse.prototype.referrerFirstName = "";

                /**
                 * GetReferralCodeInformationResponse referralTitle.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} referralTitle
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @instance
                 */
                GetReferralCodeInformationResponse.prototype.referralTitle = null;

                /**
                 * GetReferralCodeInformationResponse referralDetails.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} referralDetails
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @instance
                 */
                GetReferralCodeInformationResponse.prototype.referralDetails = null;

                /**
                 * Creates a new GetReferralCodeInformationResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralCodeInformationResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetReferralCodeInformationResponse} GetReferralCodeInformationResponse instance
                 */
                GetReferralCodeInformationResponse.create = function create(properties) {
                    return new GetReferralCodeInformationResponse(properties);
                };

                /**
                 * Encodes the specified GetReferralCodeInformationResponse message. Does not implicitly {@link visor.clientrpc.GetReferralCodeInformationResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralCodeInformationResponse} message GetReferralCodeInformationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralCodeInformationResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.referrerFirstName != null && message.hasOwnProperty("referrerFirstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.referrerFirstName);
                    if (message.referralTitle != null && message.hasOwnProperty("referralTitle"))
                        $root.visor.common.strings.LocalizedString.encode(message.referralTitle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.referralDetails != null && message.hasOwnProperty("referralDetails"))
                        $root.visor.common.strings.LocalizedString.encode(message.referralDetails, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetReferralCodeInformationResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetReferralCodeInformationResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {visor.clientrpc.IGetReferralCodeInformationResponse} message GetReferralCodeInformationResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetReferralCodeInformationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetReferralCodeInformationResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetReferralCodeInformationResponse} GetReferralCodeInformationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralCodeInformationResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetReferralCodeInformationResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.referrerFirstName = reader.string();
                            break;
                        case 2:
                            message.referralTitle = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.referralDetails = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetReferralCodeInformationResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetReferralCodeInformationResponse} GetReferralCodeInformationResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetReferralCodeInformationResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetReferralCodeInformationResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetReferralCodeInformationResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.referrerFirstName != null && message.hasOwnProperty("referrerFirstName"))
                        if (!$util.isString(message.referrerFirstName))
                            return "referrerFirstName: string expected";
                    if (message.referralTitle != null && message.hasOwnProperty("referralTitle")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.referralTitle);
                        if (error)
                            return "referralTitle." + error;
                    }
                    if (message.referralDetails != null && message.hasOwnProperty("referralDetails")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.referralDetails);
                        if (error)
                            return "referralDetails." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetReferralCodeInformationResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetReferralCodeInformationResponse} GetReferralCodeInformationResponse
                 */
                GetReferralCodeInformationResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetReferralCodeInformationResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetReferralCodeInformationResponse();
                    if (object.referrerFirstName != null)
                        message.referrerFirstName = String(object.referrerFirstName);
                    if (object.referralTitle != null) {
                        if (typeof object.referralTitle !== "object")
                            throw TypeError(".visor.clientrpc.GetReferralCodeInformationResponse.referralTitle: object expected");
                        message.referralTitle = $root.visor.common.strings.LocalizedString.fromObject(object.referralTitle);
                    }
                    if (object.referralDetails != null) {
                        if (typeof object.referralDetails !== "object")
                            throw TypeError(".visor.clientrpc.GetReferralCodeInformationResponse.referralDetails: object expected");
                        message.referralDetails = $root.visor.common.strings.LocalizedString.fromObject(object.referralDetails);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetReferralCodeInformationResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @static
                 * @param {visor.clientrpc.GetReferralCodeInformationResponse} message GetReferralCodeInformationResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetReferralCodeInformationResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.referrerFirstName = "";
                        object.referralTitle = null;
                        object.referralDetails = null;
                    }
                    if (message.referrerFirstName != null && message.hasOwnProperty("referrerFirstName"))
                        object.referrerFirstName = message.referrerFirstName;
                    if (message.referralTitle != null && message.hasOwnProperty("referralTitle"))
                        object.referralTitle = $root.visor.common.strings.LocalizedString.toObject(message.referralTitle, options);
                    if (message.referralDetails != null && message.hasOwnProperty("referralDetails"))
                        object.referralDetails = $root.visor.common.strings.LocalizedString.toObject(message.referralDetails, options);
                    return object;
                };

                /**
                 * Converts this GetReferralCodeInformationResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetReferralCodeInformationResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetReferralCodeInformationResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetReferralCodeInformationResponse;
            })();

            clientrpc.CreateReferralLeadRequest = (function() {

                /**
                 * Properties of a CreateReferralLeadRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateReferralLeadRequest
                 * @property {string|null} [refereeEmail] CreateReferralLeadRequest refereeEmail
                 * @property {string|null} [voucherCode] CreateReferralLeadRequest voucherCode
                 */

                /**
                 * Constructs a new CreateReferralLeadRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateReferralLeadRequest.
                 * @implements ICreateReferralLeadRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateReferralLeadRequest=} [properties] Properties to set
                 */
                function CreateReferralLeadRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateReferralLeadRequest refereeEmail.
                 * @member {string} refereeEmail
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @instance
                 */
                CreateReferralLeadRequest.prototype.refereeEmail = "";

                /**
                 * CreateReferralLeadRequest voucherCode.
                 * @member {string} voucherCode
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @instance
                 */
                CreateReferralLeadRequest.prototype.voucherCode = "";

                /**
                 * Creates a new CreateReferralLeadRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateReferralLeadRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateReferralLeadRequest} CreateReferralLeadRequest instance
                 */
                CreateReferralLeadRequest.create = function create(properties) {
                    return new CreateReferralLeadRequest(properties);
                };

                /**
                 * Encodes the specified CreateReferralLeadRequest message. Does not implicitly {@link visor.clientrpc.CreateReferralLeadRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateReferralLeadRequest} message CreateReferralLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateReferralLeadRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.refereeEmail != null && message.hasOwnProperty("refereeEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.refereeEmail);
                    if (message.voucherCode != null && message.hasOwnProperty("voucherCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.voucherCode);
                    return writer;
                };

                /**
                 * Encodes the specified CreateReferralLeadRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateReferralLeadRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateReferralLeadRequest} message CreateReferralLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateReferralLeadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateReferralLeadRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateReferralLeadRequest} CreateReferralLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateReferralLeadRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateReferralLeadRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.refereeEmail = reader.string();
                            break;
                        case 2:
                            message.voucherCode = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateReferralLeadRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateReferralLeadRequest} CreateReferralLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateReferralLeadRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateReferralLeadRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateReferralLeadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.refereeEmail != null && message.hasOwnProperty("refereeEmail"))
                        if (!$util.isString(message.refereeEmail))
                            return "refereeEmail: string expected";
                    if (message.voucherCode != null && message.hasOwnProperty("voucherCode"))
                        if (!$util.isString(message.voucherCode))
                            return "voucherCode: string expected";
                    return null;
                };

                /**
                 * Creates a CreateReferralLeadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateReferralLeadRequest} CreateReferralLeadRequest
                 */
                CreateReferralLeadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateReferralLeadRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateReferralLeadRequest();
                    if (object.refereeEmail != null)
                        message.refereeEmail = String(object.refereeEmail);
                    if (object.voucherCode != null)
                        message.voucherCode = String(object.voucherCode);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateReferralLeadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @static
                 * @param {visor.clientrpc.CreateReferralLeadRequest} message CreateReferralLeadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateReferralLeadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.refereeEmail = "";
                        object.voucherCode = "";
                    }
                    if (message.refereeEmail != null && message.hasOwnProperty("refereeEmail"))
                        object.refereeEmail = message.refereeEmail;
                    if (message.voucherCode != null && message.hasOwnProperty("voucherCode"))
                        object.voucherCode = message.voucherCode;
                    return object;
                };

                /**
                 * Converts this CreateReferralLeadRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateReferralLeadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateReferralLeadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateReferralLeadRequest;
            })();

            clientrpc.CreateReferralLeadResponse = (function() {

                /**
                 * Properties of a CreateReferralLeadResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateReferralLeadResponse
                 */

                /**
                 * Constructs a new CreateReferralLeadResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateReferralLeadResponse.
                 * @implements ICreateReferralLeadResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateReferralLeadResponse=} [properties] Properties to set
                 */
                function CreateReferralLeadResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateReferralLeadResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateReferralLeadResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateReferralLeadResponse} CreateReferralLeadResponse instance
                 */
                CreateReferralLeadResponse.create = function create(properties) {
                    return new CreateReferralLeadResponse(properties);
                };

                /**
                 * Encodes the specified CreateReferralLeadResponse message. Does not implicitly {@link visor.clientrpc.CreateReferralLeadResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateReferralLeadResponse} message CreateReferralLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateReferralLeadResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateReferralLeadResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateReferralLeadResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateReferralLeadResponse} message CreateReferralLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateReferralLeadResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateReferralLeadResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateReferralLeadResponse} CreateReferralLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateReferralLeadResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateReferralLeadResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateReferralLeadResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateReferralLeadResponse} CreateReferralLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateReferralLeadResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateReferralLeadResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateReferralLeadResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateReferralLeadResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateReferralLeadResponse} CreateReferralLeadResponse
                 */
                CreateReferralLeadResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateReferralLeadResponse)
                        return object;
                    return new $root.visor.clientrpc.CreateReferralLeadResponse();
                };

                /**
                 * Creates a plain object from a CreateReferralLeadResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @static
                 * @param {visor.clientrpc.CreateReferralLeadResponse} message CreateReferralLeadResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateReferralLeadResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateReferralLeadResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateReferralLeadResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateReferralLeadResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateReferralLeadResponse;
            })();

            clientrpc.CreateAndroidLeadRequest = (function() {

                /**
                 * Properties of a CreateAndroidLeadRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateAndroidLeadRequest
                 * @property {string|null} [email] CreateAndroidLeadRequest email
                 */

                /**
                 * Constructs a new CreateAndroidLeadRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateAndroidLeadRequest.
                 * @implements ICreateAndroidLeadRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateAndroidLeadRequest=} [properties] Properties to set
                 */
                function CreateAndroidLeadRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateAndroidLeadRequest email.
                 * @member {string} email
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @instance
                 */
                CreateAndroidLeadRequest.prototype.email = "";

                /**
                 * Creates a new CreateAndroidLeadRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateAndroidLeadRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateAndroidLeadRequest} CreateAndroidLeadRequest instance
                 */
                CreateAndroidLeadRequest.create = function create(properties) {
                    return new CreateAndroidLeadRequest(properties);
                };

                /**
                 * Encodes the specified CreateAndroidLeadRequest message. Does not implicitly {@link visor.clientrpc.CreateAndroidLeadRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateAndroidLeadRequest} message CreateAndroidLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAndroidLeadRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && message.hasOwnProperty("email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified CreateAndroidLeadRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateAndroidLeadRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {visor.clientrpc.ICreateAndroidLeadRequest} message CreateAndroidLeadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAndroidLeadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateAndroidLeadRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateAndroidLeadRequest} CreateAndroidLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAndroidLeadRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateAndroidLeadRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.email = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateAndroidLeadRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateAndroidLeadRequest} CreateAndroidLeadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAndroidLeadRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateAndroidLeadRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAndroidLeadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a CreateAndroidLeadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateAndroidLeadRequest} CreateAndroidLeadRequest
                 */
                CreateAndroidLeadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateAndroidLeadRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateAndroidLeadRequest();
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateAndroidLeadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @static
                 * @param {visor.clientrpc.CreateAndroidLeadRequest} message CreateAndroidLeadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAndroidLeadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.email = "";
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this CreateAndroidLeadRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateAndroidLeadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAndroidLeadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateAndroidLeadRequest;
            })();

            clientrpc.CreateAndroidLeadResponse = (function() {

                /**
                 * Properties of a CreateAndroidLeadResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateAndroidLeadResponse
                 */

                /**
                 * Constructs a new CreateAndroidLeadResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateAndroidLeadResponse.
                 * @implements ICreateAndroidLeadResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateAndroidLeadResponse=} [properties] Properties to set
                 */
                function CreateAndroidLeadResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateAndroidLeadResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateAndroidLeadResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateAndroidLeadResponse} CreateAndroidLeadResponse instance
                 */
                CreateAndroidLeadResponse.create = function create(properties) {
                    return new CreateAndroidLeadResponse(properties);
                };

                /**
                 * Encodes the specified CreateAndroidLeadResponse message. Does not implicitly {@link visor.clientrpc.CreateAndroidLeadResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateAndroidLeadResponse} message CreateAndroidLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAndroidLeadResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateAndroidLeadResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateAndroidLeadResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {visor.clientrpc.ICreateAndroidLeadResponse} message CreateAndroidLeadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAndroidLeadResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateAndroidLeadResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateAndroidLeadResponse} CreateAndroidLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAndroidLeadResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateAndroidLeadResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateAndroidLeadResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateAndroidLeadResponse} CreateAndroidLeadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAndroidLeadResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateAndroidLeadResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAndroidLeadResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateAndroidLeadResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateAndroidLeadResponse} CreateAndroidLeadResponse
                 */
                CreateAndroidLeadResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateAndroidLeadResponse)
                        return object;
                    return new $root.visor.clientrpc.CreateAndroidLeadResponse();
                };

                /**
                 * Creates a plain object from a CreateAndroidLeadResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @static
                 * @param {visor.clientrpc.CreateAndroidLeadResponse} message CreateAndroidLeadResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAndroidLeadResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateAndroidLeadResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateAndroidLeadResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAndroidLeadResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateAndroidLeadResponse;
            })();

            clientrpc.CreateAppReviewRequest = (function() {

                /**
                 * Properties of a CreateAppReviewRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateAppReviewRequest
                 * @property {string|null} [workflowToken] CreateAppReviewRequest workflowToken
                 * @property {number|Long|null} [rating] CreateAppReviewRequest rating
                 * @property {string|null} [review] CreateAppReviewRequest review
                 */

                /**
                 * Constructs a new CreateAppReviewRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateAppReviewRequest.
                 * @implements ICreateAppReviewRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateAppReviewRequest=} [properties] Properties to set
                 */
                function CreateAppReviewRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateAppReviewRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @instance
                 */
                CreateAppReviewRequest.prototype.workflowToken = "";

                /**
                 * CreateAppReviewRequest rating.
                 * @member {number|Long} rating
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @instance
                 */
                CreateAppReviewRequest.prototype.rating = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * CreateAppReviewRequest review.
                 * @member {string} review
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @instance
                 */
                CreateAppReviewRequest.prototype.review = "";

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * CreateAppReviewRequest reference.
                 * @member {"workflowToken"|undefined} reference
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @instance
                 */
                Object.defineProperty(CreateAppReviewRequest.prototype, "reference", {
                    get: $util.oneOfGetter($oneOfFields = ["workflowToken"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new CreateAppReviewRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {visor.clientrpc.ICreateAppReviewRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateAppReviewRequest} CreateAppReviewRequest instance
                 */
                CreateAppReviewRequest.create = function create(properties) {
                    return new CreateAppReviewRequest(properties);
                };

                /**
                 * Encodes the specified CreateAppReviewRequest message. Does not implicitly {@link visor.clientrpc.CreateAppReviewRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {visor.clientrpc.ICreateAppReviewRequest} message CreateAppReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAppReviewRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.workflowToken);
                    if (message.rating != null && message.hasOwnProperty("rating"))
                        writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.rating);
                    if (message.review != null && message.hasOwnProperty("review"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.review);
                    return writer;
                };

                /**
                 * Encodes the specified CreateAppReviewRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateAppReviewRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {visor.clientrpc.ICreateAppReviewRequest} message CreateAppReviewRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAppReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateAppReviewRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateAppReviewRequest} CreateAppReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAppReviewRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateAppReviewRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.workflowToken = reader.string();
                            break;
                        case 10:
                            message.rating = reader.uint64();
                            break;
                        case 11:
                            message.review = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateAppReviewRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateAppReviewRequest} CreateAppReviewRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAppReviewRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateAppReviewRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAppReviewRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken")) {
                        properties.reference = 1;
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    }
                    if (message.rating != null && message.hasOwnProperty("rating"))
                        if (!$util.isInteger(message.rating) && !(message.rating && $util.isInteger(message.rating.low) && $util.isInteger(message.rating.high)))
                            return "rating: integer|Long expected";
                    if (message.review != null && message.hasOwnProperty("review"))
                        if (!$util.isString(message.review))
                            return "review: string expected";
                    return null;
                };

                /**
                 * Creates a CreateAppReviewRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateAppReviewRequest} CreateAppReviewRequest
                 */
                CreateAppReviewRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateAppReviewRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateAppReviewRequest();
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.rating != null)
                        if ($util.Long)
                            (message.rating = $util.Long.fromValue(object.rating)).unsigned = true;
                        else if (typeof object.rating === "string")
                            message.rating = parseInt(object.rating, 10);
                        else if (typeof object.rating === "number")
                            message.rating = object.rating;
                        else if (typeof object.rating === "object")
                            message.rating = new $util.LongBits(object.rating.low >>> 0, object.rating.high >>> 0).toNumber(true);
                    if (object.review != null)
                        message.review = String(object.review);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateAppReviewRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @static
                 * @param {visor.clientrpc.CreateAppReviewRequest} message CreateAppReviewRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAppReviewRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.rating = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.rating = options.longs === String ? "0" : 0;
                        object.review = "";
                    }
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken")) {
                        object.workflowToken = message.workflowToken;
                        if (options.oneofs)
                            object.reference = "workflowToken";
                    }
                    if (message.rating != null && message.hasOwnProperty("rating"))
                        if (typeof message.rating === "number")
                            object.rating = options.longs === String ? String(message.rating) : message.rating;
                        else
                            object.rating = options.longs === String ? $util.Long.prototype.toString.call(message.rating) : options.longs === Number ? new $util.LongBits(message.rating.low >>> 0, message.rating.high >>> 0).toNumber(true) : message.rating;
                    if (message.review != null && message.hasOwnProperty("review"))
                        object.review = message.review;
                    return object;
                };

                /**
                 * Converts this CreateAppReviewRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateAppReviewRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAppReviewRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateAppReviewRequest;
            })();

            clientrpc.CreateAppReviewResponse = (function() {

                /**
                 * Properties of a CreateAppReviewResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateAppReviewResponse
                 */

                /**
                 * Constructs a new CreateAppReviewResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateAppReviewResponse.
                 * @implements ICreateAppReviewResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateAppReviewResponse=} [properties] Properties to set
                 */
                function CreateAppReviewResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateAppReviewResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {visor.clientrpc.ICreateAppReviewResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateAppReviewResponse} CreateAppReviewResponse instance
                 */
                CreateAppReviewResponse.create = function create(properties) {
                    return new CreateAppReviewResponse(properties);
                };

                /**
                 * Encodes the specified CreateAppReviewResponse message. Does not implicitly {@link visor.clientrpc.CreateAppReviewResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {visor.clientrpc.ICreateAppReviewResponse} message CreateAppReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAppReviewResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateAppReviewResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateAppReviewResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {visor.clientrpc.ICreateAppReviewResponse} message CreateAppReviewResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateAppReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateAppReviewResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateAppReviewResponse} CreateAppReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAppReviewResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateAppReviewResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateAppReviewResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateAppReviewResponse} CreateAppReviewResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateAppReviewResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateAppReviewResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateAppReviewResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateAppReviewResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateAppReviewResponse} CreateAppReviewResponse
                 */
                CreateAppReviewResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateAppReviewResponse)
                        return object;
                    return new $root.visor.clientrpc.CreateAppReviewResponse();
                };

                /**
                 * Creates a plain object from a CreateAppReviewResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @static
                 * @param {visor.clientrpc.CreateAppReviewResponse} message CreateAppReviewResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateAppReviewResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateAppReviewResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateAppReviewResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateAppReviewResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateAppReviewResponse;
            })();

            clientrpc.GetCashBoostStatusRequest = (function() {

                /**
                 * Properties of a GetCashBoostStatusRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCashBoostStatusRequest
                 */

                /**
                 * Constructs a new GetCashBoostStatusRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCashBoostStatusRequest.
                 * @implements IGetCashBoostStatusRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCashBoostStatusRequest=} [properties] Properties to set
                 */
                function GetCashBoostStatusRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetCashBoostStatusRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostStatusRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCashBoostStatusRequest} GetCashBoostStatusRequest instance
                 */
                GetCashBoostStatusRequest.create = function create(properties) {
                    return new GetCashBoostStatusRequest(properties);
                };

                /**
                 * Encodes the specified GetCashBoostStatusRequest message. Does not implicitly {@link visor.clientrpc.GetCashBoostStatusRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostStatusRequest} message GetCashBoostStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostStatusRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetCashBoostStatusRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCashBoostStatusRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostStatusRequest} message GetCashBoostStatusRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCashBoostStatusRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCashBoostStatusRequest} GetCashBoostStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostStatusRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCashBoostStatusRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCashBoostStatusRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCashBoostStatusRequest} GetCashBoostStatusRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCashBoostStatusRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCashBoostStatusRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetCashBoostStatusRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCashBoostStatusRequest} GetCashBoostStatusRequest
                 */
                GetCashBoostStatusRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCashBoostStatusRequest)
                        return object;
                    return new $root.visor.clientrpc.GetCashBoostStatusRequest();
                };

                /**
                 * Creates a plain object from a GetCashBoostStatusRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @static
                 * @param {visor.clientrpc.GetCashBoostStatusRequest} message GetCashBoostStatusRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCashBoostStatusRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetCashBoostStatusRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCashBoostStatusRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCashBoostStatusRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCashBoostStatusRequest;
            })();

            clientrpc.GetCashBoostStatusResponse = (function() {

                /**
                 * Properties of a GetCashBoostStatusResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCashBoostStatusResponse
                 * @property {string|null} [secretUrl] GetCashBoostStatusResponse secretUrl
                 * @property {visor.clientrpc.GetCashBoostStatusResponse.Eligibility|null} [clientEligibility] GetCashBoostStatusResponse clientEligibility
                 * @property {visor.clientrpc.GetCashBoostStatusResponse.ICurrentAdvance|null} [advance] GetCashBoostStatusResponse advance
                 */

                /**
                 * Constructs a new GetCashBoostStatusResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCashBoostStatusResponse.
                 * @implements IGetCashBoostStatusResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCashBoostStatusResponse=} [properties] Properties to set
                 */
                function GetCashBoostStatusResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCashBoostStatusResponse secretUrl.
                 * @member {string} secretUrl
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @instance
                 */
                GetCashBoostStatusResponse.prototype.secretUrl = "";

                /**
                 * GetCashBoostStatusResponse clientEligibility.
                 * @member {visor.clientrpc.GetCashBoostStatusResponse.Eligibility} clientEligibility
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @instance
                 */
                GetCashBoostStatusResponse.prototype.clientEligibility = 0;

                /**
                 * GetCashBoostStatusResponse advance.
                 * @member {visor.clientrpc.GetCashBoostStatusResponse.ICurrentAdvance|null|undefined} advance
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @instance
                 */
                GetCashBoostStatusResponse.prototype.advance = null;

                /**
                 * Creates a new GetCashBoostStatusResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostStatusResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCashBoostStatusResponse} GetCashBoostStatusResponse instance
                 */
                GetCashBoostStatusResponse.create = function create(properties) {
                    return new GetCashBoostStatusResponse(properties);
                };

                /**
                 * Encodes the specified GetCashBoostStatusResponse message. Does not implicitly {@link visor.clientrpc.GetCashBoostStatusResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostStatusResponse} message GetCashBoostStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostStatusResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.secretUrl != null && message.hasOwnProperty("secretUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.secretUrl);
                    if (message.clientEligibility != null && message.hasOwnProperty("clientEligibility"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.clientEligibility);
                    if (message.advance != null && message.hasOwnProperty("advance"))
                        $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.encode(message.advance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCashBoostStatusResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCashBoostStatusResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostStatusResponse} message GetCashBoostStatusResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCashBoostStatusResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCashBoostStatusResponse} GetCashBoostStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostStatusResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCashBoostStatusResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.secretUrl = reader.string();
                            break;
                        case 2:
                            message.clientEligibility = reader.int32();
                            break;
                        case 3:
                            message.advance = $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCashBoostStatusResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCashBoostStatusResponse} GetCashBoostStatusResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCashBoostStatusResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCashBoostStatusResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.secretUrl != null && message.hasOwnProperty("secretUrl"))
                        if (!$util.isString(message.secretUrl))
                            return "secretUrl: string expected";
                    if (message.clientEligibility != null && message.hasOwnProperty("clientEligibility"))
                        switch (message.clientEligibility) {
                        default:
                            return "clientEligibility: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.advance != null && message.hasOwnProperty("advance")) {
                        var error = $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.verify(message.advance);
                        if (error)
                            return "advance." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetCashBoostStatusResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCashBoostStatusResponse} GetCashBoostStatusResponse
                 */
                GetCashBoostStatusResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCashBoostStatusResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCashBoostStatusResponse();
                    if (object.secretUrl != null)
                        message.secretUrl = String(object.secretUrl);
                    switch (object.clientEligibility) {
                    case "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_INVALID":
                    case 0:
                        message.clientEligibility = 0;
                        break;
                    case "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_ELIGIBLE":
                    case 1:
                        message.clientEligibility = 1;
                        break;
                    case "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_NOT_ELIGIBLE":
                    case 2:
                        message.clientEligibility = 2;
                        break;
                    case "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITLIST":
                    case 3:
                        message.clientEligibility = 3;
                        break;
                    case "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITING":
                    case 4:
                        message.clientEligibility = 4;
                        break;
                    }
                    if (object.advance != null) {
                        if (typeof object.advance !== "object")
                            throw TypeError(".visor.clientrpc.GetCashBoostStatusResponse.advance: object expected");
                        message.advance = $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.fromObject(object.advance);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCashBoostStatusResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @static
                 * @param {visor.clientrpc.GetCashBoostStatusResponse} message GetCashBoostStatusResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCashBoostStatusResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.secretUrl = "";
                        object.clientEligibility = options.enums === String ? "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_INVALID" : 0;
                        object.advance = null;
                    }
                    if (message.secretUrl != null && message.hasOwnProperty("secretUrl"))
                        object.secretUrl = message.secretUrl;
                    if (message.clientEligibility != null && message.hasOwnProperty("clientEligibility"))
                        object.clientEligibility = options.enums === String ? $root.visor.clientrpc.GetCashBoostStatusResponse.Eligibility[message.clientEligibility] : message.clientEligibility;
                    if (message.advance != null && message.hasOwnProperty("advance"))
                        object.advance = $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.toObject(message.advance, options);
                    return object;
                };

                /**
                 * Converts this GetCashBoostStatusResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCashBoostStatusResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCashBoostStatusResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Eligibility enum.
                 * @name visor.clientrpc.GetCashBoostStatusResponse.Eligibility
                 * @enum {string}
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_INVALID=0 GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_INVALID value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_ELIGIBLE=1 GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_ELIGIBLE value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_NOT_ELIGIBLE=2 GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_NOT_ELIGIBLE value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITLIST=3 GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITLIST value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITING=4 GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITING value
                 */
                GetCashBoostStatusResponse.Eligibility = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_INVALID"] = 0;
                    values[valuesById[1] = "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_ELIGIBLE"] = 1;
                    values[valuesById[2] = "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_NOT_ELIGIBLE"] = 2;
                    values[valuesById[3] = "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITLIST"] = 3;
                    values[valuesById[4] = "GET_CASH_BOOST_STATUS_RESPONSE_ELIGIBILITY_WAITING"] = 4;
                    return values;
                })();

                GetCashBoostStatusResponse.CurrentAdvance = (function() {

                    /**
                     * Properties of a CurrentAdvance.
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse
                     * @interface ICurrentAdvance
                     * @property {visor.common.time.ILocalDate|null} [repayment] CurrentAdvance repayment
                     * @property {visor.common.money.IAmount|null} [amount] CurrentAdvance amount
                     * @property {visor.clientrpc.GetCashBoostStatusResponse.AdvanceStage|null} [stage] CurrentAdvance stage
                     * @property {visor.common.time.ILocalDate|null} [funding] CurrentAdvance funding
                     */

                    /**
                     * Constructs a new CurrentAdvance.
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse
                     * @classdesc Represents a CurrentAdvance.
                     * @implements ICurrentAdvance
                     * @constructor
                     * @param {visor.clientrpc.GetCashBoostStatusResponse.ICurrentAdvance=} [properties] Properties to set
                     */
                    function CurrentAdvance(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CurrentAdvance repayment.
                     * @member {visor.common.time.ILocalDate|null|undefined} repayment
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @instance
                     */
                    CurrentAdvance.prototype.repayment = null;

                    /**
                     * CurrentAdvance amount.
                     * @member {visor.common.money.IAmount|null|undefined} amount
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @instance
                     */
                    CurrentAdvance.prototype.amount = null;

                    /**
                     * CurrentAdvance stage.
                     * @member {visor.clientrpc.GetCashBoostStatusResponse.AdvanceStage} stage
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @instance
                     */
                    CurrentAdvance.prototype.stage = 0;

                    /**
                     * CurrentAdvance funding.
                     * @member {visor.common.time.ILocalDate|null|undefined} funding
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @instance
                     */
                    CurrentAdvance.prototype.funding = null;

                    /**
                     * Creates a new CurrentAdvance instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {visor.clientrpc.GetCashBoostStatusResponse.ICurrentAdvance=} [properties] Properties to set
                     * @returns {visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance} CurrentAdvance instance
                     */
                    CurrentAdvance.create = function create(properties) {
                        return new CurrentAdvance(properties);
                    };

                    /**
                     * Encodes the specified CurrentAdvance message. Does not implicitly {@link visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {visor.clientrpc.GetCashBoostStatusResponse.ICurrentAdvance} message CurrentAdvance message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CurrentAdvance.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.repayment != null && message.hasOwnProperty("repayment"))
                            $root.visor.common.time.LocalDate.encode(message.repayment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.stage != null && message.hasOwnProperty("stage"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.stage);
                        if (message.funding != null && message.hasOwnProperty("funding"))
                            $root.visor.common.time.LocalDate.encode(message.funding, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CurrentAdvance message, length delimited. Does not implicitly {@link visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {visor.clientrpc.GetCashBoostStatusResponse.ICurrentAdvance} message CurrentAdvance message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CurrentAdvance.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CurrentAdvance message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance} CurrentAdvance
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CurrentAdvance.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.repayment = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.stage = reader.int32();
                                break;
                            case 4:
                                message.funding = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CurrentAdvance message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance} CurrentAdvance
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CurrentAdvance.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CurrentAdvance message.
                     * @function verify
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CurrentAdvance.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.repayment != null && message.hasOwnProperty("repayment")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.repayment);
                            if (error)
                                return "repayment." + error;
                        }
                        if (message.amount != null && message.hasOwnProperty("amount")) {
                            var error = $root.visor.common.money.Amount.verify(message.amount);
                            if (error)
                                return "amount." + error;
                        }
                        if (message.stage != null && message.hasOwnProperty("stage"))
                            switch (message.stage) {
                            default:
                                return "stage: enum value expected";
                            case 0:
                            case 15:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 14:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 16:
                                break;
                            }
                        if (message.funding != null && message.hasOwnProperty("funding")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.funding);
                            if (error)
                                return "funding." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CurrentAdvance message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance} CurrentAdvance
                     */
                    CurrentAdvance.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance)
                            return object;
                        var message = new $root.visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance();
                        if (object.repayment != null) {
                            if (typeof object.repayment !== "object")
                                throw TypeError(".visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.repayment: object expected");
                            message.repayment = $root.visor.common.time.LocalDate.fromObject(object.repayment);
                        }
                        if (object.amount != null) {
                            if (typeof object.amount !== "object")
                                throw TypeError(".visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.amount: object expected");
                            message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                        }
                        switch (object.stage) {
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INVALID":
                        case 0:
                            message.stage = 0;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING_REQUESTED":
                        case 15:
                            message.stage = 15;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING":
                        case 1:
                            message.stage = 1;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SUBSEQUENT_ADVANCE":
                        case 2:
                            message.stage = 2;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INTRO":
                        case 3:
                            message.stage = 3;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SELECTION":
                        case 4:
                            message.stage = 4;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DATA_ENTRY":
                        case 5:
                            message.stage = 5;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_PLAID":
                        case 6:
                            message.stage = 6;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_CARD":
                        case 14:
                            message.stage = 14;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_STRIPE":
                        case 7:
                            message.stage = 7;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REVIEW":
                        case 8:
                            message.stage = 8;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_COMPLETE":
                        case 9:
                            message.stage = 9;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REPAYMENT":
                        case 10:
                            message.stage = 10;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_EXCEPTION":
                        case 11:
                            message.stage = 11;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FINANCE_COMPLETE":
                        case 12:
                            message.stage = 12;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_OPS_DRAFT":
                        case 13:
                            message.stage = 13;
                            break;
                        case "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DENIED":
                        case 16:
                            message.stage = 16;
                            break;
                        }
                        if (object.funding != null) {
                            if (typeof object.funding !== "object")
                                throw TypeError(".visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance.funding: object expected");
                            message.funding = $root.visor.common.time.LocalDate.fromObject(object.funding);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CurrentAdvance message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @static
                     * @param {visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance} message CurrentAdvance
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CurrentAdvance.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.repayment = null;
                            object.amount = null;
                            object.stage = options.enums === String ? "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INVALID" : 0;
                            object.funding = null;
                        }
                        if (message.repayment != null && message.hasOwnProperty("repayment"))
                            object.repayment = $root.visor.common.time.LocalDate.toObject(message.repayment, options);
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                        if (message.stage != null && message.hasOwnProperty("stage"))
                            object.stage = options.enums === String ? $root.visor.clientrpc.GetCashBoostStatusResponse.AdvanceStage[message.stage] : message.stage;
                        if (message.funding != null && message.hasOwnProperty("funding"))
                            object.funding = $root.visor.common.time.LocalDate.toObject(message.funding, options);
                        return object;
                    };

                    /**
                     * Converts this CurrentAdvance to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GetCashBoostStatusResponse.CurrentAdvance
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CurrentAdvance.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CurrentAdvance;
                })();

                /**
                 * AdvanceStage enum.
                 * @name visor.clientrpc.GetCashBoostStatusResponse.AdvanceStage
                 * @enum {string}
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INVALID=0 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INVALID value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING_REQUESTED=15 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING_REQUESTED value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING=1 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SUBSEQUENT_ADVANCE=2 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SUBSEQUENT_ADVANCE value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INTRO=3 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INTRO value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SELECTION=4 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SELECTION value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DATA_ENTRY=5 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DATA_ENTRY value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_PLAID=6 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_PLAID value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_CARD=14 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_CARD value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_STRIPE=7 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_STRIPE value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REVIEW=8 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REVIEW value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_COMPLETE=9 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_COMPLETE value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REPAYMENT=10 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REPAYMENT value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_EXCEPTION=11 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_EXCEPTION value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FINANCE_COMPLETE=12 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FINANCE_COMPLETE value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_OPS_DRAFT=13 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_OPS_DRAFT value
                 * @property {number} GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DENIED=16 GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DENIED value
                 */
                GetCashBoostStatusResponse.AdvanceStage = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INVALID"] = 0;
                    values[valuesById[15] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING_REQUESTED"] = 15;
                    values[valuesById[1] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FUNDING"] = 1;
                    values[valuesById[2] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SUBSEQUENT_ADVANCE"] = 2;
                    values[valuesById[3] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_INTRO"] = 3;
                    values[valuesById[4] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_SELECTION"] = 4;
                    values[valuesById[5] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DATA_ENTRY"] = 5;
                    values[valuesById[6] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_PLAID"] = 6;
                    values[valuesById[14] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_CARD"] = 14;
                    values[valuesById[7] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_STRIPE"] = 7;
                    values[valuesById[8] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REVIEW"] = 8;
                    values[valuesById[9] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_COMPLETE"] = 9;
                    values[valuesById[10] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_REPAYMENT"] = 10;
                    values[valuesById[11] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_EXCEPTION"] = 11;
                    values[valuesById[12] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_FINANCE_COMPLETE"] = 12;
                    values[valuesById[13] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_OPS_DRAFT"] = 13;
                    values[valuesById[16] = "GET_CASH_BOOST_STATUS_RESPONSE_ADVANCE_STAGE_DENIED"] = 16;
                    return values;
                })();

                return GetCashBoostStatusResponse;
            })();

            clientrpc.CompleteCashBoostIntroStepRequest = (function() {

                /**
                 * Properties of a CompleteCashBoostIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCashBoostIntroStepRequest
                 * @property {string|null} [stepToken] CompleteCashBoostIntroStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteCashBoostIntroStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteCashBoostIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCashBoostIntroStepRequest.
                 * @implements ICompleteCashBoostIntroStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepRequest=} [properties] Properties to set
                 */
                function CompleteCashBoostIntroStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteCashBoostIntroStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @instance
                 */
                CompleteCashBoostIntroStepRequest.prototype.stepToken = "";

                /**
                 * CompleteCashBoostIntroStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @instance
                 */
                CompleteCashBoostIntroStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteCashBoostIntroStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepRequest} CompleteCashBoostIntroStepRequest instance
                 */
                CompleteCashBoostIntroStepRequest.create = function create(properties) {
                    return new CompleteCashBoostIntroStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteCashBoostIntroStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteCashBoostIntroStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepRequest} message CompleteCashBoostIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostIntroStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCashBoostIntroStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCashBoostIntroStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepRequest} message CompleteCashBoostIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostIntroStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCashBoostIntroStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepRequest} CompleteCashBoostIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostIntroStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCashBoostIntroStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCashBoostIntroStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepRequest} CompleteCashBoostIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostIntroStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCashBoostIntroStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCashBoostIntroStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteCashBoostIntroStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepRequest} CompleteCashBoostIntroStepRequest
                 */
                CompleteCashBoostIntroStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCashBoostIntroStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteCashBoostIntroStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteCashBoostIntroStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteCashBoostIntroStepRequest} message CompleteCashBoostIntroStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCashBoostIntroStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteCashBoostIntroStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCashBoostIntroStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCashBoostIntroStepRequest;
            })();

            clientrpc.CompleteCashBoostIntroStepResponse = (function() {

                /**
                 * Properties of a CompleteCashBoostIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCashBoostIntroStepResponse
                 */

                /**
                 * Constructs a new CompleteCashBoostIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCashBoostIntroStepResponse.
                 * @implements ICompleteCashBoostIntroStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepResponse=} [properties] Properties to set
                 */
                function CompleteCashBoostIntroStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteCashBoostIntroStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepResponse} CompleteCashBoostIntroStepResponse instance
                 */
                CompleteCashBoostIntroStepResponse.create = function create(properties) {
                    return new CompleteCashBoostIntroStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteCashBoostIntroStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteCashBoostIntroStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepResponse} message CompleteCashBoostIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostIntroStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCashBoostIntroStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCashBoostIntroStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostIntroStepResponse} message CompleteCashBoostIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostIntroStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCashBoostIntroStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepResponse} CompleteCashBoostIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostIntroStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCashBoostIntroStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCashBoostIntroStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepResponse} CompleteCashBoostIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostIntroStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCashBoostIntroStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCashBoostIntroStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteCashBoostIntroStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCashBoostIntroStepResponse} CompleteCashBoostIntroStepResponse
                 */
                CompleteCashBoostIntroStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCashBoostIntroStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteCashBoostIntroStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteCashBoostIntroStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteCashBoostIntroStepResponse} message CompleteCashBoostIntroStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCashBoostIntroStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteCashBoostIntroStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCashBoostIntroStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCashBoostIntroStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCashBoostIntroStepResponse;
            })();

            clientrpc.GetBankTransactionsResult = (function() {

                /**
                 * Properties of a GetBankTransactionsResult.
                 * @memberof visor.clientrpc
                 * @interface IGetBankTransactionsResult
                 * @property {string|null} [transactionId] GetBankTransactionsResult transactionId
                 * @property {number|Long|null} [transactionAmount] GetBankTransactionsResult transactionAmount
                 * @property {visor.common.time.ILocalDate|null} [postedDate] GetBankTransactionsResult postedDate
                 * @property {string|null} [transactionDescriptor] GetBankTransactionsResult transactionDescriptor
                 */

                /**
                 * Constructs a new GetBankTransactionsResult.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBankTransactionsResult.
                 * @implements IGetBankTransactionsResult
                 * @constructor
                 * @param {visor.clientrpc.IGetBankTransactionsResult=} [properties] Properties to set
                 */
                function GetBankTransactionsResult(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBankTransactionsResult transactionId.
                 * @member {string} transactionId
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @instance
                 */
                GetBankTransactionsResult.prototype.transactionId = "";

                /**
                 * GetBankTransactionsResult transactionAmount.
                 * @member {number|Long} transactionAmount
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @instance
                 */
                GetBankTransactionsResult.prototype.transactionAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * GetBankTransactionsResult postedDate.
                 * @member {visor.common.time.ILocalDate|null|undefined} postedDate
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @instance
                 */
                GetBankTransactionsResult.prototype.postedDate = null;

                /**
                 * GetBankTransactionsResult transactionDescriptor.
                 * @member {string} transactionDescriptor
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @instance
                 */
                GetBankTransactionsResult.prototype.transactionDescriptor = "";

                /**
                 * Creates a new GetBankTransactionsResult instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {visor.clientrpc.IGetBankTransactionsResult=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBankTransactionsResult} GetBankTransactionsResult instance
                 */
                GetBankTransactionsResult.create = function create(properties) {
                    return new GetBankTransactionsResult(properties);
                };

                /**
                 * Encodes the specified GetBankTransactionsResult message. Does not implicitly {@link visor.clientrpc.GetBankTransactionsResult.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {visor.clientrpc.IGetBankTransactionsResult} message GetBankTransactionsResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankTransactionsResult.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.transactionId);
                    if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.transactionAmount);
                    if (message.postedDate != null && message.hasOwnProperty("postedDate"))
                        $root.visor.common.time.LocalDate.encode(message.postedDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.transactionDescriptor != null && message.hasOwnProperty("transactionDescriptor"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.transactionDescriptor);
                    return writer;
                };

                /**
                 * Encodes the specified GetBankTransactionsResult message, length delimited. Does not implicitly {@link visor.clientrpc.GetBankTransactionsResult.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {visor.clientrpc.IGetBankTransactionsResult} message GetBankTransactionsResult message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankTransactionsResult.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBankTransactionsResult message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBankTransactionsResult} GetBankTransactionsResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankTransactionsResult.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBankTransactionsResult();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.transactionId = reader.string();
                            break;
                        case 2:
                            message.transactionAmount = reader.uint64();
                            break;
                        case 3:
                            message.postedDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.transactionDescriptor = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBankTransactionsResult message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBankTransactionsResult} GetBankTransactionsResult
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankTransactionsResult.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBankTransactionsResult message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBankTransactionsResult.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        if (!$util.isString(message.transactionId))
                            return "transactionId: string expected";
                    if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                        if (!$util.isInteger(message.transactionAmount) && !(message.transactionAmount && $util.isInteger(message.transactionAmount.low) && $util.isInteger(message.transactionAmount.high)))
                            return "transactionAmount: integer|Long expected";
                    if (message.postedDate != null && message.hasOwnProperty("postedDate")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.postedDate);
                        if (error)
                            return "postedDate." + error;
                    }
                    if (message.transactionDescriptor != null && message.hasOwnProperty("transactionDescriptor"))
                        if (!$util.isString(message.transactionDescriptor))
                            return "transactionDescriptor: string expected";
                    return null;
                };

                /**
                 * Creates a GetBankTransactionsResult message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBankTransactionsResult} GetBankTransactionsResult
                 */
                GetBankTransactionsResult.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBankTransactionsResult)
                        return object;
                    var message = new $root.visor.clientrpc.GetBankTransactionsResult();
                    if (object.transactionId != null)
                        message.transactionId = String(object.transactionId);
                    if (object.transactionAmount != null)
                        if ($util.Long)
                            (message.transactionAmount = $util.Long.fromValue(object.transactionAmount)).unsigned = true;
                        else if (typeof object.transactionAmount === "string")
                            message.transactionAmount = parseInt(object.transactionAmount, 10);
                        else if (typeof object.transactionAmount === "number")
                            message.transactionAmount = object.transactionAmount;
                        else if (typeof object.transactionAmount === "object")
                            message.transactionAmount = new $util.LongBits(object.transactionAmount.low >>> 0, object.transactionAmount.high >>> 0).toNumber(true);
                    if (object.postedDate != null) {
                        if (typeof object.postedDate !== "object")
                            throw TypeError(".visor.clientrpc.GetBankTransactionsResult.postedDate: object expected");
                        message.postedDate = $root.visor.common.time.LocalDate.fromObject(object.postedDate);
                    }
                    if (object.transactionDescriptor != null)
                        message.transactionDescriptor = String(object.transactionDescriptor);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBankTransactionsResult message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @static
                 * @param {visor.clientrpc.GetBankTransactionsResult} message GetBankTransactionsResult
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBankTransactionsResult.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.transactionId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.transactionAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.transactionAmount = options.longs === String ? "0" : 0;
                        object.postedDate = null;
                        object.transactionDescriptor = "";
                    }
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        object.transactionId = message.transactionId;
                    if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                        if (typeof message.transactionAmount === "number")
                            object.transactionAmount = options.longs === String ? String(message.transactionAmount) : message.transactionAmount;
                        else
                            object.transactionAmount = options.longs === String ? $util.Long.prototype.toString.call(message.transactionAmount) : options.longs === Number ? new $util.LongBits(message.transactionAmount.low >>> 0, message.transactionAmount.high >>> 0).toNumber(true) : message.transactionAmount;
                    if (message.postedDate != null && message.hasOwnProperty("postedDate"))
                        object.postedDate = $root.visor.common.time.LocalDate.toObject(message.postedDate, options);
                    if (message.transactionDescriptor != null && message.hasOwnProperty("transactionDescriptor"))
                        object.transactionDescriptor = message.transactionDescriptor;
                    return object;
                };

                /**
                 * Converts this GetBankTransactionsResult to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBankTransactionsResult
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBankTransactionsResult.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBankTransactionsResult;
            })();

            clientrpc.GetBankAccountVerificationQuestionsRequest = (function() {

                /**
                 * Properties of a GetBankAccountVerificationQuestionsRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetBankAccountVerificationQuestionsRequest
                 * @property {string|null} [lastFourOfBankAccount] GetBankAccountVerificationQuestionsRequest lastFourOfBankAccount
                 */

                /**
                 * Constructs a new GetBankAccountVerificationQuestionsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBankAccountVerificationQuestionsRequest.
                 * @implements IGetBankAccountVerificationQuestionsRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsRequest=} [properties] Properties to set
                 */
                function GetBankAccountVerificationQuestionsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBankAccountVerificationQuestionsRequest lastFourOfBankAccount.
                 * @member {string} lastFourOfBankAccount
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @instance
                 */
                GetBankAccountVerificationQuestionsRequest.prototype.lastFourOfBankAccount = "";

                /**
                 * Creates a new GetBankAccountVerificationQuestionsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsRequest} GetBankAccountVerificationQuestionsRequest instance
                 */
                GetBankAccountVerificationQuestionsRequest.create = function create(properties) {
                    return new GetBankAccountVerificationQuestionsRequest(properties);
                };

                /**
                 * Encodes the specified GetBankAccountVerificationQuestionsRequest message. Does not implicitly {@link visor.clientrpc.GetBankAccountVerificationQuestionsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsRequest} message GetBankAccountVerificationQuestionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankAccountVerificationQuestionsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.lastFourOfBankAccount != null && message.hasOwnProperty("lastFourOfBankAccount"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.lastFourOfBankAccount);
                    return writer;
                };

                /**
                 * Encodes the specified GetBankAccountVerificationQuestionsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetBankAccountVerificationQuestionsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsRequest} message GetBankAccountVerificationQuestionsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankAccountVerificationQuestionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBankAccountVerificationQuestionsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsRequest} GetBankAccountVerificationQuestionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankAccountVerificationQuestionsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBankAccountVerificationQuestionsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.lastFourOfBankAccount = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBankAccountVerificationQuestionsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsRequest} GetBankAccountVerificationQuestionsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankAccountVerificationQuestionsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBankAccountVerificationQuestionsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBankAccountVerificationQuestionsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.lastFourOfBankAccount != null && message.hasOwnProperty("lastFourOfBankAccount"))
                        if (!$util.isString(message.lastFourOfBankAccount))
                            return "lastFourOfBankAccount: string expected";
                    return null;
                };

                /**
                 * Creates a GetBankAccountVerificationQuestionsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsRequest} GetBankAccountVerificationQuestionsRequest
                 */
                GetBankAccountVerificationQuestionsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBankAccountVerificationQuestionsRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetBankAccountVerificationQuestionsRequest();
                    if (object.lastFourOfBankAccount != null)
                        message.lastFourOfBankAccount = String(object.lastFourOfBankAccount);
                    return message;
                };

                /**
                 * Creates a plain object from a GetBankAccountVerificationQuestionsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @static
                 * @param {visor.clientrpc.GetBankAccountVerificationQuestionsRequest} message GetBankAccountVerificationQuestionsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBankAccountVerificationQuestionsRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.lastFourOfBankAccount = "";
                    if (message.lastFourOfBankAccount != null && message.hasOwnProperty("lastFourOfBankAccount"))
                        object.lastFourOfBankAccount = message.lastFourOfBankAccount;
                    return object;
                };

                /**
                 * Converts this GetBankAccountVerificationQuestionsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBankAccountVerificationQuestionsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBankAccountVerificationQuestionsRequest;
            })();

            clientrpc.GetBankAccountVerificationQuestionsResponse = (function() {

                /**
                 * Properties of a GetBankAccountVerificationQuestionsResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetBankAccountVerificationQuestionsResponse
                 * @property {Array.<visor.clientrpc.IGetBankTransactionsResult>|null} [transactions] GetBankAccountVerificationQuestionsResponse transactions
                 * @property {visor.clientrpc.GetBankAccountVerificationQuestionsReturnCode|null} [responseCode] GetBankAccountVerificationQuestionsResponse responseCode
                 */

                /**
                 * Constructs a new GetBankAccountVerificationQuestionsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetBankAccountVerificationQuestionsResponse.
                 * @implements IGetBankAccountVerificationQuestionsResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsResponse=} [properties] Properties to set
                 */
                function GetBankAccountVerificationQuestionsResponse(properties) {
                    this.transactions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetBankAccountVerificationQuestionsResponse transactions.
                 * @member {Array.<visor.clientrpc.IGetBankTransactionsResult>} transactions
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @instance
                 */
                GetBankAccountVerificationQuestionsResponse.prototype.transactions = $util.emptyArray;

                /**
                 * GetBankAccountVerificationQuestionsResponse responseCode.
                 * @member {visor.clientrpc.GetBankAccountVerificationQuestionsReturnCode} responseCode
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @instance
                 */
                GetBankAccountVerificationQuestionsResponse.prototype.responseCode = 0;

                /**
                 * Creates a new GetBankAccountVerificationQuestionsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsResponse} GetBankAccountVerificationQuestionsResponse instance
                 */
                GetBankAccountVerificationQuestionsResponse.create = function create(properties) {
                    return new GetBankAccountVerificationQuestionsResponse(properties);
                };

                /**
                 * Encodes the specified GetBankAccountVerificationQuestionsResponse message. Does not implicitly {@link visor.clientrpc.GetBankAccountVerificationQuestionsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsResponse} message GetBankAccountVerificationQuestionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankAccountVerificationQuestionsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transactions != null && message.transactions.length)
                        for (var i = 0; i < message.transactions.length; ++i)
                            $root.visor.clientrpc.GetBankTransactionsResult.encode(message.transactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.responseCode);
                    return writer;
                };

                /**
                 * Encodes the specified GetBankAccountVerificationQuestionsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetBankAccountVerificationQuestionsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {visor.clientrpc.IGetBankAccountVerificationQuestionsResponse} message GetBankAccountVerificationQuestionsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetBankAccountVerificationQuestionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetBankAccountVerificationQuestionsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsResponse} GetBankAccountVerificationQuestionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankAccountVerificationQuestionsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetBankAccountVerificationQuestionsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.transactions && message.transactions.length))
                                message.transactions = [];
                            message.transactions.push($root.visor.clientrpc.GetBankTransactionsResult.decode(reader, reader.uint32()));
                            break;
                        case 2:
                            message.responseCode = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetBankAccountVerificationQuestionsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsResponse} GetBankAccountVerificationQuestionsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetBankAccountVerificationQuestionsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetBankAccountVerificationQuestionsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetBankAccountVerificationQuestionsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactions != null && message.hasOwnProperty("transactions")) {
                        if (!Array.isArray(message.transactions))
                            return "transactions: array expected";
                        for (var i = 0; i < message.transactions.length; ++i) {
                            var error = $root.visor.clientrpc.GetBankTransactionsResult.verify(message.transactions[i]);
                            if (error)
                                return "transactions." + error;
                        }
                    }
                    if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                        switch (message.responseCode) {
                        default:
                            return "responseCode: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetBankAccountVerificationQuestionsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetBankAccountVerificationQuestionsResponse} GetBankAccountVerificationQuestionsResponse
                 */
                GetBankAccountVerificationQuestionsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetBankAccountVerificationQuestionsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetBankAccountVerificationQuestionsResponse();
                    if (object.transactions) {
                        if (!Array.isArray(object.transactions))
                            throw TypeError(".visor.clientrpc.GetBankAccountVerificationQuestionsResponse.transactions: array expected");
                        message.transactions = [];
                        for (var i = 0; i < object.transactions.length; ++i) {
                            if (typeof object.transactions[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetBankAccountVerificationQuestionsResponse.transactions: object expected");
                            message.transactions[i] = $root.visor.clientrpc.GetBankTransactionsResult.fromObject(object.transactions[i]);
                        }
                    }
                    switch (object.responseCode) {
                    case "GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_INVALID":
                    case 0:
                        message.responseCode = 0;
                        break;
                    case "GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_SUCCESS":
                    case 1:
                        message.responseCode = 1;
                        break;
                    case "GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_FAILURE":
                    case 2:
                        message.responseCode = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetBankAccountVerificationQuestionsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @static
                 * @param {visor.clientrpc.GetBankAccountVerificationQuestionsResponse} message GetBankAccountVerificationQuestionsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetBankAccountVerificationQuestionsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.transactions = [];
                    if (options.defaults)
                        object.responseCode = options.enums === String ? "GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_INVALID" : 0;
                    if (message.transactions && message.transactions.length) {
                        object.transactions = [];
                        for (var j = 0; j < message.transactions.length; ++j)
                            object.transactions[j] = $root.visor.clientrpc.GetBankTransactionsResult.toObject(message.transactions[j], options);
                    }
                    if (message.responseCode != null && message.hasOwnProperty("responseCode"))
                        object.responseCode = options.enums === String ? $root.visor.clientrpc.GetBankAccountVerificationQuestionsReturnCode[message.responseCode] : message.responseCode;
                    return object;
                };

                /**
                 * Converts this GetBankAccountVerificationQuestionsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetBankAccountVerificationQuestionsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetBankAccountVerificationQuestionsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetBankAccountVerificationQuestionsResponse;
            })();

            /**
             * GetBankAccountVerificationQuestionsReturnCode enum.
             * @name visor.clientrpc.GetBankAccountVerificationQuestionsReturnCode
             * @enum {string}
             * @property {number} GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_INVALID=0 GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_INVALID value
             * @property {number} GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_SUCCESS=1 GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_SUCCESS value
             * @property {number} GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_FAILURE=2 GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_FAILURE value
             */
            clientrpc.GetBankAccountVerificationQuestionsReturnCode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_INVALID"] = 0;
                values[valuesById[1] = "GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_SUCCESS"] = 1;
                values[valuesById[2] = "GET_BANK_ACCOUNT_VERIFICATION_QUESTIONS_RETURN_CODE_FAILURE"] = 2;
                return values;
            })();

            clientrpc.CompletePreSelectBankAccountStepRequest = (function() {

                /**
                 * Properties of a CompletePreSelectBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompletePreSelectBankAccountStepRequest
                 * @property {string|null} [stepToken] CompletePreSelectBankAccountStepRequest stepToken
                 * @property {string|null} [workflowToken] CompletePreSelectBankAccountStepRequest workflowToken
                 * @property {string|null} [preSelectedInstitutionId] CompletePreSelectBankAccountStepRequest preSelectedInstitutionId
                 */

                /**
                 * Constructs a new CompletePreSelectBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePreSelectBankAccountStepRequest.
                 * @implements ICompletePreSelectBankAccountStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepRequest=} [properties] Properties to set
                 */
                function CompletePreSelectBankAccountStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompletePreSelectBankAccountStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @instance
                 */
                CompletePreSelectBankAccountStepRequest.prototype.stepToken = "";

                /**
                 * CompletePreSelectBankAccountStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @instance
                 */
                CompletePreSelectBankAccountStepRequest.prototype.workflowToken = "";

                /**
                 * CompletePreSelectBankAccountStepRequest preSelectedInstitutionId.
                 * @member {string} preSelectedInstitutionId
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @instance
                 */
                CompletePreSelectBankAccountStepRequest.prototype.preSelectedInstitutionId = "";

                /**
                 * Creates a new CompletePreSelectBankAccountStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepRequest} CompletePreSelectBankAccountStepRequest instance
                 */
                CompletePreSelectBankAccountStepRequest.create = function create(properties) {
                    return new CompletePreSelectBankAccountStepRequest(properties);
                };

                /**
                 * Encodes the specified CompletePreSelectBankAccountStepRequest message. Does not implicitly {@link visor.clientrpc.CompletePreSelectBankAccountStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepRequest} message CompletePreSelectBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePreSelectBankAccountStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.preSelectedInstitutionId != null && message.hasOwnProperty("preSelectedInstitutionId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.preSelectedInstitutionId);
                    return writer;
                };

                /**
                 * Encodes the specified CompletePreSelectBankAccountStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePreSelectBankAccountStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepRequest} message CompletePreSelectBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePreSelectBankAccountStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePreSelectBankAccountStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepRequest} CompletePreSelectBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePreSelectBankAccountStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePreSelectBankAccountStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.preSelectedInstitutionId = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePreSelectBankAccountStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepRequest} CompletePreSelectBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePreSelectBankAccountStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePreSelectBankAccountStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePreSelectBankAccountStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.preSelectedInstitutionId != null && message.hasOwnProperty("preSelectedInstitutionId"))
                        if (!$util.isString(message.preSelectedInstitutionId))
                            return "preSelectedInstitutionId: string expected";
                    return null;
                };

                /**
                 * Creates a CompletePreSelectBankAccountStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepRequest} CompletePreSelectBankAccountStepRequest
                 */
                CompletePreSelectBankAccountStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePreSelectBankAccountStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompletePreSelectBankAccountStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.preSelectedInstitutionId != null)
                        message.preSelectedInstitutionId = String(object.preSelectedInstitutionId);
                    return message;
                };

                /**
                 * Creates a plain object from a CompletePreSelectBankAccountStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.CompletePreSelectBankAccountStepRequest} message CompletePreSelectBankAccountStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePreSelectBankAccountStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.preSelectedInstitutionId = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.preSelectedInstitutionId != null && message.hasOwnProperty("preSelectedInstitutionId"))
                        object.preSelectedInstitutionId = message.preSelectedInstitutionId;
                    return object;
                };

                /**
                 * Converts this CompletePreSelectBankAccountStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePreSelectBankAccountStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePreSelectBankAccountStepRequest;
            })();

            clientrpc.CompletePreSelectBankAccountStepResponse = (function() {

                /**
                 * Properties of a CompletePreSelectBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompletePreSelectBankAccountStepResponse
                 */

                /**
                 * Constructs a new CompletePreSelectBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePreSelectBankAccountStepResponse.
                 * @implements ICompletePreSelectBankAccountStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepResponse=} [properties] Properties to set
                 */
                function CompletePreSelectBankAccountStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompletePreSelectBankAccountStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepResponse} CompletePreSelectBankAccountStepResponse instance
                 */
                CompletePreSelectBankAccountStepResponse.create = function create(properties) {
                    return new CompletePreSelectBankAccountStepResponse(properties);
                };

                /**
                 * Encodes the specified CompletePreSelectBankAccountStepResponse message. Does not implicitly {@link visor.clientrpc.CompletePreSelectBankAccountStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepResponse} message CompletePreSelectBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePreSelectBankAccountStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompletePreSelectBankAccountStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePreSelectBankAccountStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePreSelectBankAccountStepResponse} message CompletePreSelectBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePreSelectBankAccountStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePreSelectBankAccountStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepResponse} CompletePreSelectBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePreSelectBankAccountStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePreSelectBankAccountStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePreSelectBankAccountStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepResponse} CompletePreSelectBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePreSelectBankAccountStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePreSelectBankAccountStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePreSelectBankAccountStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompletePreSelectBankAccountStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePreSelectBankAccountStepResponse} CompletePreSelectBankAccountStepResponse
                 */
                CompletePreSelectBankAccountStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePreSelectBankAccountStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompletePreSelectBankAccountStepResponse();
                };

                /**
                 * Creates a plain object from a CompletePreSelectBankAccountStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.CompletePreSelectBankAccountStepResponse} message CompletePreSelectBankAccountStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePreSelectBankAccountStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompletePreSelectBankAccountStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePreSelectBankAccountStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePreSelectBankAccountStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePreSelectBankAccountStepResponse;
            })();

            clientrpc.CompleteConnectBankAccountStepRequest = (function() {

                /**
                 * Properties of a CompleteConnectBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectBankAccountStepRequest
                 * @property {string|null} [stepToken] CompleteConnectBankAccountStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteConnectBankAccountStepRequest workflowToken
                 * @property {boolean|null} [plaidItemNoError] CompleteConnectBankAccountStepRequest plaidItemNoError
                 */

                /**
                 * Constructs a new CompleteConnectBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectBankAccountStepRequest.
                 * @implements ICompleteConnectBankAccountStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepRequest=} [properties] Properties to set
                 */
                function CompleteConnectBankAccountStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteConnectBankAccountStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @instance
                 */
                CompleteConnectBankAccountStepRequest.prototype.stepToken = "";

                /**
                 * CompleteConnectBankAccountStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @instance
                 */
                CompleteConnectBankAccountStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteConnectBankAccountStepRequest plaidItemNoError.
                 * @member {boolean} plaidItemNoError
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @instance
                 */
                CompleteConnectBankAccountStepRequest.prototype.plaidItemNoError = false;

                /**
                 * Creates a new CompleteConnectBankAccountStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepRequest} CompleteConnectBankAccountStepRequest instance
                 */
                CompleteConnectBankAccountStepRequest.create = function create(properties) {
                    return new CompleteConnectBankAccountStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteConnectBankAccountStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteConnectBankAccountStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepRequest} message CompleteConnectBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectBankAccountStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.plaidItemNoError != null && message.hasOwnProperty("plaidItemNoError"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.plaidItemNoError);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectBankAccountStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectBankAccountStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepRequest} message CompleteConnectBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectBankAccountStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectBankAccountStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepRequest} CompleteConnectBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectBankAccountStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectBankAccountStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.plaidItemNoError = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectBankAccountStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepRequest} CompleteConnectBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectBankAccountStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectBankAccountStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectBankAccountStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.plaidItemNoError != null && message.hasOwnProperty("plaidItemNoError"))
                        if (typeof message.plaidItemNoError !== "boolean")
                            return "plaidItemNoError: boolean expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectBankAccountStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepRequest} CompleteConnectBankAccountStepRequest
                 */
                CompleteConnectBankAccountStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectBankAccountStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteConnectBankAccountStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.plaidItemNoError != null)
                        message.plaidItemNoError = Boolean(object.plaidItemNoError);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteConnectBankAccountStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteConnectBankAccountStepRequest} message CompleteConnectBankAccountStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectBankAccountStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.plaidItemNoError = false;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.plaidItemNoError != null && message.hasOwnProperty("plaidItemNoError"))
                        object.plaidItemNoError = message.plaidItemNoError;
                    return object;
                };

                /**
                 * Converts this CompleteConnectBankAccountStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectBankAccountStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectBankAccountStepRequest;
            })();

            clientrpc.CompleteConnectBankAccountStepResponse = (function() {

                /**
                 * Properties of a CompleteConnectBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectBankAccountStepResponse
                 */

                /**
                 * Constructs a new CompleteConnectBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectBankAccountStepResponse.
                 * @implements ICompleteConnectBankAccountStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepResponse=} [properties] Properties to set
                 */
                function CompleteConnectBankAccountStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteConnectBankAccountStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepResponse} CompleteConnectBankAccountStepResponse instance
                 */
                CompleteConnectBankAccountStepResponse.create = function create(properties) {
                    return new CompleteConnectBankAccountStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteConnectBankAccountStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteConnectBankAccountStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepResponse} message CompleteConnectBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectBankAccountStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectBankAccountStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectBankAccountStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectBankAccountStepResponse} message CompleteConnectBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectBankAccountStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectBankAccountStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepResponse} CompleteConnectBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectBankAccountStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectBankAccountStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectBankAccountStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepResponse} CompleteConnectBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectBankAccountStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectBankAccountStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectBankAccountStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectBankAccountStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectBankAccountStepResponse} CompleteConnectBankAccountStepResponse
                 */
                CompleteConnectBankAccountStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectBankAccountStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteConnectBankAccountStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteConnectBankAccountStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteConnectBankAccountStepResponse} message CompleteConnectBankAccountStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectBankAccountStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteConnectBankAccountStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectBankAccountStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectBankAccountStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectBankAccountStepResponse;
            })();

            clientrpc.CompleteConnectDebitCardStepRequest = (function() {

                /**
                 * Properties of a CompleteConnectDebitCardStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectDebitCardStepRequest
                 * @property {string|null} [stepToken] CompleteConnectDebitCardStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteConnectDebitCardStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteConnectDebitCardStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectDebitCardStepRequest.
                 * @implements ICompleteConnectDebitCardStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepRequest=} [properties] Properties to set
                 */
                function CompleteConnectDebitCardStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteConnectDebitCardStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @instance
                 */
                CompleteConnectDebitCardStepRequest.prototype.stepToken = "";

                /**
                 * CompleteConnectDebitCardStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @instance
                 */
                CompleteConnectDebitCardStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteConnectDebitCardStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepRequest} CompleteConnectDebitCardStepRequest instance
                 */
                CompleteConnectDebitCardStepRequest.create = function create(properties) {
                    return new CompleteConnectDebitCardStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteConnectDebitCardStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteConnectDebitCardStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepRequest} message CompleteConnectDebitCardStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectDebitCardStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectDebitCardStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectDebitCardStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepRequest} message CompleteConnectDebitCardStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectDebitCardStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectDebitCardStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepRequest} CompleteConnectDebitCardStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectDebitCardStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectDebitCardStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectDebitCardStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepRequest} CompleteConnectDebitCardStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectDebitCardStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectDebitCardStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectDebitCardStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectDebitCardStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepRequest} CompleteConnectDebitCardStepRequest
                 */
                CompleteConnectDebitCardStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectDebitCardStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteConnectDebitCardStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteConnectDebitCardStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteConnectDebitCardStepRequest} message CompleteConnectDebitCardStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectDebitCardStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteConnectDebitCardStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectDebitCardStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectDebitCardStepRequest;
            })();

            clientrpc.CompleteConnectDebitCardStepResponse = (function() {

                /**
                 * Properties of a CompleteConnectDebitCardStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteConnectDebitCardStepResponse
                 */

                /**
                 * Constructs a new CompleteConnectDebitCardStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteConnectDebitCardStepResponse.
                 * @implements ICompleteConnectDebitCardStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepResponse=} [properties] Properties to set
                 */
                function CompleteConnectDebitCardStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteConnectDebitCardStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepResponse} CompleteConnectDebitCardStepResponse instance
                 */
                CompleteConnectDebitCardStepResponse.create = function create(properties) {
                    return new CompleteConnectDebitCardStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteConnectDebitCardStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteConnectDebitCardStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepResponse} message CompleteConnectDebitCardStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectDebitCardStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteConnectDebitCardStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteConnectDebitCardStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteConnectDebitCardStepResponse} message CompleteConnectDebitCardStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteConnectDebitCardStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteConnectDebitCardStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepResponse} CompleteConnectDebitCardStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectDebitCardStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteConnectDebitCardStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteConnectDebitCardStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepResponse} CompleteConnectDebitCardStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteConnectDebitCardStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteConnectDebitCardStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteConnectDebitCardStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteConnectDebitCardStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteConnectDebitCardStepResponse} CompleteConnectDebitCardStepResponse
                 */
                CompleteConnectDebitCardStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteConnectDebitCardStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteConnectDebitCardStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteConnectDebitCardStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteConnectDebitCardStepResponse} message CompleteConnectDebitCardStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteConnectDebitCardStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteConnectDebitCardStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteConnectDebitCardStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteConnectDebitCardStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteConnectDebitCardStepResponse;
            })();

            clientrpc.CompleteBoostCardCheckoutStepRequest = (function() {

                /**
                 * Properties of a CompleteBoostCardCheckoutStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteBoostCardCheckoutStepRequest
                 * @property {string|null} [stepToken] CompleteBoostCardCheckoutStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteBoostCardCheckoutStepRequest workflowToken
                 * @property {google.protobuf.ITimestamp|null} [acceptedTosAt] CompleteBoostCardCheckoutStepRequest acceptedTosAt
                 * @property {string|null} [paymentMethodToken] CompleteBoostCardCheckoutStepRequest paymentMethodToken
                 * @property {string|null} [cardToken] CompleteBoostCardCheckoutStepRequest cardToken
                 * @property {visor.clientrpc.ISubscription|null} [existingSubscription] CompleteBoostCardCheckoutStepRequest existingSubscription
                 * @property {visor.clientrpc.IAdvance|null} [advance] CompleteBoostCardCheckoutStepRequest advance
                 * @property {string|null} [paymentToken] CompleteBoostCardCheckoutStepRequest paymentToken
                 */

                /**
                 * Constructs a new CompleteBoostCardCheckoutStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteBoostCardCheckoutStepRequest.
                 * @implements ICompleteBoostCardCheckoutStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepRequest=} [properties] Properties to set
                 */
                function CompleteBoostCardCheckoutStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteBoostCardCheckoutStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.stepToken = "";

                /**
                 * CompleteBoostCardCheckoutStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteBoostCardCheckoutStepRequest acceptedTosAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} acceptedTosAt
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.acceptedTosAt = null;

                /**
                 * CompleteBoostCardCheckoutStepRequest paymentMethodToken.
                 * @member {string} paymentMethodToken
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.paymentMethodToken = "";

                /**
                 * CompleteBoostCardCheckoutStepRequest cardToken.
                 * @member {string} cardToken
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.cardToken = "";

                /**
                 * CompleteBoostCardCheckoutStepRequest existingSubscription.
                 * @member {visor.clientrpc.ISubscription|null|undefined} existingSubscription
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.existingSubscription = null;

                /**
                 * CompleteBoostCardCheckoutStepRequest advance.
                 * @member {visor.clientrpc.IAdvance|null|undefined} advance
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.advance = null;

                /**
                 * CompleteBoostCardCheckoutStepRequest paymentToken.
                 * @member {string} paymentToken
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.paymentToken = "";

                /**
                 * Creates a new CompleteBoostCardCheckoutStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepRequest} CompleteBoostCardCheckoutStepRequest instance
                 */
                CompleteBoostCardCheckoutStepRequest.create = function create(properties) {
                    return new CompleteBoostCardCheckoutStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteBoostCardCheckoutStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteBoostCardCheckoutStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepRequest} message CompleteBoostCardCheckoutStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardCheckoutStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.acceptedTosAt != null && message.hasOwnProperty("acceptedTosAt"))
                        $root.google.protobuf.Timestamp.encode(message.acceptedTosAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.paymentMethodToken);
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription"))
                        $root.visor.clientrpc.Subscription.encode(message.existingSubscription, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.advance != null && message.hasOwnProperty("advance"))
                        $root.visor.clientrpc.Advance.encode(message.advance, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.cardToken);
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.paymentToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteBoostCardCheckoutStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteBoostCardCheckoutStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepRequest} message CompleteBoostCardCheckoutStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardCheckoutStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteBoostCardCheckoutStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepRequest} CompleteBoostCardCheckoutStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardCheckoutStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteBoostCardCheckoutStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.acceptedTosAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.paymentMethodToken = reader.string();
                            break;
                        case 7:
                            message.cardToken = reader.string();
                            break;
                        case 5:
                            message.existingSubscription = $root.visor.clientrpc.Subscription.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.advance = $root.visor.clientrpc.Advance.decode(reader, reader.uint32());
                            break;
                        case 9:
                            message.paymentToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteBoostCardCheckoutStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepRequest} CompleteBoostCardCheckoutStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardCheckoutStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteBoostCardCheckoutStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteBoostCardCheckoutStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.acceptedTosAt != null && message.hasOwnProperty("acceptedTosAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.acceptedTosAt);
                        if (error)
                            return "acceptedTosAt." + error;
                    }
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        if (!$util.isString(message.paymentMethodToken))
                            return "paymentMethodToken: string expected";
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        if (!$util.isString(message.cardToken))
                            return "cardToken: string expected";
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription")) {
                        var error = $root.visor.clientrpc.Subscription.verify(message.existingSubscription);
                        if (error)
                            return "existingSubscription." + error;
                    }
                    if (message.advance != null && message.hasOwnProperty("advance")) {
                        var error = $root.visor.clientrpc.Advance.verify(message.advance);
                        if (error)
                            return "advance." + error;
                    }
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        if (!$util.isString(message.paymentToken))
                            return "paymentToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteBoostCardCheckoutStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepRequest} CompleteBoostCardCheckoutStepRequest
                 */
                CompleteBoostCardCheckoutStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteBoostCardCheckoutStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteBoostCardCheckoutStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.acceptedTosAt != null)
                        if (object.acceptedTosAt === null)
                            message.acceptedTosAt = {};
                        else
                            message.acceptedTosAt = {
                                seconds: Math.floor(object.acceptedTosAt.getTime() / 1000),
                                nanos: object.acceptedTosAt.getMilliseconds() * 1000000
                            };
                    if (object.paymentMethodToken != null)
                        message.paymentMethodToken = String(object.paymentMethodToken);
                    if (object.cardToken != null)
                        message.cardToken = String(object.cardToken);
                    if (object.existingSubscription != null) {
                        if (typeof object.existingSubscription !== "object")
                            throw TypeError(".visor.clientrpc.CompleteBoostCardCheckoutStepRequest.existingSubscription: object expected");
                        message.existingSubscription = $root.visor.clientrpc.Subscription.fromObject(object.existingSubscription);
                    }
                    if (object.advance != null) {
                        if (typeof object.advance !== "object")
                            throw TypeError(".visor.clientrpc.CompleteBoostCardCheckoutStepRequest.advance: object expected");
                        message.advance = $root.visor.clientrpc.Advance.fromObject(object.advance);
                    }
                    if (object.paymentToken != null)
                        message.paymentToken = String(object.paymentToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteBoostCardCheckoutStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteBoostCardCheckoutStepRequest} message CompleteBoostCardCheckoutStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteBoostCardCheckoutStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.acceptedTosAt = null;
                        object.paymentMethodToken = "";
                        object.existingSubscription = null;
                        object.advance = null;
                        object.cardToken = "";
                        object.paymentToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.acceptedTosAt != null && message.hasOwnProperty("acceptedTosAt"))
                        object.acceptedTosAt = new Date(message.acceptedTosAt.seconds * 1000 + message.acceptedTosAt.nanos / 1000000);
                    if (message.paymentMethodToken != null && message.hasOwnProperty("paymentMethodToken"))
                        object.paymentMethodToken = message.paymentMethodToken;
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription"))
                        object.existingSubscription = $root.visor.clientrpc.Subscription.toObject(message.existingSubscription, options);
                    if (message.advance != null && message.hasOwnProperty("advance"))
                        object.advance = $root.visor.clientrpc.Advance.toObject(message.advance, options);
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        object.cardToken = message.cardToken;
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        object.paymentToken = message.paymentToken;
                    return object;
                };

                /**
                 * Converts this CompleteBoostCardCheckoutStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteBoostCardCheckoutStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteBoostCardCheckoutStepRequest;
            })();

            clientrpc.CompleteBoostCardCheckoutStepResponse = (function() {

                /**
                 * Properties of a CompleteBoostCardCheckoutStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteBoostCardCheckoutStepResponse
                 * @property {string|null} [paymentToken] CompleteBoostCardCheckoutStepResponse paymentToken
                 * @property {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.IPaymentDecline|null} [decline] CompleteBoostCardCheckoutStepResponse decline
                 */

                /**
                 * Constructs a new CompleteBoostCardCheckoutStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteBoostCardCheckoutStepResponse.
                 * @implements ICompleteBoostCardCheckoutStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepResponse=} [properties] Properties to set
                 */
                function CompleteBoostCardCheckoutStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteBoostCardCheckoutStepResponse paymentToken.
                 * @member {string} paymentToken
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @instance
                 */
                CompleteBoostCardCheckoutStepResponse.prototype.paymentToken = "";

                /**
                 * CompleteBoostCardCheckoutStepResponse decline.
                 * @member {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.IPaymentDecline|null|undefined} decline
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @instance
                 */
                CompleteBoostCardCheckoutStepResponse.prototype.decline = null;

                /**
                 * Creates a new CompleteBoostCardCheckoutStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse} CompleteBoostCardCheckoutStepResponse instance
                 */
                CompleteBoostCardCheckoutStepResponse.create = function create(properties) {
                    return new CompleteBoostCardCheckoutStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteBoostCardCheckoutStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteBoostCardCheckoutStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepResponse} message CompleteBoostCardCheckoutStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardCheckoutStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentToken);
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.encode(message.decline, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteBoostCardCheckoutStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteBoostCardCheckoutStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardCheckoutStepResponse} message CompleteBoostCardCheckoutStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardCheckoutStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteBoostCardCheckoutStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse} CompleteBoostCardCheckoutStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardCheckoutStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.paymentToken = reader.string();
                            break;
                        case 2:
                            message.decline = $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteBoostCardCheckoutStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse} CompleteBoostCardCheckoutStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardCheckoutStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteBoostCardCheckoutStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteBoostCardCheckoutStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        if (!$util.isString(message.paymentToken))
                            return "paymentToken: string expected";
                    if (message.decline != null && message.hasOwnProperty("decline")) {
                        var error = $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.verify(message.decline);
                        if (error)
                            return "decline." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CompleteBoostCardCheckoutStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse} CompleteBoostCardCheckoutStepResponse
                 */
                CompleteBoostCardCheckoutStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse();
                    if (object.paymentToken != null)
                        message.paymentToken = String(object.paymentToken);
                    if (object.decline != null) {
                        if (typeof object.decline !== "object")
                            throw TypeError(".visor.clientrpc.CompleteBoostCardCheckoutStepResponse.decline: object expected");
                        message.decline = $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.fromObject(object.decline);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteBoostCardCheckoutStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteBoostCardCheckoutStepResponse} message CompleteBoostCardCheckoutStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteBoostCardCheckoutStepResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.paymentToken = "";
                        object.decline = null;
                    }
                    if (message.paymentToken != null && message.hasOwnProperty("paymentToken"))
                        object.paymentToken = message.paymentToken;
                    if (message.decline != null && message.hasOwnProperty("decline"))
                        object.decline = $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.toObject(message.decline, options);
                    return object;
                };

                /**
                 * Converts this CompleteBoostCardCheckoutStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteBoostCardCheckoutStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CompleteBoostCardCheckoutStepResponse.PaymentDecline = (function() {

                    /**
                     * Properties of a PaymentDecline.
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                     * @interface IPaymentDecline
                     * @property {visor.common.strings.ILocalizedString|null} [declineMessage] PaymentDecline declineMessage
                     */

                    /**
                     * Constructs a new PaymentDecline.
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse
                     * @classdesc Represents a PaymentDecline.
                     * @implements IPaymentDecline
                     * @constructor
                     * @param {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.IPaymentDecline=} [properties] Properties to set
                     */
                    function PaymentDecline(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PaymentDecline declineMessage.
                     * @member {visor.common.strings.ILocalizedString|null|undefined} declineMessage
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @instance
                     */
                    PaymentDecline.prototype.declineMessage = null;

                    /**
                     * Creates a new PaymentDecline instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.IPaymentDecline=} [properties] Properties to set
                     * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline} PaymentDecline instance
                     */
                    PaymentDecline.create = function create(properties) {
                        return new PaymentDecline(properties);
                    };

                    /**
                     * Encodes the specified PaymentDecline message. Does not implicitly {@link visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.IPaymentDecline} message PaymentDecline message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentDecline.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.declineMessage != null && message.hasOwnProperty("declineMessage"))
                            $root.visor.common.strings.LocalizedString.encode(message.declineMessage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PaymentDecline message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.IPaymentDecline} message PaymentDecline message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentDecline.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PaymentDecline message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline} PaymentDecline
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentDecline.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.declineMessage = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PaymentDecline message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline} PaymentDecline
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentDecline.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PaymentDecline message.
                     * @function verify
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PaymentDecline.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.declineMessage != null && message.hasOwnProperty("declineMessage")) {
                            var error = $root.visor.common.strings.LocalizedString.verify(message.declineMessage);
                            if (error)
                                return "declineMessage." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a PaymentDecline message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline} PaymentDecline
                     */
                    PaymentDecline.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline)
                            return object;
                        var message = new $root.visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline();
                        if (object.declineMessage != null) {
                            if (typeof object.declineMessage !== "object")
                                throw TypeError(".visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline.declineMessage: object expected");
                            message.declineMessage = $root.visor.common.strings.LocalizedString.fromObject(object.declineMessage);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PaymentDecline message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @static
                     * @param {visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline} message PaymentDecline
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaymentDecline.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.declineMessage = null;
                        if (message.declineMessage != null && message.hasOwnProperty("declineMessage"))
                            object.declineMessage = $root.visor.common.strings.LocalizedString.toObject(message.declineMessage, options);
                        return object;
                    };

                    /**
                     * Converts this PaymentDecline to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CompleteBoostCardCheckoutStepResponse.PaymentDecline
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaymentDecline.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PaymentDecline;
                })();

                return CompleteBoostCardCheckoutStepResponse;
            })();

            clientrpc.CompleteBoostCardQualificationStepRequest = (function() {

                /**
                 * Properties of a CompleteBoostCardQualificationStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteBoostCardQualificationStepRequest
                 * @property {string|null} [stepToken] CompleteBoostCardQualificationStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteBoostCardQualificationStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteBoostCardQualificationStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteBoostCardQualificationStepRequest.
                 * @implements ICompleteBoostCardQualificationStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepRequest=} [properties] Properties to set
                 */
                function CompleteBoostCardQualificationStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteBoostCardQualificationStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @instance
                 */
                CompleteBoostCardQualificationStepRequest.prototype.stepToken = "";

                /**
                 * CompleteBoostCardQualificationStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @instance
                 */
                CompleteBoostCardQualificationStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteBoostCardQualificationStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepRequest} CompleteBoostCardQualificationStepRequest instance
                 */
                CompleteBoostCardQualificationStepRequest.create = function create(properties) {
                    return new CompleteBoostCardQualificationStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteBoostCardQualificationStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteBoostCardQualificationStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepRequest} message CompleteBoostCardQualificationStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardQualificationStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteBoostCardQualificationStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteBoostCardQualificationStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepRequest} message CompleteBoostCardQualificationStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardQualificationStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteBoostCardQualificationStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepRequest} CompleteBoostCardQualificationStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardQualificationStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteBoostCardQualificationStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteBoostCardQualificationStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepRequest} CompleteBoostCardQualificationStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardQualificationStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteBoostCardQualificationStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteBoostCardQualificationStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteBoostCardQualificationStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepRequest} CompleteBoostCardQualificationStepRequest
                 */
                CompleteBoostCardQualificationStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteBoostCardQualificationStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteBoostCardQualificationStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteBoostCardQualificationStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteBoostCardQualificationStepRequest} message CompleteBoostCardQualificationStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteBoostCardQualificationStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteBoostCardQualificationStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteBoostCardQualificationStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteBoostCardQualificationStepRequest;
            })();

            clientrpc.CompleteBoostCardQualificationStepResponse = (function() {

                /**
                 * Properties of a CompleteBoostCardQualificationStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteBoostCardQualificationStepResponse
                 */

                /**
                 * Constructs a new CompleteBoostCardQualificationStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteBoostCardQualificationStepResponse.
                 * @implements ICompleteBoostCardQualificationStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepResponse=} [properties] Properties to set
                 */
                function CompleteBoostCardQualificationStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteBoostCardQualificationStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepResponse} CompleteBoostCardQualificationStepResponse instance
                 */
                CompleteBoostCardQualificationStepResponse.create = function create(properties) {
                    return new CompleteBoostCardQualificationStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteBoostCardQualificationStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteBoostCardQualificationStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepResponse} message CompleteBoostCardQualificationStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardQualificationStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteBoostCardQualificationStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteBoostCardQualificationStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteBoostCardQualificationStepResponse} message CompleteBoostCardQualificationStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteBoostCardQualificationStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteBoostCardQualificationStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepResponse} CompleteBoostCardQualificationStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardQualificationStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteBoostCardQualificationStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteBoostCardQualificationStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepResponse} CompleteBoostCardQualificationStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteBoostCardQualificationStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteBoostCardQualificationStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteBoostCardQualificationStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteBoostCardQualificationStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteBoostCardQualificationStepResponse} CompleteBoostCardQualificationStepResponse
                 */
                CompleteBoostCardQualificationStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteBoostCardQualificationStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteBoostCardQualificationStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteBoostCardQualificationStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteBoostCardQualificationStepResponse} message CompleteBoostCardQualificationStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteBoostCardQualificationStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteBoostCardQualificationStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteBoostCardQualificationStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteBoostCardQualificationStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteBoostCardQualificationStepResponse;
            })();

            clientrpc.CompleteSetupStripeStepRequest = (function() {

                /**
                 * Properties of a CompleteSetupStripeStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSetupStripeStepRequest
                 * @property {string|null} [stepToken] CompleteSetupStripeStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteSetupStripeStepRequest workflowToken
                 * @property {string|null} [firstName] CompleteSetupStripeStepRequest firstName
                 * @property {string|null} [lastName] CompleteSetupStripeStepRequest lastName
                 * @property {string|null} [last_4Ssn] CompleteSetupStripeStepRequest last_4Ssn
                 * @property {visor.common.time.ILocalDate|null} [dateOfBirth] CompleteSetupStripeStepRequest dateOfBirth
                 * @property {string|null} [cashboostToken] CompleteSetupStripeStepRequest cashboostToken
                 */

                /**
                 * Constructs a new CompleteSetupStripeStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSetupStripeStepRequest.
                 * @implements ICompleteSetupStripeStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSetupStripeStepRequest=} [properties] Properties to set
                 */
                function CompleteSetupStripeStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteSetupStripeStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 */
                CompleteSetupStripeStepRequest.prototype.stepToken = "";

                /**
                 * CompleteSetupStripeStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 */
                CompleteSetupStripeStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteSetupStripeStepRequest firstName.
                 * @member {string} firstName
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 */
                CompleteSetupStripeStepRequest.prototype.firstName = "";

                /**
                 * CompleteSetupStripeStepRequest lastName.
                 * @member {string} lastName
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 */
                CompleteSetupStripeStepRequest.prototype.lastName = "";

                /**
                 * CompleteSetupStripeStepRequest last_4Ssn.
                 * @member {string} last_4Ssn
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 */
                CompleteSetupStripeStepRequest.prototype.last_4Ssn = "";

                /**
                 * CompleteSetupStripeStepRequest dateOfBirth.
                 * @member {visor.common.time.ILocalDate|null|undefined} dateOfBirth
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 */
                CompleteSetupStripeStepRequest.prototype.dateOfBirth = null;

                /**
                 * CompleteSetupStripeStepRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 */
                CompleteSetupStripeStepRequest.prototype.cashboostToken = "";

                /**
                 * Creates a new CompleteSetupStripeStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSetupStripeStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSetupStripeStepRequest} CompleteSetupStripeStepRequest instance
                 */
                CompleteSetupStripeStepRequest.create = function create(properties) {
                    return new CompleteSetupStripeStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteSetupStripeStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteSetupStripeStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSetupStripeStepRequest} message CompleteSetupStripeStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSetupStripeStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
                    if (message.last_4Ssn != null && message.hasOwnProperty("last_4Ssn"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.last_4Ssn);
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                        $root.visor.common.time.LocalDate.encode(message.dateOfBirth, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.cashboostToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSetupStripeStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSetupStripeStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSetupStripeStepRequest} message CompleteSetupStripeStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSetupStripeStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSetupStripeStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSetupStripeStepRequest} CompleteSetupStripeStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSetupStripeStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSetupStripeStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.firstName = reader.string();
                            break;
                        case 4:
                            message.lastName = reader.string();
                            break;
                        case 5:
                            message.last_4Ssn = reader.string();
                            break;
                        case 6:
                            message.dateOfBirth = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.cashboostToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSetupStripeStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSetupStripeStepRequest} CompleteSetupStripeStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSetupStripeStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSetupStripeStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSetupStripeStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.last_4Ssn != null && message.hasOwnProperty("last_4Ssn"))
                        if (!$util.isString(message.last_4Ssn))
                            return "last_4Ssn: string expected";
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.dateOfBirth);
                        if (error)
                            return "dateOfBirth." + error;
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteSetupStripeStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSetupStripeStepRequest} CompleteSetupStripeStepRequest
                 */
                CompleteSetupStripeStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSetupStripeStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteSetupStripeStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.last_4Ssn != null)
                        message.last_4Ssn = String(object.last_4Ssn);
                    if (object.dateOfBirth != null) {
                        if (typeof object.dateOfBirth !== "object")
                            throw TypeError(".visor.clientrpc.CompleteSetupStripeStepRequest.dateOfBirth: object expected");
                        message.dateOfBirth = $root.visor.common.time.LocalDate.fromObject(object.dateOfBirth);
                    }
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteSetupStripeStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteSetupStripeStepRequest} message CompleteSetupStripeStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSetupStripeStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.firstName = "";
                        object.lastName = "";
                        object.last_4Ssn = "";
                        object.dateOfBirth = null;
                        object.cashboostToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.last_4Ssn != null && message.hasOwnProperty("last_4Ssn"))
                        object.last_4Ssn = message.last_4Ssn;
                    if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                        object.dateOfBirth = $root.visor.common.time.LocalDate.toObject(message.dateOfBirth, options);
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    return object;
                };

                /**
                 * Converts this CompleteSetupStripeStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSetupStripeStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSetupStripeStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSetupStripeStepRequest;
            })();

            clientrpc.CompleteSetupStripeStepResponse = (function() {

                /**
                 * Properties of a CompleteSetupStripeStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSetupStripeStepResponse
                 */

                /**
                 * Constructs a new CompleteSetupStripeStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSetupStripeStepResponse.
                 * @implements ICompleteSetupStripeStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSetupStripeStepResponse=} [properties] Properties to set
                 */
                function CompleteSetupStripeStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteSetupStripeStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSetupStripeStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSetupStripeStepResponse} CompleteSetupStripeStepResponse instance
                 */
                CompleteSetupStripeStepResponse.create = function create(properties) {
                    return new CompleteSetupStripeStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteSetupStripeStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteSetupStripeStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSetupStripeStepResponse} message CompleteSetupStripeStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSetupStripeStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSetupStripeStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSetupStripeStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSetupStripeStepResponse} message CompleteSetupStripeStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSetupStripeStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSetupStripeStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSetupStripeStepResponse} CompleteSetupStripeStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSetupStripeStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSetupStripeStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSetupStripeStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSetupStripeStepResponse} CompleteSetupStripeStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSetupStripeStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSetupStripeStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSetupStripeStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteSetupStripeStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSetupStripeStepResponse} CompleteSetupStripeStepResponse
                 */
                CompleteSetupStripeStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSetupStripeStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteSetupStripeStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteSetupStripeStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteSetupStripeStepResponse} message CompleteSetupStripeStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSetupStripeStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteSetupStripeStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSetupStripeStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSetupStripeStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSetupStripeStepResponse;
            })();

            clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest = (function() {

                /**
                 * Properties of a BeginChangeCashBoostBankAccountWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginChangeCashBoostBankAccountWorkflowRequest
                 * @property {string|null} [cashBoostToken] BeginChangeCashBoostBankAccountWorkflowRequest cashBoostToken
                 */

                /**
                 * Constructs a new BeginChangeCashBoostBankAccountWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginChangeCashBoostBankAccountWorkflowRequest.
                 * @implements IBeginChangeCashBoostBankAccountWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowRequest=} [properties] Properties to set
                 */
                function BeginChangeCashBoostBankAccountWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BeginChangeCashBoostBankAccountWorkflowRequest cashBoostToken.
                 * @member {string} cashBoostToken
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @instance
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.prototype.cashBoostToken = "";

                /**
                 * Creates a new BeginChangeCashBoostBankAccountWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest} BeginChangeCashBoostBankAccountWorkflowRequest instance
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.create = function create(properties) {
                    return new BeginChangeCashBoostBankAccountWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginChangeCashBoostBankAccountWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowRequest} message BeginChangeCashBoostBankAccountWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashBoostToken != null && message.hasOwnProperty("cashBoostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashBoostToken);
                    return writer;
                };

                /**
                 * Encodes the specified BeginChangeCashBoostBankAccountWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowRequest} message BeginChangeCashBoostBankAccountWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginChangeCashBoostBankAccountWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest} BeginChangeCashBoostBankAccountWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashBoostToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginChangeCashBoostBankAccountWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest} BeginChangeCashBoostBankAccountWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginChangeCashBoostBankAccountWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashBoostToken != null && message.hasOwnProperty("cashBoostToken"))
                        if (!$util.isString(message.cashBoostToken))
                            return "cashBoostToken: string expected";
                    return null;
                };

                /**
                 * Creates a BeginChangeCashBoostBankAccountWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest} BeginChangeCashBoostBankAccountWorkflowRequest
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest)
                        return object;
                    var message = new $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest();
                    if (object.cashBoostToken != null)
                        message.cashBoostToken = String(object.cashBoostToken);
                    return message;
                };

                /**
                 * Creates a plain object from a BeginChangeCashBoostBankAccountWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest} message BeginChangeCashBoostBankAccountWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.cashBoostToken = "";
                    if (message.cashBoostToken != null && message.hasOwnProperty("cashBoostToken"))
                        object.cashBoostToken = message.cashBoostToken;
                    return object;
                };

                /**
                 * Converts this BeginChangeCashBoostBankAccountWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginChangeCashBoostBankAccountWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginChangeCashBoostBankAccountWorkflowRequest;
            })();

            clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse = (function() {

                /**
                 * Properties of a BeginChangeCashBoostBankAccountWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginChangeCashBoostBankAccountWorkflowResponse
                 */

                /**
                 * Constructs a new BeginChangeCashBoostBankAccountWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginChangeCashBoostBankAccountWorkflowResponse.
                 * @implements IBeginChangeCashBoostBankAccountWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowResponse=} [properties] Properties to set
                 */
                function BeginChangeCashBoostBankAccountWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginChangeCashBoostBankAccountWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse} BeginChangeCashBoostBankAccountWorkflowResponse instance
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.create = function create(properties) {
                    return new BeginChangeCashBoostBankAccountWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginChangeCashBoostBankAccountWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowResponse} message BeginChangeCashBoostBankAccountWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginChangeCashBoostBankAccountWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginChangeCashBoostBankAccountWorkflowResponse} message BeginChangeCashBoostBankAccountWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginChangeCashBoostBankAccountWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse} BeginChangeCashBoostBankAccountWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginChangeCashBoostBankAccountWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse} BeginChangeCashBoostBankAccountWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginChangeCashBoostBankAccountWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginChangeCashBoostBankAccountWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse} BeginChangeCashBoostBankAccountWorkflowResponse
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginChangeCashBoostBankAccountWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse} message BeginChangeCashBoostBankAccountWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginChangeCashBoostBankAccountWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginChangeCashBoostBankAccountWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginChangeCashBoostBankAccountWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginChangeCashBoostBankAccountWorkflowResponse;
            })();

            /**
             * AdvancePayoutType enum.
             * @name visor.clientrpc.AdvancePayoutType
             * @enum {string}
             * @property {number} ADVANCE_PAYOUT_TYPE_INVALID=0 ADVANCE_PAYOUT_TYPE_INVALID value
             * @property {number} ADVANCE_PAYOUT_TYPE_STANDARD=1 ADVANCE_PAYOUT_TYPE_STANDARD value
             * @property {number} ADVANCE_PAYOUT_TYPE_INSTANT_DEPOSIT=2 ADVANCE_PAYOUT_TYPE_INSTANT_DEPOSIT value
             */
            clientrpc.AdvancePayoutType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ADVANCE_PAYOUT_TYPE_INVALID"] = 0;
                values[valuesById[1] = "ADVANCE_PAYOUT_TYPE_STANDARD"] = 1;
                values[valuesById[2] = "ADVANCE_PAYOUT_TYPE_INSTANT_DEPOSIT"] = 2;
                return values;
            })();

            clientrpc.AdvanceOffer = (function() {

                /**
                 * Properties of an AdvanceOffer.
                 * @memberof visor.clientrpc
                 * @interface IAdvanceOffer
                 * @property {string|null} [token] AdvanceOffer token
                 * @property {visor.common.money.IAmount|null} [amount] AdvanceOffer amount
                 * @property {Array.<visor.clientrpc.AdvanceOffer.IPayoutOption>|null} [payoutOptions] AdvanceOffer payoutOptions
                 */

                /**
                 * Constructs a new AdvanceOffer.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an AdvanceOffer.
                 * @implements IAdvanceOffer
                 * @constructor
                 * @param {visor.clientrpc.IAdvanceOffer=} [properties] Properties to set
                 */
                function AdvanceOffer(properties) {
                    this.payoutOptions = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AdvanceOffer token.
                 * @member {string} token
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @instance
                 */
                AdvanceOffer.prototype.token = "";

                /**
                 * AdvanceOffer amount.
                 * @member {visor.common.money.IAmount|null|undefined} amount
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @instance
                 */
                AdvanceOffer.prototype.amount = null;

                /**
                 * AdvanceOffer payoutOptions.
                 * @member {Array.<visor.clientrpc.AdvanceOffer.IPayoutOption>} payoutOptions
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @instance
                 */
                AdvanceOffer.prototype.payoutOptions = $util.emptyArray;

                /**
                 * Creates a new AdvanceOffer instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {visor.clientrpc.IAdvanceOffer=} [properties] Properties to set
                 * @returns {visor.clientrpc.AdvanceOffer} AdvanceOffer instance
                 */
                AdvanceOffer.create = function create(properties) {
                    return new AdvanceOffer(properties);
                };

                /**
                 * Encodes the specified AdvanceOffer message. Does not implicitly {@link visor.clientrpc.AdvanceOffer.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {visor.clientrpc.IAdvanceOffer} message AdvanceOffer message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdvanceOffer.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.payoutOptions != null && message.payoutOptions.length)
                        for (var i = 0; i < message.payoutOptions.length; ++i)
                            $root.visor.clientrpc.AdvanceOffer.PayoutOption.encode(message.payoutOptions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified AdvanceOffer message, length delimited. Does not implicitly {@link visor.clientrpc.AdvanceOffer.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {visor.clientrpc.IAdvanceOffer} message AdvanceOffer message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AdvanceOffer.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AdvanceOffer message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.AdvanceOffer} AdvanceOffer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdvanceOffer.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AdvanceOffer();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.token = reader.string();
                            break;
                        case 2:
                            message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 3:
                            if (!(message.payoutOptions && message.payoutOptions.length))
                                message.payoutOptions = [];
                            message.payoutOptions.push($root.visor.clientrpc.AdvanceOffer.PayoutOption.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AdvanceOffer message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.AdvanceOffer} AdvanceOffer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AdvanceOffer.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AdvanceOffer message.
                 * @function verify
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AdvanceOffer.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.visor.common.money.Amount.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    if (message.payoutOptions != null && message.hasOwnProperty("payoutOptions")) {
                        if (!Array.isArray(message.payoutOptions))
                            return "payoutOptions: array expected";
                        for (var i = 0; i < message.payoutOptions.length; ++i) {
                            var error = $root.visor.clientrpc.AdvanceOffer.PayoutOption.verify(message.payoutOptions[i]);
                            if (error)
                                return "payoutOptions." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an AdvanceOffer message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.AdvanceOffer} AdvanceOffer
                 */
                AdvanceOffer.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.AdvanceOffer)
                        return object;
                    var message = new $root.visor.clientrpc.AdvanceOffer();
                    if (object.token != null)
                        message.token = String(object.token);
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".visor.clientrpc.AdvanceOffer.amount: object expected");
                        message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                    }
                    if (object.payoutOptions) {
                        if (!Array.isArray(object.payoutOptions))
                            throw TypeError(".visor.clientrpc.AdvanceOffer.payoutOptions: array expected");
                        message.payoutOptions = [];
                        for (var i = 0; i < object.payoutOptions.length; ++i) {
                            if (typeof object.payoutOptions[i] !== "object")
                                throw TypeError(".visor.clientrpc.AdvanceOffer.payoutOptions: object expected");
                            message.payoutOptions[i] = $root.visor.clientrpc.AdvanceOffer.PayoutOption.fromObject(object.payoutOptions[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an AdvanceOffer message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @static
                 * @param {visor.clientrpc.AdvanceOffer} message AdvanceOffer
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AdvanceOffer.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.payoutOptions = [];
                    if (options.defaults) {
                        object.token = "";
                        object.amount = null;
                    }
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                    if (message.payoutOptions && message.payoutOptions.length) {
                        object.payoutOptions = [];
                        for (var j = 0; j < message.payoutOptions.length; ++j)
                            object.payoutOptions[j] = $root.visor.clientrpc.AdvanceOffer.PayoutOption.toObject(message.payoutOptions[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this AdvanceOffer to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.AdvanceOffer
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AdvanceOffer.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                AdvanceOffer.PayoutOption = (function() {

                    /**
                     * Properties of a PayoutOption.
                     * @memberof visor.clientrpc.AdvanceOffer
                     * @interface IPayoutOption
                     * @property {visor.clientrpc.AdvancePayoutType|null} [type] PayoutOption type
                     * @property {visor.common.money.IAmount|null} [fee] PayoutOption fee
                     * @property {google.protobuf.ITimestamp|null} [estimatedPayoutArrivalDate] PayoutOption estimatedPayoutArrivalDate
                     */

                    /**
                     * Constructs a new PayoutOption.
                     * @memberof visor.clientrpc.AdvanceOffer
                     * @classdesc Represents a PayoutOption.
                     * @implements IPayoutOption
                     * @constructor
                     * @param {visor.clientrpc.AdvanceOffer.IPayoutOption=} [properties] Properties to set
                     */
                    function PayoutOption(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PayoutOption type.
                     * @member {visor.clientrpc.AdvancePayoutType} type
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @instance
                     */
                    PayoutOption.prototype.type = 0;

                    /**
                     * PayoutOption fee.
                     * @member {visor.common.money.IAmount|null|undefined} fee
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @instance
                     */
                    PayoutOption.prototype.fee = null;

                    /**
                     * PayoutOption estimatedPayoutArrivalDate.
                     * @member {google.protobuf.ITimestamp|null|undefined} estimatedPayoutArrivalDate
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @instance
                     */
                    PayoutOption.prototype.estimatedPayoutArrivalDate = null;

                    /**
                     * Creates a new PayoutOption instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {visor.clientrpc.AdvanceOffer.IPayoutOption=} [properties] Properties to set
                     * @returns {visor.clientrpc.AdvanceOffer.PayoutOption} PayoutOption instance
                     */
                    PayoutOption.create = function create(properties) {
                        return new PayoutOption(properties);
                    };

                    /**
                     * Encodes the specified PayoutOption message. Does not implicitly {@link visor.clientrpc.AdvanceOffer.PayoutOption.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {visor.clientrpc.AdvanceOffer.IPayoutOption} message PayoutOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PayoutOption.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && message.hasOwnProperty("type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.fee != null && message.hasOwnProperty("fee"))
                            $root.visor.common.money.Amount.encode(message.fee, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.estimatedPayoutArrivalDate != null && message.hasOwnProperty("estimatedPayoutArrivalDate"))
                            $root.google.protobuf.Timestamp.encode(message.estimatedPayoutArrivalDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PayoutOption message, length delimited. Does not implicitly {@link visor.clientrpc.AdvanceOffer.PayoutOption.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {visor.clientrpc.AdvanceOffer.IPayoutOption} message PayoutOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PayoutOption.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PayoutOption message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.AdvanceOffer.PayoutOption} PayoutOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PayoutOption.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.AdvanceOffer.PayoutOption();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.type = reader.int32();
                                break;
                            case 2:
                                message.fee = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.estimatedPayoutArrivalDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PayoutOption message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.AdvanceOffer.PayoutOption} PayoutOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PayoutOption.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PayoutOption message.
                     * @function verify
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PayoutOption.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.fee != null && message.hasOwnProperty("fee")) {
                            var error = $root.visor.common.money.Amount.verify(message.fee);
                            if (error)
                                return "fee." + error;
                        }
                        if (message.estimatedPayoutArrivalDate != null && message.hasOwnProperty("estimatedPayoutArrivalDate")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.estimatedPayoutArrivalDate);
                            if (error)
                                return "estimatedPayoutArrivalDate." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a PayoutOption message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.AdvanceOffer.PayoutOption} PayoutOption
                     */
                    PayoutOption.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.AdvanceOffer.PayoutOption)
                            return object;
                        var message = new $root.visor.clientrpc.AdvanceOffer.PayoutOption();
                        switch (object.type) {
                        case "ADVANCE_PAYOUT_TYPE_INVALID":
                        case 0:
                            message.type = 0;
                            break;
                        case "ADVANCE_PAYOUT_TYPE_STANDARD":
                        case 1:
                            message.type = 1;
                            break;
                        case "ADVANCE_PAYOUT_TYPE_INSTANT_DEPOSIT":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.fee != null) {
                            if (typeof object.fee !== "object")
                                throw TypeError(".visor.clientrpc.AdvanceOffer.PayoutOption.fee: object expected");
                            message.fee = $root.visor.common.money.Amount.fromObject(object.fee);
                        }
                        if (object.estimatedPayoutArrivalDate != null)
                            if (object.estimatedPayoutArrivalDate === null)
                                message.estimatedPayoutArrivalDate = {};
                            else
                                message.estimatedPayoutArrivalDate = {
                                    seconds: Math.floor(object.estimatedPayoutArrivalDate.getTime() / 1000),
                                    nanos: object.estimatedPayoutArrivalDate.getMilliseconds() * 1000000
                                };
                        return message;
                    };

                    /**
                     * Creates a plain object from a PayoutOption message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @static
                     * @param {visor.clientrpc.AdvanceOffer.PayoutOption} message PayoutOption
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PayoutOption.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.type = options.enums === String ? "ADVANCE_PAYOUT_TYPE_INVALID" : 0;
                            object.fee = null;
                            object.estimatedPayoutArrivalDate = null;
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.visor.clientrpc.AdvancePayoutType[message.type] : message.type;
                        if (message.fee != null && message.hasOwnProperty("fee"))
                            object.fee = $root.visor.common.money.Amount.toObject(message.fee, options);
                        if (message.estimatedPayoutArrivalDate != null && message.hasOwnProperty("estimatedPayoutArrivalDate"))
                            object.estimatedPayoutArrivalDate = new Date(message.estimatedPayoutArrivalDate.seconds * 1000 + message.estimatedPayoutArrivalDate.nanos / 1000000);
                        return object;
                    };

                    /**
                     * Converts this PayoutOption to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.AdvanceOffer.PayoutOption
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PayoutOption.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PayoutOption;
                })();

                return AdvanceOffer;
            })();

            clientrpc.GetCreditLineDebtConsolidationOfferRequest = (function() {

                /**
                 * Properties of a GetCreditLineDebtConsolidationOfferRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditLineDebtConsolidationOfferRequest
                 */

                /**
                 * Constructs a new GetCreditLineDebtConsolidationOfferRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditLineDebtConsolidationOfferRequest.
                 * @implements IGetCreditLineDebtConsolidationOfferRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferRequest=} [properties] Properties to set
                 */
                function GetCreditLineDebtConsolidationOfferRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetCreditLineDebtConsolidationOfferRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest} GetCreditLineDebtConsolidationOfferRequest instance
                 */
                GetCreditLineDebtConsolidationOfferRequest.create = function create(properties) {
                    return new GetCreditLineDebtConsolidationOfferRequest(properties);
                };

                /**
                 * Encodes the specified GetCreditLineDebtConsolidationOfferRequest message. Does not implicitly {@link visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferRequest} message GetCreditLineDebtConsolidationOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineDebtConsolidationOfferRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditLineDebtConsolidationOfferRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferRequest} message GetCreditLineDebtConsolidationOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineDebtConsolidationOfferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditLineDebtConsolidationOfferRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest} GetCreditLineDebtConsolidationOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineDebtConsolidationOfferRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditLineDebtConsolidationOfferRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest} GetCreditLineDebtConsolidationOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineDebtConsolidationOfferRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditLineDebtConsolidationOfferRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditLineDebtConsolidationOfferRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetCreditLineDebtConsolidationOfferRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest} GetCreditLineDebtConsolidationOfferRequest
                 */
                GetCreditLineDebtConsolidationOfferRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest)
                        return object;
                    return new $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest();
                };

                /**
                 * Creates a plain object from a GetCreditLineDebtConsolidationOfferRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @static
                 * @param {visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest} message GetCreditLineDebtConsolidationOfferRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditLineDebtConsolidationOfferRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetCreditLineDebtConsolidationOfferRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditLineDebtConsolidationOfferRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditLineDebtConsolidationOfferRequest;
            })();

            clientrpc.GetCreditLineDebtConsolidationOfferResponse = (function() {

                /**
                 * Properties of a GetCreditLineDebtConsolidationOfferResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditLineDebtConsolidationOfferResponse
                 * @property {visor.common.money.IAmount|null} [creditLineInitialBalanceAmount] GetCreditLineDebtConsolidationOfferResponse creditLineInitialBalanceAmount
                 * @property {visor.common.money.IAmount|null} [advanceAmount] GetCreditLineDebtConsolidationOfferResponse advanceAmount
                 * @property {google.protobuf.ITimestamp|null} [advanceDeliveryDate] GetCreditLineDebtConsolidationOfferResponse advanceDeliveryDate
                 * @property {string|null} [advanceBankAccount] GetCreditLineDebtConsolidationOfferResponse advanceBankAccount
                 * @property {visor.clientrpc.ICreditLineConsolidationGridSubscription|null} [gridSubscription] GetCreditLineDebtConsolidationOfferResponse gridSubscription
                 * @property {visor.common.money.IAmount|null} [gridSubscriptionReactivationFeeAmount] GetCreditLineDebtConsolidationOfferResponse gridSubscriptionReactivationFeeAmount
                 * @property {visor.common.money.IAmount|null} [creditLineRemainingBalanceAmount] GetCreditLineDebtConsolidationOfferResponse creditLineRemainingBalanceAmount
                 * @property {google.protobuf.ITimestamp|null} [creditLineFirstPaymentDue] GetCreditLineDebtConsolidationOfferResponse creditLineFirstPaymentDue
                 */

                /**
                 * Constructs a new GetCreditLineDebtConsolidationOfferResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditLineDebtConsolidationOfferResponse.
                 * @implements IGetCreditLineDebtConsolidationOfferResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferResponse=} [properties] Properties to set
                 */
                function GetCreditLineDebtConsolidationOfferResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditLineDebtConsolidationOfferResponse creditLineInitialBalanceAmount.
                 * @member {visor.common.money.IAmount|null|undefined} creditLineInitialBalanceAmount
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.creditLineInitialBalanceAmount = null;

                /**
                 * GetCreditLineDebtConsolidationOfferResponse advanceAmount.
                 * @member {visor.common.money.IAmount|null|undefined} advanceAmount
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.advanceAmount = null;

                /**
                 * GetCreditLineDebtConsolidationOfferResponse advanceDeliveryDate.
                 * @member {google.protobuf.ITimestamp|null|undefined} advanceDeliveryDate
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.advanceDeliveryDate = null;

                /**
                 * GetCreditLineDebtConsolidationOfferResponse advanceBankAccount.
                 * @member {string} advanceBankAccount
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.advanceBankAccount = "";

                /**
                 * GetCreditLineDebtConsolidationOfferResponse gridSubscription.
                 * @member {visor.clientrpc.ICreditLineConsolidationGridSubscription|null|undefined} gridSubscription
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.gridSubscription = null;

                /**
                 * GetCreditLineDebtConsolidationOfferResponse gridSubscriptionReactivationFeeAmount.
                 * @member {visor.common.money.IAmount|null|undefined} gridSubscriptionReactivationFeeAmount
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.gridSubscriptionReactivationFeeAmount = null;

                /**
                 * GetCreditLineDebtConsolidationOfferResponse creditLineRemainingBalanceAmount.
                 * @member {visor.common.money.IAmount|null|undefined} creditLineRemainingBalanceAmount
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.creditLineRemainingBalanceAmount = null;

                /**
                 * GetCreditLineDebtConsolidationOfferResponse creditLineFirstPaymentDue.
                 * @member {google.protobuf.ITimestamp|null|undefined} creditLineFirstPaymentDue
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.creditLineFirstPaymentDue = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * GetCreditLineDebtConsolidationOfferResponse subscriptionType.
                 * @member {"gridSubscription"|"gridSubscriptionReactivationFeeAmount"|undefined} subscriptionType
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 */
                Object.defineProperty(GetCreditLineDebtConsolidationOfferResponse.prototype, "subscriptionType", {
                    get: $util.oneOfGetter($oneOfFields = ["gridSubscription", "gridSubscriptionReactivationFeeAmount"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new GetCreditLineDebtConsolidationOfferResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse} GetCreditLineDebtConsolidationOfferResponse instance
                 */
                GetCreditLineDebtConsolidationOfferResponse.create = function create(properties) {
                    return new GetCreditLineDebtConsolidationOfferResponse(properties);
                };

                /**
                 * Encodes the specified GetCreditLineDebtConsolidationOfferResponse message. Does not implicitly {@link visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferResponse} message GetCreditLineDebtConsolidationOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineDebtConsolidationOfferResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.creditLineInitialBalanceAmount != null && message.hasOwnProperty("creditLineInitialBalanceAmount"))
                        $root.visor.common.money.Amount.encode(message.creditLineInitialBalanceAmount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.advanceAmount != null && message.hasOwnProperty("advanceAmount"))
                        $root.visor.common.money.Amount.encode(message.advanceAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.advanceDeliveryDate != null && message.hasOwnProperty("advanceDeliveryDate"))
                        $root.google.protobuf.Timestamp.encode(message.advanceDeliveryDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.advanceBankAccount != null && message.hasOwnProperty("advanceBankAccount"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.advanceBankAccount);
                    if (message.gridSubscription != null && message.hasOwnProperty("gridSubscription"))
                        $root.visor.clientrpc.CreditLineConsolidationGridSubscription.encode(message.gridSubscription, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.gridSubscriptionReactivationFeeAmount != null && message.hasOwnProperty("gridSubscriptionReactivationFeeAmount"))
                        $root.visor.common.money.Amount.encode(message.gridSubscriptionReactivationFeeAmount, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.creditLineRemainingBalanceAmount != null && message.hasOwnProperty("creditLineRemainingBalanceAmount"))
                        $root.visor.common.money.Amount.encode(message.creditLineRemainingBalanceAmount, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.creditLineFirstPaymentDue != null && message.hasOwnProperty("creditLineFirstPaymentDue"))
                        $root.google.protobuf.Timestamp.encode(message.creditLineFirstPaymentDue, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditLineDebtConsolidationOfferResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditLineDebtConsolidationOfferResponse} message GetCreditLineDebtConsolidationOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditLineDebtConsolidationOfferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditLineDebtConsolidationOfferResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse} GetCreditLineDebtConsolidationOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineDebtConsolidationOfferResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.creditLineInitialBalanceAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.advanceAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.advanceDeliveryDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.advanceBankAccount = reader.string();
                            break;
                        case 5:
                            message.gridSubscription = $root.visor.clientrpc.CreditLineConsolidationGridSubscription.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.gridSubscriptionReactivationFeeAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.creditLineRemainingBalanceAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 8:
                            message.creditLineFirstPaymentDue = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditLineDebtConsolidationOfferResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse} GetCreditLineDebtConsolidationOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditLineDebtConsolidationOfferResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditLineDebtConsolidationOfferResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditLineDebtConsolidationOfferResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.creditLineInitialBalanceAmount != null && message.hasOwnProperty("creditLineInitialBalanceAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.creditLineInitialBalanceAmount);
                        if (error)
                            return "creditLineInitialBalanceAmount." + error;
                    }
                    if (message.advanceAmount != null && message.hasOwnProperty("advanceAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.advanceAmount);
                        if (error)
                            return "advanceAmount." + error;
                    }
                    if (message.advanceDeliveryDate != null && message.hasOwnProperty("advanceDeliveryDate")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.advanceDeliveryDate);
                        if (error)
                            return "advanceDeliveryDate." + error;
                    }
                    if (message.advanceBankAccount != null && message.hasOwnProperty("advanceBankAccount"))
                        if (!$util.isString(message.advanceBankAccount))
                            return "advanceBankAccount: string expected";
                    if (message.gridSubscription != null && message.hasOwnProperty("gridSubscription")) {
                        properties.subscriptionType = 1;
                        {
                            var error = $root.visor.clientrpc.CreditLineConsolidationGridSubscription.verify(message.gridSubscription);
                            if (error)
                                return "gridSubscription." + error;
                        }
                    }
                    if (message.gridSubscriptionReactivationFeeAmount != null && message.hasOwnProperty("gridSubscriptionReactivationFeeAmount")) {
                        if (properties.subscriptionType === 1)
                            return "subscriptionType: multiple values";
                        properties.subscriptionType = 1;
                        {
                            var error = $root.visor.common.money.Amount.verify(message.gridSubscriptionReactivationFeeAmount);
                            if (error)
                                return "gridSubscriptionReactivationFeeAmount." + error;
                        }
                    }
                    if (message.creditLineRemainingBalanceAmount != null && message.hasOwnProperty("creditLineRemainingBalanceAmount")) {
                        var error = $root.visor.common.money.Amount.verify(message.creditLineRemainingBalanceAmount);
                        if (error)
                            return "creditLineRemainingBalanceAmount." + error;
                    }
                    if (message.creditLineFirstPaymentDue != null && message.hasOwnProperty("creditLineFirstPaymentDue")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.creditLineFirstPaymentDue);
                        if (error)
                            return "creditLineFirstPaymentDue." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetCreditLineDebtConsolidationOfferResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse} GetCreditLineDebtConsolidationOfferResponse
                 */
                GetCreditLineDebtConsolidationOfferResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse();
                    if (object.creditLineInitialBalanceAmount != null) {
                        if (typeof object.creditLineInitialBalanceAmount !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse.creditLineInitialBalanceAmount: object expected");
                        message.creditLineInitialBalanceAmount = $root.visor.common.money.Amount.fromObject(object.creditLineInitialBalanceAmount);
                    }
                    if (object.advanceAmount != null) {
                        if (typeof object.advanceAmount !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse.advanceAmount: object expected");
                        message.advanceAmount = $root.visor.common.money.Amount.fromObject(object.advanceAmount);
                    }
                    if (object.advanceDeliveryDate != null)
                        if (object.advanceDeliveryDate === null)
                            message.advanceDeliveryDate = {};
                        else
                            message.advanceDeliveryDate = {
                                seconds: Math.floor(object.advanceDeliveryDate.getTime() / 1000),
                                nanos: object.advanceDeliveryDate.getMilliseconds() * 1000000
                            };
                    if (object.advanceBankAccount != null)
                        message.advanceBankAccount = String(object.advanceBankAccount);
                    if (object.gridSubscription != null) {
                        if (typeof object.gridSubscription !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse.gridSubscription: object expected");
                        message.gridSubscription = $root.visor.clientrpc.CreditLineConsolidationGridSubscription.fromObject(object.gridSubscription);
                    }
                    if (object.gridSubscriptionReactivationFeeAmount != null) {
                        if (typeof object.gridSubscriptionReactivationFeeAmount !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse.gridSubscriptionReactivationFeeAmount: object expected");
                        message.gridSubscriptionReactivationFeeAmount = $root.visor.common.money.Amount.fromObject(object.gridSubscriptionReactivationFeeAmount);
                    }
                    if (object.creditLineRemainingBalanceAmount != null) {
                        if (typeof object.creditLineRemainingBalanceAmount !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse.creditLineRemainingBalanceAmount: object expected");
                        message.creditLineRemainingBalanceAmount = $root.visor.common.money.Amount.fromObject(object.creditLineRemainingBalanceAmount);
                    }
                    if (object.creditLineFirstPaymentDue != null)
                        if (object.creditLineFirstPaymentDue === null)
                            message.creditLineFirstPaymentDue = {};
                        else
                            message.creditLineFirstPaymentDue = {
                                seconds: Math.floor(object.creditLineFirstPaymentDue.getTime() / 1000),
                                nanos: object.creditLineFirstPaymentDue.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditLineDebtConsolidationOfferResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @static
                 * @param {visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse} message GetCreditLineDebtConsolidationOfferResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditLineDebtConsolidationOfferResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.creditLineInitialBalanceAmount = null;
                        object.advanceAmount = null;
                        object.advanceDeliveryDate = null;
                        object.advanceBankAccount = "";
                        object.creditLineRemainingBalanceAmount = null;
                        object.creditLineFirstPaymentDue = null;
                    }
                    if (message.creditLineInitialBalanceAmount != null && message.hasOwnProperty("creditLineInitialBalanceAmount"))
                        object.creditLineInitialBalanceAmount = $root.visor.common.money.Amount.toObject(message.creditLineInitialBalanceAmount, options);
                    if (message.advanceAmount != null && message.hasOwnProperty("advanceAmount"))
                        object.advanceAmount = $root.visor.common.money.Amount.toObject(message.advanceAmount, options);
                    if (message.advanceDeliveryDate != null && message.hasOwnProperty("advanceDeliveryDate"))
                        object.advanceDeliveryDate = new Date(message.advanceDeliveryDate.seconds * 1000 + message.advanceDeliveryDate.nanos / 1000000);
                    if (message.advanceBankAccount != null && message.hasOwnProperty("advanceBankAccount"))
                        object.advanceBankAccount = message.advanceBankAccount;
                    if (message.gridSubscription != null && message.hasOwnProperty("gridSubscription")) {
                        object.gridSubscription = $root.visor.clientrpc.CreditLineConsolidationGridSubscription.toObject(message.gridSubscription, options);
                        if (options.oneofs)
                            object.subscriptionType = "gridSubscription";
                    }
                    if (message.gridSubscriptionReactivationFeeAmount != null && message.hasOwnProperty("gridSubscriptionReactivationFeeAmount")) {
                        object.gridSubscriptionReactivationFeeAmount = $root.visor.common.money.Amount.toObject(message.gridSubscriptionReactivationFeeAmount, options);
                        if (options.oneofs)
                            object.subscriptionType = "gridSubscriptionReactivationFeeAmount";
                    }
                    if (message.creditLineRemainingBalanceAmount != null && message.hasOwnProperty("creditLineRemainingBalanceAmount"))
                        object.creditLineRemainingBalanceAmount = $root.visor.common.money.Amount.toObject(message.creditLineRemainingBalanceAmount, options);
                    if (message.creditLineFirstPaymentDue != null && message.hasOwnProperty("creditLineFirstPaymentDue"))
                        object.creditLineFirstPaymentDue = new Date(message.creditLineFirstPaymentDue.seconds * 1000 + message.creditLineFirstPaymentDue.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this GetCreditLineDebtConsolidationOfferResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditLineDebtConsolidationOfferResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditLineDebtConsolidationOfferResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditLineDebtConsolidationOfferResponse;
            })();

            clientrpc.CreditLineConsolidationGridSubscription = (function() {

                /**
                 * Properties of a CreditLineConsolidationGridSubscription.
                 * @memberof visor.clientrpc
                 * @interface ICreditLineConsolidationGridSubscription
                 * @property {visor.common.money.IAmount|null} [amount] CreditLineConsolidationGridSubscription amount
                 * @property {google.protobuf.ITimestamp|null} [billingDate] CreditLineConsolidationGridSubscription billingDate
                 */

                /**
                 * Constructs a new CreditLineConsolidationGridSubscription.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreditLineConsolidationGridSubscription.
                 * @implements ICreditLineConsolidationGridSubscription
                 * @constructor
                 * @param {visor.clientrpc.ICreditLineConsolidationGridSubscription=} [properties] Properties to set
                 */
                function CreditLineConsolidationGridSubscription(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreditLineConsolidationGridSubscription amount.
                 * @member {visor.common.money.IAmount|null|undefined} amount
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @instance
                 */
                CreditLineConsolidationGridSubscription.prototype.amount = null;

                /**
                 * CreditLineConsolidationGridSubscription billingDate.
                 * @member {google.protobuf.ITimestamp|null|undefined} billingDate
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @instance
                 */
                CreditLineConsolidationGridSubscription.prototype.billingDate = null;

                /**
                 * Creates a new CreditLineConsolidationGridSubscription instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {visor.clientrpc.ICreditLineConsolidationGridSubscription=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreditLineConsolidationGridSubscription} CreditLineConsolidationGridSubscription instance
                 */
                CreditLineConsolidationGridSubscription.create = function create(properties) {
                    return new CreditLineConsolidationGridSubscription(properties);
                };

                /**
                 * Encodes the specified CreditLineConsolidationGridSubscription message. Does not implicitly {@link visor.clientrpc.CreditLineConsolidationGridSubscription.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {visor.clientrpc.ICreditLineConsolidationGridSubscription} message CreditLineConsolidationGridSubscription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreditLineConsolidationGridSubscription.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.billingDate != null && message.hasOwnProperty("billingDate"))
                        $root.google.protobuf.Timestamp.encode(message.billingDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreditLineConsolidationGridSubscription message, length delimited. Does not implicitly {@link visor.clientrpc.CreditLineConsolidationGridSubscription.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {visor.clientrpc.ICreditLineConsolidationGridSubscription} message CreditLineConsolidationGridSubscription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreditLineConsolidationGridSubscription.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreditLineConsolidationGridSubscription message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreditLineConsolidationGridSubscription} CreditLineConsolidationGridSubscription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreditLineConsolidationGridSubscription.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreditLineConsolidationGridSubscription();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.billingDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreditLineConsolidationGridSubscription message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreditLineConsolidationGridSubscription} CreditLineConsolidationGridSubscription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreditLineConsolidationGridSubscription.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreditLineConsolidationGridSubscription message.
                 * @function verify
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreditLineConsolidationGridSubscription.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.visor.common.money.Amount.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    if (message.billingDate != null && message.hasOwnProperty("billingDate")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.billingDate);
                        if (error)
                            return "billingDate." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreditLineConsolidationGridSubscription message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreditLineConsolidationGridSubscription} CreditLineConsolidationGridSubscription
                 */
                CreditLineConsolidationGridSubscription.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreditLineConsolidationGridSubscription)
                        return object;
                    var message = new $root.visor.clientrpc.CreditLineConsolidationGridSubscription();
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".visor.clientrpc.CreditLineConsolidationGridSubscription.amount: object expected");
                        message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                    }
                    if (object.billingDate != null)
                        if (object.billingDate === null)
                            message.billingDate = {};
                        else
                            message.billingDate = {
                                seconds: Math.floor(object.billingDate.getTime() / 1000),
                                nanos: object.billingDate.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a CreditLineConsolidationGridSubscription message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @static
                 * @param {visor.clientrpc.CreditLineConsolidationGridSubscription} message CreditLineConsolidationGridSubscription
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreditLineConsolidationGridSubscription.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.amount = null;
                        object.billingDate = null;
                    }
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                    if (message.billingDate != null && message.hasOwnProperty("billingDate"))
                        object.billingDate = new Date(message.billingDate.seconds * 1000 + message.billingDate.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this CreditLineConsolidationGridSubscription to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreditLineConsolidationGridSubscription
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreditLineConsolidationGridSubscription.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreditLineConsolidationGridSubscription;
            })();

            clientrpc.GetCashBoostCheckoutOfferRequest = (function() {

                /**
                 * Properties of a GetCashBoostCheckoutOfferRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCashBoostCheckoutOfferRequest
                 */

                /**
                 * Constructs a new GetCashBoostCheckoutOfferRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCashBoostCheckoutOfferRequest.
                 * @implements IGetCashBoostCheckoutOfferRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferRequest=} [properties] Properties to set
                 */
                function GetCashBoostCheckoutOfferRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetCashBoostCheckoutOfferRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferRequest} GetCashBoostCheckoutOfferRequest instance
                 */
                GetCashBoostCheckoutOfferRequest.create = function create(properties) {
                    return new GetCashBoostCheckoutOfferRequest(properties);
                };

                /**
                 * Encodes the specified GetCashBoostCheckoutOfferRequest message. Does not implicitly {@link visor.clientrpc.GetCashBoostCheckoutOfferRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferRequest} message GetCashBoostCheckoutOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostCheckoutOfferRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetCashBoostCheckoutOfferRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCashBoostCheckoutOfferRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferRequest} message GetCashBoostCheckoutOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostCheckoutOfferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCashBoostCheckoutOfferRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferRequest} GetCashBoostCheckoutOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostCheckoutOfferRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCashBoostCheckoutOfferRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCashBoostCheckoutOfferRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferRequest} GetCashBoostCheckoutOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostCheckoutOfferRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCashBoostCheckoutOfferRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCashBoostCheckoutOfferRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetCashBoostCheckoutOfferRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferRequest} GetCashBoostCheckoutOfferRequest
                 */
                GetCashBoostCheckoutOfferRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCashBoostCheckoutOfferRequest)
                        return object;
                    return new $root.visor.clientrpc.GetCashBoostCheckoutOfferRequest();
                };

                /**
                 * Creates a plain object from a GetCashBoostCheckoutOfferRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.GetCashBoostCheckoutOfferRequest} message GetCashBoostCheckoutOfferRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCashBoostCheckoutOfferRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetCashBoostCheckoutOfferRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCashBoostCheckoutOfferRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCashBoostCheckoutOfferRequest;
            })();

            clientrpc.GetCashBoostCheckoutOfferResponse = (function() {

                /**
                 * Properties of a GetCashBoostCheckoutOfferResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCashBoostCheckoutOfferResponse
                 * @property {visor.clientrpc.IAdvanceOffer|null} [advanceOffer] GetCashBoostCheckoutOfferResponse advanceOffer
                 * @property {visor.common.time.ILocalDate|null} [repaymentDate] GetCashBoostCheckoutOfferResponse repaymentDate
                 * @property {string|null} [bankAccountToken] GetCashBoostCheckoutOfferResponse bankAccountToken
                 * @property {string|null} [bankAccountTitle] GetCashBoostCheckoutOfferResponse bankAccountTitle
                 * @property {string|null} [bankAccountLastFour] GetCashBoostCheckoutOfferResponse bankAccountLastFour
                 */

                /**
                 * Constructs a new GetCashBoostCheckoutOfferResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCashBoostCheckoutOfferResponse.
                 * @implements IGetCashBoostCheckoutOfferResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferResponse=} [properties] Properties to set
                 */
                function GetCashBoostCheckoutOfferResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCashBoostCheckoutOfferResponse advanceOffer.
                 * @member {visor.clientrpc.IAdvanceOffer|null|undefined} advanceOffer
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @instance
                 */
                GetCashBoostCheckoutOfferResponse.prototype.advanceOffer = null;

                /**
                 * GetCashBoostCheckoutOfferResponse repaymentDate.
                 * @member {visor.common.time.ILocalDate|null|undefined} repaymentDate
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @instance
                 */
                GetCashBoostCheckoutOfferResponse.prototype.repaymentDate = null;

                /**
                 * GetCashBoostCheckoutOfferResponse bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @instance
                 */
                GetCashBoostCheckoutOfferResponse.prototype.bankAccountToken = "";

                /**
                 * GetCashBoostCheckoutOfferResponse bankAccountTitle.
                 * @member {string} bankAccountTitle
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @instance
                 */
                GetCashBoostCheckoutOfferResponse.prototype.bankAccountTitle = "";

                /**
                 * GetCashBoostCheckoutOfferResponse bankAccountLastFour.
                 * @member {string} bankAccountLastFour
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @instance
                 */
                GetCashBoostCheckoutOfferResponse.prototype.bankAccountLastFour = "";

                /**
                 * Creates a new GetCashBoostCheckoutOfferResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferResponse} GetCashBoostCheckoutOfferResponse instance
                 */
                GetCashBoostCheckoutOfferResponse.create = function create(properties) {
                    return new GetCashBoostCheckoutOfferResponse(properties);
                };

                /**
                 * Encodes the specified GetCashBoostCheckoutOfferResponse message. Does not implicitly {@link visor.clientrpc.GetCashBoostCheckoutOfferResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferResponse} message GetCashBoostCheckoutOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostCheckoutOfferResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.advanceOffer != null && message.hasOwnProperty("advanceOffer"))
                        $root.visor.clientrpc.AdvanceOffer.encode(message.advanceOffer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.repaymentDate != null && message.hasOwnProperty("repaymentDate"))
                        $root.visor.common.time.LocalDate.encode(message.repaymentDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.bankAccountToken);
                    if (message.bankAccountTitle != null && message.hasOwnProperty("bankAccountTitle"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.bankAccountTitle);
                    if (message.bankAccountLastFour != null && message.hasOwnProperty("bankAccountLastFour"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.bankAccountLastFour);
                    return writer;
                };

                /**
                 * Encodes the specified GetCashBoostCheckoutOfferResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCashBoostCheckoutOfferResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCashBoostCheckoutOfferResponse} message GetCashBoostCheckoutOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCashBoostCheckoutOfferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCashBoostCheckoutOfferResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferResponse} GetCashBoostCheckoutOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostCheckoutOfferResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCashBoostCheckoutOfferResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.advanceOffer = $root.visor.clientrpc.AdvanceOffer.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.repaymentDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.bankAccountToken = reader.string();
                            break;
                        case 4:
                            message.bankAccountTitle = reader.string();
                            break;
                        case 5:
                            message.bankAccountLastFour = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCashBoostCheckoutOfferResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferResponse} GetCashBoostCheckoutOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCashBoostCheckoutOfferResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCashBoostCheckoutOfferResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCashBoostCheckoutOfferResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.advanceOffer != null && message.hasOwnProperty("advanceOffer")) {
                        var error = $root.visor.clientrpc.AdvanceOffer.verify(message.advanceOffer);
                        if (error)
                            return "advanceOffer." + error;
                    }
                    if (message.repaymentDate != null && message.hasOwnProperty("repaymentDate")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.repaymentDate);
                        if (error)
                            return "repaymentDate." + error;
                    }
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    if (message.bankAccountTitle != null && message.hasOwnProperty("bankAccountTitle"))
                        if (!$util.isString(message.bankAccountTitle))
                            return "bankAccountTitle: string expected";
                    if (message.bankAccountLastFour != null && message.hasOwnProperty("bankAccountLastFour"))
                        if (!$util.isString(message.bankAccountLastFour))
                            return "bankAccountLastFour: string expected";
                    return null;
                };

                /**
                 * Creates a GetCashBoostCheckoutOfferResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCashBoostCheckoutOfferResponse} GetCashBoostCheckoutOfferResponse
                 */
                GetCashBoostCheckoutOfferResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCashBoostCheckoutOfferResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCashBoostCheckoutOfferResponse();
                    if (object.advanceOffer != null) {
                        if (typeof object.advanceOffer !== "object")
                            throw TypeError(".visor.clientrpc.GetCashBoostCheckoutOfferResponse.advanceOffer: object expected");
                        message.advanceOffer = $root.visor.clientrpc.AdvanceOffer.fromObject(object.advanceOffer);
                    }
                    if (object.repaymentDate != null) {
                        if (typeof object.repaymentDate !== "object")
                            throw TypeError(".visor.clientrpc.GetCashBoostCheckoutOfferResponse.repaymentDate: object expected");
                        message.repaymentDate = $root.visor.common.time.LocalDate.fromObject(object.repaymentDate);
                    }
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    if (object.bankAccountTitle != null)
                        message.bankAccountTitle = String(object.bankAccountTitle);
                    if (object.bankAccountLastFour != null)
                        message.bankAccountLastFour = String(object.bankAccountLastFour);
                    return message;
                };

                /**
                 * Creates a plain object from a GetCashBoostCheckoutOfferResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.GetCashBoostCheckoutOfferResponse} message GetCashBoostCheckoutOfferResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCashBoostCheckoutOfferResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.advanceOffer = null;
                        object.repaymentDate = null;
                        object.bankAccountToken = "";
                        object.bankAccountTitle = "";
                        object.bankAccountLastFour = "";
                    }
                    if (message.advanceOffer != null && message.hasOwnProperty("advanceOffer"))
                        object.advanceOffer = $root.visor.clientrpc.AdvanceOffer.toObject(message.advanceOffer, options);
                    if (message.repaymentDate != null && message.hasOwnProperty("repaymentDate"))
                        object.repaymentDate = $root.visor.common.time.LocalDate.toObject(message.repaymentDate, options);
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    if (message.bankAccountTitle != null && message.hasOwnProperty("bankAccountTitle"))
                        object.bankAccountTitle = message.bankAccountTitle;
                    if (message.bankAccountLastFour != null && message.hasOwnProperty("bankAccountLastFour"))
                        object.bankAccountLastFour = message.bankAccountLastFour;
                    return object;
                };

                /**
                 * Converts this GetCashBoostCheckoutOfferResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCashBoostCheckoutOfferResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCashBoostCheckoutOfferResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCashBoostCheckoutOfferResponse;
            })();

            clientrpc.CompleteCashBoostAcceptStepRequest = (function() {

                /**
                 * Properties of a CompleteCashBoostAcceptStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCashBoostAcceptStepRequest
                 * @property {string|null} [stepToken] CompleteCashBoostAcceptStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteCashBoostAcceptStepRequest workflowToken
                 * @property {google.protobuf.ITimestamp|null} [tosAcceptanceTime] CompleteCashBoostAcceptStepRequest tosAcceptanceTime
                 * @property {string|null} [cashboostToken] CompleteCashBoostAcceptStepRequest cashboostToken
                 * @property {string|null} [advanceOfferToken] CompleteCashBoostAcceptStepRequest advanceOfferToken
                 * @property {visor.clientrpc.AdvancePayoutType|null} [payoutType] CompleteCashBoostAcceptStepRequest payoutType
                 */

                /**
                 * Constructs a new CompleteCashBoostAcceptStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCashBoostAcceptStepRequest.
                 * @implements ICompleteCashBoostAcceptStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepRequest=} [properties] Properties to set
                 */
                function CompleteCashBoostAcceptStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteCashBoostAcceptStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteCashBoostAcceptStepRequest.prototype.stepToken = "";

                /**
                 * CompleteCashBoostAcceptStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteCashBoostAcceptStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteCashBoostAcceptStepRequest tosAcceptanceTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} tosAcceptanceTime
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteCashBoostAcceptStepRequest.prototype.tosAcceptanceTime = null;

                /**
                 * CompleteCashBoostAcceptStepRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteCashBoostAcceptStepRequest.prototype.cashboostToken = "";

                /**
                 * CompleteCashBoostAcceptStepRequest advanceOfferToken.
                 * @member {string} advanceOfferToken
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteCashBoostAcceptStepRequest.prototype.advanceOfferToken = "";

                /**
                 * CompleteCashBoostAcceptStepRequest payoutType.
                 * @member {visor.clientrpc.AdvancePayoutType} payoutType
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteCashBoostAcceptStepRequest.prototype.payoutType = 0;

                /**
                 * Creates a new CompleteCashBoostAcceptStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepRequest} CompleteCashBoostAcceptStepRequest instance
                 */
                CompleteCashBoostAcceptStepRequest.create = function create(properties) {
                    return new CompleteCashBoostAcceptStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteCashBoostAcceptStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteCashBoostAcceptStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepRequest} message CompleteCashBoostAcceptStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostAcceptStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.tosAcceptanceTime != null && message.hasOwnProperty("tosAcceptanceTime"))
                        $root.google.protobuf.Timestamp.encode(message.tosAcceptanceTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.cashboostToken);
                    if (message.advanceOfferToken != null && message.hasOwnProperty("advanceOfferToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.advanceOfferToken);
                    if (message.payoutType != null && message.hasOwnProperty("payoutType"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.payoutType);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCashBoostAcceptStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCashBoostAcceptStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepRequest} message CompleteCashBoostAcceptStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostAcceptStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCashBoostAcceptStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepRequest} CompleteCashBoostAcceptStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostAcceptStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCashBoostAcceptStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.tosAcceptanceTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.cashboostToken = reader.string();
                            break;
                        case 5:
                            message.advanceOfferToken = reader.string();
                            break;
                        case 6:
                            message.payoutType = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCashBoostAcceptStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepRequest} CompleteCashBoostAcceptStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostAcceptStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCashBoostAcceptStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCashBoostAcceptStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.tosAcceptanceTime != null && message.hasOwnProperty("tosAcceptanceTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.tosAcceptanceTime);
                        if (error)
                            return "tosAcceptanceTime." + error;
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.advanceOfferToken != null && message.hasOwnProperty("advanceOfferToken"))
                        if (!$util.isString(message.advanceOfferToken))
                            return "advanceOfferToken: string expected";
                    if (message.payoutType != null && message.hasOwnProperty("payoutType"))
                        switch (message.payoutType) {
                        default:
                            return "payoutType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a CompleteCashBoostAcceptStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepRequest} CompleteCashBoostAcceptStepRequest
                 */
                CompleteCashBoostAcceptStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCashBoostAcceptStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteCashBoostAcceptStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.tosAcceptanceTime != null)
                        if (object.tosAcceptanceTime === null)
                            message.tosAcceptanceTime = {};
                        else
                            message.tosAcceptanceTime = {
                                seconds: Math.floor(object.tosAcceptanceTime.getTime() / 1000),
                                nanos: object.tosAcceptanceTime.getMilliseconds() * 1000000
                            };
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.advanceOfferToken != null)
                        message.advanceOfferToken = String(object.advanceOfferToken);
                    switch (object.payoutType) {
                    case "ADVANCE_PAYOUT_TYPE_INVALID":
                    case 0:
                        message.payoutType = 0;
                        break;
                    case "ADVANCE_PAYOUT_TYPE_STANDARD":
                    case 1:
                        message.payoutType = 1;
                        break;
                    case "ADVANCE_PAYOUT_TYPE_INSTANT_DEPOSIT":
                    case 2:
                        message.payoutType = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteCashBoostAcceptStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteCashBoostAcceptStepRequest} message CompleteCashBoostAcceptStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCashBoostAcceptStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.tosAcceptanceTime = null;
                        object.cashboostToken = "";
                        object.advanceOfferToken = "";
                        object.payoutType = options.enums === String ? "ADVANCE_PAYOUT_TYPE_INVALID" : 0;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.tosAcceptanceTime != null && message.hasOwnProperty("tosAcceptanceTime"))
                        object.tosAcceptanceTime = new Date(message.tosAcceptanceTime.seconds * 1000 + message.tosAcceptanceTime.nanos / 1000000);
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.advanceOfferToken != null && message.hasOwnProperty("advanceOfferToken"))
                        object.advanceOfferToken = message.advanceOfferToken;
                    if (message.payoutType != null && message.hasOwnProperty("payoutType"))
                        object.payoutType = options.enums === String ? $root.visor.clientrpc.AdvancePayoutType[message.payoutType] : message.payoutType;
                    return object;
                };

                /**
                 * Converts this CompleteCashBoostAcceptStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCashBoostAcceptStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCashBoostAcceptStepRequest;
            })();

            clientrpc.CompleteCashBoostAcceptStepResponse = (function() {

                /**
                 * Properties of a CompleteCashBoostAcceptStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCashBoostAcceptStepResponse
                 */

                /**
                 * Constructs a new CompleteCashBoostAcceptStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCashBoostAcceptStepResponse.
                 * @implements ICompleteCashBoostAcceptStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepResponse=} [properties] Properties to set
                 */
                function CompleteCashBoostAcceptStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteCashBoostAcceptStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepResponse} CompleteCashBoostAcceptStepResponse instance
                 */
                CompleteCashBoostAcceptStepResponse.create = function create(properties) {
                    return new CompleteCashBoostAcceptStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteCashBoostAcceptStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteCashBoostAcceptStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepResponse} message CompleteCashBoostAcceptStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostAcceptStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCashBoostAcceptStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCashBoostAcceptStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostAcceptStepResponse} message CompleteCashBoostAcceptStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostAcceptStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCashBoostAcceptStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepResponse} CompleteCashBoostAcceptStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostAcceptStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCashBoostAcceptStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCashBoostAcceptStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepResponse} CompleteCashBoostAcceptStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostAcceptStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCashBoostAcceptStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCashBoostAcceptStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteCashBoostAcceptStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCashBoostAcceptStepResponse} CompleteCashBoostAcceptStepResponse
                 */
                CompleteCashBoostAcceptStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCashBoostAcceptStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteCashBoostAcceptStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteCashBoostAcceptStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteCashBoostAcceptStepResponse} message CompleteCashBoostAcceptStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCashBoostAcceptStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteCashBoostAcceptStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCashBoostAcceptStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCashBoostAcceptStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCashBoostAcceptStepResponse;
            })();

            clientrpc.CompleteSubsequentCashBoostAcceptStepRequest = (function() {

                /**
                 * Properties of a CompleteSubsequentCashBoostAcceptStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSubsequentCashBoostAcceptStepRequest
                 * @property {string|null} [stepToken] CompleteSubsequentCashBoostAcceptStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteSubsequentCashBoostAcceptStepRequest workflowToken
                 * @property {string|null} [cashboostToken] CompleteSubsequentCashBoostAcceptStepRequest cashboostToken
                 * @property {google.protobuf.ITimestamp|null} [tosAcceptanceTime] CompleteSubsequentCashBoostAcceptStepRequest tosAcceptanceTime
                 * @property {string|null} [advanceOfferToken] CompleteSubsequentCashBoostAcceptStepRequest advanceOfferToken
                 * @property {visor.clientrpc.AdvancePayoutType|null} [payoutType] CompleteSubsequentCashBoostAcceptStepRequest payoutType
                 */

                /**
                 * Constructs a new CompleteSubsequentCashBoostAcceptStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSubsequentCashBoostAcceptStepRequest.
                 * @implements ICompleteSubsequentCashBoostAcceptStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepRequest=} [properties] Properties to set
                 */
                function CompleteSubsequentCashBoostAcceptStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteSubsequentCashBoostAcceptStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.prototype.stepToken = "";

                /**
                 * CompleteSubsequentCashBoostAcceptStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteSubsequentCashBoostAcceptStepRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.prototype.cashboostToken = "";

                /**
                 * CompleteSubsequentCashBoostAcceptStepRequest tosAcceptanceTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} tosAcceptanceTime
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.prototype.tosAcceptanceTime = null;

                /**
                 * CompleteSubsequentCashBoostAcceptStepRequest advanceOfferToken.
                 * @member {string} advanceOfferToken
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.prototype.advanceOfferToken = "";

                /**
                 * CompleteSubsequentCashBoostAcceptStepRequest payoutType.
                 * @member {visor.clientrpc.AdvancePayoutType} payoutType
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @instance
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.prototype.payoutType = 0;

                /**
                 * Creates a new CompleteSubsequentCashBoostAcceptStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest} CompleteSubsequentCashBoostAcceptStepRequest instance
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.create = function create(properties) {
                    return new CompleteSubsequentCashBoostAcceptStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteSubsequentCashBoostAcceptStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepRequest} message CompleteSubsequentCashBoostAcceptStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.cashboostToken);
                    if (message.tosAcceptanceTime != null && message.hasOwnProperty("tosAcceptanceTime"))
                        $root.google.protobuf.Timestamp.encode(message.tosAcceptanceTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.advanceOfferToken != null && message.hasOwnProperty("advanceOfferToken"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.advanceOfferToken);
                    if (message.payoutType != null && message.hasOwnProperty("payoutType"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.payoutType);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSubsequentCashBoostAcceptStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepRequest} message CompleteSubsequentCashBoostAcceptStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSubsequentCashBoostAcceptStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest} CompleteSubsequentCashBoostAcceptStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 4:
                            message.cashboostToken = reader.string();
                            break;
                        case 5:
                            message.tosAcceptanceTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.advanceOfferToken = reader.string();
                            break;
                        case 7:
                            message.payoutType = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSubsequentCashBoostAcceptStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest} CompleteSubsequentCashBoostAcceptStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSubsequentCashBoostAcceptStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.tosAcceptanceTime != null && message.hasOwnProperty("tosAcceptanceTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.tosAcceptanceTime);
                        if (error)
                            return "tosAcceptanceTime." + error;
                    }
                    if (message.advanceOfferToken != null && message.hasOwnProperty("advanceOfferToken"))
                        if (!$util.isString(message.advanceOfferToken))
                            return "advanceOfferToken: string expected";
                    if (message.payoutType != null && message.hasOwnProperty("payoutType"))
                        switch (message.payoutType) {
                        default:
                            return "payoutType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a CompleteSubsequentCashBoostAcceptStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest} CompleteSubsequentCashBoostAcceptStepRequest
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.tosAcceptanceTime != null)
                        if (object.tosAcceptanceTime === null)
                            message.tosAcceptanceTime = {};
                        else
                            message.tosAcceptanceTime = {
                                seconds: Math.floor(object.tosAcceptanceTime.getTime() / 1000),
                                nanos: object.tosAcceptanceTime.getMilliseconds() * 1000000
                            };
                    if (object.advanceOfferToken != null)
                        message.advanceOfferToken = String(object.advanceOfferToken);
                    switch (object.payoutType) {
                    case "ADVANCE_PAYOUT_TYPE_INVALID":
                    case 0:
                        message.payoutType = 0;
                        break;
                    case "ADVANCE_PAYOUT_TYPE_STANDARD":
                    case 1:
                        message.payoutType = 1;
                        break;
                    case "ADVANCE_PAYOUT_TYPE_INSTANT_DEPOSIT":
                    case 2:
                        message.payoutType = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteSubsequentCashBoostAcceptStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest} message CompleteSubsequentCashBoostAcceptStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.cashboostToken = "";
                        object.tosAcceptanceTime = null;
                        object.advanceOfferToken = "";
                        object.payoutType = options.enums === String ? "ADVANCE_PAYOUT_TYPE_INVALID" : 0;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.tosAcceptanceTime != null && message.hasOwnProperty("tosAcceptanceTime"))
                        object.tosAcceptanceTime = new Date(message.tosAcceptanceTime.seconds * 1000 + message.tosAcceptanceTime.nanos / 1000000);
                    if (message.advanceOfferToken != null && message.hasOwnProperty("advanceOfferToken"))
                        object.advanceOfferToken = message.advanceOfferToken;
                    if (message.payoutType != null && message.hasOwnProperty("payoutType"))
                        object.payoutType = options.enums === String ? $root.visor.clientrpc.AdvancePayoutType[message.payoutType] : message.payoutType;
                    return object;
                };

                /**
                 * Converts this CompleteSubsequentCashBoostAcceptStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSubsequentCashBoostAcceptStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSubsequentCashBoostAcceptStepRequest;
            })();

            clientrpc.CompleteSubsequentCashBoostAcceptStepResponse = (function() {

                /**
                 * Properties of a CompleteSubsequentCashBoostAcceptStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteSubsequentCashBoostAcceptStepResponse
                 */

                /**
                 * Constructs a new CompleteSubsequentCashBoostAcceptStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteSubsequentCashBoostAcceptStepResponse.
                 * @implements ICompleteSubsequentCashBoostAcceptStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepResponse=} [properties] Properties to set
                 */
                function CompleteSubsequentCashBoostAcceptStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteSubsequentCashBoostAcceptStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse} CompleteSubsequentCashBoostAcceptStepResponse instance
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.create = function create(properties) {
                    return new CompleteSubsequentCashBoostAcceptStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteSubsequentCashBoostAcceptStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepResponse} message CompleteSubsequentCashBoostAcceptStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteSubsequentCashBoostAcceptStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteSubsequentCashBoostAcceptStepResponse} message CompleteSubsequentCashBoostAcceptStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteSubsequentCashBoostAcceptStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse} CompleteSubsequentCashBoostAcceptStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteSubsequentCashBoostAcceptStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse} CompleteSubsequentCashBoostAcceptStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteSubsequentCashBoostAcceptStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteSubsequentCashBoostAcceptStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse} CompleteSubsequentCashBoostAcceptStepResponse
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteSubsequentCashBoostAcceptStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse} message CompleteSubsequentCashBoostAcceptStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteSubsequentCashBoostAcceptStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteSubsequentCashBoostAcceptStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteSubsequentCashBoostAcceptStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteSubsequentCashBoostAcceptStepResponse;
            })();

            clientrpc.CompleteCashBoostQualificationStepRequest = (function() {

                /**
                 * Properties of a CompleteCashBoostQualificationStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCashBoostQualificationStepRequest
                 * @property {string|null} [stepToken] CompleteCashBoostQualificationStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteCashBoostQualificationStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteCashBoostQualificationStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCashBoostQualificationStepRequest.
                 * @implements ICompleteCashBoostQualificationStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepRequest=} [properties] Properties to set
                 */
                function CompleteCashBoostQualificationStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteCashBoostQualificationStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @instance
                 */
                CompleteCashBoostQualificationStepRequest.prototype.stepToken = "";

                /**
                 * CompleteCashBoostQualificationStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @instance
                 */
                CompleteCashBoostQualificationStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteCashBoostQualificationStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepRequest} CompleteCashBoostQualificationStepRequest instance
                 */
                CompleteCashBoostQualificationStepRequest.create = function create(properties) {
                    return new CompleteCashBoostQualificationStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteCashBoostQualificationStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteCashBoostQualificationStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepRequest} message CompleteCashBoostQualificationStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostQualificationStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCashBoostQualificationStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCashBoostQualificationStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepRequest} message CompleteCashBoostQualificationStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostQualificationStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCashBoostQualificationStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepRequest} CompleteCashBoostQualificationStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostQualificationStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCashBoostQualificationStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCashBoostQualificationStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepRequest} CompleteCashBoostQualificationStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostQualificationStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCashBoostQualificationStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCashBoostQualificationStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteCashBoostQualificationStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepRequest} CompleteCashBoostQualificationStepRequest
                 */
                CompleteCashBoostQualificationStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCashBoostQualificationStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteCashBoostQualificationStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteCashBoostQualificationStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteCashBoostQualificationStepRequest} message CompleteCashBoostQualificationStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCashBoostQualificationStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteCashBoostQualificationStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCashBoostQualificationStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCashBoostQualificationStepRequest;
            })();

            clientrpc.CompleteCashBoostQualificationStepResponse = (function() {

                /**
                 * Properties of a CompleteCashBoostQualificationStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCashBoostQualificationStepResponse
                 */

                /**
                 * Constructs a new CompleteCashBoostQualificationStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCashBoostQualificationStepResponse.
                 * @implements ICompleteCashBoostQualificationStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepResponse=} [properties] Properties to set
                 */
                function CompleteCashBoostQualificationStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteCashBoostQualificationStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepResponse} CompleteCashBoostQualificationStepResponse instance
                 */
                CompleteCashBoostQualificationStepResponse.create = function create(properties) {
                    return new CompleteCashBoostQualificationStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteCashBoostQualificationStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteCashBoostQualificationStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepResponse} message CompleteCashBoostQualificationStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostQualificationStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCashBoostQualificationStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCashBoostQualificationStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCashBoostQualificationStepResponse} message CompleteCashBoostQualificationStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCashBoostQualificationStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCashBoostQualificationStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepResponse} CompleteCashBoostQualificationStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostQualificationStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCashBoostQualificationStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCashBoostQualificationStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepResponse} CompleteCashBoostQualificationStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCashBoostQualificationStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCashBoostQualificationStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCashBoostQualificationStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteCashBoostQualificationStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCashBoostQualificationStepResponse} CompleteCashBoostQualificationStepResponse
                 */
                CompleteCashBoostQualificationStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCashBoostQualificationStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteCashBoostQualificationStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteCashBoostQualificationStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteCashBoostQualificationStepResponse} message CompleteCashBoostQualificationStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCashBoostQualificationStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteCashBoostQualificationStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCashBoostQualificationStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCashBoostQualificationStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCashBoostQualificationStepResponse;
            })();

            clientrpc.CreateBankAccountRequest = (function() {

                /**
                 * Properties of a CreateBankAccountRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateBankAccountRequest
                 * @property {string|null} [cashboostToken] CreateBankAccountRequest cashboostToken
                 * @property {string|null} [boostCardProgramToken] CreateBankAccountRequest boostCardProgramToken
                 * @property {string|null} [plaidPublicToken] CreateBankAccountRequest plaidPublicToken
                 * @property {Uint8Array|null} [plaidMetadata] CreateBankAccountRequest plaidMetadata
                 * @property {string|null} [stepToken] CreateBankAccountRequest stepToken
                 * @property {string|null} [workflowToken] CreateBankAccountRequest workflowToken
                 * @property {string|null} [policyToken] CreateBankAccountRequest policyToken
                 */

                /**
                 * Constructs a new CreateBankAccountRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateBankAccountRequest.
                 * @implements ICreateBankAccountRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateBankAccountRequest=} [properties] Properties to set
                 */
                function CreateBankAccountRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBankAccountRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 */
                CreateBankAccountRequest.prototype.cashboostToken = "";

                /**
                 * CreateBankAccountRequest boostCardProgramToken.
                 * @member {string} boostCardProgramToken
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 */
                CreateBankAccountRequest.prototype.boostCardProgramToken = "";

                /**
                 * CreateBankAccountRequest plaidPublicToken.
                 * @member {string} plaidPublicToken
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 */
                CreateBankAccountRequest.prototype.plaidPublicToken = "";

                /**
                 * CreateBankAccountRequest plaidMetadata.
                 * @member {Uint8Array} plaidMetadata
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 */
                CreateBankAccountRequest.prototype.plaidMetadata = $util.newBuffer([]);

                /**
                 * CreateBankAccountRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 */
                CreateBankAccountRequest.prototype.stepToken = "";

                /**
                 * CreateBankAccountRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 */
                CreateBankAccountRequest.prototype.workflowToken = "";

                /**
                 * CreateBankAccountRequest policyToken.
                 * @member {string} policyToken
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 */
                CreateBankAccountRequest.prototype.policyToken = "";

                /**
                 * Creates a new CreateBankAccountRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBankAccountRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateBankAccountRequest} CreateBankAccountRequest instance
                 */
                CreateBankAccountRequest.create = function create(properties) {
                    return new CreateBankAccountRequest(properties);
                };

                /**
                 * Encodes the specified CreateBankAccountRequest message. Does not implicitly {@link visor.clientrpc.CreateBankAccountRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBankAccountRequest} message CreateBankAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBankAccountRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    if (message.plaidPublicToken != null && message.hasOwnProperty("plaidPublicToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.plaidPublicToken);
                    if (message.plaidMetadata != null && message.hasOwnProperty("plaidMetadata"))
                        writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.plaidMetadata);
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.workflowToken);
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.boostCardProgramToken);
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.policyToken);
                    return writer;
                };

                /**
                 * Encodes the specified CreateBankAccountRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBankAccountRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.ICreateBankAccountRequest} message CreateBankAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBankAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateBankAccountRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateBankAccountRequest} CreateBankAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBankAccountRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBankAccountRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        case 6:
                            message.boostCardProgramToken = reader.string();
                            break;
                        case 2:
                            message.plaidPublicToken = reader.string();
                            break;
                        case 3:
                            message.plaidMetadata = reader.bytes();
                            break;
                        case 4:
                            message.stepToken = reader.string();
                            break;
                        case 5:
                            message.workflowToken = reader.string();
                            break;
                        case 7:
                            message.policyToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateBankAccountRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateBankAccountRequest} CreateBankAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBankAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateBankAccountRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBankAccountRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        if (!$util.isString(message.boostCardProgramToken))
                            return "boostCardProgramToken: string expected";
                    if (message.plaidPublicToken != null && message.hasOwnProperty("plaidPublicToken"))
                        if (!$util.isString(message.plaidPublicToken))
                            return "plaidPublicToken: string expected";
                    if (message.plaidMetadata != null && message.hasOwnProperty("plaidMetadata"))
                        if (!(message.plaidMetadata && typeof message.plaidMetadata.length === "number" || $util.isString(message.plaidMetadata)))
                            return "plaidMetadata: buffer expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        if (!$util.isString(message.policyToken))
                            return "policyToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreateBankAccountRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateBankAccountRequest} CreateBankAccountRequest
                 */
                CreateBankAccountRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateBankAccountRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateBankAccountRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.boostCardProgramToken != null)
                        message.boostCardProgramToken = String(object.boostCardProgramToken);
                    if (object.plaidPublicToken != null)
                        message.plaidPublicToken = String(object.plaidPublicToken);
                    if (object.plaidMetadata != null)
                        if (typeof object.plaidMetadata === "string")
                            $util.base64.decode(object.plaidMetadata, message.plaidMetadata = $util.newBuffer($util.base64.length(object.plaidMetadata)), 0);
                        else if (object.plaidMetadata.length)
                            message.plaidMetadata = object.plaidMetadata;
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.policyToken != null)
                        message.policyToken = String(object.policyToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBankAccountRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.CreateBankAccountRequest} message CreateBankAccountRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBankAccountRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cashboostToken = "";
                        object.plaidPublicToken = "";
                        if (options.bytes === String)
                            object.plaidMetadata = "";
                        else {
                            object.plaidMetadata = [];
                            if (options.bytes !== Array)
                                object.plaidMetadata = $util.newBuffer(object.plaidMetadata);
                        }
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.boostCardProgramToken = "";
                        object.policyToken = "";
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.plaidPublicToken != null && message.hasOwnProperty("plaidPublicToken"))
                        object.plaidPublicToken = message.plaidPublicToken;
                    if (message.plaidMetadata != null && message.hasOwnProperty("plaidMetadata"))
                        object.plaidMetadata = options.bytes === String ? $util.base64.encode(message.plaidMetadata, 0, message.plaidMetadata.length) : options.bytes === Array ? Array.prototype.slice.call(message.plaidMetadata) : message.plaidMetadata;
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        object.boostCardProgramToken = message.boostCardProgramToken;
                    if (message.policyToken != null && message.hasOwnProperty("policyToken"))
                        object.policyToken = message.policyToken;
                    return object;
                };

                /**
                 * Converts this CreateBankAccountRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateBankAccountRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBankAccountRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateBankAccountRequest;
            })();

            clientrpc.CreateBankAccountResponse = (function() {

                /**
                 * Properties of a CreateBankAccountResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateBankAccountResponse
                 * @property {visor.clientrpc.BankAccountIneligibilityReason|null} [ineligibility] CreateBankAccountResponse ineligibility
                 * @property {visor.clientrpc.CreateBankAccountResponse.ICreateBankAccountSuccess|null} [success] CreateBankAccountResponse success
                 * @property {visor.clientrpc.CreateBankAccountResponse.IEligibilityIssue|null} [issue] CreateBankAccountResponse issue
                 */

                /**
                 * Constructs a new CreateBankAccountResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateBankAccountResponse.
                 * @implements ICreateBankAccountResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateBankAccountResponse=} [properties] Properties to set
                 */
                function CreateBankAccountResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateBankAccountResponse ineligibility.
                 * @member {visor.clientrpc.BankAccountIneligibilityReason} ineligibility
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @instance
                 */
                CreateBankAccountResponse.prototype.ineligibility = 0;

                /**
                 * CreateBankAccountResponse success.
                 * @member {visor.clientrpc.CreateBankAccountResponse.ICreateBankAccountSuccess|null|undefined} success
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @instance
                 */
                CreateBankAccountResponse.prototype.success = null;

                /**
                 * CreateBankAccountResponse issue.
                 * @member {visor.clientrpc.CreateBankAccountResponse.IEligibilityIssue|null|undefined} issue
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @instance
                 */
                CreateBankAccountResponse.prototype.issue = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * CreateBankAccountResponse result.
                 * @member {"success"|"issue"|undefined} result
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @instance
                 */
                Object.defineProperty(CreateBankAccountResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["success", "issue"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new CreateBankAccountResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBankAccountResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateBankAccountResponse} CreateBankAccountResponse instance
                 */
                CreateBankAccountResponse.create = function create(properties) {
                    return new CreateBankAccountResponse(properties);
                };

                /**
                 * Encodes the specified CreateBankAccountResponse message. Does not implicitly {@link visor.clientrpc.CreateBankAccountResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBankAccountResponse} message CreateBankAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBankAccountResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && message.hasOwnProperty("success"))
                        $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess.encode(message.success, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.issue != null && message.hasOwnProperty("issue"))
                        $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue.encode(message.issue, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.ineligibility != null && message.hasOwnProperty("ineligibility"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ineligibility);
                    return writer;
                };

                /**
                 * Encodes the specified CreateBankAccountResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBankAccountResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.ICreateBankAccountResponse} message CreateBankAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateBankAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateBankAccountResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateBankAccountResponse} CreateBankAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBankAccountResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBankAccountResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3:
                            message.ineligibility = reader.int32();
                            break;
                        case 1:
                            message.success = $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.issue = $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateBankAccountResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateBankAccountResponse} CreateBankAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateBankAccountResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateBankAccountResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateBankAccountResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.ineligibility != null && message.hasOwnProperty("ineligibility"))
                        switch (message.ineligibility) {
                        default:
                            return "ineligibility: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.success != null && message.hasOwnProperty("success")) {
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess.verify(message.success);
                            if (error)
                                return "success." + error;
                        }
                    }
                    if (message.issue != null && message.hasOwnProperty("issue")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue.verify(message.issue);
                            if (error)
                                return "issue." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateBankAccountResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateBankAccountResponse} CreateBankAccountResponse
                 */
                CreateBankAccountResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateBankAccountResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreateBankAccountResponse();
                    switch (object.ineligibility) {
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_INVALID":
                    case 0:
                        message.ineligibility = 0;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION":
                    case 1:
                        message.ineligibility = 1;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT":
                    case 2:
                        message.ineligibility = 2;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT":
                    case 3:
                        message.ineligibility = 3;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT":
                    case 4:
                        message.ineligibility = 4;
                        break;
                    }
                    if (object.success != null) {
                        if (typeof object.success !== "object")
                            throw TypeError(".visor.clientrpc.CreateBankAccountResponse.success: object expected");
                        message.success = $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess.fromObject(object.success);
                    }
                    if (object.issue != null) {
                        if (typeof object.issue !== "object")
                            throw TypeError(".visor.clientrpc.CreateBankAccountResponse.issue: object expected");
                        message.issue = $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue.fromObject(object.issue);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateBankAccountResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.CreateBankAccountResponse} message CreateBankAccountResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateBankAccountResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.ineligibility = options.enums === String ? "BANK_ACCOUNT_INELIGIBILITY_REASON_INVALID" : 0;
                    if (message.success != null && message.hasOwnProperty("success")) {
                        object.success = $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess.toObject(message.success, options);
                        if (options.oneofs)
                            object.result = "success";
                    }
                    if (message.issue != null && message.hasOwnProperty("issue")) {
                        object.issue = $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue.toObject(message.issue, options);
                        if (options.oneofs)
                            object.result = "issue";
                    }
                    if (message.ineligibility != null && message.hasOwnProperty("ineligibility"))
                        object.ineligibility = options.enums === String ? $root.visor.clientrpc.BankAccountIneligibilityReason[message.ineligibility] : message.ineligibility;
                    return object;
                };

                /**
                 * Converts this CreateBankAccountResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateBankAccountResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateBankAccountResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreateBankAccountResponse.CreateBankAccountSuccess = (function() {

                    /**
                     * Properties of a CreateBankAccountSuccess.
                     * @memberof visor.clientrpc.CreateBankAccountResponse
                     * @interface ICreateBankAccountSuccess
                     */

                    /**
                     * Constructs a new CreateBankAccountSuccess.
                     * @memberof visor.clientrpc.CreateBankAccountResponse
                     * @classdesc Represents a CreateBankAccountSuccess.
                     * @implements ICreateBankAccountSuccess
                     * @constructor
                     * @param {visor.clientrpc.CreateBankAccountResponse.ICreateBankAccountSuccess=} [properties] Properties to set
                     */
                    function CreateBankAccountSuccess(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new CreateBankAccountSuccess instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.ICreateBankAccountSuccess=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess} CreateBankAccountSuccess instance
                     */
                    CreateBankAccountSuccess.create = function create(properties) {
                        return new CreateBankAccountSuccess(properties);
                    };

                    /**
                     * Encodes the specified CreateBankAccountSuccess message. Does not implicitly {@link visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.ICreateBankAccountSuccess} message CreateBankAccountSuccess message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateBankAccountSuccess.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateBankAccountSuccess message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.ICreateBankAccountSuccess} message CreateBankAccountSuccess message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateBankAccountSuccess.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateBankAccountSuccess message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess} CreateBankAccountSuccess
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateBankAccountSuccess.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateBankAccountSuccess message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess} CreateBankAccountSuccess
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateBankAccountSuccess.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateBankAccountSuccess message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateBankAccountSuccess.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a CreateBankAccountSuccess message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess} CreateBankAccountSuccess
                     */
                    CreateBankAccountSuccess.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess)
                            return object;
                        return new $root.visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess();
                    };

                    /**
                     * Creates a plain object from a CreateBankAccountSuccess message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess} message CreateBankAccountSuccess
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateBankAccountSuccess.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this CreateBankAccountSuccess to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateBankAccountResponse.CreateBankAccountSuccess
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateBankAccountSuccess.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreateBankAccountSuccess;
                })();

                CreateBankAccountResponse.EligibilityIssue = (function() {

                    /**
                     * Properties of an EligibilityIssue.
                     * @memberof visor.clientrpc.CreateBankAccountResponse
                     * @interface IEligibilityIssue
                     * @property {visor.clientrpc.CreateBankAccountResponse.IneligibilityReason|null} [issue] EligibilityIssue issue
                     */

                    /**
                     * Constructs a new EligibilityIssue.
                     * @memberof visor.clientrpc.CreateBankAccountResponse
                     * @classdesc Represents an EligibilityIssue.
                     * @implements IEligibilityIssue
                     * @constructor
                     * @param {visor.clientrpc.CreateBankAccountResponse.IEligibilityIssue=} [properties] Properties to set
                     */
                    function EligibilityIssue(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * EligibilityIssue issue.
                     * @member {visor.clientrpc.CreateBankAccountResponse.IneligibilityReason} issue
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @instance
                     */
                    EligibilityIssue.prototype.issue = 0;

                    /**
                     * Creates a new EligibilityIssue instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.IEligibilityIssue=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateBankAccountResponse.EligibilityIssue} EligibilityIssue instance
                     */
                    EligibilityIssue.create = function create(properties) {
                        return new EligibilityIssue(properties);
                    };

                    /**
                     * Encodes the specified EligibilityIssue message. Does not implicitly {@link visor.clientrpc.CreateBankAccountResponse.EligibilityIssue.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.IEligibilityIssue} message EligibilityIssue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EligibilityIssue.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.issue != null && message.hasOwnProperty("issue"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.issue);
                        return writer;
                    };

                    /**
                     * Encodes the specified EligibilityIssue message, length delimited. Does not implicitly {@link visor.clientrpc.CreateBankAccountResponse.EligibilityIssue.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.IEligibilityIssue} message EligibilityIssue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EligibilityIssue.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EligibilityIssue message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateBankAccountResponse.EligibilityIssue} EligibilityIssue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EligibilityIssue.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.issue = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EligibilityIssue message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateBankAccountResponse.EligibilityIssue} EligibilityIssue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EligibilityIssue.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EligibilityIssue message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EligibilityIssue.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.issue != null && message.hasOwnProperty("issue"))
                            switch (message.issue) {
                            default:
                                return "issue: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates an EligibilityIssue message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateBankAccountResponse.EligibilityIssue} EligibilityIssue
                     */
                    EligibilityIssue.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue)
                            return object;
                        var message = new $root.visor.clientrpc.CreateBankAccountResponse.EligibilityIssue();
                        switch (object.issue) {
                        case "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_INVALID":
                        case 0:
                            message.issue = 0;
                            break;
                        case "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION":
                        case 1:
                            message.issue = 1;
                            break;
                        case "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT":
                        case 2:
                            message.issue = 2;
                            break;
                        case "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT":
                        case 3:
                            message.issue = 3;
                            break;
                        case "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT":
                        case 4:
                            message.issue = 4;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an EligibilityIssue message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @static
                     * @param {visor.clientrpc.CreateBankAccountResponse.EligibilityIssue} message EligibilityIssue
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EligibilityIssue.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.issue = options.enums === String ? "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_INVALID" : 0;
                        if (message.issue != null && message.hasOwnProperty("issue"))
                            object.issue = options.enums === String ? $root.visor.clientrpc.CreateBankAccountResponse.IneligibilityReason[message.issue] : message.issue;
                        return object;
                    };

                    /**
                     * Converts this EligibilityIssue to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateBankAccountResponse.EligibilityIssue
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EligibilityIssue.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return EligibilityIssue;
                })();

                /**
                 * IneligibilityReason enum.
                 * @name visor.clientrpc.CreateBankAccountResponse.IneligibilityReason
                 * @enum {string}
                 * @property {number} CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_INVALID=0 CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_INVALID value
                 * @property {number} CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION=1 CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION value
                 * @property {number} CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT=2 CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT value
                 * @property {number} CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT=3 CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT value
                 * @property {number} CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT=4 CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT value
                 */
                CreateBankAccountResponse.IneligibilityReason = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_INVALID"] = 0;
                    values[valuesById[1] = "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION"] = 1;
                    values[valuesById[2] = "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT"] = 2;
                    values[valuesById[3] = "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT"] = 3;
                    values[valuesById[4] = "CREATE_BANK_ACCOUNT_RESPONSE_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT"] = 4;
                    return values;
                })();

                return CreateBankAccountResponse;
            })();

            clientrpc.UpdateBankAccountRequest = (function() {

                /**
                 * Properties of an UpdateBankAccountRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateBankAccountRequest
                 * @property {string|null} [bankAccountToken] UpdateBankAccountRequest bankAccountToken
                 * @property {Uint8Array|null} [plaidMetadata] UpdateBankAccountRequest plaidMetadata
                 */

                /**
                 * Constructs a new UpdateBankAccountRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateBankAccountRequest.
                 * @implements IUpdateBankAccountRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateBankAccountRequest=} [properties] Properties to set
                 */
                function UpdateBankAccountRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBankAccountRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @instance
                 */
                UpdateBankAccountRequest.prototype.bankAccountToken = "";

                /**
                 * UpdateBankAccountRequest plaidMetadata.
                 * @member {Uint8Array} plaidMetadata
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @instance
                 */
                UpdateBankAccountRequest.prototype.plaidMetadata = $util.newBuffer([]);

                /**
                 * Creates a new UpdateBankAccountRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateBankAccountRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateBankAccountRequest} UpdateBankAccountRequest instance
                 */
                UpdateBankAccountRequest.create = function create(properties) {
                    return new UpdateBankAccountRequest(properties);
                };

                /**
                 * Encodes the specified UpdateBankAccountRequest message. Does not implicitly {@link visor.clientrpc.UpdateBankAccountRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateBankAccountRequest} message UpdateBankAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBankAccountRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.bankAccountToken);
                    if (message.plaidMetadata != null && message.hasOwnProperty("plaidMetadata"))
                        writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.plaidMetadata);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateBankAccountRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateBankAccountRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateBankAccountRequest} message UpdateBankAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBankAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateBankAccountRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateBankAccountRequest} UpdateBankAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBankAccountRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateBankAccountRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.bankAccountToken = reader.string();
                            break;
                        case 2:
                            message.plaidMetadata = reader.bytes();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateBankAccountRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateBankAccountRequest} UpdateBankAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBankAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateBankAccountRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBankAccountRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    if (message.plaidMetadata != null && message.hasOwnProperty("plaidMetadata"))
                        if (!(message.plaidMetadata && typeof message.plaidMetadata.length === "number" || $util.isString(message.plaidMetadata)))
                            return "plaidMetadata: buffer expected";
                    return null;
                };

                /**
                 * Creates an UpdateBankAccountRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateBankAccountRequest} UpdateBankAccountRequest
                 */
                UpdateBankAccountRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateBankAccountRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateBankAccountRequest();
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    if (object.plaidMetadata != null)
                        if (typeof object.plaidMetadata === "string")
                            $util.base64.decode(object.plaidMetadata, message.plaidMetadata = $util.newBuffer($util.base64.length(object.plaidMetadata)), 0);
                        else if (object.plaidMetadata.length)
                            message.plaidMetadata = object.plaidMetadata;
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBankAccountRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.UpdateBankAccountRequest} message UpdateBankAccountRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBankAccountRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.bankAccountToken = "";
                        if (options.bytes === String)
                            object.plaidMetadata = "";
                        else {
                            object.plaidMetadata = [];
                            if (options.bytes !== Array)
                                object.plaidMetadata = $util.newBuffer(object.plaidMetadata);
                        }
                    }
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    if (message.plaidMetadata != null && message.hasOwnProperty("plaidMetadata"))
                        object.plaidMetadata = options.bytes === String ? $util.base64.encode(message.plaidMetadata, 0, message.plaidMetadata.length) : options.bytes === Array ? Array.prototype.slice.call(message.plaidMetadata) : message.plaidMetadata;
                    return object;
                };

                /**
                 * Converts this UpdateBankAccountRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateBankAccountRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBankAccountRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBankAccountRequest;
            })();

            clientrpc.UpdateBankAccountResponse = (function() {

                /**
                 * Properties of an UpdateBankAccountResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateBankAccountResponse
                 * @property {visor.clientrpc.BankAccountIneligibilityReason|null} [ineligibility] UpdateBankAccountResponse ineligibility
                 */

                /**
                 * Constructs a new UpdateBankAccountResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateBankAccountResponse.
                 * @implements IUpdateBankAccountResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateBankAccountResponse=} [properties] Properties to set
                 */
                function UpdateBankAccountResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBankAccountResponse ineligibility.
                 * @member {visor.clientrpc.BankAccountIneligibilityReason} ineligibility
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @instance
                 */
                UpdateBankAccountResponse.prototype.ineligibility = 0;

                /**
                 * Creates a new UpdateBankAccountResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateBankAccountResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateBankAccountResponse} UpdateBankAccountResponse instance
                 */
                UpdateBankAccountResponse.create = function create(properties) {
                    return new UpdateBankAccountResponse(properties);
                };

                /**
                 * Encodes the specified UpdateBankAccountResponse message. Does not implicitly {@link visor.clientrpc.UpdateBankAccountResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateBankAccountResponse} message UpdateBankAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBankAccountResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ineligibility != null && message.hasOwnProperty("ineligibility"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ineligibility);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateBankAccountResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateBankAccountResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateBankAccountResponse} message UpdateBankAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBankAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateBankAccountResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateBankAccountResponse} UpdateBankAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBankAccountResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateBankAccountResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3:
                            message.ineligibility = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateBankAccountResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateBankAccountResponse} UpdateBankAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBankAccountResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateBankAccountResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBankAccountResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ineligibility != null && message.hasOwnProperty("ineligibility"))
                        switch (message.ineligibility) {
                        default:
                            return "ineligibility: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates an UpdateBankAccountResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateBankAccountResponse} UpdateBankAccountResponse
                 */
                UpdateBankAccountResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateBankAccountResponse)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateBankAccountResponse();
                    switch (object.ineligibility) {
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_INVALID":
                    case 0:
                        message.ineligibility = 0;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION":
                    case 1:
                        message.ineligibility = 1;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT":
                    case 2:
                        message.ineligibility = 2;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT":
                    case 3:
                        message.ineligibility = 3;
                        break;
                    case "BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT":
                    case 4:
                        message.ineligibility = 4;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBankAccountResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.UpdateBankAccountResponse} message UpdateBankAccountResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBankAccountResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.ineligibility = options.enums === String ? "BANK_ACCOUNT_INELIGIBILITY_REASON_INVALID" : 0;
                    if (message.ineligibility != null && message.hasOwnProperty("ineligibility"))
                        object.ineligibility = options.enums === String ? $root.visor.clientrpc.BankAccountIneligibilityReason[message.ineligibility] : message.ineligibility;
                    return object;
                };

                /**
                 * Converts this UpdateBankAccountResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateBankAccountResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBankAccountResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateBankAccountResponse;
            })();

            /**
             * BankAccountIneligibilityReason enum.
             * @name visor.clientrpc.BankAccountIneligibilityReason
             * @enum {string}
             * @property {number} BANK_ACCOUNT_INELIGIBILITY_REASON_INVALID=0 BANK_ACCOUNT_INELIGIBILITY_REASON_INVALID value
             * @property {number} BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION=1 BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION value
             * @property {number} BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT=2 BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT value
             * @property {number} BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT=3 BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT value
             * @property {number} BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT=4 BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT value
             */
            clientrpc.BankAccountIneligibilityReason = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "BANK_ACCOUNT_INELIGIBILITY_REASON_INVALID"] = 0;
                values[valuesById[1] = "BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_INSTITUTION"] = 1;
                values[valuesById[2] = "BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_ANOTHER_CLIENT"] = 2;
                values[valuesById[3] = "BANK_ACCOUNT_INELIGIBILITY_REASON_UNSUPPORTED_BANK_ACCOUNT"] = 3;
                values[valuesById[4] = "BANK_ACCOUNT_INELIGIBILITY_REASON_ACCOUNT_EXISTS_ON_CLIENT"] = 4;
                return values;
            })();

            clientrpc.TransactionVerificationAnswer = (function() {

                /**
                 * Properties of a TransactionVerificationAnswer.
                 * @memberof visor.clientrpc
                 * @interface ITransactionVerificationAnswer
                 * @property {string|null} [plaidTransactionId] TransactionVerificationAnswer plaidTransactionId
                 * @property {number|Long|null} [transactionAmount] TransactionVerificationAnswer transactionAmount
                 */

                /**
                 * Constructs a new TransactionVerificationAnswer.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a TransactionVerificationAnswer.
                 * @implements ITransactionVerificationAnswer
                 * @constructor
                 * @param {visor.clientrpc.ITransactionVerificationAnswer=} [properties] Properties to set
                 */
                function TransactionVerificationAnswer(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TransactionVerificationAnswer plaidTransactionId.
                 * @member {string} plaidTransactionId
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @instance
                 */
                TransactionVerificationAnswer.prototype.plaidTransactionId = "";

                /**
                 * TransactionVerificationAnswer transactionAmount.
                 * @member {number|Long} transactionAmount
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @instance
                 */
                TransactionVerificationAnswer.prototype.transactionAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new TransactionVerificationAnswer instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {visor.clientrpc.ITransactionVerificationAnswer=} [properties] Properties to set
                 * @returns {visor.clientrpc.TransactionVerificationAnswer} TransactionVerificationAnswer instance
                 */
                TransactionVerificationAnswer.create = function create(properties) {
                    return new TransactionVerificationAnswer(properties);
                };

                /**
                 * Encodes the specified TransactionVerificationAnswer message. Does not implicitly {@link visor.clientrpc.TransactionVerificationAnswer.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {visor.clientrpc.ITransactionVerificationAnswer} message TransactionVerificationAnswer message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TransactionVerificationAnswer.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.plaidTransactionId != null && message.hasOwnProperty("plaidTransactionId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.plaidTransactionId);
                    if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.transactionAmount);
                    return writer;
                };

                /**
                 * Encodes the specified TransactionVerificationAnswer message, length delimited. Does not implicitly {@link visor.clientrpc.TransactionVerificationAnswer.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {visor.clientrpc.ITransactionVerificationAnswer} message TransactionVerificationAnswer message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TransactionVerificationAnswer.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TransactionVerificationAnswer message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.TransactionVerificationAnswer} TransactionVerificationAnswer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TransactionVerificationAnswer.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.TransactionVerificationAnswer();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.plaidTransactionId = reader.string();
                            break;
                        case 2:
                            message.transactionAmount = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TransactionVerificationAnswer message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.TransactionVerificationAnswer} TransactionVerificationAnswer
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TransactionVerificationAnswer.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TransactionVerificationAnswer message.
                 * @function verify
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TransactionVerificationAnswer.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.plaidTransactionId != null && message.hasOwnProperty("plaidTransactionId"))
                        if (!$util.isString(message.plaidTransactionId))
                            return "plaidTransactionId: string expected";
                    if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                        if (!$util.isInteger(message.transactionAmount) && !(message.transactionAmount && $util.isInteger(message.transactionAmount.low) && $util.isInteger(message.transactionAmount.high)))
                            return "transactionAmount: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a TransactionVerificationAnswer message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.TransactionVerificationAnswer} TransactionVerificationAnswer
                 */
                TransactionVerificationAnswer.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.TransactionVerificationAnswer)
                        return object;
                    var message = new $root.visor.clientrpc.TransactionVerificationAnswer();
                    if (object.plaidTransactionId != null)
                        message.plaidTransactionId = String(object.plaidTransactionId);
                    if (object.transactionAmount != null)
                        if ($util.Long)
                            (message.transactionAmount = $util.Long.fromValue(object.transactionAmount)).unsigned = true;
                        else if (typeof object.transactionAmount === "string")
                            message.transactionAmount = parseInt(object.transactionAmount, 10);
                        else if (typeof object.transactionAmount === "number")
                            message.transactionAmount = object.transactionAmount;
                        else if (typeof object.transactionAmount === "object")
                            message.transactionAmount = new $util.LongBits(object.transactionAmount.low >>> 0, object.transactionAmount.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a TransactionVerificationAnswer message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @static
                 * @param {visor.clientrpc.TransactionVerificationAnswer} message TransactionVerificationAnswer
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TransactionVerificationAnswer.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.plaidTransactionId = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.transactionAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.transactionAmount = options.longs === String ? "0" : 0;
                    }
                    if (message.plaidTransactionId != null && message.hasOwnProperty("plaidTransactionId"))
                        object.plaidTransactionId = message.plaidTransactionId;
                    if (message.transactionAmount != null && message.hasOwnProperty("transactionAmount"))
                        if (typeof message.transactionAmount === "number")
                            object.transactionAmount = options.longs === String ? String(message.transactionAmount) : message.transactionAmount;
                        else
                            object.transactionAmount = options.longs === String ? $util.Long.prototype.toString.call(message.transactionAmount) : options.longs === Number ? new $util.LongBits(message.transactionAmount.low >>> 0, message.transactionAmount.high >>> 0).toNumber(true) : message.transactionAmount;
                    return object;
                };

                /**
                 * Converts this TransactionVerificationAnswer to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.TransactionVerificationAnswer
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TransactionVerificationAnswer.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TransactionVerificationAnswer;
            })();

            clientrpc.VerifyBankAccountRequest = (function() {

                /**
                 * Properties of a VerifyBankAccountRequest.
                 * @memberof visor.clientrpc
                 * @interface IVerifyBankAccountRequest
                 * @property {string|null} [bankAccountToken] VerifyBankAccountRequest bankAccountToken
                 * @property {Array.<visor.clientrpc.ITransactionVerificationAnswer>|null} [verificationAnswers] VerifyBankAccountRequest verificationAnswers
                 */

                /**
                 * Constructs a new VerifyBankAccountRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a VerifyBankAccountRequest.
                 * @implements IVerifyBankAccountRequest
                 * @constructor
                 * @param {visor.clientrpc.IVerifyBankAccountRequest=} [properties] Properties to set
                 */
                function VerifyBankAccountRequest(properties) {
                    this.verificationAnswers = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VerifyBankAccountRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @instance
                 */
                VerifyBankAccountRequest.prototype.bankAccountToken = "";

                /**
                 * VerifyBankAccountRequest verificationAnswers.
                 * @member {Array.<visor.clientrpc.ITransactionVerificationAnswer>} verificationAnswers
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @instance
                 */
                VerifyBankAccountRequest.prototype.verificationAnswers = $util.emptyArray;

                /**
                 * Creates a new VerifyBankAccountRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.IVerifyBankAccountRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.VerifyBankAccountRequest} VerifyBankAccountRequest instance
                 */
                VerifyBankAccountRequest.create = function create(properties) {
                    return new VerifyBankAccountRequest(properties);
                };

                /**
                 * Encodes the specified VerifyBankAccountRequest message. Does not implicitly {@link visor.clientrpc.VerifyBankAccountRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.IVerifyBankAccountRequest} message VerifyBankAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VerifyBankAccountRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.bankAccountToken);
                    if (message.verificationAnswers != null && message.verificationAnswers.length)
                        for (var i = 0; i < message.verificationAnswers.length; ++i)
                            $root.visor.clientrpc.TransactionVerificationAnswer.encode(message.verificationAnswers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified VerifyBankAccountRequest message, length delimited. Does not implicitly {@link visor.clientrpc.VerifyBankAccountRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.IVerifyBankAccountRequest} message VerifyBankAccountRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VerifyBankAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VerifyBankAccountRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.VerifyBankAccountRequest} VerifyBankAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VerifyBankAccountRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.VerifyBankAccountRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.bankAccountToken = reader.string();
                            break;
                        case 2:
                            if (!(message.verificationAnswers && message.verificationAnswers.length))
                                message.verificationAnswers = [];
                            message.verificationAnswers.push($root.visor.clientrpc.TransactionVerificationAnswer.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VerifyBankAccountRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.VerifyBankAccountRequest} VerifyBankAccountRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VerifyBankAccountRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VerifyBankAccountRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerifyBankAccountRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    if (message.verificationAnswers != null && message.hasOwnProperty("verificationAnswers")) {
                        if (!Array.isArray(message.verificationAnswers))
                            return "verificationAnswers: array expected";
                        for (var i = 0; i < message.verificationAnswers.length; ++i) {
                            var error = $root.visor.clientrpc.TransactionVerificationAnswer.verify(message.verificationAnswers[i]);
                            if (error)
                                return "verificationAnswers." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a VerifyBankAccountRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.VerifyBankAccountRequest} VerifyBankAccountRequest
                 */
                VerifyBankAccountRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.VerifyBankAccountRequest)
                        return object;
                    var message = new $root.visor.clientrpc.VerifyBankAccountRequest();
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    if (object.verificationAnswers) {
                        if (!Array.isArray(object.verificationAnswers))
                            throw TypeError(".visor.clientrpc.VerifyBankAccountRequest.verificationAnswers: array expected");
                        message.verificationAnswers = [];
                        for (var i = 0; i < object.verificationAnswers.length; ++i) {
                            if (typeof object.verificationAnswers[i] !== "object")
                                throw TypeError(".visor.clientrpc.VerifyBankAccountRequest.verificationAnswers: object expected");
                            message.verificationAnswers[i] = $root.visor.clientrpc.TransactionVerificationAnswer.fromObject(object.verificationAnswers[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a VerifyBankAccountRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @static
                 * @param {visor.clientrpc.VerifyBankAccountRequest} message VerifyBankAccountRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerifyBankAccountRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.verificationAnswers = [];
                    if (options.defaults)
                        object.bankAccountToken = "";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    if (message.verificationAnswers && message.verificationAnswers.length) {
                        object.verificationAnswers = [];
                        for (var j = 0; j < message.verificationAnswers.length; ++j)
                            object.verificationAnswers[j] = $root.visor.clientrpc.TransactionVerificationAnswer.toObject(message.verificationAnswers[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this VerifyBankAccountRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.VerifyBankAccountRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerifyBankAccountRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return VerifyBankAccountRequest;
            })();

            clientrpc.VerifyBankAccountResponse = (function() {

                /**
                 * Properties of a VerifyBankAccountResponse.
                 * @memberof visor.clientrpc
                 * @interface IVerifyBankAccountResponse
                 * @property {boolean|null} [isVerified] VerifyBankAccountResponse isVerified
                 */

                /**
                 * Constructs a new VerifyBankAccountResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a VerifyBankAccountResponse.
                 * @implements IVerifyBankAccountResponse
                 * @constructor
                 * @param {visor.clientrpc.IVerifyBankAccountResponse=} [properties] Properties to set
                 */
                function VerifyBankAccountResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * VerifyBankAccountResponse isVerified.
                 * @member {boolean} isVerified
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @instance
                 */
                VerifyBankAccountResponse.prototype.isVerified = false;

                /**
                 * Creates a new VerifyBankAccountResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.IVerifyBankAccountResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.VerifyBankAccountResponse} VerifyBankAccountResponse instance
                 */
                VerifyBankAccountResponse.create = function create(properties) {
                    return new VerifyBankAccountResponse(properties);
                };

                /**
                 * Encodes the specified VerifyBankAccountResponse message. Does not implicitly {@link visor.clientrpc.VerifyBankAccountResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.IVerifyBankAccountResponse} message VerifyBankAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VerifyBankAccountResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isVerified != null && message.hasOwnProperty("isVerified"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isVerified);
                    return writer;
                };

                /**
                 * Encodes the specified VerifyBankAccountResponse message, length delimited. Does not implicitly {@link visor.clientrpc.VerifyBankAccountResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.IVerifyBankAccountResponse} message VerifyBankAccountResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                VerifyBankAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a VerifyBankAccountResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.VerifyBankAccountResponse} VerifyBankAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VerifyBankAccountResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.VerifyBankAccountResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isVerified = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a VerifyBankAccountResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.VerifyBankAccountResponse} VerifyBankAccountResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                VerifyBankAccountResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a VerifyBankAccountResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                VerifyBankAccountResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isVerified != null && message.hasOwnProperty("isVerified"))
                        if (typeof message.isVerified !== "boolean")
                            return "isVerified: boolean expected";
                    return null;
                };

                /**
                 * Creates a VerifyBankAccountResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.VerifyBankAccountResponse} VerifyBankAccountResponse
                 */
                VerifyBankAccountResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.VerifyBankAccountResponse)
                        return object;
                    var message = new $root.visor.clientrpc.VerifyBankAccountResponse();
                    if (object.isVerified != null)
                        message.isVerified = Boolean(object.isVerified);
                    return message;
                };

                /**
                 * Creates a plain object from a VerifyBankAccountResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @static
                 * @param {visor.clientrpc.VerifyBankAccountResponse} message VerifyBankAccountResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                VerifyBankAccountResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.isVerified = false;
                    if (message.isVerified != null && message.hasOwnProperty("isVerified"))
                        object.isVerified = message.isVerified;
                    return object;
                };

                /**
                 * Converts this VerifyBankAccountResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.VerifyBankAccountResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                VerifyBankAccountResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return VerifyBankAccountResponse;
            })();

            clientrpc.CreateDebitCardRequest = (function() {

                /**
                 * Properties of a CreateDebitCardRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateDebitCardRequest
                 * @property {string|null} [cashboostToken] CreateDebitCardRequest cashboostToken
                 * @property {string|null} [stripePaymentMethodId] CreateDebitCardRequest stripePaymentMethodId
                 * @property {visor.common.encryption.IEncryptedItem|null} [cardNumber] CreateDebitCardRequest cardNumber
                 * @property {visor.common.encryption.IEncryptedItem|null} [cardCvc] CreateDebitCardRequest cardCvc
                 * @property {number|Long|null} [cardExpirationMonth] CreateDebitCardRequest cardExpirationMonth
                 * @property {number|Long|null} [cardExpirationYear] CreateDebitCardRequest cardExpirationYear
                 * @property {string|null} [cardZipCode] CreateDebitCardRequest cardZipCode
                 * @property {string|null} [ckoCardToken] CreateDebitCardRequest ckoCardToken
                 * @property {string|null} [footprintCardVaultToken] CreateDebitCardRequest footprintCardVaultToken
                 */

                /**
                 * Constructs a new CreateDebitCardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateDebitCardRequest.
                 * @implements ICreateDebitCardRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateDebitCardRequest=} [properties] Properties to set
                 */
                function CreateDebitCardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateDebitCardRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.cashboostToken = "";

                /**
                 * CreateDebitCardRequest stripePaymentMethodId.
                 * @member {string} stripePaymentMethodId
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.stripePaymentMethodId = "";

                /**
                 * CreateDebitCardRequest cardNumber.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} cardNumber
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.cardNumber = null;

                /**
                 * CreateDebitCardRequest cardCvc.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} cardCvc
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.cardCvc = null;

                /**
                 * CreateDebitCardRequest cardExpirationMonth.
                 * @member {number|Long} cardExpirationMonth
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.cardExpirationMonth = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * CreateDebitCardRequest cardExpirationYear.
                 * @member {number|Long} cardExpirationYear
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.cardExpirationYear = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * CreateDebitCardRequest cardZipCode.
                 * @member {string} cardZipCode
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.cardZipCode = "";

                /**
                 * CreateDebitCardRequest ckoCardToken.
                 * @member {string} ckoCardToken
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.ckoCardToken = "";

                /**
                 * CreateDebitCardRequest footprintCardVaultToken.
                 * @member {string} footprintCardVaultToken
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 */
                CreateDebitCardRequest.prototype.footprintCardVaultToken = "";

                /**
                 * Creates a new CreateDebitCardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.ICreateDebitCardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateDebitCardRequest} CreateDebitCardRequest instance
                 */
                CreateDebitCardRequest.create = function create(properties) {
                    return new CreateDebitCardRequest(properties);
                };

                /**
                 * Encodes the specified CreateDebitCardRequest message. Does not implicitly {@link visor.clientrpc.CreateDebitCardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.ICreateDebitCardRequest} message CreateDebitCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDebitCardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    if (message.stripePaymentMethodId != null && message.hasOwnProperty("stripePaymentMethodId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.stripePaymentMethodId);
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.cardNumber, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.cardCvc != null && message.hasOwnProperty("cardCvc"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.cardCvc, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.cardExpirationMonth != null && message.hasOwnProperty("cardExpirationMonth"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.cardExpirationMonth);
                    if (message.cardExpirationYear != null && message.hasOwnProperty("cardExpirationYear"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.cardExpirationYear);
                    if (message.cardZipCode != null && message.hasOwnProperty("cardZipCode"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.cardZipCode);
                    if (message.ckoCardToken != null && message.hasOwnProperty("ckoCardToken"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.ckoCardToken);
                    if (message.footprintCardVaultToken != null && message.hasOwnProperty("footprintCardVaultToken"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.footprintCardVaultToken);
                    return writer;
                };

                /**
                 * Encodes the specified CreateDebitCardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateDebitCardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.ICreateDebitCardRequest} message CreateDebitCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDebitCardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateDebitCardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateDebitCardRequest} CreateDebitCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDebitCardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateDebitCardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        case 2:
                            message.stripePaymentMethodId = reader.string();
                            break;
                        case 3:
                            message.cardNumber = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.cardCvc = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.cardExpirationMonth = reader.uint64();
                            break;
                        case 6:
                            message.cardExpirationYear = reader.uint64();
                            break;
                        case 7:
                            message.cardZipCode = reader.string();
                            break;
                        case 8:
                            message.ckoCardToken = reader.string();
                            break;
                        case 9:
                            message.footprintCardVaultToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateDebitCardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateDebitCardRequest} CreateDebitCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDebitCardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateDebitCardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateDebitCardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.stripePaymentMethodId != null && message.hasOwnProperty("stripePaymentMethodId"))
                        if (!$util.isString(message.stripePaymentMethodId))
                            return "stripePaymentMethodId: string expected";
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.cardNumber);
                        if (error)
                            return "cardNumber." + error;
                    }
                    if (message.cardCvc != null && message.hasOwnProperty("cardCvc")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.cardCvc);
                        if (error)
                            return "cardCvc." + error;
                    }
                    if (message.cardExpirationMonth != null && message.hasOwnProperty("cardExpirationMonth"))
                        if (!$util.isInteger(message.cardExpirationMonth) && !(message.cardExpirationMonth && $util.isInteger(message.cardExpirationMonth.low) && $util.isInteger(message.cardExpirationMonth.high)))
                            return "cardExpirationMonth: integer|Long expected";
                    if (message.cardExpirationYear != null && message.hasOwnProperty("cardExpirationYear"))
                        if (!$util.isInteger(message.cardExpirationYear) && !(message.cardExpirationYear && $util.isInteger(message.cardExpirationYear.low) && $util.isInteger(message.cardExpirationYear.high)))
                            return "cardExpirationYear: integer|Long expected";
                    if (message.cardZipCode != null && message.hasOwnProperty("cardZipCode"))
                        if (!$util.isString(message.cardZipCode))
                            return "cardZipCode: string expected";
                    if (message.ckoCardToken != null && message.hasOwnProperty("ckoCardToken"))
                        if (!$util.isString(message.ckoCardToken))
                            return "ckoCardToken: string expected";
                    if (message.footprintCardVaultToken != null && message.hasOwnProperty("footprintCardVaultToken"))
                        if (!$util.isString(message.footprintCardVaultToken))
                            return "footprintCardVaultToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreateDebitCardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateDebitCardRequest} CreateDebitCardRequest
                 */
                CreateDebitCardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateDebitCardRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateDebitCardRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.stripePaymentMethodId != null)
                        message.stripePaymentMethodId = String(object.stripePaymentMethodId);
                    if (object.cardNumber != null) {
                        if (typeof object.cardNumber !== "object")
                            throw TypeError(".visor.clientrpc.CreateDebitCardRequest.cardNumber: object expected");
                        message.cardNumber = $root.visor.common.encryption.EncryptedItem.fromObject(object.cardNumber);
                    }
                    if (object.cardCvc != null) {
                        if (typeof object.cardCvc !== "object")
                            throw TypeError(".visor.clientrpc.CreateDebitCardRequest.cardCvc: object expected");
                        message.cardCvc = $root.visor.common.encryption.EncryptedItem.fromObject(object.cardCvc);
                    }
                    if (object.cardExpirationMonth != null)
                        if ($util.Long)
                            (message.cardExpirationMonth = $util.Long.fromValue(object.cardExpirationMonth)).unsigned = true;
                        else if (typeof object.cardExpirationMonth === "string")
                            message.cardExpirationMonth = parseInt(object.cardExpirationMonth, 10);
                        else if (typeof object.cardExpirationMonth === "number")
                            message.cardExpirationMonth = object.cardExpirationMonth;
                        else if (typeof object.cardExpirationMonth === "object")
                            message.cardExpirationMonth = new $util.LongBits(object.cardExpirationMonth.low >>> 0, object.cardExpirationMonth.high >>> 0).toNumber(true);
                    if (object.cardExpirationYear != null)
                        if ($util.Long)
                            (message.cardExpirationYear = $util.Long.fromValue(object.cardExpirationYear)).unsigned = true;
                        else if (typeof object.cardExpirationYear === "string")
                            message.cardExpirationYear = parseInt(object.cardExpirationYear, 10);
                        else if (typeof object.cardExpirationYear === "number")
                            message.cardExpirationYear = object.cardExpirationYear;
                        else if (typeof object.cardExpirationYear === "object")
                            message.cardExpirationYear = new $util.LongBits(object.cardExpirationYear.low >>> 0, object.cardExpirationYear.high >>> 0).toNumber(true);
                    if (object.cardZipCode != null)
                        message.cardZipCode = String(object.cardZipCode);
                    if (object.ckoCardToken != null)
                        message.ckoCardToken = String(object.ckoCardToken);
                    if (object.footprintCardVaultToken != null)
                        message.footprintCardVaultToken = String(object.footprintCardVaultToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateDebitCardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.CreateDebitCardRequest} message CreateDebitCardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateDebitCardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cashboostToken = "";
                        object.stripePaymentMethodId = "";
                        object.cardNumber = null;
                        object.cardCvc = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.cardExpirationMonth = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cardExpirationMonth = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.cardExpirationYear = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cardExpirationYear = options.longs === String ? "0" : 0;
                        object.cardZipCode = "";
                        object.ckoCardToken = "";
                        object.footprintCardVaultToken = "";
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.stripePaymentMethodId != null && message.hasOwnProperty("stripePaymentMethodId"))
                        object.stripePaymentMethodId = message.stripePaymentMethodId;
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber"))
                        object.cardNumber = $root.visor.common.encryption.EncryptedItem.toObject(message.cardNumber, options);
                    if (message.cardCvc != null && message.hasOwnProperty("cardCvc"))
                        object.cardCvc = $root.visor.common.encryption.EncryptedItem.toObject(message.cardCvc, options);
                    if (message.cardExpirationMonth != null && message.hasOwnProperty("cardExpirationMonth"))
                        if (typeof message.cardExpirationMonth === "number")
                            object.cardExpirationMonth = options.longs === String ? String(message.cardExpirationMonth) : message.cardExpirationMonth;
                        else
                            object.cardExpirationMonth = options.longs === String ? $util.Long.prototype.toString.call(message.cardExpirationMonth) : options.longs === Number ? new $util.LongBits(message.cardExpirationMonth.low >>> 0, message.cardExpirationMonth.high >>> 0).toNumber(true) : message.cardExpirationMonth;
                    if (message.cardExpirationYear != null && message.hasOwnProperty("cardExpirationYear"))
                        if (typeof message.cardExpirationYear === "number")
                            object.cardExpirationYear = options.longs === String ? String(message.cardExpirationYear) : message.cardExpirationYear;
                        else
                            object.cardExpirationYear = options.longs === String ? $util.Long.prototype.toString.call(message.cardExpirationYear) : options.longs === Number ? new $util.LongBits(message.cardExpirationYear.low >>> 0, message.cardExpirationYear.high >>> 0).toNumber(true) : message.cardExpirationYear;
                    if (message.cardZipCode != null && message.hasOwnProperty("cardZipCode"))
                        object.cardZipCode = message.cardZipCode;
                    if (message.ckoCardToken != null && message.hasOwnProperty("ckoCardToken"))
                        object.ckoCardToken = message.ckoCardToken;
                    if (message.footprintCardVaultToken != null && message.hasOwnProperty("footprintCardVaultToken"))
                        object.footprintCardVaultToken = message.footprintCardVaultToken;
                    return object;
                };

                /**
                 * Converts this CreateDebitCardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateDebitCardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateDebitCardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateDebitCardRequest;
            })();

            clientrpc.CreateDebitCardResponse = (function() {

                /**
                 * Properties of a CreateDebitCardResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateDebitCardResponse
                 * @property {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardSuccess|null} [createDebitCardAdded] CreateDebitCardResponse createDebitCardAdded
                 * @property {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardFailure|null} [createDebitCardFailed] CreateDebitCardResponse createDebitCardFailed
                 * @property {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardCardDecline|null} [createDebitCardDeclined] CreateDebitCardResponse createDebitCardDeclined
                 */

                /**
                 * Constructs a new CreateDebitCardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateDebitCardResponse.
                 * @implements ICreateDebitCardResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateDebitCardResponse=} [properties] Properties to set
                 */
                function CreateDebitCardResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateDebitCardResponse createDebitCardAdded.
                 * @member {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardSuccess|null|undefined} createDebitCardAdded
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @instance
                 */
                CreateDebitCardResponse.prototype.createDebitCardAdded = null;

                /**
                 * CreateDebitCardResponse createDebitCardFailed.
                 * @member {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardFailure} createDebitCardFailed
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @instance
                 */
                CreateDebitCardResponse.prototype.createDebitCardFailed = 0;

                /**
                 * CreateDebitCardResponse createDebitCardDeclined.
                 * @member {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardCardDecline|null|undefined} createDebitCardDeclined
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @instance
                 */
                CreateDebitCardResponse.prototype.createDebitCardDeclined = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * CreateDebitCardResponse result.
                 * @member {"createDebitCardAdded"|"createDebitCardFailed"|"createDebitCardDeclined"|undefined} result
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @instance
                 */
                Object.defineProperty(CreateDebitCardResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["createDebitCardAdded", "createDebitCardFailed", "createDebitCardDeclined"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new CreateDebitCardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.ICreateDebitCardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateDebitCardResponse} CreateDebitCardResponse instance
                 */
                CreateDebitCardResponse.create = function create(properties) {
                    return new CreateDebitCardResponse(properties);
                };

                /**
                 * Encodes the specified CreateDebitCardResponse message. Does not implicitly {@link visor.clientrpc.CreateDebitCardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.ICreateDebitCardResponse} message CreateDebitCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDebitCardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.createDebitCardAdded != null && message.hasOwnProperty("createDebitCardAdded"))
                        $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess.encode(message.createDebitCardAdded, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.createDebitCardFailed != null && message.hasOwnProperty("createDebitCardFailed"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.createDebitCardFailed);
                    if (message.createDebitCardDeclined != null && message.hasOwnProperty("createDebitCardDeclined"))
                        $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline.encode(message.createDebitCardDeclined, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreateDebitCardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateDebitCardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.ICreateDebitCardResponse} message CreateDebitCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateDebitCardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateDebitCardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateDebitCardResponse} CreateDebitCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDebitCardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateDebitCardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.createDebitCardAdded = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.createDebitCardFailed = reader.int32();
                            break;
                        case 3:
                            message.createDebitCardDeclined = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateDebitCardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateDebitCardResponse} CreateDebitCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateDebitCardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateDebitCardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateDebitCardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.createDebitCardAdded != null && message.hasOwnProperty("createDebitCardAdded")) {
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess.verify(message.createDebitCardAdded);
                            if (error)
                                return "createDebitCardAdded." + error;
                        }
                    }
                    if (message.createDebitCardFailed != null && message.hasOwnProperty("createDebitCardFailed")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        switch (message.createDebitCardFailed) {
                        default:
                            return "createDebitCardFailed: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    }
                    if (message.createDebitCardDeclined != null && message.hasOwnProperty("createDebitCardDeclined")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline.verify(message.createDebitCardDeclined);
                            if (error)
                                return "createDebitCardDeclined." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateDebitCardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateDebitCardResponse} CreateDebitCardResponse
                 */
                CreateDebitCardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateDebitCardResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreateDebitCardResponse();
                    if (object.createDebitCardAdded != null) {
                        if (typeof object.createDebitCardAdded !== "object")
                            throw TypeError(".visor.clientrpc.CreateDebitCardResponse.createDebitCardAdded: object expected");
                        message.createDebitCardAdded = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess.fromObject(object.createDebitCardAdded);
                    }
                    switch (object.createDebitCardFailed) {
                    case "CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_INVALID":
                    case 0:
                        message.createDebitCardFailed = 0;
                        break;
                    case "CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_PAYMENT_METHOD_TYPE":
                    case 1:
                        message.createDebitCardFailed = 1;
                        break;
                    case "CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_CARD_FUNDING":
                    case 2:
                        message.createDebitCardFailed = 2;
                        break;
                    }
                    if (object.createDebitCardDeclined != null) {
                        if (typeof object.createDebitCardDeclined !== "object")
                            throw TypeError(".visor.clientrpc.CreateDebitCardResponse.createDebitCardDeclined: object expected");
                        message.createDebitCardDeclined = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline.fromObject(object.createDebitCardDeclined);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateDebitCardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.CreateDebitCardResponse} message CreateDebitCardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateDebitCardResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.createDebitCardAdded != null && message.hasOwnProperty("createDebitCardAdded")) {
                        object.createDebitCardAdded = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess.toObject(message.createDebitCardAdded, options);
                        if (options.oneofs)
                            object.result = "createDebitCardAdded";
                    }
                    if (message.createDebitCardFailed != null && message.hasOwnProperty("createDebitCardFailed")) {
                        object.createDebitCardFailed = options.enums === String ? $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardFailure[message.createDebitCardFailed] : message.createDebitCardFailed;
                        if (options.oneofs)
                            object.result = "createDebitCardFailed";
                    }
                    if (message.createDebitCardDeclined != null && message.hasOwnProperty("createDebitCardDeclined")) {
                        object.createDebitCardDeclined = $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline.toObject(message.createDebitCardDeclined, options);
                        if (options.oneofs)
                            object.result = "createDebitCardDeclined";
                    }
                    return object;
                };

                /**
                 * Converts this CreateDebitCardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateDebitCardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateDebitCardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreateDebitCardResponse.CreateDebitCardSuccess = (function() {

                    /**
                     * Properties of a CreateDebitCardSuccess.
                     * @memberof visor.clientrpc.CreateDebitCardResponse
                     * @interface ICreateDebitCardSuccess
                     */

                    /**
                     * Constructs a new CreateDebitCardSuccess.
                     * @memberof visor.clientrpc.CreateDebitCardResponse
                     * @classdesc Represents a CreateDebitCardSuccess.
                     * @implements ICreateDebitCardSuccess
                     * @constructor
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardSuccess=} [properties] Properties to set
                     */
                    function CreateDebitCardSuccess(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new CreateDebitCardSuccess instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardSuccess=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess} CreateDebitCardSuccess instance
                     */
                    CreateDebitCardSuccess.create = function create(properties) {
                        return new CreateDebitCardSuccess(properties);
                    };

                    /**
                     * Encodes the specified CreateDebitCardSuccess message. Does not implicitly {@link visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardSuccess} message CreateDebitCardSuccess message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebitCardSuccess.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateDebitCardSuccess message, length delimited. Does not implicitly {@link visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardSuccess} message CreateDebitCardSuccess message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebitCardSuccess.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateDebitCardSuccess message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess} CreateDebitCardSuccess
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebitCardSuccess.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateDebitCardSuccess message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess} CreateDebitCardSuccess
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebitCardSuccess.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateDebitCardSuccess message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDebitCardSuccess.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a CreateDebitCardSuccess message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess} CreateDebitCardSuccess
                     */
                    CreateDebitCardSuccess.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess)
                            return object;
                        return new $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess();
                    };

                    /**
                     * Creates a plain object from a CreateDebitCardSuccess message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess} message CreateDebitCardSuccess
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDebitCardSuccess.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this CreateDebitCardSuccess to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardSuccess
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDebitCardSuccess.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreateDebitCardSuccess;
                })();

                /**
                 * CreateDebitCardFailure enum.
                 * @name visor.clientrpc.CreateDebitCardResponse.CreateDebitCardFailure
                 * @enum {string}
                 * @property {number} CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_INVALID=0 CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_INVALID value
                 * @property {number} CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_PAYMENT_METHOD_TYPE=1 CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_PAYMENT_METHOD_TYPE value
                 * @property {number} CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_CARD_FUNDING=2 CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_CARD_FUNDING value
                 */
                CreateDebitCardResponse.CreateDebitCardFailure = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_INVALID"] = 0;
                    values[valuesById[1] = "CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_PAYMENT_METHOD_TYPE"] = 1;
                    values[valuesById[2] = "CREATE_DEBIT_CARD_RESPONSE_CREATE_DEBIT_CARD_FAILURE_UNSUPPORTED_CARD_FUNDING"] = 2;
                    return values;
                })();

                CreateDebitCardResponse.CreateDebitCardCardDecline = (function() {

                    /**
                     * Properties of a CreateDebitCardCardDecline.
                     * @memberof visor.clientrpc.CreateDebitCardResponse
                     * @interface ICreateDebitCardCardDecline
                     * @property {string|null} [declineCode] CreateDebitCardCardDecline declineCode
                     * @property {string|null} [errorMessage] CreateDebitCardCardDecline errorMessage
                     */

                    /**
                     * Constructs a new CreateDebitCardCardDecline.
                     * @memberof visor.clientrpc.CreateDebitCardResponse
                     * @classdesc Represents a CreateDebitCardCardDecline.
                     * @implements ICreateDebitCardCardDecline
                     * @constructor
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardCardDecline=} [properties] Properties to set
                     */
                    function CreateDebitCardCardDecline(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreateDebitCardCardDecline declineCode.
                     * @member {string} declineCode
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @instance
                     */
                    CreateDebitCardCardDecline.prototype.declineCode = "";

                    /**
                     * CreateDebitCardCardDecline errorMessage.
                     * @member {string} errorMessage
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @instance
                     */
                    CreateDebitCardCardDecline.prototype.errorMessage = "";

                    /**
                     * Creates a new CreateDebitCardCardDecline instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardCardDecline=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline} CreateDebitCardCardDecline instance
                     */
                    CreateDebitCardCardDecline.create = function create(properties) {
                        return new CreateDebitCardCardDecline(properties);
                    };

                    /**
                     * Encodes the specified CreateDebitCardCardDecline message. Does not implicitly {@link visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardCardDecline} message CreateDebitCardCardDecline message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebitCardCardDecline.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.declineCode != null && message.hasOwnProperty("declineCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.declineCode);
                        if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateDebitCardCardDecline message, length delimited. Does not implicitly {@link visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.ICreateDebitCardCardDecline} message CreateDebitCardCardDecline message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateDebitCardCardDecline.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateDebitCardCardDecline message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline} CreateDebitCardCardDecline
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebitCardCardDecline.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.declineCode = reader.string();
                                break;
                            case 2:
                                message.errorMessage = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateDebitCardCardDecline message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline} CreateDebitCardCardDecline
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateDebitCardCardDecline.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateDebitCardCardDecline message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateDebitCardCardDecline.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.declineCode != null && message.hasOwnProperty("declineCode"))
                            if (!$util.isString(message.declineCode))
                                return "declineCode: string expected";
                        if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                            if (!$util.isString(message.errorMessage))
                                return "errorMessage: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreateDebitCardCardDecline message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline} CreateDebitCardCardDecline
                     */
                    CreateDebitCardCardDecline.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline)
                            return object;
                        var message = new $root.visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline();
                        if (object.declineCode != null)
                            message.declineCode = String(object.declineCode);
                        if (object.errorMessage != null)
                            message.errorMessage = String(object.errorMessage);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreateDebitCardCardDecline message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @static
                     * @param {visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline} message CreateDebitCardCardDecline
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateDebitCardCardDecline.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.declineCode = "";
                            object.errorMessage = "";
                        }
                        if (message.declineCode != null && message.hasOwnProperty("declineCode"))
                            object.declineCode = message.declineCode;
                        if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                            object.errorMessage = message.errorMessage;
                        return object;
                    };

                    /**
                     * Converts this CreateDebitCardCardDecline to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateDebitCardResponse.CreateDebitCardCardDecline
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateDebitCardCardDecline.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreateDebitCardCardDecline;
                })();

                return CreateDebitCardResponse;
            })();

            clientrpc.FinishCashBoostCheckoutRequest = (function() {

                /**
                 * Properties of a FinishCashBoostCheckoutRequest.
                 * @memberof visor.clientrpc
                 * @interface IFinishCashBoostCheckoutRequest
                 */

                /**
                 * Constructs a new FinishCashBoostCheckoutRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FinishCashBoostCheckoutRequest.
                 * @implements IFinishCashBoostCheckoutRequest
                 * @constructor
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutRequest=} [properties] Properties to set
                 */
                function FinishCashBoostCheckoutRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new FinishCashBoostCheckoutRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutRequest} FinishCashBoostCheckoutRequest instance
                 */
                FinishCashBoostCheckoutRequest.create = function create(properties) {
                    return new FinishCashBoostCheckoutRequest(properties);
                };

                /**
                 * Encodes the specified FinishCashBoostCheckoutRequest message. Does not implicitly {@link visor.clientrpc.FinishCashBoostCheckoutRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutRequest} message FinishCashBoostCheckoutRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCashBoostCheckoutRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified FinishCashBoostCheckoutRequest message, length delimited. Does not implicitly {@link visor.clientrpc.FinishCashBoostCheckoutRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutRequest} message FinishCashBoostCheckoutRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCashBoostCheckoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FinishCashBoostCheckoutRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutRequest} FinishCashBoostCheckoutRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCashBoostCheckoutRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishCashBoostCheckoutRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FinishCashBoostCheckoutRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutRequest} FinishCashBoostCheckoutRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCashBoostCheckoutRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FinishCashBoostCheckoutRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FinishCashBoostCheckoutRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a FinishCashBoostCheckoutRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutRequest} FinishCashBoostCheckoutRequest
                 */
                FinishCashBoostCheckoutRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FinishCashBoostCheckoutRequest)
                        return object;
                    return new $root.visor.clientrpc.FinishCashBoostCheckoutRequest();
                };

                /**
                 * Creates a plain object from a FinishCashBoostCheckoutRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @static
                 * @param {visor.clientrpc.FinishCashBoostCheckoutRequest} message FinishCashBoostCheckoutRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FinishCashBoostCheckoutRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this FinishCashBoostCheckoutRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FinishCashBoostCheckoutRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FinishCashBoostCheckoutRequest;
            })();

            clientrpc.FinishCashBoostCheckoutResponse = (function() {

                /**
                 * Properties of a FinishCashBoostCheckoutResponse.
                 * @memberof visor.clientrpc
                 * @interface IFinishCashBoostCheckoutResponse
                 */

                /**
                 * Constructs a new FinishCashBoostCheckoutResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a FinishCashBoostCheckoutResponse.
                 * @implements IFinishCashBoostCheckoutResponse
                 * @constructor
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutResponse=} [properties] Properties to set
                 */
                function FinishCashBoostCheckoutResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new FinishCashBoostCheckoutResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutResponse} FinishCashBoostCheckoutResponse instance
                 */
                FinishCashBoostCheckoutResponse.create = function create(properties) {
                    return new FinishCashBoostCheckoutResponse(properties);
                };

                /**
                 * Encodes the specified FinishCashBoostCheckoutResponse message. Does not implicitly {@link visor.clientrpc.FinishCashBoostCheckoutResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutResponse} message FinishCashBoostCheckoutResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCashBoostCheckoutResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified FinishCashBoostCheckoutResponse message, length delimited. Does not implicitly {@link visor.clientrpc.FinishCashBoostCheckoutResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.IFinishCashBoostCheckoutResponse} message FinishCashBoostCheckoutResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FinishCashBoostCheckoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FinishCashBoostCheckoutResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutResponse} FinishCashBoostCheckoutResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCashBoostCheckoutResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.FinishCashBoostCheckoutResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FinishCashBoostCheckoutResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutResponse} FinishCashBoostCheckoutResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FinishCashBoostCheckoutResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FinishCashBoostCheckoutResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FinishCashBoostCheckoutResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a FinishCashBoostCheckoutResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.FinishCashBoostCheckoutResponse} FinishCashBoostCheckoutResponse
                 */
                FinishCashBoostCheckoutResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.FinishCashBoostCheckoutResponse)
                        return object;
                    return new $root.visor.clientrpc.FinishCashBoostCheckoutResponse();
                };

                /**
                 * Creates a plain object from a FinishCashBoostCheckoutResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @static
                 * @param {visor.clientrpc.FinishCashBoostCheckoutResponse} message FinishCashBoostCheckoutResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FinishCashBoostCheckoutResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this FinishCashBoostCheckoutResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.FinishCashBoostCheckoutResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FinishCashBoostCheckoutResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FinishCashBoostCheckoutResponse;
            })();

            clientrpc.UpdateDebitCardRequest = (function() {

                /**
                 * Properties of an UpdateDebitCardRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateDebitCardRequest
                 */

                /**
                 * Constructs a new UpdateDebitCardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateDebitCardRequest.
                 * @implements IUpdateDebitCardRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateDebitCardRequest=} [properties] Properties to set
                 */
                function UpdateDebitCardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateDebitCardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateDebitCardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateDebitCardRequest} UpdateDebitCardRequest instance
                 */
                UpdateDebitCardRequest.create = function create(properties) {
                    return new UpdateDebitCardRequest(properties);
                };

                /**
                 * Encodes the specified UpdateDebitCardRequest message. Does not implicitly {@link visor.clientrpc.UpdateDebitCardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateDebitCardRequest} message UpdateDebitCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDebitCardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateDebitCardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateDebitCardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateDebitCardRequest} message UpdateDebitCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDebitCardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateDebitCardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateDebitCardRequest} UpdateDebitCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDebitCardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateDebitCardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateDebitCardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateDebitCardRequest} UpdateDebitCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDebitCardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateDebitCardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDebitCardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateDebitCardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateDebitCardRequest} UpdateDebitCardRequest
                 */
                UpdateDebitCardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateDebitCardRequest)
                        return object;
                    return new $root.visor.clientrpc.UpdateDebitCardRequest();
                };

                /**
                 * Creates a plain object from an UpdateDebitCardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @static
                 * @param {visor.clientrpc.UpdateDebitCardRequest} message UpdateDebitCardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDebitCardRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateDebitCardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateDebitCardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDebitCardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateDebitCardRequest;
            })();

            clientrpc.UpdateDebitCardResponse = (function() {

                /**
                 * Properties of an UpdateDebitCardResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateDebitCardResponse
                 */

                /**
                 * Constructs a new UpdateDebitCardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateDebitCardResponse.
                 * @implements IUpdateDebitCardResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateDebitCardResponse=} [properties] Properties to set
                 */
                function UpdateDebitCardResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new UpdateDebitCardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateDebitCardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateDebitCardResponse} UpdateDebitCardResponse instance
                 */
                UpdateDebitCardResponse.create = function create(properties) {
                    return new UpdateDebitCardResponse(properties);
                };

                /**
                 * Encodes the specified UpdateDebitCardResponse message. Does not implicitly {@link visor.clientrpc.UpdateDebitCardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateDebitCardResponse} message UpdateDebitCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDebitCardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateDebitCardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateDebitCardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateDebitCardResponse} message UpdateDebitCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateDebitCardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateDebitCardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateDebitCardResponse} UpdateDebitCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDebitCardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateDebitCardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateDebitCardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateDebitCardResponse} UpdateDebitCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateDebitCardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateDebitCardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateDebitCardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an UpdateDebitCardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateDebitCardResponse} UpdateDebitCardResponse
                 */
                UpdateDebitCardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateDebitCardResponse)
                        return object;
                    return new $root.visor.clientrpc.UpdateDebitCardResponse();
                };

                /**
                 * Creates a plain object from an UpdateDebitCardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @static
                 * @param {visor.clientrpc.UpdateDebitCardResponse} message UpdateDebitCardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateDebitCardResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this UpdateDebitCardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateDebitCardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateDebitCardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UpdateDebitCardResponse;
            })();

            clientrpc.GetPlaidLinkTokenRequest = (function() {

                /**
                 * Properties of a GetPlaidLinkTokenRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetPlaidLinkTokenRequest
                 * @property {string|null} [cashboostToken] GetPlaidLinkTokenRequest cashboostToken
                 * @property {string|null} [boostCardProgramToken] GetPlaidLinkTokenRequest boostCardProgramToken
                 * @property {string|null} [bankAccountToken] GetPlaidLinkTokenRequest bankAccountToken
                 * @property {google.protobuf.IStringValue|null} [androidPackageName] GetPlaidLinkTokenRequest androidPackageName
                 * @property {google.protobuf.IStringValue|null} [redirectUri] GetPlaidLinkTokenRequest redirectUri
                 * @property {string|null} [stepToken] GetPlaidLinkTokenRequest stepToken
                 * @property {string|null} [workflowToken] GetPlaidLinkTokenRequest workflowToken
                 */

                /**
                 * Constructs a new GetPlaidLinkTokenRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetPlaidLinkTokenRequest.
                 * @implements IGetPlaidLinkTokenRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetPlaidLinkTokenRequest=} [properties] Properties to set
                 */
                function GetPlaidLinkTokenRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetPlaidLinkTokenRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 */
                GetPlaidLinkTokenRequest.prototype.cashboostToken = "";

                /**
                 * GetPlaidLinkTokenRequest boostCardProgramToken.
                 * @member {string} boostCardProgramToken
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 */
                GetPlaidLinkTokenRequest.prototype.boostCardProgramToken = "";

                /**
                 * GetPlaidLinkTokenRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 */
                GetPlaidLinkTokenRequest.prototype.bankAccountToken = "";

                /**
                 * GetPlaidLinkTokenRequest androidPackageName.
                 * @member {google.protobuf.IStringValue|null|undefined} androidPackageName
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 */
                GetPlaidLinkTokenRequest.prototype.androidPackageName = null;

                /**
                 * GetPlaidLinkTokenRequest redirectUri.
                 * @member {google.protobuf.IStringValue|null|undefined} redirectUri
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 */
                GetPlaidLinkTokenRequest.prototype.redirectUri = null;

                /**
                 * GetPlaidLinkTokenRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 */
                GetPlaidLinkTokenRequest.prototype.stepToken = "";

                /**
                 * GetPlaidLinkTokenRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 */
                GetPlaidLinkTokenRequest.prototype.workflowToken = "";

                /**
                 * Creates a new GetPlaidLinkTokenRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetPlaidLinkTokenRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetPlaidLinkTokenRequest} GetPlaidLinkTokenRequest instance
                 */
                GetPlaidLinkTokenRequest.create = function create(properties) {
                    return new GetPlaidLinkTokenRequest(properties);
                };

                /**
                 * Encodes the specified GetPlaidLinkTokenRequest message. Does not implicitly {@link visor.clientrpc.GetPlaidLinkTokenRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetPlaidLinkTokenRequest} message GetPlaidLinkTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPlaidLinkTokenRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    if (message.androidPackageName != null && message.hasOwnProperty("androidPackageName"))
                        $root.google.protobuf.StringValue.encode(message.androidPackageName, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.redirectUri != null && message.hasOwnProperty("redirectUri"))
                        $root.google.protobuf.StringValue.encode(message.redirectUri, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.workflowToken);
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.boostCardProgramToken);
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.bankAccountToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetPlaidLinkTokenRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetPlaidLinkTokenRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetPlaidLinkTokenRequest} message GetPlaidLinkTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPlaidLinkTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetPlaidLinkTokenRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetPlaidLinkTokenRequest} GetPlaidLinkTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPlaidLinkTokenRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetPlaidLinkTokenRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        case 6:
                            message.boostCardProgramToken = reader.string();
                            break;
                        case 7:
                            message.bankAccountToken = reader.string();
                            break;
                        case 2:
                            message.androidPackageName = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.redirectUri = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.stepToken = reader.string();
                            break;
                        case 5:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetPlaidLinkTokenRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetPlaidLinkTokenRequest} GetPlaidLinkTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPlaidLinkTokenRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetPlaidLinkTokenRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPlaidLinkTokenRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        if (!$util.isString(message.boostCardProgramToken))
                            return "boostCardProgramToken: string expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    if (message.androidPackageName != null && message.hasOwnProperty("androidPackageName")) {
                        var error = $root.google.protobuf.StringValue.verify(message.androidPackageName);
                        if (error)
                            return "androidPackageName." + error;
                    }
                    if (message.redirectUri != null && message.hasOwnProperty("redirectUri")) {
                        var error = $root.google.protobuf.StringValue.verify(message.redirectUri);
                        if (error)
                            return "redirectUri." + error;
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetPlaidLinkTokenRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetPlaidLinkTokenRequest} GetPlaidLinkTokenRequest
                 */
                GetPlaidLinkTokenRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetPlaidLinkTokenRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetPlaidLinkTokenRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.boostCardProgramToken != null)
                        message.boostCardProgramToken = String(object.boostCardProgramToken);
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    if (object.androidPackageName != null)
                        message.androidPackageName = { value: String(object.androidPackageName) };
                    if (object.redirectUri != null)
                        message.redirectUri = { value: String(object.redirectUri) };
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetPlaidLinkTokenRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @static
                 * @param {visor.clientrpc.GetPlaidLinkTokenRequest} message GetPlaidLinkTokenRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPlaidLinkTokenRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cashboostToken = "";
                        object.androidPackageName = null;
                        object.redirectUri = null;
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.boostCardProgramToken = "";
                        object.bankAccountToken = "";
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.androidPackageName != null && message.hasOwnProperty("androidPackageName"))
                        object.androidPackageName = message.androidPackageName.value;
                    if (message.redirectUri != null && message.hasOwnProperty("redirectUri"))
                        object.redirectUri = message.redirectUri.value;
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        object.boostCardProgramToken = message.boostCardProgramToken;
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    return object;
                };

                /**
                 * Converts this GetPlaidLinkTokenRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetPlaidLinkTokenRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPlaidLinkTokenRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetPlaidLinkTokenRequest;
            })();

            clientrpc.GetPlaidLinkTokenResponse = (function() {

                /**
                 * Properties of a GetPlaidLinkTokenResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetPlaidLinkTokenResponse
                 * @property {string|null} [linkToken] GetPlaidLinkTokenResponse linkToken
                 */

                /**
                 * Constructs a new GetPlaidLinkTokenResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetPlaidLinkTokenResponse.
                 * @implements IGetPlaidLinkTokenResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetPlaidLinkTokenResponse=} [properties] Properties to set
                 */
                function GetPlaidLinkTokenResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetPlaidLinkTokenResponse linkToken.
                 * @member {string} linkToken
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @instance
                 */
                GetPlaidLinkTokenResponse.prototype.linkToken = "";

                /**
                 * Creates a new GetPlaidLinkTokenResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetPlaidLinkTokenResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetPlaidLinkTokenResponse} GetPlaidLinkTokenResponse instance
                 */
                GetPlaidLinkTokenResponse.create = function create(properties) {
                    return new GetPlaidLinkTokenResponse(properties);
                };

                /**
                 * Encodes the specified GetPlaidLinkTokenResponse message. Does not implicitly {@link visor.clientrpc.GetPlaidLinkTokenResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetPlaidLinkTokenResponse} message GetPlaidLinkTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPlaidLinkTokenResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.linkToken != null && message.hasOwnProperty("linkToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.linkToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetPlaidLinkTokenResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetPlaidLinkTokenResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetPlaidLinkTokenResponse} message GetPlaidLinkTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetPlaidLinkTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetPlaidLinkTokenResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetPlaidLinkTokenResponse} GetPlaidLinkTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPlaidLinkTokenResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetPlaidLinkTokenResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.linkToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetPlaidLinkTokenResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetPlaidLinkTokenResponse} GetPlaidLinkTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetPlaidLinkTokenResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetPlaidLinkTokenResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetPlaidLinkTokenResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.linkToken != null && message.hasOwnProperty("linkToken"))
                        if (!$util.isString(message.linkToken))
                            return "linkToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetPlaidLinkTokenResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetPlaidLinkTokenResponse} GetPlaidLinkTokenResponse
                 */
                GetPlaidLinkTokenResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetPlaidLinkTokenResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetPlaidLinkTokenResponse();
                    if (object.linkToken != null)
                        message.linkToken = String(object.linkToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetPlaidLinkTokenResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @static
                 * @param {visor.clientrpc.GetPlaidLinkTokenResponse} message GetPlaidLinkTokenResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetPlaidLinkTokenResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.linkToken = "";
                    if (message.linkToken != null && message.hasOwnProperty("linkToken"))
                        object.linkToken = message.linkToken;
                    return object;
                };

                /**
                 * Converts this GetPlaidLinkTokenResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetPlaidLinkTokenResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetPlaidLinkTokenResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetPlaidLinkTokenResponse;
            })();

            clientrpc.CreateRiskDecisionRequest = (function() {

                /**
                 * Properties of a CreateRiskDecisionRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateRiskDecisionRequest
                 * @property {string|null} [cashboostToken] CreateRiskDecisionRequest cashboostToken
                 */

                /**
                 * Constructs a new CreateRiskDecisionRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateRiskDecisionRequest.
                 * @implements ICreateRiskDecisionRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateRiskDecisionRequest=} [properties] Properties to set
                 */
                function CreateRiskDecisionRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateRiskDecisionRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @instance
                 */
                CreateRiskDecisionRequest.prototype.cashboostToken = "";

                /**
                 * Creates a new CreateRiskDecisionRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {visor.clientrpc.ICreateRiskDecisionRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateRiskDecisionRequest} CreateRiskDecisionRequest instance
                 */
                CreateRiskDecisionRequest.create = function create(properties) {
                    return new CreateRiskDecisionRequest(properties);
                };

                /**
                 * Encodes the specified CreateRiskDecisionRequest message. Does not implicitly {@link visor.clientrpc.CreateRiskDecisionRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {visor.clientrpc.ICreateRiskDecisionRequest} message CreateRiskDecisionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRiskDecisionRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    return writer;
                };

                /**
                 * Encodes the specified CreateRiskDecisionRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateRiskDecisionRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {visor.clientrpc.ICreateRiskDecisionRequest} message CreateRiskDecisionRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRiskDecisionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateRiskDecisionRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateRiskDecisionRequest} CreateRiskDecisionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRiskDecisionRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateRiskDecisionRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateRiskDecisionRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateRiskDecisionRequest} CreateRiskDecisionRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRiskDecisionRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateRiskDecisionRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateRiskDecisionRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreateRiskDecisionRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateRiskDecisionRequest} CreateRiskDecisionRequest
                 */
                CreateRiskDecisionRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateRiskDecisionRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateRiskDecisionRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateRiskDecisionRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @static
                 * @param {visor.clientrpc.CreateRiskDecisionRequest} message CreateRiskDecisionRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateRiskDecisionRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.cashboostToken = "";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    return object;
                };

                /**
                 * Converts this CreateRiskDecisionRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateRiskDecisionRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateRiskDecisionRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateRiskDecisionRequest;
            })();

            clientrpc.CreateRiskDecisionResponse = (function() {

                /**
                 * Properties of a CreateRiskDecisionResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateRiskDecisionResponse
                 */

                /**
                 * Constructs a new CreateRiskDecisionResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateRiskDecisionResponse.
                 * @implements ICreateRiskDecisionResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateRiskDecisionResponse=} [properties] Properties to set
                 */
                function CreateRiskDecisionResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateRiskDecisionResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {visor.clientrpc.ICreateRiskDecisionResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateRiskDecisionResponse} CreateRiskDecisionResponse instance
                 */
                CreateRiskDecisionResponse.create = function create(properties) {
                    return new CreateRiskDecisionResponse(properties);
                };

                /**
                 * Encodes the specified CreateRiskDecisionResponse message. Does not implicitly {@link visor.clientrpc.CreateRiskDecisionResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {visor.clientrpc.ICreateRiskDecisionResponse} message CreateRiskDecisionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRiskDecisionResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateRiskDecisionResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateRiskDecisionResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {visor.clientrpc.ICreateRiskDecisionResponse} message CreateRiskDecisionResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateRiskDecisionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateRiskDecisionResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateRiskDecisionResponse} CreateRiskDecisionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRiskDecisionResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateRiskDecisionResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateRiskDecisionResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateRiskDecisionResponse} CreateRiskDecisionResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateRiskDecisionResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateRiskDecisionResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateRiskDecisionResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateRiskDecisionResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateRiskDecisionResponse} CreateRiskDecisionResponse
                 */
                CreateRiskDecisionResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateRiskDecisionResponse)
                        return object;
                    return new $root.visor.clientrpc.CreateRiskDecisionResponse();
                };

                /**
                 * Creates a plain object from a CreateRiskDecisionResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @static
                 * @param {visor.clientrpc.CreateRiskDecisionResponse} message CreateRiskDecisionResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateRiskDecisionResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateRiskDecisionResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateRiskDecisionResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateRiskDecisionResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateRiskDecisionResponse;
            })();

            clientrpc.GetSubsequentAdvanceEligibilityRequest = (function() {

                /**
                 * Properties of a GetSubsequentAdvanceEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetSubsequentAdvanceEligibilityRequest
                 * @property {string|null} [cashboostToken] GetSubsequentAdvanceEligibilityRequest cashboostToken
                 * @property {visor.clientrpc.GetSubsequentAdvanceEligibilityRequest.AdvanceAccountingType|null} [accountingType] GetSubsequentAdvanceEligibilityRequest accountingType
                 */

                /**
                 * Constructs a new GetSubsequentAdvanceEligibilityRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetSubsequentAdvanceEligibilityRequest.
                 * @implements IGetSubsequentAdvanceEligibilityRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityRequest=} [properties] Properties to set
                 */
                function GetSubsequentAdvanceEligibilityRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSubsequentAdvanceEligibilityRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @instance
                 */
                GetSubsequentAdvanceEligibilityRequest.prototype.cashboostToken = "";

                /**
                 * GetSubsequentAdvanceEligibilityRequest accountingType.
                 * @member {visor.clientrpc.GetSubsequentAdvanceEligibilityRequest.AdvanceAccountingType} accountingType
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @instance
                 */
                GetSubsequentAdvanceEligibilityRequest.prototype.accountingType = 0;

                /**
                 * Creates a new GetSubsequentAdvanceEligibilityRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityRequest} GetSubsequentAdvanceEligibilityRequest instance
                 */
                GetSubsequentAdvanceEligibilityRequest.create = function create(properties) {
                    return new GetSubsequentAdvanceEligibilityRequest(properties);
                };

                /**
                 * Encodes the specified GetSubsequentAdvanceEligibilityRequest message. Does not implicitly {@link visor.clientrpc.GetSubsequentAdvanceEligibilityRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityRequest} message GetSubsequentAdvanceEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSubsequentAdvanceEligibilityRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    if (message.accountingType != null && message.hasOwnProperty("accountingType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.accountingType);
                    return writer;
                };

                /**
                 * Encodes the specified GetSubsequentAdvanceEligibilityRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetSubsequentAdvanceEligibilityRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityRequest} message GetSubsequentAdvanceEligibilityRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSubsequentAdvanceEligibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetSubsequentAdvanceEligibilityRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityRequest} GetSubsequentAdvanceEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSubsequentAdvanceEligibilityRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetSubsequentAdvanceEligibilityRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        case 2:
                            message.accountingType = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetSubsequentAdvanceEligibilityRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityRequest} GetSubsequentAdvanceEligibilityRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSubsequentAdvanceEligibilityRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetSubsequentAdvanceEligibilityRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSubsequentAdvanceEligibilityRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.accountingType != null && message.hasOwnProperty("accountingType"))
                        switch (message.accountingType) {
                        default:
                            return "accountingType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetSubsequentAdvanceEligibilityRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityRequest} GetSubsequentAdvanceEligibilityRequest
                 */
                GetSubsequentAdvanceEligibilityRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetSubsequentAdvanceEligibilityRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetSubsequentAdvanceEligibilityRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    switch (object.accountingType) {
                    case "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_INVALID":
                    case 0:
                        message.accountingType = 0;
                        break;
                    case "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_AIRTABLE":
                    case 1:
                        message.accountingType = 1;
                        break;
                    case "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_STATE_MACHINE":
                    case 2:
                        message.accountingType = 2;
                        break;
                    case "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_LEDGER":
                    case 3:
                        message.accountingType = 3;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetSubsequentAdvanceEligibilityRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @static
                 * @param {visor.clientrpc.GetSubsequentAdvanceEligibilityRequest} message GetSubsequentAdvanceEligibilityRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSubsequentAdvanceEligibilityRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cashboostToken = "";
                        object.accountingType = options.enums === String ? "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_INVALID" : 0;
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.accountingType != null && message.hasOwnProperty("accountingType"))
                        object.accountingType = options.enums === String ? $root.visor.clientrpc.GetSubsequentAdvanceEligibilityRequest.AdvanceAccountingType[message.accountingType] : message.accountingType;
                    return object;
                };

                /**
                 * Converts this GetSubsequentAdvanceEligibilityRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSubsequentAdvanceEligibilityRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * AdvanceAccountingType enum.
                 * @name visor.clientrpc.GetSubsequentAdvanceEligibilityRequest.AdvanceAccountingType
                 * @enum {string}
                 * @property {number} GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_INVALID=0 GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_INVALID value
                 * @property {number} GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_AIRTABLE=1 GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_AIRTABLE value
                 * @property {number} GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_STATE_MACHINE=2 GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_STATE_MACHINE value
                 * @property {number} GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_LEDGER=3 GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_LEDGER value
                 */
                GetSubsequentAdvanceEligibilityRequest.AdvanceAccountingType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_AIRTABLE"] = 1;
                    values[valuesById[2] = "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_STATE_MACHINE"] = 2;
                    values[valuesById[3] = "GET_SUBSEQUENT_ADVANCE_ELIGIBILITY_REQUEST_ADVANCE_ACCOUNTING_TYPE_LEDGER"] = 3;
                    return values;
                })();

                return GetSubsequentAdvanceEligibilityRequest;
            })();

            clientrpc.GetSubsequentAdvanceEligibilityResponse = (function() {

                /**
                 * Properties of a GetSubsequentAdvanceEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetSubsequentAdvanceEligibilityResponse
                 * @property {boolean|null} [isEligible] GetSubsequentAdvanceEligibilityResponse isEligible
                 */

                /**
                 * Constructs a new GetSubsequentAdvanceEligibilityResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetSubsequentAdvanceEligibilityResponse.
                 * @implements IGetSubsequentAdvanceEligibilityResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityResponse=} [properties] Properties to set
                 */
                function GetSubsequentAdvanceEligibilityResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetSubsequentAdvanceEligibilityResponse isEligible.
                 * @member {boolean} isEligible
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @instance
                 */
                GetSubsequentAdvanceEligibilityResponse.prototype.isEligible = false;

                /**
                 * Creates a new GetSubsequentAdvanceEligibilityResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityResponse} GetSubsequentAdvanceEligibilityResponse instance
                 */
                GetSubsequentAdvanceEligibilityResponse.create = function create(properties) {
                    return new GetSubsequentAdvanceEligibilityResponse(properties);
                };

                /**
                 * Encodes the specified GetSubsequentAdvanceEligibilityResponse message. Does not implicitly {@link visor.clientrpc.GetSubsequentAdvanceEligibilityResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityResponse} message GetSubsequentAdvanceEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSubsequentAdvanceEligibilityResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isEligible);
                    return writer;
                };

                /**
                 * Encodes the specified GetSubsequentAdvanceEligibilityResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetSubsequentAdvanceEligibilityResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.IGetSubsequentAdvanceEligibilityResponse} message GetSubsequentAdvanceEligibilityResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetSubsequentAdvanceEligibilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetSubsequentAdvanceEligibilityResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityResponse} GetSubsequentAdvanceEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSubsequentAdvanceEligibilityResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetSubsequentAdvanceEligibilityResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isEligible = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetSubsequentAdvanceEligibilityResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityResponse} GetSubsequentAdvanceEligibilityResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetSubsequentAdvanceEligibilityResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetSubsequentAdvanceEligibilityResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetSubsequentAdvanceEligibilityResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        if (typeof message.isEligible !== "boolean")
                            return "isEligible: boolean expected";
                    return null;
                };

                /**
                 * Creates a GetSubsequentAdvanceEligibilityResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetSubsequentAdvanceEligibilityResponse} GetSubsequentAdvanceEligibilityResponse
                 */
                GetSubsequentAdvanceEligibilityResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetSubsequentAdvanceEligibilityResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetSubsequentAdvanceEligibilityResponse();
                    if (object.isEligible != null)
                        message.isEligible = Boolean(object.isEligible);
                    return message;
                };

                /**
                 * Creates a plain object from a GetSubsequentAdvanceEligibilityResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @static
                 * @param {visor.clientrpc.GetSubsequentAdvanceEligibilityResponse} message GetSubsequentAdvanceEligibilityResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetSubsequentAdvanceEligibilityResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.isEligible = false;
                    if (message.isEligible != null && message.hasOwnProperty("isEligible"))
                        object.isEligible = message.isEligible;
                    return object;
                };

                /**
                 * Converts this GetSubsequentAdvanceEligibilityResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetSubsequentAdvanceEligibilityResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetSubsequentAdvanceEligibilityResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetSubsequentAdvanceEligibilityResponse;
            })();

            clientrpc.BeginBoostCardProgramSetupWorkflowRequest = (function() {

                /**
                 * Properties of a BeginBoostCardProgramSetupWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginBoostCardProgramSetupWorkflowRequest
                 * @property {string|null} [boostCardProgramToken] BeginBoostCardProgramSetupWorkflowRequest boostCardProgramToken
                 */

                /**
                 * Constructs a new BeginBoostCardProgramSetupWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginBoostCardProgramSetupWorkflowRequest.
                 * @implements IBeginBoostCardProgramSetupWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowRequest=} [properties] Properties to set
                 */
                function BeginBoostCardProgramSetupWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BeginBoostCardProgramSetupWorkflowRequest boostCardProgramToken.
                 * @member {string} boostCardProgramToken
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @instance
                 */
                BeginBoostCardProgramSetupWorkflowRequest.prototype.boostCardProgramToken = "";

                /**
                 * Creates a new BeginBoostCardProgramSetupWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest} BeginBoostCardProgramSetupWorkflowRequest instance
                 */
                BeginBoostCardProgramSetupWorkflowRequest.create = function create(properties) {
                    return new BeginBoostCardProgramSetupWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginBoostCardProgramSetupWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowRequest} message BeginBoostCardProgramSetupWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardProgramSetupWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.boostCardProgramToken);
                    return writer;
                };

                /**
                 * Encodes the specified BeginBoostCardProgramSetupWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowRequest} message BeginBoostCardProgramSetupWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardProgramSetupWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginBoostCardProgramSetupWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest} BeginBoostCardProgramSetupWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardProgramSetupWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.boostCardProgramToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginBoostCardProgramSetupWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest} BeginBoostCardProgramSetupWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardProgramSetupWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginBoostCardProgramSetupWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginBoostCardProgramSetupWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        if (!$util.isString(message.boostCardProgramToken))
                            return "boostCardProgramToken: string expected";
                    return null;
                };

                /**
                 * Creates a BeginBoostCardProgramSetupWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest} BeginBoostCardProgramSetupWorkflowRequest
                 */
                BeginBoostCardProgramSetupWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest)
                        return object;
                    var message = new $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest();
                    if (object.boostCardProgramToken != null)
                        message.boostCardProgramToken = String(object.boostCardProgramToken);
                    return message;
                };

                /**
                 * Creates a plain object from a BeginBoostCardProgramSetupWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest} message BeginBoostCardProgramSetupWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginBoostCardProgramSetupWorkflowRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.boostCardProgramToken = "";
                    if (message.boostCardProgramToken != null && message.hasOwnProperty("boostCardProgramToken"))
                        object.boostCardProgramToken = message.boostCardProgramToken;
                    return object;
                };

                /**
                 * Converts this BeginBoostCardProgramSetupWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginBoostCardProgramSetupWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginBoostCardProgramSetupWorkflowRequest;
            })();

            clientrpc.BeginBoostCardProgramSetupWorkflowResponse = (function() {

                /**
                 * Properties of a BeginBoostCardProgramSetupWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginBoostCardProgramSetupWorkflowResponse
                 */

                /**
                 * Constructs a new BeginBoostCardProgramSetupWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginBoostCardProgramSetupWorkflowResponse.
                 * @implements IBeginBoostCardProgramSetupWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowResponse=} [properties] Properties to set
                 */
                function BeginBoostCardProgramSetupWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginBoostCardProgramSetupWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse} BeginBoostCardProgramSetupWorkflowResponse instance
                 */
                BeginBoostCardProgramSetupWorkflowResponse.create = function create(properties) {
                    return new BeginBoostCardProgramSetupWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginBoostCardProgramSetupWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowResponse} message BeginBoostCardProgramSetupWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardProgramSetupWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginBoostCardProgramSetupWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginBoostCardProgramSetupWorkflowResponse} message BeginBoostCardProgramSetupWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginBoostCardProgramSetupWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginBoostCardProgramSetupWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse} BeginBoostCardProgramSetupWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardProgramSetupWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginBoostCardProgramSetupWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse} BeginBoostCardProgramSetupWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginBoostCardProgramSetupWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginBoostCardProgramSetupWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginBoostCardProgramSetupWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginBoostCardProgramSetupWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse} BeginBoostCardProgramSetupWorkflowResponse
                 */
                BeginBoostCardProgramSetupWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginBoostCardProgramSetupWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse} message BeginBoostCardProgramSetupWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginBoostCardProgramSetupWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginBoostCardProgramSetupWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginBoostCardProgramSetupWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginBoostCardProgramSetupWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginBoostCardProgramSetupWorkflowResponse;
            })();

            clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest = (function() {

                /**
                 * Properties of a BeginCreditBuilderProgramSetupWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginCreditBuilderProgramSetupWorkflowRequest
                 */

                /**
                 * Constructs a new BeginCreditBuilderProgramSetupWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginCreditBuilderProgramSetupWorkflowRequest.
                 * @implements IBeginCreditBuilderProgramSetupWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowRequest=} [properties] Properties to set
                 */
                function BeginCreditBuilderProgramSetupWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginCreditBuilderProgramSetupWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest} BeginCreditBuilderProgramSetupWorkflowRequest instance
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.create = function create(properties) {
                    return new BeginCreditBuilderProgramSetupWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginCreditBuilderProgramSetupWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowRequest} message BeginCreditBuilderProgramSetupWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginCreditBuilderProgramSetupWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowRequest} message BeginCreditBuilderProgramSetupWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginCreditBuilderProgramSetupWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest} BeginCreditBuilderProgramSetupWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginCreditBuilderProgramSetupWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest} BeginCreditBuilderProgramSetupWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginCreditBuilderProgramSetupWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginCreditBuilderProgramSetupWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest} BeginCreditBuilderProgramSetupWorkflowRequest
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest)
                        return object;
                    return new $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest();
                };

                /**
                 * Creates a plain object from a BeginCreditBuilderProgramSetupWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest} message BeginCreditBuilderProgramSetupWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginCreditBuilderProgramSetupWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginCreditBuilderProgramSetupWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginCreditBuilderProgramSetupWorkflowRequest;
            })();

            clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse = (function() {

                /**
                 * Properties of a BeginCreditBuilderProgramSetupWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginCreditBuilderProgramSetupWorkflowResponse
                 */

                /**
                 * Constructs a new BeginCreditBuilderProgramSetupWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginCreditBuilderProgramSetupWorkflowResponse.
                 * @implements IBeginCreditBuilderProgramSetupWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowResponse=} [properties] Properties to set
                 */
                function BeginCreditBuilderProgramSetupWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginCreditBuilderProgramSetupWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse} BeginCreditBuilderProgramSetupWorkflowResponse instance
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.create = function create(properties) {
                    return new BeginCreditBuilderProgramSetupWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginCreditBuilderProgramSetupWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowResponse} message BeginCreditBuilderProgramSetupWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginCreditBuilderProgramSetupWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginCreditBuilderProgramSetupWorkflowResponse} message BeginCreditBuilderProgramSetupWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginCreditBuilderProgramSetupWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse} BeginCreditBuilderProgramSetupWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginCreditBuilderProgramSetupWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse} BeginCreditBuilderProgramSetupWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginCreditBuilderProgramSetupWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginCreditBuilderProgramSetupWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse} BeginCreditBuilderProgramSetupWorkflowResponse
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginCreditBuilderProgramSetupWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse} message BeginCreditBuilderProgramSetupWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginCreditBuilderProgramSetupWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginCreditBuilderProgramSetupWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginCreditBuilderProgramSetupWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginCreditBuilderProgramSetupWorkflowResponse;
            })();

            clientrpc.CompleteCreditBuilderProgramIntroStepRequest = (function() {

                /**
                 * Properties of a CompleteCreditBuilderProgramIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCreditBuilderProgramIntroStepRequest
                 * @property {string|null} [stepToken] CompleteCreditBuilderProgramIntroStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteCreditBuilderProgramIntroStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteCreditBuilderProgramIntroStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCreditBuilderProgramIntroStepRequest.
                 * @implements ICompleteCreditBuilderProgramIntroStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepRequest=} [properties] Properties to set
                 */
                function CompleteCreditBuilderProgramIntroStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteCreditBuilderProgramIntroStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @instance
                 */
                CompleteCreditBuilderProgramIntroStepRequest.prototype.stepToken = "";

                /**
                 * CompleteCreditBuilderProgramIntroStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @instance
                 */
                CompleteCreditBuilderProgramIntroStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteCreditBuilderProgramIntroStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest} CompleteCreditBuilderProgramIntroStepRequest instance
                 */
                CompleteCreditBuilderProgramIntroStepRequest.create = function create(properties) {
                    return new CompleteCreditBuilderProgramIntroStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteCreditBuilderProgramIntroStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepRequest} message CompleteCreditBuilderProgramIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditBuilderProgramIntroStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCreditBuilderProgramIntroStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepRequest} message CompleteCreditBuilderProgramIntroStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditBuilderProgramIntroStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCreditBuilderProgramIntroStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest} CompleteCreditBuilderProgramIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditBuilderProgramIntroStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCreditBuilderProgramIntroStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest} CompleteCreditBuilderProgramIntroStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditBuilderProgramIntroStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCreditBuilderProgramIntroStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCreditBuilderProgramIntroStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteCreditBuilderProgramIntroStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest} CompleteCreditBuilderProgramIntroStepRequest
                 */
                CompleteCreditBuilderProgramIntroStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteCreditBuilderProgramIntroStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest} message CompleteCreditBuilderProgramIntroStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCreditBuilderProgramIntroStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteCreditBuilderProgramIntroStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCreditBuilderProgramIntroStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCreditBuilderProgramIntroStepRequest;
            })();

            clientrpc.CompleteCreditBuilderProgramIntroStepResponse = (function() {

                /**
                 * Properties of a CompleteCreditBuilderProgramIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCreditBuilderProgramIntroStepResponse
                 */

                /**
                 * Constructs a new CompleteCreditBuilderProgramIntroStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCreditBuilderProgramIntroStepResponse.
                 * @implements ICompleteCreditBuilderProgramIntroStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepResponse=} [properties] Properties to set
                 */
                function CompleteCreditBuilderProgramIntroStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteCreditBuilderProgramIntroStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse} CompleteCreditBuilderProgramIntroStepResponse instance
                 */
                CompleteCreditBuilderProgramIntroStepResponse.create = function create(properties) {
                    return new CompleteCreditBuilderProgramIntroStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteCreditBuilderProgramIntroStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepResponse} message CompleteCreditBuilderProgramIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditBuilderProgramIntroStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCreditBuilderProgramIntroStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCreditBuilderProgramIntroStepResponse} message CompleteCreditBuilderProgramIntroStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCreditBuilderProgramIntroStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCreditBuilderProgramIntroStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse} CompleteCreditBuilderProgramIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditBuilderProgramIntroStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCreditBuilderProgramIntroStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse} CompleteCreditBuilderProgramIntroStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCreditBuilderProgramIntroStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCreditBuilderProgramIntroStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCreditBuilderProgramIntroStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteCreditBuilderProgramIntroStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse} CompleteCreditBuilderProgramIntroStepResponse
                 */
                CompleteCreditBuilderProgramIntroStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteCreditBuilderProgramIntroStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse} message CompleteCreditBuilderProgramIntroStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCreditBuilderProgramIntroStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteCreditBuilderProgramIntroStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCreditBuilderProgramIntroStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCreditBuilderProgramIntroStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCreditBuilderProgramIntroStepResponse;
            })();

            clientrpc.GetCreditBuilderProgramCheckoutOfferRequest = (function() {

                /**
                 * Properties of a GetCreditBuilderProgramCheckoutOfferRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditBuilderProgramCheckoutOfferRequest
                 * @property {boolean|null} [returnTestFixtures] GetCreditBuilderProgramCheckoutOfferRequest returnTestFixtures
                 * @property {visor.clientrpc.CreditBuilderProgramTestFixture|null} [checkoutTestFixture] GetCreditBuilderProgramCheckoutOfferRequest checkoutTestFixture
                 */

                /**
                 * Constructs a new GetCreditBuilderProgramCheckoutOfferRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditBuilderProgramCheckoutOfferRequest.
                 * @implements IGetCreditBuilderProgramCheckoutOfferRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferRequest=} [properties] Properties to set
                 */
                function GetCreditBuilderProgramCheckoutOfferRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditBuilderProgramCheckoutOfferRequest returnTestFixtures.
                 * @member {boolean} returnTestFixtures
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @instance
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.prototype.returnTestFixtures = false;

                /**
                 * GetCreditBuilderProgramCheckoutOfferRequest checkoutTestFixture.
                 * @member {visor.clientrpc.CreditBuilderProgramTestFixture} checkoutTestFixture
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @instance
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.prototype.checkoutTestFixture = 0;

                /**
                 * Creates a new GetCreditBuilderProgramCheckoutOfferRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest} GetCreditBuilderProgramCheckoutOfferRequest instance
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.create = function create(properties) {
                    return new GetCreditBuilderProgramCheckoutOfferRequest(properties);
                };

                /**
                 * Encodes the specified GetCreditBuilderProgramCheckoutOfferRequest message. Does not implicitly {@link visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferRequest} message GetCreditBuilderProgramCheckoutOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.returnTestFixtures);
                    if (message.checkoutTestFixture != null && message.hasOwnProperty("checkoutTestFixture"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.checkoutTestFixture);
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditBuilderProgramCheckoutOfferRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferRequest} message GetCreditBuilderProgramCheckoutOfferRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditBuilderProgramCheckoutOfferRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest} GetCreditBuilderProgramCheckoutOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.returnTestFixtures = reader.bool();
                            break;
                        case 2:
                            message.checkoutTestFixture = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditBuilderProgramCheckoutOfferRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest} GetCreditBuilderProgramCheckoutOfferRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditBuilderProgramCheckoutOfferRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        if (typeof message.returnTestFixtures !== "boolean")
                            return "returnTestFixtures: boolean expected";
                    if (message.checkoutTestFixture != null && message.hasOwnProperty("checkoutTestFixture"))
                        switch (message.checkoutTestFixture) {
                        default:
                            return "checkoutTestFixture: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetCreditBuilderProgramCheckoutOfferRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest} GetCreditBuilderProgramCheckoutOfferRequest
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest();
                    if (object.returnTestFixtures != null)
                        message.returnTestFixtures = Boolean(object.returnTestFixtures);
                    switch (object.checkoutTestFixture) {
                    case "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_INVALID":
                    case 0:
                        message.checkoutTestFixture = 0;
                        break;
                    case "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_NO_SUB":
                    case 1:
                        message.checkoutTestFixture = 1;
                        break;
                    case "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_CURRENT":
                    case 2:
                        message.checkoutTestFixture = 2;
                        break;
                    case "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_NO_DISCOUNT":
                    case 3:
                        message.checkoutTestFixture = 3;
                        break;
                    case "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_DISCOUNT":
                    case 4:
                        message.checkoutTestFixture = 4;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditBuilderProgramCheckoutOfferRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @static
                 * @param {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest} message GetCreditBuilderProgramCheckoutOfferRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.returnTestFixtures = false;
                        object.checkoutTestFixture = options.enums === String ? "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_INVALID" : 0;
                    }
                    if (message.returnTestFixtures != null && message.hasOwnProperty("returnTestFixtures"))
                        object.returnTestFixtures = message.returnTestFixtures;
                    if (message.checkoutTestFixture != null && message.hasOwnProperty("checkoutTestFixture"))
                        object.checkoutTestFixture = options.enums === String ? $root.visor.clientrpc.CreditBuilderProgramTestFixture[message.checkoutTestFixture] : message.checkoutTestFixture;
                    return object;
                };

                /**
                 * Converts this GetCreditBuilderProgramCheckoutOfferRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditBuilderProgramCheckoutOfferRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditBuilderProgramCheckoutOfferRequest;
            })();

            clientrpc.GetCreditBuilderProgramCheckoutOfferResponse = (function() {

                /**
                 * Properties of a GetCreditBuilderProgramCheckoutOfferResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetCreditBuilderProgramCheckoutOfferResponse
                 * @property {visor.clientrpc.ISubscription|null} [existingSubscription] GetCreditBuilderProgramCheckoutOfferResponse existingSubscription
                 */

                /**
                 * Constructs a new GetCreditBuilderProgramCheckoutOfferResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetCreditBuilderProgramCheckoutOfferResponse.
                 * @implements IGetCreditBuilderProgramCheckoutOfferResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferResponse=} [properties] Properties to set
                 */
                function GetCreditBuilderProgramCheckoutOfferResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetCreditBuilderProgramCheckoutOfferResponse existingSubscription.
                 * @member {visor.clientrpc.ISubscription|null|undefined} existingSubscription
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @instance
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.prototype.existingSubscription = null;

                /**
                 * Creates a new GetCreditBuilderProgramCheckoutOfferResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse} GetCreditBuilderProgramCheckoutOfferResponse instance
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.create = function create(properties) {
                    return new GetCreditBuilderProgramCheckoutOfferResponse(properties);
                };

                /**
                 * Encodes the specified GetCreditBuilderProgramCheckoutOfferResponse message. Does not implicitly {@link visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferResponse} message GetCreditBuilderProgramCheckoutOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription"))
                        $root.visor.clientrpc.Subscription.encode(message.existingSubscription, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetCreditBuilderProgramCheckoutOfferResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.IGetCreditBuilderProgramCheckoutOfferResponse} message GetCreditBuilderProgramCheckoutOfferResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetCreditBuilderProgramCheckoutOfferResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse} GetCreditBuilderProgramCheckoutOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.existingSubscription = $root.visor.clientrpc.Subscription.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetCreditBuilderProgramCheckoutOfferResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse} GetCreditBuilderProgramCheckoutOfferResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetCreditBuilderProgramCheckoutOfferResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription")) {
                        var error = $root.visor.clientrpc.Subscription.verify(message.existingSubscription);
                        if (error)
                            return "existingSubscription." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetCreditBuilderProgramCheckoutOfferResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse} GetCreditBuilderProgramCheckoutOfferResponse
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse();
                    if (object.existingSubscription != null) {
                        if (typeof object.existingSubscription !== "object")
                            throw TypeError(".visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse.existingSubscription: object expected");
                        message.existingSubscription = $root.visor.clientrpc.Subscription.fromObject(object.existingSubscription);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetCreditBuilderProgramCheckoutOfferResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @static
                 * @param {visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse} message GetCreditBuilderProgramCheckoutOfferResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.existingSubscription = null;
                    if (message.existingSubscription != null && message.hasOwnProperty("existingSubscription"))
                        object.existingSubscription = $root.visor.clientrpc.Subscription.toObject(message.existingSubscription, options);
                    return object;
                };

                /**
                 * Converts this GetCreditBuilderProgramCheckoutOfferResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetCreditBuilderProgramCheckoutOfferResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetCreditBuilderProgramCheckoutOfferResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetCreditBuilderProgramCheckoutOfferResponse;
            })();

            /**
             * CreditBuilderProgramTestFixture enum.
             * @name visor.clientrpc.CreditBuilderProgramTestFixture
             * @enum {string}
             * @property {number} CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_INVALID=0 CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_INVALID value
             * @property {number} CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_NO_SUB=1 CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_NO_SUB value
             * @property {number} CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_CURRENT=2 CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_CURRENT value
             * @property {number} CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_NO_DISCOUNT=3 CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_NO_DISCOUNT value
             * @property {number} CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_DISCOUNT=4 CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_DISCOUNT value
             */
            clientrpc.CreditBuilderProgramTestFixture = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_INVALID"] = 0;
                values[valuesById[1] = "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_NO_SUB"] = 1;
                values[valuesById[2] = "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_CURRENT"] = 2;
                values[valuesById[3] = "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_NO_DISCOUNT"] = 3;
                values[valuesById[4] = "CREDIT_BUILDER_PROGRAM_TEST_FIXTURE_SUB_OVERDUE_DISCOUNT"] = 4;
                return values;
            })();

            clientrpc.CreateCashboostObjectIfRequiredRequest = (function() {

                /**
                 * Properties of a CreateCashboostObjectIfRequiredRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateCashboostObjectIfRequiredRequest
                 */

                /**
                 * Constructs a new CreateCashboostObjectIfRequiredRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateCashboostObjectIfRequiredRequest.
                 * @implements ICreateCashboostObjectIfRequiredRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredRequest=} [properties] Properties to set
                 */
                function CreateCashboostObjectIfRequiredRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CreateCashboostObjectIfRequiredRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredRequest} CreateCashboostObjectIfRequiredRequest instance
                 */
                CreateCashboostObjectIfRequiredRequest.create = function create(properties) {
                    return new CreateCashboostObjectIfRequiredRequest(properties);
                };

                /**
                 * Encodes the specified CreateCashboostObjectIfRequiredRequest message. Does not implicitly {@link visor.clientrpc.CreateCashboostObjectIfRequiredRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredRequest} message CreateCashboostObjectIfRequiredRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCashboostObjectIfRequiredRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CreateCashboostObjectIfRequiredRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCashboostObjectIfRequiredRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredRequest} message CreateCashboostObjectIfRequiredRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCashboostObjectIfRequiredRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateCashboostObjectIfRequiredRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredRequest} CreateCashboostObjectIfRequiredRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCashboostObjectIfRequiredRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCashboostObjectIfRequiredRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateCashboostObjectIfRequiredRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredRequest} CreateCashboostObjectIfRequiredRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCashboostObjectIfRequiredRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateCashboostObjectIfRequiredRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCashboostObjectIfRequiredRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CreateCashboostObjectIfRequiredRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredRequest} CreateCashboostObjectIfRequiredRequest
                 */
                CreateCashboostObjectIfRequiredRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateCashboostObjectIfRequiredRequest)
                        return object;
                    return new $root.visor.clientrpc.CreateCashboostObjectIfRequiredRequest();
                };

                /**
                 * Creates a plain object from a CreateCashboostObjectIfRequiredRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @static
                 * @param {visor.clientrpc.CreateCashboostObjectIfRequiredRequest} message CreateCashboostObjectIfRequiredRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCashboostObjectIfRequiredRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CreateCashboostObjectIfRequiredRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCashboostObjectIfRequiredRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateCashboostObjectIfRequiredRequest;
            })();

            clientrpc.CreateCashboostObjectIfRequiredResponse = (function() {

                /**
                 * Properties of a CreateCashboostObjectIfRequiredResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateCashboostObjectIfRequiredResponse
                 * @property {boolean|null} [cashboostAlreadyExists] CreateCashboostObjectIfRequiredResponse cashboostAlreadyExists
                 */

                /**
                 * Constructs a new CreateCashboostObjectIfRequiredResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateCashboostObjectIfRequiredResponse.
                 * @implements ICreateCashboostObjectIfRequiredResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredResponse=} [properties] Properties to set
                 */
                function CreateCashboostObjectIfRequiredResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateCashboostObjectIfRequiredResponse cashboostAlreadyExists.
                 * @member {boolean} cashboostAlreadyExists
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @instance
                 */
                CreateCashboostObjectIfRequiredResponse.prototype.cashboostAlreadyExists = false;

                /**
                 * Creates a new CreateCashboostObjectIfRequiredResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredResponse} CreateCashboostObjectIfRequiredResponse instance
                 */
                CreateCashboostObjectIfRequiredResponse.create = function create(properties) {
                    return new CreateCashboostObjectIfRequiredResponse(properties);
                };

                /**
                 * Encodes the specified CreateCashboostObjectIfRequiredResponse message. Does not implicitly {@link visor.clientrpc.CreateCashboostObjectIfRequiredResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredResponse} message CreateCashboostObjectIfRequiredResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCashboostObjectIfRequiredResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostAlreadyExists != null && message.hasOwnProperty("cashboostAlreadyExists"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.cashboostAlreadyExists);
                    return writer;
                };

                /**
                 * Encodes the specified CreateCashboostObjectIfRequiredResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCashboostObjectIfRequiredResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCashboostObjectIfRequiredResponse} message CreateCashboostObjectIfRequiredResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCashboostObjectIfRequiredResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateCashboostObjectIfRequiredResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredResponse} CreateCashboostObjectIfRequiredResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCashboostObjectIfRequiredResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCashboostObjectIfRequiredResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostAlreadyExists = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateCashboostObjectIfRequiredResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredResponse} CreateCashboostObjectIfRequiredResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCashboostObjectIfRequiredResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateCashboostObjectIfRequiredResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCashboostObjectIfRequiredResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostAlreadyExists != null && message.hasOwnProperty("cashboostAlreadyExists"))
                        if (typeof message.cashboostAlreadyExists !== "boolean")
                            return "cashboostAlreadyExists: boolean expected";
                    return null;
                };

                /**
                 * Creates a CreateCashboostObjectIfRequiredResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateCashboostObjectIfRequiredResponse} CreateCashboostObjectIfRequiredResponse
                 */
                CreateCashboostObjectIfRequiredResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateCashboostObjectIfRequiredResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreateCashboostObjectIfRequiredResponse();
                    if (object.cashboostAlreadyExists != null)
                        message.cashboostAlreadyExists = Boolean(object.cashboostAlreadyExists);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateCashboostObjectIfRequiredResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @static
                 * @param {visor.clientrpc.CreateCashboostObjectIfRequiredResponse} message CreateCashboostObjectIfRequiredResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCashboostObjectIfRequiredResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.cashboostAlreadyExists = false;
                    if (message.cashboostAlreadyExists != null && message.hasOwnProperty("cashboostAlreadyExists"))
                        object.cashboostAlreadyExists = message.cashboostAlreadyExists;
                    return object;
                };

                /**
                 * Converts this CreateCashboostObjectIfRequiredResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateCashboostObjectIfRequiredResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCashboostObjectIfRequiredResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateCashboostObjectIfRequiredResponse;
            })();

            clientrpc.RepayCashboostAdvanceRequest = (function() {

                /**
                 * Properties of a RepayCashboostAdvanceRequest.
                 * @memberof visor.clientrpc
                 * @interface IRepayCashboostAdvanceRequest
                 * @property {string|null} [cashboostToken] RepayCashboostAdvanceRequest cashboostToken
                 * @property {string|null} [cardToken] RepayCashboostAdvanceRequest cardToken
                 */

                /**
                 * Constructs a new RepayCashboostAdvanceRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a RepayCashboostAdvanceRequest.
                 * @implements IRepayCashboostAdvanceRequest
                 * @constructor
                 * @param {visor.clientrpc.IRepayCashboostAdvanceRequest=} [properties] Properties to set
                 */
                function RepayCashboostAdvanceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RepayCashboostAdvanceRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @instance
                 */
                RepayCashboostAdvanceRequest.prototype.cashboostToken = "";

                /**
                 * RepayCashboostAdvanceRequest cardToken.
                 * @member {string} cardToken
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @instance
                 */
                RepayCashboostAdvanceRequest.prototype.cardToken = "";

                /**
                 * Creates a new RepayCashboostAdvanceRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {visor.clientrpc.IRepayCashboostAdvanceRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.RepayCashboostAdvanceRequest} RepayCashboostAdvanceRequest instance
                 */
                RepayCashboostAdvanceRequest.create = function create(properties) {
                    return new RepayCashboostAdvanceRequest(properties);
                };

                /**
                 * Encodes the specified RepayCashboostAdvanceRequest message. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {visor.clientrpc.IRepayCashboostAdvanceRequest} message RepayCashboostAdvanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepayCashboostAdvanceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cashboostToken);
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardToken);
                    return writer;
                };

                /**
                 * Encodes the specified RepayCashboostAdvanceRequest message, length delimited. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {visor.clientrpc.IRepayCashboostAdvanceRequest} message RepayCashboostAdvanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepayCashboostAdvanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RepayCashboostAdvanceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.RepayCashboostAdvanceRequest} RepayCashboostAdvanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepayCashboostAdvanceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RepayCashboostAdvanceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cashboostToken = reader.string();
                            break;
                        case 2:
                            message.cardToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RepayCashboostAdvanceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.RepayCashboostAdvanceRequest} RepayCashboostAdvanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepayCashboostAdvanceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RepayCashboostAdvanceRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RepayCashboostAdvanceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        if (!$util.isString(message.cardToken))
                            return "cardToken: string expected";
                    return null;
                };

                /**
                 * Creates a RepayCashboostAdvanceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.RepayCashboostAdvanceRequest} RepayCashboostAdvanceRequest
                 */
                RepayCashboostAdvanceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.RepayCashboostAdvanceRequest)
                        return object;
                    var message = new $root.visor.clientrpc.RepayCashboostAdvanceRequest();
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    if (object.cardToken != null)
                        message.cardToken = String(object.cardToken);
                    return message;
                };

                /**
                 * Creates a plain object from a RepayCashboostAdvanceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @static
                 * @param {visor.clientrpc.RepayCashboostAdvanceRequest} message RepayCashboostAdvanceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RepayCashboostAdvanceRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cashboostToken = "";
                        object.cardToken = "";
                    }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        object.cardToken = message.cardToken;
                    return object;
                };

                /**
                 * Converts this RepayCashboostAdvanceRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.RepayCashboostAdvanceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RepayCashboostAdvanceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return RepayCashboostAdvanceRequest;
            })();

            clientrpc.RepayCashboostAdvanceResponse = (function() {

                /**
                 * Properties of a RepayCashboostAdvanceResponse.
                 * @memberof visor.clientrpc
                 * @interface IRepayCashboostAdvanceResponse
                 * @property {visor.clientrpc.RepayCashboostAdvanceResponse.IData|null} [success] RepayCashboostAdvanceResponse success
                 * @property {visor.clientrpc.RepayCashboostAdvanceResponse.IError|null} [failure] RepayCashboostAdvanceResponse failure
                 */

                /**
                 * Constructs a new RepayCashboostAdvanceResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a RepayCashboostAdvanceResponse.
                 * @implements IRepayCashboostAdvanceResponse
                 * @constructor
                 * @param {visor.clientrpc.IRepayCashboostAdvanceResponse=} [properties] Properties to set
                 */
                function RepayCashboostAdvanceResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RepayCashboostAdvanceResponse success.
                 * @member {visor.clientrpc.RepayCashboostAdvanceResponse.IData|null|undefined} success
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @instance
                 */
                RepayCashboostAdvanceResponse.prototype.success = null;

                /**
                 * RepayCashboostAdvanceResponse failure.
                 * @member {visor.clientrpc.RepayCashboostAdvanceResponse.IError|null|undefined} failure
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @instance
                 */
                RepayCashboostAdvanceResponse.prototype.failure = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * RepayCashboostAdvanceResponse result.
                 * @member {"success"|"failure"|undefined} result
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @instance
                 */
                Object.defineProperty(RepayCashboostAdvanceResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["success", "failure"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new RepayCashboostAdvanceResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {visor.clientrpc.IRepayCashboostAdvanceResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.RepayCashboostAdvanceResponse} RepayCashboostAdvanceResponse instance
                 */
                RepayCashboostAdvanceResponse.create = function create(properties) {
                    return new RepayCashboostAdvanceResponse(properties);
                };

                /**
                 * Encodes the specified RepayCashboostAdvanceResponse message. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {visor.clientrpc.IRepayCashboostAdvanceResponse} message RepayCashboostAdvanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepayCashboostAdvanceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && message.hasOwnProperty("success"))
                        $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data.encode(message.success, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.failure != null && message.hasOwnProperty("failure"))
                        $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error.encode(message.failure, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified RepayCashboostAdvanceResponse message, length delimited. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {visor.clientrpc.IRepayCashboostAdvanceResponse} message RepayCashboostAdvanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RepayCashboostAdvanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RepayCashboostAdvanceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.RepayCashboostAdvanceResponse} RepayCashboostAdvanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepayCashboostAdvanceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RepayCashboostAdvanceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.success = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.failure = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RepayCashboostAdvanceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.RepayCashboostAdvanceResponse} RepayCashboostAdvanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RepayCashboostAdvanceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RepayCashboostAdvanceResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RepayCashboostAdvanceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data.verify(message.success);
                            if (error)
                                return "success." + error;
                        }
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error.verify(message.failure);
                            if (error)
                                return "failure." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a RepayCashboostAdvanceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.RepayCashboostAdvanceResponse} RepayCashboostAdvanceResponse
                 */
                RepayCashboostAdvanceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.RepayCashboostAdvanceResponse)
                        return object;
                    var message = new $root.visor.clientrpc.RepayCashboostAdvanceResponse();
                    if (object.success != null) {
                        if (typeof object.success !== "object")
                            throw TypeError(".visor.clientrpc.RepayCashboostAdvanceResponse.success: object expected");
                        message.success = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data.fromObject(object.success);
                    }
                    if (object.failure != null) {
                        if (typeof object.failure !== "object")
                            throw TypeError(".visor.clientrpc.RepayCashboostAdvanceResponse.failure: object expected");
                        message.failure = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error.fromObject(object.failure);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RepayCashboostAdvanceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @static
                 * @param {visor.clientrpc.RepayCashboostAdvanceResponse} message RepayCashboostAdvanceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RepayCashboostAdvanceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        object.success = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data.toObject(message.success, options);
                        if (options.oneofs)
                            object.result = "success";
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        object.failure = $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error.toObject(message.failure, options);
                        if (options.oneofs)
                            object.result = "failure";
                    }
                    return object;
                };

                /**
                 * Converts this RepayCashboostAdvanceResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RepayCashboostAdvanceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                RepayCashboostAdvanceResponse.Data = (function() {

                    /**
                     * Properties of a Data.
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                     * @interface IData
                     */

                    /**
                     * Constructs a new Data.
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                     * @classdesc Represents a Data.
                     * @implements IData
                     * @constructor
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IData=} [properties] Properties to set
                     */
                    function Data(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new Data instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IData=} [properties] Properties to set
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Data} Data instance
                     */
                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    /**
                     * Encodes the specified Data message. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceResponse.Data.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified Data message, length delimited. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceResponse.Data.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Data message.
                     * @function verify
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Data.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a Data message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Data} Data
                     */
                    Data.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data)
                            return object;
                        return new $root.visor.clientrpc.RepayCashboostAdvanceResponse.Data();
                    };

                    /**
                     * Creates a plain object from a Data message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.Data} message Data
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Data.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this Data to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Data
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Data.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Data;
                })();

                RepayCashboostAdvanceResponse.Error = (function() {

                    /**
                     * Properties of an Error.
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                     * @interface IError
                     * @property {visor.clientrpc.RepayCashboostAdvanceResponse.Error.Code|null} [code] Error code
                     * @property {string|null} [message] Error message
                     */

                    /**
                     * Constructs a new Error.
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse
                     * @classdesc Represents an Error.
                     * @implements IError
                     * @constructor
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IError=} [properties] Properties to set
                     */
                    function Error(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Error code.
                     * @member {visor.clientrpc.RepayCashboostAdvanceResponse.Error.Code} code
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @instance
                     */
                    Error.prototype.code = 0;

                    /**
                     * Error message.
                     * @member {string} message
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @instance
                     */
                    Error.prototype.message = "";

                    /**
                     * Creates a new Error instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IError=} [properties] Properties to set
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Error} Error instance
                     */
                    Error.create = function create(properties) {
                        return new Error(properties);
                    };

                    /**
                     * Encodes the specified Error message. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceResponse.Error.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                        return writer;
                    };

                    /**
                     * Encodes the specified Error message, length delimited. Does not implicitly {@link visor.clientrpc.RepayCashboostAdvanceResponse.Error.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.int32();
                                break;
                            case 2:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Error message.
                     * @function verify
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Error.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            switch (message.code) {
                            default:
                                return "code: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };

                    /**
                     * Creates an Error message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.RepayCashboostAdvanceResponse.Error} Error
                     */
                    Error.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error)
                            return object;
                        var message = new $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error();
                        switch (object.code) {
                        case "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_INVALID":
                        case 0:
                            message.code = 0;
                            break;
                        case "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_PAYMENT_FAILED":
                        case 1:
                            message.code = 1;
                            break;
                        case "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_DECLINED":
                        case 2:
                            message.code = 2;
                            break;
                        case "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_PERMANENTLY_BROKEN":
                        case 3:
                            message.code = 3;
                            break;
                        }
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Error message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @static
                     * @param {visor.clientrpc.RepayCashboostAdvanceResponse.Error} message Error
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Error.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.code = options.enums === String ? "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_INVALID" : 0;
                            object.message = "";
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = options.enums === String ? $root.visor.clientrpc.RepayCashboostAdvanceResponse.Error.Code[message.code] : message.code;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };

                    /**
                     * Converts this Error to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.RepayCashboostAdvanceResponse.Error
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Error.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Code enum.
                     * @name visor.clientrpc.RepayCashboostAdvanceResponse.Error.Code
                     * @enum {string}
                     * @property {number} REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_INVALID=0 REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_INVALID value
                     * @property {number} REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_PAYMENT_FAILED=1 REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_PAYMENT_FAILED value
                     * @property {number} REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_DECLINED=2 REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_DECLINED value
                     * @property {number} REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_PERMANENTLY_BROKEN=3 REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_PERMANENTLY_BROKEN value
                     */
                    Error.Code = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_INVALID"] = 0;
                        values[valuesById[1] = "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_PAYMENT_FAILED"] = 1;
                        values[valuesById[2] = "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_DECLINED"] = 2;
                        values[valuesById[3] = "REPAY_CASHBOOST_ADVANCE_RESPONSE_ERROR_CODE_DEBIT_CARD_PERMANENTLY_BROKEN"] = 3;
                        return values;
                    })();

                    return Error;
                })();

                return RepayCashboostAdvanceResponse;
            })();

            clientrpc.BeginExceptionWorkflowRequest = (function() {

                /**
                 * Properties of a BeginExceptionWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @interface IBeginExceptionWorkflowRequest
                 * @property {visor.clientrpc.BeginExceptionWorkflowRequest.ExceptionType|null} [type] BeginExceptionWorkflowRequest type
                 * @property {string|null} [cashboostToken] BeginExceptionWorkflowRequest cashboostToken
                 */

                /**
                 * Constructs a new BeginExceptionWorkflowRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginExceptionWorkflowRequest.
                 * @implements IBeginExceptionWorkflowRequest
                 * @constructor
                 * @param {visor.clientrpc.IBeginExceptionWorkflowRequest=} [properties] Properties to set
                 */
                function BeginExceptionWorkflowRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BeginExceptionWorkflowRequest type.
                 * @member {visor.clientrpc.BeginExceptionWorkflowRequest.ExceptionType} type
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @instance
                 */
                BeginExceptionWorkflowRequest.prototype.type = 0;

                /**
                 * BeginExceptionWorkflowRequest cashboostToken.
                 * @member {string} cashboostToken
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @instance
                 */
                BeginExceptionWorkflowRequest.prototype.cashboostToken = "";

                /**
                 * Creates a new BeginExceptionWorkflowRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginExceptionWorkflowRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginExceptionWorkflowRequest} BeginExceptionWorkflowRequest instance
                 */
                BeginExceptionWorkflowRequest.create = function create(properties) {
                    return new BeginExceptionWorkflowRequest(properties);
                };

                /**
                 * Encodes the specified BeginExceptionWorkflowRequest message. Does not implicitly {@link visor.clientrpc.BeginExceptionWorkflowRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginExceptionWorkflowRequest} message BeginExceptionWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginExceptionWorkflowRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.type != null && message.hasOwnProperty("type"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cashboostToken);
                    return writer;
                };

                /**
                 * Encodes the specified BeginExceptionWorkflowRequest message, length delimited. Does not implicitly {@link visor.clientrpc.BeginExceptionWorkflowRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.IBeginExceptionWorkflowRequest} message BeginExceptionWorkflowRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginExceptionWorkflowRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginExceptionWorkflowRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginExceptionWorkflowRequest} BeginExceptionWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginExceptionWorkflowRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginExceptionWorkflowRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.type = reader.int32();
                            break;
                        case 2:
                            message.cashboostToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginExceptionWorkflowRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginExceptionWorkflowRequest} BeginExceptionWorkflowRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginExceptionWorkflowRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginExceptionWorkflowRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginExceptionWorkflowRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        if (!$util.isString(message.cashboostToken))
                            return "cashboostToken: string expected";
                    return null;
                };

                /**
                 * Creates a BeginExceptionWorkflowRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginExceptionWorkflowRequest} BeginExceptionWorkflowRequest
                 */
                BeginExceptionWorkflowRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginExceptionWorkflowRequest)
                        return object;
                    var message = new $root.visor.clientrpc.BeginExceptionWorkflowRequest();
                    switch (object.type) {
                    case "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_INVALID":
                    case 0:
                        message.type = 0;
                        break;
                    case "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_BANK_ACCOUNT":
                    case 1:
                        message.type = 1;
                        break;
                    case "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_UPDATE_BANK_ACCOUNT":
                    case 2:
                        message.type = 2;
                        break;
                    case "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_DEBIT_CARD":
                    case 3:
                        message.type = 3;
                        break;
                    }
                    if (object.cashboostToken != null)
                        message.cashboostToken = String(object.cashboostToken);
                    return message;
                };

                /**
                 * Creates a plain object from a BeginExceptionWorkflowRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @static
                 * @param {visor.clientrpc.BeginExceptionWorkflowRequest} message BeginExceptionWorkflowRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginExceptionWorkflowRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.type = options.enums === String ? "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_INVALID" : 0;
                        object.cashboostToken = "";
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.visor.clientrpc.BeginExceptionWorkflowRequest.ExceptionType[message.type] : message.type;
                    if (message.cashboostToken != null && message.hasOwnProperty("cashboostToken"))
                        object.cashboostToken = message.cashboostToken;
                    return object;
                };

                /**
                 * Converts this BeginExceptionWorkflowRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginExceptionWorkflowRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginExceptionWorkflowRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * ExceptionType enum.
                 * @name visor.clientrpc.BeginExceptionWorkflowRequest.ExceptionType
                 * @enum {string}
                 * @property {number} BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_INVALID=0 BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_INVALID value
                 * @property {number} BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_BANK_ACCOUNT=1 BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_BANK_ACCOUNT value
                 * @property {number} BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_UPDATE_BANK_ACCOUNT=2 BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_UPDATE_BANK_ACCOUNT value
                 * @property {number} BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_DEBIT_CARD=3 BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_DEBIT_CARD value
                 */
                BeginExceptionWorkflowRequest.ExceptionType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_BANK_ACCOUNT"] = 1;
                    values[valuesById[2] = "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_UPDATE_BANK_ACCOUNT"] = 2;
                    values[valuesById[3] = "BEGIN_EXCEPTION_WORKFLOW_REQUEST_EXCEPTION_TYPE_CONNECT_NEW_DEBIT_CARD"] = 3;
                    return values;
                })();

                return BeginExceptionWorkflowRequest;
            })();

            clientrpc.BeginExceptionWorkflowResponse = (function() {

                /**
                 * Properties of a BeginExceptionWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @interface IBeginExceptionWorkflowResponse
                 */

                /**
                 * Constructs a new BeginExceptionWorkflowResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a BeginExceptionWorkflowResponse.
                 * @implements IBeginExceptionWorkflowResponse
                 * @constructor
                 * @param {visor.clientrpc.IBeginExceptionWorkflowResponse=} [properties] Properties to set
                 */
                function BeginExceptionWorkflowResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new BeginExceptionWorkflowResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginExceptionWorkflowResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.BeginExceptionWorkflowResponse} BeginExceptionWorkflowResponse instance
                 */
                BeginExceptionWorkflowResponse.create = function create(properties) {
                    return new BeginExceptionWorkflowResponse(properties);
                };

                /**
                 * Encodes the specified BeginExceptionWorkflowResponse message. Does not implicitly {@link visor.clientrpc.BeginExceptionWorkflowResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginExceptionWorkflowResponse} message BeginExceptionWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginExceptionWorkflowResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified BeginExceptionWorkflowResponse message, length delimited. Does not implicitly {@link visor.clientrpc.BeginExceptionWorkflowResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.IBeginExceptionWorkflowResponse} message BeginExceptionWorkflowResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BeginExceptionWorkflowResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BeginExceptionWorkflowResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.BeginExceptionWorkflowResponse} BeginExceptionWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginExceptionWorkflowResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.BeginExceptionWorkflowResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BeginExceptionWorkflowResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.BeginExceptionWorkflowResponse} BeginExceptionWorkflowResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BeginExceptionWorkflowResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BeginExceptionWorkflowResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BeginExceptionWorkflowResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a BeginExceptionWorkflowResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.BeginExceptionWorkflowResponse} BeginExceptionWorkflowResponse
                 */
                BeginExceptionWorkflowResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.BeginExceptionWorkflowResponse)
                        return object;
                    return new $root.visor.clientrpc.BeginExceptionWorkflowResponse();
                };

                /**
                 * Creates a plain object from a BeginExceptionWorkflowResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @static
                 * @param {visor.clientrpc.BeginExceptionWorkflowResponse} message BeginExceptionWorkflowResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BeginExceptionWorkflowResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this BeginExceptionWorkflowResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.BeginExceptionWorkflowResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BeginExceptionWorkflowResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BeginExceptionWorkflowResponse;
            })();

            clientrpc.CashboostAdvancesHistoryRequest = (function() {

                /**
                 * Properties of a CashboostAdvancesHistoryRequest.
                 * @memberof visor.clientrpc
                 * @interface ICashboostAdvancesHistoryRequest
                 * @property {google.protobuf.ITimestamp|null} [offset] CashboostAdvancesHistoryRequest offset
                 * @property {number|Long|null} [limit] CashboostAdvancesHistoryRequest limit
                 * @property {visor.clientrpc.CashboostAdvancesHistoryRequest.ResultOrder|null} [resultOrder] CashboostAdvancesHistoryRequest resultOrder
                 * @property {boolean|null} [includeCurrentAdvance] CashboostAdvancesHistoryRequest includeCurrentAdvance
                 */

                /**
                 * Constructs a new CashboostAdvancesHistoryRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CashboostAdvancesHistoryRequest.
                 * @implements ICashboostAdvancesHistoryRequest
                 * @constructor
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryRequest=} [properties] Properties to set
                 */
                function CashboostAdvancesHistoryRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CashboostAdvancesHistoryRequest offset.
                 * @member {google.protobuf.ITimestamp|null|undefined} offset
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @instance
                 */
                CashboostAdvancesHistoryRequest.prototype.offset = null;

                /**
                 * CashboostAdvancesHistoryRequest limit.
                 * @member {number|Long} limit
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @instance
                 */
                CashboostAdvancesHistoryRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * CashboostAdvancesHistoryRequest resultOrder.
                 * @member {visor.clientrpc.CashboostAdvancesHistoryRequest.ResultOrder} resultOrder
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @instance
                 */
                CashboostAdvancesHistoryRequest.prototype.resultOrder = 0;

                /**
                 * CashboostAdvancesHistoryRequest includeCurrentAdvance.
                 * @member {boolean} includeCurrentAdvance
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @instance
                 */
                CashboostAdvancesHistoryRequest.prototype.includeCurrentAdvance = false;

                /**
                 * Creates a new CashboostAdvancesHistoryRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryRequest} CashboostAdvancesHistoryRequest instance
                 */
                CashboostAdvancesHistoryRequest.create = function create(properties) {
                    return new CashboostAdvancesHistoryRequest(properties);
                };

                /**
                 * Encodes the specified CashboostAdvancesHistoryRequest message. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryRequest} message CashboostAdvancesHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CashboostAdvancesHistoryRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        $root.google.protobuf.Timestamp.encode(message.offset, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.limit);
                    if (message.resultOrder != null && message.hasOwnProperty("resultOrder"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.resultOrder);
                    if (message.includeCurrentAdvance != null && message.hasOwnProperty("includeCurrentAdvance"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeCurrentAdvance);
                    return writer;
                };

                /**
                 * Encodes the specified CashboostAdvancesHistoryRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryRequest} message CashboostAdvancesHistoryRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CashboostAdvancesHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CashboostAdvancesHistoryRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryRequest} CashboostAdvancesHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CashboostAdvancesHistoryRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CashboostAdvancesHistoryRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.offset = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.limit = reader.int64();
                            break;
                        case 3:
                            message.resultOrder = reader.int32();
                            break;
                        case 4:
                            message.includeCurrentAdvance = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CashboostAdvancesHistoryRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryRequest} CashboostAdvancesHistoryRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CashboostAdvancesHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CashboostAdvancesHistoryRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CashboostAdvancesHistoryRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.offset != null && message.hasOwnProperty("offset")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.offset);
                        if (error)
                            return "offset." + error;
                    }
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                            return "limit: integer|Long expected";
                    if (message.resultOrder != null && message.hasOwnProperty("resultOrder"))
                        switch (message.resultOrder) {
                        default:
                            return "resultOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.includeCurrentAdvance != null && message.hasOwnProperty("includeCurrentAdvance"))
                        if (typeof message.includeCurrentAdvance !== "boolean")
                            return "includeCurrentAdvance: boolean expected";
                    return null;
                };

                /**
                 * Creates a CashboostAdvancesHistoryRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryRequest} CashboostAdvancesHistoryRequest
                 */
                CashboostAdvancesHistoryRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CashboostAdvancesHistoryRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CashboostAdvancesHistoryRequest();
                    if (object.offset != null)
                        if (object.offset === null)
                            message.offset = {};
                        else
                            message.offset = {
                                seconds: Math.floor(object.offset.getTime() / 1000),
                                nanos: object.offset.getMilliseconds() * 1000000
                            };
                    if (object.limit != null)
                        if ($util.Long)
                            (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
                        else if (typeof object.limit === "string")
                            message.limit = parseInt(object.limit, 10);
                        else if (typeof object.limit === "number")
                            message.limit = object.limit;
                        else if (typeof object.limit === "object")
                            message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
                    switch (object.resultOrder) {
                    case "CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_INVALID":
                    case 0:
                        message.resultOrder = 0;
                        break;
                    case "CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_NEWEST_FIRST":
                    case 1:
                        message.resultOrder = 1;
                        break;
                    case "CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_OLDEST_FIRST":
                    case 2:
                        message.resultOrder = 2;
                        break;
                    }
                    if (object.includeCurrentAdvance != null)
                        message.includeCurrentAdvance = Boolean(object.includeCurrentAdvance);
                    return message;
                };

                /**
                 * Creates a plain object from a CashboostAdvancesHistoryRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @static
                 * @param {visor.clientrpc.CashboostAdvancesHistoryRequest} message CashboostAdvancesHistoryRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CashboostAdvancesHistoryRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.offset = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.limit = options.longs === String ? "0" : 0;
                        object.resultOrder = options.enums === String ? "CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_INVALID" : 0;
                        object.includeCurrentAdvance = false;
                    }
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = new Date(message.offset.seconds * 1000 + message.offset.nanos / 1000000);
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (typeof message.limit === "number")
                            object.limit = options.longs === String ? String(message.limit) : message.limit;
                        else
                            object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
                    if (message.resultOrder != null && message.hasOwnProperty("resultOrder"))
                        object.resultOrder = options.enums === String ? $root.visor.clientrpc.CashboostAdvancesHistoryRequest.ResultOrder[message.resultOrder] : message.resultOrder;
                    if (message.includeCurrentAdvance != null && message.hasOwnProperty("includeCurrentAdvance"))
                        object.includeCurrentAdvance = message.includeCurrentAdvance;
                    return object;
                };

                /**
                 * Converts this CashboostAdvancesHistoryRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CashboostAdvancesHistoryRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * ResultOrder enum.
                 * @name visor.clientrpc.CashboostAdvancesHistoryRequest.ResultOrder
                 * @enum {string}
                 * @property {number} CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_INVALID=0 CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_INVALID value
                 * @property {number} CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_NEWEST_FIRST=1 CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_NEWEST_FIRST value
                 * @property {number} CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_OLDEST_FIRST=2 CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_OLDEST_FIRST value
                 */
                CashboostAdvancesHistoryRequest.ResultOrder = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_INVALID"] = 0;
                    values[valuesById[1] = "CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_NEWEST_FIRST"] = 1;
                    values[valuesById[2] = "CASHBOOST_ADVANCES_HISTORY_REQUEST_RESULT_ORDER_OLDEST_FIRST"] = 2;
                    return values;
                })();

                return CashboostAdvancesHistoryRequest;
            })();

            clientrpc.CashboostAdvancesHistoryResponse = (function() {

                /**
                 * Properties of a CashboostAdvancesHistoryResponse.
                 * @memberof visor.clientrpc
                 * @interface ICashboostAdvancesHistoryResponse
                 * @property {Array.<visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvanceReceipt>|null} [advances] CashboostAdvancesHistoryResponse advances
                 */

                /**
                 * Constructs a new CashboostAdvancesHistoryResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CashboostAdvancesHistoryResponse.
                 * @implements ICashboostAdvancesHistoryResponse
                 * @constructor
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryResponse=} [properties] Properties to set
                 */
                function CashboostAdvancesHistoryResponse(properties) {
                    this.advances = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CashboostAdvancesHistoryResponse advances.
                 * @member {Array.<visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvanceReceipt>} advances
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @instance
                 */
                CashboostAdvancesHistoryResponse.prototype.advances = $util.emptyArray;

                /**
                 * Creates a new CashboostAdvancesHistoryResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse} CashboostAdvancesHistoryResponse instance
                 */
                CashboostAdvancesHistoryResponse.create = function create(properties) {
                    return new CashboostAdvancesHistoryResponse(properties);
                };

                /**
                 * Encodes the specified CashboostAdvancesHistoryResponse message. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryResponse} message CashboostAdvancesHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CashboostAdvancesHistoryResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.advances != null && message.advances.length)
                        for (var i = 0; i < message.advances.length; ++i)
                            $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.encode(message.advances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CashboostAdvancesHistoryResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {visor.clientrpc.ICashboostAdvancesHistoryResponse} message CashboostAdvancesHistoryResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CashboostAdvancesHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CashboostAdvancesHistoryResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse} CashboostAdvancesHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CashboostAdvancesHistoryResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CashboostAdvancesHistoryResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.advances && message.advances.length))
                                message.advances = [];
                            message.advances.push($root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CashboostAdvancesHistoryResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse} CashboostAdvancesHistoryResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CashboostAdvancesHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CashboostAdvancesHistoryResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CashboostAdvancesHistoryResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.advances != null && message.hasOwnProperty("advances")) {
                        if (!Array.isArray(message.advances))
                            return "advances: array expected";
                        for (var i = 0; i < message.advances.length; ++i) {
                            var error = $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.verify(message.advances[i]);
                            if (error)
                                return "advances." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CashboostAdvancesHistoryResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse} CashboostAdvancesHistoryResponse
                 */
                CashboostAdvancesHistoryResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CashboostAdvancesHistoryResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CashboostAdvancesHistoryResponse();
                    if (object.advances) {
                        if (!Array.isArray(object.advances))
                            throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.advances: array expected");
                        message.advances = [];
                        for (var i = 0; i < object.advances.length; ++i) {
                            if (typeof object.advances[i] !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.advances: object expected");
                            message.advances[i] = $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.fromObject(object.advances[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CashboostAdvancesHistoryResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @static
                 * @param {visor.clientrpc.CashboostAdvancesHistoryResponse} message CashboostAdvancesHistoryResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CashboostAdvancesHistoryResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.advances = [];
                    if (message.advances && message.advances.length) {
                        object.advances = [];
                        for (var j = 0; j < message.advances.length; ++j)
                            object.advances[j] = $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.toObject(message.advances[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CashboostAdvancesHistoryResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CashboostAdvancesHistoryResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CashboostAdvancesHistoryResponse.AdvanceReceipt = (function() {

                    /**
                     * Properties of an AdvanceReceipt.
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                     * @interface IAdvanceReceipt
                     * @property {string|null} [token] AdvanceReceipt token
                     * @property {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.State|null} [state] AdvanceReceipt state
                     * @property {google.protobuf.ITimestamp|null} [advanceCreatedAt] AdvanceReceipt advanceCreatedAt
                     * @property {visor.common.money.IAmount|null} [amount] AdvanceReceipt amount
                     * @property {visor.common.money.IAmount|null} [payoutFee] AdvanceReceipt payoutFee
                     * @property {visor.common.money.IAmount|null} [total] AdvanceReceipt total
                     * @property {visor.common.money.IAmount|null} [outstandingAmount] AdvanceReceipt outstandingAmount
                     * @property {visor.common.time.ILocalDate|null} [repaymentDate] AdvanceReceipt repaymentDate
                     * @property {visor.common.time.ILocalDate|null} [fundingDate] AdvanceReceipt fundingDate
                     * @property {visor.common.time.ILocalDate|null} [arrivalDate] AdvanceReceipt arrivalDate
                     * @property {string|null} [bankAccountTitle] AdvanceReceipt bankAccountTitle
                     * @property {string|null} [bankAccountLastFour] AdvanceReceipt bankAccountLastFour
                     * @property {Array.<visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvancePayment>|null} [payments] AdvanceReceipt payments
                     */

                    /**
                     * Constructs a new AdvanceReceipt.
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                     * @classdesc Represents an AdvanceReceipt.
                     * @implements IAdvanceReceipt
                     * @constructor
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvanceReceipt=} [properties] Properties to set
                     */
                    function AdvanceReceipt(properties) {
                        this.payments = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AdvanceReceipt token.
                     * @member {string} token
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.token = "";

                    /**
                     * AdvanceReceipt state.
                     * @member {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.State} state
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.state = 0;

                    /**
                     * AdvanceReceipt advanceCreatedAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} advanceCreatedAt
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.advanceCreatedAt = null;

                    /**
                     * AdvanceReceipt amount.
                     * @member {visor.common.money.IAmount|null|undefined} amount
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.amount = null;

                    /**
                     * AdvanceReceipt payoutFee.
                     * @member {visor.common.money.IAmount|null|undefined} payoutFee
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.payoutFee = null;

                    /**
                     * AdvanceReceipt total.
                     * @member {visor.common.money.IAmount|null|undefined} total
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.total = null;

                    /**
                     * AdvanceReceipt outstandingAmount.
                     * @member {visor.common.money.IAmount|null|undefined} outstandingAmount
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.outstandingAmount = null;

                    /**
                     * AdvanceReceipt repaymentDate.
                     * @member {visor.common.time.ILocalDate|null|undefined} repaymentDate
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.repaymentDate = null;

                    /**
                     * AdvanceReceipt fundingDate.
                     * @member {visor.common.time.ILocalDate|null|undefined} fundingDate
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.fundingDate = null;

                    /**
                     * AdvanceReceipt arrivalDate.
                     * @member {visor.common.time.ILocalDate|null|undefined} arrivalDate
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.arrivalDate = null;

                    /**
                     * AdvanceReceipt bankAccountTitle.
                     * @member {string} bankAccountTitle
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.bankAccountTitle = "";

                    /**
                     * AdvanceReceipt bankAccountLastFour.
                     * @member {string} bankAccountLastFour
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.bankAccountLastFour = "";

                    /**
                     * AdvanceReceipt payments.
                     * @member {Array.<visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvancePayment>} payments
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     */
                    AdvanceReceipt.prototype.payments = $util.emptyArray;

                    /**
                     * Creates a new AdvanceReceipt instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvanceReceipt=} [properties] Properties to set
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt} AdvanceReceipt instance
                     */
                    AdvanceReceipt.create = function create(properties) {
                        return new AdvanceReceipt(properties);
                    };

                    /**
                     * Encodes the specified AdvanceReceipt message. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvanceReceipt} message AdvanceReceipt message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AdvanceReceipt.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.repaymentDate != null && message.hasOwnProperty("repaymentDate"))
                            $root.visor.common.time.LocalDate.encode(message.repaymentDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.fundingDate != null && message.hasOwnProperty("fundingDate"))
                            $root.visor.common.time.LocalDate.encode(message.fundingDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.bankAccountTitle != null && message.hasOwnProperty("bankAccountTitle"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.bankAccountTitle);
                        if (message.bankAccountLastFour != null && message.hasOwnProperty("bankAccountLastFour"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.bankAccountLastFour);
                        if (message.payments != null && message.payments.length)
                            for (var i = 0; i < message.payments.length; ++i)
                                $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.encode(message.payments[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount"))
                            $root.visor.common.money.Amount.encode(message.outstandingAmount, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.advanceCreatedAt != null && message.hasOwnProperty("advanceCreatedAt"))
                            $root.google.protobuf.Timestamp.encode(message.advanceCreatedAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.payoutFee != null && message.hasOwnProperty("payoutFee"))
                            $root.visor.common.money.Amount.encode(message.payoutFee, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.total != null && message.hasOwnProperty("total"))
                            $root.visor.common.money.Amount.encode(message.total, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.state);
                        if (message.arrivalDate != null && message.hasOwnProperty("arrivalDate"))
                            $root.visor.common.time.LocalDate.encode(message.arrivalDate, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AdvanceReceipt message, length delimited. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvanceReceipt} message AdvanceReceipt message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AdvanceReceipt.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AdvanceReceipt message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt} AdvanceReceipt
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AdvanceReceipt.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            case 12:
                                message.state = reader.int32();
                                break;
                            case 9:
                                message.advanceCreatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.payoutFee = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.total = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.outstandingAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.repaymentDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.fundingDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.arrivalDate = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.bankAccountTitle = reader.string();
                                break;
                            case 6:
                                message.bankAccountLastFour = reader.string();
                                break;
                            case 7:
                                if (!(message.payments && message.payments.length))
                                    message.payments = [];
                                message.payments.push($root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AdvanceReceipt message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt} AdvanceReceipt
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AdvanceReceipt.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AdvanceReceipt message.
                     * @function verify
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AdvanceReceipt.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.advanceCreatedAt != null && message.hasOwnProperty("advanceCreatedAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.advanceCreatedAt);
                            if (error)
                                return "advanceCreatedAt." + error;
                        }
                        if (message.amount != null && message.hasOwnProperty("amount")) {
                            var error = $root.visor.common.money.Amount.verify(message.amount);
                            if (error)
                                return "amount." + error;
                        }
                        if (message.payoutFee != null && message.hasOwnProperty("payoutFee")) {
                            var error = $root.visor.common.money.Amount.verify(message.payoutFee);
                            if (error)
                                return "payoutFee." + error;
                        }
                        if (message.total != null && message.hasOwnProperty("total")) {
                            var error = $root.visor.common.money.Amount.verify(message.total);
                            if (error)
                                return "total." + error;
                        }
                        if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount")) {
                            var error = $root.visor.common.money.Amount.verify(message.outstandingAmount);
                            if (error)
                                return "outstandingAmount." + error;
                        }
                        if (message.repaymentDate != null && message.hasOwnProperty("repaymentDate")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.repaymentDate);
                            if (error)
                                return "repaymentDate." + error;
                        }
                        if (message.fundingDate != null && message.hasOwnProperty("fundingDate")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.fundingDate);
                            if (error)
                                return "fundingDate." + error;
                        }
                        if (message.arrivalDate != null && message.hasOwnProperty("arrivalDate")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.arrivalDate);
                            if (error)
                                return "arrivalDate." + error;
                        }
                        if (message.bankAccountTitle != null && message.hasOwnProperty("bankAccountTitle"))
                            if (!$util.isString(message.bankAccountTitle))
                                return "bankAccountTitle: string expected";
                        if (message.bankAccountLastFour != null && message.hasOwnProperty("bankAccountLastFour"))
                            if (!$util.isString(message.bankAccountLastFour))
                                return "bankAccountLastFour: string expected";
                        if (message.payments != null && message.hasOwnProperty("payments")) {
                            if (!Array.isArray(message.payments))
                                return "payments: array expected";
                            for (var i = 0; i < message.payments.length; ++i) {
                                var error = $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.verify(message.payments[i]);
                                if (error)
                                    return "payments." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an AdvanceReceipt message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt} AdvanceReceipt
                     */
                    AdvanceReceipt.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt)
                            return object;
                        var message = new $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt();
                        if (object.token != null)
                            message.token = String(object.token);
                        switch (object.state) {
                        case "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_INVALID":
                        case 0:
                            message.state = 0;
                            break;
                        case "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_PENDING":
                        case 1:
                            message.state = 1;
                            break;
                        case "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_ARRIVED":
                        case 2:
                            message.state = 2;
                            break;
                        case "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAYMENT":
                        case 3:
                            message.state = 3;
                            break;
                        case "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAID":
                        case 4:
                            message.state = 4;
                            break;
                        }
                        if (object.advanceCreatedAt != null)
                            if (object.advanceCreatedAt === null)
                                message.advanceCreatedAt = {};
                            else
                                message.advanceCreatedAt = {
                                    seconds: Math.floor(object.advanceCreatedAt.getTime() / 1000),
                                    nanos: object.advanceCreatedAt.getMilliseconds() * 1000000
                                };
                        if (object.amount != null) {
                            if (typeof object.amount !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.amount: object expected");
                            message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                        }
                        if (object.payoutFee != null) {
                            if (typeof object.payoutFee !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.payoutFee: object expected");
                            message.payoutFee = $root.visor.common.money.Amount.fromObject(object.payoutFee);
                        }
                        if (object.total != null) {
                            if (typeof object.total !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.total: object expected");
                            message.total = $root.visor.common.money.Amount.fromObject(object.total);
                        }
                        if (object.outstandingAmount != null) {
                            if (typeof object.outstandingAmount !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.outstandingAmount: object expected");
                            message.outstandingAmount = $root.visor.common.money.Amount.fromObject(object.outstandingAmount);
                        }
                        if (object.repaymentDate != null) {
                            if (typeof object.repaymentDate !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.repaymentDate: object expected");
                            message.repaymentDate = $root.visor.common.time.LocalDate.fromObject(object.repaymentDate);
                        }
                        if (object.fundingDate != null) {
                            if (typeof object.fundingDate !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.fundingDate: object expected");
                            message.fundingDate = $root.visor.common.time.LocalDate.fromObject(object.fundingDate);
                        }
                        if (object.arrivalDate != null) {
                            if (typeof object.arrivalDate !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.arrivalDate: object expected");
                            message.arrivalDate = $root.visor.common.time.LocalDate.fromObject(object.arrivalDate);
                        }
                        if (object.bankAccountTitle != null)
                            message.bankAccountTitle = String(object.bankAccountTitle);
                        if (object.bankAccountLastFour != null)
                            message.bankAccountLastFour = String(object.bankAccountLastFour);
                        if (object.payments) {
                            if (!Array.isArray(object.payments))
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.payments: array expected");
                            message.payments = [];
                            for (var i = 0; i < object.payments.length; ++i) {
                                if (typeof object.payments[i] !== "object")
                                    throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.payments: object expected");
                                message.payments[i] = $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.fromObject(object.payments[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AdvanceReceipt message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt} message AdvanceReceipt
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AdvanceReceipt.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.payments = [];
                        if (options.defaults) {
                            object.token = "";
                            object.amount = null;
                            object.repaymentDate = null;
                            object.fundingDate = null;
                            object.bankAccountTitle = "";
                            object.bankAccountLastFour = "";
                            object.outstandingAmount = null;
                            object.advanceCreatedAt = null;
                            object.payoutFee = null;
                            object.total = null;
                            object.state = options.enums === String ? "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_INVALID" : 0;
                            object.arrivalDate = null;
                        }
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                        if (message.repaymentDate != null && message.hasOwnProperty("repaymentDate"))
                            object.repaymentDate = $root.visor.common.time.LocalDate.toObject(message.repaymentDate, options);
                        if (message.fundingDate != null && message.hasOwnProperty("fundingDate"))
                            object.fundingDate = $root.visor.common.time.LocalDate.toObject(message.fundingDate, options);
                        if (message.bankAccountTitle != null && message.hasOwnProperty("bankAccountTitle"))
                            object.bankAccountTitle = message.bankAccountTitle;
                        if (message.bankAccountLastFour != null && message.hasOwnProperty("bankAccountLastFour"))
                            object.bankAccountLastFour = message.bankAccountLastFour;
                        if (message.payments && message.payments.length) {
                            object.payments = [];
                            for (var j = 0; j < message.payments.length; ++j)
                                object.payments[j] = $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.toObject(message.payments[j], options);
                        }
                        if (message.outstandingAmount != null && message.hasOwnProperty("outstandingAmount"))
                            object.outstandingAmount = $root.visor.common.money.Amount.toObject(message.outstandingAmount, options);
                        if (message.advanceCreatedAt != null && message.hasOwnProperty("advanceCreatedAt"))
                            object.advanceCreatedAt = new Date(message.advanceCreatedAt.seconds * 1000 + message.advanceCreatedAt.nanos / 1000000);
                        if (message.payoutFee != null && message.hasOwnProperty("payoutFee"))
                            object.payoutFee = $root.visor.common.money.Amount.toObject(message.payoutFee, options);
                        if (message.total != null && message.hasOwnProperty("total"))
                            object.total = $root.visor.common.money.Amount.toObject(message.total, options);
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.State[message.state] : message.state;
                        if (message.arrivalDate != null && message.hasOwnProperty("arrivalDate"))
                            object.arrivalDate = $root.visor.common.time.LocalDate.toObject(message.arrivalDate, options);
                        return object;
                    };

                    /**
                     * Converts this AdvanceReceipt to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AdvanceReceipt.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * State enum.
                     * @name visor.clientrpc.CashboostAdvancesHistoryResponse.AdvanceReceipt.State
                     * @enum {string}
                     * @property {number} CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_INVALID=0 CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_INVALID value
                     * @property {number} CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_PENDING=1 CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_PENDING value
                     * @property {number} CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_ARRIVED=2 CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_ARRIVED value
                     * @property {number} CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAYMENT=3 CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAYMENT value
                     * @property {number} CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAID=4 CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAID value
                     */
                    AdvanceReceipt.State = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_INVALID"] = 0;
                        values[valuesById[1] = "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_PENDING"] = 1;
                        values[valuesById[2] = "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_ARRIVED"] = 2;
                        values[valuesById[3] = "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAYMENT"] = 3;
                        values[valuesById[4] = "CASHBOOST_ADVANCES_HISTORY_RESPONSE_ADVANCE_RECEIPT_STATE_REPAID"] = 4;
                        return values;
                    })();

                    return AdvanceReceipt;
                })();

                CashboostAdvancesHistoryResponse.AdvancePayment = (function() {

                    /**
                     * Properties of an AdvancePayment.
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                     * @interface IAdvancePayment
                     * @property {string|null} [token] AdvancePayment token
                     * @property {google.protobuf.ITimestamp|null} [createdAt] AdvancePayment createdAt
                     * @property {visor.common.money.IAmount|null} [attemptedAmount] AdvancePayment attemptedAmount
                     * @property {visor.common.money.IAmount|null} [capturedAmount] AdvancePayment capturedAmount
                     */

                    /**
                     * Constructs a new AdvancePayment.
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse
                     * @classdesc Represents an AdvancePayment.
                     * @implements IAdvancePayment
                     * @constructor
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvancePayment=} [properties] Properties to set
                     */
                    function AdvancePayment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AdvancePayment token.
                     * @member {string} token
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @instance
                     */
                    AdvancePayment.prototype.token = "";

                    /**
                     * AdvancePayment createdAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @instance
                     */
                    AdvancePayment.prototype.createdAt = null;

                    /**
                     * AdvancePayment attemptedAmount.
                     * @member {visor.common.money.IAmount|null|undefined} attemptedAmount
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @instance
                     */
                    AdvancePayment.prototype.attemptedAmount = null;

                    /**
                     * AdvancePayment capturedAmount.
                     * @member {visor.common.money.IAmount|null|undefined} capturedAmount
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @instance
                     */
                    AdvancePayment.prototype.capturedAmount = null;

                    /**
                     * Creates a new AdvancePayment instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvancePayment=} [properties] Properties to set
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment} AdvancePayment instance
                     */
                    AdvancePayment.create = function create(properties) {
                        return new AdvancePayment(properties);
                    };

                    /**
                     * Encodes the specified AdvancePayment message. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvancePayment} message AdvancePayment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AdvancePayment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                            $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.attemptedAmount != null && message.hasOwnProperty("attemptedAmount"))
                            $root.visor.common.money.Amount.encode(message.attemptedAmount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.capturedAmount != null && message.hasOwnProperty("capturedAmount"))
                            $root.visor.common.money.Amount.encode(message.capturedAmount, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AdvancePayment message, length delimited. Does not implicitly {@link visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.IAdvancePayment} message AdvancePayment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AdvancePayment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AdvancePayment message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment} AdvancePayment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AdvancePayment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            case 2:
                                message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.attemptedAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.capturedAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AdvancePayment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment} AdvancePayment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AdvancePayment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AdvancePayment message.
                     * @function verify
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AdvancePayment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                            if (error)
                                return "createdAt." + error;
                        }
                        if (message.attemptedAmount != null && message.hasOwnProperty("attemptedAmount")) {
                            var error = $root.visor.common.money.Amount.verify(message.attemptedAmount);
                            if (error)
                                return "attemptedAmount." + error;
                        }
                        if (message.capturedAmount != null && message.hasOwnProperty("capturedAmount")) {
                            var error = $root.visor.common.money.Amount.verify(message.capturedAmount);
                            if (error)
                                return "capturedAmount." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an AdvancePayment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment} AdvancePayment
                     */
                    AdvancePayment.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment)
                            return object;
                        var message = new $root.visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment();
                        if (object.token != null)
                            message.token = String(object.token);
                        if (object.createdAt != null)
                            if (object.createdAt === null)
                                message.createdAt = {};
                            else
                                message.createdAt = {
                                    seconds: Math.floor(object.createdAt.getTime() / 1000),
                                    nanos: object.createdAt.getMilliseconds() * 1000000
                                };
                        if (object.attemptedAmount != null) {
                            if (typeof object.attemptedAmount !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.attemptedAmount: object expected");
                            message.attemptedAmount = $root.visor.common.money.Amount.fromObject(object.attemptedAmount);
                        }
                        if (object.capturedAmount != null) {
                            if (typeof object.capturedAmount !== "object")
                                throw TypeError(".visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment.capturedAmount: object expected");
                            message.capturedAmount = $root.visor.common.money.Amount.fromObject(object.capturedAmount);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AdvancePayment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @static
                     * @param {visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment} message AdvancePayment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AdvancePayment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.token = "";
                            object.createdAt = null;
                            object.attemptedAmount = null;
                            object.capturedAmount = null;
                        }
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                            object.createdAt = new Date(message.createdAt.seconds * 1000 + message.createdAt.nanos / 1000000);
                        if (message.attemptedAmount != null && message.hasOwnProperty("attemptedAmount"))
                            object.attemptedAmount = $root.visor.common.money.Amount.toObject(message.attemptedAmount, options);
                        if (message.capturedAmount != null && message.hasOwnProperty("capturedAmount"))
                            object.capturedAmount = $root.visor.common.money.Amount.toObject(message.capturedAmount, options);
                        return object;
                    };

                    /**
                     * Converts this AdvancePayment to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CashboostAdvancesHistoryResponse.AdvancePayment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AdvancePayment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AdvancePayment;
                })();

                return CashboostAdvancesHistoryResponse;
            })();

            clientrpc.CreateCardRequest = (function() {

                /**
                 * Properties of a CreateCardRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateCardRequest
                 * @property {string|null} [clientToken] CreateCardRequest clientToken
                 * @property {string|null} [stripePaymentMethodId] CreateCardRequest stripePaymentMethodId
                 * @property {visor.common.encryption.IEncryptedItem|null} [cardNumber] CreateCardRequest cardNumber
                 * @property {visor.common.encryption.IEncryptedItem|null} [cardCvc] CreateCardRequest cardCvc
                 * @property {number|Long|null} [cardExpirationMonth] CreateCardRequest cardExpirationMonth
                 * @property {number|Long|null} [cardExpirationYear] CreateCardRequest cardExpirationYear
                 * @property {string|null} [cardZipCode] CreateCardRequest cardZipCode
                 * @property {string|null} [ckoCardToken] CreateCardRequest ckoCardToken
                 * @property {string|null} [footprintCardVaultToken] CreateCardRequest footprintCardVaultToken
                 */

                /**
                 * Constructs a new CreateCardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateCardRequest.
                 * @implements ICreateCardRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateCardRequest=} [properties] Properties to set
                 */
                function CreateCardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateCardRequest clientToken.
                 * @member {string} clientToken
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.clientToken = "";

                /**
                 * CreateCardRequest stripePaymentMethodId.
                 * @member {string} stripePaymentMethodId
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.stripePaymentMethodId = "";

                /**
                 * CreateCardRequest cardNumber.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} cardNumber
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.cardNumber = null;

                /**
                 * CreateCardRequest cardCvc.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} cardCvc
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.cardCvc = null;

                /**
                 * CreateCardRequest cardExpirationMonth.
                 * @member {number|Long} cardExpirationMonth
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.cardExpirationMonth = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * CreateCardRequest cardExpirationYear.
                 * @member {number|Long} cardExpirationYear
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.cardExpirationYear = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * CreateCardRequest cardZipCode.
                 * @member {string} cardZipCode
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.cardZipCode = "";

                /**
                 * CreateCardRequest ckoCardToken.
                 * @member {string} ckoCardToken
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.ckoCardToken = "";

                /**
                 * CreateCardRequest footprintCardVaultToken.
                 * @member {string} footprintCardVaultToken
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 */
                CreateCardRequest.prototype.footprintCardVaultToken = "";

                /**
                 * Creates a new CreateCardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateCardRequest} CreateCardRequest instance
                 */
                CreateCardRequest.create = function create(properties) {
                    return new CreateCardRequest(properties);
                };

                /**
                 * Encodes the specified CreateCardRequest message. Does not implicitly {@link visor.clientrpc.CreateCardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCardRequest} message CreateCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientToken);
                    if (message.stripePaymentMethodId != null && message.hasOwnProperty("stripePaymentMethodId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.stripePaymentMethodId);
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.cardNumber, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.cardCvc != null && message.hasOwnProperty("cardCvc"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.cardCvc, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.cardExpirationMonth != null && message.hasOwnProperty("cardExpirationMonth"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.cardExpirationMonth);
                    if (message.cardExpirationYear != null && message.hasOwnProperty("cardExpirationYear"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.cardExpirationYear);
                    if (message.cardZipCode != null && message.hasOwnProperty("cardZipCode"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.cardZipCode);
                    if (message.ckoCardToken != null && message.hasOwnProperty("ckoCardToken"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.ckoCardToken);
                    if (message.footprintCardVaultToken != null && message.hasOwnProperty("footprintCardVaultToken"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.footprintCardVaultToken);
                    return writer;
                };

                /**
                 * Encodes the specified CreateCardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {visor.clientrpc.ICreateCardRequest} message CreateCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateCardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateCardRequest} CreateCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.clientToken = reader.string();
                            break;
                        case 2:
                            message.stripePaymentMethodId = reader.string();
                            break;
                        case 3:
                            message.cardNumber = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.cardCvc = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.cardExpirationMonth = reader.uint64();
                            break;
                        case 6:
                            message.cardExpirationYear = reader.uint64();
                            break;
                        case 7:
                            message.cardZipCode = reader.string();
                            break;
                        case 8:
                            message.ckoCardToken = reader.string();
                            break;
                        case 9:
                            message.footprintCardVaultToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateCardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateCardRequest} CreateCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateCardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        if (!$util.isString(message.clientToken))
                            return "clientToken: string expected";
                    if (message.stripePaymentMethodId != null && message.hasOwnProperty("stripePaymentMethodId"))
                        if (!$util.isString(message.stripePaymentMethodId))
                            return "stripePaymentMethodId: string expected";
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.cardNumber);
                        if (error)
                            return "cardNumber." + error;
                    }
                    if (message.cardCvc != null && message.hasOwnProperty("cardCvc")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.cardCvc);
                        if (error)
                            return "cardCvc." + error;
                    }
                    if (message.cardExpirationMonth != null && message.hasOwnProperty("cardExpirationMonth"))
                        if (!$util.isInteger(message.cardExpirationMonth) && !(message.cardExpirationMonth && $util.isInteger(message.cardExpirationMonth.low) && $util.isInteger(message.cardExpirationMonth.high)))
                            return "cardExpirationMonth: integer|Long expected";
                    if (message.cardExpirationYear != null && message.hasOwnProperty("cardExpirationYear"))
                        if (!$util.isInteger(message.cardExpirationYear) && !(message.cardExpirationYear && $util.isInteger(message.cardExpirationYear.low) && $util.isInteger(message.cardExpirationYear.high)))
                            return "cardExpirationYear: integer|Long expected";
                    if (message.cardZipCode != null && message.hasOwnProperty("cardZipCode"))
                        if (!$util.isString(message.cardZipCode))
                            return "cardZipCode: string expected";
                    if (message.ckoCardToken != null && message.hasOwnProperty("ckoCardToken"))
                        if (!$util.isString(message.ckoCardToken))
                            return "ckoCardToken: string expected";
                    if (message.footprintCardVaultToken != null && message.hasOwnProperty("footprintCardVaultToken"))
                        if (!$util.isString(message.footprintCardVaultToken))
                            return "footprintCardVaultToken: string expected";
                    return null;
                };

                /**
                 * Creates a CreateCardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateCardRequest} CreateCardRequest
                 */
                CreateCardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateCardRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateCardRequest();
                    if (object.clientToken != null)
                        message.clientToken = String(object.clientToken);
                    if (object.stripePaymentMethodId != null)
                        message.stripePaymentMethodId = String(object.stripePaymentMethodId);
                    if (object.cardNumber != null) {
                        if (typeof object.cardNumber !== "object")
                            throw TypeError(".visor.clientrpc.CreateCardRequest.cardNumber: object expected");
                        message.cardNumber = $root.visor.common.encryption.EncryptedItem.fromObject(object.cardNumber);
                    }
                    if (object.cardCvc != null) {
                        if (typeof object.cardCvc !== "object")
                            throw TypeError(".visor.clientrpc.CreateCardRequest.cardCvc: object expected");
                        message.cardCvc = $root.visor.common.encryption.EncryptedItem.fromObject(object.cardCvc);
                    }
                    if (object.cardExpirationMonth != null)
                        if ($util.Long)
                            (message.cardExpirationMonth = $util.Long.fromValue(object.cardExpirationMonth)).unsigned = true;
                        else if (typeof object.cardExpirationMonth === "string")
                            message.cardExpirationMonth = parseInt(object.cardExpirationMonth, 10);
                        else if (typeof object.cardExpirationMonth === "number")
                            message.cardExpirationMonth = object.cardExpirationMonth;
                        else if (typeof object.cardExpirationMonth === "object")
                            message.cardExpirationMonth = new $util.LongBits(object.cardExpirationMonth.low >>> 0, object.cardExpirationMonth.high >>> 0).toNumber(true);
                    if (object.cardExpirationYear != null)
                        if ($util.Long)
                            (message.cardExpirationYear = $util.Long.fromValue(object.cardExpirationYear)).unsigned = true;
                        else if (typeof object.cardExpirationYear === "string")
                            message.cardExpirationYear = parseInt(object.cardExpirationYear, 10);
                        else if (typeof object.cardExpirationYear === "number")
                            message.cardExpirationYear = object.cardExpirationYear;
                        else if (typeof object.cardExpirationYear === "object")
                            message.cardExpirationYear = new $util.LongBits(object.cardExpirationYear.low >>> 0, object.cardExpirationYear.high >>> 0).toNumber(true);
                    if (object.cardZipCode != null)
                        message.cardZipCode = String(object.cardZipCode);
                    if (object.ckoCardToken != null)
                        message.ckoCardToken = String(object.ckoCardToken);
                    if (object.footprintCardVaultToken != null)
                        message.footprintCardVaultToken = String(object.footprintCardVaultToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateCardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @static
                 * @param {visor.clientrpc.CreateCardRequest} message CreateCardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.clientToken = "";
                        object.stripePaymentMethodId = "";
                        object.cardNumber = null;
                        object.cardCvc = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.cardExpirationMonth = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cardExpirationMonth = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.cardExpirationYear = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.cardExpirationYear = options.longs === String ? "0" : 0;
                        object.cardZipCode = "";
                        object.ckoCardToken = "";
                        object.footprintCardVaultToken = "";
                    }
                    if (message.clientToken != null && message.hasOwnProperty("clientToken"))
                        object.clientToken = message.clientToken;
                    if (message.stripePaymentMethodId != null && message.hasOwnProperty("stripePaymentMethodId"))
                        object.stripePaymentMethodId = message.stripePaymentMethodId;
                    if (message.cardNumber != null && message.hasOwnProperty("cardNumber"))
                        object.cardNumber = $root.visor.common.encryption.EncryptedItem.toObject(message.cardNumber, options);
                    if (message.cardCvc != null && message.hasOwnProperty("cardCvc"))
                        object.cardCvc = $root.visor.common.encryption.EncryptedItem.toObject(message.cardCvc, options);
                    if (message.cardExpirationMonth != null && message.hasOwnProperty("cardExpirationMonth"))
                        if (typeof message.cardExpirationMonth === "number")
                            object.cardExpirationMonth = options.longs === String ? String(message.cardExpirationMonth) : message.cardExpirationMonth;
                        else
                            object.cardExpirationMonth = options.longs === String ? $util.Long.prototype.toString.call(message.cardExpirationMonth) : options.longs === Number ? new $util.LongBits(message.cardExpirationMonth.low >>> 0, message.cardExpirationMonth.high >>> 0).toNumber(true) : message.cardExpirationMonth;
                    if (message.cardExpirationYear != null && message.hasOwnProperty("cardExpirationYear"))
                        if (typeof message.cardExpirationYear === "number")
                            object.cardExpirationYear = options.longs === String ? String(message.cardExpirationYear) : message.cardExpirationYear;
                        else
                            object.cardExpirationYear = options.longs === String ? $util.Long.prototype.toString.call(message.cardExpirationYear) : options.longs === Number ? new $util.LongBits(message.cardExpirationYear.low >>> 0, message.cardExpirationYear.high >>> 0).toNumber(true) : message.cardExpirationYear;
                    if (message.cardZipCode != null && message.hasOwnProperty("cardZipCode"))
                        object.cardZipCode = message.cardZipCode;
                    if (message.ckoCardToken != null && message.hasOwnProperty("ckoCardToken"))
                        object.ckoCardToken = message.ckoCardToken;
                    if (message.footprintCardVaultToken != null && message.hasOwnProperty("footprintCardVaultToken"))
                        object.footprintCardVaultToken = message.footprintCardVaultToken;
                    return object;
                };

                /**
                 * Converts this CreateCardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateCardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateCardRequest;
            })();

            clientrpc.CreateCardResponse = (function() {

                /**
                 * Properties of a CreateCardResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateCardResponse
                 * @property {visor.clientrpc.CreateCardResponse.IData|null} [success] CreateCardResponse success
                 * @property {visor.clientrpc.CreateCardResponse.IError|null} [failure] CreateCardResponse failure
                 */

                /**
                 * Constructs a new CreateCardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateCardResponse.
                 * @implements ICreateCardResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateCardResponse=} [properties] Properties to set
                 */
                function CreateCardResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateCardResponse success.
                 * @member {visor.clientrpc.CreateCardResponse.IData|null|undefined} success
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @instance
                 */
                CreateCardResponse.prototype.success = null;

                /**
                 * CreateCardResponse failure.
                 * @member {visor.clientrpc.CreateCardResponse.IError|null|undefined} failure
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @instance
                 */
                CreateCardResponse.prototype.failure = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * CreateCardResponse result.
                 * @member {"success"|"failure"|undefined} result
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @instance
                 */
                Object.defineProperty(CreateCardResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["success", "failure"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new CreateCardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateCardResponse} CreateCardResponse instance
                 */
                CreateCardResponse.create = function create(properties) {
                    return new CreateCardResponse(properties);
                };

                /**
                 * Encodes the specified CreateCardResponse message. Does not implicitly {@link visor.clientrpc.CreateCardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCardResponse} message CreateCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && message.hasOwnProperty("success"))
                        $root.visor.clientrpc.CreateCardResponse.Data.encode(message.success, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.failure != null && message.hasOwnProperty("failure"))
                        $root.visor.clientrpc.CreateCardResponse.Error.encode(message.failure, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreateCardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {visor.clientrpc.ICreateCardResponse} message CreateCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateCardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateCardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateCardResponse} CreateCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.success = $root.visor.clientrpc.CreateCardResponse.Data.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.failure = $root.visor.clientrpc.CreateCardResponse.Error.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateCardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateCardResponse} CreateCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateCardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateCardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateCardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.CreateCardResponse.Data.verify(message.success);
                            if (error)
                                return "success." + error;
                        }
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.CreateCardResponse.Error.verify(message.failure);
                            if (error)
                                return "failure." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CreateCardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateCardResponse} CreateCardResponse
                 */
                CreateCardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateCardResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreateCardResponse();
                    if (object.success != null) {
                        if (typeof object.success !== "object")
                            throw TypeError(".visor.clientrpc.CreateCardResponse.success: object expected");
                        message.success = $root.visor.clientrpc.CreateCardResponse.Data.fromObject(object.success);
                    }
                    if (object.failure != null) {
                        if (typeof object.failure !== "object")
                            throw TypeError(".visor.clientrpc.CreateCardResponse.failure: object expected");
                        message.failure = $root.visor.clientrpc.CreateCardResponse.Error.fromObject(object.failure);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CreateCardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @static
                 * @param {visor.clientrpc.CreateCardResponse} message CreateCardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateCardResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        object.success = $root.visor.clientrpc.CreateCardResponse.Data.toObject(message.success, options);
                        if (options.oneofs)
                            object.result = "success";
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        object.failure = $root.visor.clientrpc.CreateCardResponse.Error.toObject(message.failure, options);
                        if (options.oneofs)
                            object.result = "failure";
                    }
                    return object;
                };

                /**
                 * Converts this CreateCardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateCardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateCardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                CreateCardResponse.Data = (function() {

                    /**
                     * Properties of a Data.
                     * @memberof visor.clientrpc.CreateCardResponse
                     * @interface IData
                     * @property {visor.clientrpc.ICard|null} [card] Data card
                     */

                    /**
                     * Constructs a new Data.
                     * @memberof visor.clientrpc.CreateCardResponse
                     * @classdesc Represents a Data.
                     * @implements IData
                     * @constructor
                     * @param {visor.clientrpc.CreateCardResponse.IData=} [properties] Properties to set
                     */
                    function Data(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Data card.
                     * @member {visor.clientrpc.ICard|null|undefined} card
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @instance
                     */
                    Data.prototype.card = null;

                    /**
                     * Creates a new Data instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.IData=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateCardResponse.Data} Data instance
                     */
                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    /**
                     * Encodes the specified Data message. Does not implicitly {@link visor.clientrpc.CreateCardResponse.Data.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.card != null && message.hasOwnProperty("card"))
                            $root.visor.clientrpc.Card.encode(message.card, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Data message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCardResponse.Data.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateCardResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCardResponse.Data();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.card = $root.visor.clientrpc.Card.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateCardResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Data message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Data.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.card != null && message.hasOwnProperty("card")) {
                            var error = $root.visor.clientrpc.Card.verify(message.card);
                            if (error)
                                return "card." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Data message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateCardResponse.Data} Data
                     */
                    Data.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateCardResponse.Data)
                            return object;
                        var message = new $root.visor.clientrpc.CreateCardResponse.Data();
                        if (object.card != null) {
                            if (typeof object.card !== "object")
                                throw TypeError(".visor.clientrpc.CreateCardResponse.Data.card: object expected");
                            message.card = $root.visor.clientrpc.Card.fromObject(object.card);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Data message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.Data} message Data
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Data.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.card = null;
                        if (message.card != null && message.hasOwnProperty("card"))
                            object.card = $root.visor.clientrpc.Card.toObject(message.card, options);
                        return object;
                    };

                    /**
                     * Converts this Data to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateCardResponse.Data
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Data.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Data;
                })();

                CreateCardResponse.Error = (function() {

                    /**
                     * Properties of an Error.
                     * @memberof visor.clientrpc.CreateCardResponse
                     * @interface IError
                     * @property {visor.clientrpc.CreateCardResponse.Error.Code|null} [code] Error code
                     * @property {string|null} [message] Error message
                     */

                    /**
                     * Constructs a new Error.
                     * @memberof visor.clientrpc.CreateCardResponse
                     * @classdesc Represents an Error.
                     * @implements IError
                     * @constructor
                     * @param {visor.clientrpc.CreateCardResponse.IError=} [properties] Properties to set
                     */
                    function Error(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Error code.
                     * @member {visor.clientrpc.CreateCardResponse.Error.Code} code
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @instance
                     */
                    Error.prototype.code = 0;

                    /**
                     * Error message.
                     * @member {string} message
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @instance
                     */
                    Error.prototype.message = "";

                    /**
                     * Creates a new Error instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.IError=} [properties] Properties to set
                     * @returns {visor.clientrpc.CreateCardResponse.Error} Error instance
                     */
                    Error.create = function create(properties) {
                        return new Error(properties);
                    };

                    /**
                     * Encodes the specified Error message. Does not implicitly {@link visor.clientrpc.CreateCardResponse.Error.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                        return writer;
                    };

                    /**
                     * Encodes the specified Error message, length delimited. Does not implicitly {@link visor.clientrpc.CreateCardResponse.Error.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.CreateCardResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateCardResponse.Error();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.int32();
                                break;
                            case 2:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.CreateCardResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Error message.
                     * @function verify
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Error.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            switch (message.code) {
                            default:
                                return "code: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };

                    /**
                     * Creates an Error message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.CreateCardResponse.Error} Error
                     */
                    Error.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.CreateCardResponse.Error)
                            return object;
                        var message = new $root.visor.clientrpc.CreateCardResponse.Error();
                        switch (object.code) {
                        case "CREATE_CARD_RESPONSE_ERROR_CODE_INVALID":
                        case 0:
                            message.code = 0;
                            break;
                        case "CREATE_CARD_RESPONSE_ERROR_CODE_CARD_DECLINED":
                        case 1:
                            message.code = 1;
                            break;
                        }
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Error message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @static
                     * @param {visor.clientrpc.CreateCardResponse.Error} message Error
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Error.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.code = options.enums === String ? "CREATE_CARD_RESPONSE_ERROR_CODE_INVALID" : 0;
                            object.message = "";
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = options.enums === String ? $root.visor.clientrpc.CreateCardResponse.Error.Code[message.code] : message.code;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };

                    /**
                     * Converts this Error to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.CreateCardResponse.Error
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Error.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Code enum.
                     * @name visor.clientrpc.CreateCardResponse.Error.Code
                     * @enum {string}
                     * @property {number} CREATE_CARD_RESPONSE_ERROR_CODE_INVALID=0 CREATE_CARD_RESPONSE_ERROR_CODE_INVALID value
                     * @property {number} CREATE_CARD_RESPONSE_ERROR_CODE_CARD_DECLINED=1 CREATE_CARD_RESPONSE_ERROR_CODE_CARD_DECLINED value
                     */
                    Error.Code = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "CREATE_CARD_RESPONSE_ERROR_CODE_INVALID"] = 0;
                        values[valuesById[1] = "CREATE_CARD_RESPONSE_ERROR_CODE_CARD_DECLINED"] = 1;
                        return values;
                    })();

                    return Error;
                })();

                return CreateCardResponse;
            })();

            clientrpc.GetFootprintClientDecryptionAuthTokenRequest = (function() {

                /**
                 * Properties of a GetFootprintClientDecryptionAuthTokenRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetFootprintClientDecryptionAuthTokenRequest
                 * @property {Array.<string>|null} [vaultKeys] GetFootprintClientDecryptionAuthTokenRequest vaultKeys
                 */

                /**
                 * Constructs a new GetFootprintClientDecryptionAuthTokenRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetFootprintClientDecryptionAuthTokenRequest.
                 * @implements IGetFootprintClientDecryptionAuthTokenRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenRequest=} [properties] Properties to set
                 */
                function GetFootprintClientDecryptionAuthTokenRequest(properties) {
                    this.vaultKeys = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetFootprintClientDecryptionAuthTokenRequest vaultKeys.
                 * @member {Array.<string>} vaultKeys
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @instance
                 */
                GetFootprintClientDecryptionAuthTokenRequest.prototype.vaultKeys = $util.emptyArray;

                /**
                 * Creates a new GetFootprintClientDecryptionAuthTokenRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest} GetFootprintClientDecryptionAuthTokenRequest instance
                 */
                GetFootprintClientDecryptionAuthTokenRequest.create = function create(properties) {
                    return new GetFootprintClientDecryptionAuthTokenRequest(properties);
                };

                /**
                 * Encodes the specified GetFootprintClientDecryptionAuthTokenRequest message. Does not implicitly {@link visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenRequest} message GetFootprintClientDecryptionAuthTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintClientDecryptionAuthTokenRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.vaultKeys != null && message.vaultKeys.length)
                        for (var i = 0; i < message.vaultKeys.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.vaultKeys[i]);
                    return writer;
                };

                /**
                 * Encodes the specified GetFootprintClientDecryptionAuthTokenRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenRequest} message GetFootprintClientDecryptionAuthTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintClientDecryptionAuthTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetFootprintClientDecryptionAuthTokenRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest} GetFootprintClientDecryptionAuthTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintClientDecryptionAuthTokenRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.vaultKeys && message.vaultKeys.length))
                                message.vaultKeys = [];
                            message.vaultKeys.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetFootprintClientDecryptionAuthTokenRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest} GetFootprintClientDecryptionAuthTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintClientDecryptionAuthTokenRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetFootprintClientDecryptionAuthTokenRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFootprintClientDecryptionAuthTokenRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.vaultKeys != null && message.hasOwnProperty("vaultKeys")) {
                        if (!Array.isArray(message.vaultKeys))
                            return "vaultKeys: array expected";
                        for (var i = 0; i < message.vaultKeys.length; ++i)
                            if (!$util.isString(message.vaultKeys[i]))
                                return "vaultKeys: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a GetFootprintClientDecryptionAuthTokenRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest} GetFootprintClientDecryptionAuthTokenRequest
                 */
                GetFootprintClientDecryptionAuthTokenRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest();
                    if (object.vaultKeys) {
                        if (!Array.isArray(object.vaultKeys))
                            throw TypeError(".visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest.vaultKeys: array expected");
                        message.vaultKeys = [];
                        for (var i = 0; i < object.vaultKeys.length; ++i)
                            message.vaultKeys[i] = String(object.vaultKeys[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetFootprintClientDecryptionAuthTokenRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest} message GetFootprintClientDecryptionAuthTokenRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFootprintClientDecryptionAuthTokenRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.vaultKeys = [];
                    if (message.vaultKeys && message.vaultKeys.length) {
                        object.vaultKeys = [];
                        for (var j = 0; j < message.vaultKeys.length; ++j)
                            object.vaultKeys[j] = message.vaultKeys[j];
                    }
                    return object;
                };

                /**
                 * Converts this GetFootprintClientDecryptionAuthTokenRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFootprintClientDecryptionAuthTokenRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetFootprintClientDecryptionAuthTokenRequest;
            })();

            clientrpc.GetFootprintClientDecryptionAuthTokenResponse = (function() {

                /**
                 * Properties of a GetFootprintClientDecryptionAuthTokenResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetFootprintClientDecryptionAuthTokenResponse
                 * @property {string|null} [footprintAuthToken] GetFootprintClientDecryptionAuthTokenResponse footprintAuthToken
                 * @property {google.protobuf.ITimestamp|null} [footprintAuthTokenExpiresAt] GetFootprintClientDecryptionAuthTokenResponse footprintAuthTokenExpiresAt
                 */

                /**
                 * Constructs a new GetFootprintClientDecryptionAuthTokenResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetFootprintClientDecryptionAuthTokenResponse.
                 * @implements IGetFootprintClientDecryptionAuthTokenResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenResponse=} [properties] Properties to set
                 */
                function GetFootprintClientDecryptionAuthTokenResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetFootprintClientDecryptionAuthTokenResponse footprintAuthToken.
                 * @member {string} footprintAuthToken
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @instance
                 */
                GetFootprintClientDecryptionAuthTokenResponse.prototype.footprintAuthToken = "";

                /**
                 * GetFootprintClientDecryptionAuthTokenResponse footprintAuthTokenExpiresAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} footprintAuthTokenExpiresAt
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @instance
                 */
                GetFootprintClientDecryptionAuthTokenResponse.prototype.footprintAuthTokenExpiresAt = null;

                /**
                 * Creates a new GetFootprintClientDecryptionAuthTokenResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse} GetFootprintClientDecryptionAuthTokenResponse instance
                 */
                GetFootprintClientDecryptionAuthTokenResponse.create = function create(properties) {
                    return new GetFootprintClientDecryptionAuthTokenResponse(properties);
                };

                /**
                 * Encodes the specified GetFootprintClientDecryptionAuthTokenResponse message. Does not implicitly {@link visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenResponse} message GetFootprintClientDecryptionAuthTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintClientDecryptionAuthTokenResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.footprintAuthToken != null && message.hasOwnProperty("footprintAuthToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.footprintAuthToken);
                    if (message.footprintAuthTokenExpiresAt != null && message.hasOwnProperty("footprintAuthTokenExpiresAt"))
                        $root.google.protobuf.Timestamp.encode(message.footprintAuthTokenExpiresAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetFootprintClientDecryptionAuthTokenResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetFootprintClientDecryptionAuthTokenResponse} message GetFootprintClientDecryptionAuthTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintClientDecryptionAuthTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetFootprintClientDecryptionAuthTokenResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse} GetFootprintClientDecryptionAuthTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintClientDecryptionAuthTokenResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.footprintAuthToken = reader.string();
                            break;
                        case 2:
                            message.footprintAuthTokenExpiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetFootprintClientDecryptionAuthTokenResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse} GetFootprintClientDecryptionAuthTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintClientDecryptionAuthTokenResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetFootprintClientDecryptionAuthTokenResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFootprintClientDecryptionAuthTokenResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.footprintAuthToken != null && message.hasOwnProperty("footprintAuthToken"))
                        if (!$util.isString(message.footprintAuthToken))
                            return "footprintAuthToken: string expected";
                    if (message.footprintAuthTokenExpiresAt != null && message.hasOwnProperty("footprintAuthTokenExpiresAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.footprintAuthTokenExpiresAt);
                        if (error)
                            return "footprintAuthTokenExpiresAt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetFootprintClientDecryptionAuthTokenResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse} GetFootprintClientDecryptionAuthTokenResponse
                 */
                GetFootprintClientDecryptionAuthTokenResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse();
                    if (object.footprintAuthToken != null)
                        message.footprintAuthToken = String(object.footprintAuthToken);
                    if (object.footprintAuthTokenExpiresAt != null)
                        if (object.footprintAuthTokenExpiresAt === null)
                            message.footprintAuthTokenExpiresAt = {};
                        else
                            message.footprintAuthTokenExpiresAt = {
                                seconds: Math.floor(object.footprintAuthTokenExpiresAt.getTime() / 1000),
                                nanos: object.footprintAuthTokenExpiresAt.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a GetFootprintClientDecryptionAuthTokenResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse} message GetFootprintClientDecryptionAuthTokenResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFootprintClientDecryptionAuthTokenResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.footprintAuthToken = "";
                        object.footprintAuthTokenExpiresAt = null;
                    }
                    if (message.footprintAuthToken != null && message.hasOwnProperty("footprintAuthToken"))
                        object.footprintAuthToken = message.footprintAuthToken;
                    if (message.footprintAuthTokenExpiresAt != null && message.hasOwnProperty("footprintAuthTokenExpiresAt"))
                        object.footprintAuthTokenExpiresAt = new Date(message.footprintAuthTokenExpiresAt.seconds * 1000 + message.footprintAuthTokenExpiresAt.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this GetFootprintClientDecryptionAuthTokenResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetFootprintClientDecryptionAuthTokenResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFootprintClientDecryptionAuthTokenResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetFootprintClientDecryptionAuthTokenResponse;
            })();

            clientrpc.GetFootprintAuthTokenRequest = (function() {

                /**
                 * Properties of a GetFootprintAuthTokenRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetFootprintAuthTokenRequest
                 */

                /**
                 * Constructs a new GetFootprintAuthTokenRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetFootprintAuthTokenRequest.
                 * @implements IGetFootprintAuthTokenRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetFootprintAuthTokenRequest=} [properties] Properties to set
                 */
                function GetFootprintAuthTokenRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetFootprintAuthTokenRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetFootprintAuthTokenRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetFootprintAuthTokenRequest} GetFootprintAuthTokenRequest instance
                 */
                GetFootprintAuthTokenRequest.create = function create(properties) {
                    return new GetFootprintAuthTokenRequest(properties);
                };

                /**
                 * Encodes the specified GetFootprintAuthTokenRequest message. Does not implicitly {@link visor.clientrpc.GetFootprintAuthTokenRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetFootprintAuthTokenRequest} message GetFootprintAuthTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintAuthTokenRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetFootprintAuthTokenRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetFootprintAuthTokenRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.IGetFootprintAuthTokenRequest} message GetFootprintAuthTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintAuthTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetFootprintAuthTokenRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetFootprintAuthTokenRequest} GetFootprintAuthTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintAuthTokenRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetFootprintAuthTokenRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetFootprintAuthTokenRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetFootprintAuthTokenRequest} GetFootprintAuthTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintAuthTokenRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetFootprintAuthTokenRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFootprintAuthTokenRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetFootprintAuthTokenRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetFootprintAuthTokenRequest} GetFootprintAuthTokenRequest
                 */
                GetFootprintAuthTokenRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetFootprintAuthTokenRequest)
                        return object;
                    return new $root.visor.clientrpc.GetFootprintAuthTokenRequest();
                };

                /**
                 * Creates a plain object from a GetFootprintAuthTokenRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @static
                 * @param {visor.clientrpc.GetFootprintAuthTokenRequest} message GetFootprintAuthTokenRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFootprintAuthTokenRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetFootprintAuthTokenRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetFootprintAuthTokenRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFootprintAuthTokenRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetFootprintAuthTokenRequest;
            })();

            clientrpc.GetFootprintAuthTokenResponse = (function() {

                /**
                 * Properties of a GetFootprintAuthTokenResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetFootprintAuthTokenResponse
                 * @property {string|null} [footprintAuthToken] GetFootprintAuthTokenResponse footprintAuthToken
                 * @property {google.protobuf.ITimestamp|null} [footprintAuthTokenExpiresAt] GetFootprintAuthTokenResponse footprintAuthTokenExpiresAt
                 * @property {string|null} [cardToken] GetFootprintAuthTokenResponse cardToken
                 */

                /**
                 * Constructs a new GetFootprintAuthTokenResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetFootprintAuthTokenResponse.
                 * @implements IGetFootprintAuthTokenResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetFootprintAuthTokenResponse=} [properties] Properties to set
                 */
                function GetFootprintAuthTokenResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetFootprintAuthTokenResponse footprintAuthToken.
                 * @member {string} footprintAuthToken
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @instance
                 */
                GetFootprintAuthTokenResponse.prototype.footprintAuthToken = "";

                /**
                 * GetFootprintAuthTokenResponse footprintAuthTokenExpiresAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} footprintAuthTokenExpiresAt
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @instance
                 */
                GetFootprintAuthTokenResponse.prototype.footprintAuthTokenExpiresAt = null;

                /**
                 * GetFootprintAuthTokenResponse cardToken.
                 * @member {string} cardToken
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @instance
                 */
                GetFootprintAuthTokenResponse.prototype.cardToken = "";

                /**
                 * Creates a new GetFootprintAuthTokenResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetFootprintAuthTokenResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetFootprintAuthTokenResponse} GetFootprintAuthTokenResponse instance
                 */
                GetFootprintAuthTokenResponse.create = function create(properties) {
                    return new GetFootprintAuthTokenResponse(properties);
                };

                /**
                 * Encodes the specified GetFootprintAuthTokenResponse message. Does not implicitly {@link visor.clientrpc.GetFootprintAuthTokenResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetFootprintAuthTokenResponse} message GetFootprintAuthTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintAuthTokenResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.footprintAuthToken != null && message.hasOwnProperty("footprintAuthToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.footprintAuthToken);
                    if (message.footprintAuthTokenExpiresAt != null && message.hasOwnProperty("footprintAuthTokenExpiresAt"))
                        $root.google.protobuf.Timestamp.encode(message.footprintAuthTokenExpiresAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.cardToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetFootprintAuthTokenResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetFootprintAuthTokenResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.IGetFootprintAuthTokenResponse} message GetFootprintAuthTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetFootprintAuthTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetFootprintAuthTokenResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetFootprintAuthTokenResponse} GetFootprintAuthTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintAuthTokenResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetFootprintAuthTokenResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.footprintAuthToken = reader.string();
                            break;
                        case 2:
                            message.footprintAuthTokenExpiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.cardToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetFootprintAuthTokenResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetFootprintAuthTokenResponse} GetFootprintAuthTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetFootprintAuthTokenResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetFootprintAuthTokenResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetFootprintAuthTokenResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.footprintAuthToken != null && message.hasOwnProperty("footprintAuthToken"))
                        if (!$util.isString(message.footprintAuthToken))
                            return "footprintAuthToken: string expected";
                    if (message.footprintAuthTokenExpiresAt != null && message.hasOwnProperty("footprintAuthTokenExpiresAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.footprintAuthTokenExpiresAt);
                        if (error)
                            return "footprintAuthTokenExpiresAt." + error;
                    }
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        if (!$util.isString(message.cardToken))
                            return "cardToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetFootprintAuthTokenResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetFootprintAuthTokenResponse} GetFootprintAuthTokenResponse
                 */
                GetFootprintAuthTokenResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetFootprintAuthTokenResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetFootprintAuthTokenResponse();
                    if (object.footprintAuthToken != null)
                        message.footprintAuthToken = String(object.footprintAuthToken);
                    if (object.footprintAuthTokenExpiresAt != null)
                        if (object.footprintAuthTokenExpiresAt === null)
                            message.footprintAuthTokenExpiresAt = {};
                        else
                            message.footprintAuthTokenExpiresAt = {
                                seconds: Math.floor(object.footprintAuthTokenExpiresAt.getTime() / 1000),
                                nanos: object.footprintAuthTokenExpiresAt.getMilliseconds() * 1000000
                            };
                    if (object.cardToken != null)
                        message.cardToken = String(object.cardToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetFootprintAuthTokenResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @static
                 * @param {visor.clientrpc.GetFootprintAuthTokenResponse} message GetFootprintAuthTokenResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetFootprintAuthTokenResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.footprintAuthToken = "";
                        object.footprintAuthTokenExpiresAt = null;
                        object.cardToken = "";
                    }
                    if (message.footprintAuthToken != null && message.hasOwnProperty("footprintAuthToken"))
                        object.footprintAuthToken = message.footprintAuthToken;
                    if (message.footprintAuthTokenExpiresAt != null && message.hasOwnProperty("footprintAuthTokenExpiresAt"))
                        object.footprintAuthTokenExpiresAt = new Date(message.footprintAuthTokenExpiresAt.seconds * 1000 + message.footprintAuthTokenExpiresAt.nanos / 1000000);
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        object.cardToken = message.cardToken;
                    return object;
                };

                /**
                 * Converts this GetFootprintAuthTokenResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetFootprintAuthTokenResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetFootprintAuthTokenResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetFootprintAuthTokenResponse;
            })();

            clientrpc.Card = (function() {

                /**
                 * Properties of a Card.
                 * @memberof visor.clientrpc
                 * @interface ICard
                 * @property {string|null} [token] Card token
                 * @property {string|null} [title] Card title
                 * @property {string|null} [name] Card name
                 * @property {string|null} [brand] Card brand
                 * @property {string|null} [mask] Card mask
                 */

                /**
                 * Constructs a new Card.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a Card.
                 * @implements ICard
                 * @constructor
                 * @param {visor.clientrpc.ICard=} [properties] Properties to set
                 */
                function Card(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Card token.
                 * @member {string} token
                 * @memberof visor.clientrpc.Card
                 * @instance
                 */
                Card.prototype.token = "";

                /**
                 * Card title.
                 * @member {string} title
                 * @memberof visor.clientrpc.Card
                 * @instance
                 */
                Card.prototype.title = "";

                /**
                 * Card name.
                 * @member {string} name
                 * @memberof visor.clientrpc.Card
                 * @instance
                 */
                Card.prototype.name = "";

                /**
                 * Card brand.
                 * @member {string} brand
                 * @memberof visor.clientrpc.Card
                 * @instance
                 */
                Card.prototype.brand = "";

                /**
                 * Card mask.
                 * @member {string} mask
                 * @memberof visor.clientrpc.Card
                 * @instance
                 */
                Card.prototype.mask = "";

                /**
                 * Creates a new Card instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {visor.clientrpc.ICard=} [properties] Properties to set
                 * @returns {visor.clientrpc.Card} Card instance
                 */
                Card.create = function create(properties) {
                    return new Card(properties);
                };

                /**
                 * Encodes the specified Card message. Does not implicitly {@link visor.clientrpc.Card.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {visor.clientrpc.ICard} message Card message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Card.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.token != null && message.hasOwnProperty("token"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                    if (message.title != null && message.hasOwnProperty("title"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
                    if (message.name != null && message.hasOwnProperty("name"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                    if (message.brand != null && message.hasOwnProperty("brand"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.brand);
                    if (message.mask != null && message.hasOwnProperty("mask"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.mask);
                    return writer;
                };

                /**
                 * Encodes the specified Card message, length delimited. Does not implicitly {@link visor.clientrpc.Card.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {visor.clientrpc.ICard} message Card message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Card.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Card message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.Card} Card
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Card.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.Card();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.token = reader.string();
                            break;
                        case 4:
                            message.title = reader.string();
                            break;
                        case 5:
                            message.name = reader.string();
                            break;
                        case 8:
                            message.brand = reader.string();
                            break;
                        case 9:
                            message.mask = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Card message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.Card} Card
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Card.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Card message.
                 * @function verify
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Card.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.token != null && message.hasOwnProperty("token"))
                        if (!$util.isString(message.token))
                            return "token: string expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.brand != null && message.hasOwnProperty("brand"))
                        if (!$util.isString(message.brand))
                            return "brand: string expected";
                    if (message.mask != null && message.hasOwnProperty("mask"))
                        if (!$util.isString(message.mask))
                            return "mask: string expected";
                    return null;
                };

                /**
                 * Creates a Card message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.Card} Card
                 */
                Card.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.Card)
                        return object;
                    var message = new $root.visor.clientrpc.Card();
                    if (object.token != null)
                        message.token = String(object.token);
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.brand != null)
                        message.brand = String(object.brand);
                    if (object.mask != null)
                        message.mask = String(object.mask);
                    return message;
                };

                /**
                 * Creates a plain object from a Card message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.Card
                 * @static
                 * @param {visor.clientrpc.Card} message Card
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Card.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.token = "";
                        object.title = "";
                        object.name = "";
                        object.brand = "";
                        object.mask = "";
                    }
                    if (message.token != null && message.hasOwnProperty("token"))
                        object.token = message.token;
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.brand != null && message.hasOwnProperty("brand"))
                        object.brand = message.brand;
                    if (message.mask != null && message.hasOwnProperty("mask"))
                        object.mask = message.mask;
                    return object;
                };

                /**
                 * Converts this Card to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.Card
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Card.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Card;
            })();

            clientrpc.ListInvoicesRequest = (function() {

                /**
                 * Properties of a ListInvoicesRequest.
                 * @memberof visor.clientrpc
                 * @interface IListInvoicesRequest
                 */

                /**
                 * Constructs a new ListInvoicesRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ListInvoicesRequest.
                 * @implements IListInvoicesRequest
                 * @constructor
                 * @param {visor.clientrpc.IListInvoicesRequest=} [properties] Properties to set
                 */
                function ListInvoicesRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ListInvoicesRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {visor.clientrpc.IListInvoicesRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ListInvoicesRequest} ListInvoicesRequest instance
                 */
                ListInvoicesRequest.create = function create(properties) {
                    return new ListInvoicesRequest(properties);
                };

                /**
                 * Encodes the specified ListInvoicesRequest message. Does not implicitly {@link visor.clientrpc.ListInvoicesRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {visor.clientrpc.IListInvoicesRequest} message ListInvoicesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListInvoicesRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ListInvoicesRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ListInvoicesRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {visor.clientrpc.IListInvoicesRequest} message ListInvoicesRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListInvoicesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ListInvoicesRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ListInvoicesRequest} ListInvoicesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListInvoicesRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListInvoicesRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ListInvoicesRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ListInvoicesRequest} ListInvoicesRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListInvoicesRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ListInvoicesRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListInvoicesRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ListInvoicesRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ListInvoicesRequest} ListInvoicesRequest
                 */
                ListInvoicesRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ListInvoicesRequest)
                        return object;
                    return new $root.visor.clientrpc.ListInvoicesRequest();
                };

                /**
                 * Creates a plain object from a ListInvoicesRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @static
                 * @param {visor.clientrpc.ListInvoicesRequest} message ListInvoicesRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListInvoicesRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ListInvoicesRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ListInvoicesRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListInvoicesRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ListInvoicesRequest;
            })();

            clientrpc.ListInvoicesResponse = (function() {

                /**
                 * Properties of a ListInvoicesResponse.
                 * @memberof visor.clientrpc
                 * @interface IListInvoicesResponse
                 * @property {Array.<visor.clientrpc.ListInvoicesResponse.IInvoice>|null} [invoices] ListInvoicesResponse invoices
                 * @property {visor.common.time.ILocalDate|null} [nextInvoiceAt] ListInvoicesResponse nextInvoiceAt
                 */

                /**
                 * Constructs a new ListInvoicesResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ListInvoicesResponse.
                 * @implements IListInvoicesResponse
                 * @constructor
                 * @param {visor.clientrpc.IListInvoicesResponse=} [properties] Properties to set
                 */
                function ListInvoicesResponse(properties) {
                    this.invoices = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ListInvoicesResponse invoices.
                 * @member {Array.<visor.clientrpc.ListInvoicesResponse.IInvoice>} invoices
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @instance
                 */
                ListInvoicesResponse.prototype.invoices = $util.emptyArray;

                /**
                 * ListInvoicesResponse nextInvoiceAt.
                 * @member {visor.common.time.ILocalDate|null|undefined} nextInvoiceAt
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @instance
                 */
                ListInvoicesResponse.prototype.nextInvoiceAt = null;

                /**
                 * Creates a new ListInvoicesResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {visor.clientrpc.IListInvoicesResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ListInvoicesResponse} ListInvoicesResponse instance
                 */
                ListInvoicesResponse.create = function create(properties) {
                    return new ListInvoicesResponse(properties);
                };

                /**
                 * Encodes the specified ListInvoicesResponse message. Does not implicitly {@link visor.clientrpc.ListInvoicesResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {visor.clientrpc.IListInvoicesResponse} message ListInvoicesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListInvoicesResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invoices != null && message.invoices.length)
                        for (var i = 0; i < message.invoices.length; ++i)
                            $root.visor.clientrpc.ListInvoicesResponse.Invoice.encode(message.invoices[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.nextInvoiceAt != null && message.hasOwnProperty("nextInvoiceAt"))
                        $root.visor.common.time.LocalDate.encode(message.nextInvoiceAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ListInvoicesResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ListInvoicesResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {visor.clientrpc.IListInvoicesResponse} message ListInvoicesResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ListInvoicesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ListInvoicesResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ListInvoicesResponse} ListInvoicesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListInvoicesResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListInvoicesResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.invoices && message.invoices.length))
                                message.invoices = [];
                            message.invoices.push($root.visor.clientrpc.ListInvoicesResponse.Invoice.decode(reader, reader.uint32()));
                            break;
                        case 2:
                            message.nextInvoiceAt = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ListInvoicesResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ListInvoicesResponse} ListInvoicesResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ListInvoicesResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ListInvoicesResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ListInvoicesResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invoices != null && message.hasOwnProperty("invoices")) {
                        if (!Array.isArray(message.invoices))
                            return "invoices: array expected";
                        for (var i = 0; i < message.invoices.length; ++i) {
                            var error = $root.visor.clientrpc.ListInvoicesResponse.Invoice.verify(message.invoices[i]);
                            if (error)
                                return "invoices." + error;
                        }
                    }
                    if (message.nextInvoiceAt != null && message.hasOwnProperty("nextInvoiceAt")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.nextInvoiceAt);
                        if (error)
                            return "nextInvoiceAt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ListInvoicesResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ListInvoicesResponse} ListInvoicesResponse
                 */
                ListInvoicesResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ListInvoicesResponse)
                        return object;
                    var message = new $root.visor.clientrpc.ListInvoicesResponse();
                    if (object.invoices) {
                        if (!Array.isArray(object.invoices))
                            throw TypeError(".visor.clientrpc.ListInvoicesResponse.invoices: array expected");
                        message.invoices = [];
                        for (var i = 0; i < object.invoices.length; ++i) {
                            if (typeof object.invoices[i] !== "object")
                                throw TypeError(".visor.clientrpc.ListInvoicesResponse.invoices: object expected");
                            message.invoices[i] = $root.visor.clientrpc.ListInvoicesResponse.Invoice.fromObject(object.invoices[i]);
                        }
                    }
                    if (object.nextInvoiceAt != null) {
                        if (typeof object.nextInvoiceAt !== "object")
                            throw TypeError(".visor.clientrpc.ListInvoicesResponse.nextInvoiceAt: object expected");
                        message.nextInvoiceAt = $root.visor.common.time.LocalDate.fromObject(object.nextInvoiceAt);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ListInvoicesResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @static
                 * @param {visor.clientrpc.ListInvoicesResponse} message ListInvoicesResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ListInvoicesResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.invoices = [];
                    if (options.defaults)
                        object.nextInvoiceAt = null;
                    if (message.invoices && message.invoices.length) {
                        object.invoices = [];
                        for (var j = 0; j < message.invoices.length; ++j)
                            object.invoices[j] = $root.visor.clientrpc.ListInvoicesResponse.Invoice.toObject(message.invoices[j], options);
                    }
                    if (message.nextInvoiceAt != null && message.hasOwnProperty("nextInvoiceAt"))
                        object.nextInvoiceAt = $root.visor.common.time.LocalDate.toObject(message.nextInvoiceAt, options);
                    return object;
                };

                /**
                 * Converts this ListInvoicesResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ListInvoicesResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ListInvoicesResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                ListInvoicesResponse.Invoice = (function() {

                    /**
                     * Properties of an Invoice.
                     * @memberof visor.clientrpc.ListInvoicesResponse
                     * @interface IInvoice
                     * @property {string|null} [token] Invoice token
                     * @property {visor.clientrpc.ListInvoicesResponse.InvoiceState|null} [state] Invoice state
                     * @property {visor.common.money.IAmount|null} [amountDue] Invoice amountDue
                     * @property {visor.common.money.IAmount|null} [amountPaid] Invoice amountPaid
                     * @property {visor.common.money.IAmount|null} [amountRemaining] Invoice amountRemaining
                     * @property {visor.common.time.ILocalDate|null} [periodStart] Invoice periodStart
                     * @property {visor.common.time.ILocalDate|null} [periodEnd] Invoice periodEnd
                     * @property {visor.common.time.ILocalDate|null} [paidAt] Invoice paidAt
                     * @property {string|null} [subscriptionToken] Invoice subscriptionToken
                     */

                    /**
                     * Constructs a new Invoice.
                     * @memberof visor.clientrpc.ListInvoicesResponse
                     * @classdesc Represents an Invoice.
                     * @implements IInvoice
                     * @constructor
                     * @param {visor.clientrpc.ListInvoicesResponse.IInvoice=} [properties] Properties to set
                     */
                    function Invoice(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Invoice token.
                     * @member {string} token
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.token = "";

                    /**
                     * Invoice state.
                     * @member {visor.clientrpc.ListInvoicesResponse.InvoiceState} state
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.state = 0;

                    /**
                     * Invoice amountDue.
                     * @member {visor.common.money.IAmount|null|undefined} amountDue
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.amountDue = null;

                    /**
                     * Invoice amountPaid.
                     * @member {visor.common.money.IAmount|null|undefined} amountPaid
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.amountPaid = null;

                    /**
                     * Invoice amountRemaining.
                     * @member {visor.common.money.IAmount|null|undefined} amountRemaining
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.amountRemaining = null;

                    /**
                     * Invoice periodStart.
                     * @member {visor.common.time.ILocalDate|null|undefined} periodStart
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.periodStart = null;

                    /**
                     * Invoice periodEnd.
                     * @member {visor.common.time.ILocalDate|null|undefined} periodEnd
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.periodEnd = null;

                    /**
                     * Invoice paidAt.
                     * @member {visor.common.time.ILocalDate|null|undefined} paidAt
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.paidAt = null;

                    /**
                     * Invoice subscriptionToken.
                     * @member {string} subscriptionToken
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     */
                    Invoice.prototype.subscriptionToken = "";

                    /**
                     * Creates a new Invoice instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {visor.clientrpc.ListInvoicesResponse.IInvoice=} [properties] Properties to set
                     * @returns {visor.clientrpc.ListInvoicesResponse.Invoice} Invoice instance
                     */
                    Invoice.create = function create(properties) {
                        return new Invoice(properties);
                    };

                    /**
                     * Encodes the specified Invoice message. Does not implicitly {@link visor.clientrpc.ListInvoicesResponse.Invoice.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {visor.clientrpc.ListInvoicesResponse.IInvoice} message Invoice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Invoice.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
                        if (message.amountDue != null && message.hasOwnProperty("amountDue"))
                            $root.visor.common.money.Amount.encode(message.amountDue, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.amountPaid != null && message.hasOwnProperty("amountPaid"))
                            $root.visor.common.money.Amount.encode(message.amountPaid, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.amountRemaining != null && message.hasOwnProperty("amountRemaining"))
                            $root.visor.common.money.Amount.encode(message.amountRemaining, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.periodStart != null && message.hasOwnProperty("periodStart"))
                            $root.visor.common.time.LocalDate.encode(message.periodStart, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.periodEnd != null && message.hasOwnProperty("periodEnd"))
                            $root.visor.common.time.LocalDate.encode(message.periodEnd, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.subscriptionToken);
                        if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                            $root.visor.common.time.LocalDate.encode(message.paidAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Invoice message, length delimited. Does not implicitly {@link visor.clientrpc.ListInvoicesResponse.Invoice.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {visor.clientrpc.ListInvoicesResponse.IInvoice} message Invoice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Invoice.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Invoice message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.ListInvoicesResponse.Invoice} Invoice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Invoice.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ListInvoicesResponse.Invoice();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            case 2:
                                message.state = reader.int32();
                                break;
                            case 3:
                                message.amountDue = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.amountPaid = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.amountRemaining = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.periodStart = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.periodEnd = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.paidAt = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.subscriptionToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Invoice message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.ListInvoicesResponse.Invoice} Invoice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Invoice.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Invoice message.
                     * @function verify
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Invoice.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                                break;
                            }
                        if (message.amountDue != null && message.hasOwnProperty("amountDue")) {
                            var error = $root.visor.common.money.Amount.verify(message.amountDue);
                            if (error)
                                return "amountDue." + error;
                        }
                        if (message.amountPaid != null && message.hasOwnProperty("amountPaid")) {
                            var error = $root.visor.common.money.Amount.verify(message.amountPaid);
                            if (error)
                                return "amountPaid." + error;
                        }
                        if (message.amountRemaining != null && message.hasOwnProperty("amountRemaining")) {
                            var error = $root.visor.common.money.Amount.verify(message.amountRemaining);
                            if (error)
                                return "amountRemaining." + error;
                        }
                        if (message.periodStart != null && message.hasOwnProperty("periodStart")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.periodStart);
                            if (error)
                                return "periodStart." + error;
                        }
                        if (message.periodEnd != null && message.hasOwnProperty("periodEnd")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.periodEnd);
                            if (error)
                                return "periodEnd." + error;
                        }
                        if (message.paidAt != null && message.hasOwnProperty("paidAt")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.paidAt);
                            if (error)
                                return "paidAt." + error;
                        }
                        if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                            if (!$util.isString(message.subscriptionToken))
                                return "subscriptionToken: string expected";
                        return null;
                    };

                    /**
                     * Creates an Invoice message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.ListInvoicesResponse.Invoice} Invoice
                     */
                    Invoice.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.ListInvoicesResponse.Invoice)
                            return object;
                        var message = new $root.visor.clientrpc.ListInvoicesResponse.Invoice();
                        if (object.token != null)
                            message.token = String(object.token);
                        switch (object.state) {
                        case "LIST_INVOICES_RESPONSE_INVOICE_STATE_INVALID":
                        case 0:
                            message.state = 0;
                            break;
                        case "LIST_INVOICES_RESPONSE_INVOICE_STATE_OPEN":
                        case 1:
                            message.state = 1;
                            break;
                        case "LIST_INVOICES_RESPONSE_INVOICE_STATE_PAID":
                        case 2:
                            message.state = 2;
                            break;
                        case "LIST_INVOICES_RESPONSE_INVOICE_STATE_VOID":
                        case 3:
                            message.state = 3;
                            break;
                        case "LIST_INVOICES_RESPONSE_INVOICE_STATE_PAST_DUE":
                        case 4:
                            message.state = 4;
                            break;
                        case "LIST_INVOICES_RESPONSE_INVOICE_STATE_DRAFT":
                        case 5:
                            message.state = 5;
                            break;
                        case "LIST_INVOICES_RESPONSE_INVOICE_STATE_FREE":
                        case 6:
                            message.state = 6;
                            break;
                        }
                        if (object.amountDue != null) {
                            if (typeof object.amountDue !== "object")
                                throw TypeError(".visor.clientrpc.ListInvoicesResponse.Invoice.amountDue: object expected");
                            message.amountDue = $root.visor.common.money.Amount.fromObject(object.amountDue);
                        }
                        if (object.amountPaid != null) {
                            if (typeof object.amountPaid !== "object")
                                throw TypeError(".visor.clientrpc.ListInvoicesResponse.Invoice.amountPaid: object expected");
                            message.amountPaid = $root.visor.common.money.Amount.fromObject(object.amountPaid);
                        }
                        if (object.amountRemaining != null) {
                            if (typeof object.amountRemaining !== "object")
                                throw TypeError(".visor.clientrpc.ListInvoicesResponse.Invoice.amountRemaining: object expected");
                            message.amountRemaining = $root.visor.common.money.Amount.fromObject(object.amountRemaining);
                        }
                        if (object.periodStart != null) {
                            if (typeof object.periodStart !== "object")
                                throw TypeError(".visor.clientrpc.ListInvoicesResponse.Invoice.periodStart: object expected");
                            message.periodStart = $root.visor.common.time.LocalDate.fromObject(object.periodStart);
                        }
                        if (object.periodEnd != null) {
                            if (typeof object.periodEnd !== "object")
                                throw TypeError(".visor.clientrpc.ListInvoicesResponse.Invoice.periodEnd: object expected");
                            message.periodEnd = $root.visor.common.time.LocalDate.fromObject(object.periodEnd);
                        }
                        if (object.paidAt != null) {
                            if (typeof object.paidAt !== "object")
                                throw TypeError(".visor.clientrpc.ListInvoicesResponse.Invoice.paidAt: object expected");
                            message.paidAt = $root.visor.common.time.LocalDate.fromObject(object.paidAt);
                        }
                        if (object.subscriptionToken != null)
                            message.subscriptionToken = String(object.subscriptionToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Invoice message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @static
                     * @param {visor.clientrpc.ListInvoicesResponse.Invoice} message Invoice
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Invoice.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.token = "";
                            object.state = options.enums === String ? "LIST_INVOICES_RESPONSE_INVOICE_STATE_INVALID" : 0;
                            object.amountDue = null;
                            object.amountPaid = null;
                            object.amountRemaining = null;
                            object.periodStart = null;
                            object.periodEnd = null;
                            object.subscriptionToken = "";
                            object.paidAt = null;
                        }
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.visor.clientrpc.ListInvoicesResponse.InvoiceState[message.state] : message.state;
                        if (message.amountDue != null && message.hasOwnProperty("amountDue"))
                            object.amountDue = $root.visor.common.money.Amount.toObject(message.amountDue, options);
                        if (message.amountPaid != null && message.hasOwnProperty("amountPaid"))
                            object.amountPaid = $root.visor.common.money.Amount.toObject(message.amountPaid, options);
                        if (message.amountRemaining != null && message.hasOwnProperty("amountRemaining"))
                            object.amountRemaining = $root.visor.common.money.Amount.toObject(message.amountRemaining, options);
                        if (message.periodStart != null && message.hasOwnProperty("periodStart"))
                            object.periodStart = $root.visor.common.time.LocalDate.toObject(message.periodStart, options);
                        if (message.periodEnd != null && message.hasOwnProperty("periodEnd"))
                            object.periodEnd = $root.visor.common.time.LocalDate.toObject(message.periodEnd, options);
                        if (message.subscriptionToken != null && message.hasOwnProperty("subscriptionToken"))
                            object.subscriptionToken = message.subscriptionToken;
                        if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                            object.paidAt = $root.visor.common.time.LocalDate.toObject(message.paidAt, options);
                        return object;
                    };

                    /**
                     * Converts this Invoice to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.ListInvoicesResponse.Invoice
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Invoice.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Invoice;
                })();

                /**
                 * InvoiceState enum.
                 * @name visor.clientrpc.ListInvoicesResponse.InvoiceState
                 * @enum {string}
                 * @property {number} LIST_INVOICES_RESPONSE_INVOICE_STATE_INVALID=0 LIST_INVOICES_RESPONSE_INVOICE_STATE_INVALID value
                 * @property {number} LIST_INVOICES_RESPONSE_INVOICE_STATE_OPEN=1 LIST_INVOICES_RESPONSE_INVOICE_STATE_OPEN value
                 * @property {number} LIST_INVOICES_RESPONSE_INVOICE_STATE_PAID=2 LIST_INVOICES_RESPONSE_INVOICE_STATE_PAID value
                 * @property {number} LIST_INVOICES_RESPONSE_INVOICE_STATE_VOID=3 LIST_INVOICES_RESPONSE_INVOICE_STATE_VOID value
                 * @property {number} LIST_INVOICES_RESPONSE_INVOICE_STATE_PAST_DUE=4 LIST_INVOICES_RESPONSE_INVOICE_STATE_PAST_DUE value
                 * @property {number} LIST_INVOICES_RESPONSE_INVOICE_STATE_DRAFT=5 LIST_INVOICES_RESPONSE_INVOICE_STATE_DRAFT value
                 * @property {number} LIST_INVOICES_RESPONSE_INVOICE_STATE_FREE=6 LIST_INVOICES_RESPONSE_INVOICE_STATE_FREE value
                 */
                ListInvoicesResponse.InvoiceState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "LIST_INVOICES_RESPONSE_INVOICE_STATE_INVALID"] = 0;
                    values[valuesById[1] = "LIST_INVOICES_RESPONSE_INVOICE_STATE_OPEN"] = 1;
                    values[valuesById[2] = "LIST_INVOICES_RESPONSE_INVOICE_STATE_PAID"] = 2;
                    values[valuesById[3] = "LIST_INVOICES_RESPONSE_INVOICE_STATE_VOID"] = 3;
                    values[valuesById[4] = "LIST_INVOICES_RESPONSE_INVOICE_STATE_PAST_DUE"] = 4;
                    values[valuesById[5] = "LIST_INVOICES_RESPONSE_INVOICE_STATE_DRAFT"] = 5;
                    values[valuesById[6] = "LIST_INVOICES_RESPONSE_INVOICE_STATE_FREE"] = 6;
                    return values;
                })();

                return ListInvoicesResponse;
            })();

            clientrpc.PayInvoiceRequest = (function() {

                /**
                 * Properties of a PayInvoiceRequest.
                 * @memberof visor.clientrpc
                 * @interface IPayInvoiceRequest
                 * @property {string|null} [invoiceToken] PayInvoiceRequest invoiceToken
                 * @property {string|null} [cardToken] PayInvoiceRequest cardToken
                 * @property {visor.common.money.IAmount|null} [amount] PayInvoiceRequest amount
                 */

                /**
                 * Constructs a new PayInvoiceRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PayInvoiceRequest.
                 * @implements IPayInvoiceRequest
                 * @constructor
                 * @param {visor.clientrpc.IPayInvoiceRequest=} [properties] Properties to set
                 */
                function PayInvoiceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PayInvoiceRequest invoiceToken.
                 * @member {string} invoiceToken
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @instance
                 */
                PayInvoiceRequest.prototype.invoiceToken = "";

                /**
                 * PayInvoiceRequest cardToken.
                 * @member {string} cardToken
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @instance
                 */
                PayInvoiceRequest.prototype.cardToken = "";

                /**
                 * PayInvoiceRequest amount.
                 * @member {visor.common.money.IAmount|null|undefined} amount
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @instance
                 */
                PayInvoiceRequest.prototype.amount = null;

                /**
                 * Creates a new PayInvoiceRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {visor.clientrpc.IPayInvoiceRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.PayInvoiceRequest} PayInvoiceRequest instance
                 */
                PayInvoiceRequest.create = function create(properties) {
                    return new PayInvoiceRequest(properties);
                };

                /**
                 * Encodes the specified PayInvoiceRequest message. Does not implicitly {@link visor.clientrpc.PayInvoiceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {visor.clientrpc.IPayInvoiceRequest} message PayInvoiceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayInvoiceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.invoiceToken != null && message.hasOwnProperty("invoiceToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.invoiceToken);
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardToken);
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PayInvoiceRequest message, length delimited. Does not implicitly {@link visor.clientrpc.PayInvoiceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {visor.clientrpc.IPayInvoiceRequest} message PayInvoiceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayInvoiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PayInvoiceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PayInvoiceRequest} PayInvoiceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayInvoiceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayInvoiceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.invoiceToken = reader.string();
                            break;
                        case 2:
                            message.cardToken = reader.string();
                            break;
                        case 3:
                            message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PayInvoiceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PayInvoiceRequest} PayInvoiceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayInvoiceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PayInvoiceRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayInvoiceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.invoiceToken != null && message.hasOwnProperty("invoiceToken"))
                        if (!$util.isString(message.invoiceToken))
                            return "invoiceToken: string expected";
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        if (!$util.isString(message.cardToken))
                            return "cardToken: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.visor.common.money.Amount.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PayInvoiceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PayInvoiceRequest} PayInvoiceRequest
                 */
                PayInvoiceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PayInvoiceRequest)
                        return object;
                    var message = new $root.visor.clientrpc.PayInvoiceRequest();
                    if (object.invoiceToken != null)
                        message.invoiceToken = String(object.invoiceToken);
                    if (object.cardToken != null)
                        message.cardToken = String(object.cardToken);
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".visor.clientrpc.PayInvoiceRequest.amount: object expected");
                        message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PayInvoiceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @static
                 * @param {visor.clientrpc.PayInvoiceRequest} message PayInvoiceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayInvoiceRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.invoiceToken = "";
                        object.cardToken = "";
                        object.amount = null;
                    }
                    if (message.invoiceToken != null && message.hasOwnProperty("invoiceToken"))
                        object.invoiceToken = message.invoiceToken;
                    if (message.cardToken != null && message.hasOwnProperty("cardToken"))
                        object.cardToken = message.cardToken;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                    return object;
                };

                /**
                 * Converts this PayInvoiceRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PayInvoiceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayInvoiceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return PayInvoiceRequest;
            })();

            clientrpc.PayInvoiceResponse = (function() {

                /**
                 * Properties of a PayInvoiceResponse.
                 * @memberof visor.clientrpc
                 * @interface IPayInvoiceResponse
                 * @property {visor.clientrpc.PayInvoiceResponse.IData|null} [success] PayInvoiceResponse success
                 * @property {visor.clientrpc.PayInvoiceResponse.IError|null} [failure] PayInvoiceResponse failure
                 */

                /**
                 * Constructs a new PayInvoiceResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a PayInvoiceResponse.
                 * @implements IPayInvoiceResponse
                 * @constructor
                 * @param {visor.clientrpc.IPayInvoiceResponse=} [properties] Properties to set
                 */
                function PayInvoiceResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PayInvoiceResponse success.
                 * @member {visor.clientrpc.PayInvoiceResponse.IData|null|undefined} success
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @instance
                 */
                PayInvoiceResponse.prototype.success = null;

                /**
                 * PayInvoiceResponse failure.
                 * @member {visor.clientrpc.PayInvoiceResponse.IError|null|undefined} failure
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @instance
                 */
                PayInvoiceResponse.prototype.failure = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * PayInvoiceResponse result.
                 * @member {"success"|"failure"|undefined} result
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @instance
                 */
                Object.defineProperty(PayInvoiceResponse.prototype, "result", {
                    get: $util.oneOfGetter($oneOfFields = ["success", "failure"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new PayInvoiceResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {visor.clientrpc.IPayInvoiceResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.PayInvoiceResponse} PayInvoiceResponse instance
                 */
                PayInvoiceResponse.create = function create(properties) {
                    return new PayInvoiceResponse(properties);
                };

                /**
                 * Encodes the specified PayInvoiceResponse message. Does not implicitly {@link visor.clientrpc.PayInvoiceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {visor.clientrpc.IPayInvoiceResponse} message PayInvoiceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayInvoiceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && message.hasOwnProperty("success"))
                        $root.visor.clientrpc.PayInvoiceResponse.Data.encode(message.success, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.failure != null && message.hasOwnProperty("failure"))
                        $root.visor.clientrpc.PayInvoiceResponse.Error.encode(message.failure, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PayInvoiceResponse message, length delimited. Does not implicitly {@link visor.clientrpc.PayInvoiceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {visor.clientrpc.IPayInvoiceResponse} message PayInvoiceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PayInvoiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PayInvoiceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.PayInvoiceResponse} PayInvoiceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayInvoiceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayInvoiceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.success = $root.visor.clientrpc.PayInvoiceResponse.Data.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.failure = $root.visor.clientrpc.PayInvoiceResponse.Error.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PayInvoiceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.PayInvoiceResponse} PayInvoiceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PayInvoiceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PayInvoiceResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PayInvoiceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.PayInvoiceResponse.Data.verify(message.success);
                            if (error)
                                return "success." + error;
                        }
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        if (properties.result === 1)
                            return "result: multiple values";
                        properties.result = 1;
                        {
                            var error = $root.visor.clientrpc.PayInvoiceResponse.Error.verify(message.failure);
                            if (error)
                                return "failure." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a PayInvoiceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.PayInvoiceResponse} PayInvoiceResponse
                 */
                PayInvoiceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.PayInvoiceResponse)
                        return object;
                    var message = new $root.visor.clientrpc.PayInvoiceResponse();
                    if (object.success != null) {
                        if (typeof object.success !== "object")
                            throw TypeError(".visor.clientrpc.PayInvoiceResponse.success: object expected");
                        message.success = $root.visor.clientrpc.PayInvoiceResponse.Data.fromObject(object.success);
                    }
                    if (object.failure != null) {
                        if (typeof object.failure !== "object")
                            throw TypeError(".visor.clientrpc.PayInvoiceResponse.failure: object expected");
                        message.failure = $root.visor.clientrpc.PayInvoiceResponse.Error.fromObject(object.failure);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PayInvoiceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @static
                 * @param {visor.clientrpc.PayInvoiceResponse} message PayInvoiceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PayInvoiceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.success != null && message.hasOwnProperty("success")) {
                        object.success = $root.visor.clientrpc.PayInvoiceResponse.Data.toObject(message.success, options);
                        if (options.oneofs)
                            object.result = "success";
                    }
                    if (message.failure != null && message.hasOwnProperty("failure")) {
                        object.failure = $root.visor.clientrpc.PayInvoiceResponse.Error.toObject(message.failure, options);
                        if (options.oneofs)
                            object.result = "failure";
                    }
                    return object;
                };

                /**
                 * Converts this PayInvoiceResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.PayInvoiceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PayInvoiceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                PayInvoiceResponse.Data = (function() {

                    /**
                     * Properties of a Data.
                     * @memberof visor.clientrpc.PayInvoiceResponse
                     * @interface IData
                     * @property {string|null} [invoicePaymentToken] Data invoicePaymentToken
                     */

                    /**
                     * Constructs a new Data.
                     * @memberof visor.clientrpc.PayInvoiceResponse
                     * @classdesc Represents a Data.
                     * @implements IData
                     * @constructor
                     * @param {visor.clientrpc.PayInvoiceResponse.IData=} [properties] Properties to set
                     */
                    function Data(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Data invoicePaymentToken.
                     * @member {string} invoicePaymentToken
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @instance
                     */
                    Data.prototype.invoicePaymentToken = "";

                    /**
                     * Creates a new Data instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.IData=} [properties] Properties to set
                     * @returns {visor.clientrpc.PayInvoiceResponse.Data} Data instance
                     */
                    Data.create = function create(properties) {
                        return new Data(properties);
                    };

                    /**
                     * Encodes the specified Data message. Does not implicitly {@link visor.clientrpc.PayInvoiceResponse.Data.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.invoicePaymentToken != null && message.hasOwnProperty("invoicePaymentToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.invoicePaymentToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified Data message, length delimited. Does not implicitly {@link visor.clientrpc.PayInvoiceResponse.Data.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.IData} message Data message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Data.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.PayInvoiceResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayInvoiceResponse.Data();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.invoicePaymentToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Data message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.PayInvoiceResponse.Data} Data
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Data.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Data message.
                     * @function verify
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Data.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.invoicePaymentToken != null && message.hasOwnProperty("invoicePaymentToken"))
                            if (!$util.isString(message.invoicePaymentToken))
                                return "invoicePaymentToken: string expected";
                        return null;
                    };

                    /**
                     * Creates a Data message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.PayInvoiceResponse.Data} Data
                     */
                    Data.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.PayInvoiceResponse.Data)
                            return object;
                        var message = new $root.visor.clientrpc.PayInvoiceResponse.Data();
                        if (object.invoicePaymentToken != null)
                            message.invoicePaymentToken = String(object.invoicePaymentToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Data message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.Data} message Data
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Data.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.invoicePaymentToken = "";
                        if (message.invoicePaymentToken != null && message.hasOwnProperty("invoicePaymentToken"))
                            object.invoicePaymentToken = message.invoicePaymentToken;
                        return object;
                    };

                    /**
                     * Converts this Data to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.PayInvoiceResponse.Data
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Data.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Data;
                })();

                PayInvoiceResponse.Error = (function() {

                    /**
                     * Properties of an Error.
                     * @memberof visor.clientrpc.PayInvoiceResponse
                     * @interface IError
                     * @property {visor.clientrpc.PayInvoiceResponse.Error.Code|null} [code] Error code
                     * @property {string|null} [message] Error message
                     */

                    /**
                     * Constructs a new Error.
                     * @memberof visor.clientrpc.PayInvoiceResponse
                     * @classdesc Represents an Error.
                     * @implements IError
                     * @constructor
                     * @param {visor.clientrpc.PayInvoiceResponse.IError=} [properties] Properties to set
                     */
                    function Error(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Error code.
                     * @member {visor.clientrpc.PayInvoiceResponse.Error.Code} code
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @instance
                     */
                    Error.prototype.code = 0;

                    /**
                     * Error message.
                     * @member {string} message
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @instance
                     */
                    Error.prototype.message = "";

                    /**
                     * Creates a new Error instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.IError=} [properties] Properties to set
                     * @returns {visor.clientrpc.PayInvoiceResponse.Error} Error instance
                     */
                    Error.create = function create(properties) {
                        return new Error(properties);
                    };

                    /**
                     * Encodes the specified Error message. Does not implicitly {@link visor.clientrpc.PayInvoiceResponse.Error.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                        return writer;
                    };

                    /**
                     * Encodes the specified Error message, length delimited. Does not implicitly {@link visor.clientrpc.PayInvoiceResponse.Error.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.IError} message Error message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Error.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.PayInvoiceResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.PayInvoiceResponse.Error();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.int32();
                                break;
                            case 2:
                                message.message = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Error message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.PayInvoiceResponse.Error} Error
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Error.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Error message.
                     * @function verify
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Error.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            switch (message.code) {
                            default:
                                return "code: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        return null;
                    };

                    /**
                     * Creates an Error message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.PayInvoiceResponse.Error} Error
                     */
                    Error.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.PayInvoiceResponse.Error)
                            return object;
                        var message = new $root.visor.clientrpc.PayInvoiceResponse.Error();
                        switch (object.code) {
                        case "PAY_INVOICE_RESPONSE_ERROR_CODE_INVALID":
                        case 0:
                            message.code = 0;
                            break;
                        case "PAY_INVOICE_RESPONSE_ERROR_CODE_PAYMENT_FAILED":
                        case 1:
                            message.code = 1;
                            break;
                        case "PAY_INVOICE_RESPONSE_ERROR_CODE_CONFLICT":
                        case 2:
                            message.code = 2;
                            break;
                        }
                        if (object.message != null)
                            message.message = String(object.message);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Error message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @static
                     * @param {visor.clientrpc.PayInvoiceResponse.Error} message Error
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Error.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.code = options.enums === String ? "PAY_INVOICE_RESPONSE_ERROR_CODE_INVALID" : 0;
                            object.message = "";
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = options.enums === String ? $root.visor.clientrpc.PayInvoiceResponse.Error.Code[message.code] : message.code;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        return object;
                    };

                    /**
                     * Converts this Error to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.PayInvoiceResponse.Error
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Error.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Code enum.
                     * @name visor.clientrpc.PayInvoiceResponse.Error.Code
                     * @enum {string}
                     * @property {number} PAY_INVOICE_RESPONSE_ERROR_CODE_INVALID=0 PAY_INVOICE_RESPONSE_ERROR_CODE_INVALID value
                     * @property {number} PAY_INVOICE_RESPONSE_ERROR_CODE_PAYMENT_FAILED=1 PAY_INVOICE_RESPONSE_ERROR_CODE_PAYMENT_FAILED value
                     * @property {number} PAY_INVOICE_RESPONSE_ERROR_CODE_CONFLICT=2 PAY_INVOICE_RESPONSE_ERROR_CODE_CONFLICT value
                     */
                    Error.Code = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PAY_INVOICE_RESPONSE_ERROR_CODE_INVALID"] = 0;
                        values[valuesById[1] = "PAY_INVOICE_RESPONSE_ERROR_CODE_PAYMENT_FAILED"] = 1;
                        values[valuesById[2] = "PAY_INVOICE_RESPONSE_ERROR_CODE_CONFLICT"] = 2;
                        return values;
                    })();

                    return Error;
                })();

                return PayInvoiceResponse;
            })();

            clientrpc.ReplaceBoostCardRequest = (function() {

                /**
                 * Properties of a ReplaceBoostCardRequest.
                 * @memberof visor.clientrpc
                 * @interface IReplaceBoostCardRequest
                 * @property {visor.clientrpc.ReplaceBoostCardRequest.Reason|null} [replacementReason] ReplaceBoostCardRequest replacementReason
                 * @property {string|null} [subnetToken] ReplaceBoostCardRequest subnetToken
                 */

                /**
                 * Constructs a new ReplaceBoostCardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReplaceBoostCardRequest.
                 * @implements IReplaceBoostCardRequest
                 * @constructor
                 * @param {visor.clientrpc.IReplaceBoostCardRequest=} [properties] Properties to set
                 */
                function ReplaceBoostCardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReplaceBoostCardRequest replacementReason.
                 * @member {visor.clientrpc.ReplaceBoostCardRequest.Reason} replacementReason
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @instance
                 */
                ReplaceBoostCardRequest.prototype.replacementReason = 0;

                /**
                 * ReplaceBoostCardRequest subnetToken.
                 * @member {string} subnetToken
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @instance
                 */
                ReplaceBoostCardRequest.prototype.subnetToken = "";

                /**
                 * Creates a new ReplaceBoostCardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.IReplaceBoostCardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReplaceBoostCardRequest} ReplaceBoostCardRequest instance
                 */
                ReplaceBoostCardRequest.create = function create(properties) {
                    return new ReplaceBoostCardRequest(properties);
                };

                /**
                 * Encodes the specified ReplaceBoostCardRequest message. Does not implicitly {@link visor.clientrpc.ReplaceBoostCardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.IReplaceBoostCardRequest} message ReplaceBoostCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReplaceBoostCardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.replacementReason != null && message.hasOwnProperty("replacementReason"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.replacementReason);
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.subnetToken);
                    return writer;
                };

                /**
                 * Encodes the specified ReplaceBoostCardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ReplaceBoostCardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.IReplaceBoostCardRequest} message ReplaceBoostCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReplaceBoostCardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReplaceBoostCardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReplaceBoostCardRequest} ReplaceBoostCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReplaceBoostCardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReplaceBoostCardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.replacementReason = reader.int32();
                            break;
                        case 2:
                            message.subnetToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReplaceBoostCardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReplaceBoostCardRequest} ReplaceBoostCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReplaceBoostCardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReplaceBoostCardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReplaceBoostCardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.replacementReason != null && message.hasOwnProperty("replacementReason"))
                        switch (message.replacementReason) {
                        default:
                            return "replacementReason: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        if (!$util.isString(message.subnetToken))
                            return "subnetToken: string expected";
                    return null;
                };

                /**
                 * Creates a ReplaceBoostCardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReplaceBoostCardRequest} ReplaceBoostCardRequest
                 */
                ReplaceBoostCardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReplaceBoostCardRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ReplaceBoostCardRequest();
                    switch (object.replacementReason) {
                    case "REPLACE_BOOST_CARD_REQUEST_REASON_INVALID":
                    case 0:
                        message.replacementReason = 0;
                        break;
                    case "REPLACE_BOOST_CARD_REQUEST_REASON_LOST_OR_STOLEN_CARD":
                    case 1:
                        message.replacementReason = 1;
                        break;
                    case "REPLACE_BOOST_CARD_REQUEST_REASON_DAMAGED_CARD":
                    case 2:
                        message.replacementReason = 2;
                        break;
                    case "REPLACE_BOOST_CARD_REQUEST_REASON_NEVER_RECEIVED":
                    case 3:
                        message.replacementReason = 3;
                        break;
                    }
                    if (object.subnetToken != null)
                        message.subnetToken = String(object.subnetToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ReplaceBoostCardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.ReplaceBoostCardRequest} message ReplaceBoostCardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReplaceBoostCardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.replacementReason = options.enums === String ? "REPLACE_BOOST_CARD_REQUEST_REASON_INVALID" : 0;
                        object.subnetToken = "";
                    }
                    if (message.replacementReason != null && message.hasOwnProperty("replacementReason"))
                        object.replacementReason = options.enums === String ? $root.visor.clientrpc.ReplaceBoostCardRequest.Reason[message.replacementReason] : message.replacementReason;
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        object.subnetToken = message.subnetToken;
                    return object;
                };

                /**
                 * Converts this ReplaceBoostCardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReplaceBoostCardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReplaceBoostCardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Reason enum.
                 * @name visor.clientrpc.ReplaceBoostCardRequest.Reason
                 * @enum {string}
                 * @property {number} REPLACE_BOOST_CARD_REQUEST_REASON_INVALID=0 REPLACE_BOOST_CARD_REQUEST_REASON_INVALID value
                 * @property {number} REPLACE_BOOST_CARD_REQUEST_REASON_LOST_OR_STOLEN_CARD=1 REPLACE_BOOST_CARD_REQUEST_REASON_LOST_OR_STOLEN_CARD value
                 * @property {number} REPLACE_BOOST_CARD_REQUEST_REASON_DAMAGED_CARD=2 REPLACE_BOOST_CARD_REQUEST_REASON_DAMAGED_CARD value
                 * @property {number} REPLACE_BOOST_CARD_REQUEST_REASON_NEVER_RECEIVED=3 REPLACE_BOOST_CARD_REQUEST_REASON_NEVER_RECEIVED value
                 */
                ReplaceBoostCardRequest.Reason = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REPLACE_BOOST_CARD_REQUEST_REASON_INVALID"] = 0;
                    values[valuesById[1] = "REPLACE_BOOST_CARD_REQUEST_REASON_LOST_OR_STOLEN_CARD"] = 1;
                    values[valuesById[2] = "REPLACE_BOOST_CARD_REQUEST_REASON_DAMAGED_CARD"] = 2;
                    values[valuesById[3] = "REPLACE_BOOST_CARD_REQUEST_REASON_NEVER_RECEIVED"] = 3;
                    return values;
                })();

                return ReplaceBoostCardRequest;
            })();

            clientrpc.ReplaceBoostCardResponse = (function() {

                /**
                 * Properties of a ReplaceBoostCardResponse.
                 * @memberof visor.clientrpc
                 * @interface IReplaceBoostCardResponse
                 */

                /**
                 * Constructs a new ReplaceBoostCardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ReplaceBoostCardResponse.
                 * @implements IReplaceBoostCardResponse
                 * @constructor
                 * @param {visor.clientrpc.IReplaceBoostCardResponse=} [properties] Properties to set
                 */
                function ReplaceBoostCardResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ReplaceBoostCardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.IReplaceBoostCardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ReplaceBoostCardResponse} ReplaceBoostCardResponse instance
                 */
                ReplaceBoostCardResponse.create = function create(properties) {
                    return new ReplaceBoostCardResponse(properties);
                };

                /**
                 * Encodes the specified ReplaceBoostCardResponse message. Does not implicitly {@link visor.clientrpc.ReplaceBoostCardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.IReplaceBoostCardResponse} message ReplaceBoostCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReplaceBoostCardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ReplaceBoostCardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ReplaceBoostCardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.IReplaceBoostCardResponse} message ReplaceBoostCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReplaceBoostCardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReplaceBoostCardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ReplaceBoostCardResponse} ReplaceBoostCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReplaceBoostCardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ReplaceBoostCardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReplaceBoostCardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ReplaceBoostCardResponse} ReplaceBoostCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReplaceBoostCardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReplaceBoostCardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReplaceBoostCardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ReplaceBoostCardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ReplaceBoostCardResponse} ReplaceBoostCardResponse
                 */
                ReplaceBoostCardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ReplaceBoostCardResponse)
                        return object;
                    return new $root.visor.clientrpc.ReplaceBoostCardResponse();
                };

                /**
                 * Creates a plain object from a ReplaceBoostCardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.ReplaceBoostCardResponse} message ReplaceBoostCardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReplaceBoostCardResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ReplaceBoostCardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ReplaceBoostCardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReplaceBoostCardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReplaceBoostCardResponse;
            })();

            clientrpc.UpdateBoostCardRequest = (function() {

                /**
                 * Properties of an UpdateBoostCardRequest.
                 * @memberof visor.clientrpc
                 * @interface IUpdateBoostCardRequest
                 * @property {visor.clientrpc.UpdateBoostCardRequest.UpdateType|null} [cardUpdateType] UpdateBoostCardRequest cardUpdateType
                 * @property {visor.common.encryption.IEncryptedItem|null} [pin] UpdateBoostCardRequest pin
                 * @property {string|null} [subnetToken] UpdateBoostCardRequest subnetToken
                 * @property {visor.common.encryption.IEncryptedItem|null} [last_4Ssn] UpdateBoostCardRequest last_4Ssn
                 */

                /**
                 * Constructs a new UpdateBoostCardRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateBoostCardRequest.
                 * @implements IUpdateBoostCardRequest
                 * @constructor
                 * @param {visor.clientrpc.IUpdateBoostCardRequest=} [properties] Properties to set
                 */
                function UpdateBoostCardRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBoostCardRequest cardUpdateType.
                 * @member {visor.clientrpc.UpdateBoostCardRequest.UpdateType} cardUpdateType
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @instance
                 */
                UpdateBoostCardRequest.prototype.cardUpdateType = 0;

                /**
                 * UpdateBoostCardRequest pin.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} pin
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @instance
                 */
                UpdateBoostCardRequest.prototype.pin = null;

                /**
                 * UpdateBoostCardRequest subnetToken.
                 * @member {string} subnetToken
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @instance
                 */
                UpdateBoostCardRequest.prototype.subnetToken = "";

                /**
                 * UpdateBoostCardRequest last_4Ssn.
                 * @member {visor.common.encryption.IEncryptedItem|null|undefined} last_4Ssn
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @instance
                 */
                UpdateBoostCardRequest.prototype.last_4Ssn = null;

                /**
                 * Creates a new UpdateBoostCardRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateBoostCardRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateBoostCardRequest} UpdateBoostCardRequest instance
                 */
                UpdateBoostCardRequest.create = function create(properties) {
                    return new UpdateBoostCardRequest(properties);
                };

                /**
                 * Encodes the specified UpdateBoostCardRequest message. Does not implicitly {@link visor.clientrpc.UpdateBoostCardRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateBoostCardRequest} message UpdateBoostCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBoostCardRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cardUpdateType != null && message.hasOwnProperty("cardUpdateType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.cardUpdateType);
                    if (message.pin != null && message.hasOwnProperty("pin"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.pin, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.subnetToken);
                    if (message.last_4Ssn != null && message.hasOwnProperty("last_4Ssn"))
                        $root.visor.common.encryption.EncryptedItem.encode(message.last_4Ssn, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified UpdateBoostCardRequest message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateBoostCardRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.IUpdateBoostCardRequest} message UpdateBoostCardRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBoostCardRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateBoostCardRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateBoostCardRequest} UpdateBoostCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBoostCardRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateBoostCardRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.cardUpdateType = reader.int32();
                            break;
                        case 2:
                            message.pin = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.subnetToken = reader.string();
                            break;
                        case 4:
                            message.last_4Ssn = $root.visor.common.encryption.EncryptedItem.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateBoostCardRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateBoostCardRequest} UpdateBoostCardRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBoostCardRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateBoostCardRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBoostCardRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cardUpdateType != null && message.hasOwnProperty("cardUpdateType"))
                        switch (message.cardUpdateType) {
                        default:
                            return "cardUpdateType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.pin != null && message.hasOwnProperty("pin")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.pin);
                        if (error)
                            return "pin." + error;
                    }
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        if (!$util.isString(message.subnetToken))
                            return "subnetToken: string expected";
                    if (message.last_4Ssn != null && message.hasOwnProperty("last_4Ssn")) {
                        var error = $root.visor.common.encryption.EncryptedItem.verify(message.last_4Ssn);
                        if (error)
                            return "last_4Ssn." + error;
                    }
                    return null;
                };

                /**
                 * Creates an UpdateBoostCardRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateBoostCardRequest} UpdateBoostCardRequest
                 */
                UpdateBoostCardRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateBoostCardRequest)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateBoostCardRequest();
                    switch (object.cardUpdateType) {
                    case "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_INVALID":
                    case 0:
                        message.cardUpdateType = 0;
                        break;
                    case "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UPDATE_PIN":
                    case 1:
                        message.cardUpdateType = 1;
                        break;
                    case "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_FREEZE":
                    case 2:
                        message.cardUpdateType = 2;
                        break;
                    case "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UNFREEZE":
                    case 3:
                        message.cardUpdateType = 3;
                        break;
                    case "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_CHANGE_PIN":
                    case 4:
                        message.cardUpdateType = 4;
                        break;
                    }
                    if (object.pin != null) {
                        if (typeof object.pin !== "object")
                            throw TypeError(".visor.clientrpc.UpdateBoostCardRequest.pin: object expected");
                        message.pin = $root.visor.common.encryption.EncryptedItem.fromObject(object.pin);
                    }
                    if (object.subnetToken != null)
                        message.subnetToken = String(object.subnetToken);
                    if (object.last_4Ssn != null) {
                        if (typeof object.last_4Ssn !== "object")
                            throw TypeError(".visor.clientrpc.UpdateBoostCardRequest.last_4Ssn: object expected");
                        message.last_4Ssn = $root.visor.common.encryption.EncryptedItem.fromObject(object.last_4Ssn);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBoostCardRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @static
                 * @param {visor.clientrpc.UpdateBoostCardRequest} message UpdateBoostCardRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBoostCardRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cardUpdateType = options.enums === String ? "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_INVALID" : 0;
                        object.pin = null;
                        object.subnetToken = "";
                        object.last_4Ssn = null;
                    }
                    if (message.cardUpdateType != null && message.hasOwnProperty("cardUpdateType"))
                        object.cardUpdateType = options.enums === String ? $root.visor.clientrpc.UpdateBoostCardRequest.UpdateType[message.cardUpdateType] : message.cardUpdateType;
                    if (message.pin != null && message.hasOwnProperty("pin"))
                        object.pin = $root.visor.common.encryption.EncryptedItem.toObject(message.pin, options);
                    if (message.subnetToken != null && message.hasOwnProperty("subnetToken"))
                        object.subnetToken = message.subnetToken;
                    if (message.last_4Ssn != null && message.hasOwnProperty("last_4Ssn"))
                        object.last_4Ssn = $root.visor.common.encryption.EncryptedItem.toObject(message.last_4Ssn, options);
                    return object;
                };

                /**
                 * Converts this UpdateBoostCardRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateBoostCardRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBoostCardRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * UpdateType enum.
                 * @name visor.clientrpc.UpdateBoostCardRequest.UpdateType
                 * @enum {string}
                 * @property {number} UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_INVALID=0 UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_INVALID value
                 * @property {number} UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UPDATE_PIN=1 UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UPDATE_PIN value
                 * @property {number} UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_FREEZE=2 UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_FREEZE value
                 * @property {number} UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UNFREEZE=3 UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UNFREEZE value
                 * @property {number} UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_CHANGE_PIN=4 UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_CHANGE_PIN value
                 */
                UpdateBoostCardRequest.UpdateType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UPDATE_PIN"] = 1;
                    values[valuesById[2] = "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_FREEZE"] = 2;
                    values[valuesById[3] = "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_UNFREEZE"] = 3;
                    values[valuesById[4] = "UPDATE_BOOST_CARD_REQUEST_UPDATE_TYPE_CHANGE_PIN"] = 4;
                    return values;
                })();

                return UpdateBoostCardRequest;
            })();

            clientrpc.UpdateBoostCardResponse = (function() {

                /**
                 * Properties of an UpdateBoostCardResponse.
                 * @memberof visor.clientrpc
                 * @interface IUpdateBoostCardResponse
                 * @property {boolean|null} [success] UpdateBoostCardResponse success
                 * @property {visor.clientrpc.UpdateBoostCardResponse.ErrorCode|null} [errCode] UpdateBoostCardResponse errCode
                 */

                /**
                 * Constructs a new UpdateBoostCardResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents an UpdateBoostCardResponse.
                 * @implements IUpdateBoostCardResponse
                 * @constructor
                 * @param {visor.clientrpc.IUpdateBoostCardResponse=} [properties] Properties to set
                 */
                function UpdateBoostCardResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UpdateBoostCardResponse success.
                 * @member {boolean} success
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @instance
                 */
                UpdateBoostCardResponse.prototype.success = false;

                /**
                 * UpdateBoostCardResponse errCode.
                 * @member {visor.clientrpc.UpdateBoostCardResponse.ErrorCode} errCode
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @instance
                 */
                UpdateBoostCardResponse.prototype.errCode = 0;

                /**
                 * Creates a new UpdateBoostCardResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateBoostCardResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.UpdateBoostCardResponse} UpdateBoostCardResponse instance
                 */
                UpdateBoostCardResponse.create = function create(properties) {
                    return new UpdateBoostCardResponse(properties);
                };

                /**
                 * Encodes the specified UpdateBoostCardResponse message. Does not implicitly {@link visor.clientrpc.UpdateBoostCardResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateBoostCardResponse} message UpdateBoostCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBoostCardResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.success != null && message.hasOwnProperty("success"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
                    if (message.errCode != null && message.hasOwnProperty("errCode"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.errCode);
                    return writer;
                };

                /**
                 * Encodes the specified UpdateBoostCardResponse message, length delimited. Does not implicitly {@link visor.clientrpc.UpdateBoostCardResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.IUpdateBoostCardResponse} message UpdateBoostCardResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UpdateBoostCardResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an UpdateBoostCardResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.UpdateBoostCardResponse} UpdateBoostCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBoostCardResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.UpdateBoostCardResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.success = reader.bool();
                            break;
                        case 2:
                            message.errCode = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an UpdateBoostCardResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.UpdateBoostCardResponse} UpdateBoostCardResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UpdateBoostCardResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an UpdateBoostCardResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UpdateBoostCardResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.success != null && message.hasOwnProperty("success"))
                        if (typeof message.success !== "boolean")
                            return "success: boolean expected";
                    if (message.errCode != null && message.hasOwnProperty("errCode"))
                        switch (message.errCode) {
                        default:
                            return "errCode: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates an UpdateBoostCardResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.UpdateBoostCardResponse} UpdateBoostCardResponse
                 */
                UpdateBoostCardResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.UpdateBoostCardResponse)
                        return object;
                    var message = new $root.visor.clientrpc.UpdateBoostCardResponse();
                    if (object.success != null)
                        message.success = Boolean(object.success);
                    switch (object.errCode) {
                    case "UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID":
                    case 0:
                        message.errCode = 0;
                        break;
                    case "UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SSN":
                    case 1:
                        message.errCode = 1;
                        break;
                    case "UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SNN_LIMIT_REACHED":
                    case 2:
                        message.errCode = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an UpdateBoostCardResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @static
                 * @param {visor.clientrpc.UpdateBoostCardResponse} message UpdateBoostCardResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UpdateBoostCardResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.success = false;
                        object.errCode = options.enums === String ? "UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID" : 0;
                    }
                    if (message.success != null && message.hasOwnProperty("success"))
                        object.success = message.success;
                    if (message.errCode != null && message.hasOwnProperty("errCode"))
                        object.errCode = options.enums === String ? $root.visor.clientrpc.UpdateBoostCardResponse.ErrorCode[message.errCode] : message.errCode;
                    return object;
                };

                /**
                 * Converts this UpdateBoostCardResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.UpdateBoostCardResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UpdateBoostCardResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * ErrorCode enum.
                 * @name visor.clientrpc.UpdateBoostCardResponse.ErrorCode
                 * @enum {string}
                 * @property {number} UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID=0 UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID value
                 * @property {number} UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SSN=1 UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SSN value
                 * @property {number} UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SNN_LIMIT_REACHED=2 UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SNN_LIMIT_REACHED value
                 */
                UpdateBoostCardResponse.ErrorCode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID"] = 0;
                    values[valuesById[1] = "UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SSN"] = 1;
                    values[valuesById[2] = "UPDATE_BOOST_CARD_RESPONSE_ERROR_CODE_INVALID_SNN_LIMIT_REACHED"] = 2;
                    return values;
                })();

                return UpdateBoostCardResponse;
            })();

            clientrpc.CompleteEmail2FAStepRequest = (function() {

                /**
                 * Properties of a CompleteEmail2FAStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteEmail2FAStepRequest
                 * @property {string|null} [stepToken] CompleteEmail2FAStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteEmail2FAStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteEmail2FAStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteEmail2FAStepRequest.
                 * @implements ICompleteEmail2FAStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteEmail2FAStepRequest=} [properties] Properties to set
                 */
                function CompleteEmail2FAStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteEmail2FAStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @instance
                 */
                CompleteEmail2FAStepRequest.prototype.stepToken = "";

                /**
                 * CompleteEmail2FAStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @instance
                 */
                CompleteEmail2FAStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteEmail2FAStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteEmail2FAStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteEmail2FAStepRequest} CompleteEmail2FAStepRequest instance
                 */
                CompleteEmail2FAStepRequest.create = function create(properties) {
                    return new CompleteEmail2FAStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteEmail2FAStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteEmail2FAStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteEmail2FAStepRequest} message CompleteEmail2FAStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteEmail2FAStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteEmail2FAStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteEmail2FAStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteEmail2FAStepRequest} message CompleteEmail2FAStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteEmail2FAStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteEmail2FAStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteEmail2FAStepRequest} CompleteEmail2FAStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteEmail2FAStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteEmail2FAStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteEmail2FAStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteEmail2FAStepRequest} CompleteEmail2FAStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteEmail2FAStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteEmail2FAStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEmail2FAStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteEmail2FAStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteEmail2FAStepRequest} CompleteEmail2FAStepRequest
                 */
                CompleteEmail2FAStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteEmail2FAStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteEmail2FAStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteEmail2FAStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteEmail2FAStepRequest} message CompleteEmail2FAStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEmail2FAStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteEmail2FAStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteEmail2FAStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEmail2FAStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEmail2FAStepRequest;
            })();

            clientrpc.CompleteVerifyBankAccountStepRequest = (function() {

                /**
                 * Properties of a CompleteVerifyBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteVerifyBankAccountStepRequest
                 * @property {string|null} [stepToken] CompleteVerifyBankAccountStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteVerifyBankAccountStepRequest workflowToken
                 * @property {string|null} [bankAccountToken] CompleteVerifyBankAccountStepRequest bankAccountToken
                 * @property {Array.<visor.clientrpc.ITransactionVerificationAnswer>|null} [verificationAnswers] CompleteVerifyBankAccountStepRequest verificationAnswers
                 */

                /**
                 * Constructs a new CompleteVerifyBankAccountStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteVerifyBankAccountStepRequest.
                 * @implements ICompleteVerifyBankAccountStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepRequest=} [properties] Properties to set
                 */
                function CompleteVerifyBankAccountStepRequest(properties) {
                    this.verificationAnswers = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteVerifyBankAccountStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @instance
                 */
                CompleteVerifyBankAccountStepRequest.prototype.stepToken = "";

                /**
                 * CompleteVerifyBankAccountStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @instance
                 */
                CompleteVerifyBankAccountStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteVerifyBankAccountStepRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @instance
                 */
                CompleteVerifyBankAccountStepRequest.prototype.bankAccountToken = "";

                /**
                 * CompleteVerifyBankAccountStepRequest verificationAnswers.
                 * @member {Array.<visor.clientrpc.ITransactionVerificationAnswer>} verificationAnswers
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @instance
                 */
                CompleteVerifyBankAccountStepRequest.prototype.verificationAnswers = $util.emptyArray;

                /**
                 * Creates a new CompleteVerifyBankAccountStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepRequest} CompleteVerifyBankAccountStepRequest instance
                 */
                CompleteVerifyBankAccountStepRequest.create = function create(properties) {
                    return new CompleteVerifyBankAccountStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteVerifyBankAccountStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteVerifyBankAccountStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepRequest} message CompleteVerifyBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteVerifyBankAccountStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.bankAccountToken);
                    if (message.verificationAnswers != null && message.verificationAnswers.length)
                        for (var i = 0; i < message.verificationAnswers.length; ++i)
                            $root.visor.clientrpc.TransactionVerificationAnswer.encode(message.verificationAnswers[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteVerifyBankAccountStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteVerifyBankAccountStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepRequest} message CompleteVerifyBankAccountStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteVerifyBankAccountStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteVerifyBankAccountStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepRequest} CompleteVerifyBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteVerifyBankAccountStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteVerifyBankAccountStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.bankAccountToken = reader.string();
                            break;
                        case 4:
                            if (!(message.verificationAnswers && message.verificationAnswers.length))
                                message.verificationAnswers = [];
                            message.verificationAnswers.push($root.visor.clientrpc.TransactionVerificationAnswer.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteVerifyBankAccountStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepRequest} CompleteVerifyBankAccountStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteVerifyBankAccountStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteVerifyBankAccountStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteVerifyBankAccountStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    if (message.verificationAnswers != null && message.hasOwnProperty("verificationAnswers")) {
                        if (!Array.isArray(message.verificationAnswers))
                            return "verificationAnswers: array expected";
                        for (var i = 0; i < message.verificationAnswers.length; ++i) {
                            var error = $root.visor.clientrpc.TransactionVerificationAnswer.verify(message.verificationAnswers[i]);
                            if (error)
                                return "verificationAnswers." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CompleteVerifyBankAccountStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepRequest} CompleteVerifyBankAccountStepRequest
                 */
                CompleteVerifyBankAccountStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteVerifyBankAccountStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteVerifyBankAccountStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    if (object.verificationAnswers) {
                        if (!Array.isArray(object.verificationAnswers))
                            throw TypeError(".visor.clientrpc.CompleteVerifyBankAccountStepRequest.verificationAnswers: array expected");
                        message.verificationAnswers = [];
                        for (var i = 0; i < object.verificationAnswers.length; ++i) {
                            if (typeof object.verificationAnswers[i] !== "object")
                                throw TypeError(".visor.clientrpc.CompleteVerifyBankAccountStepRequest.verificationAnswers: object expected");
                            message.verificationAnswers[i] = $root.visor.clientrpc.TransactionVerificationAnswer.fromObject(object.verificationAnswers[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteVerifyBankAccountStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteVerifyBankAccountStepRequest} message CompleteVerifyBankAccountStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteVerifyBankAccountStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.verificationAnswers = [];
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.bankAccountToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    if (message.verificationAnswers && message.verificationAnswers.length) {
                        object.verificationAnswers = [];
                        for (var j = 0; j < message.verificationAnswers.length; ++j)
                            object.verificationAnswers[j] = $root.visor.clientrpc.TransactionVerificationAnswer.toObject(message.verificationAnswers[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this CompleteVerifyBankAccountStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteVerifyBankAccountStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteVerifyBankAccountStepRequest;
            })();

            clientrpc.CompleteVerifyBankAccountStepResponse = (function() {

                /**
                 * Properties of a CompleteVerifyBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteVerifyBankAccountStepResponse
                 */

                /**
                 * Constructs a new CompleteVerifyBankAccountStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteVerifyBankAccountStepResponse.
                 * @implements ICompleteVerifyBankAccountStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepResponse=} [properties] Properties to set
                 */
                function CompleteVerifyBankAccountStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteVerifyBankAccountStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepResponse} CompleteVerifyBankAccountStepResponse instance
                 */
                CompleteVerifyBankAccountStepResponse.create = function create(properties) {
                    return new CompleteVerifyBankAccountStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteVerifyBankAccountStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteVerifyBankAccountStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepResponse} message CompleteVerifyBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteVerifyBankAccountStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteVerifyBankAccountStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteVerifyBankAccountStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteVerifyBankAccountStepResponse} message CompleteVerifyBankAccountStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteVerifyBankAccountStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteVerifyBankAccountStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepResponse} CompleteVerifyBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteVerifyBankAccountStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteVerifyBankAccountStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteVerifyBankAccountStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepResponse} CompleteVerifyBankAccountStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteVerifyBankAccountStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteVerifyBankAccountStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteVerifyBankAccountStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteVerifyBankAccountStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteVerifyBankAccountStepResponse} CompleteVerifyBankAccountStepResponse
                 */
                CompleteVerifyBankAccountStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteVerifyBankAccountStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteVerifyBankAccountStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteVerifyBankAccountStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteVerifyBankAccountStepResponse} message CompleteVerifyBankAccountStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteVerifyBankAccountStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteVerifyBankAccountStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteVerifyBankAccountStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteVerifyBankAccountStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteVerifyBankAccountStepResponse;
            })();

            clientrpc.CompleteEmail2FAStepResponse = (function() {

                /**
                 * Properties of a CompleteEmail2FAStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteEmail2FAStepResponse
                 */

                /**
                 * Constructs a new CompleteEmail2FAStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteEmail2FAStepResponse.
                 * @implements ICompleteEmail2FAStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteEmail2FAStepResponse=} [properties] Properties to set
                 */
                function CompleteEmail2FAStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteEmail2FAStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteEmail2FAStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteEmail2FAStepResponse} CompleteEmail2FAStepResponse instance
                 */
                CompleteEmail2FAStepResponse.create = function create(properties) {
                    return new CompleteEmail2FAStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteEmail2FAStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteEmail2FAStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteEmail2FAStepResponse} message CompleteEmail2FAStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteEmail2FAStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteEmail2FAStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteEmail2FAStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteEmail2FAStepResponse} message CompleteEmail2FAStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteEmail2FAStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteEmail2FAStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteEmail2FAStepResponse} CompleteEmail2FAStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteEmail2FAStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteEmail2FAStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteEmail2FAStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteEmail2FAStepResponse} CompleteEmail2FAStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteEmail2FAStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteEmail2FAStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteEmail2FAStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteEmail2FAStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteEmail2FAStepResponse} CompleteEmail2FAStepResponse
                 */
                CompleteEmail2FAStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteEmail2FAStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteEmail2FAStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteEmail2FAStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteEmail2FAStepResponse} message CompleteEmail2FAStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteEmail2FAStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteEmail2FAStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteEmail2FAStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteEmail2FAStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteEmail2FAStepResponse;
            })();

            clientrpc.CompletePhone2FAStepRequest = (function() {

                /**
                 * Properties of a CompletePhone2FAStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompletePhone2FAStepRequest
                 * @property {string|null} [stepToken] CompletePhone2FAStepRequest stepToken
                 * @property {string|null} [workflowToken] CompletePhone2FAStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompletePhone2FAStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePhone2FAStepRequest.
                 * @implements ICompletePhone2FAStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompletePhone2FAStepRequest=} [properties] Properties to set
                 */
                function CompletePhone2FAStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompletePhone2FAStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @instance
                 */
                CompletePhone2FAStepRequest.prototype.stepToken = "";

                /**
                 * CompletePhone2FAStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @instance
                 */
                CompletePhone2FAStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompletePhone2FAStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePhone2FAStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePhone2FAStepRequest} CompletePhone2FAStepRequest instance
                 */
                CompletePhone2FAStepRequest.create = function create(properties) {
                    return new CompletePhone2FAStepRequest(properties);
                };

                /**
                 * Encodes the specified CompletePhone2FAStepRequest message. Does not implicitly {@link visor.clientrpc.CompletePhone2FAStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePhone2FAStepRequest} message CompletePhone2FAStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePhone2FAStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompletePhone2FAStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePhone2FAStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompletePhone2FAStepRequest} message CompletePhone2FAStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePhone2FAStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePhone2FAStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePhone2FAStepRequest} CompletePhone2FAStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePhone2FAStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePhone2FAStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePhone2FAStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePhone2FAStepRequest} CompletePhone2FAStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePhone2FAStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePhone2FAStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePhone2FAStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompletePhone2FAStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePhone2FAStepRequest} CompletePhone2FAStepRequest
                 */
                CompletePhone2FAStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePhone2FAStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompletePhone2FAStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompletePhone2FAStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @static
                 * @param {visor.clientrpc.CompletePhone2FAStepRequest} message CompletePhone2FAStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePhone2FAStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompletePhone2FAStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePhone2FAStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePhone2FAStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePhone2FAStepRequest;
            })();

            clientrpc.CompletePhone2FAStepResponse = (function() {

                /**
                 * Properties of a CompletePhone2FAStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompletePhone2FAStepResponse
                 */

                /**
                 * Constructs a new CompletePhone2FAStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompletePhone2FAStepResponse.
                 * @implements ICompletePhone2FAStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompletePhone2FAStepResponse=} [properties] Properties to set
                 */
                function CompletePhone2FAStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompletePhone2FAStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePhone2FAStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompletePhone2FAStepResponse} CompletePhone2FAStepResponse instance
                 */
                CompletePhone2FAStepResponse.create = function create(properties) {
                    return new CompletePhone2FAStepResponse(properties);
                };

                /**
                 * Encodes the specified CompletePhone2FAStepResponse message. Does not implicitly {@link visor.clientrpc.CompletePhone2FAStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePhone2FAStepResponse} message CompletePhone2FAStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePhone2FAStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompletePhone2FAStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompletePhone2FAStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompletePhone2FAStepResponse} message CompletePhone2FAStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompletePhone2FAStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompletePhone2FAStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompletePhone2FAStepResponse} CompletePhone2FAStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePhone2FAStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompletePhone2FAStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompletePhone2FAStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompletePhone2FAStepResponse} CompletePhone2FAStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompletePhone2FAStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompletePhone2FAStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompletePhone2FAStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompletePhone2FAStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompletePhone2FAStepResponse} CompletePhone2FAStepResponse
                 */
                CompletePhone2FAStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompletePhone2FAStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompletePhone2FAStepResponse();
                };

                /**
                 * Creates a plain object from a CompletePhone2FAStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @static
                 * @param {visor.clientrpc.CompletePhone2FAStepResponse} message CompletePhone2FAStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompletePhone2FAStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompletePhone2FAStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompletePhone2FAStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompletePhone2FAStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompletePhone2FAStepResponse;
            })();

            clientrpc.GetExternalBankAccountBalanceRequest = (function() {

                /**
                 * Properties of a GetExternalBankAccountBalanceRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetExternalBankAccountBalanceRequest
                 * @property {string|null} [bankAccountToken] GetExternalBankAccountBalanceRequest bankAccountToken
                 */

                /**
                 * Constructs a new GetExternalBankAccountBalanceRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetExternalBankAccountBalanceRequest.
                 * @implements IGetExternalBankAccountBalanceRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceRequest=} [properties] Properties to set
                 */
                function GetExternalBankAccountBalanceRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetExternalBankAccountBalanceRequest bankAccountToken.
                 * @member {string} bankAccountToken
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @instance
                 */
                GetExternalBankAccountBalanceRequest.prototype.bankAccountToken = "";

                /**
                 * Creates a new GetExternalBankAccountBalanceRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceRequest} GetExternalBankAccountBalanceRequest instance
                 */
                GetExternalBankAccountBalanceRequest.create = function create(properties) {
                    return new GetExternalBankAccountBalanceRequest(properties);
                };

                /**
                 * Encodes the specified GetExternalBankAccountBalanceRequest message. Does not implicitly {@link visor.clientrpc.GetExternalBankAccountBalanceRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceRequest} message GetExternalBankAccountBalanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExternalBankAccountBalanceRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.bankAccountToken);
                    return writer;
                };

                /**
                 * Encodes the specified GetExternalBankAccountBalanceRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetExternalBankAccountBalanceRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceRequest} message GetExternalBankAccountBalanceRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExternalBankAccountBalanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetExternalBankAccountBalanceRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceRequest} GetExternalBankAccountBalanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExternalBankAccountBalanceRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetExternalBankAccountBalanceRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.bankAccountToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetExternalBankAccountBalanceRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceRequest} GetExternalBankAccountBalanceRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExternalBankAccountBalanceRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetExternalBankAccountBalanceRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetExternalBankAccountBalanceRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        if (!$util.isString(message.bankAccountToken))
                            return "bankAccountToken: string expected";
                    return null;
                };

                /**
                 * Creates a GetExternalBankAccountBalanceRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceRequest} GetExternalBankAccountBalanceRequest
                 */
                GetExternalBankAccountBalanceRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetExternalBankAccountBalanceRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetExternalBankAccountBalanceRequest();
                    if (object.bankAccountToken != null)
                        message.bankAccountToken = String(object.bankAccountToken);
                    return message;
                };

                /**
                 * Creates a plain object from a GetExternalBankAccountBalanceRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @static
                 * @param {visor.clientrpc.GetExternalBankAccountBalanceRequest} message GetExternalBankAccountBalanceRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetExternalBankAccountBalanceRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.bankAccountToken = "";
                    if (message.bankAccountToken != null && message.hasOwnProperty("bankAccountToken"))
                        object.bankAccountToken = message.bankAccountToken;
                    return object;
                };

                /**
                 * Converts this GetExternalBankAccountBalanceRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetExternalBankAccountBalanceRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetExternalBankAccountBalanceRequest;
            })();

            clientrpc.GetExternalBankAccountBalanceResponse = (function() {

                /**
                 * Properties of a GetExternalBankAccountBalanceResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetExternalBankAccountBalanceResponse
                 * @property {visor.common.money.IAmount|null} [accountBalance] GetExternalBankAccountBalanceResponse accountBalance
                 * @property {google.protobuf.ITimestamp|null} [lastReadTimestamp] GetExternalBankAccountBalanceResponse lastReadTimestamp
                 */

                /**
                 * Constructs a new GetExternalBankAccountBalanceResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetExternalBankAccountBalanceResponse.
                 * @implements IGetExternalBankAccountBalanceResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceResponse=} [properties] Properties to set
                 */
                function GetExternalBankAccountBalanceResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetExternalBankAccountBalanceResponse accountBalance.
                 * @member {visor.common.money.IAmount|null|undefined} accountBalance
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @instance
                 */
                GetExternalBankAccountBalanceResponse.prototype.accountBalance = null;

                /**
                 * GetExternalBankAccountBalanceResponse lastReadTimestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} lastReadTimestamp
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @instance
                 */
                GetExternalBankAccountBalanceResponse.prototype.lastReadTimestamp = null;

                /**
                 * Creates a new GetExternalBankAccountBalanceResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceResponse} GetExternalBankAccountBalanceResponse instance
                 */
                GetExternalBankAccountBalanceResponse.create = function create(properties) {
                    return new GetExternalBankAccountBalanceResponse(properties);
                };

                /**
                 * Encodes the specified GetExternalBankAccountBalanceResponse message. Does not implicitly {@link visor.clientrpc.GetExternalBankAccountBalanceResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceResponse} message GetExternalBankAccountBalanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExternalBankAccountBalanceResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.accountBalance != null && message.hasOwnProperty("accountBalance"))
                        $root.visor.common.money.Amount.encode(message.accountBalance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.lastReadTimestamp != null && message.hasOwnProperty("lastReadTimestamp"))
                        $root.google.protobuf.Timestamp.encode(message.lastReadTimestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetExternalBankAccountBalanceResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetExternalBankAccountBalanceResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {visor.clientrpc.IGetExternalBankAccountBalanceResponse} message GetExternalBankAccountBalanceResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetExternalBankAccountBalanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetExternalBankAccountBalanceResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceResponse} GetExternalBankAccountBalanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExternalBankAccountBalanceResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetExternalBankAccountBalanceResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.accountBalance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.lastReadTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetExternalBankAccountBalanceResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceResponse} GetExternalBankAccountBalanceResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetExternalBankAccountBalanceResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetExternalBankAccountBalanceResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetExternalBankAccountBalanceResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.accountBalance != null && message.hasOwnProperty("accountBalance")) {
                        var error = $root.visor.common.money.Amount.verify(message.accountBalance);
                        if (error)
                            return "accountBalance." + error;
                    }
                    if (message.lastReadTimestamp != null && message.hasOwnProperty("lastReadTimestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.lastReadTimestamp);
                        if (error)
                            return "lastReadTimestamp." + error;
                    }
                    return null;
                };

                /**
                 * Creates a GetExternalBankAccountBalanceResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetExternalBankAccountBalanceResponse} GetExternalBankAccountBalanceResponse
                 */
                GetExternalBankAccountBalanceResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetExternalBankAccountBalanceResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetExternalBankAccountBalanceResponse();
                    if (object.accountBalance != null) {
                        if (typeof object.accountBalance !== "object")
                            throw TypeError(".visor.clientrpc.GetExternalBankAccountBalanceResponse.accountBalance: object expected");
                        message.accountBalance = $root.visor.common.money.Amount.fromObject(object.accountBalance);
                    }
                    if (object.lastReadTimestamp != null)
                        if (object.lastReadTimestamp === null)
                            message.lastReadTimestamp = {};
                        else
                            message.lastReadTimestamp = {
                                seconds: Math.floor(object.lastReadTimestamp.getTime() / 1000),
                                nanos: object.lastReadTimestamp.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a GetExternalBankAccountBalanceResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @static
                 * @param {visor.clientrpc.GetExternalBankAccountBalanceResponse} message GetExternalBankAccountBalanceResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetExternalBankAccountBalanceResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.accountBalance = null;
                        object.lastReadTimestamp = null;
                    }
                    if (message.accountBalance != null && message.hasOwnProperty("accountBalance"))
                        object.accountBalance = $root.visor.common.money.Amount.toObject(message.accountBalance, options);
                    if (message.lastReadTimestamp != null && message.hasOwnProperty("lastReadTimestamp"))
                        object.lastReadTimestamp = new Date(message.lastReadTimestamp.seconds * 1000 + message.lastReadTimestamp.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this GetExternalBankAccountBalanceResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetExternalBankAccountBalanceResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetExternalBankAccountBalanceResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetExternalBankAccountBalanceResponse;
            })();

            clientrpc.GetDisputeFormsRequest = (function() {

                /**
                 * Properties of a GetDisputeFormsRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetDisputeFormsRequest
                 */

                /**
                 * Constructs a new GetDisputeFormsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetDisputeFormsRequest.
                 * @implements IGetDisputeFormsRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetDisputeFormsRequest=} [properties] Properties to set
                 */
                function GetDisputeFormsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetDisputeFormsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {visor.clientrpc.IGetDisputeFormsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetDisputeFormsRequest} GetDisputeFormsRequest instance
                 */
                GetDisputeFormsRequest.create = function create(properties) {
                    return new GetDisputeFormsRequest(properties);
                };

                /**
                 * Encodes the specified GetDisputeFormsRequest message. Does not implicitly {@link visor.clientrpc.GetDisputeFormsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {visor.clientrpc.IGetDisputeFormsRequest} message GetDisputeFormsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeFormsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetDisputeFormsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetDisputeFormsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {visor.clientrpc.IGetDisputeFormsRequest} message GetDisputeFormsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeFormsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetDisputeFormsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetDisputeFormsRequest} GetDisputeFormsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeFormsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetDisputeFormsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetDisputeFormsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetDisputeFormsRequest} GetDisputeFormsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeFormsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetDisputeFormsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDisputeFormsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetDisputeFormsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetDisputeFormsRequest} GetDisputeFormsRequest
                 */
                GetDisputeFormsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetDisputeFormsRequest)
                        return object;
                    return new $root.visor.clientrpc.GetDisputeFormsRequest();
                };

                /**
                 * Creates a plain object from a GetDisputeFormsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @static
                 * @param {visor.clientrpc.GetDisputeFormsRequest} message GetDisputeFormsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDisputeFormsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetDisputeFormsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetDisputeFormsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDisputeFormsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetDisputeFormsRequest;
            })();

            clientrpc.GetDisputeFormsResponse = (function() {

                /**
                 * Properties of a GetDisputeFormsResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetDisputeFormsResponse
                 * @property {visor.common.strings.ILocalizedString|null} [initialSelectionTitle] GetDisputeFormsResponse initialSelectionTitle
                 * @property {Array.<visor.clientrpc.IGridForm>|null} [forms] GetDisputeFormsResponse forms
                 */

                /**
                 * Constructs a new GetDisputeFormsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetDisputeFormsResponse.
                 * @implements IGetDisputeFormsResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetDisputeFormsResponse=} [properties] Properties to set
                 */
                function GetDisputeFormsResponse(properties) {
                    this.forms = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetDisputeFormsResponse initialSelectionTitle.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} initialSelectionTitle
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @instance
                 */
                GetDisputeFormsResponse.prototype.initialSelectionTitle = null;

                /**
                 * GetDisputeFormsResponse forms.
                 * @member {Array.<visor.clientrpc.IGridForm>} forms
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @instance
                 */
                GetDisputeFormsResponse.prototype.forms = $util.emptyArray;

                /**
                 * Creates a new GetDisputeFormsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {visor.clientrpc.IGetDisputeFormsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetDisputeFormsResponse} GetDisputeFormsResponse instance
                 */
                GetDisputeFormsResponse.create = function create(properties) {
                    return new GetDisputeFormsResponse(properties);
                };

                /**
                 * Encodes the specified GetDisputeFormsResponse message. Does not implicitly {@link visor.clientrpc.GetDisputeFormsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {visor.clientrpc.IGetDisputeFormsResponse} message GetDisputeFormsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeFormsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.initialSelectionTitle != null && message.hasOwnProperty("initialSelectionTitle"))
                        $root.visor.common.strings.LocalizedString.encode(message.initialSelectionTitle, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.forms != null && message.forms.length)
                        for (var i = 0; i < message.forms.length; ++i)
                            $root.visor.clientrpc.GridForm.encode(message.forms[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GetDisputeFormsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetDisputeFormsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {visor.clientrpc.IGetDisputeFormsResponse} message GetDisputeFormsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeFormsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetDisputeFormsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetDisputeFormsResponse} GetDisputeFormsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeFormsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetDisputeFormsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.initialSelectionTitle = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        case 2:
                            if (!(message.forms && message.forms.length))
                                message.forms = [];
                            message.forms.push($root.visor.clientrpc.GridForm.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetDisputeFormsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetDisputeFormsResponse} GetDisputeFormsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeFormsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetDisputeFormsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDisputeFormsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.initialSelectionTitle != null && message.hasOwnProperty("initialSelectionTitle")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.initialSelectionTitle);
                        if (error)
                            return "initialSelectionTitle." + error;
                    }
                    if (message.forms != null && message.hasOwnProperty("forms")) {
                        if (!Array.isArray(message.forms))
                            return "forms: array expected";
                        for (var i = 0; i < message.forms.length; ++i) {
                            var error = $root.visor.clientrpc.GridForm.verify(message.forms[i]);
                            if (error)
                                return "forms." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GetDisputeFormsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetDisputeFormsResponse} GetDisputeFormsResponse
                 */
                GetDisputeFormsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetDisputeFormsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetDisputeFormsResponse();
                    if (object.initialSelectionTitle != null) {
                        if (typeof object.initialSelectionTitle !== "object")
                            throw TypeError(".visor.clientrpc.GetDisputeFormsResponse.initialSelectionTitle: object expected");
                        message.initialSelectionTitle = $root.visor.common.strings.LocalizedString.fromObject(object.initialSelectionTitle);
                    }
                    if (object.forms) {
                        if (!Array.isArray(object.forms))
                            throw TypeError(".visor.clientrpc.GetDisputeFormsResponse.forms: array expected");
                        message.forms = [];
                        for (var i = 0; i < object.forms.length; ++i) {
                            if (typeof object.forms[i] !== "object")
                                throw TypeError(".visor.clientrpc.GetDisputeFormsResponse.forms: object expected");
                            message.forms[i] = $root.visor.clientrpc.GridForm.fromObject(object.forms[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetDisputeFormsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @static
                 * @param {visor.clientrpc.GetDisputeFormsResponse} message GetDisputeFormsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDisputeFormsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.forms = [];
                    if (options.defaults)
                        object.initialSelectionTitle = null;
                    if (message.initialSelectionTitle != null && message.hasOwnProperty("initialSelectionTitle"))
                        object.initialSelectionTitle = $root.visor.common.strings.LocalizedString.toObject(message.initialSelectionTitle, options);
                    if (message.forms && message.forms.length) {
                        object.forms = [];
                        for (var j = 0; j < message.forms.length; ++j)
                            object.forms[j] = $root.visor.clientrpc.GridForm.toObject(message.forms[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GetDisputeFormsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetDisputeFormsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDisputeFormsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetDisputeFormsResponse;
            })();

            clientrpc.SubmitDisputeRequest = (function() {

                /**
                 * Properties of a SubmitDisputeRequest.
                 * @memberof visor.clientrpc
                 * @interface ISubmitDisputeRequest
                 * @property {string|null} [transactionId] SubmitDisputeRequest transactionId
                 * @property {string|null} [formKey] SubmitDisputeRequest formKey
                 * @property {visor.clientrpc.IGridFormSubmission|null} [payload] SubmitDisputeRequest payload
                 */

                /**
                 * Constructs a new SubmitDisputeRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SubmitDisputeRequest.
                 * @implements ISubmitDisputeRequest
                 * @constructor
                 * @param {visor.clientrpc.ISubmitDisputeRequest=} [properties] Properties to set
                 */
                function SubmitDisputeRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SubmitDisputeRequest transactionId.
                 * @member {string} transactionId
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @instance
                 */
                SubmitDisputeRequest.prototype.transactionId = "";

                /**
                 * SubmitDisputeRequest formKey.
                 * @member {string} formKey
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @instance
                 */
                SubmitDisputeRequest.prototype.formKey = "";

                /**
                 * SubmitDisputeRequest payload.
                 * @member {visor.clientrpc.IGridFormSubmission|null|undefined} payload
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @instance
                 */
                SubmitDisputeRequest.prototype.payload = null;

                /**
                 * Creates a new SubmitDisputeRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {visor.clientrpc.ISubmitDisputeRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SubmitDisputeRequest} SubmitDisputeRequest instance
                 */
                SubmitDisputeRequest.create = function create(properties) {
                    return new SubmitDisputeRequest(properties);
                };

                /**
                 * Encodes the specified SubmitDisputeRequest message. Does not implicitly {@link visor.clientrpc.SubmitDisputeRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {visor.clientrpc.ISubmitDisputeRequest} message SubmitDisputeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitDisputeRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.transactionId);
                    if (message.formKey != null && message.hasOwnProperty("formKey"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.formKey);
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        $root.visor.clientrpc.GridFormSubmission.encode(message.payload, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SubmitDisputeRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SubmitDisputeRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {visor.clientrpc.ISubmitDisputeRequest} message SubmitDisputeRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitDisputeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SubmitDisputeRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SubmitDisputeRequest} SubmitDisputeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitDisputeRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SubmitDisputeRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.transactionId = reader.string();
                            break;
                        case 2:
                            message.formKey = reader.string();
                            break;
                        case 3:
                            message.payload = $root.visor.clientrpc.GridFormSubmission.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SubmitDisputeRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SubmitDisputeRequest} SubmitDisputeRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitDisputeRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SubmitDisputeRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SubmitDisputeRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        if (!$util.isString(message.transactionId))
                            return "transactionId: string expected";
                    if (message.formKey != null && message.hasOwnProperty("formKey"))
                        if (!$util.isString(message.formKey))
                            return "formKey: string expected";
                    if (message.payload != null && message.hasOwnProperty("payload")) {
                        var error = $root.visor.clientrpc.GridFormSubmission.verify(message.payload);
                        if (error)
                            return "payload." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SubmitDisputeRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SubmitDisputeRequest} SubmitDisputeRequest
                 */
                SubmitDisputeRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SubmitDisputeRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SubmitDisputeRequest();
                    if (object.transactionId != null)
                        message.transactionId = String(object.transactionId);
                    if (object.formKey != null)
                        message.formKey = String(object.formKey);
                    if (object.payload != null) {
                        if (typeof object.payload !== "object")
                            throw TypeError(".visor.clientrpc.SubmitDisputeRequest.payload: object expected");
                        message.payload = $root.visor.clientrpc.GridFormSubmission.fromObject(object.payload);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SubmitDisputeRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @static
                 * @param {visor.clientrpc.SubmitDisputeRequest} message SubmitDisputeRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SubmitDisputeRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.transactionId = "";
                        object.formKey = "";
                        object.payload = null;
                    }
                    if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                        object.transactionId = message.transactionId;
                    if (message.formKey != null && message.hasOwnProperty("formKey"))
                        object.formKey = message.formKey;
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        object.payload = $root.visor.clientrpc.GridFormSubmission.toObject(message.payload, options);
                    return object;
                };

                /**
                 * Converts this SubmitDisputeRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SubmitDisputeRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SubmitDisputeRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SubmitDisputeRequest;
            })();

            /**
             * DisputeStatus enum.
             * @name visor.clientrpc.DisputeStatus
             * @enum {string}
             * @property {number} DISPUTE_STATUS_INVALID=0 DISPUTE_STATUS_INVALID value
             * @property {number} DISPUTE_STATUS_REQUESTED=1 DISPUTE_STATUS_REQUESTED value
             * @property {number} DISPUTE_STATUS_REJECTED=2 DISPUTE_STATUS_REJECTED value
             */
            clientrpc.DisputeStatus = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DISPUTE_STATUS_INVALID"] = 0;
                values[valuesById[1] = "DISPUTE_STATUS_REQUESTED"] = 1;
                values[valuesById[2] = "DISPUTE_STATUS_REJECTED"] = 2;
                return values;
            })();

            clientrpc.SubmitDisputeResponse = (function() {

                /**
                 * Properties of a SubmitDisputeResponse.
                 * @memberof visor.clientrpc
                 * @interface ISubmitDisputeResponse
                 * @property {visor.clientrpc.DisputeStatus|null} [disputeStatus] SubmitDisputeResponse disputeStatus
                 */

                /**
                 * Constructs a new SubmitDisputeResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SubmitDisputeResponse.
                 * @implements ISubmitDisputeResponse
                 * @constructor
                 * @param {visor.clientrpc.ISubmitDisputeResponse=} [properties] Properties to set
                 */
                function SubmitDisputeResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SubmitDisputeResponse disputeStatus.
                 * @member {visor.clientrpc.DisputeStatus} disputeStatus
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @instance
                 */
                SubmitDisputeResponse.prototype.disputeStatus = 0;

                /**
                 * Creates a new SubmitDisputeResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {visor.clientrpc.ISubmitDisputeResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SubmitDisputeResponse} SubmitDisputeResponse instance
                 */
                SubmitDisputeResponse.create = function create(properties) {
                    return new SubmitDisputeResponse(properties);
                };

                /**
                 * Encodes the specified SubmitDisputeResponse message. Does not implicitly {@link visor.clientrpc.SubmitDisputeResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {visor.clientrpc.ISubmitDisputeResponse} message SubmitDisputeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitDisputeResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.disputeStatus != null && message.hasOwnProperty("disputeStatus"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.disputeStatus);
                    return writer;
                };

                /**
                 * Encodes the specified SubmitDisputeResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SubmitDisputeResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {visor.clientrpc.ISubmitDisputeResponse} message SubmitDisputeResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmitDisputeResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SubmitDisputeResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SubmitDisputeResponse} SubmitDisputeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitDisputeResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SubmitDisputeResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.disputeStatus = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SubmitDisputeResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SubmitDisputeResponse} SubmitDisputeResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmitDisputeResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SubmitDisputeResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SubmitDisputeResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disputeStatus != null && message.hasOwnProperty("disputeStatus"))
                        switch (message.disputeStatus) {
                        default:
                            return "disputeStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a SubmitDisputeResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SubmitDisputeResponse} SubmitDisputeResponse
                 */
                SubmitDisputeResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SubmitDisputeResponse)
                        return object;
                    var message = new $root.visor.clientrpc.SubmitDisputeResponse();
                    switch (object.disputeStatus) {
                    case "DISPUTE_STATUS_INVALID":
                    case 0:
                        message.disputeStatus = 0;
                        break;
                    case "DISPUTE_STATUS_REQUESTED":
                    case 1:
                        message.disputeStatus = 1;
                        break;
                    case "DISPUTE_STATUS_REJECTED":
                    case 2:
                        message.disputeStatus = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SubmitDisputeResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @static
                 * @param {visor.clientrpc.SubmitDisputeResponse} message SubmitDisputeResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SubmitDisputeResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.disputeStatus = options.enums === String ? "DISPUTE_STATUS_INVALID" : 0;
                    if (message.disputeStatus != null && message.hasOwnProperty("disputeStatus"))
                        object.disputeStatus = options.enums === String ? $root.visor.clientrpc.DisputeStatus[message.disputeStatus] : message.disputeStatus;
                    return object;
                };

                /**
                 * Converts this SubmitDisputeResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SubmitDisputeResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SubmitDisputeResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SubmitDisputeResponse;
            })();

            clientrpc.GetDisputeStatisticsRequest = (function() {

                /**
                 * Properties of a GetDisputeStatisticsRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetDisputeStatisticsRequest
                 */

                /**
                 * Constructs a new GetDisputeStatisticsRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetDisputeStatisticsRequest.
                 * @implements IGetDisputeStatisticsRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetDisputeStatisticsRequest=} [properties] Properties to set
                 */
                function GetDisputeStatisticsRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new GetDisputeStatisticsRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {visor.clientrpc.IGetDisputeStatisticsRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetDisputeStatisticsRequest} GetDisputeStatisticsRequest instance
                 */
                GetDisputeStatisticsRequest.create = function create(properties) {
                    return new GetDisputeStatisticsRequest(properties);
                };

                /**
                 * Encodes the specified GetDisputeStatisticsRequest message. Does not implicitly {@link visor.clientrpc.GetDisputeStatisticsRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {visor.clientrpc.IGetDisputeStatisticsRequest} message GetDisputeStatisticsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeStatisticsRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified GetDisputeStatisticsRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetDisputeStatisticsRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {visor.clientrpc.IGetDisputeStatisticsRequest} message GetDisputeStatisticsRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeStatisticsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetDisputeStatisticsRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetDisputeStatisticsRequest} GetDisputeStatisticsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeStatisticsRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetDisputeStatisticsRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetDisputeStatisticsRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetDisputeStatisticsRequest} GetDisputeStatisticsRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeStatisticsRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetDisputeStatisticsRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDisputeStatisticsRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a GetDisputeStatisticsRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetDisputeStatisticsRequest} GetDisputeStatisticsRequest
                 */
                GetDisputeStatisticsRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetDisputeStatisticsRequest)
                        return object;
                    return new $root.visor.clientrpc.GetDisputeStatisticsRequest();
                };

                /**
                 * Creates a plain object from a GetDisputeStatisticsRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @static
                 * @param {visor.clientrpc.GetDisputeStatisticsRequest} message GetDisputeStatisticsRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDisputeStatisticsRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this GetDisputeStatisticsRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetDisputeStatisticsRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDisputeStatisticsRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetDisputeStatisticsRequest;
            })();

            clientrpc.GetDisputeStatisticsResponse = (function() {

                /**
                 * Properties of a GetDisputeStatisticsResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetDisputeStatisticsResponse
                 * @property {boolean|null} [hasElevatedDisputeRate] GetDisputeStatisticsResponse hasElevatedDisputeRate
                 */

                /**
                 * Constructs a new GetDisputeStatisticsResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetDisputeStatisticsResponse.
                 * @implements IGetDisputeStatisticsResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetDisputeStatisticsResponse=} [properties] Properties to set
                 */
                function GetDisputeStatisticsResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetDisputeStatisticsResponse hasElevatedDisputeRate.
                 * @member {boolean} hasElevatedDisputeRate
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @instance
                 */
                GetDisputeStatisticsResponse.prototype.hasElevatedDisputeRate = false;

                /**
                 * Creates a new GetDisputeStatisticsResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {visor.clientrpc.IGetDisputeStatisticsResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetDisputeStatisticsResponse} GetDisputeStatisticsResponse instance
                 */
                GetDisputeStatisticsResponse.create = function create(properties) {
                    return new GetDisputeStatisticsResponse(properties);
                };

                /**
                 * Encodes the specified GetDisputeStatisticsResponse message. Does not implicitly {@link visor.clientrpc.GetDisputeStatisticsResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {visor.clientrpc.IGetDisputeStatisticsResponse} message GetDisputeStatisticsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeStatisticsResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.hasElevatedDisputeRate != null && message.hasOwnProperty("hasElevatedDisputeRate"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasElevatedDisputeRate);
                    return writer;
                };

                /**
                 * Encodes the specified GetDisputeStatisticsResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetDisputeStatisticsResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {visor.clientrpc.IGetDisputeStatisticsResponse} message GetDisputeStatisticsResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetDisputeStatisticsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetDisputeStatisticsResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetDisputeStatisticsResponse} GetDisputeStatisticsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeStatisticsResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetDisputeStatisticsResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.hasElevatedDisputeRate = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetDisputeStatisticsResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetDisputeStatisticsResponse} GetDisputeStatisticsResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetDisputeStatisticsResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetDisputeStatisticsResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetDisputeStatisticsResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.hasElevatedDisputeRate != null && message.hasOwnProperty("hasElevatedDisputeRate"))
                        if (typeof message.hasElevatedDisputeRate !== "boolean")
                            return "hasElevatedDisputeRate: boolean expected";
                    return null;
                };

                /**
                 * Creates a GetDisputeStatisticsResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetDisputeStatisticsResponse} GetDisputeStatisticsResponse
                 */
                GetDisputeStatisticsResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetDisputeStatisticsResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetDisputeStatisticsResponse();
                    if (object.hasElevatedDisputeRate != null)
                        message.hasElevatedDisputeRate = Boolean(object.hasElevatedDisputeRate);
                    return message;
                };

                /**
                 * Creates a plain object from a GetDisputeStatisticsResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @static
                 * @param {visor.clientrpc.GetDisputeStatisticsResponse} message GetDisputeStatisticsResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetDisputeStatisticsResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.hasElevatedDisputeRate = false;
                    if (message.hasElevatedDisputeRate != null && message.hasOwnProperty("hasElevatedDisputeRate"))
                        object.hasElevatedDisputeRate = message.hasElevatedDisputeRate;
                    return object;
                };

                /**
                 * Converts this GetDisputeStatisticsResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetDisputeStatisticsResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetDisputeStatisticsResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetDisputeStatisticsResponse;
            })();

            clientrpc.GridFormField = (function() {

                /**
                 * Properties of a GridFormField.
                 * @memberof visor.clientrpc
                 * @interface IGridFormField
                 * @property {string|null} [key] GridFormField key
                 * @property {visor.common.strings.ILocalizedString|null} [label] GridFormField label
                 * @property {visor.clientrpc.GridFormField.IFormFieldText|null} [text] GridFormField text
                 * @property {visor.clientrpc.GridFormField.IFormFieldDate|null} [date] GridFormField date
                 * @property {visor.clientrpc.GridFormField.IFormFieldCurrency|null} [currency] GridFormField currency
                 * @property {visor.clientrpc.GridFormField.IFormFieldUpload|null} [upload] GridFormField upload
                 * @property {visor.clientrpc.GridFormField.IFormFieldYesNo|null} [yesNo] GridFormField yesNo
                 */

                /**
                 * Constructs a new GridFormField.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GridFormField.
                 * @implements IGridFormField
                 * @constructor
                 * @param {visor.clientrpc.IGridFormField=} [properties] Properties to set
                 */
                function GridFormField(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GridFormField key.
                 * @member {string} key
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                GridFormField.prototype.key = "";

                /**
                 * GridFormField label.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} label
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                GridFormField.prototype.label = null;

                /**
                 * GridFormField text.
                 * @member {visor.clientrpc.GridFormField.IFormFieldText|null|undefined} text
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                GridFormField.prototype.text = null;

                /**
                 * GridFormField date.
                 * @member {visor.clientrpc.GridFormField.IFormFieldDate|null|undefined} date
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                GridFormField.prototype.date = null;

                /**
                 * GridFormField currency.
                 * @member {visor.clientrpc.GridFormField.IFormFieldCurrency|null|undefined} currency
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                GridFormField.prototype.currency = null;

                /**
                 * GridFormField upload.
                 * @member {visor.clientrpc.GridFormField.IFormFieldUpload|null|undefined} upload
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                GridFormField.prototype.upload = null;

                /**
                 * GridFormField yesNo.
                 * @member {visor.clientrpc.GridFormField.IFormFieldYesNo|null|undefined} yesNo
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                GridFormField.prototype.yesNo = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * GridFormField field.
                 * @member {"text"|"date"|"currency"|"upload"|"yesNo"|undefined} field
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 */
                Object.defineProperty(GridFormField.prototype, "field", {
                    get: $util.oneOfGetter($oneOfFields = ["text", "date", "currency", "upload", "yesNo"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new GridFormField instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {visor.clientrpc.IGridFormField=} [properties] Properties to set
                 * @returns {visor.clientrpc.GridFormField} GridFormField instance
                 */
                GridFormField.create = function create(properties) {
                    return new GridFormField(properties);
                };

                /**
                 * Encodes the specified GridFormField message. Does not implicitly {@link visor.clientrpc.GridFormField.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {visor.clientrpc.IGridFormField} message GridFormField message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GridFormField.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.key != null && message.hasOwnProperty("key"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                    if (message.label != null && message.hasOwnProperty("label"))
                        $root.visor.common.strings.LocalizedString.encode(message.label, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.text != null && message.hasOwnProperty("text"))
                        $root.visor.clientrpc.GridFormField.FormFieldText.encode(message.text, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.date != null && message.hasOwnProperty("date"))
                        $root.visor.clientrpc.GridFormField.FormFieldDate.encode(message.date, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.currency != null && message.hasOwnProperty("currency"))
                        $root.visor.clientrpc.GridFormField.FormFieldCurrency.encode(message.currency, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.upload != null && message.hasOwnProperty("upload"))
                        $root.visor.clientrpc.GridFormField.FormFieldUpload.encode(message.upload, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.yesNo != null && message.hasOwnProperty("yesNo"))
                        $root.visor.clientrpc.GridFormField.FormFieldYesNo.encode(message.yesNo, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GridFormField message, length delimited. Does not implicitly {@link visor.clientrpc.GridFormField.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {visor.clientrpc.IGridFormField} message GridFormField message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GridFormField.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GridFormField message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GridFormField} GridFormField
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GridFormField.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridFormField();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.key = reader.string();
                            break;
                        case 2:
                            message.label = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.text = $root.visor.clientrpc.GridFormField.FormFieldText.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.date = $root.visor.clientrpc.GridFormField.FormFieldDate.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.currency = $root.visor.clientrpc.GridFormField.FormFieldCurrency.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.upload = $root.visor.clientrpc.GridFormField.FormFieldUpload.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.yesNo = $root.visor.clientrpc.GridFormField.FormFieldYesNo.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GridFormField message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GridFormField} GridFormField
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GridFormField.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GridFormField message.
                 * @function verify
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GridFormField.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.label != null && message.hasOwnProperty("label")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.label);
                        if (error)
                            return "label." + error;
                    }
                    if (message.text != null && message.hasOwnProperty("text")) {
                        properties.field = 1;
                        {
                            var error = $root.visor.clientrpc.GridFormField.FormFieldText.verify(message.text);
                            if (error)
                                return "text." + error;
                        }
                    }
                    if (message.date != null && message.hasOwnProperty("date")) {
                        if (properties.field === 1)
                            return "field: multiple values";
                        properties.field = 1;
                        {
                            var error = $root.visor.clientrpc.GridFormField.FormFieldDate.verify(message.date);
                            if (error)
                                return "date." + error;
                        }
                    }
                    if (message.currency != null && message.hasOwnProperty("currency")) {
                        if (properties.field === 1)
                            return "field: multiple values";
                        properties.field = 1;
                        {
                            var error = $root.visor.clientrpc.GridFormField.FormFieldCurrency.verify(message.currency);
                            if (error)
                                return "currency." + error;
                        }
                    }
                    if (message.upload != null && message.hasOwnProperty("upload")) {
                        if (properties.field === 1)
                            return "field: multiple values";
                        properties.field = 1;
                        {
                            var error = $root.visor.clientrpc.GridFormField.FormFieldUpload.verify(message.upload);
                            if (error)
                                return "upload." + error;
                        }
                    }
                    if (message.yesNo != null && message.hasOwnProperty("yesNo")) {
                        if (properties.field === 1)
                            return "field: multiple values";
                        properties.field = 1;
                        {
                            var error = $root.visor.clientrpc.GridFormField.FormFieldYesNo.verify(message.yesNo);
                            if (error)
                                return "yesNo." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GridFormField message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GridFormField} GridFormField
                 */
                GridFormField.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GridFormField)
                        return object;
                    var message = new $root.visor.clientrpc.GridFormField();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.label != null) {
                        if (typeof object.label !== "object")
                            throw TypeError(".visor.clientrpc.GridFormField.label: object expected");
                        message.label = $root.visor.common.strings.LocalizedString.fromObject(object.label);
                    }
                    if (object.text != null) {
                        if (typeof object.text !== "object")
                            throw TypeError(".visor.clientrpc.GridFormField.text: object expected");
                        message.text = $root.visor.clientrpc.GridFormField.FormFieldText.fromObject(object.text);
                    }
                    if (object.date != null) {
                        if (typeof object.date !== "object")
                            throw TypeError(".visor.clientrpc.GridFormField.date: object expected");
                        message.date = $root.visor.clientrpc.GridFormField.FormFieldDate.fromObject(object.date);
                    }
                    if (object.currency != null) {
                        if (typeof object.currency !== "object")
                            throw TypeError(".visor.clientrpc.GridFormField.currency: object expected");
                        message.currency = $root.visor.clientrpc.GridFormField.FormFieldCurrency.fromObject(object.currency);
                    }
                    if (object.upload != null) {
                        if (typeof object.upload !== "object")
                            throw TypeError(".visor.clientrpc.GridFormField.upload: object expected");
                        message.upload = $root.visor.clientrpc.GridFormField.FormFieldUpload.fromObject(object.upload);
                    }
                    if (object.yesNo != null) {
                        if (typeof object.yesNo !== "object")
                            throw TypeError(".visor.clientrpc.GridFormField.yesNo: object expected");
                        message.yesNo = $root.visor.clientrpc.GridFormField.FormFieldYesNo.fromObject(object.yesNo);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GridFormField message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GridFormField
                 * @static
                 * @param {visor.clientrpc.GridFormField} message GridFormField
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GridFormField.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.key = "";
                        object.label = null;
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = $root.visor.common.strings.LocalizedString.toObject(message.label, options);
                    if (message.text != null && message.hasOwnProperty("text")) {
                        object.text = $root.visor.clientrpc.GridFormField.FormFieldText.toObject(message.text, options);
                        if (options.oneofs)
                            object.field = "text";
                    }
                    if (message.date != null && message.hasOwnProperty("date")) {
                        object.date = $root.visor.clientrpc.GridFormField.FormFieldDate.toObject(message.date, options);
                        if (options.oneofs)
                            object.field = "date";
                    }
                    if (message.currency != null && message.hasOwnProperty("currency")) {
                        object.currency = $root.visor.clientrpc.GridFormField.FormFieldCurrency.toObject(message.currency, options);
                        if (options.oneofs)
                            object.field = "currency";
                    }
                    if (message.upload != null && message.hasOwnProperty("upload")) {
                        object.upload = $root.visor.clientrpc.GridFormField.FormFieldUpload.toObject(message.upload, options);
                        if (options.oneofs)
                            object.field = "upload";
                    }
                    if (message.yesNo != null && message.hasOwnProperty("yesNo")) {
                        object.yesNo = $root.visor.clientrpc.GridFormField.FormFieldYesNo.toObject(message.yesNo, options);
                        if (options.oneofs)
                            object.field = "yesNo";
                    }
                    return object;
                };

                /**
                 * Converts this GridFormField to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GridFormField
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GridFormField.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                GridFormField.FormFieldYesNo = (function() {

                    /**
                     * Properties of a FormFieldYesNo.
                     * @memberof visor.clientrpc.GridFormField
                     * @interface IFormFieldYesNo
                     * @property {visor.clientrpc.GridFormFieldYesNoValue|null} [value] FormFieldYesNo value
                     * @property {Array.<visor.clientrpc.IGridFormField>|null} [yes] FormFieldYesNo yes
                     * @property {Array.<visor.clientrpc.IGridFormField>|null} [no] FormFieldYesNo no
                     */

                    /**
                     * Constructs a new FormFieldYesNo.
                     * @memberof visor.clientrpc.GridFormField
                     * @classdesc Represents a FormFieldYesNo.
                     * @implements IFormFieldYesNo
                     * @constructor
                     * @param {visor.clientrpc.GridFormField.IFormFieldYesNo=} [properties] Properties to set
                     */
                    function FormFieldYesNo(properties) {
                        this.yes = [];
                        this.no = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FormFieldYesNo value.
                     * @member {visor.clientrpc.GridFormFieldYesNoValue} value
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @instance
                     */
                    FormFieldYesNo.prototype.value = 0;

                    /**
                     * FormFieldYesNo yes.
                     * @member {Array.<visor.clientrpc.IGridFormField>} yes
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @instance
                     */
                    FormFieldYesNo.prototype.yes = $util.emptyArray;

                    /**
                     * FormFieldYesNo no.
                     * @member {Array.<visor.clientrpc.IGridFormField>} no
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @instance
                     */
                    FormFieldYesNo.prototype.no = $util.emptyArray;

                    /**
                     * Creates a new FormFieldYesNo instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldYesNo=} [properties] Properties to set
                     * @returns {visor.clientrpc.GridFormField.FormFieldYesNo} FormFieldYesNo instance
                     */
                    FormFieldYesNo.create = function create(properties) {
                        return new FormFieldYesNo(properties);
                    };

                    /**
                     * Encodes the specified FormFieldYesNo message. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldYesNo.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldYesNo} message FormFieldYesNo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldYesNo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.value != null && message.hasOwnProperty("value"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                        if (message.yes != null && message.yes.length)
                            for (var i = 0; i < message.yes.length; ++i)
                                $root.visor.clientrpc.GridFormField.encode(message.yes[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.no != null && message.no.length)
                            for (var i = 0; i < message.no.length; ++i)
                                $root.visor.clientrpc.GridFormField.encode(message.no[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified FormFieldYesNo message, length delimited. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldYesNo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldYesNo} message FormFieldYesNo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldYesNo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FormFieldYesNo message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GridFormField.FormFieldYesNo} FormFieldYesNo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldYesNo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridFormField.FormFieldYesNo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.value = reader.int32();
                                break;
                            case 2:
                                if (!(message.yes && message.yes.length))
                                    message.yes = [];
                                message.yes.push($root.visor.clientrpc.GridFormField.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.no && message.no.length))
                                    message.no = [];
                                message.no.push($root.visor.clientrpc.GridFormField.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FormFieldYesNo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GridFormField.FormFieldYesNo} FormFieldYesNo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldYesNo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FormFieldYesNo message.
                     * @function verify
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FormFieldYesNo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            switch (message.value) {
                            default:
                                return "value: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.yes != null && message.hasOwnProperty("yes")) {
                            if (!Array.isArray(message.yes))
                                return "yes: array expected";
                            for (var i = 0; i < message.yes.length; ++i) {
                                var error = $root.visor.clientrpc.GridFormField.verify(message.yes[i]);
                                if (error)
                                    return "yes." + error;
                            }
                        }
                        if (message.no != null && message.hasOwnProperty("no")) {
                            if (!Array.isArray(message.no))
                                return "no: array expected";
                            for (var i = 0; i < message.no.length; ++i) {
                                var error = $root.visor.clientrpc.GridFormField.verify(message.no[i]);
                                if (error)
                                    return "no." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a FormFieldYesNo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GridFormField.FormFieldYesNo} FormFieldYesNo
                     */
                    FormFieldYesNo.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GridFormField.FormFieldYesNo)
                            return object;
                        var message = new $root.visor.clientrpc.GridFormField.FormFieldYesNo();
                        switch (object.value) {
                        case "GRID_FORM_FIELD_YES_NO_VALUE_INVALID":
                        case 0:
                            message.value = 0;
                            break;
                        case "GRID_FORM_FIELD_YES_NO_VALUE_YES":
                        case 1:
                            message.value = 1;
                            break;
                        case "GRID_FORM_FIELD_YES_NO_VALUE_NO":
                        case 2:
                            message.value = 2;
                            break;
                        }
                        if (object.yes) {
                            if (!Array.isArray(object.yes))
                                throw TypeError(".visor.clientrpc.GridFormField.FormFieldYesNo.yes: array expected");
                            message.yes = [];
                            for (var i = 0; i < object.yes.length; ++i) {
                                if (typeof object.yes[i] !== "object")
                                    throw TypeError(".visor.clientrpc.GridFormField.FormFieldYesNo.yes: object expected");
                                message.yes[i] = $root.visor.clientrpc.GridFormField.fromObject(object.yes[i]);
                            }
                        }
                        if (object.no) {
                            if (!Array.isArray(object.no))
                                throw TypeError(".visor.clientrpc.GridFormField.FormFieldYesNo.no: array expected");
                            message.no = [];
                            for (var i = 0; i < object.no.length; ++i) {
                                if (typeof object.no[i] !== "object")
                                    throw TypeError(".visor.clientrpc.GridFormField.FormFieldYesNo.no: object expected");
                                message.no[i] = $root.visor.clientrpc.GridFormField.fromObject(object.no[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FormFieldYesNo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @static
                     * @param {visor.clientrpc.GridFormField.FormFieldYesNo} message FormFieldYesNo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FormFieldYesNo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.yes = [];
                            object.no = [];
                        }
                        if (options.defaults)
                            object.value = options.enums === String ? "GRID_FORM_FIELD_YES_NO_VALUE_INVALID" : 0;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = options.enums === String ? $root.visor.clientrpc.GridFormFieldYesNoValue[message.value] : message.value;
                        if (message.yes && message.yes.length) {
                            object.yes = [];
                            for (var j = 0; j < message.yes.length; ++j)
                                object.yes[j] = $root.visor.clientrpc.GridFormField.toObject(message.yes[j], options);
                        }
                        if (message.no && message.no.length) {
                            object.no = [];
                            for (var j = 0; j < message.no.length; ++j)
                                object.no[j] = $root.visor.clientrpc.GridFormField.toObject(message.no[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this FormFieldYesNo to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GridFormField.FormFieldYesNo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FormFieldYesNo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FormFieldYesNo;
                })();

                GridFormField.FormFieldText = (function() {

                    /**
                     * Properties of a FormFieldText.
                     * @memberof visor.clientrpc.GridFormField
                     * @interface IFormFieldText
                     * @property {string|null} [value] FormFieldText value
                     */

                    /**
                     * Constructs a new FormFieldText.
                     * @memberof visor.clientrpc.GridFormField
                     * @classdesc Represents a FormFieldText.
                     * @implements IFormFieldText
                     * @constructor
                     * @param {visor.clientrpc.GridFormField.IFormFieldText=} [properties] Properties to set
                     */
                    function FormFieldText(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FormFieldText value.
                     * @member {string} value
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @instance
                     */
                    FormFieldText.prototype.value = "";

                    /**
                     * Creates a new FormFieldText instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldText=} [properties] Properties to set
                     * @returns {visor.clientrpc.GridFormField.FormFieldText} FormFieldText instance
                     */
                    FormFieldText.create = function create(properties) {
                        return new FormFieldText(properties);
                    };

                    /**
                     * Encodes the specified FormFieldText message. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldText.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldText} message FormFieldText message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldText.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.value != null && message.hasOwnProperty("value"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                        return writer;
                    };

                    /**
                     * Encodes the specified FormFieldText message, length delimited. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldText.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldText} message FormFieldText message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldText.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FormFieldText message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GridFormField.FormFieldText} FormFieldText
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldText.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridFormField.FormFieldText();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.value = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FormFieldText message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GridFormField.FormFieldText} FormFieldText
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldText.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FormFieldText message.
                     * @function verify
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FormFieldText.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (!$util.isString(message.value))
                                return "value: string expected";
                        return null;
                    };

                    /**
                     * Creates a FormFieldText message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GridFormField.FormFieldText} FormFieldText
                     */
                    FormFieldText.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GridFormField.FormFieldText)
                            return object;
                        var message = new $root.visor.clientrpc.GridFormField.FormFieldText();
                        if (object.value != null)
                            message.value = String(object.value);
                        return message;
                    };

                    /**
                     * Creates a plain object from a FormFieldText message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @static
                     * @param {visor.clientrpc.GridFormField.FormFieldText} message FormFieldText
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FormFieldText.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.value = "";
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        return object;
                    };

                    /**
                     * Converts this FormFieldText to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GridFormField.FormFieldText
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FormFieldText.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FormFieldText;
                })();

                GridFormField.FormFieldDate = (function() {

                    /**
                     * Properties of a FormFieldDate.
                     * @memberof visor.clientrpc.GridFormField
                     * @interface IFormFieldDate
                     * @property {visor.common.time.ILocalDate|null} [date] FormFieldDate date
                     */

                    /**
                     * Constructs a new FormFieldDate.
                     * @memberof visor.clientrpc.GridFormField
                     * @classdesc Represents a FormFieldDate.
                     * @implements IFormFieldDate
                     * @constructor
                     * @param {visor.clientrpc.GridFormField.IFormFieldDate=} [properties] Properties to set
                     */
                    function FormFieldDate(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FormFieldDate date.
                     * @member {visor.common.time.ILocalDate|null|undefined} date
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @instance
                     */
                    FormFieldDate.prototype.date = null;

                    /**
                     * Creates a new FormFieldDate instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldDate=} [properties] Properties to set
                     * @returns {visor.clientrpc.GridFormField.FormFieldDate} FormFieldDate instance
                     */
                    FormFieldDate.create = function create(properties) {
                        return new FormFieldDate(properties);
                    };

                    /**
                     * Encodes the specified FormFieldDate message. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldDate.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldDate} message FormFieldDate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldDate.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.date != null && message.hasOwnProperty("date"))
                            $root.visor.common.time.LocalDate.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified FormFieldDate message, length delimited. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldDate.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldDate} message FormFieldDate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldDate.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FormFieldDate message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GridFormField.FormFieldDate} FormFieldDate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldDate.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridFormField.FormFieldDate();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.date = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FormFieldDate message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GridFormField.FormFieldDate} FormFieldDate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldDate.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FormFieldDate message.
                     * @function verify
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FormFieldDate.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.date != null && message.hasOwnProperty("date")) {
                            var error = $root.visor.common.time.LocalDate.verify(message.date);
                            if (error)
                                return "date." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a FormFieldDate message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GridFormField.FormFieldDate} FormFieldDate
                     */
                    FormFieldDate.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GridFormField.FormFieldDate)
                            return object;
                        var message = new $root.visor.clientrpc.GridFormField.FormFieldDate();
                        if (object.date != null) {
                            if (typeof object.date !== "object")
                                throw TypeError(".visor.clientrpc.GridFormField.FormFieldDate.date: object expected");
                            message.date = $root.visor.common.time.LocalDate.fromObject(object.date);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FormFieldDate message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @static
                     * @param {visor.clientrpc.GridFormField.FormFieldDate} message FormFieldDate
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FormFieldDate.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.date = null;
                        if (message.date != null && message.hasOwnProperty("date"))
                            object.date = $root.visor.common.time.LocalDate.toObject(message.date, options);
                        return object;
                    };

                    /**
                     * Converts this FormFieldDate to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GridFormField.FormFieldDate
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FormFieldDate.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FormFieldDate;
                })();

                GridFormField.FormFieldCurrency = (function() {

                    /**
                     * Properties of a FormFieldCurrency.
                     * @memberof visor.clientrpc.GridFormField
                     * @interface IFormFieldCurrency
                     * @property {visor.common.money.IAmount|null} [amount] FormFieldCurrency amount
                     */

                    /**
                     * Constructs a new FormFieldCurrency.
                     * @memberof visor.clientrpc.GridFormField
                     * @classdesc Represents a FormFieldCurrency.
                     * @implements IFormFieldCurrency
                     * @constructor
                     * @param {visor.clientrpc.GridFormField.IFormFieldCurrency=} [properties] Properties to set
                     */
                    function FormFieldCurrency(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FormFieldCurrency amount.
                     * @member {visor.common.money.IAmount|null|undefined} amount
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @instance
                     */
                    FormFieldCurrency.prototype.amount = null;

                    /**
                     * Creates a new FormFieldCurrency instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldCurrency=} [properties] Properties to set
                     * @returns {visor.clientrpc.GridFormField.FormFieldCurrency} FormFieldCurrency instance
                     */
                    FormFieldCurrency.create = function create(properties) {
                        return new FormFieldCurrency(properties);
                    };

                    /**
                     * Encodes the specified FormFieldCurrency message. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldCurrency.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldCurrency} message FormFieldCurrency message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldCurrency.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            $root.visor.common.money.Amount.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified FormFieldCurrency message, length delimited. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldCurrency.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldCurrency} message FormFieldCurrency message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldCurrency.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FormFieldCurrency message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GridFormField.FormFieldCurrency} FormFieldCurrency
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldCurrency.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridFormField.FormFieldCurrency();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.amount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FormFieldCurrency message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GridFormField.FormFieldCurrency} FormFieldCurrency
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldCurrency.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FormFieldCurrency message.
                     * @function verify
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FormFieldCurrency.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.amount != null && message.hasOwnProperty("amount")) {
                            var error = $root.visor.common.money.Amount.verify(message.amount);
                            if (error)
                                return "amount." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a FormFieldCurrency message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GridFormField.FormFieldCurrency} FormFieldCurrency
                     */
                    FormFieldCurrency.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GridFormField.FormFieldCurrency)
                            return object;
                        var message = new $root.visor.clientrpc.GridFormField.FormFieldCurrency();
                        if (object.amount != null) {
                            if (typeof object.amount !== "object")
                                throw TypeError(".visor.clientrpc.GridFormField.FormFieldCurrency.amount: object expected");
                            message.amount = $root.visor.common.money.Amount.fromObject(object.amount);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FormFieldCurrency message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @static
                     * @param {visor.clientrpc.GridFormField.FormFieldCurrency} message FormFieldCurrency
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FormFieldCurrency.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.amount = null;
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = $root.visor.common.money.Amount.toObject(message.amount, options);
                        return object;
                    };

                    /**
                     * Converts this FormFieldCurrency to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GridFormField.FormFieldCurrency
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FormFieldCurrency.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FormFieldCurrency;
                })();

                GridFormField.FormFieldUpload = (function() {

                    /**
                     * Properties of a FormFieldUpload.
                     * @memberof visor.clientrpc.GridFormField
                     * @interface IFormFieldUpload
                     * @property {string|null} [uploadToken] FormFieldUpload uploadToken
                     * @property {Array.<string>|null} [uploadTokens] FormFieldUpload uploadTokens
                     */

                    /**
                     * Constructs a new FormFieldUpload.
                     * @memberof visor.clientrpc.GridFormField
                     * @classdesc Represents a FormFieldUpload.
                     * @implements IFormFieldUpload
                     * @constructor
                     * @param {visor.clientrpc.GridFormField.IFormFieldUpload=} [properties] Properties to set
                     */
                    function FormFieldUpload(properties) {
                        this.uploadTokens = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FormFieldUpload uploadToken.
                     * @member {string} uploadToken
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @instance
                     */
                    FormFieldUpload.prototype.uploadToken = "";

                    /**
                     * FormFieldUpload uploadTokens.
                     * @member {Array.<string>} uploadTokens
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @instance
                     */
                    FormFieldUpload.prototype.uploadTokens = $util.emptyArray;

                    /**
                     * Creates a new FormFieldUpload instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldUpload=} [properties] Properties to set
                     * @returns {visor.clientrpc.GridFormField.FormFieldUpload} FormFieldUpload instance
                     */
                    FormFieldUpload.create = function create(properties) {
                        return new FormFieldUpload(properties);
                    };

                    /**
                     * Encodes the specified FormFieldUpload message. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldUpload.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldUpload} message FormFieldUpload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldUpload.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.uploadToken != null && message.hasOwnProperty("uploadToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.uploadToken);
                        if (message.uploadTokens != null && message.uploadTokens.length)
                            for (var i = 0; i < message.uploadTokens.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.uploadTokens[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified FormFieldUpload message, length delimited. Does not implicitly {@link visor.clientrpc.GridFormField.FormFieldUpload.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {visor.clientrpc.GridFormField.IFormFieldUpload} message FormFieldUpload message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormFieldUpload.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FormFieldUpload message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.GridFormField.FormFieldUpload} FormFieldUpload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldUpload.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridFormField.FormFieldUpload();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.uploadToken = reader.string();
                                break;
                            case 2:
                                if (!(message.uploadTokens && message.uploadTokens.length))
                                    message.uploadTokens = [];
                                message.uploadTokens.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FormFieldUpload message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.GridFormField.FormFieldUpload} FormFieldUpload
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormFieldUpload.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FormFieldUpload message.
                     * @function verify
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FormFieldUpload.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.uploadToken != null && message.hasOwnProperty("uploadToken"))
                            if (!$util.isString(message.uploadToken))
                                return "uploadToken: string expected";
                        if (message.uploadTokens != null && message.hasOwnProperty("uploadTokens")) {
                            if (!Array.isArray(message.uploadTokens))
                                return "uploadTokens: array expected";
                            for (var i = 0; i < message.uploadTokens.length; ++i)
                                if (!$util.isString(message.uploadTokens[i]))
                                    return "uploadTokens: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a FormFieldUpload message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.GridFormField.FormFieldUpload} FormFieldUpload
                     */
                    FormFieldUpload.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.GridFormField.FormFieldUpload)
                            return object;
                        var message = new $root.visor.clientrpc.GridFormField.FormFieldUpload();
                        if (object.uploadToken != null)
                            message.uploadToken = String(object.uploadToken);
                        if (object.uploadTokens) {
                            if (!Array.isArray(object.uploadTokens))
                                throw TypeError(".visor.clientrpc.GridFormField.FormFieldUpload.uploadTokens: array expected");
                            message.uploadTokens = [];
                            for (var i = 0; i < object.uploadTokens.length; ++i)
                                message.uploadTokens[i] = String(object.uploadTokens[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FormFieldUpload message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @static
                     * @param {visor.clientrpc.GridFormField.FormFieldUpload} message FormFieldUpload
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FormFieldUpload.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.uploadTokens = [];
                        if (options.defaults)
                            object.uploadToken = "";
                        if (message.uploadToken != null && message.hasOwnProperty("uploadToken"))
                            object.uploadToken = message.uploadToken;
                        if (message.uploadTokens && message.uploadTokens.length) {
                            object.uploadTokens = [];
                            for (var j = 0; j < message.uploadTokens.length; ++j)
                                object.uploadTokens[j] = message.uploadTokens[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this FormFieldUpload to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.GridFormField.FormFieldUpload
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FormFieldUpload.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FormFieldUpload;
                })();

                return GridFormField;
            })();

            /**
             * GridFormFieldYesNoValue enum.
             * @name visor.clientrpc.GridFormFieldYesNoValue
             * @enum {string}
             * @property {number} GRID_FORM_FIELD_YES_NO_VALUE_INVALID=0 GRID_FORM_FIELD_YES_NO_VALUE_INVALID value
             * @property {number} GRID_FORM_FIELD_YES_NO_VALUE_YES=1 GRID_FORM_FIELD_YES_NO_VALUE_YES value
             * @property {number} GRID_FORM_FIELD_YES_NO_VALUE_NO=2 GRID_FORM_FIELD_YES_NO_VALUE_NO value
             */
            clientrpc.GridFormFieldYesNoValue = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "GRID_FORM_FIELD_YES_NO_VALUE_INVALID"] = 0;
                values[valuesById[1] = "GRID_FORM_FIELD_YES_NO_VALUE_YES"] = 1;
                values[valuesById[2] = "GRID_FORM_FIELD_YES_NO_VALUE_NO"] = 2;
                return values;
            })();

            clientrpc.GridForm = (function() {

                /**
                 * Properties of a GridForm.
                 * @memberof visor.clientrpc
                 * @interface IGridForm
                 * @property {string|null} [key] GridForm key
                 * @property {visor.common.strings.ILocalizedString|null} [title] GridForm title
                 * @property {Array.<visor.clientrpc.IGridFormField>|null} [fields] GridForm fields
                 */

                /**
                 * Constructs a new GridForm.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GridForm.
                 * @implements IGridForm
                 * @constructor
                 * @param {visor.clientrpc.IGridForm=} [properties] Properties to set
                 */
                function GridForm(properties) {
                    this.fields = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GridForm key.
                 * @member {string} key
                 * @memberof visor.clientrpc.GridForm
                 * @instance
                 */
                GridForm.prototype.key = "";

                /**
                 * GridForm title.
                 * @member {visor.common.strings.ILocalizedString|null|undefined} title
                 * @memberof visor.clientrpc.GridForm
                 * @instance
                 */
                GridForm.prototype.title = null;

                /**
                 * GridForm fields.
                 * @member {Array.<visor.clientrpc.IGridFormField>} fields
                 * @memberof visor.clientrpc.GridForm
                 * @instance
                 */
                GridForm.prototype.fields = $util.emptyArray;

                /**
                 * Creates a new GridForm instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {visor.clientrpc.IGridForm=} [properties] Properties to set
                 * @returns {visor.clientrpc.GridForm} GridForm instance
                 */
                GridForm.create = function create(properties) {
                    return new GridForm(properties);
                };

                /**
                 * Encodes the specified GridForm message. Does not implicitly {@link visor.clientrpc.GridForm.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {visor.clientrpc.IGridForm} message GridForm message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GridForm.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.key != null && message.hasOwnProperty("key"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                    if (message.title != null && message.hasOwnProperty("title"))
                        $root.visor.common.strings.LocalizedString.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.fields != null && message.fields.length)
                        for (var i = 0; i < message.fields.length; ++i)
                            $root.visor.clientrpc.GridFormField.encode(message.fields[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified GridForm message, length delimited. Does not implicitly {@link visor.clientrpc.GridForm.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {visor.clientrpc.IGridForm} message GridForm message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GridForm.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GridForm message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GridForm} GridForm
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GridForm.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridForm();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.key = reader.string();
                            break;
                        case 2:
                            message.title = $root.visor.common.strings.LocalizedString.decode(reader, reader.uint32());
                            break;
                        case 3:
                            if (!(message.fields && message.fields.length))
                                message.fields = [];
                            message.fields.push($root.visor.clientrpc.GridFormField.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GridForm message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GridForm} GridForm
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GridForm.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GridForm message.
                 * @function verify
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GridForm.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.visor.common.strings.LocalizedString.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.fields != null && message.hasOwnProperty("fields")) {
                        if (!Array.isArray(message.fields))
                            return "fields: array expected";
                        for (var i = 0; i < message.fields.length; ++i) {
                            var error = $root.visor.clientrpc.GridFormField.verify(message.fields[i]);
                            if (error)
                                return "fields." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GridForm message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GridForm} GridForm
                 */
                GridForm.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GridForm)
                        return object;
                    var message = new $root.visor.clientrpc.GridForm();
                    if (object.key != null)
                        message.key = String(object.key);
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".visor.clientrpc.GridForm.title: object expected");
                        message.title = $root.visor.common.strings.LocalizedString.fromObject(object.title);
                    }
                    if (object.fields) {
                        if (!Array.isArray(object.fields))
                            throw TypeError(".visor.clientrpc.GridForm.fields: array expected");
                        message.fields = [];
                        for (var i = 0; i < object.fields.length; ++i) {
                            if (typeof object.fields[i] !== "object")
                                throw TypeError(".visor.clientrpc.GridForm.fields: object expected");
                            message.fields[i] = $root.visor.clientrpc.GridFormField.fromObject(object.fields[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GridForm message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GridForm
                 * @static
                 * @param {visor.clientrpc.GridForm} message GridForm
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GridForm.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fields = [];
                    if (options.defaults) {
                        object.key = "";
                        object.title = null;
                    }
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.visor.common.strings.LocalizedString.toObject(message.title, options);
                    if (message.fields && message.fields.length) {
                        object.fields = [];
                        for (var j = 0; j < message.fields.length; ++j)
                            object.fields[j] = $root.visor.clientrpc.GridFormField.toObject(message.fields[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this GridForm to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GridForm
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GridForm.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GridForm;
            })();

            clientrpc.GridFormSubmission = (function() {

                /**
                 * Properties of a GridFormSubmission.
                 * @memberof visor.clientrpc
                 * @interface IGridFormSubmission
                 * @property {Object.<string,visor.clientrpc.IGridFormField>|null} [payload] GridFormSubmission payload
                 */

                /**
                 * Constructs a new GridFormSubmission.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GridFormSubmission.
                 * @implements IGridFormSubmission
                 * @constructor
                 * @param {visor.clientrpc.IGridFormSubmission=} [properties] Properties to set
                 */
                function GridFormSubmission(properties) {
                    this.payload = {};
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GridFormSubmission payload.
                 * @member {Object.<string,visor.clientrpc.IGridFormField>} payload
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @instance
                 */
                GridFormSubmission.prototype.payload = $util.emptyObject;

                /**
                 * Creates a new GridFormSubmission instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {visor.clientrpc.IGridFormSubmission=} [properties] Properties to set
                 * @returns {visor.clientrpc.GridFormSubmission} GridFormSubmission instance
                 */
                GridFormSubmission.create = function create(properties) {
                    return new GridFormSubmission(properties);
                };

                /**
                 * Encodes the specified GridFormSubmission message. Does not implicitly {@link visor.clientrpc.GridFormSubmission.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {visor.clientrpc.IGridFormSubmission} message GridFormSubmission message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GridFormSubmission.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.payload != null && message.hasOwnProperty("payload"))
                        for (var keys = Object.keys(message.payload), i = 0; i < keys.length; ++i) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                            $root.visor.clientrpc.GridFormField.encode(message.payload[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                        }
                    return writer;
                };

                /**
                 * Encodes the specified GridFormSubmission message, length delimited. Does not implicitly {@link visor.clientrpc.GridFormSubmission.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {visor.clientrpc.IGridFormSubmission} message GridFormSubmission message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GridFormSubmission.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GridFormSubmission message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GridFormSubmission} GridFormSubmission
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GridFormSubmission.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GridFormSubmission(), key;
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            reader.skip().pos++;
                            if (message.payload === $util.emptyObject)
                                message.payload = {};
                            key = reader.string();
                            reader.pos++;
                            message.payload[key] = $root.visor.clientrpc.GridFormField.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GridFormSubmission message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GridFormSubmission} GridFormSubmission
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GridFormSubmission.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GridFormSubmission message.
                 * @function verify
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GridFormSubmission.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.payload != null && message.hasOwnProperty("payload")) {
                        if (!$util.isObject(message.payload))
                            return "payload: object expected";
                        var key = Object.keys(message.payload);
                        for (var i = 0; i < key.length; ++i) {
                            var error = $root.visor.clientrpc.GridFormField.verify(message.payload[key[i]]);
                            if (error)
                                return "payload." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a GridFormSubmission message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GridFormSubmission} GridFormSubmission
                 */
                GridFormSubmission.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GridFormSubmission)
                        return object;
                    var message = new $root.visor.clientrpc.GridFormSubmission();
                    if (object.payload) {
                        if (typeof object.payload !== "object")
                            throw TypeError(".visor.clientrpc.GridFormSubmission.payload: object expected");
                        message.payload = {};
                        for (var keys = Object.keys(object.payload), i = 0; i < keys.length; ++i) {
                            if (typeof object.payload[keys[i]] !== "object")
                                throw TypeError(".visor.clientrpc.GridFormSubmission.payload: object expected");
                            message.payload[keys[i]] = $root.visor.clientrpc.GridFormField.fromObject(object.payload[keys[i]]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GridFormSubmission message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @static
                 * @param {visor.clientrpc.GridFormSubmission} message GridFormSubmission
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GridFormSubmission.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.objects || options.defaults)
                        object.payload = {};
                    var keys2;
                    if (message.payload && (keys2 = Object.keys(message.payload)).length) {
                        object.payload = {};
                        for (var j = 0; j < keys2.length; ++j)
                            object.payload[keys2[j]] = $root.visor.clientrpc.GridFormField.toObject(message.payload[keys2[j]], options);
                    }
                    return object;
                };

                /**
                 * Converts this GridFormSubmission to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GridFormSubmission
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GridFormSubmission.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GridFormSubmission;
            })();

            clientrpc.GetZendeskJWTRequest = (function() {

                /**
                 * Properties of a GetZendeskJWTRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetZendeskJWTRequest
                 * @property {visor.clientrpc.ZendeskPlatform|null} [platform] GetZendeskJWTRequest platform
                 */

                /**
                 * Constructs a new GetZendeskJWTRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetZendeskJWTRequest.
                 * @implements IGetZendeskJWTRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetZendeskJWTRequest=} [properties] Properties to set
                 */
                function GetZendeskJWTRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetZendeskJWTRequest platform.
                 * @member {visor.clientrpc.ZendeskPlatform} platform
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @instance
                 */
                GetZendeskJWTRequest.prototype.platform = 0;

                /**
                 * Creates a new GetZendeskJWTRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {visor.clientrpc.IGetZendeskJWTRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetZendeskJWTRequest} GetZendeskJWTRequest instance
                 */
                GetZendeskJWTRequest.create = function create(properties) {
                    return new GetZendeskJWTRequest(properties);
                };

                /**
                 * Encodes the specified GetZendeskJWTRequest message. Does not implicitly {@link visor.clientrpc.GetZendeskJWTRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {visor.clientrpc.IGetZendeskJWTRequest} message GetZendeskJWTRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetZendeskJWTRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.platform != null && message.hasOwnProperty("platform"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.platform);
                    return writer;
                };

                /**
                 * Encodes the specified GetZendeskJWTRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetZendeskJWTRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {visor.clientrpc.IGetZendeskJWTRequest} message GetZendeskJWTRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetZendeskJWTRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetZendeskJWTRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetZendeskJWTRequest} GetZendeskJWTRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetZendeskJWTRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetZendeskJWTRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.platform = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetZendeskJWTRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetZendeskJWTRequest} GetZendeskJWTRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetZendeskJWTRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetZendeskJWTRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetZendeskJWTRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.platform != null && message.hasOwnProperty("platform"))
                        switch (message.platform) {
                        default:
                            return "platform: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetZendeskJWTRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetZendeskJWTRequest} GetZendeskJWTRequest
                 */
                GetZendeskJWTRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetZendeskJWTRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetZendeskJWTRequest();
                    switch (object.platform) {
                    case "ZENDESK_PLATFORM_INVALID":
                    case 0:
                        message.platform = 0;
                        break;
                    case "ZENDESK_PLATFORM_IOS":
                    case 1:
                        message.platform = 1;
                        break;
                    case "ZENDESK_PLATFORM_ANDROID":
                    case 2:
                        message.platform = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetZendeskJWTRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @static
                 * @param {visor.clientrpc.GetZendeskJWTRequest} message GetZendeskJWTRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetZendeskJWTRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.platform = options.enums === String ? "ZENDESK_PLATFORM_INVALID" : 0;
                    if (message.platform != null && message.hasOwnProperty("platform"))
                        object.platform = options.enums === String ? $root.visor.clientrpc.ZendeskPlatform[message.platform] : message.platform;
                    return object;
                };

                /**
                 * Converts this GetZendeskJWTRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetZendeskJWTRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetZendeskJWTRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetZendeskJWTRequest;
            })();

            clientrpc.GetZendeskJWTResponse = (function() {

                /**
                 * Properties of a GetZendeskJWTResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetZendeskJWTResponse
                 * @property {string|null} [signedJwt] GetZendeskJWTResponse signedJwt
                 * @property {string|null} [channelKey] GetZendeskJWTResponse channelKey
                 */

                /**
                 * Constructs a new GetZendeskJWTResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetZendeskJWTResponse.
                 * @implements IGetZendeskJWTResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetZendeskJWTResponse=} [properties] Properties to set
                 */
                function GetZendeskJWTResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetZendeskJWTResponse signedJwt.
                 * @member {string} signedJwt
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @instance
                 */
                GetZendeskJWTResponse.prototype.signedJwt = "";

                /**
                 * GetZendeskJWTResponse channelKey.
                 * @member {string} channelKey
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @instance
                 */
                GetZendeskJWTResponse.prototype.channelKey = "";

                /**
                 * Creates a new GetZendeskJWTResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {visor.clientrpc.IGetZendeskJWTResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetZendeskJWTResponse} GetZendeskJWTResponse instance
                 */
                GetZendeskJWTResponse.create = function create(properties) {
                    return new GetZendeskJWTResponse(properties);
                };

                /**
                 * Encodes the specified GetZendeskJWTResponse message. Does not implicitly {@link visor.clientrpc.GetZendeskJWTResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {visor.clientrpc.IGetZendeskJWTResponse} message GetZendeskJWTResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetZendeskJWTResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.signedJwt != null && message.hasOwnProperty("signedJwt"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.signedJwt);
                    if (message.channelKey != null && message.hasOwnProperty("channelKey"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.channelKey);
                    return writer;
                };

                /**
                 * Encodes the specified GetZendeskJWTResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetZendeskJWTResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {visor.clientrpc.IGetZendeskJWTResponse} message GetZendeskJWTResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetZendeskJWTResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetZendeskJWTResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetZendeskJWTResponse} GetZendeskJWTResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetZendeskJWTResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetZendeskJWTResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.signedJwt = reader.string();
                            break;
                        case 2:
                            message.channelKey = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetZendeskJWTResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetZendeskJWTResponse} GetZendeskJWTResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetZendeskJWTResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetZendeskJWTResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetZendeskJWTResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.signedJwt != null && message.hasOwnProperty("signedJwt"))
                        if (!$util.isString(message.signedJwt))
                            return "signedJwt: string expected";
                    if (message.channelKey != null && message.hasOwnProperty("channelKey"))
                        if (!$util.isString(message.channelKey))
                            return "channelKey: string expected";
                    return null;
                };

                /**
                 * Creates a GetZendeskJWTResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetZendeskJWTResponse} GetZendeskJWTResponse
                 */
                GetZendeskJWTResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetZendeskJWTResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetZendeskJWTResponse();
                    if (object.signedJwt != null)
                        message.signedJwt = String(object.signedJwt);
                    if (object.channelKey != null)
                        message.channelKey = String(object.channelKey);
                    return message;
                };

                /**
                 * Creates a plain object from a GetZendeskJWTResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @static
                 * @param {visor.clientrpc.GetZendeskJWTResponse} message GetZendeskJWTResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetZendeskJWTResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.signedJwt = "";
                        object.channelKey = "";
                    }
                    if (message.signedJwt != null && message.hasOwnProperty("signedJwt"))
                        object.signedJwt = message.signedJwt;
                    if (message.channelKey != null && message.hasOwnProperty("channelKey"))
                        object.channelKey = message.channelKey;
                    return object;
                };

                /**
                 * Converts this GetZendeskJWTResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetZendeskJWTResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetZendeskJWTResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetZendeskJWTResponse;
            })();

            /**
             * ZendeskPlatform enum.
             * @name visor.clientrpc.ZendeskPlatform
             * @enum {string}
             * @property {number} ZENDESK_PLATFORM_INVALID=0 ZENDESK_PLATFORM_INVALID value
             * @property {number} ZENDESK_PLATFORM_IOS=1 ZENDESK_PLATFORM_IOS value
             * @property {number} ZENDESK_PLATFORM_ANDROID=2 ZENDESK_PLATFORM_ANDROID value
             */
            clientrpc.ZendeskPlatform = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ZENDESK_PLATFORM_INVALID"] = 0;
                values[valuesById[1] = "ZENDESK_PLATFORM_IOS"] = 1;
                values[valuesById[2] = "ZENDESK_PLATFORM_ANDROID"] = 2;
                return values;
            })();

            clientrpc.SetUpDirectDepositPDFRequest = (function() {

                /**
                 * Properties of a SetUpDirectDepositPDFRequest.
                 * @memberof visor.clientrpc
                 * @interface ISetUpDirectDepositPDFRequest
                 */

                /**
                 * Constructs a new SetUpDirectDepositPDFRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SetUpDirectDepositPDFRequest.
                 * @implements ISetUpDirectDepositPDFRequest
                 * @constructor
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFRequest=} [properties] Properties to set
                 */
                function SetUpDirectDepositPDFRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SetUpDirectDepositPDFRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFRequest} SetUpDirectDepositPDFRequest instance
                 */
                SetUpDirectDepositPDFRequest.create = function create(properties) {
                    return new SetUpDirectDepositPDFRequest(properties);
                };

                /**
                 * Encodes the specified SetUpDirectDepositPDFRequest message. Does not implicitly {@link visor.clientrpc.SetUpDirectDepositPDFRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFRequest} message SetUpDirectDepositPDFRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetUpDirectDepositPDFRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SetUpDirectDepositPDFRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SetUpDirectDepositPDFRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFRequest} message SetUpDirectDepositPDFRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetUpDirectDepositPDFRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SetUpDirectDepositPDFRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFRequest} SetUpDirectDepositPDFRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetUpDirectDepositPDFRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SetUpDirectDepositPDFRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SetUpDirectDepositPDFRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFRequest} SetUpDirectDepositPDFRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetUpDirectDepositPDFRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SetUpDirectDepositPDFRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetUpDirectDepositPDFRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SetUpDirectDepositPDFRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFRequest} SetUpDirectDepositPDFRequest
                 */
                SetUpDirectDepositPDFRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SetUpDirectDepositPDFRequest)
                        return object;
                    return new $root.visor.clientrpc.SetUpDirectDepositPDFRequest();
                };

                /**
                 * Creates a plain object from a SetUpDirectDepositPDFRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @static
                 * @param {visor.clientrpc.SetUpDirectDepositPDFRequest} message SetUpDirectDepositPDFRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetUpDirectDepositPDFRequest.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SetUpDirectDepositPDFRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetUpDirectDepositPDFRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetUpDirectDepositPDFRequest;
            })();

            clientrpc.SetUpDirectDepositPDFResponse = (function() {

                /**
                 * Properties of a SetUpDirectDepositPDFResponse.
                 * @memberof visor.clientrpc
                 * @interface ISetUpDirectDepositPDFResponse
                 */

                /**
                 * Constructs a new SetUpDirectDepositPDFResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SetUpDirectDepositPDFResponse.
                 * @implements ISetUpDirectDepositPDFResponse
                 * @constructor
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFResponse=} [properties] Properties to set
                 */
                function SetUpDirectDepositPDFResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SetUpDirectDepositPDFResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFResponse} SetUpDirectDepositPDFResponse instance
                 */
                SetUpDirectDepositPDFResponse.create = function create(properties) {
                    return new SetUpDirectDepositPDFResponse(properties);
                };

                /**
                 * Encodes the specified SetUpDirectDepositPDFResponse message. Does not implicitly {@link visor.clientrpc.SetUpDirectDepositPDFResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFResponse} message SetUpDirectDepositPDFResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetUpDirectDepositPDFResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SetUpDirectDepositPDFResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SetUpDirectDepositPDFResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {visor.clientrpc.ISetUpDirectDepositPDFResponse} message SetUpDirectDepositPDFResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SetUpDirectDepositPDFResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SetUpDirectDepositPDFResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFResponse} SetUpDirectDepositPDFResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetUpDirectDepositPDFResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SetUpDirectDepositPDFResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SetUpDirectDepositPDFResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFResponse} SetUpDirectDepositPDFResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SetUpDirectDepositPDFResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SetUpDirectDepositPDFResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SetUpDirectDepositPDFResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SetUpDirectDepositPDFResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SetUpDirectDepositPDFResponse} SetUpDirectDepositPDFResponse
                 */
                SetUpDirectDepositPDFResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SetUpDirectDepositPDFResponse)
                        return object;
                    return new $root.visor.clientrpc.SetUpDirectDepositPDFResponse();
                };

                /**
                 * Creates a plain object from a SetUpDirectDepositPDFResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @static
                 * @param {visor.clientrpc.SetUpDirectDepositPDFResponse} message SetUpDirectDepositPDFResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SetUpDirectDepositPDFResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SetUpDirectDepositPDFResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SetUpDirectDepositPDFResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SetUpDirectDepositPDFResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SetUpDirectDepositPDFResponse;
            })();

            clientrpc.SignLoanAgreementRequest = (function() {

                /**
                 * Properties of a SignLoanAgreementRequest.
                 * @memberof visor.clientrpc
                 * @interface ISignLoanAgreementRequest
                 * @property {string|null} [agreementToken] SignLoanAgreementRequest agreementToken
                 * @property {string|null} [creditLineToken] SignLoanAgreementRequest creditLineToken
                 * @property {visor.common.money.IAmount|null} [balance] SignLoanAgreementRequest balance
                 */

                /**
                 * Constructs a new SignLoanAgreementRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SignLoanAgreementRequest.
                 * @implements ISignLoanAgreementRequest
                 * @constructor
                 * @param {visor.clientrpc.ISignLoanAgreementRequest=} [properties] Properties to set
                 */
                function SignLoanAgreementRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SignLoanAgreementRequest agreementToken.
                 * @member {string} agreementToken
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @instance
                 */
                SignLoanAgreementRequest.prototype.agreementToken = "";

                /**
                 * SignLoanAgreementRequest creditLineToken.
                 * @member {string} creditLineToken
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @instance
                 */
                SignLoanAgreementRequest.prototype.creditLineToken = "";

                /**
                 * SignLoanAgreementRequest balance.
                 * @member {visor.common.money.IAmount|null|undefined} balance
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @instance
                 */
                SignLoanAgreementRequest.prototype.balance = null;

                /**
                 * Creates a new SignLoanAgreementRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.ISignLoanAgreementRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.SignLoanAgreementRequest} SignLoanAgreementRequest instance
                 */
                SignLoanAgreementRequest.create = function create(properties) {
                    return new SignLoanAgreementRequest(properties);
                };

                /**
                 * Encodes the specified SignLoanAgreementRequest message. Does not implicitly {@link visor.clientrpc.SignLoanAgreementRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.ISignLoanAgreementRequest} message SignLoanAgreementRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignLoanAgreementRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.agreementToken != null && message.hasOwnProperty("agreementToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.agreementToken);
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.creditLineToken);
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        $root.visor.common.money.Amount.encode(message.balance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SignLoanAgreementRequest message, length delimited. Does not implicitly {@link visor.clientrpc.SignLoanAgreementRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.ISignLoanAgreementRequest} message SignLoanAgreementRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignLoanAgreementRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SignLoanAgreementRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SignLoanAgreementRequest} SignLoanAgreementRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignLoanAgreementRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SignLoanAgreementRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.agreementToken = reader.string();
                            break;
                        case 2:
                            message.creditLineToken = reader.string();
                            break;
                        case 3:
                            message.balance = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SignLoanAgreementRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SignLoanAgreementRequest} SignLoanAgreementRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignLoanAgreementRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SignLoanAgreementRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SignLoanAgreementRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.agreementToken != null && message.hasOwnProperty("agreementToken"))
                        if (!$util.isString(message.agreementToken))
                            return "agreementToken: string expected";
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        if (!$util.isString(message.creditLineToken))
                            return "creditLineToken: string expected";
                    if (message.balance != null && message.hasOwnProperty("balance")) {
                        var error = $root.visor.common.money.Amount.verify(message.balance);
                        if (error)
                            return "balance." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SignLoanAgreementRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SignLoanAgreementRequest} SignLoanAgreementRequest
                 */
                SignLoanAgreementRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SignLoanAgreementRequest)
                        return object;
                    var message = new $root.visor.clientrpc.SignLoanAgreementRequest();
                    if (object.agreementToken != null)
                        message.agreementToken = String(object.agreementToken);
                    if (object.creditLineToken != null)
                        message.creditLineToken = String(object.creditLineToken);
                    if (object.balance != null) {
                        if (typeof object.balance !== "object")
                            throw TypeError(".visor.clientrpc.SignLoanAgreementRequest.balance: object expected");
                        message.balance = $root.visor.common.money.Amount.fromObject(object.balance);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SignLoanAgreementRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @static
                 * @param {visor.clientrpc.SignLoanAgreementRequest} message SignLoanAgreementRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SignLoanAgreementRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.agreementToken = "";
                        object.creditLineToken = "";
                        object.balance = null;
                    }
                    if (message.agreementToken != null && message.hasOwnProperty("agreementToken"))
                        object.agreementToken = message.agreementToken;
                    if (message.creditLineToken != null && message.hasOwnProperty("creditLineToken"))
                        object.creditLineToken = message.creditLineToken;
                    if (message.balance != null && message.hasOwnProperty("balance"))
                        object.balance = $root.visor.common.money.Amount.toObject(message.balance, options);
                    return object;
                };

                /**
                 * Converts this SignLoanAgreementRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SignLoanAgreementRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SignLoanAgreementRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SignLoanAgreementRequest;
            })();

            clientrpc.SignLoanAgreementResponse = (function() {

                /**
                 * Properties of a SignLoanAgreementResponse.
                 * @memberof visor.clientrpc
                 * @interface ISignLoanAgreementResponse
                 */

                /**
                 * Constructs a new SignLoanAgreementResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a SignLoanAgreementResponse.
                 * @implements ISignLoanAgreementResponse
                 * @constructor
                 * @param {visor.clientrpc.ISignLoanAgreementResponse=} [properties] Properties to set
                 */
                function SignLoanAgreementResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new SignLoanAgreementResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.ISignLoanAgreementResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.SignLoanAgreementResponse} SignLoanAgreementResponse instance
                 */
                SignLoanAgreementResponse.create = function create(properties) {
                    return new SignLoanAgreementResponse(properties);
                };

                /**
                 * Encodes the specified SignLoanAgreementResponse message. Does not implicitly {@link visor.clientrpc.SignLoanAgreementResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.ISignLoanAgreementResponse} message SignLoanAgreementResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignLoanAgreementResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified SignLoanAgreementResponse message, length delimited. Does not implicitly {@link visor.clientrpc.SignLoanAgreementResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.ISignLoanAgreementResponse} message SignLoanAgreementResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SignLoanAgreementResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SignLoanAgreementResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.SignLoanAgreementResponse} SignLoanAgreementResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignLoanAgreementResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.SignLoanAgreementResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SignLoanAgreementResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.SignLoanAgreementResponse} SignLoanAgreementResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SignLoanAgreementResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SignLoanAgreementResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SignLoanAgreementResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a SignLoanAgreementResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.SignLoanAgreementResponse} SignLoanAgreementResponse
                 */
                SignLoanAgreementResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.SignLoanAgreementResponse)
                        return object;
                    return new $root.visor.clientrpc.SignLoanAgreementResponse();
                };

                /**
                 * Creates a plain object from a SignLoanAgreementResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @static
                 * @param {visor.clientrpc.SignLoanAgreementResponse} message SignLoanAgreementResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SignLoanAgreementResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this SignLoanAgreementResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.SignLoanAgreementResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SignLoanAgreementResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return SignLoanAgreementResponse;
            })();

            clientrpc.CreateFootprintTokenRequest = (function() {

                /**
                 * Properties of a CreateFootprintTokenRequest.
                 * @memberof visor.clientrpc
                 * @interface ICreateFootprintTokenRequest
                 * @property {string|null} [playbookKey] CreateFootprintTokenRequest playbookKey
                 */

                /**
                 * Constructs a new CreateFootprintTokenRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateFootprintTokenRequest.
                 * @implements ICreateFootprintTokenRequest
                 * @constructor
                 * @param {visor.clientrpc.ICreateFootprintTokenRequest=} [properties] Properties to set
                 */
                function CreateFootprintTokenRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateFootprintTokenRequest playbookKey.
                 * @member {string} playbookKey
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @instance
                 */
                CreateFootprintTokenRequest.prototype.playbookKey = "";

                /**
                 * Creates a new CreateFootprintTokenRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {visor.clientrpc.ICreateFootprintTokenRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateFootprintTokenRequest} CreateFootprintTokenRequest instance
                 */
                CreateFootprintTokenRequest.create = function create(properties) {
                    return new CreateFootprintTokenRequest(properties);
                };

                /**
                 * Encodes the specified CreateFootprintTokenRequest message. Does not implicitly {@link visor.clientrpc.CreateFootprintTokenRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {visor.clientrpc.ICreateFootprintTokenRequest} message CreateFootprintTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFootprintTokenRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.playbookKey != null && message.hasOwnProperty("playbookKey"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.playbookKey);
                    return writer;
                };

                /**
                 * Encodes the specified CreateFootprintTokenRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CreateFootprintTokenRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {visor.clientrpc.ICreateFootprintTokenRequest} message CreateFootprintTokenRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFootprintTokenRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateFootprintTokenRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateFootprintTokenRequest} CreateFootprintTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFootprintTokenRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateFootprintTokenRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.playbookKey = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateFootprintTokenRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateFootprintTokenRequest} CreateFootprintTokenRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFootprintTokenRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateFootprintTokenRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateFootprintTokenRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.playbookKey != null && message.hasOwnProperty("playbookKey"))
                        if (!$util.isString(message.playbookKey))
                            return "playbookKey: string expected";
                    return null;
                };

                /**
                 * Creates a CreateFootprintTokenRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateFootprintTokenRequest} CreateFootprintTokenRequest
                 */
                CreateFootprintTokenRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateFootprintTokenRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CreateFootprintTokenRequest();
                    if (object.playbookKey != null)
                        message.playbookKey = String(object.playbookKey);
                    return message;
                };

                /**
                 * Creates a plain object from a CreateFootprintTokenRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @static
                 * @param {visor.clientrpc.CreateFootprintTokenRequest} message CreateFootprintTokenRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateFootprintTokenRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.playbookKey = "";
                    if (message.playbookKey != null && message.hasOwnProperty("playbookKey"))
                        object.playbookKey = message.playbookKey;
                    return object;
                };

                /**
                 * Converts this CreateFootprintTokenRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateFootprintTokenRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateFootprintTokenRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateFootprintTokenRequest;
            })();

            clientrpc.CreateFootprintTokenResponse = (function() {

                /**
                 * Properties of a CreateFootprintTokenResponse.
                 * @memberof visor.clientrpc
                 * @interface ICreateFootprintTokenResponse
                 * @property {string|null} [sessionToken] CreateFootprintTokenResponse sessionToken
                 * @property {string|null} [link] CreateFootprintTokenResponse link
                 * @property {google.protobuf.ITimestamp|null} [expiresAt] CreateFootprintTokenResponse expiresAt
                 */

                /**
                 * Constructs a new CreateFootprintTokenResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CreateFootprintTokenResponse.
                 * @implements ICreateFootprintTokenResponse
                 * @constructor
                 * @param {visor.clientrpc.ICreateFootprintTokenResponse=} [properties] Properties to set
                 */
                function CreateFootprintTokenResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CreateFootprintTokenResponse sessionToken.
                 * @member {string} sessionToken
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @instance
                 */
                CreateFootprintTokenResponse.prototype.sessionToken = "";

                /**
                 * CreateFootprintTokenResponse link.
                 * @member {string} link
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @instance
                 */
                CreateFootprintTokenResponse.prototype.link = "";

                /**
                 * CreateFootprintTokenResponse expiresAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @instance
                 */
                CreateFootprintTokenResponse.prototype.expiresAt = null;

                /**
                 * Creates a new CreateFootprintTokenResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {visor.clientrpc.ICreateFootprintTokenResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CreateFootprintTokenResponse} CreateFootprintTokenResponse instance
                 */
                CreateFootprintTokenResponse.create = function create(properties) {
                    return new CreateFootprintTokenResponse(properties);
                };

                /**
                 * Encodes the specified CreateFootprintTokenResponse message. Does not implicitly {@link visor.clientrpc.CreateFootprintTokenResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {visor.clientrpc.ICreateFootprintTokenResponse} message CreateFootprintTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFootprintTokenResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sessionToken != null && message.hasOwnProperty("sessionToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionToken);
                    if (message.link != null && message.hasOwnProperty("link"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.link);
                    if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                        $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CreateFootprintTokenResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CreateFootprintTokenResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {visor.clientrpc.ICreateFootprintTokenResponse} message CreateFootprintTokenResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CreateFootprintTokenResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CreateFootprintTokenResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CreateFootprintTokenResponse} CreateFootprintTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFootprintTokenResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CreateFootprintTokenResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.sessionToken = reader.string();
                            break;
                        case 2:
                            message.link = reader.string();
                            break;
                        case 3:
                            message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CreateFootprintTokenResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CreateFootprintTokenResponse} CreateFootprintTokenResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CreateFootprintTokenResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CreateFootprintTokenResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CreateFootprintTokenResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sessionToken != null && message.hasOwnProperty("sessionToken"))
                        if (!$util.isString(message.sessionToken))
                            return "sessionToken: string expected";
                    if (message.link != null && message.hasOwnProperty("link"))
                        if (!$util.isString(message.link))
                            return "link: string expected";
                    if (message.expiresAt != null && message.hasOwnProperty("expiresAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.expiresAt);
                        if (error)
                            return "expiresAt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CreateFootprintTokenResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CreateFootprintTokenResponse} CreateFootprintTokenResponse
                 */
                CreateFootprintTokenResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CreateFootprintTokenResponse)
                        return object;
                    var message = new $root.visor.clientrpc.CreateFootprintTokenResponse();
                    if (object.sessionToken != null)
                        message.sessionToken = String(object.sessionToken);
                    if (object.link != null)
                        message.link = String(object.link);
                    if (object.expiresAt != null)
                        if (object.expiresAt === null)
                            message.expiresAt = {};
                        else
                            message.expiresAt = {
                                seconds: Math.floor(object.expiresAt.getTime() / 1000),
                                nanos: object.expiresAt.getMilliseconds() * 1000000
                            };
                    return message;
                };

                /**
                 * Creates a plain object from a CreateFootprintTokenResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @static
                 * @param {visor.clientrpc.CreateFootprintTokenResponse} message CreateFootprintTokenResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CreateFootprintTokenResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sessionToken = "";
                        object.link = "";
                        object.expiresAt = null;
                    }
                    if (message.sessionToken != null && message.hasOwnProperty("sessionToken"))
                        object.sessionToken = message.sessionToken;
                    if (message.link != null && message.hasOwnProperty("link"))
                        object.link = message.link;
                    if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                        object.expiresAt = new Date(message.expiresAt.seconds * 1000 + message.expiresAt.nanos / 1000000);
                    return object;
                };

                /**
                 * Converts this CreateFootprintTokenResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CreateFootprintTokenResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CreateFootprintTokenResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CreateFootprintTokenResponse;
            })();

            clientrpc.CompleteCollectFootprintStepRequest = (function() {

                /**
                 * Properties of a CompleteCollectFootprintStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCollectFootprintStepRequest
                 * @property {string|null} [stepToken] CompleteCollectFootprintStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteCollectFootprintStepRequest workflowToken
                 * @property {string|null} [validationToken] CompleteCollectFootprintStepRequest validationToken
                 */

                /**
                 * Constructs a new CompleteCollectFootprintStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCollectFootprintStepRequest.
                 * @implements ICompleteCollectFootprintStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepRequest=} [properties] Properties to set
                 */
                function CompleteCollectFootprintStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteCollectFootprintStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @instance
                 */
                CompleteCollectFootprintStepRequest.prototype.stepToken = "";

                /**
                 * CompleteCollectFootprintStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @instance
                 */
                CompleteCollectFootprintStepRequest.prototype.workflowToken = "";

                /**
                 * CompleteCollectFootprintStepRequest validationToken.
                 * @member {string} validationToken
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @instance
                 */
                CompleteCollectFootprintStepRequest.prototype.validationToken = "";

                /**
                 * Creates a new CompleteCollectFootprintStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepRequest} CompleteCollectFootprintStepRequest instance
                 */
                CompleteCollectFootprintStepRequest.create = function create(properties) {
                    return new CompleteCollectFootprintStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteCollectFootprintStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteCollectFootprintStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepRequest} message CompleteCollectFootprintStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCollectFootprintStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    if (message.validationToken != null && message.hasOwnProperty("validationToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.validationToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCollectFootprintStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCollectFootprintStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepRequest} message CompleteCollectFootprintStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCollectFootprintStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCollectFootprintStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepRequest} CompleteCollectFootprintStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCollectFootprintStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCollectFootprintStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        case 3:
                            message.validationToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCollectFootprintStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepRequest} CompleteCollectFootprintStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCollectFootprintStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCollectFootprintStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCollectFootprintStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    if (message.validationToken != null && message.hasOwnProperty("validationToken"))
                        if (!$util.isString(message.validationToken))
                            return "validationToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteCollectFootprintStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepRequest} CompleteCollectFootprintStepRequest
                 */
                CompleteCollectFootprintStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCollectFootprintStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteCollectFootprintStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    if (object.validationToken != null)
                        message.validationToken = String(object.validationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteCollectFootprintStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteCollectFootprintStepRequest} message CompleteCollectFootprintStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCollectFootprintStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                        object.validationToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    if (message.validationToken != null && message.hasOwnProperty("validationToken"))
                        object.validationToken = message.validationToken;
                    return object;
                };

                /**
                 * Converts this CompleteCollectFootprintStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCollectFootprintStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCollectFootprintStepRequest;
            })();

            clientrpc.ResolveFootprintDocumentUploadRequest = (function() {

                /**
                 * Properties of a ResolveFootprintDocumentUploadRequest.
                 * @memberof visor.clientrpc
                 * @interface IResolveFootprintDocumentUploadRequest
                 * @property {string|null} [validationToken] ResolveFootprintDocumentUploadRequest validationToken
                 */

                /**
                 * Constructs a new ResolveFootprintDocumentUploadRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResolveFootprintDocumentUploadRequest.
                 * @implements IResolveFootprintDocumentUploadRequest
                 * @constructor
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadRequest=} [properties] Properties to set
                 */
                function ResolveFootprintDocumentUploadRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResolveFootprintDocumentUploadRequest validationToken.
                 * @member {string} validationToken
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @instance
                 */
                ResolveFootprintDocumentUploadRequest.prototype.validationToken = "";

                /**
                 * Creates a new ResolveFootprintDocumentUploadRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadRequest} ResolveFootprintDocumentUploadRequest instance
                 */
                ResolveFootprintDocumentUploadRequest.create = function create(properties) {
                    return new ResolveFootprintDocumentUploadRequest(properties);
                };

                /**
                 * Encodes the specified ResolveFootprintDocumentUploadRequest message. Does not implicitly {@link visor.clientrpc.ResolveFootprintDocumentUploadRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadRequest} message ResolveFootprintDocumentUploadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveFootprintDocumentUploadRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.validationToken != null && message.hasOwnProperty("validationToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.validationToken);
                    return writer;
                };

                /**
                 * Encodes the specified ResolveFootprintDocumentUploadRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ResolveFootprintDocumentUploadRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadRequest} message ResolveFootprintDocumentUploadRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveFootprintDocumentUploadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResolveFootprintDocumentUploadRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadRequest} ResolveFootprintDocumentUploadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveFootprintDocumentUploadRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResolveFootprintDocumentUploadRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.validationToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResolveFootprintDocumentUploadRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadRequest} ResolveFootprintDocumentUploadRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveFootprintDocumentUploadRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResolveFootprintDocumentUploadRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResolveFootprintDocumentUploadRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.validationToken != null && message.hasOwnProperty("validationToken"))
                        if (!$util.isString(message.validationToken))
                            return "validationToken: string expected";
                    return null;
                };

                /**
                 * Creates a ResolveFootprintDocumentUploadRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadRequest} ResolveFootprintDocumentUploadRequest
                 */
                ResolveFootprintDocumentUploadRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResolveFootprintDocumentUploadRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ResolveFootprintDocumentUploadRequest();
                    if (object.validationToken != null)
                        message.validationToken = String(object.validationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a ResolveFootprintDocumentUploadRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @static
                 * @param {visor.clientrpc.ResolveFootprintDocumentUploadRequest} message ResolveFootprintDocumentUploadRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResolveFootprintDocumentUploadRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.validationToken = "";
                    if (message.validationToken != null && message.hasOwnProperty("validationToken"))
                        object.validationToken = message.validationToken;
                    return object;
                };

                /**
                 * Converts this ResolveFootprintDocumentUploadRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResolveFootprintDocumentUploadRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResolveFootprintDocumentUploadRequest;
            })();

            clientrpc.ResolveFootprintDocumentUploadResponse = (function() {

                /**
                 * Properties of a ResolveFootprintDocumentUploadResponse.
                 * @memberof visor.clientrpc
                 * @interface IResolveFootprintDocumentUploadResponse
                 */

                /**
                 * Constructs a new ResolveFootprintDocumentUploadResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResolveFootprintDocumentUploadResponse.
                 * @implements IResolveFootprintDocumentUploadResponse
                 * @constructor
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadResponse=} [properties] Properties to set
                 */
                function ResolveFootprintDocumentUploadResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ResolveFootprintDocumentUploadResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadResponse} ResolveFootprintDocumentUploadResponse instance
                 */
                ResolveFootprintDocumentUploadResponse.create = function create(properties) {
                    return new ResolveFootprintDocumentUploadResponse(properties);
                };

                /**
                 * Encodes the specified ResolveFootprintDocumentUploadResponse message. Does not implicitly {@link visor.clientrpc.ResolveFootprintDocumentUploadResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadResponse} message ResolveFootprintDocumentUploadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveFootprintDocumentUploadResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ResolveFootprintDocumentUploadResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ResolveFootprintDocumentUploadResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {visor.clientrpc.IResolveFootprintDocumentUploadResponse} message ResolveFootprintDocumentUploadResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveFootprintDocumentUploadResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResolveFootprintDocumentUploadResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadResponse} ResolveFootprintDocumentUploadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveFootprintDocumentUploadResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResolveFootprintDocumentUploadResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResolveFootprintDocumentUploadResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadResponse} ResolveFootprintDocumentUploadResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveFootprintDocumentUploadResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResolveFootprintDocumentUploadResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResolveFootprintDocumentUploadResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ResolveFootprintDocumentUploadResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResolveFootprintDocumentUploadResponse} ResolveFootprintDocumentUploadResponse
                 */
                ResolveFootprintDocumentUploadResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResolveFootprintDocumentUploadResponse)
                        return object;
                    return new $root.visor.clientrpc.ResolveFootprintDocumentUploadResponse();
                };

                /**
                 * Creates a plain object from a ResolveFootprintDocumentUploadResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @static
                 * @param {visor.clientrpc.ResolveFootprintDocumentUploadResponse} message ResolveFootprintDocumentUploadResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResolveFootprintDocumentUploadResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ResolveFootprintDocumentUploadResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResolveFootprintDocumentUploadResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResolveFootprintDocumentUploadResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResolveFootprintDocumentUploadResponse;
            })();

            clientrpc.CompleteCollectFootprintStepResponse = (function() {

                /**
                 * Properties of a CompleteCollectFootprintStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteCollectFootprintStepResponse
                 */

                /**
                 * Constructs a new CompleteCollectFootprintStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteCollectFootprintStepResponse.
                 * @implements ICompleteCollectFootprintStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepResponse=} [properties] Properties to set
                 */
                function CompleteCollectFootprintStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteCollectFootprintStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepResponse} CompleteCollectFootprintStepResponse instance
                 */
                CompleteCollectFootprintStepResponse.create = function create(properties) {
                    return new CompleteCollectFootprintStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteCollectFootprintStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteCollectFootprintStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepResponse} message CompleteCollectFootprintStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCollectFootprintStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteCollectFootprintStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteCollectFootprintStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteCollectFootprintStepResponse} message CompleteCollectFootprintStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteCollectFootprintStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteCollectFootprintStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepResponse} CompleteCollectFootprintStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCollectFootprintStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteCollectFootprintStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteCollectFootprintStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepResponse} CompleteCollectFootprintStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteCollectFootprintStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteCollectFootprintStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteCollectFootprintStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteCollectFootprintStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteCollectFootprintStepResponse} CompleteCollectFootprintStepResponse
                 */
                CompleteCollectFootprintStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteCollectFootprintStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteCollectFootprintStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteCollectFootprintStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteCollectFootprintStepResponse} message CompleteCollectFootprintStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteCollectFootprintStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteCollectFootprintStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteCollectFootprintStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteCollectFootprintStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteCollectFootprintStepResponse;
            })();

            /**
             * ComplianceProductType enum.
             * @name visor.clientrpc.ComplianceProductType
             * @enum {string}
             * @property {number} COMPLIANCE_PRODUCT_TYPE_INVALID=0 COMPLIANCE_PRODUCT_TYPE_INVALID value
             * @property {number} COMPLIANCE_PRODUCT_TYPE_ADVANCE=1 COMPLIANCE_PRODUCT_TYPE_ADVANCE value
             */
            clientrpc.ComplianceProductType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "COMPLIANCE_PRODUCT_TYPE_INVALID"] = 0;
                values[valuesById[1] = "COMPLIANCE_PRODUCT_TYPE_ADVANCE"] = 1;
                return values;
            })();

            clientrpc.ComplianceProfile = (function() {

                /**
                 * Properties of a ComplianceProfile.
                 * @memberof visor.clientrpc
                 * @interface IComplianceProfile
                 * @property {string|null} [firstName] ComplianceProfile firstName
                 * @property {string|null} [lastName] ComplianceProfile lastName
                 * @property {visor.common.time.ILocalDate|null} [dob] ComplianceProfile dob
                 * @property {string|null} [ssn] ComplianceProfile ssn
                 * @property {visor.clientrpc.IAddress|null} [address] ComplianceProfile address
                 */

                /**
                 * Constructs a new ComplianceProfile.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ComplianceProfile.
                 * @implements IComplianceProfile
                 * @constructor
                 * @param {visor.clientrpc.IComplianceProfile=} [properties] Properties to set
                 */
                function ComplianceProfile(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ComplianceProfile firstName.
                 * @member {string} firstName
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @instance
                 */
                ComplianceProfile.prototype.firstName = "";

                /**
                 * ComplianceProfile lastName.
                 * @member {string} lastName
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @instance
                 */
                ComplianceProfile.prototype.lastName = "";

                /**
                 * ComplianceProfile dob.
                 * @member {visor.common.time.ILocalDate|null|undefined} dob
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @instance
                 */
                ComplianceProfile.prototype.dob = null;

                /**
                 * ComplianceProfile ssn.
                 * @member {string} ssn
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @instance
                 */
                ComplianceProfile.prototype.ssn = "";

                /**
                 * ComplianceProfile address.
                 * @member {visor.clientrpc.IAddress|null|undefined} address
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @instance
                 */
                ComplianceProfile.prototype.address = null;

                /**
                 * Creates a new ComplianceProfile instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {visor.clientrpc.IComplianceProfile=} [properties] Properties to set
                 * @returns {visor.clientrpc.ComplianceProfile} ComplianceProfile instance
                 */
                ComplianceProfile.create = function create(properties) {
                    return new ComplianceProfile(properties);
                };

                /**
                 * Encodes the specified ComplianceProfile message. Does not implicitly {@link visor.clientrpc.ComplianceProfile.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {visor.clientrpc.IComplianceProfile} message ComplianceProfile message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComplianceProfile.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    if (message.dob != null && message.hasOwnProperty("dob"))
                        $root.visor.common.time.LocalDate.encode(message.dob, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.ssn != null && message.hasOwnProperty("ssn"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.ssn);
                    if (message.address != null && message.hasOwnProperty("address"))
                        $root.visor.clientrpc.Address.encode(message.address, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ComplianceProfile message, length delimited. Does not implicitly {@link visor.clientrpc.ComplianceProfile.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {visor.clientrpc.IComplianceProfile} message ComplianceProfile message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComplianceProfile.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ComplianceProfile message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ComplianceProfile} ComplianceProfile
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComplianceProfile.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ComplianceProfile();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.firstName = reader.string();
                            break;
                        case 2:
                            message.lastName = reader.string();
                            break;
                        case 3:
                            message.dob = $root.visor.common.time.LocalDate.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.ssn = reader.string();
                            break;
                        case 5:
                            message.address = $root.visor.clientrpc.Address.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ComplianceProfile message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ComplianceProfile} ComplianceProfile
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComplianceProfile.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ComplianceProfile message.
                 * @function verify
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ComplianceProfile.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.dob != null && message.hasOwnProperty("dob")) {
                        var error = $root.visor.common.time.LocalDate.verify(message.dob);
                        if (error)
                            return "dob." + error;
                    }
                    if (message.ssn != null && message.hasOwnProperty("ssn"))
                        if (!$util.isString(message.ssn))
                            return "ssn: string expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        var error = $root.visor.clientrpc.Address.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ComplianceProfile message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ComplianceProfile} ComplianceProfile
                 */
                ComplianceProfile.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ComplianceProfile)
                        return object;
                    var message = new $root.visor.clientrpc.ComplianceProfile();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.dob != null) {
                        if (typeof object.dob !== "object")
                            throw TypeError(".visor.clientrpc.ComplianceProfile.dob: object expected");
                        message.dob = $root.visor.common.time.LocalDate.fromObject(object.dob);
                    }
                    if (object.ssn != null)
                        message.ssn = String(object.ssn);
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".visor.clientrpc.ComplianceProfile.address: object expected");
                        message.address = $root.visor.clientrpc.Address.fromObject(object.address);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ComplianceProfile message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @static
                 * @param {visor.clientrpc.ComplianceProfile} message ComplianceProfile
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ComplianceProfile.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                        object.dob = null;
                        object.ssn = "";
                        object.address = null;
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.dob != null && message.hasOwnProperty("dob"))
                        object.dob = $root.visor.common.time.LocalDate.toObject(message.dob, options);
                    if (message.ssn != null && message.hasOwnProperty("ssn"))
                        object.ssn = message.ssn;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.visor.clientrpc.Address.toObject(message.address, options);
                    return object;
                };

                /**
                 * Converts this ComplianceProfile to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ComplianceProfile
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ComplianceProfile.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ComplianceProfile;
            })();

            clientrpc.GetComplianceChecklistRequest = (function() {

                /**
                 * Properties of a GetComplianceChecklistRequest.
                 * @memberof visor.clientrpc
                 * @interface IGetComplianceChecklistRequest
                 * @property {visor.clientrpc.ComplianceProductType|null} [product] GetComplianceChecklistRequest product
                 */

                /**
                 * Constructs a new GetComplianceChecklistRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetComplianceChecklistRequest.
                 * @implements IGetComplianceChecklistRequest
                 * @constructor
                 * @param {visor.clientrpc.IGetComplianceChecklistRequest=} [properties] Properties to set
                 */
                function GetComplianceChecklistRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetComplianceChecklistRequest product.
                 * @member {visor.clientrpc.ComplianceProductType} product
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @instance
                 */
                GetComplianceChecklistRequest.prototype.product = 0;

                /**
                 * Creates a new GetComplianceChecklistRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {visor.clientrpc.IGetComplianceChecklistRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetComplianceChecklistRequest} GetComplianceChecklistRequest instance
                 */
                GetComplianceChecklistRequest.create = function create(properties) {
                    return new GetComplianceChecklistRequest(properties);
                };

                /**
                 * Encodes the specified GetComplianceChecklistRequest message. Does not implicitly {@link visor.clientrpc.GetComplianceChecklistRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {visor.clientrpc.IGetComplianceChecklistRequest} message GetComplianceChecklistRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetComplianceChecklistRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.product != null && message.hasOwnProperty("product"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.product);
                    return writer;
                };

                /**
                 * Encodes the specified GetComplianceChecklistRequest message, length delimited. Does not implicitly {@link visor.clientrpc.GetComplianceChecklistRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {visor.clientrpc.IGetComplianceChecklistRequest} message GetComplianceChecklistRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetComplianceChecklistRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetComplianceChecklistRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetComplianceChecklistRequest} GetComplianceChecklistRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetComplianceChecklistRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetComplianceChecklistRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.product = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetComplianceChecklistRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetComplianceChecklistRequest} GetComplianceChecklistRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetComplianceChecklistRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetComplianceChecklistRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetComplianceChecklistRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.product != null && message.hasOwnProperty("product"))
                        switch (message.product) {
                        default:
                            return "product: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetComplianceChecklistRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetComplianceChecklistRequest} GetComplianceChecklistRequest
                 */
                GetComplianceChecklistRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetComplianceChecklistRequest)
                        return object;
                    var message = new $root.visor.clientrpc.GetComplianceChecklistRequest();
                    switch (object.product) {
                    case "COMPLIANCE_PRODUCT_TYPE_INVALID":
                    case 0:
                        message.product = 0;
                        break;
                    case "COMPLIANCE_PRODUCT_TYPE_ADVANCE":
                    case 1:
                        message.product = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetComplianceChecklistRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @static
                 * @param {visor.clientrpc.GetComplianceChecklistRequest} message GetComplianceChecklistRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetComplianceChecklistRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.product = options.enums === String ? "COMPLIANCE_PRODUCT_TYPE_INVALID" : 0;
                    if (message.product != null && message.hasOwnProperty("product"))
                        object.product = options.enums === String ? $root.visor.clientrpc.ComplianceProductType[message.product] : message.product;
                    return object;
                };

                /**
                 * Converts this GetComplianceChecklistRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetComplianceChecklistRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetComplianceChecklistRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetComplianceChecklistRequest;
            })();

            /**
             * ComplianceChecklistItemType enum.
             * @name visor.clientrpc.ComplianceChecklistItemType
             * @enum {string}
             * @property {number} COMPLIANCE_CHECKLIST_ITEM_TYPE_INVALID=0 COMPLIANCE_CHECKLIST_ITEM_TYPE_INVALID value
             * @property {number} COMPLIANCE_CHECKLIST_ITEM_TYPE_CONFIRM_PROFILE=1 COMPLIANCE_CHECKLIST_ITEM_TYPE_CONFIRM_PROFILE value
             * @property {number} COMPLIANCE_CHECKLIST_ITEM_TYPE_FULL_DOCUMENT_UPLOAD=2 COMPLIANCE_CHECKLIST_ITEM_TYPE_FULL_DOCUMENT_UPLOAD value
             * @property {number} COMPLIANCE_CHECKLIST_ITEM_TYPE_GIVE_FAILED_VERIFICATION_REASON=3 COMPLIANCE_CHECKLIST_ITEM_TYPE_GIVE_FAILED_VERIFICATION_REASON value
             */
            clientrpc.ComplianceChecklistItemType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "COMPLIANCE_CHECKLIST_ITEM_TYPE_INVALID"] = 0;
                values[valuesById[1] = "COMPLIANCE_CHECKLIST_ITEM_TYPE_CONFIRM_PROFILE"] = 1;
                values[valuesById[2] = "COMPLIANCE_CHECKLIST_ITEM_TYPE_FULL_DOCUMENT_UPLOAD"] = 2;
                values[valuesById[3] = "COMPLIANCE_CHECKLIST_ITEM_TYPE_GIVE_FAILED_VERIFICATION_REASON"] = 3;
                return values;
            })();

            /**
             * ComplianceResultType enum.
             * @name visor.clientrpc.ComplianceResultType
             * @enum {string}
             * @property {number} COMPLIANCE_RESULT_TYPE_INVALID=0 COMPLIANCE_RESULT_TYPE_INVALID value
             * @property {number} COMPLIANCE_RESULT_TYPE_PENDING=1 COMPLIANCE_RESULT_TYPE_PENDING value
             * @property {number} COMPLIANCE_RESULT_TYPE_SUCCESS=2 COMPLIANCE_RESULT_TYPE_SUCCESS value
             * @property {number} COMPLIANCE_RESULT_TYPE_FAILED=3 COMPLIANCE_RESULT_TYPE_FAILED value
             */
            clientrpc.ComplianceResultType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "COMPLIANCE_RESULT_TYPE_INVALID"] = 0;
                values[valuesById[1] = "COMPLIANCE_RESULT_TYPE_PENDING"] = 1;
                values[valuesById[2] = "COMPLIANCE_RESULT_TYPE_SUCCESS"] = 2;
                values[valuesById[3] = "COMPLIANCE_RESULT_TYPE_FAILED"] = 3;
                return values;
            })();

            clientrpc.GetComplianceChecklistResponse = (function() {

                /**
                 * Properties of a GetComplianceChecklistResponse.
                 * @memberof visor.clientrpc
                 * @interface IGetComplianceChecklistResponse
                 * @property {visor.clientrpc.ComplianceChecklistItemType|null} [item] GetComplianceChecklistResponse item
                 * @property {visor.clientrpc.IComplianceProfile|null} [profile] GetComplianceChecklistResponse profile
                 * @property {visor.clientrpc.ComplianceResultType|null} [result] GetComplianceChecklistResponse result
                 */

                /**
                 * Constructs a new GetComplianceChecklistResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a GetComplianceChecklistResponse.
                 * @implements IGetComplianceChecklistResponse
                 * @constructor
                 * @param {visor.clientrpc.IGetComplianceChecklistResponse=} [properties] Properties to set
                 */
                function GetComplianceChecklistResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * GetComplianceChecklistResponse item.
                 * @member {visor.clientrpc.ComplianceChecklistItemType} item
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @instance
                 */
                GetComplianceChecklistResponse.prototype.item = 0;

                /**
                 * GetComplianceChecklistResponse profile.
                 * @member {visor.clientrpc.IComplianceProfile|null|undefined} profile
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @instance
                 */
                GetComplianceChecklistResponse.prototype.profile = null;

                /**
                 * GetComplianceChecklistResponse result.
                 * @member {visor.clientrpc.ComplianceResultType} result
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @instance
                 */
                GetComplianceChecklistResponse.prototype.result = 0;

                /**
                 * Creates a new GetComplianceChecklistResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {visor.clientrpc.IGetComplianceChecklistResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.GetComplianceChecklistResponse} GetComplianceChecklistResponse instance
                 */
                GetComplianceChecklistResponse.create = function create(properties) {
                    return new GetComplianceChecklistResponse(properties);
                };

                /**
                 * Encodes the specified GetComplianceChecklistResponse message. Does not implicitly {@link visor.clientrpc.GetComplianceChecklistResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {visor.clientrpc.IGetComplianceChecklistResponse} message GetComplianceChecklistResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetComplianceChecklistResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.item != null && message.hasOwnProperty("item"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.item);
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        $root.visor.clientrpc.ComplianceProfile.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.result != null && message.hasOwnProperty("result"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.result);
                    return writer;
                };

                /**
                 * Encodes the specified GetComplianceChecklistResponse message, length delimited. Does not implicitly {@link visor.clientrpc.GetComplianceChecklistResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {visor.clientrpc.IGetComplianceChecklistResponse} message GetComplianceChecklistResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                GetComplianceChecklistResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a GetComplianceChecklistResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.GetComplianceChecklistResponse} GetComplianceChecklistResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetComplianceChecklistResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.GetComplianceChecklistResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.item = reader.int32();
                            break;
                        case 2:
                            message.profile = $root.visor.clientrpc.ComplianceProfile.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.result = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a GetComplianceChecklistResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.GetComplianceChecklistResponse} GetComplianceChecklistResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                GetComplianceChecklistResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a GetComplianceChecklistResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                GetComplianceChecklistResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.item != null && message.hasOwnProperty("item"))
                        switch (message.item) {
                        default:
                            return "item: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.profile != null && message.hasOwnProperty("profile")) {
                        var error = $root.visor.clientrpc.ComplianceProfile.verify(message.profile);
                        if (error)
                            return "profile." + error;
                    }
                    if (message.result != null && message.hasOwnProperty("result"))
                        switch (message.result) {
                        default:
                            return "result: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a GetComplianceChecklistResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.GetComplianceChecklistResponse} GetComplianceChecklistResponse
                 */
                GetComplianceChecklistResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.GetComplianceChecklistResponse)
                        return object;
                    var message = new $root.visor.clientrpc.GetComplianceChecklistResponse();
                    switch (object.item) {
                    case "COMPLIANCE_CHECKLIST_ITEM_TYPE_INVALID":
                    case 0:
                        message.item = 0;
                        break;
                    case "COMPLIANCE_CHECKLIST_ITEM_TYPE_CONFIRM_PROFILE":
                    case 1:
                        message.item = 1;
                        break;
                    case "COMPLIANCE_CHECKLIST_ITEM_TYPE_FULL_DOCUMENT_UPLOAD":
                    case 2:
                        message.item = 2;
                        break;
                    case "COMPLIANCE_CHECKLIST_ITEM_TYPE_GIVE_FAILED_VERIFICATION_REASON":
                    case 3:
                        message.item = 3;
                        break;
                    }
                    if (object.profile != null) {
                        if (typeof object.profile !== "object")
                            throw TypeError(".visor.clientrpc.GetComplianceChecklistResponse.profile: object expected");
                        message.profile = $root.visor.clientrpc.ComplianceProfile.fromObject(object.profile);
                    }
                    switch (object.result) {
                    case "COMPLIANCE_RESULT_TYPE_INVALID":
                    case 0:
                        message.result = 0;
                        break;
                    case "COMPLIANCE_RESULT_TYPE_PENDING":
                    case 1:
                        message.result = 1;
                        break;
                    case "COMPLIANCE_RESULT_TYPE_SUCCESS":
                    case 2:
                        message.result = 2;
                        break;
                    case "COMPLIANCE_RESULT_TYPE_FAILED":
                    case 3:
                        message.result = 3;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a GetComplianceChecklistResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @static
                 * @param {visor.clientrpc.GetComplianceChecklistResponse} message GetComplianceChecklistResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                GetComplianceChecklistResponse.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.item = options.enums === String ? "COMPLIANCE_CHECKLIST_ITEM_TYPE_INVALID" : 0;
                        object.profile = null;
                        object.result = options.enums === String ? "COMPLIANCE_RESULT_TYPE_INVALID" : 0;
                    }
                    if (message.item != null && message.hasOwnProperty("item"))
                        object.item = options.enums === String ? $root.visor.clientrpc.ComplianceChecklistItemType[message.item] : message.item;
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        object.profile = $root.visor.clientrpc.ComplianceProfile.toObject(message.profile, options);
                    if (message.result != null && message.hasOwnProperty("result"))
                        object.result = options.enums === String ? $root.visor.clientrpc.ComplianceResultType[message.result] : message.result;
                    return object;
                };

                /**
                 * Converts this GetComplianceChecklistResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.GetComplianceChecklistResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                GetComplianceChecklistResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return GetComplianceChecklistResponse;
            })();

            clientrpc.ResolveConfirmProfileRequest = (function() {

                /**
                 * Properties of a ResolveConfirmProfileRequest.
                 * @memberof visor.clientrpc
                 * @interface IResolveConfirmProfileRequest
                 * @property {visor.clientrpc.IComplianceProfile|null} [profile] ResolveConfirmProfileRequest profile
                 */

                /**
                 * Constructs a new ResolveConfirmProfileRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResolveConfirmProfileRequest.
                 * @implements IResolveConfirmProfileRequest
                 * @constructor
                 * @param {visor.clientrpc.IResolveConfirmProfileRequest=} [properties] Properties to set
                 */
                function ResolveConfirmProfileRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResolveConfirmProfileRequest profile.
                 * @member {visor.clientrpc.IComplianceProfile|null|undefined} profile
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @instance
                 */
                ResolveConfirmProfileRequest.prototype.profile = null;

                /**
                 * Creates a new ResolveConfirmProfileRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {visor.clientrpc.IResolveConfirmProfileRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResolveConfirmProfileRequest} ResolveConfirmProfileRequest instance
                 */
                ResolveConfirmProfileRequest.create = function create(properties) {
                    return new ResolveConfirmProfileRequest(properties);
                };

                /**
                 * Encodes the specified ResolveConfirmProfileRequest message. Does not implicitly {@link visor.clientrpc.ResolveConfirmProfileRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {visor.clientrpc.IResolveConfirmProfileRequest} message ResolveConfirmProfileRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveConfirmProfileRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        $root.visor.clientrpc.ComplianceProfile.encode(message.profile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ResolveConfirmProfileRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ResolveConfirmProfileRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {visor.clientrpc.IResolveConfirmProfileRequest} message ResolveConfirmProfileRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveConfirmProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResolveConfirmProfileRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResolveConfirmProfileRequest} ResolveConfirmProfileRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveConfirmProfileRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResolveConfirmProfileRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.profile = $root.visor.clientrpc.ComplianceProfile.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResolveConfirmProfileRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResolveConfirmProfileRequest} ResolveConfirmProfileRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveConfirmProfileRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResolveConfirmProfileRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResolveConfirmProfileRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.profile != null && message.hasOwnProperty("profile")) {
                        var error = $root.visor.clientrpc.ComplianceProfile.verify(message.profile);
                        if (error)
                            return "profile." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ResolveConfirmProfileRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResolveConfirmProfileRequest} ResolveConfirmProfileRequest
                 */
                ResolveConfirmProfileRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResolveConfirmProfileRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ResolveConfirmProfileRequest();
                    if (object.profile != null) {
                        if (typeof object.profile !== "object")
                            throw TypeError(".visor.clientrpc.ResolveConfirmProfileRequest.profile: object expected");
                        message.profile = $root.visor.clientrpc.ComplianceProfile.fromObject(object.profile);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ResolveConfirmProfileRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @static
                 * @param {visor.clientrpc.ResolveConfirmProfileRequest} message ResolveConfirmProfileRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResolveConfirmProfileRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.profile = null;
                    if (message.profile != null && message.hasOwnProperty("profile"))
                        object.profile = $root.visor.clientrpc.ComplianceProfile.toObject(message.profile, options);
                    return object;
                };

                /**
                 * Converts this ResolveConfirmProfileRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResolveConfirmProfileRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResolveConfirmProfileRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResolveConfirmProfileRequest;
            })();

            clientrpc.ResolveConfirmProfileResponse = (function() {

                /**
                 * Properties of a ResolveConfirmProfileResponse.
                 * @memberof visor.clientrpc
                 * @interface IResolveConfirmProfileResponse
                 */

                /**
                 * Constructs a new ResolveConfirmProfileResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResolveConfirmProfileResponse.
                 * @implements IResolveConfirmProfileResponse
                 * @constructor
                 * @param {visor.clientrpc.IResolveConfirmProfileResponse=} [properties] Properties to set
                 */
                function ResolveConfirmProfileResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ResolveConfirmProfileResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {visor.clientrpc.IResolveConfirmProfileResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResolveConfirmProfileResponse} ResolveConfirmProfileResponse instance
                 */
                ResolveConfirmProfileResponse.create = function create(properties) {
                    return new ResolveConfirmProfileResponse(properties);
                };

                /**
                 * Encodes the specified ResolveConfirmProfileResponse message. Does not implicitly {@link visor.clientrpc.ResolveConfirmProfileResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {visor.clientrpc.IResolveConfirmProfileResponse} message ResolveConfirmProfileResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveConfirmProfileResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ResolveConfirmProfileResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ResolveConfirmProfileResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {visor.clientrpc.IResolveConfirmProfileResponse} message ResolveConfirmProfileResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveConfirmProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResolveConfirmProfileResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResolveConfirmProfileResponse} ResolveConfirmProfileResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveConfirmProfileResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResolveConfirmProfileResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResolveConfirmProfileResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResolveConfirmProfileResponse} ResolveConfirmProfileResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveConfirmProfileResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResolveConfirmProfileResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResolveConfirmProfileResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ResolveConfirmProfileResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResolveConfirmProfileResponse} ResolveConfirmProfileResponse
                 */
                ResolveConfirmProfileResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResolveConfirmProfileResponse)
                        return object;
                    return new $root.visor.clientrpc.ResolveConfirmProfileResponse();
                };

                /**
                 * Creates a plain object from a ResolveConfirmProfileResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @static
                 * @param {visor.clientrpc.ResolveConfirmProfileResponse} message ResolveConfirmProfileResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResolveConfirmProfileResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ResolveConfirmProfileResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResolveConfirmProfileResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResolveConfirmProfileResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResolveConfirmProfileResponse;
            })();

            clientrpc.ResolveUserSubmittedAppealRequest = (function() {

                /**
                 * Properties of a ResolveUserSubmittedAppealRequest.
                 * @memberof visor.clientrpc
                 * @interface IResolveUserSubmittedAppealRequest
                 * @property {string|null} [userAppeal] ResolveUserSubmittedAppealRequest userAppeal
                 */

                /**
                 * Constructs a new ResolveUserSubmittedAppealRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResolveUserSubmittedAppealRequest.
                 * @implements IResolveUserSubmittedAppealRequest
                 * @constructor
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealRequest=} [properties] Properties to set
                 */
                function ResolveUserSubmittedAppealRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResolveUserSubmittedAppealRequest userAppeal.
                 * @member {string} userAppeal
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @instance
                 */
                ResolveUserSubmittedAppealRequest.prototype.userAppeal = "";

                /**
                 * Creates a new ResolveUserSubmittedAppealRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealRequest} ResolveUserSubmittedAppealRequest instance
                 */
                ResolveUserSubmittedAppealRequest.create = function create(properties) {
                    return new ResolveUserSubmittedAppealRequest(properties);
                };

                /**
                 * Encodes the specified ResolveUserSubmittedAppealRequest message. Does not implicitly {@link visor.clientrpc.ResolveUserSubmittedAppealRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealRequest} message ResolveUserSubmittedAppealRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveUserSubmittedAppealRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userAppeal != null && message.hasOwnProperty("userAppeal"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userAppeal);
                    return writer;
                };

                /**
                 * Encodes the specified ResolveUserSubmittedAppealRequest message, length delimited. Does not implicitly {@link visor.clientrpc.ResolveUserSubmittedAppealRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealRequest} message ResolveUserSubmittedAppealRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveUserSubmittedAppealRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResolveUserSubmittedAppealRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealRequest} ResolveUserSubmittedAppealRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveUserSubmittedAppealRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResolveUserSubmittedAppealRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.userAppeal = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResolveUserSubmittedAppealRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealRequest} ResolveUserSubmittedAppealRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveUserSubmittedAppealRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResolveUserSubmittedAppealRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResolveUserSubmittedAppealRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userAppeal != null && message.hasOwnProperty("userAppeal"))
                        if (!$util.isString(message.userAppeal))
                            return "userAppeal: string expected";
                    return null;
                };

                /**
                 * Creates a ResolveUserSubmittedAppealRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealRequest} ResolveUserSubmittedAppealRequest
                 */
                ResolveUserSubmittedAppealRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResolveUserSubmittedAppealRequest)
                        return object;
                    var message = new $root.visor.clientrpc.ResolveUserSubmittedAppealRequest();
                    if (object.userAppeal != null)
                        message.userAppeal = String(object.userAppeal);
                    return message;
                };

                /**
                 * Creates a plain object from a ResolveUserSubmittedAppealRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @static
                 * @param {visor.clientrpc.ResolveUserSubmittedAppealRequest} message ResolveUserSubmittedAppealRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResolveUserSubmittedAppealRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userAppeal = "";
                    if (message.userAppeal != null && message.hasOwnProperty("userAppeal"))
                        object.userAppeal = message.userAppeal;
                    return object;
                };

                /**
                 * Converts this ResolveUserSubmittedAppealRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResolveUserSubmittedAppealRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResolveUserSubmittedAppealRequest;
            })();

            clientrpc.ResolveUserSubmittedAppealResponse = (function() {

                /**
                 * Properties of a ResolveUserSubmittedAppealResponse.
                 * @memberof visor.clientrpc
                 * @interface IResolveUserSubmittedAppealResponse
                 */

                /**
                 * Constructs a new ResolveUserSubmittedAppealResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a ResolveUserSubmittedAppealResponse.
                 * @implements IResolveUserSubmittedAppealResponse
                 * @constructor
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealResponse=} [properties] Properties to set
                 */
                function ResolveUserSubmittedAppealResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new ResolveUserSubmittedAppealResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealResponse} ResolveUserSubmittedAppealResponse instance
                 */
                ResolveUserSubmittedAppealResponse.create = function create(properties) {
                    return new ResolveUserSubmittedAppealResponse(properties);
                };

                /**
                 * Encodes the specified ResolveUserSubmittedAppealResponse message. Does not implicitly {@link visor.clientrpc.ResolveUserSubmittedAppealResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealResponse} message ResolveUserSubmittedAppealResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveUserSubmittedAppealResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified ResolveUserSubmittedAppealResponse message, length delimited. Does not implicitly {@link visor.clientrpc.ResolveUserSubmittedAppealResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {visor.clientrpc.IResolveUserSubmittedAppealResponse} message ResolveUserSubmittedAppealResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResolveUserSubmittedAppealResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResolveUserSubmittedAppealResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealResponse} ResolveUserSubmittedAppealResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveUserSubmittedAppealResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.ResolveUserSubmittedAppealResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResolveUserSubmittedAppealResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealResponse} ResolveUserSubmittedAppealResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResolveUserSubmittedAppealResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResolveUserSubmittedAppealResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResolveUserSubmittedAppealResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a ResolveUserSubmittedAppealResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.ResolveUserSubmittedAppealResponse} ResolveUserSubmittedAppealResponse
                 */
                ResolveUserSubmittedAppealResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.ResolveUserSubmittedAppealResponse)
                        return object;
                    return new $root.visor.clientrpc.ResolveUserSubmittedAppealResponse();
                };

                /**
                 * Creates a plain object from a ResolveUserSubmittedAppealResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @static
                 * @param {visor.clientrpc.ResolveUserSubmittedAppealResponse} message ResolveUserSubmittedAppealResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResolveUserSubmittedAppealResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this ResolveUserSubmittedAppealResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.ResolveUserSubmittedAppealResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResolveUserSubmittedAppealResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResolveUserSubmittedAppealResponse;
            })();

            clientrpc.CompleteComplianceChecklistStepRequest = (function() {

                /**
                 * Properties of a CompleteComplianceChecklistStepRequest.
                 * @memberof visor.clientrpc
                 * @interface ICompleteComplianceChecklistStepRequest
                 * @property {string|null} [stepToken] CompleteComplianceChecklistStepRequest stepToken
                 * @property {string|null} [workflowToken] CompleteComplianceChecklistStepRequest workflowToken
                 */

                /**
                 * Constructs a new CompleteComplianceChecklistStepRequest.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteComplianceChecklistStepRequest.
                 * @implements ICompleteComplianceChecklistStepRequest
                 * @constructor
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepRequest=} [properties] Properties to set
                 */
                function CompleteComplianceChecklistStepRequest(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CompleteComplianceChecklistStepRequest stepToken.
                 * @member {string} stepToken
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @instance
                 */
                CompleteComplianceChecklistStepRequest.prototype.stepToken = "";

                /**
                 * CompleteComplianceChecklistStepRequest workflowToken.
                 * @member {string} workflowToken
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @instance
                 */
                CompleteComplianceChecklistStepRequest.prototype.workflowToken = "";

                /**
                 * Creates a new CompleteComplianceChecklistStepRequest instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepRequest=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepRequest} CompleteComplianceChecklistStepRequest instance
                 */
                CompleteComplianceChecklistStepRequest.create = function create(properties) {
                    return new CompleteComplianceChecklistStepRequest(properties);
                };

                /**
                 * Encodes the specified CompleteComplianceChecklistStepRequest message. Does not implicitly {@link visor.clientrpc.CompleteComplianceChecklistStepRequest.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepRequest} message CompleteComplianceChecklistStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteComplianceChecklistStepRequest.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stepToken);
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.workflowToken);
                    return writer;
                };

                /**
                 * Encodes the specified CompleteComplianceChecklistStepRequest message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteComplianceChecklistStepRequest.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepRequest} message CompleteComplianceChecklistStepRequest message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteComplianceChecklistStepRequest.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteComplianceChecklistStepRequest message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepRequest} CompleteComplianceChecklistStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteComplianceChecklistStepRequest.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteComplianceChecklistStepRequest();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.stepToken = reader.string();
                            break;
                        case 2:
                            message.workflowToken = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteComplianceChecklistStepRequest message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepRequest} CompleteComplianceChecklistStepRequest
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteComplianceChecklistStepRequest.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteComplianceChecklistStepRequest message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteComplianceChecklistStepRequest.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        if (!$util.isString(message.stepToken))
                            return "stepToken: string expected";
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        if (!$util.isString(message.workflowToken))
                            return "workflowToken: string expected";
                    return null;
                };

                /**
                 * Creates a CompleteComplianceChecklistStepRequest message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepRequest} CompleteComplianceChecklistStepRequest
                 */
                CompleteComplianceChecklistStepRequest.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteComplianceChecklistStepRequest)
                        return object;
                    var message = new $root.visor.clientrpc.CompleteComplianceChecklistStepRequest();
                    if (object.stepToken != null)
                        message.stepToken = String(object.stepToken);
                    if (object.workflowToken != null)
                        message.workflowToken = String(object.workflowToken);
                    return message;
                };

                /**
                 * Creates a plain object from a CompleteComplianceChecklistStepRequest message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @static
                 * @param {visor.clientrpc.CompleteComplianceChecklistStepRequest} message CompleteComplianceChecklistStepRequest
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteComplianceChecklistStepRequest.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stepToken = "";
                        object.workflowToken = "";
                    }
                    if (message.stepToken != null && message.hasOwnProperty("stepToken"))
                        object.stepToken = message.stepToken;
                    if (message.workflowToken != null && message.hasOwnProperty("workflowToken"))
                        object.workflowToken = message.workflowToken;
                    return object;
                };

                /**
                 * Converts this CompleteComplianceChecklistStepRequest to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepRequest
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteComplianceChecklistStepRequest.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteComplianceChecklistStepRequest;
            })();

            clientrpc.CompleteComplianceChecklistStepResponse = (function() {

                /**
                 * Properties of a CompleteComplianceChecklistStepResponse.
                 * @memberof visor.clientrpc
                 * @interface ICompleteComplianceChecklistStepResponse
                 */

                /**
                 * Constructs a new CompleteComplianceChecklistStepResponse.
                 * @memberof visor.clientrpc
                 * @classdesc Represents a CompleteComplianceChecklistStepResponse.
                 * @implements ICompleteComplianceChecklistStepResponse
                 * @constructor
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepResponse=} [properties] Properties to set
                 */
                function CompleteComplianceChecklistStepResponse(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new CompleteComplianceChecklistStepResponse instance using the specified properties.
                 * @function create
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepResponse=} [properties] Properties to set
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepResponse} CompleteComplianceChecklistStepResponse instance
                 */
                CompleteComplianceChecklistStepResponse.create = function create(properties) {
                    return new CompleteComplianceChecklistStepResponse(properties);
                };

                /**
                 * Encodes the specified CompleteComplianceChecklistStepResponse message. Does not implicitly {@link visor.clientrpc.CompleteComplianceChecklistStepResponse.verify|verify} messages.
                 * @function encode
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepResponse} message CompleteComplianceChecklistStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteComplianceChecklistStepResponse.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified CompleteComplianceChecklistStepResponse message, length delimited. Does not implicitly {@link visor.clientrpc.CompleteComplianceChecklistStepResponse.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {visor.clientrpc.ICompleteComplianceChecklistStepResponse} message CompleteComplianceChecklistStepResponse message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CompleteComplianceChecklistStepResponse.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CompleteComplianceChecklistStepResponse message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepResponse} CompleteComplianceChecklistStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteComplianceChecklistStepResponse.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.CompleteComplianceChecklistStepResponse();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CompleteComplianceChecklistStepResponse message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepResponse} CompleteComplianceChecklistStepResponse
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CompleteComplianceChecklistStepResponse.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CompleteComplianceChecklistStepResponse message.
                 * @function verify
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CompleteComplianceChecklistStepResponse.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a CompleteComplianceChecklistStepResponse message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.clientrpc.CompleteComplianceChecklistStepResponse} CompleteComplianceChecklistStepResponse
                 */
                CompleteComplianceChecklistStepResponse.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.clientrpc.CompleteComplianceChecklistStepResponse)
                        return object;
                    return new $root.visor.clientrpc.CompleteComplianceChecklistStepResponse();
                };

                /**
                 * Creates a plain object from a CompleteComplianceChecklistStepResponse message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @static
                 * @param {visor.clientrpc.CompleteComplianceChecklistStepResponse} message CompleteComplianceChecklistStepResponse
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CompleteComplianceChecklistStepResponse.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this CompleteComplianceChecklistStepResponse to JSON.
                 * @function toJSON
                 * @memberof visor.clientrpc.CompleteComplianceChecklistStepResponse
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CompleteComplianceChecklistStepResponse.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return CompleteComplianceChecklistStepResponse;
            })();

            clientrpc.stripe = (function() {

                /**
                 * Namespace stripe.
                 * @memberof visor.clientrpc
                 * @namespace
                 */
                var stripe = {};

                /**
                 * PaymentMethodCardBrand enum.
                 * @name visor.clientrpc.stripe.PaymentMethodCardBrand
                 * @enum {string}
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_INVALID=0 PAYMENT_METHOD_CARD_BRAND_INVALID value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_AMEX=1 PAYMENT_METHOD_CARD_BRAND_AMEX value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_DINERS=2 PAYMENT_METHOD_CARD_BRAND_DINERS value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_DISCOVER=3 PAYMENT_METHOD_CARD_BRAND_DISCOVER value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_JCB=4 PAYMENT_METHOD_CARD_BRAND_JCB value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_MASTERCARD=5 PAYMENT_METHOD_CARD_BRAND_MASTERCARD value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_UNIONPAY=6 PAYMENT_METHOD_CARD_BRAND_UNIONPAY value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_UNKNOWN=7 PAYMENT_METHOD_CARD_BRAND_UNKNOWN value
                 * @property {number} PAYMENT_METHOD_CARD_BRAND_VISA=8 PAYMENT_METHOD_CARD_BRAND_VISA value
                 */
                stripe.PaymentMethodCardBrand = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PAYMENT_METHOD_CARD_BRAND_INVALID"] = 0;
                    values[valuesById[1] = "PAYMENT_METHOD_CARD_BRAND_AMEX"] = 1;
                    values[valuesById[2] = "PAYMENT_METHOD_CARD_BRAND_DINERS"] = 2;
                    values[valuesById[3] = "PAYMENT_METHOD_CARD_BRAND_DISCOVER"] = 3;
                    values[valuesById[4] = "PAYMENT_METHOD_CARD_BRAND_JCB"] = 4;
                    values[valuesById[5] = "PAYMENT_METHOD_CARD_BRAND_MASTERCARD"] = 5;
                    values[valuesById[6] = "PAYMENT_METHOD_CARD_BRAND_UNIONPAY"] = 6;
                    values[valuesById[7] = "PAYMENT_METHOD_CARD_BRAND_UNKNOWN"] = 7;
                    values[valuesById[8] = "PAYMENT_METHOD_CARD_BRAND_VISA"] = 8;
                    return values;
                })();

                /**
                 * PaymentMethodCardWalletType enum.
                 * @name visor.clientrpc.stripe.PaymentMethodCardWalletType
                 * @enum {string}
                 * @property {number} PAYMENT_METHOD_CARD_WALLET_TYPE_INVALID=0 PAYMENT_METHOD_CARD_WALLET_TYPE_INVALID value
                 * @property {number} PAYMENT_METHOD_CARD_WALLET_TYPE_APPLE_PAY=1 PAYMENT_METHOD_CARD_WALLET_TYPE_APPLE_PAY value
                 * @property {number} PAYMENT_METHOD_CARD_WALLET_TYPE_GOOGLE_PAY=2 PAYMENT_METHOD_CARD_WALLET_TYPE_GOOGLE_PAY value
                 * @property {number} PAYMENT_METHOD_CARD_WALLET_TYPE_MASTERPASS=3 PAYMENT_METHOD_CARD_WALLET_TYPE_MASTERPASS value
                 * @property {number} PAYMENT_METHOD_CARD_WALLET_TYPE_SAMSUNG_PAY=4 PAYMENT_METHOD_CARD_WALLET_TYPE_SAMSUNG_PAY value
                 * @property {number} PAYMENT_METHOD_CARD_WALLET_TYPE_VISA_CHECKOUT=5 PAYMENT_METHOD_CARD_WALLET_TYPE_VISA_CHECKOUT value
                 */
                stripe.PaymentMethodCardWalletType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PAYMENT_METHOD_CARD_WALLET_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "PAYMENT_METHOD_CARD_WALLET_TYPE_APPLE_PAY"] = 1;
                    values[valuesById[2] = "PAYMENT_METHOD_CARD_WALLET_TYPE_GOOGLE_PAY"] = 2;
                    values[valuesById[3] = "PAYMENT_METHOD_CARD_WALLET_TYPE_MASTERPASS"] = 3;
                    values[valuesById[4] = "PAYMENT_METHOD_CARD_WALLET_TYPE_SAMSUNG_PAY"] = 4;
                    values[valuesById[5] = "PAYMENT_METHOD_CARD_WALLET_TYPE_VISA_CHECKOUT"] = 5;
                    return values;
                })();

                stripe.PaymentMethodCardWallet = (function() {

                    /**
                     * Properties of a PaymentMethodCardWallet.
                     * @memberof visor.clientrpc.stripe
                     * @interface IPaymentMethodCardWallet
                     * @property {string|null} [dynamicLastFour] PaymentMethodCardWallet dynamicLastFour
                     * @property {visor.clientrpc.stripe.PaymentMethodCardWalletType|null} [type] PaymentMethodCardWallet type
                     */

                    /**
                     * Constructs a new PaymentMethodCardWallet.
                     * @memberof visor.clientrpc.stripe
                     * @classdesc Represents a PaymentMethodCardWallet.
                     * @implements IPaymentMethodCardWallet
                     * @constructor
                     * @param {visor.clientrpc.stripe.IPaymentMethodCardWallet=} [properties] Properties to set
                     */
                    function PaymentMethodCardWallet(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PaymentMethodCardWallet dynamicLastFour.
                     * @member {string} dynamicLastFour
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @instance
                     */
                    PaymentMethodCardWallet.prototype.dynamicLastFour = "";

                    /**
                     * PaymentMethodCardWallet type.
                     * @member {visor.clientrpc.stripe.PaymentMethodCardWalletType} type
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @instance
                     */
                    PaymentMethodCardWallet.prototype.type = 0;

                    /**
                     * Creates a new PaymentMethodCardWallet instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {visor.clientrpc.stripe.IPaymentMethodCardWallet=} [properties] Properties to set
                     * @returns {visor.clientrpc.stripe.PaymentMethodCardWallet} PaymentMethodCardWallet instance
                     */
                    PaymentMethodCardWallet.create = function create(properties) {
                        return new PaymentMethodCardWallet(properties);
                    };

                    /**
                     * Encodes the specified PaymentMethodCardWallet message. Does not implicitly {@link visor.clientrpc.stripe.PaymentMethodCardWallet.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {visor.clientrpc.stripe.IPaymentMethodCardWallet} message PaymentMethodCardWallet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentMethodCardWallet.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dynamicLastFour != null && message.hasOwnProperty("dynamicLastFour"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.dynamicLastFour);
                        if (message.type != null && message.hasOwnProperty("type"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                        return writer;
                    };

                    /**
                     * Encodes the specified PaymentMethodCardWallet message, length delimited. Does not implicitly {@link visor.clientrpc.stripe.PaymentMethodCardWallet.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {visor.clientrpc.stripe.IPaymentMethodCardWallet} message PaymentMethodCardWallet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentMethodCardWallet.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PaymentMethodCardWallet message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.stripe.PaymentMethodCardWallet} PaymentMethodCardWallet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentMethodCardWallet.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.stripe.PaymentMethodCardWallet();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.dynamicLastFour = reader.string();
                                break;
                            case 2:
                                message.type = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PaymentMethodCardWallet message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.stripe.PaymentMethodCardWallet} PaymentMethodCardWallet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentMethodCardWallet.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PaymentMethodCardWallet message.
                     * @function verify
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PaymentMethodCardWallet.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dynamicLastFour != null && message.hasOwnProperty("dynamicLastFour"))
                            if (!$util.isString(message.dynamicLastFour))
                                return "dynamicLastFour: string expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a PaymentMethodCardWallet message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.stripe.PaymentMethodCardWallet} PaymentMethodCardWallet
                     */
                    PaymentMethodCardWallet.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.stripe.PaymentMethodCardWallet)
                            return object;
                        var message = new $root.visor.clientrpc.stripe.PaymentMethodCardWallet();
                        if (object.dynamicLastFour != null)
                            message.dynamicLastFour = String(object.dynamicLastFour);
                        switch (object.type) {
                        case "PAYMENT_METHOD_CARD_WALLET_TYPE_INVALID":
                        case 0:
                            message.type = 0;
                            break;
                        case "PAYMENT_METHOD_CARD_WALLET_TYPE_APPLE_PAY":
                        case 1:
                            message.type = 1;
                            break;
                        case "PAYMENT_METHOD_CARD_WALLET_TYPE_GOOGLE_PAY":
                        case 2:
                            message.type = 2;
                            break;
                        case "PAYMENT_METHOD_CARD_WALLET_TYPE_MASTERPASS":
                        case 3:
                            message.type = 3;
                            break;
                        case "PAYMENT_METHOD_CARD_WALLET_TYPE_SAMSUNG_PAY":
                        case 4:
                            message.type = 4;
                            break;
                        case "PAYMENT_METHOD_CARD_WALLET_TYPE_VISA_CHECKOUT":
                        case 5:
                            message.type = 5;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PaymentMethodCardWallet message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @static
                     * @param {visor.clientrpc.stripe.PaymentMethodCardWallet} message PaymentMethodCardWallet
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaymentMethodCardWallet.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.dynamicLastFour = "";
                            object.type = options.enums === String ? "PAYMENT_METHOD_CARD_WALLET_TYPE_INVALID" : 0;
                        }
                        if (message.dynamicLastFour != null && message.hasOwnProperty("dynamicLastFour"))
                            object.dynamicLastFour = message.dynamicLastFour;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.visor.clientrpc.stripe.PaymentMethodCardWalletType[message.type] : message.type;
                        return object;
                    };

                    /**
                     * Converts this PaymentMethodCardWallet to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.stripe.PaymentMethodCardWallet
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaymentMethodCardWallet.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PaymentMethodCardWallet;
                })();

                stripe.PaymentMethod = (function() {

                    /**
                     * Properties of a PaymentMethod.
                     * @memberof visor.clientrpc.stripe
                     * @interface IPaymentMethod
                     * @property {string|null} [id] PaymentMethod id
                     * @property {string|null} [title] PaymentMethod title
                     * @property {string|null} [lastFour] PaymentMethod lastFour
                     * @property {visor.clientrpc.stripe.PaymentMethodCardBrand|null} [cardBrand] PaymentMethod cardBrand
                     * @property {visor.clientrpc.stripe.IPaymentMethodCardWallet|null} [cardWallet] PaymentMethod cardWallet
                     * @property {boolean|null} [isDefault] PaymentMethod isDefault
                     * @property {number|null} [expMonth] PaymentMethod expMonth
                     * @property {number|null} [expYear] PaymentMethod expYear
                     */

                    /**
                     * Constructs a new PaymentMethod.
                     * @memberof visor.clientrpc.stripe
                     * @classdesc Represents a PaymentMethod.
                     * @implements IPaymentMethod
                     * @constructor
                     * @param {visor.clientrpc.stripe.IPaymentMethod=} [properties] Properties to set
                     */
                    function PaymentMethod(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PaymentMethod id.
                     * @member {string} id
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.id = "";

                    /**
                     * PaymentMethod title.
                     * @member {string} title
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.title = "";

                    /**
                     * PaymentMethod lastFour.
                     * @member {string} lastFour
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.lastFour = "";

                    /**
                     * PaymentMethod cardBrand.
                     * @member {visor.clientrpc.stripe.PaymentMethodCardBrand} cardBrand
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.cardBrand = 0;

                    /**
                     * PaymentMethod cardWallet.
                     * @member {visor.clientrpc.stripe.IPaymentMethodCardWallet|null|undefined} cardWallet
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.cardWallet = null;

                    /**
                     * PaymentMethod isDefault.
                     * @member {boolean} isDefault
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.isDefault = false;

                    /**
                     * PaymentMethod expMonth.
                     * @member {number} expMonth
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.expMonth = 0;

                    /**
                     * PaymentMethod expYear.
                     * @member {number} expYear
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     */
                    PaymentMethod.prototype.expYear = 0;

                    /**
                     * Creates a new PaymentMethod instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {visor.clientrpc.stripe.IPaymentMethod=} [properties] Properties to set
                     * @returns {visor.clientrpc.stripe.PaymentMethod} PaymentMethod instance
                     */
                    PaymentMethod.create = function create(properties) {
                        return new PaymentMethod(properties);
                    };

                    /**
                     * Encodes the specified PaymentMethod message. Does not implicitly {@link visor.clientrpc.stripe.PaymentMethod.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {visor.clientrpc.stripe.IPaymentMethod} message PaymentMethod message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentMethod.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                        if (message.lastFour != null && message.hasOwnProperty("lastFour"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastFour);
                        if (message.cardBrand != null && message.hasOwnProperty("cardBrand"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.cardBrand);
                        if (message.cardWallet != null && message.hasOwnProperty("cardWallet"))
                            $root.visor.clientrpc.stripe.PaymentMethodCardWallet.encode(message.cardWallet, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDefault);
                        if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.expMonth);
                        if (message.expYear != null && message.hasOwnProperty("expYear"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.expYear);
                        return writer;
                    };

                    /**
                     * Encodes the specified PaymentMethod message, length delimited. Does not implicitly {@link visor.clientrpc.stripe.PaymentMethod.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {visor.clientrpc.stripe.IPaymentMethod} message PaymentMethod message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentMethod.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PaymentMethod message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.stripe.PaymentMethod} PaymentMethod
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentMethod.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.stripe.PaymentMethod();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.title = reader.string();
                                break;
                            case 3:
                                message.lastFour = reader.string();
                                break;
                            case 4:
                                message.cardBrand = reader.int32();
                                break;
                            case 5:
                                message.cardWallet = $root.visor.clientrpc.stripe.PaymentMethodCardWallet.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.isDefault = reader.bool();
                                break;
                            case 7:
                                message.expMonth = reader.int32();
                                break;
                            case 8:
                                message.expYear = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PaymentMethod message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.stripe.PaymentMethod} PaymentMethod
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentMethod.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PaymentMethod message.
                     * @function verify
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PaymentMethod.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.lastFour != null && message.hasOwnProperty("lastFour"))
                            if (!$util.isString(message.lastFour))
                                return "lastFour: string expected";
                        if (message.cardBrand != null && message.hasOwnProperty("cardBrand"))
                            switch (message.cardBrand) {
                            default:
                                return "cardBrand: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                                break;
                            }
                        if (message.cardWallet != null && message.hasOwnProperty("cardWallet")) {
                            var error = $root.visor.clientrpc.stripe.PaymentMethodCardWallet.verify(message.cardWallet);
                            if (error)
                                return "cardWallet." + error;
                        }
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            if (typeof message.isDefault !== "boolean")
                                return "isDefault: boolean expected";
                        if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                            if (!$util.isInteger(message.expMonth))
                                return "expMonth: integer expected";
                        if (message.expYear != null && message.hasOwnProperty("expYear"))
                            if (!$util.isInteger(message.expYear))
                                return "expYear: integer expected";
                        return null;
                    };

                    /**
                     * Creates a PaymentMethod message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.stripe.PaymentMethod} PaymentMethod
                     */
                    PaymentMethod.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.stripe.PaymentMethod)
                            return object;
                        var message = new $root.visor.clientrpc.stripe.PaymentMethod();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.lastFour != null)
                            message.lastFour = String(object.lastFour);
                        switch (object.cardBrand) {
                        case "PAYMENT_METHOD_CARD_BRAND_INVALID":
                        case 0:
                            message.cardBrand = 0;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_AMEX":
                        case 1:
                            message.cardBrand = 1;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_DINERS":
                        case 2:
                            message.cardBrand = 2;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_DISCOVER":
                        case 3:
                            message.cardBrand = 3;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_JCB":
                        case 4:
                            message.cardBrand = 4;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_MASTERCARD":
                        case 5:
                            message.cardBrand = 5;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_UNIONPAY":
                        case 6:
                            message.cardBrand = 6;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_UNKNOWN":
                        case 7:
                            message.cardBrand = 7;
                            break;
                        case "PAYMENT_METHOD_CARD_BRAND_VISA":
                        case 8:
                            message.cardBrand = 8;
                            break;
                        }
                        if (object.cardWallet != null) {
                            if (typeof object.cardWallet !== "object")
                                throw TypeError(".visor.clientrpc.stripe.PaymentMethod.cardWallet: object expected");
                            message.cardWallet = $root.visor.clientrpc.stripe.PaymentMethodCardWallet.fromObject(object.cardWallet);
                        }
                        if (object.isDefault != null)
                            message.isDefault = Boolean(object.isDefault);
                        if (object.expMonth != null)
                            message.expMonth = object.expMonth | 0;
                        if (object.expYear != null)
                            message.expYear = object.expYear | 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from a PaymentMethod message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @static
                     * @param {visor.clientrpc.stripe.PaymentMethod} message PaymentMethod
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaymentMethod.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.title = "";
                            object.lastFour = "";
                            object.cardBrand = options.enums === String ? "PAYMENT_METHOD_CARD_BRAND_INVALID" : 0;
                            object.cardWallet = null;
                            object.isDefault = false;
                            object.expMonth = 0;
                            object.expYear = 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.lastFour != null && message.hasOwnProperty("lastFour"))
                            object.lastFour = message.lastFour;
                        if (message.cardBrand != null && message.hasOwnProperty("cardBrand"))
                            object.cardBrand = options.enums === String ? $root.visor.clientrpc.stripe.PaymentMethodCardBrand[message.cardBrand] : message.cardBrand;
                        if (message.cardWallet != null && message.hasOwnProperty("cardWallet"))
                            object.cardWallet = $root.visor.clientrpc.stripe.PaymentMethodCardWallet.toObject(message.cardWallet, options);
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            object.isDefault = message.isDefault;
                        if (message.expMonth != null && message.hasOwnProperty("expMonth"))
                            object.expMonth = message.expMonth;
                        if (message.expYear != null && message.hasOwnProperty("expYear"))
                            object.expYear = message.expYear;
                        return object;
                    };

                    /**
                     * Converts this PaymentMethod to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.stripe.PaymentMethod
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaymentMethod.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PaymentMethod;
                })();

                /**
                 * InvoiceStatus enum.
                 * @name visor.clientrpc.stripe.InvoiceStatus
                 * @enum {string}
                 * @property {number} INVOICE_STATUS_INVALID=0 INVOICE_STATUS_INVALID value
                 * @property {number} INVOICE_STATUS_DRAFT=1 INVOICE_STATUS_DRAFT value
                 * @property {number} INVOICE_STATUS_OPEN=2 INVOICE_STATUS_OPEN value
                 * @property {number} INVOICE_STATUS_PAID=3 INVOICE_STATUS_PAID value
                 * @property {number} INVOICE_STATUS_UNCOLLECTIBLE=4 INVOICE_STATUS_UNCOLLECTIBLE value
                 * @property {number} INVOICE_STATUS_VOID=5 INVOICE_STATUS_VOID value
                 */
                stripe.InvoiceStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "INVOICE_STATUS_INVALID"] = 0;
                    values[valuesById[1] = "INVOICE_STATUS_DRAFT"] = 1;
                    values[valuesById[2] = "INVOICE_STATUS_OPEN"] = 2;
                    values[valuesById[3] = "INVOICE_STATUS_PAID"] = 3;
                    values[valuesById[4] = "INVOICE_STATUS_UNCOLLECTIBLE"] = 4;
                    values[valuesById[5] = "INVOICE_STATUS_VOID"] = 5;
                    return values;
                })();

                stripe.Invoice = (function() {

                    /**
                     * Properties of an Invoice.
                     * @memberof visor.clientrpc.stripe
                     * @interface IInvoice
                     * @property {string|null} [id] Invoice id
                     * @property {string|null} [title] Invoice title
                     * @property {visor.clientrpc.stripe.InvoiceStatus|null} [status] Invoice status
                     * @property {visor.common.money.IAmount|null} [grandTotal] Invoice grandTotal
                     * @property {string|null} [hostedInvoiceUrl] Invoice hostedInvoiceUrl
                     * @property {google.protobuf.ITimestamp|null} [issuedAt] Invoice issuedAt
                     * @property {google.protobuf.ITimestamp|null} [dueAt] Invoice dueAt
                     * @property {google.protobuf.ITimestamp|null} [paidAt] Invoice paidAt
                     */

                    /**
                     * Constructs a new Invoice.
                     * @memberof visor.clientrpc.stripe
                     * @classdesc Represents an Invoice.
                     * @implements IInvoice
                     * @constructor
                     * @param {visor.clientrpc.stripe.IInvoice=} [properties] Properties to set
                     */
                    function Invoice(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Invoice id.
                     * @member {string} id
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.id = "";

                    /**
                     * Invoice title.
                     * @member {string} title
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.title = "";

                    /**
                     * Invoice status.
                     * @member {visor.clientrpc.stripe.InvoiceStatus} status
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.status = 0;

                    /**
                     * Invoice grandTotal.
                     * @member {visor.common.money.IAmount|null|undefined} grandTotal
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.grandTotal = null;

                    /**
                     * Invoice hostedInvoiceUrl.
                     * @member {string} hostedInvoiceUrl
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.hostedInvoiceUrl = "";

                    /**
                     * Invoice issuedAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} issuedAt
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.issuedAt = null;

                    /**
                     * Invoice dueAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} dueAt
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.dueAt = null;

                    /**
                     * Invoice paidAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} paidAt
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     */
                    Invoice.prototype.paidAt = null;

                    /**
                     * Creates a new Invoice instance using the specified properties.
                     * @function create
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {visor.clientrpc.stripe.IInvoice=} [properties] Properties to set
                     * @returns {visor.clientrpc.stripe.Invoice} Invoice instance
                     */
                    Invoice.create = function create(properties) {
                        return new Invoice(properties);
                    };

                    /**
                     * Encodes the specified Invoice message. Does not implicitly {@link visor.clientrpc.stripe.Invoice.verify|verify} messages.
                     * @function encode
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {visor.clientrpc.stripe.IInvoice} message Invoice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Invoice.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                        if (message.status != null && message.hasOwnProperty("status"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                        if (message.grandTotal != null && message.hasOwnProperty("grandTotal"))
                            $root.visor.common.money.Amount.encode(message.grandTotal, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.hostedInvoiceUrl != null && message.hasOwnProperty("hostedInvoiceUrl"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.hostedInvoiceUrl);
                        if (message.issuedAt != null && message.hasOwnProperty("issuedAt"))
                            $root.google.protobuf.Timestamp.encode(message.issuedAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.dueAt != null && message.hasOwnProperty("dueAt"))
                            $root.google.protobuf.Timestamp.encode(message.dueAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                            $root.google.protobuf.Timestamp.encode(message.paidAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Invoice message, length delimited. Does not implicitly {@link visor.clientrpc.stripe.Invoice.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {visor.clientrpc.stripe.IInvoice} message Invoice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Invoice.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Invoice message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.clientrpc.stripe.Invoice} Invoice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Invoice.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.clientrpc.stripe.Invoice();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.title = reader.string();
                                break;
                            case 3:
                                message.status = reader.int32();
                                break;
                            case 4:
                                message.grandTotal = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.hostedInvoiceUrl = reader.string();
                                break;
                            case 6:
                                message.issuedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.dueAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.paidAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Invoice message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.clientrpc.stripe.Invoice} Invoice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Invoice.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Invoice message.
                     * @function verify
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Invoice.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.grandTotal != null && message.hasOwnProperty("grandTotal")) {
                            var error = $root.visor.common.money.Amount.verify(message.grandTotal);
                            if (error)
                                return "grandTotal." + error;
                        }
                        if (message.hostedInvoiceUrl != null && message.hasOwnProperty("hostedInvoiceUrl"))
                            if (!$util.isString(message.hostedInvoiceUrl))
                                return "hostedInvoiceUrl: string expected";
                        if (message.issuedAt != null && message.hasOwnProperty("issuedAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.issuedAt);
                            if (error)
                                return "issuedAt." + error;
                        }
                        if (message.dueAt != null && message.hasOwnProperty("dueAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.dueAt);
                            if (error)
                                return "dueAt." + error;
                        }
                        if (message.paidAt != null && message.hasOwnProperty("paidAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.paidAt);
                            if (error)
                                return "paidAt." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an Invoice message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.clientrpc.stripe.Invoice} Invoice
                     */
                    Invoice.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.clientrpc.stripe.Invoice)
                            return object;
                        var message = new $root.visor.clientrpc.stripe.Invoice();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.title != null)
                            message.title = String(object.title);
                        switch (object.status) {
                        case "INVOICE_STATUS_INVALID":
                        case 0:
                            message.status = 0;
                            break;
                        case "INVOICE_STATUS_DRAFT":
                        case 1:
                            message.status = 1;
                            break;
                        case "INVOICE_STATUS_OPEN":
                        case 2:
                            message.status = 2;
                            break;
                        case "INVOICE_STATUS_PAID":
                        case 3:
                            message.status = 3;
                            break;
                        case "INVOICE_STATUS_UNCOLLECTIBLE":
                        case 4:
                            message.status = 4;
                            break;
                        case "INVOICE_STATUS_VOID":
                        case 5:
                            message.status = 5;
                            break;
                        }
                        if (object.grandTotal != null) {
                            if (typeof object.grandTotal !== "object")
                                throw TypeError(".visor.clientrpc.stripe.Invoice.grandTotal: object expected");
                            message.grandTotal = $root.visor.common.money.Amount.fromObject(object.grandTotal);
                        }
                        if (object.hostedInvoiceUrl != null)
                            message.hostedInvoiceUrl = String(object.hostedInvoiceUrl);
                        if (object.issuedAt != null)
                            if (object.issuedAt === null)
                                message.issuedAt = {};
                            else
                                message.issuedAt = {
                                    seconds: Math.floor(object.issuedAt.getTime() / 1000),
                                    nanos: object.issuedAt.getMilliseconds() * 1000000
                                };
                        if (object.dueAt != null)
                            if (object.dueAt === null)
                                message.dueAt = {};
                            else
                                message.dueAt = {
                                    seconds: Math.floor(object.dueAt.getTime() / 1000),
                                    nanos: object.dueAt.getMilliseconds() * 1000000
                                };
                        if (object.paidAt != null)
                            if (object.paidAt === null)
                                message.paidAt = {};
                            else
                                message.paidAt = {
                                    seconds: Math.floor(object.paidAt.getTime() / 1000),
                                    nanos: object.paidAt.getMilliseconds() * 1000000
                                };
                        return message;
                    };

                    /**
                     * Creates a plain object from an Invoice message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @static
                     * @param {visor.clientrpc.stripe.Invoice} message Invoice
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Invoice.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.title = "";
                            object.status = options.enums === String ? "INVOICE_STATUS_INVALID" : 0;
                            object.grandTotal = null;
                            object.hostedInvoiceUrl = "";
                            object.issuedAt = null;
                            object.dueAt = null;
                            object.paidAt = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.visor.clientrpc.stripe.InvoiceStatus[message.status] : message.status;
                        if (message.grandTotal != null && message.hasOwnProperty("grandTotal"))
                            object.grandTotal = $root.visor.common.money.Amount.toObject(message.grandTotal, options);
                        if (message.hostedInvoiceUrl != null && message.hasOwnProperty("hostedInvoiceUrl"))
                            object.hostedInvoiceUrl = message.hostedInvoiceUrl;
                        if (message.issuedAt != null && message.hasOwnProperty("issuedAt"))
                            object.issuedAt = new Date(message.issuedAt.seconds * 1000 + message.issuedAt.nanos / 1000000);
                        if (message.dueAt != null && message.hasOwnProperty("dueAt"))
                            object.dueAt = new Date(message.dueAt.seconds * 1000 + message.dueAt.nanos / 1000000);
                        if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                            object.paidAt = new Date(message.paidAt.seconds * 1000 + message.paidAt.nanos / 1000000);
                        return object;
                    };

                    /**
                     * Converts this Invoice to JSON.
                     * @function toJSON
                     * @memberof visor.clientrpc.stripe.Invoice
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Invoice.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Invoice;
                })();

                /**
                 * DeclineCode enum.
                 * @name visor.clientrpc.stripe.DeclineCode
                 * @enum {string}
                 * @property {number} DECLINE_CODE_INVALID=0 DECLINE_CODE_INVALID value
                 * @property {number} DECLINE_CODE_AUTHENTICATION_REQUIRED=1 DECLINE_CODE_AUTHENTICATION_REQUIRED value
                 * @property {number} DECLINE_CODE_APPROVE_WITH_ID=2 DECLINE_CODE_APPROVE_WITH_ID value
                 * @property {number} DECLINE_CODE_CALL_ISSUER=3 DECLINE_CODE_CALL_ISSUER value
                 * @property {number} DECLINE_CODE_CARD_NOT_SUPPORTED=4 DECLINE_CODE_CARD_NOT_SUPPORTED value
                 * @property {number} DECLINE_CODE_CARD_VELOCITY_EXCEEDED=5 DECLINE_CODE_CARD_VELOCITY_EXCEEDED value
                 * @property {number} DECLINE_CODE_CURRENCY_NOT_SUPPORTED=6 DECLINE_CODE_CURRENCY_NOT_SUPPORTED value
                 * @property {number} DECLINE_CODE_DO_NOT_HONOR=7 DECLINE_CODE_DO_NOT_HONOR value
                 * @property {number} DECLINE_CODE_DO_NOT_TRY_AGAIN=8 DECLINE_CODE_DO_NOT_TRY_AGAIN value
                 * @property {number} DECLINE_CODE_DUPLICATE_TRANSACTION=9 DECLINE_CODE_DUPLICATE_TRANSACTION value
                 * @property {number} DECLINE_CODE_EXPIRED_CARD=10 DECLINE_CODE_EXPIRED_CARD value
                 * @property {number} DECLINE_CODE_FRAUDULENT=11 DECLINE_CODE_FRAUDULENT value
                 * @property {number} DECLINE_CODE_GENERIC_DECLINE=12 DECLINE_CODE_GENERIC_DECLINE value
                 * @property {number} DECLINE_CODE_INCORRECT_NUMBER=13 DECLINE_CODE_INCORRECT_NUMBER value
                 * @property {number} DECLINE_CODE_INCORRECT_CVC=14 DECLINE_CODE_INCORRECT_CVC value
                 * @property {number} DECLINE_CODE_INCORRECT_PIN=15 DECLINE_CODE_INCORRECT_PIN value
                 * @property {number} DECLINE_CODE_INCORRECT_ZIP=16 DECLINE_CODE_INCORRECT_ZIP value
                 * @property {number} DECLINE_CODE_INSUFFICIENT_FUNDS=17 DECLINE_CODE_INSUFFICIENT_FUNDS value
                 * @property {number} DECLINE_CODE_INVALID_ACCOUNT=18 DECLINE_CODE_INVALID_ACCOUNT value
                 * @property {number} DECLINE_CODE_INVALID_AMOUNT=19 DECLINE_CODE_INVALID_AMOUNT value
                 * @property {number} DECLINE_CODE_INVALID_CVC=20 DECLINE_CODE_INVALID_CVC value
                 * @property {number} DECLINE_CODE_INVALID_EXPIRY_YEAR=21 DECLINE_CODE_INVALID_EXPIRY_YEAR value
                 * @property {number} DECLINE_CODE_INVALID_NUMBER=22 DECLINE_CODE_INVALID_NUMBER value
                 * @property {number} DECLINE_CODE_INVALID_PIN=23 DECLINE_CODE_INVALID_PIN value
                 * @property {number} DECLINE_CODE_ISSUER_NOT_AVAILABLE=24 DECLINE_CODE_ISSUER_NOT_AVAILABLE value
                 * @property {number} DECLINE_CODE_LOST_CARD=25 DECLINE_CODE_LOST_CARD value
                 * @property {number} DECLINE_CODE_MERCHANT_BLACKLIST=26 DECLINE_CODE_MERCHANT_BLACKLIST value
                 * @property {number} DECLINE_CODE_NEW_ACCOUNT_INFORMATION_AVAILABLE=27 DECLINE_CODE_NEW_ACCOUNT_INFORMATION_AVAILABLE value
                 * @property {number} DECLINE_CODE_NO_ACTION_TAKEN=28 DECLINE_CODE_NO_ACTION_TAKEN value
                 * @property {number} DECLINE_CODE_NOT_PERMITTED=29 DECLINE_CODE_NOT_PERMITTED value
                 * @property {number} DECLINE_CODE_PICKUP_CARD=30 DECLINE_CODE_PICKUP_CARD value
                 * @property {number} DECLINE_CODE_PINTRY_EXCEEDED=31 DECLINE_CODE_PINTRY_EXCEEDED value
                 * @property {number} DECLINE_CODE_PROCESSING_ERROR=32 DECLINE_CODE_PROCESSING_ERROR value
                 * @property {number} DECLINE_CODE_REENTER_TRANSACTION=33 DECLINE_CODE_REENTER_TRANSACTION value
                 * @property {number} DECLINE_CODE_RESTRICTED_CARD=34 DECLINE_CODE_RESTRICTED_CARD value
                 * @property {number} DECLINE_CODE_REVOCATION_OF_ALL_AUTHORIZATIONS=35 DECLINE_CODE_REVOCATION_OF_ALL_AUTHORIZATIONS value
                 * @property {number} DECLINE_CODE_REVOCATION_OF_AUTHORIZATION=36 DECLINE_CODE_REVOCATION_OF_AUTHORIZATION value
                 * @property {number} DECLINE_CODE_SECURITY_VIOLATION=37 DECLINE_CODE_SECURITY_VIOLATION value
                 * @property {number} DECLINE_CODE_SERVICE_NOT_ALLOWED=38 DECLINE_CODE_SERVICE_NOT_ALLOWED value
                 * @property {number} DECLINE_CODE_STOLEN_CARD=39 DECLINE_CODE_STOLEN_CARD value
                 * @property {number} DECLINE_CODE_STOP_PAYMENT_ORDER=40 DECLINE_CODE_STOP_PAYMENT_ORDER value
                 * @property {number} DECLINE_CODE_TEST_MODE_DECLINE=41 DECLINE_CODE_TEST_MODE_DECLINE value
                 * @property {number} DECLINE_CODE_TRANSACTION_NOT_ALLOWED=42 DECLINE_CODE_TRANSACTION_NOT_ALLOWED value
                 * @property {number} DECLINE_CODE_TRY_AGAIN_LATER=43 DECLINE_CODE_TRY_AGAIN_LATER value
                 * @property {number} DECLINE_CODE_WITHDRAWAL_COUNT_LIMIT_EXCEEDED=44 DECLINE_CODE_WITHDRAWAL_COUNT_LIMIT_EXCEEDED value
                 */
                stripe.DeclineCode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DECLINE_CODE_INVALID"] = 0;
                    values[valuesById[1] = "DECLINE_CODE_AUTHENTICATION_REQUIRED"] = 1;
                    values[valuesById[2] = "DECLINE_CODE_APPROVE_WITH_ID"] = 2;
                    values[valuesById[3] = "DECLINE_CODE_CALL_ISSUER"] = 3;
                    values[valuesById[4] = "DECLINE_CODE_CARD_NOT_SUPPORTED"] = 4;
                    values[valuesById[5] = "DECLINE_CODE_CARD_VELOCITY_EXCEEDED"] = 5;
                    values[valuesById[6] = "DECLINE_CODE_CURRENCY_NOT_SUPPORTED"] = 6;
                    values[valuesById[7] = "DECLINE_CODE_DO_NOT_HONOR"] = 7;
                    values[valuesById[8] = "DECLINE_CODE_DO_NOT_TRY_AGAIN"] = 8;
                    values[valuesById[9] = "DECLINE_CODE_DUPLICATE_TRANSACTION"] = 9;
                    values[valuesById[10] = "DECLINE_CODE_EXPIRED_CARD"] = 10;
                    values[valuesById[11] = "DECLINE_CODE_FRAUDULENT"] = 11;
                    values[valuesById[12] = "DECLINE_CODE_GENERIC_DECLINE"] = 12;
                    values[valuesById[13] = "DECLINE_CODE_INCORRECT_NUMBER"] = 13;
                    values[valuesById[14] = "DECLINE_CODE_INCORRECT_CVC"] = 14;
                    values[valuesById[15] = "DECLINE_CODE_INCORRECT_PIN"] = 15;
                    values[valuesById[16] = "DECLINE_CODE_INCORRECT_ZIP"] = 16;
                    values[valuesById[17] = "DECLINE_CODE_INSUFFICIENT_FUNDS"] = 17;
                    values[valuesById[18] = "DECLINE_CODE_INVALID_ACCOUNT"] = 18;
                    values[valuesById[19] = "DECLINE_CODE_INVALID_AMOUNT"] = 19;
                    values[valuesById[20] = "DECLINE_CODE_INVALID_CVC"] = 20;
                    values[valuesById[21] = "DECLINE_CODE_INVALID_EXPIRY_YEAR"] = 21;
                    values[valuesById[22] = "DECLINE_CODE_INVALID_NUMBER"] = 22;
                    values[valuesById[23] = "DECLINE_CODE_INVALID_PIN"] = 23;
                    values[valuesById[24] = "DECLINE_CODE_ISSUER_NOT_AVAILABLE"] = 24;
                    values[valuesById[25] = "DECLINE_CODE_LOST_CARD"] = 25;
                    values[valuesById[26] = "DECLINE_CODE_MERCHANT_BLACKLIST"] = 26;
                    values[valuesById[27] = "DECLINE_CODE_NEW_ACCOUNT_INFORMATION_AVAILABLE"] = 27;
                    values[valuesById[28] = "DECLINE_CODE_NO_ACTION_TAKEN"] = 28;
                    values[valuesById[29] = "DECLINE_CODE_NOT_PERMITTED"] = 29;
                    values[valuesById[30] = "DECLINE_CODE_PICKUP_CARD"] = 30;
                    values[valuesById[31] = "DECLINE_CODE_PINTRY_EXCEEDED"] = 31;
                    values[valuesById[32] = "DECLINE_CODE_PROCESSING_ERROR"] = 32;
                    values[valuesById[33] = "DECLINE_CODE_REENTER_TRANSACTION"] = 33;
                    values[valuesById[34] = "DECLINE_CODE_RESTRICTED_CARD"] = 34;
                    values[valuesById[35] = "DECLINE_CODE_REVOCATION_OF_ALL_AUTHORIZATIONS"] = 35;
                    values[valuesById[36] = "DECLINE_CODE_REVOCATION_OF_AUTHORIZATION"] = 36;
                    values[valuesById[37] = "DECLINE_CODE_SECURITY_VIOLATION"] = 37;
                    values[valuesById[38] = "DECLINE_CODE_SERVICE_NOT_ALLOWED"] = 38;
                    values[valuesById[39] = "DECLINE_CODE_STOLEN_CARD"] = 39;
                    values[valuesById[40] = "DECLINE_CODE_STOP_PAYMENT_ORDER"] = 40;
                    values[valuesById[41] = "DECLINE_CODE_TEST_MODE_DECLINE"] = 41;
                    values[valuesById[42] = "DECLINE_CODE_TRANSACTION_NOT_ALLOWED"] = 42;
                    values[valuesById[43] = "DECLINE_CODE_TRY_AGAIN_LATER"] = 43;
                    values[valuesById[44] = "DECLINE_CODE_WITHDRAWAL_COUNT_LIMIT_EXCEEDED"] = 44;
                    return values;
                })();

                return stripe;
            })();

            return clientrpc;
        })();

        visor.common = (function() {

            /**
             * Namespace common.
             * @memberof visor
             * @namespace
             */
            var common = {};

            common.encryption = (function() {

                /**
                 * Namespace encryption.
                 * @memberof visor.common
                 * @namespace
                 */
                var encryption = {};

                encryption.EncryptedItem = (function() {

                    /**
                     * Properties of an EncryptedItem.
                     * @memberof visor.common.encryption
                     * @interface IEncryptedItem
                     * @property {Uint8Array|null} [ciphertext] EncryptedItem ciphertext
                     * @property {string|null} [cryptoKeyVersion] EncryptedItem cryptoKeyVersion
                     * @property {string|null} [algorithm] EncryptedItem algorithm
                     */

                    /**
                     * Constructs a new EncryptedItem.
                     * @memberof visor.common.encryption
                     * @classdesc Represents an EncryptedItem.
                     * @implements IEncryptedItem
                     * @constructor
                     * @param {visor.common.encryption.IEncryptedItem=} [properties] Properties to set
                     */
                    function EncryptedItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * EncryptedItem ciphertext.
                     * @member {Uint8Array} ciphertext
                     * @memberof visor.common.encryption.EncryptedItem
                     * @instance
                     */
                    EncryptedItem.prototype.ciphertext = $util.newBuffer([]);

                    /**
                     * EncryptedItem cryptoKeyVersion.
                     * @member {string} cryptoKeyVersion
                     * @memberof visor.common.encryption.EncryptedItem
                     * @instance
                     */
                    EncryptedItem.prototype.cryptoKeyVersion = "";

                    /**
                     * EncryptedItem algorithm.
                     * @member {string} algorithm
                     * @memberof visor.common.encryption.EncryptedItem
                     * @instance
                     */
                    EncryptedItem.prototype.algorithm = "";

                    /**
                     * Creates a new EncryptedItem instance using the specified properties.
                     * @function create
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {visor.common.encryption.IEncryptedItem=} [properties] Properties to set
                     * @returns {visor.common.encryption.EncryptedItem} EncryptedItem instance
                     */
                    EncryptedItem.create = function create(properties) {
                        return new EncryptedItem(properties);
                    };

                    /**
                     * Encodes the specified EncryptedItem message. Does not implicitly {@link visor.common.encryption.EncryptedItem.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {visor.common.encryption.IEncryptedItem} message EncryptedItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EncryptedItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.ciphertext != null && message.hasOwnProperty("ciphertext"))
                            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.ciphertext);
                        if (message.cryptoKeyVersion != null && message.hasOwnProperty("cryptoKeyVersion"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cryptoKeyVersion);
                        if (message.algorithm != null && message.hasOwnProperty("algorithm"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.algorithm);
                        return writer;
                    };

                    /**
                     * Encodes the specified EncryptedItem message, length delimited. Does not implicitly {@link visor.common.encryption.EncryptedItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {visor.common.encryption.IEncryptedItem} message EncryptedItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EncryptedItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EncryptedItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.encryption.EncryptedItem} EncryptedItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EncryptedItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.encryption.EncryptedItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.ciphertext = reader.bytes();
                                break;
                            case 2:
                                message.cryptoKeyVersion = reader.string();
                                break;
                            case 3:
                                message.algorithm = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EncryptedItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.encryption.EncryptedItem} EncryptedItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EncryptedItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EncryptedItem message.
                     * @function verify
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EncryptedItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.ciphertext != null && message.hasOwnProperty("ciphertext"))
                            if (!(message.ciphertext && typeof message.ciphertext.length === "number" || $util.isString(message.ciphertext)))
                                return "ciphertext: buffer expected";
                        if (message.cryptoKeyVersion != null && message.hasOwnProperty("cryptoKeyVersion"))
                            if (!$util.isString(message.cryptoKeyVersion))
                                return "cryptoKeyVersion: string expected";
                        if (message.algorithm != null && message.hasOwnProperty("algorithm"))
                            if (!$util.isString(message.algorithm))
                                return "algorithm: string expected";
                        return null;
                    };

                    /**
                     * Creates an EncryptedItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.encryption.EncryptedItem} EncryptedItem
                     */
                    EncryptedItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.encryption.EncryptedItem)
                            return object;
                        var message = new $root.visor.common.encryption.EncryptedItem();
                        if (object.ciphertext != null)
                            if (typeof object.ciphertext === "string")
                                $util.base64.decode(object.ciphertext, message.ciphertext = $util.newBuffer($util.base64.length(object.ciphertext)), 0);
                            else if (object.ciphertext.length)
                                message.ciphertext = object.ciphertext;
                        if (object.cryptoKeyVersion != null)
                            message.cryptoKeyVersion = String(object.cryptoKeyVersion);
                        if (object.algorithm != null)
                            message.algorithm = String(object.algorithm);
                        return message;
                    };

                    /**
                     * Creates a plain object from an EncryptedItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.encryption.EncryptedItem
                     * @static
                     * @param {visor.common.encryption.EncryptedItem} message EncryptedItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EncryptedItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if (options.bytes === String)
                                object.ciphertext = "";
                            else {
                                object.ciphertext = [];
                                if (options.bytes !== Array)
                                    object.ciphertext = $util.newBuffer(object.ciphertext);
                            }
                            object.cryptoKeyVersion = "";
                            object.algorithm = "";
                        }
                        if (message.ciphertext != null && message.hasOwnProperty("ciphertext"))
                            object.ciphertext = options.bytes === String ? $util.base64.encode(message.ciphertext, 0, message.ciphertext.length) : options.bytes === Array ? Array.prototype.slice.call(message.ciphertext) : message.ciphertext;
                        if (message.cryptoKeyVersion != null && message.hasOwnProperty("cryptoKeyVersion"))
                            object.cryptoKeyVersion = message.cryptoKeyVersion;
                        if (message.algorithm != null && message.hasOwnProperty("algorithm"))
                            object.algorithm = message.algorithm;
                        return object;
                    };

                    /**
                     * Converts this EncryptedItem to JSON.
                     * @function toJSON
                     * @memberof visor.common.encryption.EncryptedItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EncryptedItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return EncryptedItem;
                })();

                return encryption;
            })();

            common.geo = (function() {

                /**
                 * Namespace geo.
                 * @memberof visor.common
                 * @namespace
                 */
                var geo = {};

                /**
                 * Jurisdiction enum.
                 * @name visor.common.geo.Jurisdiction
                 * @enum {string}
                 * @property {number} JURISDICTION_INVALID=0 JURISDICTION_INVALID value
                 * @property {number} JURISDICTION_FEDERAL=1 JURISDICTION_FEDERAL value
                 * @property {number} JURISDICTION_ALABAMA=2 JURISDICTION_ALABAMA value
                 * @property {number} JURISDICTION_ALASKA=3 JURISDICTION_ALASKA value
                 * @property {number} JURISDICTION_ARIZONA=4 JURISDICTION_ARIZONA value
                 * @property {number} JURISDICTION_ARKANSAS=5 JURISDICTION_ARKANSAS value
                 * @property {number} JURISDICTION_CALIFORNIA=6 JURISDICTION_CALIFORNIA value
                 * @property {number} JURISDICTION_COLORADO=7 JURISDICTION_COLORADO value
                 * @property {number} JURISDICTION_CONNECTICUT=8 JURISDICTION_CONNECTICUT value
                 * @property {number} JURISDICTION_DELAWARE=9 JURISDICTION_DELAWARE value
                 * @property {number} JURISDICTION_FLORIDA=10 JURISDICTION_FLORIDA value
                 * @property {number} JURISDICTION_GEORGIA=11 JURISDICTION_GEORGIA value
                 * @property {number} JURISDICTION_HAWAII=12 JURISDICTION_HAWAII value
                 * @property {number} JURISDICTION_IDAHO=13 JURISDICTION_IDAHO value
                 * @property {number} JURISDICTION_ILLINOIS=14 JURISDICTION_ILLINOIS value
                 * @property {number} JURISDICTION_INDIANA=15 JURISDICTION_INDIANA value
                 * @property {number} JURISDICTION_IOWA=16 JURISDICTION_IOWA value
                 * @property {number} JURISDICTION_KANSAS=17 JURISDICTION_KANSAS value
                 * @property {number} JURISDICTION_KENTUCKY=18 JURISDICTION_KENTUCKY value
                 * @property {number} JURISDICTION_LOUISIANA=19 JURISDICTION_LOUISIANA value
                 * @property {number} JURISDICTION_MAINE=20 JURISDICTION_MAINE value
                 * @property {number} JURISDICTION_MARYLAND=21 JURISDICTION_MARYLAND value
                 * @property {number} JURISDICTION_MASSACHUSETTS=22 JURISDICTION_MASSACHUSETTS value
                 * @property {number} JURISDICTION_MICHIGAN=23 JURISDICTION_MICHIGAN value
                 * @property {number} JURISDICTION_MINNESOTA=24 JURISDICTION_MINNESOTA value
                 * @property {number} JURISDICTION_MISSISSIPPI=25 JURISDICTION_MISSISSIPPI value
                 * @property {number} JURISDICTION_MISSOURI=26 JURISDICTION_MISSOURI value
                 * @property {number} JURISDICTION_MONTANA=27 JURISDICTION_MONTANA value
                 * @property {number} JURISDICTION_NEBRASKA=28 JURISDICTION_NEBRASKA value
                 * @property {number} JURISDICTION_NEVADA=29 JURISDICTION_NEVADA value
                 * @property {number} JURISDICTION_NEW_HAMPSHIRE=30 JURISDICTION_NEW_HAMPSHIRE value
                 * @property {number} JURISDICTION_NEW_JERSEY=31 JURISDICTION_NEW_JERSEY value
                 * @property {number} JURISDICTION_NEW_MEXICO=32 JURISDICTION_NEW_MEXICO value
                 * @property {number} JURISDICTION_NEW_YORK=33 JURISDICTION_NEW_YORK value
                 * @property {number} JURISDICTION_NORTH_CAROLINA=34 JURISDICTION_NORTH_CAROLINA value
                 * @property {number} JURISDICTION_NORTH_DAKOTA=35 JURISDICTION_NORTH_DAKOTA value
                 * @property {number} JURISDICTION_OHIO=36 JURISDICTION_OHIO value
                 * @property {number} JURISDICTION_OKLAHOMA=37 JURISDICTION_OKLAHOMA value
                 * @property {number} JURISDICTION_OREGON=38 JURISDICTION_OREGON value
                 * @property {number} JURISDICTION_PENNSYLVANIA=39 JURISDICTION_PENNSYLVANIA value
                 * @property {number} JURISDICTION_RHODE_ISLAND=40 JURISDICTION_RHODE_ISLAND value
                 * @property {number} JURISDICTION_SOUTH_CAROLINA=41 JURISDICTION_SOUTH_CAROLINA value
                 * @property {number} JURISDICTION_SOUTH_DAKOTA=42 JURISDICTION_SOUTH_DAKOTA value
                 * @property {number} JURISDICTION_TENNESSEE=43 JURISDICTION_TENNESSEE value
                 * @property {number} JURISDICTION_TEXAS=44 JURISDICTION_TEXAS value
                 * @property {number} JURISDICTION_UTAH=45 JURISDICTION_UTAH value
                 * @property {number} JURISDICTION_VERMONT=46 JURISDICTION_VERMONT value
                 * @property {number} JURISDICTION_VIRGINIA=47 JURISDICTION_VIRGINIA value
                 * @property {number} JURISDICTION_WASHINGTON=48 JURISDICTION_WASHINGTON value
                 * @property {number} JURISDICTION_WEST_VIRGINIA=49 JURISDICTION_WEST_VIRGINIA value
                 * @property {number} JURISDICTION_WISCONSIN=50 JURISDICTION_WISCONSIN value
                 * @property {number} JURISDICTION_WYOMING=51 JURISDICTION_WYOMING value
                 * @property {number} JURISDICTION_WASHINGTON_DC=52 JURISDICTION_WASHINGTON_DC value
                 * @property {number} JURISDICTION_PUERTO_RICO=53 JURISDICTION_PUERTO_RICO value
                 * @property {number} JURISDICTION_US_VIRGIN_ISLANDS=54 JURISDICTION_US_VIRGIN_ISLANDS value
                 * @property {number} JURISDICTION_GUAM=55 JURISDICTION_GUAM value
                 * @property {number} JURISDICTION_AMERICAN_SAMOA=56 JURISDICTION_AMERICAN_SAMOA value
                 * @property {number} JURISDICTION_NORTHERN_MARIANA_ISLANDS=57 JURISDICTION_NORTHERN_MARIANA_ISLANDS value
                 */
                geo.Jurisdiction = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "JURISDICTION_INVALID"] = 0;
                    values[valuesById[1] = "JURISDICTION_FEDERAL"] = 1;
                    values[valuesById[2] = "JURISDICTION_ALABAMA"] = 2;
                    values[valuesById[3] = "JURISDICTION_ALASKA"] = 3;
                    values[valuesById[4] = "JURISDICTION_ARIZONA"] = 4;
                    values[valuesById[5] = "JURISDICTION_ARKANSAS"] = 5;
                    values[valuesById[6] = "JURISDICTION_CALIFORNIA"] = 6;
                    values[valuesById[7] = "JURISDICTION_COLORADO"] = 7;
                    values[valuesById[8] = "JURISDICTION_CONNECTICUT"] = 8;
                    values[valuesById[9] = "JURISDICTION_DELAWARE"] = 9;
                    values[valuesById[10] = "JURISDICTION_FLORIDA"] = 10;
                    values[valuesById[11] = "JURISDICTION_GEORGIA"] = 11;
                    values[valuesById[12] = "JURISDICTION_HAWAII"] = 12;
                    values[valuesById[13] = "JURISDICTION_IDAHO"] = 13;
                    values[valuesById[14] = "JURISDICTION_ILLINOIS"] = 14;
                    values[valuesById[15] = "JURISDICTION_INDIANA"] = 15;
                    values[valuesById[16] = "JURISDICTION_IOWA"] = 16;
                    values[valuesById[17] = "JURISDICTION_KANSAS"] = 17;
                    values[valuesById[18] = "JURISDICTION_KENTUCKY"] = 18;
                    values[valuesById[19] = "JURISDICTION_LOUISIANA"] = 19;
                    values[valuesById[20] = "JURISDICTION_MAINE"] = 20;
                    values[valuesById[21] = "JURISDICTION_MARYLAND"] = 21;
                    values[valuesById[22] = "JURISDICTION_MASSACHUSETTS"] = 22;
                    values[valuesById[23] = "JURISDICTION_MICHIGAN"] = 23;
                    values[valuesById[24] = "JURISDICTION_MINNESOTA"] = 24;
                    values[valuesById[25] = "JURISDICTION_MISSISSIPPI"] = 25;
                    values[valuesById[26] = "JURISDICTION_MISSOURI"] = 26;
                    values[valuesById[27] = "JURISDICTION_MONTANA"] = 27;
                    values[valuesById[28] = "JURISDICTION_NEBRASKA"] = 28;
                    values[valuesById[29] = "JURISDICTION_NEVADA"] = 29;
                    values[valuesById[30] = "JURISDICTION_NEW_HAMPSHIRE"] = 30;
                    values[valuesById[31] = "JURISDICTION_NEW_JERSEY"] = 31;
                    values[valuesById[32] = "JURISDICTION_NEW_MEXICO"] = 32;
                    values[valuesById[33] = "JURISDICTION_NEW_YORK"] = 33;
                    values[valuesById[34] = "JURISDICTION_NORTH_CAROLINA"] = 34;
                    values[valuesById[35] = "JURISDICTION_NORTH_DAKOTA"] = 35;
                    values[valuesById[36] = "JURISDICTION_OHIO"] = 36;
                    values[valuesById[37] = "JURISDICTION_OKLAHOMA"] = 37;
                    values[valuesById[38] = "JURISDICTION_OREGON"] = 38;
                    values[valuesById[39] = "JURISDICTION_PENNSYLVANIA"] = 39;
                    values[valuesById[40] = "JURISDICTION_RHODE_ISLAND"] = 40;
                    values[valuesById[41] = "JURISDICTION_SOUTH_CAROLINA"] = 41;
                    values[valuesById[42] = "JURISDICTION_SOUTH_DAKOTA"] = 42;
                    values[valuesById[43] = "JURISDICTION_TENNESSEE"] = 43;
                    values[valuesById[44] = "JURISDICTION_TEXAS"] = 44;
                    values[valuesById[45] = "JURISDICTION_UTAH"] = 45;
                    values[valuesById[46] = "JURISDICTION_VERMONT"] = 46;
                    values[valuesById[47] = "JURISDICTION_VIRGINIA"] = 47;
                    values[valuesById[48] = "JURISDICTION_WASHINGTON"] = 48;
                    values[valuesById[49] = "JURISDICTION_WEST_VIRGINIA"] = 49;
                    values[valuesById[50] = "JURISDICTION_WISCONSIN"] = 50;
                    values[valuesById[51] = "JURISDICTION_WYOMING"] = 51;
                    values[valuesById[52] = "JURISDICTION_WASHINGTON_DC"] = 52;
                    values[valuesById[53] = "JURISDICTION_PUERTO_RICO"] = 53;
                    values[valuesById[54] = "JURISDICTION_US_VIRGIN_ISLANDS"] = 54;
                    values[valuesById[55] = "JURISDICTION_GUAM"] = 55;
                    values[valuesById[56] = "JURISDICTION_AMERICAN_SAMOA"] = 56;
                    values[valuesById[57] = "JURISDICTION_NORTHERN_MARIANA_ISLANDS"] = 57;
                    return values;
                })();

                /**
                 * Country enum.
                 * @name visor.common.geo.Country
                 * @enum {string}
                 * @property {number} COUNTRY_INVALID=0 COUNTRY_INVALID value
                 * @property {number} COUNTRY_AFGHANISTAN=1 COUNTRY_AFGHANISTAN value
                 * @property {number} COUNTRY_ALBANIA=2 COUNTRY_ALBANIA value
                 * @property {number} COUNTRY_ALGERIA=3 COUNTRY_ALGERIA value
                 * @property {number} COUNTRY_ANDORRA=4 COUNTRY_ANDORRA value
                 * @property {number} COUNTRY_ANGOLA=5 COUNTRY_ANGOLA value
                 * @property {number} COUNTRY_ANTIGUA_AND_BARBUDA=6 COUNTRY_ANTIGUA_AND_BARBUDA value
                 * @property {number} COUNTRY_ARGENTINA=7 COUNTRY_ARGENTINA value
                 * @property {number} COUNTRY_ARMENIA=8 COUNTRY_ARMENIA value
                 * @property {number} COUNTRY_AUSTRALIA=9 COUNTRY_AUSTRALIA value
                 * @property {number} COUNTRY_AUSTRIA=10 COUNTRY_AUSTRIA value
                 * @property {number} COUNTRY_AZERBAIJAN=11 COUNTRY_AZERBAIJAN value
                 * @property {number} COUNTRY_BAHAMAS=12 COUNTRY_BAHAMAS value
                 * @property {number} COUNTRY_BAHRAIN=13 COUNTRY_BAHRAIN value
                 * @property {number} COUNTRY_BANGLADESH=14 COUNTRY_BANGLADESH value
                 * @property {number} COUNTRY_BARBADOS=15 COUNTRY_BARBADOS value
                 * @property {number} COUNTRY_BELARUS=16 COUNTRY_BELARUS value
                 * @property {number} COUNTRY_BELGIUM=17 COUNTRY_BELGIUM value
                 * @property {number} COUNTRY_BELIZE=18 COUNTRY_BELIZE value
                 * @property {number} COUNTRY_BENIN=19 COUNTRY_BENIN value
                 * @property {number} COUNTRY_BHUTAN=20 COUNTRY_BHUTAN value
                 * @property {number} COUNTRY_BOLIVIA=21 COUNTRY_BOLIVIA value
                 * @property {number} COUNTRY_BOSNIA_AND_HERZEGOVINA=22 COUNTRY_BOSNIA_AND_HERZEGOVINA value
                 * @property {number} COUNTRY_BOTSWANA=23 COUNTRY_BOTSWANA value
                 * @property {number} COUNTRY_BRAZIL=24 COUNTRY_BRAZIL value
                 * @property {number} COUNTRY_BRUNEI=25 COUNTRY_BRUNEI value
                 * @property {number} COUNTRY_BULGARIA=26 COUNTRY_BULGARIA value
                 * @property {number} COUNTRY_BURKINA_FASO=27 COUNTRY_BURKINA_FASO value
                 * @property {number} COUNTRY_BURUNDI=28 COUNTRY_BURUNDI value
                 * @property {number} COUNTRY_CABO_VERDE=29 COUNTRY_CABO_VERDE value
                 * @property {number} COUNTRY_CAMBODIA=30 COUNTRY_CAMBODIA value
                 * @property {number} COUNTRY_CAMEROON=31 COUNTRY_CAMEROON value
                 * @property {number} COUNTRY_CANADA=32 COUNTRY_CANADA value
                 * @property {number} COUNTRY_CENTRAL_AFRICAN_REPUBLIC=33 COUNTRY_CENTRAL_AFRICAN_REPUBLIC value
                 * @property {number} COUNTRY_CHAD=34 COUNTRY_CHAD value
                 * @property {number} COUNTRY_CHILE=35 COUNTRY_CHILE value
                 * @property {number} COUNTRY_CHINA=36 COUNTRY_CHINA value
                 * @property {number} COUNTRY_COLOMBIA=37 COUNTRY_COLOMBIA value
                 * @property {number} COUNTRY_COMOROS=38 COUNTRY_COMOROS value
                 * @property {number} COUNTRY_DEMOCRATIC_REPUBLIC_OF_THE_CONGO=39 COUNTRY_DEMOCRATIC_REPUBLIC_OF_THE_CONGO value
                 * @property {number} COUNTRY_REPUBLIC_OF_THE_CONGO=40 COUNTRY_REPUBLIC_OF_THE_CONGO value
                 * @property {number} COUNTRY_COSTA_RICA=41 COUNTRY_COSTA_RICA value
                 * @property {number} COUNTRY_COTE_DIVOIRE=42 COUNTRY_COTE_DIVOIRE value
                 * @property {number} COUNTRY_CROATIA=43 COUNTRY_CROATIA value
                 * @property {number} COUNTRY_CUBA=44 COUNTRY_CUBA value
                 * @property {number} COUNTRY_CYPRUS=45 COUNTRY_CYPRUS value
                 * @property {number} COUNTRY_CZECH_REPUBLIC=46 COUNTRY_CZECH_REPUBLIC value
                 * @property {number} COUNTRY_DENMARK=47 COUNTRY_DENMARK value
                 * @property {number} COUNTRY_DJIBOUTI=48 COUNTRY_DJIBOUTI value
                 * @property {number} COUNTRY_DOMINICA=49 COUNTRY_DOMINICA value
                 * @property {number} COUNTRY_DOMINICAN_REPUBLIC=50 COUNTRY_DOMINICAN_REPUBLIC value
                 * @property {number} COUNTRY_ECUADOR=51 COUNTRY_ECUADOR value
                 * @property {number} COUNTRY_EGYPT=52 COUNTRY_EGYPT value
                 * @property {number} COUNTRY_EL_SALVADOR=53 COUNTRY_EL_SALVADOR value
                 * @property {number} COUNTRY_EQUATORIAL_GUINEA=54 COUNTRY_EQUATORIAL_GUINEA value
                 * @property {number} COUNTRY_ERITREA=55 COUNTRY_ERITREA value
                 * @property {number} COUNTRY_ESTONIA=56 COUNTRY_ESTONIA value
                 * @property {number} COUNTRY_ESWATINI=57 COUNTRY_ESWATINI value
                 * @property {number} COUNTRY_ETHIOPIA=58 COUNTRY_ETHIOPIA value
                 * @property {number} COUNTRY_FIJI=59 COUNTRY_FIJI value
                 * @property {number} COUNTRY_FINLAND=60 COUNTRY_FINLAND value
                 * @property {number} COUNTRY_FRANCE=61 COUNTRY_FRANCE value
                 * @property {number} COUNTRY_GABON=62 COUNTRY_GABON value
                 * @property {number} COUNTRY_GAMBIA=63 COUNTRY_GAMBIA value
                 * @property {number} COUNTRY_GEORGIA=64 COUNTRY_GEORGIA value
                 * @property {number} COUNTRY_GERMANY=65 COUNTRY_GERMANY value
                 * @property {number} COUNTRY_GHANA=66 COUNTRY_GHANA value
                 * @property {number} COUNTRY_GREECE=67 COUNTRY_GREECE value
                 * @property {number} COUNTRY_GRENADA=68 COUNTRY_GRENADA value
                 * @property {number} COUNTRY_GUATEMALA=69 COUNTRY_GUATEMALA value
                 * @property {number} COUNTRY_GUINEA=70 COUNTRY_GUINEA value
                 * @property {number} COUNTRY_GUINEA_BISSAU=71 COUNTRY_GUINEA_BISSAU value
                 * @property {number} COUNTRY_GUYANA=72 COUNTRY_GUYANA value
                 * @property {number} COUNTRY_HAITI=73 COUNTRY_HAITI value
                 * @property {number} COUNTRY_HONDURAS=74 COUNTRY_HONDURAS value
                 * @property {number} COUNTRY_HUNGARY=75 COUNTRY_HUNGARY value
                 * @property {number} COUNTRY_ICELAND=76 COUNTRY_ICELAND value
                 * @property {number} COUNTRY_INDIA=77 COUNTRY_INDIA value
                 * @property {number} COUNTRY_INDONESIA=78 COUNTRY_INDONESIA value
                 * @property {number} COUNTRY_IRAN=79 COUNTRY_IRAN value
                 * @property {number} COUNTRY_IRAQ=80 COUNTRY_IRAQ value
                 * @property {number} COUNTRY_IRELAND=81 COUNTRY_IRELAND value
                 * @property {number} COUNTRY_ISRAEL=82 COUNTRY_ISRAEL value
                 * @property {number} COUNTRY_ITALY=83 COUNTRY_ITALY value
                 * @property {number} COUNTRY_JAMAICA=84 COUNTRY_JAMAICA value
                 * @property {number} COUNTRY_JAPAN=85 COUNTRY_JAPAN value
                 * @property {number} COUNTRY_JORDAN=86 COUNTRY_JORDAN value
                 * @property {number} COUNTRY_KAZAKHSTAN=87 COUNTRY_KAZAKHSTAN value
                 * @property {number} COUNTRY_KENYA=88 COUNTRY_KENYA value
                 * @property {number} COUNTRY_KIRIBATI=89 COUNTRY_KIRIBATI value
                 * @property {number} COUNTRY_KOSOVO=90 COUNTRY_KOSOVO value
                 * @property {number} COUNTRY_KUWAIT=91 COUNTRY_KUWAIT value
                 * @property {number} COUNTRY_KYRGYZSTAN=92 COUNTRY_KYRGYZSTAN value
                 * @property {number} COUNTRY_LAOS=93 COUNTRY_LAOS value
                 * @property {number} COUNTRY_LATVIA=94 COUNTRY_LATVIA value
                 * @property {number} COUNTRY_LEBANON=95 COUNTRY_LEBANON value
                 * @property {number} COUNTRY_LESOTHO=96 COUNTRY_LESOTHO value
                 * @property {number} COUNTRY_LIBERIA=97 COUNTRY_LIBERIA value
                 * @property {number} COUNTRY_LIBYA=98 COUNTRY_LIBYA value
                 * @property {number} COUNTRY_LIECHTENSTEIN=99 COUNTRY_LIECHTENSTEIN value
                 * @property {number} COUNTRY_LITHUANIA=100 COUNTRY_LITHUANIA value
                 * @property {number} COUNTRY_LUXEMBOURG=101 COUNTRY_LUXEMBOURG value
                 * @property {number} COUNTRY_MACEDONIA=102 COUNTRY_MACEDONIA value
                 * @property {number} COUNTRY_MADAGASCAR=103 COUNTRY_MADAGASCAR value
                 * @property {number} COUNTRY_MALAWI=104 COUNTRY_MALAWI value
                 * @property {number} COUNTRY_MALAYSIA=105 COUNTRY_MALAYSIA value
                 * @property {number} COUNTRY_MALDIVES=106 COUNTRY_MALDIVES value
                 * @property {number} COUNTRY_MALI=107 COUNTRY_MALI value
                 * @property {number} COUNTRY_MALTA=108 COUNTRY_MALTA value
                 * @property {number} COUNTRY_MARSHALL_ISLANDS=109 COUNTRY_MARSHALL_ISLANDS value
                 * @property {number} COUNTRY_MAURITANIA=110 COUNTRY_MAURITANIA value
                 * @property {number} COUNTRY_MAURITIUS=111 COUNTRY_MAURITIUS value
                 * @property {number} COUNTRY_MEXICO=112 COUNTRY_MEXICO value
                 * @property {number} COUNTRY_MICRONESIA=113 COUNTRY_MICRONESIA value
                 * @property {number} COUNTRY_MOLDOVA=114 COUNTRY_MOLDOVA value
                 * @property {number} COUNTRY_MONACO=115 COUNTRY_MONACO value
                 * @property {number} COUNTRY_MONGOLIA=116 COUNTRY_MONGOLIA value
                 * @property {number} COUNTRY_MONTENEGRO=117 COUNTRY_MONTENEGRO value
                 * @property {number} COUNTRY_MOROCCO=118 COUNTRY_MOROCCO value
                 * @property {number} COUNTRY_MOZAMBIQUE=119 COUNTRY_MOZAMBIQUE value
                 * @property {number} COUNTRY_MYANMAR=120 COUNTRY_MYANMAR value
                 * @property {number} COUNTRY_NAMIBIA=121 COUNTRY_NAMIBIA value
                 * @property {number} COUNTRY_NAURU=122 COUNTRY_NAURU value
                 * @property {number} COUNTRY_NEPAL=123 COUNTRY_NEPAL value
                 * @property {number} COUNTRY_NETHERLANDS=124 COUNTRY_NETHERLANDS value
                 * @property {number} COUNTRY_NEW_ZEALAND=125 COUNTRY_NEW_ZEALAND value
                 * @property {number} COUNTRY_NICARAGUA=126 COUNTRY_NICARAGUA value
                 * @property {number} COUNTRY_NIGER=127 COUNTRY_NIGER value
                 * @property {number} COUNTRY_NIGERIA=128 COUNTRY_NIGERIA value
                 * @property {number} COUNTRY_NORTH_KOREA=129 COUNTRY_NORTH_KOREA value
                 * @property {number} COUNTRY_NORWAY=130 COUNTRY_NORWAY value
                 * @property {number} COUNTRY_OMAN=131 COUNTRY_OMAN value
                 * @property {number} COUNTRY_PAKISTAN=132 COUNTRY_PAKISTAN value
                 * @property {number} COUNTRY_PALAU=133 COUNTRY_PALAU value
                 * @property {number} COUNTRY_PALESTINE=134 COUNTRY_PALESTINE value
                 * @property {number} COUNTRY_PANAMA=135 COUNTRY_PANAMA value
                 * @property {number} COUNTRY_PAPUA_NEW_GUINEA=136 COUNTRY_PAPUA_NEW_GUINEA value
                 * @property {number} COUNTRY_PARAGUAY=137 COUNTRY_PARAGUAY value
                 * @property {number} COUNTRY_PERU=138 COUNTRY_PERU value
                 * @property {number} COUNTRY_PHILIPPINES=139 COUNTRY_PHILIPPINES value
                 * @property {number} COUNTRY_POLAND=140 COUNTRY_POLAND value
                 * @property {number} COUNTRY_PORTUGAL=141 COUNTRY_PORTUGAL value
                 * @property {number} COUNTRY_QATAR=142 COUNTRY_QATAR value
                 * @property {number} COUNTRY_ROMANIA=143 COUNTRY_ROMANIA value
                 * @property {number} COUNTRY_RUSSIA=144 COUNTRY_RUSSIA value
                 * @property {number} COUNTRY_RWANDA=145 COUNTRY_RWANDA value
                 * @property {number} COUNTRY_SAINT_KITTS_AND_NEVIS=146 COUNTRY_SAINT_KITTS_AND_NEVIS value
                 * @property {number} COUNTRY_SAINT_LUCIA=147 COUNTRY_SAINT_LUCIA value
                 * @property {number} COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES=148 COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES value
                 * @property {number} COUNTRY_SAMOA=149 COUNTRY_SAMOA value
                 * @property {number} COUNTRY_SAN_MARINO=150 COUNTRY_SAN_MARINO value
                 * @property {number} COUNTRY_SAO_TOME_AND_PRINCIPE=151 COUNTRY_SAO_TOME_AND_PRINCIPE value
                 * @property {number} COUNTRY_SAUDI_ARABIA=152 COUNTRY_SAUDI_ARABIA value
                 * @property {number} COUNTRY_SENEGAL=153 COUNTRY_SENEGAL value
                 * @property {number} COUNTRY_SERBIA=154 COUNTRY_SERBIA value
                 * @property {number} COUNTRY_SEYCHELLES=155 COUNTRY_SEYCHELLES value
                 * @property {number} COUNTRY_SIERRA_LEONE=156 COUNTRY_SIERRA_LEONE value
                 * @property {number} COUNTRY_SINGAPORE=157 COUNTRY_SINGAPORE value
                 * @property {number} COUNTRY_SLOVAKIA=158 COUNTRY_SLOVAKIA value
                 * @property {number} COUNTRY_SLOVENIA=159 COUNTRY_SLOVENIA value
                 * @property {number} COUNTRY_SOLOMON_ISLANDS=160 COUNTRY_SOLOMON_ISLANDS value
                 * @property {number} COUNTRY_SOMALIA=161 COUNTRY_SOMALIA value
                 * @property {number} COUNTRY_SOUTH_AFRICA=162 COUNTRY_SOUTH_AFRICA value
                 * @property {number} COUNTRY_SOUTH_KOREA=163 COUNTRY_SOUTH_KOREA value
                 * @property {number} COUNTRY_SOUTH_SUDAN=164 COUNTRY_SOUTH_SUDAN value
                 * @property {number} COUNTRY_SPAIN=165 COUNTRY_SPAIN value
                 * @property {number} COUNTRY_SRI_LANKA=166 COUNTRY_SRI_LANKA value
                 * @property {number} COUNTRY_SUDAN=167 COUNTRY_SUDAN value
                 * @property {number} COUNTRY_SURINAME=168 COUNTRY_SURINAME value
                 * @property {number} COUNTRY_SWAZILAND=169 COUNTRY_SWAZILAND value
                 * @property {number} COUNTRY_SWEDEN=170 COUNTRY_SWEDEN value
                 * @property {number} COUNTRY_SWITZERLAND=171 COUNTRY_SWITZERLAND value
                 * @property {number} COUNTRY_SYRIA=172 COUNTRY_SYRIA value
                 * @property {number} COUNTRY_TAIWAN=173 COUNTRY_TAIWAN value
                 * @property {number} COUNTRY_TAJIKISTAN=174 COUNTRY_TAJIKISTAN value
                 * @property {number} COUNTRY_TANZANIA=175 COUNTRY_TANZANIA value
                 * @property {number} COUNTRY_THAILAND=176 COUNTRY_THAILAND value
                 * @property {number} COUNTRY_TIMOR_LESTE=177 COUNTRY_TIMOR_LESTE value
                 * @property {number} COUNTRY_TOGO=178 COUNTRY_TOGO value
                 * @property {number} COUNTRY_TONGA=179 COUNTRY_TONGA value
                 * @property {number} COUNTRY_TRINIDAD_AND_TOBAGO=180 COUNTRY_TRINIDAD_AND_TOBAGO value
                 * @property {number} COUNTRY_TUNISIA=181 COUNTRY_TUNISIA value
                 * @property {number} COUNTRY_TURKEY=182 COUNTRY_TURKEY value
                 * @property {number} COUNTRY_TURKMENISTAN=183 COUNTRY_TURKMENISTAN value
                 * @property {number} COUNTRY_TUVALU=184 COUNTRY_TUVALU value
                 * @property {number} COUNTRY_UGANDA=185 COUNTRY_UGANDA value
                 * @property {number} COUNTRY_UKRAINE=186 COUNTRY_UKRAINE value
                 * @property {number} COUNTRY_UNITED_ARAB_EMIRATES=187 COUNTRY_UNITED_ARAB_EMIRATES value
                 * @property {number} COUNTRY_UNITED_KINGDOM=188 COUNTRY_UNITED_KINGDOM value
                 * @property {number} COUNTRY_UNITED_STATES_OF_AMERICA=189 COUNTRY_UNITED_STATES_OF_AMERICA value
                 * @property {number} COUNTRY_URUGUAY=190 COUNTRY_URUGUAY value
                 * @property {number} COUNTRY_UZBEKISTAN=191 COUNTRY_UZBEKISTAN value
                 * @property {number} COUNTRY_VANUATU=192 COUNTRY_VANUATU value
                 * @property {number} COUNTRY_VATICAN_CITY=193 COUNTRY_VATICAN_CITY value
                 * @property {number} COUNTRY_VENEZUELA=194 COUNTRY_VENEZUELA value
                 * @property {number} COUNTRY_VIETNAM=195 COUNTRY_VIETNAM value
                 * @property {number} COUNTRY_YEMEN=196 COUNTRY_YEMEN value
                 * @property {number} COUNTRY_ZAMBIA=197 COUNTRY_ZAMBIA value
                 * @property {number} COUNTRY_ZIMBABWE=198 COUNTRY_ZIMBABWE value
                 */
                geo.Country = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "COUNTRY_INVALID"] = 0;
                    values[valuesById[1] = "COUNTRY_AFGHANISTAN"] = 1;
                    values[valuesById[2] = "COUNTRY_ALBANIA"] = 2;
                    values[valuesById[3] = "COUNTRY_ALGERIA"] = 3;
                    values[valuesById[4] = "COUNTRY_ANDORRA"] = 4;
                    values[valuesById[5] = "COUNTRY_ANGOLA"] = 5;
                    values[valuesById[6] = "COUNTRY_ANTIGUA_AND_BARBUDA"] = 6;
                    values[valuesById[7] = "COUNTRY_ARGENTINA"] = 7;
                    values[valuesById[8] = "COUNTRY_ARMENIA"] = 8;
                    values[valuesById[9] = "COUNTRY_AUSTRALIA"] = 9;
                    values[valuesById[10] = "COUNTRY_AUSTRIA"] = 10;
                    values[valuesById[11] = "COUNTRY_AZERBAIJAN"] = 11;
                    values[valuesById[12] = "COUNTRY_BAHAMAS"] = 12;
                    values[valuesById[13] = "COUNTRY_BAHRAIN"] = 13;
                    values[valuesById[14] = "COUNTRY_BANGLADESH"] = 14;
                    values[valuesById[15] = "COUNTRY_BARBADOS"] = 15;
                    values[valuesById[16] = "COUNTRY_BELARUS"] = 16;
                    values[valuesById[17] = "COUNTRY_BELGIUM"] = 17;
                    values[valuesById[18] = "COUNTRY_BELIZE"] = 18;
                    values[valuesById[19] = "COUNTRY_BENIN"] = 19;
                    values[valuesById[20] = "COUNTRY_BHUTAN"] = 20;
                    values[valuesById[21] = "COUNTRY_BOLIVIA"] = 21;
                    values[valuesById[22] = "COUNTRY_BOSNIA_AND_HERZEGOVINA"] = 22;
                    values[valuesById[23] = "COUNTRY_BOTSWANA"] = 23;
                    values[valuesById[24] = "COUNTRY_BRAZIL"] = 24;
                    values[valuesById[25] = "COUNTRY_BRUNEI"] = 25;
                    values[valuesById[26] = "COUNTRY_BULGARIA"] = 26;
                    values[valuesById[27] = "COUNTRY_BURKINA_FASO"] = 27;
                    values[valuesById[28] = "COUNTRY_BURUNDI"] = 28;
                    values[valuesById[29] = "COUNTRY_CABO_VERDE"] = 29;
                    values[valuesById[30] = "COUNTRY_CAMBODIA"] = 30;
                    values[valuesById[31] = "COUNTRY_CAMEROON"] = 31;
                    values[valuesById[32] = "COUNTRY_CANADA"] = 32;
                    values[valuesById[33] = "COUNTRY_CENTRAL_AFRICAN_REPUBLIC"] = 33;
                    values[valuesById[34] = "COUNTRY_CHAD"] = 34;
                    values[valuesById[35] = "COUNTRY_CHILE"] = 35;
                    values[valuesById[36] = "COUNTRY_CHINA"] = 36;
                    values[valuesById[37] = "COUNTRY_COLOMBIA"] = 37;
                    values[valuesById[38] = "COUNTRY_COMOROS"] = 38;
                    values[valuesById[39] = "COUNTRY_DEMOCRATIC_REPUBLIC_OF_THE_CONGO"] = 39;
                    values[valuesById[40] = "COUNTRY_REPUBLIC_OF_THE_CONGO"] = 40;
                    values[valuesById[41] = "COUNTRY_COSTA_RICA"] = 41;
                    values[valuesById[42] = "COUNTRY_COTE_DIVOIRE"] = 42;
                    values[valuesById[43] = "COUNTRY_CROATIA"] = 43;
                    values[valuesById[44] = "COUNTRY_CUBA"] = 44;
                    values[valuesById[45] = "COUNTRY_CYPRUS"] = 45;
                    values[valuesById[46] = "COUNTRY_CZECH_REPUBLIC"] = 46;
                    values[valuesById[47] = "COUNTRY_DENMARK"] = 47;
                    values[valuesById[48] = "COUNTRY_DJIBOUTI"] = 48;
                    values[valuesById[49] = "COUNTRY_DOMINICA"] = 49;
                    values[valuesById[50] = "COUNTRY_DOMINICAN_REPUBLIC"] = 50;
                    values[valuesById[51] = "COUNTRY_ECUADOR"] = 51;
                    values[valuesById[52] = "COUNTRY_EGYPT"] = 52;
                    values[valuesById[53] = "COUNTRY_EL_SALVADOR"] = 53;
                    values[valuesById[54] = "COUNTRY_EQUATORIAL_GUINEA"] = 54;
                    values[valuesById[55] = "COUNTRY_ERITREA"] = 55;
                    values[valuesById[56] = "COUNTRY_ESTONIA"] = 56;
                    values[valuesById[57] = "COUNTRY_ESWATINI"] = 57;
                    values[valuesById[58] = "COUNTRY_ETHIOPIA"] = 58;
                    values[valuesById[59] = "COUNTRY_FIJI"] = 59;
                    values[valuesById[60] = "COUNTRY_FINLAND"] = 60;
                    values[valuesById[61] = "COUNTRY_FRANCE"] = 61;
                    values[valuesById[62] = "COUNTRY_GABON"] = 62;
                    values[valuesById[63] = "COUNTRY_GAMBIA"] = 63;
                    values[valuesById[64] = "COUNTRY_GEORGIA"] = 64;
                    values[valuesById[65] = "COUNTRY_GERMANY"] = 65;
                    values[valuesById[66] = "COUNTRY_GHANA"] = 66;
                    values[valuesById[67] = "COUNTRY_GREECE"] = 67;
                    values[valuesById[68] = "COUNTRY_GRENADA"] = 68;
                    values[valuesById[69] = "COUNTRY_GUATEMALA"] = 69;
                    values[valuesById[70] = "COUNTRY_GUINEA"] = 70;
                    values[valuesById[71] = "COUNTRY_GUINEA_BISSAU"] = 71;
                    values[valuesById[72] = "COUNTRY_GUYANA"] = 72;
                    values[valuesById[73] = "COUNTRY_HAITI"] = 73;
                    values[valuesById[74] = "COUNTRY_HONDURAS"] = 74;
                    values[valuesById[75] = "COUNTRY_HUNGARY"] = 75;
                    values[valuesById[76] = "COUNTRY_ICELAND"] = 76;
                    values[valuesById[77] = "COUNTRY_INDIA"] = 77;
                    values[valuesById[78] = "COUNTRY_INDONESIA"] = 78;
                    values[valuesById[79] = "COUNTRY_IRAN"] = 79;
                    values[valuesById[80] = "COUNTRY_IRAQ"] = 80;
                    values[valuesById[81] = "COUNTRY_IRELAND"] = 81;
                    values[valuesById[82] = "COUNTRY_ISRAEL"] = 82;
                    values[valuesById[83] = "COUNTRY_ITALY"] = 83;
                    values[valuesById[84] = "COUNTRY_JAMAICA"] = 84;
                    values[valuesById[85] = "COUNTRY_JAPAN"] = 85;
                    values[valuesById[86] = "COUNTRY_JORDAN"] = 86;
                    values[valuesById[87] = "COUNTRY_KAZAKHSTAN"] = 87;
                    values[valuesById[88] = "COUNTRY_KENYA"] = 88;
                    values[valuesById[89] = "COUNTRY_KIRIBATI"] = 89;
                    values[valuesById[90] = "COUNTRY_KOSOVO"] = 90;
                    values[valuesById[91] = "COUNTRY_KUWAIT"] = 91;
                    values[valuesById[92] = "COUNTRY_KYRGYZSTAN"] = 92;
                    values[valuesById[93] = "COUNTRY_LAOS"] = 93;
                    values[valuesById[94] = "COUNTRY_LATVIA"] = 94;
                    values[valuesById[95] = "COUNTRY_LEBANON"] = 95;
                    values[valuesById[96] = "COUNTRY_LESOTHO"] = 96;
                    values[valuesById[97] = "COUNTRY_LIBERIA"] = 97;
                    values[valuesById[98] = "COUNTRY_LIBYA"] = 98;
                    values[valuesById[99] = "COUNTRY_LIECHTENSTEIN"] = 99;
                    values[valuesById[100] = "COUNTRY_LITHUANIA"] = 100;
                    values[valuesById[101] = "COUNTRY_LUXEMBOURG"] = 101;
                    values[valuesById[102] = "COUNTRY_MACEDONIA"] = 102;
                    values[valuesById[103] = "COUNTRY_MADAGASCAR"] = 103;
                    values[valuesById[104] = "COUNTRY_MALAWI"] = 104;
                    values[valuesById[105] = "COUNTRY_MALAYSIA"] = 105;
                    values[valuesById[106] = "COUNTRY_MALDIVES"] = 106;
                    values[valuesById[107] = "COUNTRY_MALI"] = 107;
                    values[valuesById[108] = "COUNTRY_MALTA"] = 108;
                    values[valuesById[109] = "COUNTRY_MARSHALL_ISLANDS"] = 109;
                    values[valuesById[110] = "COUNTRY_MAURITANIA"] = 110;
                    values[valuesById[111] = "COUNTRY_MAURITIUS"] = 111;
                    values[valuesById[112] = "COUNTRY_MEXICO"] = 112;
                    values[valuesById[113] = "COUNTRY_MICRONESIA"] = 113;
                    values[valuesById[114] = "COUNTRY_MOLDOVA"] = 114;
                    values[valuesById[115] = "COUNTRY_MONACO"] = 115;
                    values[valuesById[116] = "COUNTRY_MONGOLIA"] = 116;
                    values[valuesById[117] = "COUNTRY_MONTENEGRO"] = 117;
                    values[valuesById[118] = "COUNTRY_MOROCCO"] = 118;
                    values[valuesById[119] = "COUNTRY_MOZAMBIQUE"] = 119;
                    values[valuesById[120] = "COUNTRY_MYANMAR"] = 120;
                    values[valuesById[121] = "COUNTRY_NAMIBIA"] = 121;
                    values[valuesById[122] = "COUNTRY_NAURU"] = 122;
                    values[valuesById[123] = "COUNTRY_NEPAL"] = 123;
                    values[valuesById[124] = "COUNTRY_NETHERLANDS"] = 124;
                    values[valuesById[125] = "COUNTRY_NEW_ZEALAND"] = 125;
                    values[valuesById[126] = "COUNTRY_NICARAGUA"] = 126;
                    values[valuesById[127] = "COUNTRY_NIGER"] = 127;
                    values[valuesById[128] = "COUNTRY_NIGERIA"] = 128;
                    values[valuesById[129] = "COUNTRY_NORTH_KOREA"] = 129;
                    values[valuesById[130] = "COUNTRY_NORWAY"] = 130;
                    values[valuesById[131] = "COUNTRY_OMAN"] = 131;
                    values[valuesById[132] = "COUNTRY_PAKISTAN"] = 132;
                    values[valuesById[133] = "COUNTRY_PALAU"] = 133;
                    values[valuesById[134] = "COUNTRY_PALESTINE"] = 134;
                    values[valuesById[135] = "COUNTRY_PANAMA"] = 135;
                    values[valuesById[136] = "COUNTRY_PAPUA_NEW_GUINEA"] = 136;
                    values[valuesById[137] = "COUNTRY_PARAGUAY"] = 137;
                    values[valuesById[138] = "COUNTRY_PERU"] = 138;
                    values[valuesById[139] = "COUNTRY_PHILIPPINES"] = 139;
                    values[valuesById[140] = "COUNTRY_POLAND"] = 140;
                    values[valuesById[141] = "COUNTRY_PORTUGAL"] = 141;
                    values[valuesById[142] = "COUNTRY_QATAR"] = 142;
                    values[valuesById[143] = "COUNTRY_ROMANIA"] = 143;
                    values[valuesById[144] = "COUNTRY_RUSSIA"] = 144;
                    values[valuesById[145] = "COUNTRY_RWANDA"] = 145;
                    values[valuesById[146] = "COUNTRY_SAINT_KITTS_AND_NEVIS"] = 146;
                    values[valuesById[147] = "COUNTRY_SAINT_LUCIA"] = 147;
                    values[valuesById[148] = "COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES"] = 148;
                    values[valuesById[149] = "COUNTRY_SAMOA"] = 149;
                    values[valuesById[150] = "COUNTRY_SAN_MARINO"] = 150;
                    values[valuesById[151] = "COUNTRY_SAO_TOME_AND_PRINCIPE"] = 151;
                    values[valuesById[152] = "COUNTRY_SAUDI_ARABIA"] = 152;
                    values[valuesById[153] = "COUNTRY_SENEGAL"] = 153;
                    values[valuesById[154] = "COUNTRY_SERBIA"] = 154;
                    values[valuesById[155] = "COUNTRY_SEYCHELLES"] = 155;
                    values[valuesById[156] = "COUNTRY_SIERRA_LEONE"] = 156;
                    values[valuesById[157] = "COUNTRY_SINGAPORE"] = 157;
                    values[valuesById[158] = "COUNTRY_SLOVAKIA"] = 158;
                    values[valuesById[159] = "COUNTRY_SLOVENIA"] = 159;
                    values[valuesById[160] = "COUNTRY_SOLOMON_ISLANDS"] = 160;
                    values[valuesById[161] = "COUNTRY_SOMALIA"] = 161;
                    values[valuesById[162] = "COUNTRY_SOUTH_AFRICA"] = 162;
                    values[valuesById[163] = "COUNTRY_SOUTH_KOREA"] = 163;
                    values[valuesById[164] = "COUNTRY_SOUTH_SUDAN"] = 164;
                    values[valuesById[165] = "COUNTRY_SPAIN"] = 165;
                    values[valuesById[166] = "COUNTRY_SRI_LANKA"] = 166;
                    values[valuesById[167] = "COUNTRY_SUDAN"] = 167;
                    values[valuesById[168] = "COUNTRY_SURINAME"] = 168;
                    values[valuesById[169] = "COUNTRY_SWAZILAND"] = 169;
                    values[valuesById[170] = "COUNTRY_SWEDEN"] = 170;
                    values[valuesById[171] = "COUNTRY_SWITZERLAND"] = 171;
                    values[valuesById[172] = "COUNTRY_SYRIA"] = 172;
                    values[valuesById[173] = "COUNTRY_TAIWAN"] = 173;
                    values[valuesById[174] = "COUNTRY_TAJIKISTAN"] = 174;
                    values[valuesById[175] = "COUNTRY_TANZANIA"] = 175;
                    values[valuesById[176] = "COUNTRY_THAILAND"] = 176;
                    values[valuesById[177] = "COUNTRY_TIMOR_LESTE"] = 177;
                    values[valuesById[178] = "COUNTRY_TOGO"] = 178;
                    values[valuesById[179] = "COUNTRY_TONGA"] = 179;
                    values[valuesById[180] = "COUNTRY_TRINIDAD_AND_TOBAGO"] = 180;
                    values[valuesById[181] = "COUNTRY_TUNISIA"] = 181;
                    values[valuesById[182] = "COUNTRY_TURKEY"] = 182;
                    values[valuesById[183] = "COUNTRY_TURKMENISTAN"] = 183;
                    values[valuesById[184] = "COUNTRY_TUVALU"] = 184;
                    values[valuesById[185] = "COUNTRY_UGANDA"] = 185;
                    values[valuesById[186] = "COUNTRY_UKRAINE"] = 186;
                    values[valuesById[187] = "COUNTRY_UNITED_ARAB_EMIRATES"] = 187;
                    values[valuesById[188] = "COUNTRY_UNITED_KINGDOM"] = 188;
                    values[valuesById[189] = "COUNTRY_UNITED_STATES_OF_AMERICA"] = 189;
                    values[valuesById[190] = "COUNTRY_URUGUAY"] = 190;
                    values[valuesById[191] = "COUNTRY_UZBEKISTAN"] = 191;
                    values[valuesById[192] = "COUNTRY_VANUATU"] = 192;
                    values[valuesById[193] = "COUNTRY_VATICAN_CITY"] = 193;
                    values[valuesById[194] = "COUNTRY_VENEZUELA"] = 194;
                    values[valuesById[195] = "COUNTRY_VIETNAM"] = 195;
                    values[valuesById[196] = "COUNTRY_YEMEN"] = 196;
                    values[valuesById[197] = "COUNTRY_ZAMBIA"] = 197;
                    values[valuesById[198] = "COUNTRY_ZIMBABWE"] = 198;
                    return values;
                })();

                return geo;
            })();

            common.jobtracker = (function() {

                /**
                 * Namespace jobtracker.
                 * @memberof visor.common
                 * @namespace
                 */
                var jobtracker = {};

                /**
                 * Result enum.
                 * @name visor.common.jobtracker.Result
                 * @enum {string}
                 * @property {number} RESULT_INVALID=0 RESULT_INVALID value
                 * @property {number} RESULT_SUCCESS=1 RESULT_SUCCESS value
                 * @property {number} RESULT_FAILURE=2 RESULT_FAILURE value
                 * @property {number} RESULT_PENDING=3 RESULT_PENDING value
                 */
                jobtracker.Result = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "RESULT_INVALID"] = 0;
                    values[valuesById[1] = "RESULT_SUCCESS"] = 1;
                    values[valuesById[2] = "RESULT_FAILURE"] = 2;
                    values[valuesById[3] = "RESULT_PENDING"] = 3;
                    return values;
                })();

                jobtracker.JobTracker = (function() {

                    /**
                     * Properties of a JobTracker.
                     * @memberof visor.common.jobtracker
                     * @interface IJobTracker
                     * @property {Array.<visor.common.jobtracker.JobTracker.ITask>|null} [tasks] JobTracker tasks
                     * @property {visor.common.jobtracker.Result|null} [projection] JobTracker projection
                     */

                    /**
                     * Constructs a new JobTracker.
                     * @memberof visor.common.jobtracker
                     * @classdesc Represents a JobTracker.
                     * @implements IJobTracker
                     * @constructor
                     * @param {visor.common.jobtracker.IJobTracker=} [properties] Properties to set
                     */
                    function JobTracker(properties) {
                        this.tasks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * JobTracker tasks.
                     * @member {Array.<visor.common.jobtracker.JobTracker.ITask>} tasks
                     * @memberof visor.common.jobtracker.JobTracker
                     * @instance
                     */
                    JobTracker.prototype.tasks = $util.emptyArray;

                    /**
                     * JobTracker projection.
                     * @member {visor.common.jobtracker.Result} projection
                     * @memberof visor.common.jobtracker.JobTracker
                     * @instance
                     */
                    JobTracker.prototype.projection = 0;

                    /**
                     * Creates a new JobTracker instance using the specified properties.
                     * @function create
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {visor.common.jobtracker.IJobTracker=} [properties] Properties to set
                     * @returns {visor.common.jobtracker.JobTracker} JobTracker instance
                     */
                    JobTracker.create = function create(properties) {
                        return new JobTracker(properties);
                    };

                    /**
                     * Encodes the specified JobTracker message. Does not implicitly {@link visor.common.jobtracker.JobTracker.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {visor.common.jobtracker.IJobTracker} message JobTracker message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    JobTracker.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.tasks != null && message.tasks.length)
                            for (var i = 0; i < message.tasks.length; ++i)
                                $root.visor.common.jobtracker.JobTracker.Task.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.projection != null && message.hasOwnProperty("projection"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.projection);
                        return writer;
                    };

                    /**
                     * Encodes the specified JobTracker message, length delimited. Does not implicitly {@link visor.common.jobtracker.JobTracker.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {visor.common.jobtracker.IJobTracker} message JobTracker message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    JobTracker.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a JobTracker message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.jobtracker.JobTracker} JobTracker
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    JobTracker.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.jobtracker.JobTracker();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.tasks && message.tasks.length))
                                    message.tasks = [];
                                message.tasks.push($root.visor.common.jobtracker.JobTracker.Task.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.projection = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a JobTracker message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.jobtracker.JobTracker} JobTracker
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    JobTracker.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a JobTracker message.
                     * @function verify
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    JobTracker.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.tasks != null && message.hasOwnProperty("tasks")) {
                            if (!Array.isArray(message.tasks))
                                return "tasks: array expected";
                            for (var i = 0; i < message.tasks.length; ++i) {
                                var error = $root.visor.common.jobtracker.JobTracker.Task.verify(message.tasks[i]);
                                if (error)
                                    return "tasks." + error;
                            }
                        }
                        if (message.projection != null && message.hasOwnProperty("projection"))
                            switch (message.projection) {
                            default:
                                return "projection: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a JobTracker message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.jobtracker.JobTracker} JobTracker
                     */
                    JobTracker.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.jobtracker.JobTracker)
                            return object;
                        var message = new $root.visor.common.jobtracker.JobTracker();
                        if (object.tasks) {
                            if (!Array.isArray(object.tasks))
                                throw TypeError(".visor.common.jobtracker.JobTracker.tasks: array expected");
                            message.tasks = [];
                            for (var i = 0; i < object.tasks.length; ++i) {
                                if (typeof object.tasks[i] !== "object")
                                    throw TypeError(".visor.common.jobtracker.JobTracker.tasks: object expected");
                                message.tasks[i] = $root.visor.common.jobtracker.JobTracker.Task.fromObject(object.tasks[i]);
                            }
                        }
                        switch (object.projection) {
                        case "RESULT_INVALID":
                        case 0:
                            message.projection = 0;
                            break;
                        case "RESULT_SUCCESS":
                        case 1:
                            message.projection = 1;
                            break;
                        case "RESULT_FAILURE":
                        case 2:
                            message.projection = 2;
                            break;
                        case "RESULT_PENDING":
                        case 3:
                            message.projection = 3;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a JobTracker message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.jobtracker.JobTracker
                     * @static
                     * @param {visor.common.jobtracker.JobTracker} message JobTracker
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    JobTracker.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.tasks = [];
                        if (options.defaults)
                            object.projection = options.enums === String ? "RESULT_INVALID" : 0;
                        if (message.tasks && message.tasks.length) {
                            object.tasks = [];
                            for (var j = 0; j < message.tasks.length; ++j)
                                object.tasks[j] = $root.visor.common.jobtracker.JobTracker.Task.toObject(message.tasks[j], options);
                        }
                        if (message.projection != null && message.hasOwnProperty("projection"))
                            object.projection = options.enums === String ? $root.visor.common.jobtracker.Result[message.projection] : message.projection;
                        return object;
                    };

                    /**
                     * Converts this JobTracker to JSON.
                     * @function toJSON
                     * @memberof visor.common.jobtracker.JobTracker
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    JobTracker.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    JobTracker.Task = (function() {

                        /**
                         * Properties of a Task.
                         * @memberof visor.common.jobtracker.JobTracker
                         * @interface ITask
                         * @property {string|null} [identifier] Task identifier
                         * @property {Array.<visor.common.jobtracker.JobTracker.IExpectedObject>|null} [expectedObjects] Task expectedObjects
                         * @property {visor.common.jobtracker.Result|null} [result] Task result
                         */

                        /**
                         * Constructs a new Task.
                         * @memberof visor.common.jobtracker.JobTracker
                         * @classdesc Represents a Task.
                         * @implements ITask
                         * @constructor
                         * @param {visor.common.jobtracker.JobTracker.ITask=} [properties] Properties to set
                         */
                        function Task(properties) {
                            this.expectedObjects = [];
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Task identifier.
                         * @member {string} identifier
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @instance
                         */
                        Task.prototype.identifier = "";

                        /**
                         * Task expectedObjects.
                         * @member {Array.<visor.common.jobtracker.JobTracker.IExpectedObject>} expectedObjects
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @instance
                         */
                        Task.prototype.expectedObjects = $util.emptyArray;

                        /**
                         * Task result.
                         * @member {visor.common.jobtracker.Result} result
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @instance
                         */
                        Task.prototype.result = 0;

                        /**
                         * Creates a new Task instance using the specified properties.
                         * @function create
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.ITask=} [properties] Properties to set
                         * @returns {visor.common.jobtracker.JobTracker.Task} Task instance
                         */
                        Task.create = function create(properties) {
                            return new Task(properties);
                        };

                        /**
                         * Encodes the specified Task message. Does not implicitly {@link visor.common.jobtracker.JobTracker.Task.verify|verify} messages.
                         * @function encode
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.ITask} message Task message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Task.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.identifier != null && message.hasOwnProperty("identifier"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.identifier);
                            if (message.expectedObjects != null && message.expectedObjects.length)
                                for (var i = 0; i < message.expectedObjects.length; ++i)
                                    $root.visor.common.jobtracker.JobTracker.ExpectedObject.encode(message.expectedObjects[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.result != null && message.hasOwnProperty("result"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.result);
                            return writer;
                        };

                        /**
                         * Encodes the specified Task message, length delimited. Does not implicitly {@link visor.common.jobtracker.JobTracker.Task.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.ITask} message Task message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Task.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a Task message from the specified reader or buffer.
                         * @function decode
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {visor.common.jobtracker.JobTracker.Task} Task
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Task.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.jobtracker.JobTracker.Task();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.identifier = reader.string();
                                    break;
                                case 2:
                                    if (!(message.expectedObjects && message.expectedObjects.length))
                                        message.expectedObjects = [];
                                    message.expectedObjects.push($root.visor.common.jobtracker.JobTracker.ExpectedObject.decode(reader, reader.uint32()));
                                    break;
                                case 3:
                                    message.result = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a Task message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {visor.common.jobtracker.JobTracker.Task} Task
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Task.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a Task message.
                         * @function verify
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Task.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.identifier != null && message.hasOwnProperty("identifier"))
                                if (!$util.isString(message.identifier))
                                    return "identifier: string expected";
                            if (message.expectedObjects != null && message.hasOwnProperty("expectedObjects")) {
                                if (!Array.isArray(message.expectedObjects))
                                    return "expectedObjects: array expected";
                                for (var i = 0; i < message.expectedObjects.length; ++i) {
                                    var error = $root.visor.common.jobtracker.JobTracker.ExpectedObject.verify(message.expectedObjects[i]);
                                    if (error)
                                        return "expectedObjects." + error;
                                }
                            }
                            if (message.result != null && message.hasOwnProperty("result"))
                                switch (message.result) {
                                default:
                                    return "result: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a Task message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {visor.common.jobtracker.JobTracker.Task} Task
                         */
                        Task.fromObject = function fromObject(object) {
                            if (object instanceof $root.visor.common.jobtracker.JobTracker.Task)
                                return object;
                            var message = new $root.visor.common.jobtracker.JobTracker.Task();
                            if (object.identifier != null)
                                message.identifier = String(object.identifier);
                            if (object.expectedObjects) {
                                if (!Array.isArray(object.expectedObjects))
                                    throw TypeError(".visor.common.jobtracker.JobTracker.Task.expectedObjects: array expected");
                                message.expectedObjects = [];
                                for (var i = 0; i < object.expectedObjects.length; ++i) {
                                    if (typeof object.expectedObjects[i] !== "object")
                                        throw TypeError(".visor.common.jobtracker.JobTracker.Task.expectedObjects: object expected");
                                    message.expectedObjects[i] = $root.visor.common.jobtracker.JobTracker.ExpectedObject.fromObject(object.expectedObjects[i]);
                                }
                            }
                            switch (object.result) {
                            case "RESULT_INVALID":
                            case 0:
                                message.result = 0;
                                break;
                            case "RESULT_SUCCESS":
                            case 1:
                                message.result = 1;
                                break;
                            case "RESULT_FAILURE":
                            case 2:
                                message.result = 2;
                                break;
                            case "RESULT_PENDING":
                            case 3:
                                message.result = 3;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a Task message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.Task} message Task
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Task.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.arrays || options.defaults)
                                object.expectedObjects = [];
                            if (options.defaults) {
                                object.identifier = "";
                                object.result = options.enums === String ? "RESULT_INVALID" : 0;
                            }
                            if (message.identifier != null && message.hasOwnProperty("identifier"))
                                object.identifier = message.identifier;
                            if (message.expectedObjects && message.expectedObjects.length) {
                                object.expectedObjects = [];
                                for (var j = 0; j < message.expectedObjects.length; ++j)
                                    object.expectedObjects[j] = $root.visor.common.jobtracker.JobTracker.ExpectedObject.toObject(message.expectedObjects[j], options);
                            }
                            if (message.result != null && message.hasOwnProperty("result"))
                                object.result = options.enums === String ? $root.visor.common.jobtracker.Result[message.result] : message.result;
                            return object;
                        };

                        /**
                         * Converts this Task to JSON.
                         * @function toJSON
                         * @memberof visor.common.jobtracker.JobTracker.Task
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Task.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Task;
                    })();

                    JobTracker.ExpectedObject = (function() {

                        /**
                         * Properties of an ExpectedObject.
                         * @memberof visor.common.jobtracker.JobTracker
                         * @interface IExpectedObject
                         * @property {string|null} [identifier] ExpectedObject identifier
                         * @property {visor.common.jobtracker.Result|null} [result] ExpectedObject result
                         */

                        /**
                         * Constructs a new ExpectedObject.
                         * @memberof visor.common.jobtracker.JobTracker
                         * @classdesc Represents an ExpectedObject.
                         * @implements IExpectedObject
                         * @constructor
                         * @param {visor.common.jobtracker.JobTracker.IExpectedObject=} [properties] Properties to set
                         */
                        function ExpectedObject(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ExpectedObject identifier.
                         * @member {string} identifier
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @instance
                         */
                        ExpectedObject.prototype.identifier = "";

                        /**
                         * ExpectedObject result.
                         * @member {visor.common.jobtracker.Result} result
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @instance
                         */
                        ExpectedObject.prototype.result = 0;

                        /**
                         * Creates a new ExpectedObject instance using the specified properties.
                         * @function create
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.IExpectedObject=} [properties] Properties to set
                         * @returns {visor.common.jobtracker.JobTracker.ExpectedObject} ExpectedObject instance
                         */
                        ExpectedObject.create = function create(properties) {
                            return new ExpectedObject(properties);
                        };

                        /**
                         * Encodes the specified ExpectedObject message. Does not implicitly {@link visor.common.jobtracker.JobTracker.ExpectedObject.verify|verify} messages.
                         * @function encode
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.IExpectedObject} message ExpectedObject message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExpectedObject.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.identifier != null && message.hasOwnProperty("identifier"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.identifier);
                            if (message.result != null && message.hasOwnProperty("result"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
                            return writer;
                        };

                        /**
                         * Encodes the specified ExpectedObject message, length delimited. Does not implicitly {@link visor.common.jobtracker.JobTracker.ExpectedObject.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.IExpectedObject} message ExpectedObject message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ExpectedObject.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an ExpectedObject message from the specified reader or buffer.
                         * @function decode
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {visor.common.jobtracker.JobTracker.ExpectedObject} ExpectedObject
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExpectedObject.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.jobtracker.JobTracker.ExpectedObject();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.identifier = reader.string();
                                    break;
                                case 2:
                                    message.result = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an ExpectedObject message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {visor.common.jobtracker.JobTracker.ExpectedObject} ExpectedObject
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ExpectedObject.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an ExpectedObject message.
                         * @function verify
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ExpectedObject.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.identifier != null && message.hasOwnProperty("identifier"))
                                if (!$util.isString(message.identifier))
                                    return "identifier: string expected";
                            if (message.result != null && message.hasOwnProperty("result"))
                                switch (message.result) {
                                default:
                                    return "result: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates an ExpectedObject message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {visor.common.jobtracker.JobTracker.ExpectedObject} ExpectedObject
                         */
                        ExpectedObject.fromObject = function fromObject(object) {
                            if (object instanceof $root.visor.common.jobtracker.JobTracker.ExpectedObject)
                                return object;
                            var message = new $root.visor.common.jobtracker.JobTracker.ExpectedObject();
                            if (object.identifier != null)
                                message.identifier = String(object.identifier);
                            switch (object.result) {
                            case "RESULT_INVALID":
                            case 0:
                                message.result = 0;
                                break;
                            case "RESULT_SUCCESS":
                            case 1:
                                message.result = 1;
                                break;
                            case "RESULT_FAILURE":
                            case 2:
                                message.result = 2;
                                break;
                            case "RESULT_PENDING":
                            case 3:
                                message.result = 3;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an ExpectedObject message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @static
                         * @param {visor.common.jobtracker.JobTracker.ExpectedObject} message ExpectedObject
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ExpectedObject.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.identifier = "";
                                object.result = options.enums === String ? "RESULT_INVALID" : 0;
                            }
                            if (message.identifier != null && message.hasOwnProperty("identifier"))
                                object.identifier = message.identifier;
                            if (message.result != null && message.hasOwnProperty("result"))
                                object.result = options.enums === String ? $root.visor.common.jobtracker.Result[message.result] : message.result;
                            return object;
                        };

                        /**
                         * Converts this ExpectedObject to JSON.
                         * @function toJSON
                         * @memberof visor.common.jobtracker.JobTracker.ExpectedObject
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ExpectedObject.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return ExpectedObject;
                    })();

                    return JobTracker;
                })();

                return jobtracker;
            })();

            common.LegalName = (function() {

                /**
                 * Properties of a LegalName.
                 * @memberof visor.common
                 * @interface ILegalName
                 * @property {string|null} [firstName] LegalName firstName
                 * @property {string|null} [lastName] LegalName lastName
                 */

                /**
                 * Constructs a new LegalName.
                 * @memberof visor.common
                 * @classdesc Represents a LegalName.
                 * @implements ILegalName
                 * @constructor
                 * @param {visor.common.ILegalName=} [properties] Properties to set
                 */
                function LegalName(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LegalName firstName.
                 * @member {string} firstName
                 * @memberof visor.common.LegalName
                 * @instance
                 */
                LegalName.prototype.firstName = "";

                /**
                 * LegalName lastName.
                 * @member {string} lastName
                 * @memberof visor.common.LegalName
                 * @instance
                 */
                LegalName.prototype.lastName = "";

                /**
                 * Creates a new LegalName instance using the specified properties.
                 * @function create
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {visor.common.ILegalName=} [properties] Properties to set
                 * @returns {visor.common.LegalName} LegalName instance
                 */
                LegalName.create = function create(properties) {
                    return new LegalName(properties);
                };

                /**
                 * Encodes the specified LegalName message. Does not implicitly {@link visor.common.LegalName.verify|verify} messages.
                 * @function encode
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {visor.common.ILegalName} message LegalName message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LegalName.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                    return writer;
                };

                /**
                 * Encodes the specified LegalName message, length delimited. Does not implicitly {@link visor.common.LegalName.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {visor.common.ILegalName} message LegalName message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LegalName.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LegalName message from the specified reader or buffer.
                 * @function decode
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {visor.common.LegalName} LegalName
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LegalName.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.LegalName();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.firstName = reader.string();
                            break;
                        case 2:
                            message.lastName = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LegalName message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {visor.common.LegalName} LegalName
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LegalName.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LegalName message.
                 * @function verify
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LegalName.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        if (!$util.isString(message.firstName))
                            return "firstName: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    return null;
                };

                /**
                 * Creates a LegalName message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {visor.common.LegalName} LegalName
                 */
                LegalName.fromObject = function fromObject(object) {
                    if (object instanceof $root.visor.common.LegalName)
                        return object;
                    var message = new $root.visor.common.LegalName();
                    if (object.firstName != null)
                        message.firstName = String(object.firstName);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    return message;
                };

                /**
                 * Creates a plain object from a LegalName message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof visor.common.LegalName
                 * @static
                 * @param {visor.common.LegalName} message LegalName
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LegalName.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.firstName = "";
                        object.lastName = "";
                    }
                    if (message.firstName != null && message.hasOwnProperty("firstName"))
                        object.firstName = message.firstName;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    return object;
                };

                /**
                 * Converts this LegalName to JSON.
                 * @function toJSON
                 * @memberof visor.common.LegalName
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LegalName.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return LegalName;
            })();

            common.money = (function() {

                /**
                 * Namespace money.
                 * @memberof visor.common
                 * @namespace
                 */
                var money = {};

                money.Amount = (function() {

                    /**
                     * Properties of an Amount.
                     * @memberof visor.common.money
                     * @interface IAmount
                     * @property {number|Long|null} [units] Amount units
                     * @property {string|null} [currencyCode] Amount currencyCode
                     */

                    /**
                     * Constructs a new Amount.
                     * @memberof visor.common.money
                     * @classdesc Represents an Amount.
                     * @implements IAmount
                     * @constructor
                     * @param {visor.common.money.IAmount=} [properties] Properties to set
                     */
                    function Amount(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Amount units.
                     * @member {number|Long} units
                     * @memberof visor.common.money.Amount
                     * @instance
                     */
                    Amount.prototype.units = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Amount currencyCode.
                     * @member {string} currencyCode
                     * @memberof visor.common.money.Amount
                     * @instance
                     */
                    Amount.prototype.currencyCode = "";

                    /**
                     * Creates a new Amount instance using the specified properties.
                     * @function create
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {visor.common.money.IAmount=} [properties] Properties to set
                     * @returns {visor.common.money.Amount} Amount instance
                     */
                    Amount.create = function create(properties) {
                        return new Amount(properties);
                    };

                    /**
                     * Encodes the specified Amount message. Does not implicitly {@link visor.common.money.Amount.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {visor.common.money.IAmount} message Amount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Amount.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.units != null && message.hasOwnProperty("units"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.units);
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified Amount message, length delimited. Does not implicitly {@link visor.common.money.Amount.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {visor.common.money.IAmount} message Amount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Amount.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Amount message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.money.Amount} Amount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Amount.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.money.Amount();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.units = reader.int64();
                                break;
                            case 2:
                                message.currencyCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Amount message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.money.Amount} Amount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Amount.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Amount message.
                     * @function verify
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Amount.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.units != null && message.hasOwnProperty("units"))
                            if (!$util.isInteger(message.units) && !(message.units && $util.isInteger(message.units.low) && $util.isInteger(message.units.high)))
                                return "units: integer|Long expected";
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            if (!$util.isString(message.currencyCode))
                                return "currencyCode: string expected";
                        return null;
                    };

                    /**
                     * Creates an Amount message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.money.Amount} Amount
                     */
                    Amount.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.money.Amount)
                            return object;
                        var message = new $root.visor.common.money.Amount();
                        if (object.units != null)
                            if ($util.Long)
                                (message.units = $util.Long.fromValue(object.units)).unsigned = false;
                            else if (typeof object.units === "string")
                                message.units = parseInt(object.units, 10);
                            else if (typeof object.units === "number")
                                message.units = object.units;
                            else if (typeof object.units === "object")
                                message.units = new $util.LongBits(object.units.low >>> 0, object.units.high >>> 0).toNumber();
                        if (object.currencyCode != null)
                            message.currencyCode = String(object.currencyCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Amount message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.money.Amount
                     * @static
                     * @param {visor.common.money.Amount} message Amount
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Amount.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.units = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.units = options.longs === String ? "0" : 0;
                            object.currencyCode = "";
                        }
                        if (message.units != null && message.hasOwnProperty("units"))
                            if (typeof message.units === "number")
                                object.units = options.longs === String ? String(message.units) : message.units;
                            else
                                object.units = options.longs === String ? $util.Long.prototype.toString.call(message.units) : options.longs === Number ? new $util.LongBits(message.units.low >>> 0, message.units.high >>> 0).toNumber() : message.units;
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            object.currencyCode = message.currencyCode;
                        return object;
                    };

                    /**
                     * Converts this Amount to JSON.
                     * @function toJSON
                     * @memberof visor.common.money.Amount
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Amount.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Amount;
                })();

                money.HighPrecisionAmount = (function() {

                    /**
                     * Properties of a HighPrecisionAmount.
                     * @memberof visor.common.money
                     * @interface IHighPrecisionAmount
                     * @property {visor.common.numeric.IDecimal|null} [amount] HighPrecisionAmount amount
                     * @property {string|null} [currencyCode] HighPrecisionAmount currencyCode
                     */

                    /**
                     * Constructs a new HighPrecisionAmount.
                     * @memberof visor.common.money
                     * @classdesc Represents a HighPrecisionAmount.
                     * @implements IHighPrecisionAmount
                     * @constructor
                     * @param {visor.common.money.IHighPrecisionAmount=} [properties] Properties to set
                     */
                    function HighPrecisionAmount(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * HighPrecisionAmount amount.
                     * @member {visor.common.numeric.IDecimal|null|undefined} amount
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @instance
                     */
                    HighPrecisionAmount.prototype.amount = null;

                    /**
                     * HighPrecisionAmount currencyCode.
                     * @member {string} currencyCode
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @instance
                     */
                    HighPrecisionAmount.prototype.currencyCode = "";

                    /**
                     * Creates a new HighPrecisionAmount instance using the specified properties.
                     * @function create
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {visor.common.money.IHighPrecisionAmount=} [properties] Properties to set
                     * @returns {visor.common.money.HighPrecisionAmount} HighPrecisionAmount instance
                     */
                    HighPrecisionAmount.create = function create(properties) {
                        return new HighPrecisionAmount(properties);
                    };

                    /**
                     * Encodes the specified HighPrecisionAmount message. Does not implicitly {@link visor.common.money.HighPrecisionAmount.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {visor.common.money.IHighPrecisionAmount} message HighPrecisionAmount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HighPrecisionAmount.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            $root.visor.common.numeric.Decimal.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified HighPrecisionAmount message, length delimited. Does not implicitly {@link visor.common.money.HighPrecisionAmount.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {visor.common.money.IHighPrecisionAmount} message HighPrecisionAmount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HighPrecisionAmount.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a HighPrecisionAmount message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.money.HighPrecisionAmount} HighPrecisionAmount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HighPrecisionAmount.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.money.HighPrecisionAmount();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.amount = $root.visor.common.numeric.Decimal.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.currencyCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a HighPrecisionAmount message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.money.HighPrecisionAmount} HighPrecisionAmount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HighPrecisionAmount.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a HighPrecisionAmount message.
                     * @function verify
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HighPrecisionAmount.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.amount != null && message.hasOwnProperty("amount")) {
                            var error = $root.visor.common.numeric.Decimal.verify(message.amount);
                            if (error)
                                return "amount." + error;
                        }
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            if (!$util.isString(message.currencyCode))
                                return "currencyCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a HighPrecisionAmount message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.money.HighPrecisionAmount} HighPrecisionAmount
                     */
                    HighPrecisionAmount.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.money.HighPrecisionAmount)
                            return object;
                        var message = new $root.visor.common.money.HighPrecisionAmount();
                        if (object.amount != null) {
                            if (typeof object.amount !== "object")
                                throw TypeError(".visor.common.money.HighPrecisionAmount.amount: object expected");
                            message.amount = $root.visor.common.numeric.Decimal.fromObject(object.amount);
                        }
                        if (object.currencyCode != null)
                            message.currencyCode = String(object.currencyCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a HighPrecisionAmount message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @static
                     * @param {visor.common.money.HighPrecisionAmount} message HighPrecisionAmount
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HighPrecisionAmount.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.amount = null;
                            object.currencyCode = "";
                        }
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            object.amount = $root.visor.common.numeric.Decimal.toObject(message.amount, options);
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            object.currencyCode = message.currencyCode;
                        return object;
                    };

                    /**
                     * Converts this HighPrecisionAmount to JSON.
                     * @function toJSON
                     * @memberof visor.common.money.HighPrecisionAmount
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HighPrecisionAmount.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return HighPrecisionAmount;
                })();

                return money;
            })();

            common.numeric = (function() {

                /**
                 * Namespace numeric.
                 * @memberof visor.common
                 * @namespace
                 */
                var numeric = {};

                numeric.NullableUint64 = (function() {

                    /**
                     * Properties of a NullableUint64.
                     * @memberof visor.common.numeric
                     * @interface INullableUint64
                     * @property {number|Long|null} [units] NullableUint64 units
                     * @property {boolean|null} [valid] NullableUint64 valid
                     */

                    /**
                     * Constructs a new NullableUint64.
                     * @memberof visor.common.numeric
                     * @classdesc Represents a NullableUint64.
                     * @implements INullableUint64
                     * @constructor
                     * @param {visor.common.numeric.INullableUint64=} [properties] Properties to set
                     */
                    function NullableUint64(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * NullableUint64 units.
                     * @member {number|Long} units
                     * @memberof visor.common.numeric.NullableUint64
                     * @instance
                     */
                    NullableUint64.prototype.units = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * NullableUint64 valid.
                     * @member {boolean} valid
                     * @memberof visor.common.numeric.NullableUint64
                     * @instance
                     */
                    NullableUint64.prototype.valid = false;

                    /**
                     * Creates a new NullableUint64 instance using the specified properties.
                     * @function create
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {visor.common.numeric.INullableUint64=} [properties] Properties to set
                     * @returns {visor.common.numeric.NullableUint64} NullableUint64 instance
                     */
                    NullableUint64.create = function create(properties) {
                        return new NullableUint64(properties);
                    };

                    /**
                     * Encodes the specified NullableUint64 message. Does not implicitly {@link visor.common.numeric.NullableUint64.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {visor.common.numeric.INullableUint64} message NullableUint64 message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NullableUint64.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.units != null && message.hasOwnProperty("units"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.units);
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.valid);
                        return writer;
                    };

                    /**
                     * Encodes the specified NullableUint64 message, length delimited. Does not implicitly {@link visor.common.numeric.NullableUint64.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {visor.common.numeric.INullableUint64} message NullableUint64 message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NullableUint64.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a NullableUint64 message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.numeric.NullableUint64} NullableUint64
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NullableUint64.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.numeric.NullableUint64();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.units = reader.uint64();
                                break;
                            case 2:
                                message.valid = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a NullableUint64 message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.numeric.NullableUint64} NullableUint64
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NullableUint64.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a NullableUint64 message.
                     * @function verify
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NullableUint64.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.units != null && message.hasOwnProperty("units"))
                            if (!$util.isInteger(message.units) && !(message.units && $util.isInteger(message.units.low) && $util.isInteger(message.units.high)))
                                return "units: integer|Long expected";
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            if (typeof message.valid !== "boolean")
                                return "valid: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a NullableUint64 message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.numeric.NullableUint64} NullableUint64
                     */
                    NullableUint64.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.numeric.NullableUint64)
                            return object;
                        var message = new $root.visor.common.numeric.NullableUint64();
                        if (object.units != null)
                            if ($util.Long)
                                (message.units = $util.Long.fromValue(object.units)).unsigned = true;
                            else if (typeof object.units === "string")
                                message.units = parseInt(object.units, 10);
                            else if (typeof object.units === "number")
                                message.units = object.units;
                            else if (typeof object.units === "object")
                                message.units = new $util.LongBits(object.units.low >>> 0, object.units.high >>> 0).toNumber(true);
                        if (object.valid != null)
                            message.valid = Boolean(object.valid);
                        return message;
                    };

                    /**
                     * Creates a plain object from a NullableUint64 message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.numeric.NullableUint64
                     * @static
                     * @param {visor.common.numeric.NullableUint64} message NullableUint64
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NullableUint64.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.units = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.units = options.longs === String ? "0" : 0;
                            object.valid = false;
                        }
                        if (message.units != null && message.hasOwnProperty("units"))
                            if (typeof message.units === "number")
                                object.units = options.longs === String ? String(message.units) : message.units;
                            else
                                object.units = options.longs === String ? $util.Long.prototype.toString.call(message.units) : options.longs === Number ? new $util.LongBits(message.units.low >>> 0, message.units.high >>> 0).toNumber(true) : message.units;
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            object.valid = message.valid;
                        return object;
                    };

                    /**
                     * Converts this NullableUint64 to JSON.
                     * @function toJSON
                     * @memberof visor.common.numeric.NullableUint64
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NullableUint64.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return NullableUint64;
                })();

                numeric.NullableInt64 = (function() {

                    /**
                     * Properties of a NullableInt64.
                     * @memberof visor.common.numeric
                     * @interface INullableInt64
                     * @property {number|Long|null} [units] NullableInt64 units
                     * @property {boolean|null} [valid] NullableInt64 valid
                     */

                    /**
                     * Constructs a new NullableInt64.
                     * @memberof visor.common.numeric
                     * @classdesc Represents a NullableInt64.
                     * @implements INullableInt64
                     * @constructor
                     * @param {visor.common.numeric.INullableInt64=} [properties] Properties to set
                     */
                    function NullableInt64(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * NullableInt64 units.
                     * @member {number|Long} units
                     * @memberof visor.common.numeric.NullableInt64
                     * @instance
                     */
                    NullableInt64.prototype.units = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * NullableInt64 valid.
                     * @member {boolean} valid
                     * @memberof visor.common.numeric.NullableInt64
                     * @instance
                     */
                    NullableInt64.prototype.valid = false;

                    /**
                     * Creates a new NullableInt64 instance using the specified properties.
                     * @function create
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {visor.common.numeric.INullableInt64=} [properties] Properties to set
                     * @returns {visor.common.numeric.NullableInt64} NullableInt64 instance
                     */
                    NullableInt64.create = function create(properties) {
                        return new NullableInt64(properties);
                    };

                    /**
                     * Encodes the specified NullableInt64 message. Does not implicitly {@link visor.common.numeric.NullableInt64.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {visor.common.numeric.INullableInt64} message NullableInt64 message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NullableInt64.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.units != null && message.hasOwnProperty("units"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.units);
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.valid);
                        return writer;
                    };

                    /**
                     * Encodes the specified NullableInt64 message, length delimited. Does not implicitly {@link visor.common.numeric.NullableInt64.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {visor.common.numeric.INullableInt64} message NullableInt64 message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NullableInt64.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a NullableInt64 message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.numeric.NullableInt64} NullableInt64
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NullableInt64.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.numeric.NullableInt64();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.units = reader.int64();
                                break;
                            case 2:
                                message.valid = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a NullableInt64 message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.numeric.NullableInt64} NullableInt64
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NullableInt64.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a NullableInt64 message.
                     * @function verify
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NullableInt64.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.units != null && message.hasOwnProperty("units"))
                            if (!$util.isInteger(message.units) && !(message.units && $util.isInteger(message.units.low) && $util.isInteger(message.units.high)))
                                return "units: integer|Long expected";
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            if (typeof message.valid !== "boolean")
                                return "valid: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a NullableInt64 message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.numeric.NullableInt64} NullableInt64
                     */
                    NullableInt64.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.numeric.NullableInt64)
                            return object;
                        var message = new $root.visor.common.numeric.NullableInt64();
                        if (object.units != null)
                            if ($util.Long)
                                (message.units = $util.Long.fromValue(object.units)).unsigned = false;
                            else if (typeof object.units === "string")
                                message.units = parseInt(object.units, 10);
                            else if (typeof object.units === "number")
                                message.units = object.units;
                            else if (typeof object.units === "object")
                                message.units = new $util.LongBits(object.units.low >>> 0, object.units.high >>> 0).toNumber();
                        if (object.valid != null)
                            message.valid = Boolean(object.valid);
                        return message;
                    };

                    /**
                     * Creates a plain object from a NullableInt64 message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.numeric.NullableInt64
                     * @static
                     * @param {visor.common.numeric.NullableInt64} message NullableInt64
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NullableInt64.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.units = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.units = options.longs === String ? "0" : 0;
                            object.valid = false;
                        }
                        if (message.units != null && message.hasOwnProperty("units"))
                            if (typeof message.units === "number")
                                object.units = options.longs === String ? String(message.units) : message.units;
                            else
                                object.units = options.longs === String ? $util.Long.prototype.toString.call(message.units) : options.longs === Number ? new $util.LongBits(message.units.low >>> 0, message.units.high >>> 0).toNumber() : message.units;
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            object.valid = message.valid;
                        return object;
                    };

                    /**
                     * Converts this NullableInt64 to JSON.
                     * @function toJSON
                     * @memberof visor.common.numeric.NullableInt64
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NullableInt64.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return NullableInt64;
                })();

                numeric.NullableDouble = (function() {

                    /**
                     * Properties of a NullableDouble.
                     * @memberof visor.common.numeric
                     * @interface INullableDouble
                     * @property {number|null} [number] NullableDouble number
                     * @property {boolean|null} [valid] NullableDouble valid
                     */

                    /**
                     * Constructs a new NullableDouble.
                     * @memberof visor.common.numeric
                     * @classdesc Represents a NullableDouble.
                     * @implements INullableDouble
                     * @constructor
                     * @param {visor.common.numeric.INullableDouble=} [properties] Properties to set
                     */
                    function NullableDouble(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * NullableDouble number.
                     * @member {number} number
                     * @memberof visor.common.numeric.NullableDouble
                     * @instance
                     */
                    NullableDouble.prototype.number = 0;

                    /**
                     * NullableDouble valid.
                     * @member {boolean} valid
                     * @memberof visor.common.numeric.NullableDouble
                     * @instance
                     */
                    NullableDouble.prototype.valid = false;

                    /**
                     * Creates a new NullableDouble instance using the specified properties.
                     * @function create
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {visor.common.numeric.INullableDouble=} [properties] Properties to set
                     * @returns {visor.common.numeric.NullableDouble} NullableDouble instance
                     */
                    NullableDouble.create = function create(properties) {
                        return new NullableDouble(properties);
                    };

                    /**
                     * Encodes the specified NullableDouble message. Does not implicitly {@link visor.common.numeric.NullableDouble.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {visor.common.numeric.INullableDouble} message NullableDouble message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NullableDouble.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.number != null && message.hasOwnProperty("number"))
                            writer.uint32(/* id 1, wireType 1 =*/9).double(message.number);
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.valid);
                        return writer;
                    };

                    /**
                     * Encodes the specified NullableDouble message, length delimited. Does not implicitly {@link visor.common.numeric.NullableDouble.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {visor.common.numeric.INullableDouble} message NullableDouble message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NullableDouble.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a NullableDouble message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.numeric.NullableDouble} NullableDouble
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NullableDouble.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.numeric.NullableDouble();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.number = reader.double();
                                break;
                            case 2:
                                message.valid = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a NullableDouble message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.numeric.NullableDouble} NullableDouble
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NullableDouble.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a NullableDouble message.
                     * @function verify
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NullableDouble.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.number != null && message.hasOwnProperty("number"))
                            if (typeof message.number !== "number")
                                return "number: number expected";
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            if (typeof message.valid !== "boolean")
                                return "valid: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a NullableDouble message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.numeric.NullableDouble} NullableDouble
                     */
                    NullableDouble.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.numeric.NullableDouble)
                            return object;
                        var message = new $root.visor.common.numeric.NullableDouble();
                        if (object.number != null)
                            message.number = Number(object.number);
                        if (object.valid != null)
                            message.valid = Boolean(object.valid);
                        return message;
                    };

                    /**
                     * Creates a plain object from a NullableDouble message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.numeric.NullableDouble
                     * @static
                     * @param {visor.common.numeric.NullableDouble} message NullableDouble
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NullableDouble.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.number = 0;
                            object.valid = false;
                        }
                        if (message.number != null && message.hasOwnProperty("number"))
                            object.number = options.json && !isFinite(message.number) ? String(message.number) : message.number;
                        if (message.valid != null && message.hasOwnProperty("valid"))
                            object.valid = message.valid;
                        return object;
                    };

                    /**
                     * Converts this NullableDouble to JSON.
                     * @function toJSON
                     * @memberof visor.common.numeric.NullableDouble
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NullableDouble.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return NullableDouble;
                })();

                numeric.Decimal = (function() {

                    /**
                     * Properties of a Decimal.
                     * @memberof visor.common.numeric
                     * @interface IDecimal
                     * @property {Uint8Array|null} [byteValue] Decimal byteValue
                     * @property {number|null} [exponent] Decimal exponent
                     * @property {string|null} [stringRepresentation] Decimal stringRepresentation
                     * @property {boolean|null} [isNegative] Decimal isNegative
                     */

                    /**
                     * Constructs a new Decimal.
                     * @memberof visor.common.numeric
                     * @classdesc Represents a Decimal.
                     * @implements IDecimal
                     * @constructor
                     * @param {visor.common.numeric.IDecimal=} [properties] Properties to set
                     */
                    function Decimal(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Decimal byteValue.
                     * @member {Uint8Array} byteValue
                     * @memberof visor.common.numeric.Decimal
                     * @instance
                     */
                    Decimal.prototype.byteValue = $util.newBuffer([]);

                    /**
                     * Decimal exponent.
                     * @member {number} exponent
                     * @memberof visor.common.numeric.Decimal
                     * @instance
                     */
                    Decimal.prototype.exponent = 0;

                    /**
                     * Decimal stringRepresentation.
                     * @member {string} stringRepresentation
                     * @memberof visor.common.numeric.Decimal
                     * @instance
                     */
                    Decimal.prototype.stringRepresentation = "";

                    /**
                     * Decimal isNegative.
                     * @member {boolean} isNegative
                     * @memberof visor.common.numeric.Decimal
                     * @instance
                     */
                    Decimal.prototype.isNegative = false;

                    /**
                     * Creates a new Decimal instance using the specified properties.
                     * @function create
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {visor.common.numeric.IDecimal=} [properties] Properties to set
                     * @returns {visor.common.numeric.Decimal} Decimal instance
                     */
                    Decimal.create = function create(properties) {
                        return new Decimal(properties);
                    };

                    /**
                     * Encodes the specified Decimal message. Does not implicitly {@link visor.common.numeric.Decimal.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {visor.common.numeric.IDecimal} message Decimal message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Decimal.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.byteValue != null && message.hasOwnProperty("byteValue"))
                            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.byteValue);
                        if (message.exponent != null && message.hasOwnProperty("exponent"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.exponent);
                        if (message.stringRepresentation != null && message.hasOwnProperty("stringRepresentation"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.stringRepresentation);
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isNegative);
                        return writer;
                    };

                    /**
                     * Encodes the specified Decimal message, length delimited. Does not implicitly {@link visor.common.numeric.Decimal.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {visor.common.numeric.IDecimal} message Decimal message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Decimal.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Decimal message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.numeric.Decimal} Decimal
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Decimal.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.numeric.Decimal();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.byteValue = reader.bytes();
                                break;
                            case 2:
                                message.exponent = reader.int32();
                                break;
                            case 3:
                                message.stringRepresentation = reader.string();
                                break;
                            case 4:
                                message.isNegative = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Decimal message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.numeric.Decimal} Decimal
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Decimal.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Decimal message.
                     * @function verify
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Decimal.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.byteValue != null && message.hasOwnProperty("byteValue"))
                            if (!(message.byteValue && typeof message.byteValue.length === "number" || $util.isString(message.byteValue)))
                                return "byteValue: buffer expected";
                        if (message.exponent != null && message.hasOwnProperty("exponent"))
                            if (!$util.isInteger(message.exponent))
                                return "exponent: integer expected";
                        if (message.stringRepresentation != null && message.hasOwnProperty("stringRepresentation"))
                            if (!$util.isString(message.stringRepresentation))
                                return "stringRepresentation: string expected";
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            if (typeof message.isNegative !== "boolean")
                                return "isNegative: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a Decimal message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.numeric.Decimal} Decimal
                     */
                    Decimal.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.numeric.Decimal)
                            return object;
                        var message = new $root.visor.common.numeric.Decimal();
                        if (object.byteValue != null)
                            if (typeof object.byteValue === "string")
                                $util.base64.decode(object.byteValue, message.byteValue = $util.newBuffer($util.base64.length(object.byteValue)), 0);
                            else if (object.byteValue.length)
                                message.byteValue = object.byteValue;
                        if (object.exponent != null)
                            message.exponent = object.exponent | 0;
                        if (object.stringRepresentation != null)
                            message.stringRepresentation = String(object.stringRepresentation);
                        if (object.isNegative != null)
                            message.isNegative = Boolean(object.isNegative);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Decimal message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.numeric.Decimal
                     * @static
                     * @param {visor.common.numeric.Decimal} message Decimal
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Decimal.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if (options.bytes === String)
                                object.byteValue = "";
                            else {
                                object.byteValue = [];
                                if (options.bytes !== Array)
                                    object.byteValue = $util.newBuffer(object.byteValue);
                            }
                            object.exponent = 0;
                            object.stringRepresentation = "";
                            object.isNegative = false;
                        }
                        if (message.byteValue != null && message.hasOwnProperty("byteValue"))
                            object.byteValue = options.bytes === String ? $util.base64.encode(message.byteValue, 0, message.byteValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.byteValue) : message.byteValue;
                        if (message.exponent != null && message.hasOwnProperty("exponent"))
                            object.exponent = message.exponent;
                        if (message.stringRepresentation != null && message.hasOwnProperty("stringRepresentation"))
                            object.stringRepresentation = message.stringRepresentation;
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            object.isNegative = message.isNegative;
                        return object;
                    };

                    /**
                     * Converts this Decimal to JSON.
                     * @function toJSON
                     * @memberof visor.common.numeric.Decimal
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Decimal.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Decimal;
                })();

                return numeric;
            })();

            common.strings = (function() {

                /**
                 * Namespace strings.
                 * @memberof visor.common
                 * @namespace
                 */
                var strings = {};

                strings.LocalizedString = (function() {

                    /**
                     * Properties of a LocalizedString.
                     * @memberof visor.common.strings
                     * @interface ILocalizedString
                     * @property {string|null} [key] LocalizedString key
                     * @property {Object.<string,string>|null} [substitutions] LocalizedString substitutions
                     * @property {string|null} [constructedValue] LocalizedString constructedValue
                     */

                    /**
                     * Constructs a new LocalizedString.
                     * @memberof visor.common.strings
                     * @classdesc Represents a LocalizedString.
                     * @implements ILocalizedString
                     * @constructor
                     * @param {visor.common.strings.ILocalizedString=} [properties] Properties to set
                     */
                    function LocalizedString(properties) {
                        this.substitutions = {};
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LocalizedString key.
                     * @member {string} key
                     * @memberof visor.common.strings.LocalizedString
                     * @instance
                     */
                    LocalizedString.prototype.key = "";

                    /**
                     * LocalizedString substitutions.
                     * @member {Object.<string,string>} substitutions
                     * @memberof visor.common.strings.LocalizedString
                     * @instance
                     */
                    LocalizedString.prototype.substitutions = $util.emptyObject;

                    /**
                     * LocalizedString constructedValue.
                     * @member {string} constructedValue
                     * @memberof visor.common.strings.LocalizedString
                     * @instance
                     */
                    LocalizedString.prototype.constructedValue = "";

                    /**
                     * Creates a new LocalizedString instance using the specified properties.
                     * @function create
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {visor.common.strings.ILocalizedString=} [properties] Properties to set
                     * @returns {visor.common.strings.LocalizedString} LocalizedString instance
                     */
                    LocalizedString.create = function create(properties) {
                        return new LocalizedString(properties);
                    };

                    /**
                     * Encodes the specified LocalizedString message. Does not implicitly {@link visor.common.strings.LocalizedString.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {visor.common.strings.ILocalizedString} message LocalizedString message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LocalizedString.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.key != null && message.hasOwnProperty("key"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                        if (message.substitutions != null && message.hasOwnProperty("substitutions"))
                            for (var keys = Object.keys(message.substitutions), i = 0; i < keys.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.substitutions[keys[i]]).ldelim();
                        if (message.constructedValue != null && message.hasOwnProperty("constructedValue"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.constructedValue);
                        return writer;
                    };

                    /**
                     * Encodes the specified LocalizedString message, length delimited. Does not implicitly {@link visor.common.strings.LocalizedString.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {visor.common.strings.ILocalizedString} message LocalizedString message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LocalizedString.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a LocalizedString message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.strings.LocalizedString} LocalizedString
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LocalizedString.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.strings.LocalizedString(), key;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.key = reader.string();
                                break;
                            case 2:
                                reader.skip().pos++;
                                if (message.substitutions === $util.emptyObject)
                                    message.substitutions = {};
                                key = reader.string();
                                reader.pos++;
                                message.substitutions[key] = reader.string();
                                break;
                            case 3:
                                message.constructedValue = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a LocalizedString message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.strings.LocalizedString} LocalizedString
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LocalizedString.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a LocalizedString message.
                     * @function verify
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LocalizedString.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.key != null && message.hasOwnProperty("key"))
                            if (!$util.isString(message.key))
                                return "key: string expected";
                        if (message.substitutions != null && message.hasOwnProperty("substitutions")) {
                            if (!$util.isObject(message.substitutions))
                                return "substitutions: object expected";
                            var key = Object.keys(message.substitutions);
                            for (var i = 0; i < key.length; ++i)
                                if (!$util.isString(message.substitutions[key[i]]))
                                    return "substitutions: string{k:string} expected";
                        }
                        if (message.constructedValue != null && message.hasOwnProperty("constructedValue"))
                            if (!$util.isString(message.constructedValue))
                                return "constructedValue: string expected";
                        return null;
                    };

                    /**
                     * Creates a LocalizedString message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.strings.LocalizedString} LocalizedString
                     */
                    LocalizedString.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.strings.LocalizedString)
                            return object;
                        var message = new $root.visor.common.strings.LocalizedString();
                        if (object.key != null)
                            message.key = String(object.key);
                        if (object.substitutions) {
                            if (typeof object.substitutions !== "object")
                                throw TypeError(".visor.common.strings.LocalizedString.substitutions: object expected");
                            message.substitutions = {};
                            for (var keys = Object.keys(object.substitutions), i = 0; i < keys.length; ++i)
                                message.substitutions[keys[i]] = String(object.substitutions[keys[i]]);
                        }
                        if (object.constructedValue != null)
                            message.constructedValue = String(object.constructedValue);
                        return message;
                    };

                    /**
                     * Creates a plain object from a LocalizedString message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.strings.LocalizedString
                     * @static
                     * @param {visor.common.strings.LocalizedString} message LocalizedString
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LocalizedString.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.objects || options.defaults)
                            object.substitutions = {};
                        if (options.defaults) {
                            object.key = "";
                            object.constructedValue = "";
                        }
                        if (message.key != null && message.hasOwnProperty("key"))
                            object.key = message.key;
                        var keys2;
                        if (message.substitutions && (keys2 = Object.keys(message.substitutions)).length) {
                            object.substitutions = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.substitutions[keys2[j]] = message.substitutions[keys2[j]];
                        }
                        if (message.constructedValue != null && message.hasOwnProperty("constructedValue"))
                            object.constructedValue = message.constructedValue;
                        return object;
                    };

                    /**
                     * Converts this LocalizedString to JSON.
                     * @function toJSON
                     * @memberof visor.common.strings.LocalizedString
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LocalizedString.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return LocalizedString;
                })();

                return strings;
            })();

            common.taxforms = (function() {

                /**
                 * Namespace taxforms.
                 * @memberof visor.common
                 * @namespace
                 */
                var taxforms = {};

                taxforms.ty2019 = (function() {

                    /**
                     * Namespace ty2019.
                     * @memberof visor.common.taxforms
                     * @namespace
                     */
                    var ty2019 = {};

                    ty2019.FormW4 = (function() {

                        /**
                         * Properties of a FormW4.
                         * @memberof visor.common.taxforms.ty2019
                         * @interface IFormW4
                         * @property {string|null} [firstNameAndMiddleInitial] FormW4 firstNameAndMiddleInitial
                         * @property {string|null} [lastName] FormW4 lastName
                         * @property {string|null} [socialSecurityNumber] FormW4 socialSecurityNumber
                         * @property {string|null} [homeAddress] FormW4 homeAddress
                         * @property {string|null} [cityOrTownStateAndZip] FormW4 cityOrTownStateAndZip
                         * @property {visor.common.taxforms.ty2019.FormW4.MaritalStatus|null} [maritalStatus] FormW4 maritalStatus
                         * @property {boolean|null} [lastNameDiffersFromSsn] FormW4 lastNameDiffersFromSsn
                         * @property {visor.common.numeric.INullableUint64|null} [numberOfAllowances] FormW4 numberOfAllowances
                         * @property {visor.common.money.IAmount|null} [additionalAmount] FormW4 additionalAmount
                         * @property {boolean|null} [exempt] FormW4 exempt
                         * @property {Uint8Array|null} [signaturePngData] FormW4 signaturePngData
                         * @property {google.protobuf.ITimestamp|null} [signatureTimestamp] FormW4 signatureTimestamp
                         * @property {visor.common.money.IAmount|null} [targetTaxWithheld] FormW4 targetTaxWithheld
                         */

                        /**
                         * Constructs a new FormW4.
                         * @memberof visor.common.taxforms.ty2019
                         * @classdesc Represents a FormW4.
                         * @implements IFormW4
                         * @constructor
                         * @param {visor.common.taxforms.ty2019.IFormW4=} [properties] Properties to set
                         */
                        function FormW4(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FormW4 firstNameAndMiddleInitial.
                         * @member {string} firstNameAndMiddleInitial
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.firstNameAndMiddleInitial = "";

                        /**
                         * FormW4 lastName.
                         * @member {string} lastName
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.lastName = "";

                        /**
                         * FormW4 socialSecurityNumber.
                         * @member {string} socialSecurityNumber
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.socialSecurityNumber = "";

                        /**
                         * FormW4 homeAddress.
                         * @member {string} homeAddress
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.homeAddress = "";

                        /**
                         * FormW4 cityOrTownStateAndZip.
                         * @member {string} cityOrTownStateAndZip
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.cityOrTownStateAndZip = "";

                        /**
                         * FormW4 maritalStatus.
                         * @member {visor.common.taxforms.ty2019.FormW4.MaritalStatus} maritalStatus
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.maritalStatus = 0;

                        /**
                         * FormW4 lastNameDiffersFromSsn.
                         * @member {boolean} lastNameDiffersFromSsn
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.lastNameDiffersFromSsn = false;

                        /**
                         * FormW4 numberOfAllowances.
                         * @member {visor.common.numeric.INullableUint64|null|undefined} numberOfAllowances
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.numberOfAllowances = null;

                        /**
                         * FormW4 additionalAmount.
                         * @member {visor.common.money.IAmount|null|undefined} additionalAmount
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.additionalAmount = null;

                        /**
                         * FormW4 exempt.
                         * @member {boolean} exempt
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.exempt = false;

                        /**
                         * FormW4 signaturePngData.
                         * @member {Uint8Array} signaturePngData
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.signaturePngData = $util.newBuffer([]);

                        /**
                         * FormW4 signatureTimestamp.
                         * @member {google.protobuf.ITimestamp|null|undefined} signatureTimestamp
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.signatureTimestamp = null;

                        /**
                         * FormW4 targetTaxWithheld.
                         * @member {visor.common.money.IAmount|null|undefined} targetTaxWithheld
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         */
                        FormW4.prototype.targetTaxWithheld = null;

                        /**
                         * Creates a new FormW4 instance using the specified properties.
                         * @function create
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2019.IFormW4=} [properties] Properties to set
                         * @returns {visor.common.taxforms.ty2019.FormW4} FormW4 instance
                         */
                        FormW4.create = function create(properties) {
                            return new FormW4(properties);
                        };

                        /**
                         * Encodes the specified FormW4 message. Does not implicitly {@link visor.common.taxforms.ty2019.FormW4.verify|verify} messages.
                         * @function encode
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2019.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.firstNameAndMiddleInitial != null && message.hasOwnProperty("firstNameAndMiddleInitial"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstNameAndMiddleInitial);
                            if (message.lastName != null && message.hasOwnProperty("lastName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                            if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.socialSecurityNumber);
                            if (message.homeAddress != null && message.hasOwnProperty("homeAddress"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.homeAddress);
                            if (message.cityOrTownStateAndZip != null && message.hasOwnProperty("cityOrTownStateAndZip"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.cityOrTownStateAndZip);
                            if (message.maritalStatus != null && message.hasOwnProperty("maritalStatus"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maritalStatus);
                            if (message.lastNameDiffersFromSsn != null && message.hasOwnProperty("lastNameDiffersFromSsn"))
                                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.lastNameDiffersFromSsn);
                            if (message.numberOfAllowances != null && message.hasOwnProperty("numberOfAllowances"))
                                $root.visor.common.numeric.NullableUint64.encode(message.numberOfAllowances, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.additionalAmount != null && message.hasOwnProperty("additionalAmount"))
                                $root.visor.common.money.Amount.encode(message.additionalAmount, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.exempt != null && message.hasOwnProperty("exempt"))
                                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.exempt);
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                writer.uint32(/* id 11, wireType 2 =*/90).bytes(message.signaturePngData);
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                $root.google.protobuf.Timestamp.encode(message.signatureTimestamp, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.targetTaxWithheld != null && message.hasOwnProperty("targetTaxWithheld"))
                                $root.visor.common.money.Amount.encode(message.targetTaxWithheld, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified FormW4 message, length delimited. Does not implicitly {@link visor.common.taxforms.ty2019.FormW4.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2019.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer.
                         * @function decode
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {visor.common.taxforms.ty2019.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.taxforms.ty2019.FormW4();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.firstNameAndMiddleInitial = reader.string();
                                    break;
                                case 2:
                                    message.lastName = reader.string();
                                    break;
                                case 3:
                                    message.socialSecurityNumber = reader.string();
                                    break;
                                case 4:
                                    message.homeAddress = reader.string();
                                    break;
                                case 5:
                                    message.cityOrTownStateAndZip = reader.string();
                                    break;
                                case 6:
                                    message.maritalStatus = reader.int32();
                                    break;
                                case 7:
                                    message.lastNameDiffersFromSsn = reader.bool();
                                    break;
                                case 8:
                                    message.numberOfAllowances = $root.visor.common.numeric.NullableUint64.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.additionalAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.exempt = reader.bool();
                                    break;
                                case 11:
                                    message.signaturePngData = reader.bytes();
                                    break;
                                case 12:
                                    message.signatureTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 13:
                                    message.targetTaxWithheld = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {visor.common.taxforms.ty2019.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FormW4 message.
                         * @function verify
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FormW4.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.firstNameAndMiddleInitial != null && message.hasOwnProperty("firstNameAndMiddleInitial"))
                                if (!$util.isString(message.firstNameAndMiddleInitial))
                                    return "firstNameAndMiddleInitial: string expected";
                            if (message.lastName != null && message.hasOwnProperty("lastName"))
                                if (!$util.isString(message.lastName))
                                    return "lastName: string expected";
                            if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                                if (!$util.isString(message.socialSecurityNumber))
                                    return "socialSecurityNumber: string expected";
                            if (message.homeAddress != null && message.hasOwnProperty("homeAddress"))
                                if (!$util.isString(message.homeAddress))
                                    return "homeAddress: string expected";
                            if (message.cityOrTownStateAndZip != null && message.hasOwnProperty("cityOrTownStateAndZip"))
                                if (!$util.isString(message.cityOrTownStateAndZip))
                                    return "cityOrTownStateAndZip: string expected";
                            if (message.maritalStatus != null && message.hasOwnProperty("maritalStatus"))
                                switch (message.maritalStatus) {
                                default:
                                    return "maritalStatus: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            if (message.lastNameDiffersFromSsn != null && message.hasOwnProperty("lastNameDiffersFromSsn"))
                                if (typeof message.lastNameDiffersFromSsn !== "boolean")
                                    return "lastNameDiffersFromSsn: boolean expected";
                            if (message.numberOfAllowances != null && message.hasOwnProperty("numberOfAllowances")) {
                                var error = $root.visor.common.numeric.NullableUint64.verify(message.numberOfAllowances);
                                if (error)
                                    return "numberOfAllowances." + error;
                            }
                            if (message.additionalAmount != null && message.hasOwnProperty("additionalAmount")) {
                                var error = $root.visor.common.money.Amount.verify(message.additionalAmount);
                                if (error)
                                    return "additionalAmount." + error;
                            }
                            if (message.exempt != null && message.hasOwnProperty("exempt"))
                                if (typeof message.exempt !== "boolean")
                                    return "exempt: boolean expected";
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                if (!(message.signaturePngData && typeof message.signaturePngData.length === "number" || $util.isString(message.signaturePngData)))
                                    return "signaturePngData: buffer expected";
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.signatureTimestamp);
                                if (error)
                                    return "signatureTimestamp." + error;
                            }
                            if (message.targetTaxWithheld != null && message.hasOwnProperty("targetTaxWithheld")) {
                                var error = $root.visor.common.money.Amount.verify(message.targetTaxWithheld);
                                if (error)
                                    return "targetTaxWithheld." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a FormW4 message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {visor.common.taxforms.ty2019.FormW4} FormW4
                         */
                        FormW4.fromObject = function fromObject(object) {
                            if (object instanceof $root.visor.common.taxforms.ty2019.FormW4)
                                return object;
                            var message = new $root.visor.common.taxforms.ty2019.FormW4();
                            if (object.firstNameAndMiddleInitial != null)
                                message.firstNameAndMiddleInitial = String(object.firstNameAndMiddleInitial);
                            if (object.lastName != null)
                                message.lastName = String(object.lastName);
                            if (object.socialSecurityNumber != null)
                                message.socialSecurityNumber = String(object.socialSecurityNumber);
                            if (object.homeAddress != null)
                                message.homeAddress = String(object.homeAddress);
                            if (object.cityOrTownStateAndZip != null)
                                message.cityOrTownStateAndZip = String(object.cityOrTownStateAndZip);
                            switch (object.maritalStatus) {
                            case "FORM_W4_MARITAL_STATUS_INVALID":
                            case 0:
                                message.maritalStatus = 0;
                                break;
                            case "FORM_W4_MARITAL_STATUS_SINGLE":
                            case 1:
                                message.maritalStatus = 1;
                                break;
                            case "FORM_W4_MARITAL_STATUS_MARRIED":
                            case 2:
                                message.maritalStatus = 2;
                                break;
                            case "FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE":
                            case 3:
                                message.maritalStatus = 3;
                                break;
                            }
                            if (object.lastNameDiffersFromSsn != null)
                                message.lastNameDiffersFromSsn = Boolean(object.lastNameDiffersFromSsn);
                            if (object.numberOfAllowances != null) {
                                if (typeof object.numberOfAllowances !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2019.FormW4.numberOfAllowances: object expected");
                                message.numberOfAllowances = $root.visor.common.numeric.NullableUint64.fromObject(object.numberOfAllowances);
                            }
                            if (object.additionalAmount != null) {
                                if (typeof object.additionalAmount !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2019.FormW4.additionalAmount: object expected");
                                message.additionalAmount = $root.visor.common.money.Amount.fromObject(object.additionalAmount);
                            }
                            if (object.exempt != null)
                                message.exempt = Boolean(object.exempt);
                            if (object.signaturePngData != null)
                                if (typeof object.signaturePngData === "string")
                                    $util.base64.decode(object.signaturePngData, message.signaturePngData = $util.newBuffer($util.base64.length(object.signaturePngData)), 0);
                                else if (object.signaturePngData.length)
                                    message.signaturePngData = object.signaturePngData;
                            if (object.signatureTimestamp != null)
                                if (object.signatureTimestamp === null)
                                    message.signatureTimestamp = {};
                                else
                                    message.signatureTimestamp = {
                                        seconds: Math.floor(object.signatureTimestamp.getTime() / 1000),
                                        nanos: object.signatureTimestamp.getMilliseconds() * 1000000
                                    };
                            if (object.targetTaxWithheld != null) {
                                if (typeof object.targetTaxWithheld !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2019.FormW4.targetTaxWithheld: object expected");
                                message.targetTaxWithheld = $root.visor.common.money.Amount.fromObject(object.targetTaxWithheld);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a FormW4 message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2019.FormW4} message FormW4
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FormW4.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.firstNameAndMiddleInitial = "";
                                object.lastName = "";
                                object.socialSecurityNumber = "";
                                object.homeAddress = "";
                                object.cityOrTownStateAndZip = "";
                                object.maritalStatus = options.enums === String ? "FORM_W4_MARITAL_STATUS_INVALID" : 0;
                                object.lastNameDiffersFromSsn = false;
                                object.numberOfAllowances = null;
                                object.additionalAmount = null;
                                object.exempt = false;
                                if (options.bytes === String)
                                    object.signaturePngData = "";
                                else {
                                    object.signaturePngData = [];
                                    if (options.bytes !== Array)
                                        object.signaturePngData = $util.newBuffer(object.signaturePngData);
                                }
                                object.signatureTimestamp = null;
                                object.targetTaxWithheld = null;
                            }
                            if (message.firstNameAndMiddleInitial != null && message.hasOwnProperty("firstNameAndMiddleInitial"))
                                object.firstNameAndMiddleInitial = message.firstNameAndMiddleInitial;
                            if (message.lastName != null && message.hasOwnProperty("lastName"))
                                object.lastName = message.lastName;
                            if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                                object.socialSecurityNumber = message.socialSecurityNumber;
                            if (message.homeAddress != null && message.hasOwnProperty("homeAddress"))
                                object.homeAddress = message.homeAddress;
                            if (message.cityOrTownStateAndZip != null && message.hasOwnProperty("cityOrTownStateAndZip"))
                                object.cityOrTownStateAndZip = message.cityOrTownStateAndZip;
                            if (message.maritalStatus != null && message.hasOwnProperty("maritalStatus"))
                                object.maritalStatus = options.enums === String ? $root.visor.common.taxforms.ty2019.FormW4.MaritalStatus[message.maritalStatus] : message.maritalStatus;
                            if (message.lastNameDiffersFromSsn != null && message.hasOwnProperty("lastNameDiffersFromSsn"))
                                object.lastNameDiffersFromSsn = message.lastNameDiffersFromSsn;
                            if (message.numberOfAllowances != null && message.hasOwnProperty("numberOfAllowances"))
                                object.numberOfAllowances = $root.visor.common.numeric.NullableUint64.toObject(message.numberOfAllowances, options);
                            if (message.additionalAmount != null && message.hasOwnProperty("additionalAmount"))
                                object.additionalAmount = $root.visor.common.money.Amount.toObject(message.additionalAmount, options);
                            if (message.exempt != null && message.hasOwnProperty("exempt"))
                                object.exempt = message.exempt;
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                object.signaturePngData = options.bytes === String ? $util.base64.encode(message.signaturePngData, 0, message.signaturePngData.length) : options.bytes === Array ? Array.prototype.slice.call(message.signaturePngData) : message.signaturePngData;
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                object.signatureTimestamp = new Date(message.signatureTimestamp.seconds * 1000 + message.signatureTimestamp.nanos / 1000000);
                            if (message.targetTaxWithheld != null && message.hasOwnProperty("targetTaxWithheld"))
                                object.targetTaxWithheld = $root.visor.common.money.Amount.toObject(message.targetTaxWithheld, options);
                            return object;
                        };

                        /**
                         * Converts this FormW4 to JSON.
                         * @function toJSON
                         * @memberof visor.common.taxforms.ty2019.FormW4
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FormW4.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * MaritalStatus enum.
                         * @name visor.common.taxforms.ty2019.FormW4.MaritalStatus
                         * @enum {string}
                         * @property {number} FORM_W4_MARITAL_STATUS_INVALID=0 FORM_W4_MARITAL_STATUS_INVALID value
                         * @property {number} FORM_W4_MARITAL_STATUS_SINGLE=1 FORM_W4_MARITAL_STATUS_SINGLE value
                         * @property {number} FORM_W4_MARITAL_STATUS_MARRIED=2 FORM_W4_MARITAL_STATUS_MARRIED value
                         * @property {number} FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE=3 FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE value
                         */
                        FormW4.MaritalStatus = (function() {
                            var valuesById = {}, values = Object.create(valuesById);
                            values[valuesById[0] = "FORM_W4_MARITAL_STATUS_INVALID"] = 0;
                            values[valuesById[1] = "FORM_W4_MARITAL_STATUS_SINGLE"] = 1;
                            values[valuesById[2] = "FORM_W4_MARITAL_STATUS_MARRIED"] = 2;
                            values[valuesById[3] = "FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE"] = 3;
                            return values;
                        })();

                        return FormW4;
                    })();

                    return ty2019;
                })();

                taxforms.ty2020 = (function() {

                    /**
                     * Namespace ty2020.
                     * @memberof visor.common.taxforms
                     * @namespace
                     */
                    var ty2020 = {};

                    ty2020.FormW4 = (function() {

                        /**
                         * Properties of a FormW4.
                         * @memberof visor.common.taxforms.ty2020
                         * @interface IFormW4
                         * @property {string|null} [step_1aFirstMiddleName] FormW4 step_1aFirstMiddleName
                         * @property {string|null} [step_1aLastName] FormW4 step_1aLastName
                         * @property {string|null} [step_1aAddress] FormW4 step_1aAddress
                         * @property {string|null} [step_1aCityStateZip] FormW4 step_1aCityStateZip
                         * @property {string|null} [step_1b] FormW4 step_1b
                         * @property {visor.common.taxforms.ty2020.FormW4.MaritalStatus|null} [step_1c] FormW4 step_1c
                         * @property {visor.common.money.IAmount|null} [step_2b_1] FormW4 step_2b_1
                         * @property {visor.common.money.IAmount|null} [step_2b_2a] FormW4 step_2b_2a
                         * @property {visor.common.money.IAmount|null} [step_2b_2b] FormW4 step_2b_2b
                         * @property {visor.common.money.IAmount|null} [step_2b_2c] FormW4 step_2b_2c
                         * @property {visor.common.money.IAmount|null} [step_2b_3] FormW4 step_2b_3
                         * @property {visor.common.money.IAmount|null} [step_2b_4] FormW4 step_2b_4
                         * @property {boolean|null} [step_2c] FormW4 step_2c
                         * @property {visor.common.money.IAmount|null} [step_3] FormW4 step_3
                         * @property {visor.common.money.IAmount|null} [step_3_1] FormW4 step_3_1
                         * @property {visor.common.money.IAmount|null} [step_3_2] FormW4 step_3_2
                         * @property {visor.common.money.IAmount|null} [step_4a] FormW4 step_4a
                         * @property {visor.common.money.IAmount|null} [step_4b] FormW4 step_4b
                         * @property {visor.common.money.IAmount|null} [step_4b_1] FormW4 step_4b_1
                         * @property {visor.common.money.IAmount|null} [step_4b_2] FormW4 step_4b_2
                         * @property {visor.common.money.IAmount|null} [step_4b_3] FormW4 step_4b_3
                         * @property {visor.common.money.IAmount|null} [step_4b_4] FormW4 step_4b_4
                         * @property {visor.common.money.IAmount|null} [step_4b_5] FormW4 step_4b_5
                         * @property {visor.common.money.IAmount|null} [step_4c] FormW4 step_4c
                         * @property {google.protobuf.ITimestamp|null} [signatureTimestamp] FormW4 signatureTimestamp
                         * @property {Uint8Array|null} [signaturePngData] FormW4 signaturePngData
                         */

                        /**
                         * Constructs a new FormW4.
                         * @memberof visor.common.taxforms.ty2020
                         * @classdesc Represents a FormW4.
                         * @implements IFormW4
                         * @constructor
                         * @param {visor.common.taxforms.ty2020.IFormW4=} [properties] Properties to set
                         */
                        function FormW4(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FormW4 step_1aFirstMiddleName.
                         * @member {string} step_1aFirstMiddleName
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aFirstMiddleName = "";

                        /**
                         * FormW4 step_1aLastName.
                         * @member {string} step_1aLastName
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aLastName = "";

                        /**
                         * FormW4 step_1aAddress.
                         * @member {string} step_1aAddress
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aAddress = "";

                        /**
                         * FormW4 step_1aCityStateZip.
                         * @member {string} step_1aCityStateZip
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aCityStateZip = "";

                        /**
                         * FormW4 step_1b.
                         * @member {string} step_1b
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1b = "";

                        /**
                         * FormW4 step_1c.
                         * @member {visor.common.taxforms.ty2020.FormW4.MaritalStatus} step_1c
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1c = 0;

                        /**
                         * FormW4 step_2b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_1
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_1 = null;

                        /**
                         * FormW4 step_2b_2a.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2a
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2a = null;

                        /**
                         * FormW4 step_2b_2b.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2b
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2b = null;

                        /**
                         * FormW4 step_2b_2c.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2c
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2c = null;

                        /**
                         * FormW4 step_2b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_3
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_3 = null;

                        /**
                         * FormW4 step_2b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_4
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_4 = null;

                        /**
                         * FormW4 step_2c.
                         * @member {boolean} step_2c
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2c = false;

                        /**
                         * FormW4 step_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_3
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3 = null;

                        /**
                         * FormW4 step_3_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_1
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_1 = null;

                        /**
                         * FormW4 step_3_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_2
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_2 = null;

                        /**
                         * FormW4 step_4a.
                         * @member {visor.common.money.IAmount|null|undefined} step_4a
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4a = null;

                        /**
                         * FormW4 step_4b.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b = null;

                        /**
                         * FormW4 step_4b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_1
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_1 = null;

                        /**
                         * FormW4 step_4b_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_2
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_2 = null;

                        /**
                         * FormW4 step_4b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_3
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_3 = null;

                        /**
                         * FormW4 step_4b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_4
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_4 = null;

                        /**
                         * FormW4 step_4b_5.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_5
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_5 = null;

                        /**
                         * FormW4 step_4c.
                         * @member {visor.common.money.IAmount|null|undefined} step_4c
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4c = null;

                        /**
                         * FormW4 signatureTimestamp.
                         * @member {google.protobuf.ITimestamp|null|undefined} signatureTimestamp
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.signatureTimestamp = null;

                        /**
                         * FormW4 signaturePngData.
                         * @member {Uint8Array} signaturePngData
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         */
                        FormW4.prototype.signaturePngData = $util.newBuffer([]);

                        /**
                         * Creates a new FormW4 instance using the specified properties.
                         * @function create
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2020.IFormW4=} [properties] Properties to set
                         * @returns {visor.common.taxforms.ty2020.FormW4} FormW4 instance
                         */
                        FormW4.create = function create(properties) {
                            return new FormW4(properties);
                        };

                        /**
                         * Encodes the specified FormW4 message. Does not implicitly {@link visor.common.taxforms.ty2020.FormW4.verify|verify} messages.
                         * @function encode
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2020.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.step_1aFirstMiddleName);
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.step_1aLastName);
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.step_1aAddress);
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.step_1aCityStateZip);
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.step_1b);
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.step_1c);
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                $root.visor.common.money.Amount.encode(message.step_2b_1, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2a, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2b, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2c, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                $root.visor.common.money.Amount.encode(message.step_2b_3, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                $root.visor.common.money.Amount.encode(message.step_2b_4, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                $root.visor.common.money.Amount.encode(message.step_3, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                $root.visor.common.money.Amount.encode(message.step_3_1, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                $root.visor.common.money.Amount.encode(message.step_3_2, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                $root.visor.common.money.Amount.encode(message.step_4a, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                $root.visor.common.money.Amount.encode(message.step_4b, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                $root.visor.common.money.Amount.encode(message.step_4b_1, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                $root.visor.common.money.Amount.encode(message.step_4b_2, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                $root.visor.common.money.Amount.encode(message.step_4b_3, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                $root.visor.common.money.Amount.encode(message.step_4b_4, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                $root.visor.common.money.Amount.encode(message.step_4b_5, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                $root.visor.common.money.Amount.encode(message.step_4c, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                $root.google.protobuf.Timestamp.encode(message.signatureTimestamp, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                writer.uint32(/* id 25, wireType 2 =*/202).bytes(message.signaturePngData);
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                writer.uint32(/* id 26, wireType 0 =*/208).bool(message.step_2c);
                            return writer;
                        };

                        /**
                         * Encodes the specified FormW4 message, length delimited. Does not implicitly {@link visor.common.taxforms.ty2020.FormW4.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2020.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer.
                         * @function decode
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {visor.common.taxforms.ty2020.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.taxforms.ty2020.FormW4();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.step_1aFirstMiddleName = reader.string();
                                    break;
                                case 2:
                                    message.step_1aLastName = reader.string();
                                    break;
                                case 3:
                                    message.step_1aAddress = reader.string();
                                    break;
                                case 4:
                                    message.step_1aCityStateZip = reader.string();
                                    break;
                                case 5:
                                    message.step_1b = reader.string();
                                    break;
                                case 6:
                                    message.step_1c = reader.int32();
                                    break;
                                case 7:
                                    message.step_2b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.step_2b_2a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.step_2b_2b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.step_2b_2c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.step_2b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.step_2b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 26:
                                    message.step_2c = reader.bool();
                                    break;
                                case 13:
                                    message.step_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 14:
                                    message.step_3_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 15:
                                    message.step_3_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.step_4a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 17:
                                    message.step_4b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 18:
                                    message.step_4b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 19:
                                    message.step_4b_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 20:
                                    message.step_4b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 21:
                                    message.step_4b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.step_4b_5 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 23:
                                    message.step_4c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 24:
                                    message.signatureTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 25:
                                    message.signaturePngData = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {visor.common.taxforms.ty2020.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FormW4 message.
                         * @function verify
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FormW4.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                if (!$util.isString(message.step_1aFirstMiddleName))
                                    return "step_1aFirstMiddleName: string expected";
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                if (!$util.isString(message.step_1aLastName))
                                    return "step_1aLastName: string expected";
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                if (!$util.isString(message.step_1aAddress))
                                    return "step_1aAddress: string expected";
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                if (!$util.isString(message.step_1aCityStateZip))
                                    return "step_1aCityStateZip: string expected";
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                if (!$util.isString(message.step_1b))
                                    return "step_1b: string expected";
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                switch (message.step_1c) {
                                default:
                                    return "step_1c: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_1);
                                if (error)
                                    return "step_2b_1." + error;
                            }
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2a);
                                if (error)
                                    return "step_2b_2a." + error;
                            }
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2b);
                                if (error)
                                    return "step_2b_2b." + error;
                            }
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2c);
                                if (error)
                                    return "step_2b_2c." + error;
                            }
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_3);
                                if (error)
                                    return "step_2b_3." + error;
                            }
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_4);
                                if (error)
                                    return "step_2b_4." + error;
                            }
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                if (typeof message.step_2c !== "boolean")
                                    return "step_2c: boolean expected";
                            if (message.step_3 != null && message.hasOwnProperty("step_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3);
                                if (error)
                                    return "step_3." + error;
                            }
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_1);
                                if (error)
                                    return "step_3_1." + error;
                            }
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_2);
                                if (error)
                                    return "step_3_2." + error;
                            }
                            if (message.step_4a != null && message.hasOwnProperty("step_4a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4a);
                                if (error)
                                    return "step_4a." + error;
                            }
                            if (message.step_4b != null && message.hasOwnProperty("step_4b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b);
                                if (error)
                                    return "step_4b." + error;
                            }
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_1);
                                if (error)
                                    return "step_4b_1." + error;
                            }
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_2);
                                if (error)
                                    return "step_4b_2." + error;
                            }
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_3);
                                if (error)
                                    return "step_4b_3." + error;
                            }
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_4);
                                if (error)
                                    return "step_4b_4." + error;
                            }
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_5);
                                if (error)
                                    return "step_4b_5." + error;
                            }
                            if (message.step_4c != null && message.hasOwnProperty("step_4c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4c);
                                if (error)
                                    return "step_4c." + error;
                            }
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.signatureTimestamp);
                                if (error)
                                    return "signatureTimestamp." + error;
                            }
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                if (!(message.signaturePngData && typeof message.signaturePngData.length === "number" || $util.isString(message.signaturePngData)))
                                    return "signaturePngData: buffer expected";
                            return null;
                        };

                        /**
                         * Creates a FormW4 message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {visor.common.taxforms.ty2020.FormW4} FormW4
                         */
                        FormW4.fromObject = function fromObject(object) {
                            if (object instanceof $root.visor.common.taxforms.ty2020.FormW4)
                                return object;
                            var message = new $root.visor.common.taxforms.ty2020.FormW4();
                            if (object.step_1aFirstMiddleName != null)
                                message.step_1aFirstMiddleName = String(object.step_1aFirstMiddleName);
                            if (object.step_1aLastName != null)
                                message.step_1aLastName = String(object.step_1aLastName);
                            if (object.step_1aAddress != null)
                                message.step_1aAddress = String(object.step_1aAddress);
                            if (object.step_1aCityStateZip != null)
                                message.step_1aCityStateZip = String(object.step_1aCityStateZip);
                            if (object.step_1b != null)
                                message.step_1b = String(object.step_1b);
                            switch (object.step_1c) {
                            case "FORM_W4_MARITAL_STATUS_INVALID":
                            case 0:
                                message.step_1c = 0;
                                break;
                            case "FORM_W4_MARITAL_STATUS_SINGLE":
                            case 1:
                                message.step_1c = 1;
                                break;
                            case "FORM_W4_MARITAL_STATUS_MARRIED":
                            case 2:
                                message.step_1c = 2;
                                break;
                            case "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD":
                            case 3:
                                message.step_1c = 3;
                                break;
                            }
                            if (object.step_2b_1 != null) {
                                if (typeof object.step_2b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_2b_1: object expected");
                                message.step_2b_1 = $root.visor.common.money.Amount.fromObject(object.step_2b_1);
                            }
                            if (object.step_2b_2a != null) {
                                if (typeof object.step_2b_2a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_2b_2a: object expected");
                                message.step_2b_2a = $root.visor.common.money.Amount.fromObject(object.step_2b_2a);
                            }
                            if (object.step_2b_2b != null) {
                                if (typeof object.step_2b_2b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_2b_2b: object expected");
                                message.step_2b_2b = $root.visor.common.money.Amount.fromObject(object.step_2b_2b);
                            }
                            if (object.step_2b_2c != null) {
                                if (typeof object.step_2b_2c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_2b_2c: object expected");
                                message.step_2b_2c = $root.visor.common.money.Amount.fromObject(object.step_2b_2c);
                            }
                            if (object.step_2b_3 != null) {
                                if (typeof object.step_2b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_2b_3: object expected");
                                message.step_2b_3 = $root.visor.common.money.Amount.fromObject(object.step_2b_3);
                            }
                            if (object.step_2b_4 != null) {
                                if (typeof object.step_2b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_2b_4: object expected");
                                message.step_2b_4 = $root.visor.common.money.Amount.fromObject(object.step_2b_4);
                            }
                            if (object.step_2c != null)
                                message.step_2c = Boolean(object.step_2c);
                            if (object.step_3 != null) {
                                if (typeof object.step_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_3: object expected");
                                message.step_3 = $root.visor.common.money.Amount.fromObject(object.step_3);
                            }
                            if (object.step_3_1 != null) {
                                if (typeof object.step_3_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_3_1: object expected");
                                message.step_3_1 = $root.visor.common.money.Amount.fromObject(object.step_3_1);
                            }
                            if (object.step_3_2 != null) {
                                if (typeof object.step_3_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_3_2: object expected");
                                message.step_3_2 = $root.visor.common.money.Amount.fromObject(object.step_3_2);
                            }
                            if (object.step_4a != null) {
                                if (typeof object.step_4a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4a: object expected");
                                message.step_4a = $root.visor.common.money.Amount.fromObject(object.step_4a);
                            }
                            if (object.step_4b != null) {
                                if (typeof object.step_4b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4b: object expected");
                                message.step_4b = $root.visor.common.money.Amount.fromObject(object.step_4b);
                            }
                            if (object.step_4b_1 != null) {
                                if (typeof object.step_4b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4b_1: object expected");
                                message.step_4b_1 = $root.visor.common.money.Amount.fromObject(object.step_4b_1);
                            }
                            if (object.step_4b_2 != null) {
                                if (typeof object.step_4b_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4b_2: object expected");
                                message.step_4b_2 = $root.visor.common.money.Amount.fromObject(object.step_4b_2);
                            }
                            if (object.step_4b_3 != null) {
                                if (typeof object.step_4b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4b_3: object expected");
                                message.step_4b_3 = $root.visor.common.money.Amount.fromObject(object.step_4b_3);
                            }
                            if (object.step_4b_4 != null) {
                                if (typeof object.step_4b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4b_4: object expected");
                                message.step_4b_4 = $root.visor.common.money.Amount.fromObject(object.step_4b_4);
                            }
                            if (object.step_4b_5 != null) {
                                if (typeof object.step_4b_5 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4b_5: object expected");
                                message.step_4b_5 = $root.visor.common.money.Amount.fromObject(object.step_4b_5);
                            }
                            if (object.step_4c != null) {
                                if (typeof object.step_4c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2020.FormW4.step_4c: object expected");
                                message.step_4c = $root.visor.common.money.Amount.fromObject(object.step_4c);
                            }
                            if (object.signatureTimestamp != null)
                                if (object.signatureTimestamp === null)
                                    message.signatureTimestamp = {};
                                else
                                    message.signatureTimestamp = {
                                        seconds: Math.floor(object.signatureTimestamp.getTime() / 1000),
                                        nanos: object.signatureTimestamp.getMilliseconds() * 1000000
                                    };
                            if (object.signaturePngData != null)
                                if (typeof object.signaturePngData === "string")
                                    $util.base64.decode(object.signaturePngData, message.signaturePngData = $util.newBuffer($util.base64.length(object.signaturePngData)), 0);
                                else if (object.signaturePngData.length)
                                    message.signaturePngData = object.signaturePngData;
                            return message;
                        };

                        /**
                         * Creates a plain object from a FormW4 message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2020.FormW4} message FormW4
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FormW4.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.step_1aFirstMiddleName = "";
                                object.step_1aLastName = "";
                                object.step_1aAddress = "";
                                object.step_1aCityStateZip = "";
                                object.step_1b = "";
                                object.step_1c = options.enums === String ? "FORM_W4_MARITAL_STATUS_INVALID" : 0;
                                object.step_2b_1 = null;
                                object.step_2b_2a = null;
                                object.step_2b_2b = null;
                                object.step_2b_2c = null;
                                object.step_2b_3 = null;
                                object.step_2b_4 = null;
                                object.step_3 = null;
                                object.step_3_1 = null;
                                object.step_3_2 = null;
                                object.step_4a = null;
                                object.step_4b = null;
                                object.step_4b_1 = null;
                                object.step_4b_2 = null;
                                object.step_4b_3 = null;
                                object.step_4b_4 = null;
                                object.step_4b_5 = null;
                                object.step_4c = null;
                                object.signatureTimestamp = null;
                                if (options.bytes === String)
                                    object.signaturePngData = "";
                                else {
                                    object.signaturePngData = [];
                                    if (options.bytes !== Array)
                                        object.signaturePngData = $util.newBuffer(object.signaturePngData);
                                }
                                object.step_2c = false;
                            }
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                object.step_1aFirstMiddleName = message.step_1aFirstMiddleName;
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                object.step_1aLastName = message.step_1aLastName;
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                object.step_1aAddress = message.step_1aAddress;
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                object.step_1aCityStateZip = message.step_1aCityStateZip;
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                object.step_1b = message.step_1b;
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                object.step_1c = options.enums === String ? $root.visor.common.taxforms.ty2020.FormW4.MaritalStatus[message.step_1c] : message.step_1c;
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                object.step_2b_1 = $root.visor.common.money.Amount.toObject(message.step_2b_1, options);
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                object.step_2b_2a = $root.visor.common.money.Amount.toObject(message.step_2b_2a, options);
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                object.step_2b_2b = $root.visor.common.money.Amount.toObject(message.step_2b_2b, options);
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                object.step_2b_2c = $root.visor.common.money.Amount.toObject(message.step_2b_2c, options);
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                object.step_2b_3 = $root.visor.common.money.Amount.toObject(message.step_2b_3, options);
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                object.step_2b_4 = $root.visor.common.money.Amount.toObject(message.step_2b_4, options);
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                object.step_3 = $root.visor.common.money.Amount.toObject(message.step_3, options);
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                object.step_3_1 = $root.visor.common.money.Amount.toObject(message.step_3_1, options);
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                object.step_3_2 = $root.visor.common.money.Amount.toObject(message.step_3_2, options);
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                object.step_4a = $root.visor.common.money.Amount.toObject(message.step_4a, options);
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                object.step_4b = $root.visor.common.money.Amount.toObject(message.step_4b, options);
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                object.step_4b_1 = $root.visor.common.money.Amount.toObject(message.step_4b_1, options);
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                object.step_4b_2 = $root.visor.common.money.Amount.toObject(message.step_4b_2, options);
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                object.step_4b_3 = $root.visor.common.money.Amount.toObject(message.step_4b_3, options);
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                object.step_4b_4 = $root.visor.common.money.Amount.toObject(message.step_4b_4, options);
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                object.step_4b_5 = $root.visor.common.money.Amount.toObject(message.step_4b_5, options);
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                object.step_4c = $root.visor.common.money.Amount.toObject(message.step_4c, options);
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                object.signatureTimestamp = new Date(message.signatureTimestamp.seconds * 1000 + message.signatureTimestamp.nanos / 1000000);
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                object.signaturePngData = options.bytes === String ? $util.base64.encode(message.signaturePngData, 0, message.signaturePngData.length) : options.bytes === Array ? Array.prototype.slice.call(message.signaturePngData) : message.signaturePngData;
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                object.step_2c = message.step_2c;
                            return object;
                        };

                        /**
                         * Converts this FormW4 to JSON.
                         * @function toJSON
                         * @memberof visor.common.taxforms.ty2020.FormW4
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FormW4.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * MaritalStatus enum.
                         * @name visor.common.taxforms.ty2020.FormW4.MaritalStatus
                         * @enum {string}
                         * @property {number} FORM_W4_MARITAL_STATUS_INVALID=0 FORM_W4_MARITAL_STATUS_INVALID value
                         * @property {number} FORM_W4_MARITAL_STATUS_SINGLE=1 FORM_W4_MARITAL_STATUS_SINGLE value
                         * @property {number} FORM_W4_MARITAL_STATUS_MARRIED=2 FORM_W4_MARITAL_STATUS_MARRIED value
                         * @property {number} FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD=3 FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD value
                         */
                        FormW4.MaritalStatus = (function() {
                            var valuesById = {}, values = Object.create(valuesById);
                            values[valuesById[0] = "FORM_W4_MARITAL_STATUS_INVALID"] = 0;
                            values[valuesById[1] = "FORM_W4_MARITAL_STATUS_SINGLE"] = 1;
                            values[valuesById[2] = "FORM_W4_MARITAL_STATUS_MARRIED"] = 2;
                            values[valuesById[3] = "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD"] = 3;
                            return values;
                        })();

                        return FormW4;
                    })();

                    return ty2020;
                })();

                taxforms.ty2021 = (function() {

                    /**
                     * Namespace ty2021.
                     * @memberof visor.common.taxforms
                     * @namespace
                     */
                    var ty2021 = {};

                    ty2021.FormW4 = (function() {

                        /**
                         * Properties of a FormW4.
                         * @memberof visor.common.taxforms.ty2021
                         * @interface IFormW4
                         * @property {string|null} [step_1aFirstMiddleName] FormW4 step_1aFirstMiddleName
                         * @property {string|null} [step_1aLastName] FormW4 step_1aLastName
                         * @property {string|null} [step_1aAddress] FormW4 step_1aAddress
                         * @property {string|null} [step_1aCityStateZip] FormW4 step_1aCityStateZip
                         * @property {string|null} [step_1b] FormW4 step_1b
                         * @property {visor.common.taxforms.ty2021.FormW4.MaritalStatus|null} [step_1c] FormW4 step_1c
                         * @property {visor.common.money.IAmount|null} [step_2b_1] FormW4 step_2b_1
                         * @property {visor.common.money.IAmount|null} [step_2b_2a] FormW4 step_2b_2a
                         * @property {visor.common.money.IAmount|null} [step_2b_2b] FormW4 step_2b_2b
                         * @property {visor.common.money.IAmount|null} [step_2b_2c] FormW4 step_2b_2c
                         * @property {visor.common.money.IAmount|null} [step_2b_3] FormW4 step_2b_3
                         * @property {visor.common.money.IAmount|null} [step_2b_4] FormW4 step_2b_4
                         * @property {boolean|null} [step_2c] FormW4 step_2c
                         * @property {visor.common.money.IAmount|null} [step_3] FormW4 step_3
                         * @property {visor.common.money.IAmount|null} [step_3_1] FormW4 step_3_1
                         * @property {visor.common.money.IAmount|null} [step_3_2] FormW4 step_3_2
                         * @property {visor.common.money.IAmount|null} [step_4a] FormW4 step_4a
                         * @property {visor.common.money.IAmount|null} [step_4b] FormW4 step_4b
                         * @property {visor.common.money.IAmount|null} [step_4b_1] FormW4 step_4b_1
                         * @property {visor.common.money.IAmount|null} [step_4b_2] FormW4 step_4b_2
                         * @property {visor.common.money.IAmount|null} [step_4b_3] FormW4 step_4b_3
                         * @property {visor.common.money.IAmount|null} [step_4b_4] FormW4 step_4b_4
                         * @property {visor.common.money.IAmount|null} [step_4b_5] FormW4 step_4b_5
                         * @property {visor.common.money.IAmount|null} [step_4c] FormW4 step_4c
                         * @property {google.protobuf.ITimestamp|null} [signatureTimestamp] FormW4 signatureTimestamp
                         * @property {Uint8Array|null} [signaturePngData] FormW4 signaturePngData
                         */

                        /**
                         * Constructs a new FormW4.
                         * @memberof visor.common.taxforms.ty2021
                         * @classdesc Represents a FormW4.
                         * @implements IFormW4
                         * @constructor
                         * @param {visor.common.taxforms.ty2021.IFormW4=} [properties] Properties to set
                         */
                        function FormW4(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FormW4 step_1aFirstMiddleName.
                         * @member {string} step_1aFirstMiddleName
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aFirstMiddleName = "";

                        /**
                         * FormW4 step_1aLastName.
                         * @member {string} step_1aLastName
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aLastName = "";

                        /**
                         * FormW4 step_1aAddress.
                         * @member {string} step_1aAddress
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aAddress = "";

                        /**
                         * FormW4 step_1aCityStateZip.
                         * @member {string} step_1aCityStateZip
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aCityStateZip = "";

                        /**
                         * FormW4 step_1b.
                         * @member {string} step_1b
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1b = "";

                        /**
                         * FormW4 step_1c.
                         * @member {visor.common.taxforms.ty2021.FormW4.MaritalStatus} step_1c
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1c = 0;

                        /**
                         * FormW4 step_2b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_1
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_1 = null;

                        /**
                         * FormW4 step_2b_2a.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2a
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2a = null;

                        /**
                         * FormW4 step_2b_2b.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2b
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2b = null;

                        /**
                         * FormW4 step_2b_2c.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2c
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2c = null;

                        /**
                         * FormW4 step_2b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_3
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_3 = null;

                        /**
                         * FormW4 step_2b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_4
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_4 = null;

                        /**
                         * FormW4 step_2c.
                         * @member {boolean} step_2c
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2c = false;

                        /**
                         * FormW4 step_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_3
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3 = null;

                        /**
                         * FormW4 step_3_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_1
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_1 = null;

                        /**
                         * FormW4 step_3_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_2
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_2 = null;

                        /**
                         * FormW4 step_4a.
                         * @member {visor.common.money.IAmount|null|undefined} step_4a
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4a = null;

                        /**
                         * FormW4 step_4b.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b = null;

                        /**
                         * FormW4 step_4b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_1
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_1 = null;

                        /**
                         * FormW4 step_4b_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_2
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_2 = null;

                        /**
                         * FormW4 step_4b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_3
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_3 = null;

                        /**
                         * FormW4 step_4b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_4
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_4 = null;

                        /**
                         * FormW4 step_4b_5.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_5
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_5 = null;

                        /**
                         * FormW4 step_4c.
                         * @member {visor.common.money.IAmount|null|undefined} step_4c
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4c = null;

                        /**
                         * FormW4 signatureTimestamp.
                         * @member {google.protobuf.ITimestamp|null|undefined} signatureTimestamp
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.signatureTimestamp = null;

                        /**
                         * FormW4 signaturePngData.
                         * @member {Uint8Array} signaturePngData
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         */
                        FormW4.prototype.signaturePngData = $util.newBuffer([]);

                        /**
                         * Creates a new FormW4 instance using the specified properties.
                         * @function create
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2021.IFormW4=} [properties] Properties to set
                         * @returns {visor.common.taxforms.ty2021.FormW4} FormW4 instance
                         */
                        FormW4.create = function create(properties) {
                            return new FormW4(properties);
                        };

                        /**
                         * Encodes the specified FormW4 message. Does not implicitly {@link visor.common.taxforms.ty2021.FormW4.verify|verify} messages.
                         * @function encode
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2021.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.step_1aFirstMiddleName);
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.step_1aLastName);
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.step_1aAddress);
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.step_1aCityStateZip);
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.step_1b);
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.step_1c);
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                $root.visor.common.money.Amount.encode(message.step_2b_1, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2a, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2b, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2c, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                $root.visor.common.money.Amount.encode(message.step_2b_3, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                $root.visor.common.money.Amount.encode(message.step_2b_4, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                $root.visor.common.money.Amount.encode(message.step_3, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                $root.visor.common.money.Amount.encode(message.step_3_1, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                $root.visor.common.money.Amount.encode(message.step_3_2, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                $root.visor.common.money.Amount.encode(message.step_4a, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                $root.visor.common.money.Amount.encode(message.step_4b, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                $root.visor.common.money.Amount.encode(message.step_4b_1, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                $root.visor.common.money.Amount.encode(message.step_4b_2, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                $root.visor.common.money.Amount.encode(message.step_4b_3, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                $root.visor.common.money.Amount.encode(message.step_4b_4, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                $root.visor.common.money.Amount.encode(message.step_4b_5, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                $root.visor.common.money.Amount.encode(message.step_4c, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                $root.google.protobuf.Timestamp.encode(message.signatureTimestamp, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                writer.uint32(/* id 25, wireType 2 =*/202).bytes(message.signaturePngData);
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                writer.uint32(/* id 26, wireType 0 =*/208).bool(message.step_2c);
                            return writer;
                        };

                        /**
                         * Encodes the specified FormW4 message, length delimited. Does not implicitly {@link visor.common.taxforms.ty2021.FormW4.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2021.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer.
                         * @function decode
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {visor.common.taxforms.ty2021.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.taxforms.ty2021.FormW4();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.step_1aFirstMiddleName = reader.string();
                                    break;
                                case 2:
                                    message.step_1aLastName = reader.string();
                                    break;
                                case 3:
                                    message.step_1aAddress = reader.string();
                                    break;
                                case 4:
                                    message.step_1aCityStateZip = reader.string();
                                    break;
                                case 5:
                                    message.step_1b = reader.string();
                                    break;
                                case 6:
                                    message.step_1c = reader.int32();
                                    break;
                                case 7:
                                    message.step_2b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.step_2b_2a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.step_2b_2b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.step_2b_2c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.step_2b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.step_2b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 26:
                                    message.step_2c = reader.bool();
                                    break;
                                case 13:
                                    message.step_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 14:
                                    message.step_3_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 15:
                                    message.step_3_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.step_4a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 17:
                                    message.step_4b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 18:
                                    message.step_4b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 19:
                                    message.step_4b_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 20:
                                    message.step_4b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 21:
                                    message.step_4b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.step_4b_5 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 23:
                                    message.step_4c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 24:
                                    message.signatureTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 25:
                                    message.signaturePngData = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {visor.common.taxforms.ty2021.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FormW4 message.
                         * @function verify
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FormW4.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                if (!$util.isString(message.step_1aFirstMiddleName))
                                    return "step_1aFirstMiddleName: string expected";
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                if (!$util.isString(message.step_1aLastName))
                                    return "step_1aLastName: string expected";
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                if (!$util.isString(message.step_1aAddress))
                                    return "step_1aAddress: string expected";
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                if (!$util.isString(message.step_1aCityStateZip))
                                    return "step_1aCityStateZip: string expected";
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                if (!$util.isString(message.step_1b))
                                    return "step_1b: string expected";
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                switch (message.step_1c) {
                                default:
                                    return "step_1c: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_1);
                                if (error)
                                    return "step_2b_1." + error;
                            }
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2a);
                                if (error)
                                    return "step_2b_2a." + error;
                            }
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2b);
                                if (error)
                                    return "step_2b_2b." + error;
                            }
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2c);
                                if (error)
                                    return "step_2b_2c." + error;
                            }
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_3);
                                if (error)
                                    return "step_2b_3." + error;
                            }
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_4);
                                if (error)
                                    return "step_2b_4." + error;
                            }
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                if (typeof message.step_2c !== "boolean")
                                    return "step_2c: boolean expected";
                            if (message.step_3 != null && message.hasOwnProperty("step_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3);
                                if (error)
                                    return "step_3." + error;
                            }
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_1);
                                if (error)
                                    return "step_3_1." + error;
                            }
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_2);
                                if (error)
                                    return "step_3_2." + error;
                            }
                            if (message.step_4a != null && message.hasOwnProperty("step_4a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4a);
                                if (error)
                                    return "step_4a." + error;
                            }
                            if (message.step_4b != null && message.hasOwnProperty("step_4b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b);
                                if (error)
                                    return "step_4b." + error;
                            }
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_1);
                                if (error)
                                    return "step_4b_1." + error;
                            }
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_2);
                                if (error)
                                    return "step_4b_2." + error;
                            }
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_3);
                                if (error)
                                    return "step_4b_3." + error;
                            }
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_4);
                                if (error)
                                    return "step_4b_4." + error;
                            }
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_5);
                                if (error)
                                    return "step_4b_5." + error;
                            }
                            if (message.step_4c != null && message.hasOwnProperty("step_4c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4c);
                                if (error)
                                    return "step_4c." + error;
                            }
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.signatureTimestamp);
                                if (error)
                                    return "signatureTimestamp." + error;
                            }
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                if (!(message.signaturePngData && typeof message.signaturePngData.length === "number" || $util.isString(message.signaturePngData)))
                                    return "signaturePngData: buffer expected";
                            return null;
                        };

                        /**
                         * Creates a FormW4 message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {visor.common.taxforms.ty2021.FormW4} FormW4
                         */
                        FormW4.fromObject = function fromObject(object) {
                            if (object instanceof $root.visor.common.taxforms.ty2021.FormW4)
                                return object;
                            var message = new $root.visor.common.taxforms.ty2021.FormW4();
                            if (object.step_1aFirstMiddleName != null)
                                message.step_1aFirstMiddleName = String(object.step_1aFirstMiddleName);
                            if (object.step_1aLastName != null)
                                message.step_1aLastName = String(object.step_1aLastName);
                            if (object.step_1aAddress != null)
                                message.step_1aAddress = String(object.step_1aAddress);
                            if (object.step_1aCityStateZip != null)
                                message.step_1aCityStateZip = String(object.step_1aCityStateZip);
                            if (object.step_1b != null)
                                message.step_1b = String(object.step_1b);
                            switch (object.step_1c) {
                            case "FORM_W4_MARITAL_STATUS_INVALID":
                            case 0:
                                message.step_1c = 0;
                                break;
                            case "FORM_W4_MARITAL_STATUS_SINGLE":
                            case 1:
                                message.step_1c = 1;
                                break;
                            case "FORM_W4_MARITAL_STATUS_MARRIED":
                            case 2:
                                message.step_1c = 2;
                                break;
                            case "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD":
                            case 3:
                                message.step_1c = 3;
                                break;
                            }
                            if (object.step_2b_1 != null) {
                                if (typeof object.step_2b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_2b_1: object expected");
                                message.step_2b_1 = $root.visor.common.money.Amount.fromObject(object.step_2b_1);
                            }
                            if (object.step_2b_2a != null) {
                                if (typeof object.step_2b_2a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_2b_2a: object expected");
                                message.step_2b_2a = $root.visor.common.money.Amount.fromObject(object.step_2b_2a);
                            }
                            if (object.step_2b_2b != null) {
                                if (typeof object.step_2b_2b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_2b_2b: object expected");
                                message.step_2b_2b = $root.visor.common.money.Amount.fromObject(object.step_2b_2b);
                            }
                            if (object.step_2b_2c != null) {
                                if (typeof object.step_2b_2c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_2b_2c: object expected");
                                message.step_2b_2c = $root.visor.common.money.Amount.fromObject(object.step_2b_2c);
                            }
                            if (object.step_2b_3 != null) {
                                if (typeof object.step_2b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_2b_3: object expected");
                                message.step_2b_3 = $root.visor.common.money.Amount.fromObject(object.step_2b_3);
                            }
                            if (object.step_2b_4 != null) {
                                if (typeof object.step_2b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_2b_4: object expected");
                                message.step_2b_4 = $root.visor.common.money.Amount.fromObject(object.step_2b_4);
                            }
                            if (object.step_2c != null)
                                message.step_2c = Boolean(object.step_2c);
                            if (object.step_3 != null) {
                                if (typeof object.step_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_3: object expected");
                                message.step_3 = $root.visor.common.money.Amount.fromObject(object.step_3);
                            }
                            if (object.step_3_1 != null) {
                                if (typeof object.step_3_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_3_1: object expected");
                                message.step_3_1 = $root.visor.common.money.Amount.fromObject(object.step_3_1);
                            }
                            if (object.step_3_2 != null) {
                                if (typeof object.step_3_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_3_2: object expected");
                                message.step_3_2 = $root.visor.common.money.Amount.fromObject(object.step_3_2);
                            }
                            if (object.step_4a != null) {
                                if (typeof object.step_4a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4a: object expected");
                                message.step_4a = $root.visor.common.money.Amount.fromObject(object.step_4a);
                            }
                            if (object.step_4b != null) {
                                if (typeof object.step_4b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4b: object expected");
                                message.step_4b = $root.visor.common.money.Amount.fromObject(object.step_4b);
                            }
                            if (object.step_4b_1 != null) {
                                if (typeof object.step_4b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4b_1: object expected");
                                message.step_4b_1 = $root.visor.common.money.Amount.fromObject(object.step_4b_1);
                            }
                            if (object.step_4b_2 != null) {
                                if (typeof object.step_4b_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4b_2: object expected");
                                message.step_4b_2 = $root.visor.common.money.Amount.fromObject(object.step_4b_2);
                            }
                            if (object.step_4b_3 != null) {
                                if (typeof object.step_4b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4b_3: object expected");
                                message.step_4b_3 = $root.visor.common.money.Amount.fromObject(object.step_4b_3);
                            }
                            if (object.step_4b_4 != null) {
                                if (typeof object.step_4b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4b_4: object expected");
                                message.step_4b_4 = $root.visor.common.money.Amount.fromObject(object.step_4b_4);
                            }
                            if (object.step_4b_5 != null) {
                                if (typeof object.step_4b_5 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4b_5: object expected");
                                message.step_4b_5 = $root.visor.common.money.Amount.fromObject(object.step_4b_5);
                            }
                            if (object.step_4c != null) {
                                if (typeof object.step_4c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2021.FormW4.step_4c: object expected");
                                message.step_4c = $root.visor.common.money.Amount.fromObject(object.step_4c);
                            }
                            if (object.signatureTimestamp != null)
                                if (object.signatureTimestamp === null)
                                    message.signatureTimestamp = {};
                                else
                                    message.signatureTimestamp = {
                                        seconds: Math.floor(object.signatureTimestamp.getTime() / 1000),
                                        nanos: object.signatureTimestamp.getMilliseconds() * 1000000
                                    };
                            if (object.signaturePngData != null)
                                if (typeof object.signaturePngData === "string")
                                    $util.base64.decode(object.signaturePngData, message.signaturePngData = $util.newBuffer($util.base64.length(object.signaturePngData)), 0);
                                else if (object.signaturePngData.length)
                                    message.signaturePngData = object.signaturePngData;
                            return message;
                        };

                        /**
                         * Creates a plain object from a FormW4 message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2021.FormW4} message FormW4
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FormW4.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.step_1aFirstMiddleName = "";
                                object.step_1aLastName = "";
                                object.step_1aAddress = "";
                                object.step_1aCityStateZip = "";
                                object.step_1b = "";
                                object.step_1c = options.enums === String ? "FORM_W4_MARITAL_STATUS_INVALID" : 0;
                                object.step_2b_1 = null;
                                object.step_2b_2a = null;
                                object.step_2b_2b = null;
                                object.step_2b_2c = null;
                                object.step_2b_3 = null;
                                object.step_2b_4 = null;
                                object.step_3 = null;
                                object.step_3_1 = null;
                                object.step_3_2 = null;
                                object.step_4a = null;
                                object.step_4b = null;
                                object.step_4b_1 = null;
                                object.step_4b_2 = null;
                                object.step_4b_3 = null;
                                object.step_4b_4 = null;
                                object.step_4b_5 = null;
                                object.step_4c = null;
                                object.signatureTimestamp = null;
                                if (options.bytes === String)
                                    object.signaturePngData = "";
                                else {
                                    object.signaturePngData = [];
                                    if (options.bytes !== Array)
                                        object.signaturePngData = $util.newBuffer(object.signaturePngData);
                                }
                                object.step_2c = false;
                            }
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                object.step_1aFirstMiddleName = message.step_1aFirstMiddleName;
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                object.step_1aLastName = message.step_1aLastName;
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                object.step_1aAddress = message.step_1aAddress;
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                object.step_1aCityStateZip = message.step_1aCityStateZip;
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                object.step_1b = message.step_1b;
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                object.step_1c = options.enums === String ? $root.visor.common.taxforms.ty2021.FormW4.MaritalStatus[message.step_1c] : message.step_1c;
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                object.step_2b_1 = $root.visor.common.money.Amount.toObject(message.step_2b_1, options);
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                object.step_2b_2a = $root.visor.common.money.Amount.toObject(message.step_2b_2a, options);
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                object.step_2b_2b = $root.visor.common.money.Amount.toObject(message.step_2b_2b, options);
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                object.step_2b_2c = $root.visor.common.money.Amount.toObject(message.step_2b_2c, options);
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                object.step_2b_3 = $root.visor.common.money.Amount.toObject(message.step_2b_3, options);
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                object.step_2b_4 = $root.visor.common.money.Amount.toObject(message.step_2b_4, options);
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                object.step_3 = $root.visor.common.money.Amount.toObject(message.step_3, options);
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                object.step_3_1 = $root.visor.common.money.Amount.toObject(message.step_3_1, options);
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                object.step_3_2 = $root.visor.common.money.Amount.toObject(message.step_3_2, options);
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                object.step_4a = $root.visor.common.money.Amount.toObject(message.step_4a, options);
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                object.step_4b = $root.visor.common.money.Amount.toObject(message.step_4b, options);
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                object.step_4b_1 = $root.visor.common.money.Amount.toObject(message.step_4b_1, options);
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                object.step_4b_2 = $root.visor.common.money.Amount.toObject(message.step_4b_2, options);
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                object.step_4b_3 = $root.visor.common.money.Amount.toObject(message.step_4b_3, options);
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                object.step_4b_4 = $root.visor.common.money.Amount.toObject(message.step_4b_4, options);
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                object.step_4b_5 = $root.visor.common.money.Amount.toObject(message.step_4b_5, options);
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                object.step_4c = $root.visor.common.money.Amount.toObject(message.step_4c, options);
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                object.signatureTimestamp = new Date(message.signatureTimestamp.seconds * 1000 + message.signatureTimestamp.nanos / 1000000);
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                object.signaturePngData = options.bytes === String ? $util.base64.encode(message.signaturePngData, 0, message.signaturePngData.length) : options.bytes === Array ? Array.prototype.slice.call(message.signaturePngData) : message.signaturePngData;
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                object.step_2c = message.step_2c;
                            return object;
                        };

                        /**
                         * Converts this FormW4 to JSON.
                         * @function toJSON
                         * @memberof visor.common.taxforms.ty2021.FormW4
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FormW4.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * MaritalStatus enum.
                         * @name visor.common.taxforms.ty2021.FormW4.MaritalStatus
                         * @enum {string}
                         * @property {number} FORM_W4_MARITAL_STATUS_INVALID=0 FORM_W4_MARITAL_STATUS_INVALID value
                         * @property {number} FORM_W4_MARITAL_STATUS_SINGLE=1 FORM_W4_MARITAL_STATUS_SINGLE value
                         * @property {number} FORM_W4_MARITAL_STATUS_MARRIED=2 FORM_W4_MARITAL_STATUS_MARRIED value
                         * @property {number} FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD=3 FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD value
                         */
                        FormW4.MaritalStatus = (function() {
                            var valuesById = {}, values = Object.create(valuesById);
                            values[valuesById[0] = "FORM_W4_MARITAL_STATUS_INVALID"] = 0;
                            values[valuesById[1] = "FORM_W4_MARITAL_STATUS_SINGLE"] = 1;
                            values[valuesById[2] = "FORM_W4_MARITAL_STATUS_MARRIED"] = 2;
                            values[valuesById[3] = "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD"] = 3;
                            return values;
                        })();

                        return FormW4;
                    })();

                    return ty2021;
                })();

                taxforms.ty2022 = (function() {

                    /**
                     * Namespace ty2022.
                     * @memberof visor.common.taxforms
                     * @namespace
                     */
                    var ty2022 = {};

                    ty2022.FormW4 = (function() {

                        /**
                         * Properties of a FormW4.
                         * @memberof visor.common.taxforms.ty2022
                         * @interface IFormW4
                         * @property {string|null} [step_1aFirstMiddleName] FormW4 step_1aFirstMiddleName
                         * @property {string|null} [step_1aLastName] FormW4 step_1aLastName
                         * @property {string|null} [step_1aAddress] FormW4 step_1aAddress
                         * @property {string|null} [step_1aCityStateZip] FormW4 step_1aCityStateZip
                         * @property {string|null} [step_1b] FormW4 step_1b
                         * @property {visor.common.taxforms.ty2022.FormW4.MaritalStatus|null} [step_1c] FormW4 step_1c
                         * @property {visor.common.money.IAmount|null} [step_2b_1] FormW4 step_2b_1
                         * @property {visor.common.money.IAmount|null} [step_2b_2a] FormW4 step_2b_2a
                         * @property {visor.common.money.IAmount|null} [step_2b_2b] FormW4 step_2b_2b
                         * @property {visor.common.money.IAmount|null} [step_2b_2c] FormW4 step_2b_2c
                         * @property {visor.common.money.IAmount|null} [step_2b_3] FormW4 step_2b_3
                         * @property {visor.common.money.IAmount|null} [step_2b_4] FormW4 step_2b_4
                         * @property {boolean|null} [step_2c] FormW4 step_2c
                         * @property {visor.common.money.IAmount|null} [step_3] FormW4 step_3
                         * @property {visor.common.money.IAmount|null} [step_3_1] FormW4 step_3_1
                         * @property {visor.common.money.IAmount|null} [step_3_2] FormW4 step_3_2
                         * @property {visor.common.money.IAmount|null} [step_4a] FormW4 step_4a
                         * @property {visor.common.money.IAmount|null} [step_4b] FormW4 step_4b
                         * @property {visor.common.money.IAmount|null} [step_4b_1] FormW4 step_4b_1
                         * @property {visor.common.money.IAmount|null} [step_4b_2] FormW4 step_4b_2
                         * @property {visor.common.money.IAmount|null} [step_4b_3] FormW4 step_4b_3
                         * @property {visor.common.money.IAmount|null} [step_4b_4] FormW4 step_4b_4
                         * @property {visor.common.money.IAmount|null} [step_4b_5] FormW4 step_4b_5
                         * @property {visor.common.money.IAmount|null} [step_4c] FormW4 step_4c
                         * @property {google.protobuf.ITimestamp|null} [signatureTimestamp] FormW4 signatureTimestamp
                         * @property {Uint8Array|null} [signaturePngData] FormW4 signaturePngData
                         */

                        /**
                         * Constructs a new FormW4.
                         * @memberof visor.common.taxforms.ty2022
                         * @classdesc Represents a FormW4.
                         * @implements IFormW4
                         * @constructor
                         * @param {visor.common.taxforms.ty2022.IFormW4=} [properties] Properties to set
                         */
                        function FormW4(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FormW4 step_1aFirstMiddleName.
                         * @member {string} step_1aFirstMiddleName
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aFirstMiddleName = "";

                        /**
                         * FormW4 step_1aLastName.
                         * @member {string} step_1aLastName
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aLastName = "";

                        /**
                         * FormW4 step_1aAddress.
                         * @member {string} step_1aAddress
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aAddress = "";

                        /**
                         * FormW4 step_1aCityStateZip.
                         * @member {string} step_1aCityStateZip
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aCityStateZip = "";

                        /**
                         * FormW4 step_1b.
                         * @member {string} step_1b
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1b = "";

                        /**
                         * FormW4 step_1c.
                         * @member {visor.common.taxforms.ty2022.FormW4.MaritalStatus} step_1c
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1c = 0;

                        /**
                         * FormW4 step_2b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_1
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_1 = null;

                        /**
                         * FormW4 step_2b_2a.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2a
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2a = null;

                        /**
                         * FormW4 step_2b_2b.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2b
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2b = null;

                        /**
                         * FormW4 step_2b_2c.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2c
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2c = null;

                        /**
                         * FormW4 step_2b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_3
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_3 = null;

                        /**
                         * FormW4 step_2b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_4
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_4 = null;

                        /**
                         * FormW4 step_2c.
                         * @member {boolean} step_2c
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2c = false;

                        /**
                         * FormW4 step_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_3
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3 = null;

                        /**
                         * FormW4 step_3_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_1
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_1 = null;

                        /**
                         * FormW4 step_3_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_2
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_2 = null;

                        /**
                         * FormW4 step_4a.
                         * @member {visor.common.money.IAmount|null|undefined} step_4a
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4a = null;

                        /**
                         * FormW4 step_4b.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b = null;

                        /**
                         * FormW4 step_4b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_1
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_1 = null;

                        /**
                         * FormW4 step_4b_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_2
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_2 = null;

                        /**
                         * FormW4 step_4b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_3
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_3 = null;

                        /**
                         * FormW4 step_4b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_4
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_4 = null;

                        /**
                         * FormW4 step_4b_5.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_5
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_5 = null;

                        /**
                         * FormW4 step_4c.
                         * @member {visor.common.money.IAmount|null|undefined} step_4c
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4c = null;

                        /**
                         * FormW4 signatureTimestamp.
                         * @member {google.protobuf.ITimestamp|null|undefined} signatureTimestamp
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.signatureTimestamp = null;

                        /**
                         * FormW4 signaturePngData.
                         * @member {Uint8Array} signaturePngData
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         */
                        FormW4.prototype.signaturePngData = $util.newBuffer([]);

                        /**
                         * Creates a new FormW4 instance using the specified properties.
                         * @function create
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2022.IFormW4=} [properties] Properties to set
                         * @returns {visor.common.taxforms.ty2022.FormW4} FormW4 instance
                         */
                        FormW4.create = function create(properties) {
                            return new FormW4(properties);
                        };

                        /**
                         * Encodes the specified FormW4 message. Does not implicitly {@link visor.common.taxforms.ty2022.FormW4.verify|verify} messages.
                         * @function encode
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2022.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.step_1aFirstMiddleName);
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.step_1aLastName);
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.step_1aAddress);
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.step_1aCityStateZip);
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.step_1b);
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.step_1c);
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                $root.visor.common.money.Amount.encode(message.step_2b_1, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2a, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2b, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2c, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                $root.visor.common.money.Amount.encode(message.step_2b_3, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                $root.visor.common.money.Amount.encode(message.step_2b_4, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                $root.visor.common.money.Amount.encode(message.step_3, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                $root.visor.common.money.Amount.encode(message.step_3_1, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                $root.visor.common.money.Amount.encode(message.step_3_2, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                $root.visor.common.money.Amount.encode(message.step_4a, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                $root.visor.common.money.Amount.encode(message.step_4b, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                $root.visor.common.money.Amount.encode(message.step_4b_1, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                $root.visor.common.money.Amount.encode(message.step_4b_2, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                $root.visor.common.money.Amount.encode(message.step_4b_3, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                $root.visor.common.money.Amount.encode(message.step_4b_4, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                $root.visor.common.money.Amount.encode(message.step_4b_5, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                $root.visor.common.money.Amount.encode(message.step_4c, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                $root.google.protobuf.Timestamp.encode(message.signatureTimestamp, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                writer.uint32(/* id 25, wireType 2 =*/202).bytes(message.signaturePngData);
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                writer.uint32(/* id 26, wireType 0 =*/208).bool(message.step_2c);
                            return writer;
                        };

                        /**
                         * Encodes the specified FormW4 message, length delimited. Does not implicitly {@link visor.common.taxforms.ty2022.FormW4.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2022.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer.
                         * @function decode
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {visor.common.taxforms.ty2022.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.taxforms.ty2022.FormW4();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.step_1aFirstMiddleName = reader.string();
                                    break;
                                case 2:
                                    message.step_1aLastName = reader.string();
                                    break;
                                case 3:
                                    message.step_1aAddress = reader.string();
                                    break;
                                case 4:
                                    message.step_1aCityStateZip = reader.string();
                                    break;
                                case 5:
                                    message.step_1b = reader.string();
                                    break;
                                case 6:
                                    message.step_1c = reader.int32();
                                    break;
                                case 7:
                                    message.step_2b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.step_2b_2a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.step_2b_2b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.step_2b_2c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.step_2b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.step_2b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 26:
                                    message.step_2c = reader.bool();
                                    break;
                                case 13:
                                    message.step_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 14:
                                    message.step_3_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 15:
                                    message.step_3_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.step_4a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 17:
                                    message.step_4b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 18:
                                    message.step_4b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 19:
                                    message.step_4b_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 20:
                                    message.step_4b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 21:
                                    message.step_4b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.step_4b_5 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 23:
                                    message.step_4c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 24:
                                    message.signatureTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 25:
                                    message.signaturePngData = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {visor.common.taxforms.ty2022.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FormW4 message.
                         * @function verify
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FormW4.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                if (!$util.isString(message.step_1aFirstMiddleName))
                                    return "step_1aFirstMiddleName: string expected";
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                if (!$util.isString(message.step_1aLastName))
                                    return "step_1aLastName: string expected";
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                if (!$util.isString(message.step_1aAddress))
                                    return "step_1aAddress: string expected";
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                if (!$util.isString(message.step_1aCityStateZip))
                                    return "step_1aCityStateZip: string expected";
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                if (!$util.isString(message.step_1b))
                                    return "step_1b: string expected";
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                switch (message.step_1c) {
                                default:
                                    return "step_1c: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_1);
                                if (error)
                                    return "step_2b_1." + error;
                            }
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2a);
                                if (error)
                                    return "step_2b_2a." + error;
                            }
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2b);
                                if (error)
                                    return "step_2b_2b." + error;
                            }
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2c);
                                if (error)
                                    return "step_2b_2c." + error;
                            }
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_3);
                                if (error)
                                    return "step_2b_3." + error;
                            }
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_4);
                                if (error)
                                    return "step_2b_4." + error;
                            }
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                if (typeof message.step_2c !== "boolean")
                                    return "step_2c: boolean expected";
                            if (message.step_3 != null && message.hasOwnProperty("step_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3);
                                if (error)
                                    return "step_3." + error;
                            }
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_1);
                                if (error)
                                    return "step_3_1." + error;
                            }
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_2);
                                if (error)
                                    return "step_3_2." + error;
                            }
                            if (message.step_4a != null && message.hasOwnProperty("step_4a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4a);
                                if (error)
                                    return "step_4a." + error;
                            }
                            if (message.step_4b != null && message.hasOwnProperty("step_4b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b);
                                if (error)
                                    return "step_4b." + error;
                            }
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_1);
                                if (error)
                                    return "step_4b_1." + error;
                            }
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_2);
                                if (error)
                                    return "step_4b_2." + error;
                            }
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_3);
                                if (error)
                                    return "step_4b_3." + error;
                            }
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_4);
                                if (error)
                                    return "step_4b_4." + error;
                            }
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_5);
                                if (error)
                                    return "step_4b_5." + error;
                            }
                            if (message.step_4c != null && message.hasOwnProperty("step_4c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4c);
                                if (error)
                                    return "step_4c." + error;
                            }
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.signatureTimestamp);
                                if (error)
                                    return "signatureTimestamp." + error;
                            }
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                if (!(message.signaturePngData && typeof message.signaturePngData.length === "number" || $util.isString(message.signaturePngData)))
                                    return "signaturePngData: buffer expected";
                            return null;
                        };

                        /**
                         * Creates a FormW4 message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {visor.common.taxforms.ty2022.FormW4} FormW4
                         */
                        FormW4.fromObject = function fromObject(object) {
                            if (object instanceof $root.visor.common.taxforms.ty2022.FormW4)
                                return object;
                            var message = new $root.visor.common.taxforms.ty2022.FormW4();
                            if (object.step_1aFirstMiddleName != null)
                                message.step_1aFirstMiddleName = String(object.step_1aFirstMiddleName);
                            if (object.step_1aLastName != null)
                                message.step_1aLastName = String(object.step_1aLastName);
                            if (object.step_1aAddress != null)
                                message.step_1aAddress = String(object.step_1aAddress);
                            if (object.step_1aCityStateZip != null)
                                message.step_1aCityStateZip = String(object.step_1aCityStateZip);
                            if (object.step_1b != null)
                                message.step_1b = String(object.step_1b);
                            switch (object.step_1c) {
                            case "FORM_W4_MARITAL_STATUS_INVALID":
                            case 0:
                                message.step_1c = 0;
                                break;
                            case "FORM_W4_MARITAL_STATUS_SINGLE":
                            case 1:
                                message.step_1c = 1;
                                break;
                            case "FORM_W4_MARITAL_STATUS_MARRIED":
                            case 2:
                                message.step_1c = 2;
                                break;
                            case "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD":
                            case 3:
                                message.step_1c = 3;
                                break;
                            }
                            if (object.step_2b_1 != null) {
                                if (typeof object.step_2b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_2b_1: object expected");
                                message.step_2b_1 = $root.visor.common.money.Amount.fromObject(object.step_2b_1);
                            }
                            if (object.step_2b_2a != null) {
                                if (typeof object.step_2b_2a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_2b_2a: object expected");
                                message.step_2b_2a = $root.visor.common.money.Amount.fromObject(object.step_2b_2a);
                            }
                            if (object.step_2b_2b != null) {
                                if (typeof object.step_2b_2b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_2b_2b: object expected");
                                message.step_2b_2b = $root.visor.common.money.Amount.fromObject(object.step_2b_2b);
                            }
                            if (object.step_2b_2c != null) {
                                if (typeof object.step_2b_2c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_2b_2c: object expected");
                                message.step_2b_2c = $root.visor.common.money.Amount.fromObject(object.step_2b_2c);
                            }
                            if (object.step_2b_3 != null) {
                                if (typeof object.step_2b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_2b_3: object expected");
                                message.step_2b_3 = $root.visor.common.money.Amount.fromObject(object.step_2b_3);
                            }
                            if (object.step_2b_4 != null) {
                                if (typeof object.step_2b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_2b_4: object expected");
                                message.step_2b_4 = $root.visor.common.money.Amount.fromObject(object.step_2b_4);
                            }
                            if (object.step_2c != null)
                                message.step_2c = Boolean(object.step_2c);
                            if (object.step_3 != null) {
                                if (typeof object.step_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_3: object expected");
                                message.step_3 = $root.visor.common.money.Amount.fromObject(object.step_3);
                            }
                            if (object.step_3_1 != null) {
                                if (typeof object.step_3_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_3_1: object expected");
                                message.step_3_1 = $root.visor.common.money.Amount.fromObject(object.step_3_1);
                            }
                            if (object.step_3_2 != null) {
                                if (typeof object.step_3_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_3_2: object expected");
                                message.step_3_2 = $root.visor.common.money.Amount.fromObject(object.step_3_2);
                            }
                            if (object.step_4a != null) {
                                if (typeof object.step_4a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4a: object expected");
                                message.step_4a = $root.visor.common.money.Amount.fromObject(object.step_4a);
                            }
                            if (object.step_4b != null) {
                                if (typeof object.step_4b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4b: object expected");
                                message.step_4b = $root.visor.common.money.Amount.fromObject(object.step_4b);
                            }
                            if (object.step_4b_1 != null) {
                                if (typeof object.step_4b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4b_1: object expected");
                                message.step_4b_1 = $root.visor.common.money.Amount.fromObject(object.step_4b_1);
                            }
                            if (object.step_4b_2 != null) {
                                if (typeof object.step_4b_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4b_2: object expected");
                                message.step_4b_2 = $root.visor.common.money.Amount.fromObject(object.step_4b_2);
                            }
                            if (object.step_4b_3 != null) {
                                if (typeof object.step_4b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4b_3: object expected");
                                message.step_4b_3 = $root.visor.common.money.Amount.fromObject(object.step_4b_3);
                            }
                            if (object.step_4b_4 != null) {
                                if (typeof object.step_4b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4b_4: object expected");
                                message.step_4b_4 = $root.visor.common.money.Amount.fromObject(object.step_4b_4);
                            }
                            if (object.step_4b_5 != null) {
                                if (typeof object.step_4b_5 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4b_5: object expected");
                                message.step_4b_5 = $root.visor.common.money.Amount.fromObject(object.step_4b_5);
                            }
                            if (object.step_4c != null) {
                                if (typeof object.step_4c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2022.FormW4.step_4c: object expected");
                                message.step_4c = $root.visor.common.money.Amount.fromObject(object.step_4c);
                            }
                            if (object.signatureTimestamp != null)
                                if (object.signatureTimestamp === null)
                                    message.signatureTimestamp = {};
                                else
                                    message.signatureTimestamp = {
                                        seconds: Math.floor(object.signatureTimestamp.getTime() / 1000),
                                        nanos: object.signatureTimestamp.getMilliseconds() * 1000000
                                    };
                            if (object.signaturePngData != null)
                                if (typeof object.signaturePngData === "string")
                                    $util.base64.decode(object.signaturePngData, message.signaturePngData = $util.newBuffer($util.base64.length(object.signaturePngData)), 0);
                                else if (object.signaturePngData.length)
                                    message.signaturePngData = object.signaturePngData;
                            return message;
                        };

                        /**
                         * Creates a plain object from a FormW4 message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2022.FormW4} message FormW4
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FormW4.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.step_1aFirstMiddleName = "";
                                object.step_1aLastName = "";
                                object.step_1aAddress = "";
                                object.step_1aCityStateZip = "";
                                object.step_1b = "";
                                object.step_1c = options.enums === String ? "FORM_W4_MARITAL_STATUS_INVALID" : 0;
                                object.step_2b_1 = null;
                                object.step_2b_2a = null;
                                object.step_2b_2b = null;
                                object.step_2b_2c = null;
                                object.step_2b_3 = null;
                                object.step_2b_4 = null;
                                object.step_3 = null;
                                object.step_3_1 = null;
                                object.step_3_2 = null;
                                object.step_4a = null;
                                object.step_4b = null;
                                object.step_4b_1 = null;
                                object.step_4b_2 = null;
                                object.step_4b_3 = null;
                                object.step_4b_4 = null;
                                object.step_4b_5 = null;
                                object.step_4c = null;
                                object.signatureTimestamp = null;
                                if (options.bytes === String)
                                    object.signaturePngData = "";
                                else {
                                    object.signaturePngData = [];
                                    if (options.bytes !== Array)
                                        object.signaturePngData = $util.newBuffer(object.signaturePngData);
                                }
                                object.step_2c = false;
                            }
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                object.step_1aFirstMiddleName = message.step_1aFirstMiddleName;
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                object.step_1aLastName = message.step_1aLastName;
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                object.step_1aAddress = message.step_1aAddress;
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                object.step_1aCityStateZip = message.step_1aCityStateZip;
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                object.step_1b = message.step_1b;
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                object.step_1c = options.enums === String ? $root.visor.common.taxforms.ty2022.FormW4.MaritalStatus[message.step_1c] : message.step_1c;
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                object.step_2b_1 = $root.visor.common.money.Amount.toObject(message.step_2b_1, options);
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                object.step_2b_2a = $root.visor.common.money.Amount.toObject(message.step_2b_2a, options);
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                object.step_2b_2b = $root.visor.common.money.Amount.toObject(message.step_2b_2b, options);
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                object.step_2b_2c = $root.visor.common.money.Amount.toObject(message.step_2b_2c, options);
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                object.step_2b_3 = $root.visor.common.money.Amount.toObject(message.step_2b_3, options);
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                object.step_2b_4 = $root.visor.common.money.Amount.toObject(message.step_2b_4, options);
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                object.step_3 = $root.visor.common.money.Amount.toObject(message.step_3, options);
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                object.step_3_1 = $root.visor.common.money.Amount.toObject(message.step_3_1, options);
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                object.step_3_2 = $root.visor.common.money.Amount.toObject(message.step_3_2, options);
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                object.step_4a = $root.visor.common.money.Amount.toObject(message.step_4a, options);
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                object.step_4b = $root.visor.common.money.Amount.toObject(message.step_4b, options);
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                object.step_4b_1 = $root.visor.common.money.Amount.toObject(message.step_4b_1, options);
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                object.step_4b_2 = $root.visor.common.money.Amount.toObject(message.step_4b_2, options);
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                object.step_4b_3 = $root.visor.common.money.Amount.toObject(message.step_4b_3, options);
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                object.step_4b_4 = $root.visor.common.money.Amount.toObject(message.step_4b_4, options);
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                object.step_4b_5 = $root.visor.common.money.Amount.toObject(message.step_4b_5, options);
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                object.step_4c = $root.visor.common.money.Amount.toObject(message.step_4c, options);
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                object.signatureTimestamp = new Date(message.signatureTimestamp.seconds * 1000 + message.signatureTimestamp.nanos / 1000000);
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                object.signaturePngData = options.bytes === String ? $util.base64.encode(message.signaturePngData, 0, message.signaturePngData.length) : options.bytes === Array ? Array.prototype.slice.call(message.signaturePngData) : message.signaturePngData;
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                object.step_2c = message.step_2c;
                            return object;
                        };

                        /**
                         * Converts this FormW4 to JSON.
                         * @function toJSON
                         * @memberof visor.common.taxforms.ty2022.FormW4
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FormW4.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * MaritalStatus enum.
                         * @name visor.common.taxforms.ty2022.FormW4.MaritalStatus
                         * @enum {string}
                         * @property {number} FORM_W4_MARITAL_STATUS_INVALID=0 FORM_W4_MARITAL_STATUS_INVALID value
                         * @property {number} FORM_W4_MARITAL_STATUS_SINGLE=1 FORM_W4_MARITAL_STATUS_SINGLE value
                         * @property {number} FORM_W4_MARITAL_STATUS_MARRIED=2 FORM_W4_MARITAL_STATUS_MARRIED value
                         * @property {number} FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD=3 FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD value
                         */
                        FormW4.MaritalStatus = (function() {
                            var valuesById = {}, values = Object.create(valuesById);
                            values[valuesById[0] = "FORM_W4_MARITAL_STATUS_INVALID"] = 0;
                            values[valuesById[1] = "FORM_W4_MARITAL_STATUS_SINGLE"] = 1;
                            values[valuesById[2] = "FORM_W4_MARITAL_STATUS_MARRIED"] = 2;
                            values[valuesById[3] = "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD"] = 3;
                            return values;
                        })();

                        return FormW4;
                    })();

                    return ty2022;
                })();

                taxforms.ty2024 = (function() {

                    /**
                     * Namespace ty2024.
                     * @memberof visor.common.taxforms
                     * @namespace
                     */
                    var ty2024 = {};

                    ty2024.FormW4 = (function() {

                        /**
                         * Properties of a FormW4.
                         * @memberof visor.common.taxforms.ty2024
                         * @interface IFormW4
                         * @property {string|null} [step_1aFirstMiddleName] FormW4 step_1aFirstMiddleName
                         * @property {string|null} [step_1aLastName] FormW4 step_1aLastName
                         * @property {string|null} [step_1aAddress] FormW4 step_1aAddress
                         * @property {string|null} [step_1aCityStateZip] FormW4 step_1aCityStateZip
                         * @property {string|null} [step_1b] FormW4 step_1b
                         * @property {visor.common.taxforms.ty2024.FormW4.MaritalStatus|null} [step_1c] FormW4 step_1c
                         * @property {visor.common.money.IAmount|null} [step_2b_1] FormW4 step_2b_1
                         * @property {visor.common.money.IAmount|null} [step_2b_2a] FormW4 step_2b_2a
                         * @property {visor.common.money.IAmount|null} [step_2b_2b] FormW4 step_2b_2b
                         * @property {visor.common.money.IAmount|null} [step_2b_2c] FormW4 step_2b_2c
                         * @property {visor.common.money.IAmount|null} [step_2b_3] FormW4 step_2b_3
                         * @property {visor.common.money.IAmount|null} [step_2b_4] FormW4 step_2b_4
                         * @property {boolean|null} [step_2c] FormW4 step_2c
                         * @property {visor.common.money.IAmount|null} [step_3] FormW4 step_3
                         * @property {visor.common.money.IAmount|null} [step_3_1] FormW4 step_3_1
                         * @property {visor.common.money.IAmount|null} [step_3_2] FormW4 step_3_2
                         * @property {visor.common.money.IAmount|null} [step_4a] FormW4 step_4a
                         * @property {visor.common.money.IAmount|null} [step_4b] FormW4 step_4b
                         * @property {visor.common.money.IAmount|null} [step_4b_1] FormW4 step_4b_1
                         * @property {visor.common.money.IAmount|null} [step_4b_2] FormW4 step_4b_2
                         * @property {visor.common.money.IAmount|null} [step_4b_3] FormW4 step_4b_3
                         * @property {visor.common.money.IAmount|null} [step_4b_4] FormW4 step_4b_4
                         * @property {visor.common.money.IAmount|null} [step_4b_5] FormW4 step_4b_5
                         * @property {visor.common.money.IAmount|null} [step_4c] FormW4 step_4c
                         * @property {google.protobuf.ITimestamp|null} [signatureTimestamp] FormW4 signatureTimestamp
                         * @property {Uint8Array|null} [signaturePngData] FormW4 signaturePngData
                         */

                        /**
                         * Constructs a new FormW4.
                         * @memberof visor.common.taxforms.ty2024
                         * @classdesc Represents a FormW4.
                         * @implements IFormW4
                         * @constructor
                         * @param {visor.common.taxforms.ty2024.IFormW4=} [properties] Properties to set
                         */
                        function FormW4(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * FormW4 step_1aFirstMiddleName.
                         * @member {string} step_1aFirstMiddleName
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aFirstMiddleName = "";

                        /**
                         * FormW4 step_1aLastName.
                         * @member {string} step_1aLastName
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aLastName = "";

                        /**
                         * FormW4 step_1aAddress.
                         * @member {string} step_1aAddress
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aAddress = "";

                        /**
                         * FormW4 step_1aCityStateZip.
                         * @member {string} step_1aCityStateZip
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1aCityStateZip = "";

                        /**
                         * FormW4 step_1b.
                         * @member {string} step_1b
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1b = "";

                        /**
                         * FormW4 step_1c.
                         * @member {visor.common.taxforms.ty2024.FormW4.MaritalStatus} step_1c
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_1c = 0;

                        /**
                         * FormW4 step_2b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_1
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_1 = null;

                        /**
                         * FormW4 step_2b_2a.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2a
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2a = null;

                        /**
                         * FormW4 step_2b_2b.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2b
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2b = null;

                        /**
                         * FormW4 step_2b_2c.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_2c
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_2c = null;

                        /**
                         * FormW4 step_2b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_3
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_3 = null;

                        /**
                         * FormW4 step_2b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_2b_4
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2b_4 = null;

                        /**
                         * FormW4 step_2c.
                         * @member {boolean} step_2c
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_2c = false;

                        /**
                         * FormW4 step_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_3
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3 = null;

                        /**
                         * FormW4 step_3_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_1
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_1 = null;

                        /**
                         * FormW4 step_3_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_3_2
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_3_2 = null;

                        /**
                         * FormW4 step_4a.
                         * @member {visor.common.money.IAmount|null|undefined} step_4a
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4a = null;

                        /**
                         * FormW4 step_4b.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b = null;

                        /**
                         * FormW4 step_4b_1.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_1
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_1 = null;

                        /**
                         * FormW4 step_4b_2.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_2
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_2 = null;

                        /**
                         * FormW4 step_4b_3.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_3
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_3 = null;

                        /**
                         * FormW4 step_4b_4.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_4
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_4 = null;

                        /**
                         * FormW4 step_4b_5.
                         * @member {visor.common.money.IAmount|null|undefined} step_4b_5
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4b_5 = null;

                        /**
                         * FormW4 step_4c.
                         * @member {visor.common.money.IAmount|null|undefined} step_4c
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.step_4c = null;

                        /**
                         * FormW4 signatureTimestamp.
                         * @member {google.protobuf.ITimestamp|null|undefined} signatureTimestamp
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.signatureTimestamp = null;

                        /**
                         * FormW4 signaturePngData.
                         * @member {Uint8Array} signaturePngData
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         */
                        FormW4.prototype.signaturePngData = $util.newBuffer([]);

                        /**
                         * Creates a new FormW4 instance using the specified properties.
                         * @function create
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2024.IFormW4=} [properties] Properties to set
                         * @returns {visor.common.taxforms.ty2024.FormW4} FormW4 instance
                         */
                        FormW4.create = function create(properties) {
                            return new FormW4(properties);
                        };

                        /**
                         * Encodes the specified FormW4 message. Does not implicitly {@link visor.common.taxforms.ty2024.FormW4.verify|verify} messages.
                         * @function encode
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2024.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.step_1aFirstMiddleName);
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.step_1aLastName);
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.step_1aAddress);
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.step_1aCityStateZip);
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.step_1b);
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.step_1c);
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                $root.visor.common.money.Amount.encode(message.step_2b_1, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2a, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2b, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                $root.visor.common.money.Amount.encode(message.step_2b_2c, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                $root.visor.common.money.Amount.encode(message.step_2b_3, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                $root.visor.common.money.Amount.encode(message.step_2b_4, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                $root.visor.common.money.Amount.encode(message.step_3, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                $root.visor.common.money.Amount.encode(message.step_3_1, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                $root.visor.common.money.Amount.encode(message.step_3_2, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                $root.visor.common.money.Amount.encode(message.step_4a, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                $root.visor.common.money.Amount.encode(message.step_4b, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                $root.visor.common.money.Amount.encode(message.step_4b_1, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                $root.visor.common.money.Amount.encode(message.step_4b_2, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                $root.visor.common.money.Amount.encode(message.step_4b_3, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                $root.visor.common.money.Amount.encode(message.step_4b_4, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                $root.visor.common.money.Amount.encode(message.step_4b_5, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                $root.visor.common.money.Amount.encode(message.step_4c, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                $root.google.protobuf.Timestamp.encode(message.signatureTimestamp, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                writer.uint32(/* id 25, wireType 2 =*/202).bytes(message.signaturePngData);
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                writer.uint32(/* id 26, wireType 0 =*/208).bool(message.step_2c);
                            return writer;
                        };

                        /**
                         * Encodes the specified FormW4 message, length delimited. Does not implicitly {@link visor.common.taxforms.ty2024.FormW4.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2024.IFormW4} message FormW4 message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        FormW4.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer.
                         * @function decode
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {visor.common.taxforms.ty2024.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.taxforms.ty2024.FormW4();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.step_1aFirstMiddleName = reader.string();
                                    break;
                                case 2:
                                    message.step_1aLastName = reader.string();
                                    break;
                                case 3:
                                    message.step_1aAddress = reader.string();
                                    break;
                                case 4:
                                    message.step_1aCityStateZip = reader.string();
                                    break;
                                case 5:
                                    message.step_1b = reader.string();
                                    break;
                                case 6:
                                    message.step_1c = reader.int32();
                                    break;
                                case 7:
                                    message.step_2b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 8:
                                    message.step_2b_2a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 9:
                                    message.step_2b_2b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 10:
                                    message.step_2b_2c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 11:
                                    message.step_2b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 12:
                                    message.step_2b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 26:
                                    message.step_2c = reader.bool();
                                    break;
                                case 13:
                                    message.step_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 14:
                                    message.step_3_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 15:
                                    message.step_3_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 16:
                                    message.step_4a = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 17:
                                    message.step_4b = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 18:
                                    message.step_4b_1 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 19:
                                    message.step_4b_2 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 20:
                                    message.step_4b_3 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 21:
                                    message.step_4b_4 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 22:
                                    message.step_4b_5 = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 23:
                                    message.step_4c = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                    break;
                                case 24:
                                    message.signatureTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                    break;
                                case 25:
                                    message.signaturePngData = reader.bytes();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a FormW4 message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {visor.common.taxforms.ty2024.FormW4} FormW4
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        FormW4.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a FormW4 message.
                         * @function verify
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        FormW4.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                if (!$util.isString(message.step_1aFirstMiddleName))
                                    return "step_1aFirstMiddleName: string expected";
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                if (!$util.isString(message.step_1aLastName))
                                    return "step_1aLastName: string expected";
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                if (!$util.isString(message.step_1aAddress))
                                    return "step_1aAddress: string expected";
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                if (!$util.isString(message.step_1aCityStateZip))
                                    return "step_1aCityStateZip: string expected";
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                if (!$util.isString(message.step_1b))
                                    return "step_1b: string expected";
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                switch (message.step_1c) {
                                default:
                                    return "step_1c: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    break;
                                }
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_1);
                                if (error)
                                    return "step_2b_1." + error;
                            }
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2a);
                                if (error)
                                    return "step_2b_2a." + error;
                            }
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2b);
                                if (error)
                                    return "step_2b_2b." + error;
                            }
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_2c);
                                if (error)
                                    return "step_2b_2c." + error;
                            }
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_3);
                                if (error)
                                    return "step_2b_3." + error;
                            }
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_2b_4);
                                if (error)
                                    return "step_2b_4." + error;
                            }
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                if (typeof message.step_2c !== "boolean")
                                    return "step_2c: boolean expected";
                            if (message.step_3 != null && message.hasOwnProperty("step_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3);
                                if (error)
                                    return "step_3." + error;
                            }
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_1);
                                if (error)
                                    return "step_3_1." + error;
                            }
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_3_2);
                                if (error)
                                    return "step_3_2." + error;
                            }
                            if (message.step_4a != null && message.hasOwnProperty("step_4a")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4a);
                                if (error)
                                    return "step_4a." + error;
                            }
                            if (message.step_4b != null && message.hasOwnProperty("step_4b")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b);
                                if (error)
                                    return "step_4b." + error;
                            }
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_1);
                                if (error)
                                    return "step_4b_1." + error;
                            }
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_2);
                                if (error)
                                    return "step_4b_2." + error;
                            }
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_3);
                                if (error)
                                    return "step_4b_3." + error;
                            }
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_4);
                                if (error)
                                    return "step_4b_4." + error;
                            }
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4b_5);
                                if (error)
                                    return "step_4b_5." + error;
                            }
                            if (message.step_4c != null && message.hasOwnProperty("step_4c")) {
                                var error = $root.visor.common.money.Amount.verify(message.step_4c);
                                if (error)
                                    return "step_4c." + error;
                            }
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.signatureTimestamp);
                                if (error)
                                    return "signatureTimestamp." + error;
                            }
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                if (!(message.signaturePngData && typeof message.signaturePngData.length === "number" || $util.isString(message.signaturePngData)))
                                    return "signaturePngData: buffer expected";
                            return null;
                        };

                        /**
                         * Creates a FormW4 message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {visor.common.taxforms.ty2024.FormW4} FormW4
                         */
                        FormW4.fromObject = function fromObject(object) {
                            if (object instanceof $root.visor.common.taxforms.ty2024.FormW4)
                                return object;
                            var message = new $root.visor.common.taxforms.ty2024.FormW4();
                            if (object.step_1aFirstMiddleName != null)
                                message.step_1aFirstMiddleName = String(object.step_1aFirstMiddleName);
                            if (object.step_1aLastName != null)
                                message.step_1aLastName = String(object.step_1aLastName);
                            if (object.step_1aAddress != null)
                                message.step_1aAddress = String(object.step_1aAddress);
                            if (object.step_1aCityStateZip != null)
                                message.step_1aCityStateZip = String(object.step_1aCityStateZip);
                            if (object.step_1b != null)
                                message.step_1b = String(object.step_1b);
                            switch (object.step_1c) {
                            case "FORM_W4_MARITAL_STATUS_INVALID":
                            case 0:
                                message.step_1c = 0;
                                break;
                            case "FORM_W4_MARITAL_STATUS_SINGLE":
                            case 1:
                                message.step_1c = 1;
                                break;
                            case "FORM_W4_MARITAL_STATUS_MARRIED":
                            case 2:
                                message.step_1c = 2;
                                break;
                            case "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD":
                            case 3:
                                message.step_1c = 3;
                                break;
                            }
                            if (object.step_2b_1 != null) {
                                if (typeof object.step_2b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_2b_1: object expected");
                                message.step_2b_1 = $root.visor.common.money.Amount.fromObject(object.step_2b_1);
                            }
                            if (object.step_2b_2a != null) {
                                if (typeof object.step_2b_2a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_2b_2a: object expected");
                                message.step_2b_2a = $root.visor.common.money.Amount.fromObject(object.step_2b_2a);
                            }
                            if (object.step_2b_2b != null) {
                                if (typeof object.step_2b_2b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_2b_2b: object expected");
                                message.step_2b_2b = $root.visor.common.money.Amount.fromObject(object.step_2b_2b);
                            }
                            if (object.step_2b_2c != null) {
                                if (typeof object.step_2b_2c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_2b_2c: object expected");
                                message.step_2b_2c = $root.visor.common.money.Amount.fromObject(object.step_2b_2c);
                            }
                            if (object.step_2b_3 != null) {
                                if (typeof object.step_2b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_2b_3: object expected");
                                message.step_2b_3 = $root.visor.common.money.Amount.fromObject(object.step_2b_3);
                            }
                            if (object.step_2b_4 != null) {
                                if (typeof object.step_2b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_2b_4: object expected");
                                message.step_2b_4 = $root.visor.common.money.Amount.fromObject(object.step_2b_4);
                            }
                            if (object.step_2c != null)
                                message.step_2c = Boolean(object.step_2c);
                            if (object.step_3 != null) {
                                if (typeof object.step_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_3: object expected");
                                message.step_3 = $root.visor.common.money.Amount.fromObject(object.step_3);
                            }
                            if (object.step_3_1 != null) {
                                if (typeof object.step_3_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_3_1: object expected");
                                message.step_3_1 = $root.visor.common.money.Amount.fromObject(object.step_3_1);
                            }
                            if (object.step_3_2 != null) {
                                if (typeof object.step_3_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_3_2: object expected");
                                message.step_3_2 = $root.visor.common.money.Amount.fromObject(object.step_3_2);
                            }
                            if (object.step_4a != null) {
                                if (typeof object.step_4a !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4a: object expected");
                                message.step_4a = $root.visor.common.money.Amount.fromObject(object.step_4a);
                            }
                            if (object.step_4b != null) {
                                if (typeof object.step_4b !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4b: object expected");
                                message.step_4b = $root.visor.common.money.Amount.fromObject(object.step_4b);
                            }
                            if (object.step_4b_1 != null) {
                                if (typeof object.step_4b_1 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4b_1: object expected");
                                message.step_4b_1 = $root.visor.common.money.Amount.fromObject(object.step_4b_1);
                            }
                            if (object.step_4b_2 != null) {
                                if (typeof object.step_4b_2 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4b_2: object expected");
                                message.step_4b_2 = $root.visor.common.money.Amount.fromObject(object.step_4b_2);
                            }
                            if (object.step_4b_3 != null) {
                                if (typeof object.step_4b_3 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4b_3: object expected");
                                message.step_4b_3 = $root.visor.common.money.Amount.fromObject(object.step_4b_3);
                            }
                            if (object.step_4b_4 != null) {
                                if (typeof object.step_4b_4 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4b_4: object expected");
                                message.step_4b_4 = $root.visor.common.money.Amount.fromObject(object.step_4b_4);
                            }
                            if (object.step_4b_5 != null) {
                                if (typeof object.step_4b_5 !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4b_5: object expected");
                                message.step_4b_5 = $root.visor.common.money.Amount.fromObject(object.step_4b_5);
                            }
                            if (object.step_4c != null) {
                                if (typeof object.step_4c !== "object")
                                    throw TypeError(".visor.common.taxforms.ty2024.FormW4.step_4c: object expected");
                                message.step_4c = $root.visor.common.money.Amount.fromObject(object.step_4c);
                            }
                            if (object.signatureTimestamp != null)
                                if (object.signatureTimestamp === null)
                                    message.signatureTimestamp = {};
                                else
                                    message.signatureTimestamp = {
                                        seconds: Math.floor(object.signatureTimestamp.getTime() / 1000),
                                        nanos: object.signatureTimestamp.getMilliseconds() * 1000000
                                    };
                            if (object.signaturePngData != null)
                                if (typeof object.signaturePngData === "string")
                                    $util.base64.decode(object.signaturePngData, message.signaturePngData = $util.newBuffer($util.base64.length(object.signaturePngData)), 0);
                                else if (object.signaturePngData.length)
                                    message.signaturePngData = object.signaturePngData;
                            return message;
                        };

                        /**
                         * Creates a plain object from a FormW4 message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @static
                         * @param {visor.common.taxforms.ty2024.FormW4} message FormW4
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        FormW4.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.step_1aFirstMiddleName = "";
                                object.step_1aLastName = "";
                                object.step_1aAddress = "";
                                object.step_1aCityStateZip = "";
                                object.step_1b = "";
                                object.step_1c = options.enums === String ? "FORM_W4_MARITAL_STATUS_INVALID" : 0;
                                object.step_2b_1 = null;
                                object.step_2b_2a = null;
                                object.step_2b_2b = null;
                                object.step_2b_2c = null;
                                object.step_2b_3 = null;
                                object.step_2b_4 = null;
                                object.step_3 = null;
                                object.step_3_1 = null;
                                object.step_3_2 = null;
                                object.step_4a = null;
                                object.step_4b = null;
                                object.step_4b_1 = null;
                                object.step_4b_2 = null;
                                object.step_4b_3 = null;
                                object.step_4b_4 = null;
                                object.step_4b_5 = null;
                                object.step_4c = null;
                                object.signatureTimestamp = null;
                                if (options.bytes === String)
                                    object.signaturePngData = "";
                                else {
                                    object.signaturePngData = [];
                                    if (options.bytes !== Array)
                                        object.signaturePngData = $util.newBuffer(object.signaturePngData);
                                }
                                object.step_2c = false;
                            }
                            if (message.step_1aFirstMiddleName != null && message.hasOwnProperty("step_1aFirstMiddleName"))
                                object.step_1aFirstMiddleName = message.step_1aFirstMiddleName;
                            if (message.step_1aLastName != null && message.hasOwnProperty("step_1aLastName"))
                                object.step_1aLastName = message.step_1aLastName;
                            if (message.step_1aAddress != null && message.hasOwnProperty("step_1aAddress"))
                                object.step_1aAddress = message.step_1aAddress;
                            if (message.step_1aCityStateZip != null && message.hasOwnProperty("step_1aCityStateZip"))
                                object.step_1aCityStateZip = message.step_1aCityStateZip;
                            if (message.step_1b != null && message.hasOwnProperty("step_1b"))
                                object.step_1b = message.step_1b;
                            if (message.step_1c != null && message.hasOwnProperty("step_1c"))
                                object.step_1c = options.enums === String ? $root.visor.common.taxforms.ty2024.FormW4.MaritalStatus[message.step_1c] : message.step_1c;
                            if (message.step_2b_1 != null && message.hasOwnProperty("step_2b_1"))
                                object.step_2b_1 = $root.visor.common.money.Amount.toObject(message.step_2b_1, options);
                            if (message.step_2b_2a != null && message.hasOwnProperty("step_2b_2a"))
                                object.step_2b_2a = $root.visor.common.money.Amount.toObject(message.step_2b_2a, options);
                            if (message.step_2b_2b != null && message.hasOwnProperty("step_2b_2b"))
                                object.step_2b_2b = $root.visor.common.money.Amount.toObject(message.step_2b_2b, options);
                            if (message.step_2b_2c != null && message.hasOwnProperty("step_2b_2c"))
                                object.step_2b_2c = $root.visor.common.money.Amount.toObject(message.step_2b_2c, options);
                            if (message.step_2b_3 != null && message.hasOwnProperty("step_2b_3"))
                                object.step_2b_3 = $root.visor.common.money.Amount.toObject(message.step_2b_3, options);
                            if (message.step_2b_4 != null && message.hasOwnProperty("step_2b_4"))
                                object.step_2b_4 = $root.visor.common.money.Amount.toObject(message.step_2b_4, options);
                            if (message.step_3 != null && message.hasOwnProperty("step_3"))
                                object.step_3 = $root.visor.common.money.Amount.toObject(message.step_3, options);
                            if (message.step_3_1 != null && message.hasOwnProperty("step_3_1"))
                                object.step_3_1 = $root.visor.common.money.Amount.toObject(message.step_3_1, options);
                            if (message.step_3_2 != null && message.hasOwnProperty("step_3_2"))
                                object.step_3_2 = $root.visor.common.money.Amount.toObject(message.step_3_2, options);
                            if (message.step_4a != null && message.hasOwnProperty("step_4a"))
                                object.step_4a = $root.visor.common.money.Amount.toObject(message.step_4a, options);
                            if (message.step_4b != null && message.hasOwnProperty("step_4b"))
                                object.step_4b = $root.visor.common.money.Amount.toObject(message.step_4b, options);
                            if (message.step_4b_1 != null && message.hasOwnProperty("step_4b_1"))
                                object.step_4b_1 = $root.visor.common.money.Amount.toObject(message.step_4b_1, options);
                            if (message.step_4b_2 != null && message.hasOwnProperty("step_4b_2"))
                                object.step_4b_2 = $root.visor.common.money.Amount.toObject(message.step_4b_2, options);
                            if (message.step_4b_3 != null && message.hasOwnProperty("step_4b_3"))
                                object.step_4b_3 = $root.visor.common.money.Amount.toObject(message.step_4b_3, options);
                            if (message.step_4b_4 != null && message.hasOwnProperty("step_4b_4"))
                                object.step_4b_4 = $root.visor.common.money.Amount.toObject(message.step_4b_4, options);
                            if (message.step_4b_5 != null && message.hasOwnProperty("step_4b_5"))
                                object.step_4b_5 = $root.visor.common.money.Amount.toObject(message.step_4b_5, options);
                            if (message.step_4c != null && message.hasOwnProperty("step_4c"))
                                object.step_4c = $root.visor.common.money.Amount.toObject(message.step_4c, options);
                            if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                                object.signatureTimestamp = new Date(message.signatureTimestamp.seconds * 1000 + message.signatureTimestamp.nanos / 1000000);
                            if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                                object.signaturePngData = options.bytes === String ? $util.base64.encode(message.signaturePngData, 0, message.signaturePngData.length) : options.bytes === Array ? Array.prototype.slice.call(message.signaturePngData) : message.signaturePngData;
                            if (message.step_2c != null && message.hasOwnProperty("step_2c"))
                                object.step_2c = message.step_2c;
                            return object;
                        };

                        /**
                         * Converts this FormW4 to JSON.
                         * @function toJSON
                         * @memberof visor.common.taxforms.ty2024.FormW4
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        FormW4.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * MaritalStatus enum.
                         * @name visor.common.taxforms.ty2024.FormW4.MaritalStatus
                         * @enum {string}
                         * @property {number} FORM_W4_MARITAL_STATUS_INVALID=0 FORM_W4_MARITAL_STATUS_INVALID value
                         * @property {number} FORM_W4_MARITAL_STATUS_SINGLE=1 FORM_W4_MARITAL_STATUS_SINGLE value
                         * @property {number} FORM_W4_MARITAL_STATUS_MARRIED=2 FORM_W4_MARITAL_STATUS_MARRIED value
                         * @property {number} FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD=3 FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD value
                         */
                        FormW4.MaritalStatus = (function() {
                            var valuesById = {}, values = Object.create(valuesById);
                            values[valuesById[0] = "FORM_W4_MARITAL_STATUS_INVALID"] = 0;
                            values[valuesById[1] = "FORM_W4_MARITAL_STATUS_SINGLE"] = 1;
                            values[valuesById[2] = "FORM_W4_MARITAL_STATUS_MARRIED"] = 2;
                            values[valuesById[3] = "FORM_W4_MARITAL_STATUS_HEAD_OF_HOUSEHOLD"] = 3;
                            return values;
                        })();

                        return FormW4;
                    })();

                    return ty2024;
                })();

                taxforms.FormW4 = (function() {

                    /**
                     * Properties of a FormW4.
                     * @memberof visor.common.taxforms
                     * @interface IFormW4
                     * @property {string|null} [firstNameAndMiddleInitial] FormW4 firstNameAndMiddleInitial
                     * @property {string|null} [lastName] FormW4 lastName
                     * @property {string|null} [socialSecurityNumber] FormW4 socialSecurityNumber
                     * @property {string|null} [homeAddress] FormW4 homeAddress
                     * @property {string|null} [cityOrTownStateAndZip] FormW4 cityOrTownStateAndZip
                     * @property {visor.common.taxforms.FormW4.MaritalStatus|null} [maritalStatus] FormW4 maritalStatus
                     * @property {boolean|null} [lastNameDiffersFromSsn] FormW4 lastNameDiffersFromSsn
                     * @property {visor.common.numeric.INullableUint64|null} [numberOfAllowances] FormW4 numberOfAllowances
                     * @property {visor.common.money.IAmount|null} [additionalAmount] FormW4 additionalAmount
                     * @property {boolean|null} [exempt] FormW4 exempt
                     * @property {Uint8Array|null} [signaturePngData] FormW4 signaturePngData
                     * @property {google.protobuf.ITimestamp|null} [signatureTimestamp] FormW4 signatureTimestamp
                     * @property {visor.common.money.IAmount|null} [targetTaxWithheld] FormW4 targetTaxWithheld
                     */

                    /**
                     * Constructs a new FormW4.
                     * @memberof visor.common.taxforms
                     * @classdesc Represents a FormW4.
                     * @implements IFormW4
                     * @constructor
                     * @param {visor.common.taxforms.IFormW4=} [properties] Properties to set
                     */
                    function FormW4(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FormW4 firstNameAndMiddleInitial.
                     * @member {string} firstNameAndMiddleInitial
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.firstNameAndMiddleInitial = "";

                    /**
                     * FormW4 lastName.
                     * @member {string} lastName
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.lastName = "";

                    /**
                     * FormW4 socialSecurityNumber.
                     * @member {string} socialSecurityNumber
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.socialSecurityNumber = "";

                    /**
                     * FormW4 homeAddress.
                     * @member {string} homeAddress
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.homeAddress = "";

                    /**
                     * FormW4 cityOrTownStateAndZip.
                     * @member {string} cityOrTownStateAndZip
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.cityOrTownStateAndZip = "";

                    /**
                     * FormW4 maritalStatus.
                     * @member {visor.common.taxforms.FormW4.MaritalStatus} maritalStatus
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.maritalStatus = 0;

                    /**
                     * FormW4 lastNameDiffersFromSsn.
                     * @member {boolean} lastNameDiffersFromSsn
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.lastNameDiffersFromSsn = false;

                    /**
                     * FormW4 numberOfAllowances.
                     * @member {visor.common.numeric.INullableUint64|null|undefined} numberOfAllowances
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.numberOfAllowances = null;

                    /**
                     * FormW4 additionalAmount.
                     * @member {visor.common.money.IAmount|null|undefined} additionalAmount
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.additionalAmount = null;

                    /**
                     * FormW4 exempt.
                     * @member {boolean} exempt
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.exempt = false;

                    /**
                     * FormW4 signaturePngData.
                     * @member {Uint8Array} signaturePngData
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.signaturePngData = $util.newBuffer([]);

                    /**
                     * FormW4 signatureTimestamp.
                     * @member {google.protobuf.ITimestamp|null|undefined} signatureTimestamp
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.signatureTimestamp = null;

                    /**
                     * FormW4 targetTaxWithheld.
                     * @member {visor.common.money.IAmount|null|undefined} targetTaxWithheld
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     */
                    FormW4.prototype.targetTaxWithheld = null;

                    /**
                     * Creates a new FormW4 instance using the specified properties.
                     * @function create
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {visor.common.taxforms.IFormW4=} [properties] Properties to set
                     * @returns {visor.common.taxforms.FormW4} FormW4 instance
                     */
                    FormW4.create = function create(properties) {
                        return new FormW4(properties);
                    };

                    /**
                     * Encodes the specified FormW4 message. Does not implicitly {@link visor.common.taxforms.FormW4.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {visor.common.taxforms.IFormW4} message FormW4 message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormW4.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.firstNameAndMiddleInitial != null && message.hasOwnProperty("firstNameAndMiddleInitial"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstNameAndMiddleInitial);
                        if (message.lastName != null && message.hasOwnProperty("lastName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
                        if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.socialSecurityNumber);
                        if (message.homeAddress != null && message.hasOwnProperty("homeAddress"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.homeAddress);
                        if (message.cityOrTownStateAndZip != null && message.hasOwnProperty("cityOrTownStateAndZip"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.cityOrTownStateAndZip);
                        if (message.maritalStatus != null && message.hasOwnProperty("maritalStatus"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maritalStatus);
                        if (message.lastNameDiffersFromSsn != null && message.hasOwnProperty("lastNameDiffersFromSsn"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.lastNameDiffersFromSsn);
                        if (message.numberOfAllowances != null && message.hasOwnProperty("numberOfAllowances"))
                            $root.visor.common.numeric.NullableUint64.encode(message.numberOfAllowances, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.additionalAmount != null && message.hasOwnProperty("additionalAmount"))
                            $root.visor.common.money.Amount.encode(message.additionalAmount, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.exempt != null && message.hasOwnProperty("exempt"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.exempt);
                        if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                            writer.uint32(/* id 11, wireType 2 =*/90).bytes(message.signaturePngData);
                        if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                            $root.google.protobuf.Timestamp.encode(message.signatureTimestamp, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.targetTaxWithheld != null && message.hasOwnProperty("targetTaxWithheld"))
                            $root.visor.common.money.Amount.encode(message.targetTaxWithheld, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified FormW4 message, length delimited. Does not implicitly {@link visor.common.taxforms.FormW4.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {visor.common.taxforms.IFormW4} message FormW4 message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FormW4.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FormW4 message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.taxforms.FormW4} FormW4
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormW4.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.taxforms.FormW4();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.firstNameAndMiddleInitial = reader.string();
                                break;
                            case 2:
                                message.lastName = reader.string();
                                break;
                            case 3:
                                message.socialSecurityNumber = reader.string();
                                break;
                            case 4:
                                message.homeAddress = reader.string();
                                break;
                            case 5:
                                message.cityOrTownStateAndZip = reader.string();
                                break;
                            case 6:
                                message.maritalStatus = reader.int32();
                                break;
                            case 7:
                                message.lastNameDiffersFromSsn = reader.bool();
                                break;
                            case 8:
                                message.numberOfAllowances = $root.visor.common.numeric.NullableUint64.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.additionalAmount = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.exempt = reader.bool();
                                break;
                            case 11:
                                message.signaturePngData = reader.bytes();
                                break;
                            case 12:
                                message.signatureTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 13:
                                message.targetTaxWithheld = $root.visor.common.money.Amount.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FormW4 message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.taxforms.FormW4} FormW4
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FormW4.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FormW4 message.
                     * @function verify
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FormW4.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.firstNameAndMiddleInitial != null && message.hasOwnProperty("firstNameAndMiddleInitial"))
                            if (!$util.isString(message.firstNameAndMiddleInitial))
                                return "firstNameAndMiddleInitial: string expected";
                        if (message.lastName != null && message.hasOwnProperty("lastName"))
                            if (!$util.isString(message.lastName))
                                return "lastName: string expected";
                        if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                            if (!$util.isString(message.socialSecurityNumber))
                                return "socialSecurityNumber: string expected";
                        if (message.homeAddress != null && message.hasOwnProperty("homeAddress"))
                            if (!$util.isString(message.homeAddress))
                                return "homeAddress: string expected";
                        if (message.cityOrTownStateAndZip != null && message.hasOwnProperty("cityOrTownStateAndZip"))
                            if (!$util.isString(message.cityOrTownStateAndZip))
                                return "cityOrTownStateAndZip: string expected";
                        if (message.maritalStatus != null && message.hasOwnProperty("maritalStatus"))
                            switch (message.maritalStatus) {
                            default:
                                return "maritalStatus: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.lastNameDiffersFromSsn != null && message.hasOwnProperty("lastNameDiffersFromSsn"))
                            if (typeof message.lastNameDiffersFromSsn !== "boolean")
                                return "lastNameDiffersFromSsn: boolean expected";
                        if (message.numberOfAllowances != null && message.hasOwnProperty("numberOfAllowances")) {
                            var error = $root.visor.common.numeric.NullableUint64.verify(message.numberOfAllowances);
                            if (error)
                                return "numberOfAllowances." + error;
                        }
                        if (message.additionalAmount != null && message.hasOwnProperty("additionalAmount")) {
                            var error = $root.visor.common.money.Amount.verify(message.additionalAmount);
                            if (error)
                                return "additionalAmount." + error;
                        }
                        if (message.exempt != null && message.hasOwnProperty("exempt"))
                            if (typeof message.exempt !== "boolean")
                                return "exempt: boolean expected";
                        if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                            if (!(message.signaturePngData && typeof message.signaturePngData.length === "number" || $util.isString(message.signaturePngData)))
                                return "signaturePngData: buffer expected";
                        if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.signatureTimestamp);
                            if (error)
                                return "signatureTimestamp." + error;
                        }
                        if (message.targetTaxWithheld != null && message.hasOwnProperty("targetTaxWithheld")) {
                            var error = $root.visor.common.money.Amount.verify(message.targetTaxWithheld);
                            if (error)
                                return "targetTaxWithheld." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a FormW4 message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.taxforms.FormW4} FormW4
                     */
                    FormW4.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.taxforms.FormW4)
                            return object;
                        var message = new $root.visor.common.taxforms.FormW4();
                        if (object.firstNameAndMiddleInitial != null)
                            message.firstNameAndMiddleInitial = String(object.firstNameAndMiddleInitial);
                        if (object.lastName != null)
                            message.lastName = String(object.lastName);
                        if (object.socialSecurityNumber != null)
                            message.socialSecurityNumber = String(object.socialSecurityNumber);
                        if (object.homeAddress != null)
                            message.homeAddress = String(object.homeAddress);
                        if (object.cityOrTownStateAndZip != null)
                            message.cityOrTownStateAndZip = String(object.cityOrTownStateAndZip);
                        switch (object.maritalStatus) {
                        case "FORM_W4_MARITAL_STATUS_INVALID":
                        case 0:
                            message.maritalStatus = 0;
                            break;
                        case "FORM_W4_MARITAL_STATUS_SINGLE":
                        case 1:
                            message.maritalStatus = 1;
                            break;
                        case "FORM_W4_MARITAL_STATUS_MARRIED":
                        case 2:
                            message.maritalStatus = 2;
                            break;
                        case "FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE":
                        case 3:
                            message.maritalStatus = 3;
                            break;
                        }
                        if (object.lastNameDiffersFromSsn != null)
                            message.lastNameDiffersFromSsn = Boolean(object.lastNameDiffersFromSsn);
                        if (object.numberOfAllowances != null) {
                            if (typeof object.numberOfAllowances !== "object")
                                throw TypeError(".visor.common.taxforms.FormW4.numberOfAllowances: object expected");
                            message.numberOfAllowances = $root.visor.common.numeric.NullableUint64.fromObject(object.numberOfAllowances);
                        }
                        if (object.additionalAmount != null) {
                            if (typeof object.additionalAmount !== "object")
                                throw TypeError(".visor.common.taxforms.FormW4.additionalAmount: object expected");
                            message.additionalAmount = $root.visor.common.money.Amount.fromObject(object.additionalAmount);
                        }
                        if (object.exempt != null)
                            message.exempt = Boolean(object.exempt);
                        if (object.signaturePngData != null)
                            if (typeof object.signaturePngData === "string")
                                $util.base64.decode(object.signaturePngData, message.signaturePngData = $util.newBuffer($util.base64.length(object.signaturePngData)), 0);
                            else if (object.signaturePngData.length)
                                message.signaturePngData = object.signaturePngData;
                        if (object.signatureTimestamp != null)
                            if (object.signatureTimestamp === null)
                                message.signatureTimestamp = {};
                            else
                                message.signatureTimestamp = {
                                    seconds: Math.floor(object.signatureTimestamp.getTime() / 1000),
                                    nanos: object.signatureTimestamp.getMilliseconds() * 1000000
                                };
                        if (object.targetTaxWithheld != null) {
                            if (typeof object.targetTaxWithheld !== "object")
                                throw TypeError(".visor.common.taxforms.FormW4.targetTaxWithheld: object expected");
                            message.targetTaxWithheld = $root.visor.common.money.Amount.fromObject(object.targetTaxWithheld);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FormW4 message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.taxforms.FormW4
                     * @static
                     * @param {visor.common.taxforms.FormW4} message FormW4
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FormW4.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.firstNameAndMiddleInitial = "";
                            object.lastName = "";
                            object.socialSecurityNumber = "";
                            object.homeAddress = "";
                            object.cityOrTownStateAndZip = "";
                            object.maritalStatus = options.enums === String ? "FORM_W4_MARITAL_STATUS_INVALID" : 0;
                            object.lastNameDiffersFromSsn = false;
                            object.numberOfAllowances = null;
                            object.additionalAmount = null;
                            object.exempt = false;
                            if (options.bytes === String)
                                object.signaturePngData = "";
                            else {
                                object.signaturePngData = [];
                                if (options.bytes !== Array)
                                    object.signaturePngData = $util.newBuffer(object.signaturePngData);
                            }
                            object.signatureTimestamp = null;
                            object.targetTaxWithheld = null;
                        }
                        if (message.firstNameAndMiddleInitial != null && message.hasOwnProperty("firstNameAndMiddleInitial"))
                            object.firstNameAndMiddleInitial = message.firstNameAndMiddleInitial;
                        if (message.lastName != null && message.hasOwnProperty("lastName"))
                            object.lastName = message.lastName;
                        if (message.socialSecurityNumber != null && message.hasOwnProperty("socialSecurityNumber"))
                            object.socialSecurityNumber = message.socialSecurityNumber;
                        if (message.homeAddress != null && message.hasOwnProperty("homeAddress"))
                            object.homeAddress = message.homeAddress;
                        if (message.cityOrTownStateAndZip != null && message.hasOwnProperty("cityOrTownStateAndZip"))
                            object.cityOrTownStateAndZip = message.cityOrTownStateAndZip;
                        if (message.maritalStatus != null && message.hasOwnProperty("maritalStatus"))
                            object.maritalStatus = options.enums === String ? $root.visor.common.taxforms.FormW4.MaritalStatus[message.maritalStatus] : message.maritalStatus;
                        if (message.lastNameDiffersFromSsn != null && message.hasOwnProperty("lastNameDiffersFromSsn"))
                            object.lastNameDiffersFromSsn = message.lastNameDiffersFromSsn;
                        if (message.numberOfAllowances != null && message.hasOwnProperty("numberOfAllowances"))
                            object.numberOfAllowances = $root.visor.common.numeric.NullableUint64.toObject(message.numberOfAllowances, options);
                        if (message.additionalAmount != null && message.hasOwnProperty("additionalAmount"))
                            object.additionalAmount = $root.visor.common.money.Amount.toObject(message.additionalAmount, options);
                        if (message.exempt != null && message.hasOwnProperty("exempt"))
                            object.exempt = message.exempt;
                        if (message.signaturePngData != null && message.hasOwnProperty("signaturePngData"))
                            object.signaturePngData = options.bytes === String ? $util.base64.encode(message.signaturePngData, 0, message.signaturePngData.length) : options.bytes === Array ? Array.prototype.slice.call(message.signaturePngData) : message.signaturePngData;
                        if (message.signatureTimestamp != null && message.hasOwnProperty("signatureTimestamp"))
                            object.signatureTimestamp = new Date(message.signatureTimestamp.seconds * 1000 + message.signatureTimestamp.nanos / 1000000);
                        if (message.targetTaxWithheld != null && message.hasOwnProperty("targetTaxWithheld"))
                            object.targetTaxWithheld = $root.visor.common.money.Amount.toObject(message.targetTaxWithheld, options);
                        return object;
                    };

                    /**
                     * Converts this FormW4 to JSON.
                     * @function toJSON
                     * @memberof visor.common.taxforms.FormW4
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FormW4.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * MaritalStatus enum.
                     * @name visor.common.taxforms.FormW4.MaritalStatus
                     * @enum {string}
                     * @property {number} FORM_W4_MARITAL_STATUS_INVALID=0 FORM_W4_MARITAL_STATUS_INVALID value
                     * @property {number} FORM_W4_MARITAL_STATUS_SINGLE=1 FORM_W4_MARITAL_STATUS_SINGLE value
                     * @property {number} FORM_W4_MARITAL_STATUS_MARRIED=2 FORM_W4_MARITAL_STATUS_MARRIED value
                     * @property {number} FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE=3 FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE value
                     */
                    FormW4.MaritalStatus = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "FORM_W4_MARITAL_STATUS_INVALID"] = 0;
                        values[valuesById[1] = "FORM_W4_MARITAL_STATUS_SINGLE"] = 1;
                        values[valuesById[2] = "FORM_W4_MARITAL_STATUS_MARRIED"] = 2;
                        values[valuesById[3] = "FORM_W4_MARITAL_STATUS_MARRIED_BUT_WITHHOLD_AT_HIGHER_SINGLE_RATE"] = 3;
                        return values;
                    })();

                    return FormW4;
                })();

                return taxforms;
            })();

            common.time = (function() {

                /**
                 * Namespace time.
                 * @memberof visor.common
                 * @namespace
                 */
                var time = {};

                /**
                 * Month enum.
                 * @name visor.common.time.Month
                 * @enum {string}
                 * @property {number} MONTH_INVALID=0 MONTH_INVALID value
                 * @property {number} MONTH_JANUARY=1 MONTH_JANUARY value
                 * @property {number} MONTH_FEBRUARY=2 MONTH_FEBRUARY value
                 * @property {number} MONTH_MARCH=3 MONTH_MARCH value
                 * @property {number} MONTH_APRIL=4 MONTH_APRIL value
                 * @property {number} MONTH_MAY=5 MONTH_MAY value
                 * @property {number} MONTH_JUNE=6 MONTH_JUNE value
                 * @property {number} MONTH_JULY=7 MONTH_JULY value
                 * @property {number} MONTH_AUGUST=8 MONTH_AUGUST value
                 * @property {number} MONTH_SEPTEMBER=9 MONTH_SEPTEMBER value
                 * @property {number} MONTH_OCTOBER=10 MONTH_OCTOBER value
                 * @property {number} MONTH_NOVEMBER=11 MONTH_NOVEMBER value
                 * @property {number} MONTH_DECEMBER=12 MONTH_DECEMBER value
                 */
                time.Month = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "MONTH_INVALID"] = 0;
                    values[valuesById[1] = "MONTH_JANUARY"] = 1;
                    values[valuesById[2] = "MONTH_FEBRUARY"] = 2;
                    values[valuesById[3] = "MONTH_MARCH"] = 3;
                    values[valuesById[4] = "MONTH_APRIL"] = 4;
                    values[valuesById[5] = "MONTH_MAY"] = 5;
                    values[valuesById[6] = "MONTH_JUNE"] = 6;
                    values[valuesById[7] = "MONTH_JULY"] = 7;
                    values[valuesById[8] = "MONTH_AUGUST"] = 8;
                    values[valuesById[9] = "MONTH_SEPTEMBER"] = 9;
                    values[valuesById[10] = "MONTH_OCTOBER"] = 10;
                    values[valuesById[11] = "MONTH_NOVEMBER"] = 11;
                    values[valuesById[12] = "MONTH_DECEMBER"] = 12;
                    return values;
                })();

                time.LocalDate = (function() {

                    /**
                     * Properties of a LocalDate.
                     * @memberof visor.common.time
                     * @interface ILocalDate
                     * @property {number|Long|null} [year] LocalDate year
                     * @property {visor.common.time.Month|null} [month] LocalDate month
                     * @property {number|Long|null} [day] LocalDate day
                     */

                    /**
                     * Constructs a new LocalDate.
                     * @memberof visor.common.time
                     * @classdesc Represents a LocalDate.
                     * @implements ILocalDate
                     * @constructor
                     * @param {visor.common.time.ILocalDate=} [properties] Properties to set
                     */
                    function LocalDate(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LocalDate year.
                     * @member {number|Long} year
                     * @memberof visor.common.time.LocalDate
                     * @instance
                     */
                    LocalDate.prototype.year = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * LocalDate month.
                     * @member {visor.common.time.Month} month
                     * @memberof visor.common.time.LocalDate
                     * @instance
                     */
                    LocalDate.prototype.month = 0;

                    /**
                     * LocalDate day.
                     * @member {number|Long} day
                     * @memberof visor.common.time.LocalDate
                     * @instance
                     */
                    LocalDate.prototype.day = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new LocalDate instance using the specified properties.
                     * @function create
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {visor.common.time.ILocalDate=} [properties] Properties to set
                     * @returns {visor.common.time.LocalDate} LocalDate instance
                     */
                    LocalDate.create = function create(properties) {
                        return new LocalDate(properties);
                    };

                    /**
                     * Encodes the specified LocalDate message. Does not implicitly {@link visor.common.time.LocalDate.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {visor.common.time.ILocalDate} message LocalDate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LocalDate.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.year != null && message.hasOwnProperty("year"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.year);
                        if (message.month != null && message.hasOwnProperty("month"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                        if (message.day != null && message.hasOwnProperty("day"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.day);
                        return writer;
                    };

                    /**
                     * Encodes the specified LocalDate message, length delimited. Does not implicitly {@link visor.common.time.LocalDate.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {visor.common.time.ILocalDate} message LocalDate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LocalDate.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a LocalDate message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.time.LocalDate} LocalDate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LocalDate.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.time.LocalDate();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.year = reader.int64();
                                break;
                            case 2:
                                message.month = reader.int32();
                                break;
                            case 3:
                                message.day = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a LocalDate message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.time.LocalDate} LocalDate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LocalDate.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a LocalDate message.
                     * @function verify
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LocalDate.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.year != null && message.hasOwnProperty("year"))
                            if (!$util.isInteger(message.year) && !(message.year && $util.isInteger(message.year.low) && $util.isInteger(message.year.high)))
                                return "year: integer|Long expected";
                        if (message.month != null && message.hasOwnProperty("month"))
                            switch (message.month) {
                            default:
                                return "month: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                                break;
                            }
                        if (message.day != null && message.hasOwnProperty("day"))
                            if (!$util.isInteger(message.day) && !(message.day && $util.isInteger(message.day.low) && $util.isInteger(message.day.high)))
                                return "day: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a LocalDate message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.time.LocalDate} LocalDate
                     */
                    LocalDate.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.time.LocalDate)
                            return object;
                        var message = new $root.visor.common.time.LocalDate();
                        if (object.year != null)
                            if ($util.Long)
                                (message.year = $util.Long.fromValue(object.year)).unsigned = false;
                            else if (typeof object.year === "string")
                                message.year = parseInt(object.year, 10);
                            else if (typeof object.year === "number")
                                message.year = object.year;
                            else if (typeof object.year === "object")
                                message.year = new $util.LongBits(object.year.low >>> 0, object.year.high >>> 0).toNumber();
                        switch (object.month) {
                        case "MONTH_INVALID":
                        case 0:
                            message.month = 0;
                            break;
                        case "MONTH_JANUARY":
                        case 1:
                            message.month = 1;
                            break;
                        case "MONTH_FEBRUARY":
                        case 2:
                            message.month = 2;
                            break;
                        case "MONTH_MARCH":
                        case 3:
                            message.month = 3;
                            break;
                        case "MONTH_APRIL":
                        case 4:
                            message.month = 4;
                            break;
                        case "MONTH_MAY":
                        case 5:
                            message.month = 5;
                            break;
                        case "MONTH_JUNE":
                        case 6:
                            message.month = 6;
                            break;
                        case "MONTH_JULY":
                        case 7:
                            message.month = 7;
                            break;
                        case "MONTH_AUGUST":
                        case 8:
                            message.month = 8;
                            break;
                        case "MONTH_SEPTEMBER":
                        case 9:
                            message.month = 9;
                            break;
                        case "MONTH_OCTOBER":
                        case 10:
                            message.month = 10;
                            break;
                        case "MONTH_NOVEMBER":
                        case 11:
                            message.month = 11;
                            break;
                        case "MONTH_DECEMBER":
                        case 12:
                            message.month = 12;
                            break;
                        }
                        if (object.day != null)
                            if ($util.Long)
                                (message.day = $util.Long.fromValue(object.day)).unsigned = false;
                            else if (typeof object.day === "string")
                                message.day = parseInt(object.day, 10);
                            else if (typeof object.day === "number")
                                message.day = object.day;
                            else if (typeof object.day === "object")
                                message.day = new $util.LongBits(object.day.low >>> 0, object.day.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a LocalDate message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.time.LocalDate
                     * @static
                     * @param {visor.common.time.LocalDate} message LocalDate
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LocalDate.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.year = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.year = options.longs === String ? "0" : 0;
                            object.month = options.enums === String ? "MONTH_INVALID" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.day = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.day = options.longs === String ? "0" : 0;
                        }
                        if (message.year != null && message.hasOwnProperty("year"))
                            if (typeof message.year === "number")
                                object.year = options.longs === String ? String(message.year) : message.year;
                            else
                                object.year = options.longs === String ? $util.Long.prototype.toString.call(message.year) : options.longs === Number ? new $util.LongBits(message.year.low >>> 0, message.year.high >>> 0).toNumber() : message.year;
                        if (message.month != null && message.hasOwnProperty("month"))
                            object.month = options.enums === String ? $root.visor.common.time.Month[message.month] : message.month;
                        if (message.day != null && message.hasOwnProperty("day"))
                            if (typeof message.day === "number")
                                object.day = options.longs === String ? String(message.day) : message.day;
                            else
                                object.day = options.longs === String ? $util.Long.prototype.toString.call(message.day) : options.longs === Number ? new $util.LongBits(message.day.low >>> 0, message.day.high >>> 0).toNumber() : message.day;
                        return object;
                    };

                    /**
                     * Converts this LocalDate to JSON.
                     * @function toJSON
                     * @memberof visor.common.time.LocalDate
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LocalDate.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return LocalDate;
                })();

                time.Date = (function() {

                    /**
                     * Properties of a Date.
                     * @memberof visor.common.time
                     * @interface IDate
                     * @property {number|Long|null} [unixSeconds] Date unixSeconds
                     * @property {number|Long|null} [unixNano] Date unixNano
                     * @property {string|null} [rfc_3339Format] Date rfc_3339Format
                     * @property {string|null} [rfc_3339NanoFormat] Date rfc_3339NanoFormat
                     */

                    /**
                     * Constructs a new Date.
                     * @memberof visor.common.time
                     * @classdesc Represents a Date.
                     * @implements IDate
                     * @constructor
                     * @param {visor.common.time.IDate=} [properties] Properties to set
                     */
                    function Date(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Date unixSeconds.
                     * @member {number|Long} unixSeconds
                     * @memberof visor.common.time.Date
                     * @instance
                     */
                    Date.prototype.unixSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Date unixNano.
                     * @member {number|Long} unixNano
                     * @memberof visor.common.time.Date
                     * @instance
                     */
                    Date.prototype.unixNano = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Date rfc_3339Format.
                     * @member {string} rfc_3339Format
                     * @memberof visor.common.time.Date
                     * @instance
                     */
                    Date.prototype.rfc_3339Format = "";

                    /**
                     * Date rfc_3339NanoFormat.
                     * @member {string} rfc_3339NanoFormat
                     * @memberof visor.common.time.Date
                     * @instance
                     */
                    Date.prototype.rfc_3339NanoFormat = "";

                    /**
                     * Creates a new Date instance using the specified properties.
                     * @function create
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {visor.common.time.IDate=} [properties] Properties to set
                     * @returns {visor.common.time.Date} Date instance
                     */
                    Date.create = function create(properties) {
                        return new Date(properties);
                    };

                    /**
                     * Encodes the specified Date message. Does not implicitly {@link visor.common.time.Date.verify|verify} messages.
                     * @function encode
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {visor.common.time.IDate} message Date message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Date.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.unixSeconds != null && message.hasOwnProperty("unixSeconds"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.unixSeconds);
                        if (message.unixNano != null && message.hasOwnProperty("unixNano"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.unixNano);
                        if (message.rfc_3339Format != null && message.hasOwnProperty("rfc_3339Format"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.rfc_3339Format);
                        if (message.rfc_3339NanoFormat != null && message.hasOwnProperty("rfc_3339NanoFormat"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.rfc_3339NanoFormat);
                        return writer;
                    };

                    /**
                     * Encodes the specified Date message, length delimited. Does not implicitly {@link visor.common.time.Date.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {visor.common.time.IDate} message Date message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Date.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Date message from the specified reader or buffer.
                     * @function decode
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {visor.common.time.Date} Date
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Date.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.visor.common.time.Date();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.unixSeconds = reader.int64();
                                break;
                            case 2:
                                message.unixNano = reader.int64();
                                break;
                            case 3:
                                message.rfc_3339Format = reader.string();
                                break;
                            case 4:
                                message.rfc_3339NanoFormat = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Date message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {visor.common.time.Date} Date
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Date.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Date message.
                     * @function verify
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Date.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.unixSeconds != null && message.hasOwnProperty("unixSeconds"))
                            if (!$util.isInteger(message.unixSeconds) && !(message.unixSeconds && $util.isInteger(message.unixSeconds.low) && $util.isInteger(message.unixSeconds.high)))
                                return "unixSeconds: integer|Long expected";
                        if (message.unixNano != null && message.hasOwnProperty("unixNano"))
                            if (!$util.isInteger(message.unixNano) && !(message.unixNano && $util.isInteger(message.unixNano.low) && $util.isInteger(message.unixNano.high)))
                                return "unixNano: integer|Long expected";
                        if (message.rfc_3339Format != null && message.hasOwnProperty("rfc_3339Format"))
                            if (!$util.isString(message.rfc_3339Format))
                                return "rfc_3339Format: string expected";
                        if (message.rfc_3339NanoFormat != null && message.hasOwnProperty("rfc_3339NanoFormat"))
                            if (!$util.isString(message.rfc_3339NanoFormat))
                                return "rfc_3339NanoFormat: string expected";
                        return null;
                    };

                    /**
                     * Creates a Date message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {visor.common.time.Date} Date
                     */
                    Date.fromObject = function fromObject(object) {
                        if (object instanceof $root.visor.common.time.Date)
                            return object;
                        var message = new $root.visor.common.time.Date();
                        if (object.unixSeconds != null)
                            if ($util.Long)
                                (message.unixSeconds = $util.Long.fromValue(object.unixSeconds)).unsigned = false;
                            else if (typeof object.unixSeconds === "string")
                                message.unixSeconds = parseInt(object.unixSeconds, 10);
                            else if (typeof object.unixSeconds === "number")
                                message.unixSeconds = object.unixSeconds;
                            else if (typeof object.unixSeconds === "object")
                                message.unixSeconds = new $util.LongBits(object.unixSeconds.low >>> 0, object.unixSeconds.high >>> 0).toNumber();
                        if (object.unixNano != null)
                            if ($util.Long)
                                (message.unixNano = $util.Long.fromValue(object.unixNano)).unsigned = false;
                            else if (typeof object.unixNano === "string")
                                message.unixNano = parseInt(object.unixNano, 10);
                            else if (typeof object.unixNano === "number")
                                message.unixNano = object.unixNano;
                            else if (typeof object.unixNano === "object")
                                message.unixNano = new $util.LongBits(object.unixNano.low >>> 0, object.unixNano.high >>> 0).toNumber();
                        if (object.rfc_3339Format != null)
                            message.rfc_3339Format = String(object.rfc_3339Format);
                        if (object.rfc_3339NanoFormat != null)
                            message.rfc_3339NanoFormat = String(object.rfc_3339NanoFormat);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Date message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof visor.common.time.Date
                     * @static
                     * @param {visor.common.time.Date} message Date
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Date.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.unixSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.unixSeconds = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.unixNano = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.unixNano = options.longs === String ? "0" : 0;
                            object.rfc_3339Format = "";
                            object.rfc_3339NanoFormat = "";
                        }
                        if (message.unixSeconds != null && message.hasOwnProperty("unixSeconds"))
                            if (typeof message.unixSeconds === "number")
                                object.unixSeconds = options.longs === String ? String(message.unixSeconds) : message.unixSeconds;
                            else
                                object.unixSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.unixSeconds) : options.longs === Number ? new $util.LongBits(message.unixSeconds.low >>> 0, message.unixSeconds.high >>> 0).toNumber() : message.unixSeconds;
                        if (message.unixNano != null && message.hasOwnProperty("unixNano"))
                            if (typeof message.unixNano === "number")
                                object.unixNano = options.longs === String ? String(message.unixNano) : message.unixNano;
                            else
                                object.unixNano = options.longs === String ? $util.Long.prototype.toString.call(message.unixNano) : options.longs === Number ? new $util.LongBits(message.unixNano.low >>> 0, message.unixNano.high >>> 0).toNumber() : message.unixNano;
                        if (message.rfc_3339Format != null && message.hasOwnProperty("rfc_3339Format"))
                            object.rfc_3339Format = message.rfc_3339Format;
                        if (message.rfc_3339NanoFormat != null && message.hasOwnProperty("rfc_3339NanoFormat"))
                            object.rfc_3339NanoFormat = message.rfc_3339NanoFormat;
                        return object;
                    };

                    /**
                     * Converts this Date to JSON.
                     * @function toJSON
                     * @memberof visor.common.time.Date
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Date.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Date;
                })();

                return time;
            })();

            return common;
        })();

        return visor;
    })();

    $root.grid = (function() {

        /**
         * Namespace grid.
         * @exports grid
         * @namespace
         */
        var grid = {};

        grid.common = (function() {

            /**
             * Namespace common.
             * @memberof grid
             * @namespace
             */
            var common = {};

            common.requester = (function() {

                /**
                 * Namespace requester.
                 * @memberof grid.common
                 * @namespace
                 */
                var requester = {};

                requester.Requester = (function() {

                    /**
                     * Properties of a Requester.
                     * @memberof grid.common.requester
                     * @interface IRequester
                     * @property {grid.common.requester.RequesterType|null} [type] Requester type
                     * @property {string|null} [email] Requester email
                     */

                    /**
                     * Constructs a new Requester.
                     * @memberof grid.common.requester
                     * @classdesc Represents a Requester.
                     * @implements IRequester
                     * @constructor
                     * @param {grid.common.requester.IRequester=} [properties] Properties to set
                     */
                    function Requester(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Requester type.
                     * @member {grid.common.requester.RequesterType} type
                     * @memberof grid.common.requester.Requester
                     * @instance
                     */
                    Requester.prototype.type = 0;

                    /**
                     * Requester email.
                     * @member {string} email
                     * @memberof grid.common.requester.Requester
                     * @instance
                     */
                    Requester.prototype.email = "";

                    /**
                     * Creates a new Requester instance using the specified properties.
                     * @function create
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {grid.common.requester.IRequester=} [properties] Properties to set
                     * @returns {grid.common.requester.Requester} Requester instance
                     */
                    Requester.create = function create(properties) {
                        return new Requester(properties);
                    };

                    /**
                     * Encodes the specified Requester message. Does not implicitly {@link grid.common.requester.Requester.verify|verify} messages.
                     * @function encode
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {grid.common.requester.IRequester} message Requester message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Requester.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && message.hasOwnProperty("type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                        return writer;
                    };

                    /**
                     * Encodes the specified Requester message, length delimited. Does not implicitly {@link grid.common.requester.Requester.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {grid.common.requester.IRequester} message Requester message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Requester.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Requester message from the specified reader or buffer.
                     * @function decode
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {grid.common.requester.Requester} Requester
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Requester.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.grid.common.requester.Requester();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.type = reader.int32();
                                break;
                            case 2:
                                message.email = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Requester message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {grid.common.requester.Requester} Requester
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Requester.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Requester message.
                     * @function verify
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Requester.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        return null;
                    };

                    /**
                     * Creates a Requester message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {grid.common.requester.Requester} Requester
                     */
                    Requester.fromObject = function fromObject(object) {
                        if (object instanceof $root.grid.common.requester.Requester)
                            return object;
                        var message = new $root.grid.common.requester.Requester();
                        switch (object.type) {
                        case "REQUESTER_TYPE_INVALID":
                        case 0:
                            message.type = 0;
                            break;
                        case "REQUESTER_TYPE_CLIENT":
                        case 1:
                            message.type = 1;
                            break;
                        case "REQUESTER_TYPE_ADMIN":
                        case 2:
                            message.type = 2;
                            break;
                        case "REQUESTER_TYPE_SERVICE_ACCOUNT":
                        case 3:
                            message.type = 3;
                            break;
                        }
                        if (object.email != null)
                            message.email = String(object.email);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Requester message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof grid.common.requester.Requester
                     * @static
                     * @param {grid.common.requester.Requester} message Requester
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Requester.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.type = options.enums === String ? "REQUESTER_TYPE_INVALID" : 0;
                            object.email = "";
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.grid.common.requester.RequesterType[message.type] : message.type;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        return object;
                    };

                    /**
                     * Converts this Requester to JSON.
                     * @function toJSON
                     * @memberof grid.common.requester.Requester
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Requester.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Requester;
                })();

                /**
                 * RequesterType enum.
                 * @name grid.common.requester.RequesterType
                 * @enum {string}
                 * @property {number} REQUESTER_TYPE_INVALID=0 REQUESTER_TYPE_INVALID value
                 * @property {number} REQUESTER_TYPE_CLIENT=1 REQUESTER_TYPE_CLIENT value
                 * @property {number} REQUESTER_TYPE_ADMIN=2 REQUESTER_TYPE_ADMIN value
                 * @property {number} REQUESTER_TYPE_SERVICE_ACCOUNT=3 REQUESTER_TYPE_SERVICE_ACCOUNT value
                 */
                requester.RequesterType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REQUESTER_TYPE_INVALID"] = 0;
                    values[valuesById[1] = "REQUESTER_TYPE_CLIENT"] = 1;
                    values[valuesById[2] = "REQUESTER_TYPE_ADMIN"] = 2;
                    values[valuesById[3] = "REQUESTER_TYPE_SERVICE_ACCOUNT"] = 3;
                    return values;
                })();

                return requester;
            })();

            return common;
        })();

        return grid;
    })();

    $root.google = (function() {

        /**
         * Namespace google.
         * @exports google
         * @namespace
         */
        var google = {};

        google.protobuf = (function() {

            /**
             * Namespace protobuf.
             * @memberof google
             * @namespace
             */
            var protobuf = {};

            protobuf.Timestamp = (function() {

                /**
                 * Properties of a Timestamp.
                 * @memberof google.protobuf
                 * @interface ITimestamp
                 * @property {number|Long|null} [seconds] Timestamp seconds
                 * @property {number|null} [nanos] Timestamp nanos
                 */

                /**
                 * Constructs a new Timestamp.
                 * @memberof google.protobuf
                 * @classdesc Represents a Timestamp.
                 * @implements ITimestamp
                 * @constructor
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 */
                function Timestamp(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Timestamp seconds.
                 * @member {number|Long} seconds
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Timestamp nanos.
                 * @member {number} nanos
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 */
                Timestamp.prototype.nanos = 0;

                /**
                 * Creates a new Timestamp instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp=} [properties] Properties to set
                 * @returns {google.protobuf.Timestamp} Timestamp instance
                 */
                Timestamp.create = function create(properties) {
                    return new Timestamp(properties);
                };

                /**
                 * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                    return writer;
                };

                /**
                 * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Timestamp message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.seconds = reader.int64();
                            break;
                        case 2:
                            message.nanos = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Timestamp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Timestamp message.
                 * @function verify
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Timestamp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                            return "seconds: integer|Long expected";
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        if (!$util.isInteger(message.nanos))
                            return "nanos: integer expected";
                    return null;
                };

                /**
                 * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Timestamp} Timestamp
                 */
                Timestamp.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Timestamp)
                        return object;
                    var message = new $root.google.protobuf.Timestamp();
                    if (object.seconds != null)
                        if ($util.Long)
                            (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                        else if (typeof object.seconds === "string")
                            message.seconds = parseInt(object.seconds, 10);
                        else if (typeof object.seconds === "number")
                            message.seconds = object.seconds;
                        else if (typeof object.seconds === "object")
                            message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                    if (object.nanos != null)
                        message.nanos = object.nanos | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Timestamp
                 * @static
                 * @param {google.protobuf.Timestamp} message Timestamp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Timestamp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.seconds = options.longs === String ? "0" : 0;
                        object.nanos = 0;
                    }
                    if (message.seconds != null && message.hasOwnProperty("seconds"))
                        if (typeof message.seconds === "number")
                            object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                        else
                            object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                    if (message.nanos != null && message.hasOwnProperty("nanos"))
                        object.nanos = message.nanos;
                    return object;
                };

                /**
                 * Converts this Timestamp to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Timestamp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Timestamp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Timestamp;
            })();

            protobuf.DoubleValue = (function() {

                /**
                 * Properties of a DoubleValue.
                 * @memberof google.protobuf
                 * @interface IDoubleValue
                 * @property {number|null} [value] DoubleValue value
                 */

                /**
                 * Constructs a new DoubleValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a DoubleValue.
                 * @implements IDoubleValue
                 * @constructor
                 * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
                 */
                function DoubleValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DoubleValue value.
                 * @member {number} value
                 * @memberof google.protobuf.DoubleValue
                 * @instance
                 */
                DoubleValue.prototype.value = 0;

                /**
                 * Creates a new DoubleValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
                 * @returns {google.protobuf.DoubleValue} DoubleValue instance
                 */
                DoubleValue.create = function create(properties) {
                    return new DoubleValue(properties);
                };

                /**
                 * Encodes the specified DoubleValue message. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DoubleValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified DoubleValue message, length delimited. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DoubleValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DoubleValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DoubleValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DoubleValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.double();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DoubleValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DoubleValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DoubleValue message.
                 * @function verify
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DoubleValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "number")
                            return "value: number expected";
                    return null;
                };

                /**
                 * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DoubleValue} DoubleValue
                 */
                DoubleValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DoubleValue)
                        return object;
                    var message = new $root.google.protobuf.DoubleValue();
                    if (object.value != null)
                        message.value = Number(object.value);
                    return message;
                };

                /**
                 * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DoubleValue
                 * @static
                 * @param {google.protobuf.DoubleValue} message DoubleValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DoubleValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                    return object;
                };

                /**
                 * Converts this DoubleValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DoubleValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DoubleValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return DoubleValue;
            })();

            protobuf.FloatValue = (function() {

                /**
                 * Properties of a FloatValue.
                 * @memberof google.protobuf
                 * @interface IFloatValue
                 * @property {number|null} [value] FloatValue value
                 */

                /**
                 * Constructs a new FloatValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a FloatValue.
                 * @implements IFloatValue
                 * @constructor
                 * @param {google.protobuf.IFloatValue=} [properties] Properties to set
                 */
                function FloatValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * FloatValue value.
                 * @member {number} value
                 * @memberof google.protobuf.FloatValue
                 * @instance
                 */
                FloatValue.prototype.value = 0;

                /**
                 * Creates a new FloatValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue=} [properties] Properties to set
                 * @returns {google.protobuf.FloatValue} FloatValue instance
                 */
                FloatValue.create = function create(properties) {
                    return new FloatValue(properties);
                };

                /**
                 * Encodes the specified FloatValue message. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FloatValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 5 =*/13).float(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified FloatValue message, length delimited. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                FloatValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a FloatValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.FloatValue} FloatValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FloatValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FloatValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.float();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a FloatValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.FloatValue} FloatValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                FloatValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a FloatValue message.
                 * @function verify
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                FloatValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "number")
                            return "value: number expected";
                    return null;
                };

                /**
                 * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.FloatValue} FloatValue
                 */
                FloatValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.FloatValue)
                        return object;
                    var message = new $root.google.protobuf.FloatValue();
                    if (object.value != null)
                        message.value = Number(object.value);
                    return message;
                };

                /**
                 * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.FloatValue
                 * @static
                 * @param {google.protobuf.FloatValue} message FloatValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                FloatValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                    return object;
                };

                /**
                 * Converts this FloatValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.FloatValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                FloatValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return FloatValue;
            })();

            protobuf.Int64Value = (function() {

                /**
                 * Properties of an Int64Value.
                 * @memberof google.protobuf
                 * @interface IInt64Value
                 * @property {number|Long|null} [value] Int64Value value
                 */

                /**
                 * Constructs a new Int64Value.
                 * @memberof google.protobuf
                 * @classdesc Represents an Int64Value.
                 * @implements IInt64Value
                 * @constructor
                 * @param {google.protobuf.IInt64Value=} [properties] Properties to set
                 */
                function Int64Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Int64Value value.
                 * @member {number|Long} value
                 * @memberof google.protobuf.Int64Value
                 * @instance
                 */
                Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Int64Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value=} [properties] Properties to set
                 * @returns {google.protobuf.Int64Value} Int64Value instance
                 */
                Int64Value.create = function create(properties) {
                    return new Int64Value(properties);
                };

                /**
                 * Encodes the specified Int64Value message. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int64Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified Int64Value message, length delimited. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int64Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Int64Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Int64Value} Int64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int64Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int64Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Int64Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Int64Value} Int64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int64Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Int64Value message.
                 * @function verify
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Int64Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    return null;
                };

                /**
                 * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Int64Value} Int64Value
                 */
                Int64Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Int64Value)
                        return object;
                    var message = new $root.google.protobuf.Int64Value();
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Int64Value
                 * @static
                 * @param {google.protobuf.Int64Value} message Int64Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Int64Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                    return object;
                };

                /**
                 * Converts this Int64Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Int64Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Int64Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Int64Value;
            })();

            protobuf.UInt64Value = (function() {

                /**
                 * Properties of a UInt64Value.
                 * @memberof google.protobuf
                 * @interface IUInt64Value
                 * @property {number|Long|null} [value] UInt64Value value
                 */

                /**
                 * Constructs a new UInt64Value.
                 * @memberof google.protobuf
                 * @classdesc Represents a UInt64Value.
                 * @implements IUInt64Value
                 * @constructor
                 * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
                 */
                function UInt64Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UInt64Value value.
                 * @member {number|Long} value
                 * @memberof google.protobuf.UInt64Value
                 * @instance
                 */
                UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new UInt64Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
                 * @returns {google.protobuf.UInt64Value} UInt64Value instance
                 */
                UInt64Value.create = function create(properties) {
                    return new UInt64Value(properties);
                };

                /**
                 * Encodes the specified UInt64Value message. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt64Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified UInt64Value message, length delimited. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt64Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UInt64Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt64Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt64Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UInt64Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt64Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UInt64Value message.
                 * @function verify
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UInt64Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UInt64Value} UInt64Value
                 */
                UInt64Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UInt64Value)
                        return object;
                    var message = new $root.google.protobuf.UInt64Value();
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = true;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UInt64Value
                 * @static
                 * @param {google.protobuf.UInt64Value} message UInt64Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UInt64Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber(true) : message.value;
                    return object;
                };

                /**
                 * Converts this UInt64Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UInt64Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UInt64Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UInt64Value;
            })();

            protobuf.Int32Value = (function() {

                /**
                 * Properties of an Int32Value.
                 * @memberof google.protobuf
                 * @interface IInt32Value
                 * @property {number|null} [value] Int32Value value
                 */

                /**
                 * Constructs a new Int32Value.
                 * @memberof google.protobuf
                 * @classdesc Represents an Int32Value.
                 * @implements IInt32Value
                 * @constructor
                 * @param {google.protobuf.IInt32Value=} [properties] Properties to set
                 */
                function Int32Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Int32Value value.
                 * @member {number} value
                 * @memberof google.protobuf.Int32Value
                 * @instance
                 */
                Int32Value.prototype.value = 0;

                /**
                 * Creates a new Int32Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value=} [properties] Properties to set
                 * @returns {google.protobuf.Int32Value} Int32Value instance
                 */
                Int32Value.create = function create(properties) {
                    return new Int32Value(properties);
                };

                /**
                 * Encodes the specified Int32Value message. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int32Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified Int32Value message, length delimited. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Int32Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Int32Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.Int32Value} Int32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int32Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int32Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Int32Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.Int32Value} Int32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Int32Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Int32Value message.
                 * @function verify
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Int32Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    return null;
                };

                /**
                 * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.Int32Value} Int32Value
                 */
                Int32Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.Int32Value)
                        return object;
                    var message = new $root.google.protobuf.Int32Value();
                    if (object.value != null)
                        message.value = object.value | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.Int32Value
                 * @static
                 * @param {google.protobuf.Int32Value} message Int32Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Int32Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };

                /**
                 * Converts this Int32Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.Int32Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Int32Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Int32Value;
            })();

            protobuf.UInt32Value = (function() {

                /**
                 * Properties of a UInt32Value.
                 * @memberof google.protobuf
                 * @interface IUInt32Value
                 * @property {number|null} [value] UInt32Value value
                 */

                /**
                 * Constructs a new UInt32Value.
                 * @memberof google.protobuf
                 * @classdesc Represents a UInt32Value.
                 * @implements IUInt32Value
                 * @constructor
                 * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
                 */
                function UInt32Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UInt32Value value.
                 * @member {number} value
                 * @memberof google.protobuf.UInt32Value
                 * @instance
                 */
                UInt32Value.prototype.value = 0;

                /**
                 * Creates a new UInt32Value instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
                 * @returns {google.protobuf.UInt32Value} UInt32Value instance
                 */
                UInt32Value.create = function create(properties) {
                    return new UInt32Value(properties);
                };

                /**
                 * Encodes the specified UInt32Value message. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt32Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified UInt32Value message, length delimited. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UInt32Value.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UInt32Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt32Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt32Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.uint32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UInt32Value message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UInt32Value.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UInt32Value message.
                 * @function verify
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UInt32Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    return null;
                };

                /**
                 * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UInt32Value} UInt32Value
                 */
                UInt32Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UInt32Value)
                        return object;
                    var message = new $root.google.protobuf.UInt32Value();
                    if (object.value != null)
                        message.value = object.value >>> 0;
                    return message;
                };

                /**
                 * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UInt32Value
                 * @static
                 * @param {google.protobuf.UInt32Value} message UInt32Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UInt32Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = 0;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };

                /**
                 * Converts this UInt32Value to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UInt32Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UInt32Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UInt32Value;
            })();

            protobuf.BoolValue = (function() {

                /**
                 * Properties of a BoolValue.
                 * @memberof google.protobuf
                 * @interface IBoolValue
                 * @property {boolean|null} [value] BoolValue value
                 */

                /**
                 * Constructs a new BoolValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a BoolValue.
                 * @implements IBoolValue
                 * @constructor
                 * @param {google.protobuf.IBoolValue=} [properties] Properties to set
                 */
                function BoolValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BoolValue value.
                 * @member {boolean} value
                 * @memberof google.protobuf.BoolValue
                 * @instance
                 */
                BoolValue.prototype.value = false;

                /**
                 * Creates a new BoolValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue=} [properties] Properties to set
                 * @returns {google.protobuf.BoolValue} BoolValue instance
                 */
                BoolValue.create = function create(properties) {
                    return new BoolValue(properties);
                };

                /**
                 * Encodes the specified BoolValue message. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoolValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified BoolValue message, length delimited. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BoolValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BoolValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.BoolValue} BoolValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoolValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BoolValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BoolValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.BoolValue} BoolValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BoolValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BoolValue message.
                 * @function verify
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BoolValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value !== "boolean")
                            return "value: boolean expected";
                    return null;
                };

                /**
                 * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.BoolValue} BoolValue
                 */
                BoolValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.BoolValue)
                        return object;
                    var message = new $root.google.protobuf.BoolValue();
                    if (object.value != null)
                        message.value = Boolean(object.value);
                    return message;
                };

                /**
                 * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.BoolValue
                 * @static
                 * @param {google.protobuf.BoolValue} message BoolValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BoolValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = false;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };

                /**
                 * Converts this BoolValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.BoolValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BoolValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BoolValue;
            })();

            protobuf.StringValue = (function() {

                /**
                 * Properties of a StringValue.
                 * @memberof google.protobuf
                 * @interface IStringValue
                 * @property {string|null} [value] StringValue value
                 */

                /**
                 * Constructs a new StringValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a StringValue.
                 * @implements IStringValue
                 * @constructor
                 * @param {google.protobuf.IStringValue=} [properties] Properties to set
                 */
                function StringValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StringValue value.
                 * @member {string} value
                 * @memberof google.protobuf.StringValue
                 * @instance
                 */
                StringValue.prototype.value = "";

                /**
                 * Creates a new StringValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue=} [properties] Properties to set
                 * @returns {google.protobuf.StringValue} StringValue instance
                 */
                StringValue.create = function create(properties) {
                    return new StringValue(properties);
                };

                /**
                 * Encodes the specified StringValue message. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StringValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified StringValue message, length delimited. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StringValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StringValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.StringValue} StringValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StringValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.StringValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StringValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.StringValue} StringValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StringValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StringValue message.
                 * @function verify
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StringValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isString(message.value))
                            return "value: string expected";
                    return null;
                };

                /**
                 * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.StringValue} StringValue
                 */
                StringValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.StringValue)
                        return object;
                    var message = new $root.google.protobuf.StringValue();
                    if (object.value != null)
                        message.value = String(object.value);
                    return message;
                };

                /**
                 * Creates a plain object from a StringValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.StringValue
                 * @static
                 * @param {google.protobuf.StringValue} message StringValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StringValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.value = "";
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    return object;
                };

                /**
                 * Converts this StringValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.StringValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StringValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return StringValue;
            })();

            protobuf.BytesValue = (function() {

                /**
                 * Properties of a BytesValue.
                 * @memberof google.protobuf
                 * @interface IBytesValue
                 * @property {Uint8Array|null} [value] BytesValue value
                 */

                /**
                 * Constructs a new BytesValue.
                 * @memberof google.protobuf
                 * @classdesc Represents a BytesValue.
                 * @implements IBytesValue
                 * @constructor
                 * @param {google.protobuf.IBytesValue=} [properties] Properties to set
                 */
                function BytesValue(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BytesValue value.
                 * @member {Uint8Array} value
                 * @memberof google.protobuf.BytesValue
                 * @instance
                 */
                BytesValue.prototype.value = $util.newBuffer([]);

                /**
                 * Creates a new BytesValue instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue=} [properties] Properties to set
                 * @returns {google.protobuf.BytesValue} BytesValue instance
                 */
                BytesValue.create = function create(properties) {
                    return new BytesValue(properties);
                };

                /**
                 * Encodes the specified BytesValue message. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BytesValue.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified BytesValue message, length delimited. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BytesValue.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BytesValue message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.BytesValue} BytesValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BytesValue.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BytesValue();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.value = reader.bytes();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BytesValue message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.BytesValue} BytesValue
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BytesValue.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BytesValue message.
                 * @function verify
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BytesValue.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                            return "value: buffer expected";
                    return null;
                };

                /**
                 * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.BytesValue} BytesValue
                 */
                BytesValue.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.BytesValue)
                        return object;
                    var message = new $root.google.protobuf.BytesValue();
                    if (object.value != null)
                        if (typeof object.value === "string")
                            $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                        else if (object.value.length)
                            message.value = object.value;
                    return message;
                };

                /**
                 * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.BytesValue
                 * @static
                 * @param {google.protobuf.BytesValue} message BytesValue
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BytesValue.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if (options.bytes === String)
                            object.value = "";
                        else {
                            object.value = [];
                            if (options.bytes !== Array)
                                object.value = $util.newBuffer(object.value);
                        }
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                    return object;
                };

                /**
                 * Converts this BytesValue to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.BytesValue
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BytesValue.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return BytesValue;
            })();

            return protobuf;
        })();

        return google;
    })();

    return $root;
});
